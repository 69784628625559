<template>
    <!-- <div class="debug-panel one">
        <div class="debug-label">caretIndex:</div>
        <div>{{ caretIndex }}</div>
        <Spacer height="0.5rem" />
        <div class="debug-label">currentNode:</div>
        <pre>{{ JSON.stringify(currentNode, null, 2) }}</pre>
        <Spacer height="0.5rem" />
        <Spacer height="0.5rem" />
        <div class="debug-label">matchedEntities:[]</div>
        <div>
            <pre>{{ JSON.stringify(matchedEntities, null, 2) }}</pre>
        </div>
        <Spacer height="0.5rem" />
        <div class="debug-label">currentEntity:</div>
        <div>
            <pre>{{ JSON.stringify(currentEntity, null, 2) }}</pre>
        </div>
        <Spacer height="0.5rem" />
        <div class="debug-label">modelValue:[{{ modelValue.length }}]</div>
        <div>"{{ modelValue }}"</div>
    </div> -->

    <div class="chat-input-wrapper">
        <div class="chat-input-container">
            <OnboardingModal
                v-model="useAtModalRef"
                @closed="!useAtModalRef"
                position="absolute"
                :set-bounding-rect="{ top: '-56px', left: 0 }"
                max-width="52.5rem"
                gap="0.875rem"
                transition="use-at-fade"
                :transition-delay="640"
                button-text="Okay, thanks"
                style="padding: 14px 16px 14px 24px; border-radius: 20px"
            >
                <template #copy>
                    Did you know? You can use the <span class="at">@</span> symbol to reference a
                    campaign, ad group, or keyword in your account.
                </template>
                <template #button>
                    <oButton color="dark-blue" size="small" @clicked="useAtModalRef = false">
                        Okay, thanks
                    </oButton>
                </template>
            </OnboardingModal>

            <!-- v-once stops DOM node re-rendering after mount even if modelValue changes -->
            <div
                v-once
                :contenteditable="true"
                spellcheck="false"
                ref="chatInputRef"
                class="chat-input"
                @click="clickUpdateCaretPosition"
            >
                {{ modelValue }}
            </div>
            <div class="chat-input-button">
                <oButton @clicked="sendQuery" iconBefore color="dark-blue">
                    <template #icon>
                        <ChatInputButtonIcon />
                    </template>
                    Send Message
                </oButton>
            </div>
        </div>
        <div class="chat-input-disclaimer">
            <Text as="span" size="f-9" style="font-size: 0.75rem">
                All responses are provided by ChatGPT which is currently available as a research
                preview. Responses can occasionally be incorrect or have other issues.
            </Text>
        </div>
    </div>
</template>

<script lang="ts">
// Vue imports
import { defineComponent, ref, onMounted, h, watch, computed, toRefs, unref, nextTick } from 'vue'
import debounce from 'lodash-es/debounce'
import { useEventListener, useLocalStorage, useManualRefHistory } from '@vueuse/core'
import { useTippy } from 'vue-tippy'
import { useChatEntities } from '@/composition/chat/useChatEntities'
import { delay } from '@/lib/globalUtils'
import { LS_CHAT_TUTORIAL_AT } from '@/lib/cookies'

// Chat components
import ChatInputButtonIcon from '@/components/chat/ChatInputButtonIcon.vue'
import ChatSelect from '@/components/chat/ChatSelect.vue'

// @opteo/components-next
import {
    Text,
    Spacer,
    oButton,
    useShortKey,
    CircleCampaignIcon,
    CircleAdgroupIcon,
    CircleKeywordIcon,
    OnboardingModal,
} from '@opteo/components-next'

export type EntityType = 'campaign' | 'adgroup' | 'keyword'

// Export
export default defineComponent({
    name: 'ChatInput',
    components: {
        // @opteo/components-next
        Text,
        Spacer,
        oButton,
        ChatInputButtonIcon,
        ChatSelect,
        OnboardingModal,
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        buttonDisabled: {
            type: Boolean,
            required: false,
        },
        entities: {
            type: Object,
            default: {},
        },
        entitiesLoading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'sendQuery'],
    setup(props, { emit }) {
        const chatInputRef = ref()
        const useAtModalRef = useLocalStorage(LS_CHAT_TUTORIAL_AT, true)

        const { entities } = toRefs(props)
        const query = ref(props.modelValue)

        // bodyElement
        const bodyElement = document.body

        // Autofocus
        onMounted(() => {
            chatInputRef.value.focus()
        })

        // Emit sendQuery
        const sendQuery = () => {
            if (props.buttonDisabled) return
            emit('sendQuery')

            // reset after query is sent
            clearInput()
        }

        function clearInput() {
            query.value = ''
            emit('update:modelValue', '')
            insertHTML(HTML.value)
        }

        useShortKey({
            keys: ['enter'],
            onPressCallBack: () => {
                if (tippyOpenRef.value) return
                sendQuery()
            },
            eventTarget: chatInputRef,
        })

        const { HTML, matchedEntities, getEntitiesByType } = useChatEntities(query, entities)

        function insertHTML(newHTML: string) {
            const lastNode = chatInputRef.value.childNodes[chatInputRef.value.childNodes.length - 1]
            // console.log('lastNode', lastNode)

            // const addSpace = lastNode.nodeName === '#text' &&

            //     ?.textContent?.endsWith(' ') || false
            // console.log('addSpace', addSpace)
            chatInputRef.value.innerHTML = newHTML.replaceAll('> ', '>&nbsp;')
        }

        const currentNode = ref({
            node: null,
            index: 0,
        })

        const caretIndex = ref(0)

        function getCaretIndexInQuery() {
            let position = 0

            const selection = window.getSelection()

            if (selection && selection.rangeCount !== 0) {
                const range = selection.getRangeAt(0)
                const preCaretRange = range.cloneRange()
                const preCaretRangeContents = preCaretRange.selectNodeContents(chatInputRef.value)
                preCaretRange.setEnd(range.endContainer, range.endOffset)
                position = preCaretRange.toString().length
            }

            return position
        }

        // get caret position in specific node
        function getCursorPosition(parent, node, offset, status) {
            if (status.done) return status

            let _currentNode = null
            if (parent.childNodes.length == 0) {
                status.pos += parent.textContent.length
            } else {
                for (let i = 0; i < parent.childNodes.length && !status.done; i++) {
                    _currentNode = parent.childNodes[i]
                    if (_currentNode === node) {
                        status.pos += offset
                        status.done = true
                        return status
                    } else getCursorPosition(_currentNode, node, offset, status)
                }
            }
            return status
        }

        // find the child node and relative position in .chat-input and set it on range
        function setCursorPosition(parent, range, status) {
            if (status.done) return range

            let _currentNode = null
            if (parent.childNodes.length == 0) {
                if (parent.textContent.length >= status.pos) {
                    range.setStart(parent, status.pos)
                    status.done = true
                } else {
                    status.pos = status.pos - parent.textContent.length
                }
            } else {
                for (let i = 0; i < parent.childNodes.length && !status.done; i++) {
                    _currentNode = parent.childNodes[i]
                    setCursorPosition(_currentNode, range, status)
                }
            }
            return range
        }

        // keep caretIndex synced with contenteditable caret position on click
        function clickUpdateCaretPosition(event: MouseEvent) {
            const entity = (event.target as HTMLElement).closest('.entity-tag') as HTMLElement

            if (entity && entity.dataset.type !== 'incomplete') {
                const clickedIndex = getCaretIndexInQuery()
                const matchedEntity = findEntityByIndex(clickedIndex)
                const newCaretIndex = matchedEntity && matchedEntity.endIndex + 1

                const selection = window.getSelection()
                selection.removeAllRanges()

                const range = setCursorPosition(chatInputRef.value, document.createRange(), {
                    pos: newCaretIndex,
                    done: false,
                })

                range.collapse(true)
                selection.addRange(range)
                caretIndex.value = newCaretIndex ?? clickedIndex
            } else {
                caretIndex.value = getCaretIndexInQuery()
            }
        }

        const entityTypes = [
            {
                id: 'campaign',
                icon: CircleCampaignIcon,
                name: 'Campaign',
            },
            {
                id: 'adgroup',
                icon: CircleAdgroupIcon,
                name: 'Ad Group',
            },
            {
                id: 'keyword',
                icon: CircleKeywordIcon,
                name: 'Keyword',
            },
        ]

        function addEntityIcon(type: EntityType) {
            return type === 'campaign'
                ? CircleCampaignIcon
                : type === 'adgroup'
                ? CircleAdgroupIcon
                : CircleKeywordIcon
        }

        // this should only be set by tippy
        const tippyOpenRef = ref(false)

        // The following refs and computed properties will be derived from the query using regex this will help us orchestrate the tippy instance and other interactions
        const selectQuery = ref('')
        const selectListType = ref<EntityType | 'entity-type'>('entity-type')

        // campaign, adgroup, keyword
        const entityTypeIds = computed(() => entityTypes.map(entity => entity.id))

        function findEntityByIndex(index: number) {
            return matchedEntities.value.find(
                entity => !!(entity.startIndex <= index && index <= entity.endIndex)
            )
        }

        const currentEntity = computed(() => findEntityByIndex(caretIndex.value))

        const chatSelectList = computed(() =>
            selectListType.value !== 'entity-type'
                ? getEntitiesByType(selectListType.value)
                : entityTypes
        )

        const filteredSelectList = computed(() =>
            selectQuery.value && selectQuery.value.length
                ? chatSelectList.value
                      .filter(entity =>
                          entity.name.toLowerCase().includes(selectQuery.value.toLowerCase())
                      )
                      .sort((a, b) => {
                          const aStartIndex = a.name
                              .toLowerCase()
                              .indexOf(selectQuery.value.toLowerCase())
                          const bStartIndex = b.name
                              .toLowerCase()
                              .indexOf(selectQuery.value.toLowerCase())
                          return aStartIndex - bStartIndex
                      })
                : chatSelectList.value
        )

        const { show, hide, setProps } = useTippy(() => document.body, {
            // this is an unconventional technique from how we usually use tippy, we are setting the content as the <ChatSelect /> component using a vue render function denoted by h()
            // wrapping it in computed keeps the props reactive
            // https://vue-tippy.netlify.app/props/#content
            content: computed(() =>
                h(ChatSelect, {
                    // here we are setting the props
                    modelValue: tippyOpenRef.value,
                    entityType: selectListType.value,
                    entities: filteredSelectList.value?.map(entity => ({
                        // add corresponding circle icon component for entity type
                        ...(selectListType.value !== 'entity-type' && {
                            entityClass: 'entity-item',
                            type: selectListType.value,
                            icon: addEntityIcon(selectListType.value),
                        }),
                        ...entity,
                    })),
                    entitiesLoading: props.entitiesLoading,
                    // here we listen to the emitted select event, more conventionally we know this as @select=""
                    onSelect: value => {
                        if (!tippyOpenRef.value) return
                        const selectingType = selectListType.value === 'entity-type'
                        if (selectingType && !value) {
                            hideTippy()
                            const selection = window.getSelection()
                            selection.removeAllRanges()

                            const range = setCursorPosition(
                                chatInputRef.value,
                                document.createRange(),
                                {
                                    pos: caretIndex.value,
                                    done: false,
                                }
                            )

                            range.collapse(true)
                            selection.addRange(range)
                            return
                        }
                        // convert query string to array of characters
                        const queryStringToArray = Array.from(query.value)

                        // here we remove the selectQuery from the query string
                        // and replace it with the case sensitive entity type or entity name
                        if (selectingType) {
                            // here we select entity type
                            // the appended ":" is syntax to help us detect the entity types and names using regex
                            const stringToInsert = `${value.id.replace(selectQuery.value, '')}:`
                            queryStringToArray.splice(caretIndex.value, 0, stringToInsert)
                            caretIndex.value = caretIndex.value + stringToInsert.length
                        } else {
                            // here we select entity name
                            const startIndex = caretIndex.value - selectQuery.value.length
                            const numberToRemove = selectQuery.value.length
                            queryStringToArray.splice(
                                startIndex,
                                numberToRemove,
                                value.resourceName
                            )

                            caretIndex.value =
                                startIndex + value.resourceName.length + (!selectingType ? 1 : 0)
                        }

                        // convert array back to string, conditionally add a space if we are selecting an entity name
                        const queryWithEntity =
                            queryStringToArray.join('') + (!selectingType ? ' ' : '')

                        // emit the change to update the query in chat.vue
                        emit('update:modelValue', queryWithEntity)

                        query.value = queryWithEntity

                        commitToUndoHistory()

                        insertHTML(HTML.value)

                        // restore caret position
                        const selection = window.getSelection()
                        selection.removeAllRanges()

                        const range = setCursorPosition(
                            chatInputRef.value,
                            document.createRange(),
                            {
                                pos: caretIndex.value,
                                done: false,
                            }
                        )

                        range.collapse(true)
                        selection.addRange(range)

                        hideTippy()
                    },
                })
            ),
            interactive: true,
            maxWidth: 'none',
            trigger: 'manual',
            placement: 'top-start',
            offset: [-16, 16],
            delay: [0, 0],
            duration: [0, 0],
            popperOptions: {
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: bodyElement,
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            flipVariations: false,
                        },
                    },
                ],
            },
            onShown(instance) {
                // this ref should only be set by tippy
                tippyOpenRef.value = true
            },
            onHidden(instance) {
                // this ref should only be set by tippy
                tippyOpenRef.value = false
            },
        })

        const prefix = '@'
        const prefixWidth = 16 // px

        function showTippy() {
            let top = 0
            let left = 0
            // get position of caret when @ is typed, no HTML exists at this point
            if (!currentEntity.value) {
                const caretRect = window.getSelection().getRangeAt(0).getClientRects()[0]
                top = caretRect.top
                left = caretRect.left - prefixWidth
            } else {
                // get position of the currentEntity element, HTML now exists
                const entityElement = chatInputRef.value
                    .querySelector(`[data-id="${currentEntity.value?.id}"]`)
                    ?.getBoundingClientRect()

                top = entityElement.top
                left = entityElement.left
            }

            selectListType.value = entityTypeIds.value.includes(
                currentEntity.value?.type as EntityType
            )
                ? currentEntity.value?.type
                : 'entity-type'

            // set tippy position
            setProps({
                getReferenceClientRect: () => {
                    return {
                        width: 0,
                        height: 0,
                        top: top,
                        left: left,
                    }
                },
            })

            show()
        }

        function hideTippy() {
            hide()
            selectQuery.value = ''
        }

        useEventListener(chatInputRef, 'input', async (event: InputEvent) => {
            const previousCharacter = query.value.charAt(caretIndex.value - 1)
            const noTippyOrEntity = tippyOpenRef.value && !currentEntity.value

            // if tippy instance is open and there is no entity being edited, close tippy when backspace is pressed or when selectQuery has returned to empty
            if (
                (noTippyOrEntity && event.inputType === 'deleteContentBackward') ||
                (noTippyOrEntity && previousCharacter === prefix)
            ) {
                hide()
            }

            await nextTick()

            // is @ is typed and not open, show tippy
            if (event.data === prefix && !tippyOpenRef.value) {
                showTippy()
            }

            let updatedQuery = unref(query)
            let deletedStringLength = 0

            // delete entire entity if backspace is pressed and caretIndex matches the endIndex of the entity
            if (
                (event.inputType === 'deleteContentBackward' &&
                    currentEntity.value &&
                    currentEntity.value.state === 'complete' &&
                    caretIndex.value === currentEntity.value.endIndex) ||
                (event.inputType === 'deleteContentBackward' &&
                    currentEntity.value &&
                    currentEntity.value.state === 'semi-complete' &&
                    selectQuery.value.length === 0)
            ) {
                // startIndex + 1 so @ remains in query
                const start = updatedQuery.slice(0, currentEntity.value.startIndex + 1)
                const end = updatedQuery.slice(currentEntity.value.endIndex)

                // -2 because we want just want the string length of the entity
                deletedStringLength =
                    currentEntity.value.endIndex - currentEntity.value.startIndex - 2
                updatedQuery = start + end

                query.value = updatedQuery ?? ''
            } else {
                query.value = chatInputRef.value.textContent ?? ''
            }

            commitToUndoHistory()

            const newCaretIndex = getCaretIndexInQuery()
            caretIndex.value = newCaretIndex - deletedStringLength ?? 0

            insertHTML(HTML.value)

            emit('update:modelValue', chatInputRef.value.textContent ?? '')

            if (query.value.length === 0) {
                return
            }

            const selection = window.getSelection()
            // restore the caret position
            selection.removeAllRanges()
            const range = setCursorPosition(chatInputRef.value, document.createRange(), {
                pos: caretIndex.value,
                done: false,
            })

            range.collapse(true)
            selection.addRange(range)
        })

        useShortKey({
            keys: ['esc'],
            onPressCallBack: event => {
                if (tippyOpenRef.value) {
                    hide()
                }
            },
        })

        watch(currentEntity, async entity => {
            await nextTick()
            if (entity) {
                if (!tippyOpenRef.value && entity.state !== 'complete') {
                    showTippy()
                }
                if (entity.state === 'incomplete') {
                    if (selectListType.value !== 'entity-type') {
                        selectListType.value = 'entity-type'
                    }
                    selectQuery.value = entity.type
                }
                if (entity.state === 'semi-complete') {
                    if (selectListType.value !== entity.type) {
                        selectListType.value = entity.type as EntityType
                    }
                    selectQuery.value = entity.name

                    if (!tippyOpenRef.value) {
                        showTippy()
                    }
                }
                if (entity.state === 'complete') {
                    hideTippy()
                }
            } else {
                if (tippyOpenRef.value) {
                    hideTippy()
                }
            }
        })

        useShortKey({
            keys: ['arrowup'],
            preventDefault: false,
            onPressCallBack: async event => {
                if (tippyOpenRef.value) {
                    event.preventDefault()
                    return
                }
                await delay(20)
                // keep caretIndex synced with contenteditable caret position
                caretIndex.value = getCaretIndexInQuery()
            },
            eventTarget: chatInputRef,
        })

        useShortKey({
            keys: ['arrowdown'],
            preventDefault: false,
            onPressCallBack: async event => {
                if (tippyOpenRef.value) {
                    event.preventDefault()
                    return
                }
                await delay(20)

                caretIndex.value = getCaretIndexInQuery()
            },
            eventTarget: chatInputRef,
        })

        // +2 for @ and semi-colon
        const preventLeftArrow = computed(
            () =>
                currentEntity.value?.state === 'semi-complete' &&
                caretIndex.value ===
                    currentEntity.value?.startIndex + currentEntity.value?.type.length + 2
        )

        for (const keys of [['arrowleft'], ['alt', 'arrowleft']]) {
            useShortKey({
                keys,
                preventDefault: preventLeftArrow.value,
                onPressCallBack: async event => {
                    if (preventLeftArrow.value) {
                        event.preventDefault()
                        return
                    }
                    await delay(20)

                    // keep caretIndex synced with contenteditable caret position
                    const nextCaretIndex = getCaretIndexInQuery()
                    const foundEntity = matchedEntities.value.find(
                        entity =>
                            !!(
                                entity.startIndex <= nextCaretIndex &&
                                nextCaretIndex < entity.endIndex
                            )
                    )

                    // if caretNextIndex matches currentEntity endIndex, bounce caret position to start of entity
                    if (
                        currentEntity &&
                        currentEntity.value?.state !== 'semi-complete' &&
                        currentEntity.value?.startIndex < nextCaretIndex &&
                        nextCaretIndex <= currentEntity.value?.endIndex
                    ) {
                        const selection = window.getSelection()
                        selection.removeAllRanges()
                        const newCaretIndex = currentEntity.value.startIndex
                        const range = setCursorPosition(
                            chatInputRef.value,
                            document.createRange(),
                            {
                                pos: newCaretIndex,
                                done: false,
                            }
                        )

                        range.collapse(true)
                        selection.addRange(range)
                        caretIndex.value = newCaretIndex
                    } else if (currentEntity.value?.state !== 'semi-complete' && foundEntity) {
                        const selection = window.getSelection()
                        selection.removeAllRanges()
                        const newCaretIndex = foundEntity.startIndex
                        const range = setCursorPosition(
                            chatInputRef.value,
                            document.createRange(),
                            {
                                pos: newCaretIndex,
                                done: false,
                            }
                        )

                        range.collapse(true)
                        selection.addRange(range)
                        caretIndex.value = newCaretIndex
                    } else {
                        caretIndex.value = nextCaretIndex
                    }
                },
                eventTarget: chatInputRef,
            })
        }

        for (const keys of [['arrowright'], ['alt', 'arrowright']]) {
            useShortKey({
                keys,
                preventDefault: false,
                onPressCallBack: async () => {
                    await delay(20)
                    // keep caretIndex synced with contenteditable caret position
                    const nextCaretIndex = getCaretIndexInQuery()
                    const foundEntity = findEntityByIndex(nextCaretIndex)

                    // if caretNextIndex falls between the startIndex or endIndex of an entity, bounce caret position to end of entity
                    if (foundEntity) {
                        const selection = window.getSelection()
                        selection.removeAllRanges()
                        const newCaretIndex = foundEntity.endIndex + 1
                        const range = setCursorPosition(
                            chatInputRef.value,
                            document.createRange(),
                            {
                                pos: newCaretIndex,
                                done: false,
                            }
                        )

                        range.collapse(true)
                        selection.addRange(range)
                        caretIndex.value = newCaretIndex
                    } else {
                        caretIndex.value = nextCaretIndex
                    }
                },
                eventTarget: chatInputRef,
            })
        }

        useShortKey({
            keys: ['meta', 'arrowup'],
            preventDefault: false,
            onPressCallBack: async event => {
                await delay(20)
                // keep caretIndex synced with contenteditable caret position
                caretIndex.value = getCaretIndexInQuery()
            },
            eventTarget: chatInputRef,
        })

        useShortKey({
            keys: ['meta', 'arrowdown'],
            preventDefault: false,
            onPressCallBack: async event => {
                await delay(20)
                // keep caretIndex synced with contenteditable caret position
                caretIndex.value = getCaretIndexInQuery()
            },
            eventTarget: chatInputRef,
        })

        /*
            Undo/Redo support for our contentEditable element
        */

        const { commit, undo, redo } = useManualRefHistory(query)

        const commitToUndoHistory = debounce(() => commit(), 200, {
            leading: true,
            trailing: true,
        })

        for (const keys of [
            ['meta', 'z'],
            ['ctrl', 'z'],
        ]) {
            useShortKey({
                keys,
                onPressCallBack: () => {
                    undo()
                    afterUndoHistoryChange()
                },
                eventTarget: chatInputRef,
            })
        }

        for (const keys of [
            ['meta', 'y'],
            ['ctrl', 'y'],
            ['meta', 'shift', 'z'], // Doesn't seem to work... useshortkey limitation of two keys?
            ['ctrl', 'shift', 'z'], // Doesn't seem to work... useshortkey limitation of two keys?
        ]) {
            useShortKey({
                keys,
                onPressCallBack: () => {
                    redo()
                    afterUndoHistoryChange()
                },
                eventTarget: chatInputRef,
            })
        }

        const afterUndoHistoryChange = () => {
            insertHTML(HTML.value)
            emit('update:modelValue', chatInputRef.value.textContent ?? '')

            // TODO: restore cursor position instead of moving it to the end.
            // The arane commands below are to restore the cursor position to the end of the contenteditable.
            chatInputRef.value.focus()
            // select all the content in the element
            document.execCommand('selectAll', false, null)
            // collapse selection to the end
            document.getSelection().collapseToEnd()
        }

        return {
            chatInputRef,
            useAtModalRef,
            tippyOpenRef,

            caretIndex,
            currentNode,
            currentEntity,
            matchedEntities,
            HTML,
            selectQuery,
            selectListType,

            preventLeftArrow,

            filteredSelectList,

            clickUpdateCaretPosition,
            sendQuery,

            clearInput,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Containers
.chat-input-wrapper {
    width: 100%;
    padding-top: 2rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 4rem, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
}
.chat-input-container {
    box-shadow: $opteo-shadow;
    @include br-24;
    height: auto;
    @include relative;
    max-width: 63rem;
    margin: 0 auto;
    pointer-events: auto;
}

// Input
.chat-input {
    box-shadow: none;
    border: none;
    height: auto;
    width: 100%;
    padding: 1.5rem 13rem 1.5rem 2rem;
    font-size: 1.25rem;
    line-height: 2.375rem;
    font-weight: 300;
    letter-spacing: -0.0075rem;
    @include br-24;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    @include bg-opteo-white;
    // when an .entity-tag is inserted, the input height is jumps from 82px to 86px
    // min-height: 86px;

    // mozilla white-space fix
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
.chat-input:focus {
    box-shadow: $opteo-blue-shadow 0 0 0 0.25rem;
    outline: none;
}

// Button
.chat-input-button {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}
.chat-input-button :deep(.o-button) {
    height: 3.25rem;
    font-size: 0.875rem;
    padding: 0 1.5rem 0 1.4375rem;
}
.chat-input-button :deep(.o-button span) {
    transform: translateY(-0.125rem);
}

// Disclaimer
.chat-input-disclaimer {
    opacity: 0.24;
    max-width: 63rem;
    margin: 1rem auto 0 auto;
    padding-bottom: 1.5rem;
    pointer-events: auto;
}

.debug-panel {
    position: fixed;
    top: 0rem;
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.75rem;
    background-color: lavender;
    max-width: 320px;
    width: 100%;
    z-index: 999999999;
    opacity: 0.4;
}
.debug-panel.one {
    right: 0;
    top: 0rem;
}

.debug-label {
    opacity: 0.5;
    font-size: 0.75rem;
}
.debug-panel pre,
.debug-panel.one pre,
.debug-panel.two pre {
    padding: 0.125rem !important;
    border-radius: 0.75rem;
    margin: 0;
    border: 1px solid #00000014;
    background-color: lavender;
    overflow: hidden;
}

// Use @
:deep(.at) {
    margin-left: 2px;
    margin-right: 2px;
    background: hsl(233, 96%, 93%);
    padding: 3px 6px;
    border-radius: 6px;
    font-weight: 500;
}

:deep(.onboarding-use-at-fade-enter-active .onboarding) {
    transition:
        opacity 0.64s cubic-bezier(0.19, 1, 0.22, 1) 0.64s,
        transform 0.64s cubic-bezier(0.19, 1, 0.22, 1) 0.64s;
}
:deep(.onboarding-use-at-fade-leave-active .onboarding) {
    transition:
        opacity 0.64s cubic-bezier(0.19, 1, 0.22, 1),
        transform 0.64s cubic-bezier(0.19, 1, 0.22, 1);
}
:deep(.onboarding-use-at-fade-enter-from .onboarding),
:deep(.onboarding-use-at-fade-leave-to .onboarding) {
    opacity: 0;
    transform: translate3d(0, 0.5rem, 0);
}

// Responsive

@media screen and (max-width: 1440px) {
    .chat-input-container {
        max-width: 52.5rem;
    }
    .chat-input-disclaimer {
        max-width: 52.5rem;
    }
}
</style>
