<template>
    <div class="experiment">
        <!-- Header -->
        <header>
            <Text as="h6" weight="600">{{ experiment.bidStrategy }}</Text>
            <div class="vs">vs</div>
            <Text as="h6" weight="600">{{ experiment.recommendedBidStrategy }}</Text>
        </header>
        <Spacer height="1.5rem" />
        <!-- Campaigns -->
        <perfect-scrollbar class="campaigns">
            <EntityPill
                v-for="(campaign, index) in experiment.campaigns"
                :key="'campaign-' + index"
                type="campaign"
                :content="campaign.campaignName"
                :tooltip="false"
            />
        </perfect-scrollbar>
        <Spacer height="1.5rem" />
        <!-- Description -->
        <div class="description">
            <Text as="p" size="f-8">
                For the listed campaigns, Opteo recommends creating an experiment to see if they
                could perform better using
                {{ experiment.recommendedBidStrategy === 'Enhanced CPC' ? 'an' : 'a' }}
                <b>{{ experiment.recommendedBidStrategy }}</b> bidding strategy. Click below to set
                up and adjust the parameters for this experiment.
            </Text>
        </div>
        <Spacer height="1.75rem" />
        <!-- Button -->
        <div class="buttons">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
// Components
import { Text, Spacer, EntityPill } from '@opteo/components-next'

// Export
export default {
    name: 'RecommendedExperiment',
    components: {
        Text,
        Spacer,
        EntityPill,
    },
    props: {
        experiment: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.experiment {
    @include container;
    @include pa-32;
    @include br-28;
}
.experiment header {
    @include flex;
    @include items-center;
}
.experiment header h6 {
    font-size: 1.375rem;
    letter-spacing: -0.0275rem;
}
.experiment header .vs {
    @include container;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 999rem;
    @include f-10;
    @include flex-center;
    color: rgba(0, 0, 0, 0.32);
    padding-bottom: 0.125rem;
    margin: 0 0.5625rem;
}
.experiment .campaigns {
    @include flex;
    @include items-center;
    @include container;
    @include pa-20;
    width: 100%;
    gap: 0.75rem;
}
.experiment .description {
    @include container;
    @include pv-20;
    @include ph-24;
    width: 100%;
}
.experiment .buttons {
    width: 100%;
    @include flex-center;
}
</style>
