<template>
    <router-link :to="openReportLink" style="display: block; width: 100%">
        <ReportGridItemContainer>
            <div class="h-100">
                <Text as="h3" size="f-6">{{ report.title }}</Text>
                <Spacer height="0.75rem" />
                <Text as="h4" size="f-8" weight="400" color="gray">{{ report.date_range }}</Text>
            </div>

            <!-- STATUS & ACTIONS & USER -->
            <div
                class="flex items-center pl-28 pb-28 absolute bottom-0 left-0"
                v-if="isDuplicating"
            >
                <div class="spinner-cnt">
                    <Spinner />
                </div>
            </div>
            <div v-else>
                <div
                    class="flex items-center justify-between pl-28 pb-28 absolute bottom-0 left-0"
                    style="cursor: default"
                >
                    <Tooltip
                        v-if="report.last_exported_ts"
                        :content="isArchive ? 'Completed (Archived)' : 'Completed'"
                        class="status-circle"
                    >
                        <CheckIcon />
                    </Tooltip>
                    <Tooltip
                        v-else
                        :content="isArchive ? 'In Progress (Archived)' : 'In Progress'"
                        class="status-circle"
                    >
                        <InProgressIcon :archived="isArchive" />
                    </Tooltip>

                    <Spacer width="0.5rem" height="0.25rem" />

                    <!-- Avatar -->
                    <Tooltip class="status-circle" :content="`Created by ${report.name}`">
                        <ProfileImage
                            :width="24"
                            :image-url="report.profile_url"
                            :avatar-url="avatarUrl"
                        />
                    </Tooltip>

                    <Spacer width="0.5rem" height="0.25rem" />

                    <!-- Scheduled Report Icon -->
                    <Tooltip
                        style="height: 24px; width: 24px; cursor: default"
                        :content="`Generated On ${createdTs}`"
                        v-if="report.auto_gen"
                    >
                        <ScheduledIcon />
                    </Tooltip>
                </div>

                <div class="pr-24 pb-24 absolute bottom-0 right-0">
                    <ReportItemActions :report="report" />
                </div>
            </div>
        </ReportGridItemContainer>
    </router-link>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { Reports } from '@opteo/types'

import {
    oButton,
    Text,
    CloseButton,
    Spacer,
    Tooltip,
    CheckIcon,
    ScheduledIcon,
    InProgressIcon,
    Spinner,
    ProfileImage,
} from '@opteo/components-next'
import ReportItemActions from '@/components/Reports/ReportItemActions.vue'
import ReportGridItemContainer from './ReportGridItemContainer.vue'
import { format, parseISO } from 'date-fns'
import { Routes } from '@/router/routes'

import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

export default {
    name: 'ReportsRow',
    components: {
        oButton,
        ProfileImage,
        Text,
        ReportGridItemContainer,
        ReportItemActions,
        CloseButton,
        Spacer,
        Tooltip,
        CheckIcon,
        ScheduledIcon,
        InProgressIcon,
        Spinner,
    },
    props: {
        report: {
            type: Object as PropType<Reports.Core.ReportsListItem>,
            required: true,
        },
    },
    setup: props => {
        const { getAvatarUrl } = useBoringAvatar()
        const avatarUrl = computed(() => getAvatarUrl(props.report.user_id))
        const isArchive = computed(() => !!props.report.archived)
        const createdTs = computed(() => format(parseISO(props.report.created_ts), 'MMMM do'))
        const isDuplicating = computed(() => props.report.report_id === -1)

        const openReportLink = computed(() => {
            if (isDuplicating.value) {
                return ''
            }

            return {
                name: isArchive.value ? Routes.ReportArchive : Routes.ReportActive,
                params: { reportId: props.report.report_id },
            }
        })

        return {
            isArchive,
            createdTs,
            isDuplicating,
            openReportLink,
            avatarUrl,
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.grid-item {
    @include container;
    @include br-20;
    position: relative;
    width: 100%;
    @include opteo-background;
    cursor: pointer;
}
.grid-item-inner {
    position: absolute;
    @include inset;
}

.status-circle {
    width: 1.5rem;
    height: 1.5rem;
}

.report-grid-slide,
.grid-item:before,
.grid-item:after {
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    will-change: transform;
}
.grid-item:before {
    content: '';
    background-color: white;
    position: absolute;
    left: 10px;
    right: 10px;
    height: 40px;
    bottom: -10px;
    z-index: -1;
    box-shadow: $opteo-shadow;
    border-radius: 1rem;
}
.grid-item:after {
    content: '';
    background-color: transparent;
    position: absolute;
    left: 20px;
    right: 20px;
    height: 40px;
    bottom: -20px;
    z-index: -2;
    box-shadow: $opteo-shadow;
    border-radius: 1rem;
}
.spinner-cnt {
    height: 24px;
    width: 24px;
    @include flex-center;
}
</style>
