<template>
    <div v-if="layout === 'default'" class="opteo-mobile-nav-container" :data-layout="layout">
        <!-- Account-level Nav Button -->
        <transition name="mobile-circle-button">
            <oButton
                class="mobile-nav-button"
                :class="{ active: mobileNavOpen }"
                @clicked="toggleMobileNav()"
                tabindex="0"
                :circle="true"
            >
                <template #icon>
                    <OpteoLogo
                        v-if="!mobileNavOpen"
                        :width="36"
                        :height="36"
                        style="width: 2.25rem; height: 2.25rem"
                    />
                    <MobileNavCloseIcon v-else :size="18" class="mobile-menu-close-icon" />
                </template>
            </oButton>
        </transition>

        <!-- Domain-level Nav Button -->
        <transition name="mobile-circle-button">
            <oButton
                v-if="accountInfo && accountInitials"
                class="mobile-domain-nav-button"
                :class="{ active: mobileDomainNavOpen }"
                @clicked="toggleMobileDomainNav()"
                tabindex="0"
                :circle="true"
                :style="{ background: accountInfo.color }"
            >
                <span v-if="!mobileDomainNavOpen">
                    {{ accountInitials }}
                </span>
                <MobileNavCloseIcon v-else :size="18" color="#ffffff" />
            </oButton>
        </transition>

        <!-- Account Sidebar -->
        <MobileSidebar
            :sidebar-open="mobileNavOpen"
            :on-click-outside="closeSidebar"
            :no-padding="true"
        >
            <div class="mobile-sidebar-section">
                <div
                    @click="goToAccountCentre()"
                    tabindex="0"
                    class="mobile-sidebar-menu-popover-link"
                >
                    <span>Account Centre</span>
                </div>
                <div @click="goToAlerts()" tabindex="0" class="mobile-sidebar-menu-popover-link">
                    <span>Alerts</span>
                    <AlertsCounter class="mobile-alerts-counter" />
                </div>
            </div>
            <div class="mobile-sidebar-section">
                <HelpSidebarContent />
            </div>
            <div class="mobile-sidebar-section">
                <AccountSidebarContent />
            </div>
        </MobileSidebar>

        <!-- Domain Sidebar -->
        <MobileSidebar
            :sidebar-open="mobileDomainNavOpen"
            :on-click-outside="closeSidebar"
            :no-padding="true"
        >
            <DomainTopNav :is-mobile-context="true" />
        </MobileSidebar>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, provide } from 'vue'
import { OpteoLogo, Spacer, oButton } from '@opteo/components-next'
import { Account } from '@opteo/types'
import { layout } from '@/composition/layouts/useLayouts'
import { useUser } from '@/composition/user/useUser'
import { useSubscription } from '@/composition/billing/useSubscription'
import { useRouter } from 'vue-router'

import MobileNavCloseIcon from '@/components/global/MobileNavCloseIcon.vue'
import { getDomainInitials, useDomain } from '@/composition/domain/useDomain'

import HelpSidebarContent from '@/components/User/Sidebars/HelpSidebar/HelpSidebarContent.vue'
import MobileSidebar from '@/components/global/MobileSidebar.vue'
import AlertsCounter from '@/components/User/Sidebars/AlertsSidebar/AlertsCounter.vue'
import AccountSidebarContent from '@/components/User/Sidebars/AccountSidebar/AccountSidebarContent.vue'
import DomainTopNav from '@/components/User/DomainTopNav.vue'

import { Routes } from '@/router/routes'
import { useAccountList } from '@/composition/user/useAccountList'
import { getAccountInitials } from '@/composition/account/useAccount'

export default defineComponent({
    name: 'MobileNavigation',
    components: {
        OpteoLogo,
        Spacer,
        oButton,
        MobileSidebar,
        MobileNavCloseIcon,
        HelpSidebarContent,
        AlertsCounter,
        AccountSidebarContent,
        getDomainInitials,
        useDomain,
        DomainTopNav,
    },
    setup() {
        const { isBillingLocked } = useSubscription()
        const { user, userId } = useUser()

        const router = useRouter()

        const accountId = computed(
            () => router.currentRoute.value.params.accountId as Account.ID | undefined
        )

        const { getAccountInfo } = useAccountList()
        const accountInfo = computed(() =>
            accountId.value ? getAccountInfo(accountId.value) : null
        )

        const accountName = computed(() => accountInfo.value?.name ?? '')
        const accountInitials = computed(() => getAccountInitials(accountName.value))

        const mobileNavOpen = ref<boolean>(false)
        const mobileDomainNavOpen = ref<boolean>(false)

        function closeMobileNav() {
            if (mobileNavOpen.value) {
                mobileNavOpen.value = false
            }
        }
        function closeMobileDomainNav() {
            if (mobileDomainNavOpen.value) {
                mobileDomainNavOpen.value = false
            }
        }

        function toggleMobileNav() {
            closeMobileDomainNav()
            mobileNavOpen.value = !mobileNavOpen.value
        }

        function toggleMobileDomainNav() {
            closeMobileNav()
            mobileDomainNavOpen.value = !mobileDomainNavOpen.value
        }

        const closeSidebar = (event: Event) => {
            const clickedElement = event.target as HTMLElement
            if (
                !!clickedElement.closest(
                    '[data-tippy-root], .mobile-nav-button, .mobile-domain-nav-button'
                )
            ) {
                return
            }
            closeMobileNav()
            closeMobileDomainNav()
        }

        provide('closeMobileNav', closeMobileNav)
        provide('closeMobileDomainNav', closeMobileDomainNav)

        function goToAccountCentre() {
            if (router.currentRoute.value.name === Routes.AccountCentre) {
                closeMobileNav()
            }

            if (!isBillingLocked.value) {
                router.push({ name: Routes.AccountCentre })
            }
        }

        function goToAlerts() {
            if (router.currentRoute.value.name === Routes.Alerts) {
                closeMobileNav()
            }
            router.push({ name: Routes.Alerts })
        }

        watch(router.currentRoute, () => {
            closeMobileNav()
            closeMobileDomainNav()
        })

        return {
            layout,
            userId,
            mobileNavOpen,
            mobileDomainNavOpen,
            toggleMobileNav,
            toggleMobileDomainNav,
            closeSidebar,
            accountInfo,
            accountId,
            accountName,
            accountInitials,
            goToAccountCentre,
            goToAlerts,
        }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
@import '@/assets/css/theme.scss';

.opteo-mobile-nav-container {
    display: none;
}

.no-scroll-fixed {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    position: fixed;
}

// Opteo button
.mobile-nav-button {
    display: none;
    position: fixed !important;
    right: 0.75rem;
    bottom: 0.75rem;
    z-index: 4002;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 3.75rem !important;
    background: $opteo-white !important;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1) !important;
    width: 4.5rem !important;
    height: 4.5rem !important;
    padding: 0 !important;
    cursor: pointer !important;
    box-shadow: $opteo-shadow-xl !important;
    transform: none !important;
}

// Domain button

.mobile-domain-nav-button {
    display: none;
    position: fixed !important;
    right: 0.75rem;
    bottom: 5.75rem;
    z-index: 4001;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 3.75rem !important;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1) !important;
    width: 4.5rem !important;
    height: 4.5rem !important;
    padding: 0 !important;
    cursor: pointer !important;
    box-shadow: $opteo-shadow-fill-xl !important;
    transform: none !important;
}
.mobile-domain-nav-button span {
    height: auto !important;
    transform: none !important;
    font-size: 0.875rem !important;
    font-weight: 600;
}

// Transition
.mobile-nav-slide-enter-active,
.mobile-nav-slide-leave-active {
    transition: transform 0.4375s cubic-bezier(0.64, 0, 0.32, 1);
}
.mobile-nav-slide-enter-from,
.mobile-nav-slide-leave-to {
    transform: translate3d(390px, 0, 0);
}
.mobile-nav-slide-leave-to {
    transition: transform 0.4375s cubic-bezier(0.64, 0, 0.32, 1);
}

// Section
.mobile-sidebar-section {
    @include pa-24;
    border-bottom: 1px solid $opteo-light-gray;
}
.mobile-sidebar-section:last-child {
    border-bottom: none;
}

// Link Item
.mobile-sidebar-menu-popover-link {
    position: relative;
    @include flex;
    @include items-center;
    gap: 0.625rem;
    height: 2.875rem;
    @include opteo-black;
    @include f-8;
    @include mb-8;
    @include ph-16;
    border-radius: 0.625rem;
    cursor: pointer;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.mobile-sidebar-menu-popover-link:hover {
    box-shadow: $opteo-shadow;
}
.mobile-sidebar-menu-popover-link:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}
.mobile-sidebar-menu-popover-link:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}
.mobile-sidebar-menu-popover-link:last-child {
    @include mb-0;
}

// sidebar transition
.mobile-circle-button-enter-active,
.mobile-circle-button-leave-active {
    transition:
        scale 0.4375s cubic-bezier(0.64, 0, 0.32, 1),
        opacity 0.4375s cubic-bezier(0.64, 0, 0.32, 1);
}
.mobile-circle-button-enter-from,
.mobile-circle-button-leave-to {
    transform: scale(0.9);
    opacity: 0;
}

// sidebar transition
.mobile-nav-slide-enter-active,
.mobile-nav-slide-leave-active {
    transition: transform 0.4375s cubic-bezier(0.64, 0, 0.32, 1);
}
.mobile-nav-slide-enter-from,
.mobile-nav-slide-leave-to {
    transform: translateX(390px);
}

@media screen and (max-width: $mq-767-max) {
    .opteo-mobile-nav-container {
        display: flex !important;
    }

    .mobile-domain-nav-button {
        display: flex;
    }
}
</style>
