<template>
    <div class="container">
        <!-- Header -->
        <div class="header">
            <!-- Left header -->
            <div class="left">
                <slot name="header-left"></slot>
            </div>

            <!-- Right Header -->
            <div class="right">
                <slot name="header-right"></slot>
            </div>
        </div>

        <Spacer height="1.75rem" />

        <!-- Content -->
        <div class="content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Spacer, Text } from '@opteo/components-next'

export default defineComponent({
    name: 'CampaignGroupSection',
    components: {
        Spacer,
        Text,
    },
})
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.container {
    @include container;
    @include pt-28;
    @include ph-40;
    @include pb-40;
    @include br-32;
}

.header {
    @include flex;
    @include items-center;
    @include justify-between;
    height: 2.75rem;
}

.header .left,
.header .right {
    @include flex;
    @include items-center;
}

.content {
    @include container;
    @include br-20;
}
</style>
