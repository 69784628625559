<template>
    <div class="scorecard-sub-section-header">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.scorecard-sub-section-header {
    @include flex;
    @include items-center;
    @include justify-between;
    height: 1.875rem;
    @include mb-24;
}
</style>
