<template>
    <div :class="outerClass" :style="{ ...setBarStyles, ...setBackgroundColor }">
        <div
            :class="innerClass"
            :style="{ ...setBarProgress, ...setBarStyles, ...setBarColor }"
            style="border-radius: 0"
        />
    </div>
</template>

<script lang="ts">
// Props
// • progress
// a value between 0 and 1 that represents bar progress
// • microbar
// a boolean value that changes the bar to a microbar
// • barColor
// pass in a colour value for css to read (hex, rgb, rgba), or one of the opteo colours (see data)
// • backgroundColor
// pass in a colour value for css to read (hex, rgb, rgba), or one of the opteo colours (see data)
// • transitionSpeedSeconds
// time for transition of bar between animations (default 1 second)

export default {
    name: 'ProgressBar',
    props: {
        id: {
            type: String,
            required: false,
        },
        progress: {
            type: Number,
            required: false,
        },
        microBar: {
            type: Boolean,
            required: false,
            default: false,
        },
        barColor: {
            type: String,
            required: false,
            default: null,
        },
        backgroundColor: {
            type: String,
            required: false,
            default: null,
        },
        transitionSpeedSeconds: {
            type: Number,
            required: false,
            default: 1,
        },
    },
    data() {
        return {
            outerClass: 'regular-bar-outer',
            innerClass: 'regular-bar-inner',
            opteoColors: {
                'opteo-gray': 'rgba(255, 255, 255, 0.1)',
                'opteo-red': '#FF2828',
                'client-gold': '#FF9500',
                'client-green': '#00A861',
                'client-blue': '#006DFF',
                'client-purple': '#6E3AFF',
                'client-pink': '#FF365C',
            },
        }
    },
    created() {
        this.outerClass = this.microBar ? 'micro-bar-outer' : 'regular-bar-outer'
        this.innerClass = this.microBar ? 'micro-bar-inner' : 'regular-bar-inner'
    },
    computed: {
        setBarProgress() {
            return {
                transform: `scaleX(${this.progress})`,
                transition: `transform ${this.transitionSpeedSeconds}s cubic-bezier(0.19, 1, 0.22, 1)`,
            }
        },
        setBarStyles() {
            return {
                height: this.microBar ? '3px' : '6px', // microbar will actually be 2px high, but it is clipped in the css below
                'border-radius': this.microBar ? '0px' : '999px',
            }
        },
        setBarColor() {
            if (this.opteoColors.hasOwnProperty(this.barColor)) {
                return { 'background-color': this.opteoColors[this.barColor] }
            } else if (this.barColor) {
                return { 'background-color': this.barColor }
            } else {
                return { 'background-color': '#006dff' }
            }
        },
        setBackgroundColor() {
            if (this.opteoColors.hasOwnProperty(this.backgroundColor)) {
                return { 'background-color': this.opteoColors[this.backgroundColor] }
            } else if (this.backgroundColor) {
                return { 'background-color': this.backgroundColor }
            } else {
                return { 'background-color': this.microBar ? 'transparent' : '#f2f2f6' }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.regular-bar-outer {
    width: 100% !important;
    text-align: left;
    overflow: hidden;
}

.regular-bar-inner {
    width: 100%;
    transform-origin: top left;
    backface-visibility: hidden;
}

.micro-bar-outer {
    width: 100% !important;
    position: absolute !important;
    bottom: -1px !important;
    left: 0 !important;
    text-align: left;
}

.micro-bar-inner {
    width: 100%;
    transform-origin: top left;
    backface-visibility: hidden;
}
</style>
