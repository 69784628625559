<template>
    <LoginContainer :width="380" :title="noTitle ? '' : 'Add Payment Card'" :noMargin="noMargin">
        <template v-slot:info>
            <span>
                All payment data is secured using 256-bit encryption. Opteo does not store payment
                data. Read our
                <a href="https://opteo.com/privacy" rel="noopener" target="_blank">
                    Privacy Policy
                </a>
                for more.
            </span>
        </template>
        <template v-slot:content>
            <oForm @submitted="addPaymentMethod">
                <!-- Payment form loading state -->
                <div v-show="stripeElementsLoading" class="payment-form-loading">
                    <Spinner />
                </div>
                <!-- Payment form -->
                <div v-show="!stripeElementsLoading">
                    <Label>Card Number</Label>
                    <div id="card-number" tabindex="0" />
                    <Spacer height="1.5rem" />
                    <div class="card-data">
                        <div class="expiry-date">
                            <Label>Expiry Date</Label>
                            <div id="card-expiry-date" />
                        </div>
                        <Spacer width="1rem" />
                        <div class="security-code">
                            <Label>Security Code</Label>
                            <div id="card-security-code" />
                        </div>
                    </div>
                </div>
                <!-- Error message -->
                <Spacer v-if="cardEntryError" height="2rem" />
                <Text v-if="cardEntryError" as="p" color="red" class="error-container">
                    <svg
                        aria-hidden="true"
                        height="12"
                        width="12"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        style="flex-shrink: 0"
                    >
                        <path
                            d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0113.634 16H2.365A2.365 2.365 0 01.25 12.577L5.884 1.308a2.365 2.365 0 014.231 0zM8 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z"
                            fill-rule="evenodd"
                            fill="#FF2828"
                        />
                    </svg>
                    <span class="error-message">{{ cardEntryError }}</span>
                </Text>
                <!-- Submit button -->
                <Spacer height="2rem" />
                <oButton
                    size="login"
                    type="submit"
                    :loading="addPaymentMethodLoading"
                    ref="addPaymentMethodButton"
                >
                    {{ title }}
                </oButton>
                <!-- Payment Logos -->
                <Spacer height="2rem" />
                <PaymentLogos />
            </oForm>
        </template>
    </LoginContainer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoginContainer from '@/components/global/LoginContainer.vue'
import PaymentLogos from '@/components/billing/PaymentLogos.vue'
import { oButton, oForm, Spacer, Text, Label, Spinner } from '@opteo/components-next'

import { useCreditCardForm } from '@/composition/billing/useCreditCardForm'

export default defineComponent({
    name: 'CreditCardForm',
    components: { LoginContainer, PaymentLogos, oButton, oForm, Spacer, Text, Label, Spinner },
    props: {
        updatingCard: {
            type: Boolean,
            required: true,
        },
        noMargin: {
            type: Boolean,
            required: false,
            default: false,
        },
        noTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['on-success'],
    setup: (props, { emit }) => {
        return useCreditCardForm({ ...props, emit })
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.payment-form-loading {
    height: 10.25rem;
    width: 100%;
    @include flex;
    @include items-center;
    @include justify-center;
}

#card-number,
#card-expiry-date,
#card-security-code {
    font-family: 'Circular', sans-serif !important;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    text-indent: 0.875rem;
    // overflow: hidden;
    line-height: 1.25rem;
    border-radius: 0.625rem;
    border: none;
    box-shadow: $opteo-shadow;
    font-size: 0.875rem;
    letter-spacing: -0.009375rem;
    color: $opteo-black;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    background-color: transparent;
    cursor: text;
    height: 2.75rem;
}

#card-number.StripeElement--focus,
#card-expiry-date.StripeElement--focus,
#card-security-code.StripeElement--focus {
    box-shadow: $opteo-shadow-focus;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    outline: none;
}

#card-number.StripeElement--invalid,
#card-expiry-date.StripeElement--invalid,
#card-security-code.StripeElement--invalid {
    box-shadow: $opteo-shadow-focus;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    outline: none;
}

// Stripe autofill styles

#card-number.StripeElement--webkit-autofill,
#card-number.StripeElement--webkit-autofill:hover,
#card-expiry-date.StripeElement--webkit-autofill,
#card-expiry-date.StripeElement--webkit-autofill:hover,
#card-security-code.StripeElement--webkit-autofill,
#card-security-code.StripeElement--webkit-autofill:hover {
    box-shadow: 0px 6px 6px -4px rgba(4, 4, 6, 0.02), 0px 4px 4px -2px rgba(4, 4, 6, 0.03),
        0px 2px 2px -1px rgba(4, 4, 6, 0.04), 0px 1px 1px -0.5px rgba(4, 4, 6, 0.04),
        0 0 0 1px rgba(232, 232, 158, 1);
    -webkit-box-shadow: 0px 6px 6px -4px rgba(4, 4, 6, 0.02), 0px 4px 4px -2px rgba(4, 4, 6, 0.03),
        0px 2px 2px -1px rgba(4, 4, 6, 0.04), 0px 1px 1px -0.5px rgba(4, 4, 6, 0.04),
        0 0 0 1px rgba(232, 232, 158, 1);
}

#card-number.StripeElement--webkit-autofill.StripeElement--focus,
#card-number.StripeElement--webkit-autofill.StripeElement--active,
#card-expiry-date.StripeElement--webkit-autofill.StripeElement--focus,
#card-expiry-date.StripeElement--webkit-autofill.StripeElement--active,
#card-security-code.StripeElement--webkit-autofill.StripeElement--focus,
#card-security-code.StripeElement--webkit-autofill.StripeElement--active {
    box-shadow: 0px 6px 6px -4px rgba(4, 4, 6, 0.02), 0px 4px 4px -2px rgba(4, 4, 6, 0.03),
        0px 2px 2px -1px rgba(4, 4, 6, 0.04), 0px 1px 1px -0.5px rgba(4, 4, 6, 0.04),
        0 0 0 1px rgba(232, 232, 158, 1), $opteo-shadow-focus-ring;
    -webkit-box-shadow: 0px 6px 6px -4px rgba(4, 4, 6, 0.02), 0px 4px 4px -2px rgba(4, 4, 6, 0.03),
        0px 2px 2px -1px rgba(4, 4, 6, 0.04), 0px 1px 1px -0.5px rgba(4, 4, 6, 0.04),
        0 0 0 1px rgba(232, 232, 158, 1), $opteo-shadow-focus-ring;
}

.card-data {
    @include flex;
    @include items-center;
}

.error-container {
    display: inline-flex !important;
    @include items-center;
    width: 100%;
    @include container;
    @include pv-16;
    @include ph-24;
}
.error-message {
    @include f-9;
    @include ml-8;
    @include lh-140;
}

.expiry-date,
.security-code {
    width: 100%;
}
</style>
