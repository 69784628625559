<template>
    <div class="account-centre-top-nav-bar">
        <AccountCenterSearchBar />
        <div class="account-centre-top-nav-bar-buttons">
            <transition name="fade">
                <oButton
                    v-if="betaFeatures?.microsoftBetaOnboarding && !betaFeatures?.microsoftGamma"
                    :iconBefore="true"
                    size="medium"
                    color="white"
                    @clicked="toggleBetaModal()"
                >
                    <template v-slot:icon>
                        <!-- Spinner for 'connecting' state -->
                        <Spinner v-if="microsoftConnectionStatus === 'connecting'" />
                        <!-- Microsoft icon (small) -->
                        <svg
                            v-else
                            width="13"
                            height="13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M6 0H0v6h6V0Z" fill="#F25022" />
                            <path d="M13 0H7v6h6V0Z" fill="#7FBA00" />
                            <path d="M6 7H0v6h6V7Z" fill="#00A4EF" />
                            <path d="M13 7H7v6h6V7Z" fill="#FFB900" />
                        </svg>
                    </template>
                    Microsoft Ads Beta
                </oButton>
            </transition>
            <Spacer width="0.625rem" />
            <oButton size="medium" color="blue" @clicked="goToLinkedAccounts">
                Linked Accounts
            </oButton>
        </div>
    </div>
    <MicrosoftAdsBeta />
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

import AccountCenterSearchBar from '@/components/AccountCenter/AccountCenterSearchBar.vue'
import { oButton, Spacer, Spinner } from '@opteo/components-next'
import MicrosoftAdsBeta from '@/components/AccountCenter/MicrosoftAdsBeta.vue'
import { useMicrosoftAdsBeta } from '@/composition/misc/useMicrosoftAdsBeta'
import { useUser } from '@/composition/user/useUser'

export default {
    name: 'AccountCenterTopNav',
    components: {
        AccountCenterSearchBar,
        oButton,
        Spacer,
        Spinner,
        MicrosoftAdsBeta,
    },
    setup() {
        const { betaFeatures } = useUser()
        const router = useRouter()
        const { microsoftBetaModal, microsoftConnectionStatus, toggleBetaModal } =
            useMicrosoftAdsBeta()

        const goToLinkedAccounts = () => router.push({ name: Routes.LinkedAccounts })

        return {
            betaFeatures,
            microsoftBetaModal,
            microsoftConnectionStatus,
            goToLinkedAccounts,
            toggleBetaModal,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.account-centre-top-nav-bar {
    @include flex;
    @include items-center;
    @include justify-between;
    @include h-100;
    @include w-100;
}
.account-centre-top-nav-bar-buttons {
    display: none;
}
.add-new-account {
    display: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.24s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@media (min-width: $mq-768-min) {
    .account-centre-top-nav-bar-buttons {
        @include flex;
    }
}

@media (min-width: $mq-1024-max) {
    .add-new-account {
        @include flex;
        @include items-center;
    }
}
</style>
