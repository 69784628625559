<template>
    <div>
        <Text as="h4" weight="600">Sender Profile</Text>
        <Spacer height="1.75rem" />
        <div class="sender-profile-container">
            <Label>Sender Logo</Label>
            <ImageUploader
                :url="sender.logo"
                location="group"
                @uploaded="refreshSenderLogo"
                @afterRemove="deleteSenderLogo"
                :has-description="true"
            >
                <img
                    v-if="sender.logo"
                    style="max-width: 180px; max-height: 72px; padding: 0.75rem 0rem"
                    :src="sender.logo"
                />
                <div v-else class="uploader-empty-state">
                    <Text align="center" size="f-9" color="gray">No Image Uploaded</Text>
                </div>
            </ImageUploader>
            <Spacer />
            <div v-for="field in senderAddressFields" :key="field.key">
                <oInput v-model="sender[field.key]" :label="field.label" @blur="syncFooterFields" />
                <Spacer height="1.5rem" />
            </div>

            <oInput
                type="select"
                :items="teamMembers"
                v-model="selectedUserId"
                label="Sender Account"
            />
            <Spacer height="1.5rem" />

            <oInput v-model="sender.email" label="Adjust Sender Email" @blur="syncFooterFields" />
            <Spacer height="1.5rem" />

            <oInput v-model="sender.name" label="Adjust Sender Name" @blur="syncFooterFields" />
            <Spacer height="1.5rem" />

            <!-- Footer Preview -->
            <Label>Footer Preview</Label>
            <div class="footer-preview-container">
                <SlideFooter
                    :is-footer-preview="true"
                    theme="colourful"
                    :sender="footerPreviewSender"
                />
                <!-- <div class="flex items-start">
                    <img
                        v-if="footerPreviewSender.logo"
                        :src="footerPreviewSender.logo"
                        style="max-width: 128px; max-height: 48px; margin-right: 32px"
                    />
                    <div>
                        <Text v-if="footerPreviewSender.address_line1" size="f-10">
                            {{ footerPreviewSender.address_line1 }},
                        </Text>
                        <Text v-if="footerPreviewSender.address_line2" size="f-10">
                            {{ footerPreviewSender.address_line2 }},
                        </Text>
                        <Text size="f-10">
                            <span v-if="footerPreviewSender.city">{{
                                footerPreviewSender.city
                            }}</span
                            ><span v-if="footerPreviewSender.city && footerPreviewSender.post_code"
                                >,
                            </span>
                            <span v-if="footerPreviewSender.post_code">{{
                                footerPreviewSender.post_code
                            }}</span
                            ><span v-if="footerPreviewSender.city || footerPreviewSender.post_code"
                                >.</span
                            >
                        </Text>
                    </div>
                </div>
                <div class="flex items-center">
                    <ProfileImage :url="footerPreviewSender?.avatar_filename" :userId="1" />

                    <div class="ml3">
                        <Text size="f-10" weight="500">{{ footerPreviewSender.name }}</Text>
                        <Text size="f-10">
                            {{ footerPreviewSender.email }}
                        </Text>
                        <Text size="f-10">{{ currentDate }}</Text>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
type senderFieldKey = keyof Reports.Core.NewReportInfo['sender']

import { computed, ref, onMounted, watch } from 'vue'

import orderBy from 'lodash-es/orderBy'
import format from 'date-fns/format'
import { Reports } from '@opteo/types'
import {
    Modal,
    Text,
    Spacer,
    oInput,
    Label,
    SlideFooter,
    ProfileImage,
} from '@opteo/components-next'

import ImageUploader from '@/components/global/ImageUploader.vue'
import { useCreateReport } from '@/composition/reports/useCreateReport'
import { useUser } from '@/composition/user/useUser'

export default {
    components: {
        Modal,
        Text,
        Spacer,
        oInput,
        ImageUploader,
        ProfileImage,
        Label,
        SlideFooter,
    },
    setup() {
        const { userId } = useUser()
        const { newReportInfo, updateSenderFields, team } = useCreateReport()
        const sender = computed(() => newReportInfo.value?.sender)

        /*
            Logo upload handling.
            Note that a default logo may already exist from a previous report.
        */
        const deleteSenderLogo = () => {
            updateSenderFields({ logo: '' })
            syncFooterFields()
        }

        const refreshSenderLogo = (newLogoUrl: string) => {
            updateSenderFields({ logo: newLogoUrl })
            syncFooterFields()
        }

        /* 
            Address fields (to avoid repeating <oInput> 5 times)
        */
        const senderAddressFields: {
            label: string
            key: senderFieldKey
        }[] = [
            { key: 'address_line1', label: 'Address Line 1' },
            { key: 'address_line2', label: 'Address Line 2' },
            { key: 'city', label: 'City' },
            { key: 'post_code', label: 'Postal Code' },
            { key: 'telephone', label: 'Telephone' },
        ]

        /* 
            Sender dropdown, email, name
        */
        const selectedUserId = ref<number | undefined>()

        const teamMembers = computed(() => {
            if (typeof team.value === 'undefined') {
                return []
            }
            return orderBy(team.value, 'name').map(user => {
                return {
                    value: user.user_id,
                    label: `${user.name} (${user.email})`,
                }
            })
        })

        watch(selectedUserId, newVal => {
            // When the selectedUserId changes, set new values for the name/email/avatar_filename fields
            const selectedUser = team.value?.find(u => u.user_id === newVal)
            if (!selectedUser) {
                throw new Error('could not find selected user')
            }

            updateSenderFields({
                user_id: newVal,
                email: selectedUser.email,
                name: selectedUser.name,
                avatar_filename: selectedUser.avatar_filename,
                avatar_url: selectedUser.avatar_filename,
            })

            syncFooterFields()
        })

        onMounted(() => {
            if (!sender.value || !userId.value) {
                throw new Error('sender.value must be set before initing this component')
            }
            /*
                If the email is pre-set from the backend:
                    - set the dropdown to that email
                Else:
                    - set the dropdown to be the current logged-in user
            */
            const user = team.value?.find(u => u.email === sender.value?.email)
            selectedUserId.value = user?.user_id ?? userId.value

            syncFooterFields()
        })

        /* 
            Footer handling.
            Note that we don't want the footer fields to update as we type, 
            so we only update the footer on blur or after updating the sender.
        */
        const currentDate = format(new Date(), 'MMMM do yyyy')
        const footerPreviewSender = ref<Reports.Core.NewReportInfo['sender'] | {}>({}) // copy of `sender`
        const syncFooterFields = () => {
            if (!sender.value) {
                throw new Error('sender.value must be set before initing this component')
            }
            footerPreviewSender.value = {
                ...sender.value,
            }
        }

        return {
            sender,
            deleteSenderLogo,
            refreshSenderLogo,
            senderAddressFields,
            updateSenderFields,
            currentDate,
            teamMembers,
            selectedUserId,
            syncFooterFields,
            footerPreviewSender,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.sender-profile-container {
    @include container;
    @include pa-32;
}

.footer-preview-container {
    @include container;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.uploader-empty-state {
    @include pt-24;
    @include pb-28;
}
</style>
