<template>
    <div :class="[nameContainer, { wide: depth > 4 }, 'resync-table-container']">
        <div ref="tableHeader" class="resync-table-header">
            <Text as="p" size="f-7" weight="500" :color="titleColor">{{ title }} </Text>
        </div>
        <div v-if="hovered" class="attribute-label-cnt">
            <ChartTooltip
                :title="hoveredProductGroup?.product_group_attribute"
                :metrics="tooltipMetrics"
            />
        </div>
        <slot />
    </div>
</template>

<script lang="ts" setup>
import { CheckProductGroups } from '@opteo/types'
import { ref, computed } from 'vue'

// Components
import { Text, ChartTooltip, useNumber } from '@opteo/components-next'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'

const props = defineProps<{
    current: boolean
    depth: number
    hovered?: boolean
    hoveredProductGroup?: CheckProductGroups.ProductGroup
    labelDistance?: number
}>()

const tableHeader = ref<HTMLDivElement>()

const totalDistance = computed(
    () => `${(props.labelDistance ?? 0) - (tableHeader.value?.getBoundingClientRect().top ?? 0)}px`
)

const nameContainer = computed(() =>
    props.current ? 'current-product-groups-container' : 'resync-product-groups-container'
)

const titleColor = computed(() => (props.current ? 'red' : 'green'))

const title = props.current ? 'Current Product Groups' : 'Updated Product Groups'

const tooltipMetrics = computed(() => {
    return [
        {
            label: 'Cost',
            value: useDomainMoney({ value: props.hoveredProductGroup?.cost ?? 0 }).value
                .displayValue.value,
        },
        {
            label: 'Clicks',
            value: useNumber({ value: props.hoveredProductGroup?.clicks ?? 0 }).displayValue.value,
        },
        {
            label: 'Impressions',
            value: useNumber({ value: props.hoveredProductGroup?.impressions ?? 0 }).displayValue
                .value,
        },
    ]
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.resync-table-container {
    @include relative;
    @include container;
    @include w-100;
}
.resync-table-header {
    @include pl-24;
    @include pv-20;
}

.current-product-groups-container,
.resync-product-groups-container {
    max-width: 400px;
    @include ml-24;
}
.current-product-groups-container.wide,
.resync-product-groups-container.wide {
    max-width: 425px;
}

.current-product-groups-container {
    @include ma-0;
}

.resync-product-groups-container {
    @include ma-0;
    @include mt-64;
}

.attribute-label-cnt {
    @include container;
    @include opteo-background;
    @include absolute;
    @include grid;
    @include z-max;
    top: v-bind(totalDistance);
    max-width: 200px;
    pointer-events: none;
    transition: all 0.5s ease;
}

.attribute-label {
    @include pb-4;
}

.group-metric {
    pointer-events: none;
    color: black;
    padding: 0.375rem 0;
}
.group-metric:last-child {
    padding-top: 0.375rem !important;
    padding-bottom: 0 !important;
}

.current-product-groups-container .attribute-label-cnt {
    left: 0.5rem;
    transform: translate(-100%, 0%);
}

.resync-product-groups-container .attribute-label-cnt {
    right: 0.5rem;
    transform: translate(100%, 0%);
}

@media screen and (min-width: 820px) {
    .current-product-groups-container {
        margin-right: 1rem;
    }
    .resync-product-groups-container {
        margin-left: 1rem;
        margin-top: 0rem;
    }
}

@media screen and (min-width: 1050px) {
    .current-product-groups-container {
        margin-right: 1.5rem;
    }
    .resync-product-groups-container {
        margin-left: 1.5rem;
    }
}

@media screen and (min-width: 1441px) {
    .current-product-groups-container .attribute-label-cnt {
        left: -1rem;
    }

    .resync-product-groups-container .attribute-label-cnt {
        right: -1rem;
    }
}
</style>
