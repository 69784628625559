<template>
    <HelpLink @clicked="clicked">
        <slot />
    </HelpLink>
</template>
<script lang="ts">
import { HelpLink } from '@opteo/components-next'
import { PropType } from 'vue'

import { WEBSITE_DOCS_URL } from '@/lib/constants'

export default {
    name: 'ImprovementHelpLink',
    props: {
        path: {
            type: String as PropType<string>,
            required: true,
        },
        base: {
            type: String as PropType<string>,
            required: false,
        },
    },
    setup(props) {
        const clicked = () => {
            window.open(`${WEBSITE_DOCS_URL}${props.path}`)
        }

        return {
            clicked,
        }
    },
    components: {
        HelpLink,
    },
}
</script>
