import { authRequest, Endpoint } from '../api/useAPI'

import { delay } from '@opteo/promise'
import { useDismissedImprovements } from './useDismissedImprovements'
import { MOCK_IMP_DISMISSALS } from '@/lib/env'

import type { DismissDuration } from './types'
import { useAccount } from '../account/useAccount'

export function useDismissImprovement() {
    const { accountId, mutateAccount, accountInfo } = useAccount()

    const { refreshDismissedList } = useDismissedImprovements()

    const dismissImprovement = async (improvementId: number, length: DismissDuration) => {
        mutateAccount({
            tasksAvailable: (accountInfo.value?.tasksAvailable ?? 0) - 1,
            tasksDismissed: (accountInfo.value?.tasksDismissed ?? 0) + 1,
        })

        if (MOCK_IMP_DISMISSALS) {
            console.log('VITE_MOCK_IMP_DISMISSALS=1, so skipping dismissal.')
            await delay(200)

            return
        }

        await authRequest(Endpoint.DismissImprovement, {
            body: {
                improvement_id: improvementId,
                length,
                account_id: accountId.value,
            },
        })
    }

    const revertDismissImprovement = async (improvement_id: number) => {
        mutateAccount({
            tasksDismissed: (accountInfo.value?.tasksDismissed ?? 0) - 1,
        })

        if (MOCK_IMP_DISMISSALS) {
            console.log('VITE_MOCK_IMP_DISMISSALS=1, so skipping revert dismissal.')
            await delay(200)

            return
        }

        await authRequest(Endpoint.RevertDismissal, {
            body: {
                improvement_id,
            },
        })

        refreshDismissedList()
    }

    return {
        dismissImprovement,
        revertDismissImprovement,
    }
}
