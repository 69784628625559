<template>
    <VisibilityWrapper @visibility-change="onVisibilityChange">
        <div
            :style="{
                width: typeof width === 'number' ? width + 'px' : width,
                height: typeof height === 'number' ? height + 'px' : height,
            }"
            class="skeleton"
            :class="{ visible: isVisible, blue: color === 'blue' }"
        >
            <slot></slot>
        </div>
    </VisibilityWrapper>
</template>

<script lang="ts">
// TODO: move this code into components-next, and remove this file
import { defineComponent, ref, computed } from 'vue'
import VisibilityWrapper from '@/components/util/VisibilityWrapper.vue'

export default defineComponent({
    name: 'Skeleton',
    components: {
        VisibilityWrapper,
    },
    props: {
        width: {
            type: [Number, String],
            default: 128,
            required: false,
        },
        height: {
            type: [Number, String],
            default: 16,
            required: false,
        },
        color: {
            type: String,
            default: '#ececef',
            required: false,
        },
        shimmerColor: {
            type: String,
            default: '#f4f4f6',
            required: false,
        },
        shimmer: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    setup(props) {
        const isVisible = ref(true)
        const onVisibilityChange = (_isVisible: boolean) => (isVisible.value = _isVisible)

        const background = computed(() => {
            return props.shimmer
                ? `linear-gradient(to right, ${props.color} 4%, ${props.shimmerColor} 25%, ${props.color} 36%)`
                : props.color
        })

        return { onVisibilityChange, isVisible, background }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.skeleton {
    @include br-999;
    @include relative;
    background: v-bind(background);
    animation: shimmer-animation 0s infinite linear;
    -webkit-animation: shimmer-animation 0s infinite linear;
    -moz-animation: shimmer-animation 0s infinite linear;
    background-size: 1000px 100%;
    -webkit-background-size: 1000px 100%;
    -moz-background-size: 1000px 100%;
    max-width: 100%;
}

.skeleton.blue {
    @include br-999;
    @include relative;
    background: linear-gradient(
        to right,
        hsla(227, 100%, 46%, 0.06) 4%,
        hsla(227, 100%, 75%, 0.06) 25%,
        hsla(227, 100%, 46%, 0.06) 36%
    );
    animation: shimmer-animation 0s infinite linear;
    -webkit-animation: shimmer-animation 0s infinite linear;
    -moz-animation: shimmer-animation 0s infinite linear;
    background-size: 1000px 100%;
    -webkit-background-size: 1000px 100%;
    -moz-background-size: 1000px 100%;
    max-width: 100%;
}

.skeleton.visible {
    // Disabling the animation when outside the viewport saves CPU cycles spent animating the shimmer.
    // We use animation-duration to enable/disable the animation (as opposed to removing the animation)
    // because it keeps the animations in sync even when paused/unpaused at arbitrary times.
    animation-duration: 4s;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
}

@keyframes shimmer-animation {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}
</style>
