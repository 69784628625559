<template>
    <div id="improvement-header">
        <h2 class="f2 lh-title mb4">
            <WrapBalancer>
                {{ title }}
            </WrapBalancer>
        </h2>
        <div class="f5 lh-copy" style="margin-bottom: 2.1875rem">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import WrapBalancer from 'vue-wrap-balancer'

export default {
    props: {
        title: {
            type: String,
            default: 'Default Title',
        },
    },
    components: { WrapBalancer },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

@media screen and (min-width: 0px) and (max-width: 320px) {
}
@media screen and (min-width: 321px) and (max-width: 375px) {
}
@media screen and (min-width: 376px) and (max-width: 480px) {
}
@media screen and (min-width: 481px) and (max-width: 767px) {
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
@media screen and (min-width: 1025px) and (max-width: 1279px) {
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
}
@media screen and (min-width: 1441px) and (max-width: 1600px) {
}
@media screen and (min-width: 1601px) and (max-width: 1920px) {
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
}
@media screen and (min-width: 2561px) and (max-width: 9999px) {
}
</style>
