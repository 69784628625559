<template>
    <svg
        style="display: block"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_47_13404)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 18c4.9706 0 9-4.0294 9-9 0-4.97056-4.0294-9-9-9-4.97056 0-9 4.02944-9 9 0 4.9706 4.02944 9 9 9Zm7-9c0 3.866-3.134 7-7 7-3.86599 0-7-3.134-7-7 0-3.86599 3.13401-7 7-7 3.866 0 7 3.13401 7 7Zm-6-4c0-.55228-.44771-1-1-1s-1 .44772-1 1v3.75C8 9.44036 8.55964 10 9.25 10H12c.5523 0 1-.44772 1-1s-.4477-1-1-1h-2V5Z"
                fill="#040406"
            />
        </g>
        <defs>
            <clipPath id="clip0_47_13404"><path fill="#fff" d="M0 0h18v18H0z" /></clipPath>
        </defs>
    </svg>
</template>
