<template>
    <svg
        :class="['top-performing-ad', { animated }]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 452"
        fill="none"
    >
        <rect width="640" height="452" fill="white" />
        <rect x="75" y="388" width="106" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="388" width="70" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="400" width="95" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="400" width="82" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="412" width="66" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="412" width="57" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <circle cx="47" cy="404" r="14" fill="#EBEBEB" />
        <circle cx="499" cy="404" r="14" fill="#EBEBEB" />
        <rect x="33" y="34" width="146" height="24" rx="12" fill="black" fill-opacity="0.08" />
        <g filter="url(#filter0_dd_2_4776)">
            <rect x="34" y="94" width="576" height="250" rx="8" fill="white" />
        </g>
        <mask
            id="mask0_2_4776"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="34"
            y="94"
            width="576"
            height="250"
        >
            <path
                d="M34 102C34 97.5817 37.5817 94 42 94H602C606.418 94 610 97.5817 610 102V336C610 340.418 606.418 344 602 344H42C37.5817 344 34 340.418 34 336V102Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_2_4776)">
            <rect x="33.5" y="93.5" width="577" height="81" fill="white" stroke="#F2F2F3" />
            <rect x="143.94" y="173.067" width="42.9217" height="2.25904" fill="#4285F4" />
            <rect
                x="137.5"
                y="105.5"
                width="439"
                height="33"
                rx="16.5"
                fill="white"
                stroke="#EDEDEE"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M559.373 123.805H560.006L564 127.807L562.807 129L558.805 125.006V124.373L558.589 124.149C557.676 124.934 556.492 125.406 555.203 125.406C552.329 125.406 550 123.076 550 120.203C550 117.329 552.329 115 555.203 115C558.076 115 560.406 117.329 560.406 120.203C560.407 121.446 559.961 122.648 559.149 123.589L559.373 123.805ZM551.601 120.203C551.601 122.196 553.21 123.805 555.203 123.805C557.196 123.805 558.805 122.196 558.805 120.203C558.805 118.209 557.196 116.601 555.203 116.601C553.21 116.601 551.601 118.209 551.601 120.203Z"
                fill="#4285F4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M76.5565 128.333C79.5566 128.333 82.0012 126.023 82.0012 122.888C82.0012 119.731 79.5566 117.443 76.5565 117.443C73.5564 117.443 71.1118 119.731 71.1118 122.888C71.1118 126.023 73.5564 128.333 76.5565 128.333ZM76.5565 119.589C78.2009 119.589 79.6177 120.929 79.6177 122.888C79.6177 124.825 78.2009 126.187 76.5565 126.187C74.9121 126.187 73.4952 124.827 73.4952 122.888C73.4952 120.929 74.9121 119.589 76.5565 119.589Z"
                fill="#EA4335"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M88.2236 128.333C91.2237 128.333 93.6683 126.023 93.6683 122.888C93.6683 119.731 91.2237 117.443 88.2236 117.443C85.2235 117.443 82.7789 119.734 82.7789 122.888C82.7789 126.023 85.2235 128.333 88.2236 128.333ZM88.2236 119.589C89.868 119.589 91.2849 120.929 91.2849 122.888C91.2849 124.825 89.868 126.187 88.2236 126.187C86.5792 126.187 85.1624 124.827 85.1624 122.888C85.1624 120.929 86.5792 119.589 88.2236 119.589Z"
                fill="#FBBC05"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M104.558 127.412V117.77H102.358V118.645H102.277C101.756 118.013 100.757 117.443 99.4995 117.443C96.8622 117.443 94.4461 119.802 94.4461 122.835C94.4461 125.845 96.8622 128.185 99.4995 128.185C100.76 128.185 101.759 127.616 102.277 126.964H102.358V127.737C102.358 129.793 101.281 130.885 99.5423 130.885C98.125 130.885 97.2451 129.853 96.886 128.979L94.8694 129.834C95.4496 131.258 96.9883 133 99.5447 133C102.26 133 104.558 131.379 104.558 127.412ZM99.7064 119.561C101.283 119.561 102.522 120.944 102.522 122.835C102.522 124.708 101.283 126.071 99.7064 126.071C98.1083 126.071 96.7695 124.708 96.7695 122.835C96.7695 120.944 98.1083 119.561 99.7064 119.561Z"
                fill="#4285F4"
            />
            <path d="M108.447 112.778V128.334H106.113V112.778H108.447Z" fill="#34A853" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M119.232 125.92L117.364 124.682C116.741 125.591 115.891 126.19 114.665 126.19C113.441 126.19 112.569 125.632 112.008 124.54L119.336 121.526L119.086 120.907C118.632 119.692 117.24 117.443 114.395 117.443C111.57 117.443 109.225 119.65 109.225 122.888C109.225 125.942 111.55 128.333 114.665 128.333C117.176 128.333 118.629 126.806 119.232 125.92ZM116.511 120.704L111.612 122.724C111.55 120.623 113.253 119.55 114.477 119.55C115.431 119.55 116.242 120.024 116.511 120.704Z"
                fill="#EA4335"
            />
            <path
                d="M62.5017 121.708V119.477H70.2196C70.2951 119.866 70.334 120.326 70.334 120.824C70.334 122.498 69.8643 124.568 68.3504 126.043C66.8778 127.537 64.9964 128.334 62.5041 128.334C57.8845 128.334 54 124.668 54 120.167C54 115.666 57.8845 112 62.5041 112C65.0597 112 66.8803 112.977 68.2481 114.25L66.632 115.825C65.6512 114.929 64.3222 114.231 62.5017 114.231C59.1283 114.231 56.4899 116.88 56.4899 120.167C56.4899 123.454 59.1283 126.103 62.5017 126.103C64.6898 126.103 65.9359 125.246 66.7342 124.469C67.3817 123.838 67.8076 122.937 67.9755 121.706L62.5017 121.708Z"
                fill="#4285F4"
            />
            <rect
                class="metric"
                x="142"
                y="295"
                width="90"
                height="22"
                rx="11"
                fill="#00A861"
                fill-opacity="0.1"
            />
            <rect
                class="metric"
                x="152"
                y="302"
                width="49"
                height="8"
                rx="4"
                fill="#008A43"
                fill-opacity="0.2"
            />
            <rect class="metric" x="205" y="302" width="17" height="8" rx="4" fill="#008A43" />
            <rect
                class="metric"
                x="242"
                y="295"
                width="65"
                height="22"
                rx="11"
                fill="#00A861"
                fill-opacity="0.1"
            />
            <rect
                class="metric"
                x="252"
                y="302"
                width="25"
                height="8"
                rx="4"
                fill="#008A43"
                fill-opacity="0.2"
            />
            <rect class="metric" x="281" y="302" width="16" height="8" rx="4" fill="#008A43" />
            <rect
                class="metric"
                x="317"
                y="295"
                width="84"
                height="22"
                rx="11"
                fill="#00A861"
                fill-opacity="0.1"
            />
            <path
                class="metric"
                d="M327 306C327 303.791 328.791 302 331 302H365C367.209 302 369 303.791 369 306C369 308.209 367.209 310 365 310H331C328.791 310 327 308.209 327 306Z"
                fill="#008A43"
                fill-opacity="0.2"
            />
            <rect class="metric" x="373" y="302" width="18" height="8" rx="4" fill="#008A43" />
            <rect
                class="metric"
                x="411"
                y="295"
                width="99"
                height="22"
                rx="11"
                fill="#00A861"
                fill-opacity="0.1"
            />
            <path
                class="metric"
                d="M421 306C421 303.791 422.791 302 425 302H474C476.209 302 478 303.791 478 306C478 308.209 476.209 310 474 310H425C422.791 310 421 308.209 421 306Z"
                fill="#008A43"
                fill-opacity="0.2"
            />
            <rect class="metric" x="482" y="302" width="18" height="8" rx="4" fill="#008A43" />
        </g>
        <rect x="152" y="154" width="27" height="8" rx="4" fill="#4285F4" />
        <rect x="199" y="154" width="37" height="8" rx="4" fill="#EBEBEB" />
        <rect x="257" y="154" width="45" height="8" rx="4" fill="#EBEBEB" />
        <rect
            class="first-line"
            x="174"
            y="205"
            width="63"
            height="8"
            rx="4"
            fill="black"
            fill-opacity="0.35"
        />
        <rect
            class="search-term"
            x="152"
            y="118"
            width="82"
            height="8"
            rx="4"
            fill="black"
            fill-opacity="0.35"
        />
        <rect
            class="second-line"
            x="152"
            y="224"
            width="292"
            height="14"
            rx="7"
            fill="#1A0DAB"
            fill-opacity="0.2"
        />
        <rect class="second-line" x="152" y="246" width="411" height="10" rx="5" fill="#EEEFF0" />
        <rect class="third-line" x="152" y="263" width="372" height="10" rx="5" fill="#EEEFF0" />
        <rect
            class="first-line"
            x="152"
            y="205"
            width="11"
            height="8"
            rx="4"
            fill="black"
            fill-opacity="0.35"
        />
        <rect x="167" y="208" width="3" height="3" rx="1.5" fill="black" fill-opacity="0.35" />
        <rect x="323" y="154" width="43" height="8" rx="4" fill="#EBEBEB" />
        <rect x="387" y="154" width="37" height="8" rx="4" fill="#EBEBEB" />
        <defs>
            <filter
                id="filter0_dd_2_4776"
                x="31"
                y="92"
                width="582"
                height="256"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1.5" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.054902 0 0 0 0 0.0705882 0 0 0 0 0.121569 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2_4776"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect2_dropShadow_2_4776"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0567206 0 0 0 0 0.0697228 0 0 0 0 0.121732 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_2_4776"
                    result="effect2_dropShadow_2_4776"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_2_4776"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'

export default {
    props: {
        animated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        onMounted(() => {
            if (!props.animated) {
                return
            }

            const timeline = anime.timeline()
            timeline
                .add({
                    targets: '.search-term, .first-line, .second-line, .third-line',
                    opacity: [0, 1],
                    delay: anime.stagger(100, { start: 400 }),
                    duration: 1000,
                    easing: 'easeInCubic',
                })
                .add(
                    {
                        targets: '.metric',
                        opacity: [0, 1],
                        translateY: [6, 0],
                        delay: anime.stagger(100),
                        duration: 500,
                        easing: 'easeOutCubic',
                    },
                    '-=800'
                )
        })
    },
}
</script>

<style lang="scss" scoped>
svg {
    width: 100%;
    transform: scale(1);
    transform-origin: center;
}

svg.animated {
    .search-term,
    .first-line,
    .second-line,
    .third-line,
    .metric {
        opacity: 0;
        will-change: opacity;
    }
    .metric {
        transform: translateY(6px);
    }
}
</style>
