import { computed, ref, onBeforeUpdate } from 'vue'

import {
    EntityPill,
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

import { CampaignLevelExtensions, Improvement } from '@opteo/types'
import { delay } from '@/lib/globalUtils'

type FormElement = HTMLDivElement & { submit: () => void; valid: boolean }

interface HighestClickedAd {
    headlineOne: string
    headlineTwo: string
    displayUrl: string
    descriptionOne: string
    callouts: string[]
}

interface AdTitleTag {
    title: string
    classes: string
}

interface CalloutExtension {
    isNew: boolean
    calloutText: string
    feedItemID?: string
}

interface PushedData {
    is_new: boolean
    callout_text: string
    feed_item_id: string | undefined
}

const MINIMUM_CALLOUT_EXTENSIONS = 4
const MAXIMUM_CALLOUT_EXTENSIONS = 20
const MAXIMUM_CALLOUT_LENGTH = 25

export function useAddCampaignLevelCalloutExtension() {
    const { improvement, lastUpdated, title } =
        useImprovement<CampaignLevelExtensions.Body<CampaignLevelExtensions.CalloutItem>>()

    const {
        body: {
            campaign_name: campaignName,
            campaign_feed_items: campaignFeedItems,
            feed_items: generalFeedItems,
            highest_click_ad: {
                headline1: headlineOne,
                headline2: headlineTwo,
                display_url: displayUrl,
                description: descriptionOne,
            },
        },
    } =
        checkImprovement<CampaignLevelExtensions.Body<CampaignLevelExtensions.CalloutItem>>(
            improvement
        )

    // campaign mode or account mode, ie whether the prefilled items come from campaign-level or account-level
    const campaignMode = campaignFeedItems.length > 0
    const totalMissingFeedItems = MINIMUM_CALLOUT_EXTENSIONS - campaignFeedItems.length

    const calloutExtensions = ref<CalloutExtension[]>([])
    const inputRefs = ref<{ inputRef: HTMLInputElement }[]>([])

    const feedItems = campaignMode ? campaignFeedItems : generalFeedItems

    async function resetCalloutExtensions(clear: boolean) {
        calloutExtensions.value = []

        feedItems.forEach(feedItem => {
            const {
                fields: { feed_item_id: feedItemID, callout_text: calloutText },
            } = feedItem

            calloutExtensions.value.push({
                isNew: !campaignMode,
                calloutText: clear ? '' : calloutText,
                feedItemID,
            })
        })
        while (calloutExtensions.value.length < MINIMUM_CALLOUT_EXTENSIONS) {
            calloutExtensions.value.push({ isNew: true, calloutText: '' })
        }

        await delay(1)
        if (inputRefs.value[0]) {
            inputRefs.value[0].inputRef.focus()
        }
    }

    async function addCalloutExtensions() {
        if (calloutExtensions.value.length >= MAXIMUM_CALLOUT_EXTENSIONS) {
            return
        }
        calloutExtensions.value = [...calloutExtensions.value, { isNew: true, calloutText: '' }]
        await delay(1)

        inputRefs.value[calloutExtensions.value.length - 1].inputRef.focus()
    }

    resetCalloutExtensions(false)

    // make sure to reset the refs before each update
    // to prevent duplicates
    onBeforeUpdate(() => {
        inputRefs.value = []
    })

    const numbers = ['One', 'Two', 'Three', 'Four']

    const highestClickedAd = computed<HighestClickedAd>(() => {
        const ad = { headlineOne, headlineTwo, displayUrl, descriptionOne }

        if (!calloutExtensions.value || !calloutExtensions.value.length) {
            return ad as HighestClickedAd
        }

        const callouts = calloutExtensions.value.map(extension => extension.calloutText)

        return { ...ad, callouts }
    })

    const adTitleTag: AdTitleTag = { title: 'Highest Clicked Ad', classes: 'opteo-blue' }
    const calloutForm = ref<FormElement>()

    const entityPillList: EntityPill[] = [
        { type: Improvement.LocationEntity.Campaign, content: campaignName },
    ]

    const adjustSteps = ref([
        campaignMode ? 'Input Callout Extensions' : 'Customise Callout Extensions',
    ])

    const lookbackWindow = 30

    const pushActionText = ref('Add Callout Extensions')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding Callout Extensions..',
        'Confirming changes..',
        'Callout Extensions added successfully',
    ])

    const onPush: OnPushHandler<PushedData[]> = () => {
        const valid = !!calloutForm.value?.submit()

        if (!valid) {
            return { valid }
        }

        return {
            valid,
            pushedData: calloutExtensions.value
                .filter(calloutExtension => calloutExtension.calloutText.length) // Only bother sending the ones with text
                .map(calloutExtension => {
                    return {
                        is_new: calloutExtension.isNew,
                        callout_text: calloutExtension.calloutText,
                        feed_item_id: calloutExtension.feedItemID,
                    }
                }),
        }
    }

    return {
        title,
        entityPillList,
        campaignName,
        campaignMode,
        totalMissingFeedItems,
        highestClickedAd,
        adTitleTag,
        inputRefs,
        calloutForm,
        calloutExtensions,
        resetCalloutExtensions,
        addCalloutExtensions,
        MINIMUM_CALLOUT_EXTENSIONS,
        MAXIMUM_CALLOUT_EXTENSIONS,
        MAXIMUM_CALLOUT_LENGTH,
        numbers,
        lookbackWindow,
        pushMessages,
        onPush,
        lastUpdated,
        pushActionText,
        adjustSteps,
    }
}
