<template>
    <!-- ACTIONS BUTTON & TIPPY -->
    <div class="relative">
        <Popout
            v-model="actionsPopout"
            :trapFocus="true"
            :externalToggleButton="true"
            :offset="[1, 8]"
            placement="bottom-end"
        >
            <!-- prevent event propagation so router-link is not triggered -->
            <oButton color="white" @click.native.prevent @clicked="actionsPopout = !actionsPopout">
                Actions
                <template #icon>
                    <SelectArrowIcon hex="#006dff" />
                </template>
            </oButton>

            <template #content>
                <div class="report-actions-popout-container">
                    <div class="popout-row">
                        <oButton size="medium" type="phantom" @clicked="downloadReport">
                            <span class="popout-span">Download</span>
                            <template #icon>
                                <div class="reports-popout-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M3.707 6.293a1 1 0 0 0-1.414 1.414l1.414-1.414ZM8 12l-.707.707a1 1 0 0 0 1.414 0L8 12Zm5.707-4.293a1 1 0 0 0-1.414-1.414l1.414 1.414Zm-11.414 0 5 5 1.414-1.414-5-5-1.414 1.414Zm6.414 5 5-5-1.414-1.414-5 5 1.414 1.414Z"
                                            fill="#006DFF"
                                        />
                                        <path
                                            d="M8 1v11M15 15H1"
                                            stroke="#006DFF"
                                            stroke-width="2"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </template>
                        </oButton>
                    </div>
                    <div v-if="!isArchive" class="popout-row">
                        <oButton size="medium" type="phantom" @clicked="duplicate">
                            <span class="popout-span">Duplicate</span>
                            <template #icon>
                                <div class="reports-popout-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="18"
                                        viewBox="0 0 16 18"
                                        fill="none"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M9 0H3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h1v1a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3h-1V3a3 3 0 0 0-3-3Zm1 6V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V6Zm-4 8h3a3 3 0 0 0 3-3V6h1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-1Z"
                                            fill="#006DFF"
                                        />
                                    </svg>
                                </div>
                            </template>
                        </oButton>
                    </div>
                    <div v-else class="popout-row">
                        <oButton
                            size="medium"
                            type="phantom"
                            @clicked="restoreReport(report.report_id)"
                        >
                            <span class="popout-span">Restore</span>
                            <template #icon>
                                <div class="reports-popout-icon">
                                    <RestoreIcon />
                                </div>
                            </template>
                        </oButton>
                    </div>
                    <div v-if="!isArchive" class="popout-row">
                        <oButton size="medium" type="phantom" @clicked="archiveReport(report)">
                            <span class="popout-span">Archive</span>
                            <template #icon>
                                <div class="reports-popout-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="15"
                                        viewBox="0 0 17 15"
                                        fill="none"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M3 2h11a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3Zm0 2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H3Z"
                                            fill="#006DFF"
                                        />
                                        <path
                                            d="M0 2a2 2 0 0 1 2-2h4a2 2 0 1 1 0 4H3a1 1 0 0 0-1 1 1 1 0 0 1-1 1H0V2Z"
                                            fill="#006DFF"
                                        />
                                    </svg>
                                </div>
                            </template>
                        </oButton>
                    </div>
                    <div v-else class="popout-row">
                        <oButton size="medium" type="phantom" @clicked="confirmDelete">
                            <span class="popout-span">Delete</span>
                            <template #icon>
                                <div class="reports-popout-icon">
                                    <BinIcon />
                                </div>
                            </template>
                        </oButton>
                    </div>
                </div>
            </template>
        </Popout>
    </div>
    <Modal v-model="confirmModalShown" title="Delete Report">
        <template #content>
            <Text as="p"> Are you sure you want to delete this report? </Text>
        </template>
        <template #buttons>
            <oButton @clicked="confirmModalShown = false" size="medium" color="white">
                Cancel
            </oButton>
            <Spacer width="0.75rem" height="0.25rem" />
            <oButton @clicked="executeDelete(report.report_id)" size="medium">
                Delete Report
            </oButton>
        </template>
    </Modal>
</template>

<script lang="ts">
import { computed, ref, PropType, defineComponent } from 'vue'
import { Reports } from '@opteo/types'

import {
    oButton,
    Text,
    CloseButton,
    Spacer,
    Modal,
    Popout,
    DownloadIcon,
    DuplicateIcon,
    ArchiveIcon,
    RestoreIcon,
    BinIcon,
    SelectArrowIcon,
    showSnackbar,
} from '@opteo/components-next'
import { useDomain } from '@/composition/domain/useDomain'
import { useReportsList } from '@/composition/reports/useReportsList'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

export default defineComponent({
    name: 'ReportItemActions',
    components: {
        oButton,
        Text,
        CloseButton,
        Spacer,
        Modal,
        Popout,
        DownloadIcon,
        DuplicateIcon,
        ArchiveIcon,
        RestoreIcon,
        BinIcon,
        SelectArrowIcon,
    },
    props: {
        report: {
            type: Object as PropType<Reports.Core.ReportsListItem>,
            required: true,
        },
    },
    setup: props => {
        const router = useRouter()
        const { domainInfo } = useDomain()
        const {
            deleteReport,
            archiveReport: archive,
            duplicateReport,
            restoreReport,
        } = useReportsList()
        const isArchive = computed(() => !!props.report.archived)
        const color = computed(() => domainInfo.value?.color_preference)

        const actionsPopout = ref(false)

        const confirmModalShown = ref(false)

        const downloadReport = () => {
            router.push({
                name: isArchive.value ? Routes.ReportDownloadArchive : Routes.ReportDownload,
                params: { reportId: props.report.report_id },
            })
        }

        const archiveReport = (report: Reports.Core.ReportsListItem) => {
            archive(report.report_id)
            showSnackbar({
                message: `${report.title} archived successfully`,
                timeout: 3000,
            })
        }

        const confirmDelete = () => {
            actionsPopout.value = false
            confirmModalShown.value = true
        }

        const executeDelete = (reportId: number) => {
            confirmModalShown.value = false
            deleteReport(reportId)
        }

        const duplicate = () => {
            actionsPopout.value = false
            duplicateReport(props.report.report_id)
        }

        return {
            isArchive,
            downloadReport,
            color,
            actionsPopout,
            confirmDelete,
            archiveReport,
            duplicate,
            restoreReport,
            confirmModalShown,
            executeDelete,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.report-actions-popout-container {
    @include pa-8;
}

.report-actions-popout-container .popout-row {
    @include mb-4;
}

.report-actions-popout-container .popout-row:last-child {
    @include mb-0;
}

.report-actions-popout-container .popout-span {
    width: 4.75rem;
    @include block;
}

.report-actions-popout-container .reports-popout-icon {
    width: 1.25rem;
    @include tc;
}
</style>
