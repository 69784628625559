<template>
    <svg viewBox="0 0 48 48" width="24" height="24">
        <defs>
            <linearGradient
                id="b"
                x1="6.87"
                y1="33.2"
                x2="27.13"
                y2="20.05"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stop-opacity="0" />
                <stop offset="1" />
            </linearGradient>
        </defs>
        <path
            d="M46 43H12a2.06 2.06 0 0 1-2-1.81 17 17 0 0 0-1.24-4.71 17.23 17.23 0 0 0-2.36-3.94 2 2 0 0 1 .1-2.69l15.71-15.72 8.51-8.55a2 2 0 0 1 2.92.08 55.09 55.09 0 0 1 10.12 16A55.57 55.57 0 0 1 48 40.89 2 2 0 0 1 46 43Z"
            :fill="color"
            opacity=".5"
        />
        <path
            d="m29.05 27.88-18.5 7.86A2.07 2.07 0 0 1 8 34.83a16.32 16.32 0 0 0-2.78-3.65 15.94 15.94 0 0 0-4.11-3 2.06 2.06 0 0 1-1-2.5l7.24-18.9A2 2 0 0 1 10 5.67a40 40 0 0 1 12.17 8.46 40.42 40.42 0 0 1 7.86 11 2 2 0 0 1-.98 2.75Z"
            :fill="color"
        />
        <path
            d="m29 27.9-18.43 7.83A2 2 0 0 1 8 34.86l-.08-.13a5.08 5.08 0 0 0-.35-.59c-.1-.17-.21-.34-.32-.5a6.28 6.28 0 0 0-.38-.52 3.33 3.33 0 0 0-.24-.32l-.2-.27a2 2 0 0 1 .14-2.72l15.64-15.69a40.52 40.52 0 0 1 7.86 11A2 2 0 0 1 29 27.9Z"
            fill="url(#b)"
            opacity=".2"
        />
    </svg>
</template>

<script>
export default {
    name: 'Performance',
    props: {
        color: {
            type: String,
            default: '#E0E0E5',
            required: false,
        },
    },
}
</script>
