import { computed, ref, Ref } from 'vue'

import { NgramTool } from '@opteo/types'
import { useRouter } from 'vue-router'

const negativeListModalOpen = ref(false)

export function useNGramNegativeLists() {
    return {
        negativeListModalOpen,
    }
}
