<template>
    <!-- 
        This file does not use ScorecardSection.vue so that it can 
        have overview-specific skeletons.
    -->
    <div :class="['section-container', { 'not-pdf-render': !isPdf }]">
        <div>
            <ScorecardDonut
                :class="['sticky', { 'is-pdf-render': isPdf }]"
                :hidden="isLoading"
                :score="isLoading ? 0 : score"
                :name="'Overall Score'"
                :strokeWidth="12"
            />
        </div>
        <div class="section-button" :class="{ 'is-pdf-render': isPdf }">
            <oButton
                color="white"
                size="medium"
                :icon-before="aboveMobile ? true : false"
                :circle="aboveMobile ? false : true"
                @clicked="buttonClicked"
            >
                <template #icon>
                    <!-- Clock Icon -->
                    <svg
                        style="display: block"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM10 5C10 4.44772 9.55229 4 9 4C8.44771 4 8 4.44772 8 5V8.75C8 9.44036 8.55964 10 9.25 10H12C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8H10V5Z"
                            fill="black"
                        />
                    </svg>
                </template>
                <span v-if="aboveMobile">Score History</span>
            </oButton>
        </div>
        <div class="section-content">
            <Text as="h5" weight="600">Overall Score</Text>
            <Spacer height="1.5rem" />
            <div v-if="!isLoading">
                <div v-for="copy in sectionCopy" :key="copy">
                    <Text as="p" size="f-7">{{ copy }}</Text>
                    <Spacer height="1rem" />
                </div>
            </div>
            <div class="skeletons" v-else>
                <!-- Paragraph One -->
                <Spacer height="0.25rem" />
                <Skeleton width="96%" :height="13" style="margin: 0.125rem 0" />
                <Spacer height="0.4375rem" />
                <Skeleton width="92%" :height="13" style="margin: 0.125rem 0" />
                <Spacer height="0.4375rem" />
                <Skeleton width="88%" :height="13" style="margin: 0.125rem 0" />
                <Spacer height="0.4375rem" />
                <Skeleton width="72%" :height="13" style="margin: 0.125rem 0" />
                <Spacer height="1.4375rem" />
                <!-- Paragraph Two -->
                <Skeleton width="92%" :height="13" style="margin: 0.125rem 0" />
                <Spacer height="0.4375rem" />
                <Skeleton width="84%" :height="13" style="margin: 0.125rem 0" />
                <Spacer height="0.4375rem" />
                <Skeleton width="88%" :height="13" style="margin: 0.125rem 0" />
                <!-- Best / Worst Segments -->
                <Spacer height="2.5rem" />
                <Skeleton width="42%" :height="18" style="margin: 0.125rem 0" />
                <Spacer height="2rem" />
                <div class="best-worst-areas-container" style="height: 13.875rem" />
                <Spacer height="2.5rem" />
                <Skeleton width="42%" :height="18" style="margin: 0.125rem 0" />
                <Spacer height="2rem" />
                <div class="best-worst-areas-container" style="height: 13.875rem" />
                <!-- Key Statistics -->
                <Spacer height="2.5rem" />
                <Skeleton width="42%" :height="18" style="margin: 0.125rem 0" />
                <Spacer height="2rem" />
                <div class="key-statistics-container" style="height: 10.8125rem" />
            </div>
            <Spacer height="1.5rem" />

            <div v-if="!isLoading">
                <!-- Best Performing Ares -->
                <Text as="h6" weight="600">Best Performing Areas</Text>
                <Spacer height="2rem" />
                <div class="best-worst-areas-container" :class="{ 'pdf-shadow-fix': isPdf }">
                    <div v-for="area in bestPerformingAreas" class="best-worst-areas-item">
                        <div style="margin-top: 2px">
                            <ScorecardDonut
                                :width="44"
                                :strokeWidth="2"
                                :compact="true"
                                :hidden="isLoading"
                                :score="isLoading ? 0 : area.score"
                            />
                        </div>
                        <Spacer width="1.25rem" />
                        <div>
                            <Text as="p" size="f-7" weight="500">{{ area.title }}</Text>
                            <Spacer height="0.25rem" />
                            <Text as="p" size="f-8">
                                {{ area.copy[0] }}
                            </Text>
                        </div>
                    </div>
                </div>

                <Spacer height="2.5rem" />

                <!-- Worst Performing Areas -->
                <Text v-if="worstPerformingAreas.length" as="h6" weight="600"
                    >Worst Performing Areas</Text
                >
                <Spacer v-if="worstPerformingAreas.length" height="2rem" />
                <div
                    v-if="worstPerformingAreas.length"
                    class="best-worst-areas-container"
                    :class="{ 'pdf-shadow-fix': isPdf }"
                >
                    <div v-for="area in worstPerformingAreas" class="best-worst-areas-item">
                        <div style="margin-top: 2px">
                            <ScorecardDonut
                                :width="44"
                                :strokeWidth="2"
                                :compact="true"
                                :hidden="isLoading"
                                :score="isLoading ? 0 : area.score"
                            />
                        </div>
                        <Spacer width="1.25rem" />
                        <div>
                            <Text as="p" size="f-7" weight="500">{{ area.title }}</Text>
                            <Spacer height="0.25rem" />
                            <Text as="p" size="f-8">
                                {{ area.copy[0] }}
                            </Text>
                        </div>
                    </div>
                </div>

                <Spacer v-if="worstPerformingAreas.length" height="2.5rem" />

                <Text as="h6" weight="600">Key Statistics (30 Days)</Text>
                <Spacer height="2rem" />
                <div class="key-statistics-container" :class="{ 'pdf-shadow-fix': isPdf }">
                    <div
                        v-for="statistic in formattedKeyStatistics"
                        :key="statistic.id"
                        class="key-statistic-item"
                    >
                        <Text as="span" size="f-8">{{ statistic.name }}</Text>
                        <Spacer height="0.375rem" />
                        <div class="statistic">
                            <Text as="h5" weight="600" style="letter-spacing: -0.06rem">{{
                                statistic.value
                            }}</Text>
                            <Spacer width="0.75rem" />
                            <Delta
                                :delta="statistic.delta"
                                :reverse="statistic.deltaReverse"
                                :threshold="0"
                            />
                        </div>
                    </div>
                </div>
                <Spacer height="1.5rem" />
                <!-- Note -->
                <ImprovementNote>
                    <Text size="f-9" color="gray">
                        Data from the last <b>30 days</b>. Compared with the
                        <b>previous 30 days</b>.
                    </Text>
                </ImprovementNote>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue'
import formatDate from 'date-fns/format'
import useMediaQuery from '@/composition/global/useMediaQuery'

import ScorecardDonut from './ScorecardDonut.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import { getSectionCopy, getMiniCopy } from './utils'
import { Scorecard } from '@opteo/types'

import {
    Text,
    Spacer,
    useMoney,
    useNumber,
    useRoas,
    CalendarIcon,
    Delta,
    oButton,
} from '@opteo/components-next'

interface KeyStatistics {
    type: 'key_statistics'
    spend: number
    conversions: number
    conversions_value: number
    cpa: number | null
    roas: number | null
    impressions: number
    spend_delta: number
    conversions_delta: number
    conversions_value_delta: number
    cpa_delta: number | null
    roas_delta: number | null
    impressions_delta: number
}

type HiddenSections = Scorecard.SectionType[]

export default defineComponent({
    name: 'Overview',
    components: {
        Text,
        Spacer,
        ScorecardDonut,
        CalendarIcon,
        Skeleton,
        Delta,
        oButton,
    },
    props: {
        score: {
            type: Number,
        },
        scores: {
            type: Object as PropType<Scorecard.AllSections>,
        },
        lastUpdated: {
            type: [Date, String],
        },
        isLoading: {
            type: Boolean,
        },
        keyStatistics: {
            type: Object as PropType<KeyStatistics>,
        },
        domainCurrency: {
            type: String,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        hiddenSections: {
            type: Object as PropType<HiddenSections>,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
    },
    emits: ['score-history-button-clicked'],
    setup(props, { emit }) {
        const isPdf = props.mode === 'pdf'
        const { aboveMobile } = useMediaQuery()

        const buttonClicked = () => {
            emit('score-history-button-clicked')
        }

        // TODO(scorecard): add actual suggestions or remove this if not needed
        const suggestions: Record<Scorecard.SectionType, string> = {
            overall: 'Overall',
            integrations: 'Integrations',
            conversionTracking: 'Conversion Tracking',
            disapprovals: 'Disapprovals',
            projectedSpend: 'Projected Spend',
            budgetCap: 'Budget Limited Campaigns',
            spendAllocation: 'Spend Allocation',
            qs: 'Quality Score',
            adExtension: 'Ad Extension Coverage',
            matchtype: 'Match Type Breakdown',
            sqr: 'Search Term Coverage',
            searchTermAlignment: 'Search Term Alignment',
            performanceMax: 'Performance Max',
            productPerformance: 'Product Performance',
            landingPages: 'Landing Pages',
        }

        const getScoresEntries = (
            scores: Scorecard.AllSections
        ): [string, Scorecard.AllSections[keyof Scorecard.AllSections]][] => {
            return Object.entries(scores)
        }

        const bestPerformingAreas = computed(() => {
            if (!props.scores) return []

            const scoreEntries = getScoresEntries(props.scores)

            const scores = scoreEntries
                .filter(([key]) => {
                    if (!Array.isArray(props.hiddenSections)) {
                        return true
                    }

                    return !props.hiddenSections.some(section => section === key)
                })
                .filter(([_, o]) => !o.invalid) // Don't show if invalid
                .sort(([, a], [, b]) => (b.score ?? 0) - (a.score ?? 0))
                .slice(0, 2)
                .map(([_, o]) => {
                    const type = o.type
                    return {
                        type,
                        title: Scorecard.SectionNames[type],
                        suggestion: suggestions[type],
                        score: o.score,
                        copy: getSectionCopy(getMiniCopy(type, o.details), o.score),
                    }
                })

            return scores
        })

        const worstPerformingAreas = computed(() => {
            if (!props.scores) return []

            const scoreEntries = getScoresEntries(props.scores)

            const scores = scoreEntries
                .filter(([_, o]) => {
                    // Don't show the same both in best and worst
                    return bestPerformingAreas.value.every(s => s.type !== o.type)
                })
                .filter(([_, o]) => {
                    // Don't show if invalid or score is 100
                    return !o.invalid && o.score < 100
                })
                .filter(([key]) => {
                    // Don't show if hidden
                    if (!Array.isArray(props.hiddenSections)) {
                        return true
                    }

                    return !props.hiddenSections.some(section => section === key)
                })
                .sort(([, a], [, b]) => (a.score ?? 0) - (b.score ?? 0))
                .slice(0, 2)
                .map(([_, o]) => {
                    const type = o.type
                    return {
                        title: Scorecard.SectionNames[type],
                        suggestion: suggestions[type],
                        score: o.score,
                        copy: getSectionCopy(getMiniCopy(type, o.details), o.score),
                    }
                })

            return scores
        })

        const worstPerformingAreasCopy = computed(() => {
            const copyValues: string[] = []

            if (!worstPerformingAreas.value.length) {
                return ''
            }

            worstPerformingAreas.value.forEach(({ suggestion }) => {
                copyValues.push(suggestion)
            })

            if (copyValues.length === 1) {
                return copyValues[0]
            }

            return `${copyValues[0]} and ${copyValues[1]}`
        })

        const textOptions = computed(() => {
            return {
                0: [
                    `Your account has several opportunities to optimise and improve performance. The following scores highlight some changes that should help with improving performance and increasing ROI.`,
                    `${
                        worstPerformingAreasCopy?.value
                            ? `Areas of concern include ${worstPerformingAreasCopy.value}. By implementing a best-practice approach in these areas`
                            : 'By implementing a best-practice approach in the areas outlined below'
                    }, we should expect to see budget being used more efficiently and significant improvements across key performance metrics. With all going as planned, we should start to see some meaningful improvements over the coming months.`,
                ],
                50: [
                    `Your account has some opportunities to optimise and improve performance. The following scores highlight some changes that should help with maintaining existing positives and improving in areas where performance might be falling behind.`,
                    `${
                        worstPerformingAreasCopy?.value
                            ? `Areas with room for improvement include ${worstPerformingAreasCopy.value}. By implementing a best-practice approach in these areas`
                            : 'By implementing a best-practice approach in the areas outlined below'
                    }, we should expect to see budget being used more efficiently and steady improvements across key performance metrics.`,
                    `Building on existing customer targeting and refining our customer acquisition strategy should help us access new revenue streams. Through testing and targeting new audiences, we can start to understand which customer groups are worth pursuing.`,
                ],
                70: [
                    `Your account is in good health. The following scores highlight some small changes to help with maintaining existing positives and incrementally improving performance moving forward.`,
                    `Moving forward, we should aim to scale clicks and conversions within our current budget. Because performance is strong, we could consider increasing our monthly budget to reach more customers and encourage more conversions.`,
                ],
            }
        })

        const sectionCopy = computed(() => {
            return getSectionCopy(textOptions.value, props.score ?? 0)
        })

        const formattedLastUpdated = computed(() => {
            const date = props.lastUpdated ? new Date(props.lastUpdated) : new Date()
            return formatDate(date, "do 'of' LLLL 'at' HH:mm")
        })

        const formattedKeyStatistics = computed<
            | {
                  id: string
                  name: string
                  value: string
                  delta: number
                  deltaReverse: boolean
              }[]
            | undefined
        >(() => {
            if (!props.keyStatistics || !props.domainCurrency) {
                return
            }

            const {
                spend_delta,
                conversions_delta,
                conversions_value_delta,
                impressions_delta,
                cpa_delta,
                roas_delta,
            } = props.keyStatistics
            const spend = useMoney({
                value: props.keyStatistics.spend,
                currency: props.domainCurrency,
            }).displayValue.value
            const conversions = useNumber({ value: props.keyStatistics.conversions, compact: true })
                .displayValue.value
            const conversionsValue = useMoney({
                value: props.keyStatistics.conversions_value,
                currency: props.domainCurrency,
                compact: true,
            }).displayValue.value
            const impressions = useNumber({ value: props.keyStatistics.impressions, compact: true })
                .displayValue.value

            const baseKeyStatistics: {
                id: string
                name: string
                value: string
                delta: number
                deltaReverse: boolean
            }[] = [
                {
                    id: 'spend',
                    name: 'Spend',
                    value: spend,
                    delta: spend_delta / 100,
                    deltaReverse: true,
                },
                props.isUsingCpa
                    ? {
                          id: 'conversions',
                          name: 'Conversions',
                          value: conversions,
                          delta: conversions_delta / 100,
                          deltaReverse: false,
                      }
                    : {
                          id: 'conversionsValue',
                          name: 'Conversion Value',
                          value: conversionsValue,
                          delta: conversions_value_delta / 100,
                          deltaReverse: false,
                      },
                {
                    id: 'impressions',
                    name: 'Impressions',
                    value: impressions,
                    delta: impressions_delta / 100,
                    deltaReverse: false,
                },
            ]

            if (props.isUsingCpa) {
                const cpa = useMoney({
                    value: props.keyStatistics.cpa ?? 0,
                    currency: props.domainCurrency,
                }).displayValue.value
                baseKeyStatistics.push({
                    id: 'cpa',
                    name: 'Cost Per Conversion',
                    value: cpa,
                    delta: cpa_delta ? cpa_delta / 100 : 0,
                    deltaReverse: true,
                })
            } else {
                const roas = useRoas({ value: props.keyStatistics.roas ?? 0 }).displayValue.value
                baseKeyStatistics.push({
                    id: 'roas',
                    name: 'ROAS',
                    value: roas,
                    delta: roas_delta ? roas_delta / 100 : 0,
                    deltaReverse: false,
                })
            }

            return baseKeyStatistics
        })

        return {
            isPdf,
            bestPerformingAreas,
            worstPerformingAreas,
            sectionCopy,
            formattedLastUpdated,
            formattedKeyStatistics,
            buttonClicked,
            aboveMobile,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.section-container {
    @include w-100;
    @include flex;
    @include justify-center;
    @include relative;
    break-before: page; // used for pdf
}

.section-container.not-pdf-render {
    @include container;
    border-radius: 2rem;
    padding-top: 8rem;
    padding-left: 8rem;
    padding-bottom: 8rem;
    padding-right: 8rem;
    break-before: page; // used for pdf
}

.sticky {
    @include sticky;
    top: 8rem;
    margin-right: 5rem;
}

.sticky.is-pdf-render {
    position: relative;
    top: 0rem;
    margin-right: 5rem;
}

.section-button {
    @include absolute;
    top: 1.5rem;
    right: 1.5rem;
    @include flex;
}

.section-button.is-pdf-render {
    display: none;
}

.section-content {
    @include mt-8;
    @include w-100;
    max-width: 28.875rem;
}

.best-worst-areas-container {
    @include container;
    @include br-20;
    @include w-100;
}

.best-worst-areas-item {
    @include ph-24;
    @include pv-20;
    @include flex;
    border-bottom: 1px solid #f7f7f9;
}

.best-worst-areas-item:last-child {
    border-bottom: none;
}

.key-statistics-container {
    @include container;
    @include br-20;
    @include w-100;
    @include grid;
    grid-template-columns: 1fr 1fr;
}

.key-statistic-item {
    @include ph-24;
    @include pv-20;
    border-bottom: 1px solid #f7f7f9;
}

.key-statistic-item:last-child {
    border-bottom: none;
}

.key-statistic-item:nth-child(odd) {
    border-right: 1px solid #f7f7f9;
}

.key-statistic-item:nth-last-child(-n + 2) {
    border-bottom: none;
}

.statistic {
    @include flex;
    @include items-center;
}

// .scorecard-created-date {
//     @include container;
//     width: 100%;
//     @include pa-24;
//     @include flex;
//     @include items-center;
// }

.calendar-icon {
    @include mr-16;
}

.skeletons {
    @include w-100;
    max-width: 28.75rem;
}

:deep(.improvement-note) {
    @include br-20;
}

// Responsive

@media (max-width: $mq-480-max) {
    .not-pdf-render.section-container {
        flex-direction: column;
        @include items-center;
        @include ph-16;
        @include pt-48;
        @include pb-20;
    }

    .not-pdf-render .sticky {
        @include mb-24;
        margin-left: auto;
        margin-right: auto;
    }

    .not-pdf-render .section-content {
        @include mt-24;
    }
    .not-pdf-render .section-button {
        display: none;
    }
    .not-pdf-render .key-statistics-container {
        @include block;
    }

    .not-pdf-render .key-statistic-item,
    .not-pdf-render .key-statistic-item:nth-last-child(-n + 2) {
        @include ph-24;
        @include pv-20;
        border-bottom: 1px solid #f7f7f9;
    }

    .not-pdf-render .key-statistic-item:last-child {
        border-bottom: none;
    }

    .not-pdf-render .key-statistic-item:nth-child(odd) {
        border-right: unset;
    }
}

@media screen and (min-width: $mq-481-min) and (max-width: $mq-767-max) {
    .not-pdf-render.section-container {
        flex-direction: column;
        @include items-center;
        @include ph-48;
        @include pv-64;
    }

    .not-pdf-render .sticky {
        @include mb-32;
        margin-left: auto;
        margin-right: auto;
    }
    .not-pdf-render .section-content {
        @include mt-32;
    }
    .not-pdf-render .section-button {
        display: none;
    }

    .not-pdf-render .key-statistics-container {
        @include block;
    }
    .not-pdf-render .key-statistic-item,
    .not-pdf-render .key-statistic-item:nth-last-child(-n + 2) {
        @include ph-24;
        @include pv-20;
        border-bottom: 1px solid #f7f7f9;
    }

    .not-pdf-render .key-statistic-item:last-child {
        border-bottom: none;
    }

    .not-pdf-render .key-statistic-item:nth-child(odd) {
        border-right: unset;
    }
}

@media screen and (min-width: $mq-768-min) and (max-width: $mq-1024-max) {
    .not-pdf-render.section-container {
        flex-direction: column;
        @include items-center;
    }

    .not-pdf-render .sticky {
        @include mb-32;
        margin-left: auto;
        margin-right: auto;
    }

    .not-pdf-render .section-content {
        @include mt-32;
    }
}
</style>
