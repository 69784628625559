<template>
    <Popout trigger="mouseenter" placement="top" :offset="[-28, 12]" :z-index="2147483005">
        <div class="ad-extension-cell">
            {{ totalCount }}
        </div>
        <template v-slot:content>
            <ChartTooltip
                :plot-lines="[
                    {
                        label: 'Account Level',
                        value: customerLevelCount,
                    },
                    {
                        label: 'Campaign Level',
                        value: campaignLevelCount,
                    },
                    {
                        label: 'Ad Group Level',
                        value: adGroupLevelCount,
                    },
                ]"
            />
        </template>
    </Popout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { ChartTooltip, Popout } from '@opteo/components-next'

export default defineComponent({
    name: 'AdExtensionCoverageBreakdownTableCell',
    components: {
        Popout,
        ChartTooltip,
    },
    props: {
        totalCount: {
            type: Number,
            required: true,
        },
        customerLevelCount: {
            type: Number,
            required: true,
        },
        campaignLevelCount: {
            type: Number,
            required: true,
        },
        adGroupLevelCount: {
            type: Number,
            required: true,
        },
    },
})
</script>

<style lang="scss" scoped>
.ad-extension-cell {
    cursor: pointer;
}
</style>
