<template>
    <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="7" r="6" stroke="#000" stroke-width="2" />
        <path
            d="M1 17s2-4 9-4 9 4 9 4M20 10h4M22 12V8"
            stroke="#000"
            stroke-width="2"
            stroke-linecap="round"
        />
    </svg>
</template>
