<template>
    <button :id="`${id}-button`" class="opteo-button-circle" @click="clicked" :style="styles">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: false,
        },
        styles: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        clicked() {
            if (!this.disabled) {
                this.$emit('clicked')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.opteo-button-circle {
    cursor: pointer;
    @include no-select;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    box-shadow: $opteo-shadow !important;
    background-color: white;
    padding: 0rem;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    display: block;
}
</style>
