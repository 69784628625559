<template>
    <div class="template-info" @click="emitTemplateId()">
        <Text as="h5" size="f-7" weight="500" :capitalise="true">
            {{ template.template_name }}
        </Text>
        <Spacer height="1.25rem" />
        <ul>
            <li
                v-for="category in template.categories"
                :key="category.category_id"
                class="template-slide-item"
                :class="{ deselected: !category.active }"
            >
                <Text size="f-8" style="margin-right: 0.5rem">
                    {{ category.category_name }}
                </Text>
                <Count size="small" :count="category.enabledSlidesCount" />
            </li>
        </ul>
        <Spacer height="1rem" />
        <Text color="gray" size="f-9" as="span">{{ template.slide_count }} Slides</Text>
        <CheckIcon
            @click="emitTemplateId()"
            checkable
            :checked="template.template_id === selectedTemplateId"
            style="position: absolute; top: 24px; right: 24px; cursor: pointer"
        />
    </div>
    <!-- BUTTONS -->
    <div v-if="template.template_id > 0">
        <!-- EDIT BUTTON -->
        <div class="edit-button">
            <Popout
                v-model="editPopoutOpen"
                :trapFocus="true"
                :externalToggleButton="true"
                :maxWidth="340"
                :offset="[0, 8]"
                :popperOptions="{
                    modifiers: [{ name: 'flip', enabled: false }],
                }"
                placement="top-end"
                :zIndex="10000000"
                :borderRadius="20"
            >
                <oButton color="white" size="small" @clicked="editPopoutOpen = !editPopoutOpen">
                    Edit
                </oButton>
                <template #content>
                    <ReportTemplateSelectorItemPopout
                        :slides="flatSlides"
                        :templateName="template.template_name"
                        mode="update"
                        @submit-template="emitEditTemplate"
                        @delete-template="emitDeleteTemplate"
                        @cancel="editPopoutOpen = false"
                    />
                </template>
            </Popout>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, ref, computed } from 'vue'
import { Reports } from '@opteo/types'
import { useDomain } from '@/composition/domain/useDomain'
import ReportTemplateSelectorItemPopout from './ReportTemplateSelectorItemPopout.vue'

import {
    oButton,
    oInput,
    Modal,
    Text,
    CheckIcon,
    Ent,
    Spacer,
    Count,
    Popout,
    Tooltip,
} from '@opteo/components-next'

export default {
    components: {
        oButton,
        oInput,
        Modal,
        Text,
        CheckIcon,
        Ent,
        Spacer,
        Count,
        Popout,
        Tooltip,
        ReportTemplateSelectorItemPopout,
    },
    props: {
        template: {
            type: Object,
            required: true,
        },
        selectedTemplateId: {
            type: Number,
            required: false,
        },
        baseTemplateCategories: {
            type: Array as PropType<Reports.Core.BaseReportTemplate>,
            required: true,
        },
    },
    emits: ['select-template', 'delete-template', 'edit-template'],
    setup(props, { emit }) {
        const { domainInfo } = useDomain()

        const editPopoutOpen = ref(false)

        const flatSlides = computed(() =>
            props.baseTemplateCategories
                .map(category => {
                    return category.slides.map(slide => {
                        return {
                            ...slide,
                            selected: props.template.slide_types.includes(slide.slide_id),
                        }
                    })
                })
                .flat()
        )

        const emitTemplateId = () => emit('select-template', props.template.template_id)
        const emitDeleteTemplate = () => {
            emit('delete-template', props.template.template_id, props.template.template_name)
            editPopoutOpen.value = false
        }
        const emitEditTemplate = (slides: Reports.Core.BaseReportSlide[], templateName: string) => {
            emit('edit-template', props.template.template_id, slides, templateName)
            editPopoutOpen.value = false
        }

        return {
            emitTemplateId,
            emitDeleteTemplate,
            emitEditTemplate,
            editPopoutOpen,
            domainInfo,
            flatSlides,
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.template-info {
    @include pa-24;
    height: 100%;
    cursor: pointer;
    height: 320px;
}
.template-slide-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.125rem;
}
.template-slide-item.deselected {
    opacity: 0.25;
}

.edit-button {
    @include absolute;
    bottom: 1rem;
    right: 1rem;
}
.delete-button {
    bottom: 16px;
    right: 16px;
}

.delete-template-popout {
    @include pa-24;
}
.delete-template-popout-content {
    @include container;
    @include pa-24;
    max-height: 300px;
    overflow-y: auto;
}
.delete-template-popout-buttons {
    display: flex;
    justify-content: flex-start;
}
</style>
