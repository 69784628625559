<template>
    <div class="experiment" @click="openExperiment(experiment)" tabindex="0">
        <!-- Title + Campaigns -->
        <div class="left">
            <div class="title">
                <Text as="h3" size="f-6" weight="600">
                    {{ formatBiddingStrategy(experiment.currentBiddingStrategy) }}
                </Text>
                <div class="vs">vs</div>
                <Text as="h3" size="f-6" weight="600">
                    {{
                        formatBiddingStrategy(
                            experiment.settings.experimentBiddingStrategy,
                            experiment.settings
                        )
                    }}
                </Text>
            </div>
            <Popout
                v-model="campaignPopoutOpen"
                :maxWidth="720"
                :borderRadius="20"
                placement="top"
                trigger="mouseenter"
            >
                <EntityPill
                    type="campaign"
                    :content="
                        experiment.campaignExperimentPairs.length === 1
                            ? '1 Campaign'
                            : `${experiment.campaignExperimentPairs.length} Campaigns`
                    "
                    :tooltip="false"
                />
                <template v-slot:content>
                    <oTable
                        :headers="campaignTableHeaders"
                        :items="campaignTableItems"
                        :perPage="4"
                        style="width: 100%"
                    >
                        <template #column.campaign="campaign">
                            <div style="padding-top: 1rem; padding-bottom: 1rem">
                                <EntityPill
                                    type="campaign"
                                    :content="campaign.value"
                                    :tooltip="false"
                                    style="max-width: 14.625rem"
                                />
                            </div>
                        </template>
                    </oTable>
                </template>
            </Popout>
        </div>
        <!-- Comparison + Progress -->
        <div class="right">
            <div class="comparison-item">
                <!-- Value -->
                <div class="value">
                    <Text as="h3" size="f-6" weight="600">
                        <Money
                            v-if="relevantPerformanceMetricType === PerformanceMetricTypes.cpa"
                            :value="
                                experiment.baseTotalData.allConversions !== 0 &&
                                experiment.baseTotalData.cost !== 0
                                    ? experiment.baseTotalData.cost /
                                      experiment.baseTotalData.allConversions
                                    : 0
                            "
                            :currency="currencyCode"
                        />
                        <Roas
                            v-if="relevantPerformanceMetricType === PerformanceMetricTypes.roas"
                            :value="
                                experiment.baseTotalData.allConversionsValue /
                                experiment.baseTotalData.cost
                            "
                        />
                        <Percent
                            v-if="
                                relevantPerformanceMetricType ===
                                PerformanceMetricTypes.targetImpressionShare
                            "
                            :value="experiment.baseTotalData.searchImpressionShare ?? 0"
                        />
                    </Text>
                    <ColorTag
                        v-if="
                            experiment.isExperimentWinning === null &&
                            percentComplete <= 0.95 &&
                            !isExperimentCompleted
                        "
                        color="blue"
                        title="In Progress"
                        size="small"
                    />
                    <ColorTag
                        v-if="
                            experiment.isExperimentWinning === null &&
                            (percentComplete > 0.95 || isExperimentCompleted)
                        "
                        color="gray"
                        title="Inconclusive"
                        size="small"
                    />
                    <ColorTag
                        v-else-if="experiment.isExperimentWinning === true"
                        color="red"
                        :title="isActive ? 'Losing' : 'Loser'"
                        size="small"
                    />
                    <ColorTag
                        v-else-if="experiment.isExperimentWinning === false"
                        color="green"
                        :title="isActive ? 'Winning' : 'Winner'"
                        size="small"
                    />
                </div>
                <!-- Title -->
                <Text as="span" color="gray" size="f-9" class="comparison-item-title">
                    {{ relevantPerformanceMetricTypeCopy.base }}
                </Text>
            </div>
            <div class="comparison-item">
                <!-- Value -->
                <div class="value">
                    <Text as="h3" size="f-6" weight="600">
                        <Money
                            v-if="relevantPerformanceMetricType === PerformanceMetricTypes.cpa"
                            :value="
                                experiment.experimentTotalData.allConversions !== 0 &&
                                experiment.experimentTotalData.cost !== 0
                                    ? experiment.experimentTotalData.cost /
                                      experiment.experimentTotalData.allConversions
                                    : 0
                            "
                            :currency="currencyCode"
                        />
                        <Roas
                            v-if="relevantPerformanceMetricType === PerformanceMetricTypes.roas"
                            :value="
                                experiment.experimentTotalData.allConversionsValue /
                                experiment.experimentTotalData.cost
                            "
                        />
                        <Percent
                            v-if="
                                relevantPerformanceMetricType ===
                                PerformanceMetricTypes.targetImpressionShare
                            "
                            :value="experiment.experimentTotalData.searchImpressionShare ?? 0"
                        />
                    </Text>
                    <ColorTag
                        v-if="
                            experiment.isExperimentWinning === null &&
                            percentComplete <= 0.95 &&
                            !isExperimentCompleted
                        "
                        color="blue"
                        title="In Progress"
                        size="small"
                    />
                    <ColorTag
                        v-if="
                            experiment.isExperimentWinning === null &&
                            (percentComplete > 0.95 || isExperimentCompleted)
                        "
                        color="gray"
                        title="Inconclusive"
                        size="small"
                    />
                    <ColorTag
                        v-if="experiment.isExperimentWinning === true"
                        color="green"
                        :title="isActive ? 'Winning' : 'Winner'"
                        size="small"
                    />
                    <ColorTag
                        v-if="experiment.isExperimentWinning === false"
                        color="red"
                        :title="isActive ? 'Losing' : 'Loser'"
                        size="small"
                    />
                </div>
                <!-- Title -->
                <Text as="span" color="gray" size="f-9" class="comparison-item-title">
                    {{ relevantPerformanceMetricTypeCopy.experiment }}
                </Text>
            </div>
            <!-- Progress Bar -->
            <Popout
                v-if="isActive"
                v-model="progressPopoutOpen"
                :maxWidth="264"
                :borderRadius="20"
                :offset="[-24, 11]"
                placement="top-start"
                trigger="mouseenter"
            >
                <div class="progress">
                    <!-- Score/Icon/Spinner -->
                    <div class="progress-icon-container">
                        <Spinner v-if="isExperimentCompleting" />
                        <ScorecardDonut
                            v-else
                            :score="percentComplete * 100"
                            :compact="true"
                            :width="28"
                            :strokeWidth="3"
                            hideScore
                            :customColor="donutColor"
                        />
                    </div>
                    <!-- Content -->
                    <div class="progress-content">
                        <Text
                            v-if="
                                experiment.status ===
                                SmartBiddingExperiments.OpteoExperimentStatus.FAILED_COMPLETE
                            "
                            color="red"
                            as="h4"
                            size="f-6"
                            weight="600"
                        >
                            Push Unsuccessful
                        </Text>
                        <Text
                            v-else-if="isExperimentDurationComplete"
                            color="green"
                            as="h4"
                            size="f-6"
                            weight="600"
                        >
                            Awaiting Decision
                        </Text>
                        <Text
                            v-else-if="isExperimentCompleting"
                            color="blue"
                            as="h4"
                            size="f-6"
                            weight="600"
                        >
                            Pushing Experiment
                        </Text>
                        <Text v-else color="blue" as="h4" size="f-6" weight="600">
                            {{ `${Math.round(percentComplete * 100)}% Complete` }}
                        </Text>
                        <Text
                            v-if="
                                experiment.status ===
                                SmartBiddingExperiments.OpteoExperimentStatus.FAILED_COMPLETE
                            "
                            as="span"
                            color="gray"
                            size="f-9"
                            style="font-size: 0.75rem"
                        >
                            Hover for error message
                        </Text>
                        <Text
                            v-else-if="isExperimentCompleting"
                            as="span"
                            color="gray"
                            size="f-9"
                            style="font-size: 0.75rem"
                        >
                            Pushing to Google Ads
                        </Text>
                        <Text v-else as="span" color="gray" size="f-9" style="font-size: 0.75rem">
                            <span v-if="isExperimentDurationComplete">Open to apply changes</span>
                            <span v-else>
                                {{ `${getDaysLeft(experiment.actualEndDate)}` }} days remaining
                            </span>
                        </Text>
                    </div>
                </div>
                <template v-slot:content>
                    <div
                        v-if="
                            experiment.status ===
                            SmartBiddingExperiments.OpteoExperimentStatus.FAILED_COMPLETE
                        "
                        class="progress-popout error"
                    >
                        <div class="row">
                            <Text
                                v-if="experiment?.failedCompleted?.[0]?.displayError"
                                as="p"
                                color="red"
                                size="f-8"
                            >
                                <b>Error:</b>
                                {{ experiment.failedCompleted[0].displayError }}
                            </Text>
                            <Text v-else as="p" color="red" size="f-8">
                                <b>Error:</b> There was an unexpected error pushing this experiment.
                            </Text>
                        </div>
                        <div class="row">
                            <Text as="p" size="f-8">
                                Try pushing this experiment again. If the error persists, please
                                send a message to our support team.
                            </Text>
                        </div>
                    </div>
                    <div v-else class="progress-popout active">
                        <div class="row">
                            <ScorecardDonut
                                :score="confidenceLevel * 100"
                                :compact="true"
                                :width="28"
                                :strokeWidth="3"
                                hideScore
                                :customColor="confidenceLevel >= 0.95 ? '#00a861' : '#006dff'"
                            />
                            <div>
                                <Text
                                    :color="confidenceLevel >= 0.95 ? 'green' : 'blue'"
                                    as="h4"
                                    size="f-6"
                                    weight="600"
                                >
                                    <Percent :value="confidenceLevel" :decimalPlaces="0" />
                                    Confidence
                                </Text>
                                <Text
                                    v-if="confidenceLevel >= 0.95"
                                    as="span"
                                    color="gray"
                                    size="f-9"
                                    style="font-size: 0.75rem"
                                >
                                    Data is statistically significant
                                </Text>
                                <Text
                                    v-else
                                    as="span"
                                    color="gray"
                                    size="f-9"
                                    style="font-size: 0.75rem"
                                >
                                    Experiment gathering data
                                </Text>
                            </div>
                        </div>
                        <div class="row">
                            <ScorecardDonut
                                :score="percentComplete * 100"
                                :compact="true"
                                :width="28"
                                :strokeWidth="3"
                                hideScore
                                :customColor="isExperimentDurationComplete ? '#00a861' : '#006dff'"
                            />
                            <div>
                                <Text
                                    v-if="getDaysLeft(experiment.actualEndDate) === 1"
                                    color="blue"
                                    as="h4"
                                    size="f-6"
                                    weight="600"
                                >
                                    {{ `${getDaysLeft(experiment.actualEndDate)}` }}
                                    day remaining
                                </Text>
                                <Text v-else color="blue" as="h4" size="f-6" weight="600">
                                    {{ `${getDaysLeft(experiment.actualEndDate)}` }}
                                    days remaining
                                </Text>
                                <Text as="span" color="gray" size="f-9" style="font-size: 0.75rem">
                                    Experiment active for
                                    {{
                                        `${Math.round(
                                            getTotalDays(
                                                experiment.creationDate,
                                                experiment.actualEndDate
                                            ) * percentComplete
                                        )}`
                                    }}
                                    <span
                                        v-if="
                                            Math.round(
                                                getTotalDays(
                                                    experiment.creationDate,
                                                    experiment.actualEndDate
                                                ) * percentComplete
                                            ) === 1
                                        "
                                    >
                                        day
                                    </span>
                                    <span v-else>days</span>
                                </Text>
                            </div>
                        </div>
                    </div>
                </template>
            </Popout>
            <!-- Cancelled -->
            <div
                v-else-if="
                    experiment.status === SmartBiddingExperiments.OpteoExperimentStatus.CANCELLED
                "
                class="progress"
            >
                <ScorecardDonut
                    :score="100"
                    :compact="true"
                    :width="28"
                    :strokeWidth="3"
                    hideScore
                    customColor="#adadaf"
                />
                <div>
                    <Text color="gray" as="h4" size="f-6" weight="600">Experiment Cancelled</Text>
                    <Text as="span" color="gray" size="f-9" style="font-size: 0.75rem">
                        Applied to
                        <Text as="span" weight="600">
                            {{ appliedCompletedExperimentsCount }} of
                            {{ totalCampaignsCount }} campaigns
                        </Text>
                    </Text>
                </div>
            </div>
            <!-- Completed -->
            <div v-else class="progress">
                <ScorecardDonut
                    :score="100"
                    :compact="true"
                    :width="28"
                    :strokeWidth="3"
                    hideScore
                    customColor="#00a861"
                />
                <div>
                    <Text color="green" as="h4" size="f-6" weight="600">Experiment Complete</Text>
                    <Text as="span" color="gray" size="f-9" style="font-size: 0.75rem">
                        Applied to
                        <Text as="span" weight="600">
                            {{ appliedCompletedExperimentsCount }} of
                            {{ totalCampaignsCount }} campaigns
                        </Text>
                    </Text>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// Imports
import { PropType, computed, ref } from 'vue'
import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'

import {
    useExperiments,
    PerformanceMetricTypes,
} from '@/composition/toolkit/experiments/useExperiments'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'

import { SmartBiddingExperiments } from '@opteo/types'

// Components
import {
    oButton,
    Text,
    Spacer,
    EntityPill,
    Money,
    Roas,
    Percent,
    ColorTag,
    RightArrowIcon,
    Popout,
    oTable,
    Tooltip,
    Spinner,
} from '@opteo/components-next'

// Local Components
import Skeleton from '@/components/util/Skeleton.vue'
import ProgressBar from '@/components/global/ProgressBar.vue'
import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'

// Export
export default {
    name: 'ExperimentCard',
    components: {
        oButton,
        Text,
        Spacer,
        EntityPill,
        Money,
        Roas,
        Percent,
        ColorTag,
        RightArrowIcon,
        ScorecardDonut,
        Popout,
        oTable,
        Tooltip,
        Spinner,
        // Local
        Skeleton,
        ProgressBar,
    },
    props: {
        experiment: {
            type: Object as PropType<SmartBiddingExperiments.GetAccountExperimentDataResponse>,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const { experiment } = props
        const {
            getHasEnoughData,
            getRelevantPerformanceMetricType,
            getPercentComplete,
            getIsExperimentDurationComplete,
            getIsConfidenceAboveNinetyFivePercent,
            getIsExperimentCompleted,
            formatBiddingStrategy,
        } = useExperiments()

        // Campaign Popout
        const campaignPopoutOpen = ref(false)
        const campaignTableHeaders = [
            { key: 'campaign', text: 'Campaign', sortable: true, removePadding: true },
            { key: 'cost', text: 'Cost', sortable: true },
            { key: 'conversions', text: 'Conv.' },
            { key: 'cpa', text: 'CPA', sortable: true },
            { key: 'strategy', text: 'Strategy', sortable: true },
        ]

        const campaignTableItems = experiment?.campaignExperimentPairs.map(pair => {
            const baseCampaignData = pair.baseCampaignMetrics
            return {
                id: 'campaignName',
                campaign: baseCampaignData.name,
                cost: useDomainMoney({ value: baseCampaignData?.cost ?? 0 }).value.displayValue
                    .value,
                conversions: useDomainMoney({ value: baseCampaignData?.allConversions ?? 0 }).value
                    .displayValue.value,
                cpa: useDomainMoney({
                    value: baseCampaignData.allConversions
                        ? baseCampaignData.cost / baseCampaignData.allConversions
                        : 0,
                }).value.displayValue.value,
                strategy: formatBiddingStrategy(
                    experiment.currentBiddingStrategy,
                    experiment.settings
                ),
            }
        })

        // Progres Popout
        const progressPopoutOpen = ref(false)

        authRequest<SmartBiddingExperiments.PopulateExperimentCampaignsResponse>(
            Endpoint.CheckExperimentStatus,
            {
                body: {
                    experimentId: experiment.optExperimentId,
                    experimentData: experiment,
                },
                uniqueId: () => experiment.optExperimentId,
            }
        )

        const relevantPerformanceMetricType = getRelevantPerformanceMetricType(
            experiment.settings.experimentBiddingStrategy
        )

        const relevantPerformanceMetricTypeCopy = computed(() => {
            const relevantPerformanceMetric =
                relevantPerformanceMetricType ?? PerformanceMetricTypes.cpa

            const performanceMetricTypeCopyPairs = {
                [PerformanceMetricTypes.cpa]: 'CPA',
                [PerformanceMetricTypes.roas]: 'ROAS',
                [PerformanceMetricTypes.targetImpressionShare]: 'Imp. Share',
            } as const

            const relevantPerformanceMetricTypeCopy =
                performanceMetricTypeCopyPairs[relevantPerformanceMetric]

            return {
                experiment: `Experiment ${relevantPerformanceMetricTypeCopy}`,
                base: `Campaign ${relevantPerformanceMetricTypeCopy}`,
            } as const
        })

        const hasEnoughData = getHasEnoughData({
            experimentTotalData: experiment.experimentTotalData,
            baseTotalData: experiment.baseTotalData,
            experimentBiddingStrategy: experiment.settings.experimentBiddingStrategy,
            baseBiddingStrategy: experiment.currentBiddingStrategy,
        })

        const percentComplete = getPercentComplete({
            start: experiment.creationDate,
            end: experiment.actualEndDate,
        })

        const confidenceLevel = experiment.confidenceLevel

        const isExperimentCompleted = getIsExperimentCompleted(props.experiment.status)

        const isExperimentCompleting = computed(() => {
            if (!props.experiment) return false
            return (
                props.experiment.status === SmartBiddingExperiments.OpteoExperimentStatus.COMPLETING
            )
        })

        const isExperimentDurationComplete = getIsExperimentDurationComplete(
            experiment.actualEndDate
        )
        const appliedCompletedExperimentsCount = computed(() => {
            if (isExperimentCompleted) {
                return props.experiment.campaignExperimentPairs.filter(experimentPair => {
                    return experimentPair.isExperimentWinning
                }).length
            }
        })
        const totalCampaignsCount = computed(() => {
            if (isExperimentCompleted) {
                return props.experiment.campaignExperimentPairs.length
            }
        })

        const donutColor = computed(() => {
            if (
                props.experiment.status ===
                SmartBiddingExperiments.OpteoExperimentStatus.FAILED_COMPLETE
            ) {
                return '#ff2828'
            } else if (isExperimentDurationComplete) {
                return '#00a861'
            } else {
                return '#006dff'
            }
        })

        const isConfidenceAboveNinetyFivePercent = getIsConfidenceAboveNinetyFivePercent(
            experiment.confidenceLevel
        )

        return {
            ...useDomain(),
            ...useExperiments(),
            SmartBiddingExperiments,
            PerformanceMetricTypes,
            relevantPerformanceMetricType,
            relevantPerformanceMetricTypeCopy,
            hasEnoughData,
            isExperimentCompleted,
            isExperimentCompleting,
            appliedCompletedExperimentsCount,
            totalCampaignsCount,
            percentComplete,
            isExperimentDurationComplete,
            isConfidenceAboveNinetyFivePercent,
            donutColor,
            campaignPopoutOpen,
            campaignTableHeaders,
            campaignTableItems,
            progressPopoutOpen,
            confidenceLevel,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.experiment {
    @include container;
    @include pl-28;
    padding-right: 1.375rem;
    height: 4.75rem;
    @include br-20;
    @include flex;
    @include items-center;
    @include justify-between;
    outline: none;
    cursor: pointer;
    transition: transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1), color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.experiment:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}

.experiment:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}

.experiment .left,
.experiment .right,
.experiment .left .title,
.experiment .comparison-item .value {
    @include flex;
    @include items-center;
}

.experiment .left .title h3,
.experiment .comparison-item h3 {
    font-size: 1.125rem;
}

.experiment .left {
    gap: 1.25rem;
}
.experiment .right {
    gap: 1.5rem;
}
.experiment .comparison-item .value {
    gap: 0.5rem;
}

.experiment .comparison-item .comparison-item-title {
    font-size: 0.75rem;
    line-height: 1rem;
}

.experiment .left .vs {
    @include container;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 999rem;
    @include f-10;
    @include flex-center;
    color: rgba(0, 0, 0, 0.32);
    padding-bottom: 0.125rem;
    margin: 0 0.625rem;
}

.experiment .comparison-item {
    width: 10rem;
    @include flex;
    flex-direction: column;
    gap: 4px;
}
.experiment .progress {
    // width: 11rem;
    width: 13.25rem;
    @include flex;
    @include items-center;
    gap: 0.875rem;
}
.experiment .progress .progress-content {
    @include flex;
    flex-direction: column;
    gap: 0.125rem;
}
.experiment .progress .progress-icon-container {
    width: 1.75rem;
    height: 1.75rem;
    @include flex-center;
}
.experiment .progress h4 {
    font-size: 1rem;
    line-height: 1.25rem;
}
.experiment .progress span {
    font-size: 0.75rem;
    line-height: 1rem;
}
</style>

<style>
/* Unscoped Styles */
.progress-popout h4 {
    font-size: 1rem !important;
    line-height: 23px !important;
    text-transform: capitalize;
}
.progress-popout span {
    font-size: 0.75rem !important;
    line-height: 17px !important;
}
.progress-popout .row {
    display: flex;
    align-items: center;
    gap: 0.875rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    border-bottom: 1px solid #f7f7f9;
    width: 16.5rem;
}
.progress-popout .row:last-child {
    border-bottom: none;
}
</style>
