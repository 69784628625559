import { NODE_ENV } from '@/lib/env'
import { Account, Tracking } from '@opteo/types'
import { useUser } from '../user/useUser'
import { authRequest, Endpoint } from './useAPI'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

export function useTracking() {
    const { userId } = useUser()

    const router = useRouter()

    const { currentRoute } = router

    const accountId = computed(() => currentRoute.value.params.accountId as Account.ID | undefined)

    /*
        Calling this function will add rows to the common.user_actions table in the database.
    */
    async function trackAction(actionName: Tracking.ActionName, metadata?: any) {
        if (!userId.value) {
            return
        }

        const body: Tracking.TrackParams = {
            user_id: userId.value,
            account_id: accountId.value,
            action_name: actionName,
            metadata,
            // @ts-ignore NPM_VERSION is defined in vite.config.ts
            vueo_version: NPM_VERSION,
            env: NODE_ENV,
        }

        await authRequest(Endpoint.TrackUserAction, { body })
    }

    return { trackAction }
}
