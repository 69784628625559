<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :on-push="onPush"
        :steps="adjustSteps"
        :push-data-still-loading="pushDataStillLoading"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="search-term" :content="searchQuery" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    The search term listed above is triggering your ads, but <b>has not</b> been
                    added as an individual keyword. The following (existing)
                    {{ tableItems.length > 1 ? 'keywords are' : 'keyword is' }}
                    responsible for this activity:
                </Text>
                <Spacer height="2rem" />
                <oTable :headers="tableHeaders" :items="tableItems" :per-page="5">
                    <template #column.spend="cellData">
                        <Money :value="cellData.row.spend" :currency="currencyCode" />
                    </template>
                </oTable>
                <Spacer height="2rem" />
                <Text as="p">
                    Over the last 365 days, you spent
                    <b><Money :value="metrics.cost.value" :currency="currencyCode" /></b> on this
                    search term. During this period, this search term generated
                    <b><Number :value="metrics.clicks.value" /> {{ metrics.clicks.copy }}</b>
                    and
                    <b>
                        <Number :value="metrics.conversions.value" :compact="true" />
                        {{ metrics.conversions.copy }}
                    </b>
                    <span v-if="metrics.conversions.value">
                        at a CPA of
                        <b><Money :value="metrics.cpa.value" :currency="currencyCode" /></b></span
                    >.
                </Text>
                <Spacer height="1rem" />
                <Text as="p">
                    Click <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to
                    add this search term as a
                    <Text as="span" color="green" weight="600">keyword</Text> or a
                    <Text as="span" color="red" weight="600">negative keyword</Text>. If your
                    existing keyword covers this traffic adequately, feel free to click
                    <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
        </ImprovementContent>

        <ImprovementContent>
            <SerpPreview title="Microsoft SERP Preview" :image-url="serpImageUrl" />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="365" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Create Keyword">
                    <Text as="p">
                        Use this form to create your new keyword. You can add this search term as
                        either a <Text as="span" color="green" weight="600">new keyword</Text> or a
                        <Text as="span" color="red" weight="600">negative keyword</Text>. Click
                        <b>Modify Keyword</b> to make adjustments.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>
            <ImprovementContent>
                <div class="create-keyword-form">
                    <Spacer :height="aboveMobile ? '2rem' : '1.5rem'" />
                    <Tabs
                        :router="false"
                        :links="[{ key: 'positive' }, { key: 'negative' }]"
                        :active="addingNegativeKeyword ? 'negative' : 'positive'"
                        @clicked="changeKeywordType"
                    >
                        <template v-slot:positive>
                            <Text as="span" :color="addingNegativeKeyword ? 'black' : 'green'">
                                {{ aboveSmallMobile ? 'New Keyword' : 'New KW' }}
                            </Text>
                        </template>
                        <template v-slot:negative>
                            <Text as="span" :color="addingNegativeKeyword ? 'red' : 'black'">
                                {{ aboveSmallMobile ? 'Negative Keyword' : 'Negative KW' }}
                            </Text>
                        </template>
                    </Tabs>
                    <div v-if="previewKeyword" class="create-keyword-form-preview">
                        <CircleKeywordIcon v-if="!addingNegativeKeyword" />
                        <CircleNegativeKeywordIcon v-if="addingNegativeKeyword" />
                        <Spacer width="0.875rem" />
                        <Text as="span" size="f-6" weight="600"> {{ previewKeyword }} </Text>
                    </div>
                    <div v-else class="create-keyword-form-preview">
                        <Text
                            as="span"
                            size="f-6"
                            weight="600"
                            style="line-height: 1.5rem; opacity: 0.16"
                        >
                            Please select at least one word
                        </Text>
                    </div>
                    <div class="modify-keyword-button">
                        <oButton color="white" @clicked="toggleShowKeywordSelection">
                            {{ showKeywordSelection ? 'Hide Section' : 'Modify Keyword' }}
                        </oButton>
                    </div>
                    <Spacer :height="aboveMobile ? '2rem' : '1.5rem'" />
                    <div v-show="showKeywordSelection" class="modify-keyword-section">
                        <Label>Select Words</Label>
                        <div
                            v-for="word in searchTermWords"
                            v-bind:key="word.index"
                            class="search-term-word-checkbox"
                        >
                            <oInput
                                @clicked="toggleWordSelection(word.index)"
                                :label="word.word"
                                v-model="word.selected"
                                type="checkbox"
                                :button-mode="true"
                                style="display: inline-flex"
                            />
                        </div>
                        <Spacer height="0.75rem" />
                        <Label>Select Match Type</Label>
                        <div
                            v-for="matchType in keywordMatchTypes"
                            v-bind:key="matchType.index"
                            class="match-type-special-toggle"
                        >
                            <div
                                class="match-type-special-toggle-content"
                                @click="toggleMatchType(matchType.index)"
                                tabindex="0"
                            >
                                <div
                                    class="match-type-special-toggle-radio"
                                    :class="[matchType.selected ? 'active' : '']"
                                />
                                <Spacer width="0.75rem" height="0.25rem" />
                                <Text as="span" size="f-8">{{ matchType.matchType }}</Text>
                            </div>
                        </div>
                        <div>
                            <Spacer height="0.75rem" />
                            <Label>Bid (Set From Source Keyword)</Label>
                            <div v-if="keywordBid > 0" class="source-keyword-bid-container">
                                <Money :value="keywordBid" :currency="currencyCode" />
                            </div>
                            <span v-else>
                                <div class="source-keyword-bid-container">N/A</div>
                                <Spacer height="0.75rem" />
                                <Text size="f-9" color="gray">
                                    Note: A specific bid cannot be applied to this keyword. This is
                                    usually because the bid has been set at the ad group level, or
                                    because the parent ad group uses smart bidding.
                                </Text>
                            </span>
                        </div>
                    </div>
                </div>
            </ImprovementContent>
            <ImprovementContent>
                <div v-if="!negativeKeywordListsLoading" v-show="addingNegativeKeyword">
                    <Text as="h3" size="f-4" weight="600">Negative Keyword List</Text>
                    <Spacer height="2rem" />
                    <div class="negative-keyword-list-container">
                        <oInput
                            v-model="selectedNegativeKeywordListId"
                            v-if="selectedNegativeKeywordListId"
                            label="Shared Negative List"
                            :items="negativeKeywordLists"
                            type="select"
                        />
                        <Text as="p" size="f-8" v-else>
                            You don't have any shared negative lists, so Opteo will automatically
                            create a new one called
                            <b>Keyword negatives for search campaigns (auto_gen_general)</b>.
                        </Text>
                    </div>
                </div>

                <span v-if="!campaignsAndAdGroupsLoading" v-show="!addingNegativeKeyword">
                    <Text as="h3" size="f-4" weight="600">Keyword Destination</Text>
                    <Spacer height="2rem" />
                    <div class="keyword-destination-container">
                        <oInput
                            v-model="selectedCampaignId"
                            label="Destination Campaign"
                            :items="campaigns"
                            type="select"
                        />
                        <Spacer height="2rem" />
                        <oInput
                            v-model="selectedAdGroupId"
                            label="Destination Ad Group"
                            :items="adGroups"
                            type="select"
                        />
                    </div>
                </span>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts" setup>
import {
    EntityPill,
    oButton,
    oInput,
    oTable,
    Spacer,
    Number,
    Money,
    Label,
    Text,
    Tabs,
    CircleKeywordIcon,
    CircleNegativeKeywordIcon,
} from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import SerpPreview from '@/components/improvement/SerpPreview.vue'
import { useCheckQueryRelevance } from '@/composition/improvement/types/useCheckQueryRelevance.ms'
import useMediaQuery from '@/composition/global/useMediaQuery'

const { aboveMobile, aboveSmallMobile } = useMediaQuery()
const {
    // REQUIREMENTS
    title,
    adjustSteps,
    pushDataStillLoading,
    pushActionText,
    pushMessages,
    lastUpdated,
    onPush,
    // DATA
    addingNegativeKeyword,
    searchQuery,
    keywordBid,
    metrics,
    currencyCode,
    serpImageUrl,
    // KEYWORD
    tableHeaders,
    tableItems,
    // KEYWORD ADJUSTMENT
    showKeywordSelection,
    toggleShowKeywordSelection,
    changeKeywordType,
    searchTermWords,
    toggleWordSelection,
    keywordMatchTypes,
    toggleMatchType,
    previewKeyword,
    // NEGATIVE KEYWORD LISTS
    negativeKeywordListsLoading,
    negativeKeywordLists,
    selectedNegativeKeywordListId,
    // CAMPAIGNS AND AD GROUPS
    campaignsAndAdGroupsLoading,
    campaigns,
    selectedCampaignId,
    adGroups,
    selectedAdGroupId,
} = useCheckQueryRelevance()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.keyword-destination-inactive-warning {
    @include container;
    @include ph-24;
    @include pv-20;
}

// Create Keyword Form
.create-keyword-form {
    @include container;
    @include relative;
    min-height: 18.125rem;
    @include flex;
    @include items-center;
    @include justify-center;
    flex-direction: column;
}
.create-keyword-form-preview {
    @include flex;
    @include items-center;
    @include mv-64;
}
.modify-keyword-button {
    @include flex-center;
}
.modify-keyword-section {
    border-top: 1px solid $opteo-light-gray;
    width: 100%;
    @include pa-32;
}
.search-term-word-checkbox {
    display: inline-block;
    @include mr-12;
    @include mb-12;
}

.match-type-special-toggle {
    box-shadow: $opteo-shadow;
    @include br-8;
    @include inline-block;
    @include mr-12;
    @include mb-12;
    cursor: pointer;
}
.match-type-special-toggle-content {
    @include flex;
    @include items-center;
    @include ph-20;
    @include pv-16;
    text-transform: capitalize;
    @include br-8;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
.match-type-special-toggle-content:focus {
    box-shadow: $opteo-blue-shadow 0 0 0 3px;
}
.match-type-special-toggle-radio {
    width: 18px;
    height: 18px;
    box-shadow: $opteo-shadow;
    @include bg-opteo-white;
    @include br-999;
    @include relative;
}
.match-type-special-toggle-radio.active {
    @include bg-opteo-blue;
}
.match-type-special-toggle-radio.active::after {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @include bg-opteo-white;
    @include br-999;
}

.source-keyword-bid-container {
    @include inline-flex;
    @include items-center;
    @include pl-20;
    @include pr-32;
    @include pv-16;
    @include br-8;
    background-color: #f7f7f9;
    box-shadow: $opteo-shadow;
    line-height: 1.125rem;
    color: rgba(01, 01, 05, 0.24);
    @include f-8;
}

.negative-keyword-list-container,
.keyword-destination-container {
    @include container;
    @include pa-32;
}

@media screen and (max-width: $mq-767-max) {
    .modify-keyword-section {
        @include pa-24;
    }
    .keyword-destination-container {
        @include pa-24;
    }
    .negative-keyword-list-container {
        @include pa-24;
    }
}
.no-bid-info-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0%, -50%);
    z-index: 4;
}
</style>
