<template>
    <Panel :modelValue="renderPanel" @close="closePanel" :title="title" :width="width">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]="slotProps">
            <slot :name="slotName" v-bind="slotProps"></slot>
        </template>
    </Panel>
</template>

<script lang="ts" setup>
import { Panel } from '@opteo/components-next'

import { computed, watch } from 'vue'

const props = defineProps<{
    width: number
    title: string
    loading?: boolean
    showPanel: boolean
    closePanel: () => void
}>()

const emit = defineEmits<{
    breakdownRequested: []
}>()

const showPanel = computed(() => props.showPanel)
const renderPanel = computed(() => props.showPanel && !props.loading)

watch(showPanel, renderPanel => {
    renderPanel && emit('breakdownRequested')
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaign-breakdown-container {
    @include container;
    @include br-28;
    @include pa-36;
    @include w-100;
    @include h-100;
    @include relative;

    .loading-container {
        @include flex-center;
        @include container;
        @include pa-20;
        min-height: 3.625rem;
        // container height minus header + padding
        height: calc(100% - (44px + 36px));
    }
}
</style>
