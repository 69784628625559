<template>
    <div :class="[{ wide: wide }, 'coming-soon']">
        <OpteoLogo :width="44" :height="44" />
        <Spacer height="2rem" />
        <div class="coming-soon-container">
            <Text as="p" size="f-8">
                <slot />
            </Text>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Spacer, OpteoLogo, Text } from '@opteo/components-next'

export default defineComponent({
    name: 'MobileSplashContainer',
    components: {
        Spacer,
        OpteoLogo,
        Text,
    },
    props: {
        wide: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.coming-soon {
    position: fixed;
    @include inset;
    z-index: 2147483647;
    @include w-100;
    @include flex-center;
    flex-direction: column;
    background-color: white;
    @include ph-16;
}
.coming-soon-container {
    @include container;
    @include ph-32;
    @include pv-28;
    max-width: 21.375rem;
    margin: 0 auto;
}
@media screen and (min-width: 768px) {
    .coming-soon.wide {
        @include flex;
        z-index: 2147483647;
    }
    .coming-soon {
        display: none;
    }
}
@media screen and (min-width: 1200px) {
    .coming-soon.wide {
        display: none;
    }
}
</style>
