// @ts-nocheck
const initialize = (window, document, i = 'canny-jssdk', s = 'script') => {
    function l() {
        if (!document.getElementById(i)) {
            var f = document.getElementsByTagName(s)[0],
                e = document.createElement(s)
            ;(e.type = 'text/javascript'),
                (e.async = !0),
                (e.src = 'https://canny.io/sdk.js'),
                f.parentNode.insertBefore(e, f)
        }
    }
    if ('function' != typeof window.Canny) {
        var c = function () {
            c.q.push(arguments)
        }
        ;(c.q = []),
            (window.Canny = c),
            'complete' === document.readyState
                ? l()
                : window.attachEvent
                ? window.attachEvent('onload', l)
                : window.addEventListener('load', l, !1)
    }
}

export default initialize
