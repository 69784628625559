<template>
    <div v-if="!loading && schedules.length > 0">
        <div v-for="(schedule, index) in formattedSchedules" :key="index" class="schedule-item">
            <div class="schedule-info">
                <CheckIcon />
                <div class="db pl3">
                    <Text as="h5" class="report-schedule-title">
                        {{ schedule.title }}
                    </Text>
                    <div class="schedule-date">
                        <Text size="f-9" color="gray">
                            Next scheduled {{ schedule.formattedNextRunTs }}
                        </Text>
                        <Tooltip
                            :content="schedule.interval_amount === 2 ? 'Bimonthly' : 'Monthly'"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                style="padding-left: 2px; padding-top: 2px"
                            >
                                <g fill="none" fill-rule="evenodd">
                                    <path
                                        d="M8 0C3.58168075 0 0 3.58168075 0 8c0 4.4183193 3.58168075 8 8 8 4.4183193 0 8-3.5816807 8-8 .0001779-4.41831925-3.5816807-8-8-8z"
                                        fill="#D0D0D3"
                                    />
                                    <path
                                        d="M9.34927833 11.9091134c-.38812366.1529072-.69773193.2695258-.92898966.3498557-.23125774.0803299-.4997938.1205773-.80544334.1205773-.47043293 0-.83595873-.1146392-1.09641234-.3439175-.2607835-.2292784-.39109278-.5202474-.39109278-.8730722 0-.1372371.00973196-.2772784.02936082-.4204536.01962887-.1430103.05096907-.3046598.09402062-.4849484L6.736 8.54037113c.04338147-.16461853.07983507-.32032986.10919587-.46746393.02936086-.14696907.0442062-.28123707.0442062-.40263913 0-.2195464-.04519587-.37228867-.13558767-.45855674-.09039172-.08610306-.2632577-.1293196-.51876285-.1293196-.12569072 0-.25435052.01962887-.38614434.05872167-.13179381.03925773-.2447835.07637113-.33896906.11167013l.12948453-.52915466c.31785567-.12931959.62202062-.24.9123299-.33220619.29030929-.09204124.56511342-.13806185.82408249-.13806185.46696906 0 .82705153.11282474 1.08008246.33797938.25303094.22548452.3795464.51843299.3795464.87901032 0 .07455667-.0089072.20585567-.02655673.39389687-.01764947.18804127-.04997933.36074227-.09698967.51744333L8.2284536 10.092701c-.03942267.1372371-.0748866.2939382-.1062268.470433-.0313402.1764949-.04717527.3096083-.04717527.3998351 0 .227299.05080414.3821856.15257734.4644948.1017732.0823093.27793813.1235464.52849486.1235464.11727834 0 .25039174-.0206186.39917527-.0616907.14861853-.0412371.25632987-.0773609.32296907-.108866l-.12898974.5286598zM9.264 4.96247423c-.22548453.20964948-.49682473.3145567-.81435053.3145567-.31752574 0-.5900206-.10474227-.8173196-.3145567-.22729894-.20964949-.3409484-.46350516-.3409484-.76140206 0-.29789691.11364946-.55274227.3409484-.76437114.227299-.21162886.49979386-.31752577.8173196-.31752577.3175258 0 .588866.10589691.81435053.31752577.2253196.21162887.33814433.46647423.33814433.76437114 0 .2978969-.11282473.55175257-.33814433.76140206z"
                                        fill="#FFFFFF"
                                    />
                                </g>
                            </svg>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <oButton
                id="save-schedule"
                @clicked="emit('cancel-schedule', schedule.schedule_id)"
                color="white"
                size="small"
            >
                Cancel
            </oButton>
        </div>
    </div>
    <div v-else-if="!loading && schedules.length === 0" class="schedules-empty-state">
        <Text size="f-8" style="line-height: 0.875rem">No schedules currently set</Text>
    </div>
    <div v-else>
        <div class="schedule-skeleton">
            <Skeleton :height="14" />
        </div>
    </div>
</template>
<script lang="ts">
import { computed, PropType } from 'vue'
import { Reports } from '@opteo/types'
import { oInput, oButton, Spacer, Text, Label, Tooltip, CheckIcon } from '@opteo/components-next'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import Skeleton from '@/components/util/Skeleton.vue'

export default {
    name: 'reports',
    components: {
        oInput,
        oButton,
        Spacer,
        Text,
        Label,
        Tooltip,
        CheckIcon,
        Skeleton,
    },
    props: {
        schedules: {
            type: Array as PropType<Reports.Core.ReportSchedule[] | undefined>,
            required: false,
        },
    },
    emits: ['cancel-schedule'],
    setup(props, { emit }) {
        const loading = computed(() => !props.schedules)
        const formattedSchedules = computed(() => {
            return (props.schedules ?? []).map(schedule => {
                if (!schedule.next_run_ts) {
                    throw new Error('does this actually ever happen?')
                }

                return {
                    ...schedule,
                    formattedNextRunTs: format(parseISO(schedule.next_run_ts), `do 'of' MMMM yyyy`),
                }
            })
        })

        return {
            emit,
            loading,
            formattedSchedules,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.schedule-item {
    @include container;
    @include pa-24;
    display: grid;
    grid-template-columns: 1fr auto;
    row-gap: 1rem;
    grid-gap: 0.5rem;
    align-items: center;
}
.schedule-item:not(:last-child) {
    @include mb-16;
}
.schedule-info {
    display: flex;
    align-items: center;
}

.report-schedule-title {
    font-size: 1.0625rem;
    letter-spacing: -0.025rem;
    @include fw-600;
    @include mb-4;
    line-height: 1.125rem;
}
.schedule-date {
    display: flex;
    align-items: center;
    line-height: 1.25rem;
}
.schedule-date svg {
    @include ml-4;
}

.schedules-empty-state {
    @include container;
    @include pa-24;
}

.schedule-skeleton {
    @include container;
    @include pa-24;
}
</style>
