<template>
    <Modal
        v-model="isUserBlocked"
        :closeButton="false"
        :drawer="false"
        title="Plan Limit Exceeded"
        width="578px"
    >
        <template #content>
            <Text v-if="isAdmin">
                Your total monthly spend increased recently, exceeding your plan limit. You are
                currently subscribed to the <b>{{ currentPlan?.name }}</b> plan, which includes up
                to
                <span v-if="currentPlan?.account_limit">
                    <b>{{ currentPlan?.account_limit }}</b>
                    linked accounts and
                </span>
                <b><Money :value="currentPlan?.spend_limit" currency="USD" /></b>
                total spend per month.
                <Spacer height="1rem" />
                You currently have
                <Text
                    as="span"
                    weight="600"
                    :color="totalActiveAccounts > currentPlan?.account_limit ? 'red' : 'black'"
                >
                    {{ totalActiveAccounts }}
                </Text>
                linked accounts and
                <Text
                    as="span"
                    weight="600"
                    :color="totalSpend30d > currentPlan?.spend_limit ? 'red' : 'black'"
                >
                    <Money :value="totalSpend30d" currency="USD" />
                </Text>
                total spend per month. Upgrade your plan or unlink some accounts to continue.
            </Text>
            <Text v-else>
                Your total spend has increased this month, exceeding your pricing plan limit. You
                are subscribed to the
                <b>{{ currentPlan?.name }}</b> plan, which includes up to
                <span v-if="currentPlan?.account_limit">
                    <b>{{ currentPlan?.account_limit }}</b> accounts and
                </span>
                <b><Money :value="currentPlan?.spend_limit" currency="USD" /></b> total spend. You
                have <b>{{ totalActiveAccounts }}</b> accounts linked to Opteo with a total spend of
                <b><Money :value="totalSpend30d" currency="USD" /></b>. <br /><br />
                Contact your account admin ({{ adminEmail }}) to upgrade your pricing plan, or
                unlink an account in Linked Accounts.
            </Text>
        </template>
        <template #buttons>
            <oButton color="white" size="medium" @clicked="goToLinkedAccounts">
                Go to Linked Accounts
            </oButton>
            <Spacer width="0.75rem" />
            <oButton color="blue" size="medium" v-if="isAdmin" @clicked="goToBillingCentre">
                Go to Billing Centre
            </oButton>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue'
import { useUser } from '@/composition/user/useUser'
import { Modal, oButton, Text, Money, Spacer, WarnIcon } from '@opteo/components-next'
import { useRoute, useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { StorageSerializers, useLocalStorage } from '@vueuse/core'
import { LS_OVER_LIMIT } from '@/lib/cookies'
import { ENFORCEMENT } from '@/lib/env'

const enforcementVar = ENFORCEMENT

if (typeof enforcementVar === 'undefined') throw new Error('No env variable')

const enforcement = +enforcementVar

const route = useRoute()
const router = useRouter()

const { user, isAdmin, currentPlan, totalActiveAccounts, totalSpend30d, userInfo } = useUser()

const adminEmail = computed(() => user.value?.profile.admin_email)

const isUserBlocked = computed(() => {
    if (
        route.path.includes('linkedaccounts') ||
        route.path.includes('billing') ||
        route.path.includes('newpaymentmethod') ||
        route.path.includes('init')
    ) {
        return false
    }
    return !!enforcement && user.value?.profile.over_limit
})

if (isUserBlocked.value && !route.path.includes('accountcentre')) {
    goToAccountCentre()
}

const overLimit = useLocalStorage<number>(LS_OVER_LIMIT, enforcement, {
    serializer: StorageSerializers.number,
})

overLimit.value = isUserBlocked.value ? 1 : 0

watch([overLimit, isUserBlocked], () => {
    if (!enforcement) return

    overLimit.value = isUserBlocked.value ? 1 : 0
})

function goToLinkedAccounts() {
    router.push({ name: Routes.LinkedAccounts })
}

function goToBillingCentre() {
    router.push({ name: Routes.BillingCentre })
}

function goToAccountCentre() {
    router.push({ name: Routes.AccountCentre })
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
</style>
