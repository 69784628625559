export enum ProvideKeys {
    CurrencyCode = 'domainCurrencyCode',
    PerformanceExtendedStartDate = 'performanceExtendedStartDate',
    PerformanceStartDate = 'performanceStartDate',
    PerformanceEndDate = 'performanceEndDate',
    PerformanceDateDifference = 'performanceDateDifference',
    PerformanceViewOption = 'performanceViewOption',
    PerformanceSidebarOpen = 'performanceSidebarOpen',
    ActiveImprovementList = 'activeImprovementList',
    BatchBox = 'batchBox',
    ObfuscateEntityPills = 'obfuscateEntityPills',
}
