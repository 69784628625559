<template>
    <div class="adjust-device-bid-cell">
        <Popout
            v-model="popoutOpen"
            placement="bottom-start"
            :externalToggleButton="true"
            :offset="aboveMobile ? [-16, 12] : [8, 12]"
            maxWidth="13.75rem"
        >
            <div class="cell-value">
                <!-- No Bid Change -->
                <NotApplicablePill
                    v-if="!diplayedValue"
                    content="No bid modifier will be set"
                    :icon="false"
                />

                <!-- Bid Change -->
                <Text
                    v-else
                    as="span"
                    :color="bidDirection === 'increase' ? 'green' : 'red'"
                    weight="500"
                >
                    <Percent include-sign :value="diplayedValue" />
                </Text>

                <!-- Edit Bid Button -->
                <oButton
                    color="white"
                    class="adjust-bid-edit-icon"
                    :circle="true"
                    size="x-small"
                    @clicked="popoutOpen = !popoutOpen"
                >
                    <TableEdit style="transform: translateY(2px) !important" />
                </oButton>
            </div>
            <template #content>
                <oForm @submit="popoutOpen = false">
                    <div class="popout-content">
                        <div class="popout-inputs">
                            <div style="width: 4.25rem">
                                <oInput
                                    name="bidDirection"
                                    v-model="bidDirection"
                                    :items="[
                                        { value: 'increase', label: '+' },
                                        { value: 'decrease', label: '-' },
                                    ]"
                                    type="select"
                                    @updated="clampPercentage"
                                />
                            </div>
                            <Spacer width="0.75rem" />
                            <div style="width: 7.25rem">
                                <oInput
                                    name="bidChange"
                                    ref="bidChangeRef"
                                    type="number"
                                    v-model="inputValue"
                                    min="0"
                                    max="500"
                                    @updated="clampPercentage"
                                    autocomplete="off"
                                    suffix="%"
                                />
                            </div>
                        </div>
                        <Spacer height="0.75rem" />
                        <div class="popout-input-note">
                            <Text size="f-9">
                                <b>Note:</b> If left empty, no bid adjustment will be applied.
                            </Text>
                        </div>
                    </div>
                </oForm>
            </template>
        </Popout>
    </div>
</template>
<script lang="ts">
import {
    oInput,
    EntityPillList,
    Spacer,
    Text,
    Percent,
    Popout,
    oButton,
    TableEdit,
    oForm,
} from '@opteo/components-next'
import NotApplicablePill from './NotApplicablePill.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { ref, PropType, watch } from 'vue'
import Ent from '@/components/util/Ent.vue'
import { computed } from '@vue/reactivity'
import { delay } from '@opteo/promise'

interface AdjustableBid {
    value: number
    id: number
    current: number
    invalid?: boolean
    reason?: string
}
export default {
    name: 'AdjustBidCell',
    components: {
        oInput,
        Ent,
        EntityPillList,
        Spacer,
        Text,
        Percent,
        Popout,
        oButton,
        TableEdit,
        NotApplicablePill,
        oForm,
    },
    props: {
        bid: {
            type: Object as PropType<AdjustableBid>,
            required: true,
        },
    },
    emits: ['updated'],
    setup(props, { emit }) {
        const popoutOpen = ref(false)
        const bidChangeRef = ref()

        const { value, id, current } = props.bid

        const bidDirection = ref<'decrease' | 'increase'>(value < 0 ? 'decrease' : 'increase')

        // This number needs to be in the % (i.e. 50 for 50%) to display correctly in the oInput
        const inputValue = ref(Math.abs(Math.round(value * 10_000) / 100))

        // Vluae used in the percent components and emited on change
        const diplayedValue = computed(() => {
            if (bidDirection.value === 'decrease') {
                return -inputValue.value / 100
            }

            return inputValue.value / 100
        })

        watch(popoutOpen, async open => {
            if (open) {
                await delay(10)

                bidChangeRef.value?.inputRef.focus()
            } else {
                const value = diplayedValue.value

                emit('updated', { value, id })
            }
        })

        // maybe this is worth having in oInput?
        const clampPercentage = () => {
            // only allow user to write a value between min and max, 0 and 500 in this case
            const numberMin = 0

            if (inputValue.value <= numberMin) {
                inputValue.value = numberMin
                return
            }

            // When dealing with negative value, max is 100%
            const numberMax = bidDirection.value === 'increase' ? 500 : 100

            if (inputValue.value >= numberMax) {
                inputValue.value = numberMax
                return
            }
        }

        return {
            popoutOpen,
            bidDirection,
            inputValue,
            bidChangeRef,
            clampPercentage,
            diplayedValue,
            ...useMediaQuery(),
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.adjust-device-bid-cell {
    position: relative;
}
.popout-content {
    @include pa-16;
}
.popout-input-note {
    @include container;
    @include ph-16;
    @include pv-12;
}
.popout-inputs {
    display: flex;
    align-items: center;
}
.cell-value {
    position: relative;
}
:deep(.o-button.o-button--circle.adjust-bid-edit-icon) {
    width: 24px;
    height: 24px;
    padding: 0;
    position: absolute;
    right: -0.5rem;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0.75rem;
}
:deep(.o-button.o-button--circle.adjust-bid-edit-icon:active) {
    transform: translateY(-49%);
}
.hidden-buttons {
    // @include flex;
    // @include items-center;
    display: none;
}
</style>
