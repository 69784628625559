<template>
    <ImprovementAsyncView />
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import ImprovementAsyncView from '@/components/improvement/ImprovementAsyncView.vue'

export default defineComponent({
    name: 'ImprovementDismissed',
    components: {
        ImprovementAsyncView,
    },
})
</script>
