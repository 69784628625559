<template>
    <div class="hidden-slides-wrapper" ref="container">
        <Spacer height="3rem" />
        <header class="hidden-slides-header">
            <Text as="h4" size="f-5" weight="600">{{ slides.length }} Hidden Slides</Text>
            <oButton
                color="white"
                size="small"
                @clicked="restoreAllSlides"
                :disabled="!atLeastOneRestorableSlide"
                >Reactivate All</oButton
            >
        </header>
        <Spacer />
        <div ref="header" class="hidden-slides-container">
            <HelpLink :arrow="false" :clickable="false">
                Slides can be hidden for a number of reasons. Usually slides are hidden because of
                your template. Slides can also be hidden because Opteo doesn't have enough data to
                generate that slide type.
            </HelpLink>
            <Spacer v-if="slides.length > 0" />
            <!-- HIDDEN SLIDES -->
            <!-- <div v-if="open" class="w-100 center pa-24"> -->
            <div v-for="slide in slides" :key="slide.slide_id" class="hidden-slide">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11Z"
                        stroke="#E0E0E5"
                        stroke-width="2"
                    />
                </svg>
                <div>
                    <Text as="h5" size="f-7" weight="500">{{ slide.title }}</Text>
                    <Spacer height="0.25rem" />
                    <div v-if="!slide.restorable" class="reason">
                        <Text size="f-9" color="gray">No Data Available</Text>
                        <Tooltip :content="slide.reason" :max-width="999">
                            <!-- <img class="info-circle-icon" src="@/assets/img/info-circle-icon.svg" /> -->
                            <img class="info-circle-icon" src="@/assets/img/icon-info.svg" />
                        </Tooltip>
                    </div>
                    <Text v-if="slide.restorable" size="f-9" color="gray"> Removed </Text>
                </div>
                <div class="flex items-center">
                    <oButton
                        @clicked="restoreSlide(slide)"
                        color="white"
                        size="small"
                        :disabled="!slide.restorable"
                    >
                        Reactivate Slide
                    </oButton>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { oButton, Text, Tooltip, HelpLink, Spacer } from '@opteo/components-next'

interface HiddenSlide {
    slideId: string
    reason?: string
    restorable: boolean
    title: string
}

export default {
    name: 'ReportHiddenSlides',
    components: { Text, Tooltip, HelpLink, oButton, Spacer },
    props: {
        slides: {
            type: Array as PropType<HiddenSlide[]>,
            required: true,
        },
        domainColor: {
            type: String,
            required: true,
        },
    },
    emits: ['restore-slide', 'restore-all-slides'],
    setup(props, { emit }) {
        function restoreSlide(slide: HiddenSlide) {
            emit('restore-slide', slide.slideId)
        }

        function restoreAllSlides() {
            emit('restore-all-slides')
        }

        const atLeastOneRestorableSlide = computed(() => {
            return props.slides.some(slide => slide.restorable)
        })

        return {
            restoreSlide,
            restoreAllSlides,
            atLeastOneRestorableSlide,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.hidden-slides-container {
    @include container;
    @include pa-32;
    @include br-20;
    width: 100%;
    max-width: 604px;
}
.hidden-slides-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hidden-slide {
    @include container;
    @include pa-24;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 1.5rem;
    align-items: center;
}
.hidden-slide:not(:last-child) {
    margin-bottom: 1rem;
}
.hidden-slide-container .hidden-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hidden-slide-container header {
    z-index: 10;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}
.reason {
    display: flex;
    align-items: center;
}
.info-circle-icon {
    margin-top: 2px;
    margin-left: 0.125rem;
    padding-left: 0.25rem;
    width: 1.25rem;
}
</style>
