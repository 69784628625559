<template>
    <Tooltip
        content="Coming Soon"
        :placement="aboveiPadLandscape ? 'right' : 'top'"
        :offset="aboveiPadLandscape ? [0, 8] : [0, -4]"
    >
        <div :tabindex="0" class="with-google">
            <svg
                class="with-google-icon"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill="#4285F4"
                    d="M23.745 12.27c0-.79-.07-1.54-.19-2.27h-11.3v4.51h6.47c-.29 1.48-1.14 2.73-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82Z"
                />
                <path
                    fill="#34A853"
                    d="M12.255 24c3.24 0 5.95-1.08 7.93-2.91l-3.86-3c-1.08.72-2.45 1.16-4.07 1.16-3.13 0-5.78-2.11-6.73-4.96h-3.98v3.09C3.515 21.3 7.565 24 12.255 24Z"
                />
                <path
                    fill="#FBBC05"
                    d="M5.525 14.29c-.25-.72-.38-1.49-.38-2.29s.14-1.57.38-2.29V6.62h-3.98a11.86 11.86 0 0 0 0 10.76l3.98-3.09Z"
                />
                <path
                    fill="#EA4335"
                    d="M12.255 4.75c1.77 0 3.35.61 4.6 1.8l3.42-3.42C18.205 1.19 15.495 0 12.255 0c-4.69 0-8.74 2.7-10.71 6.62l3.98 3.09c.95-2.85 3.6-4.96 6.73-4.96Z"
                />
            </svg>
            <Text as="p">{{ string }}</Text>
        </div>
    </Tooltip>
</template>

<script>
import { Text, Spacer, Tooltip } from '@opteo/components-next'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    components: {
        Text,
        Spacer,
        Tooltip,
    },
    props: {
        string: {
            type: String,
            required: true,
            default: 'Default String',
        },
    },
    setup() {
        const { aboveiPadLandscape } = useMediaQuery()

        return {
            aboveiPadLandscape,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.with-google {
    @include flex;
    @include items-center;
    @include justify-center;
    @include no-select;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    box-shadow: $opteo-shadow;
    border-radius: 999rem;
    // cursor: pointer;
    cursor: default;
    border: none;
    outline: none;
    text-decoration: none;
    transition: transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1), color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
}

.with-google-icon {
    width: 1.25rem;
    height: 1.25rem;
    @include mr-12;
}
.with-google p {
    @include f-8;
    opacity: 0.24;
}
</style>
