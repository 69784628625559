<template>
    <div class="history-item-footer">
        <Text style="display: flex; font-size: 0.75rem" size="f-9">
            <span style="text-transform: capitalize">
                {{ Gads.enums.KeywordMatchType[matchType].toLowerCase() }} </span
            >&nbsp;negative added to&nbsp;
            <b
                >{{ totalDestinations }}
                {{ pluralise(totalDestinations, 'location', 'locations') }}</b
            >
            <Popout
                v-model="locationsPopoutOpen"
                :offset="[0, 8]"
                :borderRadius="22"
                placement="top"
                trigger="mouseenter"
                max-width="300px"
                wrapperClasses="locations-popout"
                :zIndex="9999999999999"
                :delay="[200, 0]"
            >
                <img class="info-circle-icon" src="@/assets/img/icon-info.svg" />
                <template #content>
                    <EntityPillList :items="entityPillListItems" />
                </template>
            </Popout>
            on {{ formatedDate }}
        </Text>
    </div>
</template>

<script lang="ts" setup>
import formatDate from 'date-fns/format'

import { Text, Popout, EntityPillList } from '@opteo/components-next'
import { computed, ref } from 'vue'
import { pluralise } from '@/lib/globalUtils'
import { Gads, Improvement, NgramTool } from '@opteo/types'

const props = defineProps<{
    matchType: Gads.enums.KeywordMatchType
    negativeKeywordsDestinations: NgramTool.NegativeKeywordsDestinations
    date: Date
}>()

const locationsPopoutOpen = ref(false)

const totalDestinations = computed(
    () =>
        props.negativeKeywordsDestinations.adGroups.length +
        props.negativeKeywordsDestinations.campaigns.length +
        props.negativeKeywordsDestinations.sharedSets.length +
        (props.negativeKeywordsDestinations.accountLevel ? 1 : 0)
)

const entityPillListItems = computed(() => {
    const items = [
        ...props.negativeKeywordsDestinations.adGroups.map(adGroup => ({
            type: Improvement.LocationEntity.AdGroup,
            content: adGroup.adGroupName,
        })),
        ...props.negativeKeywordsDestinations.campaigns.map(campaign => ({
            type: Improvement.LocationEntity.Campaign,
            content: campaign.campaignName,
        })),
        ...props.negativeKeywordsDestinations.sharedSets.map(sharedSet => ({
            type: Improvement.LocationEntity.NegativeList,
            content: sharedSet.sharedSetName,
        })),
    ]

    if (props.negativeKeywordsDestinations.accountLevel) {
        items.push({
            type: Improvement.LocationEntity.AccountLevel,
            content: 'Account',
        })
    }

    return items
})

const formatedDate = formatDate(new Date(props.date), 'do LLLL yyyy')
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.history-item-footer {
    padding: 0.875rem 1.25rem;
    border-top: 1px solid;
    @include opteo-border;
}
.info-circle-icon {
    margin-top: 2px;
    margin: 0rem 0.25rem;
    width: 0.875rem;
}

:deep(.locations-popout) {
    display: inline-flex;
}
</style>
