<template>
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1022_16992)">
            <path
                d="M9 1 5 5 1 1"
                stroke="#CACAD3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1022_16992"><path fill="#fff" d="M0 0h10v6H0z" /></clipPath>
        </defs>
    </svg>
</template>
