export const getDomainFromUrl = (url: string, keep_www = false) => {
    if (!url) {
        return ''
    }

    if (typeof url !== 'string') {
        throw new Error('URL must be of type string')
    }

    let domain = url

    if (domain.includes('//')) {
        domain = domain.split('//')[1]
    }

    if (!keep_www && domain.includes('www.')) {
        domain = domain.split('www.')[1]
    }

    domain = domain.split('/')[0]
    domain = domain.split(':')[0]
    domain = domain.split('?')[0]
    domain = domain.split('#')[0]

    return domain
}

export function getCharCount(char_string: string) {
    if (!char_string || char_string === '--') {
        return 0
    }

    return stripAdCustomisers(char_string).length
}

export const stripAdCustomisers = (s: string) => {
    // This regex replaces anything between `{` and `} with either the default text or an empty string
    const string_without_ad_customisers = s.replace(/\{[^}]*\}*/g, ad_mod => {
        // for each modifier, pluck out default text if it exists (usually after a `:`)
        if (ad_mod.toLowerCase().includes('{=if')) {
            try {
                const inside_if_statement = ad_mod.slice(4, -1) // if statements are of the form {=IF(device=mobile, text to insert):default text}
                const insert_text = inside_if_statement.split(',')[1].split(')')[0].trim() // text between `,` and `)`
                const default_text = (inside_if_statement.split(':')[1] || '').trim() // text between `:` and `}` (may not exist)
                return insert_text.length > default_text.length ? insert_text : default_text
            } catch (e) {
                console.warn(
                    'unable to parse if statement in ad customiser, defaulting to default text',
                    s
                )
            }
        }
        if (ad_mod.toLowerCase().includes('{=countdown')) {
            // usually of the form `{=COUNTDOWN("2020/10/9 12:30:00","en-US",5)})`
            return ''
        }
        return (ad_mod.slice(1, -1).split(':')[1] || '').trim() // text after `:`
    })

    return string_without_ad_customisers
}
