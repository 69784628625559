<template>
    <section class="chat-response-wrapper">
        <div class="chat-response">
            <div class="icon">
                <ChatIcon />
            </div>
            <div class="response">
                <ChatMarkdown :source="displayedResponse" />
            </div>
        </div>
    </section>
</template>

<script lang="ts">
// Vue
import { defineComponent, onMounted, ref } from 'vue'

// Local components
import ChatIcon from '@/components/chat/ChatIcon.vue'
import ChatMarkdown from '@/components/chat/ChatMarkdown'

export default defineComponent({
    name: 'ChatResponse',
    props: {
        response: {
            type: String,
            required: true,
        },
        stream: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        // Local components
        ChatIcon,
        ChatMarkdown,
    },
    emits: ['characters-added'],
    setup(props, { emit }) {
        const displayedResponse = ref('')

        const streamText = async (text: string, stream: boolean) => {
            if (stream) {
                const getRandomInt = (min: number, max: number) => {
                    return Math.floor(Math.random() * (max - min + 1)) + min
                }

                const minChars = 1 // Minimum number of characters typed at once
                const maxChars = 6 // Maximum number of characters typed at once
                const minDelay = 4 // Minimum delay in milliseconds
                const maxDelay = 64 // Maximum delay in milliseconds

                for (let i = 0; i <= text.length; ) {
                    const numChars = getRandomInt(minChars, maxChars)
                    const delay = getRandomInt(minDelay, maxDelay)

                    for (let j = 0; j < numChars && i <= text.length; j++) {
                        const char = text.charAt(i)
                        displayedResponse.value += char
                        i++

                        emit('characters-added') // Used by parent to scroll to bottom
                    }

                    const caret = '|' // Display caret at end of text
                    displayedResponse.value += caret

                    await new Promise(resolve => setTimeout(resolve, delay))
                    displayedResponse.value = displayedResponse.value.slice(0, -1) // Remove caret
                }
            } else {
                displayedResponse.value = text
            }
        }

        onMounted(async () => {
            await streamText(props.response, props.stream) // Use response.value instead of props.response
        })
        return {
            displayedResponse,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Component
.chat-response {
    @include flex;
    @include items-start;
    color: $opteo-dark-blue;
    background-color: $opteo-dark-blue-translucent;
    @include br-24;
    @include relative;
    gap: 1.625rem;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
    width: 100%;
    min-height: 4.5rem;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem 1.875rem;
}
.icon {
    flex-shrink: 0;
    top: 1.0625rem;
    position: absolute;
    left: calc(-2.5rem - 1.5rem);
}
.response {
    letter-spacing: -0.08px;
    line-height: 1.625rem;
    width: 100%;
}

// Content formatting

// <hr>
:deep(.response hr) {
    border: none;
    border-bottom: 1px solid #0035ec14;
    margin-bottom: 1.125rem;
}

// <code> + <pre>
:deep(.response pre) {
    background-color: rgba(6, 6, 12, 1);
    @include mv-20;
    @include opteo-white;
    border-radius: 1.25rem !important;
    padding: 1.375rem 1.5rem !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: subpixel-antialiased;
}
:deep(.response pre code) {
    @include opteo-white;
    background-color: transparent !important;
    line-height: 1.5rem;
    font-size: 0.875rem;
}
:deep(.response code) {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: subpixel-antialiased;
    @include opteo-pink;
    font-size: 0.9375rem;
}

// <ol> + <li>
:deep(.response ol) {
    list-style: decimal;
    margin-left: 1.625rem;
    @include mv-16;
    @include f-7;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
}
:deep(.response ol li) {
    @include mv-12;
    @include f-7;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
    word-wrap: break-word;
}

// <ul> + <li>
:deep(.response ul) {
    list-style: disc;
    margin-left: 20px;
    @include mv-16;
    @include f-7;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
}
:deep(.response ul li) {
    @include mv-8;
    padding-left: 6px;
    @include f-7;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
    word-wrap: break-word;
}

// <p>
:deep(.response p) {
    @include f-7;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
    word-wrap: break-word;
    @include mb-16;
}

// <strong>
:deep(.response strong) {
    font-weight: 600;
    @include f-7;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
    word-wrap: break-word;
}

// <table>
:deep(.response .table-wrapper) {
    border: 1px solid #0035ec14;
    max-width: 100%;
    overflow-x: auto;
    @include br-16;
    @include mv-20;
}
:deep(.response .table-wrapper:only-child) {
    margin-bottom: 0.375rem !important;
    margin-top: 0.375rem !important;
}

:deep(.response table) {
    width: 100%;
    @include f-8;
    overflow: hidden;
    border-collapse: separate;
    max-width: 100%;
}
:deep(.response table th) {
    text-align: left;
    @include fw-500;
}
:deep(.response table td),
:deep(.response table th) {
    @include pv-12;
    @include ph-20;
    border-right: 1px solid #0035ec14;
    border-bottom: 1px solid #0035ec14;
    text-align: left !important;
}
:deep(.response table th) {
    white-space: nowrap;
}
:deep(.response table td:last-child),
:deep(.response table th:last-child) {
    border-right: none;
}
:deep(.response table tr:last-child td) {
    border-bottom: none;
}
:deep(.response table td strong),
:deep(.response table th strong),
:deep(.response table td b),
:deep(.response table th b) {
    @include f-8;
}

// Remove margins from first and last element
:deep(.response div *:first-child) {
    margin-top: 0;
}
:deep(.response div *:last-child) {
    margin-bottom: 0;
}
</style>
