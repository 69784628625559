import { computed } from 'vue'

import { Targets } from '@opteo/types'
import { useAccount } from '@/composition/account/useAccount'

import { useNGramAddNegatives } from './useNGramAddNegatives'
import { useNGramTool } from './useNGramTool'

export function useNGramHeaders() {
    const { performanceMode } = useAccount()
    const { addNegativesItemType, formattedItemType } = useNGramAddNegatives()
    const { currentStepIndex, performanceMaxActive, searchCampaignsActive, shoppingActive } =
        useNGramTool()

    const Header = {
        potentialSavings: {
            key: 'potentialSavings',
            text: 'Potential Savings',
            width: 192,
            vPadding: '0.875rem',
            sortable: true,
        },
        nScore: {
            key: 'nscoreSortValue',
            text: 'nScore',
            width: 98,
            vPadding: '0.875rem',
            sortable: true,
        },
        vsAvg: {
            key: 'vsAvg',
            text: 'vs Avg.',
            width: 112,
            vPadding: '0.875rem',
            sortable: true,
        },
        actions: {
            key: 'actions',
            text: 'Actions',
            width: 148,
            vPadding: '0.875rem',
            sortable: false,
        },
        text: {
            key: 'text',
            text: formattedItemType.value,
            vPadding: '0.875rem',
            sortable: true,
        },
    } as const

    // nGram Table Headers
    const cpaHeaders = [
        {
            key: 'cost',
            text: 'Cost',
            width: 102,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'conversions',
            text: 'Conv.',
            width: 94,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'cpa',
            text: 'CPA',
            width: 94,
            vPadding: '0.875rem',
            sortable: true,
        },
    ]

    const roasHeaders = [
        {
            key: 'cost',
            text: 'Cost',
            width: 102,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'conversionValue',
            text: 'C. Value',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'roas',
            text: 'ROAS',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
    ]

    const cpmHeaders = [
        {
            key: 'impressions',
            text: 'Impr.',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'conversions',
            text: 'Conv.',
            width: 94,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'cpm',
            text: 'CPM',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
    ]

    const vpmHeaders = [
        {
            key: 'impressions',
            text: 'Impr.',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'conversionValue',
            text: 'C. Value',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'vpm',
            text: 'VPM',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
    ]

    const relevantModeHeaders = computed(() => {
        if (performanceMaxActive.value) {
            if (performanceMode.value === Targets.PerformanceMode.CPA) return cpmHeaders
            return vpmHeaders
        }
        if (performanceMode.value === Targets.PerformanceMode.CPA) return cpaHeaders
        return roasHeaders
    })

    const getHeader = (
        header: keyof typeof Header,
        options?: {
            width?: number
            sortable?: boolean
        }
    ) => {
        if (!options) {
            Header[header]
        }

        return {
            ...Header[header],
            ...options,
        }
    }

    const entitySource = computed(() => {
        if (performanceMaxActive.value) {
            return 'campaignName'
        }

        if (shoppingActive.value) {
            return 'adGroupName'
        }

        return 'keywords'
    })

    /** For Search Terms. Can be: keyword source, ad group source, campaign source */
    const entitySourceHeader = computed(() => {
        const text = performanceMaxActive.value
            ? 'Campaign Source'
            : shoppingActive.value
              ? 'Ad Group Source'
              : 'Keyword Source'

        const sortTransform = searchCampaignsActive.value
            ? (keywordResourceNames: string[]) => keywordResourceNames.length
            : undefined

        return {
            /*
                TODO: instead of having a dynamic entitySource I think it would be best to have a more powerful
                sortTransform that can sort by row. This way we're not using dynamic v-slots in the Vue templates (#[`column.${entitySource}`])
                For the sake of simplicity and speed to release the n-gram fixes I'll leave it like this for now
            */
            key: entitySource.value,
            text,
            width: currentStepIndex.value === 1 ? 168 : 192,
            vPadding: '0.5rem',
            sortable: true,
            sortTransform,
        } as const
    })

    // N-Gram flow
    const needsSearchTermsHeaders = computed(() => addNegativesItemType.value === 'searchTerm')
    /**
     * If we are in the Add Negatives step the data is normalised to use a key `text` for both N-Grams and Search Terms
     */
    const needsTextHeader = computed(() => currentStepIndex.value === 1)

    /**
     * N-Gram flow headers. Used in the initial N-Gram selection and in the Add Negatives section.
     */
    const nGramToolHeaders = computed(() => {
        const mainHeader = needsTextHeader.value
            ? /**
               * Note that there's no value for `text` here, only the key `text`.
               * The logic to show "N-Gram" or "Search Term" is handled in the NGramAddNegatives component
               */
              generateTableMainHeader('text', '')
            : generateTableMainHeader('ngram', 'N-Gram')

        const uniqueNGramFlowHeaders = needsSearchTermsHeaders.value
            ? [entitySourceHeader.value]
            : [
                  getHeader('nScore', { width: 98, sortable: true }),
                  getHeader('potentialSavings', { width: 160, sortable: false }),
              ]

        return [
            mainHeader,
            ...relevantModeHeaders.value,
            getHeader('vsAvg', { width: 104, sortable: true }),
            ...uniqueNGramFlowHeaders,
            getHeader('actions', { width: 148, sortable: false }),
        ]
    })

    // Panel headers
    const uniquePanelHeader = computed(() =>
        addNegativesItemType.value === 'nGram'
            ? getHeader('potentialSavings', { width: 192, sortable: true })
            : entitySourceHeader.value
    )

    const panelItemHeaders = computed(() => [
        getHeader('text', { sortable: true }),
        ...relevantModeHeaders.value.map(header => ({
            ...header,
            width: 118,
        })),
        getHeader('vsAvg', { width: 112, sortable: true }),
        uniquePanelHeader.value,
    ])

    // Table headers
    const searchTermTableHeaders = computed(() => [
        {
            key: 'searchTerm',
            text: 'Search Term',
            vPadding: '0.875rem',
            sortable: true,
        },
        ...relevantModeHeaders.value,
        getHeader('vsAvg', { width: 112, sortable: true }),
        entitySourceHeader.value,
    ])

    return {
        entitySource,
        nGramToolHeaders,
        panelItemHeaders,
        relevantModeHeaders,
        searchTermTableHeaders,
    }
}

/**
 * @description Generate table main header. Used for both N-Grams and Search Terms tables in order to
 * keep consistency across both in terms of padding and sorting logic.
 * @param key
 * @param text
 * @returns A table header
 */
const generateTableMainHeader = (key: string, text: string) => {
    return {
        key,
        text,
        noPadding: true,
        vPadding: '0.875rem',
        sortable: false,
    }
}
