import { ref } from 'vue'

export function useTheme() {
    const darkMode = ref(false)

    const toggleDarkMode = () => {
        if (darkMode.value) {
            darkMode.value = false
            document.body.classList.remove('o-dark-mode')
        } else {
            darkMode.value = true
            document.body.classList.add('o-dark-mode')
        }
    }

    return {
        darkMode,
        toggleDarkMode,
    }
}
