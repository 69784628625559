import { computed, ref } from 'vue'

import { OnPushHandler, useImprovement } from '@/composition/improvement/useImprovement'
import { usePercent } from '@opteo/components-next'

import { KeywordBidsMS, Targets } from '@opteo/types'
import { checkImprovement } from '../usePushImprovement'
import { useAccount } from '@/composition/account/useAccount'
import { useMoney } from '@opteo/components-next'

export function useAdjustKeywordBid() {
    const { improvement, lastUpdated } = useImprovement<KeywordBidsMS.AdjustKeywordBidsBody>()

    const {
        body: {
            window,
            keyword,
            newBid,
            currentBid,
            bidChange,
            scatterPointChartItems,
            campaignGroupTargetMode,
            metrics: {
                spend: keywordSpend,
                conversions: keywordConversions,
                firstPageBid: keywordFirstPageBid,
                impressionSharePercent: keywordImpressionSharePercent,
                absoluteTopImpressionRatePercent: keywordAbsoluteTopImpressionRatePercent,
                campaignGroupTargetCpa,
            },
        },
    } = checkImprovement(improvement)

    const campaignGroupTargetModeString =
        campaignGroupTargetMode === Targets.TargetMode.AUTO ? 'Average' : 'Target'

    const { currencySymbol, currencyCode } = useAccount()

    const keywordCpa = keywordConversions ? keywordSpend / keywordConversions : Infinity

    const metrics = {
        cpa: { value: keywordCpa },
        firstPageBid: { value: keywordFirstPageBid },
        impressionSharePercent: { value: keywordImpressionSharePercent },
        absoluteTopImpressionRatePercent: { value: keywordAbsoluteTopImpressionRatePercent },
        conversions: {
            value: keywordConversions,
            copy: keywordConversions === 1 ? 'conversion' : 'conversions',
        },
    }

    // KEYWORD BID
    const isBidIncreasing = newBid > currentBid
    const isUpdatedBidIncreasing = computed(() => adjustedBid.value > currentBid)

    const formattedCurrentBid = useMoney({
        value: currentBid,
        currency: currencyCode.value,
    }).displayValue.value

    const formattedNewBid = useMoney({ value: newBid, currency: currencyCode.value }).displayValue
        .value

    const adjustedBid = ref(Math.round(newBid * 100) / 100)
    const adjustedBidChange = computed(() => (adjustedBid.value - currentBid) / currentBid)

    function handleBidUpdate() {
        pushActionText.value = isUpdatedBidIncreasing.value
            ? 'Increase Keyword Bid'
            : 'Decrease Keyword Bid'
    }

    function resetBid() {
        adjustedBid.value = Math.round(newBid * 100) / 100
        handleBidUpdate()
    }

    // IMPROVEMENT STATISTICS
    const improvementStatistics = [
        {
            key: 'averageCPA',
            value: useMoney({ value: campaignGroupTargetCpa, currency: currencyCode.value })
                .displayValue.value,
            title: 'Target CPA',
        },
        {
            key: 'keywordCpa',
            value: useMoney({ value: keywordCpa, currency: currencyCode.value }).displayValue.value,
            title: 'Keyword CPA',
        },
        {
            key: 'searchImprShare',
            value: usePercent({ value: keywordImpressionSharePercent }).displayValue.value,
            title: 'Search Impr. Share',
        },
        {
            key: 'absoluteTopImprShare',
            value: usePercent({ value: keywordAbsoluteTopImpressionRatePercent }).displayValue
                .value,
            title: 'Absolute Top Impr. Share',
        },
        {
            key: 'firstPageBid',
            value: useMoney({ value: keywordFirstPageBid, currency: currencyCode.value })
                .displayValue.value,
            title: 'First Page Bid',
        },
    ]

    // REQUIREMENTS

    const title = computed(() => {
        const percentageBidChange = usePercent({
            value: adjustedBidChange.value,
            stripped: true,
            decimalPlaces: 0,
        }).displayValue.value

        if (isUpdatedBidIncreasing.value) {
            return `Increase Keyword Bid ${percentageBidChange}`
        } else {
            return `Decrease Keyword Bid ${percentageBidChange}`
        }
    })

    const pushMessages = computed(() => [
        'Connecting to Microsoft Ads..',
        'Applying keyword bid adjustment..',
        'Confirming changes..',
        'Bid adjustment applied successfully.',
    ])

    const pushActionText = ref(
        isUpdatedBidIncreasing.value ? 'Increase Keyword Bid' : 'Decrease Keyword Bid'
    )

    const onPush: OnPushHandler<KeywordBidsMS.ExtraDetails> = () => {
        const valid =
            typeof adjustedBidChange.value === 'number' &&
            adjustedBidChange.value !== 0 &&
            adjustedBidChange.value !== currentBid

        return { valid, pushedData: { newBid: adjustedBid.value } }
    }

    return {
        // REQUIREMENTS
        title,
        pushMessages,
        pushActionText,
        onPush,
        lastUpdated,
        window,
        // DATA
        currencySymbol,
        currencyCode,
        keyword,
        metrics,
        campaignGroupTargetCpa,
        // KEYWORD BID
        isBidIncreasing,
        bidChange,
        adjustedBid,
        adjustedBidChange,
        formattedCurrentBid,
        formattedNewBid,
        handleBidUpdate,
        resetBid,
        // OTHERS
        campaignGroupTargetModeString,
        improvementStatistics,
        scatterPointChartItems,
    }
}
