<template>
    <!-- View Plans Modal -->
    <Modal
        v-model="showPlanSelectionModal"
        :no-padding="true"
        title="Pricing Plans"
        width="calc(100% - 88px)"
    >
        <template #header>
            <Tabs
                :links="[
                    {
                        key: 'monthly',
                        name: 'Monthly',
                    },
                    {
                        key: 'yearly',
                    },
                ]"
                :router="false"
                :active="monthlyPlansVisible ? 'monthly' : 'yearly'"
                @clicked="setActiveBillingDuration"
            >
                <template v-slot:yearly>
                    Yearly
                    <div class="two-months-free-tag">2 Months Free</div>
                </template>
            </Tabs>
        </template>
        <template #content>
            <div class="plan-selector-container" v-if="pricingPlans">
                <div
                    v-for="plan in monthlyPlansVisible ? monthlyPlans : yearlyPlans"
                    :key="plan.id"
                    class="plan"
                >
                    <PlanSelectionBox :plan="plan" />
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { Billing } from '@opteo/types'
import { CloseButton, ColorTag, Modal, Tabs, Carousel } from '@opteo/components-next'
import PlanSelectionBox from './PlanSelectionBox.vue'
import { usePlanSelectionModal } from '@/composition/billing/usePlanSelectionModal'

interface PricingPlans {
    [pricingPlans: string]: Billing.CurrentPlan
}

export default {
    components: { CloseButton, ColorTag, PlanSelectionBox, Modal, Tabs, Carousel },
    setup() {
        return usePlanSelectionModal()
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.plan-selector-container {
    @include flex-center;
    @include pv-64;
    min-height: calc(100vh - 182px);
}
.plan {
    @include mr-24;
}
.plan:last-child {
    @include mr-0;
}

.two-months-free-tag {
    @include bg-opteo-red;
    color: white;
    @include absolute;
    top: -11px;
    right: -44px;
    @include f-10;
    @include fw-400;
    @include ph-8;
    @include pv-4;
    width: 87px;
    @include br-999;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
    .plan-selector-container {
        @include grid;
        grid-template-columns: 15rem 15rem;
        gap: 2rem;
    }
    .plan {
        @include mr-0;
    }
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .plan-selector-container {
        flex-direction: column;
        @include mv-0;
    }
    .plan {
        @include mr-0;
        @include mb-32;
    }
    .plan:last-child {
        @include mb-0;
    }
}
</style>
