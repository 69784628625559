<template>
    <div>
        <Text as="h4" weight="600">Recipient Profile</Text>
        <Spacer height="1.75rem" />
        <div class="recipient-profile-container">
            <Label>Recipient Logo</Label>
            <ImageUploader
                :url="recipient.logo"
                location="group"
                @uploaded="refreshRecipientLogo"
                @afterRemove="deleteRecipientLogo"
                :has-description="true"
            >
                <img
                    v-if="recipient.logo"
                    style="max-width: 180px; max-height: 72px; padding: 0.75rem 0rem"
                    :src="recipient.logo"
                />
                <div v-else class="uploader-empty-state">
                    <Text align="center" size="f-9" color="gray">No Image Uploaded</Text>
                </div>
            </ImageUploader>
            <Spacer />

            <Label>Primary Colour</Label>
            <ColorPicker v-model="theme.primary_colour" :colorPresets="colorPresets" />

            <Spacer />

            <Label>Accent Colour (Auto Generated)</Label>
            <ColorPicker v-model="theme.accent_colour" :read-only="true" />
            <Spacer />

            <div class="range-input-container">
                <ReportChangedInputWarning
                    class="range-input"
                    field="Spend Target (From)"
                    :inputIsActive="spendBottomFocused && !spendBottomPopoutOpen"
                >
                    <oInput
                        :prefix="currencySymbol"
                        type="number"
                        class="range-input"
                        v-model.number="spendTarget.bottom"
                        label="Spend Target (From)"
                        :validator="validateSpendBottom"
                        @focus="spendBottomFocused = true"
                        @blur="spendBottomFocused = false"
                        required
                    >
                        <template #label-end>
                            <Popout
                                trigger="mouseenter"
                                placement="top"
                                v-model="spendBottomPopoutOpen"
                            >
                                <img class="input-info-icon" src="@/assets/img/icon-info.svg" />
                                <template #content>
                                    <div class="pa3">
                                        <Text size="f-8">
                                            This field is used to set the bottom of your target
                                            range for the <b>Spend Targets</b> slide. It should
                                            reflect the lower end of your budget.
                                        </Text>
                                        <Spacer height="1rem" />
                                        <div class="popout-img">
                                            <img
                                                src="@/assets/img/report-tooltip-spend-bottom.png"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </Popout>
                        </template>
                    </oInput>
                </ReportChangedInputWarning>
                <Spacer width="1rem" />
                <ReportChangedInputWarning
                    class="range-input"
                    field="Spend Target (To)"
                    :inputIsActive="spendTopFocused && !spendTopPopoutOpen"
                >
                    <oInput
                        :prefix="currencySymbol"
                        type="number"
                        v-model.number="spendTarget.top"
                        label="Spend Target (To)"
                        :validator="validateSpendTop"
                        @focus="spendTopFocused = true"
                        @blur="spendTopFocused = false"
                        required
                    >
                        <template #label-end>
                            <Popout
                                trigger="mouseenter"
                                placement="top"
                                v-model="spendTopPopoutOpen"
                            >
                                <img class="input-info-icon" src="@/assets/img/icon-info.svg" />
                                <template #content>
                                    <div class="pa3">
                                        <Text size="f-8">
                                            This field is used to set the top of your target range
                                            for the <b>Spend Targets</b> slide. It should reflect
                                            the upper limit of your budget.
                                        </Text>
                                        <Spacer height="1rem" />
                                        <div class="popout-img">
                                            <img src="@/assets/img/report-tooltip-spend-top.png" />
                                        </div>
                                    </div>
                                </template>
                            </Popout>
                        </template>
                    </oInput>
                </ReportChangedInputWarning>
            </div>
            <Spacer />

            <div class="range-input-container" v-if="isUsingCpa">
                <ReportChangedInputWarning
                    class="range-input"
                    field="CPA Target (From)"
                    :inputIsActive="cpaBottomFocused && !cpaBottomPopoutOpen"
                >
                    <oInput
                        :prefix="currencySymbol"
                        type="number"
                        v-model.number="cpaTarget.bottom"
                        label="CPA Target (From)"
                        :validator="validateCpaBottom"
                        @focus="cpaBottomFocused = true"
                        @blur="cpaBottomFocused = false"
                        required
                    >
                        <template #label-end>
                            <Popout
                                trigger="mouseenter"
                                placement="top"
                                v-model="cpaBottomPopoutOpen"
                            >
                                <img class="input-info-icon" src="@/assets/img/icon-info.svg" />
                                <template #content>
                                    <div class="pa3">
                                        <Text size="f-8">
                                            This field sets the bottom of your target range for the
                                            <b>CPA Targets</b> slide. It should reflect the lower
                                            end of your desired target range.
                                        </Text>
                                        <Spacer height="1rem" />
                                        <div class="popout-img">
                                            <img src="@/assets/img/report-tooltip-cpa-bottom.png" />
                                        </div>
                                    </div>
                                </template>
                            </Popout>
                        </template>
                    </oInput>
                </ReportChangedInputWarning>
                <Spacer width="1rem" />
                <ReportChangedInputWarning
                    class="range-input"
                    field="CPA Target (To)"
                    :inputIsActive="cpaTopFocused && !cpaTopPopoutOpen"
                >
                    <oInput
                        :prefix="currencySymbol"
                        type="number"
                        class="range-input"
                        v-model.number="cpaTarget.top"
                        label="CPA Target (To)"
                        :validator="validateCpaTop"
                        @focus="cpaTopFocused = true"
                        @blur="cpaTopFocused = false"
                        required
                    >
                        <template #label-end>
                            <Popout trigger="mouseenter" placement="top" v-model="cpaTopPopoutOpen">
                                <img class="input-info-icon" src="@/assets/img/icon-info.svg" />
                                <template #content>
                                    <div class="pa3">
                                        <Text size="f-8">
                                            This field sets the top of your target range for the
                                            <b>CPA Targets</b> slide. It should reflect the upper
                                            limit of your desired target range.
                                        </Text>
                                        <Spacer height="1rem" />
                                        <div class="popout-img">
                                            <img src="@/assets/img/report-tooltip-cpa-top.png" />
                                        </div>
                                    </div>
                                </template>
                            </Popout>
                        </template>
                    </oInput>
                </ReportChangedInputWarning>
            </div>

            <div class="range-input-container" v-else>
                <ReportChangedInputWarning
                    class="range-input"
                    field="ROAS Target (From)"
                    :inputIsActive="roasBottomFocused && !roasBottomPopoutOpen"
                >
                    <oInput
                        suffix="%"
                        type="number"
                        v-model.number="roasTarget.bottom"
                        label="ROAS Target (From)"
                        :validator="validateRoasBottom"
                        @focus="roasBottomFocused = true"
                        @blur="roasBottomFocused = false"
                        required
                    >
                        <template #label-end>
                            <Popout
                                trigger="mouseenter"
                                placement="top"
                                v-model="roasBottomPopoutOpen"
                            >
                                <img class="input-info-icon" src="@/assets/img/icon-info.svg" />
                                <template #content>
                                    <div class="pa3">
                                        <Text size="f-8">
                                            This field sets the bottom of your target range for the
                                            <b>ROAS Targets</b> slide. It should reflect the lower
                                            end of your desired target range.
                                        </Text>
                                        <Spacer height="1rem" />
                                        <div class="popout-img">
                                            <!-- TODO: make a roas-bottom.png -->
                                            <img
                                                src="@/assets/img/report-tooltip-roas-bottom.png"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </Popout>
                        </template>
                    </oInput>
                </ReportChangedInputWarning>
                <Spacer width="1rem" />
                <ReportChangedInputWarning
                    class="range-input"
                    field="ROAS Target (To)"
                    :inputIsActive="roasTopFocused && !roasTopPopoutOpen"
                >
                    <oInput
                        suffix="%"
                        type="number"
                        class="range-input"
                        v-model.number="roasTarget.top"
                        label="ROAS Target (To)"
                        :validator="validateRoasTop"
                        @focus="roasTopFocused = true"
                        @blur="roasTopFocused = false"
                        required
                    >
                        <template #label-end>
                            <Popout
                                trigger="mouseenter"
                                placement="top"
                                v-model="roasTopPopoutOpen"
                            >
                                <img class="input-info-icon" src="@/assets/img/icon-info.svg" />
                                <template #content>
                                    <div class="pa3">
                                        <Text size="f-8">
                                            This field sets the top of your target range for the
                                            <b>ROAS Targets</b> slide. It should reflect the upper
                                            limit of your desired target range.
                                        </Text>
                                        <Spacer height="1rem" />
                                        <div class="popout-img">
                                            <!-- TODO: make a roas-top.png -->
                                            <img src="@/assets/img/report-tooltip-roas-top.png" />
                                        </div>
                                    </div>
                                </template>
                            </Popout>
                        </template>
                    </oInput>
                </ReportChangedInputWarning>
            </div>

            <Spacer />

            <Label>Conversion Actions</Label>
            <oTable
                :items="conversionTableItems"
                :headers="conversionTableHeaders"
                :per-page="8"
                :fixedLayout="true"
            >
                <template #[`header.name`]>
                    <div class="conversion-actions-label-checkbox-reports">
                        <oInput
                            type="checkbox"
                            @click="toggleAllConversions"
                            :modelValue="allConversionsSelected"
                            :label="`Conversion Actions (${selectedConversionTypeCount} Selected)`"
                        >
                        </oInput>
                    </div>
                </template>
                <template #[`column.name`]="cell">
                    <div class="conversion-actions-label-checkbox-reports">
                        <Tooltip
                            placement="top-start"
                            :offset="[20, 12]"
                            :content="cell.value.length > 52 ? cell.value : ''"
                            :max-width="600"
                        >
                            <oInput
                                type="checkbox"
                                @updated="selectConversionType(cell.value, $event)"
                                :modelValue="cell.row.selected"
                                :label="cell.value"
                            >
                            </oInput>
                        </Tooltip>
                    </div>
                </template>
                <template #[`column.all_conversions`]="cell">
                    <Number :value="cell.value" />
                </template>

                <template #[`column.all_conversions_value`]="cell">
                    <Money :value="cell.value" />
                </template>
            </oTable>

            <Spacer />
            <Label>Connect Google Analytics</Label>
            <Analytics @before-oauth-redirect="saveToLocalStorage" view="reports" />
        </div>
    </div>
</template>

<script lang="ts">
/*
    TODO: adjust popout in component-next so the v-model is actually optional
*/
import { computed, onMounted, ref, watch } from 'vue'
import { Account } from '@opteo/types'
import {
    Modal,
    Text,
    Spacer,
    oInput,
    oTable,
    oImage,
    oButton,
    Label,
    Number,
    Popout,
    ColorPicker,
    Tooltip,
} from '@opteo/components-next'

import Money from '@/components/global/oMoney.vue'

import ImageUploader from '@/components/global/ImageUploader.vue'
import ReportChangedInputWarning from '@/components/Reports/ReportChangedInputWarning.vue'
import { useCreateReport } from '@/composition/reports/useCreateReport'
import { useDomain } from '@/composition/domain/useDomain'
import { getAccentColor } from '@/composition/reports/utils'
import Analytics from '@/components/analytics/Analytics.vue'

export default {
    components: {
        ReportChangedInputWarning,
        Modal,
        Text,
        Spacer,
        oInput,
        oTable,
        oImage,
        ImageUploader,
        oButton,
        Label,
        Number,
        Popout,
        ColorPicker,
        Analytics,
        Tooltip,
        Money,
    },
    setup() {
        const {
            newReportInfo,
            updateRecipientFields,
            conversionTableHeaders,
            conversionTableItems,
            selectConversionType,
            validateSpendBottom,
            validateSpendTop,
            validateCpaBottom,
            validateCpaTop,
            validateRoasBottom,
            validateRoasTop,
            selectedConversionTypeCount,
            allConversionsSelected,
            saveToLocalStorage,
            isUsingCpa,
        } = useCreateReport()

        const { currencySymbol, domainInfo } = useDomain()

        const recipient = computed(() => newReportInfo.value?.recipient)
        const spendTarget = computed(() => newReportInfo.value?.spend_target)
        const cpaTarget = computed(() => newReportInfo.value?.cpa_target)
        const roasTarget = computed(() => newReportInfo.value?.roas_target)

        const theme = computed(() => newReportInfo.value?.theme)

        /*
           Conversions
        */

        const toggleAllConversions = () => {
            const allSelected = allConversionsSelected.value
            newReportInfo.value?.conversion_types.forEach(item =>
                selectConversionType(item.name, !allSelected)
            )
        }

        /*
           Logo upload
        */
        const deleteRecipientLogo = () => updateRecipientFields({ logo: '' })

        const refreshRecipientLogo = (newLogoUrl: string) =>
            updateRecipientFields({ logo: newLogoUrl })

        /*
            Color picker stuff
        */
        const colorPresets = Account.Colours.map(c => c.hex)
        const primaryColor = computed(() => newReportInfo.value?.theme.primary_colour)

        function setAccentColor() {
            if (theme.value && theme.value.primary_colour) {
                theme.value.accent_colour = getAccentColor(theme.value.primary_colour)
            }
        }
        watch(primaryColor, () => setAccentColor())

        onMounted(() => {
            // if there's no primary colour, use the color_preference instead.
            if (theme.value && !theme.value.primary_colour) {
                theme.value.primary_colour = domainInfo.value?.color_preference ?? '#008dff'
            }

            setAccentColor()
        })

        /*
            Popover behaviour
        */
        // used to show warning tooltips
        const spendBottomFocused = ref(false)
        const spendTopFocused = ref(false)
        const cpaBottomFocused = ref(false)
        const cpaTopFocused = ref(false)
        const roasBottomFocused = ref(false)
        const roasTopFocused = ref(false)

        // used to show info tooltips & to avoid showing both warning & info at the same time
        const spendBottomPopoutOpen = ref(false)
        const spendTopPopoutOpen = ref(false)
        const cpaBottomPopoutOpen = ref(false)
        const cpaTopPopoutOpen = ref(false)
        const roasBottomPopoutOpen = ref(false)
        const roasTopPopoutOpen = ref(false)

        return {
            recipient,
            theme,
            spendTarget,
            cpaTarget,
            roasTarget,
            deleteRecipientLogo,
            refreshRecipientLogo,
            updateRecipientFields,
            conversionTableHeaders,
            conversionTableItems,
            selectedConversionTypeCount,
            allConversionsSelected,
            saveToLocalStorage,
            toggleAllConversions,
            selectConversionType,
            currencySymbol,
            validateSpendBottom,
            validateSpendTop,
            validateCpaBottom,
            validateCpaTop,
            validateRoasBottom,
            validateRoasTop,
            spendBottomFocused,
            spendTopFocused,
            cpaBottomFocused,
            roasBottomFocused,
            cpaTopFocused,
            roasTopFocused,
            spendBottomPopoutOpen,
            spendTopPopoutOpen,
            cpaBottomPopoutOpen,
            roasBottomPopoutOpen,
            cpaTopPopoutOpen,
            roasTopPopoutOpen,
            colorPresets,
            isUsingCpa,
            newReportInfo,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.conversion-actions-label-checkbox-reports :deep(.o-input--checkbox) {
    height: 1.125rem;
}
.conversion-actions-label-checkbox-reports :deep(.o-input__checkbox) {
    max-width: 100%;
}

.recipient-profile-container {
    @include container;
    @include pa-32;
}

.range-input-container {
    display: flex;
    justify-content: space-between;
}

.range-input {
    width: 100%;
}

.popout-img {
    @include container;
    overflow: hidden;
    @include br-10;
    width: 18rem;
    height: 11.25rem;
}
.popout-img img {
    width: 100%;
}

.input-info-icon {
    width: 14px;
    position: absolute;
    right: 0;
    top: 0;
}
.uploader-empty-state {
    @include pt-24;
    @include pb-28;
}
.conversion-actions-empty-state {
    // @include container;
    @include mt-24;
}
</style>
