<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.4657 6.69375 13.322 4.8375c.7859-.71632 1.8176-1.10228 2.8807-1.07766 1.0631.02461 2.0758.4579 2.8277 1.20982.752.75192 1.1853 1.76466 1.2099 2.82775.0246 1.0631-.3614 2.0948-1.0777 2.88069l-2.6531 2.6438c-.3824.3838-.8368.6883-1.3371.8961-.5003.2078-1.0368.3148-1.5786.3148-.5417 0-1.0782-.107-1.5785-.3148-.5003-.2078-.9547-.5123-1.3371-.8961"
            stroke="#000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="m12.5343 17.3062-1.8562 1.8563c-.78595.7163-1.81765 1.1023-2.88075 1.0777-1.06309-.0247-2.07583-.4579-2.82775-1.2099-.75193-.7519-1.18521-1.7646-1.20982-2.8277-.02462-1.0631.36134-2.0948 1.07766-2.8807l2.65313-2.6438c.38237-.3838.83675-.68832 1.33708-.89612.50034-.20779 1.03677-.31476 1.57855-.31476.5418 0 1.0782.10697 1.5785.31476.5004.2078.9547.51232 1.3371.89612"
            stroke="#000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
