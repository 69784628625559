<template>
    <div
        v-if="layout === 'default' || showInFullScreen"
        :class="['page-content', 'tablet-content-pad', { 'fullscreen-view': showInFullScreen }]"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import { layout } from '@/composition/layouts/useLayouts'

export default {
    name: 'PageContent',
    props: {
        showInFullScreen: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {
            layout,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.page-content {
    width: 100%;
    @include ph-48;
    @include mb-48;
}

@media screen and (max-width: $mq-480-max) {
    .page-content {
        width: 100%;
        @include ph-16;
    }
}

@media screen and (min-width: $mq-481-min) and (max-width: $mq-767-max) {
    .page-content {
        width: 100%;
        @include ph-16;
    }
}

@media screen and (min-width: $mq-1441-min) and (max-width: $mq-1800-max) {
    .page-content {
        max-width: 77rem;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
}
@media screen and (min-width: $mq-1801-min) {
    .page-content {
        max-width: 89rem;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
}

@media screen and (min-width: $mq-1280-min) {
    .page-content.fullscreen-view {
        padding: 0;
        max-width: 65rem;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: $mq-1441-min) and (max-width: $mq-1800-max) {
    .page-content.fullscreen-view {
        max-width: 65rem;
    }
}
</style>
