import { computed } from 'vue'

import { useLocalStorage } from '@vueuse/core'
import getUnixTime from 'date-fns/getUnixTime'
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import orderBy from 'lodash-es/orderBy'

import { LS_RL_VIEWMODE } from '@/lib/cookies'
import { Endpoint, useAPI, authRequest } from '@/composition/api/useAPI'
import { useUser } from '@/composition/user/useUser'
import { useDomain } from '@/composition/domain/useDomain'
import { Reports } from '@opteo/types'

// This needs to be kept outside of useReportsList() to make sure viewMode is shared across all callers
const viewMode = useLocalStorage<'list' | 'grid'>(LS_RL_VIEWMODE, 'list')

export function useReportsList() {
    const { domainId } = useDomain()
    const { loading: userLoading } = useUser()

    const {
        data: rawReportsList,
        mutate,
        loading: reportsListLoading,
    } = useAPI<Reports.Core.ReportsList>(Endpoint.GetReports, {
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
    })

    const loading = computed(() => reportsListLoading.value || userLoading.value)

    const reports = computed(() =>
        orderBy(rawReportsList.value ?? [], r => -getUnixTime(parseISO(r.created_ts)))
    )
    const activeReports = computed(() => reports.value.filter(r => !r.archived))
    const archivedReports = computed(() => reports.value.filter(r => r.archived))

    const buildReportRequestBody = (reportId: number) => {
        return {
            body: {
                domain_id: domainId.value,
                report_id: reportId,
            },
        }
    }

    const findReport = (reportId: number) => {
        const report = reports.value.find(r => r.report_id === reportId)

        if (!report) {
            throw new Error('report not found in local state')
        }

        return report
    }

    const archiveReport = async (reportId: number) => {
        const report = findReport(reportId)
        mutate(() => [
            ...reports.value.filter(r => r.report_id !== reportId),
            {
                ...report,
                archived: true,
            },
        ])
        await authRequest(Endpoint.ArchiveReport, buildReportRequestBody(reportId))
        mutate()
    }

    const restoreReport = async (reportId: number) => {
        const report = findReport(reportId)
        mutate(() => [
            ...reports.value.filter(r => r.report_id !== reportId),
            {
                ...report,
                archived: false,
            },
        ])
        await authRequest(Endpoint.RestoreReport, buildReportRequestBody(reportId))
        mutate()
    }

    const deleteReport = async (reportId: number) => {
        mutate(() => [...reports.value.filter(r => r.report_id !== reportId)])
        await authRequest(Endpoint.DeleteReport, buildReportRequestBody(reportId))
        mutate()
    }

    const duplicateReport = async (reportId: number) => {
        const report = findReport(reportId)

        mutate(() => [
            ...reports.value,
            {
                ...report,
                title: `${report.title} copy`,
                report_id: -1,
                created_ts: formatISO(new Date()),
                last_exported_ts: null,
            },
        ])

        await authRequest(Endpoint.DuplicateReport, buildReportRequestBody(reportId))
        await mutate()
    }

    return {
        loading,
        activeReports,
        archivedReports,
        archiveReport,
        restoreReport,
        deleteReport,
        duplicateReport,
        viewMode,
        mutate,
    }
}
