<template>
    <svg
        :class="['featured-ad', { animated }]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 452"
        fill="none"
    >
        <rect width="640" height="452" fill="white" />
        <rect x="75" y="388" width="106" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="388" width="70" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="400" width="95" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="400" width="82" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="412" width="66" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="412" width="57" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <circle cx="47" cy="404" r="14" fill="#EBEBEB" />
        <circle cx="499" cy="404" r="14" fill="#EBEBEB" />
        <rect x="33" y="34" width="146" height="24" rx="12" fill="black" fill-opacity="0.08" />
        <g filter="url(#filter0_dd_2_4841)">
            <rect x="33" y="94" width="574.842" height="258" rx="8" fill="white" />
        </g>
        <rect x="33" y="137" width="574.842" height="0.754386" fill="#FAFAFA" />
        <rect x="51" y="112" width="37" height="8" rx="4" fill="#EBEBEB" />
        <rect x="246" y="112" width="37" height="8" rx="4" fill="#EBEBEB" />
        <rect x="326" y="112" width="23" height="8" rx="4" fill="#EBEBEB" />
        <rect x="383" y="112" width="56" height="8" rx="4" fill="#EBEBEB" />
        <rect x="466" y="112" width="46" height="8" rx="4" fill="#EBEBEB" />
        <rect x="543" y="112" width="46" height="8" rx="4" fill="#EBEBEB" />
        <rect x="33" y="180" width="574.842" height="0.754386" fill="#FAFAFA" />
        <rect class="row-one" x="51" y="155" width="119" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-one" x="246" y="155" width="35" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-one" x="326" y="155" width="12" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-one" x="383" y="155" width="27" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-one" x="466" y="155" width="28" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-one" x="543" y="155" width="29" height="8" rx="4" fill="#00A861" />
        <rect x="33" y="223" width="574.842" height="0.754386" fill="#FAFAFA" />
        <rect class="row-two" x="51" y="198" width="104" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-two" x="246" y="198" width="40" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-two" x="326" y="198" width="12" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-two" x="383" y="198" width="27" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-two" x="466" y="198" width="28" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-two" x="543" y="198" width="29" height="8" rx="4" fill="#00A861" />
        <rect x="33" y="266" width="574.842" height="0.754386" fill="#FAFAFA" />
        <rect class="row-three" x="51" y="241" width="112" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-three" x="246" y="241" width="42" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-three" x="326" y="241" width="15" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-three" x="383" y="241" width="27" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-three" x="466" y="241" width="28" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-three" x="543" y="241" width="30" height="8" rx="4" fill="#00A861" />
        <rect x="33" y="309" width="574.842" height="0.754386" fill="#FAFAFA" />
        <rect class="row-four" x="51" y="284" width="139" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-four" x="246" y="284" width="36" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-four" x="326" y="284" width="15" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-four" x="383" y="284" width="26" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-four" x="466" y="284" width="28" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-four" x="543" y="284" width="32" height="8" rx="4" fill="#00A861" />
        <rect class="row-five" x="51" y="327" width="106" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-five" x="246" y="327" width="40" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-five" x="326" y="327" width="15" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-five" x="383" y="327" width="26" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-five" x="466" y="327" width="28" height="8" rx="4" fill="#EBEBEB" />
        <rect class="row-five" x="543" y="327" width="32" height="8" rx="4" fill="#00A861" />
        <defs>
            <filter
                id="filter0_dd_2_4841"
                x="31"
                y="93"
                width="578.842"
                height="263"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_2_4841"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2_4841"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_2_4841"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_2_4841"
                    result="effect2_dropShadow_2_4841"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_2_4841"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'

export default {
    props: {
        animated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        onMounted(() => {
            if (!props.animated) {
                return
            }

            const timeline = anime.timeline()
            timeline.add({
                targets: '.row-one, .row-two, .row-three, .row-four, .row-five',
                opacity: [0, 1],
                delay: anime.stagger(50, { start: 200 }),
                duration: 300,
                easing: 'easeInCubic',
            })
        })
    },
}
</script>

<style lang="scss" scoped>
svg {
    width: 100%;
    transform: scale(1);
    transform-origin: center;
}

svg.animated {
    .row-one,
    .row-two,
    .row-three,
    .row-four,
    .row-five {
        opacity: 0;
        will-change: opacity;
    }
}
</style>
