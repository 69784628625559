<template>
    <div>
        <ImprovementView
            :push-action-text="pushActionText"
            :push-messages="pushMessages"
            :on-push="onPush"
        >
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <Text as="p">
                        Opteo scanned your account for a campaign with at least one keyword
                        mirroring your domain, but couldn't find one. Brand campaigns help you
                        control your brand, protect against traffic-hungry competitors, and generate
                        cheap conversions. Follow the instructions below to create a new brand
                        campaign. Once you've created one, click
                        <Text weight="600" as="span" color="green">{{ pushActionText }}</Text> to
                        rescan your account and confirm.
                    </Text>
                    <Spacer height="2rem" />
                    <ImprovementNote>
                        <Text as="p" size="f-8">
                            <b>Note:</b> Brand campaigns require
                            <Link
                                href="https://opteo.com/docs/en/articles/964380-why-isn-t-opteo-picking-up-my-brand-campaign"
                            >
                                at least one impression
                            </Link>
                            before Opteo can detect them. If this is taking a while — after you have
                            created your new brand campaign — you can temporarily
                            <b>Dismiss</b> this improvement to allow your campaign to gather data.
                        </Text>
                    </ImprovementNote>
                </ImprovementHeader>
                <ImprovementHelpLink path="/en/articles/900301-create-brand-campaign">
                    Learn more about the benefits of creating a brand campaign.
                </ImprovementHelpLink>
            </ImprovementContent>

            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Creating a Brand Campaign</Text>
                <Spacer height="2rem" />
                <div class="how-to-container">
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 1" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Create New Campaign</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            In <b>Google Ads</b>, navigate to the <Ent>Campaign</Ent> tab. Click the
                            blue <Ent>+</Ent> button and select the <Ent>Search</Ent> campaign type.
                            Choose your campaign goal.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 2" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Set Campaign Properties</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Name your <b>Campaign</b>, something like
                            <Ent>{{ brandingKeyword }} | Brand</Ent>. Choose your desired Locations,
                            Bids and Budget. Click <Ent>Save and Continue</Ent>.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 3" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Name Ad Group</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Name your <b>Ad Group</b>, something like
                            <Ent>{{ brandingKeyword }} Brand</Ent>.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 4" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Create a New Ad</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Write an ad to capture the attention of people searching for your
                            business. Focus on your brand language. Company taglines are useful
                            here. Make it clear to potential customers that this is your brand's
                            official website.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 5" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Input Brand Keywords</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Cover the basics, as well as thinking about potential misspellings and
                            variations of your domain name. Something along these lines:
                            <!-- Spacer -->
                            <Spacer height="1rem" />
                            <EntityPillList :items="potentialKeywordsList" />
                            <!-- <ul>
                                <li
                                    v-for="(keyword, index) in improvement.body.potential_keywords"
                                    :key="keyword"
                                >
                                    <EntityPill type="keyword" :content="keyword" />
                                    <Spacer
                                        v-if="
                                            index != improvement.body.potential_keywords.length - 1
                                        "
                                        height="0.75rem"
                                    />
                                </li>
                            </ul> -->
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 6" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Save Ad Group</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Click <Ent>Save Ad Group</Ent>. Return to Opteo and click
                            <Text as="span" weight="600" color="green">{{ pushActionText }}</Text
                            >. Your account will be re-scanned for a brand campaign, when Opteo
                            finds one, this improvement will be moved to the <b>Completed</b> tab.
                        </Text>
                    </div>
                </div>
                <!-- <div class="how-to-box">
                    <ImprovementContent class="instructions bb b--opteo-light-gray">
                        <Text as="h6">6. Save Ad Group</Text>
                        <Spacer height="2rem" />
                        <Text as="p">
                            Click
                            <b>Save Ad Group</b>. Return to Opteo and hit
                            <Text wight="600" color="green">{{ pushActionText }}</Text
                            >. We’ll rescan your account for a branding campaign. This improvement
                            will move to <b>Completed</b>.
                        </Text>
                    </ImprovementContent>
                </div> -->
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="60" />
            </ImprovementContent>
        </ImprovementView>
    </div>
</template>

<script lang="ts">
import {
    EntityPill,
    EntityPillList,
    Spacer,
    Text,
    TextAd,
    Link,
    ColorTag,
    Ent,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'

import { useCreateBrandCampaign } from '@/composition/improvement/types/useCreateBrandCampaign'

export default {
    name: 'CreateBrandCampaign',
    setup() {
        return useCreateBrandCampaign()
    },
    components: {
        ImprovementHelpLink,
        ImprovementView,
        EntityPill,
        EntityPillList,
        Spacer,
        Text,
        TextAd,
        Link,
        ColorTag,
        Ent,
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.how-to-container {
    @include container;
}

.how-to-section {
    @include ph-32;
    @include pb-32;
    @include pt-24;
    @include relative;
    border-bottom: 1px solid;
    @include opteo-border;

    & .sub-heading {
        @include flex;
        @include pb-24;
        margin-right: 68px;

        & .number {
            @include absolute;
            right: 24px;
            top: 25px;
        }
    }

    & .text-content {
        @include container;
        @include pa-24;
        overflow: hidden;
    }
}

.how-to-section:last-child {
    border-bottom: none;
}

@media screen and (max-width: $mq-767-max) {
    .how-to-section {
        @include ph-24;
        @include pb-24;
    }
}

@media screen and (max-width: $mq-375-max) {
    .how-to-section {
        @include ph-24;
        @include pb-24;
        & .sub-heading {
            @include block;
            @include pb-24;
            margin-right: unset;

            & .number {
                position: unset;
                width: fit-content;
                @include mb-12;
            }
        }
    }
}
</style>
