<template>
    <MobileSplashContainer wide>
        Reports are best viewed on screens <b>1200px or wider</b>, please increase your window size
        or use another device. If you'd like to see us develop Reports for touch devices, please
        consider submitting a <Link :to="linkToFeatureRequests">feature request</Link>.
    </MobileSplashContainer>
    <div id="reports-container" :key="domainId">
        <div>
            <PageHeader page-title="Reports">
                <template #middle-content>
                    <Tabs :links="reportsTabLinks">
                        <template #active>Active</template>
                        <template #archive>Archived</template>
                    </Tabs>
                </template>
                <template #right-content>
                    <div class="right-content-container">
                        <div class="view-toggle">
                            <ViewToggle v-model="viewMode" />
                        </div>
                        <Spacer width="1rem" class="spacer" />
                        <div class="preferences-button">
                            <oButton color="white" @clicked="showPreferencesModal = true">
                                Preferences
                            </oButton>
                        </div>
                    </div>
                </template>
            </PageHeader>
            <ReportsGridSkeleton v-if="userLoading" />
            <ReportsUpsell
                v-else-if="!hasReportsAccess"
                @show-plans-modal="showPlansModal = true"
            />
            <router-view v-else></router-view>
        </div>
    </div>

    <Modal v-model="showPreferencesModal" title="Preferences" width="804px" :no-padding="true">
        <template #content>
            <ReportPreferences />
        </template>
    </Modal>
    <PlanSelectionModal v-model="showPlansModal" />

    <!-- Reports onboarding -->
    <OnboardingTour
        step-name="tour_reports"
        :buttonText="'Continue to Reports'"
        contentMaxWidth="492px"
    >
        <template v-slot:header>Reports</template>
        <template v-slot:copy>
            Create and export beautiful Google Ads reports in minutes. With automatically generated
            comments, pre-built templates, and report scheduling, you can create reports that
            clients and managers love to read in a matter of minutes. Click below to continue to
            Reports.
        </template>
    </OnboardingTour>
</template>

<script lang="ts">
import { Routes } from '@/router/routes'
import { defineComponent, computed, ref } from 'vue'
import { oButton, Spacer, ViewToggle, Modal, Tabs, Label, Text, Link } from '@opteo/components-next'

import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'

import ReportPreferences from '@/components/Reports/ReportPreferences.vue'
import ReportsUpsell from '@/components/Reports/ReportsUpsell.vue'
import OnboardingTour from '@/components/User/OnboardingTour.vue'
import PlanSelectionModal from '@/components/billing/PlanSelectionModal.vue'

import { useUser } from '@/composition/user/useUser'
import { useDomain } from '@/composition/domain/useDomain'
import { useReportsList } from '@/composition/reports/useReportsList'
import ReportsGridSkeleton from '@/components/Reports/ReportsGridSkeleton.vue'
import MobileSplashContainer from '@/components/util/MobileSplashContainer.vue'

export default defineComponent({
    components: {
        ReportPreferences,
        ReportsUpsell,
        PageHeader,
        PageContent,
        Tabs,
        oButton,
        Spacer,
        ViewToggle,
        Modal,
        ReportsGridSkeleton,
        Label,
        Text,
        OnboardingTour,
        PlanSelectionModal,
        MobileSplashContainer,
        Link,
    },
    name: 'Reports',
    setup() {
        const { hasReportsAccess, loading: userLoading, userInfo } = useUser()
        const { domainId } = useDomain()

        const { activeReports, archivedReports, viewMode } = useReportsList()

        const activeCount = computed(() => activeReports.value?.length ?? '')
        const archivedCount = computed(() => archivedReports.value?.length ?? '')

        const reportsTabLinks = computed(() => [
            {
                key: 'active',
                to: { name: Routes.ReportsActive },
                count: activeCount.value,
            },
            {
                key: 'archive',
                to: { name: Routes.ReportsArchive },
                count: archivedCount.value,
            },
        ])

        const showPreferencesModal = ref(false)
        const showPlansModal = ref(false)

        const linkToFeatureRequests = computed(() => {
            return `/user/${userInfo.value?.user_id}/featurerequests`
        })

        return {
            reportsTabLinks,
            hasReportsAccess,
            userLoading,
            viewMode,
            showPreferencesModal,
            showPlansModal,
            linkToFeatureRequests,
            domainId,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
@import '@/assets/css/fonts/google-sans-text.css';
@import '@/assets/css/fonts/google-sans.css';

#reports-hidden-container {
    display: none;
}

.right-content-container {
    @include flex;
}

:deep(.reports-grid) {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2.125rem;
    grid-row-gap: calc(2.125rem + 22px);
    justify-items: center;
    padding-bottom: 1.25rem;
}

@media screen and (min-width: 850px) {
    :deep(.reports-grid) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 1260px) {
    :deep(.reports-grid) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: $mq-767-max) {
    .preferences-button {
        position: absolute;
        top: 32px;
        right: 48px;
    }
    .spacer {
        display: none;
    }
    .view-toggle {
        display: none;
    }
}

@media screen and (max-width: $mq-480-max) {
    .preferences-button {
        position: absolute;
        top: 32px;
        right: 16px;
    }
}
</style>
