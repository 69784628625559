<template>
    <!-- Recommended Experiments -->
    <div class="recommended-experiments">
        <Carousel
            :arrows="true"
            :options="{ slidesToScroll: 2, align: 'start', speed: 8 }"
            padding="2.5rem 2.5rem"
            :arrowHorizontalPadding="hasRecommendations ? '0.875rem' : '1.5rem'"
        >
            <template v-if="hasRecommendations" #slides>
                <div
                    v-for="(experiment, index) in experimentCarouselSlides"
                    :key="'experiment-' + index"
                    class="recommended-experiment-wrapper"
                >
                    <RecommendedExperiment v-if="!experiment.emptyState" :experiment="experiment">
                        <oButton
                            color="blue"
                            @clicked="
                                goToCreateFlow({
                                    from: 'recommendation',
                                    campaignIds: experiment.campaigns.map(item => item.campaignId),
                                    recommendedBiddingStrategy:
                                        experiment.rawRecommendedBidStrategy,
                                })
                            "
                        >
                            Create Recommended Experiment
                            <template v-slot:icon>
                                <RightArrowIcon hex="#fff" />
                            </template>
                        </oButton>
                    </RecommendedExperiment>
                    <div v-else class="experiment-empty-state">
                        <Text color="gray" align="center" size="f-8" style="max-width: 17.875rem">
                            When Opteo detects a campaign (or group of campaigns) with enough
                            performance data, a new recommendation will appear here.
                        </Text>
                    </div>
                </div>
            </template>
            <template v-else #slides>
                <div class="empty-state-wrapper">
                    <Text as="p" size="f-8" align="center">
                        Opteo has no experiments to recommend at this time. This is usually because
                        you have no eligible campaigns, or all of your campaigns are in active
                        experiments. Check back in a couple of days for updates.
                    </Text>
                </div>
            </template>
        </Carousel>
    </div>
    <!-- Divider -->
    <div class="or-divider">
        <span class="or">or <b>Select a Campaign</b></span>
    </div>
    <!-- Select Campaigns -->
    <div class="select-campaigns">
        <header>
            <div class="left">
                <Text as="h4" weight="600">Select Campaigns</Text>
                <Spacer width="1.5rem" />
                <Text
                    as="p"
                    size="f-10"
                    style="font-size: 0.75rem; width: 18rem; color: rgba(0, 0, 0, 0.32)"
                >
                    Certain campaign types and campaigns in active experiments have been filtered
                    out of this list
                    <Tooltip
                        :maxWidth="330"
                        :offset="[0, 4]"
                        content="Selection is automatically restricted to campaigns with the same bidding strategy and advertising channel type."
                    >
                        <InfoIcon class="select-campaigns-info" />
                    </Tooltip>
                </Text>
            </div>
            <!-- Only enable this button if non eligible campaigns exist -->
            <oButton
                :disabled="
                    !(
                        (allCampaignsTableItems?.length ?? 0) -
                        (eligibleCampaignsTableItems?.length ?? 0)
                    )
                "
                color="white"
                @clicked="toggleEligibleCampaigns"
                >{{
                    showEligibleCampaigns
                        ? `Show Unvailable Campaigns`
                        : `Hide Unavailable Campaigns`
                }}</oButton
            >
        </header>
        <Spacer height="2rem" />
        <div v-if="hasEligibleCampaigns || !showEligibleCampaigns" class="select-campaigns-table">
            <oTable
                :headers="baseCampaignTableHeaders"
                :items="
                    showEligibleCampaigns ? eligibleCampaignsTableItems : allCampaignsTableItems
                "
                :perPage="25"
                noShadow
                fixedLayout
                orderBy="cost"
                order="DESC"
            >
                <template #column.campaign="row">
                    <div class="table-cell overflow">
                        <Tooltip
                            :content="
                                checkIfDifferentCampaignTypeOrUnavailable(row.row)
                                    ? row.row.firstCheckThatFailsText
                                    : ''
                            "
                            :maxWidth="232"
                            :offset="[0, 10]"
                            placement="top"
                        >
                            <oInput
                                :disabled="checkIfDifferentCampaignTypeOrUnavailable(row.row)"
                                type="checkbox"
                                @click="selectCampaign(row.row)"
                                :modelValue="row.row.selected"
                                :class="{
                                    unavailable: checkIfDifferentCampaignTypeOrUnavailable(row.row),
                                }"
                            />
                        </Tooltip>

                        <Tooltip
                            v-if="row.value.length > 44"
                            :content="row.value"
                            :offset="[0, 8]"
                            :maxWidth="600"
                            placement="top-start"
                        >
                            <EntityPill
                                :content="row.value"
                                :tooltip="false"
                                :disabled="checkIfDifferentCampaignTypeOrUnavailable(row.row)"
                                type="campaign"
                                @click="selectCampaign(row.row)"
                            />
                        </Tooltip>
                        <EntityPill
                            v-else
                            :content="row.value"
                            :tooltip="false"
                            :disabled="checkIfDifferentCampaignTypeOrUnavailable(row.row)"
                            type="campaign"
                            @click="selectCampaign(row.row)"
                        />
                    </div>
                </template>

                <template v-slot:column.cost="cost">
                    <Money
                        :class="{
                            unavailable: checkIfDifferentCampaignTypeOrUnavailable(cost.row),
                        }"
                        :value="cost.value"
                    />
                </template>

                <template v-slot:column.conversions="conversions">
                    <Number
                        :class="{
                            unavailable: checkIfDifferentCampaignTypeOrUnavailable(conversions.row),
                        }"
                        :value="conversions.value"
                    />
                </template>

                <template v-slot:column.cpa="cpa">
                    <Money
                        :class="{
                            unavailable: checkIfDifferentCampaignTypeOrUnavailable(cpa.row),
                        }"
                        :value="cpa.value"
                    />
                </template>

                <template v-slot:column.convValue="convValue">
                    <Money
                        :class="{
                            unavailable: checkIfDifferentCampaignTypeOrUnavailable(convValue.row),
                        }"
                        :value="convValue.value"
                    />
                </template>

                <template v-slot:column.roas="roas">
                    <Roas
                        :class="{
                            unavailable: checkIfDifferentCampaignTypeOrUnavailable(roas.row),
                        }"
                        :value="roas.value"
                    />
                </template>
                <template v-slot:column.strategy="strategy">
                    <span
                        :class="{
                            unavailable: checkIfDifferentCampaignTypeOrUnavailable(strategy.row),
                        }"
                        >{{ strategy.value }}</span
                    >
                </template>
            </oTable>
        </div>
        <div v-else>
            <div class="empty-state-wrapper">
                <Text as="p" size="f-8" align="center">
                    Experiment eligible campaigns will appear here. Either none of your campaigns
                    are eligible, or are otherwise part of active experiments. If this seems to be
                    an error, please message support for assistance.
                </Text>
            </div>
        </div>
    </div>
    <!-- Batch Bar -->
    <BatchBar
        v-model="showBatchBar"
        @clear-selected="clearSelectedCampaigns()"
        :count="selectedCampaigns.length"
        :title="selectedCampaigns.length === 1 ? 'Campaign' : 'Campaigns'"
        :full-screen="true"
    >
        <template #buttons>
            <oButton
                @clicked="goToCreateFlow({ from: 'table' })"
                :disabled="selectedCampaigns.length === 0"
            >
                Create Experiment
                <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
            </oButton>
        </template>
    </BatchBar>
</template>

<script lang="ts">
// Imports
import { defineComponent } from 'vue'
import { useExperiments } from '@/composition/toolkit/experiments/useExperiments'
import { Routes } from '@/router/routes'

// Components
import {
    Spinner,
    oTable,
    Number,
    Tabs,
    oButton,
    Roas,
    Carousel,
    RightArrowIcon,
    Text,
    Spacer,
    Tooltip,
    oInput,
    CheckIcon,
    BatchBar,
    EntityPill,
} from '@opteo/components-next'

// Local Components
import Money from '@/components/global/oMoney.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import RecommendedExperiment from '@/components/biddingExperiments/RecommendedExperiment.vue'
import InfoIcon from '@/components/global/InfoIcon.vue'

// Export
export default defineComponent({
    name: 'CreateExperiment',
    components: {
        Spinner,
        oTable,
        Money,
        Number,
        Tabs,
        oButton,
        Roas,
        Carousel,
        RightArrowIcon,
        Text,
        Spacer,
        Tooltip,
        oInput,
        CheckIcon,
        EntityPill,
        // local
        Skeleton,
        RecommendedExperiment,
        InfoIcon,
        BatchBar,
    },
    setup() {
        const { clearSelectedCampaigns, ...experimentData } = useExperiments()
        // Clear the selected campaigns when the user navigates to this page
        // This is to prevent the user from accidentally creating an experiment with the wrong campaigns or duplicate campaigns
        clearSelectedCampaigns()
        return { ...experimentData, Routes, clearSelectedCampaigns }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.recommended-experiments {
    @include container;
    @include br-32;
}
.recommended-experiment-wrapper {
    @include mr-32;
    flex-shrink: 0;
    max-width: 28.5rem;
    width: 100%;
}

.recommended-experiments .empty-state-wrapper {
    @include w-100;
    @include flex-center;
    @include mv-40;
}
.recommended-experiments .empty-state-wrapper p,
.select-campaigns .empty-state-wrapper p {
    max-width: 31.25rem;
    @include container;
    @include br-16;
    @include pv-28;
    @include ph-32;
}

.select-campaigns .empty-state-wrapper {
    width: 100%;
    @include flex-center;
    @include pv-40;
}

.or-divider {
    width: 100%;
    height: 1px;
    background-color: #f2f2f3;
    @include relative;
    @include mv-48;
}
.or-divider span {
    @include absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -0.75rem;
    height: 1.5rem;
    @include ph-12;
    border-radius: 999rem;
    @include flex;
    @include items-center;
    @include f-10;
    padding-bottom: 0.125rem;
    color: rgba(0, 0, 0, 0.32);
    @include container;
    gap: 0.1875rem;
}

.select-campaigns {
    @include container;
    @include br-32;
    @include pt-28;
    @include ph-40;
    @include pb-40;
}
.select-campaigns header {
    @include flex;
    @include items-center;
    @include justify-between;
}
.select-campaigns header .left {
    @include flex;
    @include items-center;
}
.select-campaigns header .left .select-campaigns-info {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.125rem;
    transform: translateY(0.125rem);
}
.select-campaigns .select-campaigns-table {
    @include container;
    @include br-20;
    overflow: hidden;
}

// Carousel
.experiment-empty-state {
    @include container;
    @include pa-64;
    @include br-20;
    height: 100%;
    @include flex-center;
    flex-direction: column;
}

.experiment-empty-state img {
    @include pb-32;
    width: 6.875rem;
}

// oTable
.select-campaigns .table-cell {
    @include flex;
    @include items-center;
    @include relative;
    gap: 0.875rem;
    height: 4.75rem;
}
.select-campaigns .table-cell :deep(.o-input--checkbox),
.select-campaigns .table-cell :deep(.o-input__checkbox) {
    height: 1.125rem;
    opacity: 1;
    // width: 100%;
}
.select-campaigns :deep(.o-table-container td.remove-padding) {
    padding: 0;
}
.select-campaigns .table-cell.overflow {
    padding-left: 1.5rem;
    overflow: hidden;
}
.select-campaigns .table-cell.overflow::after {
    content: '';
    @include absolute;
    width: 4rem;
    height: 100%;
    right: 0;
    top: 0;
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 50%);
    @include z-2;
    pointer-events: none;
}
.select-campaigns .unavailable {
    opacity: 0.25;
}
.select-campaigns .unavailable :deep(.o-input__text) {
    opacity: 0.25;
}
</style>
