<template>
    <Popout
        v-model="userActionsPopoutShown"
        :externalToggleButton="true"
        :trapFocus="true"
        :offset="[-2, 10]"
        :popperOptions="{
            strategy: 'fixed',
        }"
    >
        <oButton
            color="white"
            size="small"
            :disabled="user?.profile.username === ctx.row.email"
            @clicked="userActionsPopoutShown = !userActionsPopoutShown"
        >
            Actions
            <template #icon>
                <SelectArrowIcon hex="#006dff" />
            </template>
        </oButton>
        <template v-slot:content>
            <div class="user-actions-popout">
                <div class="popout-row">
                    <button
                        class="popout-button"
                        :disabled="!ctx.row.canBeRemoved"
                        @click="handleAction('remove')"
                    >
                        <div class="popout-button-contents">
                            <div class="icon-slot">
                                <RemoveUserIcon />
                            </div>
                            <span class="popout-span">Remove User</span>
                        </div>
                    </button>
                </div>
                <div class="popout-row" v-if="ctx.row.role !== 'admin' && ctx.row.canBeRemoved">
                    <button
                        class="popout-button"
                        :disabled="!ctx.row.canBeRemoved"
                        @click="handleAction('promote')"
                    >
                        <div class="popout-button-contents">
                            <div class="icon-slot">
                                <MakeAdminIcon />
                            </div>
                            <span class="popout-span">Make User Admin</span>
                        </div>
                    </button>
                </div>
                <div class="popout-row" v-if="ctx.row.role === 'admin' && ctx.row.canBeRemoved">
                    <button
                        class="popout-button"
                        :disabled="!ctx.row.canBeRemoved"
                        @click="handleAction('demote')"
                    >
                        <div class="popout-button-contents">
                            <div class="icon-slot">
                                <RemoveAdminIcon />
                            </div>
                            <span class="popout-span">Remove as Admin</span>
                        </div>
                    </button>
                </div>
            </div>
        </template>
    </Popout>
</template>
<script lang="ts">
import { oButton, SelectArrowIcon, Popout } from '@opteo/components-next'
import RemoveAdminIcon from '@/components/accountsSettings/RemoveAdminIcon.vue'
import RemoveUserIcon from '@/components/accountsSettings/RemoveUserIcon.vue'
import MakeAdminIcon from '@/components/accountsSettings/MakeAdminIcon.vue'
import { ref, PropType } from 'vue'
import type { TeamMember } from '@/composition/user/useTeam'
import { useUser } from '@/composition/user/useUser'

interface CTX {
    column: string
    row: TeamMember & { canBeRemoved: boolean }
}
export default {
    components: {
        oButton,
        SelectArrowIcon,
        Popout,
        RemoveAdminIcon,
        RemoveUserIcon,
        MakeAdminIcon,
    },
    props: {
        ctx: {
            required: true,
            type: Object as PropType<CTX>,
        },
    },
    emits: ['promote', 'demote', 'remove'],
    setup(props, { emit }) {
        const userActionsPopoutShown = ref(false)
        const { user, userInfo } = useUser()

        const handleAction = (type: string) => {
            if (type === 'demote') {
                emit('demote')
                userActionsPopoutShown.value = false
            } else if (type === 'promote') {
                emit('promote')
                userActionsPopoutShown.value = false
            } else if (type === 'remove') {
                emit('remove')
                userActionsPopoutShown.value = false
            }
        }

        return { userActionsPopoutShown, user, userInfo, handleAction }
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.user-actions-popout {
    @include pa-8;
}

.user-actions-popout .popout-row {
    margin-bottom: 6px;
}

.user-actions-popout .popout-row:last-child {
    @include mb-0;
}

.user-actions-popout .popout-button-contents {
    @include flex;
    @include items-center;
}
.user-actions-popout .popout-span {
    @include block;
    @include tl;
}

.user-actions-popout .icon-slot {
    @include flex;
    @include justify-start;
    width: 25px;
    @include mr-12;
}

.user-actions-popout .popout-button {
    border: none;
    outline: none;
    font-size: 0.8125rem;
    letter-spacing: -0.0125rem;
    @include fw-500;
    @include bg-opteo-white;
    padding: 12px 16px;
    width: 100%;
    @include br-12;
    cursor: pointer;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.user-actions-popout .popout-button:hover {
    box-shadow: $opteo-shadow;
}
.user-actions-popout .popout-button:focus {
    box-shadow: $opteo-shadow-focus;
}
.user-actions-popout .popout-button:active {
    box-shadow: $opteo-shadow-focus;
    transform: translateY(1px);
}
</style>
