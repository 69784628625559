<template>
    <PageContent>
        <!--Loading -->
        <div v-if="loading">
            <ReportsGridSkeleton />
        </div>
        <div v-else>
            <!-- Empty State (Zero Archived Reports) -->
            <div v-if="!archivedReports.length" class="reports-empty-state-container">
                <img src="@/assets/img/archived-reports-empty-state.png" style="height: 224px" />
                <Spacer height="2rem" />
                <div class="center" style="max-width: 342px">
                    <Text as="h5" size="f-5" weight="600" align="center">
                        No archived Reports available
                    </Text>
                    <Spacer height="0.875rem" />
                    <Text as="p" size="f-8" align="center">
                        Your archived reports will appear here. Head over to the <b>Active</b> tab
                        to create your first Google Ads report.
                    </Text>
                </div>
            </div>
            <!-- Archived Reports -->
            <div v-else class="w-100 mw-opteo" :class="{ 'reports-grid': viewMode === 'grid' }">
                <ReportItems :reports="archivedReports" />
            </div>
        </div>
    </PageContent>
    <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { oButton, Text, Spacer } from '@opteo/components-next'
import ReportItems from '@/components/Reports/ReportItems.vue'
import ReportsGridSkeleton from '@/components/Reports/ReportsGridSkeleton.vue'

import PageContent from '@/layouts/PageContent.vue'
import { useReportsList } from '@/composition/reports/useReportsList'

/*
    TODO:
    - hide everything on small screens
    - Grid vs rows view
    - no reports yet state
    - no reports access state
*/
export default defineComponent({
    components: {
        PageContent,
        oButton,
        Text,
        Spacer,
        ReportItems,
        ReportsGridSkeleton,
    },
    name: 'ReportsArchive',
    setup() {
        return {
            ...useReportsList(),
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.reports-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 30rem;
}
</style>
