<template>
    <div class="skeleton-text-a">
        <Spacer height="0.25rem" />
        <Skeleton :width="80" :height="12" />
        <Spacer height="0.75rem" />
        <Skeleton :width="400" :height="20" />
        <Spacer height="0.75rem" />
        <Skeleton :width="560" :height="12" />
        <Spacer height="0.5rem" />
        <Skeleton :width="532" :height="12" />
        <Spacer height="1.125rem" />
        <div class="pills">
            <Skeleton :width="86" :height="28" />
            <Spacer width="0.375rem" height="0.25rem" />
            <Skeleton :width="87" :height="28" />
            <Spacer width="0.375rem" height="0.25rem" />
            <Skeleton :width="78" :height="28" />
            <Spacer width="0.375rem" height="0.25rem" />
            <Skeleton :width="186" :height="28" />
        </div>
    </div>
</template>

<script lang="ts">
import { Spacer } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
export default {
    name: 'SkeletonTextAd',
    components: { Skeleton, Spacer },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.skeleton-text-a {
    @include container;
    @include pa-40;
}
.pills {
    @include flex;
    @include items-center;
}

@media screen and (max-width: $mq-767-max) {
    .skeleton-text-a {
        @include pa-24;
    }
}
</style>
