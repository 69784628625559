<template>
    <oInput
        type="textarea"
        ref="textRef"
        :label="label"
        :modelValue="text"
        @updated="textUpdated"
        :maxCharacters="90"
        :custom-counter="getCharCount"
        :validator="validateDuplicateText"
        :nestedButton="true"
        :name="name"
        :required="required"
    >
        <template v-slot:nestedButton>
            <Popout
                v-model="popoutOpen"
                :trapFocus="false"
                :externalToggleButton="true"
                :maxWidth="340"
                :borderRadius="16"
                :zIndex="999999999999999"
                :offset="[-219, 6]"
                :popperOptions="{
                    strategy: 'fixed',
                }"
            >
                <RsaButton :small="true" @click.prevent="popoutOpen = !popoutOpen">
                    <rsaPinIcon v-if="currentPinPosition === 0" hex="#00000029" />
                    <rsaPinOneIcon v-if="currentPinPosition === 1" hex="#006DFF" />
                    <rsaPinTwoIcon v-if="currentPinPosition === 2" hex="#006DFF" />
                </RsaButton>
                <template v-slot:content>
                    <div class="pin-popout">
                        <Text as="h3" size="f-5" weight="600">Pin Description</Text>
                        <Spacer height="1.5rem" />
                        <div class="pin-dialog">
                            <div class="pin-item" @click="handleSelection(0)">
                                <Text as="p" size="f-8">Show in any <b>unpinned</b> position</Text>
                                <CheckIcon :checked="currentPinPosition === 0" :checkable="true" />
                            </div>
                            <div class="pin-item" @click="handleSelection(1)">
                                <Text as="p" size="f-8">Show only in <b>position one</b></Text>
                                <CheckIcon :checked="currentPinPosition === 1" :checkable="true" />
                            </div>
                            <div class="pin-item" @click="handleSelection(2)">
                                <Text as="p" size="f-8">Show only in <b>position two</b></Text>
                                <CheckIcon :checked="currentPinPosition === 2" :checkable="true" />
                            </div>
                        </div>
                    </div>
                </template>
            </Popout>
        </template>
    </oInput>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType } from 'vue'
import { RsaWriter } from '@opteo/types'

import { delay } from '@opteo/promise'
import { getCharCount } from './useRsa'

import {
    Text,
    Spacer,
    oInput,
    oButton,
    oForm,
    Popout,
    rsaPinIcon,
    rsaPinOneIcon,
    rsaPinTwoIcon,
    rsaPinThreeIcon,
    CheckIcon,
} from '@opteo/components-next'
import RsaButton from '@/components/rsaWriter/RsaButton.vue'

export default defineComponent({
    components: {
        Text,
        Spacer,
        oInput,
        oButton,
        oForm,
        Popout,
        RsaButton,
        rsaPinIcon,
        rsaPinOneIcon,
        rsaPinTwoIcon,
        rsaPinThreeIcon,
        CheckIcon,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
        },
        text: {
            type: String,
            required: true,
        },
        pinnedTo: {
            type: Number,
            required: true,
        },
        rsa: {
            type: Object as PropType<Partial<RsaWriter.RsaAd>>,
            required: true,
        },
    },
    emits: ['update:text', 'update:pinnedTo'],
    setup(props, { emit }) {
        const popoutOpen = ref(false)
        const textRef = ref()
        const currentPinPosition = ref(props.pinnedTo)

        const handleSelection = (pinnedTo: number) => {
            currentPinPosition.value = pinnedTo
            popoutOpen.value = false
            textRef.value.inputRef.focus()
        }

        const textUpdated = (newText: string) => {
            emit('update:text', newText)
        }

        watch(currentPinPosition, (newPinnedTo) => {
            emit('update:pinnedTo', newPinnedTo)
        })

        const validateDuplicateText = (text: string) => {
            if (text.trim().length === 0) {
                return
            }
            const descriptionsWithThisText = props.rsa.descriptions?.filter((d) => d.text === text) ?? []

            if (descriptionsWithThisText?.length > 1) {
                return 'This description is already used in another position'
            }
        }

        // When other descriptions change, revalidate this description to ensure it's not a duplicate
        watch(
            () => props.rsa.descriptions?.map((d) => d.text) ?? [],
            async () => {
                await delay(10)
                textRef.value?.validate()
            },
            { deep: true },
        )

        return {
            popoutOpen,
            currentPinPosition,
            handleSelection,
            textUpdated,
            textRef,
            getCharCount,
            validateDuplicateText,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.pin-popout {
    @include pa-24;
}
.pin-dialog {
    @include container;
}
.pin-item {
    @include flex;
    @include items-center;
    @include justify-between;
    padding: 1.0625rem 1.25rem;
    width: 18.25rem;
    border-bottom: 1px solid $opteo-light-gray;
    cursor: pointer;
    @include no-select;
}
.pin-item.last {
    border-bottom: none;
}
</style>
