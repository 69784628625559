<template>
    <oTable
        :headers="headers"
        :items="items"
        :fixed-layout="true"
        :border-radius="20"
        :per-page="5"
        :no-shadow="tableType === 'worstRsas'"
        :responsive-mode="tableType === 'worstRsas' ? 'columns' : 'stacked'"
        :responsive-breakpoint="tableType === 'worstRsas' ? '767px' : undefined"
        responsive-col-size="40% 1fr"
        :order-by="tableType === 'breakdown' ? 'cost' : undefined"
        :order="tableType === 'breakdown' ? 'DESC' : undefined"
        :class="{ 'pdf-shadow-fix': tableType === 'breakdown' && mode === 'pdf' }"
        @mouseenter-row="(row: TableItem) => handleMouseenterRow(row)"
        @mouseleave-row="currentlyHoveredRsaAdId = null"
        :persist-height="true"
    >
        <template #header.rsa>
            <div class="rsa-header">RSA</div>
        </template>
        <template #column.rsa="cellData">
            <div class="cell-container pl-24">
                <EntityPill
                    :type="Improvement.LocationEntity.Ad"
                    :content="formatRsaAdId(cellData.value)"
                />
            </div>
        </template>
        <template #column.cost="cellData">
            <div class="cell-container">
                <Money :currency="domainCurrency" :value="cellData.value" />
            </div>
        </template>
        <template #column.cpa="cellData">
            <div class="cell-container">
                <Money :currency="domainCurrency" :value="cellData.value" />
            </div>
        </template>
        <template #column.roas="cellData">
            <div class="cell-container">
                <Roas :value="cellData.value" />
            </div>
        </template>
        <template #column.vsAvg="cellData">
            <template v-if="tableType === 'worstRsas' && mode === 'app'">
                <div class="cell-container">
                    <Text as="span" :color="handleVsAvgColour(cellData.value)" weight="500">
                        <Percent :value="cellData.value" :decimal-places="2" :include-sign="true" />
                    </Text>

                    <transition name="button">
                        <div
                            v-if="cellData.row.rsa === currentlyHoveredRsaAdId"
                            class="open-worst-ad-button-container"
                        >
                            <OpenRsaButton
                                v-if="!enabledRsasLoading"
                                :rsa-ad-id="cellData.row.rsa"
                                :ad-group-id="cellData.row.adGroupId"
                                :campaign-id="cellData.row.campaignId"
                                :enabled-rsas="enabledRsas"
                                :mode="mode"
                                :unsupported="!cellData.row.rsa"
                            />
                        </div>
                    </transition>
                </div>
            </template>
            <template v-else>
                <Text as="span" :color="handleVsAvgColour(cellData.value)" weight="500">
                    <Percent :value="cellData.value" :decimal-places="2" :include-sign="true" />
                </Text>
            </template>
        </template>

        <!-- Rows unique to Breakdown -->
        <template #column.adGroup="cellData">
            <div class="column-overlay">
                <Tooltip
                    :content="cellData.value.length > 12 ? cellData.value : ''"
                    :offset="[0, 8]"
                    placement="top-start"
                >
                    <EntityPill
                        :type="Improvement.LocationEntity.AdGroup"
                        :content="cellData.value"
                    />
                </Tooltip>
            </div>
        </template>
        <template #column.conversions="cellData">
            <NumberVue :value="cellData.value" />
        </template>
        <template #column.conversionsValue="cellData">
            <Money :currency="domainCurrency" :value="cellData.value" />
        </template>
        <template #column.health="cellData">
            <Popout
                :offset="[-34, 18]"
                :interactive="false"
                :borderRadius="32"
                :z-index="2147483005"
                trigger="mouseenter"
                placement="top"
                max-width="24.0625rem"
                :popperOptions="{
                    strategy: 'fixed',
                }"
            >
                <ScoreDonutValueCombo
                    :key="cellData.value"
                    :score="cellData.value"
                    data-type="percentage"
                />
                <template #content>
                    <ScoreList :items="getRsaSubScores(cellData.row.rsa)" />
                </template>
            </Popout>
        </template>
        <template #column.action="cellData">
            <OpenRsaButton
                v-if="!enabledRsasLoading"
                :rsa-ad-id="cellData.row.rsa"
                :ad-group-id="cellData.row.rsa.adGroupId"
                :campaign-id="cellData.row.rsa.campaignId"
                :enabled-rsas="enabledRsas"
                :mode="mode"
                :unsupported="!cellData.row.rsa"
            />
        </template>
    </oTable>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
    EntityPill,
    oTable,
    Money,
    Number as NumberVue,
    Percent,
    Popout,
    Roas,
    Text,
    Tooltip,
    type TextColor,
} from '@opteo/components-next'
import { Improvement } from '@opteo/types'

import ScoreList from '../layouts/ScoreList.vue'
import ScoreDonutValueCombo from '../ScoreDonutValueCombo.vue'
import OpenRsaButton from './OpenRsaButton.vue'
import { SubScoreHeaderText } from './types'

import type { RsaWriter } from '@opteo/types'
import type { BreakdownTableItem, SubScoresPerRsa, WorstRsasTableItem } from './types'
import type { SectionProps } from '../../../composition/toolkit/scorecard/types'

type TableItem = BreakdownTableItem | WorstRsasTableItem
type Props = Pick<SectionProps<'rsa'>, 'domainCurrency' | 'isUsingCpa' | 'mode'> & {
    tableType: 'breakdown' | 'worstRsas'
    headers: InstanceType<typeof oTable>['$props']['headers']
    items: TableItem[]
    subScoresPerRsa: SubScoresPerRsa
    enabledRsas: RsaWriter.GadsRsa[] | undefined
    enabledRsasLoading: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<(e: 'mouseenterRow', rsaAdId: number) => void>()

const formatRsaAdId = (rsaAdId: number) => `ID: ${rsaAdId}`

const handleVsAvgColour = (vsAvg: number): 'red' | 'green' => {
    if (props.isUsingCpa) {
        return (vsAvg > 0 ? 'red' : 'green') satisfies TextColor
    } else {
        return (vsAvg < 0 ? 'red' : 'green') satisfies TextColor
    }
}

const currentlyHoveredRsaAdId = ref<number | null>(null)
const handleMouseenterRow = (row: TableItem) => {
    currentlyHoveredRsaAdId.value = row.rsa
    emit('mouseenterRow', row.rsa)
}

const getRsaSubScores = (rsaAdId: number) => {
    if (typeof rsaAdId !== 'number' || !props.subScoresPerRsa[rsaAdId]) {
        throw new Error(`No sub-scores found for RSA with id: ${rsaAdId}`)
    }

    const subScores = props.subScoresPerRsa[rsaAdId]
    const items: InstanceType<typeof ScoreList>['$props']['items'] = []

    const isValidSubScoreKey = (
        subScoreKey: string
    ): subScoreKey is keyof typeof SubScoreHeaderText => {
        return Object.keys(SubScoreHeaderText).some(key => key === subScoreKey)
    }

    Object.entries(subScores).forEach(([subScoreKey, subScoreValue]) => {
        if (!isValidSubScoreKey(subScoreKey) || subScoreKey === 'adVariations') {
            throw new Error(`Invalid sub-score: ${subScoreKey}`)
        }

        items.push({
            text: SubScoreHeaderText[subScoreKey],
            scoreDonut: {
                score: subScoreValue,
                dataType: 'percentage',
                isPercentFinal: true,
            },
        })
    })

    return items
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.rsa-header {
    @include pl-24;
}
.column-overlay {
    max-width: 100%;
    height: 4.5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.cell-container {
    max-width: 100%;
    height: 4.5rem;
    @include flex;
    @include items-center;
    @include relative;

    .open-worst-ad-button-container {
        @include absolute;
        @include flex;
        @include justify-end;
        @include items-center;
        right: 0;
        @include pr-20;
        width: 100%;
    }
    .open-worst-ad-button-container::before {
        content: '';
        background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 1) 1.5rem,
            rgba(255, 255, 255, 0) 100%
        );
        @include absolute;
        top: 0;
        right: 0;
        width: 8rem;
        bottom: 0;
    }
}
.column-overlay::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}

.health-score {
    @include flex;
    @include items-center;
    gap: 0.5rem;
}

// Transtition
.button-enter-active,
.button-leave-active {
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.button-enter-from,
.button-leave-to {
    opacity: 0;
    transform: translateY(8px);
}

@media screen and (max-width: $mq-767-max) {
    .rsa-header,
    .column-overlay,
    .cell-container {
        padding-left: unset;
        height: unset;
    }
}
</style>
