<template>
    <Modal v-model="microsoftBetaModal" width="68rem" noPadding>
        <template v-slot:title>
            <Text as="h3" style="line-height: 2.625rem">Microsoft Ads Beta</Text>
            <Spacer width="1rem" />
            <ColorTag color="blue" title="Coming Soon" />
        </template>
        <template v-slot:content>
            <div class="microsoft-beta-modal">
                <!-- Modal content block -->
                <div class="microsoft-beta-modal-content first">
                    <!-- Microsoft Connection Status -->
                    <!-- Only visible when 'microsoftConnectionStatus' is 'connecting', 'connected', or 'error' -->
                    <!-- Connecting -->
                    <div
                        v-if="microsoftConnectionStatus === 'connecting'"
                        class="microsoft-connection-status"
                    >
                        <div class="header">
                            <Spinner />
                            <Spacer width="1rem" />
                            <Text as="h6" weight="600">Connecting Microsoft Ads MCC</Text>
                        </div>
                        <div class="content">
                            <Text as="p" size="f-8">
                                Your Microsoft Ads MCC is being connected to Opteo. This process
                                should take no more than a few minutes. Feel free to close this
                                window and check back later for updates.
                            </Text>
                        </div>
                    </div>
                    <!-- Connected -->
                    <div
                        v-if="microsoftConnectionStatus === 'connected'"
                        class="microsoft-connection-status"
                    >
                        <div class="header">
                            <CheckIcon />
                            <Spacer width="1rem" />
                            <Text as="h6" weight="600">Microsoft Ads MCC Connected</Text>
                        </div>
                        <div class="content">
                            <Text as="p" size="f-8">
                                Your Microsoft Ads MCC has successfully been connected to Opteo. As
                                soon as the beta is ready for release, you will automatically
                                receive access to all Microsoft features.
                            </Text>
                        </div>
                    </div>
                    <!-- Error -->
                    <div
                        v-if="microsoftConnectionStatus === 'error'"
                        class="microsoft-connection-status"
                    >
                        <div class="header">
                            <WarnIcon />
                            <Spacer width="1rem" />
                            <Text as="h6" weight="600">Error Connecting Microsoft Ads</Text>
                        </div>
                        <div class="content">
                            <Text as="p" size="f-8">
                                There was an error connecting your Microsoft Ads MCC to Opteo.
                                Please try connecting your MCC again. Otherwise please contact
                                support for assistance with this issue.
                            </Text>
                        </div>
                    </div>
                    <!-- Intro paragraph -->
                    <Text as="h4" weight="600">Opteo for Microsoft Ads</Text>
                    <Spacer height="1.5rem" />
                    <Text as="p">
                        With our Microsoft Ads integration set for release in the coming months, the
                        time has come to <b>open registration for our upcoming private beta</b>.
                        Connect your Microsoft Ads MCC to be added to the invite list. As soon as
                        the beta is ready for release, you'll automatically receive access to all
                        new features.
                    </Text>
                </div>
                <!-- Image carousel -->
                <Carousel
                    v-if="true"
                    :arrows="true"
                    :options="{ align: 'center', speed: 7 }"
                    padding="3.75rem 0 3.875rem 0"
                    arrow-horizontal-padding="2rem"
                    style="width: 100%"
                >
                    <template #slides>
                        <div class="slide" v-for="image in carouselImages" :key="image">
                            <div
                                :style="[
                                    { paddingTop: (388 / 678) * 100 + '%' },
                                    { height: '0px' },
                                ]"
                                class="slide-wrapper"
                            >
                                <oImage :src="image" />
                            </div>
                        </div>
                    </template>
                </Carousel>
                <!-- Modal content block -->
                <div class="microsoft-beta-modal-content last">
                    <Text as="h4" weight="600">Included in the beta</Text>
                    <Spacer height="1.5rem" />
                    <Text as="p">
                        Get access to everything in our upcoming Microsoft Ads release, including
                        <b>Google Ads Sync</b>, <b>Improvements</b>, and a few extras like Campaign
                        Groups and Settings. Keep scrolling for a summary of each of the new
                        features:
                    </Text>
                    <Spacer height="3rem" />
                    <Text as="h4" weight="600">Google Ads Sync</Text>
                    <Spacer height="1.5rem" />
                    <Text as="p">
                        A smart feature to help improve Microsoft Ads performance without adding
                        hours of work. Rather than simply recreating your account from Google Ads,
                        <b>Google Ads Sync</b> gives you the best of both worlds — regular updates
                        from Google Ads and tailor-made adjustments for your Microsoft Ads account.
                    </Text>
                    <Spacer height="2rem" />
                    <div
                        :style="[{ paddingTop: (275 / 480) * 100 + '%' }, { height: '0px' }]"
                        class="slide-wrapper"
                    >
                        <oImage :src="microsoftSyncInline" />
                    </div>
                    <Spacer height="3rem" />
                    <Text as="h4" weight="600">Improvements</Text>
                    <Spacer height="1.5rem" />
                    <Text as="p">
                        A selection of our most popular improvement types, adjusted and updated to
                        work with your Microsoft Ads accounts. The beta includes improvements like
                        <b>Add New Keyword</b>, <b>Fix Below First Page Bid</b>, and
                        <b>Adjust Keyword Bid</b>, with dozens more improvement types scheduled for
                        release over the next year.
                    </Text>
                    <Spacer height="2rem" />
                    <div
                        :style="[{ paddingTop: (194 / 339) * 100 + '%' }, { height: '0px' }]"
                        class="slide-wrapper"
                    >
                        <oImage :src="microsoftImprovementsInline" />
                    </div>
                    <Spacer height="3rem" />
                    <Text as="h4" weight="600">Settings and Campaign Groups</Text>
                    <Spacer height="1.5rem" />
                    <Text as="p">
                        You'll also have access to secondary features like <b>Settings</b> and
                        <b>Campaign Groups</b> to ensure your accounts are set up for success. Like
                        improvements, we have loads of features scheduled for release over the
                        coming months.
                    </Text>
                    <Spacer height="2rem" />
                    <div
                        :style="[{ paddingTop: (194 / 339) * 100 + '%' }, { height: '0px' }]"
                        class="slide-wrapper"
                    >
                        <oImage :src="microsoftCampaignGroupsInline" />
                    </div>
                    <Spacer height="3rem" />
                    <Text as="p">
                        If you have an idea for a Microsoft feature, or would like to see us to
                        prioritise the development of an existing Opteo feature for Microsoft,
                        please message our support team or <a>submit a feature request</a>. Your
                        feedback is invaluable to the product development process and appreciated by
                        everyone on the team.
                    </Text>
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <oButton color="white" @clicked="microsoftBetaModal = !microsoftBetaModal">
                Close
            </oButton>
            <Spacer width="0.75rem" />
            <oButton
                :loading="microsoftConnectionStatus === 'connecting'"
                color="green"
                @clicked="connectMicrosoftAds"
            >
                Connect Microsoft Ads
                <template v-slot:icon>
                    <RightArrowIcon hex="#fff" />
                </template>
            </oButton>
        </template>
    </Modal>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useMicrosoftAdsBeta } from '@/composition/misc/useMicrosoftAdsBeta'
import {
    oButton,
    Spacer,
    Text,
    Modal,
    Carousel,
    oImage,
    ColorTag,
    Spinner,
    CheckIcon,
    WarnIcon,
    RightArrowIcon,
} from '@opteo/components-next'

// Import images from `assets`
// Carousel images
import microsoftSync from '@/assets/img/microsoft-sync.png'
import microsoftImprovements from '@/assets/img/microsoft-improvements.png'
import microsoftAccountCentre from '@/assets/img/microsoft-account-centre.png'
import microsoftCampaignGroups from '@/assets/img/microsoft-campaign-groups.png'
// Inline images
import microsoftSyncInline from '@/assets/img/microsoft-sync-inline.png'
import microsoftImprovementsInline from '@/assets/img/microsoft-improvements-inline.png'
import microsoftCampaignGroupsInline from '@/assets/img/microsoft-campaign-groups-inline.png'

export default defineComponent({
    name: 'MicrosoftAdsBeta',
    components: {
        oButton,
        Spacer,
        Text,
        Modal,
        Carousel,
        oImage,
        ColorTag,
        Spinner,
        CheckIcon,
        WarnIcon,
        RightArrowIcon,
    },
    setup() {
        const { microsoftBetaModal, microsoftConnectionStatus, connectMicrosoftAds } =
            useMicrosoftAdsBeta()

        const carouselImages = ref([
            microsoftSync,
            microsoftImprovements,
            microsoftAccountCentre,
            microsoftCampaignGroups,
        ])

        return {
            status,
            microsoftBetaModal,
            microsoftConnectionStatus,
            carouselImages,
            connectMicrosoftAds,
            // Images
            microsoftSync,
            microsoftImprovements,
            microsoftAccountCentre,
            microsoftCampaignGroups,
            microsoftSyncInline,
            microsoftImprovementsInline,
            microsoftCampaignGroupsInline,
        }
    },
})
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.microsoft-beta-modal {
    padding-top: 4rem;
    padding-bottom: 4rem;
    @include flex;
    flex-direction: column;
    align-items: center;
}

.microsoft-beta-modal .slide {
    // Essential CSS for slide
    // this flex value sets the width of the card
    // https://www.embla-carousel.com/guides/slide-sizes/
    flex: 0 0 42.375rem;
    max-width: 100%;
    height: 100%;
    max-height: 29.5rem;
    // margin-right sets the gap between each card
    // https://www.embla-carousel.com/guides/slide-gaps/
    margin-right: 2.75rem;
}
.microsoft-beta-modal .slide-wrapper {
    @include container;
    @include br-16;
    overflow: hidden;
    @include relative;
}
.microsoft-beta-modal .slide-wrapper .o-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.microsoft-beta-modal .microsoft-beta-modal-content.first {
    max-width: 30.25rem;
    // margin: 4.25rem auto 0 auto;
}
.microsoft-beta-modal .microsoft-beta-modal-content.last {
    max-width: 30.25rem;
    // margin: 0 auto 4.25rem auto;
}

.microsoft-beta-modal .microsoft-connection-status {
    @include container;
    @include br-20;
    @include pa-32;
    @include mb-64;
}
.microsoft-beta-modal .microsoft-connection-status .header {
    @include flex;
    @include items-center;
    @include mb-28;
}
.microsoft-beta-modal .microsoft-connection-status .content {
    @include container;
    @include br-16;
    @include pa-24;
}
</style>
