<template>
    <OpteoLogo :width="44" :height="44" class="opteo-logo" />
    <LoginContainer :width="382" title="Welcome to Opteo">
        <template v-slot:illustration>
            <div class="illustration-container">
                <img src="@/assets/img/welcome-to-opteo-small.png" />
            </div>
        </template>
        <template v-slot:info>
            Your account has been unlocked and your two-month free trial has been activated. If you
            have any questions, don't hesitate to contact our support team.
        </template>
        <template v-slot:content>
            <oButton size="login" @clicked="toOpteo">Go to Account Centre</oButton>
        </template>
    </LoginContainer>
</template>

<script lang="ts">
import LoginContainer from '@/components/global/LoginContainer.vue'

import { oButton, oInput, OpteoLogo } from '@opteo/components-next'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

export default {
    name: 'Thanks',
    components: {
        LoginContainer,
        oButton,
        oInput,
        OpteoLogo,
    },
    setup() {
        const router = useRouter()

        const toOpteo = () => router.push({ name: Routes.Login })

        return {
            toOpteo,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.illustration-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    aspect-ratio: 15 / 8;
}
.illustration-container img {
    width: 100%;
}

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}
@media screen and (max-width: $mq-767-max) {
    .opteo-logo {
        display: none;
    }
}
</style>
