import { createApp } from 'vue'

import { LOAD_GTM, LOAD_SENTRY, SENTRY_ENV } from '@/lib/env'
import initialiseGtm from '@/lib/gtm'
import initialiseSentry from '@/lib/sentry'

import App from './App.vue'

import '@opteo/components-next/css'

// Fonts
import './assets/css/fonts/circular.css'
import './assets/css/fonts/roboto.css'
import './assets/css/fonts/ia-writer-mono.css'

// Temporary Global Imports
import ImprovementContent from '@/components/improvement/ImprovementContent.vue'
import ImprovementCreatedDate from '@/components/improvement/ImprovementCreatedDate.vue'
import ImprovementHeader from '@/components/improvement/ImprovementHeader.vue'
import ImprovementNote from '@/components/improvement/ImprovementNote.vue'
import ImprovementStatistics from '@/components/improvement/ImprovementStatistics.vue'

import router from './router'

import OpteoComponents from '@opteo/components-next'
import { GesturePlugin } from '@vueuse/gesture'

const startApp: () => void = async () => {
    if (LOAD_GTM) {
        initialiseGtm()
    }

    const app = createApp(App)

    if (LOAD_SENTRY) {
        initialiseSentry(app, router, SENTRY_ENV)
    }

    app.use(router)
    app.use(OpteoComponents)
    app.use(GesturePlugin)

    // Temporary Global Imports
    app.component('ImprovementContent', ImprovementContent)
        .component('ImprovementCreatedDate', ImprovementCreatedDate)
        .component('ImprovementHeader', ImprovementHeader)
        .component('ImprovementNote', ImprovementNote)
        .component('ImprovementStatistics', ImprovementStatistics)

    app.mount('#app')
}

startApp()
