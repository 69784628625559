import { Routes } from '@/router/routes'
import { useLocalStorage } from '@vueuse/core'
import { onMounted, unref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { authRequest, Endpoint } from './api/useAPI'

export function useEmailLink() {
    const route = useRoute()
    const router = useRouter()

    let userId: number | null = route.query.u ? +route.query.u : null

    const localUserId = useLocalStorage<number>('opt-uid', null)
    const urlUserId = route.query.u
    const isSlack = route.query.medium === 'slack'

    const updateSettingsFromLink = (userId: number, id: string) => {
        return authRequest(Endpoint.UnsubEmailLink, {
            body: {
                user_id: userId,
                hash: id,
            },
        })
    }

    const toOpteo = () => router.push({ name: Routes.Login })

    onMounted(async () => {
        // If the user is already signed in, use that id for the email unsub link
        if (urlUserId === ':user_id' && unref(localUserId)) {
            userId = +localUserId.value
        }
        // Redirect to login (and back here) if not signed in already
        if (urlUserId === ':user_id' && !unref(localUserId)) {
            const path = `/login?to=/link/${route.params.id}?u=:user_id`
            router.push(path)
        }
        if (userId) {
            await updateSettingsFromLink(userId, route.params.id as string)
        }
    })

    return {
        isSlack,
        toOpteo,
    }
}
