<template>
    <div class="score-donut-value-combo">
        <ScorecardDonut
            :score="score"
            :compact="true"
            :width="22"
            :strokeWidth="2"
            :hideScore="true"
            :hidden="hidden"
            @score-color="(e: string) => setTextColour(e)"
        />
        <Text as="p" size="f-8" weight="500" :color="hidden ? 'gray' : textColour">
            <span v-if="hidden">N/A</span>
            <NumberVue v-else-if="dataType === 'number'" :value="scoreDisplayValue" />
            <Percent
                v-else
                :value="scoreDisplayValue"
                :decimal-places="0"
                :include-sign="includeSign"
            />
        </Text>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'
import { Text, Percent, Number as NumberVue, TextColor, DataType } from '@opteo/components-next'

type Props = {
    score: number
    dataType?: typeof DataType.number | typeof DataType.percentage
    isPercentFinal?: boolean
    hidden?: boolean
    includeSign?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    dataType: 'number',
    isPercentFinal: true,
    hidden: false,
    includeSign: false,
})
const scoreDisplayValue = computed(() => {
    if (props.dataType === 'number') {
        return props.score
    }
    const divider = props.isPercentFinal ? 100 : 1
    return props.score / divider
})
const textColour = ref<TextColor>('black')
const setTextColour = (colour: string) => {
    // TODO: unhardcode this by either changing ScorecardDonut to use TextColor type or by adding types to it in a different folder
    const colourCombos: Record<string, TextColor> = {
        '#00a861': 'green',
        '#FF9500': 'amber',
        '#FF2828': 'red',
        '#e6e6e6': 'gray',
    }
    if (colourCombos[colour]) {
        textColour.value = colourCombos[colour]
    }
}
</script>

<style scoped lang="scss">
.score-donut-value-combo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 3.75rem;
}
</style>
