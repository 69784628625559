<template>
    <ImprovementRowContainer :improvement="improvement" @improvement-opened="openImprovement">
        <template #icon>
            <CheckIcon animated color="green" />
        </template>
        <template #buttons>
            <oButton @clicked="openImprovement">Open Improvement</oButton>
        </template>
    </ImprovementRowContainer>
</template>
<script lang="ts">
import { useRouter } from 'vue-router'

import { PropType } from 'vue'
import { Improvement } from '@opteo/types'
import { oButton, CheckIcon } from '@opteo/components-next'

import { Routes } from '@/router/routes'
import ImprovementRowContainer from '@/components/improvement/ImprovementRowContainer.vue'

export default {
    components: {
        oButton,
        CheckIcon,
        ImprovementRowContainer,
    },
    props: {
        improvement: {
            required: true,
            type: Object as PropType<Improvement.Object>,
        },
    },
    setup(props) {
        const router = useRouter()

        const openImprovement = () => {
            const improvementId = props.improvement.improvement_id

            router.push({
                name: Routes.CompletedImprovement,
                params: { improvementId },
            })
        }

        return {
            openImprovement,
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
</style>
