<template>
    <svg width="112" height="64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="last-click-mask"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="112"
            height="64"
        >
            <path fill="#fff" d="M0 0h112v64H0z" />
        </mask>
        <g mask="url(#last-click-mask)" fill="#6E3AFF">
            <rect x="90" width="22" height="64" rx="3" />
            <rect opacity=".2" x="60" y="56" width="22" height="8" rx="3" />
            <rect opacity=".2" x="30" y="56" width="22" height="8" rx="3" />
            <rect opacity=".2" y="56" width="22" height="8" rx="3" />
        </g>
    </svg>
</template>
