import { ref, computed, watch } from 'vue'
import { Platform } from '@opteo/types'

import { Endpoint } from '@/composition/api/endpoints'
import { useAPI } from '@/composition/api/useAPI'
import { useTracking } from '../api/useTracking'

const microsoftBetaModal = ref(false)
const connecting = ref(false)

export function useMicrosoftAdsBeta() {
    const { trackAction } = useTracking()
    const { data: oauthUrl } = useAPI<string>(Endpoint.GetMicrosoftAdsOauthUrl)

    const { data: platformConnection, mutate: refreshPlatformStatus } = useAPI<Platform.Connection>(
        Endpoint.GetPlatformConnectionStatus,
        {
            body: { platform: Platform.Platform.MicrosoftAds },
        }
    )

    const microsoftConnectionStatus = computed(() => {
        if (connecting.value || platformConnection.value?.status === 'syncing') return 'connecting'
        if (platformConnection.value?.status === 'success') return 'connected'
        if (platformConnection.value?.status === 'error') return 'error'
        return 'noConnection'
    })

    // When Microsoft Ads is connecting start an interval to refresh the API request
    // We're not using SWRs refreshInterval here as it can't be dynamic
    let refreshInterval: ReturnType<typeof setInterval> | null = null
    watch(microsoftConnectionStatus, () => {
        const startRefreshInterval = () => {
            refreshInterval = setInterval(() => {
                refreshPlatformStatus()
            }, 2000)
        }
        if (microsoftConnectionStatus.value === 'connecting') {
            startRefreshInterval()
        }
        if (microsoftConnectionStatus.value !== 'connecting' && refreshInterval) {
            clearInterval(refreshInterval)
        }
    })

    const connectMicrosoftAds = async () => {
        await trackAction('started_ms_oauth_flow')

        window.location.href = oauthUrl.value as string
    }

    const toggleBetaModal = () => {
        microsoftBetaModal.value = !microsoftBetaModal.value

        if (microsoftBetaModal.value) {
            trackAction('opened_ms_beta_modal')
        }
    }

    return {
        microsoftBetaModal,
        connecting,
        microsoftConnectionStatus,
        connectMicrosoftAds,
        toggleBetaModal,
    }
}
