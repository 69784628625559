<template>
    <ImprovementRowContainer
        :improvement="improvement"
        :isInteractive="isInteractive"
        @improvement-opened="openImprovement"
    >
        <template #icon>
            <ImprovementBatchIcon
                :improvement="improvement"
                :queued-improvement="queuedImprovement"
                :batch-queue-running="batchQueueRunning"
            />
        </template>
        <template #buttons>
            <Popout
                v-if="isActive"
                v-model="dismissPopoutOpen"
                :trapFocus="true"
                :externalToggleButton="true"
                :offset="[1, 8]"
                :popperOptions="{
                    strategy: 'fixed',
                }"
                placement="bottom-end"
            >
                <oButton
                    :disabled="!isInteractive"
                    color="white"
                    @clicked="dismissPopoutOpen = !dismissPopoutOpen"
                >
                    Dismiss
                    <template #icon>
                        <SelectArrowIcon hex="#006dff" />
                    </template>
                </oButton>

                <template #content>
                    <DismissPopoutContents @dismiss="dismiss" />
                </template>
            </Popout>
            <Spacer width="0.625rem" />
            <oButton v-if="isActive" @clicked="openImprovement" :disabled="!isInteractive">
                Open Improvement
            </oButton>
        </template>
    </ImprovementRowContainer>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { oButton, Popout, SelectArrowIcon, Spacer } from '@opteo/components-next'
import { computed, ref } from 'vue'

import { Routes } from '@/router/routes'
import ImprovementBatchIcon from '@/components/improvement/ImprovementBatchIcon.vue'
import ImprovementRowContainer from '@/components/improvement/ImprovementRowContainer.vue'
import DismissPopoutContents from '@/components/improvement/DismissPopoutContents.vue'

import { useActiveImprovements } from '@/composition/improvement/useActiveImprovements'

import type { QueuedImprovement } from '@/composition/improvement/useImprovementQueue'
import type { DismissDuration, EnhancedImprovement } from '@/composition/improvement/types'

type Props = {
    improvement: EnhancedImprovement
    queuedImprovement: QueuedImprovement | undefined
    queueRunning: boolean
    batchQueueRunning: boolean
}

const props = defineProps<Props>()

const router = useRouter()
const { dismissImprovementWithFeedBack } = useActiveImprovements()

const openImprovement = () => {
    const improvementId = props.improvement.improvement_id

    router.push({
        name: Routes.Improvement,
        params: { improvementId },
    })
}

// if false, the improvment will no longer appear in the active tab
// after the batch queue has run
const isActive = computed(
    () => !['pushed', 'dismissed'].includes(props.queuedImprovement?.state ?? '')
)

const isInteractive = computed(() => {
    /*
        The row should not be clickable if:
        - it's in a running queue (batch or standard)
        -- or --
        - it's in a done queue & pushed/dismissed
    */
    if (!props.queuedImprovement) {
        return true
    }

    if (props.batchQueueRunning) {
        return false
    }

    if (props.queueRunning && props.queuedImprovement.pushingFrom === 'improvementView') {
        return false
    }

    return isActive.value
})

/*
    Dismiss feedback
*/
const dismissPopoutOpen = ref(false)
const dismiss = (length: DismissDuration) => {
    dismissImprovementWithFeedBack(props.improvement.improvement_id, length)
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.dismiss-popout-container {
    @include pa-8;
}

.dismiss-popout-container .popout-row {
    @include mb-4;
}

.dismiss-popout-container .popout-row:last-child {
    @include mb-0;
}

.dismiss-popout-container .popout-button-contents {
    @include flex;
    @include items-center;
    @include justify-center;
}
.dismiss-popout-container .popout-span {
    width: 4.125rem;
    @include block;
}
</style>
