<template>
    <div v-bind="virtualListContainerProps" class="chat-select">
        <div v-if="!entitiesReady" class="chat-entities-loading">
            <ChatSelectSkeletonItem :entityType="entityType as EntityType" />
        </div>
        <ul v-bind="virtualListwrapperProps" v-else-if="entities?.length">
            <li
                v-for="{ data: entity, index } in virtualList"
                :key="entity.id"
                :class="[
                    entity.id ? entity.id : '',
                    entity.type ? entity.type : '',
                    entity.entityClass ? entity.entityClass : '',
                    'chat-item',
                ]"
                :data-state="selectedIndex === index ? 'selected' : null"
                @click="onSelect(index)"
                @mouseenter="onMouseEnter(index)"
            >
                <component v-if="entity.icon" :is="{ ...entity.icon }" :tooltip="false"></component>
                <span v-html="entity.name"></span>
            </li>
        </ul>
        <div class="chat-select-empty-state" v-else>
            <Text size="f-8">No matching {{ emptyState }} </Text>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, watch } from 'vue'
import { useVirtualList, useScroll, useElementSize, useMouse } from '@vueuse/core'
import type { EntityType } from './ChatInput.vue'
import ChatSelectSkeletonItem from './ChatSelectSkeletonItem.vue'
import { useShortKey, Spinner, Text, Spacer } from '@opteo/components-next'

const LIST_ITEM_HEIGHT = 44 // Should match the height of the list item in the CSS

export default defineComponent({
    name: 'ChatSelect',
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        entityType: {
            type: String as PropType<'entity-type' | EntityType>,
            default: 'entity-type',
        },
        entities: {
            type: Array as PropType<
                { id: string | number; icon: { name: string; props: any }; name: string }[]
            >,
            default: [],
        },
        entitiesLoading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Spinner,
        Text,
        Spacer,
        ChatSelectSkeletonItem,
    },
    emits: ['select'],
    setup(props, { emit }) {
        const selectedIndex = ref(0)

        const listLength = computed(() => (props.entities && props.entities.length) ?? 0)

        const entitiesReady = computed(
            // Ready if just selecting an entity type, or if entities are loaded
            () => !props.entitiesLoading || props.entityType === 'entity-type'
        )

        // Create a virtual list that we can use to render only the items that are visible.
        // It needs a computed value to be able to react to changes in the list.
        // Docs: https://vueuse.org/core/useVirtualList/
        const entitiesComputed = computed(() => props.entities)
        const {
            list: virtualList,
            containerProps: virtualListContainerProps,
            wrapperProps: virtualListwrapperProps,
        } = useVirtualList(entitiesComputed, {
            itemHeight: LIST_ITEM_HEIGHT,
        })

        const { x: mouseX, y: mouseY } = useMouse()
        const { y } = useScroll(virtualListContainerProps.ref)
        const { height } = useElementSize(virtualListContainerProps.ref)

        function selectItem(index: number) {
            return props.entities && props.entities[index]
        }

        function onSelect(index: number) {
            selectedIndex.value = index
            emit('select', selectItem(index))
        }

        function resetIndex() {
            selectedIndex.value = 0
        }

        // Increment selectedIndex by +1 and -1 and loop array by ensuring no negative indexes
        function incrementIndex(increment: number) {
            selectedIndex.value =
                (((selectedIndex.value + increment) % listLength.value) + listLength.value) %
                listLength.value

            // Scroll the list to reveal the newly selected element if it's not visible.
            // Using scrollIntoView (either native or exported from useVirtualList) doesn't work very well,
            // so we're using manual indexes and pixel counting instead. Sorry.
            const minVisibleY = y.value
            const maxVisibleY = y.value + height.value

            const selectedElementY = selectedIndex.value * LIST_ITEM_HEIGHT

            allowMouseSelect = false

            // If the newly selected element is above the visible area,
            // scroll to reveal the element at the top of the tippy
            if (selectedElementY < minVisibleY + LIST_ITEM_HEIGHT) {
                virtualListContainerProps.ref.value?.scroll({
                    top: selectedElementY,
                })
            }

            // If the newly selected element is below the visible area,
            // scroll to reveal the element at the bottom of the tippy
            if (selectedElementY > maxVisibleY - LIST_ITEM_HEIGHT) {
                virtualListContainerProps.ref.value?.scroll({
                    top: selectedElementY - height.value + LIST_ITEM_HEIGHT,
                })
            }
        }

        // While using the arrow keys to scroll up and down the list, we don't want mouseenter events to
        // glitch the selection if the cursor happens to be hovering over the list.
        // When the keyboard list navigation is used, we prevent the mouseenter events from doing anything
        // until the mouse is moved again.
        let allowMouseSelect = true
        watch(
            () => [mouseX.value, mouseY.value],
            () => (allowMouseSelect = true)
        )

        function onMouseEnter(index: number) {
            if (!allowMouseSelect) return
            selectedIndex.value = index
        }

        useShortKey({
            keys: ['arrowup'],
            preventDefault: false,
            onPressCallBack: event => {
                if (!props.modelValue) return
                event.preventDefault()
                incrementIndex(-1)
            },
        })

        useShortKey({
            keys: ['arrowdown'],
            preventDefault: false,
            onPressCallBack: event => {
                if (!props.modelValue) return
                event.preventDefault()
                incrementIndex(1)
            },
        })

        useShortKey({
            keys: ['enter'],
            onPressCallBack: () => {
                if (!props.modelValue) return
                emit('select', selectItem(selectedIndex.value))
            },
        })

        useShortKey({
            keys: ['tab'],
            preventDefault: false,
            onPressCallBack: event => {
                if (!props.modelValue) return
                event.preventDefault()
                emit('select', selectItem(selectedIndex.value))
            },
        })

        const emptyState = computed(() =>
            props.entityType === 'campaign'
                ? 'campaigns'
                : props.entityType === 'adgroup'
                ? 'ad groups'
                : props.entityType === 'keyword'
                ? 'keywords'
                : 'entity types'
        )

        watch(
            () => [props.modelValue, props.entities],
            () => {
                resetIndex()
            }
        )

        return {
            selectedIndex,
            onSelect,
            emptyState,
            virtualList,
            virtualListContainerProps,
            virtualListwrapperProps,
            entitiesComputed,
            entitiesReady,
            onMouseEnter,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chat-select {
    @include container;
    box-shadow: $opteo-shadow-l;
    padding: 0.5rem;
    min-width: 192px; // Needs to be wide enough to avoid the list width janking as items scroll into existence too often
    max-height: 23.9375rem;
    overflow-y: auto;
}

.chat-item {
    padding: 0.625rem 0.875rem; // Consider updating LIST_ITEM_HEIGHT if this changes
    cursor: pointer;
    @include flex;
    @include items-center;
    gap: 0.625rem;
    border-radius: 0.75rem;
    @include f-8;
    @include fw-500;
}

.chat-item.entity-item span {
    width: 22.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-item.campaign {
    @include opteo-dark-blue;
}
.chat-item.campaign :deep(svg path) {
    fill: $opteo-dark-blue !important;
}

.chat-item.adgroup {
    @include opteo-dark-purple;
}
.chat-item.adgroup :deep(svg path) {
    fill: $opteo-dark-purple !important;
}

.chat-item.keyword {
    @include opteo-dark-teal;
}
.chat-item.keyword :deep(svg g) {
    fill: $opteo-dark-teal !important;
}

// Selected states
.chat-item.campaign[data-state='selected'] {
    background-color: $opteo-dark-blue-translucent;
}
.chat-item.adgroup[data-state='selected'] {
    background-color: $opteo-dark-purple-translucent;
}
.chat-item.keyword[data-state='selected'] {
    background-color: $opteo-dark-teal-translucent;
}

.chat-select-empty-state {
    padding: 0.5rem 0.75rem;
}
</style>
