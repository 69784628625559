<template>
    <div class="relative">
        <Popout
            :modelValue="showWarning"
            placement="top"
            maxWidth="25rem"
            :on-click-outside="dimissWarning"
            remote-control
        >
            <slot />
            <template #content>
                <div class="warning-container">
                    <div class="warning-icon-container">
                        <svg
                            width="32px"
                            height="32px"
                            viewBox="0 0 32 32"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g
                                id="warning-icon"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <path
                                    d="M16,32 C24.836556,32 32,24.836556 32,16 C32,7.163444 24.836556,0 16,0 C7.163444,0 0,7.163444 0,16 C0,24.836556 7.163444,32 16,32 Z M16,29 C8.82029825,29 3,23.1797017 3,16 C3,8.82029825 8.82029825,3 16,3 C23.1797017,3 29,8.82029825 29,16 C29,23.1797017 23.1797017,29 16,29 Z M17.5,24 L17.5,21 L14.5,21 L14.5,24 L17.5,24 Z M17.5,18 L17.5,8 L14.5,8 L14.5,18 L17.5,18 Z"
                                    id="Combined-Shape"
                                    fill="#FF9500"
                                    fill-rule="nonzero"
                                ></path>
                            </g>
                        </svg>
                    </div>

                    <Text size="f-9" color="white">
                        Take care when adjusting inputs. Changing
                        <b>{{ field }}</b> will cause the previously generated text and any of your
                        annotations to be overwritten.
                    </Text>
                </div>
            </template>
        </Popout>
    </div>
</template>

<script lang="ts">
import { computed, ref } from 'vue'
import { Popout, Text } from '@opteo/components-next'
import { useCreateReport, isExistingReport } from '@/composition/reports/useCreateReport'
export default {
    props: {
        field: {
            type: String,
            required: true,
        },
        inputIsActive: {
            type: Boolean,
            required: true,
        },
    },
    components: { Popout, Text },
    setup: props => {
        const { newReportInfo } = useCreateReport()

        const warningIsDismissed = ref(false)

        function dimissWarning() {
            warningIsDismissed.value = true
        }

        const slidesWereModified = computed(() => {
            if (!newReportInfo.value || !isExistingReport(newReportInfo.value)) {
                return false
            }

            const slides = newReportInfo.value.slides

            return !!slides?.find(slide => !slide.default && !!slide.default_text)
        })

        const showWarning = computed(
            () => slidesWereModified.value && props.inputIsActive && !warningIsDismissed.value
        )

        return { showWarning, warningIsDismissed, dimissWarning }
    },
}
</script>

<style scoped>
.warning-container {
    background-color: #121215 !important;
    padding: 1.1rem 1.4rem;
    display: flex;
}

.warning-icon-container {
    display: flex;
    align-items: center;
    justify-items: center;
    padding-right: 1rem;
}
</style>
