<template>
    <oForm @submitted="updateAccountMonthlyBudget" ref="budgetForm" class="account-budget-form">
        <div v-if="showSectionNote" class="section-note">
            <Text as="p" size="f-8" color="dark-blue">
                Set your monthly budget here. If your account does not have a set monthly budget,
                please leave this field empty.
            </Text>
        </div>
        <Spacer v-if="showSectionNote" height="1.5rem" />
        <oInput
            ref="budgetInput"
            suffix="/month"
            name="budget"
            :model-value="budget"
            :prefix="currencySymbol"
            :validator="budgetValidator"
            @updated="handleBudgetUpdate"
            label="Monthly Budget"
            type="number"
        />
        <template v-if="accountPlatform === Platform.Platform.GoogleAds">
            <Spacer height="1.5rem" />
            <oInput
                ref="budgetVarianceInput"
                suffix="%"
                name="budgetVariance"
                :model-value="
                    budgetVariance?.budget_variance && budgetVariance.budget_variance / 10_000
                "
                @updated="accountBudgetVariance = $event"
                min="0"
                label="Acceptable Variance"
                type="number"
                max="30"
            />
            <Spacer height="1.5rem" />
            <div>
                <Checkbox
                    :model-value="pauseSpendWhenOverBudget ?? budgetVariance?.has_budget_auto_pause"
                    @updated="pauseSpendWhenOverBudget = $event"
                    type="checkbox"
                    name="pauseSpendWhenOverBudget"
                    label="Pause spend when over budget"
                    :disabled="!accountBudget || budgetValidator(accountBudget)"
                />
                <Spacer height="1rem" />
                <Text
                    as="p"
                    size="f-9"
                    style="font-size: 0.75rem; line-height: 1.1875rem"
                    color="gray"
                >
                    This option will automatically pause your campaigns in the event your monthly
                    budget
                    <b>and</b> acceptable variance are exceeded (<b>{{ budgetWithVariance }}</b>
                    total spend).
                    <a
                        href="https://opteo.com/docs/en/articles/6579831-pause-spend-when-over-budget"
                        target="_blank"
                        rel="noopener"
                        class="learn-more-link"
                    >
                        Click to learn more</a
                    >.
                </Text>
            </div>
        </template>
        <template v-else>
            <Spacer height="0.25rem" />
        </template>
        <div v-if="showUpdateButton" class="account-budget-button-container">
            <oButton
                ref="saveBudgetButton"
                :loading="saveBudgetButtonLoading"
                size="medium"
                type="submit"
            >
                Update Account Budget
            </oButton>
        </div>
    </oForm>
</template>

<script lang="ts" setup>
import { Text, Spacer, oInput, Checkbox, oForm, oButton, useMoney } from '@opteo/components-next'

import { computed, ref } from 'vue'
import { Account, Currency, Platform, Tracking } from '@opteo/types'
import { Endpoint, authRequest, useAPI } from '@/composition/api/useAPI'
import { useDomainListRow } from '@/composition/user/useDomainListRow'
import { useAlerts } from '@/composition/alerts/useAlerts'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useTracking } from '@/composition/api/useTracking'

const props = defineProps<{
    accountId: Account.ID
    accountPlatform: Platform.Platform
    currencyCode?: Currency.CurrencyCode
    currencySymbol?: Currency.CurrencySymbol
    showUpdateButton?: boolean
    showSectionNote?: boolean
}>()

const { mutateRowMetrics } = useDomainListRow(ref(props.accountId))
const { mutateAlerts } = useAlerts()
const { trackAction } = useTracking()

// Fetch budget from server
const { data: budget, mutate: refreshBudgetFromServer } = useAPI<number>(Endpoint.GetDomainBudget, {
    uniqueId: () => props.accountId,
    waitFor: () => props.accountId,
    body() {
        return {
            account_id: props.accountId,
        }
    },
})

// Fetch budget variance from server
const { data: budgetVariance, mutate: refreshBudgetVarianceFromServer } = useAPI<{
    budget_variance: number
    has_budget_auto_pause: boolean
}>(Endpoint.GetDomainBudgetVariance, {
    uniqueId: () => props.accountId,
    waitFor: () => props.accountId,
    body() {
        return {
            account_id: props.accountId,
        }
    },
})

// Set default values
const accountBudget = ref(budget.value)
const accountBudgetVariance = ref((budgetVariance.value?.budget_variance ?? 0) / 10_000 ?? 0)
const pauseSpendWhenOverBudget = ref(budgetVariance.value?.has_budget_auto_pause)

function handleBudgetUpdate(value: number) {
    accountBudget.value = value
    if (!accountBudget.value) {
        pauseSpendWhenOverBudget.value = false
    }
}

function disableBudgetAutoPauseIfInvalid(budget: number, value: any) {
    if (!budget || budget < 0) {
        value.localValue = false
    }
}

const budgetValidator = (value: number, has_budget_auto_pause?: any) => {
    if (has_budget_auto_pause) {
        disableBudgetAutoPauseIfInvalid(value, has_budget_auto_pause)
    }

    if (value < 0) {
        return 'Budget must be a positive number'
    }
}

const fieldPauseSpendWhenOverBudget = computed(
    () => budgetVariance?.value?.has_budget_auto_pause ?? false
)

const saveBudgetButton = ref()
const saveBudgetButtonLoading = ref(false)

const intercom = useIntercom()

const budgetInput = ref()
const budgetVarianceInput = ref()

const updateAccountMonthlyBudget = async () => {
    // Return early if no changes
    if (
        accountBudget.value === budget.value &&
        accountBudgetVariance.value === (budgetVariance.value?.budget_variance ?? 0) / 10_000 &&
        pauseSpendWhenOverBudget.value === !!budgetVariance.value?.has_budget_auto_pause
    ) {
        return
    }

    const budgetIsValid = budgetInput.value?.valid
    const budgetVarianceIsValid = budgetVarianceInput.value?.valid

    if (!budgetIsValid) throw new Error('Invalid budget')

    // TODO: Remove this condition when pause spend over budget is available for Microsoft Ads
    if (props.accountPlatform === Platform.Platform.GoogleAds && !budgetVarianceIsValid)
        throw new Error('Invalid budget variance')

    saveBudgetButtonLoading.value = true

    const newBudget = accountBudget.value ?? 0
    const newBudgetVariance = accountBudgetVariance.value
        ? accountBudgetVariance.value * 10_000
        : null

    const enabledBudgetAutoPause =
        pauseSpendWhenOverBudget.value && !fieldPauseSpendWhenOverBudget.value

    const disabledBudgetAutoPause =
        !pauseSpendWhenOverBudget.value && fieldPauseSpendWhenOverBudget.value
    await authRequest(Endpoint.SaveAccountBudget, {
        body: {
            account_id: props.accountId,
            budget: newBudget,
            budget_variance: newBudgetVariance,
            has_budget_auto_pause: newBudget ? pauseSpendWhenOverBudget.value : false,
            /* if disabling has budget auto pause, reactivate paused campaigns*/
            disabled_budget_auto_pause: disabledBudgetAutoPause,
            enabled_budget_auto_pause: enabledBudgetAutoPause,
        },
    })

    if (enabledBudgetAutoPause || disabledBudgetAutoPause) {
        trackAction(
            Tracking.ActionName.PauseSpendWhenOverBudgetAction ??
                'pause_spend_when_over_budget_action',
            {
                action: disabledBudgetAutoPause
                    ? 'disabled'
                    : enabledBudgetAutoPause
                      ? 'enabled'
                      : 'no_action',
            }
        )
    }

    await Promise.all([
        mutateRowMetrics(),
        mutateAlerts(),
        refreshBudgetFromServer(),
        refreshBudgetVarianceFromServer(),
    ])

    saveBudgetButtonLoading.value = false
    saveBudgetButton.value?.flashSuccess()

    intercom.trackEvent('set_budget', { new_budget: budget })
}

// Form validation and formatting
const budgetForm = ref<typeof oForm>()

const budgetWithVariance = computed(() => {
    if (!accountBudget.value)
        return useMoney({
            value: 0,
            currency: props.currencyCode,
        }).displayValue.value

    const isInvalid = !!budgetValidator(accountBudget.value)

    if (isInvalid)
        return useMoney({
            value: 0,
            currency: props.currencyCode,
        }).displayValue.value

    const budgetWithVariance = useMoney({
        value: accountBudget.value * (1 + (accountBudgetVariance.value ?? 0) / 100),
        currency: props.currencyCode,
    }).displayValue.value

    return budgetWithVariance
})

// This is needed to trigger the function from the parent component
defineExpose({
    updateAccountMonthlyBudget,
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.section-content .account-budget-form,
.settings-section .account-budget-form {
    @include pa-32;
    @include pb-28;
}

.account-budget-button-container {
    @include flex-center;
    @include ph-32;
    padding-top: 1.625rem;
    @include pb-4;
}

.learn-more-link {
    color: inherit;
    font-weight: 600;
    outline: none;
    border-radius: 4px;
}
.learn-more-link:focus-visible {
    background: $opteo-dark-blue-translucent;
}
</style>
