<template>
    <ReportLoaderContainer
        :download-url="downloadUrl"
        action="downloading"
        :theme-colours="primaryColour"
        :has-error="hasDownloadError"
    >
    </ReportLoaderContainer>

    <ReportFooter :title="report?.title" :subTitle="reportPeriodFrom + ' → ' + reportPeriodTo">
        <oButton @clicked="returnToReports" color="white" class="w-auto">Close Window</oButton>
    </ReportFooter>
</template>

<script lang="ts">
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { oButton } from '@opteo/components-next'

import ReportFooter from '@/components/Reports/ReportFooter.vue'
import ReportLoaderContainer from '@/components/Reports/ReportLoaderContainer.vue'

import { useReport } from '@/composition/reports/useReport'
import { Routes } from '@/router/routes'

export default {
    name: 'ReportDownload',
    components: {
        ReportFooter,
        ReportLoaderContainer,
        oButton,
    },
    setup() {
        const { push } = useRouter()
        const {
            report,
            downloadReport,
            primaryColour,
            isArchive,
            reportPeriodFrom,
            reportPeriodTo,
        } = useReport()
        const downloadUrl = ref('')

        const hasDownloadError = ref(false)

        onMounted(async () => {
            try {
                const url = await downloadReport()
                downloadUrl.value = url
            } catch (err) {
                console.log(err)
                hasDownloadError.value = true
            }
        })

        const returnToReports = () => {
            push({ name: isArchive.value ? Routes.ReportsArchive : Routes.ReportsActive })
        }

        return {
            returnToReports,
            primaryColour,
            downloadUrl,
            report,
            hasDownloadError,
            reportPeriodFrom,
            reportPeriodTo,
        }
    },
}
</script>

<style scoped></style>
