<template>
    <oTable
        class="table"
        :headers="headers"
        :items="items"
        :fixed-layout="true"
        :border-radius="20"
        responsive-mode="columns"
        responsive-breakpoint="767px"
        :class="{ 'pdf-shadow-fix': isPdf }"
        order-by="cost"
        order="DESC"
    >
        <template #header.campaignName>
            <div class="header-cell">Campaign</div>
        </template>
        <template #column.campaignName="cellData">
            <div class="column-overlay">
                <Tooltip
                    :content="
                        isSummaryTable && cellData.value.length > 4
                            ? cellData.value
                            : cellData.value.length > 34
                            ? cellData.value
                            : ''
                    "
                    :offset="[0, 8]"
                    placement="top-start"
                    style="width: 100%"
                >
                    <EntityPill type="campaign" :content="cellData.value" :tooltip="false" />
                </Tooltip>
            </div>
        </template>
        <template #column.costPercent="cellData">
            <Percent :value="cellData.value" :decimal-places="1" />
        </template>
        <template #column.convPercent="cellData">
            <Percent :value="cellData.value" :decimal-places="1" />
        </template>
        <template #column.valuePercent="cellData">
            <Percent :value="cellData.value" :decimal-places="1" />
        </template>
        <template #column.costConvDiff="cellData">
            <Text as="span" :color="cellData.value > 0 ? 'green' : 'red'" weight="500">
                <Percent :value="cellData.value" :decimal-places="1" :include-sign="true" />
            </Text>
        </template>
        <template #column.costValueDiff="cellData">
            <Text as="span" :color="cellData.value > 0 ? 'green' : 'red'" weight="500">
                <Percent :value="cellData.value" :decimal-places="1" :include-sign="true" />
            </Text>
        </template>
        <template #column.cpa="cellData">
            <Money :currency="domainCurrency" :value="cellData.value" />
        </template>
        <template #column.roas="cellData">
            <Roas :value="cellData.value" :decimal-places="1" />
        </template>
        <template #column.cpaDiff="cellData">
            <Text
                v-if="!cellData.value || cellData.value === 0"
                as="span"
                color="gray"
                weight="500"
            >
                <Percent :value="0" :decimal-places="1" :include-sign="true" />
            </Text>
            <Text v-else as="span" :color="handleVsAvgColour(cellData.value)" weight="500">
                <Percent :value="cellData.value" :decimal-places="1" :include-sign="true" />
            </Text>
        </template>
        <template #column.roasDiff="cellData">
            <Text
                v-if="!cellData.value || cellData.value === 0"
                as="span"
                color="gray"
                weight="500"
            >
                <Percent :value="0" :decimal-places="1" :include-sign="true" />
            </Text>
            <Text v-else as="span" :color="handleVsAvgColour(cellData.value)" weight="500">
                <Percent :value="cellData.value" :decimal-places="1" :include-sign="true" />
            </Text>
        </template>
        <template #column.action="cellData">
            <div :class="['action-tag', handleActionColour(cellData.value)]">
                <Text as="p" size="f-8" weight="500" :color="handleActionColour(cellData.value)">
                    {{ handleActionText(cellData.value) }}
                    <Percent
                        v-if="cellData.value > 0 || cellData.value < 0"
                        :value="Math.abs(cellData.value)"
                        :decimal-places="0"
                        :include-sign="false"
                    />
                </Text>
            </div>
        </template>
    </oTable>
</template>

<script lang="ts" setup>
import {
    Text,
    oTable,
    Money,
    Roas,
    Number,
    Percent,
    EntityPill,
    Tooltip,
    type TextColor,
} from '@opteo/components-next'

const props = defineProps<{
    headers: {
        key: string
        text: string
        width?: number
        sortable?: boolean
        noPadding?: boolean
    }[]
    items: {
        campaignName: string
        cost?: number
        conversions?: number
        conversionsValue?: number
        costPercent: number
        convPercent: number
        valuePercent: number
        costConvDiff?: number
        costValueDiff?: number
        cpa: number
        cpaDiff: number
        roas: number
        roasDiff: number
        action?: string
    }[]
    isUsingCpa: boolean
    domainCurrency: string
    mode: 'live' | 'pdf' | 'app'
    isSummaryTable?: boolean
}>()

const isPdf = props.mode === 'pdf'

const handleVsAvgColour = (vsAvg: number): 'red' | 'green' => {
    if (props.isUsingCpa) {
        return (vsAvg > 0 ? 'red' : 'green') satisfies TextColor
    } else {
        return (vsAvg < 0 ? 'red' : 'green') satisfies TextColor
    }
}
const handleActionColour = (value: number): 'red' | 'green' | 'blue' => {
    return (value > 0 ? 'green' : value < 0 ? 'red' : 'blue') satisfies TextColor
}
const handleActionText = (
    value: number
): 'Increase spend by' | 'Decrease spend by' | 'Maintain daily spend' => {
    return value > 0
        ? 'Increase spend by'
        : value < 0
        ? 'Decrease spend by'
        : 'Maintain daily spend'
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.header-cell {
    @include pl-24;
}
.column-overlay {
    max-width: 100%;
    height: 4.5rem;
    overflow: hidden;
    @include pl-24;
    @include flex;
    @include items-center;
}
.column-overlay::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}

.action-tag {
    @include inline-flex;
    @include items-center;

    padding: 0.5625rem 0.875rem;
    @include br-999;
    user-select: none;
}
.action-tag.red {
    background-color: $opteo-red-translucent;
}
.action-tag.green {
    background-color: $opteo-green-translucent;
}
.action-tag.blue {
    background-color: $opteo-blue-translucent;
}
.action-tag p {
    font-size: 0.8125rem;
    line-height: 0.875rem;
    letter-spacing: -0.01rem;
}

@media screen and (max-width: $mq-767-max) {
    .header-cell {
        padding-left: unset;
    }
    .column-overlay {
        height: unset;
        padding-left: unset;
        overflow: unset;
        @include w-100;
        min-width: unset;
    }
    .column-overlay::after {
        display: none;
    }
}
</style>
