<template>
    <router-link to="/">
        <OpteoLogo :width="44" :height="44" class="opteo-logo" />
    </router-link>
    <LoginContainer title="Login">
        <template v-slot:google>
            <!-- Login with Google -->
            <WithGoogle string="Login with Google" />
        </template>
        <template v-slot:content>
            <!-- Email form -->
            <oForm id="create-account-form" @submitted="login" :validateOnSubmit="true">
                <oInput
                    ref="emailInput"
                    type="email"
                    name="email"
                    label="Email Address"
                    placeholder="johnny@appleseed.com"
                    autocomplete="email"
                    class="mb-24"
                    autofocus
                    required
                    @updated="validatePassword"
                />
                <oInput
                    ref="passwordInput"
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="••••••••••"
                    autocomplete="password"
                    required
                />
                <Spacer height="2rem" />
                <oButton size="login" type="submit" :loading="loading">Login</oButton>
            </oForm>
        </template>
        <template v-slot:links>
            <router-link to="/createaccount" data-cy="create-link">
                Don't have an account? Create one here.
            </router-link>
            <router-link to="/reset" data-cy="reset-link">
                Forgot password? Reset it here.
            </router-link>
        </template>
    </LoginContainer>
</template>

<script lang="ts">
import LoginContainer from '@/components/global/LoginContainer.vue'
import WithGoogle from '@/components/global/WithGoogle.vue'
import { OpteoLogo, oForm, oInput, oButton, Spacer } from '@opteo/components-next'

import { useLogin } from '@/composition/auth/useLogin'

export default {
    components: {
        LoginContainer,
        WithGoogle,
        OpteoLogo,
        oForm,
        oInput,
        oButton,
        Spacer,
    },
    setup() {
        return useLogin()
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}
@media screen and (max-width: $mq-767-max) {
    .opteo-logo {
        display: none;
    }
}
</style>
