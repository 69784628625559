<template>
    <PageContent>
        <div class="reports-grid">
            <ReportGridItemContainer v-for="i in 12" :key="i">
                <Skeleton />
            </ReportGridItemContainer>
        </div>
    </PageContent>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Skeleton from '@/components/util/Skeleton.vue'
import PageContent from '@/layouts/PageContent.vue'
import ReportGridItemContainer from './ReportGridItemContainer.vue'

export default defineComponent({
    components: {
        Skeleton,
        PageContent,
        ReportGridItemContainer,
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.grid-item {
    @include container;
    position: relative;
    width: 100%;
    @include opteo-background;
    cursor: pointer;
    max-width: 340px;
}
.grid-item-inner {
    position: absolute;
    @include inset;
    @include pa-24;
}

@media screen and (min-width: 850px) {
    .grid-item {
        max-width: 100%;
    }
}
</style>
