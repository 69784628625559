<template>
    <FullScreenOverlay @close="returnToReportsList">
        <div v-if="loading">
            <ReportEditSkeleton />
        </div>
        <div v-else-if="creatingReport">
            <ReportLoaderContainer action="creating" :hasError="creatingReportError" />
        </div>
        <div v-else>
            <div class="create-report-content">
                <ReportEditFlow />
            </div>
        </div>

        <ReportFooter title="Create New Report">
            <oButton
                v-if="!creatingReport"
                @clicked="returnToReportsList"
                color="white"
                class="mr2 w-100 w-auto"
            >
                Cancel
            </oButton>
            <oButton
                @clicked="createReport"
                :loading="creatingReport && !creatingReportError"
                :disabled="!creationFormReady"
                class="w-auto"
            >
                Create Report
                <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
            </oButton>
        </ReportFooter>
    </FullScreenOverlay>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import { oButton, Spacer, RightArrowIcon } from '@opteo/components-next'
import { Routes } from '@/router/routes'

import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'
import ReportEditFlow from '@/components/Reports/ReportEditFlow.vue'
import ReportLoaderContainer from '@/components/Reports/ReportLoaderContainer.vue'
import ReportEditSkeleton from '@/components/Reports/ReportEditSkeleton.vue'

import ReportFooter from '@/components/Reports/ReportFooter.vue'

import { provideCreateReport } from '@/composition/reports/useCreateReport'

export default {
    components: {
        ReportFooter,
        oButton,
        FullScreenOverlay,
        ReportLoaderContainer,
        ReportEditFlow,
        ReportEditSkeleton,
        Spacer,
        RightArrowIcon,
    },
    setup() {
        const { push } = useRouter()

        const returnToReportsList = () => push({ name: Routes.ReportsActive })

        return { ...provideCreateReport('create'), returnToReportsList }
    },
}
</script>
<style lang="scss" scoped>
.create-report-content {
    max-width: 604px;
    padding-bottom: 9.5rem;
    display: block;
    margin: 6rem auto 0 auto;
}
</style>
