import { useImprovement, checkImprovement, EntityPill } from '../useImprovement'
import { RemoveBroadKeywords, Improvement } from '@opteo/types'
import { computed, ref } from 'vue'

export function useRemoveBroadKeyword() {
    const { improvement, lastUpdated, title } = useImprovement<RemoveBroadKeywords.Body>()
    const { body } = checkImprovement<RemoveBroadKeywords.Body>(improvement)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Pausing broad match keyword..',
        'Adding phrase match keyword..',
        'Keyword replaced successfully.',
    ])

    const replacementKeyword = `${body.keyword_text.replace(/\+/g, '')}`

    const entityPillList: EntityPill[] = [
        { type: Improvement.LocationEntity.Campaign, content: body.campaign_label },
        { type: Improvement.LocationEntity.AdGroup, content: body.adgroup_label },
        { type: Improvement.LocationEntity.Keyword, content: body.keyword_text },
    ]

    const pushActionText = ref('Replace Keyword')

    return {
        title,
        lastUpdated,
        pushMessages,
        body,
        entityPillList,
        replacementKeyword,
        pushActionText,
    }
}
