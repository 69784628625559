<template>
    <transition name="batch-bar-fade-up">
        <div v-if="setupActive" class="account-setup-bar">
            <div class="account-setup-bar-contents">
                <slot />
                <div class="left">
                    <Avatar
                        v-if="accountColor"
                        :color="accountColor"
                        :width="36"
                        :height="36"
                        show-account-type
                        :platform="accountPlatform"
                    >
                        {{ accountInitials }}
                    </Avatar>

                    <Skeleton v-else :width="36" :height="36" />
                    <oButton color="white" size="small" @clicked="skipAccountSetup">
                        Skip Account Setup
                    </oButton>
                </div>
                <Steps :stepIndex="stepIndex" :steps="steps" @update="stepClicked($event)" />
                <div style="margin-left: auto">
                    <oButton
                        ref="nextStepButton"
                        v-if="currentStep?.key === 'account-setup'"
                        @clicked="goToCampaignGroups"
                        :loading="stepValidators[currentStep.key]?.loading"
                        color="dark-blue"
                    >
                        Continue to Campaign Groups
                        <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
                    </oButton>
                    <oButton
                        v-if="currentStep?.key === 'campaign-groups'"
                        @clicked="completeAccountSetup"
                        color="dark-blue"
                    >
                        Complete Account Setup
                        <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
                    </oButton>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { oButton, RightArrowIcon, Avatar } from '@opteo/components-next'

import Steps from './Steps.vue'

import { useAccountSetupBar } from '@/composition/account/useAccountSetupBar'
import Skeleton from '@/components/util/Skeleton.vue'

const {
    setupActive,
    stepIndex,
    currentStep,
    skipAccountSetup,
    steps,
    completeAccountSetup,
    stepClicked,
    goToCampaignGroups,
    stepValidators,
    nextStepButton,
    accountInitials,
    accountColor,
    accountPlatform,
} = useAccountSetupBar()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.account-setup-bar {
    @include bg-opteo-white;
    box-shadow: $opteo-shadow-xl;
    touch-action: none;
    position: fixed;
    bottom: 0.75rem;
    width: calc(100vw - 2.5rem);
    left: calc(50%);
    transform: translate3d(-50%, 0, 0);
    @include br-24;
    z-index: 2147482998;
}
.account-setup-bar-contents {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;
    justify-content: start;
    @include pa-20;
}
.account-setup-bar-contents .left {
    @include flex;
    @include items-center;
    gap: 1.125rem;
}
@media (min-width: 1280px) {
    .account-setup-bar {
        width: 66rem;
    }
}

// Transitions
.batch-bar-fade-up-enter-active {
    transition: all 0.64s cubic-bezier(0.19, 1, 0.22, 1);
}
.batch-bar-fade-up-leave-active {
    transition: all 0.48s cubic-bezier(0.19, 1, 0.22, 1);
}
.batch-bar-fade-up-enter-from,
.batch-bar-fade-up-leave-to {
    transform: translate3d(-50%, 0.5rem, 0);
    opacity: 0;
}
</style>
