import { SearchPartnerSettings } from '@opteo/types'
import { UseImprovement, useImprovement, checkImprovement } from '../useImprovement'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { computed, ref } from 'vue'

interface UseSearchPartnerSettings {
    campaignName: string
    googleCost: string
    partnerCost: string
    googleCpa: string
    partnerCpa: string
}

export function useSearchPartnerSettings(): UseImprovement<UseSearchPartnerSettings> {
    const { improvement, lastUpdated, title } = useImprovement<SearchPartnerSettings.Body>()
    const { body } = checkImprovement<SearchPartnerSettings.Body>(improvement)

    const campaignName = body.campaign

    const { displayValue: formattedGoogleCost } = useDomainMoney({
        value: body.cost_google_network,
    }).value

    const { displayValue: formattedPartnerCost } = useDomainMoney({
        value: body.cost_partner_network,
    }).value

    const { displayValue: formattedGoogleCpa } = useDomainMoney({
        value: body.cpa_google_network,
    }).value

    const { displayValue: formattedPartnerCpa } = useDomainMoney({
        value: body.cpa_partner_network,
    }).value

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Disabling search partner sites..',
        'Confirming changes..',
        'Search partner sites disabled successfully.',
    ])

    const pushActionText = ref('Disable Search Partner Sites')

    return {
        title,
        lastUpdated,
        pushMessages,
        campaignName,
        googleCost: formattedGoogleCost.value,
        partnerCost: formattedPartnerCost.value,
        googleCpa: formattedGoogleCpa.value,
        partnerCpa: formattedPartnerCpa.value,
        pushActionText,
    }
}
