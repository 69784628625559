<template>
    <svg
        width="680"
        height="408"
        viewBox="0 0 680 408"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1194_11751)">
            <rect width="680" height="408" fill="white" />
            <circle cx="90" cy="51" r="10" fill="#6E3AFF" />
            <g filter="url(#filter0_dd_1194_11751)">
                <circle cx="100" cy="57" r="6" fill="white" />
            </g>
            <g clip-path="url(#clip1_1194_11751)">
                <path
                    d="M99.185 54.8842C99.2377 54.7459 99.3101 54.6186 99.4177 54.5154C99.8479 54.0961 100.559 54.2015 100.851 54.7283C101.071 55.1279 101.303 55.5186 101.53 55.9138C101.907 56.5702 102.289 57.2266 102.662 57.8852C102.976 58.4362 102.636 59.1321 102.012 59.2265C101.631 59.2835 101.273 59.1079 101.075 58.7655C100.744 58.1881 100.41 57.6107 100.078 57.0356C100.072 57.0224 100.063 57.0114 100.054 57.0005C100.019 56.9719 100.004 56.9302 99.9819 56.8929C99.8348 56.6338 99.6833 56.377 99.5362 56.1201C99.4418 55.9533 99.343 55.7887 99.2486 55.6218C99.163 55.4725 99.1235 55.3101 99.1279 55.1389C99.1345 55.051 99.1455 54.9632 99.185 54.8842Z"
                    fill="#3C8BD9"
                />
                <path
                    d="M99.1849 54.8843C99.1652 54.9633 99.1476 55.0423 99.1432 55.1258C99.1366 55.3102 99.1827 55.4814 99.2749 55.6416C99.5164 56.0566 99.7579 56.4737 99.9972 56.8908C100.019 56.9281 100.037 56.9654 100.059 57.0005C99.9269 57.2288 99.7952 57.4549 99.6613 57.6833C99.4769 58.0016 99.2925 58.3221 99.1059 58.6404C99.0971 58.6404 99.0949 58.636 99.0927 58.6294C99.0905 58.6119 99.0971 58.5965 99.1015 58.5789C99.1915 58.2496 99.1169 57.9577 98.8908 57.7074C98.7525 57.5559 98.5768 57.4703 98.3749 57.4418C98.1114 57.4045 97.8787 57.4725 97.6702 57.6372C97.6329 57.6657 97.6087 57.7074 97.5648 57.7294C97.556 57.7294 97.5516 57.725 97.5494 57.7184C97.6548 57.5362 97.758 57.354 97.8634 57.1718C98.298 56.4166 98.7327 55.6614 99.1696 54.9084C99.1739 54.8996 99.1805 54.8931 99.1849 54.8843Z"
                    fill="#FABC04"
                />
                <path
                    d="M97.5582 57.7249C97.5999 57.6876 97.6394 57.6481 97.6833 57.613C98.2168 57.1915 99.0181 57.4966 99.1344 58.164C99.163 58.3242 99.1476 58.4779 99.0993 58.6316C99.0971 58.6447 99.0949 58.6557 99.0905 58.6689C99.0708 58.704 99.0532 58.7413 99.0312 58.7765C98.8359 59.0992 98.5483 59.2594 98.1707 59.2353C97.7382 59.2045 97.398 58.8796 97.3387 58.4494C97.3101 58.2408 97.3519 58.0454 97.4594 57.8654C97.4814 57.8259 97.5077 57.7908 97.5319 57.7513C97.5428 57.7425 97.5385 57.7249 97.5582 57.7249Z"
                    fill="#34A852"
                />
                <path
                    d="M97.5582 57.725C97.5494 57.7337 97.5494 57.7491 97.534 57.7513C97.5318 57.7359 97.5406 57.7272 97.5494 57.7162L97.5582 57.725Z"
                    fill="#FABC04"
                />
                <path
                    d="M99.0906 58.6689C99.0818 58.6535 99.0906 58.6426 99.0993 58.6316C99.1015 58.6338 99.1059 58.6382 99.1081 58.6404L99.0906 58.6689Z"
                    fill="#E1C025"
                />
            </g>
            <path
                d="M129.48 58V43.82H125.72L121.56 54.06L117.3 43.82H113.66V58H116.3V48.14L120.38 58H122.68L126.76 48.06V58H129.48Z"
                fill="black"
            />
            <path
                d="M133.805 53.04C133.805 51.24 134.845 50.18 136.265 50.18C137.665 50.18 138.685 51.24 138.685 53.04C138.685 54.84 137.645 55.98 136.265 55.98C134.825 55.98 133.805 54.82 133.805 53.04ZM138.705 56.78C138.705 57.26 138.765 57.78 138.805 58H141.345C141.325 57.78 141.265 57.1 141.265 56.22V48.16H138.685V49.38C138.445 48.82 137.625 47.88 135.865 47.88C133.005 47.88 131.125 50.16 131.125 53.04C131.125 56.02 133.045 58.28 135.945 58.28C137.265 58.28 138.285 57.6 138.705 56.66V56.78Z"
                fill="black"
            />
            <path
                d="M146.051 52.34C146.051 51.2 146.731 50.3 147.891 50.3C149.171 50.3 149.711 51.16 149.711 52.26V58H152.371V51.8C152.371 49.64 151.25 47.9 148.811 47.9C147.75 47.9 146.57 48.36 145.971 49.38V48.16H143.391V58H146.051V52.34Z"
                fill="black"
            />
            <path
                d="M160.832 58H163.372C163.332 57.66 163.272 56.98 163.272 56.22V48.16H160.612V53.88C160.612 55.02 159.932 55.82 158.752 55.82C157.512 55.82 156.952 54.94 156.952 53.84V48.16H154.292V54.38C154.292 56.52 155.652 58.26 158.032 58.26C159.072 58.26 160.212 57.86 160.752 56.94C160.752 57.34 160.792 57.8 160.832 58Z"
                fill="black"
            />
            <path
                d="M167.494 53.04C167.494 51.24 168.534 50.18 169.954 50.18C171.354 50.18 172.374 51.24 172.374 53.04C172.374 54.84 171.334 55.98 169.954 55.98C168.514 55.98 167.494 54.82 167.494 53.04ZM172.394 56.78C172.394 57.26 172.454 57.78 172.494 58H175.034C175.014 57.78 174.954 57.1 174.954 56.22V48.16H172.374V49.38C172.134 48.82 171.314 47.88 169.554 47.88C166.694 47.88 164.814 50.16 164.814 53.04C164.814 56.02 166.734 58.28 169.634 58.28C170.954 58.28 171.974 57.6 172.394 56.66V56.78Z"
                fill="black"
            />
            <path d="M179.739 58V43.52H177.079V58H179.739Z" fill="black" />
            <path
                d="M192.716 58.3C196.696 58.3 198.736 55.66 199.276 53.48L196.696 52.7C196.316 54.02 195.136 55.6 192.716 55.6C190.436 55.6 188.316 53.94 188.316 50.92C188.316 47.7 190.556 46.18 192.676 46.18C195.136 46.18 196.236 47.68 196.576 49.04L199.176 48.22C198.616 45.92 196.596 43.52 192.676 43.52C188.876 43.52 185.456 46.4 185.456 50.92C185.456 55.44 188.756 58.3 192.716 58.3Z"
                fill="black"
            />
            <path
                d="M203.593 50.28V46.22H205.773C207.153 46.22 207.993 47 207.993 48.26C207.993 49.48 207.153 50.28 205.773 50.28H203.593ZM206.113 52.66C208.913 52.66 210.773 50.82 210.773 48.24C210.773 45.68 208.913 43.82 206.113 43.82H200.813V58H203.573V52.66H206.113Z"
                fill="black"
            />
            <path
                d="M218.743 58.3C222.723 58.3 224.763 55.66 225.303 53.48L222.723 52.7C222.343 54.02 221.163 55.6 218.743 55.6C216.463 55.6 214.343 53.94 214.343 50.92C214.343 47.7 216.583 46.18 218.703 46.18C221.163 46.18 222.263 47.68 222.603 49.04L225.203 48.22C224.643 45.92 222.623 43.52 218.703 43.52C214.903 43.52 211.483 46.4 211.483 50.92C211.483 55.44 214.783 58.3 218.743 58.3Z"
                fill="black"
            />
            <path d="M264.06 46.44V43.82H252.32V46.44H256.8V58H259.58V46.44H264.06Z" fill="black" />
            <path
                d="M264.977 53.04C264.977 51.24 266.017 50.18 267.437 50.18C268.837 50.18 269.857 51.24 269.857 53.04C269.857 54.84 268.817 55.98 267.437 55.98C265.997 55.98 264.977 54.82 264.977 53.04ZM269.877 56.78C269.877 57.26 269.937 57.78 269.977 58H272.517C272.497 57.78 272.437 57.1 272.437 56.22V48.16H269.857V49.38C269.617 48.82 268.797 47.88 267.037 47.88C264.177 47.88 262.297 50.16 262.297 53.04C262.297 56.02 264.217 58.28 267.117 58.28C268.437 58.28 269.457 57.6 269.877 56.66V56.78Z"
                fill="black"
            />
            <path
                d="M280.562 48.12C280.362 48.1 280.162 48.08 279.942 48.08C279.102 48.08 277.742 48.32 277.142 49.62V48.16H274.562V58H277.222V53.5C277.222 51.38 278.402 50.72 279.762 50.72C280.002 50.72 280.262 50.74 280.562 50.8V48.12Z"
                fill="black"
            />
            <path
                d="M281.058 58.54C281.338 60.42 283.138 62.1 285.838 62.1C289.538 62.1 290.998 59.64 290.998 56.94V48.16H288.438V49.32C288.098 48.66 287.198 47.98 285.638 47.98C282.938 47.98 281.058 50.18 281.058 52.78C281.058 55.54 283.018 57.56 285.638 57.56C287.058 57.56 287.978 56.94 288.378 56.3V57.02C288.378 58.92 287.438 59.76 285.758 59.76C284.498 59.76 283.638 58.98 283.458 57.9L281.058 58.54ZM286.098 55.28C284.718 55.28 283.738 54.32 283.738 52.78C283.738 51.26 284.778 50.3 286.098 50.3C287.378 50.3 288.418 51.26 288.418 52.78C288.418 54.3 287.458 55.28 286.098 55.28Z"
                fill="black"
            />
            <path
                d="M295.209 51.96C295.269 51.06 296.029 50.02 297.409 50.02C298.929 50.02 299.569 50.98 299.609 51.96H295.209ZM299.869 54.52C299.549 55.4 298.869 56.02 297.629 56.02C296.309 56.02 295.209 55.08 295.149 53.78H302.189C302.189 53.74 302.229 53.34 302.229 52.96C302.229 49.8 300.409 47.86 297.369 47.86C294.849 47.86 292.529 49.9 292.529 53.04C292.529 56.36 294.909 58.3 297.609 58.3C300.029 58.3 301.589 56.88 302.089 55.18L299.869 54.52Z"
                fill="black"
            />
            <path
                d="M307.14 45.22H304.74V46.6C304.74 47.48 304.26 48.16 303.22 48.16H302.72V50.52H304.5V55.1C304.5 57 305.7 58.14 307.62 58.14C308.4 58.14 308.88 58 309.12 57.9V55.7C308.98 55.74 308.62 55.78 308.3 55.78C307.54 55.78 307.14 55.5 307.14 54.64V50.52H309.12V48.16H307.14V45.22Z"
                fill="black"
            />
            <path
                d="M322.105 58H325.185L322.145 52.2C324.005 51.66 325.165 50.16 325.165 48.18C325.165 45.72 323.405 43.82 320.645 43.82H315.105V58H317.885V52.54H319.325L322.105 58ZM317.885 50.16V46.22H320.125C321.525 46.22 322.345 47 322.345 48.2C322.345 49.36 321.525 50.16 320.125 50.16H317.885Z"
                fill="black"
            />
            <path
                d="M329.122 50.9C329.122 47.7 331.362 46.18 333.542 46.18C335.742 46.18 337.982 47.7 337.982 50.9C337.982 54.1 335.742 55.62 333.542 55.62C331.362 55.62 329.122 54.1 329.122 50.9ZM326.262 50.92C326.262 55.48 329.702 58.3 333.542 58.3C337.402 58.3 340.842 55.48 340.842 50.92C340.842 46.34 337.402 43.52 333.542 43.52C329.702 43.52 326.262 46.34 326.262 50.92Z"
                fill="black"
            />
            <path
                d="M351.726 58H354.726L349.326 43.82H346.126L340.686 58H343.606L344.786 54.76H350.546L351.726 58ZM347.666 46.88L349.626 52.24H345.706L347.666 46.88Z"
                fill="black"
            />
            <path
                d="M365.208 47.2C364.888 45.6 363.568 43.52 360.128 43.52C357.448 43.52 355.188 45.44 355.188 47.96C355.188 50.1 356.648 51.56 358.908 52.02L360.888 52.42C361.988 52.64 362.608 53.28 362.608 54.1C362.608 55.1 361.788 55.84 360.308 55.84C358.308 55.84 357.328 54.58 357.208 53.18L354.648 53.86C354.868 55.88 356.528 58.3 360.288 58.3C363.588 58.3 365.408 56.1 365.408 53.92C365.408 51.92 364.028 50.24 361.448 49.74L359.468 49.36C358.428 49.16 357.928 48.54 357.928 47.76C357.928 46.82 358.808 45.96 360.148 45.96C361.948 45.96 362.588 47.18 362.728 47.96L365.208 47.2Z"
                fill="black"
            />
            <g filter="url(#filter1_dd_1194_11751)">
                <circle cx="239" cy="51" r="6" fill="white" />
            </g>
            <g filter="url(#filter2_dd_1194_11751)">
                <rect x="80" y="98" width="520" height="176" rx="12" fill="white" />
            </g>
            <g filter="url(#filter3_dd_1194_11751)">
                <rect x="100" y="148" width="480" height="106" rx="10" fill="white" />
            </g>
            <line x1="100" y1="180.5" x2="580" y2="180.5" stroke="#F7F7F9" />
            <rect x="112" y="161" width="72" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter4_dd_1194_11751)">
                <circle cx="196" cy="164" r="6" fill="white" />
            </g>
            <rect x="324" y="161" width="22" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter5_dd_1194_11751)">
                <circle cx="358" cy="164" r="6" fill="white" />
            </g>
            <rect x="388" y="161" width="22" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter6_dd_1194_11751)">
                <circle cx="422" cy="164" r="6" fill="white" />
            </g>
            <rect x="452" y="161" width="22" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter7_dd_1194_11751)">
                <circle cx="486" cy="164" r="6" fill="white" />
            </g>
            <rect x="516" y="161" width="22" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter8_dd_1194_11751)">
                <circle cx="550" cy="164" r="6" fill="white" />
            </g>
            <rect opacity="0.1" x="228" y="195" width="23" height="8" rx="4" fill="#FF2828" />
            <rect
                x="112"
                y="188"
                width="110"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M126 204C128.761 204 131 201.761 131 199C131 196.239 128.761 194 126 194C123.239 194 121 196.239 121 199C121 201.761 123.239 204 126 204ZM126 205C122.686 205 120 202.314 120 199C120 195.686 122.686 193 126 193C129.314 193 132 195.686 132 199C132 202.314 129.314 205 126 205Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M126.064 201.605C127.457 201.605 128.171 200.681 128.36 199.918L127.457 199.645C127.324 200.107 126.911 200.66 126.064 200.66C125.266 200.66 124.524 200.079 124.524 199.022C124.524 197.895 125.308 197.363 126.05 197.363C126.911 197.363 127.296 197.888 127.415 198.364L128.325 198.077C128.129 197.272 127.422 196.432 126.05 196.432C124.72 196.432 123.523 197.44 123.523 199.022C123.523 200.604 124.678 201.605 126.064 201.605Z"
                fill="#0035EC"
            />
            <rect x="138" y="196" width="76" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="324" y="196" width="36" height="6" rx="3" fill="#ECECEF" />
            <rect x="388" y="196" width="36" height="6" rx="3" fill="#ECECEF" />
            <rect x="452" y="196" width="36" height="6" rx="3" fill="#ECECEF" />
            <rect x="516" y="196" width="48" height="6" rx="3" fill="#ECECEF" />
            <line x1="100" y1="217.5" x2="580" y2="217.5" stroke="#F7F7F9" />
            <rect opacity="0.1" x="240" y="232" width="23" height="8" rx="4" fill="#00A861" />
            <rect
                x="112"
                y="225"
                width="122"
                height="22"
                rx="11"
                fill="#5121D7"
                fill-opacity="0.06"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M120 236C120 239.314 122.686 242 126 242C129.314 242 132 239.314 132 236C132 232.686 129.314 230 126 230C122.686 230 120 232.686 120 236ZM131 236C131 238.761 128.761 241 126 241C123.239 241 121 238.761 121 236C121 233.239 123.239 231 126 231C128.761 231 131 233.239 131 236ZM127.662 237.59V238.5H124.5V233.5H127.662V234.41H125.5V235.581H127.5V236.442H125.5V237.59H127.662Z"
                fill="#5121D7"
            />
            <rect x="138" y="233" width="88" height="6" rx="3" fill="#5121D7" fill-opacity="0.16" />
            <rect x="324" y="233" width="36" height="6" rx="3" fill="#ECECEF" />
            <rect x="388" y="233" width="36" height="6" rx="3" fill="#ECECEF" />
            <rect x="452" y="233" width="36" height="6" rx="3" fill="#ECECEF" />
            <rect x="516" y="233" width="48" height="6" rx="3" fill="#ECECEF" />
            <rect x="100" y="118" width="120" height="10" rx="5" fill="#ECECEF" />
            <g filter="url(#filter9_dd_1194_11751)">
                <rect x="80" y="292" width="520" height="170" rx="12" fill="white" />
            </g>
            <g filter="url(#filter10_dd_1194_11751)">
                <rect x="100" y="342" width="480" height="100" rx="10" fill="white" />
            </g>
            <line x1="100" y1="374.5" x2="580" y2="374.5" stroke="#F7F7F9" />
            <rect x="112" y="355" width="72" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter11_dd_1194_11751)">
                <circle cx="196" cy="358" r="6" fill="white" />
            </g>
            <rect x="324" y="355" width="22" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter12_dd_1194_11751)">
                <circle cx="358" cy="358" r="6" fill="white" />
            </g>
            <rect x="388" y="355" width="22" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter13_dd_1194_11751)">
                <circle cx="422" cy="358" r="6" fill="white" />
            </g>
            <rect x="452" y="355" width="22" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter14_dd_1194_11751)">
                <circle cx="486" cy="358" r="6" fill="white" />
            </g>
            <rect x="516" y="355" width="22" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter15_dd_1194_11751)">
                <circle cx="550" cy="358" r="6" fill="white" />
            </g>
            <rect x="131" y="388" width="80" height="6" rx="3" fill="#ECECEF" />
            <path
                d="M118 396C120.761 396 123 393.761 123 391C123 388.239 120.761 386 118 386C115.238 386 113 388.239 113 391C113 393.761 115.238 396 118 396ZM118 397C114.686 397 112 394.314 112 391C112 387.686 114.686 385 118 385C121.314 385 124 387.686 124 391C124 394.314 121.314 397 118 397Z"
                fill="#006DFF"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M118.064 393.605C119.457 393.605 120.171 392.681 120.36 391.918L119.457 391.645C119.324 392.107 118.911 392.66 118.064 392.66C117.266 392.66 116.524 392.079 116.524 391.022C116.524 389.895 117.308 389.363 118.05 389.363C118.911 389.363 119.296 389.888 119.415 390.364L120.325 390.077C120.129 389.272 119.422 388.432 118.05 388.432C116.72 388.432 115.523 389.44 115.523 391.022C115.523 392.604 116.678 393.605 118.064 393.605Z"
                fill="#006DFF"
            />
            <rect x="324" y="388" width="36" height="6" rx="3" fill="#ECECEF" />
            <rect x="388" y="388" width="36" height="6" rx="3" fill="#ECECEF" />
            <rect x="452" y="388" width="36" height="6" rx="3" fill="#ECECEF" />
            <rect x="516" y="388" width="48" height="6" rx="3" fill="#ECECEF" />
            <line x1="100" y1="407.5" x2="580" y2="407.5" stroke="#F7F7F9" />
            <rect x="100" y="312" width="120" height="10" rx="5" fill="#ECECEF" />
            <g filter="url(#filter16_dd_1194_11751)">
                <rect y="321" width="680" height="87" fill="white" />
            </g>
            <g filter="url(#filter17_dd_1194_11751)">
                <rect x="154" y="339" width="220" height="51" rx="8" fill="white" />
            </g>
            <rect x="166" y="365" width="196" height="3" rx="1.5" fill="#F2F2F6" />
            <rect x="166" y="374" width="94" height="4" rx="2" fill="#ECECEF" />
            <rect x="166" y="365" width="196" height="3" rx="1.5" fill="#00A861" />
            <rect opacity="0.1" x="166" y="351" width="72" height="8" rx="4" fill="#00A861" />
            <g filter="url(#filter18_d_1194_11751)">
                <rect x="398" y="349" width="128" height="30" rx="15" fill="#00A861" />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_dd_1194_11751"
                x="92"
                y="50"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_dd_1194_11751"
                x="231"
                y="44"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_dd_1194_11751"
                x="79"
                y="97"
                width="522"
                height="179"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_dd_1194_11751"
                x="99"
                y="147"
                width="482"
                height="109"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter4_dd_1194_11751"
                x="188"
                y="157"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter5_dd_1194_11751"
                x="350"
                y="157"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter6_dd_1194_11751"
                x="414"
                y="157"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter7_dd_1194_11751"
                x="478"
                y="157"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter8_dd_1194_11751"
                x="542"
                y="157"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter9_dd_1194_11751"
                x="79"
                y="291"
                width="522"
                height="173"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter10_dd_1194_11751"
                x="99"
                y="341"
                width="482"
                height="103"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter11_dd_1194_11751"
                x="188"
                y="351"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter12_dd_1194_11751"
                x="350"
                y="351"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter13_dd_1194_11751"
                x="414"
                y="351"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter14_dd_1194_11751"
                x="478"
                y="351"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter15_dd_1194_11751"
                x="542"
                y="351"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter16_dd_1194_11751"
                x="-1"
                y="320"
                width="682"
                height="90"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter17_dd_1194_11751"
                x="152"
                y="338"
                width="224"
                height="56"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11751"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11751"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11751"
                    result="effect2_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <filter
                id="filter18_d_1194_11751"
                x="397"
                y="348.5"
                width="130"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11751"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1194_11751"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_1194_11751">
                <rect width="680" height="408" fill="white" />
            </clipPath>
            <clipPath id="clip1_1194_11751">
                <rect
                    width="5.48547"
                    height="5.48547"
                    fill="white"
                    transform="translate(97.33 54)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
