<template>
    <div :class="['display-ad', classes, { 'no-container': noContainer }]">
        <!-- Title Tag (optional) -->
        <CardTitleTag v-if="titleTag" :title="titleTag.title" :classes="titleTag.classes" />
        <!-- Ad Container -->
        <div class="inner-container">
            <div class="ad-width">
                <!-- Display Ad Image -->
                <div class="display-image">
                    <img :src="displayAdUrl" />
                </div>
                <!-- Featured Statistics -->
                <div v-if="featuredStatistics" class="ad-featured-statistics">
                    <ColorTag
                        v-for="(statistic, index) in featuredStatistics"
                        :key="'statistic-' + index"
                        :title="statistic.name"
                        :content="statistic.content"
                        :color="statistic.color"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

// Shared Components
import CardTitleTag from './CardTitleTag.vue'
import { ColorTag } from '@opteo/components-next'

interface FeatureStatistic {
    name: string
    content: string
    color: string
}

interface TitleTag {
    title: string
    classes: string
}

export default {
    name: 'DisplayAd',
    components: { CardTitleTag, ColorTag },
    props: {
        displayAdUrl: {
            type: String as PropType<string>,
            required: true,
        },
        featuredStatistics: {
            type: Array as PropType<FeatureStatistic[]>,
            required: false,
        },
        titleTag: {
            type: Object as PropType<TitleTag>,
            required: false,
        },
        classes: {
            type: String,
            default: '',
            required: false,
        },
        noContainer: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
    },
    data() {
        return {}
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.display-ad {
    position: relative;
    width: 100%;
    @include container;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    background: $opteo-white;
    backface-visibility: hidden;
}
.display-image {
    overflow: hidden;
    max-width: 18.75rem;
    margin: 0 auto;
}
.display-image img {
    display: block;
}
.display-ad.no-container {
    box-shadow: none;
}
.inner-container {
    @include flex-center;
    @include ph-40;
    @include pv-64;
    overflow-x: auto;
    backface-visibility: hidden;
}
.display-ad.no-container .inner-container {
    padding: 0rem;
}
.display-ad .ad-featured-statistics {
    @include mt-24;
    @include flex-center;
}
.ad-featured-statistics .o-color-tag {
    margin-right: 0.375rem;
}
.ad-featured-statistics .o-color-tag:last-child {
    margin-right: 0;
}

@media screen and (min-width: 0px) and (max-width: 320px) {
}
@media screen and (min-width: 321px) and (max-width: 375px) {
}
@media screen and (min-width: 376px) and (max-width: 480px) {
}
@media screen and (min-width: 481px) and (max-width: 767px) {
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
@media screen and (min-width: 1025px) and (max-width: 1279px) {
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
}
@media screen and (min-width: 1441px) and (max-width: 1600px) {
}
@media screen and (min-width: 1601px) and (max-width: 1920px) {
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
}
@media screen and (min-width: 2561px) and (max-width: 9999px) {
}
</style>
