<template>
    <div class="dismiss-popout-container">
        <div class="popout-row">
            <oButton size="medium" type="phantom" @clicked="$emit('dismiss', 'week')">
                <div class="popout-button-contents">
                    <span class="popout-span">1 Week</span>
                    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9 18c4.9706 0 9-4.0294 9-9 0-4.97056-4.0294-9-9-9-4.97056 0-9 4.02944-9 9 0 4.9706 4.02944 9 9 9Zm7-9c0 3.866-3.134 7-7 7-3.86599 0-7-3.134-7-7 0-3.86599 3.13401-7 7-7 3.866 0 7 3.13401 7 7Zm-6-4c0-.55228-.44771-1-1-1s-1 .44772-1 1v3.75C8 9.44036 8.55964 10 9.25 10H12c.5523 0 1-.44772 1-1s-.4477-1-1-1h-2V5Z"
                            fill="#006DFF"
                        />
                    </svg>
                </div>
            </oButton>
        </div>
        <div class="popout-row">
            <oButton size="medium" type="phantom" @clicked="$emit('dismiss', 'month')">
                <div class="popout-button-contents">
                    <span class="popout-span">1 Month</span>
                    <svg width="18" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.8 4.3e-7h-.0413C4.95373-.00001221 4.28937-.0000227 3.74818.0441946 3.18608.0901198 2.66937.188684 2.18404.435975 1.43139.819468.819468 1.43139.435975 2.18404.188684 2.66937.0901198 3.18608.0441946 3.74818.0147155 4.10898.00489437 4.52454.00162618 5H4.3e-7v.68853c-7.4e-7.02327-3.7e-7.04666 0 .07017v4.4826c-.00001264.805-.00002313 1.4693.04419417 2.0105.0459252.5621.1444894 1.0788.3917804 1.5642.383493.7526.995415 1.3645 1.748065 1.748.48533.2473 1.00204.3459 1.56414.3918C4.28937 16 4.95373 16 5.7587 16h6.4826c.805 0 1.4693 0 2.0105-.0442.5621-.0459 1.0788-.1445 1.5642-.3918.7526-.3835 1.3645-.9954 1.748-1.748.2473-.4854.3459-1.0021.3918-1.5642C18 11.7106 18 11.0463 18 10.2413V5h-.0016c-.0033-.47546-.0131-.89102-.0426-1.25182-.0459-.5621-.1445-1.07881-.3918-1.56414-.3835-.75265-.9954-1.364572-1.748-1.748065-.4854-.247291-1.0021-.3458552-1.5642-.3917804-.5412-.0442173-1.2055-.04420681-2.0105-.04419417H5.8ZM15.9982 5c-.0031-.44553-.0118-.79537-.0358-1.08896-.0358-.43842-.1007-.66262-.1804-.81902-.1917-.37632-.4977-.68229-.874-.87403-.1564-.07969-.3806-.14462-.819-.18044C13.6389 2.00078 13.0566 2 12.2 2H5.8c-.85658 0-1.43887.00078-1.88896.03755-.43842.03582-.66262.10075-.81902.18044-.37632.19174-.68229.49771-.87403.87403-.07969.1564-.14462.3806-.18044.81902-.02398.29359-.03266.64343-.03579 1.08896H15.9982ZM16 7H2v3.2c0 .8566.00078 1.4389.03755 1.889.03582.4384.10075.6626.18044.819.19174.3763.49771.6823.87403.874.1564.0797.3806.1446.81902.1804C4.36113 13.9992 4.94342 14 5.8 14h6.4c.8566 0 1.4389-.0008 1.889-.0376.4384-.0358.6626-.1007.819-.1804.3763-.1917.6823-.4977.874-.874.0797-.1564.1446-.3806.1804-.819.0368-.4501.0376-1.0324.0376-1.889V7Z"
                            fill="#006DFF"
                        />
                    </svg>
                </div>
            </oButton>
        </div>
        <div class="popout-row">
            <oButton size="medium" type="phantom" @clicked="$emit('dismiss', 'forever')">
                <div class="popout-button-contents">
                    <span class="popout-span">Forever</span>
                    <svg width="18" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 5c0 1.65685-1.34315 3-3 3S2 6.65685 2 5s1.34315-3 3-3c.54584 0 1.05762.14578 1.49856.40052.25092-.62702.58993-1.20927 1.00171-1.731455C6.76477.243545 5.91083 0 5 0 2.23858 0 0 2.23858 0 5s2.23858 5 5 5c1.5533 0 2.94117-.7083 3.85824-1.81954.04859-.05887.09586-.11888.14176-.17998C9.62791 7.16474 10 6.12582 10 5c0-1.65685 1.3431-3 3-3s3 1.34315 3 3-1.3431 3-3 3c-.5458 0-1.0576-.14578-1.4986-.40052-.2509.62702-.5899 1.20927-1.0017 1.73145C11.2352 9.75645 12.0892 10 13 10c2.7614 0 5-2.23858 5-5s-2.2386-5-5-5c-1.5533 0-2.9412.708299-3.85824 1.81954-.04859.05887-.09586.11888-.14176.17998C8.37209 2.83526 8 3.87418 8 5Z"
                            fill="#006DFF"
                        />
                    </svg>
                </div>
            </oButton>
        </div>
    </div>
</template>
<script lang="ts">
import { oButton } from '@opteo/components-next'
import { computed, ref, PropType } from 'vue'

export default {
    components: {
        oButton,
    },
    emits: ['dismiss'],
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.dismiss-popout-container {
    @include pa-8;
}

.dismiss-popout-container .popout-row {
    @include mb-4;
}

.dismiss-popout-container .popout-row:last-child {
    @include mb-0;
}

.dismiss-popout-container .popout-button-contents {
    @include flex;
    @include items-center;
    @include justify-center;
}
.dismiss-popout-container .popout-span {
    width: 4.125rem;
    @include block;
}
</style>
