<template>
    <div class="expanded-preview">
        <!-- URLs -->
        <div class="expanded-preview-item">
            <Label>Destination URL</Label>
            <span class="url">{{ rsa.finalUrl }}</span>
        </div>
        <div class="expanded-preview-item">
            <Label>Display URL</Label>
            <span class="url">{{ displayUrl }}</span>
        </div>
        <div class="expanded-preview-item" v-if="rsa.trackingUrlTemplate">
            <Label>Tracking Template</Label>
            <span class="url">{{ rsa.trackingUrlTemplate }}</span>
        </div>
        <div class="expanded-preview-item" v-if="rsa.finalUrlSuffix">
            <Label>Final URL Suffix</Label>
            <span class="url">{{ rsa.finalUrlSuffix }}</span>
        </div>
        <div class="expanded-preview-item" v-if="rsa.finalUrlMobile">
            <Label>Mobile Destination URL</Label>
            <span class="url">{{ displayUrl }}</span>
        </div>
        <!-- Paths -->
        <div class="expanded-preview-item path">
            <div class="path-one">
                <Label>Path One</Label>
                <span class="url">{{ rsa.pathOne }}</span>
            </div>
            <div class="path-two">
                <Label>Path Two</Label>
                <span class="url">{{ rsa.pathTwo }}</span>
            </div>
        </div>
        <!-- Headlines -->
        <div v-for="(headline, index) in rsa.headlines" class="expanded-preview-item">
            <Label>Headline {{ getNumberWord(index + 1) }}</Label>
            <span class="headline">{{ headline.text }}</span>
        </div>
        <!-- Descriptions -->
        <div
            v-for="(description, index) in rsa.descriptions"
            :class="index + 1 != rsa.descriptions.length ? '' : 'last'"
            class="expanded-preview-item"
        >
            <Label>Description {{ getNumberWord(index + 1) }}</Label>
            <span class="description">{{ description.text }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRef, PropType } from 'vue'
import { RsaWriter } from '@opteo/types'

import { Spacer, Label } from '@opteo/components-next'

import { getNumberWord } from '@/lib/globalUtils'
import { useRsaDisplayUrl } from './useRsa'

export default defineComponent({
    name: 'RsaExpandedPreview',
    components: {
        Spacer,
        Label,
    },
    props: {
        rsa: {
            type: Object as PropType<RsaWriter.RsaAd>,
            required: true,
        },
    },
    setup(props) {
        const displayUrl = useRsaDisplayUrl(toRef(props, 'rsa'))

        return { getNumberWord, displayUrl }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.expanded-preview {
    @include container;
    @include br-24;
    font-feature-settings: 'ss01' off, 'ss02' off, 'ss04' off, 'ss05' off, 'ss08' off;
    // max-width: 42.5rem;
    // margin: 0 auto;
}
.expanded-preview-item {
    @include pa-32;
    border-bottom: 1px solid $opteo-light-gray;
}
.expanded-preview-item.path {
    @include pa-0;
    @include flex;
    @include items-center;
}
.expanded-preview-item.last {
    border-bottom: none;
}
.expanded-preview-item.path .path-one,
.expanded-preview-item.path .path-two {
    width: 50%;
    flex-shrink: 0;
    @include pa-32;
}
.expanded-preview-item.path .path-one {
    border-right: 1px solid $opteo-light-gray;
}
.expanded-preview-item .headline {
    @include block;
    font-family: 'Google Sans Text', sans-serif;
    font-size: 1.25rem;
    line-height: 1.125rem;
    color: #1a0dab;
    -webkit-font-smoothing: subpixel-antialiased;
}
.expanded-preview-item .url {
    @include block;
    font-family: Arial, sans-serif;
    color: #202124;
    font-size: 0.875rem;
    line-height: 0.875rem;
    letter-spacing: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
.expanded-preview-item .description {
    @include block;
    font-family: Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    color: #4d5156;
    @include no-select;
}
</style>
