<template>
    <PageHeader :no-margin="true" pageTitle="Account Settings">
        <template v-if="aboveMobile" #right-content>
            <Popout
                v-model="jumpList"
                :trapFocus="true"
                :externalToggleButton="true"
                :offset="[1, 12]"
            >
                <oButton color="white" @clicked="jumpList = !jumpList">
                    Jump to
                    <template #icon>
                        <svg width="12" height="8" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.41 0L6 4.77083333 10.59 0 12 1.46875l-6 6.25-6-6.25z"
                                fill="#008dff"
                                fill-rule="evenodd"
                            />
                        </svg>
                    </template>
                </oButton>
                <template #content>
                    <SelectableList :initialIndexZero="true">
                        <ListItem
                            v-for="{ label, targetId } of jumpListItems"
                            :key="targetId"
                            :value="targetId"
                            @click="scrollToSection(targetId)"
                        >
                            <Text size="f-8">{{ label }}</Text>
                        </ListItem>
                    </SelectableList>
                </template>
            </Popout>
        </template>
    </PageHeader>
    <PageContent>
        <!-- Profile Settings -->
        <SettingsSection id="profile-settings" title="Profile Settings" :maxWidth="400">
            <div class="profile-image-uploader">
                <ImageUploader
                    :url="user?.avatar_filename || uploadedImageUrl"
                    location="user"
                    @uploaded="onImageUploaded"
                    @beforeRemove="onBeforeImageRemove"
                    @afterRemove="onAfterImageRemove"
                    :button-on-bounds="aboveMobile"
                    :show-button="aboveMobile"
                >
                    <!-- Profile image uploader -->
                    <div class="avatar-s">
                        <ProfileImage
                            :width="36"
                            :image-url="user?.avatar_filename || uploadedImageUrl"
                            :avatar-url="currentUserAvatarUrl"
                        />
                    </div>
                    <Spacer width="1.125rem" />
                    <div v-if="user">
                        <p class="profile-image-container-name">{{ user?.name }}</p>
                        <p class="profile-image-container-email">
                            {{ user?.username }}
                        </p>
                    </div>
                    <div v-else>
                        <Skeleton :width="152" :height="14" />
                        <Spacer width="100%" height="0.5625rem" />
                        <Skeleton :width="126" :height="12" />
                    </div>
                </ImageUploader>
            </div>
            <oForm @submitted="sumbitProfileChange">
                <div class="form-inputs">
                    <oInput
                        id="user-settings-name"
                        name="name"
                        :modelValue="user?.name"
                        class="mb-24"
                        type="text"
                        label="Name"
                        required
                    />
                    <oInput
                        id="user-settings-email"
                        ref="profileEmailInput"
                        name="email"
                        :modelValue="user?.username"
                        type="email"
                        label="Email Address"
                        required
                    />
                </div>
                <div class="form-buttons">
                    <oButton
                        id="save-profile"
                        type="submit"
                        ref="updateProfileButton"
                        :loading="profileButtonLoading"
                        color="blue"
                        size="medium"
                    >
                        Update Profile
                    </oButton>
                </div>
            </oForm>
        </SettingsSection>

        <!-- Password Settings -->
        <SettingsSection id="change-password" title="Password Settings" :maxWidth="360">
            <oForm @submitted="submitNewPassword">
                <div class="form-inputs">
                    <oInput
                        id="current-password"
                        name="currentPassword"
                        ref="currentPassword"
                        required
                        class="mb-24"
                        label="Current Password"
                        type="password"
                        placeholder="••••••••••"
                    />
                    <oInput
                        id="new-password"
                        name="newPassword"
                        v-model="newPasswordInput"
                        :validator="newPasswordValidator"
                        class="mb-24"
                        label="New Password"
                        type="password"
                        required
                        placeholder="••••••••••"
                    />
                    <oInput
                        id="confirm-password"
                        name="confirmPassword"
                        ref="confirmPassword"
                        :validator="passwordsMatchValidator"
                        class="w-100"
                        label="Confirm New Password"
                        type="password"
                        required
                        placeholder="••••••••••"
                    />
                </div>
                <div class="form-buttons">
                    <oButton
                        id="save-password"
                        ref="savePasswordButton"
                        :loading="passwordButtonLoading"
                        size="medium"
                        type="submit"
                    >
                        Update Password
                    </oButton>
                </div>
            </oForm>
        </SettingsSection>

        <!-- Team Members -->
        <SettingsSection
            id="team-box"
            title="Team Members"
            buttonLabel="Add Team Member"
            :buttonAction="() => (inviteModalShown = true)"
            noInnerContentShadow
        >
            <div v-if="teamTableRows.length">
                <oTable
                    :headers="teamTableHeaders"
                    :items="teamTableRows"
                    :borderRadius="20"
                    responsiveMode="columns"
                    responsiveColSize="40% 1fr"
                >
                    <template v-slot:[`column.name`]="ctx">
                        <div class="flex items-center fw5" style="min-width: 200px">
                            <div class="mr3 relative flex items-center">
                                <ProfileImage
                                    :width="32"
                                    :isAdmin="findTeamMember(ctx.row.id).role === 'admin'"
                                    :image-url="findTeamMember(ctx.row.id).avatar_filename"
                                    :avatar-url="getAvatarUrl(findTeamMember(ctx.row.id)?.user_id)"
                                />
                            </div>
                            <Text as="span" size="f-8" weight="500">{{ ctx.value }}</Text>
                        </div>
                    </template>
                    <template v-slot:[`column.action`]="ctx">
                        <UserActionsPopout
                            :ctx="ctx"
                            @remove="confirmRemoveTeamMember(findTeamMember(ctx.row.id))"
                            @promote="setTeamMemberRole(findTeamMember(ctx.row.id), 'admin')"
                            @demote="setTeamMemberRole(findTeamMember(ctx.row.id), 'user')"
                        ></UserActionsPopout>
                    </template>
                </oTable>
            </div>
            <Modal title="Add Team Member" v-model="inviteModalShown">
                <!-- @opened="focusInviteUserEmailInput" -->
                <template #content>
                    <oForm @submitted="addMember" ref="addMemberForm">
                        <oInput
                            id="invite-user-email"
                            name="inviteUserEmail"
                            v-model="inviteUserEmailModel"
                            ref="inviteUserEmailInput"
                            class="mb-24"
                            type="email"
                            label="Email Address"
                            required
                        />
                        <oInput
                            id="invite-user-name"
                            name="inviteUserName"
                            v-model="inviteUserNameModel"
                            class="w-100"
                            type="text"
                            label="Name"
                            required
                        />
                    </oForm>
                </template>
                <template #buttons>
                    <oButton
                        @clicked="$refs.addMemberForm.submit()"
                        :loading="inviteMemberLoading"
                        ref="inviteMemberButton"
                        size="medium"
                    >
                        Add Team Member
                    </oButton>
                </template>
            </Modal>
            <Modal title="Remove Team Member" v-model="removeModalShown">
                <template #content>
                    <Text as="p">
                        Removing {{ memberToRemove.name }} is permanent — if they would like to use
                        Opteo again, you will have to re-invite them.</Text
                    >
                </template>
                <template #buttons>
                    <oButton @clicked="removeTeamMember" size="medium">
                        Remove Team Member
                    </oButton>
                </template>
            </Modal>
        </SettingsSection>

        <!-- Connect Slack -->
        <SettingsSection id="connect-slack" title="Connect Slack" :max-width="420">
            <SlackConnectWrapper :is-domain-specific-connection="false" />
        </SettingsSection>

        <!-- Alert Settings -->
        <SettingsSection id="alert-section" title="Alert Settings" :noInnerContentShadow="true">
            <div class="alert-type-table">
                <div class="overflow-auto">
                    <table class="alert-types-table w-100" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="type-col">Alert Type</th>
                                <th
                                    v-for="medium in alertMediums"
                                    :key="medium.type"
                                    class="medium-col"
                                >
                                    {{ medium.display }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="type in alertTypesInfo"
                                :key="type.slug"
                                class="alert-type-item"
                            >
                                <td class="alert-type-item-description">
                                    <div>
                                        <Text weight="500" size="f-7">{{ type.label }}</Text>
                                    </div>
                                    <Spacer height="0.5rem" />
                                    <div>
                                        <Text size="f-8">{{ type.description }}</Text>
                                    </div>
                                    <div class="mobile-alert-type-input">
                                        <div class="flex justify-center items-center">
                                            <!-- Careful, if you render the chechbox before the data is loaded,
                                                it will fire the @updated event when the data is populated,
                                                causing 10 simultaneous unecessary updates to the server.
                                                That's why use're using a v-if here. -->

                                            <oInput
                                                v-if="!alertSettingsLoading"
                                                v-model="alertMediums.slack.settings[type.slug]"
                                                label="Slack"
                                                type="checkbox"
                                                @updated="
                                                    updateAlertSettings(
                                                        'Slack',
                                                        type.label,
                                                        alertMediums.slack.settings[type.slug]
                                                    )
                                                "
                                            />
                                            <oInput
                                                v-if="alertSettingsLoading"
                                                label="Slack"
                                                type="checkbox"
                                                disabled
                                            />
                                        </div>
                                        <div class="flex justify-center items-center">
                                            <!-- Careful, if you render the chechbox before the data is loaded,
                                                it will fire the @updated event when the data is populated,
                                                causing 10 simultaneous unecessary updates to the server.
                                                That's why use're using a v-if here. -->

                                            <oInput
                                                v-if="!alertSettingsLoading"
                                                v-model="alertMediums.email.settings[type.slug]"
                                                label="Email"
                                                type="checkbox"
                                                @updated="
                                                    updateAlertSettings(
                                                        'Email',
                                                        type.label,
                                                        alertMediums.email.settings[type.slug]
                                                    )
                                                "
                                            />
                                            <oInput
                                                v-if="alertSettingsLoading"
                                                label="Email"
                                                type="checkbox"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td className="desktop-alert-type-input">
                                    <div class="flex justify-center items-center">
                                        <!-- Careful, if you render the chechbox before the data is loaded,
                                            it will fire the @updated event when the data is populated,
                                            causing 10 simultaneous unecessary updates to the server.
                                            That's why use're using a v-if here. -->

                                        <oInput
                                            v-if="!alertSettingsLoading"
                                            v-model="alertMediums.slack.settings[type.slug]"
                                            type="checkbox"
                                            @updated="
                                                updateAlertSettings(
                                                    'Slack',
                                                    type.label,
                                                    alertMediums.slack.settings[type.slug]
                                                )
                                            "
                                        />
                                        <oInput
                                            v-if="alertSettingsLoading"
                                            type="checkbox"
                                            disabled
                                        />
                                    </div>
                                </td>
                                <td className="desktop-alert-type-input">
                                    <div class="flex justify-center items-center">
                                        <!-- Careful, if you render the chechbox before the data is loaded,
                                            it will fire the @updated event when the data is populated,
                                            causing 10 simultaneous unecessary updates to the server.
                                            That's why use're using a v-if here. -->

                                        <oInput
                                            v-if="!alertSettingsLoading"
                                            v-model="alertMediums.email.settings[type.slug]"
                                            type="checkbox"
                                            @updated="
                                                updateAlertSettings(
                                                    'Email',
                                                    type.label,
                                                    alertMediums.email.settings[type.slug]
                                                )
                                            "
                                        />
                                        <oInput
                                            v-if="alertSettingsLoading"
                                            type="checkbox"
                                            disabled
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </SettingsSection>
    </PageContent>
</template>

<script lang="ts">
import { ref, nextTick } from 'vue'

import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'
import {
    oButton,
    oInput,
    oForm,
    oTable,
    CloseButton,
    Text,
    Spacer,
    Modal,
    Popout,
    SelectableList,
    ListItem,
    ProfileImage,
    showSnackbar,
    SelectArrowIcon,
} from '@opteo/components-next'

import SettingsSection from '@/components/global/SettingsSection.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import ImageUploader from '@/components/global/ImageUploader.vue'
import { useUserSettings } from '@/composition/user/useUserSettings'
import AlertChannelItem from '@/components/User/settings/AlertChannelItem.vue'
import AlertTypeTable from '@/components/User/settings/AlertTypeTable.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { scrollTo, delay } from '@/lib/globalUtils'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import SlackConnectWrapper from '@/components/slack/SlackConnectWrapper.vue'
import UserActionsPopout from '@/components/accountsSettings/UserActionsPopout.vue'

export default {
    name: 'UserSettings',
    components: {
        PageHeader,
        PageContent,
        oButton,
        oInput,
        oForm,
        Skeleton,
        ImageUploader,
        ProfileImage,
        oTable,
        CloseButton,
        SettingsSection,
        SlackConnectWrapper,
        Text,
        Spacer,
        Modal,
        AlertChannelItem,
        AlertTypeTable,
        Popout,
        SelectArrowIcon,
        // Replace below with SelectableList,
        SelectableList,
        ListItem,
        UserActionsPopout,
    },
    setup() {
        const jumpList = ref(false)
        const { updateAlertSettings: updateAlertsRequest } = useUserSettings()

        const { aboveMobile } = useMediaQuery()

        const jumpListItems = [
            {
                label: 'Profile Settings',
                targetId: 'profile-settings',
            },
            {
                label: 'Password Settings',
                targetId: 'change-password',
            },
            {
                label: 'Team Members',
                targetId: 'team-box',
            },
            {
                label: 'Connect Slack',
                targetId: 'connect-slack',
            },
            {
                label: 'Alert Settings',
                targetId: 'alert-section',
            },
        ]

        const scrollToSection = async (targetId: string) => {
            await delay(100)

            nextTick(() => {
                scrollTo(targetId)
            })
        }

        const updateAlertSettings = (channel: string, alertType: string, enabled: boolean) => {
            updateAlertsRequest()
            showSnackbar({
                message: `${channel} alert for ${alertType} successfully ${
                    enabled ? 'enabled' : 'disabled'
                }`,
                timeout: 5000,
            })
        }

        return {
            jumpList,
            ...useUserSettings(),
            ...useBoringAvatar(),
            updateAlertSettings,
            jumpListItems,
            aboveMobile,
            scrollToSection,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.profile-image-container {
    @include flex;
    @include items-center;
    width: 100%;
}

.form-inputs {
    @include pt-32;
    @include ph-32;
}
.form-inputs p {
    @include f-8;
    @include mb-24;
    @include block;
}
.form-buttons {
    @include pv-32;
    @include tc;
}

.profile-image-uploader {
    @include pt-0;
    @include ph-0;
}
.profile-image-container-name {
    @include opteo-black;
    @include f-7;
    @include fw-500;
    @include lh-100;
    margin-bottom: 6px;
    white-space: nowrap;
}
.profile-image-container-email {
    @include f-9;
    @include fw-400;
    @include o-30;
    white-space: nowrap;
    line-height: 0.875rem;
}

.alert-channels {
    @include container;
    @include br-20;
    max-width: 36rem;
    margin: 0 auto;
}
.alert-type-table {
    @include container;
    @include br-20;
    max-width: 36rem;
    margin: 0 auto;
}
.alert-types-table {
    table-layout: fixed;
}
.type-col {
    min-width: 20rem;
    text-align: left;
}
.medium-col {
    display: none;
    width: 6rem;
}
table {
    font-size: 0.875rem;
    letter-spacing: -0.00625rem;
}
thead {
    border-bottom: 1px solid;
    @include opteo-border;
}
th {
    font-weight: 500;
    vertical-align: middle;
    padding: 1.4375rem 1.5rem;
    white-space: nowrap;
}
.alert-type-item {
    border-bottom: 1px solid;
    @include opteo-border;
}
.alert-type-item:last-child {
    border-bottom: 0px solid;
}
.mobile-alert-type-input {
    display: flex;
    gap: 1.5rem;
    @include mt-16;
}
td {
    vertical-align: middle;
    padding: 1.25rem 1.5rem;
}

.desktop-alert-type-input {
    display: none;
}

@media (min-width: $mq-768-min) {
    .profile-image-uploader {
        @include pt-32;
        @include ph-32;
    }
    .alert-channels {
        @include pa-32;
        @include br-20;
        margin: 0 auto;
    }
    .type-col {
        min-width: calc(100% - 12rem);
    }
    th {
        padding: 1.75rem 2rem 1.75rem 2rem;
    }
    .medium-col {
        display: table-cell;
        width: 6.5rem;
    }
    .alert-type-item-description {
        padding: 2rem;
    }
    .desktop-alert-type-input {
        display: table-cell;
    }
    .mobile-alert-type-input {
        display: none;
    }
    td {
        vertical-align: middle;
        padding: 2rem;
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .settings-section:first-child {
        padding-top: unset;
    }
}
</style>
