<template>
    <div class="cheatsheet-content-wrapper">
        <section v-for="(item, index) in items" :key="'item-' + index">
            <!-- Title -->
            <Text as="h5" weight="600">
                {{ item.title }}
            </Text>
            <!-- Query -->
            <Spacer v-if="item.query" height="1.75rem" />
            <div
                v-if="item.query"
                v-html="item.query"
                :class="[item.modifyPadding ? 'modify-padding' : '', 'query']"
            />
            <!-- Paragraph -->
            <Spacer v-if="item.paragraph" height="1.5rem" />
            <Text v-if="item.paragraph" v-html="item.paragraph" as="p" />
        </section>
    </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue'

// Components
import { Text, Spacer } from '@opteo/components-next'

export default defineComponent({
    name: 'ChatCheatsheetContent',
    components: {
        // Components
        Text,
        Spacer,
    },
    setup() {
        const items = [
            {
                title: 'General principles',
                query: '<ul><li>Give clear and concise instructions</li><li>Ask Chat to explain its reasoning</li><li>Ask for adjustments where necessary</li><li>Good Input = Good Output</li><li>Chat only has access to the data you share</li></ul>',
                modifyPadding: true,
                paragraph: `The following queries are only intended as a starting point. For example, if you asked Chat to generate some RSA headlines and they need to be written in a different tone — ask Chat to make the adjustments for you. Get comfortable with this format of querying and adjusting, repeating the process as needed to achieve the desired output. Please note, <b>Chat does not have access to your account data as a whole</b>. Opteo sends a summary of your account data with your first message, after that point, you need to reference entities directly using <b>@</b> to share any further data with Chat.`,
            },
            {
                title: 'Generating RSA headlines and descriptions',
                query: 'Please generate 12 headlines and 12 descriptions for <b>ad group</b>. Headlines should be limited to 30 characters and descriptions should be limited to 90 characters (including spaces). The goal for this ad group is to <b>goal</b>. Focus on the following benefits: <b>Benefit One</b>, <b>Benefit Two</b>, <b>Benefit Three</b>. Format the results in a table. Use a different angle for each asset generated and include the angle as a column in the table. Potential angles could include: keyword focused, feature focused, benefit focused, price focused, brand focused, social proof, call to action, and so on.',
                paragraph:
                    'When attaching an entity (such as an ad group), Opteo will automatically include the top performing RSA in that entity. Querying ChatGPT is primarily about context — the more relevant context you can provide, the better the output you can expect. Mention product benefits, target audiences, tone of voice, a particular style you might want to replicate (e.g. “Headlines that sound like they were written by Apple”).',
            },
            {
                title: 'Generating keyword ideas',
                query: 'Please generate 20 potential keywords for <b>ad group</b>. Look at the existing converting keywords in this ad group and think about what might convert successfully. Show the results in a table, include a column specifying the keyword match type, and a column describing why you think the recommended keyword will perform well.',
                paragraph: `When attempting to generate potential keywords, include information about your target audience and customer intent. Whether you're looking to convince a given demographic to try a popular new product, or connect with potential customers that need emergency plumbing services — make sure Chat knows what you're trying to achieve.`,
            },
            {
                title: 'Generating negative keywords',
                query: 'Please generate 10 negative keywords I should consider for this account. I am already excluding traffic from searchers that are looking for the following: <b>Theme One</b>, <b>Theme Two</b>, <b>Theme Three</b>, <b>Theme Four</b>, <b>Theme Five</b>.',
                paragraph: `When attempting to generate negative keywords, include information about themes you have already excluded to prevent duplicate recommendations. Remember to share as much context as possible — the clearer the instructions, the better the output.`,
            },
            {
                title: 'Generating target audiences',
                query: 'Please recommend a target audience for this account, include as much detail as possible about affinity segments, demographics, life events, purchase intent, and so on.',
                paragraph: `When taking on a new client, asking Chat to recommend a target audience can give some insight into the company as a whole, the products they provide and the types of customers they're looking to target. This can be useful for deciding which campaigns to build, as well as understanding the search intent of any potential customers.`,
            },
            {
                title: 'Generating competitor analyses',
                query: 'Please generate a list of potential competitors for <b>company name</b>. List the name of the company, their location, business size (small, medium, or large), and whether or not they are a direct competitor. Compile the results in a table.',
                paragraph: `Chat can help you research the key competitors for a given account. This can be useful when searching for competitors to target, adding new keywords, identifying gaps in the market, understanding what gives your offering a competitive advantage, and so on.`,
            },
        ]
        return { items }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.cheatsheet-content-wrapper {
    @include flex;
    @include items-center;
    flex-direction: column;
    gap: 2.5rem;
}

.cheatsheet-content-wrapper .query {
    color: $opteo-dark-blue;
    background-color: $opteo-dark-blue-translucent;
    @include br-24;
    @include relative;
    letter-spacing: -0.005rem;
    line-height: 1.5rem;
    width: 100%;
    padding: 1.5rem 1.875rem;
}
.cheatsheet-content-wrapper .query.modify-padding {
    padding: 1.375rem 1.875rem;
}

.cheatsheet-content-wrapper .query ul li {
    @include flex;
    @include relative;
    padding-left: 1.375rem;
    line-height: 1.75rem;
}
.cheatsheet-content-wrapper .query ul li::before {
    content: '';
    @include absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 999px;
    background-color: $opteo-dark-blue;
}
</style>
