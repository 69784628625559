<template>
    <FullScreenOverlay @close="backToAlertsList">
        <div class="single-column">
            <div class="alert-container">
                <SingleAlert />
            </div>
        </div>
    </FullScreenOverlay>
</template>

<script lang="ts">
import { CloseButton } from '@opteo/components-next'

import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'
import Alert from '@/components/Alerts/Alert.vue'
import { provideAlert } from '@/composition/alerts/useAlert'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

export default {
    components: { SingleAlert: Alert, CloseButton, FullScreenOverlay },
    setup() {
        provideAlert()

        const { push } = useRouter()

        return {
            backToAlertsList: () => push({ name: Routes.Alerts }),
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.single-column {
    @include flex;
    @include justify-center;
}
.alert-container {
    width: 468px;
    @include container;
    @include pa-32;
    @include mv-48;
}

.close {
    right: 1.5rem;
    top: 1.5rem;
    position: absolute;
}

@media screen and (max-width: $mq-767-max) {
    .alert-container {
        box-shadow: unset;
        @include pa-24;
    }
}
</style>
