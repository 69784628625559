<template>
    <div class="report-slides" style="position: relative">
        <section class="w-100 flex items-top center">
            <div v-if="previewMode" class="preview-sidebar">
                <perfect-scrollbar ref="scroll">
                    <div id="mini-slides" class="preview-sidebar-container">
                        <div class="mini-slides-inner">
                            <div v-for="slide_type in orderedSlideTypes" :key="slide_type">
                                <div
                                    v-for="slide in slidesByType[slide_type]"
                                    :key="slide.slide_id"
                                    :class="{
                                        active: currentSlidePageNo === slide.page_no,
                                    }"
                                    class="preview-mini-slide-container"
                                >
                                    <a
                                        @click="goToSlide(slide.page_no)"
                                        class="preview-mini-slide-link"
                                    >
                                        <ReportSlide
                                            :id="`mini-slide--${slide.page_no}`"
                                            :slideNum="slide.page_no"
                                            :details="report.sender"
                                            :user="reportUser"
                                            :recipient="report.recipient"
                                            :date="report.date"
                                            :slide="slide"
                                            :theme="report.theme"
                                            :previewMode="previewMode"
                                            :report-period="{
                                                from: reportPeriodFrom,
                                                to: reportPeriodTo,
                                            }"
                                            class="mini-report-slide"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>

            <div class="w-100 relative z-2">
                <div
                    v-for="(slide_type, index) of orderedSlideTypes"
                    :key="slide_type"
                    :class="`slide-section slide-type-${slide_type}-cnt`"
                >
                    <div class="insight-column" :data-mode="previewMode ? 'preview' : null">
                        <!-- Cover Insights -->
                        <InsightCard v-if="coverSlideActive && slide_type === 0 && !previewMode">
                            <Text as="h3" class="mb3" size="f-6" weight="600">Insights</Text>
                            <Text class="mb-24" size="f-9">
                                Unique insights are generated for each of your reports. Using your
                                Google Ads account data, they surface information that clients and
                                managers might find interesting. Use insights to supplement your own
                                research and explain the why behind account performance.
                            </Text>
                            <Text as="h3" class="mb3" size="f-6" weight="600">Advice</Text>
                            <Text size="f-9">
                                Smart writing tips based on hundreds of customer interviews
                                conducted by our research team. Write useful, clear and engaging
                                reports that clients and managers are excited to read.
                            </Text>
                        </InsightCard>

                        <!-- Insights -->
                        <ReportInsights
                            v-if="slide_type >= 1 && !previewMode"
                            :content="report.insights[slide_type]"
                            :slides="slidesByType[slide_type]"
                        />
                    </div>

                    <div
                        :class="[
                            'slide-area',
                            {
                                'last-slide-area':
                                    previewMode && index === orderedSlideTypes.length - 1,
                            },
                        ]"
                    >
                        <!-- Slide(s) Container -->
                        <div class="w-100 relative" style="width: 842px">
                            <!-- Slide -->
                            <div
                                v-for="(slide, index) in slidesByType[slide_type]"
                                :id="`slide-${slide.page_no}`"
                                :ref="`slide${slide.category}`"
                                :key="slide.slide_id"
                            >
                                <div v-if="slide.active && !slide.invalid" class="slide-wrapper">
                                    <VisibilityWrapper
                                        @visibility-change="
                                            syncPreviewScroll(slide.page_no, $event)
                                        "
                                    >
                                        <ReportSlide
                                            v-if="slide.active && !slide.invalid"
                                            :ref="`slide--${slide.page_no}`"
                                            :id="`slide--${slide.page_no}`"
                                            :data-slide-id="slide.slide_id"
                                            :slideNum="slide.page_no"
                                            :details="report.sender"
                                            :user="reportUser"
                                            :recipient="report.recipient"
                                            :date="report.date"
                                            :data-type="slide.type"
                                            :data-title="slide.title"
                                            :data-index="slide.page_no"
                                            :slide="slide"
                                            :theme="report.theme"
                                            :report-period="{
                                                from: reportPeriodFrom,
                                                to: reportPeriodTo,
                                            }"
                                            :previewMode="previewMode"
                                            @slide-deleted="
                                                toggleSlideDeleted(slide.slide_id, false)
                                            "
                                            @slide-text-updated="
                                                updateSlideText(slide.slide_id, $event)
                                            "
                                            @slide-text-reset="resetSlideText(slide.slide_id)"
                                            @work-summary-button-clicked="workSummaryOpen = true"
                                        />
                                    </VisibilityWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slide-section">
                    <!-- include .insight-column here to persist sidebar border next in the hidden slides ui -->
                    <div class="insight-column"></div>
                    <div :class="['slide-area', { 'last-slide-area': !previewMode }]">
                        <ReportHiddenSlides
                            v-if="hiddenSlides.length && !previewMode"
                            :slides="hiddenSlides"
                            :domain-color="domainInfo.color_preference"
                            @restore-slide="restoreSlide"
                            @restore-all-slides="restoreAllSlides"
                        />
                    </div>
                </div>
                <Spacer height="9.5rem" />
            </div>
        </section>

        <ReportNav
            :color="domainInfo?.color_preference"
            :items="shownCategories"
            :selected-item="selectedCategory"
            :previewMode="previewMode"
            @change-item="goToSlideCategory"
        />
        <Modal
            width="1000px"
            title="Work Summary"
            v-model="workSummaryOpen"
            @closed="resetWorkSummary"
            :noPadding="true"
        >
            <template #content>
                <ReportWorkSummary />
            </template>
            <template #buttons>
                <div class="work-summary-modal-footer">
                    <div class="work-summary-modal-buttons">
                        <oButton @clicked="workSummaryOpen = false" color="white"> Cancel </oButton>
                        <oButton @clicked="updateWorkSummary" :loading="workSummaryLoading">
                            Update Work Summary
                        </oButton>
                    </div>
                    <div class="work-summary-modal-total">
                        <CheckIcon />
                        <Text size="f-8">
                            <b>{{ workSummaryTotals.tasks }}</b> tasks completed in
                            <b>{{ workSummaryTotals.time }}</b>
                        </Text>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts">
import { ReportSlide, Modal, oButton, Text, CheckIcon, Spacer } from '@opteo/components-next'

import { provideReportSlides } from '@/composition/reports/useReportSlides'
import InsightCard from '@/components/Reports/InsightCard.vue'
import ReportInsights from '@/components/Reports/ReportInsights.vue'
import ReportWorkSummary from '@/components/Reports/ReportWorkSummary.vue'
import ReportNav from '@/components/Reports/ReportNav.vue'
import ReportHiddenSlides from '@/components/Reports/ReportHiddenSlides.vue'
import VisibilityWrapper from '@/components/util/VisibilityWrapper.vue'

export default {
    name: 'ReportSlides',
    components: {
        VisibilityWrapper,
        ReportSlide,
        ReportHiddenSlides,
        InsightCard,
        ReportInsights,
        ReportNav,
        ReportWorkSummary,
        Modal,
        oButton,
        Text,
        CheckIcon,
        Spacer,
    },
    setup: () => {
        return provideReportSlides()
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.slide-section {
    display: grid;
    grid-template-columns: 342px 1fr;
}

.slide-wrapper {
    @include mt-48;
}

.insight-column {
    position: relative;
    height: 100%;
    flex: 0 0 342px;
    @include ph-32;
    border-right: 1px solid;
    @include opteo-border;
}
.insight-column[data-mode='preview'] {
    opacity: 0;
}
.slide-area {
    width: 100%;
    display: flex;
    justify-content: center;
}
.last-slide-area {
    // padding-bottom: 8rem;
}
.cover-insights {
    position: sticky;
    @include container;
    @include pa-24;
    @include mb-32;
    top: 32px;
}
.preview-sidebar {
    width: 342px;
    background-color: $opteo-white;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 2;
    border-right: 1px solid;
    @include opteo-border;
    z-index: 9;
}
.mini-slides-inner {
    padding-bottom: 6.5rem;
}

.preview-sidebar-container {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    width: 100%;
    height: 100vh;
}
.preview-mini-slide-container {
    // original slide dimensions: 842 x 595px
    // scale factor of 0.32897862232
    // -> mini slide dimensions: 277 x 195px
    height: 195px;
    margin-bottom: 1.5rem;
    border-radius: 0.25rem;
    overflow: hidden;
    @include container;
}
.preview-mini-slide-container.active {
    box-shadow: $opteo-shadow-focus;
}
.preview-mini-slide-container .mini-report-slide {
    width: 842px;
    transform: scale(0.32897862232);
    transform-origin: 0% 0%;
    margin: 0;
}
.preview-mini-slide-link {
    display: block;
    @include opteo-foreground;
}
.preview-mini-slide-container .mini-report-slide .slide {
    box-shadow: none !important;
}
.work-summary-modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.work-summary-modal-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.work-summary-modal-total {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}
</style>
