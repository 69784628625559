<template>
    <Tooltip :content="content" :max-width="maxWidth">
        <div class="not-enough-data-pill">
            <span class="not-enough-data-text">N/A</span>
            <svg
                v-if="icon"
                class="not-enough-data-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
            >
                <path
                    d="M7 0C3.13397 0 0 3.13397 0 7C0 10.866 3.13397 14 7 14C10.866 14 14 10.866 14 7C14.0002 3.13397 10.866 0 7 0ZM8.18062 10.4205C7.84101 10.5543 7.5701 10.6563 7.36775 10.7266C7.1654 10.7969 6.93043 10.8321 6.66299 10.8321C6.25136 10.8321 5.93153 10.7318 5.70363 10.5312C5.47544 10.3306 5.36142 10.076 5.36142 9.76724C5.36142 9.64715 5.36994 9.52462 5.38711 9.39934C5.40429 9.27421 5.43171 9.13276 5.46938 8.97501L5.894 7.47282C5.93196 7.32878 5.96386 7.19254 5.98955 7.06379C6.01524 6.9352 6.02823 6.81771 6.02823 6.71148C6.02823 6.51938 5.98868 6.38573 5.90959 6.31025C5.83049 6.23491 5.67924 6.19709 5.45567 6.19709C5.34569 6.19709 5.23311 6.21427 5.11779 6.24847C5.00247 6.28282 4.90361 6.3153 4.8212 6.34619L4.93449 5.88318C5.21262 5.77002 5.47876 5.67318 5.73278 5.59249C5.9868 5.51196 6.22726 5.47169 6.45386 5.47169C6.86245 5.47169 7.17753 5.57041 7.39893 5.76742C7.62033 5.96472 7.73103 6.22105 7.73103 6.53656C7.73103 6.60179 7.72324 6.71668 7.70779 6.88122C7.69235 7.04575 7.66406 7.19687 7.62293 7.33398L7.1999 8.83111C7.1654 8.9512 7.13437 9.08831 7.10695 9.24274C7.07953 9.39717 7.06567 9.51365 7.06567 9.5926C7.06567 9.79148 7.11012 9.92701 7.19917 9.99903C7.28823 10.0711 7.44237 10.1071 7.66161 10.1071C7.76423 10.1071 7.8807 10.0891 8.01089 10.0532C8.14093 10.0171 8.23518 9.98546 8.29348 9.9579L8.18062 10.4205ZM8.106 4.34216C7.9087 4.52561 7.67128 4.6174 7.39344 4.6174C7.11561 4.6174 6.87717 4.52575 6.67829 4.34216C6.4794 4.15872 6.37996 3.9366 6.37996 3.67594C6.37996 3.41528 6.4794 3.19229 6.67829 3.00711C6.87717 2.82194 7.11561 2.72928 7.39344 2.72928C7.67128 2.72928 7.9087 2.82194 8.106 3.00711C8.30315 3.19229 8.40188 3.41528 8.40188 3.67594C8.40188 3.9366 8.30315 4.15872 8.106 4.34216Z"
                    fill="#DDDFE4"
                />
            </svg>
        </div>
    </Tooltip>
</template>

<script lang="ts">
import { defineComponent, PropType, VNode } from 'vue'
import { Tooltip } from '@opteo/components-next'

export default defineComponent({
    props: {
        content: {
            type: String as PropType<string>,
            default: 'Not enough data',
            required: false,
        },
        icon: {
            type: Boolean as PropType<boolean>,
            default: true,
            required: false,
        },
        maxWidth: {
            type: Number as PropType<Number>,
            default: 250,
            required: false,
        },
    },
    components: {
        Tooltip,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.not-enough-data-pill {
    // @include container;
    // @include pv-4;
    // @include ph-8;
    @include inline-flex;
    @include items-center;
    @include justify-between;
    cursor: default;
}
.not-enough-data-text {
    // font-size: 12px;
    color: #bec1cb;
}
.not-enough-data-icon {
    // font-size: 12px;
    color: #bec1cb;
    margin-left: 0.375rem;
}
</style>
