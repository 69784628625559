import { inject, ComputedRef, computed } from 'vue'
import { useMoney } from '@opteo/components-next'
import { ProvideKeys } from '@/composition/useProvide'

export function useDomainMoney({
    value,
    locale,
    compact,
}: {
    value: number
    locale?: string
    compact?: boolean
}) {
    const currency = inject<ComputedRef<string>>(ProvideKeys.CurrencyCode)

    if (typeof currency === 'undefined') {
        throw new Error(
            'Currency cannot be undefined, useDomainMoney must be used at domain level.'
        )
    }

    return computed(() => useMoney({ value, currency: currency.value, locale, compact }))
}
