<template>
    <div class="rsa-saved-status">
        <div class="icon-container">
            <Spinner v-if="saving" />
            <CheckIcon v-else />
        </div>
        <Text v-if="saving" color="blue" as="p" class="text-slot saving">Saving</Text>
        <Text v-else color="green" as="p" class="text-slot">Saved</Text>
    </div>
</template>

<script lang="ts">
import { CheckIcon, Spinner, Text } from '@opteo/components-next'

export default {
    name: 'RsaSavedStatus',
    components: { Text, CheckIcon, Spinner },
    props: {
        saving: {
            type: Boolean,
            required: true,
            default: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Base
.rsa-saved-status {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none;
    font-family: inherit;
    transition: transform 0.25s cubic-bezier(0.19, 1, 0.22, 1), background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0.625rem;
    position: relative;
    font-weight: 500;
    user-select: none;
    -webkit-user-select: none;
    @include opteo-foreground;
    box-shadow: $opteo-shadow;
    padding: 0.625rem 0.8125rem;
    overflow: hidden;
}
.rsa-saved-status .icon-container {
    width: 1.5rem;
    height: 1.5rem;
    @include flex-center;
}
.rsa-saved-status .text-slot {
    margin-left: 0.5625rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8125rem;
    letter-spacing: -0.0125rem;
    font-weight: 500;
}
.rsa-saved-status .text-slot.saving {
    margin-left: 0.4375rem;
}
</style>
