<template>
    <div :class="{ 'score-list': !removeBorder }">
        <div :class="{ 'score-list-items': !removeBorder }">
            <div v-for="(item, index) in items" :key="index" class="score-list-row">
                <InlineAlignmentOverlap
                    v-if="isInlineAlignmentOverlapItem(item.text)"
                    size="small"
                    :left="item.text.leftItem"
                    :right="item.text.rightItem"
                    :disabled="item.scoreDonut?.hidden"
                />

                <Text v-else size="f-8" style="white-space: nowrap">
                    <span v-html="item.text"></span>
                </Text>

                <div v-if="item.scoreDonut">
                    <div v-if="scoreMode === 'donut'" class="donut-value">
                        <ScoreDonutValueCombo
                            :key="item.scoreDonut.score"
                            :score="item.scoreDonut.score"
                            :data-type="item.scoreDonut.dataType"
                            :is-percent-final="item.scoreDonut.isPercentFinal"
                            :hidden="item.scoreDonut.hidden"
                        />
                    </div>
                    <div>
                        <ScorePill
                            v-if="scoreMode === 'pill'"
                            :key="item.scoreDonut.score"
                            :score="item.scoreDonut.score"
                            :data-type="item.scoreDonut.dataType"
                            :is-percent-final="item.scoreDonut.isPercentFinal"
                            :hidden="item.scoreDonut.hidden"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Text, DataType } from '@opteo/components-next'
import ScorePill from '@/components/scorecard/ScorePill.vue'
import ScoreDonutValueCombo from '@/components/scorecard/ScoreDonutValueCombo.vue'
import InlineAlignmentOverlap from '../InlineAlignmentOverlap.vue'

type InlineAlignmentOverlapItem = {
    leftItem?: { name: string; color: string }
    rightItem?: { name: string; color: string }
}

export interface Props {
    scoreMode?: 'donut' | 'pill'
    labelMode?: 'text' | 'overlap'
    items: {
        text: string | InlineAlignmentOverlapItem
        scoreDonut?: {
            score: number
            dataType?: typeof DataType.number | typeof DataType.percentage
            isPercentFinal?: boolean
            hidden?: boolean
        }
    }[]
    removeBorder: boolean
}

withDefaults(defineProps<Props>(), {
    scoreMode: 'pill',
    labelMode: 'text',
    removeBorder: false,
})

const isInlineAlignmentOverlapItem = (itemText: unknown): itemText is InlineAlignmentOverlapItem =>
    typeof itemText === 'object' &&
    itemText !== null &&
    ('leftItem' in itemText || 'rightItem' in itemText)
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.score-list {
    @include pa-20;
}
.score-list-items {
    @include container;
}
.score-list-row {
    @include flex;
    @include items-center;
    @include justify-between;
    @include ph-32;
    @include pv-20;
    gap: 1.75rem;
    border-bottom: 1px solid $opteo-light-gray;

    .donut-value {
        width: 4rem;
    }
}
.score-list-row:last-child {
    border-bottom: none;
}
</style>
