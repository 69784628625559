<template>
    <div
        class="settings-slack-connect-container"
        :class="isDomainSpecificConnection ? 'domain-specific-connection' : 'general-connection'"
    >
        <template v-if="slackSettings">
            <div class="slack-settings">
                <div class="slack-connection">
                    <CheckIcon />
                    <div class="slack-connection-info">
                        <Text as="p" size="f-8" weight="500">
                            {{ slackSettings.channel_name }}
                        </Text>
                        <Text as="p" size="f-10" color="gray">
                            Connected to {{ slackSettings.workspace_name ?? 'Opteo' }}
                        </Text>
                    </div>
                </div>
                <div class="slack-edit-btns">
                    <oButton
                        v-if="!isPopup"
                        @clicked="connectSlack()"
                        color="white"
                        :circle="true"
                        size="x-small"
                    >
                        <SmallPencilIcon style="transform: translateY(1px)" hex="#cacad3" />
                    </oButton>
                </div>
            </div>

            <div class="slack-edit-btns domain-specific-connection-btn">
                <oButton @clicked="removeSlack()" size="medium">Remove Slack Connection</oButton>
            </div>
        </template>

        <div v-else>
            <oButton v-if="!isDomainSpecificConnection" @clicked="connectSlack()" size="medium">
                Connect Slack
            </oButton>
            <oButton v-else @clicked="connectSlack()" size="medium">Connect Slack Channel</oButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import SmallPencilIcon from '@/components/chat/SmallPencilIcon.vue'
import { oButton, CheckIcon, Text } from '@opteo/components-next'

import { useSlack } from '@/composition/connectors/useSlack'

interface Props {
    isPopup?: boolean
    isDomainSpecificConnection: boolean
}

const { isPopup, isDomainSpecificConnection } = withDefaults(defineProps<Props>(), {
    isPopup: false,
})

const { connectSlack, removeSlack, slackSettings } = useSlack({
    isPopup,
    isDomainSpecificConnection,
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.settings-slack-connect-container {
    @include flex;
    @include items-center;

    &.general-connection {
        @include w-100;
        flex-direction: column;
        gap: 1.5rem;
    }

    &.domain-specific-connection {
        @include w-100;
        flex-direction: column;
        gap: 1.5rem;
    }
}

.slack-settings {
    @include container;
    @include pv-20;
    @include ph-24;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.slack-connection {
    @include flex;
    @include items-center;
    @include mb-12;
}

.slack-connection-info {
    @include pl-16;
    @include flex;
    flex-direction: column;
}

.slack-edit-btns {
    @include relative;
    @include flex;
    @include items-center;
    @include z-2;
}

.slack-edit-btns::after {
    content: '';
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 62.5%);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(100% + 2rem);
    z-index: -1;
}

@media (min-width: $mq-768-min) {
    .slack-settings {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .slack-connection {
        display: flex;
        align-items: center;
        @include mb-0;
    }

    .slack-edit-btns {
        &.general-connection-btns {
            position: absolute;
            @include pr-20;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }
}

@media (min-width: $mq-1024-max) {
    .slack-connection {
        display: flex;
        align-items: center;
        @include mb-0;
    }

    .slack-edit-btns {
        &.general-connection-btns {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }
}

@media screen and (max-width: $mq-375-max) {
    .general-connection-btns {
        padding-left: unset;

        &.o-button {
            width: 50%;
        }
    }
}

@media screen and (min-width: $mq-0-min) and (max-width: $mq-767-max) {
    .settings-slack-connect-container {
        &.general-connection {
            margin-left: 0;
        }
    }
}
</style>
