<template>
    <div class="rsa-serp-preview">
        <header>
            <div class="left">
                <Text as="h5" weight="600">SERP Preview</Text>
            </div>
            <div class="header-slot">
                <slot name="header"></slot>
            </div>
        </header>
        <Spacer height="1.75rem" />
        <div :style="{ height: imageLoaded ? 'auto' : '600px' }" class="serp-container">
            <img v-if="!imageErrored" :src="imageUrl" @load="onImageLoaded" v-bind="$attrs" :onerror="onLoadingError" />
            <div v-show="!imageLoaded && serpAvailable && !imageErrored" class="spinner-container">
                <Spinner />
            </div>
            <div v-if="!serpAvailable || imageErrored" class="spinner-container">
                <Text weight="400">SERP preview could not be loaded.</Text>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs } from 'vue'
import { Spacer, Spinner, Text } from '@opteo/components-next'

export default defineComponent({
    components: {
        Text,
        Spacer,
        Spinner,
    },
    inheritAttrs: false,
    props: {
        title: {
            type: String,
            default: 'Search Results Preview',
        },
        imageUrl: {
            type: String,
            default: '',
        },
        serpAvailable: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const { serpAvailable } = toRefs(props)
        const imageLoaded = ref(false)
        const imageErrored = ref(false)

        function onImageLoaded() {
            imageLoaded.value = true
        }

        function onLoadingError() {
            imageErrored.value = true
        }

        return { onImageLoaded, imageLoaded, serpAvailable, onLoadingError, imageErrored }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.rsa-serp-preview {
    @include container;
    @include br-24;
    @include pt-28;
    @include ph-32;
    @include pb-32;
    // max-width: 55rem;
    margin: 0 auto;
    @include w-100;
}
.rsa-serp-preview header {
    @include flex;
    @include items-center;
    @include justify-between;
    height: 1.75rem;
}
.rsa-serp-preview header .left,
.rsa-serp-preview header .header-slot {
    @include flex;
    @include items-center;
}

.serp-container {
    height: 600px;
    max-width: 700px;
    margin: 0 auto;
    overflow: hidden;
    @include container;
    @include br-20;
    @include relative;
}
.serp-container img {
    max-width: unset;
    width: 800px;
    display: block;
}

.spinner-container {
    @include flex-center;
    width: 100%;
    height: 600px;
    @include z-10;
    @include bg-opteo-white;
    @include relative;
}
@media screen and (min-width: 0px) and (max-width: 320px) {
    .serp-container img {
        max-width: 388px;
    }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
    .serp-container img {
        max-width: 464px;
    }
}
@media screen and (min-width: 376px) and (max-width: 479px) {
    .serp-container img {
        max-width: 520px;
    }
}
@media screen and (min-width: 480px) and (max-width: 600px) {
    .serp-container img {
        max-width: 640px;
    }
}
</style>
