<template>
    <div class="channel-item">
        <div class="channel-item-info">
            <slot name="icon"></slot>
            <div class="channel-item-text">
                <Text weight="500">{{ name }}</Text>
                <Spacer height="0.5rem" />
                <Text size="f-8">{{ description }}</Text>
            </div>
        </div>
        <slot name="connector"></slot>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Text, Spacer } from '@opteo/components-next'

export default defineComponent({
    props: {
        name: {
            type: String,
            default: false,
        },
        description: {
            type: String,
            default: false,
        },
    },
    setup() {},
    components: {
        Text,
        Spacer,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.channel-item {
    @include pa-32;
}
.channel-item:first-child {
    border-bottom: 1px solid $opteo-light-gray;
}
.channel-item-info {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 1rem;
}

@media (min-width: $mq-768-min) {
    .channel-item {
        @include pa-32;
        @include container;
    }
    .channel-item-info {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 1.75rem;
    }
}
</style>
