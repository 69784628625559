import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useNumber, usePercent } from '@opteo/components-next'
import { computed, Ref, ref } from 'vue'
import { checkImprovement, useImprovement, UseImprovement, OnPushHandler } from '../useImprovement'

interface Body {
    status: string
    domain_id: string
    adgroup_id: string
    campaign_id: string
    overlapping_days: number
    ctr_change: number
    confidence: number
    cpi_confidence: number
    cpi_mode: boolean
    cpi_change: number
    ad_type: string
    mobile: boolean
    worst_ad: Ad
    best_ad: Ad
    campaign_label: string
    adgroup_label: string
    adgroup_30d_cost: number
}

interface Ad {
    id: string
    image_name: string
    image_url: string
    image_width: number | null
    image_height: number | null
    raw_clicks: number
    clicks: number
    impressions: number
    cost: number
    impr_abs_top: number
    impr_top: number
    ctr: number
    raw_ctr: number
    conversions: number
    conversion_value: number
    conversion_rate: number
    cost_conversion: number | null
    cpc: number
    ppi: number
    cpi: number
    cpa: number
    device_preference: string
    headline: string
    expanded_text_ad_headline3: string
    headline_part_1: string
    headline_part_2: string
    line_1: string
    line_2: string
    description: string
    expanded_text_ad_description2: string
    display_url: string
    path_1: string
    path_2: string
    legacy: boolean
    root_domain: string
}

interface adData {
    ad: {
        headlineOne: string
        headlineTwo: string
        headlineThree: string
        descriptionOne: string
        descriptionTwo: string
        displayUrl: string
        imageUrl: string
    }
    statistics: adStatistics[]
    titleTag: { title: string; classes: string }
}

interface adStatistics {
    name: string
    content: string
    color: string
}
interface UsePauseAd {
    adgroupLabel: string
    campaignLabel: string
    cpiChange: number
    ctrChange: number
    cpiMode: boolean
    bestAdData: adData
    worstAdData: adData
    comparisonTableHeaders: { key: string; text: string; sortable: boolean }[]
    selectedAdToPause: Ref<string>
    comparisonTableItems: {
        metric: string
        winner: string
        loser: string
    }[]
    stats: { title: string; value: string; key: string }[]
    adSelectItems: SelectItem[]
    adType: string
    handleSelectAdToPause: () => void
}

interface SelectItem {
    label: string
    value: string
}

export function usePauseAd(): UseImprovement<UsePauseAd> {
    const { improvement, lastUpdated, title } = useImprovement<Body>()
    const { body } = checkImprovement<Body>(improvement)

    const {
        adgroup_label: adgroupLabel,
        campaign_label: campaignLabel,
        cpi_change: cpiChange,
        ctr_change: ctrChange,
        cpi_mode: cpiMode,
        ad_type: adType,
        confidence,
        overlapping_days: overlappingDays,
        best_ad,
        worst_ad,
    } = body

    const selectedAdToPause = ref('red')

    const stats = [
        cpiMode
            ? {
                  key: 'cpi',
                  value: usePercent({ value: cpiChange / 100 }).displayValue.value,
                  title: 'CPI Improvement',
              }
            : {
                  key: 'ctr',
                  value: usePercent({ value: ctrChange / 100 }).displayValue.value,
                  title: 'CTR Improvement',
              },
        {
            key: 'confidence',
            value: usePercent({ value: confidence / 100 }).displayValue.value,
            title: 'Confidence',
        },
        {
            key: 'days-counted',
            value: useNumber({ value: overlappingDays }).displayValue.value,
            title: 'Days Counted',
        },
    ]

    const bestAdData: adData = {
        ad: {
            headlineOne: best_ad.headline_part_1,
            headlineTwo: best_ad.headline_part_2,
            headlineThree: best_ad.expanded_text_ad_headline3,
            descriptionOne: best_ad.description,
            descriptionTwo: best_ad.expanded_text_ad_description2,
            displayUrl: best_ad.display_url,
            imageUrl: best_ad.image_url,
        },
        statistics: [
            {
                name: 'Clicks',
                content: useNumber({ value: best_ad.raw_clicks }).displayValue.value,
                color: 'green',
            },
        ],
        titleTag: { title: 'Winning Ad', classes: 'opteo-green' },
    }

    const worstAdData: adData = {
        ad: {
            headlineOne: worst_ad.headline_part_1,
            headlineTwo: worst_ad.headline_part_2,
            headlineThree: worst_ad.expanded_text_ad_headline3,
            descriptionOne: worst_ad.description,
            descriptionTwo: worst_ad.expanded_text_ad_description2,
            displayUrl: worst_ad.display_url,
            imageUrl: worst_ad.image_url,
        },
        statistics: [
            {
                name: 'Clicks',
                content: useNumber({ value: worst_ad.raw_clicks }).displayValue.value,
                color: 'red',
            },
        ],
        titleTag: { title: 'Losing Ad', classes: 'opteo-red' },
    }

    const comparisonTableHeaders = [
        { key: 'metric', text: 'Metric', sortable: false },
        { key: 'winner', text: 'Winning Ad', sortable: false },
        { key: 'loser', text: 'Losing Ad', sortable: false },
    ]
    const comparisonTableItems = []

    // for comparison table items
    if (!cpiMode) {
        // comparison table items
        comparisonTableItems.push({
            metric: 'Click Through Rate',
            winner: usePercent({ value: best_ad.raw_ctr / 100 }).displayValue.value,
            loser: usePercent({ value: worst_ad.raw_ctr / 100 }).displayValue.value,
        })
        comparisonTableItems.push({
            metric: 'Clicks',
            winner: useNumber({ value: best_ad.raw_clicks }).displayValue.value,
            loser: useNumber({ value: worst_ad.raw_clicks }).displayValue.value,
        })
    }

    if (cpiMode) {
        // comparison table items
        comparisonTableItems.push({
            metric: 'Conv. Per Impression',
            winner: `${Math.round((best_ad.cpi + Number.EPSILON) * 100000) / 100000}`,
            loser: `${Math.round((worst_ad.cpi + Number.EPSILON) * 100000) / 100000}`,
        })
        comparisonTableItems.push({
            metric: 'Conversions',
            winner: useNumber({ value: best_ad.conversions }).displayValue.value,
            loser: useNumber({ value: worst_ad.conversions }).displayValue.value,
        })
    }

    if (adType !== 'image') {
        comparisonTableItems.push({
            metric: 'Abs. Top Impr. Share',
            winner: usePercent({ value: best_ad.impr_abs_top }).displayValue.value,
            loser: usePercent({ value: worst_ad.impr_abs_top }).displayValue.value,
        })
        comparisonTableItems.push({
            metric: 'Top Impr. Share',
            winner: usePercent({ value: best_ad.impr_top }).displayValue.value,
            loser: usePercent({ value: worst_ad.impr_top }).displayValue.value,
        })
    }

    if (adType === 'image') {
        comparisonTableItems.push({
            metric: 'Cost Per Acquisition',
            winner: useDomainMoney({ value: best_ad.cpa }).value.displayValue.value,
            loser: useDomainMoney({ value: worst_ad.cpa }).value.displayValue.value,
        })
    }

    if (best_ad.cpc) {
        comparisonTableItems.push({
            metric: 'Impressions',
            winner: useNumber({ value: best_ad.impressions }).displayValue.value,
            loser: useNumber({ value: worst_ad.impressions }).displayValue.value,
        })
        comparisonTableItems.push({
            metric: 'Cost',
            winner: useDomainMoney({ value: best_ad.cost }).value.displayValue.value,
            loser: useDomainMoney({ value: worst_ad.cost }).value.displayValue.value,
        })
        comparisonTableItems.push({
            metric: 'Cost Per Click',
            winner: useDomainMoney({ value: best_ad.cpc }).value.displayValue.value,
            loser: useDomainMoney({ value: worst_ad.cpc }).value.displayValue.value,
        })
    }

    if (best_ad.conversion_rate) {
        if (!cpiMode) {
            comparisonTableItems.push({
                metric: 'Conversions',
                winner: useNumber({ value: best_ad.conversions }).displayValue.value,
                loser: useNumber({ value: worst_ad.conversions }).displayValue.value,
            })
        }
        if (cpiMode) {
            comparisonTableItems.push({
                metric: 'Clicks',
                winner: useNumber({ value: best_ad.raw_clicks }).displayValue.value,
                loser: useNumber({ value: worst_ad.raw_clicks }).displayValue.value,
            })
        }
        comparisonTableItems.push({
            metric: 'Conversion Rate',
            winner: usePercent({ value: best_ad.conversion_rate / 100 }).displayValue.value,
            loser: usePercent({ value: worst_ad.conversion_rate / 100 }).displayValue.value,
        })
        if (adType !== 'image') {
            comparisonTableItems.push({
                metric: 'Cost Per Acquisition',
                winner: useDomainMoney({ value: best_ad.cpa }).value.displayValue.value,
                loser: useDomainMoney({ value: worst_ad.cpa }).value.displayValue.value,
            })
        }
        if (adType === 'image') {
            comparisonTableItems.push({
                metric: 'Abs. Top Impr. Share',
                winner: usePercent({ value: best_ad.impr_abs_top }).displayValue.value,
                loser: usePercent({ value: worst_ad.impr_abs_top }).displayValue.value,
            })
            comparisonTableItems.push({
                metric: 'Top Impr. Share',
                winner: usePercent({ value: best_ad.impr_top }).displayValue.value,
                loser: usePercent({ value: worst_ad.impr_top }).displayValue.value,
            })
        }
    }

    if (best_ad.ppi) {
        comparisonTableItems.push({
            metric: 'Value',
            winner: useDomainMoney({ value: best_ad.conversion_value }).value.displayValue.value,
            loser: useDomainMoney({ value: worst_ad.conversion_value }).value.displayValue.value,
        })
        if (!cpiMode) {
            comparisonTableItems.push({
                metric: 'Conv. Per Impression',
                winner: usePercent({ value: best_ad.cpi * 10 }).displayValue.value,
                loser: usePercent({ value: worst_ad.cpi * 10 }).displayValue.value,
            })
        }
        if (cpiMode) {
            comparisonTableItems.push({
                metric: 'Click Through Rate',
                winner: usePercent({ value: best_ad.raw_ctr / 100 }).displayValue.value,
                loser: usePercent({ value: worst_ad.raw_ctr / 100 }).displayValue.value,
            })
        }
        comparisonTableItems.push({
            metric: 'Profit Per Impression',
            winner: useDomainMoney({ value: best_ad.ppi }).value.displayValue.value,
            loser: useDomainMoney({ value: worst_ad.ppi }).value.displayValue.value,
        })
    }

    if (cpiMode) {
        // text ad dependent stats
        bestAdData.statistics.push({
            name: 'CPI',
            content: `${Math.round((best_ad.cpi + Number.EPSILON) * 100000) / 100000}`,
            color: 'green',
        })
        worstAdData.statistics.push({
            name: 'CPI',
            content: `${Math.round((worst_ad.cpi + Number.EPSILON) * 100000) / 100000}`,
            color: 'red',
        })
    } else {
        // text ad dependent stats
        bestAdData.statistics.push({
            name: 'CTR',
            content: usePercent({ value: best_ad.ctr / 100 }).displayValue.value,
            color: 'green',
        })
        worstAdData.statistics.push({
            name: 'CTR',
            content: usePercent({ value: worst_ad.ctr / 100 }).displayValue.value,
            color: 'red',
        })
    }

    function handleSelectAdToPause() {
        pushActionText.value =
            selectedAdToPause.value === 'red' ? 'Pause Losing Ad' : 'Pause Winning Ad'
    }

    const pushActionText = ref(
        selectedAdToPause.value === 'red' ? 'Pause Losing Ad' : 'Pause Winning Ad'
    )

    const pushMessages = computed(() =>
        selectedAdToPause.value === 'red'
            ? [
                  'Connecting to Google Ads..',
                  'Pausing losing ad..',
                  'Confirming changes..',
                  'Losing ad paused successfully.',
              ]
            : [
                  'Connecting to Google Ads..',
                  'Pausing winning ad..',
                  'Confirming changes..',
                  'Winning ad paused successfully.',
              ]
    )

    const adSelectItems: SelectItem[] = [
        {
            label: 'Losing Ad',
            value: 'red',
        },
        {
            label: 'Winning Ad',
            value: 'green',
        },
    ]

    const onPush: OnPushHandler<{ value: string }> = () => {
        return {
            valid: true,
            pushedData: { value: selectedAdToPause.value },
        }
    }

    return {
        title,
        lastUpdated,
        pushMessages,
        adgroupLabel,
        campaignLabel,
        cpiChange,
        ctrChange,
        cpiMode,
        bestAdData,
        worstAdData,
        comparisonTableHeaders,
        comparisonTableItems,
        stats,
        onPush,
        adSelectItems,
        selectedAdToPause,
        adType,
        handleSelectAdToPause,
        pushActionText,
    }
}
