<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="false"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill
                        v-if="singleCampaign"
                        :content="campaignNames[0].content"
                        :type="campaignNames[0].type"
                    />
                    <EntityPill v-else :content="campaignGroupName" type="campaign-group" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    In this campaign{{ !singleCampaign ? ' group, ' : ', ' }}your ads are
                    {{ isLocationUnderperforming ? 'underperforming' : 'performing well' }}
                    for searchers in the <b>{{ locationTargetType }}</b> listed below.

                    <template v-if="isCpaMode">
                        Searchers in this location generated
                        <b><Number :value="metrics.conversions.value" /></b>
                        {{ metrics.conversions.copy }} at a cost of
                        <b><Money :value="metrics.cost.value" /></b> with an average CPA of
                        <b><Money :value="metrics.cpa.value" /></b>. This is
                        <Text
                            as="span"
                            :color="isLocationUnderperforming ? 'red' : 'green'"
                            weight="600"
                        >
                            <Percent :value="performanceDifference" />
                            {{ isLocationUnderperforming ? 'higher' : 'lower' }}
                        </Text>
                        {{
                            singleCampaign
                                ? 'than the campaign average'
                                : 'than the average CPA of the campaigns below'
                        }}
                        (<b><Money :value="metrics.environmentCpa.value" /></b>).
                    </template>

                    <template v-else>
                        Searchers in this location generated
                        <b><Money :value="metrics.conversionsValue.value" /></b>
                        {{ metrics.conversionsValue.copy }} at a cost of
                        <b><Money :value="metrics.cost.value" /></b> with an average ROAS of
                        <b><Roas :value="metrics.roas.value" /></b>. This is
                        <Text
                            as="span"
                            :color="isLocationUnderperforming ? 'red' : 'green'"
                            weight="600"
                        >
                            <Percent :value="performanceDifference" />
                            {{ isLocationUnderperforming ? 'lower' : 'higher' }}
                        </Text>
                        {{
                            singleCampaign
                                ? 'than the campaign average'
                                : 'than the average ROAS of the campaigns below'
                        }}
                        (<b><Roas :value="metrics.environmentRoas.value" /></b>).
                    </template>

                    <span v-if="singleCampaign">
                        Consider excluding this location to reduce wasted spend.
                    </span>
                    <span v-else>Opteo recommends excluding this location.</span>
                </Text>

                <Spacer height="2rem" />

                <div v-if="aboveMobile">
                    <EntityPill :content="trueLocationName" type="location" />
                    <Spacer height="2rem" />
                </div>

                <Text as="p">
                    This improvement will prevent your ads from being shown to searchers in the
                    location above.
                    <template v-if="isCpaMode">
                        <template v-if="metrics.conversionsDecrease.value">
                            This change should lead to a small decrease in
                            {{ metrics.conversionsDecrease.copy }} — around
                            <b><Number :value="metrics.conversionsDecrease.value" /></b> per month,
                            however monthly spend should be reduced by
                            <b><Money :value="metrics.costDecrease.value" /></b>
                            (<Text as="span" color="green" weight="600">
                                <Percent :value="metrics.costDecreasePercent.value" /> </Text
                            >) and overall CPA should decrease from
                            <b><Money :value="metrics.environmentCpa.value" /></b> to
                            <b><Money :value="metrics.environmentCpaAfter.value" /></b>
                            (<Text as="span" color="green" weight="600">
                                <Percent :value="metrics.cpaDecreasePercent.value" /></Text
                            >).
                        </template>
                        <template v-else>
                            This change should decrease monthly spend by
                            <b><Money :value="metrics.costDecrease.value" /></b>
                            (<Text as="span" color="green" weight="600">
                                <Percent :value="metrics.costDecreasePercent.value" /> </Text
                            >) and overall CPA should decrease from
                            <b><Money :value="metrics.environmentCpa.value" /></b> to
                            <b><Money :value="metrics.environmentCpaAfter.value" /></b>
                            (<Text as="span" color="green" weight="600">
                                <Percent :value="metrics.cpaDecreasePercent.value" /></Text
                            >) with <b>no loss</b> of conversions.
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="metrics.conversionValueDecrease.value">
                            This change should lead to a small decrease in
                            {{ metrics.conversionValueDecrease.copy }} — around
                            <b><Money :value="metrics.conversionValueDecrease.value" /></b> per
                            month, however monthly spend should be reduced by
                            <b><Money :value="metrics.costDecrease.value" /></b>
                            (<Text as="span" color="green" weight="600"
                                ><Percent :value="metrics.costDecreasePercent.value" /> </Text
                            >) and overall ROAS should increase from
                            <b><Roas :value="metrics.environmentRoas.value" /></b> to
                            <b><Roas :value="metrics.environmentRoasAfter.value" /></b>
                            (<Text as="span" color="green" weight="600">
                                <Percent :value="metrics.roasIncreasePercent.value" /></Text
                            >).
                        </template>
                        <template v-else>
                            This change should decrease monthly spend by
                            <b><Money :value="metrics.costDecrease.value" /></b>
                            (<Text as="span" color="green" weight="600">
                                <Percent :value="metrics.costDecreasePercent.value" /> </Text
                            >) and ROAS should increase from
                            <b><Roas :value="metrics.environmentRoas.value" /></b> to
                            <b><Roas :value="metrics.environmentRoasAfter.value" /></b>
                            (<Text as="span" color="green" weight="600">
                                <Percent :value="metrics.roasIncreasePercent.value" /> </Text
                            >) with <b>no loss</b> of {{ metrics.conversionValueDecrease.copy }}.
                        </template>
                    </template>
                    <Spacer height="1rem" />
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    exclude searchers based in this location. This exclusion will be applied to the
                    <Text as="span" weight="600" v-if="campaignCount > 1">{{ campaignCount }}</Text>
                    {{ singleCampaign ? 'campaign listed above' : 'campaigns listed below' }}.
                </Text>
                <div v-if="!singleCampaign">
                    <Spacer height="2rem" />
                    <EntityPillList :items="campaignNames" />
                    <Spacer height="2rem" />
                    <ImprovementNote
                        ><b>Note:</b> Frequently campaigns don't have enough location performance
                        data for Opteo to suggest a location exclusion per campaign. In this
                        scenario, Opteo combines campaigns with insufficient data to suggest an
                        exclusion based on the sum of their data.
                    </ImprovementNote>
                </div>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/8948177-exclude-location">
                Click here to learn more about the benefits of location exclusions.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <div v-if="mapImageUrl" class="location-breakdown-image">
                <!-- Show placeholder while map loads. -->
                <div v-show="mapIsLoading" class="relative">
                    <div class="map-spinner">
                        <Spinner />
                    </div>
                    <oImage
                        v-if="locationTypeIsCountry"
                        :src="mapLoaderSmall"
                        :placeholder="mapLoaderSmallPlaceholder"
                        :width="604"
                        :height="420"
                    />
                    <oImage
                        v-else
                        :src="mapLoaderLarge"
                        :placeholder="mapLoaderLargePlaceholder"
                        :width="604"
                        :height="540"
                    />
                </div>
                <!-- Location name -->
                <span class="short-location-name">{{ shortLocationName }}</span>
                <!-- Render map once it has loaded. -->
                <oImage :src="mapImageUrl" @load="setMapIsLoadingTrue" />
            </div>

            <!-- If map is non-renderable display placeholder. -->
            <div v-else class="relative">
                <div class="overlay-tag">
                    <Text as="p" size="f-9">
                        Map preview unavailable for this {{ locationTargetType }}
                    </Text>
                </div>
                <div class="location-breakdown-image">
                    <oImage
                        :src="mapLoaderSmall"
                        :placeholder="mapLoaderSmallPlaceholder"
                        :width="604"
                        :height="420"
                    />
                </div>
            </div>
            <Spacer height="2rem" />

            <oTable
                :headers="locationTableHeaders"
                :items="locationTableItems"
                responsive-mode="columns"
                responsive-breakpoint="768px"
            >
                <template v-slot:[`column.bidMod`]="bidMod">
                    <Text
                        v-if="bidMod.value.includes('+') || bidMod.value.includes('-')"
                        :color="bidMod.value.includes('+') ? 'green' : 'red'"
                        as="span"
                        weight="500"
                        size="f-8"
                    >
                        {{ bidMod.value }}
                    </Text>
                    <Text v-else as="span" weight="400" size="f-8">
                        {{ bidMod.value }}
                    </Text>
                </template>
            </oTable>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="window" />
        </ImprovementContent>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
    EntityPillList,
    EntityPill,
    Spacer,
    Text,
    Number,
    Percent,
    Roas,
    oButton,
    oTable,
    oImage,
    Spinner,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useAdjustLocationBids } from '@/composition/improvement/types/useAdjustLocationBids'

export default defineComponent({
    name: 'ExludeLocation',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        EntityPillList,
        EntityPill,
        Spacer,
        Text,
        Number,
        Money,
        Percent,
        Roas,
        oButton,
        oTable,
        oImage,
        Spinner,
    },
    setup() {
        return { ...useAdjustLocationBids(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.location-breakdown-image {
    @include container;
    @include relative;
    overflow: hidden;
}

.map-skeleton {
    width: 100%;
}

.map-spinner {
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.overlay-tag {
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include container;
    @include ph-16;
    @include pv-8;
    border-radius: 99px;
    z-index: 1;
    white-space: nowrap;
}
.short-location-name {
    @include absolute;
    top: 1.8125rem;
    left: 1.875rem;
    @include f-4;
    @include fw-500;
    @include z-2;
    opacity: 0.15;
}
</style>
