<template>
    <h2 v-if="title" class="f3 thick lh-title mb4">
        {{ title }}
    </h2>
    <div class="f5 lh-copy" style="margin-bottom: 2.1875rem">
        <slot />
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

export default {
    name: 'ImprovementCopy',
    props: {
        title: {
            type: String as PropType<string>,
            required: false,
        },
    },
}
</script>
