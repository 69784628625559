import { computed, onMounted, ref, watch } from 'vue'
import { ALWAYS_SHOW_ONBOARDING, SKIP_ONBOARDING_DISMISSAL } from '@/lib/env'
import { useUser } from '@/composition/user/useUser'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { delay } from '@/lib/globalUtils'

/*
    Comes from  from .env.development, is used to show the onboarding modals 
    no matter what is in the DB
*/

export const useOnboardingTour = (props: { stepName: string; renderDelayMs?: number }) => {
    const renderDelayMs = props.renderDelayMs ?? 500
    const shown = ref(false)
    const { userInfo, mutateUserInfo } = useUser()

    const isDismissed = computed(() => {
        if (!userInfo.value) {
            return true
        }

        return !!userInfo.value.onboardingStepsDismissed.find(
            step => step.stepName === props.stepName
        )?.isDismissed
    })

    const shouldShow = computed(
        () =>
            !isDismissed.value || (ALWAYS_SHOW_ONBOARDING && !dismissedTours.value[props.stepName])
    )

    /*
        `shown` & `shouldShow` both need to be true for the onboarding modal to be visible.
        `shown` only activates after a delay to avoid flashing the modal during redirects (eg /init),
        and to allow the previous onboarding div to animate out.
        `shouldShow` will be true if the onboarding modal is not dismissed & the path matches.
    */
    const modalVisible = computed(() => shouldShow.value && shown.value)

    // "dismissedTours" is only useful for when ALWAYS_SHOW_ONBOARDING=1
    const dismissedTours = ref<Record<string, boolean>>({})
    const dismiss = async () => {
        dismissedTours.value[props.stepName] = true

        // No need to do anything if the tour is already dismissed
        if (isDismissed.value) {
            return
        }

        mutateUserInfo({
            onboardingStepsDismissed: [
                ...(userInfo.value?.onboardingStepsDismissed ?? []),
                { stepName: props.stepName, isDismissed: true },
            ],
        })

        if (SKIP_ONBOARDING_DISMISSAL) {
            return
        }

        await authRequest(Endpoint.DismissOnboardingStep, {
            body: {
                stepName: props.stepName,
            },
        })

        mutateUserInfo()
    }

    const toggleVisibilityWithDelay = async () => {
        if (shouldShow.value) {
            // wait for half a second to allow the parent page to be ready and for the animation to be smooth
            if (renderDelayMs) {
                await delay(renderDelayMs)
            }
            shown.value = true
        } else {
            shown.value = false
        }
    }

    watch(shouldShow, () => {
        toggleVisibilityWithDelay()
    })

    onMounted(() => {
        toggleVisibilityWithDelay()
    })

    return {
        modalVisible,
        dismiss,
    }
}
