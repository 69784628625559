declare global {
    interface Window {
        dataLayer: Record<string, any>[]
    }
}

const pushToDatalayer = (event_name: string, params: Record<string, any> = {}) => {
    const data_layer_o = {
        event: event_name,

        ...params,
    }

    window.dataLayer?.push(data_layer_o) // dataLayer will be undefined if GTM is not loaded
}

export { pushToDatalayer }
