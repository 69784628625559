<template>
    <div>
        <ImprovementView
            :push-action-text="pushActionText"
            :push-messages="pushMessages"
            :on-push="onPush"
        >
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <Text as="p">
                        Opteo scanned your account for a remarketing campaign, but couldn't find
                        one. Follow the instructions below to create a new remarketing campaign.
                        Once you've created one, click
                        <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                        rescan your account and confirm.
                    </Text>
                    <Spacer height="2rem" />
                    <ImprovementNote>
                        <Text as="p" size="f-8">
                            <b>Note:</b> Remarketing campaigns require
                            <b>at least one impression</b> before Opteo can detect them. If this is
                            taking a while — after you have created your new campaign — you can
                            temporarily <b>Dismiss</b> this improvement to allow your campaign to
                            gather data.
                        </Text>
                    </ImprovementNote>
                </ImprovementHeader>
                <ImprovementHelpLink
                    path="/en/articles/936639-the-ultimate-adwords-remarketing-setup"
                >
                    Click here to learn more about the benefits of remarketing campaigns.
                </ImprovementHelpLink>
            </ImprovementContent>

            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Creating a Remarketing Campaign</Text>
                <Spacer height="2rem" />
                <div class="how-to-container">
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 1" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Check Privileges</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Before creating a remarketing campaign, ensure you have admin or account
                            manager access to both <b>Google Ads</b> and <b>Google Analytics</b>.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 2" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Link Analytics to Ads</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            In <b>Analytics</b>, under the <Ent>Admin</Ent> tab, navigate to
                            <Ent>Google Ads Linking</Ent> in the <Ent>Property</Ent> column. Create
                            a new link group for your Ads account.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 3" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Link Ads to Analytics</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            In <b>Google Ads</b>, select the <Ent>Tools</Ent> icon at the top right
                            of the screen, head to <Ent>Linked Accounts > Google Analytics</Ent> and
                            link your chosen property.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 4" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Enable Features in Analytics</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            In <b>Analytics</b>, under the <Ent>Admin</Ent> tab, locate the
                            <Ent>Property</Ent> column. Select <Ent>Tracking Info</Ent> then
                            <Ent>Data Collection</Ent>. Ensure both <Ent>Remarketing</Ent> and
                            <Ent>Advertising Reporting Features</Ent> are toggled to <Ent>On</Ent>.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 5" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Start Building Lists</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            In <b>Analytics</b>, under the <Ent>Admin</Ent> tab, navigate to
                            <Ent>Property > Audience Definitions > Audiences</Ent>. Here you can
                            create new audiences. See Opteo’s
                            <Link href="https://opteo.com/kb/the-ultimate-remarketing-setup/"
                                >guide to remarketing</Link
                            >
                            for examples of audiences to target.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 6" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Check Google Ads Audiences</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Once you have created your audiences in <b>Analytics</b>, check that
                            they are displaying correctly in <b>Google Ads</b>. To do this, open
                            Google Ads, navigate to
                            <Ent>Tools > Shared Library > Audience Manager</Ent>. If successful,
                            each of the audiences you created in Analytics will be displayed here.
                            Please note that audience lists should start populating within 24 hours.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 7" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Create a Display Campaign</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            In
                            <b>Google Ads</b>, create a remarketing campaign to target your chosen
                            audiences. Select the blue plus icon to open the
                            <Ent>Create New Campaign</Ent> flow and then follow the instructions to
                            create a new display campaign. Once you have created an ad group, click
                            <Ent>People: who you want to reach</Ent> and select the appropriate
                            remarketing list under <Ent>Audience</Ent>.
                        </Text>
                    </div>
                </div>
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
            </ImprovementContent>
        </ImprovementView>
    </div>
</template>

<script lang="ts">
import { Spacer, Text, Link, ColorTag, Ent } from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'

import { useCreateRemarketingCampaign } from '@/composition/improvement/types/useCreateRemarketingCampaign'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CreateRemarketingCampaign',
    setup() {
        return useCreateRemarketingCampaign()
    },
    components: {
        ImprovementHelpLink,
        ImprovementView,
        Spacer,
        Text,
        Link,
        ColorTag,
        Ent,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.how-to-container {
    @include container;
}

.how-to-section {
    @include ph-32;
    @include pb-32;
    @include pt-24;
    @include relative;
    border-bottom: 1px solid;
    @include opteo-border;

    & .sub-heading {
        @include flex;
        @include pb-24;
        margin-right: 68px;

        & .number {
            @include absolute;
            right: 24px;
            top: 25px;
        }
    }

    & .text-content {
        @include container;
        @include pa-24;
    }
}

.how-to-section:last-child {
    border-bottom: none;
}

@media screen and (max-width: $mq-767-max) {
    .how-to-section {
        @include ph-24;
        @include pb-24;
    }
}

@media screen and (max-width: $mq-375-max) {
    .how-to-section {
        @include ph-24;
        @include pb-24;
        & .sub-heading {
            @include block;
            @include pb-24;
            margin-right: unset;

            & .number {
                position: unset;
                width: fit-content;
                @include mb-12;
            }
        }
    }
}
</style>
