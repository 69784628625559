<template>
    <PageContent>
        <!--Loading -->
        <div v-if="loading">
            <ReportsGridSkeleton />
        </div>
        <div v-else>
            <!-- Empty State (Zero Reports) -->
            <div v-if="!activeReports.length" class="reports-empty-state-container">
                <img src="@/assets/img/reports-empty-state.png" style="height: 182px" />
                <Spacer height="2rem" />
                <div class="center" style="max-width: 342px">
                    <Text as="h5" size="f-5" weight="600" align="center">
                        Create your first Opteo report
                    </Text>
                    <Spacer height="0.875rem" />
                    <Text as="p" size="f-8" align="center">
                        Create and export beautiful Google Ads reports in a few minutes, keep
                        clients and managers in the loop. Click the button below to create your
                        first report.
                    </Text>
                    <Spacer height="1.125rem" />
                    <router-link :to="{ name: newReportRouteName }">
                        <oButton>Create New Report</oButton>
                    </router-link>
                </div>
            </div>
            <!-- Reports -->
            <div v-else class="w-100 mw-opteo" :class="{ 'reports-grid': viewMode === 'grid' }">
                <div v-if="viewMode === 'list'" class="create-report-list-mode">
                    <div class="flex center pv3">
                        <router-link :to="{ name: newReportRouteName }">
                            <oButton>Create New Report</oButton>
                        </router-link>
                    </div>
                </div>
                <ReportGridItemContainer v-else>
                    <div class="w-100 h-100 flex items-center justify-center">
                        <router-link :to="{ name: newReportRouteName }">
                            <oButton>Create New Report</oButton>
                        </router-link>
                    </div>
                </ReportGridItemContainer>
                <ReportItems :reports="activeReports" />
            </div>
        </div>
    </PageContent>
    <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { oButton, Text, Spacer } from '@opteo/components-next'
import ReportItems from '@/components/Reports/ReportItems.vue'
import ReportsGridSkeleton from '@/components/Reports/ReportsGridSkeleton.vue'
import ReportGridItemContainer from '@/components/Reports/ReportGridItemContainer.vue'

import PageContent from '@/layouts/PageContent.vue'
import { useReportsList } from '@/composition/reports/useReportsList'
import { Routes } from '@/router/routes'

export default defineComponent({
    components: {
        PageContent,
        oButton,
        Text,
        Spacer,
        ReportItems,
        ReportGridItemContainer,
        ReportsGridSkeleton,
    },
    setup() {
        const newReportRouteName = Routes.ReportCreate
        return {
            ...useReportsList(),
            newReportRouteName,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.create-report-list-mode {
    @include mb-24;
    @include flex-center;
    @include container;
    @include br-24;
    @include pv-32;
    @include relative;
}

.reports-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 30rem;
}
</style>
