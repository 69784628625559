<template>
    <div
        :class="[
            'pill-tag',
            { hidden: hidden },
            scoreColor(isPercentFinal ? scoreDisplayValue * 100 : scoreDisplayValue).name,
        ]"
    >
        <Text
            as="p"
            size="f-8"
            weight="500"
            :color="
                hidden
                    ? 'gray'
                    : scoreColor(isPercentFinal ? scoreDisplayValue * 100 : scoreDisplayValue).name
            "
        >
            <span v-if="hidden">N/A</span>
            <NumberVue v-else-if="dataType === 'number'" :value="scoreDisplayValue" />
            <Percent
                v-else
                :value="scoreDisplayValue"
                :decimal-places="0"
                :include-sign="includeSign"
            />
        </Text>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Text, Number as NumberVue, Percent, DataType } from '@opteo/components-next'
import { scoreColor } from '@/composition/toolkit/scorecard/utils'

export interface Props {
    score: number
    dataType?: typeof DataType.number | typeof DataType.percentage
    isPercentFinal?: boolean
    hidden?: boolean
    includeSign?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    dataType: 'number',
    isPercentFinal: true,
    hidden: false,
    includeSign: false,
})

const scoreDisplayValue = computed(() => {
    if (props.dataType === 'number') {
        return props.score
    }

    const divider = props.isPercentFinal ? 100 : 1

    return props.score / divider
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.pill-tag {
    @include inline-flex;
    @include items-center;
    padding: 0.5625rem 0.875rem;
    @include br-999;
    user-select: none;
}
.pill-tag p {
    font-size: 0.8125rem;
    line-height: 0.875rem;
    letter-spacing: -0.01rem;
}
.pill-tag.green {
    background-color: $opteo-green-translucent;
}
.pill-tag.amber {
    background-color: $opteo-amber-translucent;
}
.pill-tag.red {
    background-color: $opteo-red-translucent;
}
.pill-tag.hidden {
    background-color: $opteo-gray-translucent;
}
</style>
