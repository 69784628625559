<template>
    <PageHeader :no-margin="true" pageTitle="Feature Requests" />
    <PageContent>
        <div v-show="cannyLoading" class="canny-loading">
            <Spinner />
        </div>
        <div v-show="!cannyLoading" id="canny-container">
            <div data-canny></div>
        </div>
    </PageContent>
</template>

<script lang="ts">
import { computed, watch, ref } from 'vue'
import { useAPI, Endpoint } from '@/composition/api/useAPI'
import { useUser } from '@/composition/user/useUser'
import { useCanny } from '@/lib/canny/useCanny'
import { CANNY_BOARD_TOKEN } from '@/lib/env'

import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'
import { Spinner } from '@opteo/components-next'

export default {
    name: 'FeatureRequests',
    components: { PageHeader, PageContent, Spinner },
    setup() {
        const cannyLoading = ref(true)
        if (!window.Canny) {
            useCanny()
        }
        const { userId, loading: loadingUser } = useUser()
        const { data: token, loading: loadingToken } = useAPI<string>(Endpoint.GetCannyToken)

        const dataLoaded = computed(() => {
            return !loadingUser.value && !loadingToken.value
        })

        async function renderCanny() {
            cannyLoading.value = true
            await window.Canny('render', {
                boardToken: CANNY_BOARD_TOKEN,
                ssoToken: token.value,
                basePath: `/user/${userId.value}/featurerequests`,
            })

            cannyLoading.value = false
        }

        watch(dataLoaded, () => {
            if (dataLoaded.value) {
                renderCanny()
            }
        })

        return { loadingUser, cannyLoading }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.canny-loading {
    width: 100%;
    height: 20rem;
    @include container;
    @include flex;
    @include items-center;
    @include justify-center;
}
#canny-container {
    @include container;
    @include pa-32;
}
</style>
