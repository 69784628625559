<template>
    <oButton
        ref="accountMenu"
        :class="['opteo-sidebar-menu-link account-sidebar-menu', { active }]"
        color="white"
    >
        <ProfileImage :image-url="profileImageUrl" :width="36" :avatar-url="currentUserAvatarUrl" />
    </oButton>
</template>

<script lang="ts" setup>
import { oButton, ProfileImage } from '@opteo/components-next'
import { useUser } from '@/composition/user/useUser'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

const { profileImageUrl } = useUser()
const { currentUserAvatarUrl } = useBoringAvatar()

defineProps<{
    active: boolean
}>()
</script>
