<template>
    <div class="chart-container">
        <div class="chart">
            <div v-for="(axis, index) in items" class="lines" :class="`axis${index + 1}`">
                <div class="line"></div>
            </div>
            <div v-for="(axis, index) in items" class="circles" :class="`axis${index + 1}`">
                <div class="inner">
                    <div
                        class="circle-container"
                        :style="`transform: translateY(${Math.max(100 - axis.median, 0)}%)`"
                    >
                        <div
                            :class="[`circle`, `color${index + 1}`]"
                            :style="`transform: translateY(-${100 - axis.median}%)`"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <Spacer height="1.25rem" />
        <div class="key-container">
            <div v-for="(item, index) in items" class="key">
                <span :class="[`dot`, `color${index + 1}`]"></span>
                <Text as="p" size="f-9" style="white-space: nowrap">{{ item.label }}</Text>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { Text, Spacer } from '@opteo/components-next'
import { computed } from 'vue'

export interface ChartItem {
    length: number
    label: string
}

function isValidTriangle(a: number, b: number, c: number): boolean {
    // Check triangle inequality rule
    return a + b > c && a + c > b && b + c > a
}

function findValidTriangle(
    a: number,
    b: number,
    c: number,
    maxIterations: number = 100
): [boolean, number, number, number] {
    if (isValidTriangle(a, b, c)) {
        return [true, a, b, c] // Triangle found, return adjusted sides (optional)
    }

    for (let i = 0; i < maxIterations; i++) {
        a += 1 // Increment side a
        b += 1 // Increment side b
        c += 1 // Increment side c
        if (isValidTriangle(a, b, c)) {
            // if the longest side would be longer than 100, adjust all sides to fit within 100
            const longestSide = Math.max(a, b, c)

            if (longestSide > 100) {
                a = a * (100 / longestSide)
                b = b * (100 / longestSide)
                c = c * (100 / longestSide)
            }

            return [true, a, b, c] // Triangle found, return adjusted sides (optional)
        }
    }

    return [false, 0, 0, 0] // No valid triangle found after iterations
}

const props = defineProps<{
    items: {
        sideAB: ChartItem
        sideBC: ChartItem
        sideCA: ChartItem
    }
    landingPageScanFailed?: boolean
}>()

const { sideAB, sideBC, sideCA } = props.items

const [validTriangle, adjustedAB, adjustedBC, adjustedCA] = findValidTriangle(
    sideAB.length,
    sideBC.length,
    sideCA.length
)

if (!validTriangle) {
    throw new Error('No valid triangle found')
}

function calculateMedian(base: number, side1: number, side2: number) {
    // Apollonius's theorem: side1^2 + side2^2 = 2*median^2 + (base^2)/2
    // solving for median: 2*median^2 = side1^2 + side2^2 - (base^2)/2
    // thus: median = sqrt((side1^2 + side2^2 - (base^2)/2) / 2)
    if (props.landingPageScanFailed) {
        return Math.sqrt((2 * side1 ** 2 + 2 * side2 ** 2 - base ** 2) / 4) * 0.8
    } else {
        return Math.sqrt((2 * side1 ** 2 + 2 * side2 ** 2 - base ** 2) / 4) * 1.15
    }
}

const items = computed(() => [
    {
        ...sideCA,
        median: calculateMedian(adjustedCA, adjustedBC, adjustedAB),
    },
    {
        ...sideBC,
        median: calculateMedian(adjustedBC, adjustedCA, adjustedAB),
    },
    {
        ...sideAB,
        median: props.landingPageScanFailed
            ? 100
            : calculateMedian(adjustedAB, adjustedBC, adjustedCA),
    },
])

// Chart circle size
const circleSize = `100px`
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chart-container {
    @include pa-20;

    .chart {
        width: 100%;
        @include flex;
        @include justify-center;
        height: 16rem;

        .lines,
        .circles {
            @include absolute;
            transform-origin: bottom center;
            height: 9.625rem;
            width: v-bind(circleSize);

            .line {
                @include absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 0.1875rem;
                height: 100%;
                background-color: $opteo-light-gray;
            }

            .circle-container {
                @include absolute;
                @include w-100;
                // height of line + circle radius - 4px
                // Ensures circles stay within outer edge of lines and retains an edge of single colour in perfect lengths
                height: calc(100% + calc(v-bind(circleSize) / 2) - 4px);
                z-index: 2;

                .circle {
                    width: v-bind(circleSize);
                    aspect-ratio: 1/1;
                    opacity: 0.68;
                    border-radius: 7.75rem;
                }
            }
        }
        .circles {
            mix-blend-mode: multiply;
        }
        .axis1 {
            transform: rotate(240deg);
        }
        .axis2 {
            transform: rotate(0deg);
        }
        .axis3 {
            transform: rotate(120deg);
        }
    }

    .key-container {
        @include container;
        @include br-16;
        @include ph-16;
        @include pv-12;
        @include flex;
        @include items-center;
        @include justify-center;
        flex-wrap: wrap;
        width: fit-content;
        margin: 0 auto;

        .key {
            @include flex;
            @include items-center;
            @include ph-12;
            height: 1.5rem;

            .dot {
                height: 0.5625rem;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                margin-right: 0.5rem;
            }
        }
    }

    .color1 {
        background-color: $opteo-pink;
    }
    .color2 {
        background-color: $opteo-green;
    }
    .color3 {
        background-color: $opteo-purple;
    }
}
</style>
