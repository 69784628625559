import { ref, Ref, computed, ComputedRef } from 'vue'

import { LiftCampaignBudgetCap, Targets } from '@opteo/types'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useNumber, useRoas } from '@opteo/components-next'
import {
    checkImprovement,
    useImprovement,
    UseImprovement,
    OnPushHandler,
} from '@/composition/improvement/useImprovement'
import { useDomain } from '@/composition/domain/useDomain'
import { toFixedAccurate } from '@/lib/globalUtils'

interface UseLiftCampaignBudgetCap {
    campaignName: string
    budget: number
    currencySymbol: ComputedRef<string | undefined>
    accountCpa: number
    accountRoas: number
    accountCpaAfterChange: number
    accountRoasAfterChange: number
    cpaChangePercent: number
    roasChangePercent: number
    daysWindow: number
    statistics: Statistics[]
    selectedIncreasePercent: Ref<number>
    newBudget: Ref<string>
    resetBudget: () => void
    onPush: OnPushHandler<PushedData>
    isUsingRoas: boolean
}

interface PushedData {
    value: number
}

interface Statistics {
    key: string
    deltaMode: boolean
    beforeValue: string | number
    beforeTitle: string
    afterValue: string | number
    afterTitle: string
}

export function useLiftCampaignBudgetCap(): UseImprovement<UseLiftCampaignBudgetCap> {
    const { improvement, lastUpdated } = useImprovement<LiftCampaignBudgetCap.Body>()
    const { currencySymbol } = useDomain()
    const { body } = checkImprovement<LiftCampaignBudgetCap.Body>(improvement)

    const {
        campaign_label: campaignName,
        budget,
        account_cpa,
        account_roas,
        account_cpa_after_change,
        account_roas_after_change,
        days_window: daysWindow,
        campaign_cost: campaignCost,
        campaign_clicks: campaignClicks,
        campaign_conversions: campaignConversions,
        campaign_conversions_value: campaignConversionsValue,
        performance_mode: performanceMode,
    } = body

    const formatRecommendedBudget = () => toFixedAccurate(budget * 1.3, 2)

    const newBudget = ref(formatRecommendedBudget())

    // For resetting the budget when clicking `Back`
    function resetBudget() {
        newBudget.value = formatRecommendedBudget()
    }

    // (newBudget / oldBudget - 1) * 100 = increaseFactor
    // (130 / 100 - 1) * 100 = 30
    const selectedIncreasePercent = computed(() => (+newBudget.value / budget - 1) * 100)

    const accountCpaAfterChange = account_cpa_after_change ?? 0
    const accountRoasAfterChange = account_roas_after_change ?? 0
    const accountCpa = account_cpa ?? 0
    const accountRoas = account_roas ?? 0

    const cpaChangePercent = (accountCpaAfterChange - accountCpa) / accountCpa
    const roasChangePercent = (accountRoasAfterChange - accountRoas) / accountRoas

    const statistics: Statistics[] = [
        {
            key: 'costDelta',
            deltaMode: true,
            beforeValue: useDomainMoney({ value: campaignCost }).value.displayValue.value,
            beforeTitle: 'Current Cost',
            afterValue: useDomainMoney({ value: campaignCost * 1.3 }).value.displayValue.value,
            afterTitle: 'Adjusted Cost',
        },
        {
            key: 'clicksDelta',
            deltaMode: true,
            beforeValue: useNumber({ value: campaignClicks }).displayValue.value,
            beforeTitle: 'Current Clicks',
            afterValue: useNumber({ value: campaignClicks * 1.3 }).displayValue.value,
            afterTitle: 'Adjusted Clicks',
        },
    ]

    const isUsingRoas = performanceMode === Targets.PerformanceMode.ROAS

    if (isUsingRoas) {
        statistics.push(
            {
                key: 'conversionsValueDelta',
                deltaMode: true,
                beforeValue: useDomainMoney({ value: campaignConversionsValue }).value.displayValue
                    .value,
                beforeTitle: 'Current Conversion Value',
                afterValue: useDomainMoney({ value: campaignConversionsValue * 1.3 }).value
                    .displayValue.value,
                afterTitle: 'Adjusted Conversion Value',
            },
            {
                key: 'campaignRoasDelta',
                deltaMode: true,
                beforeValue: useRoas({ value: accountRoas }).displayValue.value,
                beforeTitle: 'Current Group ROAS',
                afterValue: useRoas({ value: accountRoasAfterChange }).displayValue.value,
                afterTitle: 'Adjusted Group ROAS',
            }
        )
    } else {
        statistics.push(
            {
                key: 'conversionsDelta',
                deltaMode: true,
                beforeValue: useNumber({ value: campaignConversions }).displayValue.value,
                beforeTitle: 'Current Conversions',
                afterValue: useNumber({ value: campaignConversions * 1.3 }).displayValue.value,
                afterTitle: 'Adjusted Conversions',
            },
            {
                key: 'campaignCpaDelta',
                deltaMode: true,
                beforeValue: useDomainMoney({ value: accountCpa }).value.displayValue.value,
                beforeTitle: 'Current Group CPA',
                afterValue: useDomainMoney({ value: accountCpaAfterChange }).value.displayValue
                    .value,
                afterTitle: 'Adjusted Group CPA',
            }
        )
    }

    const increasingOrDecreasing = computed(() =>
        +newBudget.value > budget ? 'Raising' : 'Lowering'
    )
    const raisedOrLowered = computed(() => (+newBudget.value > budget ? 'raised' : 'lowered'))

    const pushMessages = computed(() => {
        return [
            `Connecting to Google Ads..`,
            `${increasingOrDecreasing.value} campaign budget cap..`,
            `Confirming changes..`,
            `Campaign budget ${raisedOrLowered.value} successfully.`,
        ]
    })

    const pushAction = computed(() =>
        +newBudget.value > budget ? 'Raise Budget Cap' : 'Lower Budget Cap'
    )

    const pushActionText = ref(pushAction)

    const title = pushAction

    const onPush: OnPushHandler<PushedData> = () => {
        return {
            valid: true,
            pushedData: {
                value: selectedIncreasePercent.value,
            },
        }
    }

    return {
        title,
        pushMessages,
        lastUpdated,
        campaignName,
        budget,
        resetBudget,
        accountCpa,
        accountRoas,
        accountCpaAfterChange,
        accountRoasAfterChange,
        cpaChangePercent,
        roasChangePercent,
        daysWindow,
        statistics,
        selectedIncreasePercent,
        newBudget,
        onPush,
        pushActionText,
        currencySymbol,
        isUsingRoas,
    }
}
