<template>
    <!-- Campaign Groups -->
    <div v-if="campaignGroups && !showCampaignGroupsError" class="campaign-groups">
        <div
            v-for="group of orderedCampaignGroups"
            :key="group.id"
            :id="`cg_${group.id}`"
            class="campaign-group-wrapper"
        >
            <CampaignGroup :group="group" />
        </div>
        <CampaignGroupComparison v-if="!setupActive" />
        <Spacer height="4rem" v-if="!setupActive" />
        <Spacer height="7rem" v-if="setupActive" />
    </div>

    <!-- Campaign Groups Error -->
    <div v-else-if="showCampaignGroupsError" class="campaign-groups-error-state-container">
        <div class="warning-icon-container">
            <WarnIcon style="transform: scale(1.8333)" />
        </div>
        <Spacer height="2.5rem" />
        <div class="center" style="max-width: 434px">
            <Text as="h5" size="f-5" weight="600" align="center"> No Campaign Spend Detected </Text>
            <Spacer height="1.25rem" />
            <Text as="p" size="f-8" align="center">
                For Opteo to generate improvements, your account needs to have at least one active
                campaign with spend. If you are seeing this message in error and your account has
                active spend, please message support.
            </Text>
            <Spacer height="1.5rem" />
            <oButton @clicked="openMessenger()">Message Customer Support</oButton>
        </div>
    </div>

    <!-- Campaign Groups Skeleton -->
    <div v-else class="campaign-groups">
        <div v-for="i of 3" :key="i" class="campaign-group-wrapper">
            <CampaignGroupSkeleton />
        </div>
    </div>

    <!-- Batch Bar -->
    <BatchBar
        v-model="showBatchBar"
        @clear-selected="clearCampaignSelection()"
        :count="selectedCampaignsSorted.length"
        title="Campaigns"
        :full-screen="true"
    >
        <template #buttons>
            <oButton color="dark" @clicked="handleMoveToModal" style="width: 100%">
                <span>Move to Campaign Group</span>
            </oButton>
        </template>
    </BatchBar>

    <!-- Move Campaign Group Modal -->
    <Modal
        title="Move to Campaign Group"
        v-model="moveCampaignPopoutOpen"
        contentMaxHeight="360px"
        width="580px"
        noPadding
    >
        <template #content>
            <!-- Add logic to selected a new group -->
            <div
                v-for="(group, index) of orderedCampaignGroups"
                :key="group.id"
                :class="[
                    index === orderedCampaignGroups.length - 1 ? 'last-row' : '',
                    'move-campaigns-modal-row',
                ]"
                @click="selectedGroup = group"
            >
                <CheckIcon color="blue" checkable :checked="selectedGroup?.id === group.id" />
                <Spacer width="1.25rem" />
                <div class="text">
                    <Text as="span" size="f-8" weight="500">
                        {{ group.name }}
                    </Text>
                    <Spacer height="4px" />
                    <Text as="span" size="f-9" color="gray">
                        Contains {{ group.campaigns.length }} Campaign<span
                            v-if="group.campaigns.length != 1"
                            >s</span
                        >
                    </Text>
                </div>
            </div>
        </template>
        <template #buttons>
            <oButton color="white" @clicked="moveCampaignPopoutOpen = false">Cancel</oButton>
            <Spacer width="0.5rem" />
            <oButton color="blue" @clicked="moveCampaigns" :loading="movingCampaigns">
                Move to {{ selectedGroup?.name }}
            </oButton>
        </template>
    </Modal>

    <!-- Campaign Groups onboarding -->
    <OnboardingModal
        v-model="showOnboarding"
        @closed="setCampaignGroupsSeenStep1"
        mode="large-modal"
        buttonText="Continue to Campaign Groups"
        class="campaign-groups-onboarding"
        contentMaxWidth="500px"
    >
        <template #header>Campaign Groups</template>
        <template #copy>
            By comparing the performance of similar campaigns, tracking specific conversion actions
            and defining shared targets, Opteo can generate more specific, higher quality
            recommendations that align with your overall goals, whether conversions, traffic, or
            otherwise.
        </template>
    </OnboardingModal>

    <!-- Secondary onboarding -->
    <OnboardingModal
        v-model="showSecondaryOnboarding"
        @closed="setCampaignGroupsSeenStep2"
        buttonText="Okay, thanks"
        mode="bottom-banner"
        maxWidth="49.25rem"
    >
        <template #copy>
            Opteo automatically sorts your brand campaigns into a group called
            <b>Brand Campaigns</b>. All remaining campaigns are grouped by campaign type. For
            reference, Campaign Groups and their targets only apply to Opteo and do not have any
            direct influence on Google Ads.
        </template>
    </OnboardingModal>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import {
    oButton,
    oInput,
    Label,
    CloseButton,
    oForm,
    Spacer,
    Ent,
    Text,
    SelectArrowIcon,
    Popout,
    ColorTag,
    Money,
    Number,
    WarnIcon,
    OnboardingModal,
    CheckIcon,
    BatchBar,
    Modal,
} from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import CampaignGroup from '@/components/campaignGroups/CampaignGroup.vue'
import CampaignGroupComparison from '@/components/campaignGroups/CampaignGroupComparison.vue'
import CampaignGroupSkeleton from '@/components/campaignGroups/CampaignGroupSkeleton.vue'
import { useOnboardingTour } from '@/composition/user/useOnboardingTour'
import { useCampaignGroups } from '@/composition/campaignGroups/useCampaignGroups'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useAccountSetupBar } from '@/composition/account/useAccountSetupBar'

export default defineComponent({
    components: {
        oButton,
        oInput,
        Label,
        CloseButton,
        oForm,
        Spacer,
        Ent,
        Text,
        SelectArrowIcon,
        Popout,
        ColorTag,
        Money,
        Number,
        Skeleton,
        CampaignGroupSkeleton,
        CampaignGroup,
        CampaignGroupComparison,
        OnboardingModal,
        WarnIcon,
        CheckIcon,
        BatchBar,
        Modal,
    },
    setup() {
        const { dismiss: dismissOnboarding, modalVisible } = useOnboardingTour({
            stepName: 'tour_campaigngroups',
        })

        const { showBatchBar } = useCampaignGroups()
        const { setupActive, showAccountSetupBar, hideAccountSetupBar } = useAccountSetupBar()

        if (setupActive.value) {
            watch(showBatchBar, open => {
                if (open) {
                    hideAccountSetupBar()
                } else {
                    showAccountSetupBar()
                }
            })
        }

        const showOnboarding = computed(() => {
            // Show if user hasn't seen it yet (modalVisible) AND the secondary onboarding isn't showing
            return modalVisible.value && !showSecondaryOnboarding.value
        })

        const showSecondaryOnboarding = ref(false)

        const setCampaignGroupsSeenStep1 = () => {
            showSecondaryOnboarding.value = true
        }

        const setCampaignGroupsSeenStep2 = () => {
            dismissOnboarding()
            showSecondaryOnboarding.value = false
        }

        const { openMessenger } = useIntercom()

        return {
            ...useCampaignGroups(),
            showOnboarding,
            showSecondaryOnboarding,
            setCampaignGroupsSeenStep1,
            setCampaignGroupsSeenStep2,
            openMessenger,
            setupActive,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.campaign-groups {
    width: 100%;
}
.campaign-group-wrapper {
    @include mb-32;
}
.campaign-group-wrapper:last-child {
    @include mb-0;
}
.move-to-campaign-group-popout {
    @include pa-8;
    max-height: 22rem;
    background-color: $opteo-black;
}
.move-to-campaign-group-popout .button {
    @include w-100;
    justify-content: start;
}

// Error States
.campaign-groups-error-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    @include w-100;
    flex-direction: column;
    height: 30rem;
}

// Move campaigns modal

.move-campaigns-modal-row {
    border-bottom: 1px solid;
    @include opteo-border;
    @include pa-24;
    @include flex;
    @include items-center;
    cursor: pointer;
}
.move-campaigns-modal-row.last-row {
    border-bottom: none;
}

// Responsive
.campaign-groups-bigger-screen {
    display: block;
    height: calc(100vh - 224px - 96px);
    width: 100%;
}
.campaign-groups-bigger-screen-container {
    @include ph-32;
    @include pv-28;
    @include container;
    width: 100%;
    max-width: 324px;
    margin: 0 auto;
}
.campaign-groups,
.action-buttons,
.selected-campaigns,
.campaign-groups-onboarding,
.campaign-batch-bar {
    display: none;
}

@media screen and (min-width: 1024px) {
    .campaign-groups-bigger-screen {
        display: none;
    }
    .campaign-groups,
    .selected-campaigns {
        @include block;
    }
    .action-buttons,
    .campaign-groups-onboarding {
        @include flex;
    }
    .campaign-batch-bar {
        display: flex;
    }
}
</style>
