<template>
    <div class="create-loader-wrapper">
        <transition name="state-change" mode="out-in">
            <div v-if="!error" class="create-loader-container">
                <transition-group
                    @before-enter="onBeforeEnter"
                    @enter="onEnter"
                    @enter-cancelled="onEnterCancelled"
                    @before-leave="onBeforeLeave"
                    @leave="onLeave"
                    @leave-cancelled="onLeaveCancelled"
                    :css="false"
                    appear
                >
                    <div
                        v-for="slide in filterVisibleSlides"
                        :key="slide"
                        class="slide-wrapper"
                        :data-type="slide"
                    >
                        <div class="slide-cnt">
                            <StatisticsGridSlide
                                v-if="slide === 'statistics-grid'"
                                :animated="true"
                            />
                            <TopPerformingAdSlide
                                v-if="slide === 'top-performing-ad'"
                                :animated="true"
                            />
                            <BarChartSlide v-if="slide === 'bar-chart'" :animated="true" />
                            <TableSlide v-if="slide === 'table'" :animated="true" />
                            <LineChartSlide v-if="slide === 'line-chart'" :animated="true" />
                            <FeaturedKeywordSlide
                                v-if="slide === 'featured-keyword'"
                                :animated="true"
                            />
                        </div>
                    </div>
                </transition-group>
            </div>
            <div v-else>
                <div class="slide-cnt" data-type="bar-chart">
                    <BarChartSlide :animated="true" />
                    <div class="error-icon">
                        <WarnIcon animated :delay="500" />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, computed } from 'vue'
import StatisticsGridSlide from './loader/StatisticsGridSlide.vue'
import TopPerformingAdSlide from './loader/TopPerformingAdSlide.vue'
import BarChartSlide from './loader/BarChartSlide.vue'
import TableSlide from './loader/TableSlide.vue'
import LineChartSlide from './loader/LineChartSlide.vue'
import FeaturedKeywordSlide from './loader/FeaturedKeywordSlide.vue'
import { WarnIcon } from '@opteo/components-next'
import anime from 'animejs/lib/anime.es.js'

type SlideType =
    | 'statistics-grid'
    | 'top-performing-ad'
    | 'bar-chart'
    | 'table'
    | 'line-chart'
    // | 'text'
    | 'featured-keyword'

export default {
    components: {
        StatisticsGridSlide,
        TopPerformingAdSlide,
        BarChartSlide,
        TableSlide,
        LineChartSlide,
        FeaturedKeywordSlide,
        WarnIcon,
    },
    props: {
        themeColours: {
            type: String,
            required: false,
            default: '#7D49FF',
        },
        isLoading: {
            type: Boolean,
            required: true,
            default: true,
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const visibleSlides = ref<SlideType[]>([])
        const slideTypes: SlideType[] = [
            'statistics-grid',
            'top-performing-ad',
            'bar-chart',
            'table',
            'line-chart',
            // 'text',
            'featured-keyword',
        ]
        const currentIndex = ref(0)
        const timer = ref()

        onMounted(() => {
            visibleSlides.value.push(slideTypes[currentIndex.value])
        })

        const filterVisibleSlides = computed(() =>
            slideTypes.filter((s: SlideType) => visibleSlides.value.includes(s))
        )

        const onBeforeEnter = (el: HTMLElement) => {
            // called before the element is inserted into the DOM.
            // use this to set the "enter-from" state of the element
            el.style.opacity = '0'
            el.style.transform = 'translate(-50%, -50%)'
            el.style.display = 'block'
        }

        const onEnter = (el: HTMLElement, done: () => void) => {
            // called one frame after the element is inserted.
            // use this to start the animation.
            anime({
                targets: el,
                opacity: [0, 1],
                translateY: ['+=8', 0],
                scale: [0.98, 1],
                delay: 500,
                duration: 500,
                easing: 'easeInOutSine',
                complete: () => {
                    timer.value = setTimeout(() => {
                        visibleSlides.value.shift()
                        const newIndex =
                            (slideTypes.length + ((currentIndex.value + 1) % slideTypes.length)) %
                            slideTypes.length
                        visibleSlides.value.push(slideTypes[newIndex])
                        currentIndex.value = newIndex
                    }, 1200)
                    done()
                },
            })
        }

        const onBeforeLeave = (el: HTMLElement) => {
            // called before the leave hook.
            clearTimeout(timer.value)
        }

        const onEnterCancelled = (el: HTMLElement) => {
            console.log('onEnterCancelled')
        }

        const onLeave = (el: HTMLElement, done: () => void) => {
            // called when the leave transition starts.
            // use this to start the leaving animation.
            el.style.zIndex = '3'

            anime({
                targets: el,
                opacity: [1, 0],
                translateY: [0, '-=200%'],
                easing: 'easeInOutSine',
                duration: 1800,
                complete: () => {
                    // call the done callback to indicate transition end
                    done()
                },
            })
        }

        const onLeaveCancelled = (el: HTMLElement) => {
            // only available with v-show transitions
            console.log('leaveCancelled')
        }

        return {
            filterVisibleSlides,
            slideTypes,

            onBeforeEnter,
            onEnter,
            onEnterCancelled,
            onBeforeLeave,
            onLeave,
            onLeaveCancelled,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.create-loader-wrapper {
    width: 600px;
    position: relative;
}

// white gradient at top
.create-loader-wrapper:before {
    content: '';
    height: 2rem;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
    background: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0));
}
.create-loader-container {
    width: 100%;
    height: 100%;
    position: relative;
}
.slide-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
    will-change: transform;
}
.slide-cnt {
    @include container;
    overflow: hidden;
}
.error-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.state-change-enter-active,
.state-change-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.state-change-enter-from,
.state-change-leave-to {
    opacity: 0;
    transform: translateY(8px);
}
</style>
