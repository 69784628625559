import { snakeCaseToTitleCase, snakeCaseToLowerCase } from '@/lib/globalUtils'
import { AdjustAttributionModel } from '@opteo/types'
import { unref, ref, Ref, computed } from 'vue'
import { checkImprovement, useImprovement, UseImprovement, OnPushHandler } from '../useImprovement'
import { DonutChartTypes, AreaChartTypes } from '@opteo/components-next'

interface UseAdjustAttributionModel {
    selectedAttributionModel: Ref<string>
    conversionActionName: string
    titleCaseLongestLagDelay: string
    conversionsLongerThanDayAsAPercent: number
    donutChartItems: DonutChartTypes.DonutChartItem[]
    areaChartSeries: AreaChartTypes.AreaChartSeries[]
    tableItems: TableItem[]
    attributionModelSelectItems: SelectItem[]
}

interface TableItem {
    key: string
    value: string | number
}

interface SelectItem {
    label: string
    value: string
}

export function useAdjustAttributionModel(): UseImprovement<UseAdjustAttributionModel> {
    const { improvement, lastUpdated, title } = useImprovement<AdjustAttributionModel.Body>()
    const { body } = checkImprovement<AdjustAttributionModel.Body>(improvement)

    const {
        conversion_name,
        external_conversion_source_label,
        conversion_window,
        conversion_category_label,
        include_in_conversions_metric,
        all_conversions,
        all_conversions_value,
        counting_type_label,
        longest_lag_delay,
        conversion_lag_pie_chart_series,
        conversion_by_date_line_chart_series,
    } = body

    const selectedAttributionModel = ref('105') // position based

    const titleCaseExternalConversionSource = snakeCaseToTitleCase(external_conversion_source_label)

    const titleCaseConversionCategory = snakeCaseToTitleCase(conversion_category_label)

    const formatIncludeInConversionsMetrics = include_in_conversions_metric ? 'Yes' : 'No'

    const titleCaseCountingType = snakeCaseToTitleCase(counting_type_label)

    const attributionModelSelectItems: SelectItem[] = [
        {
            label: 'Time Decay',
            value: '104',
        },
        {
            label: 'Linear',
            value: '103',
        },
        {
            label: 'Position Based',
            value: '105',
        },
        {
            label: 'First Click',
            value: '102',
        },
    ]

    const titleCaseLongestLagDelay = snakeCaseToLowerCase(longest_lag_delay)

    const tableItems: TableItem[] = [
        { key: 'Conversion Action', value: conversion_name },
        { key: 'Source', value: titleCaseExternalConversionSource },
        { key: 'Window', value: `${conversion_window} days` },
        { key: 'Category', value: titleCaseConversionCategory },
        { key: 'Include In Conversion', value: formatIncludeInConversionsMetrics },
        { key: 'Conversions', value: Math.round(all_conversions) },
        { key: 'Conversion Value', value: Math.round(all_conversions_value) },
        { key: 'Counting Type', value: titleCaseCountingType },
        { key: 'Attribution Model', value: 'Last Click' },
    ]

    const donutChartItems = conversion_lag_pie_chart_series.map(item => {
        return { y: item.value, label: item.name }
    })

    const areaChartSeries = [
        {
            name: conversion_name,
            items: conversion_by_date_line_chart_series.map(item => {
                return { y: item.y, x: new Date(item.x) }
            }),
        },
    ]

    const lessThanOneDayConversions = conversion_lag_pie_chart_series[0].value
    const greaterThanOneDayConversions = conversion_lag_pie_chart_series[1].value
    const conversionsLongerThanDayAsAPercent =
        greaterThanOneDayConversions / (lessThanOneDayConversions + greaterThanOneDayConversions)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adjusting attribution model..',
        'Confirming changes..',
        'Attribution model adjusted successfully.',
    ])

    const pushActionText = ref('Set Attribution Model')

    const onPush: OnPushHandler<{ attribution_model: number }> = () => {
        return {
            valid: true,
            pushedData: { attribution_model: +selectedAttributionModel.value },
        }
    }

    return {
        title,
        pushMessages,
        lastUpdated,
        onPush,
        conversionActionName: conversion_name,
        titleCaseLongestLagDelay: unref(titleCaseLongestLagDelay),
        conversionsLongerThanDayAsAPercent,
        donutChartItems,
        areaChartSeries,
        tableItems,
        attributionModelSelectItems,
        selectedAttributionModel,
        pushActionText,
    }
}
