<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
    >
        <template #content>
            <div v-for="copy in scoreTexts" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>

            <Spacer height="1.5rem" />

            <Text as="h6" weight="600">
                {{ isUsingCpa ? 'Conversions' : 'Value' }} (Last 30 days)
            </Text>
            <Spacer height="2rem" />
            <div class="line-chart-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <Spacer height="0.5rem" />
                <LineChart
                    :series="dailyPerformanceMetricsLineChartSeries"
                    :metric="dailyPerformanceMetricsLineChartMetric"
                    :axis-titles="dailyPerformanceMetricsLineChartAxisTitles"
                    :show-zero="true"
                    :max-item="dailyPerformanceMetricsLineChartMaxItem"
                    :chartHeight="200"
                    :remove-placeholder-container="true"
                />
            </div>
            <Spacer height="2.5rem" />

            <Text as="h6" weight="600">Top Primary Conversion Actions</Text>
            <Spacer height="2rem" />
            <oTable
                v-if="topConversionActionsTableItems.length"
                :headers="topConversionActionsTableHeaders"
                :items="topConversionActionsTableItems"
                :fixed-layout="true"
                :border-radius="20"
                :class="{ 'pdf-shadow-fix': isPdf }"
                responsive-mode="columns"
                responsive-breakpoint="767px"
            >
                <template #column.conversionName="cellData">
                    <div class="table-cell">
                        <Tooltip
                            v-if="cellData.value.length > 16"
                            :content="cellData.value"
                            :offset="[-10, 8]"
                        >
                            <Text as="span" size="f-8">
                                {{ cellData.value }}
                            </Text>
                        </Tooltip>
                        <Text v-else as="span" size="f-8">
                            {{ cellData.value }}
                        </Text>
                    </div>
                </template>
                <template #column.source="cellData">
                    <div class="table-cell">
                        <Tooltip
                            v-if="cellData.value.length > 14"
                            :content="cellData.value"
                            :offset="[-10, 8]"
                        >
                            <Text as="span" size="f-8">
                                {{ cellData.value }}
                            </Text>
                        </Tooltip>
                        <Text v-else as="span" size="f-8">
                            {{ cellData.value }}
                        </Text>
                    </div>
                </template>
                <template #column.conversions="cellData">
                    <NumberVue :value="cellData.value" :compact="true" />
                </template>
                <template #column.conversionsValue="cellData">
                    <Money :currency="domainCurrency" :value="cellData.value" :compact="true" />
                </template>
            </oTable>
            <div v-else class="no-conversion-action-data">
                <Text as="p" size="f-8" color="gray">
                    Primary Conversion Actions data is unavailable.
                </Text>
            </div>
            <Spacer height="2.5rem" />

            <!-- Conversion Tracking Status -->
            <Text as="h6" weight="600">Conversion Tracking Status</Text>
            <Spacer height="2rem" />
            <div class="sub-scores-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div v-for="row in conversionTrackingStatus" class="item">
                    <div style="margin-top: 2px">
                        <ScorecardDonut
                            :width="44"
                            :strokeWidth="2"
                            :compact="true"
                            :hidden="isLoading"
                            :score="isLoading ? 0 : row.score"
                        />
                    </div>
                    <Spacer width="1.5rem" />
                    <div>
                        <Text as="p" size="f-7" weight="500">{{ row.title }}</Text>
                        <Spacer height="0.25rem" />
                        <Text as="p" size="f-8">
                            {{ row.copy }}
                        </Text>
                    </div>
                </div>
            </div>
        </template>
    </ScorecardSection>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import dateFormat from 'date-fns/format'
import dateSubtract from 'date-fns/sub'
import { Scorecard } from '@opteo/types'

import {
    Text,
    Spacer,
    oTable,
    Number as NumberVue,
    Money,
    Tooltip,
    LineChart,
    MetricInfo,
} from '@opteo/components-next'

import ScorecardSection from './ScorecardSection.vue'
import ScorecardDonut from './ScorecardDonut.vue'
import { getSectionCopy } from './utils'

export default defineComponent({
    name: 'ConversionTracking',
    components: {
        Text,
        Spacer,
        oTable,
        NumberVue,
        Money,
        ScorecardSection,
        Tooltip,
        LineChart,
        ScorecardDonut,
    },
    props: {
        score: {
            type: Number,
        },
        invalid: {
            type: Boolean,
        },
        isLoading: {
            type: Boolean,
        },
        domainName: {
            type: String,
        },
        domainCurrency: {
            type: String,
        },
        details: {
            type: Object as PropType<Scorecard.ConversionTrackingScoreDetails>,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
    },
    setup(props) {
        const isPdf = props.mode === 'pdf'

        const scoreTextOptions = {
            0: [
                `Your account has either not recorded a conversion yet, or does not have conversion tracking set up correctly. If the latter is the case — it is essential to set up conversion tracking to measure profitability and inform optimisation decisions. Whether setting performance targets, adjusting keyword bids, or building smart campaigns, plentiful, accurate conversion data is essential for building and maintaining a profitable Google Ads account.`,
                `If your account is struggling to generate enough conversions, it might make sense to increase spending in your most successful campaigns (in the hope of generating a few more conversions), or create a secondary conversion action more likely to generate conversions (while still being a relevant performance indicator).`,
            ],
            1: [
                `Some conversions have been recorded in your account, however more conversion volume is required to reach an appropriate level of statistical significance. Whether setting targets, adjusting bids, or building smart campaigns, plentiful, accurate conversion data is an essential component of a profitable Google Ads account.`,
                `If your account is struggling to generate enough conversions, it might make sense to increase spending in your most successful campaigns (in the hope of generating a few more conversions), or create a secondary conversion action more likely to generate conversions (while still being a relevant performance indicator).`,
            ],
            33: [
                `Conversion tracking is active and functional. Enough conversions have been generated for your conversion data to be statistically significant. Whether setting performance targets, adjusting bids, or building out smart campaigns, accurate conversion data is essential for maintaining a profitable Google Ads account.`,
            ],
        }

        const scoreTexts = computed(() =>
            props.score ? getSectionCopy(scoreTextOptions, props.score) : []
        )

        // Daily Performance Metrics: can be conversions if using CPA or conversions value if using ROAS

        const dailyPerformanceMetricsLineChartMetric: MetricInfo = props.isUsingCpa
            ? { dataType: 'number' }
            : { dataType: 'money', currency: props.domainCurrency }
        const dailyPerformanceMetricsLineChartStartDate = dateFormat(
            dateSubtract(new Date(), { days: 30 }),
            'yyyy-MM-dd'
        )
        const dailyPerformanceMetricsLineChartEndDate = dateFormat(
            dateSubtract(new Date(), { days: 1 }),
            'yyyy-MM-dd'
        )
        const dailyPerformanceMetricsLineChartAxisTitles = {
            x: `Date (${dailyPerformanceMetricsLineChartStartDate} → ${dailyPerformanceMetricsLineChartEndDate})`,
        }
        const dailyPerformanceMetricsLineChartSeries = computed(() => {
            if (!props.details?.dailyPerformanceMetrics) return []

            if (props.isUsingCpa) {
                const { byConversions } = props.details.dailyPerformanceMetrics

                return [
                    {
                        name: 'Conversions',
                        items: byConversions.map(({ date, conversions }) => {
                            return {
                                x: new Date(date),
                                y: Math.floor(conversions),
                            }
                        }),
                    },
                ]
            } else {
                const { byConversionsValue } = props.details.dailyPerformanceMetrics

                return [
                    {
                        name: 'Conversions Value',
                        items: byConversionsValue.map(({ date, conversionsValue }) => {
                            return {
                                x: new Date(date),
                                y: Math.floor(conversionsValue),
                            }
                        }),
                    },
                ]
            }
        })
        // TODO(scorecard): this really shouldn't be needed, but sometimes the graph would show weird numbers with some decimals instead of round numbers
        const dailyPerformanceMetricsLineChartMaxItem = computed(() => {
            const conversionsSeries = dailyPerformanceMetricsLineChartSeries.value.flatMap(
                ({ items }) => {
                    return items.map(({ y: performanceMetric }) => performanceMetric)
                }
            )

            const topValue = props.isUsingCpa ? 1 : 100

            return Math.max(...conversionsSeries) + topValue
        })

        const topConversionActionsTableHeaders = [
            { key: 'conversionName', text: 'Conv. Name' },
            { key: 'source', text: 'Source', width: 110, noPadding: true },
            props.isUsingCpa
                ? { key: 'conversions', text: 'Conv.', width: 90, noPadding: true }
                : { key: 'conversionsValue', text: 'Value', width: 90, noPadding: true },
        ]
        const topConversionActionsTableItems = computed(() => {
            if (!props.details) return []

            const { byConversions, byConversionsValue } = props.details.topConversionActions

            const relevantTopConversionActions = props.isUsingCpa
                ? byConversions
                : byConversionsValue

            return relevantTopConversionActions.map(conversionAction => {
                const { conversions, conversionsValue, ...rest } = conversionAction

                const performanceMetric = props.isUsingCpa ? { conversions } : { conversionsValue }

                return {
                    ...rest,
                    ...performanceMetric,
                }
            })
        })

        const ConversionTrackingStatusCopy = {
            conversionVolume: {
                title: 'Conversion Volume',
                copy: 'Sufficient conversion volume is essential for collecting enough data to make statistically confident decisions.',
            },
            // TODO(scorecard): add this mini score when upgrading to Gads v14 ->
            // importingGA4Conversions: { title: 'Importing GA4 Conversions', copy: 'Send Google Analytics conversion events to Google Ads for reporting and further campaign optimisation.' },
            conversionValues: {
                title: 'Conversion Values',
                copy: 'Some conversion types are of higher value than others and should be reported and optimised for accordingly.',
            },
            dynamicConversionValues: {
                title: 'Dynamic Conversion Values',
                copy: 'Assigning transaction-specific values to conversions helps to provide a more accurate sense of your ROI.',
            },
        } as const satisfies Record<
            Scorecard.ConversionTrackingMiniScore,
            { title: string; copy: string }
        >

        const conversionTrackingStatus = computed(() => {
            const { details } = props

            if (!details) return []

            const { miniScores } = details

            return Object.values(Scorecard.ConversionTrackingMiniScore).map(miniScore => {
                return {
                    ...ConversionTrackingStatusCopy[miniScore],
                    score: miniScores[miniScore],
                }
            })
        })

        return {
            isPdf,
            scoreTexts,
            dailyPerformanceMetricsLineChartMetric,
            dailyPerformanceMetricsLineChartSeries,
            dailyPerformanceMetricsLineChartAxisTitles,
            dailyPerformanceMetricsLineChartMaxItem,
            topConversionActionsTableHeaders,
            topConversionActionsTableItems,
            sectionType: Scorecard.SectionTypes.conversionTracking,
            sectionName: Scorecard.SectionNames.conversionTracking,
            conversionTrackingStatus,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.table-header,
.table-cell {
    // @include pl-24;
    overflow: hidden;
}
.table-cell::after {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -24px;
    width: 4rem;
    bottom: 0;
}
.line-chart-container {
    @include container;
    @include br-20;
    @include pa-32;
    overflow: auto;
}
.sub-scores-container {
    @include container;
    @include br-20;
    @include w-100;
}
.sub-scores-container .item {
    @include ph-24;
    @include pv-20;
    @include flex;
    border-bottom: 1px solid #f7f7f9;
}
.sub-scores-container .item:last-child {
    border-bottom: none;
}

.no-conversion-action-data {
    @include container;
    @include ph-24;
    @include br-20;
    @include flex-center;
    height: 4rem;
}

@media screen and (max-width: $mq-767-max) {
    .table-header,
    .table-cell {
        text-overflow: ellipsis;
    }
    .table-cell::after {
        display: none;
    }
    :deep(.tooltip) {
        width: 100%;
    }
}
</style>
