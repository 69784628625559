import { computed, ref } from 'vue'

import { usePercent, useRoas } from '@opteo/components-next'
import { Targets } from '@opteo/types'

import { ChartItem, Body, ExtraDetails } from '../product-group-bids/types'
import { useDomain } from '@/composition/domain/useDomain'

import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

import { fromMicros, toFixedAccurate } from '@/lib/globalUtils'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'

export function useAdjustProductGroupBid() {
    const { improvement, lastUpdated, title } = useImprovement<Body>()
    const { domainInfo, currencySymbol, currencyCode } = useDomain()

    const { body } = checkImprovement<Body>(improvement)

    const {
        current_cpc_bid: currentCpcBid,
        metrics: productGroupMetrics,
        is_using_target: isUsingTarget,
        predictions,
        product_group_tree,
        product_group_label: productGroupLabel,
        siblings,
        bid_change: bidChange,
        is_increase: bidIncrease,
        campaign_group_label: campaignGroupLabel,

        target: { target_cpa: targetCpa, target_roas: targetRoas },

        // New variables
        new_bid: newBid,
        performance_mode,
        is_using_bid_factory: isUsingBidFactory,
        prediction_items: predictionItems,
        simulation_points: simulationPoints,
    } = body

    const bidAdjustmentItems = [
        {
            key: 'bidChange',
            deltaMode: true,
            beforeValue: useDomainMoney({ value: currentCpcBid }).value.displayValue.value,
            beforeTitle: 'Current CPC Bid',
            afterValue: useDomainMoney({ value: newBid }).value.displayValue.value,
            afterTitle: 'Adjusted CPC Bid',
        },
    ]

    // Raw Metrics
    const productGroupCost = fromMicros(productGroupMetrics.cost_micros ?? 0)
    const productGroupConversions = productGroupMetrics.all_conversions ?? 0
    const productGroupConversionsValue = productGroupMetrics.all_conversions_value ?? 0
    const searchAbsoluteTopImpressionShare =
        productGroupMetrics.search_absolute_top_impression_share ?? 0
    const searchImpressionShare = productGroupMetrics.search_impression_share ?? 0

    // This is the only rare case where if the performance mode is not set, we default to ROAS
    const performanceMode = !performance_mode ? Targets.PerformanceMode.ROAS : performance_mode

    // Calulated Metrics
    const productGroupCpa = productGroupCost > 0 ? productGroupCost / productGroupConversions : 0
    const productGroupRoas =
        productGroupConversionsValue > 0 ? productGroupConversionsValue / productGroupCost : 0

    const performanceValue =
        performanceMode === Targets.PerformanceMode.CPA ? productGroupCpa : productGroupRoas

    const performanceTarget =
        performanceMode === Targets.PerformanceMode.CPA ? targetCpa : targetRoas

    const conversionRate = productGroupConversions / productGroupMetrics.clicks

    if (!performanceTarget) throw new Error('Performance target is not set')

    const statsItems = [
        // Performance Metrics
        performanceMode === Targets.PerformanceMode.CPA
            ? {
                  key: 'productGroupCpa',
                  value: useDomainMoney({ value: productGroupCpa }).value.displayValue.value,
                  title: 'Product Group CPA',
              }
            : {
                  key: 'productGroupRoas',
                  value: useRoas({ value: productGroupRoas }).displayValue.value,
                  title: 'Product Group ROAS',
              },

        // Performance Targets
        performanceMode === Targets.PerformanceMode.CPA
            ? {
                  key: 'targetCpa',
                  value: useDomainMoney({ value: performanceTarget }).value.displayValue.value,
                  title: `${isUsingTarget ? 'Target' : 'Average'} CPA`,
              }
            : {
                  key: 'targetRoas',
                  value: useRoas({ value: performanceTarget }).displayValue.value,
                  title: `${isUsingTarget ? 'Target' : 'Average'} ROAS`,
              },

        // Search Impr. Share
        {
            key: 'searchImpressionShare',
            value: usePercent({ value: searchImpressionShare }).displayValue.value,
            title: 'Search Impr. Share',
        },

        // Absolute Top Impr. Share
        {
            key: 'absoluteTopImpressionShare',
            value:
                searchAbsoluteTopImpressionShare >= 0.1
                    ? usePercent({ value: searchAbsoluteTopImpressionShare }).displayValue.value
                    : '< 10%',
            title: 'Absolute Top Impr. Share',
        },
    ]

    const chartItems: ChartItem[] = (siblings ?? []).map(sibling => {
        const { name, focus, metrics } = sibling

        const cost = fromMicros(metrics.cost_micros ?? 0)

        if (performanceMode === Targets.PerformanceMode.ROAS) {
            return {
                y: metrics.all_conversions_value ?? 0,
                x: cost,
                label: name,
                highlighted: focus,
            }
        }

        return {
            y: cost,
            x: metrics.all_conversions ?? 0,
            label: name,
            highlighted: focus,
        }
    })

    function generatGraphKeys() {
        let keys = ['Below CPA Target', '', 'Above CPA Target']

        if (performanceMode === Targets.PerformanceMode.ROAS) {
            keys = keys.map(key => key.replace('CPA', 'ROAS'))
            keys.reverse()
        }

        if (!isUsingTarget) {
            keys = keys.map(key => key.replace('Target', 'Average'))
        }

        return keys
    }

    const graphKeys = generatGraphKeys()

    function generateGraphAxis() {
        if (performanceMode === Targets.PerformanceMode.ROAS) {
            return {
                x: { label: 'Cost', dataType: 'money', currency: currencyCode?.value },
                y: { label: 'Value', dataType: 'money', currency: currencyCode?.value },
            }
        }

        return {
            x: { label: 'Conversions' },
            y: { label: 'Cost', dataType: 'money', currency: currencyCode?.value },
        }
    }
    const axis = generateGraphAxis()

    function formatChartMertics() {
        if (performanceMode === Targets.PerformanceMode.ROAS) {
            return {
                label: 'ROAS',
                dataType: 'percentage',
            }
        }

        return {
            label: 'CPA',
            dataType: 'money',
            currency: currencyCode?.value,
            inverted: true,
        }
    }

    const chartMetrics = formatChartMertics()

    const cutOff = performanceTarget ?? 0

    const predictedConversions = predictions?.best_cpc_bid.conversions ?? 0
    const predictedConversionsValue = predictions?.best_cpc_bid.conversions_value ?? 0

    const all_conversions_value = productGroupMetrics.all_conversions_value ?? 0
    const all_conversions = productGroupMetrics.all_conversions ?? 0

    const recommendedBid = ref(toFixedAccurate(newBid, 2))
    const bidToPush = ref(toFixedAccurate(newBid, 2))

    const mainProductGroupTree = product_group_tree[0]

    const aboveOrBelowTargetText = performanceValue > performanceTarget ? 'above' : 'below'

    const additionalPredictedAllConversionsValue = predictedConversionsValue - all_conversions_value
    const additionalPredictedConversions = Math.round(predictedConversions - all_conversions)

    const differenceFromTarget = (performanceValue - performanceTarget) / performanceValue

    const increasingOrDecreasing = computed(() =>
        +bidToPush.value > currentCpcBid ? 'Increasing' : 'Decreasing'
    )
    const increasedOrDecreased = computed(() =>
        +bidToPush.value > currentCpcBid ? 'increased' : 'decreased'
    )

    const targetOrAverageText = isUsingTarget ? 'target' : 'average'

    const pushMessages = computed(() => {
        return [
            `Connecting to Google Ads..`,
            `${increasingOrDecreasing.value} product group bid..`,
            `Confirming changes..`,
            `Product group bid ${increasedOrDecreased.value} successfully.`,
        ]
    })

    const pushAction = computed(() =>
        +bidToPush.value > currentCpcBid
            ? 'Increase Product Group Bid'
            : 'Decrease Product Group Bid'
    )

    const onPush: OnPushHandler<ExtraDetails> = () => {
        const pushedData = { cpc_bid: +bidToPush.value }

        const valid = validate(pushedData)

        return {
            valid,
            pushedData,
        }
    }

    function validate(pushedData: ExtraDetails) {
        if (!pushedData.cpc_bid || +pushedData.cpc_bid === 0 || isNaN(+pushedData.cpc_bid)) {
            return false
        } else if (domainInfo?.value?.is_grant && +pushedData?.cpc_bid > 2) {
            return false
        } else if (+pushedData.cpc_bid < 0) {
            return false
        } else if (+pushedData.cpc_bid < 0.01) {
            return false
        }

        return true
    }

    return {
        // Improvement Info
        onPush,
        lastUpdated,
        pushMessages,
        pushAction,
        title,
        currencySymbol,
        currencyCode,

        // Targets
        performanceMode,
        performanceValue,
        performanceTarget,
        targetOrAverageText,
        isUsingTarget,
        differenceFromTarget,
        aboveOrBelowTargetText,

        // Product Goup Info
        productGroupLabel,
        campaignGroupLabel,

        // Product Group Metrics
        productGroupCost,
        productGroupConversions,
        productGroupConversionsValue,
        searchImpressionShare,
        searchAbsoluteTopImpressionShare,
        conversionRate,
        mainProductGroupTree,

        // Predictions
        predictionItems,
        simulationPoints,
        isUsingBidFactory,
        additionalPredictedAllConversionsValue,
        additionalPredictedConversions,

        // No Predictions
        chartItems,
        graphKeys,
        axis,
        chartMetrics,
        cutOff,
        bidAdjustmentItems,

        // Stats
        statsItems,

        // Bid Adjustments
        currentCpcBid,
        bidToPush,
        bidIncrease,
        bidChange,
        recommendedBid,
    }
}
