import { RemovePlussedNegatives } from '@opteo/types'
import { useImprovement, checkImprovement } from '@/composition/improvement/useImprovement'
import { computed, ref } from 'vue'

export function useRemovePlussedNegatives() {
    const { improvement, lastUpdated, title } = useImprovement<RemovePlussedNegatives.Body>()

    const { body } = checkImprovement<RemovePlussedNegatives.Body>(improvement)

    const pushActionText = ref('Fix Negatives')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Replacing negative keywords..',
        'Confirming changes..',
        'Negative keywords replaced successfully.',
    ])

    const tableHeaders = [
        { key: 'level', text: 'Level', width: 114, noPadding: true },
        { key: 'location', text: 'Location', width: 122, noPadding: true },
        { key: 'negative', text: 'Negative', width: 170, noPadding: true },
        { key: 'newNegative', text: 'Replace With', width: 194, noPadding: true },
    ]

    const adGroupItems = body.adgroup_negatives.map(keyword => {
        return {
            level: 'Ad Group',
            location: keyword.adgroup,
            negative: keyword.negative_keyword,
            newNegative: `${keyword.negative_keyword.replaceAll('+', '')}`,
        }
    })

    const campaignItems = body.campaign_negatives.map(keyword => {
        return {
            level: 'Campaign',
            location: keyword.campaign,
            negative: keyword.negative_keyword,
            newNegative: `${keyword.negative_keyword.replaceAll('+', '')}`,
        }
    })

    const sharedSetItems = body.shared_set_negatives.map(keyword => {
        return {
            level: 'Shared Set',
            location: keyword.shared_set_name,
            negative: keyword.negative_keyword,
            newNegative: `${keyword.negative_keyword.replaceAll('+', '')}`,
        }
    })

    const tableItems = [...sharedSetItems, ...campaignItems, ...adGroupItems]

    const refresherTable = {
        headers: [
            { key: 'matchtype', text: 'Match Type', width: 162 },
            { key: 'explainer', text: 'Explanation', width: 218 },
            { key: 'example', text: 'Example', width: 148 },
            { key: 'blocked', text: 'Blocked Searches', width: 188 },
            { key: 'allowed', text: 'Allowed Searches', width: 188 },
        ],
        items: [
            {
                matchtype: '[Negative Exact]',
                explainer: 'Ad will not show if search term matches the negative keyword exactly.',
                example: '[Leather boots]',
                blocked: ['Leather boots'],
                allowed: ['Leather boot', 'Buy leather boots', 'Leather boots for sale'],
            },
            {
                matchtype: '"Negative Phrase"',
                explainer:
                    'Ad will not show if search term contains this exact phrase (incl. search terms with other words placed before or after the phrase).',
                example: '"Leather boots"',
                blocked: ['Leather boots', 'Buy leather boots'],
                allowed: ['Leather boot', 'Boots real leather', 'Boots genuine leather'],
            },
            {
                matchtype: 'Negative Broad',
                explainer: 'Ad will not show when a search term contains these terms in any order.',
                example: 'Leather boots',
                blocked: ['Leather boots', 'Buy leather boots', 'Boots genuine leather'],
                allowed: ['Leather boot', 'Leather bootmaking'],
            },
        ],
    }

    return {
        title,
        pushMessages,
        body,
        lastUpdated,
        tableHeaders,
        tableItems,
        refresherTable,
        pushActionText,
    }
}
