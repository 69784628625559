<template></template>

<script lang="ts">
import { onMounted } from 'vue'
import { showSnackbar } from '@opteo/components-next'
import { delay } from '@/lib/globalUtils'

export default {
    setup() {
        onMounted(() => {
            //@ts-ignore -- this variable is set in ads-prebid.js
            if (!window.adsAllowed) {
                showSnackbar({
                    message: `Please disable your ad blocker for Opteo to work properly.`,
                    timeout: 6000,
                })
            }
        })
    },
}
</script>
