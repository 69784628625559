<template>
    <div id="domain-settings-wrapper">
        <PageHeader page-title="Settings" :no-margin="!aboveMobile">
            <template #end-content>
                <Popout
                    v-model="jumpList"
                    :trapFocus="true"
                    :externalToggleButton="true"
                    :offset="[1, 12]"
                >
                    <oButton color="white" @clicked="jumpList = !jumpList">
                        Jump to
                        <template v-slot:icon>
                            <SelectArrowIcon hex="#006dff" />
                        </template>
                    </oButton>
                    <template v-slot:content>
                        <perfect-scrollbar class="jump-to-list-max-height">
                            <SelectableList :allowEnterInitialIndex="true">
                                <ListItem
                                    v-for="{ label, targetId } of jumpListItems"
                                    :key="targetId"
                                    :value="targetId"
                                    @click="scrollToSection(targetId)"
                                >
                                    <Text size="f-8">{{ label }}</Text>
                                </ListItem>
                            </SelectableList>
                        </perfect-scrollbar>
                    </template>
                </Popout>
            </template>
        </PageHeader>
        <PageContent>
            <!-- Account Profile -->
            <SettingsSection id="account-profile" title="Account Profile" :maxWidth="376">
                <AccountProfile show-update-button />
            </SettingsSection>

            <!-- Budget -->
            <SettingsSection id="update-budget" title="Monthly Budget" :maxWidth="376">
                <AccountMonthlyBudget
                    :account-id="accountId"
                    :currency-code="currencyCode"
                    :currency-symbol="currencySymbol"
                    :account-platform="accountPlatform"
                    :show-section-note="false"
                    show-update-button
                />
            </SettingsSection>

            <!-- Performance Mode -->
            <SettingsSection id="performance-mode" title="Performance Mode" :maxWidth="376">
                <AccountPerformanceMode show-update-button />
            </SettingsSection>

            <!-- Conversion Actions -->
            <SettingsSection id="conversion-actions" title="Conversion Actions" :maxWidth="376">
                <AccountConversionActions />
            </SettingsSection>

            <!-- Performance Goals -->
            <SettingsSection id="performance-goals" title="Performance Goals" :maxWidth="376">
                <AccountPerformanceGoals show-update-button />
            </SettingsSection>

            <!-- Google Analytics -->

            <SettingsSection
                v-if="accountPlatform === Platform.Platform.GoogleAds"
                id="analytics"
                title="Connect Google Analytics"
                class="google-analytics-connection"
                :maxWidth="420"
            >
                <Analytics view="domain-settings" />
            </SettingsSection>

            <!-- Connect Slack -->
            <SettingsSection id="connect-slack" title="Connect Slack Channel" :maxWidth="420">
                <SlackConnectWrapper :is-domain-specific-connection="true" />
            </SettingsSection>

            <!-- Alert Settings -->
            <SettingsSection id="alert-settings" title="Alert Settings" :maxWidth="380">
                <oForm @submitted="saveAlertSettings">
                    <div class="section-content">
                        <Spacer height="2rem" />
                        <oInput
                            :button-mode="true"
                            type="checkbox"
                            v-model="muteSettings.email"
                            name="domainEmailMuted"
                            label="Mute Email alerts for this client"
                        />
                        <Spacer height="0.75rem" />

                        <oInput
                            :button-mode="true"
                            type="checkbox"
                            v-model="muteSettings.slack"
                            name="domainSlackMuted"
                            label="Mute Slack alerts for this client"
                        />
                        <Spacer height="1rem" />
                        <Text
                            as="p"
                            size="f-9"
                            style="font-size: 0.75rem; line-height: 1.1875rem"
                            color="gray"
                        >
                            Please note, selecting this option will mute Slack alerts for everyone
                            on your team (applies to this account only).
                        </Text>
                    </div>
                    <div class="domain-settings-button-container short-top">
                        <oButton
                            :loading="saveAlertSettingsButtonLoading"
                            ref="saveAlertSettingsButton"
                            size="medium"
                            type="submit"
                        >
                            Update Alert Settings
                        </oButton>
                    </div>
                </oForm>
            </SettingsSection>

            <!-- Active Improvement Types -->
            <SettingsSection
                v-if="
                    accountPlatform === Platform.Platform.MicrosoftAds ||
                    accountPlatform === Platform.Platform.GoogleAds
                "
                id="active-imp-types"
                title="Active Improvement Types"
                :maxWidth="432"
                :noInnerContentShadow="true"
            >
                <div class="section-head-imp">
                    <Text as="p" size="f-8">
                        Some improvement types may not be appropriate for your account. You can
                        disable those that are not relevant here. Please note, this will not affect
                        your teammates' task list.
                    </Text>
                </div>

                <div
                    v-for="category of recActionPrefrerenceCategories"
                    :key="category.categoryLabel"
                >
                    <div class="section-sub-head">
                        <Text as="h4" weight="600" size="f-5" style="letter-spacing: -0.0375rem">
                            {{ category.categoryLabel }}
                        </Text>
                    </div>
                    <div class="section-content-imp">
                        <div v-if="category.preferences?.length">
                            <div v-for="pref of category.preferences" :key="pref.static_title">
                                <oInput
                                    v-model="pref.selected"
                                    :button-mode="true"
                                    type="checkbox"
                                    :label="pref.static_title"
                                    @click="setActiveImpType()"
                                />
                                <Spacer height="0.75rem" />
                            </div>
                        </div>
                        <div v-else>
                            <div v-for="i of new Array(6)" :key="i" class="checkbox-container">
                                <Skeleton :height="20" :width="20" />
                                <Skeleton
                                    class="ml-12"
                                    :height="12"
                                    :width="Math.random() * 80 + 100"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </SettingsSection>

            <!-- Timezone -->
            <SettingsSection
                v-if="
                    accountPlatform === Platform.Platform.MicrosoftAds ||
                    accountPlatform === Platform.Platform.GoogleAds
                "
                id="update-country"
                title="SERP Country"
                :maxWidth="396"
            >
                <div class="section-head">
                    <div class="timezone-container">
                        <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M33 18c0 8.2843-6.7157 15-15 15-8.28427 0-15-6.7157-15-15C3 9.71573 9.71573 3 18 3c8.2843 0 15 6.71573 15 15Zm3 0c0 9.9411-8.0589 18-18 18-9.94113 0-18-8.0589-18-18C0 8.05887 8.05887 0 18 0c9.9411 0 18 8.05887 18 18ZM20 8.5c0-.82843-.6716-1.5-1.5-1.5S17 7.67157 17 8.5v8.75c0 .9665.7835 1.75 1.75 1.75h5.75c.8284 0 1.5-.6716 1.5-1.5s-.6716-1.5-1.5-1.5H20V8.5Z"
                                fill="#006DFF"
                            />
                        </svg>
                        <div class="timezone">
                            <Text as="span" size="f-8">Native timezone:</Text>
                            <Spacer height="0.25rem" />
                            <Text v-if="timezone" as="h5" size="f-7" weight="600">
                                {{ timezone }}
                            </Text>
                            <Skeleton v-else :width="120" />
                        </div>
                    </div>
                    <Spacer height="2rem" />
                    <div class="text-container">
                        <Text as="p" size="f-8">
                            Search Engine Results Page (SERP) previews are based on the native
                            timezone in
                            <Text v-if="accountPlatform === Platform.Platform.GoogleAds" as="span">
                                Google Ads.</Text
                            >. To override this, please select one of the countries from the
                            dropdown menu below.
                        </Text>
                    </div>
                </div>
                <oForm @submitted="saveCountry">
                    <div class="section-content">
                        <oInput
                            v-if="countryDropdownItems.length > 0"
                            :model-value="countryMutable || countryRaw"
                            @update:model-value="countryMutable = $event"
                            name="country"
                            :items="countryDropdownItems"
                            type="select"
                            label="Country"
                        />
                        <oInput v-else disabled type="select" label="Country" />
                    </div>
                    <div class="domain-settings-button-container">
                        <oButton
                            ref="saveCountryButton"
                            :loading="saveCountryButtonLoading"
                            size="medium"
                            type="submit"
                        >
                            Override Native Timezone
                        </oButton>
                    </div>
                </oForm>
            </SettingsSection>

            <!-- Change History Settings -->
            <SettingsSection
                id="update-domain-connection"
                title="Change History Settings"
                :maxWidth="400"
            >
                <div class="section-head">
                    <div class="text-container">
                        <Text as="p" size="f-8">
                            When Opteo pushes a change to this client, the email listed below will
                            be displayed in the Change History section in
                            <Text v-if="accountPlatform === Platform.Platform.GoogleAds" as="span">
                                Google Ads.</Text
                            >
                            <Text
                                v-else-if="accountPlatform === Platform.Platform.MetaAds"
                                as="span"
                            >
                                Meta Ads.</Text
                            >
                            <Text v-else as="span"> Microsoft Ads.</Text>
                            <Spacer height="1rem" />
                            You can select a preferred email if this client has access to Opteo via
                            more than one email.
                        </Text>
                    </div>
                </div>
                <oForm @submitted="saveConnection">
                    <div class="section-content">
                        <oInput
                            :model-value="currentConnection?.connectionId"
                            :items="connectionDropdownItems"
                            name="connectionId"
                            type="select"
                            :label="platformEmailLabel"
                        />
                    </div>
                    <div class="domain-settings-button-container">
                        <oButton
                            ref="saveConnectionButton"
                            :loading="saveConnectionButtonLoading"
                            size="medium"
                            type="submit"
                        >
                            Update Connection Email
                        </oButton>
                    </div>
                </oForm>
            </SettingsSection>

            <!-- CPI Override -->
            <SettingsSection
                v-if="accountPlatform === Platform.Platform.GoogleAds"
                id="update-cpi-override"
                title="CPI Override Settings"
                :maxWidth="406"
            >
                <div class="section-head">
                    <div class="text-container">
                        <Text as="p" size="f-8">
                            When comparing ads, Opteo uses Conversions Per Impression (CPI) to
                            measure performance. When there are not enough conversions to use CPI,
                            Opteo uses Click Through Rate (CTR).
                            <Spacer height="1rem" />
                            To ignore any
                            <b>Pause Ad</b> improvements based on Click Through Rate, check the box
                            below.
                        </Text>
                    </div>
                </div>
                <oForm @submitted="saveCpiSetting">
                    <div class="section-content">
                        <oInput
                            type="checkbox"
                            :button-mode="true"
                            :modelValue="cpiSetting"
                            label="Ignore CTR-based <b>Pause Ad</b> Tasks"
                            name="cpiSetting"
                            @updated="updateCpiSetting"
                        />
                    </div>
                    <div class="domain-settings-button-container">
                        <oButton
                            ref="saveCpiButton"
                            :loading="saveCpiButtonLoading"
                            size="medium"
                            type="submit"
                        >
                            Update CPI Override Settings
                        </oButton>
                    </div>
                </oForm>
            </SettingsSection>
        </PageContent>
    </div>
</template>
<script lang="ts" setup>
import { nextTick, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import { Platform } from '@opteo/types'
import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'

import {
    oButton,
    oInput,
    oForm,
    Text,
    Spacer,
    Popout,
    SelectableList,
    SelectArrowIcon,
    ListItem,
    showSnackbar,
} from '@opteo/components-next'

import SettingsSection from '@/components/global/SettingsSection.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import Analytics from '@/components/analytics/Analytics.vue'
import { useDomain } from '@/composition/domain/useDomain'
import { useImprovementPreferences } from '@/composition/improvement/useImprovementPreferences'
import { scrollTo, delay } from '@/lib/globalUtils'
import SlackConnectWrapper from '@/components/slack/SlackConnectWrapper.vue'
import AccountProfile from '@/components/accountsSettings/AccountProfile.vue'
import AccountMonthlyBudget from '@/components/accountsSettings/AccountMonthlyBudget.vue'
import AccountPerformanceGoals from '@/components/accountsSettings/AccountPerformanceGoals.vue'
import AccountPerformanceMode from '@/components/accountsSettings/AccountPerformanceMode.vue'
import { useAccountSettings } from '@/composition/account/useAccountSettings'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useAccount } from '@/composition/account/useAccount'
import AccountConversionActions from '@/components/accountsSettings/AccountConversionActions.vue'

const route = useRoute()

const jumpList = ref(false)

const { aboveMobile } = useMediaQuery()
const { accountPlatform, accountId, currencySymbol, currencyCode } = useAccount()

const scrollPosition = route?.params?.scroll_position

const scrollToSection = async (targetId: string) => {
    await delay(100)

    nextTick(() => {
        scrollTo(targetId, 'smooth', 96)
    })
}

if (scrollPosition) {
    // @ts-expect-error
    scrollToSection(scrollPosition)
}

const jumpListItems = [
    {
        label: 'Account Profile',
        targetId: 'account-profile',
    },
    {
        label: 'Monthly Budget',
        targetId: 'update-budget',
    },
    {
        label: 'Performance Mode',
        targetId: 'performance-mode',
    },
    {
        label: 'Conversion Actions',
        targetId: 'conversion-actions',
    },
    {
        label: 'Performance Goals',
        targetId: 'performance-goals',
    },
    {
        label: 'Connect Google Analytics',
        targetId: 'analytics',
    },
    {
        label: 'Connect Slack Channel',
        targetId: 'connect-slack',
    },
    {
        label: 'Alert Settings',
        targetId: 'alert-settings',
    },
    {
        label: 'Active Improvement Types',
        targetId: 'active-imp-types',
    },
    {
        label: 'SERP Country',
        targetId: 'update-country',
    },
    {
        label: 'Change History Settings',
        targetId: 'update-domain-connection',
    },
    {
        label: 'CPI Override Settings',
        targetId: 'update-cpi-override',
    },
]

const { updateActiveImpTypes, recActionPrefrerenceCategories } = useImprovementPreferences()

const setActiveImpType = () => {
    updateActiveImpTypes()
    showSnackbar({
        message: `Improvement Preferences Saved.`,
        timeout: 5000,
    })
}

const {
    saveAlertSettings,
    muteSettings,
    saveAlertSettingsButton,
    saveAlertSettingsButtonLoading,
    timezone,
    saveCountry,
    countryDropdownItems,
    countryMutable,
    countryRaw,
    saveCountryButton,
    saveCountryButtonLoading,
    saveConnection,
    currentConnection,
    connectionDropdownItems,
    saveConnectionButton,
    saveConnectionButtonLoading,
    saveCpiSetting,
    cpiSetting,
    updateCpiSetting,
    saveCpiButton,
    saveCpiButtonLoading,
} = useAccountSettings()

const platformEmailLabel = computed(() => {
    if (accountPlatform.value === Platform.Platform.GoogleAds) {
        return 'Google Ads Email'
    } else if (accountPlatform.value === Platform.Platform.MicrosoftAds) {
        return 'Microsoft Ads Email'
    } else {
        ;('Meta Ads Email')
    }
})
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

#domain-settings-wrapper .section-head {
    @include pa-32;
}

#domain-settings-wrapper .section-head-imp {
    @include ph-32;
    @include pv-28;
    @include mb-32;
    @include container;
    @include br-24;
}

#domain-settings-wrapper .section-sub-head {
    @include mt-40;
    @include mb-28;
}

#domain-settings-wrapper .section-content {
    @include ph-32;
    @include w-100;
}

#domain-settings-wrapper .mobile-jump-to-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

#domain-settings-wrapper .section-content-imp {
    @include ph-32;
    @include pt-32;
    @include pb-20;
    @include container;
    @include w-100;
    @include br-24;
}

#domain-settings-wrapper .scroll {
    max-height: 500px;
    overflow: auto;
    @include pt-32;
    @include pb-20;
}

#domain-settings-wrapper .domain-settings-button-container {
    @include flex-center;
    @include pa-32;

    &.no-padding-top {
        @include pt-0;
    }
    &.short-top {
        padding-top: 1.625rem;
    }
}

#domain-settings-wrapper .checkbox-container {
    @include flex;
    @include container;
    @include pa-16;
    @include mb-12;
    @include items-center;
    overflow-x: auto;
}
#domain-settings-wrapper .domain-color-container {
    @include flex;
    @include container;
    @include br-12;
    padding-left: 0.875rem;
    @include mb-0;
    @include items-center;
    overflow-x: auto;
    height: 2.75rem;
}

#domain-settings-wrapper .color-circle {
    @include br-999;
    margin-right: 0.375rem;
    @include pointer;
    @include flex;
    @include justify-center;
    @include items-center;
    width: 1.375rem;
    height: 1.375rem;
}

#domain-settings-wrapper .account-profile-container {
    outline: none;
    @include no-select;
    @include flex;
    @include items-center;
    @include justify-center;
    @include pv-56;
    border-bottom: 1px solid $opteo-light-gray;
}

#domain-settings-wrapper .client-selector {
    @include flex;
    @include items-center;
}

#domain-settings-wrapper .client-name {
    margin-left: 0.875rem;
    white-space: nowrap;
    max-width: 11.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.04rem;
}

#domain-settings-wrapper .timezone-container {
    outline: none;
    @include no-select;
    @include flex;
    @include items-center;
    @include justify-center;
    @include pa-32;
    @include container;
}

#domain-settings-wrapper .timezone {
    @include ml-16;
    @include mr-12;
}

#domain-settings-wrapper .unset-mb {
    margin-bottom: unset;
}

#domain-settings-wrapper .button-mode {
    width: 100%;
    max-width: 100%;
}

#domain-settings-wrapper .text-container {
    @include container;
    @include ph-24;
    @include pv-20;
}

.jump-to-list-max-height {
    max-height: 260px;
}

#domain-settings-wrapper .google-analytics-connection {
    display: none;
}

// Responsive
@media screen and (max-width: $mq-767-max) {
    #domain-settings-wrapper .jump-to-button {
        position: absolute;
        top: 32px;
        right: 48px;
    }
}

@media screen and (max-width: $mq-480-max) {
    #domain-settings-wrapper .jump-to-button {
        position: absolute;
        top: 32px;
        right: 16px;
    }
}

@media screen and (min-width: 1264px) {
    #domain-settings-wrapper .google-analytics-connection {
        display: block;
    }
}

.section-note {
    background-color: $opteo-dark-blue-translucent;
    border-radius: 1rem;
    @include ph-24;
    @include pv-20;
}
</style>
