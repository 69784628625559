<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <EntityPill type="adgroup" :content="adgroupLabel" />
                <Spacer height="2rem" />
                <Text as="p">
                    In the ad group listed above, the
                    <Text as="span" weight="600" color="green">winning</Text> ad is performing
                    <b><Percent :value="cpiMode ? cpiChange / 100 : ctrChange / 100" /></b> better
                    than the <Text as="span" weight="600" color="red">losing</Text> ad (using
                    {{ cpiMode ? 'CPI' : 'CTR' }} as the primary performance metric). Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to pause
                    the losing ad and channel spend into a more successful alternative. If this ad
                    group needs a replacement, Opteo will create a <b>Write New Ad</b> task
                    automatically.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/900213-pause-losing-ad">
                Click here to learn how Opteo makes a fair comparison between ads.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Ad Comparison</Text>
        </ImprovementContent>
        <ImprovementContent :adWidth="true" v-if="adType === 'image'">
            <DisplayAd
                :display-ad-url="bestAdData.ad.imageUrl"
                :featured-statistics="bestAdData.statistics"
                :title-tag="bestAdData.titleTag"
            />

            <Spacer height="3rem" />
            <DisplayAd
                :display-ad-url="worstAdData.ad.imageUrl"
                :featured-statistics="worstAdData.statistics"
                :title-tag="worstAdData.titleTag"
            />
            <Spacer height="3rem" />
        </ImprovementContent>
        <ImprovementContent :adWidth="true" v-else>
            <TextAd
                :ad="bestAdData.ad"
                :featured-statistics="bestAdData.statistics"
                :title-tag="bestAdData.titleTag"
            />

            <Spacer height="3rem" />
            <TextAd
                :ad="worstAdData.ad"
                :featured-statistics="worstAdData.statistics"
                :title-tag="worstAdData.titleTag"
            />
            <Spacer height="3rem" />
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Comparison Table</Text>
            <Spacer height="2rem" />
            <oTable :headers="comparisonTableHeaders" :items="comparisonTableItems">
                <!-- Winner Header -->
                <template #header.winner>
                    <Text as="span" size="f-8" weight="500" color="green">Winning Ad</Text>
                </template>
                <!-- Winner Header -->
                <template #header.loser>
                    <Text as="span" size="f-8" weight="500" color="red">Losing Ad</Text>
                </template>
            </oTable>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="stats">
                <template v-slot:cpi v-if="cpiMode">
                    The winning ad is performing
                    <b>{{ stats[0].value }}</b> better than the losing ad.
                </template>
                <template v-slot:ctr v-else>
                    The winning ad is performing
                    <b>{{ stats[0].value }}</b> better than the losing ad.
                </template>
                <template v-slot:confidence>
                    Opteo is
                    <b>{{ stats[1].value }}</b> certain that this difference is statistically
                    significant.
                </template>
                <template v-slot:days-counted>
                    Clicks were only counted from days where both ads were running at the same time
                    to discount weekday variance and seasonality.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="+stats[2].value" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Pause Winning Ad">
                    <Text as="p">
                        By default Opteo will pause the
                        <Text as="span" weight="500" color="red">Losing Ad</Text>. In some cases,
                        you may want to pause the
                        <Text as="span" weight="500" color="green">Winning Ad</Text>
                        instead. Use the dropdown below to choose which ad to pause.
                    </Text>
                </ImprovementHeader>
                <div class="improvement-adjustment-container">
                    <Text as="span" size="f-8">Pause the</Text>
                    <oInput
                        v-model="selectedAdToPause"
                        :items="adSelectItems"
                        type="select"
                        class="adjustment-input"
                        @updated="handleSelectAdToPause"
                    />
                    <Text as="span" size="f-8">in this comparison.</Text>
                </div>
                <Spacer height="3rem" />
                <Text as="h3" size="f-4" weight="600">Comparison Table</Text>
                <Spacer height="2rem" />
                <oTable :headers="comparisonTableHeaders" :items="comparisonTableItems">
                    <!-- Winner Header -->
                    <template #header.winner>
                        <Text as="span" size="f-8" weight="500" color="green">Winning Ad</Text>
                    </template>
                    <!-- Winner Header -->
                    <template #header.loser>
                        <Text as="span" size="f-8" weight="500" color="red">Losing Ad</Text>
                    </template>
                </oTable>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { usePauseAd } from '@/composition/improvement/types/usePauseAd'
import useMediaQuery from '@/composition/global/useMediaQuery'

// components-next
import {
    EntityPill,
    Spacer,
    Text,
    Percent,
    TextAd,
    useNumber,
    oTable,
    oInput,
} from '@opteo/components-next'

// Improvement Components
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementCopy from '@/components/improvement/ImprovementCopy.vue'

// Shared Components
import DisplayAd from '@/components/improvement/shared-components/DisplayAd.vue'

// Util Components
import Ent from '@/components/util/Ent.vue'

export default {
    name: 'PauseAd',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        ImprovementCopy,
        EntityPill,
        Ent,
        Spacer,
        Text,
        Percent,
        TextAd,
        DisplayAd,
        oTable,
        oInput,
    },
    methods: { useNumber },
    setup() {
        return { ...usePauseAd(), ...useMediaQuery() }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.improvement-adjustment-container {
    @include container;
    @include ph-32;
    @include pv-28;
    @include flex;
    @include items-center;
}

.adjustment-input {
    @include ph-16;
}

@media screen and (min-width: $mq-0-min) and (max-width: $mq-767-max) {
    .improvement-adjustment-container {
        flex-direction: column;
        align-items: unset;
    }

    .adjustment-input {
        @include w-100;
        @include mv-20;
        padding: unset;
    }
}
</style>
