<template>
    <div class="insight-card">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.insight-card {
    position: sticky;
    @include container;
    @include pa-24;
    // this margin-top should match the margin-top applied to .slide-container in <SlideContainer />
    @include mt-48;
    // @include mb-48;
    top: 3rem;
}
</style>
