import { computed, ref } from 'vue'
import { OverBudgetAlert } from '@opteo/types'
import { useLocalStorage, StorageSerializers } from '@vueuse/core'
import format from 'date-fns/format'

import { useActiveImprovements } from './useActiveImprovements'

import { Endpoint, useAPI, authRequest } from '../api/useAPI'
import { useDomain } from '../domain/useDomain'
import { useAccount } from '../account/useAccount'

export interface ImprovementEmptyStates {
    state:
        | OverBudgetAlert.EmptyStates.CampaignsPaused
        | OverBudgetAlert.EmptyStates.ZeroSpend
        | OverBudgetAlert.EmptyStates.FirstImps
        | OverBudgetAlert.EmptyStates.CompletedImps
        | OverBudgetAlert.EmptyStates.LostWriteAccess
}

interface PausedStatus {
    status: OverBudgetAlert.EmptyStates
}

export function useImprovementEmptyStates() {
    const { improvements, loading: improvementsLoading } = useActiveImprovements()
    const { accountId } = useAccount()
    const { domainId } = useDomain()

    const {
        data: pausedStatus,
        loading: pausedStatusLoading,
        mutate: mutatePausedStatus,
        error,
    } = useAPI<PausedStatus>(Endpoint.GetAccountPausedStatus, {
        body: () => ({ account_id: accountId.value }),
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value && improvements.value.length === 0,
    })

    const reactivatingCampaignsLoader = ref(false)
    const currentDate = new Date()
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    const formattedReEnableDate = format(firstDay, 'do MMMM yyyy')

    const loading = computed(() => {
        if (improvements.value.length > 0) {
            return false
        }

        return improvementsLoading.value || pausedStatusLoading.value
    })

    const localMockEmptyState = useLocalStorage<string>('MOCK_EMPTY_STATE', null, {
        serializer: StorageSerializers.string,
    })

    if (!localMockEmptyState.value) {
        localMockEmptyState.value = ''
    }

    const reactivatePausedCampaigns = async () => {
        reactivatingCampaignsLoader.value = true
        await authRequest(Endpoint.ReactivatePausedCampaigns, {
            body: { domain_id: domainId.value },
        })

        mutatePausedStatus()
        reactivatingCampaignsLoader.value = false
    }

    /* Setting this variable in local storage will force mock an empty state for testing. enums in EmptyStates */
    const mock = computed(() => {
        return { status: localMockEmptyState.value }
    })

    const mockEmptyState = computed(() => {
        if (mock.value.status) {
            return true
        }

        return !(improvements.value.length > 0)
    })

    const emptyState = computed(() => {
        if (mock.value.status) {
            return mock.value
        }
        return pausedStatus.value
    })

    return {
        emptyState,
        loadingEmptyState: loading,
        formattedReEnableDate,
        reactivatePausedCampaigns,
        reactivatingCampaignsLoader,
        EmptyStates: OverBudgetAlert.EmptyStates,
        mockEmptyState,
    }
}
