import { AdjustDeviceBidsV2, Improvement } from '@opteo/types'
import { InvalidBidReason, InvalidBidReasonRaw, TableItem } from './types'
import { getProperDeviceLabel } from './utils'

export interface TableHeader {
    key: string
    text?: string
    sortable?: boolean
    width?: number
    noPadding?: boolean
}

export function useDeviceTables(
    devices: AdjustDeviceBidsV2.DeviceMetrics[],
    isUsingCpa: boolean,
    formattedTarget: string,
    entityAverage: number,
    targetSet: boolean,
    recAction?: Improvement.RecAction
) {
    const deviceTableHeaders: TableHeader[] = [
        { key: 'device', text: 'Device', sortable: false, noPadding: true },
        { key: 'cost', text: 'Cost', sortable: true, width: 116 },
        { key: 'impressions', text: 'Impr.', sortable: true, width: 116 },
    ]

    if (isUsingCpa) {
        deviceTableHeaders.push(
            { key: 'conversions', text: 'Conv.', sortable: true, width: 112 },
            { key: 'cpa', text: 'CPA', sortable: true, width: 112 },
            { key: 'avg-cpa', text: 'Avg. CPA', sortable: false, width: 112 },
            { key: 'difference', text: 'Diff.', sortable: true, width: 112 },
            recAction === Improvement.RecAction.ExcludeSingleDevice
                ? { key: 'action', text: 'Action', width: 200 }
                : { key: 'bid-adjustment', text: 'Bid Adj.', width: 136 }
        )
    } else {
        deviceTableHeaders.push(
            { key: 'conversions-value', text: 'Value', sortable: true, width: 116 },
            { key: 'roas', text: 'ROAS', sortable: true, width: 116 },
            { key: 'avg-roas', text: 'Avg. ROAS', sortable: false, width: 116 },
            { key: 'difference', text: 'Diff.', sortable: true, width: 112 },
            recAction === Improvement.RecAction.ExcludeSingleDevice
                ? { key: 'action', text: 'Action', width: 200 }
                : { key: 'bid-adjustment', text: 'Bid Adj.', width: 136 }
        )
    }

    const deviceAdjustTableHeaders = deviceTableHeaders.map(header => {
        if (header.key === 'bid-adjustment') header.width = 132
        return header
    })

    function mapInvalidReasons(
        rawReason: string | undefined,
        targetSet: boolean,
        performanceModeEntityToCompare: number,
        placementCpa: number,
        placementRoas: number,
        recAction?: Improvement.RecAction
    ) {
        /* Reasons for exclusions */
        if (recAction === Improvement.RecAction.ExcludeSingleDevice) {
            if (rawReason === InvalidBidReasonRaw.PLACEMENT_COST_OVER_ENVIRONMENT_THRESHOLD) {
                return {
                    reason: InvalidBidReason.PLACEMENT_COST_OVER_ENVIRONMENT_THRESHOLD,
                    width: 256,
                }
            } else if (rawReason === InvalidBidReasonRaw.ZERO_BID_MOD) {
                return { reason: InvalidBidReason.ZERO_BID_MOD, width: 208 }
            }
            return { width: 0, reason: '' }
        } else {
            /* Reasons for bid adjustments */
            if (
                rawReason === InvalidBidReasonRaw.BID_IN_WRONG_DIRECTION &&
                targetSet &&
                isUsingCpa
            ) {
                if (placementCpa < performanceModeEntityToCompare) {
                    return {
                        reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING_CPA.replace(
                            '$TARGET_CPA',
                            formattedTarget
                        ),
                        width: 256,
                    }
                }
                return {
                    reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING_CPA.replace(
                        '$TARGET_CPA',
                        formattedTarget
                    ),
                    width: 256,
                }
            }
            if (
                rawReason === InvalidBidReasonRaw.BID_IN_WRONG_DIRECTION &&
                targetSet &&
                !isUsingCpa
            ) {
                if (placementRoas < performanceModeEntityToCompare) {
                    return {
                        reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING_ROAS.replace(
                            '$TARGET_ROAS',
                            formattedTarget
                        ),
                        width: 256,
                    }
                }
                return {
                    reason: InvalidBidReason.BID_IN_WRONG_DIRECTION_UNDERPERFORMING_ROAS.replace(
                        '$TARGET_ROAS',
                        formattedTarget
                    ),
                    width: 256,
                }
            }
            if (rawReason === InvalidBidReasonRaw.BID_CHANGE_IS_TOO_SMALL) {
                if (isUsingCpa)
                    return { reason: InvalidBidReason.BID_CHANGE_IS_TOO_SMALL_CPA, width: 160 }
                return { reason: InvalidBidReason.BID_CHANGE_IS_TOO_SMALL_ROAS, width: 160 }
            }
            if (rawReason === InvalidBidReasonRaw.BUDGET_CAPPED_CONSTRAINTS) {
                return { reason: InvalidBidReason.BUDGET_CAPPED_CONSTRAINTS, width: 169 }
            }
            if (rawReason === InvalidBidReasonRaw.ZERO_BID_MOD) {
                return { reason: InvalidBidReason.BID_IS_ZERO, width: 208 }
            }

            return { reason: InvalidBidReason.DEFAULT, width: 200 }
        }
    }

    const deviceTableItems: TableItem[] = []
    const deviceAdjustTableItems: TableItem[] = []

    devices.forEach(device => {
        const cpa =
            device.cost === 0 && device.all_conversions === 0
                ? 0
                : device.cost / device.all_conversions

        const roas =
            device.all_conversions_value > 0 ? device.all_conversions_value / device.cost : 0

        const { reason, width } = mapInvalidReasons(
            device.reason,
            targetSet,
            entityAverage,
            cpa,
            roas,
            recAction
        )

        const difference =
            entityAverage === 0
                ? 0
                : isUsingCpa
                ? (cpa - entityAverage) / entityAverage
                : (roas - entityAverage) / entityAverage

        const row = {
            id: getProperDeviceLabel(device.device_label),
            device: getProperDeviceLabel(device.device_label),
            cost: device.cost,
            impressions: device.impressions,
            conversions: device.all_conversions,
            ['conversions-value']: device.all_conversions_value,
            cpa,
            roas,
            'avg-cpa': entityAverage,
            'avg-roas': entityAverage,
            difference: difference,
            'bid-adjustment': {
                value: !device.invalid ? device.new_bid_mod! - 1 : 0,
                id: device.criteria,
                invalid: device.invalid,
                reason,
                width,
                current: device.current_bid_mod! - 1,
            },
            action: {
                id: device.criteria,
                width,
                exclude: device?.is_exclude ?? false,
                message: device?.invalid ? reason : '',
            },
        }

        deviceTableItems.push(row)
        deviceAdjustTableItems.push(row)
    })

    return {
        deviceTableHeaders,
        deviceTableItems,
        deviceAdjustTableHeaders,
        deviceAdjustTableItems,
    }
}
