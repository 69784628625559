<template>
    <TopNavBar>
        <AccountCenterTopNav />
    </TopNavBar>
    <PageHeader pageTitle="Account Centre">
        <template #right-content>
            <Label :left="true">Sort By</Label>
            <oInput
                v-model="sortedBy"
                :items="[
                    { value: SortBy.Name, label: 'Account Name' },
                    { value: SortBy.Spend, label: 'Spend (30d)' },
                    { value: SortBy.TasksAvailable, label: 'Improvements' },
                    { value: SortBy.Budget, label: 'Budget Status' },
                    { value: SortBy.LastActive, label: 'Last Active' },
                ]"
                type="select"
                class="sorted-by-select"
            />
        </template>
    </PageHeader>

    <PageContent>
        <!-- First login /init state to prompt to go to linked accounts-->
        <div v-if="isInitPage" class="empty-state-container">
            <AccountCenterInitIcon />
            <Spacer height="1.625rem" />
            <div class="center" style="max-width: 288px">
                <Text as="p" size="f-8" align="center">
                    Welcome to Opteo. To get started and link your first account, click the button
                    below.
                </Text>
            </div>
            <Spacer height="1.625rem" />
            <oButton color="white" @click="goToLinkedAccounts">Go to Linked Accounts</oButton>
        </div>

        <!-- Skeleton rows -->
        <div v-else-if="loading" class="domain-list">
            <DomainListRow v-for="skeletonId in 20" :key="skeletonId" :account="{}" />
        </div>

        <!-- Domain List -->
        <div v-else-if="truncatedDomainList?.length" class="domain-list">
            <DomainListRow
                v-for="domain in truncatedDomainList"
                :key="domain.accountId"
                :account="domain"
            />
        </div>

        <!-- No domains available -->
        <div v-else-if="userHasNoDomains" class="empty-state-container">
            <AccountCenterInitIcon />
            <Spacer height="1.625rem" />
            <div class="center" style="max-width: 268px">
                <Text as="p" size="f-8" align="center">
                    You currently have no accounts linked to Opteo. Click the button below to add a
                    new account or import a new MCC.
                </Text>
            </div>
            <Spacer height="1.625rem" />
            <oButton color="white" @click="goToLinkedAccounts">Go to Linked Accounts</oButton>
        </div>

        <!-- No result found -->
        <div v-else class="empty-state-container">
            <AccountCenterInitIcon />
            <Spacer height="1.625rem" />
            <div class="center" style="max-width: 272px">
                <Text as="p" size="f-8" align="center">
                    No accounts matching your search query. Click the button below to clear your
                    search.
                </Text>
            </div>
            <Spacer height="1.625rem" />
            <oButton color="white" @click="searchedDomain = ''">Clear Search</oButton>
        </div>
    </PageContent>

    <!-- Account Centre onboarding -->
    <OnboardingTour
        step-name="tour_domainlist"
        :buttonText="'Continue to Account Centre'"
        contentMaxWidth="460px"
    >
        <template v-slot:header>Account Centre</template>
        <template v-slot:copy>
            Account Centre is a place for all of the accounts you have linked to Opteo. Each row
            contains recent performance trends, budget statuses, and available improvements. You can
            also update account settings and budgets using the settings button.
        </template>
    </OnboardingTour>
</template>

<script lang="ts">
import { ref, defineComponent, computed, onMounted } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'
import { useRouter } from 'vue-router'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useAccountList } from '@/composition/user/useAccountList'
import { SortBy } from '@/lib/accountList'
import { useIntercom } from '@/lib/intercom/useIntercom'

// Components
import { oButton, oInput, Spacer, ViewToggle, Label, Text } from '@opteo/components-next'

import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'

import DomainListRow from '@/components/AccountCenter/DomainListRow.vue'
import AccountCenterInitIcon from '@/components/AccountCenter/AccountCenterInitIcon.vue'
import { Routes } from '@/router/routes'
import Skeleton from '@/components/util/Skeleton.vue'
import OnboardingTour from '@/components/User/OnboardingTour.vue'

import AccountCenterTopNav from '@/components/AccountCenter/AccountCenterTopNav.vue'
import TopNavBar from '@/layouts/TopNavBar.vue'
import { pushToDatalayer } from '@/lib/datalayer'

export default defineComponent({
    name: 'AccountCentre',
    components: {
        PageHeader,
        DomainListRow,
        AccountCenterInitIcon,
        PageContent,
        oButton,
        oInput,
        Spacer,
        ViewToggle,
        Label,
        Skeleton,
        AccountCenterTopNav,
        TopNavBar,
        OnboardingTour,
        Text,
    },
    setup() {
        const { user } = useUser()
        const {
            searchedDomain,
            userHasNoDomains,
            setDomainListOrder,
            sortedBy,
            filteredDomainList,
            resetSearch,
            domainListLoading,
        } = useAccountList()

        const loading = computed(() => !user.value || domainListLoading.value)

        const { push, currentRoute } = useRouter()

        const isInitPage = computed(() => currentRoute.value.name === Routes.AccountCentreInit)

        const goToLinkedAccounts = () => push({ name: Routes.LinkedAccounts })

        const intercom = useIntercom()

        onMounted(() => {
            intercom.trackEvent('60_saw_client_list')
            pushToDatalayer('gtm_saw_client_centre')
            resetSearch()
        })

        /*
            Infinite scroll bits
        */
        const listTruncationCount = ref(25)
        const truncatedDomainList = computed(() => {
            return filteredDomainList.value.slice(0, listTruncationCount.value)
        })
        useInfiniteScroll(
            document,
            () => {
                listTruncationCount.value = listTruncationCount.value + 25
            },
            { distance: 500 } // 500px from bottom, allow more rows to be shown
        )

        return {
            searchedDomain,
            userHasNoDomains,
            setDomainListOrder,
            sortedBy,
            loading,
            goToLinkedAccounts,
            truncatedDomainList,
            SortBy,
            isInitPage,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.sorted-by-select {
    width: 152px;
}

.sidebar-title {
    @include f-9;
    @include fw-400;
    @include mb-24;
    @include ph-32;
    @include opteo-foreground;
    @include o-30;
}
.sidebar-content {
    @include ph-32;
    @include mb-32;
}
.sidebar-content .o-button {
    @include opteo-background-l2;
    @include opteo-foreground;
}

.paginate-links {
    width: 400px;
}

.open-sidebar-button {
    @include opteo-background;
    @include flex;
    @include items-center;
    @include justify-center;
    @include br-999;
    @include pointer;
    width: 2.75rem !important;
    height: 2.75rem !important;
}

.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 24rem;
    padding: 6rem 0;
    @include container;
    @include br-24;
}
.empty-state-image {
    width: 12.5rem;
    @include mb-16;
}
.empty-state-text {
    @include f-9;
    @include lh-150;
    @include tc;
    width: 11rem;
}

.no-accounts-image {
    width: 20.375rem;
}

.domain-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 3rem;
}

@media screen and (min-width: $mq-1025-min) and (max-width: $mq-1279-max) {
    .domain-grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 3rem;
    }
}
@media screen and (min-width: 0) and (max-width: $mq-1024-max) {
    .domain-grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-gap: 3rem;
    }
}
</style>
