<template>
    <PageContent>
        <div class="reports-upgrade">
            <div class="reports-upgrade-container">
                <div class="reports-upgrade-header">
                    <div class="reports-upgrade-paragraph">
                        <Text as="h3" weight="600" size="f-3" style="line-height: 2.25rem">
                            Upgrade your plan and start creating beautiful Google Ads reports.
                        </Text>
                        <Spacer height="1.125rem" />
                        <Text as="p">
                            Reports is available for users on the
                            <b>Professional</b> pricing plan and above. Click the button below to
                            upgrade your plan and unlock Opteo Reports.
                        </Text>
                        <Spacer height="1.375rem" />
                        <oButton v-if="isAdmin && selfUpgradeablePlan" @clicked="openPlansModal">
                            Upgrade Pricing Plan
                        </oButton>
                        <oButton
                            v-else-if="isAdmin"
                            :loading="intercomLoading"
                            @clicked="showIntercom()"
                        >
                            Message Support
                        </oButton>
                        <p v-else class="lh-copy opteo-steel f7">
                            Account administrator required to upgrade pricing plan. Contact
                            <b>{{ adminEmail }}</b> to get access to Reports.
                        </p>
                    </div>
                    <div class="screenshot-image-container">
                        <img src="@/assets/img/reports-i.png" class="screenshot-image" />
                    </div>
                </div>
                <div
                    v-for="(page, index) in pageSections"
                    :key="index"
                    class="reports-upgrade-header"
                >
                    <div class="reports-upgrade-paragraph">
                        <Text as="h3" weight="600" size="f-3" style="line-height: 2.25rem">
                            {{ page.title }}
                        </Text>
                        <Spacer height="1.125rem" />
                        <Text as="p">
                            {{ page.paragraph }}
                        </Text>
                    </div>
                    <div class="screenshot-image-container">
                        <img
                            :src="page.image"
                            :style="[{ width: page.imageWidth }]"
                            class="screenshot-image"
                        />
                    </div>
                </div>
            </div>
        </div>
    </PageContent>
</template>

<script lang="ts">
/*
    States to consider:
    - user admin or not
    - team has_reports_access or not
    - team plan has_reports_access or not
    - team plan "upgradeable" or not ('plan-v2-s', 'plan-v3-s', 'plan-v4-s', 'plan-v5-s')
*/
import { computed, ref, defineComponent } from 'vue'

import { Billing } from '@opteo/types'
import { oButton, Text, Spacer } from '@opteo/components-next'
import { useIntercom } from '@/lib/intercom/useIntercom'
import imageTwo from '@/assets/img/reports-ii.png'
import imageThree from '@/assets/img/reports-iii.png'
import imageFour from '@/assets/img/reports-iv.png'
import imageFive from '@/assets/img/reports-v.png'
import imageSix from '@/assets/img/reports-vi.png'
import { useUser } from '@/composition/user/useUser'
import PageContent from '@/layouts/PageContent.vue'

export default defineComponent({
    name: 'ReportsUpsell',
    components: { oButton, Text, Spacer, PageContent },
    emits: ['show-plans-modal'],
    setup: (_, { emit }) => {
        const { editUserAttributes } = useIntercom()
        const { userInfo, isAdmin, currentPlan } = useUser()

        const adminEmail = computed(() => userInfo.value?.admin_email)

        const selfUpgradeablePlan = computed(() =>
            [
                'plan-v2-s',
                'plan-v3-s',
                Billing.LegacyPlan.Basic,
                Billing.StandardPlan.Basic,
            ].includes(currentPlan.value?.id ?? '')
        )

        const pageSections = [
            {
                title: 'Create beautiful white label reports with your branding and colours.',
                paragraph:
                    'Add your logo, choose a theme, select primary and secondary colours, give each report a unique look that impresses clients and colleagues.',
                image: imageTwo,
                imageWidth: '1120px',
            },
            {
                title: 'Get automatically generated comments unique to each report.',
                paragraph:
                    'Comments are generated according the contents of your report, use them as a guideline or export with default comments at the click of a button.',
                image: imageThree,
                imageWidth: '1120px',
            },
            {
                title: 'Show the value of good account management.',
                paragraph: `Reports are geared towards showcasing business value. Whether you're managing spend sensibly or making loads of bid adjustments, reports show the value of good Google Ads management.`,
                image: imageFour,
                imageWidth: '1120px',
            },
            {
                title: 'Help stakeholders understand important Google Ads concepts.',
                paragraph:
                    'Reports are designed to communicate with business owners and managers. They explain Google Ads concepts in simple terms that make sense to businesspeople and non-experts.',
                image: imageFive,
                imageWidth: '670px',
            },
            {
                title: 'Retain more clients and keep managers happy.',
                paragraph:
                    'Communication is the key to happy clients and managers. Reports show the work that goes into Google Ads management and communicate the value Google Ads can bring to a business.',
                image: imageSix,
                imageWidth: '670px',
            },
        ]

        /*
            An trigger should be set up in the Intercom UI that sends a message
            to this user when reports_grandfather_chat=true. Tricky to test 
            because it only happens the first time.
        */
        const intercomLoading = ref(false)
        const showIntercom = () => {
            intercomLoading.value = true
            editUserAttributes({ reports_grandfather_chat: true })
            setTimeout(() => {
                intercomLoading.value = false
            }, 2000)
        }

        function openPlansModal() {
            emit('show-plans-modal')
        }

        return {
            pageSections,
            adminEmail,
            isAdmin,
            showIntercom,
            openPlansModal,
            intercomLoading,
            selfUpgradeablePlan,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.reports-upgrade {
    margin: 0 auto;
}
.reports-upgrade-container {
    @include container;
}
.reports-upgrade-header {
    @include flex;
    @include items-center;
    @include justify-start;
    overflow: hidden;
    border-bottom: 1px solid #f7f7f9;
    @include br-12;
}
.reports-upgrade-header:last-child {
    border-bottom: none;
}
// .reports-upgrade-row {
//     @include flex-center;
//     overflow: hidden;
//     border-bottom: 1px solid #f7f7f9;
// }
.screenshot-image-container {
    width: 670px;
    overflow: visible;
}
.screenshot-image {
    box-shadow: $opteo-shadow-l;
    @include br-16;
    margin-top: 5rem;
    margin-bottom: 5rem;
    min-width: 1120px;
    height: 640px;
}
.reports-upgrade-paragraph {
    width: 350px;
    flex: 0 0 350px;
    margin-right: 5rem;
    margin-left: 6rem;
}
/* .report-upgrade-body .page-header {
    display: none;
} */
</style>
