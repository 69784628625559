<template>
    <section class="rsa-statistics">
        <div class="chart">
            <DonutChart :items="donutChart.items" :metric="donutChart.metric" />
        </div>
        <oTable :headers="table.headers" :items="table.items" :noShadow="true" :fixedLayout="true">
            <!-- Cost -->
            <template v-slot:column.cost="cost">
                <Money :value="cost.value" :currency="currencyCode" />
            </template>
            <!-- CTR -->
            <template v-slot:column.averageCtr="ctr">
                <Percent :value="ctr.value" />
            </template>
        </oTable>
    </section>
</template>

<script lang="ts">
import { Spacer, Money, Text, Percent, oTable, DonutChart } from '@opteo/components-next'

export default {
    name: 'RsaStatistics',
    components: { Text, Spacer, Money, Percent, oTable, DonutChart },
    props: {
        donutChart: {
            type: Object,
            required: true,
        },
        table: {
            type: Object,
            required: true,
        },
        currencyCode: {
            type: String,
            required: true,
        },
    },
    setup: () => {},
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.rsa-statistics {
    @include container;
    @include br-24;
    overflow: hidden;
}

.rsa-statistics .chart {
    @include pv-32;
    border-bottom: 1px solid $opteo-light-gray;
}

// Responsive
@media screen and (max-width: $mq-480-max) {
}

// Transitions
</style>
