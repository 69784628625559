<template>
    <svg
        style="display: block"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1086_18070)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.1716 7.75739C9.69512 6.96696 10 6.01908 10 5c0-2.76142-2.23858-5-5-5S0 2.23858 0 5s2.23858 5 5 5c1.01908 0 1.96696-.30488 2.75739-.8284l2.53551 2.5355c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L9.1716 7.75739ZM8 5c0 1.65685-1.34315 3-3 3S2 6.65685 2 5s1.34315-3 3-3 3 1.34315 3 3Z"
                fill="#CACAD3"
            />
        </g>
        <defs>
            <clipPath id="clip0_1086_18070"><path fill="#fff" d="M0 0h12v12H0z" /></clipPath>
        </defs>
    </svg>
</template>
