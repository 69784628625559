import { computed, ref, watchEffect, Ref } from 'vue'
import { Endpoint, useAPI, authRequest } from '@/composition/api/useAPI'
import { Ngram, AdWords, Gads, Targets, Improvement } from '@opteo/types'
import { enums } from '@opteo/types/gads'
import sortBy from 'lodash-es/sortBy'
import round from 'lodash-es/round'
import compact from 'lodash-es/compact'

import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useDomain } from '@/composition/domain/useDomain'
import { scrollToSelector } from '@/lib/globalUtils'
import { getCommonVariables } from './utils'

import type {
    Entity as KeywordDestinationEntity,
    Entities,
} from '@/components/improvement/shared-components/keyword-destination/keyword-destination-types'

type KeywordMatchTypes = Ref<{ matchType: enums.KeywordMatchType; selected: boolean }[]>

export function useAddNegativeNgram() {
    const { currencyCode: currency, domainId } = useDomain()
    const { improvement, lastUpdated } = useImprovement<Ngram.NgramImpType.Body>()
    const { body } = checkImprovement<Ngram.NgramImpType.Body>(improvement)
    const {
        performanceMode,
        ngramText,
        campaignGroupName,
        campaignGroupTarget,
        hasTarget,
        scatterPlotItems,
        ngramConversions,
        ngramConversionsValue,
        ngramCost,
        ngramCpa,
        ngramRoas,
        dataPeriod,
        window,
        contributingSearchTerms,
        isShopping,
        stats,
    } = getCommonVariables(body)

    const pushActionText = ref('Add Negative Keyword')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding negative keyword..',
        'Confirming changes..',
        'Negative keyword added successfully.',
    ])
    const adjustSteps = ref(['Classify Negative'])
    const header = 'Add N-Gram Negative'
    const defaultSharedSetName = isShopping
        ? 'Keyword negatives for shopping campaigns (auto_gen_shopping)'
        : 'Keyword negatives for search campaigns (auto_gen_general)'

    /**
     * Adjust phase word selection
     */

    const showKeywordSelector = ref(false)

    const selectedWords = ref(
        body.ngram.text.split(' ').map(word => {
            return {
                word,
                selected: true,
            }
        })
    )

    const newNgram = computed(() =>
        selectedWords.value
            .filter(w => w.selected)
            .map(w => w.word)
            .join(' ')
    )

    const previewKeyword = computed(() => {
        if (!selectedWords.value.some(w => w.selected)) {
            return null
        }

        const words = selectedWords.value
            .filter(w => w.selected)
            .map(w => w.word)
            .join(' ')

        const matchType = keywordMatchTypes.value.find(matchType => matchType.selected)

        if (matchType?.matchType === enums.KeywordMatchType.PHRASE) {
            return `“${words}”`
        } else if (matchType?.matchType === enums.KeywordMatchType.EXACT) {
            return `[${words}]`
        } else {
            return words
        }
    })

    const keywordMatchTypes: KeywordMatchTypes = ref([
        { matchType: enums.KeywordMatchType.EXACT, selected: false },
        { matchType: enums.KeywordMatchType.PHRASE, selected: true },
        { matchType: enums.KeywordMatchType.BROAD, selected: false },
    ])

    function toggleMatchType(matchType: enums.KeywordMatchType) {
        const selectedKeywordMatchType = keywordMatchTypes.value.find(
            mt => mt.matchType === matchType
        )

        if (selectedKeywordMatchType) {
            keywordMatchTypes.value.forEach(mt => (mt.selected = false))
            selectedKeywordMatchType.selected = true
        }
    }

    const newNgramConflictStatus = computed(() => {
        if (newNgram.value === '') {
            return {
                hasConflicts: false,
                conversionLoss: 0,
            }
        }
        const partialNgram = body.partial_ngrams.find(n => n.text === newNgram.value)
        if (!partialNgram) {
            return {
                hasConflicts: false,
                conversionLoss: 0,
            }
        }
        return {
            hasConflicts: partialNgram.conflicting_keywords.length > 0,
            conversionLoss: round(partialNgram.all_conversions, 1),
        }
    })

    const defaultNegativeLevel = body.default_negative_level
    const includedNegativeLevels = computed(() => {
        return {
            shared: !!selectedSharedSets.value.length,
            campaign: !!selectedCampaigns.value.length,
        }
    })

    /**
     * Adjust phase - shared sets
     */
    const { data: sharedSetData } = useAPI<AdWords.SharedNegativeList[]>(
        Endpoint.GetSharedNegativeLists,
        {
            body: { type: 'keywords' },
            uniqueId: () => domainId.value,
            waitFor: () => domainId.value,
        }
    )

    const createNegativeListEntity = (id: string, label: string): KeywordDestinationEntity => {
        const TYPE: `${Improvement.LocationEntity.NegativeList}` = 'negative-list'

        return { id, label, type: TYPE, checked: false }
    }

    const setInitialSharedSets = (sharedSets: AdWords.SharedNegativeList[]) => {
        const formattedSharedSets = sharedSets.map(sharedSet => {
            const { shared_set_resource_name: id, shared_set_name: label } = sharedSet

            return createNegativeListEntity(id, label)
        })

        return sortBy(
            formattedSharedSets.map(formattedSharedSet => ({
                ...formattedSharedSet,
                checked: false,
            }))
        )
    }

    /**
     * Adjust phase - campaigns
     */

    const { data: campaignsData, loading: campaignsLoading } =
        useAPI<Ngram.SelectableCampaignsForNegativeKeyword>(
            Endpoint.GetSelectableCampaignsForNegativeKeyword,
            {
                body: {},
                uniqueId: () => domainId.value,
                waitFor: () => domainId.value,
            }
        )

    const setInitialCampaigns = (campaigns: Ngram.SelectableCampaignsForNegativeKeyword) => {
        const initiallySelectedCampaigns =
            defaultNegativeLevel === 'campaign'
                ? campaigns.map(campaign => campaign.resource_name)
                : []

        const formatCampaigns = (): KeywordDestinationEntity[] => {
            return campaigns
                .filter(campaign => {
                    const correctChannel = isShopping
                        ? campaign.advertising_channel_type ===
                          Gads.enums.AdvertisingChannelType.SHOPPING
                        : campaign.advertising_channel_type ===
                          Gads.enums.AdvertisingChannelType.SEARCH

                    return (
                        correctChannel &&
                        body.campaign_group.campaign_rns.includes(campaign.resource_name)
                    )
                })
                .map<KeywordDestinationEntity>(campaign => {
                    const { resource_name: id, name: label } = campaign

                    return {
                        id,
                        label,
                        type: 'campaign',
                        checked: initiallySelectedCampaigns.includes(id),
                        //@ts-ignore
                        children: campaign?.adgroups_data?.map(adGroup => {
                            return {
                                id: adGroup.adgroup_id,
                                label: adGroup.adgroup,
                                type: 'adgroup',
                                checked: false,
                            }
                        }),
                    }
                })
        }

        return sortBy(formatCampaigns(), campaign => campaign.label)
    }

    /**
     * Adjust phase - Keyword Destination Selector
     */

    const destinations = computed(() => [
        {
            key: Improvement.LocationEntity.NegativeList,
            name: 'Negative Lists',
            active: defaultNegativeLevel === 'shared',
            // ensure our account level "entity" is included in the count
            count: accountLevelEntity.value.checked ? 1 : 0,
        },
        {
            key: Improvement.LocationEntity.Campaign,
            name: 'Campaigns',
            active: defaultNegativeLevel === 'campaign',
        },
    ])

    // Handle entities reactive values

    const ACCOUNT_LEVEL = 'account-level'
    const accountLevelEntity = ref<KeywordDestinationEntity>({
        id: ACCOUNT_LEVEL,
        label: 'Account Level',
        type: ACCOUNT_LEVEL,
        checked: false,
    })

    const entities = ref<Entities>({
        [Improvement.LocationEntity.NegativeList]: [],
        [Improvement.LocationEntity.Campaign]: [],
    })

    // Set up initial values for the entities object
    const handleInitiallySelectedEntities = () => {
        entities.value[Improvement.LocationEntity.NegativeList] = setInitialSharedSets(
            sharedSetData?.value ?? []
        )

        entities.value[Improvement.LocationEntity.Campaign] = setInitialCampaigns(
            campaignsData?.value ?? []
        )
    }

    const addNegativeList = (negativeList: KeywordDestinationEntity) => {
        entities.value[Improvement.LocationEntity.NegativeList].push(negativeList)
    }

    watchEffect(() => {
        if (sharedSetData.value && campaignsData.value) {
            handleInitiallySelectedEntities()
        }
    })

    const sharedSets = computed(() => entities.value[Improvement.LocationEntity.NegativeList])
    const adGroups = computed(() => entities.value[Improvement.LocationEntity.NegativeList])

    const selectedSharedSets = computed(() =>
        sharedSets.value.filter(({ checked }) => checked).map(({ id }) => id)
    )

    const selectedAdGroupIds = computed(() =>
        adGroups.value.filter(({ checked }) => checked).map(({ id }) => id)
    )
    const existingSharedSetNames = computed(() =>
        sharedSets.value
            .filter(sharedSet => sharedSet.id !== 'default')
            .map(sharedSet => sharedSet.label)
    )

    const campaigns = computed(() => entities.value[Improvement.LocationEntity.Campaign])
    const selectedCampaigns = computed(() =>
        campaigns.value.filter(({ checked }) => checked).map(({ id }) => id)
    )

    const negativeListModalOpen = ref(false)

    /**
     * Adjust phase - validation & prepare for push
     */

    // reset everything when going back from adjust phase
    const onReset = () => {
        selectedWords.value = selectedWords.value.map(w => ({ ...w, selected: true }))
        handleInitiallySelectedEntities()
    }

    const onPushError = ref({ status: false, message: '' })

    const onPush: OnPushHandler<Ngram.NgramImpType.NegativeNgramExtraDetails> = () => {
        /**
         * Check that selected ngram words aren't empty.
         */
        if (newNgram.value === '') {
            scrollToSelector('.create-keyword-form')
            return {
                valid: false,
            }
        }

        const keywordMatchType = keywordMatchTypes.value.find(matchType => matchType.selected)
            ?.matchType

        const adGroups: number[] =
            compact(
                entities.value?.campaign?.flatMap(parentCampaign => {
                    return parentCampaign?.children
                        ?.filter(adGroup => adGroup.checked)
                        .map(adGroup => +adGroup.id)
                })
            ) ?? []

        const areAllSelectedEmpty =
            !accountLevelEntity.value.checked &&
            selectedSharedSets.value.length === 0 &&
            selectedCampaigns.value.length === 0 &&
            adGroups.length === 0

        if (areAllSelectedEmpty) {
            onPushError.value.status = true
            onPushError.value.message = 'Please select at least one destination for your negative.'
            scrollToSelector('.keyword-destination-selector')
            return {
                valid: false,
            }
        }

        const sharedSetRns: string[] = []
        let includesDefaultSharedSet = false

        selectedSharedSets.value.forEach(id => {
            if (id === 'default') {
                includesDefaultSharedSet = true
            } else {
                sharedSetRns.push(id)
            }
        })
        onPushError.value.status = false

        return {
            valid: true,
            pushedData: {
                included_negative_levels: includedNegativeLevels.value,
                ngram_text: newNgram.value,
                shared_set_rns: sharedSetRns,
                includes_default_shared_set: includesDefaultSharedSet,
                includes_account_level: accountLevelEntity.value.checked,
                campaign_rns: selectedCampaigns.value,
                ad_group_ids: adGroups,
                match_type: keywordMatchType,
            },
        }
    }

    return {
        currency,
        pushMessages,
        onPush,
        lastUpdated,
        header,
        isShopping,
        performanceMode,
        PerformanceMode: Targets.PerformanceMode,
        ngramText,
        campaignGroupName,
        defaultSharedSetName,
        scatterPlotItems,
        campaignGroupTarget,
        hasTarget,
        ngramConversions,
        ngramConversionsValue,
        ngramCost,
        ngramCpa,
        ngramRoas,
        window,
        dataPeriod,
        stats,
        contributingSearchTerms,
        selectedWords,
        showKeywordSelector,
        newNgram,
        newNgramConflictStatus,
        defaultNegativeLevel,
        includedNegativeLevels,
        entities,
        campaigns,
        existingSharedSetNames,
        addNegativeList,
        accountLevelEntity,
        negativeListModalOpen,
        destinations,
        campaignsLoading,
        onReset,
        pushActionText,
        adjustSteps,
        onPushError,
        keywordMatchTypes,
        KeywordMatchType: enums.KeywordMatchType,
        toggleMatchType,
        previewKeyword,
    }
}
