<template>
    <section class="w-100">
        <!-- Headline -->
        <Text as="h3" size="f-4" weight="600">{{ title }}</Text>
        <Spacer height="2rem" />
        <!-- Mobile App Grid -->
        <div :class="gridItems.length > 1 ? 'mobile-app-grid' : 'single-mobile-app'">
            <div
                v-for="(item, index) in gridItems"
                :key="'app-' + index"
                :class="['item-' + (index + 1), 'mobile-app-grid-item']"
            >
                <div class="mobile-app-grid-icon-container">
                    <!-- Placeholder / App Icon -->
                    <svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 22.4c0-7.8407 0-11.7611 1.52591-14.75587 1.34223-2.63427 3.48395-4.77599 6.11822-6.11822C10.6389 0 14.5593 0 22.4 0h19.2c7.8407 0 11.7611 0 14.7559 1.52591 2.6342 1.34223 4.776 3.48395 6.1182 6.11822C64 10.6389 64 14.5593 64 22.4v19.2c0 7.8407 0 11.7611-1.5259 14.7559-1.3422 2.6342-3.484 4.776-6.1182 6.1182C53.3611 64 49.4407 64 41.6 64H22.4c-7.8407 0-11.7611 0-14.75587-1.5259-2.63427-1.3422-4.77599-3.484-6.11822-6.1182C0 53.3611 0 49.4407 0 41.6V22.4Z"
                            fill="#F0F0F5"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M32 42c5.5228 0 10-4.4772 10-10s-4.4772-10-10-10-10 4.4772-10 10 4.4772 10 10 10Zm0-15.0001c.5523 0 1 .4477 1 1v4c0 .5523-.4477 1-1 1s-1-.4477-1-1v-4c0-.5523.4477-1 1-1Zm1.5 8.5c0 .8284-.6716 1.5-1.5 1.5s-1.5-.6716-1.5-1.5.6716-1.5 1.5-1.5 1.5.6716 1.5 1.5Z"
                            fill="#E0E0E5"
                        />
                    </svg>
                    <transition v-if="item.image" name="fade" appear>
                        <img
                            class="mobile-app-icon-image"
                            :src="item.image"
                            @error="imageLoadError(item)"
                        />
                    </transition>
                </div>
                <!-- Mask -->
                <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <clipPath id="svgPath">
                            <path
                                d="M0 22.4c0-7.8407 0-11.7611 1.52591-14.75587 1.34223-2.63427 3.48395-4.77599 6.11822-6.11822C10.6389 0 14.5593 0 22.4 0h19.2c7.8407 0 11.7611 0 14.7559 1.52591 2.6342 1.34223 4.776 3.48395 6.1182 6.11822C64 10.6389 64 14.5593 64 22.4v19.2c0 7.8407 0 11.7611-1.5259 14.7559-1.3422 2.6342-3.484 4.776-6.1182 6.1182C53.3611 64 49.4407 64 41.6 64H22.4c-7.8407 0-11.7611 0-14.75587-1.5259-2.63427-1.3422-4.77599-3.484-6.11822-6.1182C0 53.3611 0 49.4407 0 41.6V22.4Z"
                                fill="#F0F0F5"
                            />
                        </clipPath>
                    </defs>
                </svg>
                <Spacer height="1.125rem" />
                <Text size="f-8" as="span" align="center" class="mobile-app-name">{{
                    item.name
                }}</Text>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue'

interface MobileAppGridItem {
    name: string
    image?: string
    placeholder?: string
    alt?: string
    title: string
}

import {
    // Components
    oImage,
    Text,
    Spacer,
    // Icons
    MobileAppIcon,
} from '@opteo/components-next'

export default defineComponent({
    components: { MobileAppIcon, oImage, Text, Spacer },
    props: {
        items: {
            type: Array as PropType<MobileAppGridItem[]>,
            required: true,
            // default: () => [
            //     {
            //         name: 'Word Life',
            //         image: '/images/appOne.png',
            //         placeholder: '/images/appOne-min.png',
            //     },
            //     {
            //         name: 'Clash of Clans',
            //         image: '/images/appTwo.png',
            //         placeholder: '/images/appOne-min.png',
            //     },
            //     {
            //         name: 'Anime Piano Tiles',
            //         image: '/images/appThree.png',
            //         placeholder: '/images/appOne-min.png',
            //     },
            //     {
            //         name: 'Default App Icon',
            //     },
            // ],
        },
        title: {
            type: String,
            required: false,
            default: 'Top Spending Mobile Apps',
        },
    },
    setup(props) {
        const erroredUrls = ref<string[]>([])

        /* When an image fails to load, make sure the markup shows the default svg */
        const gridItems = computed(() => {
            return props.items.map(item => {
                if (erroredUrls.value.includes(item.image ?? '')) {
                    return {
                        ...item,
                        image: undefined,
                    }
                }
                return item
            })
        })

        return {
            imageLoadError(item: MobileAppGridItem) {
                erroredUrls.value.push(item.image ?? '')
            },
            gridItems,
            erroredUrls,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.single-mobile-app {
    display: block;
    width: 100%;
    @include container;
    @include br-20;
}
.mobile-app-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0;
    width: 100%;
    @include container;
    @include br-20;
}
.mobile-app-grid .mobile-app-grid-item {
    @include pv-56;
    @include flex-center;
    flex-direction: column;
}
.single-mobile-app .mobile-app-grid-item {
    @include pv-64;
    @include flex-center;
    flex-direction: column;
}
.mobile-app-grid .mobile-app-grid-item.item-1 {
    border-right: 1px solid $opteo-light-gray;
    border-bottom: 1px solid $opteo-light-gray;
}
.mobile-app-grid .mobile-app-grid-item.item-2 {
    border-bottom: 1px solid $opteo-light-gray;
}
.mobile-app-grid .mobile-app-grid-item.item-3 {
    border-right: 1px solid $opteo-light-gray;
}

.mobile-app-name {
    width: calc(100% - 8rem);
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 18px;
    overflow: hidden;
}

.mobile-app-grid-icon-container {
    width: 4rem;
    height: 4rem;
    @include relative;
}
.mobile-app-icon-image {
    clip-path: url(#svgPath);
    width: 4rem;
    height: 4rem;
    @include absolute;
    top: 0;
    left: 0;
}

// Transitions
.fade-enter-active,
.fade-leave-active {
    transition: all 2s cubic-bezier(0, 0, 0.2, 1);
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
