<template>
    <InsightCard v-if="hasInsights && !slideTypeHidden">
        <Tabs
            :links="[
                {
                    //  insights is keyed as insight in data from api call
                    key: 'insight',
                    name: 'Insights',
                    count: content.insight.length ?? 0,
                    disabled: !content.insight.length,
                },
                {
                    //  advice is keyed as generic in data from api call
                    key: 'generic',
                    name: 'Advice',
                    count: content.generic.length ?? 0,
                    disabled: !content.generic.length,
                },
            ]"
            :router="false"
            :active="activeTab"
            @clicked="setActiveTab"
        />

        <div v-if="activeTab === 'insight'" class="insight-body">
            <ul>
                <li
                    v-for="(insight, index) in parsedContent.insight"
                    :key="index"
                    class="insight-item"
                    v-html="insight"
                ></li>
                <!-- This will eventually form the copy functionality documented on the website
                https://opteo.com/features/reports/google-ads-reports -->
                <!-- @mouseover="
                        setHoveredItem({
                            index,
                            type: 'insight',
                            content: insight,
                        })
                    "
                    @mouseout="clearHoveredItem()"
                    class="insight-item"
                    :data-state="[isHovered('insight', index)]"
                    v-html="insight" -->
                <!-- <oButton :circle="true" color="white" @clicked="copyInsight(insight.content)" style="position: absolute;right: 0;"">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9 0H3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h1v1a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3h-1V3a3 3 0 0 0-3-3Zm1 6V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V6Zm-4 8h3a3 3 0 0 0 3-3V6h1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-1Z"
                            fill="#006DFF"
                        />
                    </svg>
                </oButton> -->
            </ul>
        </div>
        <div v-else class="insight-body">
            <ul>
                <li
                    v-for="(advice, index) in parsedContent.generic"
                    :key="index"
                    class="insight-item"
                    v-html="advice"
                ></li>
            </ul>
        </div>
    </InsightCard>
</template>

<script lang="ts">
import { computed, ref, PropType, onMounted } from 'vue'
import InsightCard from '@/components/Reports/InsightCard.vue'
import { Tabs } from '@opteo/components-next'

import { Reports } from '@opteo/types'

type InsightTabs = 'insight' | 'generic'

export default {
    name: 'ReportInsights',
    components: { InsightCard, Tabs },
    props: {
        content: {
            required: false,
            type: Object as PropType<Reports.Core.InsightSentencesByType>,
        },
        slides: {
            required: true,
            type: Array as PropType<Reports.Core.ReportSlide[]>,
        },
    },
    setup(props) {
        const activeTab = ref<InsightTabs>('insight')
        const hasInsights = computed(() => {
            return (
                props.content &&
                ((props.content.insight && props.content.insight.length > 0) ||
                    (props.content.generic && props.content.generic.length > 0))
            )
        })

        const slideTypeHidden = computed(() => {
            return props.slides.every(slide => {
                return !slide.active || slide.invalid
            })
        })

        /*
            TODO: instead of stripping these characters, remove them from the backend in insights.ts
        */
        const parsedContent = computed(() => {
            const liStripped = props.content?.insight.map(text =>
                // replace "<li>1. " with "<li>" (to remove numbers)
                // replace "<li/>" with "</li>" (typo in backend)
                text.replaceAll(/<li>.\. /g, '<li>').replaceAll('<li/>', '</li>')
            )

            return {
                ...props.content,
                insight: liStripped,
            }
        })

        // This will eventually form the copy functionality documented on the website
        // https://opteo.com/features/reports/google-ads-reports
        // const hoveredItem = ref()
        // const setHoveredItem = item => (hoveredItem.value = item)
        // const clearHoveredItem = item => (hoveredItem.value = null)
        // const isHovered = (type: InsightTabs, index: number) =>
        //     hoveredItem.value && type === 'insight' && hoveredItem.value.index === index
        //         ? 'hovered'
        //         : false
        // const copyInsight = (insight) => {
        //     // copy insight
        //     // insert it into the editor somehow
        // }

        onMounted(() => {
            if (props.content?.insight && props.content.insight.length === 0) {
                activeTab.value = 'generic'
            }
        })

        const setActiveTab = (tab: InsightTabs) => {
            if (props.content && props.content[tab].length === 0) {
                return
            }
            activeTab.value = tab
        }

        return {
            setActiveTab,
            activeTab,
            hasInsights,
            slideTypeHidden,
            parsedContent,
            // hoveredItem,
            // setHoveredItem,
            // clearHoveredItem,
            // isHovered,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.insights-container {
    position: sticky;
    top: 32px;
}
.insights-container .not-active {
    color: #212125 !important;
    border-bottom: 2px solid transparent !important;
}
.insights-container .tab-disabled {
    color: #c6c6cc !important;
}
.insights-container .navigation-item {
    border-bottom: 2px solid;
    padding-bottom: 22px;
    font-size: 13px;
}
.insight-body {
    width: 100%;
    @include mt-20;
    font-size: 13px;
    line-height: 1.5;
}
.insight-item:not(:last-child) {
    margin-bottom: 1rem;
}

// below is taken from Ordered List in components-next
// when we have time it would be nice to create some kind of serializer
// to use components with the insights body
:deep(ol) {
    counter-reset: ol-list-counter;
    list-style: none;
    padding-top: 0.75rem;
    // set space on left for counter
    padding-left: 2.25rem;
}
li.insight-item :deep(ol li) {
    padding-top: 2px;
    margin-bottom: 0.5rem;
    counter-increment: ol-list-counter;
    position: relative;
}
li.insight-item :deep(ol li::before) {
    content: counter(ol-list-counter);
    position: absolute;
    top: 0;
    --circumference: 22px;
    // set padding between counter and text in space
    // created on ol with negative integer
    left: calc(-1 * var(--circumference) - 0.75rem);
    line-height: var(--circumference);
    width: var(--circumference);
    height: var(--circumference);
    font-size: 0.6875rem;
    color: $opteo-black;
    background: $opteo-white;
    border-radius: 100%;
    box-shadow: $opteo-shadow;
    text-align: center;
}
</style>
