<template>
    <div class="linked-accounts-team-members" @click="handleModalOpened">
        <div v-if="!isLinked">
            <div class="linked-accounts-team-avatars">
                <div
                    v-for="i in 3"
                    :style="{
                        zIndex: 3 - i,
                    }"
                    class="profile-image-item"
                >
                    <div class="unlinked-avatar" />
                </div>
            </div>
        </div>
        <div v-else class="linked-accounts-team-avatars">
            <div
                v-for="(user, index) in activeUsers.slice(0, 3)"
                :key="user.name"
                class="profile-image-item"
                :style="{
                    zIndex: hoveredProfile === user.name ? 10 : 0 - index,
                }"
                @mouseenter="setHoveredProfile(user.name)"
                @mouseleave="setHoveredProfile()"
            >
                <Tooltip :content="user.name" :delay="[400, 0]">
                    <ProfileImage
                        :width="28"
                        :height="28"
                        :isAdmin="user.isAdmin"
                        :image-url="user.profileUrl"
                        :avatar-url="getAvatarUrl(user.userId)"
                        :hide-role="true"
                    />
                </Tooltip>
            </div>
        </div>

        <oButton
            size="x-small"
            color="white"
            :circle="true"
            style="margin-left: 0.75rem"
            @clicked="handleModalOpened"
        >
            <template #icon>
                <TableEdit />
            </template>
        </oButton>
    </div>

    <Modal
        title="Team Members"
        width="640px"
        v-model="teamModalOpened"
        :noPadding="true"
        container-class="modal-z-max"
    >
        <template #title>
            <Avatar :color="account.color" :width="36" show-account-type>
                {{ account.initials }}
            </Avatar>
            <Spacer width="1.25rem" />
            <Text as="h3" weight="600" style="line-height: 2.625rem" class="title">
                Team Members
            </Text>
        </template>
        <template #content>
            <div
                role="table"
                aria-label="Manage Team Members"
                aria-describedby="team-members-table-description"
                :aria-rowcount="account.teamMembers.length"
            >
                <div id="team-members-table-description">
                    Manage team member access for {{ account.name }}
                </div>
                <div class="team-members-table-header" role="rowgroup">
                    <div role="row">
                        <Text
                            as="span"
                            size="f-8"
                            weight="500"
                            role="columnheader"
                            aria-sort="none"
                        >
                            Team Member
                        </Text>
                        <Spacer width="1.25rem" />
                        <div class="team-members-search">
                            <SmallSearchIcon class="search-icon" />
                            <oInput
                                v-model="searchedUser"
                                class="team-members-search-input"
                                name="searchTeamMembers"
                            />
                        </div>
                    </div>
                </div>
                <perfect-scrollbar role="rowgroup">
                    <div
                        v-if="filteredTeamMembers.length > 0"
                        v-for="(user, index) in filteredTeamMembers"
                        :aria-rowindex="index"
                        :class="[
                            filteredTeamMembers.length === index + 1 ? 'last' : '',
                            'team-members-table-item',
                        ]"
                        role="row"
                    >
                        <div role="cell" @click="toggleUser(user.userId)">
                            <Toggle v-model="user.active" viewOnly size="large" />
                            <Spacer width="1.5rem" />
                            <ProfileImage
                                :width="36"
                                :height="36"
                                :isAdmin="user.isAdmin"
                                :image-url="user.profileUrl ?? undefined"
                                :avatar-url="getAvatarUrl(user.userId)"
                                :hide-role="true"
                            />
                            <Spacer width="1rem" />
                            <div>
                                <Text size="f-8" weight="500">{{ user.name }}</Text>
                                <Text color="gray" size="f-9">{{ user.email }}</Text>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="searchedUser.length && filteredTeamMembers.length === 0">
                        <div role="cell">
                            <div class="team-members-empty-state">
                                <Text as="h2" size="f-2">
                                    {{ searchedUser }}
                                </Text>

                                <Text size="f-8" align="center"
                                    >No team members match search query.</Text
                                >
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div role="cell">
                            <div class="team-members-empty-state">
                                <Text size="f-8" align="center">No team members.</Text>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </template>
        <template #buttons>
            <oButton color="white" @clicked="teamModalOpened = false">Cancel</oButton>
            <Spacer width="0.5rem" />
            <oButton
                v-if="isLinked"
                ref="updateTeamSettingsButton"
                @clicked="updateTeamSettings()"
                :icon-before="true"
                :loading="updatingTeam"
            >
                <template #icon>
                    <UsersIcon />
                </template>
                Update Team Members
            </oButton>
            <Tooltip
                :content="
                    linkingIsOverLimit ? 'Linking this account would exceed your plan limit.' : ''
                "
            >
                <oButton
                    v-if="!isLinked"
                    ref="updateTeamAndLinkButton"
                    @clicked="updateTeamSettings()"
                    :icon-before="true"
                    :loading="updatingTeam"
                    :disabled="linkingIsOverLimit"
                >
                    <template #icon>
                        <LinkIcon />
                    </template>
                    Confirm and Link Account
                </oButton>
            </Tooltip>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { Team, User } from '@opteo/types'
import {
    Avatar,
    Modal,
    oButton,
    Spacer,
    Tooltip,
    ProfileImage,
    Text,
    TableEdit,
    Checkbox,
    oInput,
    showSnackbar,
    Toggle,
} from '@opteo/components-next'

import { useLinkedAccounts, OpteoButton } from '@/composition/linkedaccounts/useLinkedAccounts'

import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import useMediaQuery from '@/composition/global/useMediaQuery'
import UsersIcon from '@/components/linkedAccounts/icons/UsersIcon.vue'
import LinkIcon from '@/components/linkedAccounts/icons/LinkIcon.vue'
import SmallSearchIcon from '@/components/linkedAccounts/icons/SmallSearchIcon.vue'
import { delay } from '@opteo/promise'
import cloneDeep from 'lodash-es/cloneDeep'
import { Endpoint, authRequest } from '@/composition/api/useAPI'

const props = defineProps<{ account: Team.Account; isLinked: boolean }>()

const updatedTeamMembers = ref(cloneDeep(props.account.teamMembers))

const teamModalOpened = ref(false)

const {
    accountsCountRemaining,
    accounts30dSpendRemaining,
    mutateAllDomains,
    flashButton,
    clearSelectedAccounts,
    planLimitWarning,
    searchedUnlinkedAccounts,
    userId,
    linkingAccounts,
} = useLinkedAccounts()

const { getAvatarUrl } = useBoringAvatar()

const activeUsers = computed(() => props.account.teamMembers.filter(u => u.active))

const allUsersActive = computed(() => updatedTeamMembers.value.every(user => user.active))

function handleModalOpened() {
    if (linkingIsOverLimit.value) {
        planLimitWarning.value = true
        return
    }
    updatedTeamMembers.value = cloneDeep(props.account.teamMembers)
    clearSelectedAccounts()
    teamModalOpened.value = true

    if (linkingAccounts.value) {
        const user = updatedTeamMembers.value.find(user => user.userId === userId.value)
        if (user && !user.active) {
            user.active = true
        }
    }
}

// Toggling Team Members
function toggleAllUsers() {
    const active = !allUsersActive.value
    updatedTeamMembers.value.forEach(user => (user.active = active))
}

function toggleUser(userId: User.ID) {
    const user = updatedTeamMembers.value.find(user => user.userId === userId)

    if (!user) return

    user.active = !user.active
}

// Updating Team Members Logic
const updateTeamSettingsButton = ref<OpteoButton>()
const updateTeamAndLinkButton = ref<OpteoButton>()

const linkingIsOverLimit = computed(
    () =>
        !props.isLinked &&
        (accountsCountRemaining.value - 1 < 0 ||
            accounts30dSpendRemaining.value - props.account.spend30d < 0)
)

// Updates team members for a specific account
const updatingTeam = ref(false)

async function updateTeamSettings() {
    updatingTeam.value = true
    const usersToLink = updatedTeamMembers.value
        .filter(
            user =>
                user.active &&
                !props.account.teamMembers.find(u => u.userId === user.userId)?.active
        )
        .map(user => user.userId)

    const usersToUnlink = updatedTeamMembers.value
        .filter(
            user =>
                !user.active &&
                props.account.teamMembers.find(u => u.userId === user.userId)?.active
        )
        .map(user => user.userId)

    if (!usersToLink.length && !usersToUnlink.length) {
        teamModalOpened.value = false
        updatingTeam.value = false
        return
    }

    const button = props.isLinked ? updateTeamSettingsButton : updateTeamAndLinkButton

    try {
        if (usersToUnlink.length) {
            await authRequest(Endpoint.UpdateLinkedAccountStatus, {
                body: {
                    userIds: usersToUnlink,
                    accountIds: [props.account.accountId],
                    newStatus: false,
                },
            })
        }

        if (usersToLink.length) {
            await authRequest(Endpoint.UpdateLinkedAccountStatus, {
                body: {
                    userIds: usersToLink,
                    accountIds: [props.account.accountId],
                    newStatus: true,
                },
            })
        }

        await mutateAllDomains()

        updatingTeam.value = false
        flashButton(button, true)

        await delay(1000)
        teamModalOpened.value = false

        showSnackbar({
            message: props.isLinked
                ? `Team members updated successfully`
                : `Account linked and team members updated successfully`,
            timeout: 4000,
            actionHandler: () => {},
        })

        searchedUnlinkedAccounts.value = ''
    } catch {
        updatingTeam.value = false
        flashButton(button, false)

        showSnackbar({
            message: `Something went wrong updating team members`,
            timeout: 4000,
            actionText: 'Contact Support',
            actionHandler: () => {},
        })
    }
}

// Hovering Team Members Logic
const hoveredProfile = ref()

function setHoveredProfile(name?: string) {
    hoveredProfile.value = name
}

// Search Functionality
const searchedUser = ref('')

const filteredTeamMembers = computed(() => {
    if (!searchedUser.value) return updatedTeamMembers.value

    const searchTermLower = searchedUser.value.toLowerCase()
    const filtered = updatedTeamMembers.value.filter(user =>
        user.name.toLowerCase().includes(searchTermLower)
    )

    return filtered
})

watch(teamModalOpened, open => {
    if (!open && searchedUser.value.length > 0) {
        searchedUser.value = ''
    }
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// styles for main table role
.linked-accounts-team-members {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.unlinked-avatar {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: #dddde4;
}

// styles for modal
.team-members-table-header {
    @include opteo-foreground;
    @include bg-opteo-white;
    border-bottom: 1px solid;
    @include opteo-border;
}
.team-members-table-header div[role='row'] {
    padding: 0.875rem 1.75rem;
}

#team-members-table-description {
    display: none;
}
div[role='row'],
div[role='cell'] {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
div[role='cell'] {
    padding: 1.375rem 1.75rem;
    @include flex;
    @include items-center;
}
div[role='rowgroup'] {
    max-height: 21.1875rem;
}
div[role='cell'] p {
    line-height: 1.25rem;
}
.team-members-table-item:not(.last) div[role='cell'] {
    border-bottom: 1px solid;
    @include opteo-border;
}

.modal-z-max {
    z-index: 99999999999999999999999;
}
.team-members-search {
    max-width: 12.5rem;
    @include relative;
}
.team-members-search .search-icon {
    @include absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.875rem;
}
:deep(.team-members-search-input input) {
    padding: 12px 14px 12px 38px;
}

.team-members-empty-state {
    @include flex-center;
    flex-direction: column;
    gap: 1.125rem;
    width: 100%;
    padding: 2.75rem 0;
}
</style>
