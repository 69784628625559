<template>
    <svg
        width="40"
        height="40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="display: block"
    >
        <circle cx="20" cy="20" r="20" fill="url(#opwvmipcpow)" />
        <path
            d="M18.94 10.0001c-2.2175 0-4.1876 1.4087-4.8743 3.4879-1.4256.2899-2.656 1.1707-3.3776 2.4179-1.11215 1.8963-.8582 4.2804.6317 5.9058-.46 1.3615-.3021 2.8513.4325 4.0841 1.1054 1.903 3.3294 2.8784 5.5058 2.4245.964 1.0734 2.3497 1.6854 3.8033 1.6797 2.2174 0 4.1876-1.4087 4.8743-3.488 1.4279-.2913 2.6568-1.1718 3.3707-2.4178 1.119-1.8963.865-4.2804-.6247-5.9058v-.0067c.4598-1.3615.3021-2.8523-.4325-4.0908-1.1056-1.8964-3.3299-2.872-5.4992-2.4181-.9684-1.0705-2.3557-1.67961-3.81-1.6727Zm0 1.3004-.0068.0068c.8924 0 1.7506.3047 2.4371.867-.0274.0135-.0825.0473-.1235.0677l-4.0366 2.296c-.206.1151-.3296.3318-.3296.569v5.391l-1.7368-.9887v-4.4566c-.0008-2.0699 1.6981-3.7492 3.7962-3.7524v.0002Zm4.8626 1.5695c1.3615-.0025 2.6204.7139 3.3 1.8779.4393.7586.6039 1.6459.4529 2.506-.0275-.0204-.0822-.0473-.1168-.0677l-4.0365-2.3029c-.2066-.1152-.4592-.1152-.6657 0l-4.7301 2.6955v-1.9777l3.9061-2.2283c.5743-.3285 1.2262-.502 1.8901-.503v.0002Zm-9.9838 2.0471v4.7342c0 .2371.1235.4471.3296.569l4.723 2.6888-1.7439.9955-3.8994-2.2216c-1.8144-1.0374-2.4348-3.3283-1.3865-5.1203.4441-.7595 1.1436-1.3417 1.9772-1.6458v.0002Zm9.048 1.1716 3.9061 2.2216c1.8194 1.0363 2.437 3.3255 1.3866 5.1203l.0068.0067c-.4461.7586-1.1465 1.3411-1.9772 1.6392V20.342c0-.2371-.1235-.4538-.3295-.5688l-4.7299-2.6957 1.7371-.9888Zm-2.8697 1.6324 1.9911 1.1378v2.2688l-1.9911 1.1378-1.9907-1.1378v-2.2688l1.9907-1.1378Zm3.1305 1.7882 1.7368.9887v4.4498c0 2.0725-1.7025 3.7522-3.7962 3.7522v-.0067c-.8857 0-1.7507-.3048-2.4304-.8668.0275-.0135.0893-.0476.1236-.068l4.0365-2.2959c.2061-.1152.3365-.3319.3295-.569l.0002-5.3843Zm-1.1329 2.9122v1.9775l-3.9061 2.2215c-1.8193 1.0295-4.1397.42-5.1901-1.3681h.0069c-.4462-.7519-.6043-1.6459-.4532-2.5059.0274.0203.0824.0472.1167.0676l4.0366 2.3029c.2065.1153.4592.1153.6657 0l4.7235-2.6955Z"
            fill="#fff"
        />
        <defs>
            <linearGradient
                id="opwvmipcpow"
                x1="33"
                y1="5"
                x2="5"
                y2="33"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#32B6FF" />
                <stop offset=".645833" stop-color="#006DFF" />
                <stop offset="1" stop-color="#0035EC" />
            </linearGradient>
        </defs>
    </svg>
</template>
