<template>
    <div class="w-100">
        <!-- Headline -->
        <Text as="h3" size="f-4" weight="600"> Related Search Terms </Text>
        <Spacer height="1.75rem" />
        <p class="f6 lh-copy mb4 pb1">
            Searches that triggered your ads via <b>{{ keyword }}</b
            >:
        </p>
        <!-- Search Terms Loop -->
        <div
            v-for="(item, index) in items"
            :key="'search-term-' + index"
            class="search-term-container flex items-center mb3"
            style="padding: 1.5rem"
        >
            <div class="desktop-layout">
                <GoogleLogo style="width: 4.4375rem; height: auto" />
                <div class="br-pill ba items-center justify-between search-input">
                    <span class="f6 search-query">
                        {{ item }}
                    </span>
                    <GoogleSearchIcon style="flex-shrink: 0" />
                </div>
            </div>

            <!-- MOBILE LAYOUT -->
            <div class="mobile-layout br-pill ba items-center justify-between search-input">
                <div class="flex items-center search-query">
                    <GoogleGIcon style="flex-shrink: 0; margin-right: 8px" />
                    <span class="f6 search-query">
                        {{ item }}
                    </span>
                </div>
                <GoogleMicIcon style="flex-shrink: 0" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// components-next
import {
    GoogleLogo,
    GoogleSearchIcon,
    Text,
    Spacer,
    GoogleGIcon,
    GoogleMicIcon,
} from '@opteo/components-next'

export default {
    components: { GoogleLogo, GoogleSearchIcon, Text, Spacer, GoogleGIcon, GoogleMicIcon },
    props: {
        items: {
            type: Array,
            default: () => ['default search term one', 'default search term two'],
            required: true,
        },
        keyword: {
            type: String,
            default: 'default keyword',
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.search-term-container {
    @include container;
}
.search-input {
    padding: 0.6875rem 1.25rem 0.6875rem;
    border-color: rgb(242, 242, 243);
    margin-left: 1.5rem;
    max-width: calc(100% - 1.5rem - 4.4375rem);
    @include flex;
    @include w-100;
}
.search-query {
    // transform: translateY(-0.0625rem);
    font-family: 'Roboto', arial, helvetica, sans-serif;
    font-feature-settings:
        'ss01' off,
        'ss02' off,
        'ss04' off,
        'ss05' off,
        'ss08' off;
    letter-spacing: 0;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
}

.desktop-layout {
    @include flex;
    @include items-center;
    @include w-100;
}

.mobile-layout {
    display: none;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
    .desktop-layout {
        display: none;
    }
    .mobile-layout {
        @include flex;
        @include w-100;
        @include items-center;
    }
    .search-input {
        padding: 12px;
        border-color: rgb(242, 242, 243);
        margin-left: unset;
        max-width: unset;
        @include flex;
        @include w-100;
    }
}
</style>
