<template>
    <Spacer height="2.125rem" />
    <Text as="h1" weight="600" style="font-size: 2.25rem">Performance Comparison</Text>
    <Spacer height="3.125rem" />
    <CampaignGroupSection>
        <template v-slot:header-left>
            <Text as="h4" weight="600">Campaign Groups</Text>
        </template>
        <template v-slot:content>
            <oTable
                :headers="comparisonTableHeaders"
                :items="comparisonTableItems"
                :border-radius="20"
                ref="comparisonTable"
                order-by="campaignGroupCost"
                order="DESC"
                fixed-layout
                no-shadow
            >
                <template v-slot:header.campaignGroupName>
                    <div class="ml-24">Campaign Group</div>
                </template>
                <template v-slot:column.campaignGroupName="cellData">
                    <div
                        class="campaign-name-column"
                        v-if="cellData.row.campaignGroupName === 'Total'"
                    >
                        <Text weight="500" size="f-8">Total</Text>
                    </div>

                    <Tooltip
                        v-else
                        placement="top-start"
                        :offset="[44, 12]"
                        :content="cellData.length > 46 ? cellData : ''"
                    >
                        <div class="campaign-name-column">
                            {{ cellData.value }}
                        </div>
                    </Tooltip>
                </template>
                <template v-slot:column.campaignGroupCost="cellData">
                    <Text weight="500" size="f-8" v-if="cellData.row.campaignGroupName === 'Total'"
                        ><Money :value="totalCost"
                    /></Text>
                    <Money v-else :value="cellData.value" />
                </template>
                <template v-slot:column.campaignGroupClicks="cellData">
                    <Text weight="500" size="f-8" v-if="cellData.row.campaignGroupName === 'Total'"
                        ><Number :value="totalClicks"
                    /></Text>
                    <Number v-else :value="cellData.value" />
                </template>
                <template v-slot:column.campaignGroupCpc="cellData">
                    <Text weight="500" size="f-8" v-if="cellData.row.campaignGroupName === 'Total'"
                        ><Money :value="totalCpc"
                    /></Text>
                    <Money v-else :value="cellData.value" />
                </template>
                <template v-slot:column.campaignGroupConv="cellData">
                    <Text weight="500" size="f-8" v-if="cellData.row.campaignGroupName === 'Total'"
                        ><Number :value="totalConversions"
                    /></Text>
                    <Number v-else :value="cellData.value" />
                </template>
                <template v-slot:column.campaignGroupConvVal="cellData">
                    <Text weight="500" size="f-8" v-if="cellData.row.campaignGroupName === 'Total'"
                        ><Money :value="totalConversionsValue"
                    /></Text>
                    <Money v-else :value="cellData.value" />
                </template>
                <template v-slot:column.campaignGroupCpa="cellData">
                    <Text weight="500" size="f-8" v-if="cellData.row.campaignGroupName === 'Total'"
                        ><Money :value="totalCpa"
                    /></Text>
                    <Money v-else :value="cellData.value" />
                </template>
                <template v-slot:column.campaignGroupRoas="cellData">
                    <Text weight="500" size="f-8" v-if="cellData.row.campaignGroupName === 'Total'"
                        ><Roas :value="totalRoas"
                    /></Text>
                    <Roas v-else :value="cellData.value" />
                </template>
            </oTable>
        </template>
    </CampaignGroupSection>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import CampaignGroupSection from './CampaignGroupSection.vue'
import { useCampaignGroups } from '@/composition/campaignGroups/useCampaignGroups'

import {
    Spacer,
    Number,
    Text,
    ColorTag,
    oTable,
    Percent,
    Roas,
    Tooltip,
} from '@opteo/components-next'
import Money from '@/components/global/oMoney.vue'
import sumBy from 'lodash-es/sumBy'

export default defineComponent({
    name: 'CampaignGroupComparison',
    components: {
        CampaignGroupSection,
        Spacer,
        Money,
        Number,
        Text,
        ColorTag,
        oTable,
        Percent,
        Roas,
        Tooltip,
    },
    setup() {
        const { isUsingCpa, isUsingRoas, campaignGroups } = useCampaignGroups()

        const totalCost = computed(() => sumBy(campaignGroups.value, 'cost'))
        const totalClicks = computed(() => sumBy(campaignGroups.value, 'clicks'))
        const totalCpc = computed(() =>
            totalCost.value > 0 ? totalCost.value / totalClicks.value : 0
        )
        const totalConversions = computed(() => sumBy(campaignGroups.value, 'conversions'))
        const totalConversionsValue = computed(() =>
            sumBy(campaignGroups.value, 'conversionsValue')
        )
        const totalCpa = computed(() =>
            totalCost.value > 0 ? totalCost.value / totalConversions.value : 0
        )
        const totalRoas = computed(() =>
            totalConversionsValue.value > 0 ? totalConversionsValue.value / totalCost.value : 0
        )

        const comparisonTable = ref()

        const comparisonTableItems = computed(() => {
            const groups =
                campaignGroups.value?.map(group => {
                    return {
                        campaignGroupName: group.name,
                        campaignGroupCost: group.cost,
                        campaignGroupClicks: group.clicks,
                        campaignGroupCpc: group.cost > 0 ? group.cost / group.clicks : 0,
                        campaignGroupConv: group.conversions,
                        campaignGroupConvVal: group.conversionsValue,
                        campaignGroupCpa: group.cost > 0 ? group.cost / group.conversions : 0,
                        campaignGroupRoas:
                            group.conversionsValue > 0 ? group.conversionsValue / group.cost : 0,
                    }
                }) ?? []

            // This is to insure the total row is always at the bottom
            const sortValue = comparisonTable.value?.sortOrder === 'ASC' ? Infinity : 0

            const total = {
                campaignGroupName: 'Total',
                campaignGroupCost: sortValue,
                campaignGroupClicks: sortValue,
                campaignGroupCpc: sortValue,
                campaignGroupConv: sortValue,
                campaignGroupConvVal: sortValue,
                campaignGroupCpa: sortValue,
                campaignGroupRoas: sortValue,
            }

            return [...groups, total]
        })

        const comparisonTableHeaders = computed(() => [
            {
                key: 'campaignGroupName',
                text: 'Campaign Group',
                noPadding: true,
                width: 334,
            },
            {
                key: 'campaignGroupCost',
                text: 'Cost',
                sortable: true,
            },
            {
                key: 'campaignGroupClicks',
                text: 'Clicks',
                sortable: true,
            },
            {
                key: 'campaignGroupCpc',
                text: 'CPC',
                sortable: true,
            },
            {
                key: isUsingCpa.value ? 'campaignGroupConv' : 'campaignGroupConvVal',
                text: isUsingCpa.value ? 'Conv.' : 'Value',
                sortable: true,
            },
            {
                key: isUsingCpa.value ? 'campaignGroupCpa' : 'campaignGroupRoas',
                text: isUsingCpa.value ? 'CPA' : 'ROAS',
                sortable: true,
            },
        ])

        return {
            isUsingCpa,
            isUsingRoas,
            comparisonTableHeaders,
            comparisonTableItems,
            totalCost,
            totalClicks,
            totalConversions,
            totalConversionsValue,
            totalCpa,
            totalCpc,
            totalRoas,
            comparisonTable,
        }
    },
})
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaign-name-column {
    @include pl-24;
    overflow: hidden;
}
.campaign-name-column::after {
    content: '';
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 62.5%);
    position: absolute;
    top: 0;
    right: -1.5rem;
    height: 100%;
    width: 6rem;
    z-index: 2;
}

:deep(.o-table-wrapper),
:deep(.o-table-container) {
    @include br-16;
}

.date-stamp {
    transform: translateY(-16px);
}
</style>
