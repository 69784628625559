<template>
    <svg
        width="680"
        height="394"
        viewBox="0 0 680 394"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="680" height="394" fill="white" />
        <mask
            id="mask0_1642_7839"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="680"
            height="394"
        >
            <rect width="680" height="394" fill="white" />
        </mask>
        <g mask="url(#mask0_1642_7839)">
            <g filter="url(#filter0_dd_1642_7839)">
                <rect x="72" y="24" width="536" height="495" rx="12" fill="white" />
            </g>
            <path
                d="M94.576 49.53C94.576 47.29 96.144 46.226 97.67 46.226C99.21 46.226 100.778 47.29 100.778 49.53C100.778 51.77 99.21 52.834 97.67 52.834C96.144 52.834 94.576 51.77 94.576 49.53ZM92.574 49.544C92.574 52.736 94.982 54.71 97.67 54.71C100.372 54.71 102.78 52.736 102.78 49.544C102.78 46.338 100.372 44.364 97.67 44.364C94.982 44.364 92.574 46.338 92.574 49.544Z"
                fill="black"
            />
            <path
                d="M105.812 57.16V53.828C106.148 54.29 106.848 54.668 107.8 54.668C109.746 54.668 111.048 53.128 111.048 51.042C111.048 48.998 109.886 47.458 107.87 47.458C106.834 47.458 106.064 47.92 105.756 48.452V47.612H103.95V57.16H105.812ZM109.214 51.056C109.214 52.288 108.458 53.002 107.506 53.002C106.554 53.002 105.784 52.274 105.784 51.056C105.784 49.838 106.554 49.124 107.506 49.124C108.458 49.124 109.214 49.838 109.214 51.056Z"
                fill="black"
            />
            <path
                d="M113.669 50.272C113.711 49.642 114.243 48.914 115.209 48.914C116.273 48.914 116.721 49.586 116.749 50.272H113.669ZM116.931 52.064C116.707 52.68 116.231 53.114 115.363 53.114C114.439 53.114 113.669 52.456 113.627 51.546H118.555C118.555 51.518 118.583 51.238 118.583 50.972C118.583 48.76 117.309 47.402 115.181 47.402C113.417 47.402 111.793 48.83 111.793 51.028C111.793 53.352 113.459 54.71 115.349 54.71C117.043 54.71 118.135 53.716 118.485 52.526L116.931 52.064Z"
                fill="black"
            />
            <path
                d="M121.6 50.538C121.6 49.74 122.076 49.11 122.888 49.11C123.784 49.11 124.162 49.712 124.162 50.482V54.5H126.024V50.16C126.024 48.648 125.24 47.43 123.532 47.43C122.79 47.43 121.964 47.752 121.544 48.466V47.612H119.738V54.5H121.6V50.538Z"
                fill="black"
            />
            <path
                d="M135.591 54.5H137.747L135.619 50.44C136.921 50.062 137.733 49.012 137.733 47.626C137.733 45.904 136.501 44.574 134.569 44.574H130.691V54.5H132.637V50.678H133.645L135.591 54.5ZM132.637 49.012V46.254H134.205C135.185 46.254 135.759 46.8 135.759 47.64C135.759 48.452 135.185 49.012 134.205 49.012H132.637Z"
                fill="black"
            />
            <path
                d="M145.852 46.94C145.628 45.82 144.704 44.364 142.296 44.364C140.42 44.364 138.838 45.708 138.838 47.472C138.838 48.97 139.86 49.992 141.442 50.314L142.828 50.594C143.598 50.748 144.032 51.196 144.032 51.77C144.032 52.47 143.458 52.988 142.422 52.988C141.022 52.988 140.336 52.106 140.252 51.126L138.46 51.602C138.614 53.016 139.776 54.71 142.408 54.71C144.718 54.71 145.992 53.17 145.992 51.644C145.992 50.244 145.026 49.068 143.22 48.718L141.834 48.452C141.106 48.312 140.756 47.878 140.756 47.332C140.756 46.674 141.372 46.072 142.31 46.072C143.57 46.072 144.018 46.926 144.116 47.472L145.852 46.94Z"
                fill="black"
            />
            <path
                d="M153.828 54.5H155.928L152.148 44.574H149.908L146.1 54.5H148.144L148.97 52.232H153.002L153.828 54.5ZM150.986 46.716L152.358 50.468H149.614L150.986 46.716Z"
                fill="black"
            />
            <g filter="url(#filter1_d_1642_7839)">
                <rect x="516" y="38" width="72" height="22" rx="11" fill="#006DFF" />
            </g>
            <g filter="url(#filter2_dd_1642_7839)">
                <rect x="120" y="74" width="440" height="218" rx="12" fill="white" />
            </g>
            <rect x="132" y="239" width="26" height="6" rx="3" fill="#ECECEF" />
            <rect x="290" y="239" width="16" height="6" rx="3" fill="#ECECEF" />
            <rect x="330" y="239" width="16" height="6" rx="3" fill="#ECECEF" />
            <rect x="370" y="239" width="16" height="6" rx="3" fill="#ECECEF" />
            <rect x="410" y="239" width="16" height="6" rx="3" fill="#ECECEF" />
            <rect x="450" y="239" width="16" height="6" rx="3" fill="#ECECEF" />
            <rect x="490" y="239" width="32" height="6" rx="3" fill="#ECECEF" />
            <line x1="120" y1="256.5" x2="560" y2="256.5" stroke="#F7F7F9" />
            <line x1="120" y1="227.5" x2="560" y2="227.5" stroke="#F7F7F9" />
            <rect x="290" y="272" width="22" height="6" rx="3" fill="#ECECEF" />
            <rect x="330" y="272" width="16" height="6" rx="3" fill="#ECECEF" />
            <rect x="370" y="272" width="12" height="6" rx="3" fill="#ECECEF" />
            <rect x="410" y="272" width="20" height="6" rx="3" fill="#ECECEF" />
            <rect x="450" y="272" width="26" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter3_dd_1642_7839)">
                <rect x="490" y="264" width="56" height="22" rx="5" fill="white" />
            </g>
            <path
                opacity="0.1"
                d="M506.499 275C506.499 275.591 506.382 276.176 506.156 276.722C505.93 277.268 505.598 277.764 505.18 278.182C504.762 278.6 504.266 278.931 503.72 279.157C503.174 279.384 502.589 279.5 501.998 279.5C501.407 279.5 500.822 279.384 500.276 279.157C499.73 278.931 499.234 278.6 498.816 278.182C498.398 277.764 498.067 277.268 497.841 276.722C497.615 276.176 497.499 275.591 497.499 275C497.499 274.409 497.616 273.824 497.842 273.278C498.068 272.732 498.4 272.236 498.818 271.818C499.236 271.4 499.732 271.069 500.278 270.843C500.824 270.616 501.409 270.5 502 270.5C502.591 270.5 503.176 270.616 503.722 270.843C504.268 271.069 504.764 271.4 505.182 271.818C505.599 272.236 505.931 272.732 506.157 273.278C506.383 273.824 506.499 274.409 506.499 275L506.499 275Z"
                stroke="#00A861"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M502 270.5C502.974 270.5 503.922 270.816 504.702 271.401C505.481 271.987 506.049 272.809 506.321 273.745C506.592 274.68 506.553 275.679 506.208 276.591C505.863 277.502 505.232 278.277 504.409 278.799C503.586 279.322 502.616 279.563 501.645 279.486C500.673 279.41 499.753 279.02 499.022 278.376C498.291 277.731 497.79 276.867 497.592 275.913C497.395 274.958 497.513 273.966 497.928 273.084"
                stroke="#00A861"
                stroke-linecap="round"
            />
            <rect x="513" y="272" width="26" height="6" rx="3" fill="#00A861" />
            <g filter="url(#filter4_dd_1642_7839)">
                <rect x="132" y="264" width="56" height="22" rx="5" fill="white" />
                <path
                    d="M175 273.75L177.5 276.25L180 273.75"
                    stroke="black"
                    stroke-opacity="0.16"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <rect x="139" y="272" width="26" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter5_dd_1642_7839)">
                <rect x="170" y="128" width="340" height="84" rx="10" fill="white" />
            </g>
            <rect opacity="0.1" x="190" y="160" width="288" height="8" rx="4" fill="#1A0DAB" />
            <rect x="190" y="146" width="105" height="6" rx="3" fill="#ECECEF" />
            <rect x="190" y="176" width="292" height="6" rx="3" fill="#ECECEF" />
            <rect x="190" y="188" width="280" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter6_dd_1642_7839)">
                <rect x="136" y="90" width="45" height="22" rx="5" fill="white" />
            </g>
            <rect x="144.5" y="97" width="6" height="8" rx="1.5" stroke="black" />
            <circle cx="147.5" cy="102.75" r="0.75" fill="black" />
            <rect x="169" y="103" width="1" height="2" fill="#006DFF" />
            <rect x="166.5" y="104.5" width="6" height="1" rx="0.5" fill="#006DFF" />
            <path
                d="M165.609 102.454L165.86 102.326L165.609 102.454C165.705 102.642 165.858 102.795 166.046 102.891C166.182 102.96 166.317 102.982 166.431 102.992C166.535 103 166.658 103 166.784 103L166.8 103H172.2L172.216 103C172.342 103 172.465 103 172.569 102.992C172.683 102.982 172.818 102.96 172.954 102.891C173.142 102.795 173.295 102.642 173.391 102.454C173.46 102.318 173.482 102.183 173.492 102.069C173.5 101.965 173.5 101.842 173.5 101.716L173.5 101.7V98.3L173.5 98.2841C173.5 98.1579 173.5 98.035 173.492 97.9309C173.482 97.8166 173.46 97.6817 173.391 97.546C173.295 97.3578 173.142 97.2049 172.954 97.109L172.73 97.5488L172.954 97.109C172.818 97.0398 172.683 97.0178 172.569 97.0085C172.465 97 172.342 97 172.216 97L172.2 97H166.8C166.795 97 166.789 97 166.784 97C166.658 97 166.535 97 166.431 97.0085C166.317 97.0178 166.182 97.0398 166.046 97.109C165.858 97.2049 165.705 97.3578 165.609 97.546C165.54 97.6817 165.518 97.8166 165.508 97.9309C165.5 98.035 165.5 98.1579 165.5 98.2841C165.5 98.2894 165.5 98.2947 165.5 98.3V101.7L165.5 101.716C165.5 101.842 165.5 101.965 165.508 102.069C165.518 102.183 165.54 102.318 165.609 102.454Z"
                stroke="#006DFF"
            />
            <rect opacity="0.7" x="158" y="90" width="1" height="22" fill="#F0F0F5" />
            <g filter="url(#filter7_dd_1642_7839)">
                <rect x="187" y="90" width="44.5" height="22" rx="5" fill="white" />
            </g>
            <path
                d="M199.5 98L196.5 101L199.5 104"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M219 98L222 101L219 104"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect opacity="0.7" x="209" y="90" width="1" height="22" fill="#F0F0F5" />
            <g filter="url(#filter8_dd_1642_7839)">
                <rect x="484" y="90" width="60" height="22" rx="11" fill="white" />
            </g>
            <g filter="url(#filter9_dd_1642_7839)">
                <rect x="120" y="308" width="440" height="218" rx="12" fill="white" />
            </g>
            <g filter="url(#filter10_dd_1642_7839)">
                <rect x="170" y="362" width="340" height="84" rx="10" fill="white" />
            </g>
            <rect x="190" y="380" width="105" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter11_dd_1642_7839)">
                <rect x="136" y="324" width="45" height="22" rx="5" fill="white" />
            </g>
            <rect x="144.5" y="331" width="6" height="8" rx="1.5" stroke="black" />
            <circle cx="147.5" cy="336.75" r="0.75" fill="black" />
            <rect x="169" y="337" width="1" height="2" fill="#006DFF" />
            <rect x="166.5" y="338.5" width="6" height="1" rx="0.5" fill="#006DFF" />
            <path
                d="M165.609 336.454L165.86 336.326L165.609 336.454C165.705 336.642 165.858 336.795 166.046 336.891C166.182 336.96 166.317 336.982 166.431 336.992C166.535 337 166.658 337 166.784 337L166.8 337H172.2L172.216 337C172.342 337 172.465 337 172.569 336.992C172.683 336.982 172.818 336.96 172.954 336.891C173.142 336.795 173.295 336.642 173.391 336.454C173.46 336.318 173.482 336.183 173.492 336.069C173.5 335.965 173.5 335.842 173.5 335.716L173.5 335.7V332.3L173.5 332.284C173.5 332.158 173.5 332.035 173.492 331.931C173.482 331.817 173.46 331.682 173.391 331.546C173.295 331.358 173.142 331.205 172.954 331.109L172.73 331.549L172.954 331.109C172.818 331.04 172.683 331.018 172.569 331.008C172.465 331 172.342 331 172.216 331L172.2 331H166.8C166.795 331 166.789 331 166.784 331C166.658 331 166.535 331 166.431 331.008C166.317 331.018 166.182 331.04 166.046 331.109C165.858 331.205 165.705 331.358 165.609 331.546C165.54 331.682 165.518 331.817 165.508 331.931C165.5 332.035 165.5 332.158 165.5 332.284C165.5 332.289 165.5 332.295 165.5 332.3V335.7L165.5 335.716C165.5 335.842 165.5 335.965 165.508 336.069C165.518 336.183 165.54 336.318 165.609 336.454Z"
                stroke="#006DFF"
            />
            <rect opacity="0.7" x="158" y="324" width="1" height="22" fill="#F0F0F5" />
            <g filter="url(#filter12_dd_1642_7839)">
                <rect x="187" y="324" width="44.5" height="22" rx="5" fill="white" />
            </g>
            <path
                d="M199.5 332L196.5 335L199.5 338"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M219 332L222 335L219 338"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect opacity="0.7" x="209" y="324" width="1" height="22" fill="#F0F0F5" />
            <g filter="url(#filter13_dd_1642_7839)">
                <rect x="484" y="324" width="60" height="22" rx="11" fill="white" />
            </g>
            <g filter="url(#filter14_dd_1642_7839)">
                <rect x="12" y="342" width="656" height="40" rx="8" fill="white" />
            </g>
            <circle cx="33" cy="362" r="9" fill="#006DFF" />
            <rect x="50" y="359" width="40" height="6" rx="3" fill="#006DFF" />
            <g filter="url(#filter15_dd_1642_7839)">
                <rect x="538" y="351" width="40" height="22" rx="11" fill="white" />
            </g>
            <g opacity="0.2">
                <g filter="url(#filter16_d_1642_7839)">
                    <rect x="584" y="351" width="72" height="22" rx="11" fill="#006DFF" />
                </g>
            </g>
            <g filter="url(#filter17_dd_1642_7839)">
                <rect x="102" y="351" width="112" height="22" rx="5" fill="white" />
            </g>
            <rect x="126" y="359" width="82" height="6" rx="3" fill="#ECECEF" />
            <path
                d="M114 367C116.761 367 119 364.761 119 362C119 359.239 116.761 357 114 357C111.239 357 109 359.239 109 362C109 364.761 111.239 367 114 367ZM114 368C110.686 368 108 365.314 108 362C108 358.686 110.686 356 114 356C117.314 356 120 358.686 120 362C120 365.314 117.314 368 114 368Z"
                fill="#006DFF"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M114.064 364.605C115.457 364.605 116.171 363.681 116.36 362.918L115.457 362.645C115.324 363.107 114.911 363.66 114.064 363.66C113.266 363.66 112.524 363.079 112.524 362.022C112.524 360.895 113.308 360.363 114.05 360.363C114.911 360.363 115.296 360.888 115.415 361.364L116.325 361.077C116.129 360.272 115.422 359.432 114.05 359.432C112.72 359.432 111.523 360.44 111.523 362.022C111.523 363.604 112.678 364.605 114.064 364.605Z"
                fill="#006DFF"
            />
            <g filter="url(#filter18_dd_1642_7839)">
                <rect x="220" y="351" width="112" height="22" rx="5" fill="white" />
            </g>
            <path
                d="M232 367C234.761 367 237 364.761 237 362C237 359.239 234.761 357 232 357C229.239 357 227 359.239 227 362C227 364.761 229.239 367 232 367ZM232 368C228.686 368 226 365.314 226 362C226 358.686 228.686 356 232 356C235.314 356 238 358.686 238 362C238 365.314 235.314 368 232 368Z"
                fill="#6E3AFF"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M233.434 364.5L233.021 363.366H231.005L230.592 364.5H229.57L231.474 359.537H232.594L234.484 364.5H233.434ZM232.013 360.608L231.327 362.484H232.699L232.013 360.608Z"
                fill="#6E3AFF"
            />
            <rect x="244" y="359" width="82" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter19_dd_1642_7839)">
                <rect x="338" y="351" width="22" height="22" rx="5" fill="white" />
            </g>
            <path
                d="M349 367C351.761 367 354 364.761 354 362C354 359.239 351.761 357 349 357C346.239 357 344 359.239 344 362C344 364.761 346.239 367 349 367ZM349 368C345.686 368 343 365.314 343 362C343 358.686 345.686 356 349 356C352.314 356 355 358.686 355 362C355 365.314 352.314 368 349 368Z"
                fill="#00A861"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M350.091 364.5H351.302L349.321 361.7L351.288 359.537H350.021L348.033 361.784V359.537H347.067V364.5H348.033V363.121L348.649 362.442L350.091 364.5Z"
                fill="#00A861"
            />
        </g>
        <defs>
            <filter
                id="filter0_dd_1642_7839"
                x="71"
                y="23"
                width="538"
                height="498"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_d_1642_7839"
                x="515"
                y="37.5"
                width="74"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_dd_1642_7839"
                x="119"
                y="73"
                width="442"
                height="221"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_dd_1642_7839"
                x="489"
                y="263"
                width="58"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter4_dd_1642_7839"
                x="131"
                y="263"
                width="58"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter5_dd_1642_7839"
                x="169"
                y="127"
                width="342"
                height="87"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter6_dd_1642_7839"
                x="135"
                y="89"
                width="47"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter7_dd_1642_7839"
                x="186"
                y="89"
                width="46.5"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter8_dd_1642_7839"
                x="483"
                y="89"
                width="62"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter9_dd_1642_7839"
                x="119"
                y="307"
                width="442"
                height="221"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter10_dd_1642_7839"
                x="169"
                y="361"
                width="342"
                height="87"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter11_dd_1642_7839"
                x="135"
                y="323"
                width="47"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter12_dd_1642_7839"
                x="186"
                y="323"
                width="46.5"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter13_dd_1642_7839"
                x="483"
                y="323"
                width="62"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter14_dd_1642_7839"
                x="4"
                y="338"
                width="672"
                height="56"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="4"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="6" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter15_dd_1642_7839"
                x="537"
                y="350"
                width="42"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter16_d_1642_7839"
                x="583"
                y="350.5"
                width="74"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter17_dd_1642_7839"
                x="101"
                y="350"
                width="114"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter18_dd_1642_7839"
                x="219"
                y="350"
                width="114"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
            <filter
                id="filter19_dd_1642_7839"
                x="337"
                y="350"
                width="24"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_7839"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_7839"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_7839"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_7839"
                    result="effect2_dropShadow_1642_7839"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_7839"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
</template>
