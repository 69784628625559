import { Improvement } from '@opteo/types'
import parseISO from 'date-fns/parseISO'
import sortBy from 'lodash-es/sortBy'

export type ImprovementSort = 'priority' | 'type' | 'created' | 'batch_only'

export function sortImprovements(
    improvements: Improvement.Object[],
    sortField: ImprovementSort
): Improvement.Object[] {
    return sortBy(improvements, imp => {
        if (sortField === 'priority') return -imp.priority
        if (sortField === 'type') return imp.static_title
        if (sortField === 'created') return -parseISO(imp.created as unknown as string).valueOf()
        if (sortField === 'batch_only') return imp.requires_adjust
    })
}
