<template>
    <FullScreenOverlay @close="closeReport">
        <router-view></router-view>
    </FullScreenOverlay>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { provideReport } from '@/composition/reports/provideReport'
import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'
export default defineComponent({
    components: { FullScreenOverlay },
    setup() {
        const { closeReport } = provideReport()
        return {
            closeReport,
        }
    },
})
</script>
