import groupBy from 'lodash-es/groupBy'
import parseISO from 'date-fns/parseISO'
import formatDate from 'date-fns/format'
import { showSnackbar } from '@opteo/components-next'
import cloneDeep from 'lodash-es/cloneDeep'
import { inject, provide, computed } from 'vue'

import { Endpoint, useAPI } from '../api/useAPI'
import { useImprovementPreferences } from './useImprovementPreferences'
import { useDismissedImprovements } from './useDismissedImprovements'
import { useDismissImprovement } from './useDismissImprovement'
import { ProvideKeys } from '@/composition/useProvide'
import { ImprovementSort, sortImprovements } from '@/lib/improvement'
import { useLocalStorage } from '@vueuse/core'
import { LS_IMPROVEMENTS_SORTBY } from '@/lib/cookies'
import { useDismissFeedbackModal } from './useDismissFeedbackModal'

import type { Account, Improvement } from '@opteo/types'
import type { DismissDuration, EnhancedImprovement } from './types'
import { useAccount } from '../account/useAccount'

export interface ImprovementGroup {
    label: string
    subLabel: string
    improvementsInGroup: EnhancedImprovement[]
}

export function provideActiveImprovements() {
    const { accountId } = useAccount()
    const { dismissImprovement } = useDismissImprovement()
    const { recActionPreferences } = useImprovementPreferences()
    const { refreshDismissedList } = useDismissedImprovements()

    const {
        data: improvementsRaw,
        loading: activeImpsLoading,
        error,
        mutate: mutateImprovementsList,
    } = useAPI<Improvement.Object[]>(Endpoint.ListImprovements, {
        body: () => ({ account_id: accountId?.value }),
        uniqueId: () => accountId?.value,
        waitFor: () => accountId.value,
        dedupingInterval: 0,
    })

    const loading = computed(() => activeImpsLoading.value || !recActionPreferences.value)

    /*
        Improvements list grouping & ordering
    */
    const localImprovementSort = useLocalStorage<{ [accountId: Account.ID]: ImprovementSort }>(
        LS_IMPROVEMENTS_SORTBY,
        {}
    )

    const improvementSort = computed<ImprovementSort>(
        () => localImprovementSort.value[accountId.value] ?? 'priority'
    )

    function updateImprovementSort(newSort: ImprovementSort) {
        if (improvementSort.value === newSort) return
        localImprovementSort.value[accountId.value] = newSort
    }

    const improvements = computed<EnhancedImprovement[]>(() => {
        if (!improvementsRaw.value || !recActionPreferences.value) {
            return []
        }

        const unsorted = improvementsRaw.value
            .map(imp => {
                const preferences = (recActionPreferences.value ?? []).find(pref =>
                    pref.rec_actions.includes(imp.rec_action)
                )
                return {
                    ...imp,
                    preferences,
                    static_title: preferences?.static_title ?? '',
                }
            })
            .filter(imp => imp.preferences?.selected)

        return sortImprovements(unsorted, improvementSort.value)
    })

    const improvementsGrouped = computed<ImprovementGroup[]>(() => {
        const impsGrouped = groupBy(improvements.value, imp => {
            if (improvementSort.value === 'priority') {
                const { priority } = imp
                if (priority > 1000) {
                    return 'High Priority'
                }
                if (priority > 500 && priority <= 1000) {
                    return 'Medium Priority'
                }
                if ((priority >= 0 && priority <= 500) || priority < 0 || !priority) {
                    return 'Low Priority'
                }
            }
            if (improvementSort.value === 'type') {
                return imp.static_title
            }
            if (improvementSort.value === 'created') {
                return formatDate(parseISO(imp.created as unknown as string), 'EEEE do MMMM')
            }
            if (improvementSort.value === 'batch_only') {
                return imp.requires_adjust ? 'Dismissable' : 'Batchable'
            }
        })

        return Object.keys(impsGrouped).map(label => {
            const impGroup = impsGrouped[label]
            if (impGroup.length > 1) {
                return {
                    label,
                    subLabel: `${impGroup.length} Improvements`,
                    improvementsInGroup: impGroup,
                }
            } else
                return {
                    label,
                    subLabel: `${impGroup.length} Improvement`,
                    improvementsInGroup: impGroup,
                }
        })
    })

    const removeImprovements = async (ids: number[]) => {
        if (ids.length === 0) {
            return
        }

        if (!improvementsRaw.value) {
            throw new Error('cannot mutate until improvementsRaw.value is set')
        }
        const newList = improvementsRaw.value.filter(row => !ids.includes(row.improvement_id))
        await mutateImprovementsList(() => newList)
        await mutateImprovementsList()
    }

    const { openDismissFeedbackModal } = useDismissFeedbackModal()

    const dismissImprovementWithFeedBack = async (
        improvementId: number,
        length: DismissDuration
    ) => {
        await dismissImprovement(improvementId, length)

        refreshDismissedList()

        const recentlyDismissedImprovement = cloneDeep(
            improvements.value.find(i => i.improvement_id === improvementId)
        )

        if (!recentlyDismissedImprovement) throw new Error('No dismissed improvement to show')

        await removeImprovements([improvementId])

        showSnackbar({
            message: `Improvement dismissed. How could we do better?`,
            timeout: 10000,
            actionText: 'Share Feedback',
            actionHandler: () =>
                openDismissFeedbackModal({
                    improvementId,
                    improvementTitle: recentlyDismissedImprovement.title,
                    improvementLocation: recentlyDismissedImprovement.location,
                    improvementRecAction: recentlyDismissedImprovement.rec_action,
                }),
            snackbarMaxWidth: 520,
        })
    }

    const toInject = {
        loading,
        error,
        improvements,
        improvementsGrouped,
        improvementSort,
        updateImprovementSort,
        removeImprovements,
        dismissImprovement,
        dismissImprovementWithFeedBack,
        mutateImprovementsList,
    }

    provide(ProvideKeys.ActiveImprovementList, toInject)

    return toInject
}

export function useActiveImprovements() {
    const injected = inject<ReturnType<typeof provideActiveImprovements>>(
        ProvideKeys.ActiveImprovementList
    )

    if (!injected) {
        throw new Error(`activeImprovementList not yet injected, something is wrong. `)
    }

    return injected
}
