<template>
    <ReportSettings :containerTitle="title" />
    <Spacer height="3rem" />
    <ReportSenderProfile />
    <Spacer height="3rem" />
    <ReportRecipientProfile />
    <Spacer height="3rem" />
    <ReportSchedule />
</template>

<script lang="ts">
import ReportSettings from '@/components/Reports/ReportSettings.vue'
import ReportSenderProfile from '@/components/Reports/ReportSenderProfile.vue'
import ReportRecipientProfile from '@/components/Reports/ReportRecipientProfile.vue'
import ReportSchedule from '@/components/Reports/ReportSchedule.vue'

import { Spacer } from '@opteo/components-next'

export default {
    components: {
        ReportSettings,
        ReportSenderProfile,
        ReportRecipientProfile,
        ReportSchedule,
        Spacer,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: 'Create New Report',
        },
    },
}
</script>

<style lang="scss" scoped></style>
