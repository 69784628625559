import { AdjustDeviceBidsV2 } from '@opteo/types'
import startCase from 'lodash-es/startCase'
import toLower from 'lodash-es/toLower'

export function getProperDeviceLabel(label: string) {
    return startCase(toLower(label))
}

export function calculateCpa(cost: number, conversions: number) {
    if (cost === 0 && conversions === 0) return 0
    return cost / conversions
}

export function calculateRoas(cost: number, conversionsValue: number) {
    if (cost === 0 && conversionsValue === 0) return 0
    return conversionsValue / cost
}

export function instanceOfAdGroup(object: any): object is AdjustDeviceBidsV2.HighVolumeAdGroup {
    return object?.ad_group
}

export function instanceOfCampaign(object: any): object is AdjustDeviceBidsV2.HighVolumeCampaign {
    return !object?.ad_group && object.campaign
}

export function instanceOfCampaignGroup(
    object: any
): object is AdjustDeviceBidsV2.HighVolumeCampaignGroup {
    return !!object?.group_metrics
}
