import * as Sentry from '@sentry/vue'
import { Vue } from '@sentry/vue/types/types'
import { BrowserTracing } from '@sentry/tracing'
import { Router } from 'vue-router'
import { API_URL } from '@/lib/env'

const apiHost = new URL(API_URL).host

const initialize = (app: Vue, router: Router, environment: string) => {
    Sentry.init({
        app,
        dsn: 'https://410f688585c34ebabfc2160f821fe390@o75491.ingest.sentry.io/6316117',
        // @ts-ignore this is defined in vite.config.ts
        release: NPM_VERSION,
        environment,
        logErrors: true,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['localhost', apiHost, /^\//],
                shouldCreateSpanForRequest: url => {
                    const spanUrlHost = new URL(url).host

                    // ensures spans are only made for our api requests
                    return spanUrlHost === apiHost
                },
            }),
        ],

        // Set tracesSampleRate to 0.5 to capture 50%
        tracesSampleRate: 0.5,
    })
}

export default initialize
