<template>
    <div class="onboarding-container">
        <div class="onboarding">
            <section class="instructions">
                <Text as="h2" size="f-4" class="title">Instructions</Text>
                <Spacer height="1.25rem" />
                <Text as="p" size="f-8">
                    Ask Chat to compare the performance of two ad groups, write new headlines for a
                    responsive search ad, generate keyword ideas for a new campaign, or whatever you
                    might need help with. Use the
                    <span class="tag at">@</span> symbol to reference an entity in your Google Ads
                    account.
                </Text>
            </section>
            <section class="examples">
                <Text as="h2" size="f-4" class="title">Examples</Text>
                <Spacer height="1.625rem" />
                <div v-if="!entitiesLoading && !adGroup" class="chat-onboarding-empty-state">
                    To generate example queries, Opteo uses the top spending ad group from your top
                    spending campaign. This account does not appear to contain any active campaigns
                    with ad groups inside. If this is in error, please message support.
                </div>
                <div v-else class="example-queries">
                    <button class="example-query" @click="emitQuery('rsa-clicked')">
                        Please generate 12 headlines and 12 descriptions for
                        <div class="nested-full-stop">
                            <Skeleton v-if="entitiesLoading" />
                            <span v-else-if="adGroup" class="tag adgroup">{{ adGroup.name }}</span
                            >.
                        </div>
                        Headlines should be limited to 30 characters and descriptions should be
                        limited to 90 characters (including spaces). Format the results in a table.
                        Use a different angle for each asset generated and include the angle as a
                        column in the table. Potential angles could include: keyword focused,
                        feature focused, benefit focused, price focused, brand focused, social
                        proof, call to action, and so on.
                    </button>
                    <button class="example-query" @click="emitQuery('keywords-clicked')">
                        Please generate 20 potential keywords for the ad group
                        <div class="nested-full-stop">
                            <Skeleton v-if="entitiesLoading" />
                            <span v-else-if="adGroup" class="tag adgroup">{{ adGroup.name }}</span
                            >.
                        </div>
                        Look at the existing converting keywords in this ad group and think about
                        what might convert successfully. Show the results in a table, include a
                        column specifying the keyword match type, and a column describing why you
                        think the recommended keyword will perform well.
                    </button>
                    <button class="example-query" @click="emitQuery('audience-clicked')">
                        Please recommend a target audience for this account, include as much detail
                        as possible about affinity segments, demographics, life events, purchase
                        intent, and so on.
                    </button>
                </div>
            </section>
            <section class="capabilities">
                <Text as="h2" size="f-4" class="title">Capabilities</Text>
                <Spacer height="1.25rem" />
                <Text as="p" size="f-8">
                    Chat remembers what you said earlier in the conversation, up to a limit of
                    around 16,000 characters, meaning you can ask follow up questions. Chat has been
                    trained to decline inappropriate requests. If you have an idea for a new Chat
                    feature, please let us know.
                </Text>
            </section>
            <section class="limitations">
                <Text as="h2" size="f-4" class="title">Limitations</Text>
                <Spacer height="1.25rem" />
                <Text as="p" size="f-8">
                    Chat can occasionally generate incorrect information, please use the service
                    with care. Chat only has limited understanding of your account as a whole, this
                    is because there are limits on how much data can be stored and actioned by
                    ChatGPT at one time.
                </Text>
            </section>
        </div>
    </div>
</template>

<script lang="ts">
// Vue
import { ref } from 'vue'
import type { PropType } from 'vue'
// @opteo/types
import { Chat } from '@opteo/types'
// local components
import Skeleton from '@/components/util/Skeleton.vue'
// @opteo/components-next
import { Text, Spacer } from '@opteo/components-next'

export default {
    name: 'ChatOnboarding',
    components: { Text, Spacer, Skeleton },
    //emits: ['rsa-clicked', 'keywords-clicked', 'audience-clicked'],
    emits: ['onboarding-clicked'],
    props: {
        adGroup: {
            type: Object as PropType<Chat.AdGroupResponse | undefined>, // Not sure why this is not working? Types export ok but interfaces are missing
        },
        entitiesLoading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        function toRegexString(resourceName?: string) {
            return `@adgroup:${resourceName}`
        }

        const emitQuery = (type: string) => {
            // If ad group is not yet loaded, don't allow clicking
            if (props.entitiesLoading) {
                return
            }
            // Initialise variable for query string content
            let queryString = ''
            // Set query string to the correct value
            if (type === 'rsa-clicked') {
                queryString = `Please generate 12 headlines and 12 descriptions for ${toRegexString(
                    props.adGroup?.resourceName
                )}. Headlines should be limited to 30 characters and descriptions should be limited to 90 characters (including spaces). Format the results in a table. Use a different angle for each asset generated and include the angle as a column in the table. Potential angles could include: keyword focused, feature focused, benefit focused, price focused, brand focused, social proof, call to action, and so on.`
            } else if (type === 'keywords-clicked') {
                queryString = `Please generate 20 potential keywords for the ad group ${toRegexString(
                    props.adGroup?.resourceName
                )}. Look at the existing converting keywords in this ad group and think about what might convert successfully. Show the results in a table, include a column specifying the keyword match type, and a column describing why you think the recommended keyword will perform well.`
            } else if (type === 'audience-clicked') {
                queryString = `Please recommend a target audience for this account, include as much detail as possible about affinity segments, demographics, life events, purchase intent, and so on.`
            } else {
                console.log(
                    `Invalid queryType: ${type}. Needs to be rsa-clicked, keywords-clicked, or audience-clicked.`
                )
                return
            }

            // Emit event with the correct query string as argument
            emit('onboarding-clicked', queryString)
        }

        return { emitQuery }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.onboarding-container {
    width: 100%;
    @include flex-center;
}
.onboarding-container .onboarding {
    @include container;
    @include br-32;
    max-width: 31.5rem;
}
.onboarding-container .onboarding section {
    @include ph-40;
    @include pv-36;
}
.chat-container .onboarding section.examples {
    @include pb-40;
}

.chat-container .onboarding section.examples .example-queries {
    @include flex;
    flex-direction: column;
    gap: 0.75rem;
}
.chat-container .onboarding section.examples .example-queries .example-query {
    @include container;
    @include flex;
    @include items-center;
    white-space: nowrap;
    overflow: hidden;
    @include f-8;
    padding: 7px 14px;
    height: 39px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    outline: none;
}
.chat-container .onboarding section.examples .example-queries .example-query:active {
    transform: translateY(1px);
    box-shadow: $opteo-shadow-focus;
}
.chat-container .onboarding section.examples .example-queries .example-query:focus {
    box-shadow: $opteo-shadow-focus;
}

.onboarding-container .onboarding section .tag {
    background: $opteo-dark-blue-translucent;
    color: $opteo-dark-blue;
    margin: 0 0.375rem;
    padding: 4px 6px 5px 6px;
    border-radius: 6px;
    white-space: nowrap;
}
.onboarding-container .onboarding section .tag.adgroup {
    background: $opteo-dark-purple-translucent;
    color: $opteo-dark-purple;
}
.onboarding-container .onboarding section .tag.at {
    margin-left: 2px;
    margin-right: 2px;
    padding: 3px 6px;
}
.onboarding-container .onboarding section .nested-full-stop {
    @include flex;
    @include items-center;
    margin-left: 0.375rem;
    margin-right: 0.25rem;
    gap: 0.0625rem;
}
.onboarding-container .onboarding section .nested-full-stop .tag {
    margin: 0;
    padding: 4px 6px 5px 6px;
    border-radius: 6px;
    white-space: nowrap;
}

.onboarding-container .onboarding section p {
    line-height: 1.375rem;
}

.chat-container .onboarding section.instructions,
.chat-container .onboarding section.capabilities,
.chat-container .onboarding section.examples {
    border-bottom: 1px solid $opteo-light-gray;
}

.chat-onboarding-empty-state {
    background: $opteo-dark-blue-translucent;
    color: $opteo-dark-blue;
    @include br-20;
    @include ph-28;
    @include pv-24;
    @include f-8;
    line-height: 22px;
    // height: 141px;
    width: 100%;
}
</style>
