<template>
    <oTable :items="workSummaryRows" :headers="workSummaryHeaders" :noShadow="true">
        <template #[`header.name`]>
            <div class="name-cell">
                <Text as="span" size="f-8" weight="500">Task Completed</Text>
            </div>
        </template>
        <template #[`column.name`]="cell">
            <div class="cell name-cell">
                <oInput
                    class="taskNameInput"
                    :modelValue="cell.value"
                    @updated="updateSummary(cell.row.id, 'name', $event)"
                    :readonly="cell.row.active ? null : true"
                    :class="{ inactive: !cell.row.active }"
                />
            </div>
        </template>
        <template #[`column.number`]="cell">
            <div class="cell number-cell">
                <oInput
                    :modelValue="cell.value"
                    type="number"
                    @updated="updateSummary(cell.row.id, 'number', $event)"
                    :readonly="cell.row.active ? null : true"
                    :class="{ inactive: !cell.row.active }"
                />
            </div>
        </template>
        <template #[`column.time`]="cell">
            <div class="cell time-cell">
                <oInput
                    :modelValue="cell.value"
                    type="number"
                    @updated="updateSummary(cell.row.id, 'time', $event)"
                    :readonly="cell.row.active ? null : true"
                    suffix="min"
                    :class="{ inactive: !cell.row.active }"
                />
            </div>
        </template>
        <template #[`column.totalTime`]="cell">
            <div class="cell total-cell">
                <Text :class="{ inactive: !cell.row.active }">
                    {{ cell.value }}
                </Text>
            </div>
        </template>
        <template #[`column.actions`]="cell">
            <oButton
                color="white"
                :circle="true"
                size="small"
                @clicked="toggleRowActive(cell.row.id)"
            >
                <template #icon>
                    <!-- Restore Icon -->
                    <svg
                        v-if="!cell.row.active"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                    >
                        <g fill="#006dff">
                            <path
                                d="M4.5 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.3.4 5.9.4 3.1 3.1L.9.9.2 7.3l6.4-.7-2.1-2.1z"
                            />
                            <path
                                data-color="color-2"
                                d="M15.8 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.1 2 4.9 2 1.8 0 3.6-.7 4.9-2l2.2 2.2.8-6.4z"
                            />
                        </g>
                    </svg>
                    <!-- Remove Icon -->
                    <BinIcon v-else />
                </template>
            </oButton>
        </template>
    </oTable>
    <div v-if="workSummaryRows.length === 0" class="work-summary-empty-state">
        <Spacer height="3rem" />
        <Text size="f-8" align="center">No tasks currently added</Text>
        <Spacer height="1rem" />
    </div>
    <!-- ADD ROW BUTTON -->
    <div class="add-new-task-button-container">
        <oButton color="white" size="medium" @clicked="addNewRow()">
            Add New Task
            <template #icon>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 11a1 1 0 1 0 2 0V7h4a1 1 0 1 0 0-2H7V1a1 1 0 0 0-2 0v4H1a1 1 0 0 0 0 2h4v4Z"
                        fill="#006DFF"
                    />
                </svg>
            </template>
        </oButton>
    </div>
</template>

<script lang="ts">
import { computed } from 'vue'
import { oTable, oButton, Text, oInput, Spacer, Tooltip, BinIcon } from '@opteo/components-next'
import { useReportSlides } from '@/composition/reports/useReportSlides'
import { delay } from '@/lib/globalUtils'

export default {
    name: 'ReportWorkSummary',
    components: { oTable, oButton, Text, oInput, Spacer, Tooltip, BinIcon },
    setup() {
        const { workSummary, friendlyTime } = useReportSlides()

        const workSummaryHeaders = [
            { key: 'name', text: 'Task Completed', removePadding: true },
            { key: 'number', text: '#', removePadding: true },
            { key: 'time', text: 'Time Per Task', removePadding: true },
            { key: 'totalTime', text: 'Total Time', removePadding: true },
            { key: 'actions', text: ' ', removePadding: true },
        ]

        const workSummaryRows = computed(() => {
            return workSummary.value.map((row, index) => {
                return {
                    id: index,
                    name: row.name,
                    number: row.number,
                    time: row.time,
                    totalTime: friendlyTime(row.number * row.time),
                    active: row.active,
                }
            })
        })

        const updateSummary = (
            index: number,
            field: 'name' | 'number' | 'time',
            newVal: string
        ) => {
            workSummary.value[index][field] = field === 'name' ? newVal : +newVal
        }

        const addNewRow = async () => {
            workSummary.value.push({
                name: `Task ${workSummary.value.length + 1}`,
                number: 1,
                time: 20,
                active: true,
            })

            // Hey I know this is a bit gross, but what is life without some native JS DOM memes?
            // This hightlights the just-created element
            await delay(1)
            const lastInput = Array.from(document.getElementsByClassName('taskNameInput')).pop()
            lastInput?.querySelector('input')?.select()
        }

        const toggleRowActive = (index: number) => {
            workSummary.value[index].active = !workSummary.value[index].active
        }

        return {
            workSummaryHeaders,
            workSummaryRows,
            updateSummary,
            addNewRow,
            toggleRowActive,
            workSummary,
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.inactive {
    filter: blur(5px);
    opacity: 0.2;
}
.inactive :deep(.o-input__input) {
    box-shadow: none !important;
}

.toggle-icon {
    width: 2rem;
    height: 2rem;
    @include br-999;
    @include pa-8;
    @include flex;
    @include items-center;
    @include justify-center;
    @include pointer;
}

.cell {
    @include pt-12;
    @include pb-12;
}
.name-cell {
    width: 20rem;
    @include pl-4;
}

.number-cell {
    width: 4rem;
}

.time-cell {
    width: 6.5rem;
}

.total-cell {
    width: 6rem;
}
.add-new-task-button-container {
    border-top: 1px solid #f7f7f9;
    @include ph-28;
    @include pv-24;
}
</style>
