<template>
    <teleport to="body">
        <ViewBackground :active="sidebarOpen" />
        <transition name="mobile-nav-slide">
            <div
                ref="mobileSidebar"
                v-if="sidebarOpen"
                class="mobile-sidebar"
                :class="noPadding ? 'no-padding' : ''"
            >
                <slot></slot>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts">
import { defineComponent, computed, ref, toRef, PropType } from 'vue'
import { useBodyLock, ViewBackground } from '@opteo/components-next'

import { onClickOutside } from '@vueuse/core'

export default defineComponent({
    name: 'MobileSidebar',
    components: {
        ViewBackground,
    },
    props: {
        sidebarOpen: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        onClickOutside: {
            type: Function as PropType<(event: Event) => void>,
            required: true,
        },
        noPadding: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const mobileSidebar = ref()
        const active = toRef(props, 'sidebarOpen')

        useBodyLock(active)

        onClickOutside(mobileSidebar, event => {
            props.onClickOutside(event)
        })

        return {
            mobileSidebar,
        }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
@import '@/assets/css/theme.scss';

.mobile-sidebar-background {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: fixed;
    z-index: 4000;
    width: 100vw;
    top: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.4375s cubic-bezier(0.64, 0, 0.32, 1),
        background-color 0.4375s cubic-bezier(0.64, 0, 0.32, 1);
}
.mobile-sidebar-background.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    background-color: #ffffffad;
}

.mobile-sidebar {
    position: fixed;
    z-index: 4000;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #ffffff;
    @include pa-28;
    text-align: left;
    box-shadow: $opteo-shadow;
    width: calc(100vw - 64px);
    max-width: 22rem;
    overflow: scroll;
    will-change: transform;
    transition: transform 0.4375s cubic-bezier(0.64, 0, 0.32, 1);
}

// sidebar transition
.mobile-nav-slide-enter-active,
.mobile-nav-slide-leave-active {
    transition: transform 0.4375s cubic-bezier(0.64, 0, 0.32, 1);
}
.mobile-nav-slide-enter-from,
.mobile-nav-slide-leave-to {
    transform: translateX(390px);
}

.mobile-sidebar.no-padding {
    @include pa-0;
}
</style>
