<template>
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.65072 14.0287 8.25169 2.14697c.76201-1.371623 2.73461-1.371621 3.49661 0l6.601 11.88173C19.0899 15.3618 18.1259 17 16.601 17H3.39903c-1.52497 0-2.488905-1.6382-1.74831-2.9713Z"
            stroke="#000"
            stroke-width="2"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 6c0-.55228-.4477-1-1-1-.55228 0-1 .44772-1 1v4c0 .5523.44772 1 1 1 .5523 0 1-.4477 1-1V6Zm-1 8.5c.6904 0 1.25-.5596 1.25-1.25S10.6904 12 10 12c-.69036 0-1.25.5596-1.25 1.25S9.30964 14.5 10 14.5Z"
            fill="#000"
        />
    </svg>
</template>
