import sortBy from 'lodash-es/sortBy'
import { computed, ref, watch } from 'vue'

import errorMessages from '@/composition/improvement/knownErrors'
import { useAccountList } from '@/composition/user/useAccountList'
import { useDomain } from '@/composition/domain/useDomain'
import { delay } from '@/lib/globalUtils'

import { provideActiveImprovements } from './useActiveImprovements'
import { usePushImprovement } from './usePushImprovement'

import type { Improvement } from '@opteo/types'
import type { OnPushHandler } from '@/composition/improvement/useImprovement'
import type { EnhancedImprovement } from './types'

type PushingFrom = 'improvementView' | 'batchBar'

const States = ['idle', 'waiting', 'working', 'pushed', 'failed', 'skipped', 'dismissed'] as const
type State = (typeof States)[number]

export interface QueuedImprovement {
    id: number
    improvement: EnhancedImprovement
    state: State
    progress: number
    pushingFrom: PushingFrom
    onPush?: OnPushHandler | undefined
    error?: string
}

const MIN_PUSHING_TIME = 2000
const PUSH_ERROR_MESSAGE = `This improvement is no longer valid. Please refresh the page to load fresh improvements. If the problem persists, please contact support.`

const queuedImprovements = ref<QueuedImprovement[]>([])
const currentImpPushingIds = ref<number[]>([])
const currentImpBatchPushingId = ref(0)

const queueRunning = computed(() => {
    return !!(currentImpPushingIds.value.length && !queueDone.value)
})
const queueDone = computed(() => {
    const isNotInProgress = currentImpPushingIds.value.length === 0
    const areQueuedImprovementsFinished = queuedImprovements.value.every(i =>
        ['pushed', 'failed', 'skipped', 'dismissed'].includes(i.state)
    )
    const queueHasElements = queuedImprovements.value.length > 0

    return isNotInProgress && areQueuedImprovementsFinished && queueHasElements
})

export function useImprovementQueue() {
    const { improvements, removeImprovements } = provideActiveImprovements()
    const { mutateDomainList } = useAccountList()
    const { mutateDomain } = useDomain()
    const { pushImprovement } = usePushImprovement()

    /*
        Improvements queue setup
    */
    const queuedImprovementsSorted = computed<QueuedImprovement[]>(() => {
        // Make sure that the queue is sorted the same way as the list
        const sorted = sortBy(queuedImprovements.value, i => {
            return improvements.value.findIndex(_i => _i.improvement_id === i.id)
        })

        return sorted
    })

    watch(improvements, newVal => {
        /**
         * Make sure that the queue never contains improvements that are no longer in the list,
         * with the exception of 'working' and 'failed' improvements, which need to preserve state
         * between page renderings.
         */
        queuedImprovements.value = queuedImprovements.value.filter(
            (queuedImprovement: QueuedImprovement) => {
                const needsPreserving = ['working', 'failed'].includes(queuedImprovement.state)

                if (needsPreserving) return true

                const newImprovement = newVal.find(
                    ({ improvement_id: improvementId }) => improvementId === queuedImprovement.id
                )

                return !!newImprovement
            }
        )
    })

    /*
        Improvement queue pushing
    */
    const pushToImprovementQueue = <T extends PushingFrom>({
        improvementId,
        pushingFrom,
        onPush,
    }: {
        improvementId: number
        pushingFrom: T
        onPush?: T extends 'improvementView' ? OnPushHandler | undefined : never
    }) => {
        const matchingImprovement = (improvements.value ?? []).find(
            i => i.improvement_id === improvementId
        )

        if (!matchingImprovement) {
            throw new Error(PUSH_ERROR_MESSAGE)
        }

        const improvementToQueue: QueuedImprovement = {
            id: improvementId,
            improvement: matchingImprovement,
            state: 'idle',
            progress: 0,
            pushingFrom,
            onPush,
        }

        const queuedImprovementsExclNew = queuedImprovements.value.filter(
            improvement => improvement.id !== improvementId
        )

        queuedImprovements.value = [...queuedImprovementsExclNew, improvementToQueue]
    }

    const pushQueueSingle = async ({
        improvementId,
        pushingFrom = 'improvementView',
        onPush,
    }: {
        improvementId: number
        pushingFrom?: PushingFrom
        onPush: OnPushHandler | undefined
    }) => {
        pushToImprovementQueue({ improvementId, pushingFrom, onPush })

        await pushQueue({ improvementId, pushingFrom })
    }

    const pushQueue = async <T extends PushingFrom>({
        pushingFrom,
        improvementId,
    }: {
        pushingFrom: T
        improvementId?: T extends 'improvementView' ? number : undefined
    }) => {
        const improvementsToPush =
            pushingFrom === 'batchBar'
                ? queuedImprovementsSorted.value.filter(queuedImprovement => {
                      return queuedImprovement.pushingFrom === 'batchBar'
                  })
                : queuedImprovementsSorted.value.filter(queuedImprovement => {
                      if (!improvementId) {
                          throw new Error(
                              `You need an improvement id when pushing a single improvement`
                          )
                      }

                      return (
                          queuedImprovement.pushingFrom === 'improvementView' &&
                          queuedImprovement.id === improvementId
                      )
                  })

        for (const imp of improvementsToPush) {
            imp.state = 'waiting'
        }

        // We need to loop over the queuedImprovementsFull because it is sorted.
        for (const { id, improvement: fullImprovement } of improvementsToPush) {
            // However, the actual states to modify live on the elements of queuedImprovements.
            const queuedImprovement = queuedImprovements.value.find(i => i.id === id)

            // The queue has been cleared, exit this loop
            if (!queuedImprovement?.id) {
                break
            }

            if (!fullImprovement) {
                queuedImprovement.state = 'failed'
                queuedImprovement.error = PUSH_ERROR_MESSAGE

                throw new Error(PUSH_ERROR_MESSAGE)
            }

            currentImpPushingIds.value = [...currentImpPushingIds.value, queuedImprovement.id]
            queuedImprovement.state = 'working'

            if (pushingFrom === 'batchBar') {
                currentImpBatchPushingId.value = queuedImprovement.id

                if (fullImprovement.requires_adjust) {
                    queuedImprovement.state = 'skipped'
                    removeQueuedImprovementFromCurrentPushing(id)
                    continue
                }

                queuedImprovement.progress = 0
                await delay(600) // allow progressbar to snap back to start
                queuedImprovement.progress = 0.8
            }

            await pushQueuedImprovement({ queuedImprovement, fullImprovement })
        }

        if (pushingFrom === 'batchBar') {
            currentImpBatchPushingId.value = 0
        }

        // refresh improvement counts in account centre
        mutateDomainList()
        mutateDomain()
    }

    const pushQueuedImprovement = async ({
        queuedImprovement,
        fullImprovement,
    }: {
        queuedImprovement: QueuedImprovement
        fullImprovement: EnhancedImprovement
    }) => {
        const { pushingFrom } = queuedImprovement
        const { rec_action: recAction } = fullImprovement

        try {
            const [improvementResult] = await Promise.allSettled([
                pushSingleImprovementByPushingFrom(queuedImprovement, recAction),
                delay(MIN_PUSHING_TIME),
            ])

            if (improvementResult.status === 'rejected') {
                throw improvementResult.reason
            }

            queuedImprovement.state = 'pushed'
            queuedImprovement.progress = 1
        } catch (error: any) {
            queuedImprovement.state = 'failed'
            queuedImprovement.error = error.message

            Object.keys(errorMessages).some(snippet => {
                if (queuedImprovement.error && queuedImprovement.error.includes(snippet)) {
                    queuedImprovement.error = errorMessages[snippet].short
                    return
                }
            })

            queuedImprovement.progress = 1

            if (pushingFrom === 'batchBar') {
                await delay(1000) // extra time for user to read error message
            }

            if (pushingFrom === 'improvementView') {
                throw new Error(queuedImprovement.error ?? 'Unknown Error')
            }
        } finally {
            removeQueuedImprovementFromCurrentPushing(queuedImprovement.id)
        }

        if (pushingFrom === 'batchBar') {
            // give the icon some time to be shown to user
            await delay(600)
        }
    }

    const pushSingleImprovementByPushingFrom = async (
        queuedImprovement: QueuedImprovement,
        recAction: Improvement.RecAction
    ) => {
        const { id: improvementId, pushingFrom, onPush = undefined } = queuedImprovement

        /**
         * Make sure we only push improvements that are still in the queue.
         * This is especially useful for batch queues, since there's a case where
         * a user can navigate back from the Improvements page (e.g. to Account Settings),
         * causing the batch queue to be cleared.
         */
        const isStillInQueue =
            queuedImprovements?.value?.length &&
            queuedImprovements.value.some(q => q.id === queuedImprovement.id)

        if (!isStillInQueue) {
            return
        }

        // Push improvement without any extra details (onPush) and isInBatch as true
        if (pushingFrom === 'batchBar') {
            await pushImprovement({
                improvementId,
                impParams: {},
                recAction,
                isInBatch: true,
            })
        }

        //  Push improvement with extra details if they exist (onPush) and isInBatch as false
        if (pushingFrom === 'improvementView') {
            await pushImprovement({
                improvementId,
                impParams: { onPush },
                recAction,
                isInBatch: false,
            })
        }
    }

    /*
        Remove improvements functionality
    */
    const removeQueuedImprovementFromCurrentPushing = (queuedImprovementId: number) => {
        currentImpPushingIds.value = currentImpPushingIds.value.filter(
            idCurrentlyPushing => idCurrentlyPushing !== queuedImprovementId
        )
    }

    const removeFailedImprovement = (failedImprovementId: number) => {
        queuedImprovements.value = queuedImprovements.value.filter(
            improvement => improvement.id !== failedImprovementId
        )
    }

    const removePushedImprovement = async (id: number) => {
        await removeImprovements([id])
    }

    const cleanupQueue = async () => {
        const idsToRemove = queuedImprovements.value
            .filter(({ state }) => state === 'working' || state === 'pushed')
            .map(({ id }) => id)

        await removeImprovements(idsToRemove)
    }

    return {
        queuedImprovements,
        queuedImprovementsSorted,
        queueDone,
        queueRunning,
        currentImpBatchPushingId,
        pushToImprovementQueue,
        pushQueueSingle,
        pushQueue,
        removeFailedImprovement,
        removePushedImprovement,
        cleanupQueue,
    }
}
