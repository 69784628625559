<template>
    <transition name="improvement-footer-fade" appear>
        <div id="improvement-footer">
            <!-- Left container (avatar, name, location) -->
            <div class="improvement-footer-left flex items-center">
                <!-- Account avatar -->
                <Avatar
                    :color="avatarColor"
                    v-tippy="{
                        animateFill: 'false',
                        animation: 'shift-away',
                        duration: '250',
                        placement: 'top-start',
                        theme: 'footer-avatar',
                        offset: [0, 8],
                        arrow: false,
                        zIndex: 10001,
                    }"
                    :content="`<span class='f7'>${accountName}</span>`"
                    :platform="platform"
                    show-account-type
                >
                    {{ avatarInitials }}
                    <Avatar class="phantom-avatar" color="#01010514" />
                </Avatar>
                <!-- Improvement name -->
                <h4 class="improvement-name f5 fw6 ml3 lh-title" style="margin-right: 1.5rem">
                    {{ improvementName }}
                </h4>
                <div class="flex items-center" style="gap: 0.625rem">
                    <EntityPill
                        v-for="({ entity, label }, index) of locations"
                        :key="index"
                        :type="entity"
                        :content="label"
                    />
                </div>
            </div>
            <!-- Right container (buttons) -->
            <div class="improvement-footer-right">
                <div class="dismiss-adjust improvement-footer-button" key="dismissAdjustButtons">
                    <!-- Dismiss button -->
                    <Popout
                        v-if="dismissButton"
                        v-model="dismissPopoutOpen"
                        :trapFocus="true"
                        :externalToggleButton="true"
                        :popperOptions="{
                            strategy: 'fixed',
                        }"
                        placement="top"
                    >
                        <oButton
                            key="dismissButton"
                            color="white"
                            class="dismiss-button"
                            @clicked="dismissPopoutOpen = !dismissPopoutOpen"
                        >
                            Dismiss
                            <template #icon>
                                <SelectArrowIcon hex="#006dff" />
                            </template>
                        </oButton>
                        <template v-slot:content>
                            <div @click="dismissPopoutOpen = false">
                                <slot name="dismissPopout" />
                            </div>
                        </template>
                    </Popout>
                    <!-- Adjust button -->
                    <oButton
                        v-if="adjustButton"
                        key="adjustButton"
                        color="white"
                        class="adjust-button"
                        @clicked="adjustClicked()"
                    >
                        Adjust
                    </oButton>
                    <!-- Completed Mode -->
                    <oButton
                        v-if="completed"
                        class="completed-button ml2"
                        key="completedButton"
                        color="white"
                        :disabled="true"
                    >
                        {{ completedText }}
                    </oButton>
                </div>
                <!-- Back button -->
                <oButton
                    v-if="backButton"
                    key="backButton"
                    color="white"
                    class="back-button improvement-footer-button"
                    @clicked="backClicked()"
                >
                    Back
                </oButton>
                <!-- Step one button -->
                <oButton
                    v-if="stepOneButton"
                    class="improvement-footer-button step-button"
                    key="stepOneButton"
                    color="blue"
                    @clicked="stepOneClicked()"
                >
                    {{ stepOneButtonText }}
                    <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
                </oButton>
                <!-- Step two button -->
                <oButton
                    v-if="stepTwoButton"
                    class="improvement-footer-button step-button"
                    key="stepTwoButton"
                    color="blue"
                    @clicked="stepTwoClicked()"
                >
                    {{ stepTwoButtonText }}
                    <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
                </oButton>
                <!-- Step three button -->
                <oButton
                    v-if="stepThreeButton"
                    class="improvement-footer-button step-button"
                    key="stepThreeButton"
                    color="blue"
                    @clicked="stepThreeClicked()"
                >
                    {{ stepThreeButtonText }}
                    <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
                </oButton>
                <!-- Push button -->
                <oButton
                    v-if="pushButton"
                    class="improvement-footer-button push-button"
                    key="pushButton"
                    color="green"
                    @clicked="pushClicked()"
                    :loading="pushButtonHasBeenClicked && pushDataStillLoading"
                >
                    {{ pushButtonText }}
                    <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
                </oButton>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { PropType, ref, computed, watch } from 'vue'
import { Improvement, Platform } from '@opteo/types'

import {
    // Components
    EntityPill,
    oButton,
    Popout,
    Avatar,
    // Icons
    CircleCampaignIcon,
    CircleKeywordIcon,
    SelectArrowIcon,
    RightArrowIcon,
} from '@opteo/components-next'

export default {
    components: {
        EntityPill,
        oButton,
        Popout,
        Avatar,
        SelectArrowIcon,
        CircleCampaignIcon,
        CircleKeywordIcon,
        RightArrowIcon,
    },
    props: {
        avatarInitials: {
            type: String,
            default: 'DE',
        },
        avatarColor: {
            type: String,
            default: '#ffffff', // white so the avatar is invisible until color is set
        },
        accountName: {
            type: String,
            default: 'Default Name',
        },
        platform: {
            type: Number as PropType<Platform.Platform>,
            default: Platform.Platform.GoogleAds,
            required: false,
        },
        improvementName: {
            type: String,
            default: 'Default Name',
        },
        locations: {
            type: Array as PropType<Improvement.AccountLocation[]>,
        },
        stepOneButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        stepOneButtonText: {
            type: String,
            default: 'Default StepOne Button',
        },
        stepTwoButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        stepTwoButtonText: {
            type: String,
            default: 'Default StepTwo Button',
        },
        stepThreeButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        stepThreeButtonText: {
            type: String,
            default: 'Default StepThree Button',
        },
        pushButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        pushButtonText: {
            type: String,
            default: 'Default Push Button',
        },
        pushDataStillLoading: {
            type: Boolean,
            default: false,
            required: false,
        },
        adjustButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        dismissButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        backButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        completed: {
            type: Boolean,
            default: false,
            required: false,
        },
        completedText: {
            type: String,
            default: 'Default Completed Text',
            required: false,
        },
    },
    emits: [
        'adjust-clicked',
        'back-clicked',
        'step-one-clicked',
        'step-two-clicked',
        'step-three-clicked',
        'push-clicked',
    ],
    methods: {
        adjustClicked() {
            this.$emit('adjust-clicked')
        },
        backClicked() {
            this.$emit('back-clicked')
        },
        stepOneClicked() {
            this.$emit('step-one-clicked')
        },
        stepTwoClicked() {
            this.$emit('step-two-clicked')
        },
        stepThreeClicked() {
            this.$emit('step-three-clicked')
        },
    },
    // Required to access the enum in the template section
    setup(props, { emit }) {
        const dismissPopoutOpen = ref(false)
        const pushButtonHasBeenClicked = ref(false)

        const pushDataLoading = computed(() => props.pushDataStillLoading)

        function pushClicked() {
            pushButtonHasBeenClicked.value = true

            if (!pushDataLoading.value) {
                emit('push-clicked')
            }
        }

        watch(pushDataLoading, () => {
            if (pushButtonHasBeenClicked.value && !pushDataLoading.value) {
                emit('push-clicked')
            }
        })

        return {
            dismissPopoutOpen,
            pushButtonHasBeenClicked,
            pushClicked,
            LocationEntity: Improvement.LocationEntity,
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Base
#improvement-footer {
    @include bg-opteo-white;
    box-shadow: $opteo-shadow-xl;
    height: 5rem;
    @include ph-24;
    @include br-24;
    touch-action: none;
    overflow: hidden;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    width: calc(100vw - 2rem);
    z-index: 3999;
    @include flex;
    @include items-center;
    @include justify-between;
}
#improvement-footer .account-avatar {
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
    flex: 0 0 2.5rem;
}
#improvement-footer .account-avatar.blue {
    background-color: $opteo-blue;
}
#improvement-footer .account-avatar.purple {
    background-color: $opteo-purple;
}
#improvement-footer .account-avatar.green {
    background-color: $opteo-green;
}
#improvement-footer .account-avatar.pink {
    background-color: $opteo-pink;
}
#improvement-footer .account-avatar.orange {
    background-color: $opteo-orange;
}
#improvement-footer .account-avatar.teal {
    background-color: $opteo-teal;
}

#improvement-footer .phantom-avatar {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
    flex: 0 0 2.5rem;
    z-index: -1;
}

#improvement-footer button {
    z-index: 2;
}

.improvement-footer-left h4,
.improvement-footer-left span {
    white-space: nowrap;
}

.improvement-footer-left .improvement-name {
    font-size: 1.0625rem;
    letter-spacing: -0.025rem;
}

.improvement-footer-right {
    position: absolute;
    right: 0;
    padding-right: 1.5rem;
    padding-left: 0.25rem;
    height: 100%;
    display: flex;
    align-items: center;
    background: $opteo-white;
}
.improvement-footer-right::after {
    content: '';
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 80%);
    width: 6rem;
    left: -6rem;
    height: calc(100% - 8px);
    top: 4px;
    position: absolute;
    pointer-events: none;
}

.improvement-footer-right .dismiss-adjust {
    display: flex;
    align-items: center;
}

.improvement-footer-right .adjust-button,
.improvement-footer-right .push-button,
.improvement-footer-right .step-button {
    margin-left: 0.625rem;
}

// Tippy theme
.tippy-box[data-theme~='footer-avatar'] {
    padding: 0.25rem 0.625rem 0.5rem 0.625rem;
    background-color: $opteo-black;
    color: $opteo-white;
    border-radius: 0.5rem;
}
.tippy-box[data-theme~='footer-avatar-theme'] .tippy-backdrop {
    background-color: $opteo-black;
}

// Transition

.improvement-footer-fade-enter-active {
    transition: opacity 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition-delay: 0.3s;
}
.improvement-footer-fade-leave-active {
    transition: opacity 0s cubic-bezier(0.19, 1, 0.22, 1);
}
.improvement-footer-fade-enter-from,
.improvement-footer-fade-leave-to {
    // transform: translateY(16px);
    opacity: 0;
}

// Responsive
@media screen and (min-width: 0px) and (max-width: 480px) {
    .improvement-footer-right .adjust-button,
    .improvement-footer-right .push-button,
    .improvement-footer-right .step-button {
        margin-left: 0;
    }
}
@media screen and (min-width: 0px) and (max-width: 320px) {
    #improvement-footer {
        display: block;
        height: auto;
        padding: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
    }
    .improvement-footer-left {
        border-bottom: 1px solid $opteo-light-gray;
        padding: 1.5rem;
        display: none;
    }
    .improvement-footer-right {
        padding: 1.5rem;
        display: block;
        position: relative;
    }
    .improvement-footer-right button:first-child {
        margin-top: 0;
    }
    .improvement-footer-right .o-button {
        width: 100%;
        height: 48px !important;
    }
    .improvement-footer-right .dismiss-adjust {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
        margin-right: 0;
    }
    .improvement-footer-right .dismiss-adjust .dismiss-button,
    .improvement-footer-right .dismiss-adjust .back-button,
    .improvement-footer-right .dismiss-adjust .adjust-button {
        margin-bottom: 1rem;
    }
    .improvement-footer-right .dismiss-adjust .dismiss-button,
    .improvement-footer-right .dismiss-adjust .back-button,
    .improvement-footer-right .dismiss-adjust .adjust-button,
    .improvement-footer-right .dismiss-adjust .completed-button,
    .improvement-footer-right .improvement-footer-button {
        width: 100% !important;
        margin-top: 0;
        margin-right: 0;
    }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
    #improvement-footer {
        display: block;
        height: auto;
        padding: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
    }
    .improvement-footer-left {
        border-bottom: 1px solid $opteo-light-gray;
        padding: 1.5rem;
        display: none;
    }
    .improvement-footer-right {
        padding: 1.5rem;
        display: block;
        position: relative;
    }
    .improvement-footer-right button:first-child {
        margin-top: 0;
    }
    .improvement-footer-right .o-button {
        width: 100%;
        height: 48px !important;
    }
    .improvement-footer-right .dismiss-adjust {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
        margin-right: 0;
    }
    .improvement-footer-right .dismiss-adjust .dismiss-button,
    .improvement-footer-right .dismiss-adjust .back-button,
    .improvement-footer-right .dismiss-adjust .adjust-button {
        margin-bottom: 1rem;
    }
    .improvement-footer-right .dismiss-adjust .dismiss-button,
    .improvement-footer-right .dismiss-adjust .back-button,
    .improvement-footer-right .dismiss-adjust .adjust-button,
    .improvement-footer-right .dismiss-adjust .completed-button,
    .improvement-footer-right .improvement-footer-button {
        width: 100% !important;
        margin-top: 0;
        margin-right: 0;
    }
}
@media screen and (min-width: 376px) and (max-width: 480px) {
    #improvement-footer {
        display: block;
        height: auto;
        padding: 0;
    }
    .improvement-footer-left {
        border-bottom: 1px solid $opteo-light-gray;
        padding: 1.5rem;
        display: none;
    }
    .improvement-footer-right {
        padding: 1.5rem;
        display: block;
        position: relative;
    }
    .improvement-footer-right button:first-child {
        margin-top: 0;
    }
    .improvement-footer-right .o-button {
        width: 100%;
        height: 48px !important;
    }
    .improvement-footer-right .dismiss-adjust {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
        margin-right: 0;
    }
    .improvement-footer-right .dismiss-adjust .dismiss-button,
    .improvement-footer-right .dismiss-adjust .back-button,
    .improvement-footer-right .dismiss-adjust .adjust-button {
        margin-bottom: 1rem;
    }
    .improvement-footer-right .dismiss-adjust .dismiss-button,
    .improvement-footer-right .dismiss-adjust .back-button,
    .improvement-footer-right .dismiss-adjust .adjust-button,
    .improvement-footer-right .dismiss-adjust .completed-button,
    .improvement-footer-right .improvement-footer-button {
        width: 100% !important;
        margin-top: 0;
        margin-right: 0;
    }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
@media screen and (min-width: 1025px) and (max-width: 1279px) {
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
}
@media screen and (min-width: 1441px) and (max-width: 1600px) {
}
@media screen and (min-width: 1601px) and (max-width: 1920px) {
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
}
@media screen and (min-width: 2561px) and (max-width: 9999px) {
}

.dismiss-footer .completed-button {
    text-transform: capitalize;
}
</style>
