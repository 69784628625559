<template>
    <div class="loader-container">
        <div class="animation-container flex justify-center overflow-hidden relative">
            <ReportCreateLoader
                v-if="action === 'creating'"
                :themeColours="themeColours"
                :isLoading="loading"
                :error="hasError"
                @reset="resetAnimation()"
            />
            <ReportDownloadLoader
                v-else
                :themeColours="themeColours"
                :isLoading="loading"
                :error="hasError"
                @reset="resetAnimation()"
            />
        </div>
        <div class="info-container" style="max-width: 370px">
            <transition-group
                name="fade-in-down"
                class="status-container relative overflow-hidden"
                tag="div"
                mode="out-in"
            >
                <Text
                    key="initial"
                    v-if="status === 'initial'"
                    as="h5"
                    size="f-5"
                    weight="600"
                    align="center"
                    class="status-text absolute"
                >
                    {{ initialStatus }}
                </Text>
                <Text
                    key="final"
                    v-if="status === 'final'"
                    as="h5"
                    size="f-5"
                    weight="600"
                    align="center"
                    class="status-text absolute"
                >
                    {{ finalStatus }}
                </Text>
                <Text
                    key="error"
                    v-if="status === 'error'"
                    as="h5"
                    size="f-5"
                    weight="600"
                    align="center"
                    class="status-text absolute"
                >
                    Error Creating Report
                </Text>
            </transition-group>
            <Spacer height="0.875rem" />
            <Text v-if="!hasError" as="p" size="f-8" align="center">
                <span v-if="action === 'creating' && status === 'initial'">
                    Opteo is collecting your performance data and preparing a first draft of your
                    report. This should take a few moments.
                </span>
                <span v-if="action === 'downloading' && status === 'initial'">
                    Your report slides are being gathered and processed into a downloadable PDF.
                    This should only take a few moments.
                </span>
                <span v-if="action === 'downloading' && status === 'final'">
                    Click <b>Download Report</b> to download a PDF of your report. You can then save
                    it, print a copy, and share with ease.
                </span>
            </Text>
            <Text v-else as="p" size="f-8" align="center" color="red">
                <span v-if="action === 'downloading'">
                    There has been an error downloading your report. Please contact support if you
                    need any help resolving this issue.
                </span>
                <span v-else>
                    There was an error creating your report. Please contact support if you need any
                    help with resolving this issue.
                </span>
            </Text>
            <Spacer height="1.5rem" />
            <oButton
                v-if="action === 'downloading' && !hasError"
                color="green"
                ref="download-btn"
                @clicked="openPDF"
                :loading="loading"
            >
                Download Report
            </oButton>
            <!-- Contact Support (if an error occurs) -->
            <oButton
                v-if="hasError"
                color="blue"
                ref="contact-support-btn"
                @clicked="contactSupport"
            >
                Contact Support
            </oButton>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { oButton, Text, Spacer } from '@opteo/components-next'
import ReportCreateLoader from '@/components/Reports/ReportCreateLoader.vue'
import ReportDownloadLoader from '@/components/Reports/ReportDownloadLoader.vue'
import { generateApiUrl } from '@/composition/api/useAPI'
import { useIntercom } from '@/lib/intercom/useIntercom'

export default {
    name: 'ReportLoaderContainer',
    props: {
        downloadUrl: {
            type: String,
            required: false,
        },
        action: {
            type: String as PropType<'creating' | 'updating' | 'downloading'>,
        },
        themeColours: {
            type: String,
            required: false,
            default: '#7D49FF',
        },
        hasError: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        ReportCreateLoader,
        ReportDownloadLoader,
        oButton,
        Text,
        Spacer,
    },
    setup(props) {
        const intercom = useIntercom()
        const finalStatus = computed(() => {
            if (props.action === 'creating') {
                return 'Report Created'
            } else if (props.action === 'updating') {
                return 'Report Updated'
            }
            return 'Report Download Ready'
        })

        const initialStatus = computed(() => {
            if (props.action === 'creating') {
                return 'Creating Report Slides'
            } else if (props.action === 'updating') {
                return 'Updating Report Slides'
            }
            return props.hasError ? 'Failed to Download Report' : 'Preparing Report for Download'
        })

        const status = computed<'initial' | 'final' | 'error'>(() => {
            if (props.hasError) {
                return 'error'
            }
            if (props.downloadUrl) {
                return 'final'
            }
            return 'initial'
        })

        const loading = computed(() => !props.hasError && !props.downloadUrl)

        const openPDF = () => {
            if (!props.downloadUrl) {
                throw new Error('cannot download PDF, file not yet ready')
            }
            intercom.trackEvent('report_downloaded')
            window.open(generateApiUrl(props.downloadUrl))
        }

        const contactSupport = () => {
            const status =
                props.action === 'creating' ? 'creating a new report' : 'downloading a report'
            const message = `Hi there,\n\nI'm getting an error ${status}.\n\nCan you please look into this and get back to me?`
            intercom.showNewMessage(message)
        }

        return {
            loading,
            status,
            initialStatus,
            finalStatus,
            openPDF,
            contactSupport,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.loader-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.animation-container {
    width: 100%;
    height: 250px;
}

.info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.download-report-content {
    max-width: 640px;
    margin-bottom: 80px;
}

.status-container {
    height: 2.25rem;
    width: 100%;
}

.status-text {
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fade-in-down-enter-active,
.fade-in-down-leave-active {
    transition: all 0.4s;
}
.fade-in-down-enter-active {
    transition-delay: 0.4s;
}
.fade-in-down-enter-from,
.fade-in-down-leave-to {
    opacity: 0;
    transform: translate(-50%, calc(-50% + 30px));
}
</style>
