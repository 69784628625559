<template>
    <div class="group-row" tabindex="0">
        <div :class="['entity-name', { roas: !isUsingCpa }]">
            <EntityPill
                :type="group.type === 'ad-group' ? 'adgroup' : group.type"
                :content="group.name"
                :tooltip="false"
            />
        </div>
        <div class="entity-item cost">
            <Text as="p" size="f-9">Cost</Text>
            <Spacer width="0.625rem" />
            <Text as="span" size="f-7" weight="600">
                <Money :value="group.cost" :currency="currencyCode" />
            </Text>
        </div>

        <div v-if="isUsingCpa" class="entity-item conversions">
            <Text as="p" size="f-9">Conv.</Text>
            <Spacer width="0.625rem" />
            <Text as="span" size="f-7" weight="600">
                <Number :value="group.conversions" />
            </Text>
        </div>
        <div v-else class="entity-item value">
            <Text as="p" size="f-9">Value</Text>
            <Spacer width="0.625rem" />
            <Text as="span" size="f-7" weight="600">
                <Money :value="group.conversionsValue" :currency="currencyCode" />
            </Text>
        </div>
        <div v-if="isUsingCpa" class="entity-item cpa">
            <Text as="p" size="f-9">CPA</Text>
            <Spacer width="0.625rem" />
            <Text as="span" size="f-7" weight="600">
                <Money :value="group.cpa" :currency="currencyCode" />
            </Text>
        </div>
        <div v-else class="entity-item roas">
            <Text as="p" size="f-9">ROAS</Text>
            <Spacer width="0.625rem" />
            <Text as="span" size="f-7" weight="600">
                <Roas :value="group.roas" />
            </Text>
        </div>
        <Popout
            v-model="adScorePopoutVisible"
            :zIndex="9999999999999"
            :offset="[0, -6]"
            :borderRadius="16"
            trigger="mouseenter"
            placement="top"
            class="entity-item ad-score"
            maxWidth="22.875rem"
        >
            <div class="entity-item ad-score">
                <Text as="p" size="f-9">AdScore</Text>
                <Spacer width="0.625rem" />
                <ScorecardDonut
                    :score="group.adScore.total"
                    :hidden="group.adScore.noAds"
                    :compact="true"
                    :width="24"
                    :strokeWidth="2"
                    :hideScore="true"
                />
            </div>
            <template v-slot:content>
                <div class="ad-score-popout">
                    <div class="ad-score-popout-header">
                        <ScorecardDonut
                            :score="group.adScore.total"
                            :hidden="group.adScore.noAds"
                            :compact="true"
                            :width="24"
                            :strokeWidth="2"
                            :hideScore="true"
                        />
                        <Spacer width="0.75rem" />
                        <Text size="f-6" weight="600">AdScore</Text>
                    </div>
                    <Spacer height="1.25rem" />
                    <div class="ad-score-popout-content">
                        <div v-if="group.adScore.noAds" class="ad-score-popout-row">
                            <Text size="f-8" style="max-width: 14.5rem">
                                At least one active RSA is required in order to generate an Ad Score for a given
                                campaign or ad group.
                            </Text>
                        </div>
                        <div v-if="!group.adScore.noAds" class="ad-score-popout-row">
                            <Text class="popout-row-text" size="f-8">
                                RSAs with <b>at least one</b> converting search term in asset collection
                            </Text>
                            <Spacer width="2rem" />
                            <ScorecardDonut
                                :score="group.adScore.convertingSearchTerms"
                                :compact="true"
                                :width="24"
                                :strokeWidth="2"
                                :hideScore="true"
                            />
                        </div>
                        <div v-if="!group.adScore.noAds" class="ad-score-popout-row">
                            <Text class="popout-row-text" size="f-8">RSAs with <b>above average</b> CTR</Text>
                            <Spacer width="2rem" />
                            <ScorecardDonut
                                :score="group.adScore.averageCtr"
                                :compact="true"
                                :width="24"
                                :strokeWidth="2"
                                :hideScore="true"
                            />
                        </div>
                        <div v-if="!group.adScore.noAds" class="ad-score-popout-row">
                            <Text class="popout-row-text" size="f-8">RSAs updated in the last <b>90 days</b></Text>
                            <Spacer width="2rem" />
                            <ScorecardDonut
                                :score="group.adScore.updatedRecently"
                                :compact="true"
                                :width="24"
                                :strokeWidth="2"
                                :hideScore="true"
                            />
                        </div>
                        <div v-if="!group.adScore.noAds" class="ad-score-popout-row">
                            <Text class="popout-row-text" size="f-8">RSAs with <b>at least 8</b> headlines</Text>
                            <Spacer width="2rem" />
                            <ScorecardDonut
                                :score="group.adScore.minimumHeadlines"
                                :compact="true"
                                :width="24"
                                :strokeWidth="2"
                                :hideScore="true"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </Popout>
        <div class="entity-item has-drafts">
            <Tooltip :content="group.hasDrafts ? 'Has Drafts' : 'No Drafts'">
                <div :class="{ active: group.hasDrafts }" class="has-drafts-indicator" />
            </Tooltip>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { CurrencyCode } from '@opteo/types/currency'
import { Targets } from '@opteo/types'

import { Text, Spacer, Money, Number, Popout, Tooltip, EntityPill, Roas } from '@opteo/components-next'
import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'


const props = defineProps<{
    group: any
    currencyCode: CurrencyCode
    performanceMode?: Targets.PerformanceMode
}>()

const adScorePopoutVisible = ref(false)
const isUsingCpa = computed(() => !props.performanceMode || props.performanceMode === Targets.PerformanceMode.CPA)
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.group-row {
    @include container;
    @include br-24;
    @include mb-16;
    @include pointer;
    @include flex;
    @include items-center;
    @include pr-24;
    @include pl-20;
    @include pv-16;
    @include no-select;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
}
.group-row:last-child {
    @include mb-0;
}
.group-row:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}
.group-row:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}
.group-row .entity-icon {
    @include mr-12;
    width: 1.5rem;
    height: 1.5rem;
}
.group-row .entity-name {
    @include mr-64;
    @include relative;
    width: 100%;
    max-width: 25rem;
    overflow: hidden;
    white-space: nowrap;
    flex-shrink: 0;
}
.group-row .entity-name.roas {
    max-width: 23.5rem;
}
.group-row .entity-item {
    @include relative;
    @include flex;
    @include items-center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}
.group-row .entity-name::after,
.group-row .entity-item.cost::after,
.group-row .entity-item.conversions::after,
.group-row .entity-item.value::after,
.group-row .entity-item.roas::after,
.group-row .entity-item.cpa::after {
    content: '';
    @include absolute;
    right: 0;
    top: 0;
    width: 1.25rem;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
.group-row .entity-item.cost {
    max-width: 9.5rem;
}
.group-row .entity-item.conversions {
    max-width: 8rem;
}
.group-row .entity-item.value {
    max-width: 9rem;
}
.group-row .entity-item.cpa {
    max-width: 8rem;
}
.group-row .entity-item.roas {
    max-width: 8.5rem;
}
.group-row .entity-item.ad-score {
    max-width: 5.25rem;
    height: 100%;
}
.group-row .entity-item.has-drafts {
    max-width: 2.75rem;
    justify-content: flex-end;
}
.group-row .has-drafts-indicator {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 999px;
    background: rgba(0, 0, 0, 0.1);
}
.group-row .has-drafts-indicator.active {
    @include bg-opteo-blue;
}

// AdScore Popout

.ad-score-popout {
    @include pa-24;
}
.ad-score-popout-header {
    @include flex;
    @include items-center;
}
.ad-score-popout-content {
    @include container;
}
.ad-score-popout-row {
    @include flex;
    @include items-center;
    @include ph-24;
    @include pv-20;
    border-bottom: 1px solid $opteo-light-gray;
}
.ad-score-popout-row:last-child {
    border-bottom: none;
}
.ad-score-popout-row .popout-row-text {
    width: 13.375rem;
}

// Responsive
@media screen and (max-width: 1280px) {
    .group-row .entity-name {
        max-width: 20rem;
    }
    .group-row .entity-name.roas {
        max-width: 18.5rem;
    }
}
</style>
