import { computed, ref } from 'vue'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
} from '@/composition/improvement/useImprovement'

export const DEMO_IMP = {
    improvement_id: 123,
    title: 'Demo Improvement',
    body: {
        someBid: 123,
    },
    type: 'demo',
    rec_action: 'demo_improvement',
    last_updated: new Date().toDateString(),
    priority: 10000,
    created: new Date().toDateString(),
    location: [
        {
            entity: 'campaign',
            label: 'campaignName',
        },
        {
            entity: 'adgroup',
            label: 'adGroupName',
        },
    ],
}

export interface ImprovementData {
    someBid: number
}

export interface UseDemoImprovement {}

interface PushedData {
    somePushedData: number
}

export function useDemoImprovement(): UseImprovement<UseDemoImprovement> {
    const { lastUpdated, title } = useImprovement<UseDemoImprovement>()

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Doing something',
        'Confirming changes..',
        'Successfully did something',
    ])

    const onPush: OnPushHandler<PushedData> = () => {
        const pushedData: PushedData = {
            somePushedData: 123,
        }
        return {
            valid: true,
            pushedData,
        }
    }

    const pushActionText = ref('Do Something')

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        pushActionText,
    }
}
