<template>
    <div id="report-settings">
        <Text as="h3" size="f-2" weight="600" style="line-height: 2.625rem">
            {{ containerTitle }}
        </Text>
        <Spacer height="2rem" />
        <div class="report-settings-inner">
            <div class="ph4 pt4">
                <oInput
                    v-model="title"
                    :label="`Report Title (${title.length}/25)`"
                    placeholder="Google Ads Report"
                    :validator="validateTitle"
                />
                <Spacer />

                <Label>Report Period</Label>
                <ReportChangedInputWarning field="Report Period" :inputIsActive="datePickerOpen">
                    <DateRange
                        :presetDates="{
                            start: fromDate,
                            end: toDate,
                        }"
                        :long-date-format="true"
                        @apply="setNewDateRange"
                        v-model:popoverOpen="datePickerOpen"
                    />
                </ReportChangedInputWarning>
                <Spacer />
            </div>
            <Label class="pl4">Report Theme</Label>
            <ReportThemeSelector
                :themeName="newReportInfo.theme.typeface"
                @update-theme="updateThemeValue"
            />
            <Spacer />

            <Label class="pl4">Report Template</Label>
            <ReportTemplateSelector
                :templateId="templateId"
                @update-template="updateTemplateValue"
            />
            <Spacer height="2rem" />
        </div>
    </div>
</template>

<script lang="ts">
import { computed, ref } from 'vue'
import { Reports } from '@opteo/types'

import ReportChangedInputWarning from '@/components/Reports/ReportChangedInputWarning.vue'
import ReportThemeSelector from '@/components/Reports/ReportThemeSelector.vue'
import ReportTemplateSelector from '@/components/Reports/ReportTemplateSelector.vue'
import { useCreateReport } from '@/composition/reports/useCreateReport'
import format from 'date-fns/format'
import { oInput, oButton, Spacer, Text, DateRange, Label, Carousel } from '@opteo/components-next'

export default {
    name: 'reports',
    components: {
        ReportThemeSelector,
        ReportTemplateSelector,
        ReportChangedInputWarning,
        oInput,
        oButton,
        Spacer,
        Text,
        DateRange,
        Label,
        Carousel,
    },
    props: {
        containerTitle: {
            type: String,
            required: false,
            default: 'Create New Report',
        },
    },
    setup() {
        const {
            newReportInfo,
            fromDate,
            toDate,
            title,
            validateTitle,
            templateId,
            selectedTemplateId,
            setNewDateRange,
        } = useCreateReport()

        const datePickerOpen = ref(false)

        const updateThemeValue = (themeName: Reports.Core.ReportTypeface) => {
            if (!newReportInfo.value) {
                throw new Error('cannot set typeface before newReportInfo.value is set')
            }
            newReportInfo.value.theme.typeface = themeName
        }

        const updateTemplateValue = (_templateId: number) => {
            console.log('_templateId', _templateId)

            selectedTemplateId.value = _templateId
        }

        const periodString = computed(() => {
            if (!fromDate.value || !toDate.value) {
                return ''
            }
            const arrow = '\u2192'
            const start_date = format(fromDate.value, 'MMMM do yyyy')
            const end_date = format(toDate.value, 'MMMM do yyyy')
            return `${start_date}  ${arrow}  ${end_date}`
        })

        return {
            newReportInfo,
            title,
            templateId,
            periodString,
            fromDate,
            toDate,
            setNewDateRange,
            updateThemeValue,
            updateTemplateValue,
            validateTitle,
            datePickerOpen,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.report-settings-inner {
    @include container;
}
:deep(.selector-container) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}
</style>
