import { ref, computed } from 'vue'
import uniqBy from 'lodash-es/uniqBy'
import sortBy from 'lodash-es/sortBy'
import { Endpoint, authRequest, useAPI } from '@/composition/api/useAPI'

type SetVatNumber = HTMLDivElement & { setError: (message: string) => void }

import {
    InvoicesVatDataProps,
    InvoiceAddress,
    CountryTld,
} from '@/composition/billing/invoices/useInvoices'

import { VAT_COUNTRIES } from '@/lib/constants'

export function useInvoicesSettings(props: { vatData: InvoicesVatDataProps }) {
    const updatingVat = ref(false)
    const updatedVat = ref(false)
    const vatNumberField = ref<SetVatNumber>()
    const vatNumber = ref(props.vatData.vatCountry + props.vatData.vatNumber)
    const invoiceAddress = ref<InvoiceAddress>(props?.vatData?.invoiceAddress)

    async function updateAdminEmail() {
        await authRequest(Endpoint.UpdateInvoiceEmail, {
            body: { new_recipient_email: props.vatData.adminEmail },
        })
    }

    async function updateVatNumber() {
        if ((updatedVat.value && updatingVat.value) || !vatNumber.value) {
            return
        }
        updatingVat.value = true
        try {
            const { hasCountry, country } = validateVatCountry({ vatNumber: vatNumber.value })

            if (!hasCountry) {
                throw new Error('Invalid VAT Number.')
            }

            const fullVatNnumber = vatNumber.value.replace(/-/g, '').trim()

            const isValid = await authRequest(Endpoint.ValidateVatNumber, {
                body: { vat_number: fullVatNnumber },
            })
            if (!isValid) {
                throw new Error('Invalid VAT Number.')
            }

            await authRequest(Endpoint.UpdateVatNumber, {
                body: {
                    vat_country: country,
                    new_vat_number: fullVatNnumber.slice(2),
                },
            })
        } catch (error) {
            vatNumberField?.value?.setError('Invalid vat number')
            throw error
        }
    }

    function validateVatCountry({ vatNumber }: { vatNumber: string }) {
        const countryCode = vatNumber.slice(0, 2)
        const hasCountry = !!VAT_COUNTRIES.find(vatCountry => {
            return countryCode === vatCountry.country_code
        })
        return { hasCountry, country: countryCode }
    }

    async function updateAddress() {
        await authRequest(Endpoint.UpdateInvoiceAddressData, {
            body: {
                company_name: props.vatData.invoiceAddress.company_name ?? '',
                street_line_1: props.vatData.invoiceAddress.street_line_1 ?? '',
                street_line_2: props.vatData.invoiceAddress.street_line_2 ?? '',
                city: props.vatData.invoiceAddress.city ?? '',
                region: props.vatData.invoiceAddress.region ?? '',
                postal_code: props.vatData.invoiceAddress.postal_code ?? '',
                country: props.vatData.invoiceAddress.country ?? '',
                country_code: props.vatData.invoiceAddress.country_code ?? '',
            },
        })
    }

    async function updateVatForm() {
        await Promise.all([updateAdminEmail(), updateVatNumber(), updateAddress()])
    }

    const { data: countries } = useAPI<CountryTld[]>(Endpoint.GetCountryTld)

    function selectVatAddressCountry() {
        const newCountry = countries?.value?.find((c: CountryTld) => {
            return c.country_code === props.vatData.invoiceAddress.country_code
        })

        if (!newCountry) {
            return
        }
        props.vatData.invoiceAddress.country = newCountry.country
    }

    const countriesObject = computed(() => {
        return sortBy(
            uniqBy(
                countries?.value?.map((c: CountryTld) => {
                    return { value: c.country_code, label: c.country }
                }),
                'label'
            ),
            'label'
        )
    })

    return {
        updateAdminEmail,
        vatNumberField,
        updateVatNumber,
        updatingVat,
        updatedVat,
        vatNumber,
        updateAddress,
        invoiceAddress,
        countriesObject,
        selectVatAddressCountry,
        countries,
        updateVatForm,
    }
}
