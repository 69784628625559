<template>
    <div class="scorecard-list-item-wrapper">
        <div class="scorecard-list-item" tabindex="0" @click="goToSingleScorecard()">
            <div class="left">
                <ScorecardDonut
                    :score="score"
                    :strokeWidth="3"
                    :width="56"
                    compact
                    compact-text-size="1.25rem"
                    name="Overall Score"
                />
                <div class="title-container">
                    <Text as="h3" size="f-6" weight="600" class="title">Google Ads Scorecard</Text>
                    <Spacer height="0.5rem" />
                    <Text as="span" size="f-9" color="gray" class="subtitle">
                        Generated {{ createdDate }}
                    </Text>
                </div>
            </div>
            <div class="right">
                <div class="mini-scores">
                    <ScorecardListItemMiniScore
                        v-for="section in orderedSections"
                        :section="section"
                        :key="section.type"
                    />
                </div>
            </div>
        </div>
        <div class="actions">
            <!-- Profile Image -->
            <Tooltip
                v-if="isManuallyCreated"
                :content="`Created by ${createdBy.userFullName}`"
                :offset="[0, 10]"
            >
                <div class="profile">
                    <ProfileImage
                        v-if="createdBy.userFullName"
                        :image-url="createdBy.profileImageUrl ?? undefined"
                        :width="32"
                        :user-id="createdBy.userId"
                    />
                    <Skeleton v-else :width="32" :height="32" />
                </div>
            </Tooltip>
            <Tooltip v-else content="Automatically generated by Opteo" :offset="[0, 10]">
                <div class="profile">
                    <OpteoLogo :width="32" :height="32" />
                </div>
            </Tooltip>
            <!-- Delete Button -->
            <oButton
                color="white"
                :circle="true"
                size="small"
                class="delete-button"
                :disabled="!isManuallyCreated"
                @clicked="deleteScorecardClicked"
            >
                <template #icon>
                    <ScorecardBinIcon />
                </template>
            </oButton>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, computed, ref } from 'vue'
import sortBy from 'lodash-es/sortBy'
import { Scorecard } from '@opteo/types'

import formatDate from 'date-fns/format'
import ScorecardDonut from './ScorecardDonut.vue'
import ScorecardListItemMiniScore from './ScorecardListItemMiniScore.vue'
import ScorecardBinIcon from './ScorecardBinIcon.vue'
import { getSectionCopy, getMiniCopy } from './utils'

import Skeleton from '@/components/util/Skeleton.vue'

import animatedScorecardButton from './animatedScorecardButton.vue'

import {
    Text,
    Spacer,
    Delta,
    oButton,
    RightArrowIcon,
    ColorTag,
    CalendarIcon,
    Avatar,
    OpteoLogo,
    Tooltip,
    ProfileImage,
} from '@opteo/components-next'

export default defineComponent({
    name: 'ScorecardListItem',
    components: {
        Text,
        Spacer,
        ScorecardDonut,
        ScorecardListItemMiniScore,
        ScorecardBinIcon,
        CalendarIcon,
        Skeleton,
        Delta,
        oButton,
        RightArrowIcon,
        ColorTag,
        animatedScorecardButton,
        Avatar,
        OpteoLogo,
        Tooltip,
        ProfileImage,
    },
    props: {
        scorecardItem: {
            type: Object as PropType<Scorecard.ScorecardListItem>,
            required: true,
        },
        coverPageData: {
            type: Object as PropType<Scorecard.CoverPageData>,
            required: true,
        },
        isManuallyCreated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['delete-scorecard', 'scorecard-opened'],
    setup(props, { emit }) {
        const orderedSections = computed(() => {
            // sections array
            const sections = props.scorecardItem.sections.map(x => {
                return {
                    ...x,
                    copy: getSectionCopy(getMiniCopy(x.type, x.details), x.score),
                }
            })
            // ordered sections
            return sortBy(sections, section => Scorecard.SlideOrder.indexOf(section.type))
        })

        const score = computed(() => {
            return props.scorecardItem?.overallScore
        })

        function goToSingleScorecard() {
            emit('scorecard-opened')
        }

        const createdDate = computed(() => {
            return formatDate(new Date(props.scorecardItem.createdAt), 'MMMM do yyyy')
        })

        function deleteScorecardClicked() {
            emit('delete-scorecard')
        }

        const createdBy = computed(() => {
            return {
                userFullName: props.coverPageData.senderName ?? props.coverPageData.senderEmail,
                userId: props.coverPageData.userId,
                profileImageUrl: props.coverPageData.senderLogoUrl,
            }
        })

        return {
            orderedSections,
            score,
            goToSingleScorecard,
            createdDate,
            deleteScorecardClicked,
            createdBy,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.scorecard-list-item-wrapper {
    @include relative;
}
.scorecard-list-item {
    @include w-100;
    @include container;
    border-radius: 2rem;
    @include ph-28;
    @include pt-24;
    padding-bottom: 1.375rem;
    @include pointer;
    @include flex;
    @include items-center;
    @include justify-between;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    outline: none;
    border: none;
}
.scorecard-list-item:focus,
.scorecard-list-item:active {
    box-shadow: $opteo-shadow-focus;
}
.scorecard-list-item:active {
    transform: translateY(1px);
}
.scorecard-list-item .left {
    @include flex;
    @include items-center;
    gap: 1.25rem;
}
.scorecard-list-item .right {
    @include flex;
    @include items-center;
    gap: 2rem;
    margin-right: 7.75rem;
}

.mini-scores {
    @include flex;
    @include items-center;
    gap: 1rem;
}
.actions {
    @include flex;
    @include items-center;
    gap: 1.25rem;
}

.title {
    font-size: 1.1875rem;
    letter-spacing: -0.02rem;
    line-height: 0.9375rem;
}
.subtitle {
    @include inline-block;
    line-height: 0.6875rem;
    letter-spacing: -0.005rem;
}

.actions {
    @include absolute;
    top: 0;
    right: 1.75rem;
    top: 50%;
    transform: translateY(-50%);
}

:deep(.delete-button) {
    width: 2.75rem !important;
    height: 2.75rem !important;
}
</style>
