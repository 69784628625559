import initialize from './init'
import { LOAD_PROFITWELL } from '@/lib/env'

let initialised = false
export function useProfitwell() {
    if (!initialised && LOAD_PROFITWELL) {
        initialize()
        initialised = true
    }

    function profitwellIdentify(email: string) {
        if (!LOAD_PROFITWELL) {
            return
        }
        if (!(<any>window).profitwell) {
            console.warn('called profitwell identify() before profitwell exists')
        }

        ;(<any>window).profitwell('user_email', email)
        ;(<any>window).profitwell('cq_get')
    }

    return { profitwellIdentify }
}
