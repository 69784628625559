import { Entity } from './keyword-destination-types'

export function updateCheckedValues(items: Entity[], entity: Entity): Entity[] {
    return items.map(item => {
        if (item.id === entity.id && item.type === entity.type) {
            return { ...item, checked: !item.checked }
        }
        if (item.children) {
            return { ...item, children: updateCheckedValues(item.children, entity) }
        }
        return item
    })
}

export function isEntityVisible(entity: Entity, searchQuery: string) {
    return !searchQuery || (searchQuery.length && searchQuery.includes(entity.label.toLowerCase()))
}
