<template>
    <div class="report-preferences">
        <!-- Scheduled Reports -->
        <div class="report-preference-section">
            <Text as="h4" weight="600" size="f-5" style="letter-spacing: -0.0375rem">
                Scheduled Reports
            </Text>
            <Spacer height="2rem" />
            <ReportScheduleList :schedules="schedules" @cancel-schedule="removeSchedule" />
        </div>
        <Spacer height="3rem" />

        <!-- Default Theme -->
        <div class="report-preference-section">
            <Text as="h4" weight="600" size="f-5" style="letter-spacing: -0.0375rem">
                Default Theme
            </Text>
            <Spacer height="2rem" />
        </div>
        <ReportThemeSelector :themeName="selectedTheme" @update-theme="updateThemeValue" />
        <Spacer height="3rem" />

        <!-- Default Template -->
        <div class="report-preference-section">
            <div class="default-template-header">
                <Text as="h4" weight="600" size="f-5" style="letter-spacing: -0.0375rem">
                    Default Template
                </Text>
            </div>
            <Spacer height="2rem" />
        </div>
        <ReportTemplateSelector
            v-if="selectedTemplateId"
            :templateId="selectedTemplateId"
            @update-template="updateTemplateValue"
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import {
    oButton,
    Text,
    Spacer,
    ViewToggle,
    Modal,
    Label,
    Popout,
    oInput,
} from '@opteo/components-next'
import { Reports } from '@opteo/types'

import ReportThemeSelector from '@/components/Reports/ReportThemeSelector.vue'
import ReportTemplateSelector from '@/components/Reports/ReportTemplateSelector.vue'
import ReportScheduleList from '@/components/Reports/ReportScheduleList.vue'

import { useReportPreferences } from '@/composition/reports/useReportPreferences'

export default defineComponent({
    components: {
        ReportThemeSelector,
        ReportTemplateSelector,
        ReportScheduleList,
        Popout,
        oInput,
        oButton,
        Text,
        Spacer,
        ViewToggle,
        Modal,
        Label,
    },
    name: 'ReportPreferences',
    setup() {
        const settingsModalOpen = ref(false)
        const {
            preferences,
            updateDefaultTemplate,
            updateDefaultTheme,
            schedules,
            removeSchedule,
            loading,
        } = useReportPreferences()

        const schedulesExist = computed(() => !!schedules.value?.length)

        const selectedTemplateId = computed(() => preferences.value?.template_id)
        const updateTemplateValue = async (newTemplateId: number) => {
            await updateDefaultTemplate(newTemplateId)
        }

        const createTemplatePopout = ref(false)

        const selectedTheme = computed(() => preferences.value?.typeface ?? 'professional')

        const updateThemeValue = async (newTheme: Reports.Core.ReportTypeface) => {
            await updateDefaultTheme(newTheme)
        }

        return {
            settingsModalOpen,
            selectedTemplateId,
            updateTemplateValue,
            schedules,
            schedulesExist,
            removeSchedule,
            createTemplatePopout,
            selectedTheme,
            updateThemeValue,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.report-preferences {
    @include pv-48;
}
.report-preference-section {
    margin: 0 auto;
    max-width: 540px;
}
.default-template-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
:deep(.selector-container) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}
</style>
