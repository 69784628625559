<template>
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m9 1.5-4 4-4-4"
            :stroke="hex"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script lang="ts">
export default {
    props: {
        hex: {
            type: String,
            required: false,
            default: '#CACAD3',
        },
    },
}
</script>
