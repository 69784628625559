<template>
    <component
        v-for="report of reports"
        :report="report"
        :is="viewMode === 'grid' ? 'ReportGridItem' : 'ReportRowItem'"
        :key="report.report_id"
    />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ReportGridItem from '@/components/Reports/ReportGridItem.vue'
import ReportRowItem from '@/components/Reports/ReportRowItem.vue'
import { Reports } from '@opteo/types'
import { useReportsList } from '@/composition/reports/useReportsList'

export default defineComponent({
    components: { ReportGridItem, ReportRowItem },
    name: 'ReportItems',
    props: {
        reports: {
            type: Object as PropType<Reports.Core.ReportsList>,
            required: true,
        },
    },
    setup: props => {
        const { viewMode } = useReportsList()

        return {
            viewMode,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
</style>
