<template>
    <PerformanceFullScreenPreview
        v-if="fullScreenChart"
        :chart="fullScreenChart"
        @close-full-screen="fullScreenChart = null"
    />

    <PageContent>
        <!-- Row Layout -->
        <div v-if="selectedLayoutOption === Layout.ROWS || width < 1241" class="chart-rows-wrapper">
            <div style="width: 100%">
                <div v-if="chartDataLoading">
                    <div v-for="number in 12" v-bind:key="number" class="chart-wrapper">
                        <PerformanceChartContainer
                            :skeleton="true"
                            :skeleton-height="aboveMobile ? 300 : 210"
                            :show-expand-button="true"
                        />
                        <Spacer height="2.125rem" />
                    </div>
                </div>
                <div
                    v-else
                    v-for="chart in allVisibleCharts"
                    v-bind:key="chart.label"
                    class="chart-wrapper"
                >
                    <PerformanceChartContainer
                        :chart-label="chart.label"
                        :total="chart.total"
                        :delta="chart.delta"
                        :current-values="chart.currentValues"
                        @preview-opened="openFullScreenPreview(chart)"
                        :show-expand-button="true"
                        :noDataAvailable="!chart.chartData.length"
                        :skeleton-height="aboveMobile ? 300 : 210"
                    >
                        <div class="spinner-container">
                            <Spinner v-if="chartDataValidating" class="spinner" />
                        </div>
                        <span v-if="chart.chartType === ChartType.LINE">
                            <LineChart
                                disable-fallback
                                v-if="chart.chartData.length"
                                :series="chart.chartData"
                                :metric="chart.chartMetric"
                                :show-zero="chart.showZero"
                                :horizontal-markers="chart.chartMarkers"
                                :max-item="chart.maxItem"
                                :chart-height="aboveMobile ? 300 : 160"
                            />
                        </span>

                        <span v-else-if="chart.chartType === ChartType.AREA">
                            <AreaChart
                                disable-fallback
                                v-if="chart.chartData.length"
                                :series="chart.chartData"
                                :metric="chart.chartMetric"
                                :show-zero="chart.showZero"
                                :horizontal-markers="chart.chartMarkers"
                                :max-item="chart.maxItem"
                                :chart-height="aboveMobile ? 300 : 160"
                            />
                        </span>
                    </PerformanceChartContainer>
                    <Spacer height="2.125rem" />
                </div>
            </div>
        </div>
        <!-- Grid Layout -->
        <!--
            Note: We have to use two columns made up of `display: block` elements for Safari. 
            Chart tooltips lag significantly in Safari when positioned inline, within a grid
            container, etc. In the left column, even numbered graphs are hidden, in the right
            column, odd numbered graphs are hidden (both in CSS).
        -->
        <div
            v-else-if="selectedLayoutOption === Layout.GRID && width > 1240"
            class="chart-grid-wrapper"
        >
            <!-- Column One -->
            <div v-if="chartDataLoading" class="chart-column left">
                <div v-for="number in 12" v-bind:key="number" class="chart-wrapper">
                    <PerformanceChartContainer
                        :skeleton="true"
                        :skeleton-height="aboveMobile ? 350 : 210"
                        :show-expand-button="true"
                    />
                </div>
            </div>
            <div v-else class="chart-column left">
                <div
                    v-for="chart in allVisibleCharts"
                    v-bind:key="chart.label"
                    class="chart-wrapper"
                >
                    <PerformanceChartContainer
                        :chart-label="chart.label"
                        :total="chart.total"
                        :delta="chart.delta"
                        :current-values="chart.currentValues"
                        @preview-opened="openFullScreenPreview(chart)"
                        :show-expand-button="true"
                        :noDataAvailable="!chart.chartData.length"
                        :skeleton-height="aboveMobile ? 350 : 210"
                    >
                        <div class="spinner-container">
                            <Spinner v-if="chartDataValidating" class="spinner" />
                        </div>
                        <span v-if="chart.chartType === ChartType.LINE">
                            <LineChart
                                disable-fallback
                                v-if="chart.chartData.length"
                                :series="chart.chartData"
                                :metric="chart.chartMetric"
                                :show-zero="chart.showZero"
                                :horizontal-markers="chart.chartMarkers"
                                :max-item="chart.maxItem"
                                :chart-height="aboveMobile ? 300 : 160"
                            />
                        </span>

                        <span v-else-if="chart.chartType === ChartType.AREA">
                            <AreaChart
                                disable-fallback
                                v-if="chart.chartData.length"
                                :series="chart.chartData"
                                :metric="chart.chartMetric"
                                :show-zero="chart.showZero"
                                :horizontal-markers="chart.chartMarkers"
                                :max-item="chart.maxItem"
                                :chart-height="aboveMobile ? 300 : 160"
                            />
                        </span>
                    </PerformanceChartContainer>
                </div>
            </div>
            <!-- Spacer -->
            <Spacer width="2.125rem" />
            <!-- Column Two -->
            <div v-if="chartDataLoading" class="chart-column right">
                <div v-for="number in 12" v-bind:key="number" class="chart-wrapper">
                    <PerformanceChartContainer
                        :skeleton="true"
                        :skeleton-height="aboveMobile ? 350 : 210"
                        :show-expand-button="true"
                    />
                </div>
            </div>
            <div v-else class="chart-column right">
                <div
                    v-for="chart in allVisibleCharts"
                    v-bind:key="chart.label"
                    class="chart-wrapper"
                >
                    <PerformanceChartContainer
                        :chart-label="chart.label"
                        :total="chart.total"
                        :delta="chart.delta"
                        :current-values="chart.currentValues"
                        @preview-opened="openFullScreenPreview(chart)"
                        :show-expand-button="true"
                        :noDataAvailable="!chart.chartData.length"
                        :skeleton-height="aboveMobile ? 350 : 210"
                    >
                        <div class="spinner-container">
                            <Spinner v-if="chartDataValidating" class="spinner" />
                        </div>
                        <span v-if="chart.chartType === ChartType.LINE">
                            <LineChart
                                disable-fallback
                                v-if="chart.chartData.length"
                                :series="chart.chartData"
                                :metric="chart.chartMetric"
                                :show-zero="chart.showZero"
                                :horizontal-markers="chart.chartMarkers"
                                :max-item="chart.maxItem"
                                :chart-height="aboveMobile ? 300 : 160"
                            />
                        </span>

                        <span v-else-if="chart.chartType === ChartType.AREA">
                            <AreaChart
                                disable-fallback
                                v-if="chart.chartData.length"
                                :series="chart.chartData"
                                :metric="chart.chartMetric"
                                :show-zero="chart.showZero"
                                :horizontal-markers="chart.chartMarkers"
                                :max-item="chart.maxItem"
                                :chart-height="aboveMobile ? 300 : 160"
                            />
                        </span>
                    </PerformanceChartContainer>
                </div>
            </div>
        </div>
    </PageContent>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useWindowSize } from '@vueuse/core'
import PageContent from '@/layouts/PageContent.vue'
import {
    Spacer,
    Spinner,
    LineChart,
    AreaChart,
    PerformanceChartContainer,
} from '@opteo/components-next'
import { useGraphs } from '@/composition/performance/useGraphs'
import { Layout, ChartType } from '@/composition/performance/types'
import PerformanceFullScreenPreview from '@/components/Performance/PerformanceFullScreenPreview.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default defineComponent({
    name: 'PerformanceGraphs',
    setup() {
        const useGraphsConfig = useGraphs()
        const { width, height } = useWindowSize()
        const { aboveMobile } = useMediaQuery()

        return {
            // useGraphsConfig,
            ...useGraphsConfig,
            Layout,
            ChartType,
            width,
            height,
            aboveMobile,
        }
    },
    components: {
        Spacer,
        Spinner,
        LineChart,
        AreaChart,
        PageContent,
        PerformanceChartContainer,
        PerformanceFullScreenPreview,
        Skeleton,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.chart-grid-wrapper {
    display: flex;
    align-items: flex-start;
    // gap: 2.125rem;
}

.spinner-container {
    position: relative;
}

.spinner {
    position: absolute;
    right: 5.75rem;
    top: -4.125rem;
    z-index: 100;
}

.chart-column .chart-wrapper {
    margin-bottom: 2.125rem;
    display: block;
}

.chart-column.left {
    width: calc(50% - 1.0625rem);
}

.chart-column.right {
    width: calc(50% - 1.0625rem);
}

.chart-column.left .chart-wrapper:nth-child(even) {
    display: none;
}

.chart-column.right .chart-wrapper:nth-child(odd) {
    display: none;
}

@media screen and (max-width: 72.5rem) {
    .chart-column .chart-wrapper {
        width: 100%;
        margin-right: 0;
    }
}
</style>
