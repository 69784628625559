import { computed, ComputedRef, Ref, ref } from 'vue'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { Endpoint, useAPI } from '../../api/useAPI'

import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useDomain } from '@/composition/domain/useDomain'
import type { ImprovementStatistic } from '.'

interface SharedSet {
    shared_set_id: number
    shared_set_name: string
    shared_set_resource_name: string
}

type DropDownItem = { label: string; value: string }

interface AddNegativeListShoppingBody {
    word: string
    sources: string[]
    conversions: number
    clicks: number
    cost: number
    cpa: number
    campaign_id: string
    average_cpa: number
    target_cpa: number
    campaign_group: string
}

interface UseAddNegativeListShopping {
    keyword: string
    campaignGroupName: string
    copy: { conversions: string; targetOrAverage: string }
    conversions: number
    cost: number
    cpa: number
    targetedCpa: number
    improvementStatistics: ImprovementStatistic[]
    searchTerms: string[]
    sharedSets: ComputedRef<DropDownItem[]>
    defaultSharedSet: string
    selectedSharedSet: Ref<string>
    sharedNegativeListsLoading: Ref<boolean>
    currency: ComputedRef<string | undefined>
}

const defaultSharedSetName = 'Keyword negatives for shopping campaigns (auto_gen_shopping)'

export function useAddNegativeListShopping(): UseImprovement<UseAddNegativeListShopping> {
    const { currencyCode: currency, domainId } = useDomain()
    const { improvement, lastUpdated, title } = useImprovement<AddNegativeListShoppingBody>()
    const {
        body: {
            word: keyword,
            campaign_group: campaignGroupName,
            conversions,
            cost,
            cpa,
            target_cpa: targetCpa,
            average_cpa: averageCpa,
            sources: searchTerms,
        },
    } = checkImprovement<AddNegativeListShoppingBody>(improvement)

    const targetedCpa = targetCpa || averageCpa

    const { data: sharedNegativeLists, loading: sharedNegativeListsLoading } = useAPI<SharedSet[]>(
        Endpoint.GetSharedNegativeLists,
        { uniqueId: () => domainId.value, waitFor: () => domainId.value }
    )

    const defaultSharedSet = defaultSharedSetName
    const selectedSharedSet = ref(defaultSharedSetName)

    const sharedSets: ComputedRef<DropDownItem[]> = computed(() => {
        if (!sharedNegativeLists.value) {
            return [{ label: defaultSharedSetName, value: defaultSharedSetName }]
        }

        const sharedSets = sharedNegativeLists.value.map(sharedSet => {
            return {
                value: sharedSet.shared_set_resource_name,
                label: sharedSet.shared_set_name,
            }
        })

        const opteoSharedSet = sharedSets.find(sharedSet => sharedSet.label === defaultSharedSet)

        if (opteoSharedSet) {
            selectedSharedSet.value = opteoSharedSet.value
        } else {
            sharedSets.unshift({ label: defaultSharedSetName, value: defaultSharedSetName })
        }

        return sharedSets
    })

    const copy = {
        conversions: conversions === 1 ? 'conversion' : 'conversions',
        targetOrAverage: targetCpa ? 'target' : 'average',
    }

    const improvementStatistics: ImprovementStatistic[] = [
        {
            key: 'cost',
            value: useDomainMoney({ value: cost }).value.displayValue.value,
            title: 'Cost',
        },
        {
            key: 'conversions',
            value: conversions.toString(),
            title: 'Conversions',
        },
        {
            key: 'wordCPA',
            value: useDomainMoney({ value: cpa }).value.displayValue.value,
            title: 'Word CPA',
        },
        {
            key: 'targetCPA',
            value: useDomainMoney({ value: targetedCpa }).value.displayValue.value,
            title: 'Target CPA',
        },
    ]

    const pushActionText = ref('Add Negative Keyword')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding negative keyword..',
        'Confirming changes..',
        'Successfully added negative keyword.',
    ])

    const onPush: OnPushHandler<SharedSet> = () => {
        if (selectedSharedSet.value === defaultSharedSetName) {
            return {
                valid: true,
            }
        }

        const sharedSet = computed(() => {
            if (sharedNegativeLists.value) {
                return sharedNegativeLists.value.find(
                    sharedSet => sharedSet.shared_set_resource_name === selectedSharedSet.value
                )
            }
        })

        return { valid: true, pushedData: sharedSet.value }
    }

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        keyword,
        campaignGroupName,
        copy,
        conversions,
        cost,
        cpa,
        targetedCpa,
        improvementStatistics,
        searchTerms,
        sharedSets,
        defaultSharedSet,
        selectedSharedSet,
        sharedNegativeListsLoading,
        currency,
        pushActionText,
    }
}
