import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { Routes } from '@/router/routes'
import { delay } from '@opteo/promise'
import { SmartBiddingExperiments } from '@opteo/types'
import { enums } from '@opteo/types/gads'
import { computed, ref, Ref } from 'vue'
import {
    useExperiments,
    CampaignTableItem,
    displayBiddingStrategiesSelectItems,
    searchBiddingStrategiesSelectItems,
} from './useExperiments'
import { useRouter } from 'vue-router'

export function useCreateFlow() {
    const {
        selectedCampaigns,
        baseCampaignTableHeaders,
        eligibleCampaignsTableItems,
        allBiddingStrategiesSelectItems,
        currencySymbol,
        mutateGetCampaignsAndExperiments,
        mutateGetAccountExperimentData,
        clearSelectedCampaigns,
        experimentCreationFormBase,
        searchExperimentCreationForm,
        displayExperimentCreationForm,
        campaignsAndExperiments,
        isAdvertisingChannelTypeSearch,
        latestCreatedExperimentId,
        isRecommendation,
    } = useExperiments()

    const { push } = useRouter()

    if (selectedCampaigns.value.length === 0) {
        push({ name: Routes.ToolkitSmartBiddingExperimentsRecommendations })
    }

    const createExperimentLoading = ref()
    const createExperimentButton = ref()
    const createExperimentErrors: Ref<string[]> = ref([])

    const createExperiment = async function () {
        createExperimentLoading.value = true

        const selectedCampaignIds = selectedCampaigns.value
            .filter(item => item.selected)
            .map(item => item.id)
        const selectedCampaignItems = eligibleCampaignsTableItems.value?.filter(item =>
            selectedCampaignIds.includes(item.id)
        )

        if (!selectedCampaignItems || selectedCampaignItems.length === 0) {
            // Should be impossible
            throw new Error('Please select at least one campaign')
        }

        if (
            selectedCampaignItems[0].advertisingChannelType !==
                enums.AdvertisingChannelType.DISPLAY &&
            selectedCampaignItems[0].advertisingChannelType !== enums.AdvertisingChannelType.SEARCH
        ) {
            // Should be impossible
            throw new Error('The selected campaigns must be of type Search or Display')
        }

        function generateSettings(): SmartBiddingExperiments.ExperimentSettings.ExperimentSettings {
            if (isAdvertisingChannelTypeSearch.value) {
                switch (searchExperimentCreationForm.value.experimentBiddingStrategy) {
                    case enums.BiddingStrategyType.TARGET_IMPRESSION_SHARE:
                        return {
                            experimentBiddingStrategy:
                                searchExperimentCreationForm.value.experimentBiddingStrategy,
                            targetImpressionShareLocation:
                                searchExperimentCreationForm.value.targetImpressionShareLocation,
                            impressionSharePercent:
                                (searchExperimentCreationForm.value
                                    .targetImpressionSharePercentage as number) / 100,
                            maxCpcBidLimit:
                                typeof searchExperimentCreationForm.value
                                    .targetImpressionShareMaxCpcBidLimit === 'number'
                                    ? searchExperimentCreationForm.value
                                          .targetImpressionShareMaxCpcBidLimit
                                    : undefined,
                        }
                    case enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE:
                        return {
                            experimentBiddingStrategy:
                                searchExperimentCreationForm.value.experimentBiddingStrategy,
                            isTargetRoas: false,
                        }
                    case enums.BiddingStrategyType.TARGET_ROAS:
                        return {
                            experimentBiddingStrategy:
                                enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE,
                            isTargetRoas: true,
                            targetRoas:
                                (searchExperimentCreationForm.value
                                    .maximiseConversionValueTargetRoas as number) / 100,
                            maxCpcBidLimit:
                                typeof searchExperimentCreationForm.value
                                    .maximiseConversionValueMaxCpcBidLimit === 'number'
                                    ? searchExperimentCreationForm.value
                                          .maximiseConversionValueMaxCpcBidLimit
                                    : undefined,
                        }

                    case enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS:
                        return {
                            experimentBiddingStrategy:
                                searchExperimentCreationForm.value.experimentBiddingStrategy,
                            isTargetCpa: false,
                        }
                    case enums.BiddingStrategyType.TARGET_CPA:
                        return {
                            experimentBiddingStrategy:
                                enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS,
                            isTargetCpa: true,
                            targetCpa: searchExperimentCreationForm.value
                                .maximiseConversionsTargetCpa as number,
                            maxCpcBidLimit:
                                typeof searchExperimentCreationForm.value
                                    .maximiseConversionsMaxCpcBidLimit === 'number'
                                    ? searchExperimentCreationForm.value
                                          .maximiseConversionsMaxCpcBidLimit
                                    : undefined,
                        }

                    case enums.BiddingStrategyType.TARGET_SPEND:
                        if (searchExperimentCreationForm.value.targetSpendSetMaxCpc) {
                            return {
                                experimentBiddingStrategy:
                                    searchExperimentCreationForm.value.experimentBiddingStrategy,
                                setMaxCpc: searchExperimentCreationForm.value.targetSpendSetMaxCpc,
                                maxCpc: searchExperimentCreationForm.value
                                    .targetSpendMaxCpc as number,
                            }
                        }
                        return {
                            experimentBiddingStrategy:
                                searchExperimentCreationForm.value.experimentBiddingStrategy,
                            setMaxCpc: searchExperimentCreationForm.value.targetSpendSetMaxCpc,
                        }
                    case enums.BiddingStrategyType.MANUAL_CPC:
                        return {
                            experimentBiddingStrategy:
                                searchExperimentCreationForm.value.experimentBiddingStrategy,
                            useEnhancedCpc:
                                searchExperimentCreationForm.value.manualCpcUseEnhancedCpc,
                            maxAdGroupCpcBidLimit: searchExperimentCreationForm.value
                                .manualCpcMaxAdGroupCpcBidLimit as number,
                        }
                    default:
                        // Should be impossible because of form validation
                        throw new Error('Please select an experiment bidding strategy')
                }
            } else {
                switch (displayExperimentCreationForm.value.experimentBiddingStrategy) {
                    case enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE:
                        return {
                            experimentBiddingStrategy:
                                displayExperimentCreationForm.value.experimentBiddingStrategy,
                        }
                    case enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS:
                        return {
                            experimentBiddingStrategy:
                                displayExperimentCreationForm.value.experimentBiddingStrategy,
                        }
                    case enums.BiddingStrategyType.TARGET_SPEND:
                        if (displayExperimentCreationForm.value.targetSpendSetMaxCpc) {
                            return {
                                experimentBiddingStrategy:
                                    displayExperimentCreationForm.value.experimentBiddingStrategy,
                                setMaxCpc: displayExperimentCreationForm.value.targetSpendSetMaxCpc,
                                maxCpc: displayExperimentCreationForm.value
                                    .targetSpendMaxCpc as number,
                            }
                        }
                        return {
                            experimentBiddingStrategy:
                                displayExperimentCreationForm.value.experimentBiddingStrategy,
                            setMaxCpc: displayExperimentCreationForm.value.targetSpendSetMaxCpc,
                        }
                    case enums.BiddingStrategyType.TARGET_ROAS:
                        return {
                            experimentBiddingStrategy:
                                displayExperimentCreationForm.value.experimentBiddingStrategy,
                            targetRoas:
                                (displayExperimentCreationForm.value.targetRoas as number) / 100,
                        }
                    case enums.BiddingStrategyType.TARGET_CPA:
                        return {
                            experimentBiddingStrategy:
                                displayExperimentCreationForm.value.experimentBiddingStrategy,
                            targetCpa: displayExperimentCreationForm.value.targetCpa as number,
                        }
                    case enums.BiddingStrategyType.MANUAL_CPC:
                        return {
                            experimentBiddingStrategy:
                                displayExperimentCreationForm.value.experimentBiddingStrategy,
                            useEnhancedCpc:
                                displayExperimentCreationForm.value.manualCpcUseEnhancedCpc,
                            maxAdGroupCpcBidLimit: displayExperimentCreationForm.value
                                .manualCpcMaxAdGroupCpcBidLimit as number,
                        }
                    default:
                        // Should be impossible because of form validation
                        throw new Error('Please select an experiment bidding strategy')
                }
            }
        }

        try {
            const {
                experimentName,
                experimentDescription,
                experimentBaseBudgetAllocation,
                experimentDurationDays,
            } = experimentCreationFormBase.value

            if (experimentName === '') {
                // Should be impossible because of form validation
                throw new Error('Please enter an experiment name')
            }

            const settings = generateSettings()

            const request: SmartBiddingExperiments.CreateExperimentRequest<SmartBiddingExperiments.ExperimentSettings.ExperimentSettings> =
                {
                    experimentName,
                    experimentDescription,
                    currentBiddingStrategy: selectedCampaignItems[0].rawStrategy,
                    experimentBaseBudgetAllocation,
                    experimentDurationDays,
                    campaignIds: selectedCampaignIds,
                    advertisingChannelType: selectedCampaignItems[0].advertisingChannelType,
                    settings,
                    isRecommendation: isRecommendation.value,
                }

            const response: SmartBiddingExperiments.CreateExperimentResponse =
                await authRequest<SmartBiddingExperiments.CreateExperimentResponse>(
                    Endpoint.CreateExperimentRequest,
                    { body: { request } }
                )

            if (response.succeeded) {
                if (!campaignsAndExperiments.value) {
                    throw new Error('campaigns and experiments is undefined')
                }

                const newData = {
                    eligibleCampaigns: campaignsAndExperiments.value.eligibleCampaigns.filter(
                        item => !selectedCampaignIds.includes(item.campaignId)
                    ),
                    ineligibleCampaigns: [
                        ...campaignsAndExperiments.value.ineligibleCampaigns,
                        ...campaignsAndExperiments.value.eligibleCampaigns.filter(item =>
                            selectedCampaignIds.includes(item.campaignId)
                        ),
                    ],
                    experimentRecommendations:
                        campaignsAndExperiments.value.experimentRecommendations.filter(
                            recommendation =>
                                !recommendation.campaigns.some(recommendationCampaign =>
                                    selectedCampaignIds.includes(recommendationCampaign.campaignId)
                                )
                        ),
                }

                mutateGetCampaignsAndExperiments(() => newData)

                await mutateGetAccountExperimentData()

                latestCreatedExperimentId.value = response.experimentId

                createExperimentLoading.value = false

                createExperimentButton.value.flashSuccess()

                await delay(500)

                clearSelectedCampaigns()

                createExperimentErrors.value = []

                push({ name: Routes.ToolkitSmartBiddingExperimentsActive })
            } else {
                throw new Error(response.error)
            }
        } catch (e: any) {
            if (!createExperimentErrors.value.includes(e.message)) {
                createExperimentErrors.value.push(e.message)
            }

            createExperimentLoading.value = false

            createExperimentButton.value.flashError()
        }
    }

    const toggleSelectedCampaign = (campaign: CampaignTableItem) => {
        const relevantCampaign = selectedCampaigns.value.find(item => item.id === campaign.id)

        if (!relevantCampaign) {
            return
        }

        relevantCampaign.selected = !relevantCampaign?.selected
    }

    const canSubmitForm = computed(() => selectedCampaigns.value.some(item => item.selected))

    const currentBiddingStrategy = computed(() => selectedCampaigns.value[0]?.rawStrategy)

    const targetImpressionShareLocationSelectItems = [
        {
            value: enums.TargetImpressionShareLocation.ABSOLUTE_TOP_OF_PAGE,
            label: 'Absolute top of results page',
        },
        {
            value: enums.TargetImpressionShareLocation.ANYWHERE_ON_PAGE,
            label: 'Anywhere on results page',
        },
        {
            value: enums.TargetImpressionShareLocation.TOP_OF_PAGE,
            label: 'Top of results page',
        },
    ]

    const experimentBudgetAllocation = computed(() => {
        const { experimentBaseBudgetAllocation } = experimentCreationFormBase.value
        if (
            isNaN(experimentBaseBudgetAllocation) ||
            experimentBaseBudgetAllocation < 1 ||
            experimentBaseBudgetAllocation > 99
        ) {
            return undefined
        }

        return 100 - experimentBaseBudgetAllocation
    })

    return {
        experimentCreationFormBase,
        searchExperimentCreationForm,
        displayExperimentCreationForm,
        selectedCampaigns,
        baseCampaignTableHeaders,
        toggleSelectedCampaign,
        createExperiment,
        canSubmitForm,
        isAdvertisingChannelTypeSearch,
        displayBiddingStrategiesSelectItems,
        searchBiddingStrategiesSelectItems,
        currentBiddingStrategy,
        allBiddingStrategiesSelectItems,
        targetImpressionShareLocationSelectItems,
        currencySymbol,
        experimentBudgetAllocation,
        createExperimentLoading,
        createExperimentButton,
        createExperimentErrors,
    }
}
