<template>
    <div class="campaign-cost-container">
        <EntityPill :type="entityType" :content="entityLabel" />
        <Spacer width="1rem" />
        <div :class="['total-cost-tab', { disabled: cost === null }]">
            <Label class="label" noMargin>Cost</Label>
            <Text as="h6">
                <Money :currency="currency" :value="cost" />
            </Text>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
    Text,
    Spacer,
    Money,
    EntityPill,
    Label,
    // @ts-ignore
} from '@opteo/components-next'

export default defineComponent({
    name: 'BreakdownTableHeader',
    props: {
        entityLabel: {
            type: String,
        },
        entityType: {
            type: String,
            required: false,
            default: 'campaign',
        },
        currency: {
            type: String,
            required: true,
        },
        cost: {
            type: Number,
        },
    },
    components: {
        Text,
        Spacer,
        Money,
        EntityPill,
        Label,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaign-cost-container {
    @include flex;
    // @include justify-between;
    @include items-center;
}
.campaign-cost-container .total-cost-tab {
    @include container;
    @include bg-opteo-white;
    @include br-12;
    @include flex-center;
    height: 2.75rem;
    padding: 0 0.9375rem;
}
.campaign-cost-container .total-cost-tab h6 {
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: -0.0175rem;
    transform: translateY(-1px);
    margin-left: 0.625rem;
    white-space: nowrap;
}
.campaign-cost-container .total-cost-tab.disabled .label,
.campaign-cost-container .total-cost-tab.disabled h6 {
    color: $opteo-medium-gray;
}
</style>
