<template>
    <div class="br3 shadow-card-ns">
        <div class="overflow-x-scroll">
            <table v-if="invoices.length" class="f6 w-100" cellspacing="0">
                <thead>
                    <tr>
                        <th
                            class="bb b--opteo-near-white tl v-mid h3 pl4-ns pl-24 invoice-date"
                            style="min-width: 172px"
                        >
                            <span class="small-caps"> Invoice Date </span>
                        </th>
                        <th
                            class="bb b--opteo-near-white tl v-mid h3 pr3 invoice-total"
                            style="min-width: 100px"
                        >
                            <span class="small-caps"> Total </span>
                        </th>
                        <th
                            class="bb b--opteo-near-white tl v-mid h3 pr3 invoice-type"
                            style="min-width: 116px"
                        >
                            <span class="small-caps"> Type </span>
                        </th>
                        <th
                            class="bb b--opteo-near-white tl v-mid h3 pr-24 invoice-download"
                            style="min-width: 140px"
                        >
                            <div class="flex items-center">
                                <span class="small-caps">Download</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="invoices.length" class="lh-copy">
                    <tr v-for="invoice of invoices" :key="`${invoice.id}-${invoice.state}`">
                        <td
                            class="pl4-ns pl-24 v-mid h3 bt b--opteo-near-white tl fw5 f6 invoice-date"
                        >
                            {{ invoice.issue_date }}
                        </td>
                        <td class="v-mid h3 pr4 bt b--opteo-near-white tl fw5 f6">
                            <Ent><Money currency="USD" :value="invoice.total" /></Ent>
                        </td>
                        <td class="v-mid h3 pr4 bt b--opteo-near-white tl fw5 f6">
                            {{ invoice.state }}
                        </td>
                        <td class="v-mid h3 bt b--opteo-near-white tl">
                            <a
                                :id="`invoice-${invoice.id}`"
                                :href="invoiceDownloadUrl(invoice)"
                                target="_blank"
                                class="o-1-imp"
                            >
                                <oButton color="white" size="small"> Download PDF </oButton>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        {{ invoices }}
    </div>
</template>
<script lang="ts">
import { oButton, oInput, oForm, Money } from '@opteo/components-next'
import Ent from '@/components/util/Ent.vue'
import { API_URL } from '@/lib/env'

export default {
    name: 'InvoicesTable',
    props: {
        invoices: {
            type: Array,
            required: true,
        },
    },
    setup() {
        function invoiceDownloadUrl(invoice) {
            if (invoice.not_supported) {
                return invoice.stripe_invoice_url
            }

            return `${API_URL}/render/invoice/?charge_id=${invoice.id}&is_refund=${invoice.refunded}&issue_date=${invoice.issue_date}`
        }
        return { API_URL, invoiceDownloadUrl }
    },
    components: { oButton, oInput, oForm, Money, Ent },
}
</script>
