<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <circle cx="12" cy="12" r="12" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0)">
            <circle cx="12" cy="12" r="12" fill="#15375F" />
            <path
                d="M14.5121 10.9044L14.8402 12.3202L14.2531 19.1576L14.9783 18.9159L17.7582 9.97199L21.9366 8.78062C22.4719 8.60795 23.0071 8.29717 23.3697 8.05544C23.2316 7.69285 23.0934 7.33026 22.9208 6.96767C22.4891 6.96767 21.9366 7.0022 21.4531 7.12307L17.292 8.31443L10.2129 2.18494L9.47042 2.37486L13.597 7.86551L14.0805 9.22954L10.1438 10.3518L0.16394 13.8223C0.198473 14.0295 0.233005 14.2367 0.267538 14.4439L10.5927 12.0267L14.5121 10.9044Z"
                fill="#FFC717"
            />
        </g>
    </svg>
</template>
