<template>
    <svg
        style="display: block"
        width="16"
        height="19"
        viewBox="0 0 16 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_37_13336)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 2c-.55228 0-1 .44772-1 1h4c0-.55229-.44772-1-1-1H7Zm5 1c0-1.65685-1.3431-3-3-3H7C5.34315 0 4 1.34315 4 3H1c-.552285 0-1 .44772-1 1s.447715 1 1 1v11c0 1.6569 1.34315 3 3 3h8c1.6569 0 3-1.3431 3-3V5c.5523 0 1-.44772 1-1s-.4477-1-1-1h-3Zm-1 2H3v11c0 .5523.44772 1 1 1h8c.5523 0 1-.4477 1-1V5h-2ZM6 7c.55228 0 1 .44772 1 1v6c0 .5523-.44772 1-1 1s-1-.4477-1-1V8c0-.55228.44772-1 1-1Zm5 1c0-.55228-.4477-1-1-1-.55229 0-1 .44772-1 1v6c0 .5523.44771 1 1 1 .5523 0 1-.4477 1-1V8Z"
                fill="#CACAD3"
            />
        </g>
        <defs>
            <clipPath id="clip0_37_13336"><path fill="#fff" d="M0 0h16v19H0z" /></clipPath>
        </defs>
    </svg>
</template>
