import { computed, ref, watch } from 'vue'
import { CheckQueryRelevanceMS, Api } from '@opteo/types'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useAccount } from '@/composition/account/useAccount'
import { buildSerpPreviewUrl } from '@/lib/serp'

type DropDownItem = { value: number; label: string }

export function useCheckQueryRelevance() {
    const { improvement, lastUpdated, title } = useImprovement<CheckQueryRelevanceMS.Body>()

    const {
        body: {
            searchQuery,
            keywords,
            metrics: { clicks, conversions, spend },
        },
        account_id: accountId,
    } = checkImprovement(improvement)

    const { currencyCode } = useAccount()

    const addingNegativeKeyword = ref(false)

    const keywordBid = ref(0)

    const cpa = conversions ? spend / conversions : 0

    const metrics = {
        cpa: { value: cpa },
        cost: { value: spend },
        clicks: { value: clicks, copy: clicks === 1 ? 'click' : 'clicks' },
        conversions: { value: conversions, copy: conversions === 1 ? 'conversion' : 'conversions' },
    }

    const serpImageUrl = buildSerpPreviewUrl({ accountId, searchTerm: searchQuery })

    // KEYWORDS

    const tableHeaders = [
        { key: 'keyword', text: 'Keyword' },
        { key: 'matchType', text: 'Match Type' },
        { key: 'campaignName', text: 'Campaign' },
        { key: 'adGroupName', text: 'Ad Group' },
        { key: 'spend', text: 'Cost' },
        { key: 'clicks', text: 'Clicks' },
        { key: 'conversions', text: 'Conv.' },
    ]

    const tableItems = keywords.map(keyword => {
        return {
            id: keyword.keywordId,
            keyword: keyword.keyword,
            matchType: keyword.matchType,
            campaignName: keyword.campaignName,
            adGroupName: keyword.adGroupName,
            spend: keyword.metrics.spend,
            clicks: keyword.metrics.clicks,
            conversions: keyword.metrics.conversions,
        }
    })

    // KEYWORD ADJUSTMENT

    const showKeywordSelection = ref(false)

    function toggleShowKeywordSelection() {
        showKeywordSelection.value = !showKeywordSelection.value
    }

    function changeKeywordType(value: 'positive' | 'negative') {
        addingNegativeKeyword.value = value === 'negative'
    }

    const searchTermWords = ref(
        searchQuery.split(' ').map((word, index) => {
            return { word, index, selected: true }
        })
    )

    function toggleWordSelection(index: number) {
        const word = searchTermWords.value.find(word => word.index === index)
        if (word) word.selected = !word.selected
    }

    const selectedWords = computed(() => searchTermWords.value.filter(word => word.selected))

    const keywordMatchTypes = ref(
        Object.values(CheckQueryRelevanceMS.DeliveredMatchType).map((matchType, index) => {
            return {
                matchType,
                index,
                selected: matchType === CheckQueryRelevanceMS.DeliveredMatchType.PHRASE,
            }
        })
    )

    function toggleMatchType(index: number) {
        const matchType = keywordMatchTypes.value.find(matchType => matchType.index === index)

        if (matchType) {
            keywordMatchTypes.value.forEach(mt => (mt.selected = false))
            matchType.selected = true
        }
    }

    const previewKeyword = computed(() => {
        if (!selectedWords.value.length) {
            return null
        }

        const words = selectedWords.value.map(word => word.word).join(' ')

        const matchType = keywordMatchTypes.value.find(matchType => matchType.selected)

        if (matchType?.matchType === CheckQueryRelevanceMS.DeliveredMatchType.PHRASE) {
            return `“${words}”`
        } else if (matchType?.matchType === CheckQueryRelevanceMS.DeliveredMatchType.EXACT) {
            return `[${words}]`
        } else {
            return words
        }
    })

    // NEGATIVE KEYWORD LISTS

    const { data: negativeKeywordListData, loading: negativeKeywordListsLoading } =
        useAPI<Api.GetNegativeKeywordListsMS.Response>(Endpoint.GetNegativeKeywordListsMS, {
            body: { accountId },
            uniqueId: () => accountId,
            waitFor: () => accountId,
        })

    const negativeKeywordLists = computed(() => {
        return (negativeKeywordListData.value || []).map(negativeKeywordList => {
            return { value: negativeKeywordList.id, label: negativeKeywordList.name }
        })
    })

    const selectedNegativeKeywordListId = ref<number>()

    watch(negativeKeywordLists, () => {
        if (!negativeKeywordLists.value.length) return

        const opteoNegativeKeywordList = negativeKeywordLists.value.find(negativeKeywordList =>
            negativeKeywordList.label.includes('auto_gen_general')
        )

        selectedNegativeKeywordListId.value = opteoNegativeKeywordList
            ? opteoNegativeKeywordList.value
            : negativeKeywordLists.value[0].value
    })

    // CAMPAIGNS AND ADGROUPS

    const { data: campaignAndAdGroupData, loading: campaignsAndAdGroupsLoading } =
        useAPI<Api.GetCampaignsAndAdGroupsMS.Response>(Endpoint.GetCampaignsAndAdGroupsMS, {
            body: { accountId },
            uniqueId: () => accountId,
            waitFor: () => accountId,
        })

    const campaignsAndAdGroups = computed(() => {
        const activeCampaigns = campaignAndAdGroupData.value?.activeCampaigns || []
        const inactiveCampaigns = campaignAndAdGroupData.value?.inactiveCampaigns || []

        return activeCampaigns.length ? activeCampaigns : inactiveCampaigns
    })
    const selectedCampaignId = ref<number>()
    const selectedAdGroupId = ref<number>()

    const campaigns = computed<DropDownItem[]>(() => {
        return campaignsAndAdGroups.value.map(campaign => {
            return { value: campaign.campaignId, label: campaign.campaignName }
        })
    })

    const adGroups = computed<DropDownItem[]>(() => {
        const campaign = campaignsAndAdGroups.value.find(
            campaign => campaign.campaignId === selectedCampaignId.value
        )

        if (!campaign) return []

        return campaign.adGroups.map(adGroup => {
            return { value: adGroup.adGroupId, label: adGroup.adGroupName }
        })
    })

    watch([adGroups], () => {
        const parentCampaign = campaigns.value.find(c => c.value === selectedCampaignId.value)
            ?.value

        selectedAdGroupId.value = campaignAndAdGroupData.value?.activeCampaigns?.find(
            a => a.campaignId === parentCampaign
        )?.adGroups[0].adGroupId
    })
    watch([campaigns], () => {
        if (!campaigns?.value?.length && !adGroups?.value?.length) return

        const destinationKeyword = keywords.find(keyword => {
            return campaignsAndAdGroups.value.find(campaign => {
                return (
                    campaign.campaignId === keyword.campaignId &&
                    campaign.adGroups.find(adGroup => adGroup.adGroupId === keyword.adGroupId)
                )
            })
        })

        if (destinationKeyword) {
            selectedCampaignId.value = destinationKeyword.campaignId
            keywordBid.value = destinationKeyword.keywordBid ?? 0
            selectedAdGroupId.value = destinationKeyword.adGroupId
        } else {
            /* Default Condition */
            selectedCampaignId.value = campaignsAndAdGroups.value[0].campaignId
            selectedAdGroupId.value = campaignsAndAdGroups.value[0].adGroups[0].adGroupId
        }
    })

    // REQUIREMENTS

    const adjustSteps = ref(['Create Keyword'])
    const pushDataStillLoading = computed(
        () => campaignsAndAdGroupsLoading.value || negativeKeywordListsLoading.value
    )
    const pushActionText = computed(() =>
        addingNegativeKeyword.value ? 'Add Negative Keyword' : 'Add New Keyword'
    )

    const pushMessages = computed(() => [
        'Connecting to Microsoft Ads..',
        `Adding ${addingNegativeKeyword.value ? 'Negative Keyword' : 'Keyword'}..`,
        'Confirming changes..',
        `${addingNegativeKeyword.value ? 'Negative Keyword' : 'Keyword'} added successfully.`,
    ])

    const onPush: OnPushHandler<CheckQueryRelevanceMS.ExtraDetails> = () => {
        if (!selectedWords.value.length) {
            showKeywordSelection.value = true
            return { valid: false }
        }

        const keywordText = selectedWords.value.map(word => word.word).join(' ')

        const keywordMatchType = keywordMatchTypes.value.find(matchType => matchType.selected)
            ?.matchType

        if (!keywordMatchType || !selectedAdGroupId.value) {
            return { valid: false }
        }

        if (addingNegativeKeyword.value) {
            return {
                valid: true,
                pushedData: {
                    deliveredMatchType: keywordMatchType,
                    keyword: keywordText,
                    sharedSetId: selectedNegativeKeywordListId.value || 0,
                },
            }
        } else {
            return {
                valid: true,
                pushedData: {
                    deliveredMatchType: keywordMatchType,
                    keyword: keywordText,
                    adGroupId: selectedAdGroupId.value,
                    bid: keywordBid.value,
                },
            }
        }
    }

    return {
        // REQUIREMENTS
        title,
        adjustSteps,
        pushDataStillLoading,
        pushActionText,
        pushMessages,
        lastUpdated,
        onPush,
        // DATA
        addingNegativeKeyword,
        searchQuery,
        keywordBid,
        metrics,
        currencyCode,
        serpImageUrl,
        // KEYWORD
        tableHeaders,
        tableItems,
        // KEYWORD ADJUSTMENT
        showKeywordSelection,
        toggleShowKeywordSelection,
        changeKeywordType,
        searchTermWords,
        toggleWordSelection,
        keywordMatchTypes,
        toggleMatchType,
        previewKeyword,
        // NEGATIVE KEYWORD LISTS
        negativeKeywordListsLoading,
        negativeKeywordLists,
        selectedNegativeKeywordListId,
        // CAMPAIGNS AND AD GROUPS
        campaignsAndAdGroupsLoading,
        campaigns,
        selectedCampaignId,
        adGroups,
        selectedAdGroupId,
    }
}
