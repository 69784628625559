<template>
    <PageHeader :no-margin="true" page-title="Alerts">
        <template #end-content>
            <oButton color="white" @clicked="markAllAsRead">Mark All Read</oButton>
        </template>
    </PageHeader>
    <PageContent>
        <div v-if="loading">
            <AlertRow v-for="skeletonId in 20" :key="skeletonId" :alert="{}" />
        </div>
        <div v-else-if="alerts?.length > 0">
            <AlertRow v-for="alert in alertsSliced" :key="alert.id" :alert="alert" />
            <div v-if="alerts?.length !== alertsSliced.length" class="load-more">
                <oButton @clicked="limit = limit + 100" color="white"> Show More </oButton>
            </div>
        </div>
        <div v-else class="empty-state-container">
            <img
                src="@/assets/img/no-results-account-centre.png"
                class="empty-state-image"
                alt="No accounts found"
            />
            <div class="empty-state-text">No alerts generated yet. Check back later for more.</div>
        </div>
    </PageContent>
    <router-view />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { oButton } from '@opteo/components-next'

import { useAlerts } from '@/composition/alerts/useAlerts'
import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'
import AlertRow from '@/components/Alerts/AlertRow.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
    components: {
        oButton,
        PageHeader,
        PageContent,
        AlertRow,
    },
    setup() {
        const route = useRoute()
        const alertOpen = computed(() => !!route.params.alertId)

        return {
            ...useAlerts(),
            alertOpen,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 24rem;
}
.empty-state-image {
    width: 200px;
    @include mb-16;
}
.empty-state-text {
    @include f-9;
    @include lh-150;
    @include tc;
    width: 11rem;
}

.load-more {
    @include flex;
    @include items-center;
    @include justify-center;
    @include mt-24;
}

@media screen and (max-width: $mq-767-max) {
    .empty-state-container {
        margin-bottom: 6rem;
    }
}
</style>
