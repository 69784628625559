import { computed, ref, unref, Ref, ComputedRef } from 'vue'
import useSWRV from 'swrv'
import forEach from 'lodash-es/forEach'
import includes from 'lodash-es/includes'
import reduce from 'lodash-es/reduce'

import { usePercent, useMoney } from '@opteo/components-next'

import { useAPI, Endpoint } from '@/composition/api/useAPI'
import { getDomainFromUrl, getCharCount, stripAdCustomisers } from './utils'
import { UsePercentParams, UseMoneyParams } from '@/composition/utils/types'

import { buildSerpPreviewUrl } from '@/lib/serp'
import { useAccount } from '@/composition/account/useAccount'

type Headline1 = HTMLDivElement & { setError: (message: string) => void }
type Headline2 = HTMLDivElement & { setError: (message: string) => void }
type Headline3 = HTMLDivElement & { setError: (message: string) => void }
type Description = HTMLDivElement & { setError: (message: string) => void }
type Description2 = HTMLDivElement & { setError: (message: string) => void }
type Path1 = HTMLDivElement & { setError: (message: string) => void }
type Path2 = HTMLDivElement & { setError: (message: string) => void }
type DestinationUrl = HTMLDivElement & { valid: boolean; setError: (message: string) => void }

type Section =
    | 'headline_part_1'
    | 'headline_part_2'
    | 'headline_part_3'
    | 'description'
    | 'description2'
    | 'path_1'
    | 'path_2'
    | 'destination_url'

enum AdErrors {
    InvalidHeadlineOneLength = 'Invalid length',
    InvalidHeadlineTwoLength = 'Invalid length',
    InvalidHeadlineThreeLength = 'Invalid length',
    InvalidDestinationUrl = 'Please enter a URL',
    InvalidHeadPathOneLength = 'Invalid length',
    InvalidHeadPathTwoLength = 'Invalid length',
    PathsCantContainSpaces = 'Remove spaces',
    PathsCantContainPeriods = 'Remove periods',
    PathsCantContainSlashes = 'Remove slashes',
    PathOneBeforePathTwo = 'Path One required',
    InvalidDescriptionOneLength = 'Invalid length',
    InvalidDescriptionTwoLength = 'Invalid length',
}

type Error = {
    [section in Section]?: string
}

interface Ad {
    headline_part_1: string
    headline_part_2: string
    description: string
    description2: string
    expanded_text_ad_description2: string
    expanded_text_ad_headline3: string
    path_1: string
    path_2: string
    destination_url: string
}

interface SampleAd {
    description: string
    description_line1: string
    description_line2: string
    description2: string
    expanded_text_ad_description2: string
    line_1: string
    line_2: string
    headline: string
    headline_part_1: string
    headline1: string
    headline2: string
    headline_part1: string
    headline_part2: string
    headline_part_2: string
    headline_part3: string
    expanded_text_ad_headline3: string
    path1: string
    path2: string
    path_1: string
    path_2: string
    display_url: string
    legacy: boolean
}
interface CompetitorAd {
    domain: string
    path1?: string
    path2?: string
    display_url: string
    headline_part1: string
    headline_part2?: string
    headline_part3?: string
    description: string
    description2?: string
}

interface PausedAd {
    ad_id: string
    ad_type: string
    adgroup_id: string
    campaign_id: string
    clicks: number
    conversions: number
    cost: number
    cpi: number
    ctr: number
    description: string
    description_line1: string
    description_line2: string
    destination_url: string
    display_url: string
    expanded_text_ad_description2: string
    expanded_text_ad_headline3: string
    headline: string
    headline1: string
    headline2: string
    impr_abs_top: number
    impressions: number
    legacy: boolean
    path1: string
    path2: string
}

interface UseAdWriter {
    newAd: Ref<Ad> | Ref
    resolveAd: () => boolean
    inspirationPackage: ComputedRef<{
        ads: any
        keywords: any
    }>
    pausedAds: Ref<PausedAd[]> | Ref
    competitorAds: Ref<CompetitorAd[]> | Ref
    prefill: (ad: SampleAd) => void
    clearAd: () => void
    getDomainFromUrl: (url: string, keep_www?: boolean) => string
    usePercent: (params: UsePercentParams) => any
    useMoney: (params: UseMoneyParams) => any
    unref: <T>(ref: T | Ref<T>) => T
    buildSerpImageUrl: () => string
    HeadlineOne: Ref<Headline1> | Ref
    HeadlineTwo: Ref<Headline2> | Ref
    HeadlineThree: Ref<Headline3> | Ref
    Description: Ref<Description> | Ref
    DescriptionTwo: Ref<Description2> | Ref
    PathOne: Ref<Path1> | Ref
    PathTwo: Ref<Path2> | Ref
    DestinationUrl: Ref<DestinationUrl> | Ref
    inspirationLoading: Ref<boolean>
    getCharCount: (s: string) => number
    stripAdCustomisers: (s: string) => string
}

export interface Props {
    competitorTerms: {
        text: string
        impressions: number
        keyword?: string
        impressions_percent?: number
        destination_url?: string
    }[]
    improvementId: number
    domainId: number
    adgroupId: number
    campaignId: number
}

export type EmittedEvent = (event: 'ad-writer-resolve', args: Ad) => void

export function useAdWriter(props: Props, emit: EmittedEvent): UseAdWriter {
    const { accountId } = useAccount()
    const HeadlineOne = ref<Headline1>()
    const HeadlineTwo = ref<Headline2>()
    const HeadlineThree = ref<Headline3>()
    const Description = ref<Description>()
    const DescriptionTwo = ref<Description2>()
    const PathOne = ref<Path1>()
    const PathTwo = ref<Path2>()
    const DestinationUrl = ref<DestinationUrl>()

    const newAd = ref<Ad>({
        headline_part_1: '',
        headline_part_2: '',
        expanded_text_ad_headline3: '',
        path_1: '',
        path_2: '',
        expanded_text_ad_description2: '',
        description: '',
        description2: '',
        destination_url: '',
    })

    const swrvKey = `adWriter|${props.domainId}|${props.improvementId}`
    const { data, mutate } = useSWRV(swrvKey)

    if (data?.value?.ad) {
        newAd.value = data.value.ad
    }

    const { data: adGroupAds, loading: activeAdsLoading } = useAPI<any>(Endpoint.GetAllAdgroupAds, {
        body: {
            adgroup_id: props.adgroupId,
            campaign_id: props.campaignId,
        },
    })
    const { data: pausedAds, loading: pausedAdsLoading } = useAPI<any>(Endpoint.GetPausedAds, {
        body: {
            adgroup_id: props.adgroupId,
            campaign_id: props.campaignId,
        },
    })

    const inspirationLoading = computed(() => activeAdsLoading.value || pausedAdsLoading.value)

    const inspirationPackage = computed(() => {
        const allKeywords = props.competitorTerms.map(k => {
            k.keyword = k.text
            return k
        })

        if (!allKeywords.length) {
            return { ads: adGroupAds.value, keywords: [] }
        }

        const total_impressions = reduce(
            allKeywords,
            (sum, kw) => {
                return sum + +kw.impressions
            },
            0
        )

        allKeywords.forEach(keyword => {
            keyword.impressions_percent =
                Math.round((keyword.impressions / total_impressions) * 1000) / 10
        })
        if (adGroupAds.value && newAd.value) {
            newAd.value.destination_url = adGroupAds.value[0]?.destination_url
        }
        return { ads: adGroupAds.value, keywords: allKeywords }
    })

    const { data: competitorAds } = useAPI<CompetitorAd>(Endpoint.GetCompetitorAds, {
        waitFor: () => inspirationPackage.value,
        body: {
            keyword: inspirationPackage.value?.keywords[0]?.keyword,
            is_adwriter: true,
        },
    })

    function buildSerpImageUrl() {
        const keyword = inspirationPackage.value.keywords[0].keyword ?? ''

        return buildSerpPreviewUrl({
            searchTerm: keyword,
            accountId: accountId.value,
        })
    }

    function clearAd() {
        newAd.value.headline_part_1 = ''
        newAd.value.headline_part_2 = ''
        newAd.value.expanded_text_ad_headline3 = ''
        newAd.value.path_1 = ''
        newAd.value.path_2 = ''
        newAd.value.description = ''
        newAd.value.expanded_text_ad_description2 = ''
        newAd.value.destination_url = inspirationPackage.value.ads[0].destination_url
    }

    function prefill(ad: SampleAd) {
        let url
        if (ad.legacy) {
            /* Legacy ad */
            url = ad.display_url.split('/')
            newAd.value.headline_part_1 = ad.headline
            newAd.value.headline_part_2 = ad.line_1 || ad.description_line1
            newAd.value.description = ad.line_2 || ad.description_line2
            newAd.value.path_1 = url[1]
            newAd.value.path_2 = url[2]
        } else if (ad.headline_part_1 || ad.headline1) {
            /* Expanded text ad */
            newAd.value.headline_part_1 = ad.headline_part_1 || ad.headline1
            newAd.value.headline_part_2 = ad.headline_part_2 || ad.headline2
            newAd.value.expanded_text_ad_headline3 =
                ad.expanded_text_ad_headline3 && ad.expanded_text_ad_headline3 !== '--'
                    ? ad.expanded_text_ad_headline3
                    : ''
            newAd.value.description = ad.description
            newAd.value.expanded_text_ad_description2 =
                ad.expanded_text_ad_description2 && ad.expanded_text_ad_description2 !== '--'
                    ? ad.expanded_text_ad_description2
                    : ''
            newAd.value.path_1 = ad.path_1 ?? ad.path1
            newAd.value.path_2 = ad.path_2 ?? ad.path2
        } else {
            newAd.value.headline_part_1 = ad.headline_part1
            newAd.value.headline_part_2 = ad.headline_part2
            newAd.value.expanded_text_ad_headline3 = ad.headline_part3
            newAd.value.description = ad.description
            newAd.value.expanded_text_ad_description2 = ad.description2
            newAd.value.path_1 = ad.path1
            newAd.value.path_2 = ad.path2
        }
    }

    function checkAdValidity(ad: {
        headline_part_1: string
        headline_part_2: string
        expanded_text_ad_headline3: string
        description: string
        expanded_text_ad_description2: string
        path_1?: string
        path_2?: string
        destination_url: string
    }): Error {
        const errors = {
            headline_part_1: '',
            headline_part_2: '',
            headline_part_3: '',
            description: '',
            description2: '',
            path_1: '',
            path_2: '',
            destination_url: '',
        }

        if (!getCharCount(ad.headline_part_1) || getCharCount(ad.headline_part_1) > 30) {
            errors.headline_part_1 = AdErrors.InvalidHeadlineOneLength
        }

        if (!getCharCount(ad.headline_part_2) || getCharCount(ad.headline_part_2) > 30) {
            errors.headline_part_2 = AdErrors.InvalidHeadlineTwoLength
        }

        if (getCharCount(ad.expanded_text_ad_headline3) > 30) {
            errors.headline_part_3 = AdErrors.InvalidHeadlineThreeLength
        }

        if (getCharCount(ad.path_1 ?? '') > 15) {
            errors.path_1 = AdErrors.InvalidHeadPathOneLength
        }

        if (getCharCount(ad.path_2 ?? '') > 15) {
            errors.path_2 = AdErrors.InvalidHeadPathTwoLength
        }

        if (includes((ad.path_1 || '').trim() + (ad.path_2 || '').trim(), ' ')) {
            errors.path_1 = AdErrors.PathsCantContainSpaces
        }

        if (includes((ad.path_1 || '').trim() + (ad.path_2 || '').trim(), '.')) {
            errors.path_1 = AdErrors.PathsCantContainPeriods
        }

        if (includes((ad.path_1 || '').trim() + (ad.path_2 || '').trim(), '/')) {
            errors.path_1 = AdErrors.PathsCantContainSlashes
        }

        if (getCharCount(ad.path_1 ?? '') === 0 && getCharCount(ad.path_2 ?? '') > 0) {
            errors.path_1 = AdErrors.PathOneBeforePathTwo
        }

        if (!getCharCount(ad.description) || getCharCount(ad.description) > 90) {
            errors.description = AdErrors.InvalidDescriptionOneLength
        }

        if (getCharCount(ad.expanded_text_ad_description2) > 90) {
            errors.description2 = AdErrors.InvalidDescriptionTwoLength
        }

        if (!DestinationUrl?.value?.valid) {
            errors.destination_url = AdErrors.InvalidDestinationUrl
        }

        return errors
    }

    function resolveAd() {
        mutate(() => {
            return { ad: newAd.value }
        })
        let any_errors = false
        const errors = checkAdValidity(newAd.value)

        forEach(errors, (error, key) => {
            if (error) {
                setError(error, key as Section)
                any_errors = true
            }
        })

        if (any_errors) {
            return false
        }

        emit('ad-writer-resolve', newAd.value)
        return true
    }

    function setError(error: string, key: Section) {
        switch (key) {
            case 'headline_part_1':
                HeadlineOne.value?.setError(error)
                break
            case 'headline_part_2':
                HeadlineTwo.value?.setError(error)
                break
            case 'headline_part_3':
                HeadlineThree.value?.setError(error)
                break
            case 'description':
                Description.value?.setError(error)
                break
            case 'description2':
                DescriptionTwo.value?.setError(error)
                break
            case 'path_1':
                PathOne.value?.setError(error)
                break
            case 'path_2':
                PathTwo.value?.setError(error)
                break
            case 'destination_url':
                DestinationUrl.value?.setError(error)
                break
            default:
                return
        }
    }

    return {
        newAd,
        resolveAd,
        inspirationPackage,
        pausedAds,
        competitorAds,
        prefill,
        clearAd,
        getDomainFromUrl,
        usePercent,
        useMoney,
        unref,
        buildSerpImageUrl,
        HeadlineOne,
        HeadlineTwo,
        HeadlineThree,
        Description,
        DescriptionTwo,
        PathOne,
        PathTwo,
        DestinationUrl,
        inspirationLoading,
        getCharCount,
        stripAdCustomisers,
    }
}
