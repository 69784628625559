<template>
    <template v-if="searchCampaignsActive">
        <KeywordSourceCell :keyword-resource-names="searchTermItem.keywords ?? []" />
    </template>

    <template v-else-if="performanceMaxActive">
        <EntityPill
            v-if="searchTermItem.campaignName"
            :type="Improvement.LocationEntity.Campaign"
            :content="searchTermItem.campaignName"
            :tooltip="false"
        />
        <EntityPill v-else :type="Improvement.LocationEntity.Campaign">
            <Skeleton
                width="7rem"
                color="hsla(214, 100%, 50%, 0.2)"
                shimmer-color="hsla(214, 100%, 50%, 0.06)"
            />
        </EntityPill>
    </template>

    <template v-else-if="shoppingActive">
        <EntityPill
            v-if="searchTermItem.adGroupName"
            :type="Improvement.LocationEntity.AdGroup"
            :content="searchTermItem.adGroupName"
            :tooltip="false"
        />
        <EntityPill v-else :type="Improvement.LocationEntity.AdGroup">
            <Skeleton
                width="4.5rem"
                color="hsla(256, 100%, 61%, 0.2)"
                shimmer-color="hsla(256, 100%, 61%, 0.06)"
            />
        </EntityPill>
    </template>
</template>

<script setup lang="ts">
import { EntityPill } from '@opteo/components-next'
import { Improvement } from '@opteo/types'

import Skeleton from '@/components/util/Skeleton.vue'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'

import KeywordSourceCell from './KeywordSourceCell.vue'

import type { SearchTermItem, SearchTermRes } from '@/composition/toolkit/nGramTool/types/items'

const { performanceMaxActive, searchCampaignsActive, shoppingActive } = useNGramTool()

defineProps<{
    searchTermItem: SearchTermItem | SearchTermRes
}>()
</script>
