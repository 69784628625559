<template>
    <div ref="visibilityWrapper">
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import { useIntersectionObserver } from '@vueuse/core'

export default defineComponent({
    components: {},
    name: 'VisibilityWrapper',
    emits: ['visibility-change'],
    setup(props, { emit }) {
        const visibilityWrapper = ref()
        useIntersectionObserver(
            visibilityWrapper,
            ([{ isIntersecting }]) => emit('visibility-change', isIntersecting),
            {
                threshold: 0.7,
            }
        )
        return {
            visibilityWrapper,
        }
    },
})
</script>
