<template>
    <div class="create-template-popout">
        <oForm @submitted="editTemplate">
            <div class="create-template-popout-content">
                <perfect-scrollbar :style="{ maxHeight: '300px' }">
                    <div class="create-template-popout-inner">
                        <oInput
                            name="templateName"
                            label="Template Name"
                            type="text"
                            v-model="templateName"
                            :max-characters="18"
                            autofocus
                        />
                        <Spacer height="1rem" />
                        <div v-for="slide in localCheckBoxes" :key="slide.slide_id">
                            <Spacer height="0.35rem" />
                            <oInput
                                type="checkbox"
                                :label="slide.slide_name"
                                v-model="slide.selected"
                            />
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <Spacer />
            <div class="create-template-popout-buttons">
                <!-- Add deleteTemplate() functionality -->
                <oButton
                    v-if="mode === 'update'"
                    @clicked="deleteTemplate"
                    :circle="true"
                    size="small"
                    color="white"
                >
                    <template #icon>
                        <BinIcon />
                    </template>
                </oButton>
                <div class="right">
                    <oButton color="white" size="small" @clicked="cancel">Cancel</oButton>
                    <Spacer width="0.5rem" />
                    <oButton
                        size="small"
                        type="submit"
                        :disabled="templateName.length === 0 || !atLeastOneSelected"
                    >
                        {{ mode === 'create' ? 'Create Template' : 'Update Template' }}
                    </oButton>
                </div>
            </div>
        </oForm>
    </div>
</template>

<script lang="ts">
import { PropType, ref, computed } from 'vue'
import { Reports } from '@opteo/types'

import {
    oForm,
    oButton,
    oInput,
    Modal,
    Text,
    CheckIcon,
    Ent,
    Spacer,
    BinIcon,
    Popout,
} from '@opteo/components-next'

export default {
    components: {
        oButton,
        oInput,
        Modal,
        Text,
        CheckIcon,
        Ent,
        Spacer,
        BinIcon,
        Popout,
        oForm,
    },
    props: {
        slides: {
            type: Array as PropType<Reports.Core.BaseReportSlide[]>,
            required: true,
        },
        templateName: {
            type: String as PropType<string>,
            required: true,
        },
        mode: {
            type: String as PropType<'create' | 'update'>,
            required: true,
        },
    },
    emits: ['submit-template', 'cancel'],
    setup(props, { emit }) {
        const templateName = ref(props.templateName)

        const localCheckBoxes = ref(props.slides)

        const atLeastOneSelected = computed(() =>
            localCheckBoxes.value.some(slide => slide.selected)
        )

        const editTemplate = () => {
            if (templateName.value.length === 0 || !atLeastOneSelected) {
                return
            }
            emit('submit-template', localCheckBoxes.value, templateName.value)
        }

        const deleteTemplate = () => {
            emit('delete-template')
        }

        const cancel = () => emit('cancel')

        return {
            templateName,
            localCheckBoxes,
            atLeastOneSelected,
            deleteTemplate,
            editTemplate,
            cancel,
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.create-template-popout {
    @include pa-24;
}
.create-template-popout-content {
    @include container;
}
.create-template-popout-inner {
    @include pa-24;
}
.create-template-popout-buttons {
    @include flex;
    @include items-center;
    @include justify-between;
}
.create-template-popout-buttons .right {
    @include flex;
    @include items-center;
}
</style>
