<template>
    <div class="report-settings-skeleton">
        <Text as="h3" size="f-2" weight="600" style="line-height: 2.625rem">
            {{ title }}
        </Text>
        <Spacer height="2rem" />
        <div class="report-settings-skeleton-body">
            <div class="report-settings-skeleton-inner">
                <Label>Report Title (0/25)</Label>
                <div class="mock-o-input">
                    <Skeleton :height="11" :width="118" />
                </div>
                <Spacer />
                <Label>Report Period</Label>
                <div class="mock-o-input">
                    <Skeleton :height="11" :width="228" />
                </div>
                <Spacer />
            </div>
            <Label class="pl4">Report Theme</Label>
            <div class="skeleton-grid">
                <Carousel
                    :arrows="true"
                    :options="{
                        align: 'center',
                        speed: 8,
                        slidesToScroll: 2,
                    }"
                >
                    <template #slides>
                        <div class="skeleton-theme-card">
                            <div class="skeleton-theme-preview">
                                <div class="skeleton-theme-preview-container">
                                    <Spinner />
                                </div>
                            </div>
                            <div class="skeleton-theme-card-info">
                                <Spacer height="0.125rem" />
                                <Skeleton />
                                <Spacer height="1rem" />
                                <Skeleton :height="10" :width="176" />
                                <Spacer height="0.5rem" />
                                <Skeleton :height="10" :width="146" />
                            </div>
                        </div>
                        <div class="skeleton-theme-card">
                            <div class="skeleton-theme-preview">
                                <div class="skeleton-theme-preview-container">
                                    <Spinner />
                                </div>
                            </div>
                            <div class="skeleton-theme-card-info">
                                <Spacer height="0.125rem" />
                                <Skeleton />
                                <Spacer height="1rem" />
                                <Skeleton :height="10" :width="176" />
                                <Spacer height="0.5rem" />
                                <Skeleton :height="10" :width="146" />
                            </div>
                        </div>
                        <div class="skeleton-theme-card">
                            <div class="skeleton-theme-preview">
                                <div class="skeleton-theme-preview-container">
                                    <!-- <Spinner /> -->
                                </div>
                            </div>
                            <div class="skeleton-theme-card-info">
                                <Spacer height="0.125rem" />
                                <Skeleton />
                                <Spacer height="1rem" />
                                <Skeleton :height="10" :width="176" />
                                <Spacer height="0.5rem" />
                                <Skeleton :height="10" :width="146" />
                            </div>
                        </div>
                        <div class="skeleton-theme-card">
                            <div class="skeleton-theme-preview">
                                <div class="skeleton-theme-preview-container">
                                    <!-- <Spinner /> -->
                                </div>
                            </div>
                            <div class="skeleton-theme-card-info">
                                <Spacer height="0.125rem" />
                                <Skeleton />
                                <Spacer height="1rem" />
                                <Skeleton :height="10" :width="176" />
                                <Spacer height="0.5rem" />
                                <Skeleton :height="10" :width="146" />
                            </div>
                        </div>
                    </template>
                </Carousel>
            </div>
            <Spacer />
            <Label class="pl4">Report Template</Label>
            <div class="skeleton-grid">
                <Carousel
                    :arrows="true"
                    :options="{
                        align: 'center',
                        speed: 8,
                        slidesToScroll: 2,
                    }"
                >
                    <template #slides>
                        <div class="skeleton-template-card">
                            <Skeleton />
                        </div>
                        <div class="skeleton-template-card">
                            <Skeleton />
                        </div>
                        <div class="skeleton-template-card">
                            <Skeleton />
                        </div>
                        <div class="skeleton-template-card">
                            <Skeleton />
                        </div>
                    </template>
                </Carousel>
            </div>
            <Spacer height="2rem" />
        </div>
    </div>
</template>

<script lang="ts">
import { Text, Spacer, Label, oInput, Spinner, Carousel } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'

export default {
    name: 'ReportEditSkeleton',
    props: {
        title: {
            type: String,
            required: false,
            default: 'Create New Report',
        },
    },
    components: {
        Text,
        Spacer,
        Label,
        Skeleton,
        oInput,
        Spinner,
        Carousel,
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.mock-o-input {
    box-shadow: $opteo-shadow;
    width: 100%;
    border-radius: 0.5rem;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: 0 0.875rem;
    overflow: hidden;
    line-height: 1.25rem;
    border-radius: 0.5rem;
    border: none;
    height: 2.8125rem;
}
.skeleton-theme-preview-container {
    @include container;
    @include flex-center;
    width: 100%;
    height: 148px;
}

.report-settings-skeleton {
    max-width: 604px;
    padding-bottom: 144px;
    display: block;
    margin: 6rem auto;
}
.report-settings-skeleton-body {
    @include container;
    // min-height: 70vh;
}
.report-settings-skeleton-inner {
    @include pt-32;
    @include ph-32;
}
.skeleton-grid {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // gap: 1.5rem;
    // padding: 0rem 2rem;
}
.skeleton-theme-card {
    @include container;
    height: 311px;
    background-color: #ffffff;
    position: relative;
    flex: 0 0 258px;
    max-width: 100%;
    overflow: hidden;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 1.5rem;
}
.skeleton-theme-preview {
    height: 194px;
    padding: 0 1.5rem;
    @include flex-center;
}
.skeleton-theme-card-info {
    @include pa-24;
    border-top: 1px solid;
    @include opteo-border;
}
.skeleton-template-card {
    @include container;
    @include pa-24;
    height: 320px;
    background-color: #ffffff;
    flex: 0 0 258px;
    max-width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 1.5rem;
}
</style>
