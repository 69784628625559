<template>
    <div :class="[layout, 'app']">
        <router-view />
        <!-- <div class="dark-mode-toggle">
            <oButton @clicked="toggleDarkMode()">
                Toggle <span v-if="!darkMode">Dark</span><span v-else>Light</span> Mode
            </oButton>
        </div> -->
    </div>

    <div class="min-screen-size">
        <OpteoLogo :width="44" :height="44" />
        <Spacer height="2rem" />
        <div class="min-screen-size-container">
            <Text as="p" size="f-8">
                Opteo is best viewed on screens <b>320px or wider</b>, please increase your window
                size or use another device to continue. If you'd like to see us develop Opteo for
                smaller mobile devices, please consider submitting a feature request.
            </Text>
        </div>
    </div>
    <!-- Global Connection Status -->
    <ConnectionStatus />
    <!-- Global Snackbar -->
    <Snackbar />
    <!-- Global Async Push -->
    <AsyncPush />
    <!-- Global Ad Block Detector -->
    <AdBlockDetector />
</template>

<script lang="ts">
import { OBFUSCATE_ENTITY_PILLS } from '@/lib/env'
import { provide } from 'vue'
import {
    oButton,
    Snackbar,
    Text,
    OpteoLogo,
    Spacer,
    ConnectionStatus,
} from '@opteo/components-next'
import { AsyncPush } from '@opteo/components-next'
import { layout } from '@/composition/layouts/useLayouts'
import { useApp } from '@/composition/useApp'
import { useTheme } from '@/composition/global/useTheme'

import AdBlockDetector from '@/components/util/AdBlockDetector.vue'
import { ProvideKeys } from './composition/useProvide'

export default {
    name: 'App',
    components: {
        oButton,
        Snackbar,
        Text,
        OpteoLogo,
        Spacer,
        AdBlockDetector,
        AsyncPush,
        ConnectionStatus,
    },
    setup() {
        // Load plugins such as Intercom
        useApp()

        // Dark mode
        const { darkMode, toggleDarkMode } = useTheme()

        provide(ProvideKeys.ObfuscateEntityPills, OBFUSCATE_ENTITY_PILLS)

        return { layout, darkMode, toggleDarkMode }
    },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

html,
body {
    overscroll-behavior-y: none;
}

.dark-mode-toggle {
    position: fixed;
    left: 8rem;
    top: 5.5rem;
    z-index: 9999;
}

.app {
    display: none;
}

.min-screen-size {
    @include w-100;
    @include flex-center;
    flex-direction: column;
    max-width: 18rem;
    margin: 6rem auto 0 auto;
    @include ph-16;
}

.min-screen-size-container {
    @include container;
    @include pa-24;
}

@media (min-width: 320px) {
    .min-screen-size {
        display: none;
    }
    .app {
        display: block;
    }
}
</style>
