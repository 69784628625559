<template>
    <div>
        <Text as="h4" weight="600">Report Schedule</Text>
        <Spacer height="1.75rem" />
        <div class="report-schedule-container">
            <!-- Create Schedule -->
            <div class="schedule-info items-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11ZM12 6C12 5.44772 11.5523 5 11 5C10.4477 5 10 5.44772 10 6V10.75C10 11.4404 10.5596 12 11.25 12H15C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10H12V6Z"
                        fill="#006DFF"
                    />
                </svg>
                <Text size="f-8">
                    Set up a schedule to automatically generate new reports at an interval of your
                    choosing. You can also cancel existing schedules here. Opteo will send you an
                    alert whenever a new report is generated.
                </Text>
            </div>
            <Spacer width="1rem" />
            <oForm @submitted="addSchedule" class="create-schedule-form">
                <oInput
                    name="interval"
                    label="Schedule"
                    type="select"
                    v-model.number="newScheduleInterval"
                    :items="intervals"
                    class="schedule-input"
                />
                <Spacer width="1.25rem" />

                <oInput
                    name="dayOfMonth"
                    label="Generate Report On"
                    type="select"
                    :modelValue="3"
                    :items="[{ value: 3, label: 'Third Day of Month' }]"
                    class="day-of-month-input"
                />
                <Spacer width="1.25rem" />

                <oButton type="submit" :disabled="scheduleAlreadyCreated">Create Schedule</oButton>
            </oForm>

            <!-- Schedules List -->
            <div v-if="newReportInfo.schedules.length">
                <Spacer width="1rem" />
                <ReportScheduleList
                    :schedules="newReportInfo.schedules"
                    @cancel-schedule="removeSchedule"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { Reports } from '@opteo/types'

import { useCreateReport } from '@/composition/reports/useCreateReport'
import { oInput, oButton, Spacer, Text, oForm, Label } from '@opteo/components-next'
import ReportScheduleList from '@/components/Reports/ReportScheduleList.vue'

export default {
    name: 'reports',
    components: {
        ReportScheduleList,
        oInput,
        oButton,
        Spacer,
        Text,
        oForm,
        Label,
    },
    setup() {
        const { newReportInfo, addSchedule, removeSchedule, scheduleAlreadyCreated } =
            useCreateReport()

        const newScheduleInterval = ref<Reports.Core.ReportScheduleInterval>(
            Reports.Core.ReportScheduleInterval.Monthly
        )
        const intervals = [
            { value: Reports.Core.ReportScheduleInterval.Monthly, label: 'Monthly' },
            { value: Reports.Core.ReportScheduleInterval.Bimonthly, label: 'Bimonthly' },
        ]

        return {
            newScheduleInterval,
            intervals,
            newReportInfo,
            addSchedule,
            removeSchedule,
            scheduleAlreadyCreated,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.report-schedule-container {
    @include container;
    @include pa-32;
}
.schedule-info {
    @include container;
    @include pa-24;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1.25rem;
}
.create-schedule-form {
    @include container;
    @include pa-24;
    display: flex;
    align-items: flex-end;
}

.schedule-input {
    width: 7.25rem;
}
.day-of-month-input {
    width: 12rem;
}
</style>
