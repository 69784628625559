<template>
    <Text as="p">
        We at Opteo (together with our affiliates, “we”, “our” or “us”) respect your privacy and are
        strongly committed to keeping secure any information we obtain from you or about you. This
        Privacy Policy describes our practices with respect to personal information we collect from
        or about you when you use our website and services (collectively, “services”). Our use of
        that data is governed by our customer agreements covering access to and use of those
        offerings.
        <br /><br />
        When you share data with OpenAI via our ChatGPT plugin, the data is securely transmitted to
        OpenAI servers for processing. The data is only used to generate responses to user queries,
        and is never shared with any third parties.
        <br /><br />
        OpenAI uses state-of-the-art security measures to protect user data. All data is encrypted
        both in transit and at rest, and is stored securely on servers that are regularly audited
        and updated.
        <br /><br />
        Opteo only stores the minimum amount of data required for Chat to function properly. If you
        have any concerns about the privacy of your data when using our ChatGPT plugin, please don't
        hesitate to contact us. We're committed to making sure our users always feel safe and secure
        when using our products.
        <br /><br />
        We may aggregate or de-identify personal information and use the aggregated information to
        analyse the effectiveness of our services, to improve and add features to our services, to
        conduct research, and for other similar purposes. In addition, from time to time, we may
        analyse the behaviour and characteristics of users of our services and share aggregated
        information like general user statistics with third parties, publish such aggregated
        information or make such aggregated information generally available. We may collect
        aggregated data through the services, through cookies, and through any other means described
        in this document. We will maintain and use personal information in anonymous or
        de-identified form and we will not attempt to reidentify the information.
        <br /><br />
        Depending on location, individuals in the EEA, the UK, and across the globe may have certain
        statutory rights in relation to their personal information. For example, you may have the
        right to:
        <br /><br />
        <ul>
            <li>Access your personal information.</li>
            <li>Delete your personal information.</li>
            <li>Correct or update your personal information.</li>
            <li>Transfer your personal information elsewhere.</li>
            <li>Withdraw your consent to the processing of your personal information.</li>
            <li>Object to or restrict the processing of your personal information.</li>
        </ul>
        <br />
        You can exercise some of these rights through your Opteo account. If you are unable to
        exercise your rights through your account, please send your request to
        <b>support@opteo.com</b>. <br /><br />
        We may change this Privacy Policy at any time. When we do, we will post an updated version
        on this page, unless another type of notice is required by law. By continuing to use our
        service or providing us with personal information after we have posted an updated Privacy
        Policy, or notified you by other means, you consent to the revised Privacy Policy.
        <br /><br />
        Please contact support or email <b>support@opteo.com</b> if you have any questions or
        concerns not already addressed in this Privacy Policy.
    </Text>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue'

// Components
import { Text, Spacer } from '@opteo/components-next'

export default defineComponent({
    name: 'ChatPrivacyModalContent',
    components: {
        // Components
        Text,
        Spacer,
    },
    setup() {
        const items = [
            {
                title: 'General principles',
                query: '<ul><li>Give clear and concise instructions</li><li>Ask Chat to explain its reasoning</li><li>Ask for adjustments where necessary</li><li>Good Input = Good Output</li></ul>',
                modifyPadding: true,
                paragraph: `The following queries are only intended as a starting point. For example, if you asked Chat to generate some RSA headlines and they need to be written in a different tone, simply ask it to make the necessary adjustments. Get comfortable with this format of querying and adjusting, repeating the process as needed to achieve the desired output.`,
            },
            {
                title: 'Generating RSA headlines and descriptions',
                query: 'Please generate 15 headlines and 4 descriptions for <b>ad group</b>. Headlines should be limited to 30 characters and descriptions should be limited to 90 characters. The overarching goal for this ad group is to <b>goal</b>. Focus on the following benefits: <b>Benefit One</b>, <b>Benefit Two</b>, <b>Benefit Three</b>.',
                paragraph:
                    'When attaching an entity (such as an ad group), Opteo will automatically include the top performing RSA in that entity. Querying ChatGPT is primarily about context — the more relevant context you can provide, the better the output you can expect. Mention product benefits, target audiences, tone of voice, a particular style you might want to replicate (e.g. “Headlines that sound like they were written by Apple”).',
            },
            {
                title: 'Generating keyword ideas',
                query: 'Please generate 20 potential keywords for <b>ad group</b>. Consider the person searching for a product like <b>product</b> and their motivations for doing so.',
                paragraph: `When attempting to generate keyword variants, include information about your goal for said keyword variants. Whether you're trying to convince a specific demographic to try a new product, or connect with a potential customer that needs emergency plumbing services, make sure Chat knows what you're trying to achieve.`,
            },
            {
                title: 'Generating negative keywords',
                query: 'Please generate 10 negative keywords I should consider for this account. I am already excluding traffic from searchers that are looking for the following: <b>Theme One</b>, <b>Theme Two</b>, <b>Theme Three</b>, <b>Theme Four</b>, <b>Theme Five</b>.',
                paragraph: `When attempting to generate keyword variants, include information about your goal for said keyword variants. Whether you're trying to convince a specific demographic to try a new product, or connect with a potential customer that needs emergency plumbing services, make sure Chat knows what you're trying to achieve.`,
            },
            {
                title: 'Generating target audiences',
                query: 'Please recommend a target audience for this account.',
                paragraph: `When taking on a new client, asking Chat to recommend a target audience can give some insight into the company as a whole, the products they provide, and the types of customers they are looking to target. This can be useful for deciding which campaigns to build, as well as understanding the intent of any potential customers.`,
            },
            {
                title: 'Generating competitor analyses',
                query: 'Please generate a list of companies that compete with <b>company</b>. List the name of the company, their location, business size (small, medium, or large), and whether or not they are a direct competitor. Compile the results in a table.',
                paragraph: `Chat can help you research the key competitors for a given account. This can be useful when searching for competitors to target, adding new keywords, identifying gaps in the market, understanding what gives your offering a competitive advantage, etc.`,
            },
        ]
        return { items }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

ul {
    list-style-type: disc;
    list-style-position: inside;
    line-height: 1.75;
}
</style>
