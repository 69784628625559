<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <Text as="p">
                    Opteo has detected a number of negative keywords that include a
                    <Text weight="600" color="black" as="span">+</Text>, indicating an intention to
                    add them as modified broad negatives. Modified broad is
                    <Text weight="600" color="black" as="span">not</Text> a Google Ads negative
                    match type. Click
                    <Text color="green" weight="600" as="span">{{ pushActionText }}</Text> to remove
                    any <Text weight="600" color="black" as="span">+</Text> characters from your
                    negative keywords. If this improvement is a false positive, feel free to click
                    <b>Dismiss</b>.
                </Text>
                <Spacer height="2rem" />
                <oTable
                    :headers="tableHeaders"
                    :items="tableItems"
                    :per-page="6"
                    :fixedLayout="true"
                    responsive-breakpoint="768px"
                >
                    <!-- Level Header -->
                    <template #header.level>
                        <div class="plussed-negatives-table-column">
                            <span class="plussed-negatives-table-header">Level</span>
                        </div>
                    </template>
                    <!-- Level -->
                    <template #column.level="cellData">
                        <Text as="p" size="f-8" class="plussed-negatives-table-column">
                            <span class="plussed-negatives-table-label">
                                {{ cellData.value }}
                            </span>
                        </Text>
                    </template>
                    <!-- Location Header -->
                    <template #header.location>
                        <div class="plussed-negatives-table-column">
                            <span class="plussed-negatives-table-header">Location</span>
                        </div>
                    </template>
                    <!-- Location -->
                    <template #column.location="cellData">
                        <Tooltip
                            placement="top-start"
                            :offset="[14, -8]"
                            :content="cellData.value.length > 12 ? cellData.value : ''"
                            :max-width="600"
                        >
                            <Text
                                as="p"
                                size="f-8"
                                class="plussed-negatives-table-column extra-right-padding"
                            >
                                <span class="plussed-negatives-table-label">
                                    {{ cellData.value }}
                                </span>
                            </Text>
                        </Tooltip>
                    </template>
                    <!-- Negative Header -->
                    <template #header.negative>
                        <div class="plussed-negatives-table-column">
                            <span class="plussed-negatives-table-header">Negative</span>
                        </div>
                    </template>
                    <!-- Negative -->
                    <template #column.negative="cellData">
                        <Tooltip
                            placement="top-start"
                            :offset="[14, -8]"
                            :content="cellData.value.length > 22 ? cellData.value : ''"
                            :max-width="600"
                        >
                            <Text as="p" size="f-8" class="plussed-negatives-table-column">
                                <span class="plussed-negatives-table-label">
                                    {{ cellData.value }}
                                </span>
                            </Text>
                        </Tooltip>
                    </template>
                    <!-- New Negative Header -->
                    <template #header.newNegative>
                        <div class="plussed-negatives-table-column">
                            <span class="plussed-negatives-table-header">Updated Negative</span>
                        </div>
                    </template>
                    <!-- New Negative -->
                    <template #column.newNegative="cellData">
                        <Tooltip
                            placement="top-start"
                            :offset="[14, -8]"
                            :content="cellData.value.length > 22 ? cellData.value : ''"
                            :max-width="600"
                        >
                            <Text
                                as="p"
                                size="f-8"
                                class="plussed-negatives-table-column extra-right-padding"
                            >
                                <span class="plussed-negatives-table-label">
                                    {{ cellData.value }}
                                </span>
                            </Text>
                        </Tooltip>
                    </template>
                </oTable>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/903046-fix-modified-broad-negatives">
                Learn why modified broad negatives might not work as expected.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Negative Keyword Match Types</Text>
            <Spacer height="2rem" />
            <Text as="p">
                Here's a refresher on the available match types for negative keywords.
            </Text>
            <Spacer height="2rem" />

            <oTable
                :headers="refresherTable.headers"
                :items="refresherTable.items"
                :fixedLayout="true"
            >
                <template v-slot:[`column.explainer`]="ctx">
                    <div style="white-space: normal">
                        {{ ctx.value }}
                    </div>
                </template>
                <template v-slot:[`column.example`]="ctx">
                    {{ ctx.value }}
                </template>
                <template v-slot:[`column.blocked`]="ctx">
                    <div style="white-space: normal">
                        <div v-for="(kw, index) of ctx.value" :key="kw">
                            {{ kw }}<span v-if="index !== ctx.value.length - 1">,</span>
                        </div>
                    </div>
                </template>
                <template v-slot:[`column.allowed`]="ctx">
                    <div style="white-space: normal">
                        <div v-for="(kw, index) of ctx.value" :key="kw">
                            {{ kw }}<span v-if="index !== ctx.value.length - 1">,</span>
                        </div>
                    </div>
                </template>
            </oTable>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import { oTable, Text, Spacer, Ent, EntityPill, Tooltip } from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'

import { useRemovePlussedNegatives } from '@/composition/improvement/types/useRemovePlussedNegatives'

export default {
    name: 'RemovePlussedNegatives',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        oTable,
        Text,
        Spacer,
        Ent,
        EntityPill,
        Tooltip,
    },
    setup() {
        return useRemovePlussedNegatives()
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

@media screen and (min-width: $mq-768-min) {
    .plussed-negatives-table-column {
        height: 64px;
        @include flex;
        @include items-center;
        @include relative;
    }
    .plussed-negatives-table-column .plussed-negatives-table-label {
        max-width: 100%;
        overflow: hidden;
        @include flex;
        @include items-center;
        height: 64px;
        @include pl-24;
    }
    .plussed-negatives-table-column .plussed-negatives-table-label::after {
        content: '';
        background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
        @include absolute;
        top: 0;
        right: 0;
        width: 1rem;
        bottom: 0;
    }
    .plussed-negatives-table-column .plussed-negatives-table-header {
        max-width: 100%;
        overflow: hidden;
        @include flex;
        @include items-center;
        height: 64px;
        @include pl-24;
    }
    .plussed-negatives-table-column.extra-right-padding {
        @include pr-24;
    }
    .plussed-negatives-table-column.extra-right-padding .plussed-negatives-table-label::after {
        right: 1.5rem;
    }
}
</style>
