<template>
    <div class="connect-status-button-cell">
        <div
            ref="connectStatusButton"
            class="connect-status-button"
            @click="tippyOpenRef = !tippyOpenRef"
            role="button"
            :tabindex="0"
            :aria-expanded="tippyOpenRef"
            aria-controls="changelog-select-list"
        >
            <div class="connect-status-profile-wrapper">
                <ProfileImage
                    :width="24"
                    :height="24"
                    :image-url="profileImageUrl"
                    :avatar-url="getAvatarUrl(userId)"
                    :hide-role="true"
                />
                <div :class="['connect-status-profile-indicator', { connected }]" />
            </div>

            <span v-if="connected" class="connect-status-label">Connected</span>
            <span v-else="connected" class="connect-status-label">Disconnected</span>
            <SmallDownCaret class="select-arrow" aria-hidden="true" />
        </div>
    </div>

    <div ref="popoutContent" class="popout-content">
        <div class="popout-content-buttons">
            <oButton
                v-if="connectedAfterTippyClosed"
                :loading="buttonLoading"
                color="red"
                iconBefore
                @clicked="updateLinkedStatus(false)"
            >
                <template #icon>
                    <UnlinkIcon />
                </template>
                Disconnect Account</oButton
            >
            <oButton
                v-else
                color="green"
                :loading="buttonLoading"
                iconBefore
                @clicked="updateLinkedStatus(true)"
            >
                <template #icon>
                    <LinkIcon />
                </template>
                Connect Account</oButton
            >
        </div>
        <div class="popout-content-info">
            <Text v-if="connectedAfterTippyClosed" size="f-9" color="gray">
                Please note, disconnecting an account will remove it from
                <b>your Account Centre only</b>. No other team members will be affected.
            </Text>
            <Text v-else size="f-9" color="gray">
                Please note, connecting an account will add it to <b>your Account Centre only</b>.
                No other team members will be affected.
            </Text>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, nextTick, computed } from 'vue'
import { useTippy } from 'vue-tippy'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'

import { oButton, Text, ProfileImage, useShortKey, showSnackbar } from '@opteo/components-next'
import SmallDownCaret from '@/components/linkedAccounts/icons/SmallDownCaret.vue'
import LinkIcon from './icons/LinkIcon.vue'
import UnlinkIcon from './icons/UnlinkIcon.vue'
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { Team } from '@opteo/types'
import { delay } from '@opteo/promise'

const { userId, profileImageUrl, updateCurrentUserLinkedStatus } = useLinkedAccounts()
const { getAvatarUrl } = useBoringAvatar()

const props = defineProps<{
    account: Team.Account
}>()

const connectedStatusOverride = ref<undefined | boolean>()

const connected = computed(
    () => props.account.teamMembers.find(member => member.userId === userId.value)?.active
)

const connectedAfterTippyClosed = computed(() => connectedStatusOverride.value ?? connected.value)

const buttonLoading = ref(false)

const updateLinkedStatus = async (newLinkedStatus: boolean) => {
    connectedStatusOverride.value = !newLinkedStatus // keep showing the current button until after the tippy is closed
    buttonLoading.value = true

    try {
        await updateCurrentUserLinkedStatus(props.account.accountId, newLinkedStatus)
        hide()
        await delay(250) // time for the tippy to animate out

        connectedStatusOverride.value = undefined

        buttonLoading.value = false
    } catch {
        connectedStatusOverride.value = undefined
        buttonLoading.value = false
        hide()

        showSnackbar({
            message: `Something went wrong linking or unlinking this account`,
            timeout: 4000,
        })
    }
}

const connectStatusButton = ref()
const popoutContent = ref()

const tippyOpenRef = ref(false)

const { activate, deactivate } = useFocusTrap(popoutContent, {
    initialFocus: false,
    allowOutsideClick: true,
    returnFocusOnDeactivate: true,
})

const { hide, show } = useTippy(connectStatusButton, {
    content: () => popoutContent.value,
    interactive: true,
    touch: true,
    animateFill: false,
    animation: 'shift-away',
    duration: [200, 120],
    hideOnClick: true,
    offset: [-2, -2],
    placement: 'bottom-start',
    trigger: 'click',
    maxWidth: 302,
    zIndex: 99999999999999,
    appendTo: () => document.body,
    popperOptions: {
        strategy: 'fixed',
    },
    onShown: () => {
        onOpen()
    },
    onHide: () => {
        onClose()
    },
    onMount: () => {
        document.body.classList.add('no-scroll')
    },
    onHidden: () => {
        document.body.classList.remove('no-scroll')
    },
})

async function onOpen() {
    tippyOpenRef.value = true

    await nextTick()
    activate()
}

function onClose() {
    tippyOpenRef.value = false
    deactivate()
}

useShortKey({
    keys: ['esc'],
    onPressCallBack: () => {
        if (tippyOpenRef.value) {
            hide()
        }
    },
})

useShortKey({
    keys: ['enter'],
    onPressCallBack: () => {
        if (tippyOpenRef.value) return
        show()
    },
    eventTarget: connectStatusButton,
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.connect-status-button-cell {
    display: flex;
    justify-content: flex-start;
}

.connect-status-button {
    display: flex;
    align-items: center;
    appearance: none;
    width: auto;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 0.75rem 0;
    padding-left: 0.875rem;
    padding-right: 2.25rem;
    border-radius: 0.75rem;
    border: none;
    box-shadow: $opteo-shadow;
    color: $opteo-black;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    background-color: white;
    height: 2.75rem;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.connect-status-button[disabled]::before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, #f7f7f9 30%);
}

.connect-status-button:focus {
    box-shadow: $opteo-shadow-focus;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    outline: none;
}

.connect-status-button[disabled] {
    background: $opteo-light-gray;
    color: rgba(0, 0, 8, 0.25);
    cursor: default;
}

.connect-status-button[disabled] svg {
    opacity: 0.6;
}

.connect-status-profile-wrapper {
    position: relative;
}
.connect-status-profile-indicator {
    position: absolute;
    right: -0.25rem;
    bottom: -0.125rem;
    height: 0.75rem;
    width: 0.75rem;
    background-color: $opteo-medium-gray;
    border-radius: 100%;
    border: 2px solid $opteo-white;
}
.connect-status-profile-indicator.connected {
    background-color: $opteo-green;
}

.connect-status-label {
    font-size: 14px;
    line-height: 1.125rem;
    letter-spacing: -0.005em;
    @include truncate;
    margin-left: 0.625rem;
}

.connect-status-button .select-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.875rem;
    z-index: 2;
}

.popout-content {
    box-shadow: $opteo-shadow-xl;
    @include bg-opteo-white;
    @include opteo-foreground;
    border-radius: 1.375rem;
    overflow: hidden;
}

.popout-content-buttons {
    @include pa-20;
}

.popout-content-info {
    @include pv-20;
    @include ph-28;
    border-top: 1px solid;
    @include opteo-border;
    width: 100%;
}
</style>
