<template>
    <ImprovementView
        :title="title"
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :on-push="onPush"
        is-adjustable
    >
        <ImprovementContent>
            <ImprovementHeader :title="`${title}`">
                <div v-if="aboveMobile">
                    <EntityPill :content="entityName" :type="entity" />
                    <Spacer height="2rem" />
                </div>
                <!-- Campaign Group -->
                <Text v-if="entity === 'campaign-group'" as="p">
                    Opteo has combined the performance data of several campaigns in this campaign
                    group to calculate recommended bid adjustments. Opteo recommends applying a
                    <Text :color="newBidMod > 1 ? 'green' : 'red'" as="span" weight="600">
                        {{ newBidMod > 1 ? '+' : '-' }}<Percent :value="Math.abs(1 - newBidMod)" />
                    </Text>
                    bid adjustment to searchers using <b>{{ highlightedSegment }}</b> devices. This
                    adjustment should help generate more conversions at a reasonable cost and reduce
                    wasted spend.
                    <Spacer height="1rem" />
                    For reference, this bid adjustment will be applied to the following campaigns:
                </Text>
                <!-- Campaign or Ad Group -->
                <Text v-else as="p">
                    In the {{ entityLabel }} {{ entity === 'adgroup' ? 'listed' : '' }} above, Opteo
                    recommends applying a
                    <Text :color="newBidMod > 1 ? 'green' : 'red'" as="span" weight="600">
                        {{ newBidMod > 1 ? '+' : '-' }}<Percent :value="Math.abs(1 - newBidMod)" />
                    </Text>
                    bid adjustment to searchers using <b>{{ highlightedSegment }}</b> devices.
                    Applying this adjustment should help generate more conversions at a reasonable
                    cost and reduce wasted spend.
                </Text>
                <div v-if="entity === 'campaign-group'">
                    <Spacer height="2rem" />
                    <EntityPillList :items="entityPillList" />
                    <Spacer height="2rem" />
                </div>
                <Spacer v-else height="1rem" />
                <Text as="p">
                    Click <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    apply the recommended bid adjustment. If you need to set a specific adjustment
                    percentage, click <b>Adjust</b>. Otherwise, click <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/articles/3638401-adjust-device-bids">
                Click here to learn more about device bid adjustments in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent :fullWidth="aboveMobile ? true : false">
            <oTable
                :headers="deviceTableHeaders"
                :items="deviceTableItems"
                :fixed-layout="true"
                :border-radius="24"
                order-by="id"
                order="ASC"
                responsive-mode="columns"
                responsive-breakpoint="768px"
            >
                <template #header.device>
                    <div class="device-header">Device</div>
                </template>
                <template #column.device="device">
                    <div class="device-label-column">
                        <EntityPill type="device">
                            {{ device.value }}
                        </EntityPill>
                    </div>
                </template>
                <template #column.cost="cost">
                    <Money :value="cost.value" :currency="domainCurrencyCode" />
                </template>

                <template #column.conversions="conversions">
                    <Number :value="conversions.value" :compact="true" />
                </template>

                <template #column.conversions-value="conversionsValue">
                    <Money :value="conversionsValue.value" :currency="domainCurrencyCode" />
                </template>

                <template #column.cpa="cpa">
                    <Money :value="cpa.value" :currency="domainCurrencyCode" />
                </template>

                <template #column.roas="roas">
                    <Roas :value="roas.value" />
                </template>

                <template #column.avg-cpa="avgCpa">
                    <Money :value="avgCpa.value" :currency="domainCurrencyCode" />
                </template>

                <template #column.avg-roas="avgRoas">
                    <Roas :value="avgRoas.value" />
                </template>

                <template #column.difference="difference">
                    <Text
                        v-if="difference.value < 0"
                        as="span"
                        :color="isUsingCpa ? 'green' : 'red'"
                        weight="500"
                    >
                        <Percent :value="difference.value" include-sign />
                    </Text>
                    <Text v-if="difference.value === 0" as="span" color="black" weight="400">
                        <Percent :value="difference.value" />
                    </Text>
                    <Text
                        v-if="difference.value > 0"
                        as="span"
                        :color="isUsingCpa ? 'red' : 'green'"
                        weight="500"
                    >
                        <Percent :value="difference.value" include-sign />
                    </Text>
                </template>
                <template #column.bid-adjustment="bidAdjustment">
                    <Text v-if="bidAdjustment.value.value > 0" as="span" color="green" weight="500">
                        <Percent :value="bidAdjustment.value.value" include-sign />
                    </Text>

                    <Text
                        v-else-if="bidAdjustment.value.value === 0 && !bidAdjustment.value.invalid"
                        as="span"
                        color="black"
                        weight="400"
                    >
                        <Percent :value="bidAdjustment.value.value" />
                    </Text>

                    <Text
                        v-else-if="bidAdjustment.value.value < 0 && !bidAdjustment.value.invalid"
                        as="span"
                        color="red"
                        weight="500"
                    >
                        <Percent :value="bidAdjustment.value.value" include-sign />
                    </Text>

                    <Tooltip
                        v-if="bidAdjustment.value.current != 0 && !bidAdjustment.value.invalid"
                        :content="`The bid adjustment for this segment will be updated from <b>${(
                            100 * bidAdjustment.value.current
                        ).toFixed(2)}%</b> to <b>${(100 * bidAdjustment.value.value).toFixed(
                            2
                        )}%</b>.`"
                        :max-width="240"
                    >
                        <div class="not-enough-data-pill">
                            <svg
                                class="not-enough-data-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <path
                                    d="M7 0C3.13397 0 0 3.13397 0 7C0 10.866 3.13397 14 7 14C10.866 14 14 10.866 14 7C14.0002 3.13397 10.866 0 7 0ZM8.18062 10.4205C7.84101 10.5543 7.5701 10.6563 7.36775 10.7266C7.1654 10.7969 6.93043 10.8321 6.66299 10.8321C6.25136 10.8321 5.93153 10.7318 5.70363 10.5312C5.47544 10.3306 5.36142 10.076 5.36142 9.76724C5.36142 9.64715 5.36994 9.52462 5.38711 9.39934C5.40429 9.27421 5.43171 9.13276 5.46938 8.97501L5.894 7.47282C5.93196 7.32878 5.96386 7.19254 5.98955 7.06379C6.01524 6.9352 6.02823 6.81771 6.02823 6.71148C6.02823 6.51938 5.98868 6.38573 5.90959 6.31025C5.83049 6.23491 5.67924 6.19709 5.45567 6.19709C5.34569 6.19709 5.23311 6.21427 5.11779 6.24847C5.00247 6.28282 4.90361 6.3153 4.8212 6.34619L4.93449 5.88318C5.21262 5.77002 5.47876 5.67318 5.73278 5.59249C5.9868 5.51196 6.22726 5.47169 6.45386 5.47169C6.86245 5.47169 7.17753 5.57041 7.39893 5.76742C7.62033 5.96472 7.73103 6.22105 7.73103 6.53656C7.73103 6.60179 7.72324 6.71668 7.70779 6.88122C7.69235 7.04575 7.66406 7.19687 7.62293 7.33398L7.1999 8.83111C7.1654 8.9512 7.13437 9.08831 7.10695 9.24274C7.07953 9.39717 7.06567 9.51365 7.06567 9.5926C7.06567 9.79148 7.11012 9.92701 7.19917 9.99903C7.28823 10.0711 7.44237 10.1071 7.66161 10.1071C7.76423 10.1071 7.8807 10.0891 8.01089 10.0532C8.14093 10.0171 8.23518 9.98546 8.29348 9.9579L8.18062 10.4205ZM8.106 4.34216C7.9087 4.52561 7.67128 4.6174 7.39344 4.6174C7.11561 4.6174 6.87717 4.52575 6.67829 4.34216C6.4794 4.15872 6.37996 3.9366 6.37996 3.67594C6.37996 3.41528 6.4794 3.19229 6.67829 3.00711C6.87717 2.82194 7.11561 2.72928 7.39344 2.72928C7.67128 2.72928 7.9087 2.82194 8.106 3.00711C8.30315 3.19229 8.40188 3.41528 8.40188 3.67594C8.40188 3.9366 8.30315 4.15872 8.106 4.34216Z"
                                    fill="#DDDFE4"
                                />
                            </svg>
                        </div>
                    </Tooltip>

                    <NotApplicablePill
                        v-if="bidAdjustment.value.invalid"
                        :content="bidAdjustment.value.reason"
                        :max-width="bidAdjustment.value.width"
                    />
                </template>
            </oTable>
        </ImprovementContent>

        <div v-if="entity === 'campaign-group'">
            <ImprovementContent>
                <Spacer height="2rem" />
                <ImprovementNote>
                    <b>Note:</b> When individual campaigns do not have enough performance data for
                    Opteo to recommend adjustments, campaigns with insufficient data are combined to
                    generate statistically significant suggestions. Adjustments based on aggregated
                    campaign data.
                </ImprovementNote>
            </ImprovementContent>
        </div>
        <Spacer v-else height="3rem" />

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Cost Comparison</Text>
            <Spacer height="2rem" />
            <div class="graph-container">
                <DonutChart
                    :items="donutChartItems"
                    :metric="{ label: 'Cost', dataType: 'money', currency: domainCurrencyCode }"
                    :show-keys="true"
                />
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="improvementStatisticsItems">
                <template #segmentCpa v-if="isUsingCpa">
                    Over the last <b>{{ lookbackWindow }}</b> days, searchers using
                    <b>{{ highlightedSegment }}</b> devices have an average CPA of
                    <b>{{ formattedSegmentCpa }}</b
                    >. Please note, this is based on conversion types set in Campaign Groups.
                </template>

                <template #segmentRoas v-else>
                    Over the last <b>{{ lookbackWindow }}</b> days, searchers using
                    <b>{{ highlightedSegment }}</b> devices have an average ROAS of
                    <b>{{ formattedSegmentRoas }}</b
                    >. Please note, this is based on conversion types set in Campaign Groups.
                </template>

                <template #entityAverage>
                    In comparison, the containing ad group has an average
                    {{ isUsingCpa ? 'CPA' : 'ROAS' }} of <b>{{ formattedEntityAverage }}</b> over
                    the same time period. This means that searchers using
                    <b>{{ highlightedSegment }}</b> devices generated a
                    <b>
                        <Percent :value="Math.abs(differenceFromEntityAverage)" />
                        <span>{{ differenceFromEntityAverage > 0 ? ' higher ' : ' lower ' }}</span>
                    </b>
                    {{ isUsingCpa ? 'CPA' : 'ROAS' }} than the ad group average.
                </template>

                <template #campaignGroupTarget>
                    The parent campaign group has a Target {{ isUsingCpa ? 'CPA' : 'ROAS' }} of
                    <b>{{ formattedTarget }}</b
                    >. This means — in this ad group — searchers using
                    <b>{{ highlightedSegment }}</b> devices generated a
                    <b>
                        <Percent :value="Math.abs(differenceFromTarget)" />
                        <span>{{ differenceFromTarget > 0 ? ' higher ' : ' lower ' }}</span>
                    </b>
                    {{ isUsingCpa ? 'CPA' : 'ROAS' }} than the campaign group target.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>

        <!-- Adjust Step -->
        <template #adjust-step-1>
            <ImprovementContent>
                <ImprovementBidAdjustment
                    ref="impBidAdjustmentRef"
                    :bid-type="entity"
                    :current-bid="1"
                    :new-bid="newBidMod"
                    @on-bid-update="updateBid"
                >
                    <template #entity-pill>
                        <EntityPill v-if="aboveMobile" :content="entityName" :type="entity" />
                    </template>
                    <template #text>
                        <!-- Campaign Group -->
                        <Text v-if="entity === 'campaign-group'" as="p">
                            Opteo has combined the performance data of several campaigns in this
                            campaign group to calculate recommended bid adjustments. Opteo
                            recommends applying a
                            <Text :color="newBidMod > 1 ? 'green' : 'red'" as="span" weight="600">
                                {{ newBidMod > 1 ? '+' : '-'
                                }}<Percent :value="Math.abs(1 - newBidMod)" />
                            </Text>
                            bid adjustment to searchers using
                            <b>{{ highlightedSegment }}</b> devices. This adjustment should help
                            generate more conversions at a reasonable cost and reduce wasted spend.
                            <Spacer height="1rem" />
                            For reference, this bid adjustment will be applied to the following
                            campaigns:
                        </Text>
                        <!-- Campaign or Ad Group -->
                        <Text v-else as="p">
                            In the {{ entityLabel }}
                            {{ entity === 'adgroup' ? 'listed' : '' }} above, Opteo recommends
                            applying a
                            <Text :color="newBidMod > 1 ? 'green' : 'red'" as="span" weight="600">
                                {{ newBidMod > 1 ? '+' : '-'
                                }}<Percent :value="Math.abs(1 - newBidMod)" />
                            </Text>
                            bid adjustment to searchers using
                            <b>{{ highlightedSegment }}</b> devices. Applying this adjustment should
                            help generate more conversions at a reasonable cost and reduce wasted
                            spend.
                        </Text>
                        <div v-if="entity === 'campaign-group'">
                            <Spacer height="2rem" />
                            <EntityPillList :items="entityPillList" />
                            <Spacer height="2rem" />
                        </div>
                    </template>
                </ImprovementBidAdjustment>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts" setup>
import {
    Text,
    Spacer,
    EntityPill,
    EntityPillList,
    oTable,
    Number,
    Percent,
    DonutChart,
    Tooltip,
    Roas,
} from '@opteo/components-next'

import Money from '@/components/global/oMoney.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import NotApplicablePill from '@/components/improvement/shared-components/NotApplicablePill.vue'
import { useAdjustSingleDeviceBid } from '@/composition/improvement/types/device-bids-v2/useAdjustSingleDeviceBid'
import useMediaQuery from '@/composition/global/useMediaQuery'

const {
    title,
    entity,
    entityLabel,
    entityName,
    entityPillList,
    highlightedSegment,
    deviceTableHeaders,
    deviceTableItems,
    donutChartItems,
    domainCurrencyCode,
    proposeIncreaseBid,
    currentBidMod,
    newBidMod,
    formattedSegmentCpa,
    formattedSegmentRoas,
    improvementStatisticsItems,
    lookbackWindow,
    updateBid,
    pushMessages,
    onPush,
    lastUpdated,
    pushActionText,
    isUsingCpa,
    formattedEntityAverage,
    differenceFromEntityAverage,
    differenceFromTarget,
    formattedTarget,
} = useAdjustSingleDeviceBid()

const { aboveMobile } = useMediaQuery()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.graph-container {
    @include container;
    @include pa-32;
}
.adjust-bid-input {
    display: flex;
    align-items: center;
}

.not-enough-data-pill {
    @include inline-flex;
    @include items-center;
    @include justify-between;
    cursor: default;
    margin-left: 0.1875rem;
}
.not-enough-data-text {
    color: #bec1cb;
}
.not-enough-data-icon {
    color: #bec1cb;
    margin-left: 0.25rem;
    transform: translateY(3px);
}

.device-header {
    @include pl-24;
}
.device-label-column {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 4.5rem;
    @include pl-24;
}

@media screen and (max-width: 1080px) {
    .device-label-column {
        @include pl-0;
        height: auto;
    }
    .device-header {
        @include pl-0;
    }
}
</style>
