import { computed, Ref, ref, ComputedRef, unref, inject } from 'vue'
import {
    UseImprovement,
    useImprovement,
    checkImprovement,
    EntityPill,
} from '@/composition/improvement/useImprovement'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { BarChartTypes, DonutChartTypes, usePercent } from '@opteo/components-next'
import { AdjustDeviceBid, Improvement } from '@opteo/types'
import isFinite from 'lodash-es/isFinite'
import { CurrencyCode } from '@/composition/utils/types'
import { ProvideKeys } from '@/composition/useProvide'

type ImprovementDeviceType = 'Mobile' | 'Desktop' | 'Tablet' | 'Connected TV' | 'Unknown'

interface UseAdjustDeviceBid {
    campaignName: string
    deviceCPA: number
    deviceCost: number
    deviceConversions: number
    campaignCPA: number
    campaignGroupName: string
    campaignGroupCPA: number
    targetCPA: number
    bidModifier: number
    absoluteBidModifier: Ref<number>
    cpaToCampaignRatio: ComputedRef<number>
    cpaToCampaignRatioLabel: Ref<'less' | 'more'>
    cpaToGroupRatio: ComputedRef<number>
    isIncreasingBid: ComputedRef<boolean>
    cpaToGroupRatioLabel: Ref<string>
    currentBidMod: number
    unitBid: Ref<number>
    usingTargetCPA: boolean
    improvementDeviceType: ImprovementDeviceType
    barChartItems: BarChartTypes.BarChartItem[]
    donutChartItems: DonutChartTypes.DonutChartItem[]
    domainCurrencyCode: CurrencyCode | undefined
    impBidAdjustmentRef: Ref
    recommendedBidRelativeToUnitBid: Ref<number>
    improvementEntityPillList: ComputedRef<EntityPill[]>
    formattedDeviceCPA: string
    formattedCampaignCPA: string
    formattedCampaignGroupCPA: string
    formattedBidModifier: string
    onBidUpdate: (updatedBid: number) => void
    // Table Data
    campaignDevicesTableHeaders: any[]
    campaignDevicesTableItems: any[]
}

const DEVICES = ['Mobile', 'Desktop', 'Tablet', 'Connected TV', 'Unknown']

export function useAdjustDeviceBid(): UseImprovement<UseAdjustDeviceBid> {
    const { improvement, lastUpdated, title } = useImprovement<AdjustDeviceBid.Body>()
    const { body } = checkImprovement<AdjustDeviceBid.Body>(improvement)

    const improvementBody = body

    const campaignName = improvementBody.campaign_name
    const deviceCPA = improvementBody.device_cpa
    const formattedDeviceCPA = unref(useDomainMoney({ value: deviceCPA }).value.displayValue)

    const deviceCost = improvementBody.device_cost
    const deviceConversions = improvementBody.device_conversions

    const campaignCPA = improvementBody.campaign_cpa
    const formattedCampaignCPA = unref(useDomainMoney({ value: campaignCPA }).value.displayValue)

    const campaignGroupName = improvementBody.campaign_group
    const campaignGroupCPA = improvementBody.campaign_group_cpa
    const formattedCampaignGroupCPA = unref(
        useDomainMoney({ value: campaignGroupCPA }).value.displayValue
    )
    const targetCPA = improvementBody.target_cpa

    const bidModifier = improvementBody.bid_modifier
    const formattedBidModifier = unref(usePercent({ value: bidModifier / 100 }).displayValue)

    const currentBidMod = improvementBody.current_bid_mod
    const unitBid = ref(1)
    const recommendedBidRelativeToUnitBid = ref(formatBidModifier(improvementBody.bid_modifier))
    const bidToPush = ref(improvementBody.bid_modifier)
    const absoluteBidModifier = ref(Math.abs(bidModifier))

    function formatBidModifier(bid_mod: number) {
        return (bid_mod + 100) / 100
    }

    function onBidUpdate(updatedBid: number) {
        bidToPush.value = 100 * updatedBid - 100
    }

    const impBidAdjustmentRef = ref()

    const improvementDeviceType = body.device as ImprovementDeviceType
    const usingTargetCPA = improvementBody.using_target_cpa

    const isIncreasingBid = computed(() => {
        return currentBidMod < bidToPush.value
    })

    const cpaToGroupRatioLabel = ref('less')

    const cpaToGroupRatio = computed(() => {
        const delta = deviceCPA - campaignGroupCPA

        if (delta > 0) {
            cpaToGroupRatioLabel.value = 'more'
        }

        if (campaignGroupCPA === 0) {
            return 0
        }

        return Math.abs(delta / campaignGroupCPA)
    })

    const cpaToCampaignRatioLabel = ref<'less' | 'more'>('less')

    const cpaToCampaignRatio = computed(() => {
        const delta = deviceCPA - campaignCPA

        if (delta > 0) {
            cpaToCampaignRatioLabel.value = 'more'
        }

        if (campaignCPA === 0) {
            return 0
        }

        return Math.abs(delta / campaignCPA)
    })

    // Table Data

    const campaignDevicesTableHeaders = [
        { key: 'device', text: 'Device', sortable: true },
        { key: 'cost', text: 'Cost', sortable: true },
        { key: 'cpa', text: 'CPA', sortable: true },
        { key: 'average-cpa', text: 'Avg. CPA' },
        { key: 'difference', text: 'Diff.', sortable: true },
    ]
    const campaignDevicesTableData = [
        {
            device: improvementDeviceType,
            device_cost: deviceCost,
            device_conversions: deviceConversions,
            device_cpa: deviceCPA,
            device_roas: 0,
            device_profit: 0,
        },
        ...(improvementBody.campaign_devices_data ?? []),
    ]
    const campaignDevicesTableItems = campaignDevicesTableData.map(device => {
        return {
            id: device.device,
            device: device.device,
            cost: device.device_cost,
            cpa: device.device_cpa,
            'average-cpa': campaignCPA,
            difference: (device.device_cpa - campaignCPA) / campaignCPA,
        }
    })

    // End Table Data

    const barChartItems: BarChartTypes.BarChartItem[] = DEVICES.map(type => {
        const deviceType: AdjustDeviceBid.OtherDeviceData | undefined =
            improvementBody.campaign_devices_data?.find(d => d.device === type)

        const highlighted = improvementDeviceType === type
        let y_value = 0

        if (highlighted) {
            y_value = isFinite(deviceCPA) ? deviceCPA : 0
        } else {
            y_value = isFinite(deviceType?.device_cpa) ? deviceType?.device_cpa! : 0
        }

        return {
            y: y_value,
            label: type,
            highlighted,
        }
    })

    const donutChartItems: DonutChartTypes.DonutChartItem[] = improvementBody.campaign_devices_data
        ? DEVICES.map(type => {
              const deviceType: AdjustDeviceBid.OtherDeviceData =
                  improvementBody.campaign_devices_data!.find(d => d.device === type)!

              const highlighted = improvementDeviceType === type

              let y_value = 0

              if (highlighted) {
                  y_value = isFinite(deviceCost) ? deviceCost : 0
              } else {
                  y_value = isFinite(deviceType?.device_cost) ? deviceType?.device_cost : 0
              }

              return {
                  y: y_value,
                  label: type,
                  highlighted,
              }
          }).filter(chart_item => chart_item.y)
        : []

    const domainCurrencyCode = inject<CurrencyCode>(ProvideKeys.CurrencyCode)

    const improvementEntityPillList = computed<EntityPill[]>(() => {
        return [
            {
                type: Improvement.LocationEntity.Campaign,
                content: campaignName,
            },
        ]
    })

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Applying device bid adjustment..',
        'Confirming changes..',
        'Bid adjustment applied successfully.',
    ])

    const pushActionText = ref('Apply Bid Adjustment')

    const onPush = () => {
        const valid = !(+bidToPush.value === 0 || isNaN(bidToPush.value))
        const bid = parseFloat(bidToPush.value?.toFixed(2) ?? '0')
        return { pushedData: { value: bid }, valid }
    }

    return {
        title,
        pushMessages,
        lastUpdated,
        campaignName,
        deviceCPA,
        deviceCost,
        deviceConversions,
        campaignCPA,
        campaignGroupName,
        campaignGroupCPA,
        cpaToCampaignRatio,
        cpaToCampaignRatioLabel,
        cpaToGroupRatio,
        cpaToGroupRatioLabel,
        currentBidMod,
        unitBid,
        targetCPA,
        usingTargetCPA,
        bidModifier,
        absoluteBidModifier,
        improvementDeviceType,
        barChartItems,
        donutChartItems,
        domainCurrencyCode,
        isIncreasingBid,
        onPush,
        impBidAdjustmentRef,
        recommendedBidRelativeToUnitBid,
        improvementEntityPillList,
        onBidUpdate,
        formattedDeviceCPA,
        formattedCampaignCPA,
        formattedCampaignGroupCPA,
        formattedBidModifier,
        // Table Data
        campaignDevicesTableItems,
        campaignDevicesTableHeaders,
        pushActionText,
    }
}
