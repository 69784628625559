<template>
    <section :style="{ width: width + 'px' }" :class="{ 'no-margin': noMargin }" class="lo-section">
        <!-- Container -->
        <div class="lo-container">
            <!-- Title -->
            <Text v-if="title" as="h4" weight="600">{{ title }}</Text>
            <Spacer v-if="title" height="2rem" />
            <!-- Illustration slot -->
            <div v-if="$slots.illustration" class="lo-illustration">
                <slot name="illustration"></slot>
            </div>
            <!-- Info slot -->
            <div v-if="$slots.info" class="lo-info">
                <slot name="info"></slot>
            </div>
            <!-- Google slot -->
            <div v-if="$slots.google" class="lo-google">
                <slot name="google"></slot>
            </div>
            <!-- Content slot -->
            <div v-if="$slots.content" class="lo-content">
                <slot name="content"></slot>
            </div>
        </div>
        <!-- Links -->
        <div v-if="$slots.links" class="lo-links">
            <slot name="links"></slot>
        </div>
    </section>
</template>

<script>
import { Text, Spacer } from '@opteo/components-next'

export default {
    components: {
        Text,
        Spacer,
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        width: {
            type: Number,
            required: false,
            default: 400,
        },
        noMargin: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.lo-section {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: auto;
    margin-right: auto;
}
.lo-section.no-margin {
    margin-top: 0;
    margin-bottom: 0;
}

.lo-container {
    @include container;
    @include br-24;
    @include pa-32;
    @include relative;
    overflow: hidden;
}
.lo-illustration {
    width: 100%;
    @include tc;
    @include mt-4;
    @include mb-32;
}
.lo-info {
    @include container;
    @include f-8;
    @include lh-150;
    @include pa-24;
    @include mb-32;
    @include flex;
    @include items-center;
}
.lo-google {
    border-bottom: 1px solid $opteo-light-gray;
    @include pb-32;
    @include mb-28;
    @include no-select;
}
.lo-links {
    @include mt-32;
}
.lo-links a {
    @include opteo-black;
    @include f-9;
    @include tc;
    @include mb-12;
    display: block;
    outline: none;
}

// Responsive

@media screen and (max-width: 374px) {
    .lo-section {
        width: calc(100% - 1rem) !important;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        margin-bottom: 3rem;
    }
    .lo-container {
        @include pa-24;
    }
}

@media screen and (min-width: 375px) and (max-width: $mq-767-max) {
    .lo-section {
        width: calc(100% - 2rem) !important;
        max-width: 400px;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }
}
</style>
