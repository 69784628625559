<template>
    <div class="time-savings-cell-contents">
        <div class="time-savings-cell-count">{{ count }}</div>
        <div class="time-savings-cell-time-saved">{{ formattedTimeSaved }}</div>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'

import useImpactStatsTimeSavings from '@/composition/impactStatistics/useImpactStatsTimeSavings'

const { formatFromMinutes } = useImpactStatsTimeSavings()

const props = defineProps({
    count: {
        type: Number,
        required: true,
    },
    timeSaved: {
        type: Number,
        required: true,
    },
})

const formattedTimeSaved = computed(() => {
    return `(${formatFromMinutes(props.timeSaved, false)})`
})
</script>
<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.time-savings-cell-contents {
    @include flex;
    @include items-center;
    gap: 0.375rem;
}
.time-savings-cell-contents .time-savings-cell-count {
    @include f-8;
}
.time-savings-cell-contents .time-savings-cell-time-saved {
    @include f-8;
    @include fw-400;
    @include opteo-steel;
}
</style>
