<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 604 436"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="placement-container"
    >
        <g filter="url(#filter0_d_121_2772)">
            <rect x="62" y="60" width="480" height="316" rx="20" fill="white" />
        </g>
        <rect x="204" y="90" width="278" height="20" rx="6" fill="#F0F0F5" />
        <rect x="94" y="322" width="195" height="8" rx="4" fill="#F0F0F5" />
        <rect x="94" y="338" width="159" height="8" rx="4" fill="#F0F0F5" />
        <rect x="490" y="90" width="20" height="20" rx="6" fill="#F0F0F5" />
        <g clip-path="url(#clip0_121_2772)">
            <path
                d="M118.243 94.6548C117.958 93.6092 117.12 92.7876 116.054 92.5078C114.124 92 106.38 92 106.38 92C106.38 92 98.637 92 96.7068 92.5078C95.6408 92.7876 94.8031 93.6092 94.5177 94.6548C94 96.5479 94 100.5 94 100.5C94 100.5 94 104.452 94.5177 106.345C94.8031 107.391 95.6408 108.212 96.7068 108.492C98.637 109 106.38 109 106.38 109C106.38 109 114.124 109 116.054 108.492C117.12 108.212 117.958 107.391 118.243 106.345C118.761 104.452 118.761 100.5 118.761 100.5C118.761 100.5 118.759 96.5479 118.243 94.6548Z"
                fill="#FF0000"
            />
            <path d="M103.902 104.143L110.335 100.5L103.902 96.858V104.143Z" fill="white" />
            <path
                d="M123.989 103.053L121.209 93.2057H123.635L124.609 97.6696C124.857 98.7691 125.039 99.7066 125.157 100.482H125.228C125.31 99.9265 125.493 98.995 125.777 97.6856L126.786 93.2057H129.211L126.396 103.053V107.777H123.987V103.053H123.989Z"
                fill="#444444"
            />
            <path
                d="M129.941 107.465C129.452 107.141 129.104 106.637 128.896 105.953C128.69 105.27 128.586 104.362 128.586 103.227V101.681C128.586 100.536 128.704 99.6143 128.941 98.9207C129.177 98.227 129.546 97.7192 130.047 97.4014C130.549 97.0835 131.207 96.9236 132.022 96.9236C132.825 96.9236 133.468 97.0855 133.953 97.4094C134.436 97.7332 134.79 98.241 135.015 98.9287C135.239 99.6183 135.351 100.536 135.351 101.681V103.227C135.351 104.362 135.241 105.274 135.023 105.961C134.805 106.651 134.45 107.155 133.961 107.473C133.472 107.791 132.807 107.95 131.969 107.95C131.105 107.952 130.431 107.789 129.941 107.465ZM132.685 105.797C132.819 105.45 132.889 104.884 132.889 104.096V100.78C132.889 100.016 132.821 99.4564 132.685 99.1046C132.548 98.7508 132.31 98.5748 131.967 98.5748C131.637 98.5748 131.403 98.7508 131.268 99.1046C131.132 99.4584 131.064 100.016 131.064 100.78V104.096C131.064 104.884 131.13 105.452 131.26 105.797C131.391 106.145 131.625 106.319 131.967 106.319C132.31 106.319 132.548 106.145 132.685 105.797Z"
                fill="#444444"
            />
            <path
                d="M143.239 107.779H141.328L141.116 106.475H141.063C140.543 107.459 139.764 107.951 138.725 107.951C138.005 107.951 137.473 107.719 137.131 107.257C136.788 106.793 136.617 106.07 136.617 105.086V97.1319H139.061V104.946C139.061 105.422 139.114 105.76 139.22 105.962C139.326 106.164 139.503 106.266 139.752 106.266C139.964 106.266 140.168 106.202 140.364 106.074C140.559 105.946 140.702 105.784 140.798 105.588V97.1299H143.239V107.779Z"
                fill="#444444"
            />
            <path
                d="M149.879 95.1344H147.453V107.778H145.062V95.1344H142.637V93.2074H149.879V95.1344Z"
                fill="#444444"
            />
            <path
                d="M155.773 107.779H153.861L153.649 106.475H153.596C153.076 107.459 152.298 107.951 151.258 107.951C150.538 107.951 150.006 107.719 149.664 107.257C149.322 106.793 149.15 106.07 149.15 105.086V97.1319H151.594V104.946C151.594 105.422 151.647 105.76 151.753 105.962C151.859 106.164 152.037 106.266 152.285 106.266C152.497 106.266 152.701 106.202 152.897 106.074C153.092 105.946 153.235 105.784 153.331 105.588V97.1299H155.773V107.779Z"
                fill="#444444"
            />
            <path
                d="M163.862 98.8329C163.713 98.1612 163.475 97.6754 163.145 97.3736C162.815 97.0717 162.36 96.9218 161.781 96.9218C161.333 96.9218 160.913 97.0457 160.524 97.2956C160.134 97.5455 159.833 97.8714 159.621 98.2772H159.602V92.6678H157.248V107.777H159.266L159.515 106.769H159.568C159.757 107.129 160.04 107.411 160.418 107.621C160.795 107.829 161.215 107.933 161.675 107.933C162.501 107.933 163.11 107.559 163.499 106.813C163.889 106.065 164.084 104.9 164.084 103.313V101.628C164.084 100.438 164.009 99.5046 163.862 98.8329ZM161.622 103.177C161.622 103.952 161.59 104.56 161.524 105C161.459 105.44 161.351 105.754 161.196 105.938C161.043 106.123 160.835 106.215 160.576 106.215C160.375 106.215 160.189 106.169 160.018 106.075C159.847 105.984 159.708 105.844 159.602 105.66V99.6165C159.684 99.3267 159.826 99.0908 160.028 98.9049C160.228 98.719 160.448 98.627 160.682 98.627C160.931 98.627 161.123 98.723 161.257 98.9129C161.394 99.1048 161.488 99.4246 161.541 99.8764C161.594 100.328 161.62 100.97 161.62 101.803V103.177H161.622Z"
                fill="#444444"
            />
            <path
                d="M167.551 103.791C167.551 104.474 167.571 104.986 167.612 105.328C167.653 105.67 167.738 105.918 167.869 106.076C167.999 106.231 168.199 106.309 168.47 106.309C168.835 106.309 169.088 106.169 169.222 105.892C169.359 105.614 169.432 105.15 169.444 104.502L171.552 104.624C171.564 104.716 171.57 104.844 171.57 105.006C171.57 105.989 171.295 106.725 170.747 107.211C170.199 107.697 169.422 107.941 168.419 107.941C167.215 107.941 166.371 107.571 165.888 106.829C165.403 106.087 165.162 104.942 165.162 103.391V101.532C165.162 99.9344 165.413 98.7669 165.914 98.0313C166.416 97.2956 167.274 96.9278 168.491 96.9278C169.328 96.9278 169.972 97.0777 170.421 97.3796C170.869 97.6814 171.185 98.1492 171.369 98.7869C171.552 99.4246 171.644 100.304 171.644 101.428V103.251H167.551V103.791ZM167.861 98.7729C167.736 98.9228 167.655 99.1687 167.612 99.5105C167.571 99.8524 167.551 100.37 167.551 101.066V101.829H169.338V101.066C169.338 100.382 169.314 99.8644 169.267 99.5105C169.22 99.1567 169.135 98.9088 169.01 98.7629C168.886 98.619 168.694 98.545 168.436 98.545C168.175 98.547 167.983 98.623 167.861 98.7729Z"
                fill="#444444"
            />
        </g>
        <rect x="94" y="140" width="277" height="170" rx="8" fill="#F0F0F5" />
        <rect opacity="0.1" x="387" y="140" width="123" height="48" rx="4" fill="#FF2828" />
        <rect opacity="0.1" x="478" y="192" width="32" height="16" rx="4" fill="#FF2828" />
        <rect opacity="0.1" x="107" y="261" width="146" height="38" rx="4" fill="#FF2828" />
        <rect opacity="0.5" x="387" y="202" width="16" height="6" rx="3" fill="#FF9500" />
        <rect opacity="0.1" x="387" y="192" width="60" height="6" rx="3" fill="#FF2828" />
        <rect width="55" height="34" rx="4" transform="matrix(1 0 0 -1 387 260)" fill="#F0F0F5" />
        <rect x="450" y="237" width="49" height="6" rx="3" fill="#F0F0F5" />
        <rect x="450" y="227" width="60" height="6" rx="3" fill="#F0F0F5" />
        <rect width="55" height="34" rx="4" transform="matrix(1 0 0 -1 387 310)" fill="#F0F0F5" />
        <rect x="450" y="287" width="49" height="6" rx="3" fill="#F0F0F5" />
        <rect x="450" y="277" width="60" height="6" rx="3" fill="#F0F0F5" />
        <circle cx="510" cy="140" r="11" fill="#FB3535" />
        <path
            d="M513 137L510 140M507 143L510 140M510 140L507 137M510 140L513 143"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M513 137L510 140M507 143L510 140M510 140L507 137M510 140L513 143"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <defs>
            <filter
                id="filter0_d_121_2772"
                x="26"
                y="48"
                width="552"
                height="388"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="12"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect1_dropShadow_121_2772"
                />
                <feOffset dy="24" />
                <feGaussianBlur stdDeviation="24" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_121_2772"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_121_2772"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_121_2772">
                <rect width="78" height="17" fill="white" transform="translate(94 92)" />
            </clipPath>
        </defs>
    </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        width: {
            type: String,
            required: true,
            default: '100%',
        },
        height: {
            type: String,
            required: true,
            default: 'auto',
        },
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.placement-container {
    @include bg-opteo-light-gray;
    @include br-20;
}
</style>
