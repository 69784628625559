<template>
    <OpteoLogo :width="44" :height="44" class="opteo-logo" />
    <!-- Default -->
    <LoginContainer
        v-if="!noProfilesDetected"
        :width="436"
        title="Connect Google Ads"
        class="connect-google-ads-top"
    >
        <template v-slot:info>
            Connect Google Ads by clicking the button below and signing in with Google. On the next
            page, make sure to choose your primary Google Ads account.
        </template>
        <template v-slot:content>
            <!-- Error message -->
            <Text v-if="oauthError" as="p" color="red" class="error-container">
                <svg
                    aria-hidden="true"
                    height="12"
                    width="12"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0113.634 16H2.365A2.365 2.365 0 01.25 12.577L5.884 1.308a2.365 2.365 0 014.231 0zM8 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z"
                        fill-rule="evenodd"
                        fill="#FF2828"
                    />
                </svg>
                <span class="error-message">
                    Connection failed. Click the button again to retry.
                </span>
            </Text>
            <Spacer v-if="oauthError" height="2rem" />
            <oButton size="login" @clicked="connectGoogleAds" :loading="loading">
                Connect Google Ads
            </oButton>
        </template>
    </LoginContainer>
    <!-- No accounts detected -->
    <LoginContainer
        v-if="noProfilesDetected"
        :width="436"
        title="No Accounts Detected"
        class="connect-google-ads-top"
    >
        <template v-slot:info>
            <span>
                Unfortunately, we couldn't detect any Google Ads accounts associated with the Google
                account you just connected. Try connecting another account. If you continue to have
                problems, message <a onclick="Intercom('show')">support</a>.
            </span>
        </template>
        <template v-slot:content>
            <!-- Error message -->
            <Text v-if="oauthError" as="p" color="red" class="error-container">
                <svg
                    aria-hidden="true"
                    height="12"
                    width="12"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0113.634 16H2.365A2.365 2.365 0 01.25 12.577L5.884 1.308a2.365 2.365 0 014.231 0zM8 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z"
                        fill-rule="evenodd"
                        fill="#FF2828"
                    />
                </svg>
                <span class="error-message">
                    Connection failed. Click the button again to retry.
                </span>
            </Text>
            <Spacer v-if="oauthError" height="2rem" />
            <oButton size="login" @clicked="connectGoogleAds" :loading="loading">
                Connect Google Ads
            </oButton>
        </template>
    </LoginContainer>
    <!-- Additional context -->
    <LoginContainer :width="436" class="connect-google-ads-bottom">
        <template v-slot:content>
            <div v-if="!userLoading">
                <div class="context-row">
                    <SmallCheck />
                    <span>
                        Opteo needs access to your Google Ads account to gather the data needed to
                        generate improvements.
                    </span>
                </div>
                <Spacer height="1.5rem" />
                <div class="context-row">
                    <SmallCheck />
                    <span>
                        Opteo can only make changes in Google Ads with your permission. No hidden or
                        unwanted changes.
                    </span>
                </div>
                <Spacer height="1.5rem" />
                <div v-if="isAdvertiser" class="context-row">
                    <SmallCheck />
                    <span>
                        We promise never to share your data with, or sell your data to any third
                        parties. No matter what happens.
                    </span>
                </div>
                <div v-if="isAgency" class="context-row">
                    <SmallCheck />
                    <span>
                        Clients never see Opteo in their change history. All changes come from your
                        Google Ads email address.
                    </span>
                </div>
            </div>
            <div v-else>
                <div v-for="i in 3">
                    <div class="context-row">
                        <SmallCheck />
                        <span>
                            <Skeleton :height="16" :width="320" />
                            <Spacer height="0.625rem" />
                            <Skeleton :height="16" :width="300" />
                        </span>
                    </div>
                    <Spacer v-if="i !== 3" height="1.5rem" />
                </div>
            </div>
        </template>
    </LoginContainer>
    <oButton color="white" size="small" class="logout-button" @clicked="logout">Logout</oButton>
</template>

<script lang="ts">
import LoginContainer from '@/components/global/LoginContainer.vue'
import SmallCheck from '@/components/global/SmallCheck.vue'
import Skeleton from '@/components/util/Skeleton.vue'

import { useConnectAdwords } from '@/composition/user/useConnectAdwords'
import { oButton, OpteoLogo, Text, Spacer } from '@opteo/components-next'

export default {
    name: 'ConnectGoogleAds',
    components: {
        LoginContainer,
        SmallCheck,
        oButton,
        OpteoLogo,
        Text,
        Spacer,
        Skeleton,
    },
    setup() {
        return useConnectAdwords()
    },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.context-row {
    @include flex;
    @include items-center;
    @include f-8;
    @include lh-150;
}
.context-row span {
    @include ml-16;
}

.connect-google-ads-top.lo-section {
    @include mb-40;
}
.connect-google-ads-bottom.lo-section {
    @include mt-40;
}

.error-container {
    display: inline-flex !important;
    @include items-center;
    width: 100%;
    @include container;
    @include pv-16;
    @include ph-24;
}
.error-message {
    @include f-9;
    @include ml-8;
}

// External elements

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}
.logout-button {
    position: fixed !important;
    bottom: 1.625rem;
    left: 1.625rem;
}
@media screen and (max-width: $mq-767-max) {
    .opteo-logo,
    .logout-button {
        display: none !important;
    }
}
</style>
