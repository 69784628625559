<template>
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="9" :fill="hex" />
        <path
            d="m6 9 2 2 4-4"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script lang="ts">
export default {
    props: {
        hex: {
            type: String,
            default: '#00A861',
            required: false,
        },
    },
}
</script>
