<template>
    <div class="last-login-cell" @click="selectAccount(account, linked, !accountIsChecked)">
        <Text as="span" size="f-8">{{
            value
                ? formatDistanceToNowStrict(new Date(value), {
                      addSuffix: true,
                  })
                : 'Never'
        }}</Text>
    </div>
</template>

<script lang="ts" setup>
import { Text } from '@opteo/components-next'
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { Team, Account } from '@opteo/types'
import { computed } from 'vue'

const props = defineProps<{ value: string | number; account: Team.Account; linked: boolean }>()

const { formatDistanceToNowStrict, selectAccount, queuedLinkedAccounts, queuedUnlinkedAccounts } =
    useLinkedAccounts()

const accountIsChecked = computed(() => {
    if (!props.account) return false
    const accountId = props.account.accountId as Account.ID

    if (props.linked) return queuedLinkedAccounts.value[accountId] >= 0

    return queuedUnlinkedAccounts.value[accountId] >= 0
})
</script>

<style scoped>
.last-login-cell {
    height: 4.625rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}
</style>
