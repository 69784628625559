import { computed, ComputedRef, Ref, ref } from 'vue'

import {
    EntityPill,
    OnPushHandler,
    UseImprovement,
    useImprovement,
} from '@/composition/improvement/useImprovement'
import { usePercent } from '@opteo/components-next'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useDomain } from '@/composition/domain/useDomain'

// TODO: Move to @opteo/types
export interface AdjustKeywordBidBody {
    campaign_id: number
    adgroup_id: number
    keyword_id: number
    campaign_label: string
    adgroup_label: string
    keyword_text: string
    keyword_text_mt: string
    clicks: number
    impressions: number
    average_position: number
    search_impression_share: number
    cost: number
    conversions: number
    avg_cpa: number
    cpa: number
    conversion_name: string
    max_cpc: number
    new_bid: number
    direction: 'down' | 'up'
    bid_change: number
    bid_change_text: string
    impr_abs_top: number
    first_page_cpc: number
    is_equal_to_first_page_bid: boolean
    all_keywords: {
        keyword_text_mt: string
        campaign: string
        adgroup: string
        match_type: string
        cost: number
        conversions: number
        focus: boolean
    }[]
}

// TODO: Expose and import from @opteo/components
export interface ChartItem {
    x?: number
    y: number
    label: string
    highlighted?: boolean
}

interface UseAdjustKeywordBid {
    isBidIncreasing: boolean
    conversions: number
    formattedKeyword: string
    cost: number
    percentBidChange: number
    averageCPA: number
    currentBid: number
    bidDirection: string
    scatterPointChartItems: ChartItem[]
    bidChange: number
    formattedCurrentBid: string
    formattedNewBid: string
    campaignGroupName: string
    formattedAverageCPA: string
    keywordCPA: number
    formattedKeywordCPA: string
    searchImprShare: number
    formattedSearchImprShare: string
    absoluteTopImprShare: number
    formattedAbsoluteTopImprShare: string
    firstPageBid: number
    formattedFirstPageBid: string
    isEqualToFirstPageBid: boolean
    newBid: number
    adjustedBid: Ref<number>
    onBidUpdate: (updatedBid: number) => void
    improvementEntityPillList: ComputedRef<EntityPill[]>
    impBidAdjustmentRef: Ref
    currencyCode: ComputedRef<string | undefined>
}

type BidDirection = 'increase' | 'decrease'

interface PushedData {
    sign: BidDirection
    value: number // Absolute value of the bid
}

export function useAdjustKeywordBid(): UseImprovement<UseAdjustKeywordBid> {
    const { currencyCode } = useDomain()
    const { improvement, lastUpdated, title } = useImprovement<AdjustKeywordBidBody>()

    /* 
        BID ADJUSTMENT
        Note : any variable that is adjustable, should be a ref()
     */
    const adjustedBid = ref(improvement.value?.body.new_bid ?? 0)
    const improvementEntityPillList = computed<EntityPill[]>(() => {
        const campaign = improvement.value?.location.find(l => l.entity === 'campaign')!

        return [
            {
                type: campaign.entity,
                content: campaign.label,
            },
        ]
    })

    const impBidAdjustmentRef = ref()

    // Improvement View Props
    const currentBid = improvement.value?.body.max_cpc ?? 0
    const newBid = improvement.value?.body.new_bid ?? 0
    const isBidIncreasing = newBid > currentBid

    const bidDirection = isBidIncreasing ? 'increase' : 'decrease'

    const isAdjustedBidIncreasing = computed(() => adjustedBid.value > currentBid)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Applying keyword bid adjustment..',
        'Confirming changes..',
        'Bid adjustment applied successfully.',
    ])

    const bidChange = improvement.value?.body.bid_change ?? 0

    const adjustedBidChange = computed(() => ((adjustedBid.value - currentBid) / currentBid) * 100)

    const onPush: OnPushHandler<PushedData> = () => {
        const sign: BidDirection = isAdjustedBidIncreasing.value ? 'increase' : 'decrease'

        const pushedData = {
            sign,
            value: Math.abs(adjustedBidChange.value),
        }

        const valid =
            typeof adjustedBidChange.value === 'number' &&
            (impBidAdjustmentRef.value ? impBidAdjustmentRef.value.valid : true)

        return {
            valid,
            pushedData,
        }
    }

    const pushActionText = ref('Apply Bid Adjustment')

    // Improvement Header
    const formattedKeyword = improvement.value?.body.keyword_text_mt ?? ''
    const conversions = improvement.value?.body.conversions ?? 0

    const campaignGroupName = improvement.value?.body.conversion_name ?? ''

    function onBidUpdate(updatedBid: number) {
        adjustedBid.value = updatedBid
    }

    // Note : in future improvement bodies, these items should be calculated in the backend
    const scatterPointChartItems =
        improvement.value?.body.all_keywords.map(keyword => {
            return {
                x: keyword.conversions,
                y: keyword.cost,
                label: keyword.keyword_text_mt,
                highlighted: keyword.focus,
            }
        }) ?? []

    const cost = improvement.value?.body.cost ?? 0

    const percentBidChange = bidChange / 100

    // Recommended Bid Adjustment
    const { displayValue: formattedCurrentBid } = useDomainMoney({ value: currentBid }).value
    const { displayValue: formattedNewBid } = useDomainMoney({
        value: improvement.value?.body.new_bid ?? 0,
    }).value

    const isEqualToFirstPageBid = !!improvement.value?.body.is_equal_to_first_page_bid

    // Improvement Stats
    const averageCPA = improvement.value?.body.avg_cpa ?? 0
    const { displayValue: formattedAverageCPA } = useDomainMoney({
        value: averageCPA,
    }).value

    const keywordCPA = improvement.value?.body.cpa ?? 0
    const { displayValue: formattedKeywordCPA } = useDomainMoney({
        value: keywordCPA,
    }).value

    const searchImprShare = (improvement.value?.body.search_impression_share ?? 0) / 100

    const { displayValue: formattedSearchImprShare } = usePercent({
        value: searchImprShare,
    })

    const absoluteTopImprShare = improvement.value?.body.impr_abs_top ?? 0
    const { displayValue: formattedAbsoluteTopImprShare } = usePercent({
        value: absoluteTopImprShare,
    })

    const firstPageBid = improvement.value?.body.first_page_cpc ?? 0
    const { displayValue: formattedFirstPageBid } = useDomainMoney({
        value: firstPageBid,
    }).value

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        isBidIncreasing,
        conversions,
        formattedKeyword,
        cost,
        percentBidChange,
        averageCPA,
        currentBid,
        adjustedBid,
        bidDirection,
        scatterPointChartItems,
        bidChange,
        formattedCurrentBid: formattedCurrentBid.value,
        formattedNewBid: formattedNewBid.value,
        campaignGroupName,
        formattedAverageCPA: formattedAverageCPA.value,
        keywordCPA,
        formattedKeywordCPA: formattedKeywordCPA.value,
        searchImprShare,
        formattedSearchImprShare: formattedSearchImprShare.value,
        absoluteTopImprShare,
        formattedAbsoluteTopImprShare: formattedAbsoluteTopImprShare.value,
        firstPageBid,
        formattedFirstPageBid: formattedFirstPageBid.value,
        isEqualToFirstPageBid,
        newBid,
        onBidUpdate,
        improvementEntityPillList,
        impBidAdjustmentRef,
        currencyCode,
        pushActionText,
    }
}
