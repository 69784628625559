<template>
    <div ref="sidebarRef" class="sidebar-menu">
        <div
            ref="focusTrapRef"
            class="trap"
            tabindex="-1"
            @click="onClickInside"
            @keydown.enter="onClickInside"
        >
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import {
    defineComponent,
    ref,
    PropType,
    computed,
    onMounted,
    onUnmounted,
    nextTick,
    watch,
} from 'vue'
import { useShortKey } from '@opteo/components-next'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
    name: 'LeftSidebarMenu',
    props: {
        id: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            required: false,
            default: 384,
        },
        onClickOutside: {
            type: Function as PropType<(clickedElement: HTMLElement) => void | boolean>,
            required: false,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const sidebarRef = ref()

        const focusTrapRef = ref()
        const returnFocusOnDeactivate = ref()

        const sidebarWidth = computed(() => `${props.width}px`)

        useShortKey({
            keys: ['esc'],
            onPressCallBack: () => {
                const openModals = Array.from(document.querySelectorAll('.modal')).length > 0

                if (openModals) {
                    return
                }

                emit('close')
            },
        })

        const { hasFocus, activate, deactivate, isPaused, pause, unpause } = useFocusTrap(
            focusTrapRef,
            {
                initialFocus: false,
                returnFocusOnDeactivate: true,
                allowOutsideClick: true,
                fallbackFocus: () => focusTrapRef.value,
                setReturnFocus: previousActiveElement => {
                    if (returnFocusOnDeactivate.value !== undefined) {
                        return returnFocusOnDeactivate.value
                    }

                    return previousActiveElement
                },
                onPostDeactivate: () => {
                    if (returnFocusOnDeactivate.value) {
                        returnFocusOnDeactivate.value = undefined
                    }
                },
            }
        )

        onClickOutside(sidebarRef, event => {
            if (!hasFocus.value) {
                return
            }
            const clickedElement = event.target as HTMLElement
            if (!clickedElement.classList.contains(props.id)) {
                returnFocusOnDeactivate.value = clickedElement
            }

            if (
                !!clickedElement.classList.contains('opteo-sidebar-menu-link') ||
                (props.onClickOutside && props.onClickOutside(clickedElement))
            ) {
                return
            }
            emit('close')
        })

        const onClickInside = (event: Event) => {
            const clickedElement = event.target as HTMLElement
            if (!!clickedElement.closest('.alerts-list-row')) {
                pause()
            }
        }

        const handleFocus = (event: FocusEvent) => {
            if (isPaused.value) {
                unpause()
            }
        }

        onMounted(async () => {
            await nextTick()
            activate()
            focusTrapRef.value?.addEventListener('focusin', handleFocus)
        })

        onUnmounted(() => {
            if (hasFocus) {
                deactivate()
            }
            focusTrapRef.value?.removeEventListener('focusin', handleFocus)
        })

        watch(hasFocus, focus => {
            console.log('LeftSidebarMenu focus', focus)
        })

        return {
            sidebarWidth,
            sidebarRef,
            focusTrapRef,
            onClickInside,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.sidebar-menu {
    @include fixed;
    @include bg-opteo-white;
    z-index: 99998;
    box-shadow: $opteo-shadow;
    width: v-bind(sidebarWidth);
    height: 100vh;
    bottom: 0;
    left: 6rem;
    overflow: hidden;
    will-change: transform;
}
</style>
