<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="flex-shrink: 0"
    >
        <circle cx="12" cy="12" r="12" fill="#FF2828" fill-opacity=".05" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 6.5c-.5523 0-1 .44772-1 1v4c0 .5523.4477 1 1 1s1-.4477 1-1v-4c0-.55228-.4477-1-1-1ZM12 17c.8284 0 1.5-.6716 1.5-1.5S12.8284 14 12 14s-1.5.6716-1.5 1.5.6716 1.5 1.5 1.5Z"
            fill="#FF2828"
        />
    </svg>
</template>
