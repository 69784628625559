<template>
    <div :class="['button-container', { 'is-hovered': props.animationTrigger }]">
        <Text as="p" size="f-7" weight="500"> Open Scorecard </Text>
        <div class="icon-container">
            <RightArrowIcon hex="#006DFF" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Text, Spacer, RightArrowIcon } from '@opteo/components-next'

const props = defineProps({
    animationTrigger: Boolean,
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.button-container {
    @include relative;
    @include container;
    @include br-32;
    @include flex;
    @include items-center;
    overflow: hidden;
    width: 3.25rem;
    height: 3.25rem;
    transition: width cubic-bezier(0.19, 1, 0.22, 1) 1s;
}
.button-container p {
    white-space: nowrap;
    @include ml-20;
}
.button-container .icon-container {
    @include flex-center;
    @include absolute;
    top: 0;
    right: 0;
    width: 3.25rem;
    height: 3.25rem;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 25%);
}
.is-hovered.button-container {
    transition: width cubic-bezier(0.19, 1, 0.22, 1) 1s;
    width: 190px;
}
</style>
