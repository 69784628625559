<template>
    <SettingsSection id="cancel-subscription" title="Cancel Subscription" :maxWidth="522">
        <div class="cancel-subscription-wrapper">
            <div class="cancel-details">
                <Text size="f-8">
                    To cancel your subscription, click the button below. If you have a few seconds
                    to spare, we'd appreciate the chance to ask you a few questions about your Opteo
                    experience, why you're choosing to leave, and how we could improve our product
                    in the future.
                </Text>
                <Spacer height="0.75rem" />
                <Text size="f-8">
                    To revoke our API access, update your
                    <a
                        target="_blank "
                        href="https://security.google.com/settings/security/permissions "
                        >Google App Permissions</a
                    >.
                </Text>
            </div>
            <Spacer height="2rem" />
            <div class="cancel-button">
                <oButton
                    id="cancel-subscription"
                    size="medium"
                    color="red"
                    @clicked="openCancelModal()"
                >
                    Cancel Subscription
                </oButton>
            </div>
        </div>
    </SettingsSection>

    <!-- Cancel Subscription Modal -->
    <Modal v-model="showCancelationModal" title="Cancel Subscription" width="524px">
        <template #content>
            <div v-if="!cancelationSuccesful">
                <div class="cancel-info">
                    <Text size="f-8">
                        Select a reason for cancellation and click the button below to cancel your
                        subscription. You will still be able to access Opteo until the end of your
                        current billing cycle, which is on
                        <b>{{ nextChargeDate }}</b
                        >.
                    </Text>
                </div>
                <Spacer height="1.5rem" />
                <oInput
                    v-model="selectedCancellationReason"
                    :items="userCancelationReasons"
                    type="select"
                    label="Reason for Cancellation"
                />
                <Spacer height="1.5rem" />
                <oInput
                    v-model="cancellationComments"
                    type="textarea"
                    label="Additional Comments"
                />
            </div>

            <div v-else>
                <div class="ph4 ph5-ns pv4" style="margin-left: 15px; margin-right: 15px">
                    <img src="@/assets/img/cancel-confirm.png" style="width: 100%" />
                </div>
                <p class="f6 lh-copy db pa4 bt b--opteo-light-gray">
                    You can still access Opteo until
                    <b>{{ nextChargeDate }}</b
                    >. Your payment card won't be charged again. We're sorry to see you go.
                </p>
            </div>
        </template>

        <template #buttons>
            <oButton size="medium" color="white" @clicked="showCancelationModal = false">
                Close Modal
            </oButton>
            <Spacer width="0.75rem" />
            <oButton
                v-if="!cancelationSuccesful"
                size="medium"
                color="red"
                :disabled="!selectedCancellationReason"
                :loading="updatingSubscription"
                @clicked="cancelSubscription()"
            >
                Cancel Subscription
            </oButton>
            <div v-else>
                <oButton
                    id="close-cancel-modal"
                    size="medium"
                    color="white"
                    @clicked="hideCancelModal()"
                >
                    Close Window
                </oButton>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { ref } from 'vue'
import { oButton, oInput, Text, Money, CloseButton, Modal, Spacer } from '@opteo/components-next'

import { pushToDatalayer } from '@/lib/datalayer'

import { useSubscription } from '@/composition/billing/useSubscription'
import { authRequest, Endpoint } from '@/composition/api/useAPI'

import SettingsSection from '@/components/global/SettingsSection.vue'

export default {
    name: 'SubscriptionCancel',
    components: { oButton, oInput, Text, Money, CloseButton, Modal, SettingsSection, Spacer },
    setup() {
        const { nextChargeDate, userCancelationReasons, mutate } = useSubscription()

        const showCancelationModal = ref(false)

        const cancellationComments = ref()
        const selectedCancellationReason = ref()

        const cancelationSuccesful = ref(false)

        const updatingSubscription = ref(false)

        function openCancelModal() {
            showCancelationModal.value = true
        }

        function hideCancelModal() {
            showCancelationModal.value = false
        }

        async function cancelSubscription() {
            updatingSubscription.value = true
            await authRequest(Endpoint.CancelSubscription, {
                body: {
                    reason: selectedCancellationReason.value,
                    comments: cancellationComments.value,
                },
            })

            updatingSubscription.value = false

            mutate()

            pushToDatalayer('gtm_cancelled_subscription')
        }

        function resetModal() {
            cancelationSuccesful.value = false
        }

        return {
            openCancelModal,
            hideCancelModal,
            nextChargeDate,
            resetModal,
            showCancelationModal,
            cancelationSuccesful,
            selectedCancellationReason,
            userCancelationReasons,
            cancellationComments,
            updatingSubscription,
            cancelSubscription,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.cancel-subscription-wrapper {
    @include pa-32;
}

.cancel-details,
.cancel-info {
    @include container;
    @include ph-24;
    @include pv-20;
}
.cancel-button {
    @include flex-center;
    @include w-100;
}
</style>
