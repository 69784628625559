<template>
    <div class="w-100">
        <div
            :class="[
                'outer-container w-100 relative shadow br3',
                { 'call-extension-active': callExtension },
            ]"
        >
            <CardTitleTag
                v-if="titleTag"
                :title="titleTag.title"
                :classes="`${titleTag.classes} z-4`"
            />
            <div v-if="exampleName" class="example-label pt-16 pr-24">
                <div class="example-logo">
                    <StaplesIcon v-if="exampleName === 'staples'" />
                    <ExpediaIcon v-if="exampleName === 'expedia'" />
                    <AppleIcon v-if="exampleName === 'apple'" />
                </div>
                <div class="example-name f6 lh-copy fw5 ml-8">{{ ad.name }}</div>
            </div>

            <!-- Call Extension -->
            <div v-if="callExtension" class="inner-container">
                <div class="iphone relative">
                    <div class="relative z-2 w-100 relative shadow br3">
                        <div class="w-100 pv-20 ph-20">
                            <div class="ad-url flex items-center">
                                <div class="ui-display-url" />
                            </div>
                            <div class="ui-headline" />
                            <div v-if="callExtension" class="ui-headline two" />
                            <div class="ui-description-one" />
                        </div>

                        <div
                            v-if="callExtension"
                            class="call-extension-container flex bt b--opteo-light-gray"
                        >
                            <div class="call-extension-icon">
                                <svg
                                    focusable="false"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56a.977.977 0 0 0-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"
                                    ></path>
                                </svg>
                            </div>
                            <div class="call-extension-number">
                                <span>Call </span>
                                <span>{{ ad.callExtension.intlPrefix }}</span>
                                <span>{{ ad.callExtension.phoneNumber }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Ad Extensions -->
            <perfect-scrollbar v-else>
                <div :class="['inner-container', { 'call-extension-active': callExtension }]">
                    <div class="ad-width">
                        <div class="ad-url flex items-center">
                            <div class="ui-display-url" />
                        </div>
                        <div class="ui-headline" />
                        <div v-if="callExtension" class="ui-headline two" />
                        <div class="ui-description-one" />

                        <!-- Callouts -->
                        <div v-if="callouts" class="callouts">
                            <span
                                v-for="(callout, index) in ad.callouts"
                                :key="index"
                                class="callout"
                            >
                                {{ callout }}
                            </span>
                        </div>

                        <!-- Structured Snippets -->
                        <div v-if="structuredSnippets" class="structured-snippets">
                            <span class="snippet-header">{{ ad.structuredSnippets.type }}: </span>
                            <span
                                v-for="(snippet, index) in ad.structuredSnippets.items"
                                :key="'snippet-' + index"
                                class="snippet"
                            >
                                {{ snippet
                                }}<span v-if="index != ad.structuredSnippets.items.length - 1"
                                    >, </span
                                ><span v-if="index === ad.structuredSnippets.items.length - 1"
                                    >.</span
                                >
                            </span>
                        </div>

                        <!-- Sitelinks -->
                        <div v-if="sitelinks" class="sitelinks">
                            <div
                                v-for="(sitelink, index) in ad.sitelinks"
                                :key="index"
                                class="sitelink-item"
                            >
                                <span class="ad-headline">
                                    {{ sitelink.title }}
                                </span>
                                <div class="description">
                                    {{ sitelink.description }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script lang="ts">
// Shared Components
import CardTitleTag from '@/components/improvement/shared-components/CardTitleTag.vue'
import StaplesIcon from '@/components/improvement/shared-components/StaplesIcon.vue'
import ExpediaIcon from '@/components/improvement/shared-components/ExpediaIcon.vue'
import AppleIcon from '@/components/improvement/shared-components/AppleIcon.vue'

export default {
    components: { CardTitleTag, StaplesIcon, ExpediaIcon, AppleIcon },
    props: {
        titleTag: {
            type: Object, // { title: '', classes: '' }
            required: true,
        },
        // select an example ad: expedia, staples, apple (lowercase)
        exampleName: {
            type: String,
            required: false,
        },
        // if using a pre-determined example from above, set true on the
        // props you'd like to show in the example, otherwise, define your
        // own matching the correct data structure
        callouts: {
            type: [Boolean, Array],
            required: false,
        },
        // if creating a preview, make sure to use header from Google's list
        // this is passed in as type
        // https://support.google.com/google-ads/answer/6280012?hl=en-GB
        structuredSnippets: {
            type: [Boolean, Object], // { type: "", items: [] }
            required: false,
        },
        sitelinks: {
            type: [Boolean, Array],
            required: false,
        },
        callExtension: {
            type: [Boolean, Object], // { countryCode: "", intlPrefix: "", phoneNumber: "" }
            required: false,
        },
    },
    computed: {
        ad() {
            return this.exampleName ? this.examples[this.exampleName] : this
        },
    },
    data() {
        return {
            examples: {
                expedia: {
                    name: 'Expedia',
                    callouts: [
                        'Best Prices',
                        '500+ Airlines Worldwide',
                        'Official Partner of UCL',
                        '500,000+ Hotels Worldwide',
                    ],
                    structuredSnippets: {
                        type: 'Destinations',
                        items: ['New York', 'Amsterdam', 'Seoul', 'Copenhagen', 'Seattle'],
                    },
                    sitelinks: [
                        {
                            title: 'Top City Breaks',
                            description:
                                'Top Expedia City Breaks Worldwide: Dubai, Rome, Paris, Tokyo & More!',
                            url: '',
                        },
                        {
                            title: 'Premium Class Holidays',
                            description:
                                'Choose your Flight Class when you Book a Flight + Hotel Package.',
                            url: '',
                        },
                        {
                            title: "Today's Top Deals",
                            description:
                                'Cheap Holiday Deals & Bargains: Save on Hotels, Flights & Packages!',
                            url: '',
                        },
                        {
                            title: 'Holiday Rentals',
                            description:
                                'Rent a Home for Half the Price of a Hotel. Villas, Cabins & More.',
                            url: '',
                        },
                    ],
                    callExtension: {
                        countryCode: 'GB',
                        intlPrefix: '+44',
                        phoneNumber: '20 3788 0445', // Should be typeof number? consider formatting? https://gitlab.com/catamphetamine/libphonenumber-js#readme
                    },
                },
                staples: {
                    name: 'Staples',
                    callouts: [
                        'Orders Over £36',
                        'Speedy Checkout',
                        '20,000+ Products',
                        'Special Offers',
                        'Free Returns',
                    ],
                    structuredSnippets: {
                        type: 'Brands',
                        items: [
                            'Epson',
                            '3M',
                            'Canon',
                            'Drexel',
                            'HP',
                            'Herman Miller',
                            'Brother',
                            'Samsung',
                        ],
                    },
                    sitelinks: [
                        {
                            title: 'Office Furniture',
                            description: 'Wide Range Of Desks, Chairs & Storage Available Online!',
                            url: '',
                        },
                        {
                            title: 'Paper Supplies',
                            description:
                                'For A Huge Range Of Photocopy & Printer Paper Visit Staples.',
                            url: '',
                        },
                        {
                            title: 'Ink & Toner Finder',
                            description: 'Use Our Ink & Toner Finder To Easily Find What You Need.',
                            url: '',
                        },
                        {
                            title: 'Office Basics',
                            description: 'Essential everyday equipment. Simplify your desk.',
                            url: '',
                        },
                    ],
                    callExtension: {
                        countryCode: 'GB',
                        intlPrefix: '+44',
                        phoneNumber: '1372 744093',
                    },
                },
                apple: {
                    name: 'Apple',
                    callouts: [
                        'Shop now',
                        'Specialist help',
                        'Financing available',
                        'Apple Trade-in',
                        'In-store Pick-up',
                    ],
                    structuredSnippets: {
                        type: 'Models',
                        items: [
                            'iPhone 12',
                            'iPhone 12 Pro',
                            'Apple Watch',
                            'iPad Air',
                            'MacBook Pro',
                            'AirPods',
                        ],
                    },
                    sitelinks: [
                        {
                            title: 'The new 5G iPhone 12',
                            description:
                                'Buy the new iPhone 12 or iPhone 12 Pro. Trade-in your iPhone X for £280 off.',
                            url: '',
                        },
                        {
                            title: 'AirPods',
                            description:
                                'AirPods, AirPods Pro & AirPods Max. Free engraving. Only available at Apple.',
                            url: '',
                        },
                        {
                            title: 'iPad with A1 chip',
                            description:
                                'Purchase the iPad Air, iPad mini and iPad Pro. Find the perfect iPad for you.',
                            url: '',
                        },
                        {
                            title: 'Apple Watch',
                            description:
                                '3 months Apple Fitness+ included with purchase. Shop now, starting at £269.',
                            url: '',
                        },
                    ],
                    callExtension: {
                        countryCode: 'US',
                        intlPrefix: '+1',
                        phoneNumber: '212 336 1440',
                    },
                },
            },
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.outer-container {
    font-feature-settings:
        'ss01' off,
        'ss02' off,
        'ss04' off,
        'ss05' off,
        'ss08' off;
}

.improvement-statistic:last-child {
    margin-bottom: 0;
}

.inner-container {
    padding: 2.5rem;
    // overflow-x: auto;
    backface-visibility: hidden;
}

.ad-width {
    width: calc(42.5rem - 2.5rem - 2.5rem);
    margin: 0 auto;
}

.example-label {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 5rem;
}
.example-logo {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
}
.example-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.google-ad-badge,
.ui-display-url,
.ui-headline,
.ui-description-one,
.ui-description-two {
    height: 0.75rem;
    border-radius: 999px;
    opacity: 0.2;
}
.ad-url {
    margin-bottom: 0.6875rem;
}
.google-ad-badge {
    background-color: #888;
    width: 0.75rem;
}
.ui-display-url {
    background-color: #888;
    width: 30%;
}

.ui-headline {
    background-color: #1a0dab;
    width: 70%;
    margin-bottom: 0.6875rem;
}

.ui-description-one {
    margin-bottom: 0.6875rem;
    background-color: #888;
    width: 89%;
}

.ui-description-two {
    background-color: #888;
    width: 86%;
}

.callouts {
    display: inline-block;
    color: #545454;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
    position: relative;
}
.callouts.preview-mode::after {
    content: '';
    background-color: $opteo-blue-shadow;
    width: calc(100% + 6px);
    height: 100%;
    position: absolute;
    left: -3px;
    top: 0;
    border-radius: 0.25rem;
}
.callouts .callout {
    font-size: 0.875rem;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 400;
    letter-spacing: -0.003125rem;
    line-height: 1.25rem;
}
.callouts .callout:first-child:before {
    content: '';
    color: transparent;
}
.callouts .callout::before {
    content: ' · ';
    color: #777;
}

.structured-snippets {
    color: #545454;
    font-size: 0.875rem;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 400;
    letter-spacing: -0.003125rem;
    line-height: 0.75rem;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
}
.structured-snippets .snippet {
    font-size: 0.875rem;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 400;
    letter-spacing: -0.003125rem;
    line-height: 1.25rem;
}
.structured-snippets .snippet-header {
    font-size: 0.875rem;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 400;
    letter-spacing: -0.003125rem;
    line-height: 1.25rem;
}

.sitelinks {
    margin: 0 -0.8125rem 0 0;
    padding: 1rem 0 0 1.75rem;
    width: 38.375rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.ad-headline {
    color: #1a0dab;
    font-size: 1.1875rem;
    line-height: 1.33;
    font-family: 'Roboto', arial, sans-serif;
    letter-spacing: -0.00625rem;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
}
.description {
    color: #545454;
    font-size: 0.875rem;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 400;
    letter-spacing: -0.003125rem;
    line-height: 1.25rem;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
}
.sitelinks .sitelink-item {
    box-sizing: border-box;
    display: inline-block;
    padding: 0 13px 15px 0;
    vertical-align: top;
}
.sitelinks .sitelink-item:nth-child(3),
.sitelinks .sitelink-item:nth-child(4) {
    padding: 0 13px 0 0;
}

// iphone mockup for call extension
.iphone {
    width: 21rem;
    height: 42rem;
    padding: 3.5rem 1.25rem;
    background: #fafafa;
    border-radius: 2.25rem;
    box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    transform: translateY(-3.5rem);
}
.iphone:before {
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    position: absolute;
    top: 6px;
    content: '';
    left: 6px;
    border-radius: 2rem;
    background: white;
    z-index: 1;
}
// call extension ui
.outer-container.call-extension-active {
    height: 14.75rem;
}
.outer-container.call-extension-active .inner-container {
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: 2.25rem 2.5rem;
}
.outer-container.call-extension-active .ui-display-url {
    width: 45%;
}
.outer-container.call-extension-active .ui-headline {
    width: 95%;
}
.outer-container.call-extension-active .ui-headline.two {
    width: 60%;
}
.outer-container.call-extension-active .ui-description-one {
    margin-bottom: 0rem;
}

.outer-container.call-extension-active .ui-description-one {
    width: 75%;
}

.call-extension-container {
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
}
.call-extension-icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 1rem;
}
.call-extension-icon svg {
    fill: #5f6368;
}
.call-extension-number {
    color: #545454;
    font-size: 0.875rem;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 400;
    letter-spacing: -0.003125rem;
    line-height: 1.25rem;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
}

@media screen and (max-width: 767px) {
    .inner-container {
        padding-top: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .card-title-tag {
        left: 0.5rem;
    }
}

@media screen and (max-width: 1279px) {
    .ad-width {
        width: calc(37.5rem + 2.5rem);
        padding-right: 2.5rem;
        margin: 0;
    }
}
</style>
