<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :on-push="onPush"
    >
        <!-- Improvement Header -->
        <ImprovementContent>
            <ImprovementHeader :title="`${title}`">
                <div v-if="aboveMobile">
                    <EntityPill :content="entityName" :type="entity" />
                    <Spacer height="2rem" />
                </div>
                <!-- If combined low data ad groups -->
                <Text v-if="entity === 'campaign'" as="p">
                    In the campaign above, Opteo has combined the performance of low-data ad groups
                    to gather enough data to make a recommendation. This aggregated data shows that
                    searchers in the
                    <b>{{ highlightedSegment }}</b> demographic are underperforming significantly.
                    Excluding this demographic should help to reduce wasted spend.
                </Text>
                <!-- If ad group -->
                <Text v-else as="p">
                    <span
                        v-if="
                            highlightedSegment === 'Not a Parent' ||
                            highlightedSegment === 'Lower 50%'
                        "
                    >
                        In this {{ entityLabel }},
                    </span>
                    <span v-else> In the {{ entityLabel }} above, </span>
                    searchers in the
                    <b>{{ highlightedSegment }}</b> demographic are underperforming significantly.
                    Opteo recommends excluding searchers in this demographic. This should help to
                    reduce wasted spend and
                    {{ !isUsingROAS ? 'improve your overall CPA' : 'improve your overall ROAS' }}.
                </Text>
                <Spacer height="1rem" />
                <Text
                    v-if="
                        (conversionLoss > 0 && !isUsingROAS) ||
                        (conversionValueLoss > 0 && isUsingROAS)
                    "
                >
                    This change should lead to a relatively small decrease in
                    {{ !isUsingROAS ? 'conversions' : 'conversion value' }}
                    — around
                    <b>
                        <Number v-if="!isUsingROAS" :value="conversionLoss" />
                        <Money v-else :value="conversionValueLoss" :currency="domainCurrencyCode" />
                    </b>
                    per month — however spend should also decrease by
                    <b><Money :value="costSavings" :currency="domainCurrencyCode" /></b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="costSavingPercent" includeSign />)</Text
                    >
                    per month,
                    {{ !isUsingROAS ? 'lowering your overall CPA' : 'improving your overall ROAS' }}
                    from
                    <template v-if="!isUsingROAS">
                        <b><Money :value="cpaBefore" :currency="domainCurrencyCode" /> </b>
                        to
                        <b><Money :value="cpaAfter" :currency="domainCurrencyCode" /> </b>
                    </template>
                    <template v-else>
                        <b><Roas :value="roasBefore" /></b>
                        to
                        <b><Roas :value="roasAfter" /></b>
                    </template>
                    <Text v-if="!isUsingROAS" as="span" color="green" weight="600">
                        (<Percent :value="cpaDecreasePercent" includeSign />)</Text
                    >
                    <Text v-else as="span" color="green" weight="600">
                        (<Percent :value="roasIncreasePercent" includeSign />)</Text
                    >.
                </Text>
                <Text v-else>
                    This change should reduce spend by
                    <b><Money :value="costSavings" :currency="domainCurrencyCode" /></b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="costSavingPercent" includeSign />)</Text
                    >
                    each month and {{ !isUsingROAS ? 'lower CPA' : 'increase ROAS' }} from
                    <template v-if="!isUsingROAS">
                        <b><Money :value="cpaBefore" :currency="domainCurrencyCode" /> </b>
                        to
                        <b><Money :value="cpaAfter" :currency="domainCurrencyCode" /> </b>
                    </template>
                    <template v-else>
                        <b><Roas :value="roasBefore" /></b>
                        to
                        <b><Roas :value="roasAfter" /></b>
                    </template>
                    <Text v-if="!isUsingROAS" as="span" color="green" weight="600">
                        (<Percent :value="cpaDecreasePercent" includeSign />)</Text
                    >
                    <Text v-else as="span" color="green" weight="600">
                        (<Percent :value="roasIncreasePercent" includeSign />)</Text
                    >
                    with <b>no loss</b> of {{ !isUsingROAS ? 'conversions' : 'conversion value' }}.
                </Text>
                <Text v-if="entity === 'campaign'">
                    <Spacer height="1rem" />
                    For reference, this exclusion will be applied to the following ad groups:
                    <Spacer height="2rem" />
                    <EntityPillList :items="entityPillList" />
                </Text>
                <Text as="p">
                    <Spacer :height="entity === 'campaign' ? '2rem' : '1rem'" />
                    Click <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    apply the recommended exclusion. You can also exclude additional demographics
                    using the table below. Otherwise, feel free to <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <!-- @Jose: `path` needs to be updated prior to release -->
            <ImprovementHelpLink path="/en/articles/902679-adjust-demographic-bids">
                Click here to learn more about demographic exclusions in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <!-- Table -->
        <ImprovementContent fullWidth>
            <Spacer height="1rem" />
            <oTable
                :headers="tableHeaders"
                :items="tableItems"
                :per-page="aboveMobile ? 100 : 1"
                responsive-mode="columns"
                responsive-breakpoint="1120px"
                :border-radius="24"
                :fixed-layout="true"
            >
                <template #[dynamicSegmentHeader]>
                    <div class="demographic-header">{{ segmentLabel }}</div>
                </template>
                <template #[dynamicSegmentColumn]="segment">
                    <div class="demographic-label-column">
                        <EntityPill
                            v-if="segment.value === highlightedSegment"
                            type="demographic"
                            color="red"
                        >
                            {{ segment.value }}
                        </EntityPill>
                        <EntityPill v-else type="demographic"> {{ segment.value }} </EntityPill>
                    </div>
                </template>
                <template #column.cost="cost">
                    <Money :value="cost.value" :currency="domainCurrencyCode" />
                </template>
                <template #column.impressions="impressions">
                    <Number :value="impressions.value" />
                </template>
                <template #column.conversions="conversions">
                    <Number :value="conversions.value" :compact="true" />
                </template>
                <template #column.conversions_value="conversions_value">
                    <Money :value="conversions_value.value" :currency="domainCurrencyCode" />
                </template>
                <template #column.cpa="cpa">
                    <Money
                        v-if="cpa.value !== 'N/A'"
                        :value="cpa.value"
                        :currency="domainCurrencyCode"
                    />
                    <span v-else class="not-enough-data-text">N/A</span>
                </template>
                <template #column.roas="roas">
                    <Roas v-if="roas.value !== 'N/A'" :value="roas.value" />
                    <span v-else class="not-enough-data-text">N/A</span>
                </template>
                <template #column.avg-cpa="avgCpa">
                    <Money
                        v-if="avgCpa.value > 0"
                        :value="avgCpa.value"
                        :currency="domainCurrencyCode"
                    />
                    <span v-else class="not-enough-data-text">N/A</span>
                </template>
                <template #column.avg-roas="avgROAS">
                    <Roas v-if="avgROAS.value > 0" :value="avgROAS.value" />
                    <span v-else class="not-enough-data-text">N/A</span>
                </template>
                <template #column.difference_cpa="difference_cpa">
                    <Text v-if="difference_cpa.value > 0" as="span" color="red" weight="500">
                        <Percent :value="difference_cpa.value" includeSign />
                    </Text>
                    <Text v-if="difference_cpa.value === 0" as="span" color="black" weight="400">
                        <Percent :value="difference_cpa.value" includeSign />
                    </Text>
                    <Text v-if="difference_cpa.value < 0" as="span" color="green" weight="500">
                        <Percent :value="difference_cpa.value" includeSign />
                    </Text>

                    <NotApplicablePill
                        v-if="difference_cpa.value === 'disabled'"
                        content="Segment disabled"
                    />
                </template>
                <template #column.difference_roas="difference_roas">
                    <Text v-if="difference_roas.value < 0" as="span" color="red" weight="500">
                        <Percent :value="difference_roas.value" includeSign />
                    </Text>
                    <Text v-if="difference_roas.value === 0" as="span" color="black" weight="400">
                        <Percent :value="difference_roas.value" includeSign />
                    </Text>
                    <Text v-if="difference_roas.value > 0" as="span" color="green" weight="500">
                        <Percent :value="difference_roas.value" includeSign />
                    </Text>

                    <NotApplicablePill
                        v-if="difference_roas.value === 'disabled'"
                        content="Segment disabled"
                    />
                </template>
                <template #column.action="Action">
                    <ExclusionSwitcher
                        v-if="!Action.value.message"
                        v-model="Action.value.exclude"
                        @update:modelValue="updateExlusions(Action.value.id, Action.value.exclude)"
                    />
                    <ExclusionMessage
                        v-else
                        :message="Action.value.message"
                        :width="Action.value.width"
                    />
                </template>
            </oTable>
        </ImprovementContent>

        <!-- Improvement Note -->
        <div v-if="entity === 'campaign'">
            <ImprovementContent>
                <ImprovementNote>
                    <b>Note:</b> When individual ad groups do not have enough performance data for
                    Opteo to recommend an adjustment, ad groups with insufficient data are combined
                    to generate statistically significant suggestions. Adjustments based on
                    aggregated ad group data.
                </ImprovementNote>
            </ImprovementContent>
        </div>

        <!-- Cost Comparison (Donut Chart) -->
        <div v-if="demographic === 'parental_status' || demographic === 'gender'">
            <ImprovementContent noMargin>
                <Spacer height="1rem" />
                <Text as="h3" size="f-4" weight="600">Cost Comparison</Text>
                <Spacer height="2rem" />
            </ImprovementContent>
            <ImprovementContent :fullWidth="true">
                <div class="chart-container">
                    <div class="chart-wrapper">
                        <DonutChart
                            :items="donutChartItems"
                            :metric="{
                                label: 'Cost',
                                dataType: 'money',
                                currency: domainCurrencyCode,
                            }"
                            :show-keys="true"
                        />
                    </div>
                </div>
            </ImprovementContent>
        </div>

        <!-- CPA Statistics-->
        <ImprovementContent v-if="performanceMode === Targets.PerformanceMode.CPA">
            <Spacer height="1rem" />
            <ImprovementStatistics :items="improvementStatisticsItems">
                <template #segmentCpa>
                    Over the last <b>{{ lookbackWindow }}</b> days, the
                    <b>{{ highlightedSegment }}</b> demographic has an average CPA of
                    <b>{{ formattedSegmentCpa }}</b
                    >. Please note, this CPA is based on conversion types set in Campaign Groups.
                </template>
                <template #adGroupCpa>
                    In comparison, the containing ad group has an average CPA of
                    <b>{{ formattedAdGroupCpa }}</b> over the same time period. This means that
                    searchers in the <b>{{ highlightedSegment }}</b> demographic cost
                    <b>
                        <Percent :value="segmentToAdGroupPerformanceRatio" />
                        <span>{{ segmentBetterThanAverage ? ' less' : ' more' }} </span></b
                    >
                    to convert than average.
                </template>
                <template #avgAdGroupCpa>
                    In comparison, the combined low-data ad groups have an average CPA of
                    <b>{{ formattedAvgAdGroupsCpa }}</b> over the same time period. This means that
                    searchers in the <b>{{ highlightedSegment }}</b> demographic cost
                    <b>
                        <Percent :value="segmentToAdGroupPerformanceRatio" />
                        <span>{{ segmentCpa - avgAdGroupCpa < 0 ? ' less' : ' more' }} </span></b
                    >
                    to convert than average.
                </template>
                <template #campaignGroupCpa>
                    The parent campaign group has a Target CPA of
                    <b>{{ formattedTargetCpa }}</b
                    >. This means searchers in the <b>{{ highlightedSegment }}</b> group cost
                    <b>
                        <Percent :value="segmentToCampaignGroupPerformanceRatio" />
                        <span>{{ segmentCpa - campaignGroupCpa < 0 ? ' less' : ' more' }}</span>
                    </b>
                    to convert than the campaign group target.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <!-- ROAS Statistics-->
        <ImprovementContent v-if="performanceMode === Targets.PerformanceMode.ROAS">
            <Spacer height="1rem" />
            <ImprovementStatistics :items="improvementStatisticsItems">
                <template #segmentROAS>
                    Over the last <b>{{ lookbackWindow }}</b> days, the
                    <b>{{ highlightedSegment }}</b> demographic has an average ROAS of
                    <b>{{ formattedSegmentROAS }}</b
                    >. Please note, this ROAS is based on conversion types set in Campaign Groups.
                </template>
                <template #adGroupROAS>
                    In comparison, the containing ad group has an average ROAS of
                    <b>{{ formattedAdGroupROAS }}</b> over the same time period. This means that
                    searchers in the <b>{{ highlightedSegment }}</b> demographic have a
                    <b>
                        <Percent :value="segmentToAdGroupPerformanceRatio" />
                        <span>{{ segmentBetterThanAverage ? ' higher' : ' lower' }} </span></b
                    >
                    ROAS than the ad group average.
                </template>
                <template #avgAdGroupROAS>
                    In comparison, the combined low-data ad groups have an average ROAS of
                    <b>{{ formattedAvgAdGroupsROAS }}</b> over the same time period. This means that
                    searchers in the <b>{{ highlightedSegment }}</b> demographic have a
                    <b>
                        <Percent :value="segmentToAdGroupPerformanceRatio" />
                        <span>{{ segmentROAS - avgAdGroupROAS < 0 ? ' lower' : ' higher' }}</span>
                    </b>
                    ROAS than the ad group as a whole.
                </template>
                <template #campaignGroupROAS>
                    The parent campaign group has a Target ROAS of
                    <b>{{ formattedTargetROAS }}</b
                    >. This means — in this ad group — searchers in the
                    <b>{{ highlightedSegment }}</b> demographic have a
                    <b>
                        <Percent :value="segmentToCampaignGroupPerformanceRatio" />
                        <span>{{ segmentROAS - targetROAS < 0 ? ' lower' : ' higher' }}</span>
                    </b>
                    ROAS than the campaign group target.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <!-- Improvement Created Date -->
        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
    Text,
    Spacer,
    EntityPill,
    EntityPillList,
    HelpLink,
    oTable,
    oInput,
    Money,
    Percent,
    Number,
    DonutChart,
    Tooltip,
    Roas,
} from '@opteo/components-next'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'
import ExclusionSwitcher from '@/components/improvement/shared-components/ExclusionSwitcher.vue'
import ExclusionMessage from '@/components/improvement/shared-components/ExclusionMessage.vue'
import AdjustBidCell from '@/components/improvement/shared-components/AdjustBidCell.vue'
import NotApplicablePill from '@/components/improvement/shared-components/NotApplicablePill.vue'
import { useExcludeDemoBids } from '@/composition/improvement/types/demo-bids-v2/useExcludeDemoBids'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default defineComponent({
    name: 'ExcludeSingleDemographic',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        Text,
        Spacer,
        EntityPill,
        EntityPillList,
        HelpLink,
        oTable,
        oInput,
        Money,
        Percent,
        Number,
        DonutChart,
        Tooltip,
        AdjustBidCell,
        NotApplicablePill,
        ImprovementBidAdjustment,
        Roas,
        ExclusionSwitcher,
        ExclusionMessage,
    },
    setup() {
        return { ...useExcludeDemoBids(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chart-container {
    @include container;
    @include br-24;
    @include pv-64;
    width: 100%;
    @include flex-center;
}
.chart-wrapper {
    width: 100%;
    max-width: 48rem;
}
.demographic-label-column {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
    height: 4.5rem;
    @include pl-24;
}
.demographic-label-column::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}
.demographic-header {
    @include pl-24;
}
.not-enough-data-text {
    color: #bec1cb;
}
</style>
