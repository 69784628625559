import { Ref } from 'vue'
import sortBy from 'lodash-es/sortBy'
import { Targets, Improvement, DemoBids } from '@opteo/types'

interface MissingDemo {
    placement: string
    criteria: number
    ad_group_placement_cost: number
    ad_group_placement_conversions: number
    ad_group_placement_impressions: number
    ad_group_placement_all_conversions_value: number
    ad_group_placement_cpa: number
    ad_group_placement_roas: number
    current_bid: number
    new_bid_mod: number
    invalid: boolean
    is_exclude?: boolean
    mocked: boolean
    reason: string
}

export function getDemographicsStatistics(
    showSegmentPerfMetric: Ref<boolean>,
    formattedSegmentPerfMetric: string,
    showAdgroupPerfMetric: Ref<boolean>,
    formattedAdGroupPerfMetric: string,
    showAvgAdgroupPerfMetric: Ref<boolean>,
    formattedAvgAdGroupsPerfMetric: string,
    showCampaignGroupPerfMetric: Ref<boolean>,
    formattedTargetPerfMetric: string,
    performanceMode: Targets.PerformanceMode
) {
    const improvementStatisticsItemsCPA = [
        ...(showSegmentPerfMetric.value
            ? [
                  {
                      key: 'segmentCpa',
                      value: formattedSegmentPerfMetric,
                      title: 'Demographic CPA',
                  },
              ]
            : []),
        ...(showAdgroupPerfMetric.value
            ? [
                  {
                      key: 'adGroupCpa',
                      value: formattedAdGroupPerfMetric,
                      title: 'Ad Group CPA',
                  },
              ]
            : []),
        ...(showAvgAdgroupPerfMetric.value
            ? [
                  {
                      key: 'avgAdGroupCpa',
                      value: formattedAvgAdGroupsPerfMetric,
                      title: 'Combined Ad Groups CPA',
                  },
              ]
            : []),
        ...(showCampaignGroupPerfMetric.value
            ? [
                  {
                      key: 'campaignGroupCpa',
                      value: formattedTargetPerfMetric,
                      title: 'Campaign Group Target CPA',
                  },
              ]
            : []),
    ]

    const improvementStatisticsItemsROAS = [
        ...(showSegmentPerfMetric.value
            ? [
                  {
                      key: 'segmentROAS',
                      value: formattedSegmentPerfMetric,
                      title: 'Demographic ROAS',
                  },
              ]
            : []),
        ...(showAdgroupPerfMetric.value
            ? [
                  {
                      key: 'adGroupROAS',
                      value: formattedAdGroupPerfMetric,
                      title: 'Ad Group ROAS',
                  },
              ]
            : []),
        ...(showAvgAdgroupPerfMetric.value
            ? [
                  {
                      key: 'avgAdGroupROAS',
                      value: formattedAvgAdGroupsPerfMetric,
                      title: 'Combined Ad Groups ROAS',
                  },
              ]
            : []),
        ...(showCampaignGroupPerfMetric.value
            ? [
                  {
                      key: 'campaignGroupROAS',
                      value: formattedTargetPerfMetric,
                      title: 'Campaign Group Target ROAS',
                  },
              ]
            : []),
    ]

    if (performanceMode == Targets.PerformanceMode.ROAS) {
        return improvementStatisticsItemsROAS
    }

    return improvementStatisticsItemsCPA
}

export function getDemographicsTableHeaders({
    performanceMode,
    segment,
    segmentLabel,
    isUsingROAS,
    recAction,
}: {
    performanceMode: any
    segment: any
    segmentLabel: any
    isUsingROAS: boolean
    recAction: Improvement.RecAction
}) {
    return [
        { key: segment, text: segmentLabel.value, sortable: false },
        { key: 'cost', text: 'Cost', sortable: true },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'conversions_value', text: 'Value', sortable: true, width: 104 }
            : { key: 'conversions', text: 'Conv.', sortable: true, width: 96 },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'roas', text: 'ROAS', sortable: true }
            : { key: 'cpa', text: 'CPA', sortable: true },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'avg-roas', text: 'Avg. ROAS', sortable: false }
            : { key: 'avg-cpa', text: 'Avg. CPA', sortable: false },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'difference_roas', text: 'Diff.', sortable: true, width: 100 }
            : { key: 'difference_cpa', text: 'Diff.', sortable: true, width: 100 },
        recAction === Improvement.RecAction.ExcludeSingleDemographic
            ? {
                  key: 'action',
                  text: 'Action',
                  sortable: false,
                  width: isUsingROAS ? 132 : 114,
              }
            : {
                  key: 'bid-adjustment',
                  text: 'Bid Adj.',
                  sortable: false,
                  width: isUsingROAS ? 132 : 114,
              },
    ]
}

// Exclusion Table Headers
export function getExclusionTableHeaders({
    performanceMode,
    segment,
    segmentLabel,
}: {
    performanceMode: any
    segment: any
    segmentLabel: any
    isUsingROAS: boolean
    recAction: Improvement.RecAction
}) {
    return [
        { key: segment, text: segmentLabel.value, sortable: false, noPadding: true },
        { key: 'cost', text: 'Cost', sortable: true, width: 112 },
        { key: 'impressions', text: 'Impr.', sortable: true, width: 112 },
        { key: 'conversions', text: 'Conv.', sortable: true, width: 108 },
        { key: 'conversions_value', text: 'Value', sortable: true, width: 112 },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'roas', text: 'ROAS', sortable: true, width: 112 }
            : { key: 'cpa', text: 'CPA', sortable: true, width: 108 },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'avg-roas', text: 'Avg. ROAS', sortable: false, width: 112 }
            : { key: 'avg-cpa', text: 'Avg. CPA', sortable: false, width: 108 },
        performanceMode === Targets.PerformanceMode.ROAS
            ? { key: 'difference_roas', text: 'Diff.', sortable: true, width: 108 }
            : { key: 'difference_cpa', text: 'Diff.', sortable: true, width: 108 },
        { key: 'action', text: 'Action', sortable: false, width: 196 },
    ]
}

// We use this function instead of native oTable sorting because:
// - We need to sort in a special way for Income demographics (it happens not to sort nicely alphabetically)
// - We need to sort the items going into the donut chart
export function sortDemos(
    segment: DemoBids.AllowedCriteriaTypes,
    toSort: DemoBids.DemoBodies,
    toSortMissing: MissingDemo[]
) {
    if (segment === DemoBids.AllowedCriteriaTypes.INCOME) {
        const incomeSortOrders = [
            'Top 10%',
            '11-20%',
            '21-30%',
            '31-40%',
            '41-50%',
            'Lower 50%',
            'Unknown',
        ]

        return incomeSortOrders.map(incomeLabel => {
            const found = [...toSort, ...toSortMissing].find(i => i.placement === incomeLabel)
            if (!found) {
                throw new Error(`incomeLabel "${incomeLabel}" not found in body`)
            }

            return found
        })
    }

    // Other demographic types are sorted alphabetically
    return sortBy([...toSort, ...toSortMissing], i => i.placement)
}
