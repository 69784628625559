<template>
    <router-link to="/">
        <OpteoLogo :width="44" :height="44" class="opteo-logo" />
    </router-link>
    <perfect-scrollbar>
        <LoginContainer title="Create Account">
            <template v-slot:google v-if="!showConfCodeInput">
                <!-- Login with Google -->
                <WithGoogle string="Sign up with Google" />
            </template>
            <template v-slot:content>
                <oForm
                    v-if="!showConfCodeInput"
                    id="create-account-form"
                    @submitted="createAccount"
                    :validateOnSubmit="true"
                >
                    <oInput
                        v-model="name"
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Johnny Appleseed"
                        autocomplete="name"
                        autofocus
                        required
                    />
                    <Spacer height="1.5rem" />
                    <oInput
                        v-model="emailAddress"
                        type="email"
                        name="emailAddress"
                        label="Email Address"
                        placeholder="johnny@appleseed.com"
                        autocomplete="email"
                        required
                    />
                    <Spacer height="1.5rem" />
                    <oInput
                        v-model="password"
                        type="password"
                        name="password"
                        label="Password"
                        :minlength="8"
                        placeholder="••••••••••"
                        autocomplete="new-password"
                        required
                    />
                    <Spacer height="1.5rem" />
                    <AccountTypeSelector v-model="avatar" />
                    <!-- Error message -->
                    <Spacer v-if="error" height="2rem" />
                    <Text v-if="error" as="p" color="red" class="error-container">
                        <svg
                            aria-hidden="true"
                            height="12"
                            width="12"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            style="flex-shrink: 0"
                        >
                            <path
                                d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0113.634 16H2.365A2.365 2.365 0 01.25 12.577L5.884 1.308a2.365 2.365 0 014.231 0zM8 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z"
                                fill-rule="evenodd"
                                fill="#FF2828"
                            />
                        </svg>
                        <span class="error-message">{{ error }}</span>
                    </Text>
                    <Spacer height="2rem" />
                    <oButton size="login" type="submit" :loading="loading">Create Account</oButton>
                </oForm>
                <oForm v-else @submitted="verifyConfCode" :validateOnSubmit="true">
                    <Text as="p" size="f-8">An email confirmation code has been sent to:</Text>
                    <Spacer height="1.5rem" />

                    <ColorTag color="blue" :title="emailAddress" content="" class="email-address" />

                    <Spacer height="1.5rem" />

                    <Text as="p" size="f-8">
                        To confirm your email address and finish creating your account, please enter
                        the code below.
                    </Text>

                    <Spacer height="1.5rem" />

                    <oInput
                        id="conf-code"
                        name="confcode"
                        label="Email Confirmation Code"
                        type="text"
                        required
                        autofocus
                    />

                    <!-- Error message -->
                    <Spacer v-if="error" height="2rem" />
                    <Text v-if="error" as="p" color="red" class="error-container">
                        <svg
                            aria-hidden="true"
                            height="12"
                            width="12"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            style="flex-shrink: 0"
                        >
                            <path
                                d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0113.634 16H2.365A2.365 2.365 0 01.25 12.577L5.884 1.308a2.365 2.365 0 014.231 0zM8 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z"
                                fill-rule="evenodd"
                                fill="#FF2828"
                            />
                        </svg>
                        <span class="error-message">{{ error }}</span>
                    </Text>
                    <Spacer height="2rem" />
                    <oButton size="login" type="submit" :loading="loading">Create Account</oButton>
                </oForm>
            </template>
            <template v-slot:links>
                <router-link to="/login">Already have an account? Login here.</router-link>
            </template>
        </LoginContainer>
    </perfect-scrollbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoginContainer from '@/components/global/LoginContainer.vue'
import WithGoogle from '@/components/global/WithGoogle.vue'
import AccountTypeSelector from '@/components/User/AccountTypeSelector.vue'

import {
    oButton,
    oForm,
    oInput,
    OpteoLogo,
    Label,
    Spacer,
    Text,
    ColorTag,
} from '@opteo/components-next'
import { useCreateAccount } from '@/composition/auth/useCreateAccount'

export default defineComponent({
    name: 'CreateAccount',
    components: {
        LoginContainer,
        AccountTypeSelector,
        oButton,
        oForm,
        oInput,
        OpteoLogo,
        Label,
        Spacer,
        WithGoogle,
        Text,
        ColorTag,
    },
    setup() {
        return useCreateAccount()
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.email-address {
    display: inline;
}

.error-container {
    display: inline-flex !important;
    @include items-center;
    width: 100%;
    @include container;
    @include pv-16;
    @include ph-24;
}
.error-message {
    @include f-9;
    @include ml-8;
    @include lh-140;
}

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}
@media screen and (max-width: $mq-767-max) {
    .opteo-logo {
        display: none;
    }
}
</style>
