<template>
    <div
        v-if="layout === 'default'"
        ref="topNavBar"
        :class="['domain-topbar-bar']"
        :data-position="isFloatingMode ? 'floating' : 'collapsed'"
    >
        <div ref="topBarInner" class="topbar-inner"></div>
    </div>
    <div
        v-if="layout === 'default'"
        :class="['domain-topbar-content']"
        :data-position="isFloatingMode ? 'floating' : 'collapsed'"
    >
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, computed } from 'vue'
import { layout } from '@/composition/layouts/useLayouts'
import { useRouter } from 'vue-router'
import { useWindowSize, useScroll } from '@vueuse/core'
import { delay } from '@/lib/globalUtils'

const NAVBAR_TRANSITION_HEIGHT = 128 // pixels
const LEFT_NAVBAR_WIDTH = 94 // pixels

export default defineComponent({
    name: 'TopNavBar',
    setup() {
        const topNavBar = ref<HTMLDivElement | null>(null)
        const topBarInner = ref<HTMLDivElement | null>(null)
        const { currentRoute } = useRouter()

        const { width: windowWidth } = useWindowSize()
        const { y } = useScroll(document)

        watch(layout, newLayout => {
            /**
             * When transitioning out of a full screen modal, we sometimes restore scroll position...
             * Then the scroll pos jumps from 0 to somewhere down the page, which causes the
             * navbar to animate from one state to another.
             * This is a bit distracting, so we disable the animation so the topNavBar can
             * instantly be in the correct state.
             **/
            if (newLayout === 'full-screen') {
                disableAnimation()
            } else {
                enableAnimation()
            }
        })

        const transitionEasing = ref('transform 0 cubic-bezier(0.19, 1, 0.22, 1)')

        async function enableAnimation() {
            await delay(100) // need to wait for the DOM to update
            transitionEasing.value = 'transform 0.5s cubic-bezier(0.19, 1, 0.22, 1)'
        }

        function disableAnimation() {
            transitionEasing.value = 'transform 0 cubic-bezier(0.19, 1, 0.22, 1)'
        }

        const isFloatingMode = computed(() => {
            return y.value < NAVBAR_TRANSITION_HEIGHT
        })

        const topBarTransform = ref<string | null>(null)
        const innerTransform = ref<string | null>(null)

        function calculateScales() {
            const topNavBarWidth = topNavBar?.value?.clientWidth ?? 0
            // animate using transforms rather than height & width
            // https://aerotwist.com/blog/pixels-are-expensive/
            // https://aerotwist.com/blog/flip-your-animations/
            topBarTransform.value = `translateY(-28px) scale(${
                (windowWidth.value - LEFT_NAVBAR_WIDTH) / topNavBarWidth
            }, 0.9) perspective(1px)`
            // invert the outer topBarTransform transform in topNavBar
            innerTransform.value = `scale(${
                topNavBarWidth / (windowWidth.value - LEFT_NAVBAR_WIDTH)
            }, 1.1) perspective(1px)`
        }

        onMounted(async () => {
            calculateScales()

            // This tranform is initialized to take 0s to avoid jank on load. After load, we re-enable the transition.
            enableAnimation()
        })

        // recalculate scale factors when window is resized or when topNavBar comes in/out of existance.
        watch([windowWidth, topNavBar], () => {
            calculateScales()
        })

        return {
            topNavBar,
            topBarInner,
            layout,
            currentRoute,
            isFloatingMode,
            topBarTransform,
            innerTransform,
            transitionEasing,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.target {
    // height: 0px;
    width: 10rem;
    transform: translateY(8rem);
    // uncomment to see the element that triggers intersection
    height: 1px;
    // background-color: red;
}

.domain-topbar-bar {
    @include fixed;
    @include top-0;
    height: 4.25rem;
    width: 100%;
    @include opteo-background;
    box-shadow: none;
    border-bottom: 1px solid $opteo-light-gray;
    @include pa-16;
    z-index: 2999;
}
.domain-topbar-content {
    @include fixed;
    @include top-0;
    @include z-9;
    width: 100%;
    @include z-9;
    @include pa-16;
    z-index: 3000;
}

.topbar-inner {
    height: 100%;
}

.o-dark-mode .domain-topbar-bar {
    box-shadow: $opteo-shadow-dark-l;
}

@media (min-width: $mq-768-min) {
    .domain-topbar-bar {
        width: calc(100% - 96px - 48px);
        @include ph-24;
        @include br-24;
        top: 24px;
        left: calc(24px + 96px);
        height: 5rem;
        will-change: transform;
        transition: v-bind(transitionEasing);
        box-shadow: $opteo-shadow-l;
        border-bottom: none;
    }

    .domain-topbar-content {
        width: calc(100% - 96px - 48px);
        @include ph-24;
        @include br-24;
        top: 24px;
        left: calc(24px + 96px);
        height: 5rem;
        will-change: transform;
        transition: v-bind(transitionEasing);
    }

    .domain-topbar-bar[data-position='collapsed'] {
        border-radius: 0px;
        // y scaling shortens TopNavBar to:
        // height: 4.5rem;
        will-change: transform;
        // https://v3.vuejs.org/api/sfc-style.html#state-driven-dynamic-css
        // https://www.jsnow.io/javascript/vue/vue-dynamic-css
        transform: v-bind(topBarTransform);
    }
    .domain-topbar-content[data-position='collapsed'] {
        transform: translateY(-1.75rem);
    }
    .domain-topbar-bar .topbar-inner {
        will-change: transform;
        transition: v-bind(transitionEasing);
    }
    .domain-topbar-bar[data-position='collapsed'] .topbar-inner {
        transform: v-bind(innerTransform);
    }
}
</style>
