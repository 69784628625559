import {
    OnPushHandler,
    useImprovement,
    UseImprovement,
} from '@/composition/improvement/useImprovement'
import { ref } from 'vue'

export function useCreateRemarketingCampaign(): UseImprovement<any> {
    const { improvement, lastUpdated, title } = useImprovement()

    const pushActionText = ref('Recheck Campaigns')

    const pushMessages = [
        'Connecting to Google Ads..',
        'Scanning account..',
        'Verifying campaign..',
        'Remarketing campaign verified successfully.',
    ]

    const onPush: OnPushHandler = () => {
        return {
            valid: true,
        }
    }

    return {
        improvement,
        lastUpdated,
        title,
        onPush,
        pushMessages,
        pushActionText,
    }
}
