<template>
    <div :class="[view, 'analytics-container']">
        <div
            @mouseenter="slidePreviewButtonShown = true"
            @mouseleave="slidePreviewButtonShown = false"
            class="slide-image-container"
        >
            <img class="slide-image" src="@/assets/img/connect-g-a.png" />
        </div>
        <div v-if="!hasConnection" class="description-text-container">
            <Text as="p" size="f-8">
                <slot v-if="$slots['no-connection-copy']" name="no-connection-copy" />
                <span v-else
                    >Connect Google Analytics to this account to get access to usage metrics and
                    supplementary data in your Opteo account. Once connected, your Google Analytics
                    data will be available in Performance, Reports and more.</span
                >
            </Text>
        </div>

        <!-- Not Connected -->
        <div v-if="!hasConnection" class="connection-status">
            <div class="not-connected">
                <Spinner v-if="connectionStatusLoading" />
                <oButton
                    v-else
                    :color="buttonColor"
                    size="medium"
                    :loading="linkGaButtonLoading"
                    @clicked="linkGoogleAnalytics()"
                >
                    Connect Google Analytics
                </oButton>
            </div>
        </div>

        <!-- Connected -->
        <div v-if="hasConnection" class="connection-status">
            <div class="is-connected">
                <div v-if="accountProfile" class="status-container">
                    <CheckIcon class="check-icon" />
                    <div class="view-connected-text">
                        <Text as="p" size="f-8" weight="500"> Connected to Opteo </Text>
                        <Text as="p" size="f-10" color="gray">on {{ formattedLinkingDate }}</Text>
                    </div>
                </div>
                <div class="status-container">
                    <div v-if="savingChanges" class="spinner-container">
                        <Spinner />
                    </div>
                    <div v-else>
                        <div
                            v-if="accountProfile && accountProfile.name"
                            class="check-text-container"
                        >
                            <CheckIcon class="check-icon" />
                            <div class="view-connected-text">
                                <Text as="p" size="f-8" weight="500">
                                    {{ accountProfile?.name }}
                                </Text>
                                <Text as="p" size="f-10" color="gray">
                                    Analytics ID: {{ accountProfile?.id }}
                                </Text>
                            </div>
                        </div>
                        <div v-else>
                            <oButton
                                class="connected-view-button"
                                id="unlink-analytics"
                                color="white"
                                size="small"
                                @clicked="openViewSelector()"
                            >
                                Connect Analytics View
                            </oButton>
                        </div>
                    </div>
                </div>
            </div>
            <!-- EDIT BUTTON -->
            <div
                v-if="hasConnection && accountProfile && accountProfile?.name"
                class="button-container"
            >
                <oButton
                    id="edit-analytics-connection"
                    class="edit-button"
                    @clicked="openViewSelector()"
                    size="medium"
                    :color="buttonColor"
                >
                    Edit Connection Settings
                </oButton>
            </div>
        </div>
    </div>

    <Modal
        v-model="actuallyShowModal"
        title="Connect Google Analytics"
        width="calc(100% - 88px)"
        @closed="closeViewSelector"
    >
        <template #content>
            <div class="modal-content-container">
                <AnalyticsTable
                    class="modal-table"
                    :accounts="availableProfiles?.accounts"
                    :selected="selectedEntities"
                    :filtered="filteredEntities"
                    :select-entity="selectAnalyticsEntity"
                    :search-entities="searchAnalyticsEntities"
                />
                <!-- RIGHT SIDE BAR -->
                <div class="modal-side-bar">
                    <div class="modal-sidebar-section">
                        <Text as="p" size="f-8">
                            Please select a view or data stream for this account using the table
                            opposite. The table includes all properties and apps available in your
                            Google Analytics account.
                        </Text>
                    </div>
                    <Spacer height="2rem" />

                    <div class="modal-sidebar-section">
                        <Label>Connect View or Data Stream</Label>
                        <div class="domain-pill">
                            <Text color="gray" as="p" size="f-8" v-if="!selectedEntities?.profile"
                                >Select View or Data Stream</Text
                            >
                            <Text as="p" size="f-8" v-else>
                                {{ selectedEntities?.profile?.name }}
                            </Text>
                        </div>
                        <Spacer height="1.5rem" />
                        <Label>To Account</Label>
                        <div class="domain-pill">
                            <Text as="p" size="f-8">{{ domainName }} </Text>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #buttons>
            <AnalyticsFooter
                :viewSelected="!selectedEntities?.profile"
                :loading="availableProfilesLoading"
                @close-modal="cancelViewSelection"
                @link-view="linkView()"
                @unlink-account="unlinkGoogleAnalytics()"
            />
        </template>
    </Modal>
</template>

<script lang="ts">
import {
    oButton,
    CloseButton,
    Modal,
    Text,
    Tooltip,
    CheckIcon,
    Label,
    Spinner,
    Spacer,
    SelectArrowIcon,
} from '@opteo/components-next'

import AnalyticsTable from '@/components/analytics/components/AnalyticsTable.vue'
import AnalyticsFooter from '@/components/analytics/components/AnalyticsFooter.vue'
import { useAnalytics } from '@/composition/misc/useAnalytics'
import { useDomain } from '@/composition/domain/useDomain'
import CircleButton from '../global/CircleButton.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import DisableInterestLocations from '../improvement/types/location-interests/DisableInterestLocations.vue'

export default {
    name: 'Analytics',
    components: {
        AnalyticsTable,
        oButton,
        AnalyticsFooter,
        CloseButton,
        CircleButton,
        Modal,
        Text,
        Skeleton,
        Tooltip,
        CheckIcon,
        DisableInterestLocations,
        Label,
        Spinner,
        Spacer,
        SelectArrowIcon,
    },
    props: {
        view: {
            // Location of component, for example 'domain-settings'
            type: String,
            required: false,
        },
        buttonColor: {
            // Location of component, for example 'domain-settings'
            type: String,
            required: false,
            default: 'blue',
        },
    },
    emits: ['before-oauth-redirect'],
    setup(_, { emit }) {
        const analytics = useAnalytics(async () => emit('before-oauth-redirect'))
        return {
            ...analytics,
            ...useDomain(),
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.analytics-container {
    @include container;
    @include flex;
    flex-direction: column;
    gap: 1.5rem;
}
.analytics-container.domain-settings {
    box-shadow: none;
}
.analytics-container.reports {
    padding: 1rem 3.75rem;
}

.preview-toggle {
    @include absolute;
    @include container;
    @include flex;
    @include items-center;
    @include justify-center;
    @include pointer;
    border-radius: 4px;
    top: 10px;
    right: 10px;
    height: 28px;
    width: 28px;
}

.slide-image {
    @include container;
}

.description-text-container {
    @include ph-32;
}

.connection-status {
    @include ph-32;
    @include pb-32;
}

.connection-status .is-connected {
    @include container;
    @include w-100;
    display: block;
}

.connection-status .not-connected {
    @include flex-center;
    min-height: 44px;
}

.status-container {
    @include flex;
    @include items-center;
    @include h-100;
    @include no-wrap;
    @include ph-24;
    @include pv-20;
    border-bottom: 1px solid;
    @include opteo-border;
    max-height: 74px;
}

.status-container:last-child {
    border-bottom: unset;
}

.status-container .view-connected-text {
    overflow: hidden;
}

.check-text-container {
    @include flex;
    @include items-center;
}

.connect-view-button {
    @include ml-8;
}

.check-icon {
    @include mr-16;
}

.button-container {
    @include flex-center;
    @include mt-24;
}

.domain-pill {
    @include container;
    @include pa-16;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edit-button {
    display: inline-flex;
}

.modal-content-container {
    @include flex;
    @include justify-center;
    margin: 0 auto;
    max-width: 1280px;
    @include relative;
}

.modal-table {
    @include mr-32;
    min-width: 500px;
    max-width: 782px;
    min-height: 640px;
}

.modal-side-bar {
    @include sticky;
    top: 0;
    height: 415px;
}
.modal-sidebar-section {
    @include container;
    width: 318px;
    @include pa-24;
}

.spinner-container {
    height: 78px;
    @include flex;
    @include items-center;
    margin: 0 auto;
}

.slide-image-container {
    @include relative;
    @include w-100;
    @include ph-32;
    @include pt-32;
}

@media screen and (max-width: $mq-767-max) {
    .modal-table {
        @include mr-32;
        min-width: unset;
        max-width: 100%;
        min-height: unset;
    }
    .modal-sidebar-section {
        width: 100%;
    }
}

@media screen and (max-width: $mq-1024-max) {
    .modal-content-container {
        flex-direction: column;
    }
    .modal-side-bar {
        margin: 0 auto;
        @include pv-32;
    }
}
</style>
