<template>
    <div v-if="loading">
        <ReportEditSkeleton title="Update Report" />
    </div>

    <div v-else-if="creatingReport">
        <ReportLoaderContainer action="updating" :hasError="creatingReportError" />
    </div>
    <div v-else>
        <div class="create-report-content">
            <ReportEditFlow title="Update Report" />
        </div>
    </div>

    <ReportFooter title="Update Report">
        <oButton @clicked="returnToSlides" color="white" class="mr2 w-100 w-auto">Cancel</oButton>

        <oButton
            :loading="creatingReport && !creatingReportError"
            :disabled="!creationFormReady"
            @clicked="reviseReport"
            color="domain-color"
            class="w-auto"
        >
            Update Report
            <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
        </oButton>
    </ReportFooter>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'

import { oButton, Spacer, RightArrowIcon } from '@opteo/components-next'

import ReportFooter from '@/components/Reports/ReportFooter.vue'
import ReportLoaderContainer from '@/components/Reports/ReportLoaderContainer.vue'
import { provideCreateReport } from '@/composition/reports/useCreateReport'
import ReportEditFlow from '@/components/Reports/ReportEditFlow.vue'
import ReportEditSkeleton from '@/components/Reports/ReportEditSkeleton.vue'

import { Routes } from '@/router/routes'
import { useReport } from '@/composition/reports/useReport'
import { useDomain } from '@/composition/domain/useDomain'

export default {
    components: {
        oButton,
        Spacer,
        ReportFooter,
        ReportLoaderContainer,
        ReportEditFlow,
        ReportEditSkeleton,
        RightArrowIcon,
    },
    setup() {
        const { domainId } = useDomain()
        const { push, currentRoute } = useRouter()
        const { refreshReport } = useReport()
        const {
            newReportInfo,
            createReport,
            creatingReport,
            creatingReportError,
            loading,
            creationFormReady,
        } = provideCreateReport('update')

        const returnToReportsList = () => push({ name: Routes.ReportsActive })
        const returnToSlides = () => push({ name: Routes.ReportSlides })

        const reviseReport = async () => {
            const localDomainId = domainId.value
            await createReport()
            await refreshReport()

            if (
                localDomainId !== domainId.value ||
                currentRoute.value.name !== Routes.ReportUpdate
            ) {
                return // domain changed or user navigated away, so don't redirect
            }
            returnToSlides()
        }

        return {
            reviseReport,
            creatingReport,
            creatingReportError,
            loading,
            returnToReportsList,
            returnToSlides,
            newReportInfo,
            creationFormReady,
        }
    },
}
</script>

<style lang="scss" scoped>
.create-report-content {
    max-width: 604px;
    padding-bottom: 9.5rem;
    display: block;
    margin: 6rem auto 0 auto;
}
</style>
