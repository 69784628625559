<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 12h10M12 6h10M12 18h10M6.875 4.5l-3.25313 3L2 6M6.875 10.5l-3.25313 3L2 12M6.875 16.5l-3.25313 3L2 18"
            stroke="#000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
