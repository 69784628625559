// Source: https://github.com/devrnt/react-use-intercom

import { IntercomMethod } from './types'

import { LOAD_INTERCOM } from '@/lib/env'

/**
 * Safely exposes `window.Intercom` and passes the arguments to the instance
 *
 * @param method method passed to the `window.Intercom` instance
 * @param args arguments passed to the `window.Intercom` instance
 *
 * @see {@link https://developers.intercom.com/installing-intercom/docs/intercom-javascript}
 */
export const IntercomAPI = (method: IntercomMethod, ...args: Array<any>) => {
    if (window.Intercom) {
        return window.Intercom.apply(null, [method, ...args])
    } else {
        if (LOAD_INTERCOM) {
            console.error(`${method} Intercom instance is not initalized yet`)
        }
    }
}
