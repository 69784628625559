<template>
    <div v-if="layout === 'default'" class="opteo-sidebar">
        <div class="opteo-sidebar-upper">
            <oButton
                @clicked="goToAccountCentre"
                class="opteo-sidebar-menu-link opteo-sidebar-account-centre-link"
                tabindex="0"
                color="white"
            >
                <OpteoLogo :width="44" :height="44" style="width: 2.75rem; height: 2.75rem" />
            </oButton>
            <Spacer height="0.75rem" />
            <oButton
                @clicked="goToChat"
                class="opteo-sidebar-menu-link opteo-sidebar-chat-link"
                tabindex="0"
                color="white"
            >
                <ChatIcon />
            </oButton>
        </div>

        <div class="opteo-sidebar-lower">
            <!-- Sidebar Menu Buttons -->
            <HelpSidebarButton
                :active="activeMenu === 'help-sidebar'"
                @clicked="toggleMenu('help-sidebar')"
            />
            <Spacer height="0.5rem" />
            <AlertsSidebarButton
                :active="activeMenu === 'alerts-sidebar'"
                @clicked="toggleMenu('alerts-sidebar')"
            />
            <Spacer height="0.5rem" />
            <AccountSidebarButton
                :active="activeMenu === 'account-sidebar'"
                @clicked="toggleMenu('account-sidebar')"
            />
        </div>
    </div>

    <teleport to="body">
        <transition-group name="sidebar-menu-slide" @leave="onLeave">
            <!-- Sidebar Menus -->
            <LeftSidebarMenu
                id="help-sidebar"
                v-if="activeMenu === 'help-sidebar'"
                @close="activeMenu = undefined"
            >
                <div style="padding: 2rem">
                    <HelpSidebarContent />
                </div>
            </LeftSidebarMenu>

            <LeftSidebarMenu
                id="alerts-sidebar"
                v-if="activeMenu === 'alerts-sidebar'"
                :width="484"
                @close="activeMenu = undefined"
                :on-click-outside="maybePreventAlertsClosing"
            >
                <AlertsSidebar />
            </LeftSidebarMenu>

            <LeftSidebarMenu
                id="account-sidebar"
                v-if="activeMenu === 'account-sidebar'"
                @close="activeMenu = undefined"
            >
                <div style="padding: 2rem">
                    <AccountSidebarContent />
                </div>
            </LeftSidebarMenu>
        </transition-group>
    </teleport>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, nextTick } from 'vue'
import { OpteoLogo, Spacer, oButton } from '@opteo/components-next'

import { layout } from '@/composition/layouts/useLayouts'
import { useUser } from '@/composition/user/useUser'
import { useSubscription } from '@/composition/billing/useSubscription'
import { useRouter } from 'vue-router'
import HelpSidebarButton from '@/components/User/Sidebars/HelpSidebar/HelpSidebarButton.vue'
import AlertsSidebarButton from '@/components/User/Sidebars/AlertsSidebar/AlertsSidebarButton.vue'
import AccountSidebarButton from '@/components/User/Sidebars/AccountSidebar/AccountSidebarButton.vue'

import LeftSidebarMenu from '@/components/User/Sidebars/LeftSidebarMenu.vue'

import HelpSidebarContent from '@/components/User/Sidebars/HelpSidebar/HelpSidebarContent.vue'
import AlertsSidebar from '@/components/User/Sidebars/AlertsSidebar/AlertsSidebar.vue'
import AccountSidebarContent from '@/components/User/Sidebars/AccountSidebar/AccountSidebarContent.vue'

import ChatIcon from '@/components/chat/ChatIcon.vue'

import { Routes } from '@/router/routes'

type SidebarMenu = 'help-sidebar' | 'alerts-sidebar' | 'account-sidebar'

export default defineComponent({
    name: 'LeftNavBar',
    components: {
        OpteoLogo,
        Spacer,
        oButton,
        HelpSidebarButton,
        AlertsSidebarButton,
        AccountSidebarButton,
        LeftSidebarMenu,
        AccountSidebarContent,
        AlertsSidebar,
        HelpSidebarContent,
        ChatIcon,
    },
    setup() {
        const { isBillingLocked } = useSubscription()
        const { user, userId } = useUser()

        const activeMenu = ref()

        function toggleMenu(menu: SidebarMenu) {
            if (activeMenu.value === menu) {
                activeMenu.value = undefined
                return
            }

            activeMenu.value = menu
        }

        function maybePreventAlertsClosing(clickedElement: HTMLElement) {
            return !!clickedElement.closest('#alert-modal')
        }

        const router = useRouter()
        function goToAccountCentre() {
            if (!isBillingLocked.value) {
                router.push({ name: Routes.AccountCentre })
            }
        }

        function goToChat() {
            if (!isBillingLocked.value) {
                router.push({ name: Routes.Chat })
            }
        }

        // when changing route, if the next layout is full-screen, we want to disable the transition
        async function onLeave(el: Element, done: () => void) {
            await nextTick()
            if (layout.value === 'full-screen') {
                done()
            }
        }

        watch(router.currentRoute, () => {
            if (activeMenu.value?.length > 0) {
                activeMenu.value = undefined
            }
        })

        return {
            layout,
            activeMenu,
            toggleMenu,
            onLeave,
            maybePreventAlertsClosing,
            userId,
            goToAccountCentre,
            goToChat,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/theme.scss';

.opteo-sidebar {
    display: none;
    @include fixed;
    @include opteo-background;
    @include pv-16;
    z-index: 99999;
    max-height: 100vh;
    bottom: 0;
    top: 0;
    width: 6rem;
}

.opteo-sidebar-menu-link,
:deep(.opteo-sidebar-menu-link) {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 1rem !important;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1) !important;
    width: 4rem !important;
    height: 4rem !important;
    padding: 0 !important;
    cursor: pointer !important;
    box-shadow: none !important;
}
.opteo-sidebar-menu-link:hover,
:deep(.opteo-sidebar-menu-link:hover) {
    box-shadow: $opteo-shadow !important;
}
.opteo-sidebar-menu-link:focus,
:deep(.opteo-sidebar-menu-link:focus) {
    outline: none !important;
    box-shadow: $opteo-shadow-focus !important;
}
.opteo-sidebar-menu-link:active,
:deep(.opteo-sidebar-menu-link:active) {
    outline: none !important;
    box-shadow: $opteo-shadow-focus !important;
    transform: translate3d(0, 1px, 0) !important;
}

.opteo-sidebar-menu-link span,
:deep(.opteo-sidebar-menu-link span) {
    height: auto;
    transform: none;
}

.opteo-sidebar-account-centre-link span,
:deep(.opteo-sidebar-account-centre-link span) {
    height: 2.75rem !important;
    transform: none !important;
}

.opteo-sidebar-menu-link span svg,
:deep(.opteo-sidebar-menu-link span svg) {
    display: block;
}

.sidebar-menu-slide-enter-active,
.sidebar-menu-slide-leave-active {
    transition: transform 0.4375s cubic-bezier(0.64, 0, 0.32, 1);
}
.sidebar-menu-slide-enter-from,
.sidebar-menu-slide-leave-to {
    transform: translate3d(calc(-100% - 6.5rem), 0, 0);
}
.sidebar-menu-slide-leave-to {
    transition: transform 0.4375s cubic-bezier(0.64, 0, 0.32, 1);
}

@media screen and (min-width: $mq-768-min) {
    .opteo-sidebar {
        @include flex;
        @include items-center;
        @include justify-between;
        flex-direction: column;
    }
}
</style>
