<template>
    <div
        :data-depth="depth"
        :class="[determineNestedMargin, highlightProductGroupRow, 'relative']"
        @mouseenter="handleHoveredTable(true, current)"
        @mouseleave="handleHoveredTable(false, current)"
    >
        <div
            @mouseenter="handleHoveredItem(productGroup)"
            ref="resyncRecursiveRow"
            class="product-group-row flex items-center justify-between relative overflow-hidden"
            v-if="current || !bucket"
        >
            <div class="product-group-name flex-grow-1 lh-title inline-flex pr2">
                <span
                    v-if="productGroup.generated_product_group"
                    class="fw6"
                    style="margin-right: 0.5rem; max-width: 100%; color: #00be72 !important"
                    >+</span
                >
                <span v-if="bucket" class="truncate db fw5" :title="productGroup.name"
                    >Everything else in "<span class="fw5">{{ productGroupLabel }}</span
                    >"
                </span>
                <span v-else class="truncate db" :title="productGroup.name"
                    >{{ productGroup.name }}
                </span>
            </div>
            <div
                v-if="current && bucket"
                :class="[current ? 'opteo-red' : 'o-40', 'pr4 w-15 fw5 tr']"
            >
                {{ current ? newProductGroups : 0 }}
            </div>
        </div>

        <div v-else class="">
            <div
                @mouseenter="handleHoveredItem(productGroupWithNoMetrics)"
                ref="resyncRecursiveRow"
                class="empty-product-group-row product-group-name"
            >
                <Text
                    >Everything else in "<span class="fw5">{{ productGroupLabel }}</span
                    >"
                </Text>
                <Text>0</Text>
            </div>

            <div
                ref="generatedRefBucket"
                class="everything-else-bucket bg-white highlight-row new-product-group-container"
            >
                <ResyncRecursiveRow
                    v-for="product_group in visibleGeneratedProductGroups"
                    :key="product_group.resource_name"
                    :current="current"
                    :depth="depth + 1"
                    :product-group-label="productGroupLabel"
                    :product-group="product_group"
                />

                <div
                    class="flex pa-24"
                    @click="generatedProductGroupsShown += 5"
                    v-if="showMoreGeneratedProductGroups"
                >
                    <oButton size="small" color="white">Show More</oButton>
                </div>
            </div>
        </div>

        <ResyncRecursiveRow
            v-for="product_group in visibleProductGroups"
            :key="product_group.resource_name"
            :current="current"
            :depth="depth + 1"
            :product-group-label="productGroupLabel"
            :product-group="product_group"
            :resynced-product-group="resyncedProductGroup"
        />
        <div :class="[{ mb3: depth === 0 }, 'ml4', determineNestedMargin]" v-if="isShowMoreVisible">
            <div class="ml4 pt3" :style="{ paddingBottom: '1.25rem' }" size="small" color="white">
                ...
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import orderBy from 'lodash-es/orderBy'
import { inject, computed, ref, onMounted } from 'vue'

import { CheckProductGroups } from '@opteo/types'
import { oButton, Text } from '@opteo/components-next'
import { ProductGroup } from '@/composition/improvement/types/product-group-bids/types'

const props = defineProps<{
    current: boolean
    depth: number
    productGroupLabel: string
    productGroup: CheckProductGroups.ProductGroup
}>()

const resyncRecursiveRow = ref<HTMLDivElement>()
const generatedRefBucket = ref<HTMLDivElement>()
const productGroupsShown = ref(10)
const generatedProductGroupsShown = ref(5)

const updateHoveredItem =
    inject<(payload: { item: CheckProductGroups.ProductGroup; distance: number }) => void>(
        'updateHoveredItem'
    )

const updateHoveredBoolean =
    inject<(payload: { mouseenter: boolean; current: boolean }) => void>('updateHoveredBoolean')

function handleHoveredItem(item: CheckProductGroups.ProductGroup) {
    const payload = {
        item,
        distance: resyncRecursiveRow.value?.getBoundingClientRect().top ?? 0,
    }

    if (updateHoveredItem) updateHoveredItem(payload)
}

const generatedProductGroupTree = inject<CheckProductGroups.ProductGroup[]>(
    'generatedProductGroupTree'
)

const newProductGroups = inject<number>('newProductGroups')

const showMoreGeneratedProductGroups = computed(() => {
    if (props.current || !bucket.value) {
        return false
    }

    return (generatedProductGroupTree?.length ?? 0) > generatedProductGroupsShown.value
})

const resyncedProductGroup = inject<string>('resyncedProductGroup')

const bucket = computed(() => props.productGroup.resource_name === resyncedProductGroup)
const isShowMoreVisible = computed(
    () => props.productGroup.product_groups?.length ?? 0 + 1 > productGroupsShown.value ?? false
)

const visibleProductGroups = computed(
    () =>
        orderBy(
            props.productGroup.product_groups,
            [
                product_group => product_group.product_groups?.length,
                product_group => product_group.resource_name === resyncedProductGroup,
            ],
            ['desc', 'desc']
        )?.slice(0, productGroupsShown.value) ?? []
)

const visibleGeneratedProductGroups = computed(
    () => generatedProductGroupTree?.slice(0, generatedProductGroupsShown.value) ?? []
)

function handleHoveredTable(mouseenter: boolean, currenttable: boolean) {
    if (window && window.innerWidth < 1200) {
        return
    }
    const payload = {
        mouseenter,
        current: currenttable,
    }

    if (typeof updateHoveredBoolean === 'function') updateHoveredBoolean(payload)
}

const highlightProductGroupRow = computed(() =>
    bucket.value && props.current ? 'everything-else-bucket shadow-card bg-white highlight-row' : ''
)

const determineNestedMargin = computed(() => {
    if (props.depth >= 1) {
        return props.productGroup.generated_product_group || (!props.current && bucket.value)
            ? 'root-row'
            : 'nested-row'
    }
})

const productGroupWithNoMetrics = computed<ProductGroup>(() => {
    const productGroup = {
        name: props.productGroup.name,
        product_group_attribute: props.productGroup.product_group_attribute,
        cost: 0,
        clicks: 0,
        impressions: 0,
    }

    return productGroup
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.product-group-row {
    height: 3.25rem;
    width: 100%;
    cursor: default;
    @include f-8;
}
.product-group-row:hover {
    background-color: $opteo-lighter-gray;
}

.highlight-row {
    transform: scale(1.015, 1);
    z-index: 999;
    @include f-8;
    @include container;
    overflow: hidden;
    @include relative;
}
.highlight-row .root-row:first-child {
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
}

.product-group-name {
    max-width: 90%;
    padding-left: 1.5rem;
    @include f-8;
}
.product-group-name span {
    @include f-8;
}

.nested-row {
    margin-left: 1.5rem;
    border-left: 1px solid rgba(2, 2, 12, 0.04);
    @include f-8;
}

.new-product-group-container {
    margin-left: 1.5rem;
}

.nested-row.everything-else-bucket.highlight-row {
    border: none;
}

@media screen and (min-width: 1050px) {
    .attribute-label {
        display: inline;
    }
}

.empty-product-group-row {
    margin-left: 1.5rem;
    border-left: 1px solid rgba(2, 2, 12, 0.04);
    height: 3.25rem;
    cursor: default;
    @include flex;
    @include items-center;
    @include justify-between;
    @include relative;
}
.empty-product-group-row p {
    opacity: 32%;
    @include f-8;
}
</style>
