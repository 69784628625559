<template>
    <ImprovementView
        :pushMessages="pushMessages"
        :pushActionText="pushAction"
        :isAdjustable="true"
        :onPush="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="keyword" :content="formattedKeyword" />
                    <Spacer width="100%" height="2rem" />
                </div>
                <Text v-if="isBidIncreasing">
                    The keyword above has generated
                    <b><Number :value="Math.round(conversions)" /></b> conversions at an average CPA
                    of <b>{{ formattedKeywordCPA }}</b
                    >. In comparison, the parent campaign group has a Target CPA of
                    <b>{{ formattedAverageCPA }}</b
                    >.
                    <Spacer height="1rem" />
                    Increasing spend on this keyword should generate more conversions at a
                    reasonable CPA. Opteo recommends applying a
                    <Text as="span" weight="600" color="green">
                        +<Percent :value="percentBidChange" />
                    </Text>
                    bid adjustment to this keyword.
                    <Spacer height="1rem" />
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to apply
                    the recommended adjustment to this keyword. Click <b>Adjust</b> to use a more
                    specific percentage (or to apply a different adjustment).
                </Text>
                <Text v-else>
                    The keyword above has generated
                    <b><Number :value="Math.round(conversions)" /></b> conversions at an average CPA
                    of <b>{{ formattedKeywordCPA }}</b
                    >. In comparison, the parent campaign group has a Target CPA of
                    <b>{{ formattedAverageCPA }}</b
                    >.
                    <Spacer height="1rem" />
                    Decreasing spend on this keyword should reduce CPA and allow for smarter budget
                    allocation. Opteo recommends applying a
                    <Text as="span" weight="600" color="red">
                        <Percent :value="percentBidChange" />
                    </Text>
                    bid adjustment to this keyword.
                    <Spacer height="1rem" />
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to apply
                    the recommended adjustment to this keyword. Click <b>Adjust</b> to use a more
                    specific percentage (or to apply a different adjustment).
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/902970-adjust-keyword-bid">
                Learn how keyword bid adjustments can help reduce overall CPA.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ScatterPointChart
                :items="scatterPointChartItems"
                :graphKeys="['Above CPA Target', '', 'Below CPA Target']"
                :cutOff="averageCPA"
                :axis="{
                    x: { label: 'Conversions' },
                    y: { label: 'Cost', dataType: 'money', currency: currencyCode },
                }"
                :metric="{
                    label: 'CPA',
                    dataType: 'money',
                    currency: currencyCode,
                    inverted: true,
                }"
            />
            <Spacer height="2rem" />
            <ImprovementNote>
                <b>Note:</b> Hover over any keyword point to explore its stats. Keywords in green
                zones are below your CPA target, whilst red zones are above your CPA target.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics
                title="Recommended Bid Adjustment"
                :items="[
                    {
                        key: 'bidAdjustment',
                        deltaMode: true,
                        beforeValue: formattedCurrentBid,
                        beforeTitle: 'Current CPC Bid',
                        afterValue: formattedNewBid,
                        afterTitle: 'Adjusted CPC Bid',
                    },
                ]"
            >
                <template v-slot:bidAdjustment>
                    <Text as="p" size="f-8">
                        Opteo recommends a
                        <Text
                            :color="percentBidChange > 0 ? 'green' : 'red'"
                            as="span"
                            weight="600"
                        >
                            <Percent :value="percentBidChange" :include-sign="true" />
                        </Text>
                        bid adjustment.
                        <!-- <span v-if="isEqualToFirstPageBid">
                            This is estimated as the minimum required for first page bid status, but
                            you can make a further decrease by clicking <b>Adjust</b>.
                        </span> -->
                    </Text>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>
        <ImprovementContent>
            <ImprovementStatistics
                :items="[
                    {
                        key: 'averageCPA',
                        value: formattedAverageCPA,
                        title: 'Target CPA',
                    },
                    {
                        key: 'keywordCPA',
                        value: formattedKeywordCPA,
                        title: 'Keyword CPA',
                    },
                    {
                        key: 'searchImprShare',
                        value: formattedSearchImprShare,
                        title: 'Search Impr. Share',
                    },
                    {
                        key: 'absoluteTopImprShare',
                        value: formattedAbsoluteTopImprShare,
                        title: 'Absolute Top Impr. Share',
                    },
                    {
                        key: 'firstPageBid',
                        value: formattedFirstPageBid,
                        title: 'First Page Bid',
                    },
                ]"
            >
                <template v-slot:averageCPA>
                    <Text as="p" size="f-8">
                        The campaign group
                        <Ent>{{ campaignGroupName }}</Ent> has a Target CPA of
                        <Ent><Money :value="averageCPA" /></Ent>. Please note, this target is based
                        on your settings in <b>Campaign Groups</b>.
                    </Text>
                </template>
                <template v-slot:keywordCPA>
                    <Text as="p" size="f-8">
                        Over the last 60 days, this keyword had an average CPA of
                        <Ent><Money :value="keywordCPA" /></Ent>.
                    </Text>
                </template>
                <template v-slot:searchImprShare>
                    <Text as="p" size="f-8">
                        Your ads were shown in Google's search results
                        <Ent><Percent :value="searchImprShare" /></Ent> of the time they were
                        eligible.
                    </Text>
                </template>
                <template v-slot:absoluteTopImprShare>
                    <Text as="p" size="f-8">
                        Over the last 60 days, ads triggered by this keyword were in the top-most
                        position of Google's Search Engine Results Page (SERP)
                        <Ent><Percent :value="absoluteTopImprShare" /></Ent>
                        of the time.
                    </Text>
                </template>
                <template v-slot:firstPageBid>
                    <Text as="p" size="f-8">
                        Google recommends bidding a minimum of
                        <Ent><Money :value="firstPageBid" /></Ent> to appear on the first page.
                    </Text>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="60" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementBidAdjustment
                    ref="impBidAdjustmentRef"
                    bid-type="keyword"
                    :current-bid="currentBid"
                    :new-bid="adjustedBid"
                    :entity-pill-list="improvementEntityPillList"
                    entType="keyword"
                    @on-bid-update="onBidUpdate"
                >
                    <template v-slot:entity-pill>
                        <EntityPill v-if="aboveMobile" type="keyword" :content="formattedKeyword" />
                    </template>
                    <template v-slot:text>
                        <Text v-if="isBidIncreasing">
                            The keyword above has generated
                            <b><Number :value="Math.round(conversions)" /></b> conversions with an
                            average CPA of <b>{{ formattedKeywordCPA }}</b
                            >. In comparison, the parent campaign group has a Target CPA of
                            <b>{{ formattedAverageCPA }}</b
                            >.
                            <Spacer height="1rem" />
                            Increasing spend on this keyword should generate more conversions at a
                            reasonable CPA. Opteo recommends applying a
                            <Text as="span" weight="600" color="green">
                                +<Percent :value="percentBidChange" />
                            </Text>
                            bid adjustment to this keyword.
                        </Text>
                        <Text v-else>
                            The keyword above has generated
                            <b><Number :value="Math.round(conversions)" /></b> conversions with an
                            average CPA of <b>{{ formattedKeywordCPA }}</b
                            >. In comparison, the parent campaign group has a Target CPA of
                            <b>{{ formattedAverageCPA }}</b
                            >.
                            <Spacer height="1rem" />
                            Decreasing spend on this keyword should reduce CPA and allow for smarter
                            budget allocation. Opteo recommends applying a
                            <Text as="span" weight="600" color="red">
                                <Percent :value="percentBidChange" />
                            </Text>
                            bid adjustment to this keyword.
                        </Text>
                    </template>
                </ImprovementBidAdjustment>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import {
    Percent,
    ScatterPointChart,
    oInput,
    Number,
    Spacer,
    Text,
    EntityPill,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import Ent from '@/components/util/Ent.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAdjustKeywordBid } from '@/composition/improvement/types/useAdjustKeywordBid'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AdjustKeywordBid',
    components: {
        ImprovementHelpLink,
        Money,
        Percent,
        ScatterPointChart,
        ImprovementView,
        oInput,
        Ent,
        ImprovementBidAdjustment,
        Number,
        Spacer,
        Text,
        EntityPill,
    },
    setup() {
        return { ...useAdjustKeywordBid(), ...useMediaQuery() }
    },
})
</script>
