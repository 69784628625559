<template>
    <div class="loading-slides-wrapper">
        <marquee-text :duration="50" :repeat="4">
            <div class="slides">
                <ImpactStatsLoaderSkeleton3 />
                <ImpactStatsLoaderSkeleton1 />
                <ImpactStatsLoaderSkeleton2 />
            </div>
        </marquee-text>
    </div>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'
import MarqueeText from 'vue-marquee-text-component'

import ImpactStatsLoaderSkeleton1 from './ImpactStatsLoaderSkeleton1.vue'
import ImpactStatsLoaderSkeleton2 from './ImpactStatsLoaderSkeleton2.vue'
import ImpactStatsLoaderSkeleton3 from './ImpactStatsLoaderSkeleton3.vue'

export default {
    components: {
        MarqueeText,
        ImpactStatsLoaderSkeleton1,
        ImpactStatsLoaderSkeleton2,
        ImpactStatsLoaderSkeleton3,
    },
    setup() {
        onMounted(() => {
            // Set default states
            anime.set('.frame.one', {
                opacity: () => 0,
            })
            anime.set('.frame.two', {
                opacity: () => 0,
            })
            anime.set('.frame.three', {
                opacity: () => 0,
            })

            // Timeline
            const timeline = anime.timeline()
            timeline
                .add({
                    targets: '.frame.one',
                    opacity: [0, 1],
                    translateY: [16, 0],
                    delay: 500,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.one',
                    opacity: [1, 0],
                    translateY: [0, 16],
                    delay: 3000,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.two',
                    opacity: [0, 1],
                    translateY: [16, 0],
                    delay: 0,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.two',
                    opacity: [1, 0],
                    translateY: [0, 16],
                    delay: 3000,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.three',
                    opacity: [0, 1],
                    translateY: [16, 0],
                    delay: 0,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.three',
                    opacity: [1, 0],
                    translateY: [0, 16],
                    delay: 3000,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
            // Restart timeline
            setInterval(() => {
                timeline.restart()
            }, 12500)
        })
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.slides {
    @include flex;
    @include items-center;
}

.loading-slides-wrapper {
    @include relative;
}
.loading-slides-wrapper :deep(.marquee-text-wrap) {
    @include pt-4;
    @include pb-48;
}
.loading-slides-wrapper :deep(.marquee-text-content .slides svg) {
    margin-right: 2rem;
    @include container;
    @include br-20;
}
.loading-slides-wrapper::before {
    content: '';
    width: 25vw;
    height: 100%;
    @include absolute;
    top: 0;
    left: 0;
    @include z-2;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
}
.loading-slides-wrapper::after {
    content: '';
    width: 25vw;
    height: 100%;
    @include absolute;
    top: 0;
    right: 0;
    @include z-2;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
    pointer-events: none;
}
</style>
