import { API_URL } from '@/lib/env'

export const mapFiles = {
    GB: 'gb-countries',
    US: 'usaMercatorHigh',
    CA: 'canadaHigh',
    AU: 'australiaHigh',
    NZ: 'newZealandHigh',
    ZA: 'southAfricaHigh',
    UZ: 'uzbekistanHigh',
    UY: 'uruguayHigh',
    AE: 'unitedArabEmiratesHigh',
    UA: 'ukraineHigh',
    ES: 'spainHigh',
    SE: 'swedenHigh',
    CH: 'switzerlandHigh',
    TR: 'turkeyHigh',
    SI: 'sloveniaHigh',
    SK: 'slovakiaHigh',
    IE: 'irelandHigh',
    QA: 'qatarHigh',
    RO: 'romaniaHigh',
    RU: 'russiaHigh',
    SA: 'saudiArabiaHigh',
    RS: 'serbiaHigh',
    NL: 'netherlandsHigh',
    NG: 'nigeriaHigh',
    NO: 'norwayHigh',
    PS: 'palestineHigh',
    PE: 'peruHigh',
    PH: 'philippinesHigh',
    PL: 'polandHigh',
    PT: 'portugalHigh',
    MK: 'macedoniaHigh',
    MY: 'malaysiaHigh',
    MT: 'maltaHigh',
    MC: 'monacoHigh',
    MA: 'moroccoHigh',
    NP: 'nepalHigh',
    JP: 'japanHigh',
    KE: 'kenyaHigh',
    LV: 'latviaHigh',
    LI: 'liechtensteinHigh',
    LT: 'lithuaniaHigh',
    LU: 'luxembourgHigh',
    GE: 'georgiaHigh',
    DE: 'germanyHigh',
    GI: 'gibraltarHigh',
    GR: 'greeceHigh',
    GN: 'guineaHigh',
    HU: 'hungaryHigh',
    IS: 'icelandHigh',
    IN: 'indiaHigh',
    ID: 'indonesiaHigh',
    IL: 'israelHigh',
    IT: 'italyHigh',
    CZ: 'czechRepublicHigh',
    DK: 'denmarkHigh',
    EG: 'egyptHigh',
    EE: 'estoniaHigh',
    FI: 'finlandHigh',
    FR: 'franceHigh',
    CL: 'chileHigh',
    CN: 'chinaHigh',
    CO: 'colombiaHigh',
    CR: 'costaRicaHigh',
    HR: 'croatiaHigh',
    CU: 'cubaHigh',
    CY: 'cyprusHigh',
    BD: 'bangladeshHigh',
    BY: 'belarusHigh',
    BE: 'belgiumHigh',
    BA: 'bosniaHerzegovinaRegionsHigh',
    BR: 'brazilHigh',
    BG: 'bulgariaHigh',
    AL: 'albaniaHigh',
    DZ: 'algeriaHigh',
    AD: 'andorraHigh',
    AR: 'argentinaHigh',
    AT: 'austriaHigh',
    AZ: 'azerbaijanHigh',
}

export function buildMapImageUrl({
    version,
    domainId,
    improvementId,
    impIndex,
    currentMap,
    countryCode,
    locationTypeIsCountry,
}: {
    version: number
    domainId: number
    improvementId: number
    impIndex: number
    currentMap: string
    countryCode: string
    locationTypeIsCountry: boolean
}) {
    return `${API_URL}/render/map_v${version}/${domainId}/${improvementId}/map-${impIndex}/${currentMap}/world-map-${impIndex}/${countryCode}/${locationTypeIsCountry}`
}
