<template>
    <div class="improvement-group">
        <div class="header">
            <slot name="header"></slot>
        </div>
        <slot name="content"></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ImprovementGroup',
    setup() {},
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.improvement-group {
    @include relative;
}
.improvement-group .header {
    @include flex;
    @include items-center;
    @include mb-32;
    height: 1.75rem;
}
</style>
