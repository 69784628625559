<template>
    <div class="download-loader">
        <transition name="state-change" mode="out-in" appear>
            <div v-if="!error && isLoading">
                <transition-group
                    @before-enter="onLoadingBeforeEnter"
                    @enter="onLoadingEnter"
                    @after-enter="onLoadingAfterEnter"
                    @enter-cancelled="onLoadingEnterCancelled"
                    @leave="onLoadingLeave"
                    @leave-cancelled="onLoadingLeaveCancelled"
                    :css="false"
                    appear
                >
                    <div
                        v-for="slide in filterVisibleSlides"
                        :key="slide"
                        class="slide-wrapper"
                        :data-type="slide"
                    >
                        <div class="relative">
                            <div class="slide-cnt">
                                <StatisticsGridSlide v-if="slide === 'statistics-grid'" />
                                <TopPerformingAdSlide v-if="slide === 'top-performing-ad'" />
                                <BarChartSlide v-if="slide === 'bar-chart'" />
                                <TableSlide v-if="slide === 'table'" />
                                <LineChartSlide v-if="slide === 'line-chart'" />
                                <FeaturedKeywordSlide v-if="slide === 'featured-keyword'" />
                            </div>
                            <div class="check-icon">
                                <CheckIcon animated :delay="1000" />
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>
            <div v-else-if="!isLoading && error">
                <div class="slide-cnt" data-type="bar-chart">
                    <BarChartSlide :animated="true" />
                    <div class="error-icon">
                        <WarnIcon animated :delay="500" />
                    </div>
                </div>
            </div>
            <div v-else class="complete-state">
                <transition-group
                    @before-enter="onCompleteBeforeEnter"
                    @enter="onCompleteEnter"
                    :css="false"
                    appear
                >
                    <div
                        v-for="(slide, index) in slideTypes"
                        :key="slide"
                        class="slide-wrapper"
                        :data-type="slide"
                        :data-index="index"
                    >
                        <div class="slide-cnt">
                            <StatisticsGridSlide v-if="slide === 'statistics-grid'" />
                            <TopPerformingAdSlide v-if="slide === 'top-performing-ad'" />
                            <BarChartSlide v-if="slide === 'bar-chart'" />
                            <TableSlide v-if="slide === 'table'" />
                            <LineChartSlide v-if="slide === 'line-chart'" />
                            <FeaturedKeywordSlide v-if="slide === 'featured-keyword'" />
                        </div>
                    </div>
                </transition-group>
                <div class="check-icon">
                    <CheckIcon animated :delay="2600" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, computed, onBeforeUpdate, watch } from 'vue'
import StatisticsGridSlide from './loader/StatisticsGridSlide.vue'
import TopPerformingAdSlide from './loader/TopPerformingAdSlide.vue'
import BarChartSlide from './loader/BarChartSlide.vue'
import TableSlide from './loader/TableSlide.vue'
import LineChartSlide from './loader/LineChartSlide.vue'
import FeaturedKeywordSlide from './loader/FeaturedKeywordSlide.vue'
import { CheckIcon, WarnIcon } from '@opteo/components-next'
import anime from 'animejs/lib/anime.es.js'

type SlideType =
    | 'statistics-grid'
    | 'top-performing-ad'
    | 'bar-chart'
    | 'table'
    | 'line-chart'
    // | 'text'
    | 'featured-keyword'

export default {
    components: {
        StatisticsGridSlide,
        TopPerformingAdSlide,
        BarChartSlide,
        TableSlide,
        LineChartSlide,
        FeaturedKeywordSlide,
        CheckIcon,
        WarnIcon,
    },
    props: {
        themeColours: {
            type: String,
            required: false,
            default: '#7D49FF',
        },
        isLoading: {
            type: Boolean,
            required: true,
            default: true,
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const visibleSlides = ref<SlideType[]>([])
        const slideTypes: SlideType[] = [
            'statistics-grid',
            'top-performing-ad',
            'bar-chart',
            'table',
            'line-chart',
            // 'text',
            'featured-keyword',
        ]
        const currentIndex = ref(0)

        onMounted(() => {
            visibleSlides.value.push(slideTypes[currentIndex.value])
        })

        const filterVisibleSlides = computed(() =>
            slideTypes.filter((s: SlideType) => visibleSlides.value.includes(s))
        )

        // preparing animation
        const onLoadingBeforeEnter = (el: HTMLElement) => {
            // called before the element is inserted into the DOM.
            // use this to set the "enter-from" state of the element
            el.style.opacity = '0'
            el.style.transform = 'translate(-50%, -50%)'
            el.style.display = 'block'
        }
        const onLoadingEnter = (el: HTMLElement, done: () => void) => {
            // called one frame after the element is inserted.
            // use this to start the animation.
            anime({
                targets: el,
                opacity: [0, 1],
                translateY: ['+=8', 0],
                scale: [0.98, 1],
                delay: 500,
                duration: 700,
                easing: 'easeInOutSine',
                complete: () => {
                    setTimeout(() => {
                        // loop array, set currentIndex back to zero when at the end of the array
                        const newIndex =
                            (slideTypes.length + ((currentIndex.value + 1) % slideTypes.length)) %
                            slideTypes.length
                        visibleSlides.value.push(slideTypes[newIndex])
                        currentIndex.value = newIndex

                        // call the done callback to indicate transition end
                        done()
                    }, 800)
                },
            })
        }
        const onLoadingAfterEnter = (el: HTMLElement) => {
            // called when the enter transition has finished.
            visibleSlides.value.shift()
        }
        const onLoadingEnterCancelled = (el: HTMLElement) => {
            console.log('onLoadingEnterCancelled')
        }

        const onLoadingLeave = (el: HTMLElement, done: () => void) => {
            // called when the leave transition starts.
            // use this to start the leaving animation.
            el.style.zIndex = '3'
            anime({
                targets: el,
                opacity: [1, 0],
                translateY: [0, '-=150%'],
                easing: 'easeInOutSine',
                duration: 1200,
                complete: () => {
                    done()
                },
            })
        }

        const onLoadingLeaveCancelled = (el: HTMLElement) => {
            // only available with v-show transitions
            console.log('leaveCancelled')
        }

        // Download Complete Animation
        const onCompleteBeforeEnter = (el: HTMLElement) => {
            // called before the element is inserted into the DOM.
            // use this to set the "enter-from" state of the element
            el.style.opacity = '0'
            el.style.transform = 'translate(-50%, -50%)'
            el.style.display = 'block'
        }
        const onCompleteEnter = (el: HTMLElement, done: () => void) => {
            // called one frame after the element is inserted.
            // use this to start the animation.
            const timeline = anime.timeline()

            // set initial starting position before anime runs using index
            timeline.set('.complete-state .slide-wrapper', {
                translateY: (el: HTMLElement, index: number) => {
                    const initial = 16
                    const step = 6
                    const increment = initial + index * step

                    return increment
                },
            })

            timeline
                .add({
                    targets: '.complete-state .slide-wrapper',
                    translateX: (el: HTMLElement, index: number) => {
                        const inital = -4
                        const step = 1
                        return inital + step
                    },
                    translateY: (el: HTMLElement, index: number) => {
                        const initial = 20
                        const step = -6
                        const increment = initial + index * step

                        return increment
                    },
                    scale: (el: HTMLElement, index: number, length: number) => {
                        const low = 0.9
                        const high = 1
                        const difference = high - low
                        const step = difference / length
                        const increment = low + step * index

                        return increment
                    },
                    opacity: (el: HTMLElement, index: number, length: number) => {
                        const low = 0
                        const high = 1
                        const difference = high - low
                        const step = difference / (length - 1)
                        const increment = low + step * index

                        return increment
                    },
                    delay: anime.stagger(200, { start: 200 }),
                    duration: 3000,
                    easing: 'spring(1, 50, 10, 0)',
                    direction: 'reverse',
                })
                .add(
                    {
                        targets: '.complete-state .slide-wrapper',
                        translateX: '-=1.75rem',
                        delay: anime.stagger(20, { start: 0 }),
                        duration: 750,
                        easing: 'easeInOutSine',
                    },
                    '-=800'
                )
                .add(
                    {
                        targets: '.complete-state .check-icon',
                        opacity: [0, 1],
                        scale: {
                            value: [0.5, 1.25],
                            // https://animejs.com/documentation/#springPhysicsEasing
                            easing: 'spring(1, 80, 8, 1)',
                        },
                        duration: 500,
                        easing: 'easeInOutSine',
                        complete: () => {
                            done()
                        },
                    },
                    '-=200'
                )
        }

        const onCompleteEnterCancelled = (el: HTMLElement) => {
            console.log('onCompleteEnterCancelled')
        }

        return {
            filterVisibleSlides,
            slideTypes,

            onLoadingBeforeEnter,
            onLoadingEnter,
            onLoadingAfterEnter,
            onLoadingEnterCancelled,
            onLoadingLeave,
            onLoadingLeaveCancelled,

            onCompleteBeforeEnter,
            onCompleteEnter,
            onCompleteEnterCancelled,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.download-loader {
    width: 600px;
    position: relative;
}
.complete-state {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}
// white gradient at top
.download-loader:before {
    content: '';
    height: 2rem;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
    background: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0));
}

.slide-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
    will-change: transform;
}
.slide-cnt {
    @include container;
    overflow: hidden;
    // opacity: 0;
}
.check-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -0.75rem;
}
.complete-state .slide-wrapper {
    opacity: 0;
    transform: scale(0.9);
}
.complete-state .check-icon {
    position: absolute;
    top: 6.75rem;
    right: 10.25rem;
}
.error-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.state-change-enter-active,
.state-change-leave-active {
    transition:
        opacity 0.3s ease,
        transform 0.3s ease;
}
.state-change-enter-from,
.state-change-leave-to {
    opacity: 0;
    transform: translateY(8px);
}
</style>
