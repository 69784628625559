<template>
    <div class="settings-section">
        <div class="settings-section-title">
            <Skeleton v-if="skeleton" :height="24" :width="136" />
            <Text as="h4" weight="600" size="f-5" style="letter-spacing: -0.0375rem">
                {{ title }}
            </Text>
            <div class="settings-section-button">
                <oButton
                    v-if="!skeleton && buttonLabel"
                    :color="buttonColor"
                    size="small"
                    @clicked="buttonAction"
                >
                    {{ buttonLabel }}
                </oButton>
                <Skeleton v-if="skeleton && buttonLabel" :height="36" :width="105" />
            </div>
        </div>
        <div
            :class="['settings-section-content', { 'container-shadow': !noInnerContentShadow }]"
            :style="{ 'max-width': `${maxWidth}px` ?? 'none' }"
        >
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { oButton, Text } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import { PropType } from 'vue'

type ButtonColor = 'blue' | 'white' | 'green' | 'red' | 'dark' | 'dark-blue'

export default {
    name: 'SettingsSection',
    components: { oButton, Text, Skeleton },
    props: {
        title: {
            type: String,
            required: false, // can be false when skeleton
        },
        maxWidth: {
            type: Number,
            required: false,
        },
        buttonLabel: {
            type: String,
            required: false,
        },
        buttonAction: {
            type: Function as PropType<() => any>,
            required: false,
        },
        buttonColor: {
            type: String as PropType<ButtonColor>,
            required: false,
            default: 'blue',
        },
        noInnerContentShadow: {
            type: Boolean,
            required: false,
            default: false,
        },
        skeleton: {
            type: Boolean as PropType<boolean>,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.settings-section {
    width: 100%;
    @include pt-20;
    @include mb-32;
    @include relative;
}
.settings-section:last-child {
    @include mb-0;
}
.settings-section-title {
    @include f-8;
    @include fw-600;
    @include mb-28;
    @include flex;
    @include items-center;
    @include justify-between;
}
.settings-section-title h5 {
    @include lh-150;
}
.settings-section-content {
    margin: 0 auto;
}
.settings-section-content.container-shadow {
    @include container;
    @include br-24;
    overflow: hidden;
}

.settings-section-button {
    height: 1.5rem;
    @include flex;
    @include items-center;
}

@media screen and (max-width: $mq-767-max) {
    .settings-section-content {
        max-width: 100% !important;
    }
}
@media (min-width: $mq-768-min) {
    .settings-section {
        @include container;
        @include br-20;
        @include pt-28;
        @include ph-32;
        @include pb-32;
        @include mb-32;
    }
}
</style>
