<template>
    <TopNavBar v-if="showDomainNavBar">
        <DomainTopNav />
    </TopNavBar>
    <router-view />
    <AccountSetupBar>
        <OnboardingModal
            v-model="showAccountSetupOnboarding"
            position="absolute"
            buttonText="Okay, thanks"
            :setBoundingRect="{ top: 'calc(-100% - 1.25rem)', left: '50%', translateX: '-50%' }"
            transition="fade-up"
            style="padding: 1.5rem 1.875rem"
            maxWidth="550px"
        >
            <template #copy>
                <b>Account Setup</b> is a quick way to set up your accounts with Opteo. Fill out the
                form on this page and click the <b>Continue to Campaign Groups</b> button to
                continue.
            </template>
        </OnboardingModal>
    </AccountSetupBar>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'

import TopNavBar from '@/layouts/TopNavBar.vue'
import DomainTopNav from '@/components/User/DomainTopNav.vue'
import AccountSetupBar from '@/components/accountsSettings/AccountSetupBar.vue'
import { OnboardingModal } from '@opteo/components-next'

import { useTopNavBar } from '@/composition/user/useTopNavBar'
import { useLocalStorage } from '@vueuse/core'
import { provideDomain } from '@/composition/domain/useDomain'
import { useAccount } from '@/composition/account/useAccount'

export default defineComponent({
    name: 'Domain',
    setup() {
        const { showDomainNavBar } = useTopNavBar()

        // Only show the onboarding modal once, no need to show it for every account
        const showAccountSetupOnboarding = useLocalStorage<boolean>(
            'showAccountSetupOnboarding',
            true
        )
        provideDomain() // TODO(ms): remove the need for provideDomain entirely
        const { trackAccountVisit, accountId } = useAccount()

        const trackVisit = () => {
            if (!accountId.value) return

            trackAccountVisit()
        }

        // Track visit when first mounted and when account changes via quick-switcher
        // TODO: avoid needing the trackVisit calls at all, and extract that information from Endpoint.TrackPageLoad instead.
        onMounted(trackVisit)
        watch(accountId, trackVisit)

        return { showDomainNavBar, showAccountSetupOnboarding }
    },
    components: { TopNavBar, DomainTopNav, AccountSetupBar, OnboardingModal },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.domain-top-nav-bar {
    @include grid;
    grid-template-columns: 1fr auto 1fr;
    height: 100%;
    // align-items: center;
}
.domain-top-nav-bar-center {
    @include flex;
    @include items-center;
}
.domain-top-nav-bar-right {
    @include flex;
    @include items-center;
    justify-self: end;
}

.top-nav-bar-link {
    @include flex;
    @include items-center;
    @include f-7;
    @include fw-500;
    @include opteo-foreground;
    @include ml-20;
    @include h-100;
}
.top-nav-bar-link:first-child {
    @include ml-0;
}
.top-nav-bar-icon {
    width: 1.5rem;
    height: 1.5rem;
    @include br-999;
    border: 0.125rem solid;
    @include opteo-foreground;
}
.top-bar-nav-link-text {
    @include ml-12;
}
.top-nav-bar-link.router-link-active {
    @include opteo-blue;
    opacity: 1;
}
.top-nav-bar-link.router-link-active .top-nav-bar-icon {
    @include opteo-blue;
}
</style>
