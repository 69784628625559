<template>
    <svg
        width="680"
        height="394"
        viewBox="0 0 680 394"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="680" height="394" fill="white" />
        <mask
            id="mask0_1640_3946"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="680"
            height="394"
        >
            <rect width="680" height="394" fill="white" />
        </mask>
        <g mask="url(#mask0_1640_3946)">
            <g filter="url(#filter0_dd_1640_3946)">
                <rect x="72" y="24" width="536" height="495" rx="12" fill="white" />
            </g>
            <path
                d="M94.576 49.53C94.576 47.29 96.144 46.226 97.67 46.226C99.21 46.226 100.778 47.29 100.778 49.53C100.778 51.77 99.21 52.834 97.67 52.834C96.144 52.834 94.576 51.77 94.576 49.53ZM92.574 49.544C92.574 52.736 94.982 54.71 97.67 54.71C100.372 54.71 102.78 52.736 102.78 49.544C102.78 46.338 100.372 44.364 97.67 44.364C94.982 44.364 92.574 46.338 92.574 49.544Z"
                fill="black"
            />
            <path
                d="M105.812 57.16V53.828C106.148 54.29 106.848 54.668 107.8 54.668C109.746 54.668 111.048 53.128 111.048 51.042C111.048 48.998 109.886 47.458 107.87 47.458C106.834 47.458 106.064 47.92 105.756 48.452V47.612H103.95V57.16H105.812ZM109.214 51.056C109.214 52.288 108.458 53.002 107.506 53.002C106.554 53.002 105.784 52.274 105.784 51.056C105.784 49.838 106.554 49.124 107.506 49.124C108.458 49.124 109.214 49.838 109.214 51.056Z"
                fill="black"
            />
            <path
                d="M113.669 50.272C113.711 49.642 114.243 48.914 115.209 48.914C116.273 48.914 116.721 49.586 116.749 50.272H113.669ZM116.931 52.064C116.707 52.68 116.231 53.114 115.363 53.114C114.439 53.114 113.669 52.456 113.627 51.546H118.555C118.555 51.518 118.583 51.238 118.583 50.972C118.583 48.76 117.309 47.402 115.181 47.402C113.417 47.402 111.793 48.83 111.793 51.028C111.793 53.352 113.459 54.71 115.349 54.71C117.043 54.71 118.135 53.716 118.485 52.526L116.931 52.064Z"
                fill="black"
            />
            <path
                d="M121.6 50.538C121.6 49.74 122.076 49.11 122.888 49.11C123.784 49.11 124.162 49.712 124.162 50.482V54.5H126.024V50.16C126.024 48.648 125.24 47.43 123.532 47.43C122.79 47.43 121.964 47.752 121.544 48.466V47.612H119.738V54.5H121.6V50.538Z"
                fill="black"
            />
            <path
                d="M135.185 54.71C137.971 54.71 139.399 52.862 139.777 51.336L137.971 50.79C137.705 51.714 136.879 52.82 135.185 52.82C133.589 52.82 132.105 51.658 132.105 49.544C132.105 47.29 133.673 46.226 135.157 46.226C136.879 46.226 137.649 47.276 137.887 48.228L139.707 47.654C139.315 46.044 137.901 44.364 135.157 44.364C132.497 44.364 130.103 46.38 130.103 49.544C130.103 52.708 132.413 54.71 135.185 54.71Z"
                fill="black"
            />
            <path
                d="M142.183 51.028C142.183 49.768 142.911 49.026 143.905 49.026C144.885 49.026 145.599 49.768 145.599 51.028C145.599 52.288 144.871 53.086 143.905 53.086C142.897 53.086 142.183 52.274 142.183 51.028ZM145.613 53.646C145.613 53.982 145.655 54.346 145.683 54.5H147.461C147.447 54.346 147.405 53.87 147.405 53.254V47.612H145.599V48.466C145.431 48.074 144.857 47.416 143.625 47.416C141.623 47.416 140.307 49.012 140.307 51.028C140.307 53.114 141.651 54.696 143.681 54.696C144.605 54.696 145.319 54.22 145.613 53.562V53.646Z"
                fill="black"
            />
            <path
                d="M150.825 54.5V50.468C150.825 49.712 151.301 49.11 152.113 49.11C152.953 49.11 153.331 49.67 153.331 50.412V54.5H155.179V50.468C155.179 49.726 155.655 49.11 156.453 49.11C157.307 49.11 157.671 49.67 157.671 50.412V54.5H159.477V50.034C159.477 48.186 158.259 47.416 156.985 47.416C156.075 47.416 155.347 47.724 154.801 48.564C154.451 47.822 153.695 47.416 152.757 47.416C152.001 47.416 151.119 47.78 150.741 48.452V47.612H148.963V54.5H150.825Z"
                fill="black"
            />
            <path
                d="M162.821 57.16V53.828C163.157 54.29 163.857 54.668 164.809 54.668C166.755 54.668 168.057 53.128 168.057 51.042C168.057 48.998 166.895 47.458 164.879 47.458C163.843 47.458 163.073 47.92 162.765 48.452V47.612H160.959V57.16H162.821ZM166.223 51.056C166.223 52.288 165.467 53.002 164.515 53.002C163.563 53.002 162.793 52.274 162.793 51.056C162.793 49.838 163.563 49.124 164.515 49.124C165.467 49.124 166.223 49.838 166.223 51.056Z"
                fill="black"
            />
            <path
                d="M170.678 51.028C170.678 49.768 171.406 49.026 172.4 49.026C173.38 49.026 174.094 49.768 174.094 51.028C174.094 52.288 173.366 53.086 172.4 53.086C171.392 53.086 170.678 52.274 170.678 51.028ZM174.108 53.646C174.108 53.982 174.15 54.346 174.178 54.5H175.956C175.942 54.346 175.9 53.87 175.9 53.254V47.612H174.094V48.466C173.926 48.074 173.352 47.416 172.12 47.416C170.118 47.416 168.802 49.012 168.802 51.028C168.802 53.114 170.146 54.696 172.176 54.696C173.1 54.696 173.814 54.22 174.108 53.562V53.646Z"
                fill="black"
            />
            <path
                d="M179.32 54.5V47.612H177.458V54.5H179.32ZM177.234 45.358C177.234 45.974 177.752 46.492 178.382 46.492C179.026 46.492 179.53 45.974 179.53 45.358C179.53 44.714 179.026 44.196 178.382 44.196C177.752 44.196 177.234 44.714 177.234 45.358Z"
                fill="black"
            />
            <path
                d="M180.462 54.878C180.658 56.194 181.918 57.37 183.808 57.37C186.398 57.37 187.42 55.648 187.42 53.758V47.612H185.628V48.424C185.39 47.962 184.76 47.486 183.668 47.486C181.778 47.486 180.462 49.026 180.462 50.846C180.462 52.778 181.834 54.192 183.668 54.192C184.662 54.192 185.306 53.758 185.586 53.31V53.814C185.586 55.144 184.928 55.732 183.752 55.732C182.87 55.732 182.268 55.186 182.142 54.43L180.462 54.878ZM183.99 52.596C183.024 52.596 182.338 51.924 182.338 50.846C182.338 49.782 183.066 49.11 183.99 49.11C184.886 49.11 185.614 49.782 185.614 50.846C185.614 51.91 184.942 52.596 183.99 52.596Z"
                fill="black"
            />
            <path
                d="M190.829 50.538C190.829 49.74 191.305 49.11 192.117 49.11C193.013 49.11 193.391 49.712 193.391 50.482V54.5H195.253V50.16C195.253 48.648 194.469 47.43 192.761 47.43C192.019 47.43 191.193 47.752 190.773 48.466V47.612H188.967V54.5H190.829V50.538Z"
                fill="black"
            />
            <rect opacity="0.6" x="507" y="47" width="18" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter1_dd_1640_3946)">
                <rect x="532" y="38" width="56" height="22" rx="5" fill="white" />
                <path
                    d="M575 47.75L577.5 50.25L580 47.75"
                    stroke="black"
                    stroke-opacity="0.16"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <rect x="539" y="46" width="26" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter2_dd_1640_3946)">
                <rect x="92" y="74" width="496" height="36" rx="8" fill="white" />
            </g>
            <mask
                id="mask1_1640_3946"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="92"
                y="74"
                width="234"
                height="36"
            >
                <rect x="92" y="74" width="234" height="36" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask1_1640_3946)">
                <rect x="122" y="89" width="128" height="6" rx="3" fill="#ECECEF" />
                <path
                    d="M110 97C112.761 97 115 94.7614 115 92C115 89.2386 112.761 87 110 87C107.239 87 105 89.2386 105 92C105 94.7614 107.239 97 110 97ZM110 98C106.686 98 104 95.3137 104 92C104 88.6863 106.686 86 110 86C113.314 86 116 88.6863 116 92C116 95.3137 113.314 98 110 98Z"
                    fill="#006DFF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M110.064 94.6051C111.457 94.6051 112.171 93.6811 112.36 92.9181L111.457 92.6451C111.324 93.1071 110.911 93.6601 110.064 93.6601C109.266 93.6601 108.524 93.0791 108.524 92.0221C108.524 90.8951 109.308 90.3631 110.05 90.3631C110.911 90.3631 111.296 90.8881 111.415 91.3641L112.325 91.0771C112.129 90.2721 111.422 89.4321 110.05 89.4321C108.72 89.4321 107.523 90.4401 107.523 92.0221C107.523 93.6041 108.678 94.6051 110.064 94.6051Z"
                    fill="#006DFF"
                />
                <rect x="306" y="74" width="20" height="36" fill="url(#paint0_linear_1640_3946)" />
            </g>
            <rect opacity="0.6" x="323" y="90" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="341" y="89" width="30" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="391" y="90" width="14" height="4" rx="2" fill="#ECECEF" />
            <rect x="411" y="89" width="24" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="455" y="90" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="473" y="89" width="28" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="521" y="90" width="18" height="4" rx="2" fill="#ECECEF" />
            <path
                opacity="0.1"
                d="M554.501 92C554.501 92.591 554.384 93.1761 554.158 93.7221C553.932 94.268 553.6 94.7641 553.182 95.182C552.764 95.5999 552.268 95.9313 551.722 96.1575C551.176 96.3836 550.591 96.5 550 96.5C549.409 96.5 548.824 96.3836 548.278 96.1575C547.732 95.9313 547.236 95.5999 546.818 95.182C546.4 94.7641 546.069 94.268 545.843 93.7221C545.617 93.1761 545.501 92.591 545.501 92C545.501 91.4091 545.618 90.8239 545.844 90.2779C546.07 89.732 546.402 89.2359 546.82 88.818C547.238 88.4002 547.734 88.0687 548.28 87.8425C548.826 87.6164 549.411 87.5 550.002 87.5C550.593 87.5 551.178 87.6164 551.724 87.8425C552.27 88.0687 552.766 88.4002 553.184 88.818C553.601 89.2359 553.933 89.732 554.159 90.2779C554.385 90.8239 554.501 91.4091 554.501 92L554.501 92Z"
                stroke="#FF2828"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M550.002 87.5C550.832 87.5 551.645 87.7295 552.353 88.1631C553.06 88.5967 553.634 89.2176 554.011 89.957C554.387 90.6965 554.552 91.5257 554.487 92.3531C554.422 93.1804 554.129 93.9736 553.641 94.645"
                stroke="#FF2828"
                stroke-linecap="round"
            />
            <circle cx="573.502" cy="92" r="2.5" fill="#F0F0F5" />
            <circle cx="573.502" cy="92" r="2.5" fill="#006DFF" />
            <g filter="url(#filter3_dd_1640_3946)">
                <rect x="92" y="119" width="496" height="36" rx="8" fill="white" />
            </g>
            <mask
                id="mask2_1640_3946"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="92"
                y="119"
                width="234"
                height="36"
            >
                <rect x="92" y="119" width="234" height="36" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask2_1640_3946)">
                <rect x="122" y="134" width="118" height="6" rx="3" fill="#ECECEF" />
                <path
                    d="M110 142C112.761 142 115 139.761 115 137C115 134.239 112.761 132 110 132C107.239 132 105 134.239 105 137C105 139.761 107.239 142 110 142ZM110 143C106.686 143 104 140.314 104 137C104 133.686 106.686 131 110 131C113.314 131 116 133.686 116 137C116 140.314 113.314 143 110 143Z"
                    fill="#006DFF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M110.064 139.605C111.457 139.605 112.171 138.681 112.36 137.918L111.457 137.645C111.324 138.107 110.911 138.66 110.064 138.66C109.266 138.66 108.524 138.079 108.524 137.022C108.524 135.895 109.308 135.363 110.05 135.363C110.911 135.363 111.296 135.888 111.415 136.364L112.325 136.077C112.129 135.272 111.422 134.432 110.05 134.432C108.72 134.432 107.523 135.44 107.523 137.022C107.523 138.604 108.678 139.605 110.064 139.605Z"
                    fill="#006DFF"
                />
                <rect x="306" y="119" width="20" height="36" fill="url(#paint1_linear_1640_3946)" />
            </g>
            <rect opacity="0.6" x="323" y="135" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="341" y="134" width="30" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="391" y="135" width="14" height="4" rx="2" fill="#ECECEF" />
            <rect x="411" y="134" width="24" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="455" y="135" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="473" y="134" width="28" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="521" y="135" width="18" height="4" rx="2" fill="#ECECEF" />
            <path
                opacity="0.1"
                d="M554.501 137C554.501 137.591 554.384 138.176 554.158 138.722C553.932 139.268 553.6 139.764 553.182 140.182C552.764 140.6 552.268 140.931 551.722 141.157C551.176 141.384 550.591 141.5 550 141.5C549.409 141.5 548.824 141.384 548.278 141.157C547.732 140.931 547.236 140.6 546.818 140.182C546.4 139.764 546.069 139.268 545.843 138.722C545.617 138.176 545.501 137.591 545.501 137C545.501 136.409 545.618 135.824 545.844 135.278C546.07 134.732 546.402 134.236 546.82 133.818C547.238 133.4 547.734 133.069 548.28 132.843C548.826 132.616 549.411 132.5 550.002 132.5C550.593 132.5 551.178 132.616 551.724 132.843C552.27 133.069 552.766 133.4 553.184 133.818C553.601 134.236 553.933 134.732 554.159 135.278C554.385 135.824 554.501 136.409 554.501 137L554.501 137Z"
                stroke="#FF2828"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M550.002 132.5C550.832 132.5 551.645 132.729 552.353 133.163C553.06 133.597 553.634 134.218 554.011 134.957C554.387 135.696 554.552 136.526 554.487 137.353C554.422 138.18 554.129 138.974 553.641 139.645"
                stroke="#FF2828"
                stroke-linecap="round"
            />
            <circle cx="573.502" cy="137" r="2.5" fill="#F0F0F5" />
            <circle cx="573.502" cy="137" r="2.5" fill="#006DFF" />
            <g filter="url(#filter4_ddd_1640_3946)">
                <rect x="92" y="164" width="496" height="36" rx="8" fill="white" />
            </g>
            <mask
                id="mask3_1640_3946"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="92"
                y="164"
                width="234"
                height="36"
            >
                <rect x="92" y="164" width="234" height="36" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask3_1640_3946)">
                <rect x="122" y="179" width="134" height="6" rx="3" fill="#ECECEF" />
                <path
                    d="M110 187C112.761 187 115 184.761 115 182C115 179.239 112.761 177 110 177C107.239 177 105 179.239 105 182C105 184.761 107.239 187 110 187ZM110 188C106.686 188 104 185.314 104 182C104 178.686 106.686 176 110 176C113.314 176 116 178.686 116 182C116 185.314 113.314 188 110 188Z"
                    fill="#006DFF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M110.064 184.605C111.457 184.605 112.171 183.681 112.36 182.918L111.457 182.645C111.324 183.107 110.911 183.66 110.064 183.66C109.266 183.66 108.524 183.079 108.524 182.022C108.524 180.895 109.308 180.363 110.05 180.363C110.911 180.363 111.296 180.888 111.415 181.364L112.325 181.077C112.129 180.272 111.422 179.432 110.05 179.432C108.72 179.432 107.523 180.44 107.523 182.022C107.523 183.604 108.678 184.605 110.064 184.605Z"
                    fill="#006DFF"
                />
                <rect x="306" y="164" width="20" height="36" fill="url(#paint2_linear_1640_3946)" />
            </g>
            <rect opacity="0.6" x="323" y="180" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="341" y="179" width="30" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="391" y="180" width="14" height="4" rx="2" fill="#ECECEF" />
            <rect x="411" y="179" width="24" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="455" y="180" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="473" y="179" width="28" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="521" y="180" width="18" height="4" rx="2" fill="#ECECEF" />
            <path
                opacity="0.1"
                d="M554.501 182C554.501 182.591 554.384 183.176 554.158 183.722C553.932 184.268 553.6 184.764 553.182 185.182C552.764 185.6 552.268 185.931 551.722 186.157C551.176 186.384 550.591 186.5 550 186.5C549.409 186.5 548.824 186.384 548.278 186.157C547.732 185.931 547.236 185.6 546.818 185.182C546.4 184.764 546.069 184.268 545.843 183.722C545.617 183.176 545.501 182.591 545.501 182C545.501 181.409 545.618 180.824 545.844 180.278C546.07 179.732 546.402 179.236 546.82 178.818C547.238 178.4 547.734 178.069 548.28 177.843C548.826 177.616 549.411 177.5 550.002 177.5C550.593 177.5 551.178 177.616 551.724 177.843C552.27 178.069 552.766 178.4 553.184 178.818C553.601 179.236 553.933 179.732 554.159 180.278C554.385 180.824 554.501 181.409 554.501 182L554.501 182Z"
                stroke="#FF9500"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M550.002 177.5C550.832 177.5 551.645 177.729 552.353 178.163C553.06 178.597 553.634 179.218 554.011 179.957C554.387 180.696 554.552 181.526 554.487 182.353C554.422 183.18 554.129 183.974 553.641 184.645"
                stroke="#FF9500"
                stroke-linecap="round"
            />
            <circle cx="573.502" cy="182" r="2.5" fill="#F0F0F5" />
            <circle cx="573.502" cy="182" r="2.5" fill="#ECECEF" />
            <g filter="url(#filter5_dd_1640_3946)">
                <rect x="92" y="209" width="496" height="36" rx="8" fill="white" />
            </g>
            <mask
                id="mask4_1640_3946"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="92"
                y="209"
                width="234"
                height="36"
            >
                <rect x="92" y="209" width="234" height="36" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask4_1640_3946)">
                <rect x="122" y="224" width="112" height="6" rx="3" fill="#ECECEF" />
                <path
                    d="M110 232C112.761 232 115 229.761 115 227C115 224.239 112.761 222 110 222C107.239 222 105 224.239 105 227C105 229.761 107.239 232 110 232ZM110 233C106.686 233 104 230.314 104 227C104 223.686 106.686 221 110 221C113.314 221 116 223.686 116 227C116 230.314 113.314 233 110 233Z"
                    fill="#006DFF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M110.064 229.605C111.457 229.605 112.171 228.681 112.36 227.918L111.457 227.645C111.324 228.107 110.911 228.66 110.064 228.66C109.266 228.66 108.524 228.079 108.524 227.022C108.524 225.895 109.308 225.363 110.05 225.363C110.911 225.363 111.296 225.888 111.415 226.364L112.325 226.077C112.129 225.272 111.422 224.432 110.05 224.432C108.72 224.432 107.523 225.44 107.523 227.022C107.523 228.604 108.678 229.605 110.064 229.605Z"
                    fill="#006DFF"
                />
                <rect x="306" y="209" width="20" height="36" fill="url(#paint3_linear_1640_3946)" />
            </g>
            <rect opacity="0.6" x="323" y="225" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="341" y="224" width="30" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="391" y="225" width="14" height="4" rx="2" fill="#ECECEF" />
            <rect x="411" y="224" width="24" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="455" y="225" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="473" y="224" width="28" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="521" y="225" width="18" height="4" rx="2" fill="#ECECEF" />
            <path
                opacity="0.1"
                d="M554.501 227C554.501 227.591 554.384 228.176 554.158 228.722C553.932 229.268 553.6 229.764 553.182 230.182C552.764 230.6 552.268 230.931 551.722 231.157C551.176 231.384 550.591 231.5 550 231.5C549.409 231.5 548.824 231.384 548.278 231.157C547.732 230.931 547.236 230.6 546.818 230.182C546.4 229.764 546.069 229.268 545.843 228.722C545.617 228.176 545.501 227.591 545.501 227C545.501 226.409 545.618 225.824 545.844 225.278C546.07 224.732 546.402 224.236 546.82 223.818C547.238 223.4 547.734 223.069 548.28 222.843C548.826 222.616 549.411 222.5 550.002 222.5C550.593 222.5 551.178 222.616 551.724 222.843C552.27 223.069 552.766 223.4 553.184 223.818C553.601 224.236 553.933 224.732 554.159 225.278C554.385 225.824 554.501 226.409 554.501 227L554.501 227Z"
                stroke="#FF9500"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M550.002 222.5C550.832 222.5 551.645 222.729 552.353 223.163C553.06 223.597 553.634 224.218 554.011 224.957C554.387 225.696 554.552 226.526 554.487 227.353C554.422 228.18 554.129 228.974 553.641 229.645"
                stroke="#FF9500"
                stroke-linecap="round"
            />
            <circle cx="573.502" cy="227" r="2.5" fill="#F0F0F5" />
            <circle cx="573.502" cy="227" r="2.5" fill="#006DFF" />
            <g filter="url(#filter6_dd_1640_3946)">
                <rect x="92" y="254" width="496" height="36" rx="8" fill="white" />
            </g>
            <mask
                id="mask5_1640_3946"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="92"
                y="254"
                width="234"
                height="36"
            >
                <rect x="92" y="254" width="234" height="36" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask5_1640_3946)">
                <rect x="122" y="269" width="136" height="6" rx="3" fill="#ECECEF" />
                <path
                    d="M110 277C112.761 277 115 274.761 115 272C115 269.239 112.761 267 110 267C107.239 267 105 269.239 105 272C105 274.761 107.239 277 110 277ZM110 278C106.686 278 104 275.314 104 272C104 268.686 106.686 266 110 266C113.314 266 116 268.686 116 272C116 275.314 113.314 278 110 278Z"
                    fill="#006DFF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M110.064 274.605C111.457 274.605 112.171 273.681 112.36 272.918L111.457 272.645C111.324 273.107 110.911 273.66 110.064 273.66C109.266 273.66 108.524 273.079 108.524 272.022C108.524 270.895 109.308 270.363 110.05 270.363C110.911 270.363 111.296 270.888 111.415 271.364L112.325 271.077C112.129 270.272 111.422 269.432 110.05 269.432C108.72 269.432 107.523 270.44 107.523 272.022C107.523 273.604 108.678 274.605 110.064 274.605Z"
                    fill="#006DFF"
                />
                <rect x="306" y="254" width="20" height="36" fill="url(#paint4_linear_1640_3946)" />
            </g>
            <rect opacity="0.6" x="323" y="270" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="341" y="269" width="30" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="391" y="270" width="14" height="4" rx="2" fill="#ECECEF" />
            <rect x="411" y="269" width="24" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="455" y="270" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="473" y="269" width="28" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="521" y="270" width="18" height="4" rx="2" fill="#ECECEF" />
            <path
                opacity="0.1"
                d="M554.501 272C554.501 272.591 554.384 273.176 554.158 273.722C553.932 274.268 553.6 274.764 553.182 275.182C552.764 275.6 552.268 275.931 551.722 276.157C551.176 276.384 550.591 276.5 550 276.5C549.409 276.5 548.824 276.384 548.278 276.157C547.732 275.931 547.236 275.6 546.818 275.182C546.4 274.764 546.069 274.268 545.843 273.722C545.617 273.176 545.501 272.591 545.501 272C545.501 271.409 545.618 270.824 545.844 270.278C546.07 269.732 546.402 269.236 546.82 268.818C547.238 268.4 547.734 268.069 548.28 267.843C548.826 267.616 549.411 267.5 550.002 267.5C550.593 267.5 551.178 267.616 551.724 267.843C552.27 268.069 552.766 268.4 553.184 268.818C553.601 269.236 553.933 269.732 554.159 270.278C554.385 270.824 554.501 271.409 554.501 272L554.501 272Z"
                stroke="#00A861"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M550.002 267.5C550.832 267.5 551.645 267.729 552.353 268.163C553.06 268.597 553.634 269.218 554.011 269.957C554.387 270.696 554.552 271.526 554.487 272.353C554.422 273.18 554.129 273.974 553.641 274.645"
                stroke="#00A861"
                stroke-linecap="round"
            />
            <circle cx="573.502" cy="272" r="2.5" fill="#F0F0F5" />
            <circle cx="573.502" cy="272" r="2.5" fill="#ECECEF" />
            <g filter="url(#filter7_dd_1640_3946)">
                <rect x="92" y="299" width="496" height="36" rx="8" fill="white" />
            </g>
            <mask
                id="mask6_1640_3946"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="92"
                y="299"
                width="234"
                height="36"
            >
                <rect x="92" y="299" width="234" height="36" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask6_1640_3946)">
                <rect x="122" y="314" width="122" height="6" rx="3" fill="#ECECEF" />
                <path
                    d="M110 322C112.761 322 115 319.761 115 317C115 314.239 112.761 312 110 312C107.239 312 105 314.239 105 317C105 319.761 107.239 322 110 322ZM110 323C106.686 323 104 320.314 104 317C104 313.686 106.686 311 110 311C113.314 311 116 313.686 116 317C116 320.314 113.314 323 110 323Z"
                    fill="#006DFF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M110.064 319.605C111.457 319.605 112.171 318.681 112.36 317.918L111.457 317.645C111.324 318.107 110.911 318.66 110.064 318.66C109.266 318.66 108.524 318.079 108.524 317.022C108.524 315.895 109.308 315.363 110.05 315.363C110.911 315.363 111.296 315.888 111.415 316.364L112.325 316.077C112.129 315.272 111.422 314.432 110.05 314.432C108.72 314.432 107.523 315.44 107.523 317.022C107.523 318.604 108.678 319.605 110.064 319.605Z"
                    fill="#006DFF"
                />
                <rect x="306" y="299" width="20" height="36" fill="url(#paint5_linear_1640_3946)" />
            </g>
            <rect opacity="0.6" x="323" y="315" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="341" y="314" width="30" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="391" y="315" width="14" height="4" rx="2" fill="#ECECEF" />
            <rect x="411" y="314" width="24" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="455" y="315" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="473" y="314" width="28" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="521" y="315" width="18" height="4" rx="2" fill="#ECECEF" />
            <path
                opacity="0.1"
                d="M554.501 317C554.501 317.591 554.384 318.176 554.158 318.722C553.932 319.268 553.6 319.764 553.182 320.182C552.764 320.6 552.268 320.931 551.722 321.157C551.176 321.384 550.591 321.5 550 321.5C549.409 321.5 548.824 321.384 548.278 321.157C547.732 320.931 547.236 320.6 546.818 320.182C546.4 319.764 546.069 319.268 545.843 318.722C545.617 318.176 545.501 317.591 545.501 317C545.501 316.409 545.618 315.824 545.844 315.278C546.07 314.732 546.402 314.236 546.82 313.818C547.238 313.4 547.734 313.069 548.28 312.843C548.826 312.616 549.411 312.5 550.002 312.5C550.593 312.5 551.178 312.616 551.724 312.843C552.27 313.069 552.766 313.4 553.184 313.818C553.601 314.236 553.933 314.732 554.159 315.278C554.385 315.824 554.501 316.409 554.501 317L554.501 317Z"
                stroke="#00A861"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M550.002 312.5C550.832 312.5 551.645 312.729 552.353 313.163C553.06 313.597 553.634 314.218 554.011 314.957C554.387 315.696 554.552 316.526 554.487 317.353C554.422 318.18 554.129 318.974 553.641 319.645"
                stroke="#00A861"
                stroke-linecap="round"
            />
            <circle cx="573.502" cy="317" r="2.5" fill="#F0F0F5" />
            <circle cx="573.502" cy="317" r="2.5" fill="#ECECEF" />
            <g filter="url(#filter8_dd_1640_3946)">
                <rect x="92" y="344" width="496" height="36" rx="8" fill="white" />
            </g>
            <mask
                id="mask7_1640_3946"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="92"
                y="344"
                width="234"
                height="36"
            >
                <rect x="92" y="344" width="234" height="36" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask7_1640_3946)">
                <rect x="122" y="359" width="128" height="6" rx="3" fill="#ECECEF" />
                <path
                    d="M110 367C112.761 367 115 364.761 115 362C115 359.239 112.761 357 110 357C107.239 357 105 359.239 105 362C105 364.761 107.239 367 110 367ZM110 368C106.686 368 104 365.314 104 362C104 358.686 106.686 356 110 356C113.314 356 116 358.686 116 362C116 365.314 113.314 368 110 368Z"
                    fill="#006DFF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M110.064 364.605C111.457 364.605 112.171 363.681 112.36 362.918L111.457 362.645C111.324 363.107 110.911 363.66 110.064 363.66C109.266 363.66 108.524 363.079 108.524 362.022C108.524 360.895 109.308 360.363 110.05 360.363C110.911 360.363 111.296 360.888 111.415 361.364L112.325 361.077C112.129 360.272 111.422 359.432 110.05 359.432C108.72 359.432 107.523 360.44 107.523 362.022C107.523 363.604 108.678 364.605 110.064 364.605Z"
                    fill="#006DFF"
                />
                <rect x="306" y="344" width="20" height="36" fill="url(#paint6_linear_1640_3946)" />
            </g>
            <rect opacity="0.6" x="323" y="360" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="341" y="359" width="30" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="391" y="360" width="14" height="4" rx="2" fill="#ECECEF" />
            <rect x="411" y="359" width="24" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="455" y="360" width="12" height="4" rx="2" fill="#ECECEF" />
            <rect x="473" y="359" width="28" height="6" rx="3" fill="#ECECEF" />
            <rect opacity="0.6" x="521" y="360" width="18" height="4" rx="2" fill="#ECECEF" />
            <path
                opacity="0.1"
                d="M554.501 362C554.501 362.591 554.384 363.176 554.158 363.722C553.932 364.268 553.6 364.764 553.182 365.182C552.764 365.6 552.268 365.931 551.722 366.157C551.176 366.384 550.591 366.5 550 366.5C549.409 366.5 548.824 366.384 548.278 366.157C547.732 365.931 547.236 365.6 546.818 365.182C546.4 364.764 546.069 364.268 545.843 363.722C545.617 363.176 545.501 362.591 545.501 362C545.501 361.409 545.618 360.824 545.844 360.278C546.07 359.732 546.402 359.236 546.82 358.818C547.238 358.4 547.734 358.069 548.28 357.843C548.826 357.616 549.411 357.5 550.002 357.5C550.593 357.5 551.178 357.616 551.724 357.843C552.27 358.069 552.766 358.4 553.184 358.818C553.601 359.236 553.933 359.732 554.159 360.278C554.385 360.824 554.501 361.409 554.501 362L554.501 362Z"
                stroke="#FF2828"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M550.002 357.5C550.832 357.5 551.645 357.729 552.353 358.163C553.06 358.597 553.634 359.218 554.011 359.957C554.387 360.696 554.552 361.526 554.487 362.353C554.422 363.18 554.129 363.974 553.641 364.645"
                stroke="#FF2828"
                stroke-linecap="round"
            />
            <circle cx="573.502" cy="362" r="2.5" fill="#F0F0F5" />
            <circle cx="573.502" cy="362" r="2.5" fill="#006DFF" />
            <g filter="url(#filter9_dd_1640_3946)">
                <rect x="92" y="389" width="496" height="36" rx="8" fill="white" />
            </g>
            <mask
                id="mask8_1640_3946"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="92"
                y="389"
                width="234"
                height="36"
            >
                <rect x="92" y="389" width="234" height="36" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask8_1640_3946)">
                <rect x="306" y="389" width="20" height="36" fill="url(#paint7_linear_1640_3946)" />
            </g>
            <g filter="url(#filter10_dd_1640_3946)">
                <rect x="12" y="342" width="656" height="40" rx="8" fill="white" />
            </g>
            <circle cx="33" cy="362" r="9" fill="#6E3AFF" />
            <rect x="50" y="359" width="48" height="6" rx="3" fill="#6E3AFF" />
            <g filter="url(#filter11_dd_1640_3946)">
                <rect x="538" y="351" width="40" height="22" rx="11" fill="white" />
            </g>
            <g opacity="0.2">
                <g filter="url(#filter12_d_1640_3946)">
                    <rect x="584" y="351" width="72" height="22" rx="11" fill="#006DFF" />
                </g>
            </g>
        </g>
        <defs>
            <filter
                id="filter0_dd_1640_3946"
                x="71"
                y="23"
                width="538"
                height="498"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_dd_1640_3946"
                x="531"
                y="37"
                width="58"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_dd_1640_3946"
                x="91"
                y="73"
                width="498"
                height="39"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_dd_1640_3946"
                x="91"
                y="118"
                width="498"
                height="39"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter4_ddd_1640_3946"
                x="91"
                y="163"
                width="498"
                height="39"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend
                    mode="normal"
                    in2="effect2_dropShadow_1640_3946"
                    result="effect3_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect3_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter5_dd_1640_3946"
                x="91"
                y="208"
                width="498"
                height="39"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter6_dd_1640_3946"
                x="91"
                y="253"
                width="498"
                height="39"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter7_dd_1640_3946"
                x="91"
                y="298"
                width="498"
                height="39"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter8_dd_1640_3946"
                x="91"
                y="343"
                width="498"
                height="39"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter9_dd_1640_3946"
                x="91"
                y="388"
                width="498"
                height="39"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter10_dd_1640_3946"
                x="4"
                y="338"
                width="672"
                height="56"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="4"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="6" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter11_dd_1640_3946"
                x="537"
                y="350"
                width="42"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1640_3946"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1640_3946"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1640_3946"
                    result="effect2_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <filter
                id="filter12_d_1640_3946"
                x="583"
                y="350.5"
                width="74"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1640_3946"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1640_3946"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_1640_3946"
                x1="326"
                y1="92"
                x2="306"
                y2="92"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1640_3946"
                x1="326"
                y1="137"
                x2="306"
                y2="137"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_1640_3946"
                x1="326"
                y1="182"
                x2="306"
                y2="182"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_1640_3946"
                x1="326"
                y1="227"
                x2="306"
                y2="227"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_1640_3946"
                x1="326"
                y1="272"
                x2="306"
                y2="272"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_1640_3946"
                x1="326"
                y1="317"
                x2="306"
                y2="317"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_1640_3946"
                x1="326"
                y1="362"
                x2="306"
                y2="362"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_1640_3946"
                x1="326"
                y1="407"
                x2="306"
                y2="407"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>
