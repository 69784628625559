import { Account } from '@opteo/types'

type LanguageCode = keyof typeof languages
export type SnippetHeader = keyof typeof snippetHeaders.en

interface DomainLanguageSettings {
    [accountId: Account.ID]: LanguageCode
}

export const snippetHeaders = {
    bg: {
        Brands: 'Марки',
        Amenities: 'Удобства',
        Styles: 'Стилове',
        Types: 'Типове',
        Destinations: 'Дестинации',
        'Service catalog': 'Услуги',
        Courses: 'Курсове',
        Neighborhoods: 'Квартали',
        Shows: 'Предавания',
        'Insurance coverage': 'Застраховка',
        'Degree programs': 'Специалности',
        'Featured hotels': 'Представени хотели',
        Models: 'Модели',
    },
    ca: {
        Brands: 'Marques',
        Amenities: 'Serveis addicionals',
        Styles: 'Estils',
        Types: 'Tipus',
        Destinations: 'Destinacions',
        'Service catalog': 'Serveis',
        Courses: 'Cursos',
        Neighborhoods: 'Barris',
        Shows: 'Programes',
        'Insurance coverage': 'Assegurança',
        'Degree programs': 'Titulacions',
        'Featured hotels': 'Hotels destacats',
        Models: 'Models',
    },
    hr: {
        Brands: 'Robne marke',
        Amenities: 'Sadržaji',
        Styles: 'Stilovi',
        Types: 'Vrste',
        Destinations: 'Odredišta',
        'Service catalog': 'Usluge',
        Courses: 'Tečajevi',
        Neighborhoods: 'Četvrti',
        Shows: 'Emisije',
        'Insurance coverage': 'Osiguranje',
        'Degree programs': 'Studijski programi',
        'Featured hotels': 'Istaknuti hoteli',
        Models: 'Modeli',
    },
    cs: {
        Brands: 'Značky',
        Amenities: 'Vybavení',
        Styles: 'Styly',
        Types: 'Typy',
        Destinations: 'Cíle',
        'Service catalog': 'Služby',
        Courses: 'Kurzy',
        Neighborhoods: 'Známé oblasti',
        Shows: 'Pořady',
        'Insurance coverage': 'Pojištění',
        'Degree programs': 'Studijní programy',
        'Featured hotels': 'Vybrané hotely',
        Models: 'Modely',
    },
    da: {
        Brands: 'Brands',
        Amenities: 'Faciliteter',
        Styles: 'Design',
        Types: 'Typer',
        Destinations: 'Destinationer',
        'Service catalog': 'Tjenester',
        Courses: 'Kurser',
        Neighborhoods: 'Nabolag',
        Shows: 'Serier',
        'Insurance coverage': 'Forsikringsdækning',
        'Degree programs': 'Uddannelser',
        'Featured hotels': 'Udvalgte hoteller',
        Models: 'Modeller',
    },
    nl: {
        Brands: 'Merken',
        Amenities: 'Voorzieningen',
        Styles: 'Stijlen',
        Types: 'Typen',
        Destinations: 'Bestemmingen',
        'Service catalog': 'Services',
        Courses: 'Cursussen',
        Neighborhoods: 'Buurten',
        Shows: 'Shows',
        'Insurance coverage': 'Dekking',
        'Degree programs': "Studieprogramma's",
        'Featured hotels': 'Aanbevolen hotels',
        Models: 'Modellen',
    },
    en: {
        Brands: 'Brands',
        Amenities: 'Amenities',
        Styles: 'Styles',
        Types: 'Types',
        Destinations: 'Destinations',
        'Service catalog': 'Services',
        Courses: 'Courses',
        Neighborhoods: 'Neighbourhoods',
        Shows: 'Shows',
        'Insurance coverage': 'Insurance coverage',
        'Degree programs': 'Degree programmes',
        'Featured hotels': 'Featured hotels',
        Models: 'Models',
    },
    et: {
        Brands: 'Brändid',
        Amenities: 'Mugavusteenused',
        Styles: 'Stiilid',
        Types: 'Tüübid',
        Destinations: 'Sihtkohad',
        'Service catalog': 'Teenused',
        Courses: 'Kursused',
        Neighborhoods: 'Ümbruskonnad',
        Shows: 'Saated ja etendused',
        'Insurance coverage': 'Kindlustuskaitse',
        'Degree programs': 'Kraadiõppeprogrammid',
        'Featured hotels': 'Soovitatud hotellid',
        Models: 'Mudelid',
    },
    fil: {
        Brands: 'Mga Brand',
        Amenities: 'Mga Amenity',
        Styles: 'Mga Istilo',
        Types: 'Mga Uri',
        Destinations: 'Mga Destinasyon',
        'Service catalog': 'Mga Serbisyo',
        Courses: 'Mga Kurso',
        Neighborhoods: 'Mga Komunidad',
        Shows: 'Mga Palabas',
        'Insurance coverage': 'Sakop ng insurance',
        'Degree programs': 'Mga degree program',
        'Featured hotels': 'Itinatampok na hotel',
        Models: 'Mga Modelo',
    },
    fi: {
        Brands: 'Brändit',
        Amenities: 'Lisäpalvelut',
        Styles: 'Tyylit',
        Types: 'Tyypit',
        Destinations: 'Kohteet',
        'Service catalog': 'Palvelut',
        Courses: 'Kurssit',
        Neighborhoods: 'Kaupunginosat',
        Shows: "Show't",
        'Insurance coverage': 'Vakuutukset',
        'Degree programs': 'Oppiaineet',
        'Featured hotels': 'Suositellut hotellit',
        Models: 'Automerkit',
    },
    de: {
        Brands: 'Marken',
        Amenities: 'Ausstattung',
        Styles: 'Stile',
        Types: 'Typen',
        Destinations: 'Ziele',
        'Service catalog': 'Dienstleistungen',
        Courses: 'Kurse',
        Neighborhoods: 'Viertel',
        Shows: 'Serien',
        'Insurance coverage': 'Versicherungsleistung',
        'Degree programs': 'Studiengänge',
        'Featured hotels': 'Vorgestellte Hotels',
        Models: 'Modelle',
    },
    el: {
        Brands: 'Επωνυμίες',
        Amenities: 'Παροχές',
        Styles: 'Στυλ',
        Types: 'Τύποι',
        Destinations: 'Προορισμοί',
        'Service catalog': 'Υπηρεσίες',
        Courses: 'Μαθήματα',
        Neighborhoods: 'Γειτονιές',
        Shows: 'Εκπομπές',
        'Insurance coverage': 'Ασφαλιστική κάλυψη',
        'Degree programs': 'Προγράμματα πτυχίων',
        'Featured hotels': 'Ξενοδοχεία',
        Models: 'Μοντέλα',
    },
    hu: {
        Brands: 'Márkák',
        Amenities: 'Szolgáltatások',
        Styles: 'Stílusok',
        Types: 'Típusok',
        Destinations: 'Úti célok',
        'Service catalog': 'Szolgáltatások',
        Courses: 'Tanfolyamok',
        Neighborhoods: 'Városrészek',
        Shows: 'Műsorok',
        'Insurance coverage': 'Biztosítási módozat',
        'Degree programs': 'Diplomaprogramok',
        'Featured hotels': 'Kiemelt szállodák',
        Models: 'Modellek',
    },
    ja: {
        Brands: 'ブランド',
        Amenities: '設備',
        Styles: 'スタイル',
        Types: 'タイプ',
        Destinations: '到着地',
        'Service catalog': 'サービス',
        Courses: 'コース',
        Neighborhoods: '周辺地域',
        Shows: '番組',
        'Insurance coverage': '保険の保障',
        'Degree programs': '学位プログラム',
        'Featured hotels': 'おすすめのホテル',
        Models: 'モデル',
    },
    ko: {
        Brands: '브랜드',
        Amenities: '편의 시설',
        Styles: '스타일',
        Types: '유형',
        Destinations: '목적지',
        'Service catalog': '서비스',
        Courses: '과정',
        Neighborhoods: '인근 지역',
        Shows: '프로그램',
        'Insurance coverage': '보험 보상 범위',
        'Degree programs': '학위 취득 프로그램',
        'Featured hotels': '추천 호텔',
        Models: '모델',
    },
    lv: {
        Brands: 'Zīmoli',
        Amenities: 'Ērtības',
        Styles: 'Stili',
        Types: 'Veidi',
        Destinations: 'Galamērķi',
        'Service catalog': 'Pakalpojumi',
        Courses: 'Kursi',
        Neighborhoods: 'Apkaimes',
        Shows: 'Pārraides',
        'Insurance coverage': 'Apdrošināšana',
        'Degree programs': 'Studiju programmas',
        'Featured hotels': 'Piedāvātās viesnīcas',
        Models: 'Modeļi',
    },
    lt: {
        Brands: 'Prekių ženklai',
        Amenities: 'Patogumai',
        Styles: 'Stiliai',
        Types: 'Tipai',
        Destinations: 'Paskirties vietos',
        'Service catalog': 'Paslaugos',
        Courses: 'Kursai',
        Neighborhoods: 'Namų apylinkės',
        Shows: 'Laidos',
        'Insurance coverage': 'Draudimo aprėptis',
        'Degree programs': 'Moksliniai laipsniai',
        'Featured hotels': 'Siūlomi viešbučiai',
        Models: 'Modeliai',
    },
    no: {
        Brands: 'Merkevarer',
        Amenities: 'Fasiliteter',
        Styles: 'Stiler',
        Types: 'Typer',
        Destinations: 'Destinasjoner',
        'Service catalog': 'Tjenester',
        Courses: 'Kurs',
        Neighborhoods: 'Lokalområder',
        Shows: 'Programmer',
        'Insurance coverage': 'Forsikringsdekning',
        'Degree programs': 'Utdanningsprogrammer',
        'Featured hotels': 'Utvalgte hoteller',
        Models: 'Modeller',
    },
    pt: {
        Brands: 'Marcas',
        Amenities: 'Comodidades',
        Styles: 'Estilos',
        Types: 'Tipos',
        Destinations: 'Destinos',
        'Service catalog': 'Serviços',
        Courses: 'Cursos',
        Neighborhoods: 'Arredores',
        Shows: 'Programas',
        'Insurance coverage': 'Cobertura do seguro',
        'Degree programs': 'Licenciaturas',
        'Featured hotels': 'Hotéis em destaque',
        Models: 'Modelos',
    },
    ro: {
        Brands: 'Mărci',
        Amenities: 'Dotări',
        Styles: 'Stiluri',
        Types: 'Tipuri',
        Destinations: 'Destinații',
        'Service catalog': 'Servicii',
        Courses: 'Cursuri',
        Neighborhoods: 'Cartiere',
        Shows: 'Emisiuni',
        'Insurance coverage': 'Asigurare',
        'Degree programs': 'Programe de studiu',
        'Featured hotels': 'Hoteluri prezentate',
        Models: 'Modele',
    },
    sr: {
        Brands: 'брендови',
        Amenities: 'садржаји',
        Styles: 'стилови',
        Types: 'типови',
        Destinations: 'дестинације',
        'Service catalog': 'услуге',
        Courses: 'курсеви',
        Neighborhoods: 'делови града',
        Shows: 'емисије',
        'Insurance coverage': 'осигурање',
        'Degree programs': 'дипломе',
        'Featured hotels': 'истакнути хотели',
        Models: 'модели',
    },
    sk: {
        Brands: 'Značky',
        Amenities: 'Vybavenie',
        Styles: 'Štýly',
        Types: 'Typy',
        Destinations: 'Destinácie',
        'Service catalog': 'Služby',
        Courses: 'Kurzy',
        Neighborhoods: 'Štvrte',
        Shows: 'Relácie',
        'Insurance coverage': 'Poistenie',
        'Degree programs': 'Vzdelávacie programy',
        'Featured hotels': 'Odporúčané hotely',
        Models: 'Modely',
    },
    sl: {
        Brands: 'Blagovne znamke',
        Amenities: 'Ponudba',
        Styles: 'Slogi',
        Types: 'Vrste',
        Destinations: 'Cilji',
        'Service catalog': 'Storitve',
        Courses: 'Tečaji',
        Neighborhoods: 'Soseske',
        Shows: 'Oddaje',
        'Insurance coverage': 'Kritje zavarovanj',
        'Degree programs': 'Študijski programi',
        'Featured hotels': 'Predstavljeni hoteli',
        Models: 'Modeli',
    },
    esl: {
        Brands: 'Marcas',
        Amenities: 'Servicios adicionales',
        Styles: 'Estilos',
        Types: 'Tipos',
        Destinations: 'Destinos',
        'Service catalog': 'Servicios',
        Courses: 'Cursos',
        Neighborhoods: 'Barrios',
        Shows: 'Programas',
        'Insurance coverage': 'Cobertura de seguro',
        'Degree programs': 'Carreras universitarias',
        'Featured hotels': 'Hoteles destacados',
        Models: 'Modelos',
    },
    es: {
        Brands: 'Marcas',
        Amenities: 'Servicios adicionales',
        Styles: 'Estilos',
        Types: 'Tipos',
        Destinations: 'Destinos',
        'Service catalog': 'Servicios',
        Courses: 'Cursos',
        Neighborhoods: 'Barrios',
        Shows: 'Espectáculos',
        'Insurance coverage': 'Cobertura de seguro',
        'Degree programs': 'Carreras universitarias',
        'Featured hotels': 'Hoteles destacados',
        Models: 'Modelos',
    },
    th: {
        Brands: 'แบรนด์',
        Amenities: 'สิ่งอำนวยความสะดวก',
        Styles: 'สไตล์',
        Types: 'ประเภท',
        Destinations: 'ปลายทาง',
        'Service catalog': 'บริการ',
        Courses: 'หลักสูตร',
        Neighborhoods: 'ย่านใกล้เคียง',
        Shows: 'โชว์',
        'Insurance coverage': 'ความคุ้มครองในประกัน',
        'Degree programs': 'หลักสูตรปริญญา',
        'Featured hotels': 'โรงแรมเด่น',
        Models: 'รุ่น',
    },
    tr: {
        Brands: 'Markalar',
        Amenities: 'Sunulan olanaklar',
        Styles: 'Stiller',
        Types: 'Türler',
        Destinations: 'destinasyonlar',
        'Service catalog': 'Hizmetler',
        Courses: 'Kurslar',
        Neighborhoods: 'Mahalleler',
        Shows: 'Programlar',
        'Insurance coverage': 'Sigorta kapsamı',
        'Degree programs': 'Lisans programları',
        'Featured hotels': 'Öne çıkan oteller',
        Models: 'Modeller',
    },
    uk: {
        Brands: 'Бренди',
        Amenities: 'Зручності',
        Styles: 'Стилі',
        Types: 'Типи',
        Destinations: 'Місця',
        'Service catalog': 'Послуги',
        Courses: 'Курси',
        Neighborhoods: 'Квартали',
        Shows: 'Шоу',
        'Insurance coverage': 'Страховий захист',
        'Degree programs': 'Освітні програми',
        'Featured hotels': 'Рекомендовані готелі',
        Models: 'Моделі',
    },
    vi: {
        Brands: 'Thương hiệu',
        Amenities: 'Tiện nghi',
        Styles: 'Kiểu',
        Types: 'Loại',
        Destinations: 'Điểm đến',
        'Service catalog': 'Dịch vụ',
        Courses: 'Khóa học',
        Neighborhoods: 'Vùng lân cận',
        Shows: 'Chương trình',
        'Insurance coverage': 'Phạm vi bảo hiểm',
        'Degree programs': 'Cấp bằng',
        'Featured hotels': 'Khách sạn nổi bật',
        Models: 'Model',
    },
    hi: {
        Brands: 'ब्रैंड',
        Amenities: 'सुविधाएं',
        Styles: 'शैलियां',
        Types: 'प्रकार',
        Destinations: 'गंतव्य',
        'Service catalog': 'सेवाएं',
        Courses: 'पाठ्‍यक्रम',
        Neighborhoods: 'आस-पड़ोस',
        Shows: 'कार्यक्रम',
        'Insurance coverage': 'बीमा कवरेज',
        'Degree programs': 'डिग्री कार्यक्रम',
        'Featured hotels': 'प्रदर्शित होटल',
        Models: 'मॉडल',
    },
    ms: {
        Brands: 'Jenama',
        Amenities: 'Kemudahan',
        Styles: 'Gaya',
        Types: 'Jenis',
        Destinations: 'Destinasi',
        'Service catalog': 'Perkhidmatan',
        Courses: 'Kursus',
        Neighborhoods: 'Kejiranan',
        Shows: 'Rancangan',
        'Insurance coverage': 'Liputan insurans',
        'Degree programs': 'Program ijazah',
        'Featured hotels': 'Hotel utama',
        Models: 'Model',
    },
    fr: {
        Brands: 'Marques',
        Amenities: 'Équipements',
        Styles: 'Styles',
        Types: 'Types',
        Destinations: 'Destinations',
        'Service catalog': 'Services',
        Courses: 'Cours',
        Neighborhoods: 'Quartiers',
        Shows: 'Émissions',
        'Insurance coverage': "Couverture d'assurance",
        'Degree programs': "Programmes d'études",
        'Featured hotels': "Sélection d'hôtels",
        Models: 'Modèles',
    },
    id: {
        Brands: 'Merek',
        Amenities: 'Fasilitas',
        Styles: 'Ragam',
        Types: 'Jenis',
        Destinations: 'Destinasi',
        'Service catalog': 'Layanan',
        Courses: 'Kelas',
        Neighborhoods: 'Kawasan',
        Shows: 'Acara',
        'Insurance coverage': 'Cakupan asuransi',
        'Degree programs': 'Program sarjana',
        'Featured hotels': 'Hotel pilihan',
        Models: 'Model',
    },
    pl: {
        Brands: 'Marki',
        Amenities: 'Udogodnienia',
        Styles: 'Style',
        Types: 'Typy',
        Destinations: 'Miejsca docelowe',
        'Service catalog': 'Usługi',
        Courses: 'Kursy',
        Neighborhoods: 'Okolice',
        Shows: 'Programy',
        'Insurance coverage': 'Ubezpieczenia',
        'Degree programs': 'Kierunki studiów',
        'Featured hotels': 'Polecane hotele',
        Models: 'Modele',
    },
    ptb: {
        Brands: 'Marcas',
        Amenities: 'Comodidades',
        Styles: 'Estilos',
        Types: 'Tipos',
        Destinations: 'Destinos',
        'Service catalog': 'Serviços',
        Courses: 'Cursos',
        Neighborhoods: 'Arredores',
        Shows: 'Programas',
        'Insurance coverage': 'Cobertura do seguro',
        'Degree programs': 'Programas de graduação',
        'Featured hotels': 'Hotéis em destaque',
        Models: 'Modelos',
    },
    ru: {
        Brands: 'Бренды',
        Amenities: 'Удобства',
        Styles: 'Стили',
        Types: 'Типы',
        Destinations: 'Места',
        'Service catalog': 'Услуги',
        Courses: 'Курсы',
        Neighborhoods: 'Кварталы',
        Shows: 'Шоу',
        'Insurance coverage': 'Страховая защита',
        'Degree programs': 'Программы обучения',
        'Featured hotels': 'Рекомендуемые отели',
        Models: 'Модели',
    },
    sv: {
        Brands: 'Varumärken',
        Amenities: 'Faciliteter',
        Styles: 'Stilar',
        Types: 'Typer',
        Destinations: 'Resmål',
        'Service catalog': 'Tjänster',
        Courses: 'Kurser',
        Neighborhoods: 'Grannskap',
        Shows: 'Program',
        'Insurance coverage': 'Försäkringstyper',
        'Degree programs': 'Utbildningar',
        'Featured hotels': 'Hotellval',
        Models: 'Modeller',
    },
    it: {
        Brands: 'Marche',
        Amenities: 'Servizi',
        Styles: 'Stili',
        Types: 'Tipi',
        Destinations: 'Destinazioni',
        'Service catalog': 'Servizi',
        Courses: 'Corsi',
        Neighborhoods: 'Quartieri',
        Shows: 'Programmi',
        'Insurance coverage': 'Copertura assicurativa',
        'Degree programs': 'Corsi di studio',
        'Featured hotels': 'Hotel consigliati',
        Models: 'Modelli',
    },
}

export const languages = {
    bg: 'Bulgarian',
    ca: 'Catalan',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    nl: 'Dutch',
    en: 'English',
    et: 'Estonian',
    fil: 'Filipino',
    fi: 'Finnish',
    fr: 'French',
    de: 'German',
    el: 'Greek',
    hi: 'Hindi',
    hu: 'Hungarian',
    id: 'Indonesian',
    it: 'Italian',
    ja: 'Japanese',
    ko: 'Korean',
    lv: 'Latvian',
    lt: 'Lithuanian',
    ms: 'Malay',
    no: 'Norwegian',
    pl: 'Polish',
    pt: 'Portuguese',
    ptb: 'Portuguese (Brazil)',
    ro: 'Romanian',
    ru: 'Russian',
    sr: 'Serbian',
    sk: 'Slovak',
    sl: 'Slovenian',
    esl: 'Spanish (Latin America)',
    es: 'Spanish (Spain)',
    sv: 'Swedish',
    th: 'Thai',
    tr: 'Turkish',
    uk: 'Ukrainian',
    vi: 'Vietnamese',
}

function getDomainLanguageSettings(): DomainLanguageSettings {
    const DLS = localStorage.getItem('domainLanguageSetting')
    return DLS ? JSON.parse(DLS) : {}
}

export function getDomainLanguageCode(accountId: Account.ID): LanguageCode {
    if (!accountId) {
        throw new Error('Missing accound id.')
    }

    const DLS = getDomainLanguageSettings()
    return DLS[accountId] ?? 'en'
}

export function setDomainLanguageCode({
    accountId,
    languageCode,
}: {
    accountId: Account.ID
    languageCode: LanguageCode
}): void {
    if (!accountId) {
        throw new Error('Missing account id.')
    }

    const DLS = getDomainLanguageSettings()
    DLS[accountId] = languageCode
    localStorage.setItem('domainLanguageSetting', JSON.stringify(DLS))
}
