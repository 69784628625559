import { noAuthRequest } from '@/composition/api/useAPI'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

interface ResetPasswordSubmitResponse {
    data: string
}

export function useResetPasswordLink() {
    const route = useRoute()

    const { email, token } = route.params

    const newPassword = ref()
    const confirmNewPassword = ref()

    const updatePasswordForm = ref()
    const newPasswordInput = ref()

    const showForm = ref(true)

    const invalidToken = ref(false)

    function newPasswordValidator(value: string) {
        if (value.length < 8 || !/\d/.test(value)) {
            return 'Please choose a password with at least 6 characters including a number.'
        }
    }

    function confirmNewPasswordValidator(value: string) {
        if (newPassword.value !== value) {
            return 'Passwords must match.'
        }
    }

    const loading = ref(false)

    async function resetPassword() {
        if (!updatePasswordForm.value?.valid) {
            return
        }

        loading.value = true

        let response

        try {
            const { data } = await noAuthRequest<ResetPasswordSubmitResponse>(
                '/api/auth/resetPasswordSubmit',
                {
                    meta: {
                        function: 'auth:resetPasswordSubmit',
                        args: {
                            username: email,
                            password: confirmNewPassword.value,
                            reset_hash: token,
                        },
                    },
                }
            )

            response = data
        } catch {}

        loading.value = false

        if (!response) {
            newPasswordInput.value.setError('Something went wrong please contact support')
            return
        }

        if (response === 'invalid password reset token') {
            invalidToken.value = true
            showForm.value = false
            return
        }

        showForm.value = false
    }

    return {
        newPassword,
        confirmNewPassword,
        newPasswordInput,
        resetPassword,
        newPasswordValidator,
        confirmNewPasswordValidator,
        showForm,
        updatePasswordForm,
        loading,
        email,
        invalidToken,
    }
}
