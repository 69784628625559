import { computed, ref, onBeforeUpdate } from 'vue'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { AddCalloutExtension, Improvement } from '@opteo/types'
import { delay, getNumberWord } from '@/lib/globalUtils'

type FormElement = HTMLDivElement & { submit: () => void; valid: boolean }

interface HighestClickedAd {
    headlineOne: string
    headlineTwo: string
    displayUrl: string
    descriptionOne: string
    callouts: string[]
}

interface AdTitleTag {
    title: string
    classes: string
}

interface CalloutExtension {
    calloutText: string
    feedItemId: string
    feedId: string
    mutate: boolean
}

interface CalloutExtensionExample {
    title: string
    callouts: string[]
}

const MINIMUM_CALLOUT_EXTENSIONS = 4
const MAXIMUM_CALLOUT_EXTENSIONS = 20
const MAXIMUM_CALLOUT_LENGTH = 25
// limit of 10 listed here:
// https://support.google.com/google-ads/answer/6079510?hl=en-GB

export function useAddCalloutExtension() {
    const { improvement, lastUpdated, title } = useImprovement<AddCalloutExtension.Body>()

    const {
        body: {
            grey_adgroups_campaign_name_uniq: campaignNames,
            highest_click_ad_data: {
                headline1: headlineOne,
                headline2: headlineTwo,
                display_url: displayUrl,
                description: descriptionOne,
            },
            callouts,
        },
    } = checkImprovement(improvement)

    const hasExistingCallouts = callouts.some(c => c.mutate)
    const missingCalloutCount = MINIMUM_CALLOUT_EXTENSIONS - callouts.filter(c => c.mutate).length

    const calloutExtensions = ref<CalloutExtension[]>([])

    const inputRefs = ref<{ inputRef: HTMLInputElement }[]>([])

    async function resetCalloutExtensions(clear: boolean) {
        calloutExtensions.value = callouts.map(callout => {
            return {
                calloutText: clear ? '' : callout.callout_text,
                feedItemId: callout.feed_item_id,
                feedId: callout.feed_item_id,
                mutate: callout.mutate,
            }
        })
        await delay(1)
        if (inputRefs.value[0]) {
            inputRefs.value[0].inputRef.focus()
        }
    }

    async function addCalloutExtensions() {
        if (calloutExtensions.value.length >= MAXIMUM_CALLOUT_EXTENSIONS) {
            return
        }
        calloutExtensions.value = [
            ...calloutExtensions.value,
            {
                calloutText: '',
                feedItemId: '',
                feedId: '',
                mutate: false,
            },
        ]
        await delay(1)

        inputRefs.value[calloutExtensions.value.length - 1].inputRef.focus()
    }

    resetCalloutExtensions(false)

    const entityPillList: Improvement.EntityPill[] = campaignNames.map(campaignName => {
        return {
            type: Improvement.LocationEntity.Campaign,
            content: campaignName,
        }
    })

    const calloutExtensionExamples: CalloutExtensionExample[] = [
        {
            title: 'Car Insurance',
            callouts: [
                'Switch and save £480',
                'Multi-policy discounts',
                '24/7 customer service',
                'Free online quotes',
            ],
        },
        {
            title: 'Running Shoes',
            callouts: [
                'Trail, Track and Road',
                'Nike, Adidas and more',
                'Free Returns',
                'Free Shipping',
                '90-Day Guarantee',
            ],
        },
        {
            title: 'Hotel Bookings',
            callouts: [
                'Best Price Guarantee',
                '500,000+ Hotels Worldwide',
                'No booking fees',
                'Get Instant Confirmation',
                'Official Partner of UCL',
            ],
        },
        {
            title: 'Dentist',
            callouts: [
                'Book Online',
                'Routine checks from £18',
                'Compassionate care',
                'Exceptionally rated',
                'Open late and on Weekends',
                'Locations across London',
            ],
        },
    ]
    const highestClickedAd = computed<HighestClickedAd>(() => {
        const ad = { headlineOne, headlineTwo, displayUrl, descriptionOne }

        if (!calloutExtensions.value || !calloutExtensions.value.length) {
            return ad as HighestClickedAd
        }

        const anyCalloutsExist = calloutExtensions.value.some(c => c.calloutText)

        // Use placeholders if no callouts exist
        if (!anyCalloutsExist) {
            const callouts = [1, 2, 3, 4].map(i => `Callout ${getNumberWord(i)}`)
            return { ...ad, callouts }
        }

        const callouts = calloutExtensions.value.map(extension => extension.calloutText)

        return { ...ad, callouts }
    })

    const adTitleTag: AdTitleTag = { title: 'Highest Clicked Ad', classes: 'opteo-blue' }
    const calloutForm = ref<FormElement>()

    // make sure to reset the refs before each update
    // to prevent duplicates
    onBeforeUpdate(() => {
        inputRefs.value = []
    })

    const adjustSteps = ref(['Input Callout Extensions'])
    const pushActionText = ref('Add Callout Extensions')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding callout extensions..',
        'Confirming changes..',
        'Callout extensions added successfully.',
    ])

    const onPush: OnPushHandler<AddCalloutExtension.Callout[]> = () => {
        const valid = !!calloutForm.value?.submit()

        if (!valid) {
            return { valid }
        }

        return {
            valid,
            pushedData: calloutExtensions.value
                .filter(calloutExtension => calloutExtension.calloutText.length) // Only bother sending the ones with text
                .map(calloutExtension => {
                    return {
                        callout_text: calloutExtension.calloutText,
                        feed_item_id: calloutExtension.feedItemId,
                        feed_id: calloutExtension.feedId,
                        mutate: calloutExtension.mutate,
                    }
                }),
        }
    }

    return {
        title,
        campaignNames,
        entityPillList,
        calloutExtensionExamples,
        highestClickedAd,
        adTitleTag,
        inputRefs,
        calloutForm,
        MINIMUM_CALLOUT_EXTENSIONS,
        MAXIMUM_CALLOUT_EXTENSIONS,
        MAXIMUM_CALLOUT_LENGTH,
        calloutExtensions,
        resetCalloutExtensions,
        addCalloutExtensions,
        onPush,
        lastUpdated,
        missingCalloutCount,
        hasExistingCallouts,
        pushMessages,
        pushActionText,
        adjustSteps,
    }
}
