<template>
    <PageHeader :no-margin="true" page-title="Billing Centre">
        <template #middle-content>
            <Tabs
                :links="[
                    {
                        key: 'billing',
                        name: 'Billing',
                        to: { name: Routes.BillingCentreSubscription },
                    },
                    {
                        key: 'invoices',
                        name: 'Invoices',
                        to: { name: Routes.Invoices },
                    },
                ]"
            />
        </template>
        <template v-if="aboveMobile" #right-content>
            <oButton class="contact-support-button" color="white" @clicked="showNewMessage">
                Message Support
            </oButton>
        </template>
    </PageHeader>

    <PageContent>
        <router-view />
    </PageContent>
</template>

<script lang="ts">
import { oButton, Tabs, Ent } from '@opteo/components-next'
import { useSubscription } from '@/composition/billing/useSubscription'
import PageContent from '@/layouts/PageContent.vue'
import PageHeader from '@/layouts/PageHeader.vue'
import { useIntercom } from '@/lib/intercom/useIntercom'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { Routes } from '@/router/routes'

export default {
    components: { PageHeader, oButton, PageContent, Tabs, Ent },
    setup() {
        const { stripeCustomer } = useSubscription()

        const { showNewMessage } = useIntercom()

        const { aboveMobile } = useMediaQuery()

        return { stripeCustomer, showNewMessage, Routes, aboveMobile }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.contact-support-button {
    @include opteo-background;
    @include opteo-foreground;
    transition: none;
}
</style>
