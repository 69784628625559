<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.70711 1.70711c-.39053-.39053-1.02369-.39053-1.41422 0l-.7071.7071 2.12132 2.12132.70709-.7071c.3905-.39053.3905-1.02369 0-1.41422l-.70709-.7071ZM9 5.24264 6.87868 3.12132 1.63604 8.36396l-.35355 2.47484 2.47487-.3535L9 5.24264Z"
            :fill="hex"
        />
    </svg>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SmallPencilIcon',
    props: {
        hex: {
            type: String,
            default: '#000',
        },
    },
})
</script>
