import uniqBy from 'lodash-es/uniqBy'
import { ref, computed, Ref } from 'vue'
import { RsaWriter, Targets } from '@opteo/types'
import { CurrencyCode } from '@opteo/types/currency'

import { useNumber, usePercent, useMoney } from '@opteo/components-next'

type Rsa = RsaWriter.RsaAd | RsaWriter.RsaTextFields

export type RsaAssets = {
    headlines: RsaWriter.RsaAsset[]
    descriptions: RsaWriter.RsaAsset[]
}

const PERMUTATION_COUNT = 15

export function useRsaPermutations<TRsa extends Ref<Rsa>>(rsa: TRsa) {
    const randomSeed = Math.floor(Math.random() * 1000)

    const pickRandom = (
        array: RsaWriter.RsaAsset[],
        seed: number
    ): RsaWriter.RsaAsset | undefined => {
        function random() {
            var x = Math.sin(seed++) * 10000
            return x - Math.floor(x)
        }

        const i = Math.floor(random() * array.length)
        return array[i]
    }

    const pickAsset = (
        sourceAssets: RsaWriter.RsaAsset[],
        alreadyPickedAssets: RsaWriter.RsaAsset[],
        assetIndex: number,
        _permutationIndex: number
    ) => {
        const baseSeed = _permutationIndex * 1000 + assetIndex

        // find assets pinned to the current index
        const pinnedAssets = sourceAssets
            .filter(a => a?.text)
            .filter(a => a.pinnedTo - 1 === assetIndex)

        // if any exist, pick one of them randomly
        if (pinnedAssets.length) {
            return pickRandom(pinnedAssets, randomSeed + baseSeed)
        }

        // find assets that are not pinned to any index and aren't aready picked
        const unPinnedUnusedAssets = sourceAssets
            .filter(a => a?.text) // has text
            .filter(h => h.pinnedTo === 0) // not a pinned asset
            .filter(h => !alreadyPickedAssets.map(fh => fh.text).includes(h.text)) // not already used

        return pickRandom(unPinnedUnusedAssets, randomSeed + baseSeed + 7)
    }

    const permutationIndex = ref(0)

    // Given the set of available headlines and descriptions, select an appropriate random one for a given slot.

    // get 5 randomly generated permutations of the headlines and descriptions
    const permutations = computed(() => {
        return uniqBy(
            Array.from(Array(PERMUTATION_COUNT)).map((_, i) => {
                const finalHeadlines: RsaWriter.RsaAsset[] = []
                const finalDescriptions: RsaWriter.RsaAsset[] = []

                // 3 headlines
                for (const j of [0, 1, 2]) {
                    const asset = pickAsset(rsa.value.headlines, finalHeadlines, j, i)
                    if (!asset) {
                        break // if there's no valid asset that can be picked (eg not enough headlines written)
                    }
                    finalHeadlines[j] = asset
                }
                // 2 descriptions
                for (const j of [0, 1]) {
                    const asset = pickAsset(rsa.value.descriptions, finalDescriptions, j, i)
                    if (!asset) {
                        break // if there's no valid asset that can be picked (eg not enough descripions written)
                    }
                    finalDescriptions[j] = asset
                }

                return { headlines: finalHeadlines, descriptions: finalDescriptions }
            }),
            p => JSON.stringify(p)
        )
    })

    const currentPermutation = computed(() => permutations.value[permutationIndex.value])

    const prevPermutation = () => {
        permutationIndex.value =
            (permutationIndex.value - 1 + permutations.value.length) % permutations.value.length
    }

    const nextPermutation = () => {
        permutationIndex.value = (permutationIndex.value + 1) % permutations.value.length
    }

    return {
        currentPermutation,
        prevPermutation,
        nextPermutation,
    }
}

export function useRsaStatistics(rsa: Ref<RsaWriter.RsaAd>, currencyCode?: CurrencyCode) {
    const statistics = computed(() => {
        const { metrics } = rsa.value
        if (!metrics) {
            return
        }
        return [
            {
                title: 'Impr.',
                value: useNumber({ value: metrics.impressions }).displayValue.value,
            },
            {
                title: 'Clicks',
                value: useNumber({ value: metrics.clicks }).displayValue.value,
            },
            {
                title: 'Conv.',
                value: useNumber({ value: metrics.conversions }).displayValue.value,
            },
            {
                title: 'CTR',
                value: usePercent({
                    value: metrics.impressions ? metrics.clicks / metrics.impressions : 0,
                }).displayValue.value,
            },
            {
                title: 'CPI',
                value: useNumber({
                    value: metrics.impressions ? metrics.conversions / metrics.impressions : 0,
                    decimalPlaces: 5,
                }).displayValue.value,
            },
            {
                title: 'Value',
                value: useMoney({ value: metrics.conversionsValue, currency: currencyCode })
                    .displayValue.value,
            },
        ]
    })

    return { statistics }
}

export function useRsaDisplayUrl(rsa: Ref<RsaWriter.RsaAd>) {
    return computed(() => generateRsaDisplayUrl(rsa.value))
}

/**
 * `RsaWriter.CompetitorAd` picks a few value types from `RsaWriter.RsaAd`, but `finalUrl` is unique for the latter
 * and is also the value we want to display in the RSA previews.
 * @param {RsaWriter.RsaAd | RsaWriter.CompetitorAd} rsa
 * @returns Type predicate: if true, rsa is of type `RsaWriter.RsaAd`, else is of type `RsaWriter.CompetitorAd`
 */
function isRsaAd(rsa: RsaWriter.RsaAd | RsaWriter.CompetitorAd): rsa is RsaWriter.RsaAd {
    return rsa.hasOwnProperty('finalUrl')
}

export function generateRsaDisplayUrl<TRsa extends Rsa>(rsa: TRsa) {
    let url: string = ''

    if (isRsaAd(rsa)) {
        try {
            const urlObject: URL = new URL(rsa.finalUrl)
            const { protocol, hostname } = urlObject

            const formattedHostname = hostname.startsWith('www.')
                ? hostname.replace('www.', '')
                : hostname
            url = `${protocol}//${formattedHostname}`
        } catch {
            url = rsa.finalUrl
        }

        if (!url) url = 'https://example.com'
    } else {
        url = rsa.urlBase
    }

    const urlHasTrailingSlash = url.endsWith('/')
    if (!urlHasTrailingSlash) {
        url += '/'
    }

    if (rsa.pathOne) {
        url += rsa.pathOne
    }
    if (rsa.pathTwo) {
        url += `/${rsa.pathTwo}`
    }

    // Always return a truthy string
    return url || ' '
}

export function getCharCount(char_string: string) {
    if (!char_string || char_string === '--') {
        return 0
    }

    return stripAdCustomisers(char_string).length
}

export const stripAdCustomisers = (s: string) => {
    // This regex replaces anything between `{` and `} with either the default text or an empty string
    const string_without_ad_customisers = s.replace(/\{[^}]*\}*/g, ad_mod => {
        // for each modifier, pluck out default text if it exists (usually after a `:`)
        if (ad_mod.toLowerCase().includes('{=if')) {
            try {
                const inside_if_statement = ad_mod.slice(4, -1) // if statements are of the form {=IF(device=mobile, text to insert):default text}
                const insert_text = inside_if_statement.split(',')[1].split(')')[0].trim() // text between `,` and `)`
                const default_text = (inside_if_statement.split(':')[1] || '').trim() // text between `:` and `}` (may not exist)
                return insert_text.length > default_text.length ? insert_text : default_text
            } catch (e) {
                console.warn(
                    'unable to parse if statement in ad customiser, defaulting to default text',
                    s
                )
            }
        }
        if (ad_mod.toLowerCase().includes('{=countdown')) {
            // usually of the form `{=COUNTDOWN("2020/10/9 12:30:00","en-US",5)})`
            return ''
        }
        return (ad_mod.slice(1, -1).split(':')[1] || '').trim() // text after `:`
    })

    return string_without_ad_customisers
}
