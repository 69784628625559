<template>
    <EntityPill v-if="entity === 'search-query'" :content="label" type="search-term" />
    <EntityPill v-else :content="label" :type="entity" />
</template>
<script lang="ts">
import { EntityPill } from '@opteo/components-next'

export default {
    name: 'ImprovementLocation',
    components: {
        EntityPill,
    },
    props: {
        entity: {
            required: true,
            type: String,
            validator(value) {
                return [
                    'account-level',
                    'campaign-group',
                    'campaign',
                    'adgroup',
                    'product-group',
                    'experiment',
                    'keyword',
                    'placement',
                    'search-term',
                    'location',
                    'url',
                    'word',
                    'n-gram',
                    'conversion-action',
                    'negative-keyword',
                    'negative-list',
                    'placement-exclusion-list',
                    'custom',
                ].includes(value)
            },
        },
        label: {
            required: true,
            type: String,
        },
        charLimit: {
            required: false,
            type: Number,
        },
    },
    data() {
        return {
            display_label: '',
        }
    },
    created() {
        if (this.charLimit && this.label.length > this.charLimit + 3) {
            this.display_label = `${this.label.substring(0, this.charLimit)}...`
        } else {
            this.display_label = this.label
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.location-span {
    @include opteo-foreground;
    @include f-8;
    @include fw-300;
    @include no-wrap;
    @include ml-12;
    @include mr-16;
    opacity: 0.8;
}
.location-span-last {
    @include opteo-foreground;
    @include f-8;
    @include fw-300;
    @include no-wrap;
    @include mr-0;
    @include ml-12;
    opacity: 0.8;
}
</style>
