import { computed } from 'vue'
import { authRequest, Endpoint, useAPI } from '../api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'
import { Reports } from '@opteo/types'

export function useReportPreferences() {
    const { domainId } = useDomain()

    const { data: preferences, mutate: mutatePreferences } = useAPI<Reports.Core.ReportPreferences>(
        Endpoint.GetReportPreferences,
        { uniqueId: () => domainId.value, waitFor: () => domainId.value }
    )

    const { data: schedules, mutate: mutateShedules } = useAPI<Reports.Core.ReportSchedule[]>(
        Endpoint.GetReportSchedules,
        { uniqueId: () => domainId.value, waitFor: () => domainId.value }
    )

    const loading = computed(() => !preferences.value || !schedules.value)

    const updateDefaultTemplate = async (templateId: number) => {
        mutatePreferences(() => {
            if (!preferences.value) {
                throw new Error('Cannot update preferences before it is loaded')
            }
            return {
                ...preferences.value,
                template_id: templateId,
            }
        })

        await authRequest<void>(Endpoint.UpdateDefaultTemplate, {
            body: {
                domain_id: domainId.value,
                template_id: templateId,
            },
        })

        await mutatePreferences()
    }

    const updateDefaultTheme = async (themeName: Reports.Core.ReportTypeface) => {
        mutatePreferences(() => {
            if (!preferences.value) {
                throw new Error('Cannot update preferences before it is loaded')
            }
            return {
                ...preferences.value,
                typeface: themeName,
            }
        })

        await authRequest<void>(Endpoint.UpdateDefaultTheme, {
            body: {
                domain_id: domainId.value,
                typeface: themeName,
            },
        })

        await mutatePreferences()
    }

    // This function looks a lot like its namesake in useCreateReport.ts, but it is different enough to be repeated here.
    const removeSchedule = async (scheduleId: number) => {
        mutateShedules(() => {
            if (!schedules.value) {
                throw new Error('Cannot update preferences before it is loaded')
            }
            return schedules.value.filter(s => s.schedule_id !== scheduleId)
        })

        await authRequest<void>(Endpoint.CancelSchedule, {
            body: {
                schedule_id: scheduleId,
            },
        })

        await mutateShedules()
    }

    return {
        loading,
        preferences,
        schedules,
        updateDefaultTemplate,
        updateDefaultTheme,
        removeSchedule,
    }
}
