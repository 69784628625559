import pickBy from 'lodash-es/pickBy'
import sortBy from 'lodash-es/sortBy'
import uniq from 'lodash-es/uniq'
import groupBy from 'lodash-es/groupBy'
import sumBy from 'lodash-es/sumBy'
import cloneDeep from 'lodash-es/cloneDeep'

import { ref, computed, watch, provide, inject } from 'vue'
import { delay, scrollTo, scrollToSelector, promiseDebounce } from '@/lib/globalUtils'

import { useDomain } from '@/composition/domain/useDomain'
import { useReport } from '@/composition/reports/useReport'
import { useUser } from '@/composition/user/useUser'
import { Reports } from '@opteo/types'
import { showSnackbar, useNumber } from '@opteo/components-next'
import { authRequest, Endpoint } from '@/composition/api/useAPI'

const SLIDE_CATEGORIES = {
    0: 'Cover',
    1: 'Summary',
    2: 'Performance Summary',
    3: 'Performance Targets',
    4: 'Top Performers',
    7: 'Featured Improvements',
    8: 'Work Summary',
    9: 'Conclusion',
}

export function provideReportSlides() {
    const { userInfo } = useUser()
    const { domainInfo, domainName, domainId } = useDomain()
    const { report, previewMode, activeSlides, refreshReport, reportPeriodFrom, reportPeriodTo } =
        useReport()

    /*
        Setup data required to loop over and render slides
    */

    const orderedSlideTypes = computed(() => {
        const types = sortBy(activeSlides.value, 'slide_order').map(s => s.type)
        return uniq(types)
    })

    const slidesByType = computed(() => {
        return groupBy(activeSlides.value, 'type')
    })

    const coverSlideActive = computed(() => {
        if (slidesByType.value.hasOwnProperty(0)) {
            const [cover_slide] = slidesByType.value[0]
            return cover_slide.active && !cover_slide.invalid
        }
        return false
    })

    const reportUser = computed(() => {
        return {
            username: userInfo.value?.name,
            email: userInfo.value?.username,
            logo: userInfo.value?.avatar_filename,
            company_name: domainName,
        }
    })

    /*
        Slide scroll handling & sync with preview sidebar
    */
    const scroll = ref()
    const currentSlidePageNo = ref(0)
    const selectedCategory = ref(0)

    let syncPreviewBarScroll = true // Allows us to "unhook" the preview mini-slides' scrollbar when we don't want it to follow the main scroll

    const goToSlide = async (pageNo: number) => {
        currentSlidePageNo.value = pageNo

        syncPreviewBarScroll = false
        scrollTo(`slide--${pageNo}`, 'auto')
        await delay(100)
        syncPreviewBarScroll = true
    }

    const syncPreviewScroll = (pageNo: number, visible: boolean) => {
        if (!visible) {
            return
        }

        currentSlidePageNo.value = pageNo

        const firstSlideOfCategory = report.value?.slides.find(slide => +slide.page_no === pageNo)

        selectedCategory.value = firstSlideOfCategory?.category ?? 0

        if (!syncPreviewBarScroll) {
            return
        }

        const el = document.getElementById(`mini-slide--${pageNo}`)

        if (!el) {
            return
        }

        const top = Math.floor(el.offsetTop - window.innerHeight / 3)

        scroll.value.$el.scroll({ top })
    }

    /*
        Hidden slides
    */
    const hiddenSlides = computed(() => {
        if (!report.value?.slides.length) {
            return []
        }

        return report.value.slides
            .filter(slide => !slide.active || slide.invalid)
            .map(slide => {
                return {
                    title: slide.title ?? '',
                    restorable: !slide.invalid,
                    reason: slide.invalid_reason,
                    slideId: slide.slide_id,
                }
            })
    })

    const restoreSlide = (slideId: string) => {
        toggleSlideDeleted(slideId, true)

        const slideTitle = report.value?.slides.find(s => s.slide_id === slideId)?.title ?? ''

        showSnackbar({
            message: 'Reactivated <b>' + slideTitle + '</b>',
            actionText: 'Scroll To Slide',
            actionHandler: () => {
                scrollToSelector(`div[data-slide-id="${slideId}"]`)
            },
            timeout: 5000,
        })
    }

    const restoreAllSlides = () => {
        hiddenSlides.value.forEach(hiddenSlide => {
            if (hiddenSlide.restorable) {
                toggleSlideDeleted(hiddenSlide.slideId, true)
            }
        })
    }

    const toggleSlideDeleted = async (slideId: string, isActive: boolean) => {
        refreshReport(() => {
            if (!report.value) {
                throw new Error('cannot toggle slides until report is defined')
            }
            const editedSlide = report.value.slides.find(s => s.slide_id === slideId)
            if (!editedSlide) {
                throw new Error('cannot toggle slides that does not exist')
            }
            editedSlide.active = isActive

            return {
                ...report.value,
                slides: [...report.value.slides.filter(s => s.slide_id !== slideId), editedSlide],
            }
        })

        await authRequest(Endpoint.UpdateSlideStatus, {
            body: {
                slide_id: slideId,
                status: isActive,
            },
        })

        refreshReport()
    }

    /*
        Updating & resetting slide text
    */

    const updateSlideText = (slideId: string, newText: string) => {
        const slide = report.value?.slides.find(s => s.slide_id === slideId)
        if (!slide) {
            return
        }
        const isDefaultText = newText === slide.default_text

        refreshReport(() => {
            if (!report.value) {
                throw new Error('cannot toggle slides until report is defined')
            }
            const editedSlide = report.value.slides.find(s => s.slide_id === slideId)
            if (!editedSlide) {
                throw new Error('cannot toggle slides that does not exist')
            }
            editedSlide.text = newText

            return {
                ...report.value,
                slides: [...report.value.slides.filter(s => s.slide_id !== slideId), editedSlide],
            }
        })

        updateSlideTextThrottled(slideId, newText, isDefaultText)
    }

    const resetSlideText = (slideId: string) => {
        refreshReport(() => {
            if (!report.value) {
                throw new Error('cannot toggle slides until report is defined')
            }
            const editedSlide = report.value.slides.find(s => s.slide_id === slideId)
            if (!editedSlide) {
                throw new Error('cannot toggle slides that does not exist')
            }
            editedSlide.text = editedSlide.default_text

            return {
                ...report.value,
                slides: [...report.value.slides.filter(s => s.slide_id !== slideId), editedSlide],
            }
        })

        const editedSlide = report.value?.slides.find(s => s.slide_id === slideId)
        if (!editedSlide) {
            throw new Error('cannot toggle slides that does not exist')
        }

        updateSlideTextThrottled(slideId, editedSlide.default_text ?? '', true)
    }

    const updateSlideTextThrottled = promiseDebounce(async function (
        slideId: string,
        newText: string,
        isDefaultText: boolean
    ) {
        await authRequest(Endpoint.UpdateSlideText, {
            body: {
                slide_id: slideId,
                text: newText,
                default_text: isDefaultText,
            },
        })
    },
    500)

    /*
        Work Summary
    */
    const workSummaryOpen = ref(false)
    const workSummary = ref<Reports.Core.WorkSummaryRow[]>([])

    watch(report, newReport => {
        // Initialise the work summary to whatever the backend says
        if (newReport?.work_summary.length) {
            resetWorkSummary()
        }
    })

    const workSummaryTotals = computed(() => {
        return {
            tasks: sumBy(report.value?.work_summary, row => (row.active ? row.number : 0)),
            time: friendlyTime(
                sumBy(report.value?.work_summary, row => (row.active ? row.time * row.number : 0))
            ),
        }
    })

    const friendlyTime = (totalMinutes: number) => {
        const hours = useNumber({ value: Math.floor(totalMinutes / 60) }).displayValue.value
        const minutes = useNumber({ value: totalMinutes % 60 }).displayValue.value

        if (hours === '0') {
            return `${minutes} minutes`
        }
        if (minutes === '0') {
            return `${hours} hours`
        }
        return `${hours} hours ${minutes} minutes`
    }

    const workSummaryLoading = ref(false)
    const updateWorkSummary = async () => {
        workSummaryLoading.value = true
        await authRequest(Endpoint.SaveWorkSummary, {
            body: {
                domain_id: domainId.value,
                report_id: report.value?.report_id,
                work_summary: workSummary.value,
            },
        })

        await refreshReport()

        workSummaryOpen.value = false
        workSummaryLoading.value = false
    }

    const resetWorkSummary = () => {
        if (!report.value) {
            throw new Error('Cannot reset work summary until report is set')
        }
        workSummary.value = cloneDeep(report.value.work_summary)
    }

    /*
        ReportNav setup
    */
    const shownCategories = computed(() => {
        return pickBy(SLIDE_CATEGORIES, (_, key) => {
            const existing_categories = activeSlides.value
                .filter(s => !s.invalid && s.active)
                .map(s => s.category)
            return existing_categories.includes(+key)
        })
    })

    const goToSlideCategory = (category: number) => {
        const firstSlideOfCategory = report.value?.slides.find(
            s => s.category === category && !s.invalid && s.active
        )

        if (!firstSlideOfCategory) {
            return
        }

        goToSlide(firstSlideOfCategory.page_no)
    }

    const toProvide = {
        goToSlide,
        report,
        domainInfo,
        activeSlides,
        orderedSlideTypes,
        slidesByType,
        coverSlideActive,
        previewMode,
        syncPreviewScroll,
        reportUser,
        reportPeriodFrom,
        reportPeriodTo,
        currentSlidePageNo,
        scroll,
        hiddenSlides,
        restoreSlide,
        restoreAllSlides,
        shownCategories,
        selectedCategory,
        goToSlideCategory,
        toggleSlideDeleted,
        updateSlideText,
        resetSlideText,
        workSummaryOpen,
        workSummaryTotals,
        workSummary,
        friendlyTime,
        updateWorkSummary,
        resetWorkSummary,
        workSummaryLoading,
    }

    provide('reportSlides', toProvide)

    return toProvide
}

export function useReportSlides() {
    const injected = inject<ReturnType<typeof provideReportSlides>>('reportSlides')

    if (!injected) {
        throw new Error(
            `Report Slides not yet injected, something is wrong. useCreateReport() can only be called in a reports/active/create or reports/active/:id/slides route.`
        )
    }

    return injected
}
