<template>
    <nav :class="[{ 'all-hidden': previewMode }, 'fixed fw5']">
        <ul>
            <li v-for="(item, key) in items" :key="item">
                <a
                    class="menu-item"
                    @click="selectItem(+key)"
                    :class="{ selected: +key === selectedItem }"
                >
                    <span
                        class="menu-text"
                        :style="{ color: +key === selectedItem ? '#006dff' : '#d0d0d3' }"
                        >{{ item }}
                    </span>
                    <div
                        class="menu-bar"
                        :style="{
                            'background-color': +key === selectedItem ? '#006dff' : '#d0d0d3',
                        }"
                    />
                </a>
            </li>
        </ul>
    </nav>
</template>
<script>
export default {
    name: 'ReportNav',
    props: {
        color: {
            type: String,
            required: false,
            default: '#008dff',
        },
        items: {
            type: Object,
            required: false,
        },
        selectedItem: {
            type: Number,
            required: false,
        },
        previewMode: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        selectItem(key) {
            this.$emit('change-item', key)
        },
    },
}
</script>

<style lang="scss" scoped>
nav {
    display: none;
}
@media screen and (min-width: 1600px) {
    nav {
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.3s;
        z-index: 10;
        display: block;
        text-align: right;
    }

    nav.all-hidden {
        transform: translate(25px, -50%);
    }

    nav.all-hidden .menu-item {
        pointer-events: none;
    }

    .menu-item {
        display: inline-flex;
        white-space: nowrap;
        height: 1.25rem;
        cursor: pointer;
    }

    .menu-bar {
        margin-top: 5px;
        margin-left: 10px;
        height: 2px;
        transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
        width: 12px;
    }

    .selected .menu-bar,
    .menu-item:hover .menu-bar {
        width: 18px;
    }

    .menu-text,
    nav.all-hidden:hover .menu-text {
        opacity: 0;
        transition: all 0.8s cubic-bezier(0, 0, 0.2, 1);
        font-size: 0.8125rem;
        letter-spacing: -0.1px;
        -webkit-transform: translateX(12px);
        transform: translateX(12px);
    }

    .selected .menu-text {
        -webkit-transform: translateX(11px);
        transform: translateX(11px);
    }

    nav:hover .menu-text {
        opacity: 1;
        transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}
</style>
