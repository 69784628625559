import { computed, ref } from 'vue'
import { Api, Alert } from '@opteo/types'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'

// Outside because it needs to be synced across many AlertRows
const alertsPopoverOpen = ref(false)

export function useAlerts() {
    const { data: alerts, mutate } = useAPI<Api.ListAlerts.Response>(Endpoint.ListAlerts)

    // Only done loading once the colors are ready as well to avoid flash of white icons
    const loading = computed(() => !alerts.value)
    const limit = ref(50)
    const miniAlertList = computed(() => (alerts.value ?? []).slice(0, 15))
    const unreadCount = computed(() => (alerts.value ?? []).filter(alert => !alert.checked).length)
    const alertsSliced = computed(() => (alerts.value ?? []).slice(0, limit.value))

    const markAllAsRead = async () => {
        const ids = alerts.value?.map(alert => alert.id)

        mutate(
            // @ts-ignore This doesn't need to be async
            () =>
                alerts.value?.map(alert => {
                    return {
                        ...alert,
                        checked: true,
                    }
                })
        )
        await authRequest(Endpoint.SetAlertsAllRead, {
            body: {
                alert_ids: ids,
            },
        })
    }

    // Util method for updating fields of a single alert by id
    const mutateAlert = (alertId: number, updatedAlert: Partial<Alert.Object>) => {
        mutate(
            // @ts-ignore This doesn't need to be async
            () =>
                alerts.value?.map(a => {
                    if (a.id === alertId) {
                        return {
                            ...a,
                            ...updatedAlert,
                        }
                    }
                    return a
                })
        )
    }

    return {
        alerts,
        alertsSliced,
        limit,
        loading,
        miniAlertList,
        unreadCount,
        markAllAsRead,
        mutateAlert,
        alertsPopoverOpen,
        mutateAlerts: mutate,
    }
}
