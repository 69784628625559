import { noAuthRequest } from '@/composition/api/useAPI'
import { ref } from 'vue'

interface ResetPasswordResponse {
    data: {
        username_found: boolean
        error: 'No registered email found' | 'Registered email found'
        username: string
    }
}

export function useResetPassword() {
    const userEmailInput = ref()

    const emailAddress = ref<string>()

    const showForm = ref(true)
    const loading = ref(false)

    async function sendResetLink() {
        if (!userEmailInput.value?.valid) {
            return
        }

        loading.value = true
        let response

        try {
            const { data } = await noAuthRequest<ResetPasswordResponse>('/api/auth/resetPassword', {
                meta: {
                    function: 'auth:resetPassword',
                    args: {
                        username: emailAddress.value,
                    },
                },
            })

            response = data
        } catch {}

        loading.value = false

        if (!response) {
            userEmailInput.value.setError('Something went wrong please contact support')
            return
        }

        if (!response.username_found) {
            userEmailInput.value.setError('No account found with this email address')
            return
        }

        showForm.value = false
    }

    return { sendResetLink, showForm, emailAddress, loading, userEmailInput }
}
