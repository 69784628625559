<template>
    <div class="grid-item no-select" style="padding-top: 66%">
        <div class="grid-item-inner">
            <slot></slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.grid-item {
    @include container;
    @include br-20;
    position: relative;
    width: 100%;
    @include opteo-background;
    cursor: pointer;
}
.grid-item-inner {
    position: absolute;
    @include inset;
    @include pa-28;
}

.grid-item:before {
    content: '';
    background-color: white;
    position: absolute;
    left: 10px;
    right: 10px;
    height: 40px;
    bottom: -10px;
    z-index: -1;
    box-shadow: $opteo-shadow;
    border-radius: 0.5rem;
}
.grid-item:after {
    content: '';
    background-color: transparent;
    position: absolute;
    left: 20px;
    right: 20px;
    height: 40px;
    bottom: -20px;
    z-index: -2;
    box-shadow: $opteo-shadow;
    border-radius: 0.5rem;
}
</style>
