import { Account, Platform } from '@opteo/types'

// Necessary
export const LS_TOKEN_NAME = 'opt-token'
export const LS_USER_ID = 'opt-uid'
export const LS_ACCOUNT_ID = 'opt-aid'

// Account centre
export const LS_AC_SORTBY = 'opt-accountcentre-sortby'
export const LS_AC_VIEWMODE = 'opt-accountcentre-viewmode'

// Performance
export function LS_GRAPHS_SIDEBAR(accountId: Account.ID) {
    return `opt-graphs-sidebar:${accountId}:v1`
}
export function SS_PERFORMANCE_DATE_RANGE(accountId: Account.ID) {
    return `opt-performance-daterange:${accountId}`
}
export function LS_PERFORMANCE_DATE_DIFFERENCE(accountId: Account.ID) {
    return `opt-performance-date-difference:${accountId}`
}

// Toolkit
export function LS_SCORECARD_SIDEBAR(accountId: Account.ID) {
    return `opt-scorecard-sidebar-v3:${accountId}`
}

// Chat
export const LS_CHAT_TUTORIAL_AT = 'opt-chat-tutorial-at'

// Reports list
export const LS_RL_VIEWMODE = 'opt-reportslist-viewmode'

// Enforcement
export const LS_OVER_LIMIT = 'opt-over-limit'
export const LS_BILLING_LOCKED = 'opt-billing-locked'

// Improvements
export const LS_IMPROVEMENTS_SORTBY = 'opt-improvements-sortby'

// LINKED ACCOUNTS
export function LS_UNLINKED_LAST_VISITED(platform: Platform.Platform) {
    return `opt-unlinked-last-visited:${platform}`
}
export function LS_UNLINKED_LAST_VISITED_UNMOUNT(platform: Platform.Platform) {
    return `opt-unlinked-last-visited-unmount:${platform}`
}
export function LS_LINKED_ACCOUNTS_LAST_REFRESHED(platform: Platform.Platform) {
    return `opt-linked-accounts-last-refreshed:${platform}`
}

// Ngram
export function LS_NGRAM_EXCLUDED_CAMPAIGNS(accountId: Account.ID) {
    return `opt-ngram-excluded-campaigns:${accountId}`
}
