import ExcelJS from 'exceljs'
import { sortBy } from 'lodash-es'

export const downloadExcelFile = async (params: { title: string; workbook: ExcelJS.Workbook }) => {
    const { title, workbook } = params

    const buffer = await workbook.xlsx.writeBuffer()
    const xlsxFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const blob = new Blob([buffer], { type: xlsxFormat })

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `${title}.xlsx`
    link.click()
}

export const adjustWidthsToFitText = (params: {
    columns: Partial<ExcelJS.Column>[]
    rows: Record<string, string>[]
}): Partial<ExcelJS.Column>[] => {
    const { rows, columns } = params

    const columnsWithAdjustedWidth = columns.map(column => {
        const { header, key } = column

        if (!key || !header) return column

        const columnTitle = header as string

        const columnValues = rows.map(row => row?.[key]?.split(',\n')).flat()
        const sortedColumnValues = sortBy([...columnValues, columnTitle], value => -value?.length)
        const maxColumnValueWidth = measureTextWidth(sortedColumnValues?.[0] ?? '')
        return { ...column, width: maxColumnValueWidth }
    })

    return columnsWithAdjustedWidth
}

export const measureTextWidth = (text: string): number => {
    const charLength = text.length
    let multiplier = 1.2

    if (charLength > 20) {
        multiplier = 1
    }

    if (charLength > 50) {
        multiplier = 0.9
    }

    const width = charLength * multiplier

    return Math.max(width, 10)
}
