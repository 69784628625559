<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill
                        :content="entityPillList[0].content"
                        :type="entityPillList[0].type"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    For the above campaign, consider adjusting your bids to reflect the suggested
                    schedule below. Ad schedule bid adjustments allow you to reduce spend in low
                    performing periods and increase spend during high performing periods.
                </Text>
                <Spacer height="1rem" />
                <Text v-if="campaignDoesNotHaveAdSchedule" as="p">
                    This campaign does not currently have an ad schedule set, so Opteo created one.
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to adjust
                    your bids in line with the chart below.
                </Text>
                <Text v-else as="p">
                    Click <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    adjust bids in line with the chart below.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/900592-adjust-ad-schedule-bids">
                Click here to learn more about adjusting ad schedule bids in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <AdScheduleChart
                :adSchedule="adSchedule"
                :metric="{
                    label: 'CPA',
                    dataType: 'money',
                    inverted: true,
                    currency: currencyCode,
                }"
            />
            <Spacer height="2rem" />
            <ImprovementNote>
                <b>Note:</b> Hover over any section to explore the recommended bid adjustment.
                Sections in <Text as="span" size="f-8" color="green" weight="600">green</Text> will
                be adjusted upwards, whilst sections in
                <Text as="span" size="f-8" color="red" weight="600">red</Text> will be adjusted
                downwards.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <oTable
                :headers="tableHeaders"
                :items="tableItems"
                :per-page="6"
                responsive-mode="columns"
                responsive-breakpoint="768px"
                responsive-col-size="40% 1fr"
            >
                <template v-slot:[`column.datetime`]="datetime">
                    <span style="width: 11.25rem; display: block">
                        {{ datetime.value }}
                    </span>
                </template>
                <template v-slot:[`column.cpa`]="cpa">
                    <span style="width: 3.5rem; display: block">
                        {{ cpa.value }}
                    </span>
                </template>
                <template v-slot:[`column.bidMod`]="bidMod">
                    <span
                        :class="{
                            'opteo-green': bidMod.value[0] === '+',
                            'opteo-red': bidMod.value[0] === '-',
                        }"
                    >
                        {{ bidMod.value }}
                    </span>
                </template>
            </oTable>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>
    </ImprovementView>
</template>
<script lang="ts">
import {
    EntityPill,
    EntityPillList,
    Spacer,
    Text,
    AdScheduleChart,
    oTable,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAdjustAdScheduleBids } from '@/composition/improvement/types/useAdjustAdScheduleBids'

export default {
    name: 'AdjustAdScheduleBids',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        EntityPill,
        EntityPillList,
        Spacer,
        Text,
        AdScheduleChart,
        oTable,
    },
    setup() {
        const { aboveMobile } = useMediaQuery()
        return { ...useAdjustAdScheduleBids(), aboveMobile }
    },
}
</script>
