<template>
    <div class="rsa-footer">
        <div class="domain">
            <slot></slot>
            <div class="location">
                <slot name="location"></slot>
            </div>
        </div>
        <div class="buttons">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { oButton, Avatar, Text, Spacer } from '@opteo/components-next'

export default {
    name: 'RsaFooter',
    components: {
        oButton,
        Avatar,
        Text,
        Spacer,
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Base
.rsa-footer {
    @include bg-opteo-white;
    box-shadow: $opteo-shadow-xl;
    height: 5rem;
    @include ph-24;
    @include br-20;
    touch-action: none;
    overflow: hidden;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    width: calc(100vw - 2rem);
    z-index: 10000;
    @include flex;
    @include items-center;
    @include justify-between;
}
.rsa-footer .domain,
.rsa-footer .location {
    @include flex;
    @include items-center;
}
.rsa-footer .buttons {
    @include flex;
    @include items-center;
}
</style>
