p<template>
    <svg viewBox="0 0 48 48" width="24" height="24">
        <defs>
            <linearGradient
                id="a"
                x1="24"
                y1="16.68"
                x2="24"
                y2="30.58"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" />
                <stop offset=".1" stop-opacity=".8" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
        </defs>
        <path
            d="M32 2v28a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2 3 3 0 0 1 .92-1.68L28.32.92A2.87 2.87 0 0 1 30 0a2 2 0 0 1 2 2Z"
            :fill="color"
        />
        <path
            d="M16 46V18a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2 3 3 0 0 1-.92 1.68l-27.4 27.4A2.87 2.87 0 0 1 18 48a2 2 0 0 1-2-2Z"
            :fill="color"
            opacity=".5"
        />
        <path d="M32 16v14a2 2 0 0 1-2 2H16V18a2 2 0 0 1 2-2Z" fill="url(#a)" opacity=".2" />
    </svg>
</template>

<script>
export default {
    name: 'Improvements',
    props: {
        color: {
            type: String,
            default: '#E0E0E5',
            required: false,
        },
    },
}
</script>
