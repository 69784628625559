<template>
    <div :class="classes" class="keyword-container">
        <!-- Title Tag (optional) -->
        <CardTitleTag v-if="titleTag" :title="titleTag.title" :classes="titleTag.classes" />
        <!-- Keyword -->
        <div class="w-100 f6 lh-copy">
            <div class="flex items-center justify-center relative">
                <EntityPill type="keyword" :content="keyword.keyword" size="large" />
            </div>
        </div>
        <!-- Location -->
        <KeywordLocation
            :campaign="keyword.campaign"
            :adgroup="keyword.adGroup"
            classes="small mt3"
        />
        <!-- Statistics (optional) -->
        <div class="keyword-statistics flex-wrap">
            <ColorTag
                v-for="statistic in statistics"
                :key="statistic.title"
                :title="statistic.title"
                :content="statistic.content"
                :color="statistic.color"
                style="margin: 0 3px 6px 3px"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

// Local Components
import CardTitleTag from './CardTitleTag.vue'
import KeywordLocation from './KeywordLocation.vue'

// components-next
import { EntityPill, ColorTag } from '@opteo/components-next'

interface Keyword {
    campaign: string
    adGroup: string
    keyword: string
}

interface Statistics {
    title: string
    content: string
    color: string
}

interface TitleTag {
    title: string
    classes: string
}

export default {
    components: {
        CardTitleTag,
        ColorTag,
        EntityPill,
        KeywordLocation,
    },
    props: {
        keyword: {
            type: Object as PropType<Keyword>,
            required: true,
            // default: () => ({ keyword: '', campaign: '', adGroup: '' }),
        },
        titleTag: {
            type: Object as PropType<TitleTag>,
            required: false,
            // default: () => ({ title: '', classes: '' }),
        },
        statistics: {
            type: Array as PropType<Statistics[]>,
            required: false,
            // Array of objects containing { title: '', content: '', color: '' }
        },
        classes: {
            type: [String, Array],
            required: false,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.keyword-container {
    @include container;
    // no-select is commented out to ensure entity pill text is copiable (for customer squad)
    //@include no-select;
    @include tc;
    @include relative;
    padding: 48px 48px 42px 48px;
}

.keyword-statistics {
    @include flex;
    @include items-center;
    @include justify-center;
    @include mt-24;
}

@media screen and (max-width: $mq-767-max) {
    .keyword-container {
        padding: 36px 24px 24px 24px;
    }
    :deep(.card-title-tag) {
        left: 0.5rem;
    }
}
</style>
