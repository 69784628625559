<template>
    <perfect-scrollbar
        ref="container"
        class="table-container"
        tabindex="0"
        role="group"
        aria-labelledby="caption"
    >
        <table class="f6 fw5 w-100" cellspacing="0">
            <caption v-if="caption" id="caption">
                {{
                    caption
                }}
            </caption>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <th class="">
                        {{ item.key }}
                    </th>
                    <td>
                        {{ item.value }}
                    </td>
                </tr>
            </tbody>
        </table>
    </perfect-scrollbar>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

type ProfileItem = { key: string; value: string | number }[]

export default defineComponent({
    props: {
        items: {
            type: Array as PropType<ProfileItem>,
            required: true,
        },
        caption: {
            type: String as PropType<string>,
            required: false,
        },
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.table-container {
    width: 100%;
    @include container;
    @include br-8;
}
table {
    @include tl;
    @include lh-130;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.875rem;
    letter-spacing: -0.00625rem;
}
th,
td {
    @include pv-20;
    vertical-align: middle;
    white-space: nowrap;
}
th {
    @include fw-500;
    @include ph-24;
    width: 50%;
}
td {
    @include fw-400;
    @include pr-24;
}
tr {
    border-bottom: 1px solid;
    @include opteo-border;
}
tr:last-child {
    border-bottom: none;
}
</style>
