<template>
    <MobileSplashContainer wide>
        Toolkit is best viewed on screens <b>1200px or wider</b>, please increase your window size
        or use another device. If you'd like to see us develop Toolkit for mobile devices, please
        consider submitting a <Link :to="linkToFeatureRequests">feature request</Link>.
    </MobileSplashContainer>

    <PageHeader page-title="Toolkit">
        <template v-slot:middle-content>
            <Tabs :links="pageSelectorLinks">
                <template v-slot:tools>Tools</template>
                <template v-slot:history>History</template>
            </Tabs>
        </template>
        <template v-slot:right-content>
            <div class="preferences-button">
                <oButton color="white" disabled>Preferences</oButton>
            </div>
        </template>
    </PageHeader>

    <router-view></router-view>
    <!-- Reports onboarding -->
    <OnboardingTour
        step-name="tour_toolkit"
        :buttonText="'Continue to Toolkit'"
        contentMaxWidth="496px"
    >
        <template v-slot:header>Toolkit</template>
        <template v-slot:copy>
            A collection of tools to help you complete daily management tasks. Whether adding new
            keywords, finding negatives, writing ad creative, or exporting scorecards to share with
            clients, Toolkit has the tools to make everyday management processes easier and more
            efficient.
        </template>
    </OnboardingTour>
</template>

<script lang="ts">
import { computed } from 'vue'
import { useUser } from '@/composition/user/useUser'
import { Routes } from '@/router/routes'

import { oButton, Text, Tabs, Link } from '@opteo/components-next'

import PageHeader from '@/layouts/PageHeader.vue'
import OnboardingTour from '@/components/User/OnboardingTour.vue'
import MobileSplashContainer from '@/components/util/MobileSplashContainer.vue'
import Skeleton from '@/components/util/Skeleton.vue'

export default {
    name: 'Toolkit',
    components: {
        PageHeader,
        OnboardingTour,
        MobileSplashContainer,
        Skeleton,
        oButton,
        Text,
        Tabs,
        Link,
    },
    setup() {
        const pageSelectorLinks = [
            {
                key: 'tools',
                to: { name: Routes.ToolkitTools },
            },
            {
                key: 'history',
                to: { name: Routes.ToolkitHistory },
            },
        ]
        const { userInfo } = useUser()
        const linkToFeatureRequests = computed(() => {
            return `/user/${userInfo.value?.user_id}/featurerequests`
        })
        return {
            pageSelectorLinks,
            linkToFeatureRequests,
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

@media screen and (max-width: $mq-767-max) {
    .preferences-button {
        position: absolute;
        top: 32px;
        right: 48px;
    }
}

@media screen and (max-width: $mq-480-max) {
    .preferences-button {
        position: absolute;
        top: 32px;
        right: 16px;
    }
}
</style>
