<template>
    <div class="inline-input-container">
        <slot v-if="$slots.mobile && !aboveMobile" name="mobile" />

        <div class="left-side">
            <slot
                v-if="($slots['left-side'] && aboveMobile) || ($slots['left-side'] && singleSide)"
                name="left-side"
            />
        </div>

        <Spacer v-if="aboveMobile" width="1rem" />
        <Spacer v-else height="1.25rem" />

        <slot v-if="$slots.input" name="input" />

        <Spacer v-if="aboveMobile" width="1rem" />

        <div class="right-side">
            <slot v-if="$slots['right-side'] && aboveMobile" name="right-side" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Spacer } from '@opteo/components-next'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default defineComponent({
    name: 'InlineInputContainer',
    props: {
        singleSide: {
            type: Boolean as PropType<boolean>,
            default: false,
            required: false,
        },
    },
    setup() {
        const { aboveMobile } = useMediaQuery()

        return { aboveMobile }
    },
    components: {
        Spacer,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.inline-input-container {
    @include container;
    @include ph-32;
    @include pv-28;
    @include flex;
    flex-direction: column;
}

@media (min-width: 767px) {
    .inline-input-container {
        flex-direction: row;
        @include items-start;
    }
    .left-side,
    .right-side {
        line-height: 2.8125rem;
    }
}
</style>
