<template>
    <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.4 9h5.2c1.1366 0 1.9289.00078 2.5458.05118.6051.04944.9528.14161 1.2162.2758.5645.28762 1.0234.74652 1.311 1.31102.1342.2634.2264.6111.2758 1.2162.0504.6169.0512 1.4092.0512 2.5458v5.2c0 1.1366-.0008 1.9289-.0512 2.5458-.0494.6051-.1416.9528-.2758 1.2162-.2876.5645-.7465 1.0234-1.311 1.311-.2634.1342-.6111.2264-1.2162.2758-.6169.0504-1.4092.0512-2.5458.0512h-5.2c-1.1366 0-1.9289-.0008-2.5458-.0512-.6051-.0494-.9528-.1416-1.2162-.2758-.5645-.2876-1.0234-.7465-1.31102-1.311-.13419-.2634-.22636-.6111-.2758-1.2162C9.00078 21.5289 9 20.7366 9 19.6v-5.2c0-1.1366.00078-1.9289.05118-2.5458.04944-.6051.14161-.9528.2758-1.2162.28762-.5645.74652-1.0234 1.31102-1.31102.2634-.13419.6111-.22636 1.2162-.2758C12.4711 9.00078 13.2634 9 14.4 9Z"
            stroke="#006DFF"
            stroke-width="2"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.4 2h5.2c1.1531 0 1.8977.00156 2.4643.04785.5434.0444.7448.11975.8437.17014.3763.19174.6823.4977.874.87403.0504.09889.1258.30033.1701.84365C15.9984 4.50235 16 5.24689 16 6.4V8h2V6.4c0-2.24021 0-3.36031-.436-4.21596-.3835-.75265-.9954-1.364573-1.748-1.748066C14.9603 0 13.8402 0 11.6 0H6.4C4.15979 0 3.03969 0 2.18404.435974 1.43139.819467.819467 1.43139.435974 2.18404 0 3.03969 0 4.15979 0 6.4v5.2c0 2.2402 0 3.3603.435974 4.216.383493.7526.995416 1.3645 1.748066 1.748C3.03969 18 4.15979 18 6.4 18H8v-2H6.4c-1.15311 0-1.89765-.0016-2.46433-.0479-.54332-.0443-.74476-.1197-.84365-.1701-.37633-.1917-.68229-.4977-.87403-.874-.05039-.0989-.12574-.3003-.17014-.8437C2.00156 13.4977 2 12.7531 2 11.6V6.4c0-1.15311.00156-1.89765.04785-2.46433.0444-.54332.11975-.74476.17014-.84365.19174-.37633.4977-.68229.87403-.87403.09889-.05039.30033-.12574.84365-.17014C4.50235 2.00156 5.24689 2 6.4 2Z"
            fill="#006DFF"
        />
    </svg>
</template>
