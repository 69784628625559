<template>
    <div>
        <div>table</div>
        <table class="f6 w-100 center" cellspacing="0">
            <thead>
                <tr class="">
                    <th
                        style="height: 56px"
                        class="
                            small-caps
                            bb
                            b--opteo-near-white
                            v-mid
                            w-10
                            bg-transparent
                            tl
                            pl4-ns
                            pl-24
                        "
                    >
                        Type
                    </th>
                    <th
                        style="height: 56px; min-width: 128px"
                        class="small-caps bb bl b--opteo-near-white v-mid w-15 bg-transparent tc"
                    >
                        KPI Anomalies
                    </th>
                    <th
                        style="height: 56px; min-width: 86px"
                        class="
                            small-caps
                            bb
                            bl
                            b--opteo-near-white
                            v-mid
                            w-15
                            bg-transparent
                            tc
                            ph3
                        "
                    >
                        Budget
                    </th>
                    <th
                        style="height: 56px; min-width: 158px"
                        class="small-caps bb bl b--opteo-near-white v-mid w-15 bg-transparent tc"
                    >
                        New Improvements
                    </th>
                    <th
                        style="height: 56px; min-width: 162px"
                        class="small-caps bb bl b--opteo-near-white v-mid w-15 bg-transparent tc"
                    >
                        Conv. Performance
                    </th>
                    <th
                        style="height: 56px; min-width: 146px"
                        class="small-caps bb bl b--opteo-near-white v-mid w-15 bg-transparent tc"
                    >
                        Zero Impressions
                    </th>
                </tr>
            </thead>
            <tbody class="lh-copy">
                <tr v-for="medium in alertMediums" :key="medium.type">
                    <td class="pl4-ns pl-24 v-mid h3 pr4 bt b--opteo-near-white tl">
                        <div class="fw5 tracked-tight lh-copy">
                            {{ medium.display }}
                        </div>
                    </td>
                    <td
                        v-for="(type, index) in alertTypes"
                        :key="index"
                        class="v-mid h3 bt bl b--opteo-near-white tl"
                    >
                        <span class="w2 h2 flex items-center justify-center pointer center">
                            Careful, if you render the chechbox before the data is loaded, it will
                            fire the @updated event when the data is populated, causing 10
                            simultaneous unecessary updates to the server. That's why use're using a
                            v-if here.

                            <oInput
                                v-if="!alertSettingsLoading"
                                v-model="medium.settings[type]"
                                type="checkbox"
                                @updated="updateAlertSettings()"
                            />
                            <oInput v-if="alertSettingsLoading" type="checkbox" disabled />
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        name: {
            type: String,
            default: false,
        },
        description: {
            type: String,
            default: false,
        },
    },
    setup() {},
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.channel-item {
    @include container;
}
</style>
