<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.344651 12.2416C-0.0458732 12.6321 -0.0458732 13.2652 0.344651 13.6558C0.735175 14.0463 1.36834 14.0463 1.75886 13.6558L7.00021 8.41442L12.2416 13.6558C12.6321 14.0463 13.2652 14.0463 13.6558 13.6558C14.0463 13.2652 14.0463 12.6321 13.6558 12.2416L8.41442 7.0002L13.6558 1.75886C14.0463 1.36834 14.0463 0.735175 13.6558 0.344651C13.2652 -0.0458736 12.6321 -0.0458733 12.2415 0.344651L7.00021 5.58599L1.75888 0.344651C1.36835 -0.0458733 0.735188 -0.0458736 0.344664 0.344651C-0.0458598 0.735175 -0.0458595 1.36834 0.344664 1.75886L5.586 7.0002L0.344651 12.2416Z"
            :fill="color"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MobileNavCloseIcon',
    props: {
        size: {
            type: Number,
            required: false,
            default: 14,
        },
        color: {
            type: String,
            required: false,
            default: '#006DFF',
        },
    },
})
</script>
