import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Improvement } from '@opteo/types'
import groupBy from 'lodash-es/groupBy'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

import { Endpoint, useAPI } from '../api/useAPI'
import { useAccount } from '../account/useAccount'

export function useCompletedImprovements() {
    const { accountId, accountInfo } = useAccount()

    const route = useRoute()
    const improvementOpen = computed(() => !!route.params.improvementId)

    const limit = ref(50)
    const {
        data: completedImprovements,
        error,
        isValidating: moreLoading,
        loading,
        mutate,
    } = useAPI<Improvement.CompletedObject[]>(Endpoint.ListCompletedImprovements, {
        body: () => {
            return {
                limit: limit.value,
            }
        },
        uniqueId: () => `${accountId.value}`,
        waitFor: () => accountId.value,
    })

    watch(limit, () => mutate())

    const improvements = computed(() => {
        return groupBy(completedImprovements.value, i => {
            // TODO: Backend type is incorrect, should be string or parsed to Date
            return format(parseISO(i.completed_timestamp as unknown as string), 'EEEE do MMMM')
        })
    })

    const moreAvailable = computed(() => (accountInfo.value?.tasksCompleted ?? 0) >= limit.value)

    return {
        improvements,
        loading,
        error,
        improvementOpen,
        limit,
        moreAvailable,
        moreLoading,
    }
}
