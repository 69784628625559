import { ComputedRef, reactive, Ref, ref, watch } from 'vue'

import { noAuthRequest, useAPI } from '@/composition/api/useAPI'
import { Endpoint } from '@/composition/api/endpoints'

import { Scorecard } from '@opteo/types'

export function useScorecardBreakdowns(params: { scorecardId: ComputedRef<string | undefined> }) {
    const { scorecardId } = params

    const scorecardBreakdowns: Partial<Scorecard.ScorecardBreakdowns> = reactive({})
    const scorecardLoadingBreakdowns: Partial<
        Record<keyof Scorecard.ScorecardBreakdowns, boolean>
    > = reactive({})

    const breakdownToFetch: Ref<Scorecard.SectionTypeWithBreakdown | undefined> = ref()
    const { data: breakdownData } = useAPI<Partial<Scorecard.ScorecardBreakdowns>>(
        Endpoint.GetScorecardSectionBreakdown,
        {
            uniqueId: () => `${scorecardId.value}:${breakdownToFetch.value}:breakdown`,
            waitFor: () => !!scorecardId.value && !!breakdownToFetch.value,
            body: () => ({ scorecardId: scorecardId.value, sectionType: breakdownToFetch.value }),
        }
    )

    watch(breakdownData, data => {
        if (!breakdownToFetch.value) return
        Object.assign(scorecardBreakdowns, { ...data })
        Object.assign(scorecardLoadingBreakdowns, { [breakdownToFetch.value]: false })
        breakdownToFetch.value = undefined
    })

    const fetchBreakdown = (sectionType: Scorecard.SectionTypeWithBreakdown) => {
        if (scorecardLoadingBreakdowns[sectionType] || scorecardBreakdowns[sectionType]) return
        breakdownToFetch.value = sectionType
        Object.assign(scorecardLoadingBreakdowns, { [sectionType]: true })
    }

    return {
        scorecardBreakdowns,
        scorecardLoadingBreakdowns,
        fetchBreakdown,
    }
}

export function useNoAuthScorecardBreakdowns(params: { scorecardLinkId: string }) {
    const { scorecardLinkId } = params

    const scorecardBreakdowns: Partial<Scorecard.ScorecardBreakdowns> = reactive({})
    const scorecardLoadingBreakdowns: Partial<
        Record<keyof Scorecard.ScorecardBreakdowns, boolean>
    > = reactive({})

    const fetchBreakdown = async (sectionType: Scorecard.SectionTypeWithBreakdown) => {
        if (scorecardLoadingBreakdowns[sectionType] || scorecardBreakdowns[sectionType]) return
        Object.assign(scorecardLoadingBreakdowns, { [sectionType]: true })

        const { data, meta } = await noAuthRequest<
            | {
                  data: Partial<Scorecard.ScorecardBreakdowns>
                  meta: { type: 'result' }
              }
            | { data: string; meta: { type: 'error' } }
        >(`/api/${Endpoint.GetScorecardSectionBreakdownFromShareableLink}`, {
            meta: {
                function: 'scorecard:getScorecardSectionBreakdownFromShareableLink',
                args: { scorecardLinkId, sectionType },
            },
        })

        if (meta.type === 'error' || typeof data === 'string') {
            throw new Error(data as string)
        }

        Object.assign(scorecardBreakdowns, { ...data })
        Object.assign(scorecardLoadingBreakdowns, { [sectionType]: false })
    }

    return { scorecardBreakdowns, scorecardLoadingBreakdowns, fetchBreakdown }
}
