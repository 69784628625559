<template>
    <div class="ent di">
        <b>
            <slot>ENT</slot>
        </b>
    </div>
</template>
<script>
export default {
    name: 'Ent',
}
</script>
<style scoped>
.ent {
    padding: 0;
    margin: 0;
    color: '#006dff';
    word-break: break-word;
}
</style>
