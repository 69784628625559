<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.75 10.7531V5.25c0-.19891.07902-.38968.21967-.53033C4.11032 4.57902 4.30109 4.5 4.5 4.5h15c.1989 0 .3897.07902.5303.21967.1407.14065.2197.33142.2197.53033v5.5031c0 7.875-6.6844 10.4813-8.0156 10.9219-.1513.056-.3175.056-.4688 0-1.3312-.4406-8.0156-3.0469-8.0156-10.9219Z"
            stroke="#000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.125 9.75 10.6219 15 7.875 12.375"
            stroke="#000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
