import { computed, ref, Ref, ComputedRef, inject } from 'vue'
import isNaN from 'lodash-es/isNaN'

import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { AdjustParentalBid } from '@opteo/types'
import {
    OnPushHandler,
    useImprovement,
    UseImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { BarChartTypes } from '@opteo/components-next'
import { CurrencyCode } from '@/composition/utils/types'
import { ProvideKeys } from '@/composition/useProvide'

const PARENTALS = ['Parent', 'Not a parent', 'Undetermined']

interface UseAdjustParentalBid {
    usingTargetCpa: boolean
    improvement: Ref<AdjustParentalBid.AdjustParentalBidBody> | Ref
    parentalStatusCpaToCampaignRatio: ComputedRef<number>
    parentalStatusCpaToCampaignGroupRatio: ComputedRef<number>
    statsCampaignCpa: ComputedRef<string>
    statsCpa: ComputedRef<string>
    statsBucketCpa: ComputedRef<string>
    campaignCpa: number
    bucketCpa: number
    adjustedBid: Ref<number>
    currentBidModRelativeToUnitBid: Ref<number>
    onBidUpdate: (updatedBid: number) => void
    barChartItems: BarChartTypes.BarChartItem[]
    domainCurrencyCode: CurrencyCode | undefined
    campaignName: string
    campaignGroupName: string
    parentalStatus: string
    negativeBidMod: number
    // Table Data
    TableHeaders: any[]
    TableItems: any[]
}

type BidDirection = 'increase' | 'decrease'

interface PushedData {
    sign: BidDirection
    value: number
}

export function useAdjustParentalBid(): UseImprovement<UseAdjustParentalBid> {
    const bidToPush = ref()
    const sign = ref()
    const bidAdjusted = ref(false)

    function setDefaultCustomValues() {
        bidToPush.value = negativeBidMod
        sign.value = 'decrease'
    }

    const { improvement, lastUpdated, title } =
        useImprovement<AdjustParentalBid.AdjustParentalBidBody>()

    const {
        body: {
            negative_bid_mod: negativeBidMod,
            cpa,
            campaign_cpa: campaignCpa,
            bucket_cpa: bucketCpa,
            using_target_cpa: usingTargetCpa,
            other_parentals: otherParentals,
            conversion_type_name: campaignGroupName,
            campaign_label: campaignName,
            parental_status: parentalStatus,
        },
    } = checkImprovement<AdjustParentalBid.AdjustParentalBidBody>(improvement)

    const currentBidModRelativeToUnitBid = ref(1 - negativeBidMod / 100)
    const adjustedBid = ref(1)

    setDefaultCustomValues()

    const { displayValue: statsCpa } = useDomainMoney({
        value: cpa,
    }).value
    const { displayValue: statsCampaignCpa } = useDomainMoney({
        value: campaignCpa,
    }).value
    const { displayValue: statsBucketCpa } = useDomainMoney({
        value: bucketCpa,
    }).value

    const parentalStatusCpaToCampaignRatio = computed(() => {
        return (cpa - campaignCpa) / campaignCpa
    })

    const parentalStatusCpaToCampaignGroupRatio = computed(() => {
        return (cpa - bucketCpa) / bucketCpa
    })

    const barChartItems: BarChartTypes.BarChartItem[] = PARENTALS.map(type => {
        const currentParentalStatus: AdjustParentalBid.OtherParentals | undefined =
            otherParentals.find(a => a.parental_status === type)

        const highlighted = parentalStatus === type
        let y_value = 0

        if (highlighted) {
            y_value = isFinite(cpa) ? cpa : 0
        } else {
            y_value = currentParentalStatus?.cost
                ? isFinite(currentParentalStatus?.cost) && currentParentalStatus?.conversions !== 0
                    ? currentParentalStatus?.cost / currentParentalStatus?.conversions
                    : 0
                : 0
        }

        return {
            y: y_value,
            label: type,
            highlighted,
        }
    }).filter(chart_item => chart_item?.y)

    // Table Data

    const TableHeaders = [
        { key: 'parental_status', text: 'Par. Status', sortable: true },
        { key: 'cost', text: 'Cost', sortable: true },
        { key: 'cpa', text: 'CPA', sortable: true },
        { key: 'average-cpa', text: 'Avg. CPA' },
        { key: 'difference', text: 'Diff.', sortable: true },
    ]

    const TableData = [
        {
            parental_status: parentalStatus,
            cost: improvement.value!.body.cost,
            conversions: improvement.value!.body.conversions,
            clicks: improvement.value!.body.clicks,
        },
        ...otherParentals,
    ]

    const TableItems = TableData.map(group => {
        return {
            id: group.parental_status,
            parental_status: group.parental_status,
            cost: group.cost,
            cpa: group.cost / group.conversions,
            'average-cpa': campaignCpa,
            difference: (group.cost / group.conversions - campaignCpa) / campaignCpa,
        }
    })

    const domainCurrencyCode = inject<CurrencyCode>(ProvideKeys.CurrencyCode)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Applying parental status bid adjustment..',
        'Confirming changes..',
        'Bid adjustment applied successfully.',
    ])

    const pushActionText = ref('Apply Bid Adjustment')

    const onPush: OnPushHandler<PushedData> = () => {
        const pushedData = {
            sign: sign.value,
            value: parseFloat(bidToPush.value?.toFixed(2) ?? '0'),
        }
        const valid = validate(pushedData)

        if (!bidAdjusted.value) {
            return { valid }
        }

        return {
            valid,
            pushedData,
        }
    }

    function validate(pushedData: { value: number; sign: string }) {
        if (+pushedData.value === 0 || isNaN(+pushedData.value)) {
            return false
        } else if (pushedData.value < 0) {
            return false
        } else if (pushedData.sign === 'decrease' && pushedData.value > 90) {
            return false
        } else if (pushedData.sign === 'increase' && pushedData.value > 900) {
            return false
        }

        return true
    }

    function onBidUpdate(updatedBid: number) {
        const absolutePercentBid = (updatedBid - 1) * 100
        sign.value = absolutePercentBid > 0 ? 'increase' : 'decrease'
        bidToPush.value = Math.abs(absolutePercentBid)

        if (
            sign.value !== 'decrease' ||
            updatedBid.toFixed(2) !== ((100 - negativeBidMod) / 100).toFixed(2)
        ) {
            bidAdjusted.value = true
        } else {
            bidAdjusted.value = false
        }
    }

    return {
        lastUpdated,
        campaignName,
        campaignGroupName,
        title,
        pushMessages,
        onPush,
        onBidUpdate,
        improvement,
        usingTargetCpa,
        statsCampaignCpa,
        statsCpa,
        statsBucketCpa,
        bucketCpa,
        campaignCpa,
        currentBidModRelativeToUnitBid,
        adjustedBid,
        barChartItems,
        domainCurrencyCode,
        parentalStatusCpaToCampaignRatio,
        parentalStatusCpaToCampaignGroupRatio,
        parentalStatus,
        negativeBidMod,
        // Table Data
        TableHeaders,
        TableItems,

        pushActionText,
    }
}
