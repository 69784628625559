<template>
    <div class="no-chart-data-wrapper">
        <div class="copy-wrapper">
            <Text as="p" size="f-9">
                <span v-if="emptyState === 'experiment-complete'">
                    Graph data is unavailable for this experiment. Too little data was collected to
                    make a statistically significant comparison.
                </span>
                <span v-else-if="emptyState === 'insufficient-conversion-data'">
                    The graphs will become available once the experiment has generated at least one
                    conversion. Please check back soon.
                </span>
                <span v-else-if="emptyState === 'insufficient-impression-data'">
                    The graphs will become available once the experiment has generated sufficient
                    impression share and conversion data.
                </span>
                <span v-else-if="emptyState === 'no-points'">
                    Please allow some time for the experiment to collect data. The graphs will
                    become available once the experiment has collected sufficient data to make a
                    statistical comparison.
                </span>
                <!-- Debugging -->
                <span v-else> Oops, something's gone wrong. Graph data is unavailable.</span>
            </Text>
        </div>

        <div class="chart-wrapper">
            <PerformanceChartContainer :skeleton="true" />
        </div>
    </div>
</template>

<script lang="ts">
import { Text, PerformanceChartContainer } from '@opteo/components-next'

export default {
    name: 'NoChartData',
    components: {
        Text,
        PerformanceChartContainer,
    },
    props: {
        // 'experiment-complete' | 'insufficient-conversion-data' | 'insufficient-impression-data' | 'no-points'
        emptyState: {
            type: String,
            required: false,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.no-chart-data-wrapper {
    @include container;
    @include br-16;
    @include w-100;
    overflow: hidden;
    @include flex-center;
    @include relative;
}

.copy-wrapper {
    @include container;
    @include br-16;
    @include pv-28;
    @include ph-32;
    @include w-100;
    max-width: 25.75rem;
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 2;
}

.chart-wrapper {
    width: 100%;
    overflow: hidden;
}

.no-chart-data-wrapper :deep(svg) {
    @include block;
}
.no-chart-data-wrapper :deep(.chart) {
    width: 78rem;
}
</style>
