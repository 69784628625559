<template>
    <svg
        width="680"
        height="408"
        viewBox="0 0 680 408"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1812_8974)">
            <path fill="#fff" d="M0 0h680v408H0z" />
            <mask id="path-1-inside-1_1812_8974" fill="#fff"><path d="M0 0h46v408H0V0Z" /></mask>
            <path d="M0 0h46v408H0V0Z" fill="#fff" />
            <path
                d="M45 0v408h2V0h-2Z"
                fill="#000352"
                fill-opacity=".03"
                mask="url(#path-1-inside-1_1812_8974)"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 22.9141c0-.601.5146-1.1172 1.1172-1.1172.6683 0 1.1822.5162 1.2031 1.1172-.0209 4.8561 3.8821 8.7763 8.6797 8.7656 4.7976.0107 8.7006-3.9095 8.6797-8.7656.0209-.601.5348-1.1172 1.2031-1.1172.6026 0 1.1172.5162 1.1172 1.1172C34 29.0436 29.0654 34 23 34s-11-4.9564-11-11.0859Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M31.6797 23.0859c.0209-4.8561-3.8821-8.7763-8.6797-8.7656-4.7976-.0107-8.7006 3.9095-8.6797 8.7656-.0209.601-.5348 1.1172-1.2031 1.1172-.6026 0-1.1172-.5162-1.1172-1.1172C12 16.9564 16.9346 12 23 12s11 4.9564 11 11.0859c0 .601-.5146 1.1172-1.1172 1.1172-.6683 0-1.1822-.5162-1.2031-1.1172Z"
                fill="#73D8FF"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M31.7656 23.6016c-1.7933-3.2881-5.3071-4.7495-8.9375-4.7266-3.4166-.0229-6.7722 1.389-8.5938 4.7266-.411.4918-1.1128.6769-1.7187.3437-.4933-.3037-.6785-1.006-.3438-1.6328 2.3076-3.9082 6.4247-5.7578 10.6563-5.7578 4.4331 0 8.7278 1.91 11 5.7578.3347.6268.1495 1.3291-.3438 1.6328-.238.1211-.4368.1719-.6015.1719-.4277 0-.813-.2044-1.1172-.5156Z"
                fill="#3FBBFF"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.1719 23.6875c-.3346-.6269-.1493-1.3299.3437-1.6328.6036-.3346 1.308-.1495 1.7188.3438 1.7916 3.2886 5.3074 4.7494 8.9375 4.7265 3.4164.0229 6.7727-1.3891 8.5937-4.7265.411-.4933 1.116-.6758 1.7188-.3438.4932.3029.6785 1.0059.3437 1.6328-2.3063 3.9088-6.4236 5.7578-10.6562 5.7578-4.4341 0-8.7289-1.91-11-5.7578Z"
                fill="#006DFF"
            />
            <path
                d="M23 70c5.5228 0 10-4.4772 10-10s-4.4772-10-10-10-10 4.4772-10 10 4.4772 10 10 10Z"
                fill="url(#paint0_linear_1812_8974)"
            />
            <path
                d="M22.47 55.0001c-1.1088 0-2.0938.7043-2.4372 1.7439-.7128.145-1.328.5854-1.6888 1.209-.556.9481-.4291 2.1402.3159 2.9529-.23.6807-.1511 1.4256.2162 2.042.5527.9515 1.6648 1.4392 2.753 1.2123.482.5367 1.1748.8427 1.9016.8398 1.1087 0 2.0938-.7043 2.4371-1.744.714-.1456 1.3285-.5859 1.6854-1.2089.5595-.9481.4325-2.1402-.3124-2.9529v-.0033c.2299-.6808.1511-1.4262-.2162-2.0454-.5528-.9482-1.665-1.436-2.7496-1.2091-.4842-.5352-1.1779-.8398-1.905-.8363Zm0 .6502-.0034.0034c.4462 0 .8753.1523 1.2185.4335-.0137.0067-.0412.0236-.0617.0338l-2.0183 1.148c-.103.0576-.1648.1659-.1648.2845v2.6955l-.8684-.4943v-2.2283c-.0004-1.035.849-1.8745 1.8981-1.8761Zm2.4313.7847c.6807-.0012 1.3102.357 1.65.939.2197.3793.302.8229.2265 1.253-.0138-.0102-.0412-.0237-.0585-.0339l-2.0182-1.1514c-.1033-.0576-.2296-.0576-.3329 0l-2.365 1.3477v-.9888l1.953-1.1142c.2872-.1642.6131-.2509.9451-.2514Zm-4.9919 1.0236v2.3671c0 .1185.0617.2235.1648.2845l2.3615 1.3444-.8719.4977-1.9498-1.1108c-.9072-.5187-1.2174-1.6641-.6932-2.5601.222-.3798.5718-.6708.9886-.8228Zm4.524.5858 1.953 1.1108c.9097.5181 1.2186 1.6627.6934 2.5601l.0033.0034c-.223.3793-.5732.6705-.9885.8196V60.171c0-.1185-.0618-.2269-.1648-.2844l-2.365-1.3478.8686-.4944Zm-1.4349.8162.9956.5689v1.1344l-.9956.5689-.9953-.5689v-1.1344l.9953-.5689Zm1.5653.8941.8684.4943v2.2249c0 1.0363-.8513 1.8761-1.8981 1.8761v-.0033c-.4429 0-.8754-.1524-1.2152-.4334.0137-.0068.0446-.0238.0618-.034l2.0182-1.148c.1031-.0576.1683-.1659.1648-.2845l.0001-2.6921Zm-.5665 1.4561v.9887l-1.953 1.1108c-.9097.5147-2.0699.21-2.5951-.6841h.0035c-.2231-.3759-.3022-.8229-.2266-1.2529.0137.0101.0412.0236.0583.0338l2.0183 1.1514c.1033.0577.2296.0577.3329 0l2.3617-1.3477Z"
                fill="#fff"
            />
            <g opacity=".2" stroke="#010105">
                <path
                    d="M23 329.5c4.1421 0 7.5-3.358 7.5-7.5 0-4.142-3.3579-7.5-7.5-7.5s-7.5 3.358-7.5 7.5c0 4.142 3.3579 7.5 7.5 7.5ZM23 321.5v4"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                />
                <path
                    d="M23.5 318.75c0 .276-.2239.5-.5.5s-.5-.224-.5-.5.2239-.5.5-.5.5.224.5.5Z"
                    fill="#010105"
                    stroke-width=".5"
                />
            </g>
            <g opacity=".2" stroke="#010105" stroke-miterlimit="10" stroke-linecap="square">
                <path
                    d="M21.5 360.861c.1941.202.427.363.6848.473.2577.109.535.166.8152.166s.5575-.057.8152-.166c.2578-.11.4907-.271.6848-.473M28 353.5v-2.05c-.0396-1.309-.5806-2.552-1.5112-3.474-.9306-.921-2.1796-1.45-3.4888-1.476-1.3141.038-2.5641.577-3.4937 1.506-.9296.93-1.4685 2.18-1.5063 3.494v2c0 2.25-1.8 2.65-1.8 3.9 0 1.15 2.65 2.05 6.8 2.05s6.8-.9 6.8-2.05c0-1.25-1.8-1.65-1.8-3.9Z"
                />
            </g>
            <rect x="23.5" y="344" width="9" height="9" rx="4.5" fill="#FF1414" />
            <path
                d="M28 348.207c.305 0 .48-.195.48-.425 0-.215-.175-.41-.48-.41-.3 0-.475.195-.475.41 0 .23.175.425.475.425Zm0 1.42c.36 0 .55-.22.55-.45 0-.235-.185-.46-.55-.46-.365 0-.545.225-.545.46 0 .23.19.45.545.45Zm0 .57c-.74 0-1.225-.39-1.225-.95 0-.35.23-.66.575-.79-.325-.12-.51-.435-.51-.725 0-.545.485-.93 1.16-.93.675 0 1.16.385 1.16.93 0 .285-.18.605-.505.725.34.125.57.44.57.79 0 .56-.485.95-1.225.95Z"
                fill="#fff"
            />
            <mask
                id="mask0_1812_8974"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="14"
                y="378"
                width="18"
                height="18"
            >
                <path
                    d="M32 387c0-4.971-4.0294-9-9-9s-9 4.029-9 9 4.0294 9 9 9 9-4.029 9-9Z"
                    fill="#fff"
                />
            </mask>
            <g mask="url(#mask0_1812_8974)">
                <path
                    d="M31.55 378h-17.1c-.2485 0-.45.201-.45.45v17.1c0 .249.2015.45.45.45h17.1c.2485 0 .45-.201.45-.45v-17.1c0-.249-.2015-.45-.45-.45Z"
                    fill="#6129FF"
                />
                <g filter="url(#filter0_f_1812_8974)">
                    <path
                        d="m13.7532 380.946-6.77939 2.225-4.7996 5.719 18.35629 15.403 8.4113-10.024-2.0208-4.88-11.1355-.948-2.0323-7.495Z"
                        fill="#FF3D62"
                    />
                </g>
                <g style="mix-blend-mode: overlay" filter="url(#filter1_f_1812_8974)">
                    <path
                        d="m24.893 387.583 9.2296-5.476-1.2686-13.662-20.7308-5.947-4.17347 19.593 7.64507.649-2.4656-7.713 10.0051-6.891 1.759 19.447h-.0003Z"
                        fill="#FF9500"
                    />
                </g>
            </g>
            <g filter="url(#filter2_ddddd_1812_8974)">
                <rect x="87" y="-139" width="552" height="188" rx="18" fill="#fff" />
                <g filter="url(#filter3_ddddd_1812_8974)">
                    <g clip-path="url(#clip1_1812_8974)">
                        <rect x="103" y="-123" width="520" height="156" rx="12" fill="#fff" />
                        <g filter="url(#filter4_dddd_1812_8974)">
                            <rect x="551" y="-12" width="57" height="11" rx="5.5" fill="#fff" />
                        </g>
                        <path stroke="#000352" stroke-opacity=".03" d="M103 6.5h516" />
                        <g filter="url(#filter5_ddddd_1812_8974)">
                            <circle cx="340" cy="20" r="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter6_ddddd_1812_8974)">
                            <circle cx="354" cy="20" r="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter7_ddddd_1812_8974)">
                            <circle cx="368" cy="20" r="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter8_ddddd_1812_8974)">
                            <circle cx="382" cy="20" r="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter9_ddddd_1812_8974)">
                            <circle cx="396" cy="20" r="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter10_ddddd_1812_8974)">
                            <rect x="571" y="14" width="37" height="11" rx="4" fill="#fff" />
                        </g>
                        <g filter="url(#filter11_ddddd_1812_8974)">
                            <rect x="116" y="14" width="37" height="11" rx="4" fill="#fff" />
                        </g>
                    </g>
                </g>
            </g>
            <g filter="url(#filter12_ddddd_1812_8974)">
                <rect x="87" y="65" width="552" height="300" rx="18" fill="#fff" />
                <rect x="103" y="81" width="88" height="14" rx="7" fill="#ECECEF" />
                <g filter="url(#filter13_dd_1812_8974)">
                    <rect x="566" y="79" width="57" height="18" rx="9" fill="#fff" />
                </g>
                <g filter="url(#filter14_ddddd_1812_8974)">
                    <g clip-path="url(#clip2_1812_8974)">
                        <rect x="103" y="111" width="520" height="238" rx="12" fill="#fff" />
                        <mask id="path-35-inside-2_1812_8974" fill="#fff">
                            <path d="M103 111h520v34H103v-34Z" />
                        </mask>
                        <path d="M103 111h520v34H103v-34Z" fill="#fff" />
                        <path
                            d="M623 144H103v2h520v-2Z"
                            fill="#000352"
                            fill-opacity=".03"
                            mask="url(#path-35-inside-2_1812_8974)"
                        />
                        <rect x="115" y="124" width="88" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="311" y="124" width="36" height="8" rx="4" fill="#F3F3F7" />
                        <g filter="url(#filter15_ddddd_1812_8974)">
                            <circle cx="358" cy="128" r="5" fill="#fff" />
                        </g>
                        <rect x="395" y="124" width="36" height="8" rx="4" fill="#F3F3F7" />
                        <g filter="url(#filter16_ddddd_1812_8974)">
                            <circle cx="442" cy="128" r="5" fill="#fff" />
                        </g>
                        <rect x="479" y="124" width="28" height="8" rx="4" fill="#F3F3F7" />
                        <g filter="url(#filter17_ddddd_1812_8974)">
                            <circle cx="518" cy="128" r="5" fill="#fff" />
                        </g>
                        <rect x="555" y="124" width="36" height="8" rx="4" fill="#F3F3F7" />
                        <mask id="path-45-inside-3_1812_8974" fill="#fff">
                            <path d="M103 145h520v34H103v-34Z" />
                        </mask>
                        <path d="M103 145h520v34H103v-34Z" fill="#fff" />
                        <path
                            d="M623 178H103v2h520v-2Z"
                            fill="#000352"
                            fill-opacity=".03"
                            mask="url(#path-45-inside-3_1812_8974)"
                        />
                        <mask
                            id="mask1_1812_8974"
                            style="mask-type: luminance"
                            maskUnits="userSpaceOnUse"
                            x="115"
                            y="155"
                            width="14"
                            height="14"
                        >
                            <path
                                d="M129 162c0-3.866-3.134-7-7-7s-7 3.134-7 7 3.134 7 7 7 7-3.134 7-7Z"
                                fill="#fff"
                            />
                        </mask>
                        <g mask="url(#mask1_1812_8974)">
                            <path
                                d="M128.65 155h-13.3c-.193 0-.35.157-.35.35v13.3c0 .193.157.35.35.35h13.3c.193 0 .35-.157.35-.35v-13.3c0-.193-.157-.35-.35-.35Z"
                                fill="#6129FF"
                            />
                            <g filter="url(#filter18_f_1812_8974)">
                                <path
                                    d="m114.808 157.291-5.273 1.731-3.733 4.448 14.277 11.98 6.542-7.796-1.571-3.796-8.661-.737-1.581-5.83Z"
                                    fill="#FF3D62"
                                />
                            </g>
                            <g style="mix-blend-mode: overlay" filter="url(#filter19_f_1812_8974)">
                                <path
                                    d="m123.472 162.454 7.179-4.259-.987-10.627-16.124-4.625-3.246 15.239 5.946.505-1.917-6 7.782-5.359 1.368 15.126h-.001Z"
                                    fill="#FF9500"
                                />
                            </g>
                        </g>
                        <rect x="139" y="158" width="88" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="311" y="158" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="395" y="158" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="479" y="158" width="36" height="8" rx="4" fill="#F3F3F7" />
                        <g filter="url(#filter20_ddddd_1812_8974)">
                            <rect x="555" y="155" width="56" height="14" rx="7" fill="#fff" />
                        </g>
                        <mask id="path-56-inside-4_1812_8974" fill="#fff">
                            <path d="M103 179h520v34H103v-34Z" />
                        </mask>
                        <path d="M103 179h520v34H103v-34Z" fill="#fff" />
                        <path
                            d="M623 212H103v2h520v-2Z"
                            fill="#000352"
                            fill-opacity=".03"
                            mask="url(#path-56-inside-4_1812_8974)"
                        />
                        <mask
                            id="mask2_1812_8974"
                            style="mask-type: luminance"
                            maskUnits="userSpaceOnUse"
                            x="115"
                            y="189"
                            width="14"
                            height="14"
                        >
                            <path
                                d="M129 196c0-3.866-3.134-7-7-7s-7 3.134-7 7 3.134 7 7 7 7-3.134 7-7Z"
                                fill="#fff"
                            />
                        </mask>
                        <g mask="url(#mask2_1812_8974)">
                            <path
                                d="M128.65 189h-13.3c-.193 0-.35.157-.35.35v13.3c0 .193.157.35.35.35h13.3c.193 0 .35-.157.35-.35v-13.3c0-.193-.157-.35-.35-.35Z"
                                fill="#FF3D62"
                            />
                            <g filter="url(#filter21_f_1812_8974)">
                                <path
                                    d="m127.232 195.332 1.632-4.524-1.047-4.924-15.799 3.359 1.834 8.628 3.431.951 5.124-5.521 4.825 2.031Z"
                                    fill="#FF9500"
                                />
                            </g>
                            <g style="mix-blend-mode: overlay" filter="url(#filter22_f_1812_8974)">
                                <path
                                    d="m120.442 196.386-2.153 6.906 6.797 6.272 13.067-6.372-6.746-11.697-4.123 3.123 4.714 2.752-1.903 7.965-9.653-8.949Z"
                                    fill="#00AD9C"
                                />
                            </g>
                        </g>
                        <rect x="139" y="192" width="88" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="311" y="192" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="395" y="192" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="479" y="192" width="36" height="8" rx="4" fill="#F3F3F7" />
                        <g filter="url(#filter23_ddddd_1812_8974)">
                            <rect x="555" y="189" width="56" height="14" rx="7" fill="#fff" />
                        </g>
                        <mask id="path-67-inside-5_1812_8974" fill="#fff">
                            <path d="M103 213h520v34H103v-34Z" />
                        </mask>
                        <path d="M103 213h520v34H103v-34Z" fill="#fff" />
                        <path
                            d="M623 246H103v2h520v-2Z"
                            fill="#000352"
                            fill-opacity=".03"
                            mask="url(#path-67-inside-5_1812_8974)"
                        />
                        <mask
                            id="mask3_1812_8974"
                            style="mask-type: luminance"
                            maskUnits="userSpaceOnUse"
                            x="115"
                            y="223"
                            width="14"
                            height="14"
                        >
                            <path
                                d="M129 230c0-3.866-3.134-7-7-7s-7 3.134-7 7 3.134 7 7 7 7-3.134 7-7Z"
                                fill="#fff"
                            />
                        </mask>
                        <g mask="url(#mask3_1812_8974)">
                            <path
                                d="M128.65 223h-13.3c-.193 0-.35.157-.35.35v13.3c0 .193.157.35.35.35h13.3c.193 0 .35-.157.35-.35v-13.3c0-.193-.157-.35-.35-.35Z"
                                fill="#00AD9C"
                            />
                            <g filter="url(#filter24_f_1812_8974)">
                                <path
                                    d="m117.763 223.27-4.784-.489-4.524 2.207 7.081 14.518 7.927-3.867.094-3.559-6.597-3.637.803-5.173Z"
                                    fill="#006DFF"
                                />
                            </g>
                            <g style="mix-blend-mode: overlay" filter="url(#filter25_f_1812_8974)">
                                <path
                                    d="m121.097 228.394-7.203-.671-4.721 7.953 8.949 11.457 10.039-9.031-3.912-3.383-1.712 5.183-8.186-.206 6.746-11.303v.001Z"
                                    fill="#6129FF"
                                />
                            </g>
                        </g>
                        <rect x="139" y="226" width="88" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="311" y="226" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="395" y="226" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="479" y="226" width="36" height="8" rx="4" fill="#F3F3F7" />
                        <g filter="url(#filter26_ddddd_1812_8974)">
                            <rect x="555" y="223" width="56" height="14" rx="7" fill="#fff" />
                        </g>
                        <mask id="path-78-inside-6_1812_8974" fill="#fff">
                            <path d="M103 247h520v34H103v-34Z" />
                        </mask>
                        <path d="M103 247h520v34H103v-34Z" fill="#fff" />
                        <path
                            d="M623 280H103v2h520v-2Z"
                            fill="#000352"
                            fill-opacity=".03"
                            mask="url(#path-78-inside-6_1812_8974)"
                        />
                        <mask
                            id="mask4_1812_8974"
                            style="mask-type: luminance"
                            maskUnits="userSpaceOnUse"
                            x="115"
                            y="257"
                            width="14"
                            height="14"
                        >
                            <path
                                d="M129 264c0-3.866-3.134-7-7-7s-7 3.134-7 7 3.134 7 7 7 7-3.134 7-7Z"
                                fill="#fff"
                            />
                        </mask>
                        <g mask="url(#mask4_1812_8974)">
                            <path
                                d="M128.65 257h-13.3c-.193 0-.35.157-.35.35v13.3c0 .194.157.35.35.35h13.3c.193 0 .35-.156.35-.35v-13.3c0-.193-.157-.35-.35-.35Z"
                                fill="#FF9500"
                            />
                            <g filter="url(#filter27_f_1812_8974)">
                                <path
                                    d="m121.811 258.537-3.926-2.073-4.634.324 1.04 14.874 8.122-.568 1.311-3.014-4.389-5.394 2.476-4.149Z"
                                    fill="#00AD9C"
                                />
                            </g>
                            <g style="mix-blend-mode: overlay" filter="url(#filter28_f_1812_8974)">
                                <path
                                    d="m124.193 261.884-4.263-5.14-8.273 2.11-1.637 13.319 12.448.658-.346-4.762-4.5 2.266-5.209-5.478 11.78-2.973Z"
                                    fill="#006DFF"
                                />
                            </g>
                        </g>
                        <rect x="139" y="260" width="88" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="311" y="260" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="395" y="260" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="479" y="260" width="36" height="8" rx="4" fill="#F3F3F7" />
                        <g filter="url(#filter29_ddddd_1812_8974)">
                            <rect x="555" y="257" width="56" height="14" rx="7" fill="#fff" />
                        </g>
                        <mask id="path-89-inside-7_1812_8974" fill="#fff">
                            <path d="M103 281h520v34H103v-34Z" />
                        </mask>
                        <path d="M103 281h520v34H103v-34Z" fill="#fff" />
                        <path
                            d="M623 314H103v2h520v-2Z"
                            fill="#000352"
                            fill-opacity=".03"
                            mask="url(#path-89-inside-7_1812_8974)"
                        />
                        <mask
                            id="mask5_1812_8974"
                            style="mask-type: luminance"
                            maskUnits="userSpaceOnUse"
                            x="115"
                            y="291"
                            width="14"
                            height="14"
                        >
                            <path
                                d="M129 298c0-3.866-3.134-7-7-7s-7 3.134-7 7 3.134 7 7 7 7-3.134 7-7Z"
                                fill="#fff"
                            />
                        </mask>
                        <g mask="url(#mask5_1812_8974)">
                            <path
                                d="M128.65 291h-13.3c-.193 0-.35.157-.35.35v13.3c0 .194.157.35.35.35h13.3c.193 0 .35-.156.35-.35v-13.3c0-.193-.157-.35-.35-.35Z"
                                fill="#006DFF"
                            />
                            <g filter="url(#filter30_f_1812_8974)">
                                <path
                                    d="m127.453 298.384 2.472-3.688.162-4.643-14.901-.521-.284 8.138 2.86 1.618 5.823-3.801 3.868 2.897Z"
                                    fill="#6129FF"
                                />
                            </g>
                            <g style="mix-blend-mode: overlay" filter="url(#filter31_f_1812_8974)">
                                <path
                                    d="m125.719 296.877-.429-6.664-7.933-3.156-9.153 9.813 9.684 7.849 2.519-4.055-4.973-.812-.994-7.494 11.279 4.519Z"
                                    fill="#FF3D62"
                                />
                            </g>
                        </g>
                        <rect x="139" y="294" width="88" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="311" y="294" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="395" y="294" width="44" height="8" rx="4" fill="#F3F3F7" />
                        <rect x="479" y="294" width="36" height="8" rx="4" fill="#F3F3F7" />
                        <g filter="url(#filter32_ddddd_1812_8974)">
                            <rect x="555" y="291" width="56" height="14" rx="7" fill="#fff" />
                        </g>
                        <path fill="#fff" d="M103 315h520v34H103z" />
                        <g filter="url(#filter33_ddddd_1812_8974)">
                            <rect x="115" y="326" width="38" height="12" rx="4" fill="#fff" />
                        </g>
                        <g filter="url(#filter34_ddddd_1812_8974)">
                            <rect x="330" y="327" width="10" height="10" rx="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter35_ddddd_1812_8974)">
                            <rect x="344" y="327" width="10" height="10" rx="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter36_ddddd_1812_8974)">
                            <rect x="358" y="327" width="10" height="10" rx="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter37_ddddd_1812_8974)">
                            <rect x="372" y="327" width="10" height="10" rx="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter38_ddddd_1812_8974)">
                            <rect x="386" y="327" width="10" height="10" rx="5" fill="#fff" />
                        </g>
                        <g filter="url(#filter39_ddddd_1812_8974)">
                            <rect x="573" y="326" width="38" height="12" rx="4" fill="#fff" />
                        </g>
                    </g>
                </g>
            </g>
            <g filter="url(#filter40_ddddd_1812_8974)">
                <rect x="87" y="381" width="552" height="208" rx="18" fill="#fff" />
                <rect x="103" y="397" width="88" height="14" rx="7" fill="#ECECEF" />
                <g filter="url(#filter41_dd_1812_8974)">
                    <rect x="566" y="395" width="57" height="18" rx="9" fill="#fff" />
                </g>
            </g>
        </g>
        <defs>
            <filter
                id="filter0_f_1812_8974"
                x="-2.55081"
                y="376.221"
                width="36.2176"
                height="32.7977"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2.3625" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter1_f_1812_8974"
                x="3.22471"
                y="357.773"
                width="35.6229"
                height="34.5349"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="2.3625" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter2_ddddd_1812_8974"
                x="84"
                y="-140"
                width="558"
                height="198"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter3_ddddd_1812_8974"
                x="100"
                y="-124"
                width="526"
                height="166"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter4_dddd_1812_8974"
                x="548"
                y="-12"
                width="63"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect1_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect4_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter5_ddddd_1812_8974"
                x="332"
                y="14"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter6_ddddd_1812_8974"
                x="346"
                y="14"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter7_ddddd_1812_8974"
                x="360"
                y="14"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter8_ddddd_1812_8974"
                x="374"
                y="14"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter9_ddddd_1812_8974"
                x="388"
                y="14"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter10_ddddd_1812_8974"
                x="568"
                y="13"
                width="43"
                height="21"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter11_ddddd_1812_8974"
                x="113"
                y="13"
                width="43"
                height="21"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter12_ddddd_1812_8974"
                x="84"
                y="64"
                width="558"
                height="310"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter13_dd_1812_8974"
                x="564"
                y="78"
                width="61"
                height="23"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter14_ddddd_1812_8974"
                x="100"
                y="110"
                width="526"
                height="248"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter15_ddddd_1812_8974"
                x="350"
                y="122"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter16_ddddd_1812_8974"
                x="434"
                y="122"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter17_ddddd_1812_8974"
                x="510"
                y="122"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter18_f_1812_8974"
                x="102.127"
                y="153.616"
                width="28.1692"
                height="25.5094"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.8375" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter19_f_1812_8974"
                x="106.619"
                y="139.268"
                width="27.7067"
                height="26.8605"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.8375" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter20_ddddd_1812_8974"
                x="552"
                y="154"
                width="62"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter21_f_1812_8974"
                x="108.833"
                y="182.699"
                width="23.216"
                height="19.308"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.5925" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter22_f_1812_8974"
                x="115.104"
                y="188.31"
                width="26.2348"
                height="24.439"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.5925" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter23_ddddd_1812_8974"
                x="552"
                y="188"
                width="62"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter24_f_1812_8974"
                x="105.27"
                y="219.596"
                width="21.4724"
                height="23.0942"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.5925" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter25_f_1812_8974"
                x="105.988"
                y="224.538"
                width="25.3582"
                height="25.7799"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.5925" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter26_ddddd_1812_8974"
                x="552"
                y="222"
                width="62"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter27_f_1812_8974"
                x="110.311"
                y="253.524"
                width="16.3531"
                height="21.0778"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.47" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter28_f_1812_8974"
                x="107.08"
                y="253.804"
                width="20.0532"
                height="21.9664"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.47" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter29_ddddd_1812_8974"
                x="552"
                y="256"
                width="62"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter30_f_1812_8974"
                x="111.962"
                y="286.592"
                width="21.0651"
                height="15.6359"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.47" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter31_f_1812_8974"
                x="105.264"
                y="284.117"
                width="23.3943"
                height="23.542"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="1.47" result="effect1_foregroundBlur_1812_8974" />
            </filter>
            <filter
                id="filter32_ddddd_1812_8974"
                x="552"
                y="290"
                width="62"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter33_ddddd_1812_8974"
                x="112"
                y="325"
                width="44"
                height="22"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter34_ddddd_1812_8974"
                x="327"
                y="326"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter35_ddddd_1812_8974"
                x="341"
                y="326"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter36_ddddd_1812_8974"
                x="355"
                y="326"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter37_ddddd_1812_8974"
                x="369"
                y="326"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter38_ddddd_1812_8974"
                x="383"
                y="326"
                width="16"
                height="20"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter39_ddddd_1812_8974"
                x="570"
                y="325"
                width="44"
                height="22"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter40_ddddd_1812_8974"
                x="84"
                y="380"
                width="558"
                height="218"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.010719 0 0 0 0 0.321569 0 0 0 0.05 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius=".5" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation=".5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" in="SourceAlpha" result="effect3_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend in2="effect2_dropShadow_1812_8974" result="effect3_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect4_dropShadow_1812_8974" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend in2="effect3_dropShadow_1812_8974" result="effect4_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="3" in="SourceAlpha" result="effect5_dropShadow_1812_8974" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend in2="effect4_dropShadow_1812_8974" result="effect5_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect5_dropShadow_1812_8974" result="shape" />
            </filter>
            <filter
                id="filter41_dd_1812_8974"
                x="564"
                y="394"
                width="61"
                height="23"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1812_8974"
                />
                <feOffset />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1812_8974" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect2_dropShadow_1812_8974" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend in2="effect1_dropShadow_1812_8974" result="effect2_dropShadow_1812_8974" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_1812_8974" result="shape" />
            </filter>
            <clipPath id="clip0_1812_8974"><path fill="#fff" d="M0 0h680v408H0z" /></clipPath>
            <clipPath id="clip1_1812_8974">
                <rect x="103" y="-123" width="520" height="156" rx="12" fill="#fff" />
            </clipPath>
            <clipPath id="clip2_1812_8974">
                <rect x="103" y="111" width="520" height="238" rx="12" fill="#fff" />
            </clipPath>
            <linearGradient
                id="paint0_linear_1812_8974"
                x1="29.5"
                y1="52.5"
                x2="15.5"
                y2="66.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#32B6FF" />
                <stop offset=".645833" stop-color="#006DFF" />
                <stop offset="1" stop-color="#0035EC" />
            </linearGradient>
        </defs>
    </svg>
</template>
