import {
    OnPushHandler,
    useImprovement,
    UseImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

import { computed, ref, Ref, ComputedRef, inject } from 'vue'
import isNaN from 'lodash-es/isNaN'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { BarChartTypes, usePercent } from '@opteo/components-next'
import { AdjustGenderBid } from '@opteo/types'
import { CurrencyCode } from '@/composition/utils/types'
import { ProvideKeys } from '@/composition/useProvide'

interface UseAdjustGenderBid {
    usingTargetCpa: boolean
    improvement: Ref<AdjustGenderBid.AdjustGenderBidBody> | Ref
    genderCpaToCampaignRatio: ComputedRef<number>
    genderCpaToCampaignGroupRatio: ComputedRef<number>
    statsBidMod: ComputedRef<string>
    statsCampaignCpa: ComputedRef<string>
    statsCpa: ComputedRef<string>
    statsBucketCpa: ComputedRef<string>
    campaignCpa: number
    bucketCpa: number
    adjustedBid: Ref<number>
    currentBidModRelativeToUnitBid: Ref<number>
    onBidUpdate: (updatedBid: number) => void
    barChartItems: BarChartTypes.BarChartItem[]
    domainCurrencyCode: CurrencyCode | undefined
    negativeBidMod: number
    campaignGroupName: string
    campaignName: string
    gender: string
    // Table Data
    TableHeaders: any[]
    TableItems: any[]
}

type BidDirection = 'increase' | 'decrease'

interface PushedData {
    sign: BidDirection | undefined
    value: number | undefined
}

const GENDERS = ['Male', 'Female', 'Undetermined']

export function useAdjustGenderBid(): UseImprovement<UseAdjustGenderBid> {
    const bidToPush = ref<number | undefined>()
    const sign = ref<BidDirection | undefined>()
    const bidAdjusted = ref(false)

    function setDefaultCustomValues() {
        bidToPush.value = negativeBidMod
        sign.value = 'decrease'
    }

    const { improvement, lastUpdated, title } =
        useImprovement<AdjustGenderBid.AdjustGenderBidBody>()

    const {
        body: {
            negative_bid_mod: negativeBidMod,
            cpa,
            campaign_cpa: campaignCpa,
            bucket_cpa: bucketCpa,
            using_target_cpa: usingTargetCpa,
            other_genders: otherGenders,
            conversion_type_name: campaignGroupName,
            campaign_label: campaignName,
            gender,
        },
    } = checkImprovement<AdjustGenderBid.AdjustGenderBidBody>(improvement)

    const currentBidModRelativeToUnitBid = ref(1 - negativeBidMod / 100)
    const adjustedBid = ref(1)

    setDefaultCustomValues()

    const { displayValue: statsBidMod } = usePercent({
        value: negativeBidMod / 100,
    })

    const { displayValue: statsCpa } = useDomainMoney({
        value: cpa,
    }).value
    const { displayValue: statsCampaignCpa } = useDomainMoney({
        value: campaignCpa,
    }).value
    const { displayValue: statsBucketCpa } = useDomainMoney({
        value: bucketCpa,
    }).value

    const genderCpaToCampaignRatio = computed(() => {
        return (cpa - campaignCpa) / campaignCpa
    })

    const genderCpaToCampaignGroupRatio = computed(() => {
        return (cpa - bucketCpa) / bucketCpa
    })

    const barChartItems: BarChartTypes.BarChartItem[] = GENDERS.map(type => {
        const currentGender = otherGenders.find(a => a.gender === type)

        const highlighted = gender === type
        let y_value = 0

        if (highlighted) {
            y_value = isFinite(cpa) ? cpa : 0
        } else {
            y_value = currentGender?.cost
                ? isFinite(currentGender?.cost) && currentGender?.conversions !== 0
                    ? currentGender?.cost / currentGender?.conversions
                    : 0
                : 0
        }

        return {
            y: y_value,
            label: type,
            highlighted,
        }
    }).filter(chart_item => chart_item?.y)

    // Table Data

    const TableHeaders = [
        { key: 'gender', text: 'Gender', sortable: true },
        { key: 'cost', text: 'Cost', sortable: true },
        { key: 'cpa', text: 'CPA', sortable: true },
        { key: 'average-cpa', text: 'Avg. CPA' },
        { key: 'difference', text: 'Diff.', sortable: true },
    ]

    const TableData = [
        {
            gender: gender,
            cost: improvement.value!.body.cost,
            conversions: improvement.value!.body.conversions,
            clicks: improvement.value!.body.clicks,
        },
        ...otherGenders,
    ]

    const TableItems = TableData.map(group => {
        return {
            id: group.gender,
            gender: group.gender,
            cost: group.cost,
            cpa: group.cost / group.conversions,
            'average-cpa': campaignCpa,
            difference: (group.cost / group.conversions - campaignCpa) / campaignCpa,
        }
    })

    const domainCurrencyCode = inject<CurrencyCode>(ProvideKeys.CurrencyCode)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Applying gender bid adjustment..',
        'Confirming changes..',
        'Bid adjustment applied successfully.',
    ])

    const pushActionText = ref('Apply Bid Adjustment')

    const onPush: OnPushHandler<PushedData> = () => {
        const pushedData = {
            sign: sign.value,
            value: parseFloat(bidToPush.value?.toFixed(2) ?? '0'),
        }

        const valid = validate(pushedData)

        if (!bidAdjusted.value) {
            return { valid }
        }

        return {
            valid,
            pushedData,
        }
    }

    function validate(pushedData: { value: number | undefined; sign: BidDirection | undefined }) {
        if (!pushedData.value || !pushedData.sign) {
            return false
        }
        if (+pushedData.value === 0 || isNaN(+pushedData.value)) {
            return false
        } else if (pushedData.value < 0) {
            return false
        } else if (pushedData.sign === 'decrease' && pushedData.value > 90) {
            return false
        } else if (pushedData.sign === 'increase' && pushedData.value > 900) {
            return false
        }

        return true
    }

    function onBidUpdate(updatedBid: number) {
        const absolutePercentBid = (updatedBid - 1) * 100
        sign.value = absolutePercentBid > 0 ? 'increase' : 'decrease'
        bidToPush.value = Math.abs(absolutePercentBid)

        if (
            sign.value !== 'decrease' ||
            updatedBid.toFixed(2) !== ((100 - negativeBidMod) / 100).toFixed(2)
        ) {
            bidAdjusted.value = true
        } else {
            bidAdjusted.value = false
        }
    }

    return {
        lastUpdated,
        title,
        pushMessages,
        onPush,
        onBidUpdate,
        improvement,
        usingTargetCpa,
        statsBidMod,
        statsCampaignCpa,
        statsCpa,
        statsBucketCpa,
        bucketCpa,
        campaignCpa,
        currentBidModRelativeToUnitBid,
        adjustedBid,
        barChartItems,
        domainCurrencyCode,
        genderCpaToCampaignRatio,
        genderCpaToCampaignGroupRatio,
        negativeBidMod,
        campaignGroupName,
        campaignName,
        gender,
        // Table Data
        TableHeaders,
        TableItems,
        pushActionText,
    }
}
