import { computed, inject, ref } from 'vue'
import round from 'lodash/round'
import some from 'lodash-es/some'
import capitalize from 'lodash-es/capitalize'

import { useRoas } from '@opteo/components-next'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { AdjustDeviceBidsV2, Targets } from '@opteo/types'
import { sortTable } from '@opteo/components-next'
import { CurrencyCode } from '@/composition/utils/types'
import { ProvideKeys } from '@/composition/useProvide'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import {
    calculateCpa,
    calculateRoas,
    getProperDeviceLabel,
    instanceOfAdGroup,
    instanceOfCampaign,
    instanceOfCampaignGroup,
} from './utils'
import { useDeviceTables } from './useDeviceTables'
import { useEntityInfo } from './useEntityInfo'

export function useExcludeSingleDevice() {
    const { improvement, lastUpdated, title } =
        useImprovement<AdjustDeviceBidsV2.DeviceBidsRecActions>()

    const { body, rec_action: recAction } = checkImprovement(improvement)

    const lookbackWindow = body.window ?? 180

    // Find relevant device
    const singleSegmentExclusion = body.devices.find(placement => placement.is_exclude)

    if (!singleSegmentExclusion || !singleSegmentExclusion.current_bid_mod) {
        throw new Error('NO_DEVICE_TO_EXCLUDE')
    }

    const currentBidMod = singleSegmentExclusion.current_bid_mod

    const isUsingCpa =
        !body.performance_mode || body.performance_mode === Targets.PerformanceMode.CPA

    const highlightedSegment = getProperDeviceLabel(singleSegmentExclusion.device_label)
    const highlightedEnum = singleSegmentExclusion.device

    const updateExlusions = (placement: number, value: boolean) => {
        body.devices.forEach(item => {
            if (item.criteria === placement) {
                item.is_exclude = value
            }
        })

        const { deviceTableItems: deviceTableItemsRaw } = useDeviceTables(
            body.devices,
            isUsingCpa,
            formattedTarget,
            entityAverage,
            targetSet,
            recAction
        )

        /* Update table */
        deviceTableItems.value = deviceTableItemsRaw

        const numberOfExclusions = body.devices.filter(d => d.is_exclude)

        pushActionText.value =
            numberOfExclusions.length > 1 ? 'Apply Exclusions' : 'Apply Exclusion'
    }

    const segmentCpa = calculateCpa(
        singleSegmentExclusion.cost,
        singleSegmentExclusion.all_conversions
    )
    const segmentRoas = calculateRoas(
        singleSegmentExclusion.cost,
        singleSegmentExclusion.all_conversions_value
    )

    const campaignGroupPerformanceMetric =
        (isUsingCpa ? body.campaign_group.target_cpa : body.campaign_group.target_roas) ?? 0

    const { displayValue: formattedTargetCpa } = useDomainMoney({
        value: campaignGroupPerformanceMetric ?? 0,
    }).value

    const { displayValue: formattedTargetRoas } = useRoas({
        value: campaignGroupPerformanceMetric ?? 0,
    })

    const formattedTarget = isUsingCpa ? formattedTargetCpa.value : formattedTargetRoas.value

    const {
        entity,
        entityLabel,
        entityName,
        entityAverage,
        entityPillList,
        campaignGroup,
        formattedEntityAverage,
    } = useEntityInfo(body, isUsingCpa)

    const targetSet =
        (isUsingCpa && body.campaign_group.target_cpa_set) ||
        (!isUsingCpa && body.campaign_group.target_roas_set)

    const { deviceTableHeaders, deviceTableItems: deviceTableItemsRaw } = useDeviceTables(
        body.devices,
        isUsingCpa,
        formattedTarget,
        entityAverage,
        targetSet,
        recAction
    )

    const deviceTableItems = ref(deviceTableItemsRaw)

    const domainCurrencyCode = inject<CurrencyCode>(ProvideKeys.CurrencyCode)

    const formattedSegmentCpa = useDomainMoney({
        value: segmentCpa,
    }).value.displayValue.value

    const formattedSegmentRoas = useRoas({
        value: segmentRoas,
    }).displayValue.value

    const relevantEntityMetrics =
        entity === 'adgroup' && instanceOfAdGroup(body)
            ? body.ad_group_metrics
            : entity === 'campaign' && instanceOfCampaign(body)
            ? body.campaign_metrics
            : instanceOfCampaignGroup(body) && body.group_metrics

    const monthsInWindow = lookbackWindow / 30

    const differenceFromEntityAverage = isUsingCpa
        ? (segmentCpa - entityAverage) / entityAverage
        : (segmentRoas - entityAverage) / entityAverage

    const differenceFromTarget = isUsingCpa
        ? (segmentCpa - campaignGroupPerformanceMetric) / campaignGroupPerformanceMetric
        : (segmentRoas - campaignGroupPerformanceMetric) / campaignGroupPerformanceMetric

    const conversionLoss = singleSegmentExclusion
        ? round(singleSegmentExclusion?.all_conversions / monthsInWindow, 1)
        : 0
    const conversionValueLoss = singleSegmentExclusion
        ? singleSegmentExclusion?.all_conversions_value / monthsInWindow
        : 0

    const costSavings = singleSegmentExclusion ? singleSegmentExclusion?.cost / monthsInWindow : 0

    const costSavingPercent =
        singleSegmentExclusion && relevantEntityMetrics
            ? -singleSegmentExclusion?.cost / relevantEntityMetrics.cost
            : 0

    const cpaAfter =
        singleSegmentExclusion && relevantEntityMetrics
            ? (relevantEntityMetrics.cost - singleSegmentExclusion?.cost) /
              (relevantEntityMetrics.all_conversions - singleSegmentExclusion?.all_conversions)
            : 0

    const roasAfter =
        singleSegmentExclusion && relevantEntityMetrics
            ? (relevantEntityMetrics.all_conversions_value -
                  singleSegmentExclusion?.all_conversions_value) /
              (relevantEntityMetrics.cost - singleSegmentExclusion?.cost)
            : 0

    const cpaBefore = entityAverage
    const roasBefore = entityAverage
    const cpaDecreasePercent = cpaAfter / cpaBefore - 1
    const roasIncreasePercent = roasAfter / roasBefore - 1

    const improvementStatisticsItems = [
        isUsingCpa
            ? {
                  key: 'segmentCpa',
                  value: formattedSegmentCpa,
                  title: 'Segment CPA',
              }
            : {
                  key: 'segmentRoas',
                  value: formattedSegmentRoas,
                  title: 'Segment ROAS',
              },
        {
            key: 'entityAverage',
            value: formattedEntityAverage,
            title: `${
                entityLabel === 'ad group'
                    ? 'Ad Group'
                    : entityLabel === 'campaign group'
                    ? 'Campaign Group'
                    : capitalize(entityLabel)
            } ${isUsingCpa ? 'CPA' : 'ROAS'}`,
        },

        {
            key: 'campaignGroupTarget',
            value: formattedTarget,
            title: `Campaign Group Target ${isUsingCpa ? 'CPA' : 'ROAS'}`,
        },
    ]

    let extraDetails: { resource_name: string; cpc_bid: number }[] = []

    const donutChartItems = sortTable(
        'label',
        'ASC',
        body.devices.map(device => {
            return {
                y: device.cost,
                label: getProperDeviceLabel(device.device_label),
                highlighted: device.device_label === singleSegmentExclusion.device_label,
            }
        })
    )

    const pushActionText = ref('Apply Exclusion')

    const pushMessages = computed(() => [
        `Connecting to Google Ads..`,
        `Applying device exclusion..`,
        `Confirming changes..`,
        `Exclusion applied successfully.`,
    ])

    const onPush: OnPushHandler = () => {
        let valid = true

        const placementsToExclude = !instanceOfCampaignGroup(body)
            ? body.devices
                  .filter((deviceData: any) => deviceData.is_exclude)
                  .map(excludedDevice => {
                      return {
                          resource_name: excludedDevice.resource_name,
                          cpc_bid: 0,
                      }
                  })
            : body.devices
                  .filter((deviceData: any) => deviceData.is_exclude)
                  .flatMap(excludedDevice => {
                      const criteria = excludedDevice.criteria
                      return body.campaigns.map(c => {
                          return {
                              resource_name: `${c.resource_name.replace(
                                  'campaigns',
                                  'campaignCriteria'
                              )}~${criteria}`,
                              cpc_bid: 0,
                          }
                      })
                  })

        if (!singleSegmentExclusion || !placementsToExclude.length) {
            valid = false
        }

        return {
            valid,
            pushedData: placementsToExclude,
        }
    }

    return {
        title,
        entity,
        entityLabel,
        entityName,
        entityPillList,
        highlightedSegment,
        highlightedEnum,
        deviceTableHeaders,
        deviceTableItems,
        donutChartItems,
        domainCurrencyCode,
        currentBidMod,
        formattedSegmentCpa,
        formattedSegmentRoas,
        improvementStatisticsItems,
        lookbackWindow,
        pushMessages,
        onPush,
        lastUpdated,
        pushActionText,
        isUsingCpa,
        formattedEntityAverage,
        differenceFromEntityAverage,
        differenceFromTarget,
        formattedTarget,
        // Impact paragraph
        conversionLoss,
        conversionValueLoss,
        costSavings,
        costSavingPercent,
        cpaBefore,
        roasBefore,
        cpaAfter,
        roasAfter,
        cpaDecreasePercent,
        roasIncreasePercent,
        updateExlusions,
    }
}
