<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <circle cx="12" cy="12" r="12" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0)">
            <circle cx="12" cy="12" r="12" fill="#D3D3D6" />
            <path
                d="M15.1017 11.8752C15.119 13.6912 16.7321 14.2955 16.75 14.3032C16.7364 14.3458 16.4922 15.1644 15.9001 16.01C15.3882 16.741 14.857 17.4693 14.0201 17.4844C13.1977 17.4992 12.9333 17.0079 11.9932 17.0079C11.0533 17.0079 10.7595 17.4693 9.98106 17.4992C9.17324 17.5291 8.5581 16.7087 8.04197 15.9804C6.98732 14.4905 6.18134 11.7705 7.26356 9.93437C7.80118 9.02255 8.76196 8.44515 9.80479 8.43035C10.598 8.41556 11.3468 8.9518 11.8317 8.9518C12.3163 8.9518 13.2262 8.30693 14.1827 8.40163C14.5832 8.41792 15.7072 8.55968 16.4289 9.59196C16.3708 9.62719 15.0877 10.357 15.1017 11.8752V11.8752ZM13.5562 7.41598C13.9851 6.90873 14.2737 6.2026 14.195 5.5C13.5768 5.52428 12.8293 5.90251 12.3859 6.40948C11.9885 6.85842 11.6405 7.57698 11.7344 8.26568C12.4234 8.31777 13.1273 7.92355 13.5562 7.41598"
                fill="white"
            />
        </g>
    </svg>
</template>
