<template>
    <svg viewBox="0 0 48 48" width="24" height="24">
        <defs>
            <linearGradient
                id="c"
                x1="15.74"
                y1="21.27"
                x2="29.42"
                y2="44.96"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
        </defs>
        <path
            d="m42.49 37.41-3.31.93-17.44 4.91-9.23 2.6A4 4 0 0 1 8 44l-5.71-9.46a2.07 2.07 0 0 1 .54-2.72L24 16.53a1.9 1.9 0 0 1 2.67.67L37.22 35l.24.43a4.65 4.65 0 0 0 3.87 2.11 4.45 4.45 0 0 0 1.16-.13Z"
            :fill="color"
            opacity=".5"
        />
        <path
            d="M46 4v28.64a4.92 4.92 0 0 1-3.51 4.77l-18.94 5.33-11 3.12a4.86 4.86 0 0 0 2.23-4.12V4a2 2 0 0 1 2-2h27.27A2 2 0 0 1 46 4Z"
            :fill="color"
        />
        <path
            d="m42.48 37.41-18.94 5.33-3.86 1.09-7.17 2a4.87 4.87 0 0 0 2.23-4.12v-18.5L24 16.52a1.92 1.92 0 0 1 2.68.68L37.22 35l.24.43a4.63 4.63 0 0 0 3.87 2.11 4.9 4.9 0 0 0 1.15-.13Z"
            fill="url(#c)"
            opacity=".2"
        />
    </svg>
</template>

<script>
export default {
    name: 'Reports',
    props: {
        color: {
            type: String,
            default: '#E0E0E5',
            required: false,
        },
    },
}
</script>
