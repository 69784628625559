<template>
    <svg
        class="bg"
        width="268"
        height="364"
        viewBox="0 0 268 364"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="overviewSlide">
            <rect id="shadowContainer" width="268" height="364" rx="28" fill="#fff" />
            <g id="sectionFour">
                <g id="shadowContainer_2" filter="url(#filter0_dd_131_4068)">
                    <rect x="118" y="283" width="113" height="46" rx="8" fill="#fff" />
                </g>
                <path id="dividerLine" stroke="#F6F6F9" d="M174.5 283v46" />
                <path id="dividerLine_2" stroke="#F6F6F9" d="M118 305.5h113" />
                <g id="delta" fill="#00A367" fill-opacity=".2">
                    <rect id="text" x="204" y="319" width="9" height="3" rx="1.5" />
                    <rect id="icon" x="198" y="318" width="5" height="5" rx="2.5" />
                </g>
                <rect
                    id="copySkeleton"
                    x="181"
                    y="318"
                    width="15"
                    height="5"
                    rx="2.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="copySkeleton_2"
                    x="181"
                    y="312"
                    width="20"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <g id="delta_2" fill="#FF2828" fill-opacity=".2">
                    <rect id="text_2" x="147" y="319" width="9" height="3" rx="1.5" />
                    <rect id="icon_2" x="141" y="318" width="5" height="5" rx="2.5" />
                </g>
                <rect
                    id="copySkeleton_3"
                    x="124"
                    y="318"
                    width="15"
                    height="5"
                    rx="2.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="copySkeleton_4"
                    x="124"
                    y="312"
                    width="20"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <g id="delta_3" fill="#00A367" fill-opacity=".2">
                    <rect id="text_3" x="204" y="296" width="9" height="3" rx="1.5" />
                    <rect id="icon_3" x="198" y="295" width="5" height="5" rx="2.5" />
                </g>
                <rect
                    id="copySkeleton_5"
                    x="181"
                    y="295"
                    width="15"
                    height="5"
                    rx="2.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="copySkeleton_6"
                    x="181"
                    y="289"
                    width="20"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <g id="delta_4" fill="#00A367" fill-opacity=".2">
                    <rect id="text_4" x="147" y="296" width="9" height="3" rx="1.5" />
                    <rect id="icon_4" x="141" y="295" width="5" height="5" rx="2.5" />
                </g>
                <rect
                    id="copySkeleton_7"
                    x="124"
                    y="295"
                    width="15"
                    height="5"
                    rx="2.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="copySkeleton_8"
                    x="124"
                    y="289"
                    width="20"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="headingSkeleton"
                    x="118"
                    y="272"
                    width="45"
                    height="5"
                    rx="2.5"
                    fill="#000"
                    fill-opacity=".1"
                />
            </g>
            <g id="sectionThree">
                <g id="shadowContainer_3" filter="url(#filter1_dd_131_4068)">
                    <rect x="118" y="207" width="113" height="55" rx="8" fill="#fff" />
                </g>
                <rect
                    id="copySkeleton_9"
                    x="140"
                    y="253"
                    width="75"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="copySkeleton_10"
                    x="140"
                    y="248"
                    width="85"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="subheadingSkeleton"
                    x="140"
                    y="240"
                    width="43"
                    height="4"
                    rx="2"
                    fill="#000"
                    fill-opacity=".1"
                />
                <g id="scoreDonut" stroke="#FF2828" stroke-width="1.5" stroke-linecap="round">
                    <path
                        id="bigSlice"
                        opacity=".1"
                        d="M135.251 246c0 .689-.136 1.372-.4 2.009-.264.637-.651 1.216-1.138 1.703-.488.488-1.067.875-1.704 1.138-.637.264-1.32.4-2.009.4-.689 0-1.372-.136-2.009-.4-.637-.263-1.216-.65-1.703-1.138-.487-.487-.874-1.066-1.138-1.703-.263-.637-.399-1.32-.399-2.009 0-.689.136-1.372.4-2.009.264-.637.651-1.216 1.139-1.703.487-.488 1.066-.875 1.703-1.138.637-.264 1.32-.4 2.009-.4.69 0 1.372.136 2.009.4.637.263 1.216.65 1.703 1.138.488.487.874 1.066 1.138 1.703.264.637.399 1.32.399 2.009h0Z"
                        stroke-linejoin="round"
                    />
                    <path
                        id="smallSlice"
                        d="M130.002 240.75c.829 0 1.645.196 2.384.572.738.376 1.376.922 1.863 1.592.487.67.808 1.446.938 2.265.129.818.063 1.655-.193 2.443"
                    />
                </g>
                <path id="dividerLine_3" stroke="#F6F6F9" d="M118 234.5h113" />
                <rect
                    id="copySkeleton_11"
                    x="140"
                    y="226"
                    width="75"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="copySkeleton_12"
                    x="140"
                    y="221"
                    width="85"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="subheadingSkeleton_2"
                    x="140"
                    y="213"
                    width="43"
                    height="4"
                    rx="2"
                    fill="#000"
                    fill-opacity=".1"
                />
                <g id="scoreDonut_2" stroke="#FF9500" stroke-width="1.5" stroke-linecap="round">
                    <path
                        id="bigSlice_2"
                        opacity=".1"
                        d="M135.251 219c0 .689-.136 1.372-.4 2.009-.264.637-.651 1.216-1.138 1.703-.488.488-1.067.875-1.704 1.138-.637.264-1.32.4-2.009.4-.689 0-1.372-.136-2.009-.4-.637-.263-1.216-.65-1.703-1.138-.487-.487-.874-1.066-1.138-1.703-.263-.637-.399-1.32-.399-2.009 0-.689.136-1.372.4-2.009.264-.637.651-1.216 1.139-1.703.487-.488 1.066-.875 1.703-1.138.637-.264 1.32-.4 2.009-.4.69 0 1.372.136 2.009.4.637.263 1.216.65 1.703 1.138.488.487.874 1.066 1.138 1.703.264.637.399 1.32.399 2.009h0Z"
                        stroke-linejoin="round"
                    />
                    <path
                        id="smallSlice_2"
                        d="M130.002 213.75c.745 0 1.482.159 2.161.465.679.307 1.284.754 1.777 1.313.493.559.86 1.216 1.079 1.928.219.712.284 1.463.191 2.202-.094.739-.344 1.45-.733 2.085s-.91 1.18-1.526 1.599c-.616.419-1.314.702-2.048.83s-1.487.098-2.209-.087"
                    />
                </g>
                <rect
                    id="headingSkeleton_2"
                    x="118"
                    y="196"
                    width="45"
                    height="5"
                    rx="2.5"
                    fill="#000"
                    fill-opacity=".1"
                />
            </g>
            <g id="sectionTwo">
                <g id="shadowContainer_4" filter="url(#filter2_dd_131_4068)">
                    <rect x="118" y="131" width="113" height="55" rx="8" fill="#fff" />
                </g>
                <rect
                    id="copySkeleton_13"
                    x="140"
                    y="177"
                    width="75"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="copySkeleton_14"
                    x="140"
                    y="172"
                    width="85"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="subheadingSkeleton_3"
                    x="140"
                    y="164"
                    width="43"
                    height="4"
                    rx="2"
                    fill="#000"
                    fill-opacity=".1"
                />
                <g id="scoreDonut_3" stroke="#00A367" stroke-width="1.5" stroke-linecap="round">
                    <path
                        id="bigSlice_3"
                        opacity=".1"
                        d="M135.251 170c0 .689-.136 1.372-.4 2.009-.264.637-.651 1.216-1.138 1.703-.488.488-1.067.875-1.704 1.138-.637.264-1.32.4-2.009.4-.689 0-1.372-.136-2.009-.4-.637-.263-1.216-.65-1.703-1.138-.487-.487-.874-1.066-1.138-1.703-.263-.637-.399-1.32-.399-2.009 0-.689.136-1.372.4-2.009.264-.637.651-1.216 1.139-1.703.487-.488 1.066-.875 1.703-1.138.637-.264 1.32-.4 2.009-.4.69 0 1.372.136 2.009.4.637.263 1.216.65 1.703 1.138.488.487.874 1.066 1.138 1.703.264.637.399 1.32.399 2.009h0Z"
                        stroke-linejoin="round"
                    />
                    <path
                        id="smallSlice_3"
                        d="M130.002 164.75c1.081 0 2.135.333 3.019.955.884.621 1.554 1.5 1.92 2.517.366 1.016.409 2.121.124 3.163-.286 1.043-.886 1.971-1.718 2.66-.833.689-1.858 1.104-2.935 1.189-1.077.085-2.154-.165-3.084-.715-.93-.55-1.668-1.374-2.112-2.359-.445-.984-.574-2.082-.372-3.144"
                    />
                </g>
                <path id="dividerLine_4" stroke="#F6F6F9" d="M118 158.5h113" />
                <rect
                    id="copySkeleton_15"
                    x="140"
                    y="150"
                    width="75"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="copySkeleton_16"
                    x="140"
                    y="145"
                    width="85"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <rect
                    id="subheadingSkeleton_4"
                    x="140"
                    y="137"
                    width="43"
                    height="4"
                    rx="2"
                    fill="#000"
                    fill-opacity=".1"
                />
                <g id="scoreDonut_4" stroke="#00A367" stroke-width="1.5" stroke-linecap="round">
                    <path
                        id="bigSlice_4"
                        opacity=".1"
                        d="M135.251 143c0 .689-.136 1.372-.4 2.009-.264.637-.651 1.216-1.138 1.703-.488.488-1.067.875-1.704 1.138-.637.264-1.32.4-2.009.4-.689 0-1.372-.136-2.009-.4-.637-.263-1.216-.65-1.703-1.138-.487-.487-.874-1.066-1.138-1.703-.263-.637-.399-1.32-.399-2.009 0-.689.136-1.372.4-2.009.264-.637.651-1.216 1.139-1.703.487-.488 1.066-.875 1.703-1.138.637-.264 1.32-.4 2.009-.4.69 0 1.372.136 2.009.4.637.263 1.216.65 1.703 1.138.488.487.874 1.066 1.138 1.703.264.637.399 1.32.399 2.009h0Z"
                        stroke-linejoin="round"
                    />
                    <path
                        id="smallSlice_4"
                        d="M130.002 137.75c1.137 0 2.243.369 3.152 1.052.909.682 1.572 1.642 1.889 2.733.317 1.092.271 2.257-.131 3.321-.402 1.063-1.139 1.968-2.099 2.577-.96.609-2.092.89-3.225.801-1.133-.089-2.207-.544-3.06-1.296-.852-.752-1.438-1.76-1.668-2.873-.23-1.114-.093-2.272.391-3.3"
                    />
                </g>
                <rect
                    id="headingSkeleton_3"
                    x="118"
                    y="120"
                    width="45"
                    height="5"
                    rx="2.5"
                    fill="#000"
                    fill-opacity=".1"
                />
            </g>
            <g id="sectionIntro" fill="#000" fill-opacity=".1">
                <rect id="copySkeleton_17" x="118" y="104" width="82" height="3" rx="1.5" />
                <rect id="copySkeleton_18" x="118" y="98" width="114" height="3" rx="1.5" />
                <rect id="copySkeleton_19" x="118" y="92" width="114" height="3" rx="1.5" />
                <rect id="copySkeleton_20" x="118" y="86" width="114" height="3" rx="1.5" />
                <rect id="copySkeleton_21" x="118" y="74" width="94" height="3" rx="1.5" />
                <rect id="copySkeleton_22" x="118" y="68" width="114" height="3" rx="1.5" />
                <rect id="copySkeleton_23" x="118" y="62" width="114" height="3" rx="1.5" />
                <rect id="copySkeleton_24" x="118" y="56" width="114" height="3" rx="1.5" />
                <rect id="copySkeleton_25" x="118" y="50" width="114" height="3" rx="1.5" />
                <rect id="headingSkeleton_4" x="118" y="36" width="45" height="5" rx="2.5" />
            </g>
            <g id="scorecardDonut">
                <path
                    id="backfill"
                    opacity=".1"
                    d="M98.4931 69c-.0008 4.0053-.7906 7.9714-2.3242 11.6718-1.5335 3.7005-3.7809 7.0628-6.6137 9.895-2.8327 2.8321-6.1955 5.0788-9.8963 6.6115-3.7007 1.5328-7.667 2.3217-11.6723 2.3217-4.0054 0-7.9713-.7889-11.6714-2.3217-3.7001-1.5327-7.0619-3.7794-9.8935-6.6115-2.8316-2.8322-5.0774-6.1945-6.6094-9.895-1.532-3.7004-2.32-7.6665-2.3192-11.6718.0009-4.0053.7906-7.9714 2.3242-11.6718 1.5336-3.7005 3.7809-7.0628 6.6137-9.895 2.8328-2.8321 6.1956-5.0788 9.8963-6.6115C60.0281 39.2889 63.9944 38.5 67.9997 38.5s7.9712.7889 11.6713 2.3217c3.7001 1.5327 7.0619 3.7794 9.8935 6.6115 2.8316 2.8322 5.0775 6.1945 6.6095 9.895 1.5319 3.7004 2.32 7.6665 2.3191 11.6718h0Z"
                    stroke="#00A367"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    id="fill"
                    d="M67.9997 38.5c6.6048 0 13.0306 2.144 18.3115 6.1096 5.2809 3.9657 9.1314 9.5386 10.9727 15.8812 1.8413 6.3425 1.5739 13.1118-.762 19.2902-2.336 6.1784-6.6142 11.432-12.1916 14.971-5.5774 3.539-12.1524 5.1722-18.7367 4.654-6.5843-.5182-12.822-3.1598-17.7754-7.5276-4.9534-4.3678-8.3547-10.2258-9.6927-16.6934-1.338-6.4675-.5403-13.1951 2.2732-19.1713"
                    stroke="#00A367"
                    stroke-width="3"
                    stroke-linecap="round"
                />
                <rect
                    id="text_5"
                    x="58"
                    y="78"
                    width="20"
                    height="3"
                    rx="1.5"
                    fill="#000"
                    fill-opacity=".1"
                />
                <g id="82" fill="#00A367">
                    <path
                        id="Vector"
                        d="m71.156 64.0978-2.394-.126c-.036-.18-.072-.504-.072-.792 0-2.214 1.656-4.212 4.572-4.212 2.826 0 4.518 1.854 4.518 4.032 0 1.674-.918 3.024-2.448 3.96l-2.484 1.53c-.54.36-1.026.738-1.206 1.332h6.21v2.178h-9.27c.018-2.214.774-3.96 3.024-5.328l2.088-1.296c1.098-.684 1.566-1.404 1.566-2.322 0-.99-.666-1.89-2.052-1.89-1.44 0-2.124.99-2.124 2.25 0 .234.036.468.072.684Z"
                    />
                    <path
                        id="Vector_2"
                        d="M62.8384 72.2698c-2.898 0-4.752-1.566-4.752-3.69 0-1.44.954-2.646 2.286-3.114-1.224-.45-1.998-1.674-1.998-2.88 0-2.124 1.89-3.618 4.464-3.618 2.592 0 4.482 1.494 4.482 3.618 0 1.188-.774 2.43-2.016 2.88 1.332.468 2.304 1.674 2.304 3.114 0 2.124-1.854 3.69-4.77 3.69Zm0-7.722c1.242-.036 2.016-.792 2.016-1.8 0-.918-.756-1.71-2.016-1.71-1.242 0-1.998.792-1.998 1.71 0 1.008.774 1.764 1.998 1.8Zm0 5.652c1.494 0 2.304-.882 2.304-1.872 0-1.008-.792-1.908-2.304-1.908-1.494 0-2.286.9-2.286 1.908 0 .99.81 1.872 2.286 1.872Z"
                    />
                </g>
            </g>
        </g>
        <defs>
            <filter
                id="filter0_dd_131_4068"
                x="116"
                y="282"
                width="117"
                height="51"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_131_4068"
                />
                <feOffset />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_131_4068" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect2_dropShadow_131_4068" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend in2="effect1_dropShadow_131_4068" result="effect2_dropShadow_131_4068" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_131_4068" result="shape" />
            </filter>
            <filter
                id="filter1_dd_131_4068"
                x="116"
                y="206"
                width="117"
                height="60"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_131_4068"
                />
                <feOffset />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_131_4068" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect2_dropShadow_131_4068" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend in2="effect1_dropShadow_131_4068" result="effect2_dropShadow_131_4068" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_131_4068" result="shape" />
            </filter>
            <filter
                id="filter2_dd_131_4068"
                x="116"
                y="130"
                width="117"
                height="60"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_131_4068"
                />
                <feOffset />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_131_4068" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect2_dropShadow_131_4068" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend in2="effect1_dropShadow_131_4068" result="effect2_dropShadow_131_4068" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_131_4068" result="shape" />
            </filter>
        </defs>
    </svg>
</template>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.bg {
    box-shadow: $opteo-shadow-l;
    @include br-28;
}
</style>
