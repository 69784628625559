import { computed, ref, watchEffect } from 'vue'

import { NgramTool } from '@opteo/types'

import { useNGramTool } from './useNGramTool'
import { AddNegatives } from './types'

const addNegativesItemType = ref<AddNegatives.ItemType>('nGram')
const formattedItemType = computed(() => AddNegatives.FormattedItemType[addNegativesItemType.value])
/**
 * Final Negative Keyword selection
 */
const negativeKeywords = ref<NgramTool.NegativeKeywordAttributes[]>([])

export function useNGramAddNegatives() {
    const { currentStepIndex } = useNGramTool()

    watchEffect(() => {
        if (currentStepIndex.value === 0) {
            addNegativesItemType.value = 'nGram'
        }
    })

    return {
        addNegativesItemType,
        formattedItemType,
        negativeKeywords,
    }
}
