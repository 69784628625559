<template>
    <router-link to="/">
        <OpteoLogo :width="44" :height="44" class="opteo-logo" />
    </router-link>
    <LoginContainer :width="462" title="Preferences Updated">
        <template v-slot:info>
            <SmallCheck class="email-preferences-check" />Your
            {{ isSlack ? 'Slack' : 'email' }} preferences were updated successfully.
        </template>
        <template v-slot:content>
            <oButton size="login" @clicked="toOpteo">Continue to Opteo</oButton>
        </template>
    </LoginContainer>
</template>

<script lang="ts">
import LoginContainer from '@/components/global/LoginContainer.vue'
import SmallCheck from '@/components/global/SmallCheck.vue'

import { oButton, oForm, oInput, OpteoLogo } from '@opteo/components-next'
import { useEmailLink } from '@/composition/useEmailLink'

export default {
    name: 'EmailLink',
    components: {
        LoginContainer,
        SmallCheck,
        oButton,
        oForm,
        oInput,
        OpteoLogo,
    },
    setup() {
        return useEmailLink()
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.email-preferences-check {
    @include mr-12;
}

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}
@media screen and (max-width: $mq-767-max) {
    .opteo-logo {
        display: none;
    }
}
</style>
