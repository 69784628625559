<template>
    <!-- Completed Experiments -->
    <div v-if="completedExperiments?.length" class="experiments-grid">
        <div v-for="experiment in completedExperiments" :key="experiment.optExperimentId">
            <ExperimentCard :experiment="experiment" :is-active="false" />
        </div>
    </div>
    <!-- Empty State -->
    <div v-else class="empty-state-container">
        <div class="inner-container">
            <img
                src="@/assets/img/bidding-experiments/completed-experiments-empty-state.png"
                style="height: 158px"
            />
            <Spacer height="2rem" />
            <Text as="h5" size="f-5" weight="600" align="center">No Completed Experiments</Text>
            <Spacer height="1rem" />
            <Text as="p" size="f-8" align="center">
                Your completed experiments will be displayed here. Head to the
                <b>Create Experiment</b> tab to create a new experiment.
            </Text>
        </div>
    </div>
</template>

<script lang="ts">
// Imports
import { defineComponent } from 'vue'
import { Routes } from '@/router/routes'
import { useExperiments } from '@/composition/toolkit/experiments/useExperiments'

// Components
import {
    Spinner,
    oTable,
    Money,
    Number,
    Tabs,
    oButton,
    EntityPillList,
    Spacer,
    ColorTag,
    Roas,
    Text,
} from '@opteo/components-next'

// Local Components
import ProgressBar from '@/components/global/ProgressBar.vue'
import ExperimentCard from '@/components/biddingExperiments/ExperimentCard.vue'
import Skeleton from '@/components/util/Skeleton.vue'

// Export
export default defineComponent({
    name: 'CompletedExperiments',
    components: {
        Skeleton,
        Spinner,
        oTable,
        Number,
        Money,
        Tabs,
        oButton,
        EntityPillList,
        Spacer,
        ColorTag,
        Roas,
        ProgressBar,
        ExperimentCard,
        Text,
    },
    setup() {
        return { ...useExperiments(), Routes }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.experiments-grid {
    @include flex;
    flex-direction: column;
    gap: 1rem;
}

.empty-state-container {
    height: calc(100vh - 17.25rem);
    min-height: 30rem;
    @include flex-center;
    flex-direction: column;
}
.empty-state-container .inner-container {
    @include flex-center;
    flex-direction: column;
    max-width: 23.25rem;
}
</style>
