<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="flex-shrink: 0"
    >
        <rect width="24" height="24" rx="12" fill="#F6F6F9" />
        <g clip-path="url(#clip0_54_417)">
            <path
                d="m8.5 12.5 2 2 5-5"
                stroke="#CACAD3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_54_417"><path fill="#fff" d="M7.5 8.5h9v7h-9z" /></clipPath>
        </defs>
    </svg>
</template>
