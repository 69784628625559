import { computed } from 'vue'
import debounce from 'lodash-es/debounce'
import uniqBy from 'lodash-es/uniqBy'

import { Endpoint, useAPI, authRequest } from '../api/useAPI'
import { useDomain } from '../../composition/domain/useDomain'

import { ImprovementList, Improvement, Platform } from '@opteo/types'
import { useAccount } from '../account/useAccount'

type RelevantRecActions = Exclude<Improvement.RecAction, Improvement.SyncRecAction>

type SettingsCategoryMap = Record<
    RelevantRecActions,
    (typeof REC_ACTION_CATEGORIES)[keyof typeof REC_ACTION_CATEGORIES]
>

const REC_ACTION_CATEGORIES = {
    keywords: { key: 'keywords', label: 'Manage Keywords' },
    ad_creative: { key: 'ad_creative', label: 'Improve Ad Creative' },
    bids: { key: 'bids', label: 'Optimise Bids' },
    exclude_bad_traffic: { key: 'exclude_bad_traffic', label: 'Exclude Bad Traffic' },
    detect_errors: { key: 'detect_errors', label: 'Detect Errors' },
    shopping: { key: 'shopping', label: 'Manage Shopping Ads' },
    other: { key: 'other', label: 'Create Campaigns' },
}

const MERGED_REC_ACTIONS: { [mergedDemoName: string]: Improvement.RecAction[] } = {
    ['Adjust Demographic Bids']: [
        Improvement.RecAction.AdjustSinglePlacementDemoBid,
        Improvement.RecAction.AdjustMultiplePlacementDemoBids,
    ],
    ['Adjust Device Bids']: [
        Improvement.RecAction.AdjustSingleDeviceBid,
        Improvement.RecAction.AdjustMultipleDeviceBids,
    ],
    ['Pause Keywords']: [Improvement.RecAction.PauseKeyword, Improvement.RecAction.PauseKeywordV2],
}

const SETTINGS_CATEGORY_MAP = {
    [Improvement.RecAction.AddCallExtension]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.AddCalloutExtension]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.AddCampaignLevelCalloutExtension]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.AddCampaignLevelSitelinkExtension]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.AddCampaignLevelSnippetExtension]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.AddFrequencyCapping]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.AddMissingCampaignsToSharedSet]: REC_ACTION_CATEGORIES.detect_errors,
    [Improvement.RecAction.AddNegativeGeoKeyword]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.AddNegativeListShopping]: REC_ACTION_CATEGORIES.shopping,
    [Improvement.RecAction.AddNegativeNgram]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.AddNegativeWord]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.AddRobotNegative]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.AddSiteExclusions]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.AddSitelinkExtension]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.AddSkagV1]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.AddSkagV2]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.AddStructuredSnippetsExtension]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.AdjustAdGroupMultiTargets]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustAdScheduleBids]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustAdScheduleBidsV2]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustSinglePlacementDemoBid]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustMultiplePlacementDemoBids]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustAgeBid]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustAttributionModel]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustDeviceBid]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustMultipleDeviceBids]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustSingleDeviceBid]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.ExcludeSingleDevice]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.AdjustGenderBid]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustGeoBids]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustLocationBids]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustKeywordBid]: REC_ACTION_CATEGORIES.bids, // could also be keyword
    [Improvement.RecAction.AdjustKeywordBidV2]: REC_ACTION_CATEGORIES.bids, // could also be keyword
    [Improvement.RecAction.AdjustParentalBid]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.AdjustProductGroupBid]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.CheckAdSpelling]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.CheckQueryRelevance]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.CreateBrandCampaign]: REC_ACTION_CATEGORIES.other,
    [Improvement.RecAction.CreateRemarketingCampaign]: REC_ACTION_CATEGORIES.other,
    [Improvement.RecAction.DailyAccountCheckups]: REC_ACTION_CATEGORIES.other,
    [Improvement.RecAction.DisableInterestLocations]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.DisableInterestLocationsV2]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.DisableSearchPartners]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.DuplicateKeyword]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.ExcludeContentAccount]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.ExcludeContentMobileApp]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.ExcludeContentMobileAll]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.ExcludeContentWebsite]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.ExcludeContentYoutubeAll]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.ExcludeContentYoutubeChannel]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.ExcludeContent]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.ExcludeLocation]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.ExcludePlacement]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.ExcludeMobileApps]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.ExcludeSingleDemographic]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.FixBelowFirstPageBid]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.FixBrokenLink]: REC_ACTION_CATEGORIES.detect_errors,
    [Improvement.RecAction.FixBrokenSitelink]: REC_ACTION_CATEGORIES.detect_errors,
    [Improvement.RecAction.FixNegativesBlockingKeywords]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.IncreaseKeywordBidV2]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.LiftCampaignBudgetCap]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.PauseAd]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.PauseAdV2]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.PauseDuplicateKeywords]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.PauseDuplicateKeywordsV2]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.PauseKeyword]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.PauseKeywordV2]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.ReduceCampaignBids]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.ReduceNgramBids]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.ReduceWordBids]: REC_ACTION_CATEGORIES.bids,
    [Improvement.RecAction.RemoveBroadKeywords]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.RemoveBroadKeywordsV2]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.RemovePlussedNegatives]: REC_ACTION_CATEGORIES.detect_errors,
    [Improvement.RecAction.ResyncExclusionLists]: REC_ACTION_CATEGORIES.detect_errors,
    [Improvement.RecAction.ResyncProductGroups]: REC_ACTION_CATEGORIES.shopping,
    [Improvement.RecAction.SearchPartnersSettings]: REC_ACTION_CATEGORIES.exclude_bad_traffic,
    [Improvement.RecAction.SubdivideProductGroup]: REC_ACTION_CATEGORIES.shopping,
    [Improvement.RecAction.WriteAd]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.WriteAdV2]: REC_ACTION_CATEGORIES.ad_creative,
    [Improvement.RecAction.CheckQueryRelevanceMs]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.PauseKeywordMs]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.AdjustKeywordBidMs]: REC_ACTION_CATEGORIES.keywords,
    [Improvement.RecAction.IncreaseKeywordBidMs]: REC_ACTION_CATEGORIES.keywords,
} as const satisfies SettingsCategoryMap

export function useImprovementPreferences() {
    const { mutateDomain, domainId, accountId } = useDomain()
    const { accountInfo } = useAccount()

    const accountPlatform = computed(() => accountInfo?.value?.platform)
    const { data: improvementPreferences } = useAPI<ImprovementList.UserDomainPreferences[]>(
        Endpoint.GetImprovementPreferences,
        {
            body: () => ({ account_id: accountId.value }),
            uniqueId: () => accountId.value,
            waitFor: () => accountId.value,
        }
    )

    /* This function will join multiple rec actions of the same type - found in `MERGED_REC_ACTIONS` into a single one to be displayed in settings */
    const recActionPreferences = computed<ImprovementList.RecActionPreferences[] | undefined>(
        () => {
            return uniqBy(
                (improvementPreferences.value ?? [])[0]?.rec_action_preferences
                    .map(recAction => {
                        if (!Object.entries(MERGED_REC_ACTIONS).length) {
                            return recAction
                        }
                        const recActionName = recAction?.rec_actions[0]

                        let mergedRecAction
                        for (const [newName, toBeMerged] of Object.entries(MERGED_REC_ACTIONS)) {
                            const needsToMerge = recActionName && toBeMerged.includes(recActionName)
                            if (needsToMerge) {
                                recAction.static_title = newName
                                recAction.rec_actions = toBeMerged
                            }
                            mergedRecAction = recAction
                        }

                        return mergedRecAction as ImprovementList.RecActionPreferences
                    })
                    .filter(mergedRecAction => mergedRecAction.static_title),
                'static_title'
            )
        }
    )

    const recActionPrefrerenceCategories = computed(() => {
        return Object.keys(REC_ACTION_CATEGORIES)
            .map(_key => {
                const categoryKey = _key as keyof typeof REC_ACTION_CATEGORIES
                return {
                    categoryLabel: REC_ACTION_CATEGORIES[categoryKey].label,
                    preferences: recActionPreferences.value?.filter(preference => {
                        //@ts-ignore
                        if (!SETTINGS_CATEGORY_MAP[preference.rec_actions[0]]) {
                            throw new Error('rec_action missing in SETTINGS_CATEGORY_MAP')
                        }
                        return (
                            // @ts-ignore TODO: Remove me, waiting for dev fix
                            SETTINGS_CATEGORY_MAP[preference.rec_actions[0]].key ===
                            REC_ACTION_CATEGORIES[categoryKey].key
                        )
                    }),
                }
            })
            .filter(categorySection => categorySection?.preferences?.length)
    })

    const updateActiveImpTypes = debounce(async () => {
        if (!recActionPreferences.value) {
            throw new Error('cannot update imp preferences before it is defined')
        }
        const rec_actions = recActionPreferences.value
            .filter(pref => !pref.selected)
            .map(pref => pref.rec_actions)
            .flat()

        await authRequest(Endpoint.SetImprovementPreferences, {
            body: { rec_actions, domain_id: domainId.value },
        })

        await mutateDomain()
    }, 500)

    return {
        recActionPreferences,
        recActionPrefrerenceCategories,
        updateActiveImpTypes,
    }
}
