<template>
    <Popout
        v-model="popoutVisible"
        :zIndex="9999999999999"
        :offset="[0, -3]"
        :borderRadius="16"
        trigger="mouseenter"
        placement="top"
        class="entity-item ad-score"
        maxWidth="100rem"
    >
        <div ref="assetScore" class="asset-score">
            <ScorecardDonut
                :score="score ?? 0"
                :hidden="donutEmptyState"
                :compact="true"
                :width="24"
                :strokeWidth="2"
                :hideScore="true"
            />
        </div>
        <template v-slot:content>
            <slot></slot>
        </template>
    </Popout>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'

import { Popout } from '@opteo/components-next'
import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'


export default defineComponent({
    components: {
        Popout,
        ScorecardDonut,
    },
    props: {
        score: {
            type: Number,
            required: false, // can be false if no score available
        },
    },
    setup(props) {
        const popoutVisible = ref(false)

        const donutEmptyState = computed(() => {
            return !props.score
        })
        return { popoutVisible, donutEmptyState }
    },
})
</script>

<style scoped>
.asset-score {
    padding: 0.875rem 1rem;
}
</style>
