<template>
    <div
        :class="[
            conversationHovered ? 'show-controls' : '',
            'conversation-wrapper',
            { active: isCurrentConversation },
        ]"
        @mouseenter="conversationHovered = true"
        @mouseleave="conversationHovered = false"
        ref="conversationWrapper"
    >
        <button class="conversation" @click="onClicked">
            <Text as="span" v-html="HTML" />
        </button>
        <div class="buttons">
            <!-- Edit -->
            <Popout
                v-model="editPopout"
                :appendTo="$refs.conversationWrapper"
                :popperOptions="{
                    strategy: 'fixed',
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                boundary: bodyElement,
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                flipVariations: false,
                            },
                        },
                    ],
                }"
                :offset="[-198, 10]"
                :maxWidth="270"
                :borderRadius="18"
                placement="top-start"
            >
                <button class="edit-button" tabindex="-1" @click="editClicked">
                    <SmallPencilIcon :hex="editPopout ? '#0035ec' : '#000'" />
                </button>
                <template v-slot:content>
                    <div class="popout-container">
                        <!-- Input -->
                        <oInput
                            v-model="title"
                            placeholder="Conversation Title"
                            name="title"
                            ref="titleInput"
                            type="text"
                            style="width: 14.375rem"
                        />
                        <!-- Buttons -->
                        <div class="popout-buttons">
                            <oButton color="white" size="small" @clicked="closeEditPopout()">
                                Cancel
                            </oButton>
                            <oButton
                                color="dark-blue"
                                size="small"
                                ref="updateTitleButton"
                                :loading="updateTitleLoading"
                                @clicked="updateConversationTitle(title)"
                            >
                                Save Changes
                            </oButton>
                        </div>
                    </div>
                </template>
            </Popout>
            <!-- Delete -->
            <Popout
                v-model="deletePopout"
                :appendTo="$refs.conversationWrapper"
                :popperOptions="{
                    strategy: 'fixed',
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                boundary: bodyElement,
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                flipVariations: false,
                            },
                        },
                    ],
                }"
                :offset="[-230, 10]"
                :maxWidth="270"
                :borderRadius="18"
                placement="top-start"
            >
                <button class="delete-button" tabindex="-1" @click="deleteClicked">
                    <SmallCrossIcon :hex="deletePopout ? '#ff2828' : '#000'" />
                </button>
                <template v-slot:content>
                    <div class="popout-container">
                        <Text as="p" size="f-8" style="width: 14.375rem">
                            Click the button below to delete this conversation. Please note this
                            action is permanent and cannot be undone.
                        </Text>
                        <!-- Buttons -->
                        <div class="popout-buttons">
                            <oButton color="white" size="small" @clicked="closeDeletePopout()">
                                Cancel
                            </oButton>
                            <oButton
                                ref="deleteButton"
                                color="red"
                                size="small"
                                :loading="deleteLoading"
                                @clicked="deleteConversation"
                            >
                                Delete Conversation
                            </oButton>
                        </div>
                    </div>
                </template>
            </Popout>
        </div>
    </div>
</template>

<script lang="ts">
// Vue
import { defineComponent, ref, computed } from 'vue'

// Utils
import { delay } from '@/lib/globalUtils'
import { Endpoint, authRequest } from '@/composition/api/useAPI'

// @opteo/components-next
import { Text, oButton, Popout, oInput, oForm, useShortKey } from '@opteo/components-next'

// @opteo/types
import { Chat } from '@opteo/types'

// Chat components
import SmallPencilIcon from '@/components/chat/SmallPencilIcon.vue'
import SmallCrossIcon from '@/components/chat/SmallCrossIcon.vue'
import { useChatEntities } from '@/composition/chat/useChatEntities'

export default defineComponent({
    name: 'ChatConversation',
    components: {
        // @opteo/components-next
        Text,
        oButton,
        Popout,
        oInput,
        oForm,
        // Chat components
        SmallPencilIcon,
        SmallCrossIcon,
    },
    props: {
        conversation: {
            type: Object,
            default: {},
        },
        id: {
            type: String,
            required: true,
        },
        entities: {
            type: Object,
            default: {},
        },
        currentConversationId: {
            type: String,
            default: '',
        },
    },
    emits: ['clicked', 'chatDeleted', 'titleUpdated'],
    setup(props, { emit }) {
        // Title
        const title = ref(props.conversation.title)

        const { HTML } = useChatEntities(title, props.entities)

        // onClicked
        function onClicked() {
            emit('clicked')
        }

        // editClicked
        function editClicked() {
            focusEditInput()
        }
        // deleteClicked
        function deleteClicked() {}

        // focusEditInput()
        const focusEditInput = async () => {
            await delay(1)
            titleInput.value.inputRef.select()
        }

        // updateConversationTitle()
        async function updateConversationTitle(title: string) {
            updateTitleLoading.value = true

            const requestBody = {
                chat_id: props.id,
                title,
            }

            const response: Chat.UpdateChatTitleResponse = await authRequest(
                Endpoint.updateChatTitle,
                {
                    body: () => requestBody,
                }
            )

            await delay(1000)

            updateTitleLoading.value = false

            if (response.success) {
                updateTitleButton.value.flashSuccess()
                emit('titleUpdated', title)
                await delay(650)
                editPopout.value = false
            } else {
                updateTitleButton.value.flashError()
            }
        }

        // deleteConversation()
        async function deleteConversation() {
            deleteLoading.value = true

            const requestBody = {
                chat_id: props.id,
            }

            const response: Chat.DeleteChatResponse = await authRequest(Endpoint.deleteChat, {
                body: () => requestBody,
            })

            await delay(1000)

            deleteLoading.value = false

            if (response.success) {
                deleteButton.value.flashSuccess()
                await delay(650)

                emit('chatDeleted')
                deletePopout.value = false
            } else {
                deleteButton.value.flashError()
            }
        }

        // closeEditPopout()
        function closeEditPopout() {
            editPopout.value = false
            conversationHovered.value = false
        }
        // closeDeletePopout()
        function closeDeletePopout() {
            deletePopout.value = false
            conversationHovered.value = false
        }

        useShortKey({
            keys: ['enter'],
            onPressCallBack: () => {
                if (editPopout.value === true) {
                    updateConversationTitle(title.value)
                } else if (deletePopout.value === true) {
                    deleteConversation()
                }
            },
        })

        // conversationHovered
        const conversationHovered = ref(false)

        // editPopout
        const editPopout = ref(false)

        // deletePopout
        const deletePopout = ref(false)

        // deleteButton
        const deleteButton = ref(null)
        const deleteLoading = ref(false)

        // updateTitleButton
        const updateTitleButton = ref(null)
        const updateTitleLoading = ref(false)

        // conversationWrapper
        const conversationWrapper = ref(null)

        // bodyElement
        const bodyElement = document.body

        // titleInput
        const titleInput = ref(null)

        const isCurrentConversation = computed(() => {
            return props.currentConversationId === props.id
        })

        // Return
        return {
            title,
            HTML,
            onClicked,
            editClicked,
            deleteClicked,
            deleteButton,
            conversationHovered,
            editPopout,
            conversationWrapper,
            deletePopout,
            bodyElement,
            titleInput,
            updateConversationTitle,
            closeEditPopout,
            closeDeletePopout,
            deleteConversation,
            deleteLoading,
            updateTitleButton,
            updateTitleLoading,
            isCurrentConversation,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.conversation-wrapper {
    @include relative;
    margin-bottom: 0.625rem;
}
.conversation-wrapper:last-child {
    margin-bottom: 0;
}

.conversation {
    font-feature-settings: 'ss01' on, 'ss02' on, 'ss04' on, 'ss05' on, 'ss08' off;
    outline: none;
    border: none;
    @include bg-opteo-white;
    // @include container;
    border-radius: 0.75rem !important;
    @include ph-16;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: nowrap;
    min-height: 2.875rem;
    overflow: hidden;
    @include flex;
    @include items-center;
    @include f-8;
    @include relative;
    cursor: pointer;
    user-select: none;
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    transition: transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1), color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.conversation span {
    transition: color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    line-height: 1.625rem;
}
.conversation-wrapper.show-controls .conversation {
    @include container;
}
.conversation::after {
    content: '';
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 75%);
    width: 3rem;
    height: 100%;
    @include absolute;
    right: 0;
    @include z-2;
    pointer-events: none;
}
.conversation-wrapper .buttons {
    @include absolute;
    padding-right: 0.6875rem;
    right: 0;
    top: 0;
    height: 100%;
    @include flex;
    @include items-center;
    gap: 0.5rem;
    @include z-3;
    transition: background-color 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
.conversation-wrapper .buttons::after {
    content: '';
    width: 3rem;
    height: 100%;
    @include absolute;
    left: -3rem;
    pointer-events: none;
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 75%);
    opacity: 0;
    transition: opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.conversation-wrapper.show-controls .buttons::after {
    opacity: 1;
}
.conversation-wrapper.show-controls .buttons {
    @include bg-opteo-white;
}
.conversation-wrapper .buttons :deep(.popout-wrapper) {
    @include z-3;
}
.conversation-wrapper .edit-button {
    // Transition
    transition: opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    // Hover
    opacity: 0;
    // transform: translateY(0.375rem);
    @include z-4;
}
.conversation-wrapper .delete-button {
    // Transition
    transition: opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    // transition-delay: 100ms;
    // Hover
    opacity: 0;
    // transform: translateY(0.375rem);
    @include z-4;
}
.conversation-wrapper.show-controls .edit-button,
.conversation-wrapper.show-controls .delete-button {
    opacity: 1;
    transform: translateY(0);
}

.conversation-wrapper .buttons button {
    outline: none;
    border: none;
    box-shadow: $opteo-shadow;
    @include bg-opteo-white;
    width: 1.5rem;
    height: 1.5rem;
    @include flex-center;
    border-radius: 0.375rem;
    cursor: pointer;
    // Transition
    transition: transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1), color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.conversation-wrapper .buttons button:focus,
.conversation:focus,
.conversation-wrapper.show-controls .conversation:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}

.conversation-wrapper .buttons button:active,
.conversation:active,
.conversation-wrapper.show-controls .conversation:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}

.conversation:active + .buttons {
    transform: translate3d(0, 1px, 0);
}

// Popout

.popout-container {
    @include pa-20;
    @include flex;
    flex-direction: column;
    gap: 1rem;
}
.popout-buttons {
    @include flex;
    @include items-center;
    gap: 0.5rem;
}
.active .conversation span {
    color: $opteo-dark-blue;
}
</style>
