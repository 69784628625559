import { computed, ref, Ref } from 'vue'
import groupBy from 'lodash-es/groupBy'
import add from 'date-fns/add'
import sub from 'date-fns/sub'
import startOfDay from 'date-fns/startOfDay'
import parseISO from 'date-fns/parseISO'
import max from 'date-fns/max'
import isBefore from 'date-fns/isBefore'
import format from 'date-fns/format'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'

import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'
import { useAccountList } from '../user/useAccountList'
import { Account } from '@opteo/types'

type ISODate = string // YYYY-MM-DD
type ISODateTime = string // YYYY-MM-DD hh:mm:ss:SSS + timezone

interface AllNotes {
    [date: string]: DayBlock
}

interface DayBlock {
    date: ISODate
    notes: UserNote[]
    opteo: OpteoNote[]
    user_joined?: boolean
}

export interface NormalisedDayBlock {
    date: ISODate
    formattedDate: string
    notes: NormalisedNote[]
}

interface UserNote {
    id: number
    ts: ISODateTime
    day: ISODate
    domain_id: number
    user_id: number
    note: string
    deleted: 0 | 1
    username: string
    name: string
    avatar_filename: string
}

interface OpteoNote {
    ts: ISODateTime
    text: string
    authors: {
        user_id: number
        name: string
        username: string
        avatar_filename?: string
    }[]
    is_start_note: boolean
}

export interface NormalisedNote {
    id: number
    ts: ISODateTime
    day: ISODate
    type: NoteType
    text: string
    author_user_id: number
    author_name: string
    author_email: string
    author_avatar_filename?: string
    currentlyEditing: boolean
}

export enum NoteType {
    AccountStart = 'accountStart',
    Improvemement = 'improvement',
    Text = 'text',
}

/*
    `currentlyEditing` lives outside of useNotes such that it is 
    common to all callers of useNotes()
*/
const currentlyEditing = ref(0)

export const useNotes = (accountId: Ref<Account.ID>) => {
    const { getAccountInfo } = useAccountList()

    const domainName = computed(() => getAccountInfo(accountId.value)?.name)

    const {
        data: allNotes,
        loading,
        mutate: mutateNotes,
    } = useAPI<AllNotes>(Endpoint.GetAllNotes, {
        body: () => {
            return {
                timezone_offset: new Date().getTimezoneOffset(),
                account_id: accountId.value,
            }
        },
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const notesByDay = computed(() => {
        if (!allNotes.value) {
            return []
        }

        const timeline = []
        let cursor = startOfDay(sub(new Date(), { years: 1 }))

        /*
            Because of timezone memes, some notes may have been written tomorrow.
            To make sure we always show all notes, set the "end_date" cursor to be at the latest date ts.
        */
        const endDateContenders = [...Object.keys(allNotes.value).map(d => parseISO(d)), new Date()]
        const maxEndDate = max(endDateContenders)
        const endDate = add(maxEndDate, { hours: 1 }) // this helps to avoid skipping todays date

        while (isBefore(cursor, endDate)) {
            const date = format(cursor, 'yyyy-MM-dd')
            const formattedDate = format(cursor, 'MMMM do yyyy')
            const changes = allNotes.value[date]
            const normalisedNotes = [...(changes?.notes ?? []), ...(changes?.opteo ?? [])].map(
                note => normaliseNote(note, date)
            )
            const blob: NormalisedDayBlock = {
                date,
                formattedDate,
                notes: normalisedNotes.reverse(),
            }
            timeline.unshift(blob)

            cursor = add(cursor, { days: 1 })
        }

        return timeline
    })

    /*
        While the data is loading, will return a dummy set of 
        dates for skeleton rendering.
    */
    const notesByMonth = computed(() => {
        const monthYearFormat = 'yyyy-MM'
        const prettyFormat = 'MMMM yyyy'
        if (loading.value) {
            return Object.values(
                groupBy(
                    eachDayOfInterval({
                        start: sub(new Date(), { years: 1 }),
                        end: new Date(),
                    }),
                    d => format(d, monthYearFormat)
                )
            )
                .reverse()
                .map(days => {
                    return {
                        month: format(days[0], prettyFormat),
                        days,
                    }
                })
        }

        return Object.values(
            groupBy(notesByDay.value, d => format(parseISO(d.date), monthYearFormat))
        ).map(days => {
            return {
                month: format(parseISO(days[0].date), prettyFormat),
                days: days,
            }
        })
    })

    const datesWithNotes = computed(() => {
        return notesByDay.value.filter(day => day.notes.length).map(day => new Date(day.date))
    })

    /*
        Imp pushes and UGC notes are different, 
        but this fn gives them the same shape such that 
        the Notes.vue component can be simplified.
    */
    const normaliseNote = (note: OpteoNote | UserNote, day: ISODate): NormalisedNote => {
        function isUserNote(note: OpteoNote | UserNote): note is UserNote {
            return (note as UserNote).id !== undefined
        }

        const normalisedNote: NormalisedNote = {
            id: new Date(note.ts).getUTCMilliseconds(),
            ts: note.ts,
            day,
            type: NoteType.Text,
            text: '',
            author_user_id: 1,
            author_name: '',
            author_email: '',
            currentlyEditing: false,
        }

        if (isUserNote(note)) {
            const { id, note: text, name, username, avatar_filename, user_id } = note

            return {
                ...normalisedNote,
                id,
                text,
                author_user_id: user_id,
                author_name: name,
                author_email: username,
                author_avatar_filename: avatar_filename,
                currentlyEditing: currentlyEditing.value == id,
            }
        }

        /* There can (rarely) be multiple authors here (for batches of pushed imps). One day, show them all */
        return {
            ...normalisedNote,
            type: note.is_start_note ? NoteType.AccountStart : NoteType.Improvemement,
            text: note.text,
            author_user_id: note.authors[0]?.user_id,
            author_name: note.authors[0]?.name ?? 'Opteo',
            author_email: note.authors[0]?.username ?? 'support@opteo.com',
            author_avatar_filename: note.authors[0]?.avatar_filename,
        }
    }

    const startNoteEdit = (noteId: number) => {
        currentlyEditing.value = noteId
    }

    const endNoteEdit = () => {
        currentlyEditing.value = 0
    }

    const deleteNote = async (noteId: number, day: ISODate) => {
        if (!allNotes.value) {
            throw new Error('operation impossible until allNotes is set')
        }

        const mutatedAllNotes: AllNotes = {
            ...allNotes.value,
            [day]: {
                ...allNotes.value[day],
                notes: allNotes.value[day].notes.filter(note => note.id !== noteId),
            },
        }

        mutateNotes(() => mutatedAllNotes)

        await authRequest(Endpoint.DeleteNote, {
            body: {
                account_id: accountId.value,
                note_id: noteId,
            },
        })

        await mutateNotes()
    }

    const saveNote = async (noteId: number, day: ISODate, newText: string) => {
        if (!allNotes.value) {
            throw new Error('operation impossible until allNotes is set')
        }

        const matchingNote = allNotes.value[day].notes.find(note => note.id === noteId)
        if (!matchingNote) {
            throw new Error('cannot edit missing note')
        }

        const mutatedAllNotes: AllNotes = {
            ...allNotes.value,
            [day]: {
                ...allNotes.value[day],
                notes: [
                    ...allNotes.value[day].notes.filter(note => note.id !== noteId),
                    {
                        ...matchingNote,
                        note: newText,
                    },
                ],
            },
        }

        mutateNotes(() => mutatedAllNotes)

        endNoteEdit()

        await authRequest(Endpoint.UpdateNote, {
            body: {
                account_id: accountId.value,
                note_id: noteId,
                text: newText,
            },
        })

        await mutateNotes()
    }

    const addNote = async (day: ISODate) => {
        if (!allNotes.value) {
            throw new Error('operation impossible until allNotes is set')
        }

        const newNote = await authRequest<UserNote>(Endpoint.AddNote, {
            body: {
                account_id: accountId.value,
                note: '',
                day,
            },
        })

        startNoteEdit(newNote.id)

        await mutateNotes()
    }

    const exportNotes = () => {
        const filename = `${domainName.value} Notes ${format(new Date(), 'yyyy-MM-dd')}.csv`
        const rows = ['"Date","Author","Note"']

        notesByDay.value.forEach(day => {
            day.notes.forEach(note => {
                const content = note.text.replace(/"/g, '""')
                rows.push(
                    `"${format(parseISO(note.day), 'yyyy-MM-dd')}","${
                        note.author_name
                    }","${content}"`
                )
            })
        })

        const csvOutput = rows.join('\n')

        const encoded_uri = encodeURI(csvOutput)
        const link = document.createElement('a')

        if (link.download !== undefined) {
            // Works only in browsers that support HTML5 download attribute
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encoded_uri)
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
        }
    }

    return {
        loading,
        notesByDay,
        notesByMonth,
        datesWithNotes,
        mutateNotes,
        startNoteEdit,
        endNoteEdit,
        deleteNote,
        saveNote,
        addNote,
        exportNotes,
    }
}
