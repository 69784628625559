import type { NGramRes, PanelItem, SearchTermRes } from './types'

const hasNGramObjProperty = (item: NGramRes | SearchTermRes | PanelItem) => {
    return !!item?.hasOwnProperty('ngram')
}

export const isNGramRes = (item: NGramRes | SearchTermRes): item is NGramRes => {
    return hasNGramObjProperty(item)
}

export const isNGramItem = (item: PanelItem): item is PanelItem<NGramRes> => {
    return hasNGramObjProperty(item)
}
