<template>
    <div class="chat-select-skeleton-item">
        <component :is="icon" :tooltip="false" :dark="true" />
        <Skeleton :width="100" :height="14" />
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import type { EntityType } from './ChatInput.vue'
import {
    CircleCampaignIcon,
    CircleAdgroupIcon,
    CircleKeywordIcon,
    Text,
    Spacer,
} from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'

export default defineComponent({
    name: 'ChatSelectSkeletonItem',
    props: {
        entityType: {
            type: String as PropType<EntityType>,
            default: 'entity-type',
        },
    },
    components: {
        CircleCampaignIcon,
        CircleAdgroupIcon,
        CircleKeywordIcon,
        Text,
        Spacer,
        Skeleton,
    },
    emits: ['select'],
    setup(props, { emit }) {
        const entityIcons = {
            campaign: CircleCampaignIcon,
            adgroup: CircleAdgroupIcon,
            keyword: CircleKeywordIcon,
        }

        const icon = computed(() => entityIcons[props.entityType])

        return {
            icon,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chat-select-skeleton-item {
    @include flex;
    @include items-center;
    gap: 0.75rem;
    padding: 0.625rem 0.875rem;
    width: 174px;
}
</style>
