<template>
    <div class="account-type-selector">
        <div class="small">
            <Label>Account Type</Label>
            <oInput
                v-model="type"
                :items="[
                    {
                        value: 'agency',
                        label: '<b>Agency</b> — I manage Google Ads on behalf of my clients.',
                    },
                    {
                        value: 'advertiser',
                        label: '<b>Business</b> — I manage Google Ads on behalf of my business.',
                    },
                ]"
                name="avatar"
                type="radio"
            />
        </div>
        <div class="medium">
            <Label>Account Type</Label>
            <oInput
                v-model="type"
                :items="[
                    {
                        value: 'agency',
                        label: '<b>Agency</b> — I manage Google Ads accounts on behalf of my clients.',
                    },
                    {
                        value: 'advertiser',
                        label: '<b>Business</b> — I manage the Google Ads account for my business.',
                    },
                ]"
                name="avatar"
                type="radio"
            />
        </div>
        <div class="large">
            <Label>Account Type</Label>
            <oInput
                v-model="type"
                :items="[
                    {
                        value: 'agency',
                        label: '<b>Agency</b> — I manage a number of Google Ads accounts on behalf of my clients.',
                    },
                    {
                        value: 'advertiser',
                        label: '<b>Business</b> — I manage one or two Google Ads accounts on behalf of my business.',
                    },
                ]"
                name="avatar"
                type="radio"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue'
import { oInput, Label } from '@opteo/components-next'

export default defineComponent({
    name: 'AccountTypeSelector',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    components: {
        Label,
        oInput,
    },
    setup(props, { emit }) {
        const type = ref(props.modelValue)

        watch(type, async newVal => emit('update:modelValue', newVal))

        return {
            type,
        }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Phones up to and including iPhone 5
$mq-0-min: 0px;
$mq-320-max: 320px;
// Phones up to and including iPhone 6/7/8/X
$mq-321-min: 321px;
$mq-375-max: 375px;
// Extra large phones up to and including Nokia N9 + iPhone Plus
$mq-376-min: 376px;
$mq-480-max: 480px;

.account-type-selector .o-input__radio {
    @include container;
    padding: 1rem 1.125rem;
    @include lh-140;
    width: 100%;
}
.account-type-selector .o-input__radio:first-child {
    @include mb-12;
}
.account-type-selector .o-input__radio .circle {
    top: 50%;
    transform: translateY(-50%);
    left: 1.5rem;
}

.account-type-selector .o-input__radio .o-input__text {
    margin-left: 0.875rem;
    white-space: normal;
}

// Small mobiles
@media screen and (max-width: 374px) {
    .account-type-selector .medium {
        display: none;
    }
    .account-type-selector .large {
        display: none;
    }
}
// Medium mobiles
@media screen and (min-width: 375px) and (max-width: 414px) {
    .account-type-selector .small {
        display: none;
    }
    .account-type-selector .large {
        display: none;
    }
}
// Large mobiles and above
@media screen and (min-width: 415px) {
    .account-type-selector .small {
        display: none;
    }
    .account-type-selector .medium {
        display: none;
    }
}
</style>
