<template>
    <svg width="112" height="64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="position-based-mask"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="112"
            height="64"
        >
            <path fill="#fff" d="M0 0h112v64H0z" />
        </mask>
        <g mask="url(#position-based-mask)" fill="#6E3AFF">
            <path
                opacity="1"
                d="M90 35c0-1.6569 1.3431-3 3-3h16c1.657 0 3 1.3431 3 3v26c0 1.6569-1.343 3-3 3H93c-1.6569 0-3-1.3431-3-3V35Z"
            />
            <rect opacity=".4" x="60" y="48" width="22" height="16" rx="3" />
            <rect opacity=".4" x="30" y="48" width="22" height="16" rx="3" />
            <rect opacity="1" y="32" width="22" height="32" rx="3" />
        </g>
    </svg>
</template>
