<template>
    <perfect-scrollbar :class="classes" class="keyword-location">
        <!-- Campaign -->
        <EntityPill v-if="campaign" type="campaign" :content="campaign" size="small" />
        <!-- Ad Group -->
        <EntityPill v-if="adgroup" type="adgroup" :content="adgroup" size="small" />
        <!-- Keyword -->
        <EntityPill v-if="keyword" type="keyword" :content="keyword" size="small" />
    </perfect-scrollbar>
</template>

<script lang="ts">
import { EntityPill } from '@opteo/components-next'

export default {
    components: { EntityPill },

    props: {
        classes: {
            type: String,
            required: false,
            default: '',
        },
        campaign: {
            type: String,
            required: false,
            default: '',
        },
        adgroup: {
            type: String,
            required: false,
            default: '',
        },
        keyword: {
            type: String,
            required: false,
            default: '',
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Base
.keyword-location {
    @include container;
    @include inline-flex;
    @include items-center;
    @include ph-20;
    @include pv-16;
    gap: 0.625rem;
    max-width: 100%;
}
</style>
