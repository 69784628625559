<template>
    <Popout
        v-model="popoutOpen"
        :trapFocus="true"
        :offset="[0, -2]"
        :borderRadius="16"
        placement="bottom"
        max-width="23.25rem"
    >
        <!-- Button -->
        <div class="exclusion-switcher" tabindex="0">
            <div class="left">
                <div :class="[modelValue === true ? 'red' : '', 'status-indicator']" />
                <span v-if="modelValue === true">Exclude</span>
                <span v-else>No Change</span>
            </div>
            <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9 1 5 5 1 1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
        <!-- Popout Content -->
        <template v-slot:content>
            <div class="exclusion-switcher-popout">
                <button @click="updateValue(false)">
                    <div class="status-indicator" />
                    No Change
                </button>
                <button @click="updateValue(true)">
                    <div class="status-indicator red" />
                    Exclude
                </button>
            </div>
        </template>
    </Popout>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Popout } from '@opteo/components-next'

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const popoutOpen = ref(false)

function updateValue(updatedValue: boolean) {
    emit('update:modelValue', updatedValue)

    popoutOpen.value = false
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.exclusion-switcher {
    @include container;
    @include br-999;
    @include inline-flex;
    @include items-center;
    gap: 0.625rem;
    @include f-9;
    @include fw-500;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    line-height: 1.375rem;
    padding: 7px 18px;
    cursor: pointer;
    user-select: none;
}
.exclusion-switcher:focus,
.exclusion-switcher-popout button:focus,
.exclusion-switcher-popout button:hover:focus {
    box-shadow: $opteo-shadow-focus;
    outline: none;
}
.exclusion-switcher:active,
.exclusion-switcher-popout button:active {
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
    -webkit-transform: translate3d(0, 1px, 0);
    -moz-transform: translate3d(0, 1px, 0);
    outline: none;
}

.left {
    @include flex;
    @include items-center;
    gap: 0.625rem;
}
.status-indicator {
    @include bg-opteo-medium-gray;
    width: 0.5rem;
    height: 0.5rem;
    @include br-999;
}
.status-indicator.red {
    @include bg-opteo-red;
}
.exclusion-switcher svg path {
    stroke: $opteo-medium-gray;
}

.exclusion-switcher-popout {
    @include flex;
    flex-direction: column;
    gap: 0.5rem;
    @include pa-12;
    width: 154px;
}
.exclusion-switcher-popout button {
    @include flex;
    @include items-center;
    border: none;
    text-decoration: none;
    background: $opteo-white;
    color: $opteo-black;
    font-family: inherit;
    cursor: pointer;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    border-radius: 0.75rem;
    @include relative;
    @include f-9;
    @include fw-500;
    user-select: none;
    gap: 0.625rem;
    line-height: 1.375rem;
    padding: 6px 12px;
}
.exclusion-switcher-popout button:hover {
    box-shadow: $opteo-shadow;
}
</style>
