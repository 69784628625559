import { useUser } from '@/composition/user/useUser'
import { API_URL } from '@/lib/env'
import { Account, Domain } from '@opteo/types'

export function buildSerpPreviewUrl({
    accountId,
    searchTerm,
    scale = 2,
}: {
    accountId: Account.ID
    searchTerm: string
    scale?: number
}) {
    const { userId } = useUser()
    const normalisedSearchTerm = encodeURIComponent(searchTerm)
    return `${API_URL}/render/serp-image?keyword=${normalisedSearchTerm}&account_id=${accountId}&scale=${scale}&user_id=${userId.value}`
}
