<template>
    <div class="thankyou-container">
        <LoginContainer :width="384" title="Payment Card Saved">
            <template v-slot:info>
                <span>
                    Thanks for updating your payment card. Your most recent unpaid invoice has been
                    resolved and your card has been saved for future invoices. If you have any
                    questions, don't hesitate to
                    <Text
                        color="blue"
                        as="span"
                        class="thankyou-message-support-link"
                        @click="showNewMessage()"
                        >message support</Text
                    >.
                </span>
            </template>
            <template v-slot:content>
                <oButton size="login" @clicked="toAccountCentre">Go to Account Centre</oButton>
            </template>
        </LoginContainer>
        <div class="add-payment-method">
            <img src="@/assets/img/add-payment-method-small.png" alt="Add Payment Method" />
        </div>
    </div>
</template>

<script lang="ts">
import { useIntercom } from '@/lib/intercom/useIntercom'

import LoginContainer from '@/components/global/LoginContainer.vue'

import { oButton, Text, Spacer } from '@opteo/components-next'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

export default {
    name: 'Thankyou',
    components: { LoginContainer, oButton, Text, Spacer },
    setup() {
        const router = useRouter()
        const toAccountCentre = () => router.push({ name: Routes.AccountCentre })
        const { showNewMessage } = useIntercom()
        return { toAccountCentre, showNewMessage }
    },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.thankyou-container {
    @include flex;
    @include items-center;
    @include justify-center;
    height: 100vh;
}
.thankyou-container .lo-section {
    @include mh-0;
}
.thankyou-container .add-payment-method {
    width: 390px;
    @include ml-64;
}

.thankyou-message-support-link {
    cursor: pointer;
}

@media screen and (max-width: $mq-1024-max) {
    .thankyou-container {
        @include block;
        height: auto;
    }
    .thankyou-container .lo-section {
        width: 100%;
        max-width: 384px;
        margin-left: auto;
        margin-right: auto;
        @include mb-64;
    }
    .thankyou-container .add-payment-method {
        margin: 0 auto;
        margin-bottom: 10rem;
        max-width: 100%;
    }
}
</style>
