<template>
    <PageContent v-if="!improvementOpen">
        <!-- Skeleton Rows -->
        <div v-if="loading">
            <div class="improvement-section-header">
                <Skeleton :height="24" :width="200" />
            </div>
            <div v-for="skid in 20" :key="skid">
                <ImprovementRowCompleted :improvement="{}" :completed="true" />
            </div>
        </div>
        <!-- No completed improvements -->
        <div
            v-else-if="Object.keys(improvements).length === 0"
            class="improvements-empty-state-container"
        >
            <img src="@/assets/img/noCompletedImprovements.png" style="height: 218px" />
            <Spacer height="2rem" />
            <div class="center" style="max-width: 356px">
                <Text as="h5" size="f-5" weight="600" align="center">
                    No completed Improvements
                </Text>
                <Spacer height="0.875rem" />
                <Text as="p" size="f-8" align="center">
                    Your completed improvements will appear here. Switch to the <b>Active</b> tab to
                    complete your first improvement.
                </Text>
            </div>
        </div>
        <!-- Completed improvement rows -->
        <div v-else>
            <div v-for="(date, index) in Object.keys(improvements)" :key="date">
                <Spacer v-if="index > 0" />
                <div class="improvement-section-header">
                    <ColorTag color="green" :title="`Completed ${date}`" content="" />
                </div>
                <div v-for="improvement in improvements[date]" :key="improvement.improvement_id">
                    <ImprovementRowCompleted :improvement="improvement" :completed="true" />
                </div>
            </div>
            <div v-if="moreAvailable" class="load-more">
                <oButton @clicked="limit = limit + 100" :loading="moreLoading" color="white">
                    Show More
                </oButton>
            </div>
        </div>
    </PageContent>
    <router-view v-else />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ColorTag, oButton, Spacer, Text } from '@opteo/components-next'

import PageContent from '@/layouts/PageContent.vue'
import ImprovementRowCompleted from '@/components/improvement/ImprovementRowCompleted.vue'
import { useCompletedImprovements } from '@/composition/improvement/useCompletedImprovements'
import Skeleton from '@/components/util/Skeleton.vue'

export default defineComponent({
    components: { PageContent, ImprovementRowCompleted, Skeleton, ColorTag, oButton, Spacer, Text },
    name: 'Completed',
    setup() {
        return useCompletedImprovements()
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.improvement-rows {
    @include mb-48;
}
.improvement-section-header {
    @include flex;
    @include items-center;
    @include mb-32;
    height: 1.75rem;
}

.load-more {
    @include flex;
    @include items-center;
    @include justify-center;
    @include mt-24;
}

.improvements-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 30rem;
}

@media screen and (max-width: $mq-767-max) {
    .improvements-empty-state-container {
        margin-bottom: 6rem;
    }
}
</style>
