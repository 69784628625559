<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="campaign" :content="campaignName" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    The above campaign is set to display ads on Google Search Partner sites. Ads
                    listed on these sites have an above average CPA. Preventing your ads from being
                    shown on these sites should help you reduce overall CPA. Click
                    <Text as="span" color="green" weight="600">Disable Search Partner Sites</Text>
                    to prevent ads in this campaign from being shown on Google Search Partner sites.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/articles/900511-disable-search-partner-sites">
                Click here to learn more about disabling Search Partner sites in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics
                :items="[
                    {
                        key: 'google-cost',
                        value: googleCost,
                        title: 'Google Network Cost',
                    },
                    {
                        key: 'partner-cost',
                        value: partnerCost,
                        title: 'Search Partner Sites Cost',
                    },
                    {
                        key: 'google-cpa',
                        value: googleCpa,
                        title: 'Google Network CPA',
                    },
                    {
                        key: 'partner-cpa',
                        value: partnerCpa,
                        title: 'Search Partner Sites CPA',
                    },
                ]"
            >
                <template v-slot:google-cost>
                    Over the last <Ent>90</Ent> days, <Ent>{{ googleCost }}</Ent> was spent on the
                    <Ent>Google Network</Ent>.
                </template>
                <template v-slot:partner-cost>
                    Over the last <Ent>90</Ent> days, <Ent>{{ partnerCost }}</Ent> was spent on the
                    <Ent>Search Partner Sites</Ent>.
                </template>
                <template v-slot:google-cpa>
                    Over the last <Ent>90</Ent> days, Ads on the <Ent>Google Network</Ent> had a CPA
                    of <Ent>{{ googleCpa }}</Ent
                    >.
                </template>
                <template v-slot:partner-cpa>
                    Over the last <Ent>90</Ent> days, Ads on <Ent>Search Partner Sites</Ent> had an
                    average CPA of <Ent>{{ partnerCpa }}</Ent
                    >.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import { EntityPill, Spacer, Text } from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Ent from '@/components/util/Ent.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useSearchPartnerSettings } from '@/composition/improvement/types/useSearchPartnerSettings'

export default {
    name: 'SearchPartnerSettings',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        EntityPill,
        Spacer,
        Text,
        Ent,
    },
    setup() {
        return { ...useSearchPartnerSettings(), ...useMediaQuery() }
    },
}
</script>
