<template>
    <BackButton v-if="backMode" fixed @clicked="shouldEmit('back')" />
    <CloseButton v-else fixed @clicked="shouldEmit('close')" ref="closeButtonRef" />
    <div v-if="moreButton || deleteButton" class="additional-buttons-container">
        <MoreButton v-if="moreButton" @clicked="shouldEmit('more')" />
        <DeleteButton v-if="deleteButton" @clicked="shouldEmit('delete')" />
    </div>
    <div ref="fullScreenOverlayRef" class="fullscreen-overlay-content">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { CloseButton, BackButton, MoreButton, oButton, DeleteButton } from '@opteo/components-next'
import { useLayouts } from '@/composition/layouts/useLayouts'

export default defineComponent({
    components: { CloseButton, BackButton, MoreButton, oButton, DeleteButton },
    emits: ['close', 'back', 'more', 'delete'],
    props: {
        backMode: {
            type: Boolean,
            default: false,
            required: false,
        },
        moreButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        deleteButton: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    setup: (_, { emit }) => {
        useLayouts('full-screen')

        const fullScreenOverlayRef = ref()
        const closeButtonRef = ref()

        function shouldEmit(mode: 'back' | 'close' | 'more' | 'delete') {
            function checkOpenUIElements() {
                const selectors = ['.modal', '.popout', '.select-dropdown']
                const selectorString = selectors.join(', ')

                const hasOpenViews =
                    fullScreenOverlayRef.value.querySelectorAll('.close-button').length > 0

                const hasOpenElements = document.querySelectorAll(selectorString).length > 0

                return hasOpenViews || hasOpenElements
            }

            if (checkOpenUIElements()) {
                return
            }

            emit(mode)

            // Blur the close button to remove the focus ring after it's been clicked.
            // This avoids the button staying focused when it only "goes back" instead of closing,
            // such as when going back to scorecard or SBE lists from singleScorecard or singleExperiement
            closeButtonRef.value?.$el.blur()
        }

        return {
            fullScreenOverlayRef,
            closeButtonRef,
            shouldEmit,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.additional-buttons-container {
    @include z-9; // Same z-index as the CloseButton. Without this, block elements can invisibly overlap this button and it cannot be clicked.
    @include fixed;
    right: 1.5rem;
    // padding, buttonHeight, gap
    top: calc(1.5rem + 3rem + 0.75rem);
    @include flex;
    flex-direction: column;
    gap: 0.75rem;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
    .additional-buttons-container {
        right: 0.75rem;
        // padding, buttonHeight, gap
        top: calc(0.75rem + 3rem + 0.75rem);
        @include flex;
        flex-direction: column;
        gap: 0.75rem;
    }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
    .additional-buttons-container {
        right: 0.875rem;
        // padding, buttonHeight, gap
        top: calc(0.875rem + 3rem + 0.75rem);
        @include flex;
        flex-direction: column;
        gap: 0.75rem;
    }
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
    .additional-buttons-container {
        right: 1rem;
        // padding, buttonHeight, gap
        top: calc(1rem + 3rem + 0.75rem);
        @include flex;
        flex-direction: column;
        gap: 0.75rem;
    }
}
</style>
