<template>
    <div class="w-100">
        <!-- Headline -->
        <Text v-if="title" as="h3" size="f-4" weight="600">
            {{ title }}
        </Text>
        <Spacer v-if="title" height="2rem" />
        <!-- Loop -->
        <div
            v-for="(item, index) in items"
            :key="'statistic-' + index"
            class="improvement-statistic"
        >
            <div v-if="item.deltaMode" class="stat-container">
                <div class="arrow-icon-container">
                    <svg
                        class="icon"
                        width="18"
                        height="14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m11 1 5.6464 5.64645c.1953.19526.1953.51184 0 .7071L11 13m5-6H1"
                            stroke="#010105"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </div>
                <div class="stat-item">
                    <Text as="h3" size="f-3" weight="600" align="center">
                        {{ item.beforeValue }}
                    </Text>
                    <Spacer height="0.375rem" />
                    <Text as="p" size="f-8" color="gray" align="center">
                        {{ item.beforeTitle }}
                    </Text>
                </div>
                <div class="stat-item">
                    <Text as="h3" size="f-3" weight="600" align="center">
                        {{ item.afterValue }}
                    </Text>
                    <Spacer height="0.375rem" />
                    <Text as="p" size="f-8" color="gray" align="center">
                        {{ item.afterTitle }}
                    </Text>
                </div>
            </div>
            <div v-else class="stat-item-100">
                <Text as="h3" size="f-3" weight="600" align="center">
                    {{ item.value }}
                </Text>
                <Spacer height="0.375rem" />
                <Text as="p" size="f-8" color="gray" align="center">
                    {{ item.title }}
                </Text>
            </div>
            <Text as="p" size="f-8" style="padding: 1.5rem">
                <slot :name="item.key" />
            </Text>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { Text, Spacer } from '@opteo/components-next'

interface DeltaStat {
    deltaMode?: boolean
    beforeValue?: string | number
    beforeTitle?: string
    afterValue?: string | number
    afterTitle?: string
}

interface ValueStat {
    value?: string | number
    title?: string
}

interface ImprovementStatisticsItem extends DeltaStat, ValueStat {
    key: string
}

export default defineComponent({
    name: 'ImprovementStatistics',
    props: {
        title: {
            type: String as PropType<string>,
            required: false,
            default: 'Improvement Statistics',
        },
        items: {
            type: Array as PropType<ImprovementStatisticsItem[]>,
            default: () => [],
            required: true,
        },
    },
    components: { Text, Spacer },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.improvement-statistic {
    @include container;
    @include br-20;
    @include mb-32;
}
.improvement-statistic:last-child {
    margin-bottom: 0;
}

.improvement-statistic .f-3 {
    @include f-3;
}

.improvement-statistic .stat-container {
    @include flex;
    @include items-center;
    @include relative;
}

.improvement-statistic .stat-item {
    width: 50%;
    padding: 4rem 1.5rem;
    @include tc;
    border-bottom: 1px solid;
    @include opteo-border;
}
.improvement-statistic .stat-item:last-child {
    border-left: 1px solid;
    @include opteo-border;
}

.improvement-statistic .stat-item-100 {
    width: 100%;
    padding: 4rem 1.5rem;
    @include tc;
    border-bottom: 1px solid;
    @include opteo-border;
}

.arrow-icon-container {
    @include absolute;
    @include top-0;
    @include container;
    @include flex;
    @include items-center;
    @include justify-center;
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
    left: calc(50% - 1.5rem);
    top: calc(50% - 1.5rem);
}

@media screen and (min-width: $mq-0-min) and (max-width: $mq-767-max) {
    .arrow-icon-container .icon {
        transform: rotate(90deg);
    }
    .improvement-statistic .stat-container {
        flex-direction: column;
    }
    .improvement-statistic .stat-item {
        width: 100%;
    }
    .improvement-statistic .stat-item:last-child {
        border-left: unset;
    }
}
</style>
