<template>
    <div class="improvement-batch-icon" tabindex="0" ref="selectIconContainer">
        <!-- Unselected & batch-NOT-pushable Icon -->
        <div
            v-show="iconState === 'unselected_not_batchable'"
            class="batch-click-area improvement-batch-icon-contents flex items-center"
            style="width: 24px"
            @click="select"
        >
            <!-- z-index 1 here so the tooltip displays over the clickable button -->
            <Tooltip
                :content="
                    aboveMobile
                        ? 'This improvement will be skipped when pushing via the queue.'
                        : ''
                "
                style="z-index: 1"
                :max-width="206"
            >
                <svg
                    style="display: block"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12 2C6.477153 2 2 6.4771525 2 12s4.477153 10 10 10 10-4.4771525 10-10S17.522847 2 12 2zm0-2c6.627417 0 12 5.372583 12 12s-5.372583 12-12 12S0 18.627417 0 12 5.372583 0 12 0z"
                        fill-rule="nonzero"
                        fill="#e0e0e5"
                    />
                </svg>
            </Tooltip>
        </div>

        <!-- Unselected & batch-pushable Icon -->
        <div
            v-show="iconState === 'unselected_batchable'"
            @click="select"
            class="batch-click-area improvement-batch-icon-contents"
        >
            <svg
                style="display: block"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12 2C6.477153 2 2 6.4771525 2 12s4.477153 10 10 10 10-4.4771525 10-10S17.522847 2 12 2zm1 9V7h-2v4H7v2h4v4h2v-4h4v-2h-4zM12 0c6.627417 0 12 5.372583 12 12s-5.372583 12-12 12S0 18.627417 0 12 5.372583 0 12 0z"
                    fill-rule="nonzero"
                    fill="#e0e0e5"
                />
            </svg>
        </div>

        <!-- Selected Icon -->
        <div
            v-show="iconState === 'selected'"
            @click="select"
            class="batch-click-area improvement-batch-icon-contents"
            style="width: 24px; height: 24px"
        >
            <CheckIcon animated color="blue" />
        </div>

        <!-- In-Progress (pushing) Icon -->
        <div v-if="iconState === 'working'" class="spinner-cnt">
            <Spinner />
        </div>

        <!-- Success Icon -->
        <div v-if="iconState === 'pushed'" style="width: 24px; height: 24px">
            <Tooltip
                :content="aboveMobile ? 'Improvement pushed successfully.' : ''"
                :offset="[0, 16]"
            >
                <CheckIcon animated />
            </Tooltip>
        </div>

        <!-- Skipped Icon -->
        <div v-if="iconState === 'skipped'" style="width: 24px; height: 24px">
            <Tooltip
                :content="'This improvement was skipped because it requires your input before being completed.'"
                :max-width="188"
                :offset="[0, 16]"
            >
                <SkipIcon animated />
            </Tooltip>
        </div>

        <!-- Failed Icon -->
        <div v-if="iconState === 'failed'" style="width: 24px; height: 24px">
            <Tooltip :content="errorMessage" :offset="[0, 16]">
                <WarnIcon animated />
            </Tooltip>
        </div>

        <!-- Dismissed Icon -->
        <div v-if="iconState === 'dismissed'" style="width: 24px; height: 24px">
            <DismissIcon animated />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import {
    Spinner,
    Tooltip,
    CheckIcon,
    WarnIcon,
    SkipIcon,
    DismissIcon,
    useShortKey,
} from '@opteo/components-next'

import useMediaQuery from '@/composition/global/useMediaQuery'
import { useBatchBox } from '@/composition/improvement/useBatchBox'

import type { QueuedImprovement } from '@/composition/improvement/useImprovementQueue'
import type { EnhancedImprovement } from '@/composition/improvement/types'

type Props = {
    improvement: EnhancedImprovement
    queuedImprovement: QueuedImprovement | undefined
    batchQueueRunning: boolean
}

const props = defineProps<Props>()

const selectIconContainer = ref()
const { toggleSelection } = useBatchBox()
const { aboveMobile } = useMediaQuery()

const iconState = computed(() => {
    if (!props.queuedImprovement) {
        return props.improvement.requires_adjust
            ? 'unselected_not_batchable'
            : 'unselected_batchable'
    }

    if (props.queuedImprovement.state === 'idle' || props.queuedImprovement.state === 'waiting') {
        return 'selected'
    }

    return props.queuedImprovement.state
})

const errorMessage = computed(() => props.queuedImprovement?.error ?? 'Unknown Error')

function select(event: { shiftKey: boolean }) {
    if (props.batchQueueRunning) {
        // Don't allow toggling while a queue is already running
        return
    }

    if (props.queuedImprovement && props.queuedImprovement.state !== 'idle') {
        // Don't allow toggling for non-idle rows
        return
    }

    const shouldSelect = iconState.value !== 'selected'

    toggleSelection(props.improvement.improvement_id, shouldSelect, event.shiftKey)
}

// Pressing "enter" while having the icon selected should toggle the icon
useShortKey({
    keys: ['enter'],
    onPressCallBack: async (e: KeyboardEvent) => {
        if (
            iconState.value === 'unselected_not_batchable' ||
            iconState.value === 'unselected_batchable' ||
            iconState.value === 'selected'
        ) {
            select(e)
        }
    },
    eventTarget: selectIconContainer,
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.improvement-batch-icon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.improvement-batch-icon:focus {
    @include br-999;
    outline: none;
    box-shadow: $opteo-shadow-focus-ring;
}
.improvement-batch-icon:active {
    outline: none;
    box-shadow: none;
}

.improvement-batch-icon-contents {
    @include br-999;
}

// make clickable area larger without
// affecting layout
.batch-click-area {
    position: relative;
}
.batch-click-area::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 3rem;
    width: 3rem;
    z-index: 0;
    left: 0;
    bottom: 0;
    margin: -0.75rem;
}

.spinner-cnt {
    height: 1.5rem;
    width: 1.5rem;
    @include flex-center;
}

.unselectable {
    cursor: default;
}
</style>
