import { computed, ref, watch } from 'vue'
import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
    EntityPill,
} from '@/composition/improvement/useImprovement'
import {
    snippetHeaders,
    languages,
    getDomainLanguageCode,
    setDomainLanguageCode,
    SnippetHeader,
} from '@/composition/utils/useLanguage'
import { AddStructuredSnippetsExtension, Improvement } from '@opteo/types'
import { useAccount } from '@/composition/account/useAccount'

type FormElement = HTMLDivElement & { submit: () => void; valid: boolean }
type DropDownItem = { value: string; label: string }

interface HighestClickedAd {
    headlineOne: string
    headlineTwo: string
    displayUrl: string
    descriptionOne: string
    structuredSnippetsHeader: string
    structuredSnippets: string[]
}

interface AdTitleTag {
    title: string
    classes: string
}

interface SnippetExtension {
    snippet: string
    required: boolean
}

const EXPECTED_SNIPPET_COUNT = 3
const MAXIMUM_SNIPPET_LENGTH = 25

export function useAddStructuredSnippetsExtension() {
    const { accountId } = useAccount()
    const { improvement, lastUpdated, title } =
        useImprovement<AddStructuredSnippetsExtension.Body>()

    const {
        body: {
            grey_adgroups_campaign_name_uniq: campaignNames,
            highest_click_ad_data: {
                headline1: headlineOne,
                headline2: headlineTwo,
                display_url: displayUrl,
                description: descriptionOne,
            },
            structured_snippet_data: structuredSnippets,
        },
    } = checkImprovement(improvement)

    const defaultSnippetsHeader = structuredSnippets.header || undefined // set to undefined if it's an empty string

    const snippetExtensions = ref<SnippetExtension[]>([])
    const selectedLanguage = ref(getDomainLanguageCode(accountId.value))
    const selectedHeader = ref<string | undefined>(defaultSnippetsHeader)
    const headersDropDownItems = computed<DropDownItem[]>(() =>
        Object.entries(snippetHeaders[selectedLanguage.value]).map(([value, label]) => {
            return { value, label }
        })
    )
    const languagesDropDownItems = Object.entries(languages).map(([value, label]): DropDownItem => {
        return { value, label }
    })

    watch(selectedLanguage, () =>
        setDomainLanguageCode({ accountId: accountId.value, languageCode: selectedLanguage.value })
    )

    const headerRef = ref() // oInput select of the form
    function resetSnippetExtensions() {
        snippetExtensions.value = Object.entries(structuredSnippets)
            .filter(([key]) => key !== 'header')
            .map(([key, snippet]) => {
                const required = +key.split(/value/g)[1] <= EXPECTED_SNIPPET_COUNT
                return { snippet, required }
            })

        selectedLanguage.value = getDomainLanguageCode(accountId.value)
        selectedHeader.value = defaultSnippetsHeader

        // At the moment, focusing a dropdown oInput doesn't work quite right.
        // It scrolls to the element but doesn't allow interacting with it via the keyboard.
        // To be improved in components-next.
        headerRef.value?.inputRef.focus()
    }

    resetSnippetExtensions()

    const highestClickedAd = computed<HighestClickedAd>(() => {
        const ad = { headlineOne, headlineTwo, displayUrl, descriptionOne }

        if (!snippetExtensions.value || !snippetExtensions.value.length) {
            return ad as HighestClickedAd
        }

        const structuredSnippetsHeader =
            snippetHeaders[selectedLanguage.value][selectedHeader.value as SnippetHeader]
        const structuredSnippets = snippetExtensions.value
            .map(snippetExtension => snippetExtension.snippet)
            .filter(snippet => snippet.length)

        if (!structuredSnippetsHeader && structuredSnippets.length == 0) {
            return {
                ...ad,
                structuredSnippetsHeader: 'Header',
                structuredSnippets: ['Snippet One', 'Snippet Two', 'Snippet Three'],
            }
        }

        return { ...ad, structuredSnippetsHeader, structuredSnippets }
    })

    const adTitleTag: AdTitleTag = { title: 'Highest Clicked Ad', classes: 'opteo-blue' }
    const snippetForm = ref<FormElement>()

    const adjustSteps = ref(['Input Structured Snippets'])
    const pushActionText = ref('Add Structured Snippets')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding structured snippets..',
        'Confirming changes..',
        'Structured snippets added successfully.',
    ])

    const entityPillList: EntityPill[] = campaignNames.map(campaignName => {
        return {
            type: Improvement.LocationEntity.Campaign,
            content: campaignName,
        }
    })

    const snippetExamples = [
        {
            example: 'Accountancy Service Example',
            type: 'Service Catalog',
            items: ['Accountancy', 'Taxation Advice', 'Financial Advice', 'Bookkeeping', 'Audits'],
        },
        {
            example: 'Sunglasses Store Example',
            type: 'Brands',
            items: ['Burberry', 'Maui Jim', 'Oakley', 'Prada', 'Ray-Ban'],
        },
        {
            example: 'Local Business in Boston',
            type: 'Neighborhoods',
            items: [
                'Back Bay',
                'Beacon Hill',
                'Charlestown',
                'North End',
                'South Boston',
                'West End',
            ],
        },
    ]

    const onPush: OnPushHandler<AddStructuredSnippetsExtension.StructuredSnippets> = () => {
        snippetForm.value?.submit()

        if (!selectedHeader.value) {
            return { valid: false }
        }

        const values = snippetExtensions.value
            .map(snippetExtension => snippetExtension.snippet)
            .filter(snippet => snippet.length)

        const invalidValues = values.filter(snippet => snippet.length > MAXIMUM_SNIPPET_LENGTH)

        if (values.length < EXPECTED_SNIPPET_COUNT || invalidValues.length > 0) {
            return { valid: false }
        }

        const pushedData: AddStructuredSnippetsExtension.StructuredSnippets = {
            header: selectedHeader.value,
        }

        values.forEach((snippet, index) => {
            pushedData[
                `value${index + 1}` as keyof AddStructuredSnippetsExtension.StructuredSnippets
            ] = snippet
        })

        return { valid: true, pushedData }
    }
    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        entityPillList,
        highestClickedAd,
        adTitleTag,
        snippetForm,
        selectedLanguage,
        selectedHeader,
        headersDropDownItems,
        languagesDropDownItems,
        snippetExtensions,
        headerRef,
        resetSnippetExtensions,
        snippetExamples,
        MAXIMUM_SNIPPET_LENGTH,
        pushActionText,
        adjustSteps,
    }
}
