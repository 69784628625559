<template>
    <div class="product-group-parent">
        <div
            class="product-group-row"
            @mouseenter="handleHoverRow($event, productGroup)"
            :data-row-depth="depth"
        >
            <div class="product-group-info">
                <!-- indentation -->
                <div v-if="depth > 0" class="indent-cnt flex items-center flex-start">
                    <div
                        v-for="(n, index) in depth"
                        :key="index"
                        :style="{ width: 1.5 + 'rem' }"
                        :class="[
                            n === depth ? 'b--opteo-light-gray' : 'b--opteo-light-gray',
                            'indent-marker br',
                        ]"
                    ></div>
                </div>
                <div class="product-group-name">{{ productGroup.name }}</div>
            </div>
            <div class="product-group-attribute">{{ productGroup.product_group_attribute }}</div>
        </div>

        <!-- recursive nesting of component -->
        <ProductGroupRow
            v-for="group in productGroup.product_groups"
            :key="group.resource_name"
            :product-group="group"
            :depth="depth + 1"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs, inject } from 'vue'

interface ProductGroup {
    name: string
    product_group_attribute: string
    resource_name: string | null
    products_submitted: number
    product_groups?: ProductGroup[]
    generated_product_group?: boolean
    cost: number
    clicks: number
    impressions: number
}

export default defineComponent({
    name: 'ProductGroupRow',
    props: {
        productGroup: {
            type: Object as PropType<ProductGroup>,
            required: true,
            default: false,
        },
        depth: {
            type: Number,
            required: false,
            default: () => 0,
        },
    },
    setup(props, { emit }) {
        const { productGroup } = toRefs(props)

        const handleHoverRow = inject('handleHoverRow')

        return { handleHoverRow, productGroup }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.product-group-row {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 12rem;
}
.product-group-info {
    display: grid;
    grid-template-columns: auto 1fr;
}
.product-group-name {
    @include pv-20;
    @include ph-24;
    @include truncate;
}
.product-group-attribute {
    width: 12rem;
    @include pv-20;
    @include ph-24;
}
.indent-marker {
    height: 100%;
}
</style>
