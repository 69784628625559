const MISSING_ENV_VAR_ERROR = 'Missing environment variable'

export function parseEnvString(envVar: string | undefined, _default?: string): string {
    if (envVar === undefined && _default === undefined) {
        throw new Error(MISSING_ENV_VAR_ERROR)
    }

    return envVar || _default || ''
}

export function parseEnvBool(envVar: string | undefined, _default?: boolean): boolean {
    if (envVar === undefined && _default === undefined) {
        throw new Error(MISSING_ENV_VAR_ERROR)
    }

    if (envVar && envVar !== '1' && envVar !== '0') {
        throw new Error('Boolean env var must be 1 or 0')
    }

    return !!+(envVar ?? _default ?? 0) // parse to number and then to boolean
}

export function parseEnvNumber(envVar: string | undefined, _default?: number): number {
    if (envVar === undefined && _default === undefined) {
        throw new Error(MISSING_ENV_VAR_ERROR)
    }

    return Number(envVar ?? _default)
}
