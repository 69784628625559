import { useNumber, useRoas } from '@opteo/components-next'
import { Ngram, Gads, Targets } from '@opteo/types'

import { useDomainMoney } from '@/composition/domain/useDomainMoney'

export const getCommonVariables = (body: Ngram.NgramImpType.Body) => {
    const isShopping = body.advertising_channel_type !== Gads.enums.AdvertisingChannelType.SEARCH

    const ngramText = body.ngram.text
    const performanceMode = body.performance_mode ?? Targets.PerformanceMode.CPA
    const campaignGroupName = body.campaign_group.name
    const campaignGroupTarget =
        performanceMode === Targets.PerformanceMode.CPA
            ? body.campaign_group.cpa_target_or_avg ?? 0
            : body.campaign_group.roas_target_or_avg ?? 0
    const hasTarget = body.campaign_group.is_target_set

    const scatterPlotItems = body.ngram_graph_points.map(p => {
        if (performanceMode === Targets.PerformanceMode.ROAS) {
            const roas = p.metrics.all_conversions_value / p.metrics.cost
            const roasDiff = (roas - campaignGroupTarget) / campaignGroupTarget

            return {
                x: roasDiff,
                y: p.metrics.cost,
                label: p.name,
                highlighted: p.focus,
            }
        } else {
            // CPA mode
            const cpa = p.metrics.cost / p.metrics.all_conversions
            const cpaDiff = (cpa - campaignGroupTarget) / campaignGroupTarget

            return {
                x: cpaDiff,
                y: p.metrics.cost,
                label: p.name,
                highlighted: p.focus,
            }
        }
    })

    const ngramConversions = body.ngram.all_conversions
    const ngramConversionsValue = body.ngram.all_conversions_value
    const ngramCost = body.ngram.cost
    const ngramCpa = ngramCost / ngramConversions
    const ngramRoas = ngramConversionsValue / ngramCost

    const dataPeriod = body.window + ' days'
    const window = body.window

    const stats = [
        {
            key: 'cost',
            value: useDomainMoney({ value: ngramCost }).value.displayValue.value,
            title: 'Cost',
        },
    ]

    if (performanceMode === Targets.PerformanceMode.ROAS) {
        stats.push(
            ...[
                {
                    key: 'conversionsValue',
                    value: useDomainMoney({ value: ngramConversionsValue }).value.displayValue
                        .value,
                    title: 'Conversion Value',
                },
                {
                    key: 'wordRoas',
                    value: useRoas({ value: ngramRoas }).displayValue.value,
                    title: 'N-Gram ROAS',
                },
                {
                    key: 'targetRoas',
                    value: useRoas({ value: campaignGroupTarget }).displayValue.value,
                    title: hasTarget ? 'Target ROAS' : 'Average ROAS',
                },
            ]
        )
    } else {
        stats.push(
            ...[
                {
                    key: 'conversions',
                    value: useNumber({ value: ngramConversions }).displayValue.value,
                    title: 'Conversions',
                },
                {
                    key: 'wordCpa',
                    value: useDomainMoney({ value: ngramCpa }).value.displayValue.value,
                    title: 'N-Gram CPA',
                },
                {
                    key: 'targetCpa',
                    value: useDomainMoney({ value: campaignGroupTarget }).value.displayValue.value,
                    title: hasTarget ? 'Target CPA' : 'Average CPA',
                },
            ]
        )
    }

    const contributingSearchTerms = body.search_terms.map(s => s.text)

    return {
        performanceMode,
        isShopping,
        ngramText,
        campaignGroupName,
        campaignGroupTarget,
        hasTarget,
        scatterPlotItems,
        ngramConversions,
        ngramConversionsValue,
        ngramCost,
        ngramCpa,
        ngramRoas,
        dataPeriod,
        window,
        contributingSearchTerms,
        stats,
    }
}
