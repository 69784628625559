<template>
    <svg
        :class="['line-chart', { animated }]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 452"
        fill="none"
    >
        <rect width="640" height="452" fill="white" />
        <rect
            x="33.5"
            y="93.5"
            width="375"
            height="236"
            rx="7.5"
            stroke="black"
            stroke-opacity="0.04"
        />
        <mask
            id="mask0_406_2782"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="34"
            y="93"
            width="374"
            height="236"
        >
            <path
                d="M34 101C34 96.5817 37.5817 93 42 93H400C404.418 93 408 96.5817 408 101V321C408 325.418 404.418 329 400 329H42C37.5817 329 34 325.418 34 321V101Z"
                fill="white"
            />
            <path
                d="M34.5 101C34.5 96.8579 37.8579 93.5 42 93.5H400C404.142 93.5 407.5 96.8579 407.5 101V321C407.5 325.142 404.142 328.5 400 328.5H42C37.8579 328.5 34.5 325.142 34.5 321V101Z"
                stroke="black"
                stroke-opacity="0.08"
            />
        </mask>
        <g mask="url(#mask0_406_2782)">
            <path
                class="fill"
                d="M27 176.643V330H494V241.781C484.618 241.781 487.051 248.253 477.321 248.253C467.592 248.253 469.33 279.119 460.643 279.119C451.956 279.119 453.346 219.095 443.964 219.095C434.583 219.095 435.625 229.34 427.286 229.34C418.946 229.34 420.336 214.997 410.607 214.997C400.879 214.997 402.962 224.168 393.929 224.168C384.894 224.168 386.284 203.993 377.25 203.993C368.215 203.993 369.953 219.742 360.571 219.742C351.19 219.742 353.622 208.939 343.893 208.939C334.164 208.939 336.596 223.864 327.214 223.864C317.833 223.864 320.265 208.464 310.536 208.464C300.807 208.464 302.544 239.256 293.857 239.256C285.17 239.256 286.213 177.25 277.179 177.25C268.144 177.25 269.534 233.395 260.5 233.395C251.466 233.395 252.508 215.655 243.821 215.655C235.135 215.655 236.872 228.078 227.143 228.078C217.414 228.078 219.499 237.464 210.464 237.464C201.43 237.464 202.473 210.142 193.786 210.142C185.099 210.142 185.794 193.809 177.107 193.809C168.42 193.809 169.463 171.367 160.429 171.367C151.395 171.367 152.785 206.111 143.75 206.111C134.716 206.111 136.105 229.498 127.071 229.498C118.037 229.498 118.732 189.037 110.393 189.037C102.054 189.037 103.444 207.108 93.7143 207.108C83.985 207.108 86.4173 182.432 77.0357 182.432C67.6541 182.432 69.0442 191.44 60.3571 191.44C51.671 191.44 52.3657 169 43.6786 169C34.9924 169 35.3394 176.643 27 176.643Z"
                fill="url(#paint0_linear_406_2782)"
            />
            <path
                class="graph-line"
                d="M27 176.643C35.3394 176.643 34.9925 169 43.6786 169C52.3657 169 51.671 191.44 60.3572 191.44C69.0443 191.44 67.6542 182.432 77.0357 182.432C86.4173 182.432 83.9851 207.108 93.7143 207.108C103.444 207.108 102.054 189.037 110.393 189.037C118.732 189.037 118.037 229.498 127.071 229.498C136.105 229.498 134.716 206.111 143.75 206.111C152.785 206.111 151.395 171.367 160.429 171.367C169.463 171.367 168.42 193.809 177.107 193.809C185.794 193.809 185.099 210.142 193.786 210.142C202.473 210.142 201.43 237.464 210.464 237.464C219.499 237.464 217.414 228.078 227.143 228.078C236.872 228.078 235.135 215.655 243.821 215.655C252.508 215.655 251.466 233.395 260.5 233.395C269.534 233.395 268.144 177.25 277.179 177.25C286.213 177.25 285.17 239.256 293.857 239.256C302.544 239.256 300.807 208.464 310.536 208.464C320.265 208.464 317.833 223.864 327.214 223.864C336.596 223.864 334.164 208.939 343.893 208.939C353.622 208.939 351.19 219.742 360.571 219.742C369.953 219.742 368.215 203.993 377.25 203.993C386.284 203.993 384.894 224.168 393.929 224.168C402.962 224.168 400.879 214.997 410.607 214.997"
                stroke="#006DFF"
                stroke-width="2"
            />
            <rect x="48" y="110" width="64" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="230" y="305" width="24" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="185" y="305" width="24" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="320" y="305" width="24" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="275" y="305" width="24" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="365" y="305" width="24" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="95" y="305" width="24" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="140" y="305" width="24" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="305" width="24" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="167" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="195" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="223" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="251" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="279" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
        </g>
        <circle cx="93" cy="134" r="8" fill="#E6F7F0" />
        <path
            d="M92.9999 136.908V131.09M92.9999 131.09L90.8181 133.272M92.9999 131.09L95.1818 133.272"
            stroke="#00A861"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect x="523" y="388" width="82" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="523" y="400" width="86" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="523" y="412" width="72" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="388" width="82" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="400" width="86" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="412" width="72" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <circle cx="47" cy="404" r="14" fill="#EBEBEB" />
        <circle cx="493" cy="404" r="14" fill="#EBEBEB" />
        <rect x="33" y="34" width="146" height="24" rx="12" fill="black" fill-opacity="0.08" />
        <rect x="48" y="127" width="30" height="14" rx="7" fill="black" fill-opacity="0.08" />
        <rect x="107" y="127" width="30" height="14" rx="7" fill="#00A861" />
        <rect x="356" y="109" width="36" height="36" rx="18" fill="white" />
        <rect
            x="355.5"
            y="108.5"
            width="37"
            height="37"
            rx="18.5"
            stroke="black"
            stroke-opacity="0.04"
        />
        <path
            d="M371.536 121.629C371.685 121.238 371.89 120.877 372.195 120.585C373.413 119.398 375.426 119.697 376.252 121.188C376.873 122.319 377.532 123.425 378.172 124.543C379.241 126.401 380.322 128.259 381.378 130.123C382.267 131.683 381.304 133.652 379.539 133.919C378.458 134.081 377.445 133.584 376.886 132.615C375.948 130.98 375.003 129.346 374.065 127.718C374.046 127.681 374.021 127.65 373.997 127.619C373.897 127.538 373.854 127.42 373.792 127.314C373.375 126.581 372.946 125.854 372.53 125.127C372.263 124.655 371.983 124.189 371.716 123.717C371.474 123.294 371.362 122.834 371.374 122.35C371.393 122.101 371.424 121.853 371.536 121.629Z"
            fill="#3C8BD9"
        />
        <path
            d="M371.536 121.629C371.48 121.853 371.43 122.076 371.418 122.312C371.399 122.834 371.53 123.319 371.791 123.773C372.474 124.947 373.158 126.128 373.835 127.308C373.897 127.414 373.947 127.519 374.009 127.619C373.636 128.265 373.263 128.905 372.884 129.551C372.362 130.452 371.84 131.359 371.312 132.26C371.287 132.26 371.281 132.248 371.275 132.229C371.269 132.18 371.287 132.136 371.3 132.086C371.555 131.154 371.343 130.328 370.703 129.62C370.312 129.191 369.815 128.948 369.243 128.868C368.498 128.762 367.839 128.955 367.249 129.421C367.143 129.501 367.075 129.62 366.95 129.682C366.926 129.682 366.913 129.669 366.907 129.651C367.205 129.135 367.497 128.619 367.795 128.103C369.026 125.966 370.256 123.829 371.493 121.697C371.505 121.672 371.524 121.654 371.536 121.629Z"
            fill="#FABC04"
        />
        <path
            d="M366.932 129.669C367.05 129.564 367.162 129.452 367.286 129.353C368.796 128.16 371.064 129.023 371.393 130.912C371.474 131.366 371.43 131.801 371.294 132.236C371.287 132.273 371.281 132.304 371.269 132.341C371.213 132.441 371.163 132.546 371.101 132.646C370.548 133.559 369.734 134.013 368.665 133.944C367.441 133.857 366.478 132.938 366.31 131.72C366.23 131.13 366.348 130.577 366.652 130.067C366.714 129.955 366.789 129.856 366.857 129.744C366.888 129.719 366.876 129.669 366.932 129.669Z"
            fill="#34A852"
        />
        <path
            d="M366.932 129.669C366.907 129.694 366.907 129.738 366.863 129.744C366.857 129.7 366.882 129.676 366.907 129.645L366.932 129.669Z"
            fill="#FABC04"
        />
        <path
            d="M371.269 132.341C371.244 132.297 371.269 132.266 371.294 132.235C371.3 132.242 371.312 132.254 371.319 132.26L371.269 132.341Z"
            fill="#E1C025"
        />
        <rect
            class="line"
            x="431"
            y="93"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="111"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="129"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="147"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="165"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="183"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="201"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="219"
            width="153"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <defs>
            <linearGradient
                id="paint0_linear_406_2782"
                x1="260.5"
                y1="169"
                x2="260.5"
                y2="330"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0F6FF" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'

export default {
    props: {
        animated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        onMounted(() => {
            if (!props.animated) {
                return
            }
            anime.set('.line-chart .line', {
                opacity: () => 0,
            })
            const timeline = anime.timeline()
            timeline
                .add({
                    targets: '.graph-line',
                    opacity: [0, 1],
                    strokeDashoffset: [anime.setDashoffset, 0],
                    delay: 600,
                    duration: 1400,
                    easing: 'linear',
                })
                .add(
                    {
                        targets: '.fill',
                        opacity: [0, 1],
                        duration: 400,
                        easing: 'easeInCubic',
                    },
                    '-=1800'
                )
                .add(
                    {
                        targets: '.line-chart .line',
                        opacity: [0, 1],
                        delay: anime.stagger(100),
                        duration: 500,
                        easing: 'easeOutCubic',
                    },
                    '-=1000'
                )
        })
    },
}
</script>

<style lang="scss" scoped>
svg {
    width: 100%;
    transform: scale(1);
    transform-origin: center;
}
.bars {
    overflow: hidden;
}
svg.animated {
    .bar {
        opacity: 0;
    }
}
.line {
    opacity: 1;
    will-change: opacity;
}
</style>
