<template>
    <transition name="mobile-improvement-footer-fade" appear>
        <div id="mobile-improvement-footer">
            <div :class="'mobile-footer-grid'">
                <!-- completedText or revertsInText -->
                <oButton
                    v-if="completed"
                    :fullWidth="true"
                    color="white"
                    key="completedButton"
                    disabled
                >
                    {{ completedText }}
                </oButton>
                <!-- Adjust, Back and Dismiss Buttons -->
                <div
                    v-if="dismissButton || backButton || (adjustButton && !adjustDisabled)"
                    :class="[
                        adjustButton && !adjustDisabled ? 'grid' : '',
                        'adjust-back-dismiss-buttons',
                    ]"
                >
                    <!-- Adjust Button -->
                    <oButton
                        v-if="adjustButton && !adjustDisabled"
                        :fullWidth="true"
                        color="white"
                        key="adjustButton"
                        @clicked="adjustClicked()"
                    >
                        Adjust
                    </oButton>
                    <!-- Back Button -->
                    <oButton
                        v-if="backButton"
                        :fullWidth="true"
                        color="white"
                        key="backButton"
                        @clicked="backClicked()"
                    >
                        Back
                    </oButton>
                    <!-- Dismiss Button -->
                    <Popout
                        v-if="dismissButton"
                        v-model="dismissPopoutOpen"
                        :trapFocus="true"
                        :externalToggleButton="true"
                        placement="top"
                        class="justify-center w-100"
                        style="z-index: 2"
                    >
                        <oButton
                            :fullWidth="true"
                            color="white"
                            key="dismissButton"
                            @clicked="dismissPopoutOpen = !dismissPopoutOpen"
                        >
                            Dismiss
                        </oButton>
                        <template v-slot:content>
                            <div @click="dismissPopoutOpen = false">
                                <slot name="dismissPopout" />
                            </div>
                        </template>
                    </Popout>
                </div>
                <!-- Next Step (One) -->
                <oButton
                    v-if="stepOneButton"
                    :fullWidth="true"
                    color="blue"
                    key="stepOneButton"
                    @clicked="stepOneClicked()"
                >
                    {{ stepOneButtonText }}
                </oButton>
                <!-- Next Step (Two) -->
                <oButton
                    v-if="stepTwoButton"
                    :fullWidth="true"
                    color="blue"
                    key="stepTwoButton"
                    @clicked="stepTwoClicked()"
                >
                    {{ stepTwoButtonText }}
                </oButton>
                <!-- Next Step (Three) -->
                <oButton
                    v-if="stepThreeButton"
                    :fullWidth="true"
                    color="blue"
                    key="stepThreeButton"
                    @clicked="stepThreeClicked()"
                >
                    {{ stepThreeButtonText }}
                </oButton>
                <!-- Push Button -->
                <oButton
                    v-if="pushButton"
                    :loading="pushButtonHasBeenClicked && pushDataStillLoading"
                    :fullWidth="true"
                    color="green"
                    key="pushButton"
                    @clicked="pushClicked()"
                >
                    {{ pushButtonText }}
                    <template v-slot:icon>
                        <RightArrowIcon hex="#fff" class="right-arrow" />
                    </template>
                </oButton>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { ref, computed, watch } from 'vue'
import { Improvement } from '@opteo/types'
import useMediaQuery from '@/composition/global/useMediaQuery'

import {
    // Components
    Spinner,
    Text,
    Spacer,
    oButton,
    Popout,
    // Icons
    SelectArrowIcon,
    RightArrowIcon,
} from '@opteo/components-next'

export default {
    components: {
        Spinner,
        RightArrowIcon,
        Popout,
        SelectArrowIcon,
        Text,
        oButton,
        Spacer,
    },
    props: {
        stepOneButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        stepOneButtonText: {
            type: String,
            default: 'Default StepOne Button',
        },
        stepTwoButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        stepTwoButtonText: {
            type: String,
            default: 'Default StepTwo Button',
        },
        stepThreeButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        stepThreeButtonText: {
            type: String,
            default: 'Default StepThree Button',
        },
        pushButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        pushButtonText: {
            type: String,
            default: 'Default Push Button',
        },
        pushDataStillLoading: {
            type: Boolean,
            default: false,
            required: false,
        },
        adjustButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        adjustDisabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        dismissButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        backButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        completed: {
            type: Boolean,
            default: false,
            required: false,
        },
        completedText: {
            type: String,
            default: 'Default Completed Text',
            required: false,
        },
        singleColButton: {
            type: Boolean,
            default: false,
            required: false,
        },
        dismissedView: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    emits: [
        'adjust-clicked',
        'back-clicked',
        'step-one-clicked',
        'step-two-clicked',
        'step-three-clicked',
        'push-clicked',
        'close-clicked',
    ],
    methods: {
        adjustClicked() {
            this.$emit('adjust-clicked')
        },
        backClicked() {
            this.$emit('back-clicked')
        },
        stepOneClicked() {
            this.$emit('step-one-clicked')
        },
        stepTwoClicked() {
            this.$emit('step-two-clicked')
        },
        stepThreeClicked() {
            this.$emit('step-three-clicked')
        },
    },
    // Required to access the enum in the template section
    setup(props, { emit }) {
        const dismissPopoutOpen = ref(false)
        const pushButtonHasBeenClicked = ref(false)

        const pushDataLoading = computed(() => props.pushDataStillLoading)

        function pushClicked() {
            pushButtonHasBeenClicked.value = true

            if (!pushDataLoading.value) {
                emit('push-clicked')
            }
        }

        watch(pushDataLoading, () => {
            if (pushButtonHasBeenClicked.value && !pushDataLoading.value) {
                emit('push-clicked')
            }
        })

        return {
            ...useMediaQuery(),
            dismissPopoutOpen,
            pushButtonHasBeenClicked,
            pushClicked,
            LocationEntity: Improvement.LocationEntity,
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

#mobile-improvement-footer {
    @include bg-opteo-white;
    box-shadow: $opteo-shadow-xl;
    touch-action: none;
    position: fixed;
    bottom: 0.75rem;
    left: 0.75rem;
    width: calc(100vw - 1.5rem);
    @include br-20;
    z-index: 10000;
    @include pa-20;
}
#mobile-improvement-footer .o-button {
    height: 3rem;
}
#mobile-improvement-footer .adjust-back-dismiss-buttons {
    @include flex;
    @include items-center;
}
#mobile-improvement-footer .adjust-back-dismiss-buttons.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.625rem;
}

#mobile-improvement-footer .completed-text {
    width: 100%;
    text-align: center;
    height: 1.25rem;
}

#mobile-improvement-footer .reverts-in-text {
    width: 100%;
    text-align: center;
    height: 1.25rem;
    @include mb-16;
}

#mobile-improvement-footer .mobile-footer-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.75rem;
}

// Transition

.mobile-improvement-footer-fade-enter-active {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transition-delay: 0.125s;
}
.mobile-improvement-footer-fade-leave-active {
    transition: all 0s cubic-bezier(0.19, 1, 0.22, 1);
}
.mobile-improvement-footer-fade-enter-from,
.mobile-improvement-footer-fade-leave-to {
    transform: translateY(1rem);
    opacity: 0;
}
</style>
