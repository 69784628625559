import { useMediaQuery as useMQ } from '@vueuse/core'

export default function useMediaQuery() {
    const aboveSmallMobile = useMQ('(min-width: 374px)')
    const aboveMobile = useMQ('(min-width: 768px)')
    const aboveiPadLandscape = useMQ('(min-width: 1024px)')
    const above1080 = useMQ('(min-width: 1080px)')
    const below1280 = useMQ('(max-width: 1280px')

    return { aboveSmallMobile, aboveMobile, aboveiPadLandscape, above1080, below1280 }
}
