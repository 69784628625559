<template>
    <div :class="classes" class="flex items-center card-title-tag shadow br-pill">
        {{ title }}
    </div>
</template>

<script lang="ts">
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: 'Default Title',
        },
        classes: {
            type: String,
            required: false,
        },
    },
}
</script>

<style scoped>
.card-title-tag {
    position: absolute;
    padding: 0.625rem 1rem;
    top: -0.9375rem;
    background-color: white;
    left: 1.625rem;
    font-size: 0.625rem;
    font-weight: 600;
    text-transform: uppercase;
}
</style>
