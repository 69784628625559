import { computed, ComputedRef, ref } from 'vue'

import { AdjustTarget, AdjustAdGroupMultiTargets, Improvement } from '@opteo/types'

import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useDomain } from '@/composition/domain/useDomain'
import { useNumber, useRoas, usePercent, ScatterPointChart } from '@opteo/components-next'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

export namespace Table {
    export type Header = {
        key: string
        text: string
        sortable?: boolean
        width?: number
        noPadding?: true
    }

    const PerformanceMode = {
        uppercase: {
            cpa: 'CPA',
            roas: 'ROAS',
        },
        lowercase: {
            cpa: 'cpa',
            roas: 'roas',
        },
        capitalised: {
            cpa: 'Cpa',
            roas: 'Roas',
        },
    } as const

    type UppercasePerformanceMode =
        (typeof PerformanceMode.uppercase)[keyof typeof PerformanceMode.uppercase]

    export type LowercasePerformanceMode =
        (typeof PerformanceMode.lowercase)[keyof typeof PerformanceMode.lowercase]

    export type CapitalisedPerformanceMode =
        (typeof PerformanceMode.capitalised)[keyof typeof PerformanceMode.capitalised]

    export type CapitalisedPerformanceAvg = `avg${CapitalisedPerformanceMode}`

    export type CopyItem = {
        performanceMode: UppercasePerformanceMode
        convTableHeader: 'Conv.' | 'Value'
        conversionMode: 'conversion' | 'conversion value'
    }

    export type InvalidReason = {
        invalidReason: string
        width: number
    }

    export type NewTarget<T extends 'valid' | 'notValid' = 'valid'> = {
        isValid: T extends 'valid' ? true : false
        rawExistingTargetValue: number
        resourceName: string
        adjustedValue?: number
    } & (T extends 'valid'
        ? {
              rawValue: number
              formattedValue: string
              differenceVsExistingTarget: number
          }
        : InvalidReason)

    export type DifferenceResult = 'positive' | 'negative' | 'neutral' | 'n/a'

    export type CommonInfoItem = {
        adGroup: string
        id: string
        cost: number
        difference: number
        // Conversions if in CPA mode, conversion value if in ROAS mode
        performanceMetric: string
        allConversions: number
        allConversionsValue: number
    }

    const avgCpa = `avg${PerformanceMode.capitalised.cpa}` as `avg${Capitalize<
        typeof PerformanceMode.capitalised.cpa
    >}`
    const avgRoas = `avg${PerformanceMode.capitalised.cpa}` as `avg${Capitalize<
        typeof PerformanceMode.capitalised.roas
    >}`

    /**
     * cpa: string; avgCpa: string -> valid
     * roas: string; avgRoas: string -> error
     */
    export type CpaPerformanceObject = {
        [PerformanceMode.lowercase.cpa]: string
        [avgCpa]: string
        [PerformanceMode.lowercase.roas]?: never
        [avgRoas]?: never
    }

    /**
     * roas: string; avgRoas: string -> valid
     * cpa: string; avgCpa: string -> error
     */
    export type RoasPerformanceObject = {
        [PerformanceMode.lowercase.roas]: string
        [avgRoas]: string
        [PerformanceMode.lowercase.cpa]?: never
        [avgCpa]?: never
    }

    export type Item<T extends 'valid' | 'notValid'> = CommonInfoItem & {
        currentTarget: string
        newTarget: NewTarget<T>
    } & (CpaPerformanceObject | RoasPerformanceObject)
}

namespace ScatterPointChart {
    export type Item = {
        x: number
        y: number
        label: string
        highlighted: boolean
    }

    export type Axis = Record<'x' | 'y', { label: string; dataType: string; currency: string }>

    export type Metric = {
        label: string
        dataType: string
        currency: string
        inverted: boolean
    }

    export type Body = {
        items: Item[]
        keys: string[]
        cutOff: number
        axis: Axis
        metric: Metric
    }
}

export type MultiTargetUpdateArgs = {
    adjustedTarget: number
    resourceName: string
}

type FormattedCampaign = {
    entityType: Improvement.LocationEntity.Campaign
    name: string
    cpa?: number
    roas?: number
    formattedCpa?: string
    formattedRoas?: string
    cost: string
    conversions?: string
    conversionsValue?: string
}

export interface UseAdjustAdGroupMultiTargets {
    onReset: () => void
    getDifferenceResult: ({
        differenceValue,
        isCpaMode,
    }: {
        differenceValue: number
        isCpaMode: boolean
    }) => Table.DifferenceResult
    onMultiTargetUpdate: ({ adjustedTarget, resourceName }: MultiTargetUpdateArgs) => void

    currencyCode: ComputedRef<string | undefined>
    lookbackWindow: AdjustTarget.ValidLookbackWindow

    isCpaMode: boolean
    campaign: FormattedCampaign
    table: {
        headers: Table.Header[]
        items: Table.Item<'valid' | 'notValid'>[]
    }
    adjustTableHeaders: Table.Header[]
    scatterPointChart: ScatterPointChart.Body
    improvementStatistics: {
        key: string
        value: number | string
        title: string
    }[]
}

export function useAdjustAdGroupMultiTargets(): UseImprovement<UseAdjustAdGroupMultiTargets> {
    const { improvement, lastUpdated, title } = useImprovement<AdjustAdGroupMultiTargets.Body>()

    const { body } = checkImprovement(improvement)

    const {
        campaign,
        campaign_group: { desired_target: desiredCampaignGroupTarget },
        window: lookbackWindow,
        currency: bodyCurrencyCode,
        ad_group_multi: adGroupMulti,
    } = body

    const { currencyCode } = useDomain()

    /** We decide this based on the campaign bidding_strategy_type, so account performance_mode is not relevant in this case */
    const isCpaMode =
        campaign.bidding_strategy_type === AdjustTarget.ValidBiddingStrategyTypes.TARGET_CPA ||
        campaign.bidding_strategy_type ===
            AdjustTarget.ValidBiddingStrategyTypes.MAXIMIZE_CONVERSIONS

    const copyItem: Table.CopyItem = {
        performanceMode: isCpaMode ? 'CPA' : 'ROAS',
        convTableHeader: isCpaMode ? 'Conv.' : 'Value',
        conversionMode: isCpaMode ? 'conversion' : 'conversion value',
    } as const

    // Campaign
    const { campaignCpa, campaignRoas, formattedCampaign, formattedCampaignGroupDesiredTarget } =
        getCampaignInfo({
            isCpaMode,
            campaign,
            desiredCampaignGroupTarget,
        })

    // Table
    /** 'cpa' | 'roas' */
    const performanceKey = copyItem.performanceMode.toLowerCase() as Table.LowercasePerformanceMode
    /** 'avgCpa' | 'avgRoas' */
    const avgPerformanceKey = `avg${performanceKey[0].toUpperCase()}${performanceKey.slice(
        1
    )}` as Table.CapitalisedPerformanceAvg

    const tableHeaders: Table.Header[] = [
        {
            key: 'adGroup',
            text: 'Ad Group',
            sortable: true,
            noPadding: true,
        },
        {
            key: 'cost',
            text: 'Cost',
            sortable: true,
            width: 112,
            noPadding: true,
        },
        {
            key: 'performanceMetric',
            text: `${copyItem.convTableHeader}`,
            sortable: true,
            width: isCpaMode ? 102 : 104,
            noPadding: true,
        },
        {
            key: performanceKey,
            text: `${copyItem.performanceMode}`,
            sortable: true,
            width: isCpaMode ? 102 : 108,
            noPadding: true,
        },
        {
            key: avgPerformanceKey,
            text: `Avg. ${copyItem.performanceMode}`,
            width: isCpaMode ? 102 : 102,
            noPadding: true,
        },
        {
            key: 'difference',
            text: 'Diff.',
            sortable: true,
            width: 100,
            noPadding: true,
        },
        {
            key: 'currentTarget',
            text: 'Target',
            sortable: false,
            width: isCpaMode ? 98 : 98,
            noPadding: true,
        },
        {
            key: 'newTarget',
            text: 'Adjusted Target',
            sortable: false,
            width: isCpaMode ? 144 : 144,
            noPadding: true,
        },
    ]

    const adjustTableHeaders = [...tableHeaders] // Duplicate the array
    adjustTableHeaders.pop() // Remove the last item
    adjustTableHeaders.push({
        // Replace the last item
        key: 'newTarget',
        text: 'Adjusted Target',
        sortable: false,
        width: 188,
        noPadding: true,
    })

    const tableItems: Table.Item<'valid' | 'notValid'>[] = []

    // Scatter Point Chart
    const { scatterPointChart } = getScatterPointChartInfo({
        copyItemPerformanceMode: copyItem.performanceMode,
        isCpaMode,
        campaignCpa,
        campaignRoas,
        currency: currencyCode?.value ?? bodyCurrencyCode,
    })

    /**
     * Loops through every adGroup inside adGroupMulti and extracts raw and formatted info.
     * It then pushes this as items to both tables and to scatterPointChart.
     */
    adGroupMulti.forEach(adGroup => {
        const { resource_name: resourceName, name, metrics, is_valid: isAdGroupValid } = adGroup

        const {
            cost,
            all_conversions: allConversions,
            all_conversions_value: allConversionsValue,
        } = metrics

        const { adGroupInfo } = getAdGroupInfo({ adGroup, campaignCpa, campaignRoas })
        const { newAdGroupTarget, existingTarget, formatted, adGroupCpa, adGroupRoas } = adGroupInfo

        let formattedNewTarget: string = ''

        if (newAdGroupTarget) {
            formattedNewTarget = formatNewTarget({ newAdGroupTarget, isCpaMode })
        }

        const commonTableItems: Table.CommonInfoItem = {
            adGroup: name,
            id: resourceName,
            cost,
            difference: isCpaMode ? adGroupInfo.cpaDifference : adGroupInfo.roasDifference,
            performanceMetric: isCpaMode ? formatted.conversions : formatted.conversionValue,
            allConversions,
            allConversionsValue,
        }

        let newTarget: Table.NewTarget<'valid' | 'notValid'>
        const newTargetCommonData = {
            rawExistingTargetValue: existingTarget,
            resourceName,
        }

        if (isAdGroupValid && newAdGroupTarget) {
            const rawValue = isCpaMode
                ? // Limit new CPA to 2 decimals so there is no discrepancies with the Adjust Table, where we limit the input to 2 decimals too
                  limitDecimalPlaces(newAdGroupTarget)
                : // Suggested new ROAS target may come as float but we only allow whole numbers as an input, hence rounding that value
                  Math.round(newAdGroupTarget * 100) / 100

            newTarget = {
                isValid: true,
                rawValue,
                formattedValue: formattedNewTarget,
                differenceVsExistingTarget: getNewTargetDifference({
                    existingTargetValue: existingTarget,
                    newTargetValue: rawValue,
                }),
                ...newTargetCommonData,
            }
        } else {
            const invalidReason = getInvalidTargetReason({
                isCpaMode,
                invalidReasonRaw:
                    adGroup.new_ad_group_target_invalid_reason as AdjustTarget.InvalidTargetReason,
                copyItem,
                formattedCampaignGroupDesiredTarget,
                rawDesiredCampaignGroupTarget: desiredCampaignGroupTarget,
                adGroupPerformance: isCpaMode ? adGroupCpa : adGroupRoas,
            })
            newTarget = { isValid: false, ...invalidReason, ...newTargetCommonData }
        }

        let performanceItems!: Table.CpaPerformanceObject | Table.RoasPerformanceObject

        if (isCpaMode) {
            performanceItems = {
                cpa: formatted.cpa,
                avgCpa: formatted.avgCpa,
            } as Table.CpaPerformanceObject
        } else {
            performanceItems = {
                roas: formatted.roas,
                avgRoas: formatted.avgRoas,
            } as Table.RoasPerformanceObject
        }

        tableItems.push({
            ...commonTableItems,
            ...performanceItems,
            currentTarget: isCpaMode ? formatted.existingCpaTarget : formatted.existingRoasTarget,
            newTarget,
        })

        scatterPointChart.items.push({
            x: isCpaMode ? allConversions : cost,
            y: isCpaMode ? cost : allConversionsValue,
            label: name,
            highlighted: false,
        })
    })

    // Improvement Statistics
    const validTableItems = tableItems.filter(
        (item): item is Table.Item<'valid'> => item.newTarget.isValid
    )

    const totalSpendAffected = validTableItems.reduce((acc, item) => item.cost + acc, 0)
    const totalAdjustmentsRecommended = validTableItems.length
    const mostSignificantAdjustment = validTableItems.reduce((max, item) => {
        const { differenceVsExistingTarget: currentDifference } = item.newTarget

        const maxAbsDifference = Math.abs(max)
        const currentAbsDifference = Math.abs(currentDifference)

        return currentAbsDifference > maxAbsDifference ? currentDifference : max
    }, 0)

    /**
     * If positive number: saving
     * If negative number: spending more - will not be added to improvement statistics
     */
    const potentialSavings = validTableItems.reduce((acc, item) => {
        const { cost, newTarget } = item

        const savingsDiff = isCpaMode
            ? cost - cost * (1 + newTarget.differenceVsExistingTarget)
            : cost - cost * (1 - newTarget.differenceVsExistingTarget)

        return savingsDiff + acc
    }, 0)

    /**
     * If positive number: more conversions or better conversion value
     * If negative number: fewer conversions or worse conversion value - will not be added to improvement statistics
     */
    const potentialExtraPerformance = validTableItems.reduce((acc, item) => {
        const { newTarget, allConversions, allConversionsValue } = item

        const isDiscountApplied = isCpaMode
            ? newTarget.differenceVsExistingTarget > 0
            : newTarget.differenceVsExistingTarget < 0

        let discountPercent = 0
        if (isDiscountApplied) {
            const absoluteDiff = Math.abs(newTarget.differenceVsExistingTarget)

            discountPercent = absoluteDiff < 0.1 ? 0.1 : absoluteDiff > 0.2 ? 0.2 : 0.15
        }

        const performanceMetricDiff =
            newTarget.differenceVsExistingTarget *
            (1 - discountPercent) *
            (isCpaMode ? allConversions : allConversionsValue)

        return (isCpaMode ? performanceMetricDiff : performanceMetricDiff * -1) + acc
    }, 0)

    const improvementStatistics = [
        {
            key: 'spend',
            value: useDomainMoney({ value: totalSpendAffected }).value.displayValue.value,
            title: 'Total Spend Affected',
        },
        {
            key: 'adjustments',
            value: totalAdjustmentsRecommended,
            title: 'Adjustments Recommended',
        },
        {
            key: 'significantAdjustment',
            value: usePercent({
                value: mostSignificantAdjustment,
                includeSign: true,
            }).displayValue.value,
            title: 'Most Significant Adjustment',
        },
    ]

    const POTENTIAL_SAVINGS_MIN_THRESHOLD = 10
    const POTENTIAL_EXTRA_PERFORMANCE_MIN_THRESHOLD = 1

    if (potentialSavings > POTENTIAL_SAVINGS_MIN_THRESHOLD) {
        improvementStatistics.push({
            key: 'potentialSavings',
            value: useDomainMoney({ value: potentialSavings }).value.displayValue.value,
            title: 'Potential Savings',
        })
    }

    if (potentialExtraPerformance > POTENTIAL_EXTRA_PERFORMANCE_MIN_THRESHOLD) {
        improvementStatistics.push({
            key: 'potentialExtraPerformance',
            value: isCpaMode
                ? useNumber({ value: potentialExtraPerformance }).displayValue.value
                : useDomainMoney({ value: potentialExtraPerformance }).value.displayValue.value,
            title: `Potential Extra ${isCpaMode ? 'Conversions' : 'Conversion Value'}`,
        })
    }

    // Adjust Step actions
    let isAdjusting = false

    const adjustSteps = computed(() => ['adjust-step-1'])

    const onMultiTargetUpdate = ({
        adjustedTarget,
        resourceName,
    }: {
        adjustedTarget: number
        resourceName: string
    }) => {
        const targetToChange = tableItems.find(
            item => item.newTarget.resourceName === resourceName
        )?.newTarget

        if (!targetToChange) return

        isAdjusting = true
        targetToChange.adjustedValue = adjustedTarget
    }

    const onReset = () => {
        isAdjusting = false

        // Reset table: remove adjustedValue if exists
        tableItems.forEach(item => {
            const { newTarget } = item

            const { adjustedValue, ...nonAdjustedNewTarget } = newTarget

            item.newTarget = nonAdjustedNewTarget
        })
    }

    // Push Actions
    const pushActionText = ref('Apply Target Adjustments')
    const pushMessages = computed(() => [
        `Connecting to Google Ads..`,
        `Applying Target Adjustments..`,
        `Confirming changes..`,
        `Target Adjustments applied successfully.`,
    ])

    // Pushed Data
    const onPush: OnPushHandler = () => {
        if (isAdjusting) {
            const checkIsValid = (
                item: Table.Item<'valid' | 'notValid'>
            ): item is Table.Item<'valid'> => item.newTarget.isValid

            const extraDetails: AdjustTarget.ExtraDetails[] = []

            tableItems.forEach(item => {
                /**
                 * An ad group isValid if Opteo suggested a new target for it.
                 * However, a user can edit a target of a !isValid ad group in the adjust step.
                 * Therefore, we first check if an ad group has an adjustedValue and push that to extraDetails.
                 * Else, we push the rawValue suggested by us.
                 */
                const { adjustedValue, resourceName } = item.newTarget

                // This can happen if a user removes a suggested target, i.e. changes its field to be N/A
                if (adjustedValue === 0) return

                if (adjustedValue) {
                    extraDetails.push({
                        new_target: adjustedValue,
                        resource_name: resourceName,
                    })
                    return
                }

                if (checkIsValid(item)) {
                    const { rawValue } = item.newTarget

                    extraDetails.push({
                        new_target: rawValue,
                        resource_name: resourceName,
                    })
                }
            })

            return {
                valid: true,
                pushedData: extraDetails,
            }
        } else {
            return {
                valid: true,
            }
        }
    }

    return {
        onPush,
        onReset,
        getDifferenceResult,
        onMultiTargetUpdate,

        title,
        currencyCode,
        lookbackWindow,
        lastUpdated,
        pushActionText,
        pushMessages,
        adjustSteps,
        improvementStatistics,

        isCpaMode,
        campaign: formattedCampaign,
        table: {
            headers: tableHeaders,
            items: tableItems,
        },
        adjustTableHeaders,
        scatterPointChart,
    }
}

const getCampaignInfo = ({
    isCpaMode,
    campaign,
    desiredCampaignGroupTarget,
}: {
    isCpaMode: boolean
    campaign: AdjustAdGroupMultiTargets.Body['campaign']
    desiredCampaignGroupTarget: number | undefined
}): {
    campaignCpa: number
    campaignRoas: number
    formattedCampaign: FormattedCampaign
    formattedCampaignGroupDesiredTarget: string
} => {
    // Raw Campaign Metrics
    const campaignCpa = campaign.metrics.cost / campaign.metrics.all_conversions
    const campaignRoas = campaign.metrics.all_conversions_value / campaign.metrics.cost

    // Formatted Campaign Metrics
    const formattedCampaignCost = useDomainMoney({
        value: campaign.metrics.cost,
    }).value.displayValue.value
    const formattedCampaignConv = useNumber({
        value: campaign.metrics.all_conversions,
    }).displayValue.value
    const formattedCampaignConvValue = useDomainMoney({
        value: campaign.metrics.all_conversions_value,
    }).value.displayValue.value
    const formattedCampaignCpa = useDomainMoney({
        value: campaignCpa,
    }).value.displayValue.value
    const formattedCampaignRoas = useRoas({
        value: campaignRoas,
    }).displayValue.value

    // Formatted Campaign Group Metrics
    let formattedCampaignGroupDesiredTarget = ''
    if (desiredCampaignGroupTarget) {
        formattedCampaignGroupDesiredTarget = isCpaMode
            ? useDomainMoney({ value: desiredCampaignGroupTarget }).value.displayValue.value
            : useRoas({ value: desiredCampaignGroupTarget }).displayValue.value
    }

    return {
        campaignCpa,
        campaignRoas,
        formattedCampaign: {
            entityType: Improvement.LocationEntity.Campaign,
            name: campaign.name,
            cpa: campaignCpa,
            roas: campaignRoas,
            formattedCpa: formattedCampaignCpa,
            formattedRoas: formattedCampaignRoas,
            cost: formattedCampaignCost,
            conversions: formattedCampaignConv,
            conversionsValue: formattedCampaignConvValue,
        },
        formattedCampaignGroupDesiredTarget,
    }
}

const getScatterPointChartInfo = ({
    copyItemPerformanceMode,
    isCpaMode,
    campaignCpa,
    campaignRoas,
    currency,
}: {
    copyItemPerformanceMode: string
    isCpaMode: boolean
    campaignCpa: number
    campaignRoas: number
    currency: string
}): { scatterPointChart: ScatterPointChart.Body } => {
    const scatterPointChartItems: ScatterPointChart.Item[] = []

    const scatterPointChartKeys = [
        `Above ${copyItemPerformanceMode} Average`,
        '',
        `Below ${copyItemPerformanceMode} Average`,
    ]

    const scatterPointChartCutOff = isCpaMode ? campaignCpa : campaignRoas

    const scatterPointChartAxis = isCpaMode
        ? {
              x: {
                  label: 'Conversions',
                  dataType: 'number',
                  currency,
              },
              y: {
                  label: 'Cost',
                  dataType: 'money',
                  currency,
              },
          }
        : {
              x: {
                  label: 'Cost',
                  dataType: 'money',
                  currency,
              },
              y: {
                  label: 'Value',
                  dataType: 'money',
                  currency,
              },
          }

    const scatterPointChartMetric = {
        label: isCpaMode ? 'CPA' : 'ROAS',
        dataType: isCpaMode ? 'money' : 'roas',
        currency,
        inverted: isCpaMode ? true : false,
    }

    return {
        scatterPointChart: {
            items: scatterPointChartItems,
            keys: scatterPointChartKeys,
            cutOff: scatterPointChartCutOff,
            axis: scatterPointChartAxis,
            metric: scatterPointChartMetric,
        },
    }
}

const getAdGroupInfo = ({
    adGroup,
    campaignCpa,
    campaignRoas,
}: {
    adGroup: AdjustAdGroupMultiTargets.Body['ad_group_multi'][0]
    campaignCpa: number
    campaignRoas: number
}) => {
    const { existing_target: existingTarget, new_ad_group_target: newAdGroupTarget } = adGroup

    // Raw Ad Group Metrics
    const adGroupCpa = adGroup.metrics.cost / adGroup.metrics.all_conversions
    const adGroupRoas = adGroup.metrics.all_conversions_value / adGroup.metrics.cost

    const adGroupCpaDifference = (adGroupCpa - campaignCpa) / campaignCpa
    const adGroupRoasDifference = (adGroupRoas - campaignRoas) / campaignRoas

    const adGroupInfo = {
        adGroupCpa,
        adGroupRoas,

        cpaDifference: adGroupCpaDifference,
        roasDifference: adGroupRoasDifference,

        existingTarget,
        newAdGroupTarget,

        formatted: {
            conversions: useNumber({
                value: adGroup.metrics.all_conversions,
            }).displayValue.value,
            conversionValue: useDomainMoney({
                value: adGroup.metrics.all_conversions_value,
            }).value.displayValue.value,

            cpa: useDomainMoney({
                value: adGroupCpa,
            }).value.displayValue.value,
            roas: useRoas({
                value: adGroupRoas,
                decimalPlaces: adGroupRoas < 1 ? 2 : 1,
            }).displayValue.value,

            avgCpa: useDomainMoney({
                value: campaignCpa,
            }).value.displayValue.value,
            avgRoas: useRoas({
                value: campaignRoas,
                decimalPlaces: campaignRoas < 1 ? 2 : 1,
            }).displayValue.value,

            existingCpaTarget: useDomainMoney({
                value: existingTarget,
            }).value.displayValue.value,
            existingRoasTarget: useRoas({
                value: existingTarget,
                decimalPlaces: 2,
            }).displayValue.value,
        },
    }

    return { adGroupInfo }
}

const formatNewTarget = ({
    newAdGroupTarget,
    isCpaMode,
}: {
    newAdGroupTarget: number
    isCpaMode: boolean
}): string => {
    let formattedNewTarget: string

    if (isCpaMode) {
        formattedNewTarget = useDomainMoney({
            value: newAdGroupTarget,
        }).value.displayValue.value
    } else {
        formattedNewTarget = useRoas({
            value: newAdGroupTarget,
        }).displayValue.value
    }

    return formattedNewTarget
}

const getInvalidTargetReason = ({
    isCpaMode,
    invalidReasonRaw,
    copyItem,
    formattedCampaignGroupDesiredTarget,
    rawDesiredCampaignGroupTarget,
    adGroupPerformance,
}: {
    isCpaMode: boolean
    invalidReasonRaw: AdjustTarget.InvalidTargetReason
    copyItem: Table.CopyItem
    formattedCampaignGroupDesiredTarget: string
    rawDesiredCampaignGroupTarget: number | undefined
    adGroupPerformance: number
}): Table.InvalidReason => {
    const InvalidTargetReasonCopy = {
        BUDGET_CAPPED_CONSTRAINT: `No new target proposed because the parent campaign is limited by budget.`,
        TARGET_CHANGE_IS_TOO_SMALL: `No new target proposed because the ad group ${copyItem.performanceMode} is within 3% of average.`,
        LATEST_TARGET_CHANGE_WITHIN_WINDOW: `No new target proposed due to a recent target change in ad group.`,
        TARGET_IN_WRONG_DIRECTION_UNDERPERFORMING: `No target ${
            isCpaMode ? 'increase' : 'decrease'
        } proposed because the ad group ${
            copyItem.performanceMode
        } is underperforming the campaign group target of <b>${formattedCampaignGroupDesiredTarget}</b>.`,
        TARGET_IN_WRONG_DIRECTION_OUTPERFORMING: `No target ${
            isCpaMode ? 'decrease' : 'increase'
        } proposed because the ad group ${
            copyItem.performanceMode
        } is outperforming the campaign group target of <b>${formattedCampaignGroupDesiredTarget}</b>.`,
        DEFAULT: 'No new target proposed due to insufficient clicks in ad group.',
    } as const

    if (invalidReasonRaw === AdjustTarget.InvalidTargetReason.BUDGET_CAPPED_CONSTRAINT) {
        return {
            invalidReason: InvalidTargetReasonCopy.BUDGET_CAPPED_CONSTRAINT,
            width: 230,
        }
    }

    if (invalidReasonRaw === AdjustTarget.InvalidTargetReason.TARGET_CHANGE_IS_TOO_SMALL) {
        return {
            invalidReason: InvalidTargetReasonCopy.TARGET_CHANGE_IS_TOO_SMALL,
            width: 238,
        }
    }

    if (invalidReasonRaw === AdjustTarget.InvalidTargetReason.LATEST_TARGET_CHANGE_WITHIN_WINDOW) {
        return {
            invalidReason: InvalidTargetReasonCopy.LATEST_TARGET_CHANGE_WITHIN_WINDOW,
            width: 212,
        }
    }

    if (
        invalidReasonRaw === AdjustTarget.InvalidTargetReason.TARGET_IN_WRONG_DIRECTION &&
        rawDesiredCampaignGroupTarget
    ) {
        const isAdGroupUnderperforming = isCpaMode
            ? adGroupPerformance > rawDesiredCampaignGroupTarget
            : adGroupPerformance < rawDesiredCampaignGroupTarget

        return {
            invalidReason: isAdGroupUnderperforming
                ? InvalidTargetReasonCopy.TARGET_IN_WRONG_DIRECTION_UNDERPERFORMING
                : InvalidTargetReasonCopy.TARGET_IN_WRONG_DIRECTION_OUTPERFORMING,
            width: 338,
        }
    }

    return { invalidReason: InvalidTargetReasonCopy.DEFAULT, width: 192 }
}

const getDifferenceResult: UseAdjustAdGroupMultiTargets['getDifferenceResult'] = ({
    differenceValue,
    isCpaMode,
}) => {
    if (differenceValue === 0) {
        return 'neutral'
    }

    if (isCpaMode) {
        if (differenceValue > 0) {
            return 'negative'
        }
        if (differenceValue < 0) {
            return 'positive'
        }
    } else {
        if (differenceValue < 0) {
            return 'negative'
        }
        if (differenceValue > 0) {
            return 'positive'
        }
    }

    return 'n/a'
}

/**
 * @description Simple division to get the % difference between the new proposed target and the existing target.
 * Due to rounding, the difference might be a bit over 30% (our max proposed target). In these cases, we just round to 30%.
 * If the user is adjusting, they're able to set the target without limits, so we ignore this min/max logic.
 * @param args.existingTargetValue
 * @param args.newTargetValue
 * @param args.isAdjusting
 * @returns The target % difference as a number
 */
export const getNewTargetDifference = ({
    existingTargetValue,
    newTargetValue,
    isAdjusting,
}: {
    existingTargetValue: number
    newTargetValue: number
    isAdjusting?: boolean
}) => {
    // TODO: think whether it's better to do this and the rounding in the backend
    const newTargetDifference = (newTargetValue - existingTargetValue) / existingTargetValue

    if (isAdjusting) {
        return newTargetDifference
    }

    if (newTargetDifference > 0) {
        return Math.min(newTargetDifference, 0.3)
    } else {
        return Math.max(newTargetDifference, -0.3)
    }
}

/**
 * @description Inspired by useRoas, removes decimal places if the percentValue >= 1000%, else: uses 2 decimal places.
 * @param percentValue
 * @returns 2 | 0
 */
export const setDecimalPlaces = (percentValue: number) => {
    return Math.abs(percentValue) >= 10 || percentValue === -1 ? 0 : 2
}

/**
 * @description Limits a given number to 2 decimal places.
 * For weird JS gotchas, it rounds the number. E.g.: 2.26 * 100 = 225.99999999999997 - will change to 2.26
 * @param newTargetValue
 * @returns A number with max 2 decimal places
 */
export const limitDecimalPlaces = (newTargetValue: number) => {
    return parseFloat(Math.abs(newTargetValue).toFixed(2)) || 0
}
