<template>
    <Panel :model-value="showPanel" title="Search Terms" :width="1140" @close="closePanel">
        <template #title>
            <EntityPill
                v-if="singleItemPanel"
                :type="Improvement.LocationEntity.NGram"
                :content="singleItemPanel.text"
            />
            <EntityPill
                v-else
                :type="Improvement.LocationEntity.NGram"
                :content="`${selectedNGramItems.length} ${
                    selectedNGramItems.length > 1 ? 'N-Grams' : 'N-Gram'
                }`"
            />
        </template>
        <template #content>
            <oTable
                :headers="panelItemHeaders"
                :items="ngrams as unknown as Record<string, number | string>[]"
                fixed-layout
                :border-radius="24"
            >
                <template #column.text="{ value, row }">
                    <div class="ngram-cell">
                        <EntityPill
                            :type="Improvement.LocationEntity.NGram"
                            :content="value"
                        /><Spacer width="0.5rem" /><NScoreCell v-bind="row" />
                    </div>
                </template>
                <template #column.cost="{ value }">
                    <Money :value="value" :currency="currencyCode" />
                </template>
                <template #column.impressions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversionValue="{ value }">
                    <Money :value="value" :currency="currencyCode" />
                </template>
                <template #column.cpa="{ value }">
                    <Money :value="value" :currency="currencyCode" />
                </template>
                <template #column.roas="{ value }">
                    <Percent :value="value" />
                </template>
                <template #column.cpm="{ value }">
                    {{ formatCpm(value) }}
                </template>
                <template #column.vpm="{ value }">
                    {{ formatVpm(value) }}
                </template>
                <template #column.vsAvg="{ value, row }">
                    <Text size="f-8" weight="500" :color="row.vsAverageColor">
                        <Percent :value="value" :decimal-places="2" include-sign />
                    </Text>
                </template>
                <template #column.potentialSavings="{ value }">
                    <Pill><Money :value="value" :currency="currencyCode" />/month</Pill>
                </template>
            </oTable>
            <Spacer height="2.375rem" />
            <BlockNote v-if="ngrams.length > 1">
                Adding these n-grams as negatives would block the following search terms
            </BlockNote>
            <BlockNote v-else>
                Adding this n-gram as a negative would block the following search terms
            </BlockNote>
            <Spacer height="2.375rem" />
            <SearchTermTable
                :search-terms="searchTerms"
                :key="uniqId"
                @add-search-terms-as-negatives="$emit('add-search-terms-as-negatives', $event)"
            />
        </template>
    </Panel>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import uniq from 'lodash-es/uniq'

import {
    Panel,
    oTable,
    Spacer,
    Text,
    EntityPill,
    Percent,
    Money,
    Number,
} from '@opteo/components-next'
import { Improvement } from '@opteo/types'
import Pill from '@/components/toolkit/ngram/Pill.vue'
import BlockNote from '@/components/toolkit/ngram/BlockNote.vue'
import { useNGramHeaders } from '@/composition/toolkit/nGramTool/useNGramHeaders'
import { useNgramPanels } from '@/composition/toolkit/nGramTool/useNgramPanels'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { isNGramItem } from '@/composition/toolkit/nGramTool/utils'

import SearchTermTable from './SearchTermTable.vue'
import NScoreCell from './NScoreCell.vue'

const { panelItemHeaders } = useNGramHeaders()
const { activePanel, singleItemPanel, closePanel } = useNgramPanels()
const { currencyCode, formatCpm, formatVpm, selectedNGramItems } = useNGramTool()

const showPanel = computed(
    () =>
        !!(
            activePanel.value === 'search-terms' &&
            (singleItemPanel.value || selectedNGramItems.value.length)
        )
)

const uniqId = computed(() => uniq(selectedNGramItems.value.map(ngram => ngram.ngram)).join('-'))

const ngrams = computed(() =>
    singleItemPanel.value && isNGramItem(singleItemPanel.value)
        ? [singleItemPanel.value]
        : selectedNGramItems.value
)
const searchTerms = computed(() => uniq(ngrams.value.flatMap(ngram => ngram.searchTerms)))
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.ngram-cell,
.conflicts-cell,
.nscore-cell,
.keyword-source-cell {
    display: flex;
    align-items: center;
}
.info-circle-icon {
    width: 1rem;
    cursor: pointer;
}
</style>
