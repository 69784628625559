<template>
    <router-link :to="openReportLink" class="report-row-container" tabindex="0">
        <div class="report-row" :class="{ pointer: !isDuplicating }">
            <!-- Report Status -->
            <div class="report-status-icon">
                <!-- Completed -->
                <Tooltip
                    v-if="report.last_exported_ts"
                    :content="isArchive ? 'Completed (Archived)' : 'Completed'"
                    class="report-status-icon"
                >
                    <CheckIcon />
                </Tooltip>
                <!-- Duplicating -->
                <Tooltip
                    v-else-if="isDuplicating"
                    content="Duplicating..."
                    class="report-status-icon"
                >
                    <div class="spinner-container">
                        <Spinner />
                    </div>
                </Tooltip>
                <!-- Incomplete / In Progress -->
                <Tooltip
                    v-else
                    :content="isArchive ? 'Incomplete (Archived)' : 'In Progress'"
                    class="report-status-icon"
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="50%"
                            cy="50%"
                            r="11"
                            fill-rule="nonzero"
                            :stroke="isArchive ? '#e0e0e5' : '#ff9500'"
                            stroke-width="2"
                            fill="none"
                        />
                    </svg>
                </Tooltip>
            </div>
            <!-- Spacer -->
            <Spacer width="1rem" height="0.25rem" />
            <!-- Title -->
            <Text as="h3" class="report-title">{{ report.title }}</Text>
            <!-- Spacer -->
            <Spacer width="1rem" height="0.25rem" />
            <!-- Date Range -->
            <Text as="h4" size="f-8" weight="400" color="gray">{{ report.date_range }}</Text>
        </div>
        <div v-if="!isDuplicating" class="report-row-right">
            <div class="report-row-right-inner">
                <!-- Scheduled Report -->
                <Tooltip
                    v-if="report.auto_gen"
                    style="height: 1.5rem; width: 1.5rem"
                    :content="`Generated On ${createdTs}`"
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 0c6.627417 0 12 5.372583 12 12s-5.372583 12-12 12S0 18.627417 0 12 5.372583 0 12 0zm0 2C6.477153 2 2 6.477153 2 12s4.477153 10 10 10 10-4.477153 10-10S17.522847 2 12 2zm-1 11V5h2v6h5v2h-7z"
                            fill="#D0D0D9"
                            fill-rule="nonzero"
                        />
                    </svg>
                </Tooltip>
                <!-- Spacer -->
                <Spacer width="1rem" height="0.25rem" />
                <!-- User -->
                <Tooltip :content="`Created by ${report.name}`">
                    <ProfileImage
                        :width="24"
                        :image-url="report.profile_url"
                        :avatar-url="avatarUrl"
                    />
                </Tooltip>
                <!-- Spacer -->
                <Spacer width="1rem" height="0.25rem" />
                <!-- Actions  -->
                <ReportItemActions :report="report" />
                <!-- Spacer -->
                <Spacer width="0.625rem" height="0.25rem" />
                <!-- Open Button -->
                <router-link :to="openReportLink">
                    <oButton :id="`push-btn-${report.report_id}`">Open Report</oButton>
                </router-link>
            </div>
        </div>
    </router-link>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { Reports } from '@opteo/types'

import {
    oButton,
    Text,
    CloseButton,
    Spacer,
    Tooltip,
    CheckIcon,
    Spinner,
    ProfileImage,
} from '@opteo/components-next'
import ReportItemActions from '@/components/Reports/ReportItemActions.vue'

import { format, parseISO } from 'date-fns'
import { Routes } from '@/router/routes'

import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

export default {
    name: 'ReportsRow',
    components: {
        oButton,
        ProfileImage,
        Text,
        ReportItemActions,
        CloseButton,
        Spacer,
        Tooltip,
        CheckIcon,
        Spinner,
    },
    props: {
        report: {
            type: Object as PropType<Reports.Core.ReportsListItem>,
            required: true,
        },
    },
    setup: props => {
        const { getAvatarUrl } = useBoringAvatar()
        const avatarUrl = computed(() => getAvatarUrl(props.report.user_id))

        const isArchive = computed(() => !!props.report.archived)
        const createdTs = computed(() => format(parseISO(props.report.created_ts), 'MMMM do'))
        const isDuplicating = computed(() => props.report.report_id === -1)

        const openReportLink = computed(() => {
            if (isDuplicating.value) {
                return ''
            }

            return {
                name: isArchive.value ? Routes.ReportArchive : Routes.ReportActive,
                params: { reportId: props.report.report_id },
            }
        })

        return {
            isArchive,
            createdTs,
            isDuplicating,
            openReportLink,
            avatarUrl,
        }
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.report-row-container {
    @include relative;
    @include flex;
    @include items-center;
    @include mb-16;
    outline: none !important;
}

.report-row {
    height: 4.5rem;
    @include w-100;
    @include flex;
    @include items-center;
    @include container;
    @include br-24;
    @include ph-24;
    cursor: pointer;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    outline: none !important;
}
.report-row:focus {
    box-shadow: $opteo-shadow-focus;
    outline: none !important;
}
.report-row:active {
    box-shadow: $opteo-shadow-focus;
    outline: none !important;
    transform: translate3d(0, 1px, 0);
    -webkit-transform: translate3d(0, 1px, 0);
    -moz-transform: translate3d(0, 1px, 0);
}

.report-row-right {
    @include flex;
    @include items-center;
    @include absolute;
    @include right-0;
    @include top-0;
    @include h-100;
    @include pl-4;
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
// "~" selects a given sibling element, for more information check this link:
// https://developer.mozilla.org/en-US/docs/Web/CSS/General_sibling_combinator
.report-row:active ~ .report-row-right {
    transform: translate3d(0, 1px, 0);
    -webkit-transform: translate3d(0, 1px, 0);
    -moz-transform: translate3d(0, 1px, 0);
}
.report-row-right-inner {
    @include flex;
    @include items-center;
    position: relative;
    z-index: 2;
    padding-right: 1.5rem;
}

.report-title {
    @include opteo-foreground;
    @include fw-600;
    white-space: nowrap;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: -0.0225rem;
}

.spinner-container,
.report-status-icon {
    height: 24px;
    width: 24px;
    @include flex-center;
}
</style>
