<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
        @score-history-button-clicked="emit('score-history-button-clicked')"
    >
        <template #content>
            <div v-for="copy in getSectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>
            <Spacer height="1rem" />

            <Text as="h6" weight="600">Product Overview</Text>
            <Spacer height="2rem" />
            <div class="product-overview-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div v-for="item in productOverviewItems" :key="item.id" class="item">
                    <Text as="span" size="f-8">{{ item.name }}</Text>
                    <Spacer height="0.375rem" />
                    <div class="statistic">
                        <Text as="h5" weight="600" style="letter-spacing: -0.06rem">{{
                            item.value
                        }}</Text>
                        <Spacer width="0.75rem" />
                        <Delta :delta="item.delta" :reverse="item.deltaReverse" :threshold="0" />
                    </div>
                </div>
            </div>

            <Spacer height="2.5rem" />

            <!-- Product Status Breakdown -->
            <div class="flex items-center justify-between">
                <Text as="h6" weight="600">Product Status Breakdown</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="openCampaignBreakdown"
                    :loading="breakdownLoading"
                    color="white"
                    size="small"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />

            <div class="data-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <DonutChart
                    :showKeys="true"
                    :items="donutChartItems"
                    :metric="{ label: 'Cost', dataType: 'percentage' }"
                />

                <oTable
                    class="table"
                    :headers="productStatusTableHeaders"
                    :items="productStatusTableItems"
                    :fixed-layout="true"
                    no-shadow
                    :border-radius="0"
                    responsive-mode="columns"
                    responsive-breakpoint="767px"
                >
                    <template #column.count="cellData">
                        <NumberVue :value="cellData.value" />
                    </template>
                    <template #column.cost="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value" />
                    </template>
                    <template #column.conversions="cellData">
                        <NumberVue :value="cellData.value" />
                    </template>
                    <template #column.conversionsValue="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value ?? 0" />
                    </template>
                    <template #column.cpa="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value" />
                    </template>
                    <template #column.roas="cellData">
                        <Roas :value="cellData.value ?? 0" />
                    </template>
                </oTable>
            </div>
        </template>
    </ScorecardSection>

    <!-- Breakdown Panel -->
    <BreakdownPanel
        @breakdown-requested="emit('breakdown-requested')"
        :showPanel="showProductStatusBreakdown"
        :closePanel="closeCampaignBreakdown"
        :loading="breakdownLoading"
        title="Product Status Breakdown"
        :width="1176"
    >
        <template #title>
            <ColorTag color="blue" title="Campaign Breakdown" />
        </template>
        <template #content>
            <div
                v-for="([campaignName, items], index) in productStatusBreakdown.tableItems"
                :key="campaignName"
                :class="[
                    'campaign-breakdown-container',
                    {
                        'mb-36': index !== productStatusBreakdown.tableItems.length - 1,
                    },
                ]"
            >
                <BreakdownTableHeader
                    :entity-label="campaignName"
                    :currency="domainCurrency"
                    :cost="productStatusBreakdown.totalCostPerCampaignName?.[campaignName] ?? 0"
                />
                <Spacer height="2.25rem" />
                <oTable
                    :headers="productStatusBreakdownTableHeaders"
                    :items="items"
                    :fixed-layout="true"
                    :border-radius="20"
                    :class="{ 'pdf-shadow-fix': isPdf }"
                    order-by="cost"
                    order="DESC"
                    :per-page="4"
                    :persist-height="true"
                >
                    <template #header.productName>
                        <div class="header-cell">Product Name</div>
                    </template>
                    <template #column.productName="cellData">
                        <div class="column-overlay">
                            <Tooltip
                                :content="cellData.value.length >= 56 ? cellData.value : ''"
                                :offset="[0, 8]"
                                :max-width="700"
                                placement="top-start"
                            >
                                <EntityPill
                                    type="product"
                                    cursor="default"
                                    :content="cellData.value"
                                    :tooltip="false"
                                />
                            </Tooltip>
                        </div>
                    </template>
                    <template #column.status="cellData">
                        {{ cellData.value }}
                    </template>
                    <template #column.cost="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value" />
                    </template>
                    <template #column.conversions="cellData">
                        <NumberVue :value="cellData.value" />
                    </template>
                    <template #column.cpa="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value" />
                    </template>
                    <template #column.conversionsValue="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value" />
                    </template>
                    <template #column.roas="cellData">
                        <Roas :value="cellData.value" />
                    </template>
                </oTable>
            </div>
            <!-- Show More Button -->
            <div class="panel-button-container">
                <Tooltip
                    :content="showMoreButtonDisabled ? 'Showing all campaigns above' : ''"
                    :offset="[0, 4]"
                >
                    <oButton
                        color="white"
                        size="medium"
                        @clicked="showMoreTables"
                        :disabled="showMoreButtonDisabled"
                    >
                        Show More
                    </oButton>
                </Tooltip>
            </div>
        </template>
        <template #footer>
            <oButton
                @clicked="
                    downloadCsv({
                        dataSet: 'product_performance_breakdown',
                        items: productStatusBreakdown.csvItems,
                        columnHeaders: [
                            'Type',
                            'Campaign',
                            'Product Name',
                            'Product Id',
                            'Status',
                            'Impressions (30 days)',
                            'Cost',
                            'Conversions',
                            'Conversions Value',
                            'CPA',
                            'ROAS',
                        ],
                    })
                "
                size="extra-large"
            >
                Download CSV
            </oButton>
        </template>
    </BreakdownPanel>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from 'vue'

import { Gads, Scorecard } from '@opteo/types'
import useMediaQuery from '@/composition/global/useMediaQuery'
import {
    calculateCpa,
    calculateRoas,
    fromMicros,
    sortCollectionBy,
    downloadCsv,
} from '@/lib/globalUtils'

import {
    Text,
    Spacer,
    oTable,
    Money,
    Percent,
    Roas,
    Number as NumberVue,
    useMoney,
    useRoas,
    useNumber,
    DonutChart,
    ColorTag,
    Delta,
    oButton,
    Tooltip,
    EntityPill,
    Label,
} from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import SectionHidden from './SectionHidden.vue'
import ScorecardDonut from './ScorecardDonut.vue'
import ScorecardSection from './ScorecardSection.vue'
import { formatDonutChartData, TextOptions, getSectionCopy, formatBreakdown } from './utils'
import BreakdownTableHeader from './BreakdownTableHeader.vue'
import BreakdownPanel from './BreakdownPanel.vue'

export default defineComponent({
    name: 'ProductPerformance',
    props: {
        score: {
            type: Number,
        },
        isLoading: {
            type: Boolean,
        },
        invalid: {
            type: Boolean,
        },
        domainCurrency: {
            type: String,
            required: true,
        },
        domainName: {
            type: String,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object as PropType<Scorecard.ProductPerformanceScoreDetails>,
        },
        breakdown: {
            type: Object as PropType<Scorecard.ProductPerformanceScoreBreakdown>,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
        breakdownLoading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Text,
        Spacer,
        DonutChart,
        oTable,
        Money,
        Percent,
        Roas,
        NumberVue,
        SectionHidden,
        ScorecardDonut,
        ScorecardSection,
        Skeleton,
        oButton,
        BreakdownPanel,
        Delta,
        ColorTag,
        EntityPill,
        Tooltip,
        Label,
        BreakdownTableHeader,
    },
    emits: ['score-history-button-clicked', 'breakdown-requested'],
    setup(props, { emit }) {
        const isPdf = props.mode === 'pdf'
        const { aboveiPadLandscape } = useMediaQuery()

        const productOverviewItems = computed(
            (): {
                id: string
                name: string
                value: string
                delta: number
                deltaReverse: boolean
            }[] => {
                const { details } = props

                if (!details) return []

                const {
                    totalProductsWithImpressions,
                    totalCost,
                    totalConversions,
                    totalConversionsValue,
                    avgCpa,
                    avgRoas,
                } = details.totals

                const getPercentageChange = (
                    metric: number,
                    metricType: keyof Scorecard.ProductMetricsTotals
                ) => {
                    const previousMetric = details.previous30DaysTotals[metricType]

                    return (metric - previousMetric) / previousMetric
                }

                const performanceSpecificHeaders = props.isUsingCpa
                    ? [
                          {
                              id: 'itemThree',
                              name: 'Total Conversions',
                              value: useNumber({ value: totalConversions }).displayValue.value,
                              delta: getPercentageChange(totalConversions, 'totalConversions'),
                              deltaReverse: false,
                          },
                          {
                              id: 'itemFour',
                              name: 'Average CPA',
                              value: useMoney({ value: avgCpa, currency: props.domainCurrency })
                                  .displayValue.value,
                              delta: getPercentageChange(avgCpa, 'avgCpa'),
                              deltaReverse: true,
                          },
                      ]
                    : [
                          {
                              id: 'itemFive',
                              name: 'Total Value',
                              value: useMoney({
                                  value: totalConversionsValue,
                                  currency: props.domainCurrency,
                              }).displayValue.value,
                              delta: getPercentageChange(
                                  totalConversionsValue,
                                  'totalConversionsValue'
                              ),
                              deltaReverse: false,
                          },
                          {
                              id: 'itemSix',
                              name: 'Average ROAS',
                              value: useRoas({ value: avgRoas }).displayValue.value,
                              delta: getPercentageChange(avgRoas, 'avgRoas'),
                              deltaReverse: false,
                          },
                      ]

                return [
                    {
                        id: 'itemOne',
                        name: 'Products With Impr.',
                        value: useNumber({ value: totalProductsWithImpressions, compact: true })
                            .displayValue.value,
                        delta: getPercentageChange(
                            totalProductsWithImpressions,
                            'totalProductsWithImpressions'
                        ),
                        deltaReverse: false,
                    },
                    {
                        id: 'itemTwo',
                        name: 'Total Cost',
                        value: useMoney({ value: totalCost, currency: props.domainCurrency })
                            .displayValue.value,
                        delta: getPercentageChange(totalCost, 'totalCost'),
                        deltaReverse: true,
                    },
                    ...performanceSpecificHeaders,
                ]
            }
        )

        // Product Status
        const StatusCopy = {
            [Scorecard.ProductStatus.zeroImpressions]: 'Zero Impr.',
            [Scorecard.ProductStatus.learning]: 'Learning',
            [Scorecard.ProductStatus.topConverters]: 'Top Converters',
            [Scorecard.ProductStatus.converters]: 'Converters',
            [Scorecard.ProductStatus.nonConverters]: 'Non-converters',
            [Scorecard.ProductStatus.poorPerformers]: 'Poor Performers',
        } as const satisfies Record<Scorecard.ProductStatus, string>

        const statuses = Object.values(Scorecard.ProductStatus)

        const getProductMetricsByStatus = (details: Scorecard.ProductPerformanceScoreDetails) => {
            const { zeroImpressions, learning, cpaModeData, roasModeData } = details

            const productMetricsByStatus = {
                zeroImpressions,
                learning,
                ...(props.isUsingCpa ? cpaModeData : roasModeData),
            }

            return { productMetricsByStatus }
        }

        const donutChartItems = computed(() => {
            const { details } = props

            if (!details) return []

            const { totalCost } = details.totals

            const { productMetricsByStatus } = getProductMetricsByStatus(details)

            return statuses.map(status => {
                const { cost } = productMetricsByStatus[status]

                const costPercent = cost / totalCost

                return { y: costPercent, label: StatusCopy[status] }
            })
        })

        const productStatusTableHeaders = computed(
            (): {
                key: string
                text: string
                width: number
            }[] => {
                const performanceSpecificHeaders = props.isUsingCpa
                    ? [
                          { key: 'conversions', text: 'Conv.', width: 80, noPadding: true },
                          { key: 'cpa', text: 'CPA', width: 80, noPadding: true },
                      ]
                    : [
                          { key: 'conversionsValue', text: 'Value', width: 80, noPadding: true },
                          { key: 'roas', text: 'ROAS', width: 80, noPadding: true },
                      ]

                return [
                    { key: 'status', text: 'Status', width: 152 },
                    { key: 'count', text: 'Count', width: 64, noPadding: true },
                    { key: 'cost', text: 'Cost', width: 84, noPadding: true },
                    ...performanceSpecificHeaders,
                ]
            }
        )

        const productStatusTableItems = computed(() => {
            const { details } = props

            if (!details) return []

            const formatProductMetricsByStatus = (
                productMetricsByStatus: Scorecard.ProductMetricsByStatus,
                status: Scorecard.ProductStatus
            ) => {
                const formattedStatus = StatusCopy[status]

                const { cost, conversions, conversionsValue } = productMetricsByStatus

                const cpa = calculateCpa({ cost, conversions })
                const roas = calculateRoas({ cost, conversionsValue })

                return {
                    ...productMetricsByStatus,
                    status: formattedStatus,
                    cpa,
                    roas,
                }
            }

            const { productMetricsByStatus } = getProductMetricsByStatus(details)

            return statuses.map(status =>
                formatProductMetricsByStatus(productMetricsByStatus[status], status)
            )
        })

        // Breakdown Panel
        const showProductStatusBreakdown = ref(false)
        const openCampaignBreakdown = () => {
            showProductStatusBreakdown.value = true
        }
        const closeCampaignBreakdown = () => {
            showProductStatusBreakdown.value = false
            resetLimitTables()
        }

        const productStatusBreakdownTableHeaders = computed(
            (): {
                key: string
                text: string
                width?: number
                sortable: boolean
                noPadding?: boolean
            }[] => {
                const performanceSpecificHeaders = props.isUsingCpa
                    ? [
                          {
                              key: 'conversions',
                              text: 'Conv.',
                              width: 120,
                              sortable: true,
                          },
                          { key: 'cpa', text: 'CPA', width: 120, sortable: true },
                      ]
                    : [
                          {
                              key: 'conversionsValue',
                              text: 'Value',
                              width: 120,
                              sortable: true,
                          },
                          { key: 'roas', text: 'ROAS', width: 120, sortable: true },
                      ]

                return [
                    {
                        key: 'productName',
                        text: 'Product Name',
                        sortable: true,
                        noPadding: true,
                    },
                    { key: 'status', text: 'Status', width: 148, sortable: true },
                    { key: 'cost', text: 'Cost', width: 120, sortable: true },
                    ...performanceSpecificHeaders,
                ]
            }
        )

        const productStatusBreakdown = computed(() => {
            const { breakdown } = props

            if (!breakdown) {
                return {
                    tableItems: [],
                    csvItems: [],
                }
            }

            const { totalCostPerCampaign, zeroImpressions, learning, cpaModeData, roasModeData } =
                breakdown

            const relevantModeProducts = Object.values(
                props.isUsingCpa ? cpaModeData : roasModeData
            )

            const jointProducts = [zeroImpressions, learning, ...relevantModeProducts].flat()
            const campaignsByCostDesc = Object.entries(totalCostPerCampaign)
                .sort(([, costA], [, costB]) => costB - costA)
                .map(([campaignResourceName]) => campaignResourceName)

            const totalCostPerCampaignName: Record<string, number> = {}

            const productBreakdown =
                // Group by campaign, sorting by campaign cost descending
                sortCollectionBy({
                    collectionToSort: jointProducts,
                    sortingValue: product => product.campaignResourceName,
                    arrayToSortBy: campaignsByCostDesc,
                })
                    // Then, do all the calculations and formatting
                    .map(product => {
                        const {
                            campaignResourceName,
                            campaignName,
                            advertisingChannelType,
                            costMicros,
                            conversions,
                            conversionsValue,
                            impressions,
                            productName,
                            productId,
                            status: productStatus,
                        } = product

                        if (!totalCostPerCampaignName[campaignName]) {
                            totalCostPerCampaignName[campaignName] = fromMicros(
                                totalCostPerCampaign[campaignResourceName]
                            )
                        }

                        const formattedAdvertisingChannelType =
                            advertisingChannelType ===
                            Gads.enums.AdvertisingChannelType.PERFORMANCE_MAX
                                ? 'PMax'
                                : 'Shopping'

                        const cost = fromMicros(costMicros)
                        const cpa = calculateCpa({ cost, conversions })
                        const roas = calculateRoas({ cost, conversionsValue })

                        return {
                            type: formattedAdvertisingChannelType,
                            campaign: campaignName,
                            productName,
                            status: StatusCopy[productStatus],
                            cost,
                            conversions,
                            conversionsValue,
                            cpa,
                            roas,
                            // Anything below this is only used for the CSV download
                            'Impressions (30 days)': impressions,
                            productId,
                        }
                    })

            const tableItems = formatBreakdown(productBreakdown).slice(0, limitTables.value)

            return {
                tableItems,
                totalCostPerCampaignName,
                csvItems: productBreakdown,
            }
        })

        // Show more button
        let limitTables = ref(8)
        function showMoreTables() {
            limitTables.value += 8
        }
        function resetLimitTables() {
            limitTables.value = 8
        }
        const showMoreButtonDisabled = computed(() => {
            return limitTables.value >= productStatusBreakdown.tableItems?.length
        })

        const textOptions = computed<TextOptions>(() => {
            return {
                0: [
                    `A good score shows that your budget is being allocated to high performing products with a good return on investment, as well as limiting wasted spend on low performers. It also shows that most of your product inventory is collecting enough impressions to give each product a fair opportunity to perform effectively.`,
                ],
            }
        })

        const _getSectionCopy = computed((): string[] => {
            return getSectionCopy(textOptions.value, props.score ?? 0) ?? []
        })

        return {
            sectionType: Scorecard.SectionTypes.productPerformance,
            sectionName: Scorecard.SectionNames.productPerformance,
            textOptions,
            formatDonutChartData,
            getSectionCopy: _getSectionCopy,
            isPdf,
            aboveiPadLandscape,

            productOverviewItems,
            donutChartItems,
            productStatusTableHeaders,
            productStatusTableItems,
            // Breakdown Panel
            showProductStatusBreakdown,
            productStatusBreakdownTableHeaders,
            productStatusBreakdown,
            openCampaignBreakdown,
            closeCampaignBreakdown,
            downloadCsv,
            emit,
            showMoreTables,
            resetLimitTables,
            showMoreButtonDisabled,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaign-breakdown-container {
    @include container;
    @include br-28;
    @include pa-36;
}

.product-overview-container {
    @include container;
    @include grid;
    grid-template-columns: 1fr 1fr;
    @include br-20;
    @include w-100;
}
.product-overview-container .item {
    @include ph-24;
    @include pv-20;
    border-bottom: 1px solid #f7f7f9;
}
.product-overview-container .item:nth-child(odd) {
    border-right: 1px solid #f7f7f9;
}
.product-overview-container .item:nth-last-child(-n + 2) {
    border-bottom: none;
}
.product-overview-container .item:last-child {
    border-bottom: none;
}
.product-overview-container .statistic {
    @include flex;
    @include items-center;
}

.data-container {
    @include container;
    @include br-20;
    overflow: hidden;

    & .table {
        border-top: 1px solid;
        @include opteo-border;
        margin-top: 32px;
    }
}
.header-cell {
    @include pl-24;
}
.column-overlay {
    max-width: 100%;
    overflow: hidden;
    height: 4.5rem;
    @include flex;
    @include items-center;
    @include pl-24;
}
.column-overlay::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}

:deep(.improvement-note) {
    @include br-20;
}

.panel-button-container {
    @include w-100;
    @include flex-center;
    @include pt-36;
}

@media screen and (max-width: $mq-767-max) {
    .product-overview-container {
        @include block;
    }
    .product-overview-container .item,
    .product-overview-container .item:nth-last-child(-n + 2) {
        @include ph-24;
        @include pv-20;
        border-bottom: 1px solid #f7f7f9;
    }
    .product-overview-container .item:last-child {
        border-bottom: none;
    }
    .product-overview-container .item:nth-child(odd) {
        border-right: unset;
    }

    .header-cell,
    .column-overlay {
        padding-left: unset;
        height: unset;
    }
    .column-overlay::after {
        display: none;
    }
    :deep(.tooltip) {
        width: 100%;
    }
}
</style>
