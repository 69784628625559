<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillList :items="entityPillList" :scrollToRight="true" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    Consider replacing this <b>Broad</b> match keyword with a <b>Phrase</b> match
                    keyword. <b>Phrase</b> match keywords ensure that your ads are targeted more
                    closely to your audience. Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text>
                    to replace this keyword in line with the suggestion below.
                </Text>
                <Spacer height="1rem" />
                <Text as="p">
                    If using this broad keyword is a strategic move, feel free to dismiss this task.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/903022-replace-broad-keyword">
                Learn more about the benefits of replacing broad match keywords.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <EntityTransform
                title="Keyword Replacement"
                :entity-before="body.keyword_text"
                entity-before-type="pause"
                :entity-after="'“' + replacementKeyword + '”'"
                entity-after-type="replacement-keyword"
            />
        </ImprovementContent>

        <ImprovementContent>
            <RelatedSearchTerms :items="body.searches" :keyword="body.keyword_text" />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import {
    EntityTransform,
    Money,
    oTable,
    Text,
    EntityPillList,
    Spacer,
    oInput,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import RelatedSearchTerms from '@/components/improvement/shared-components/RelatedSearchTerms.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useRemoveBroadKeyword } from '@/composition/improvement/types/useRemoveBroadKeyword'

export default {
    name: 'RemoveBroadKeywords',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        EntityPillList,
        Money,
        oTable,
        Text,
        Spacer,
        oInput,
        RelatedSearchTerms,
        EntityTransform,
    },
    setup() {
        return { ...useRemoveBroadKeyword(), ...useMediaQuery() }
    },
}
</script>
