<template>
    <div class="serp">
        <!-- Headline -->
        <Text v-if="title" as="h3" size="f-4" weight="600">
            {{ title }}
        </Text>
        <Spacer v-if="title" height="2rem" />
        <div :style="{ height: imageLoaded ? 'auto' : '600px' }" class="serp-container">
            <img :src="imageUrl" @load="onImageLoaded" v-bind="$attrs" />
            <div v-show="!imageLoaded" class="spinner-container">
                <Spinner />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import { Text, Spacer, Spinner } from '@opteo/components-next'

export default defineComponent({
    components: {
        Text,
        Spacer,
        Spinner,
    },
    inheritAttrs: false,
    props: {
        title: {
            type: String,
            default: 'Search Results Preview',
        },
        imageUrl: {
            type: String,
            default: '',
        },
    },
    setup() {
        const imageLoaded = ref(false)
        function onImageLoaded() {
            imageLoaded.value = true
        }
        return { onImageLoaded, imageLoaded }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.serp-container {
    height: 600px;
    overflow: hidden;
    @include container;
    @include relative;
    @include br-24;
}
.serp h2 {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.serp-container img {
    max-width: 100%;
    display: block;
}

.spinner-container {
    @include flex-center;
    width: 100%;
    height: 600px;
    @include z-10;
    @include bg-opteo-white;
    @include relative;
}
@media screen and (min-width: 0px) and (max-width: 320px) {
    .serp-container img {
        max-width: 388px;
    }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
    .serp-container img {
        max-width: 464px;
    }
}
@media screen and (min-width: 376px) and (max-width: 479px) {
    .serp-container img {
        max-width: 520px;
    }
}
@media screen and (min-width: 480px) and (max-width: 600px) {
    .serp-container img {
        max-width: 640px;
    }
}
</style>
