<template>
    <div
        v-if="layout === 'default' || showInFullScreen"
        :class="[
            'page-header',
            { 'fullscreen-view': showInFullScreen },
            { 'no-margin': noMargin },
            { 'middle-content-empty': !$slots['middle-content'] },
            { 'right-content-empty': !$slots['right-content'] },
            { full: $slots['middle-content'] && $slots['right-content'] },
        ]"
    >
        <div class="left">
            <Avatar
                v-if="showAvatar && accountName"
                :color="accountColour"
                :width="40"
                :height="40"
                :show-account-type="true"
                class="avatar"
                :platform="accountPlatform"
            >
                {{ accountInitials }}
            </Avatar>
            <Skeleton v-if="showAvatar && !accountName" :width="40" :height="40" class="avatar" />

            <div :class="['title-slot-container', { 'end-content-empty': !$slots['end-content'] }]">
                <h1 class="page-title">{{ pageTitle }}</h1>
                <slot v-if="$slots['title-content']" name="title-content" />
                <slot
                    v-if="$slots['end-content'] && !aboveMobile"
                    name="end-content"
                    class="end-content-mobile"
                />
            </div>
        </div>

        <div v-if="$slots['middle-content']" class="middle-slot-container">
            <slot name="middle-content" />
        </div>

        <div
            v-if="$slots['right-content'] || ($slots['end-content'] && aboveMobile)"
            :class="['right-slot-container']"
        >
            <slot name="right-content" />
            <slot
                v-if="$slots['end-content'] && aboveMobile"
                name="end-content"
                class="end-content-desktop"
            />
        </div>
        <div v-if="showBottomBorder" class="page-title-bottom-border" />
    </div>
</template>

<script lang="ts">
import { computed } from 'vue'
import { Account } from '@opteo/types'
import { layout } from '@/composition/layouts/useLayouts'
import Skeleton from '@/components/util/Skeleton.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { Avatar } from '@opteo/components-next'
import { useAccountList } from '@/composition/user/useAccountList'
import { useRouter } from 'vue-router'
import { getAccountInitials } from '@/composition/account/useAccount'

export default {
    name: 'PageHeader',
    components: {
        Avatar,
        Skeleton,
    },
    props: {
        pageTitle: {
            type: String,
            required: true,
        },
        noMargin: {
            type: Boolean,
            default: false,
            required: false,
        },
        showInFullScreen: {
            type: Boolean,
            default: false,
        },
        showAvatar: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { slots }) {
        const router = useRouter()

        const { currentRoute } = router

        const accountId = computed(
            () => currentRoute.value.params.accountId as Account.ID | undefined
        )

        const { getAccountInfo } = useAccountList()

        const accountInfo = computed(() =>
            accountId.value ? getAccountInfo(accountId.value) : null
        )

        const accountName = computed(() => accountInfo.value?.name ?? '')
        const accountColour = computed(() => accountInfo.value?.color ?? '')
        const accountInitials = computed(() => getAccountInitials(accountName.value))
        const accountPlatform = computed(() => accountInfo.value?.platform)

        const { aboveMobile } = useMediaQuery()

        const showBottomBorder = computed(
            () =>
                (!slots['middle-content'] && !slots['right-content'] && !aboveMobile.value) ||
                (slots['middle-content'] && slots['right-content'] && !aboveMobile.value)
        )

        return {
            layout,
            accountName,
            accountColour,
            accountInitials,
            aboveMobile,
            showBottomBorder,
            accountPlatform,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.avatar {
    @include mr-28;
}

.page-header {
    width: 100%;
    @include ph-16;
    @include mb-32;
    @include block;
}

.title-slot-container {
    position: relative;
    margin-top: 96px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.title-slot-container.end-content-empty {
    display: block;
    justify-content: normal;
}
.left {
    @include flex;
    @include items-center;
}
.page-title {
    @include opteo-foreground;
    @include f-2;
    @include no-select;
    line-height: 2.75rem;
}
.middle-slot-container {
    @include flex-center;
    @include mt-24;
    @include pt-24;
    border-top: 1px solid;
    @include opteo-border;
}
.right-slot-container {
    @include flex-center;
    @include mt-24;
    @include pv-24;
    border-top: 1px solid;
    @include opteo-border;
}
.page-header.middle-content-empty:not(.right-content-empty) .right-slot-container {
    // @include pb-24;
    border-bottom: 1px solid;
    @include opteo-border;
}
.page-header.right-content-empty:not(.middle-content-empty) .middle-slot-container {
    @include pb-24;
    border-bottom: 1px solid;
    @include opteo-border;
}

.page-header .page-title-bottom-border {
    @include mt-24;
    border-bottom: 1px solid;
    @include opteo-border;
}
.page-header.full .page-title-bottom-border {
    @include mt-0;
}

.page-header.no-margin .title-slot-container {
    @include mt-32;
}
.page-header.no-margin .avatar {
    @include mt-32;
    @include mr-28;
}

@media screen and (min-width: $mq-768-min) {
    .page-header {
        @include grid;
        grid-template-columns: 1fr auto;
        margin-top: 10.5rem;
        @include mb-64;
        @include ph-48;
    }

    .page-header.no-margin {
        margin-top: 4.5rem;
    }
    .title-slot-container,
    .avatar,
    .page-header.no-margin .title-slot-container {
        margin: 0;
    }
    .page-header.no-margin .avatar {
        margin: 0;
        @include mr-28;
    }
    .middle-slot-container {
        grid-column: 1 / -1;
    }
    .right-slot-container {
        grid-row: 1;
        grid-column: 2 / -1;
        margin: 0;
        padding: 0;
        border: none !important;
    }
}
@media screen and (min-width: $mq-1024-max) {
    .page-title {
        white-space: nowrap;
    }
    .title-slot-container.end-content-empty {
        @include flex;
        @include items-center;
    }
    .page-header {
        grid-template-columns: 1fr auto 1fr;
        margin-top: 11.25rem;
        margin-bottom: 4rem;
    }
    .page-header.middle-content-empty {
        grid-template-columns: 1fr 1fr;
    }

    .middle-slot-container {
        grid-column: auto;
        margin: 0;
        padding: 0;
        border: none !important;
    }
    .right-slot-container {
        grid-row: auto;
        grid-column: auto;
        justify-self: end;
        padding: 0 !important;
    }
}

@media screen and (min-width: $mq-1280-min) {
    .page-header,
    .page-header .left,
    .page-header .right-slot-container {
        height: 2.75rem;
    }
    .page-title {
        font-size: 2.25rem;
        font-weight: 600;
        letter-spacing: -0.075rem;
    }
    .page-header.fullscreen-view {
        padding: 0;
        max-width: 65rem;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: $mq-1441-min) and (max-width: $mq-1800-max) {
    .page-header {
        max-width: 77rem;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
    .page-header.fullscreen-view {
        max-width: 65rem;
    }
}
@media screen and (min-width: $mq-1801-min) {
    .page-header {
        max-width: 89rem;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
}
</style>
