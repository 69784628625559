<template>
    <!-- Active Experiments -->
    <div v-if="activeExperiments?.length" class="experiments-grid">
        <transition-group name="experiments-grid">
            <div
                v-for="experiment in activeExperiments"
                :key="experiment.optExperimentId"
                :class="{ 'new-experiment-shadow': isNewExperiment(experiment.optExperimentId) }"
            >
                <ExperimentCard
                    :experiment="experiment"
                    :is-active="true"
                    @click="latestCreatedExperimentId = ''"
                />
            </div>
        </transition-group>
    </div>
    <!-- Empty State -->
    <div v-else class="empty-state-container">
        <div class="inner-container">
            <img
                src="@/assets/img/bidding-experiments/active-experiments-empty-state.png"
                style="height: 158px"
            />
            <Spacer height="2rem" />
            <Text as="h5" size="f-5" weight="600" align="center">No Active Experiments</Text>
            <Spacer height="1rem" />
            <Text as="p" size="f-8" align="center">
                Your active experiments will be displayed here. Head to the
                <b>Create Experiment</b> tab to create a new experiment.
            </Text>
        </div>
    </div>
</template>

<script lang="ts">
// Imports
import { defineComponent, ref } from 'vue'
import { Routes } from '@/router/routes'
import { useExperiments } from '@/composition/toolkit/experiments/useExperiments'

// Components
import {
    Spinner,
    oTable,
    Money,
    Number,
    Tabs,
    oButton,
    EntityPillList,
    Spacer,
    ColorTag,
    Roas,
    Text,
} from '@opteo/components-next'

// Local Components
import Skeleton from '@/components/util/Skeleton.vue'
import ProgressBar from '@/components/global/ProgressBar.vue'
import ExperimentCard from '@/components/biddingExperiments/ExperimentCard.vue'

// Export
export default defineComponent({
    name: 'ActiveExperiments',
    components: {
        Skeleton,
        Spinner,
        oTable,
        Number,
        Money,
        Tabs,
        oButton,
        EntityPillList,
        Spacer,
        ColorTag,
        Roas,
        ProgressBar,
        ExperimentCard,
        Text,
    },
    setup() {
        const viewExperiment = ref(false)
        return {
            ...useExperiments(),
            Routes,
            viewExperiment,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.experiments-grid {
    @include flex;
    flex-direction: column;
    gap: 1rem;
}

.empty-state-container {
    height: calc(100vh - 17.25rem);
    min-height: 30rem;
    @include flex-center;
    flex-direction: column;
}
.empty-state-container .inner-container {
    @include flex-center;
    flex-direction: column;
    max-width: 22.75rem;
}

.new-experiment-shadow {
    @include opteo-focus;
    outline: none;
    box-shadow: $opteo-shadow-focus-ring;
    border-radius: 1.25rem;
}

// Transitions
.experiments-grid-move,
.experiments-grid-enter-active,
.experiments-grid-leave-active {
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.experiments-grid-enter-from,
.experiments-grid-leave-to {
    opacity: 0;
}

// Ensure leaving items are taken out of layout flow
// so moving animations can be calculated correctly
.experiments-grid-leave-active {
    position: absolute;
    width: 100%;
}
</style>
