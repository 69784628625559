<template>
    <div class="flex items-center h-100">
        <oButton
            color="white"
            :circle="true"
            size="small"
            id="search-button"
            @clicked="focusSearch"
        >
            <template #icon>
                <svg width="14" height="14">
                    <path
                        d="M10.0317379 8.6175243l3.503796 3.503796-1.4142136 1.4142136-3.503796-3.503796C7.7314831 10.642436 6.6574983 11 5.5 11 2.4624339 11 0 8.5375661 0 5.5S2.4624339 0 5.5 0 11 2.4624339 11 5.5c0 1.1574983-.357564 2.2314831-.9682621 3.1175243zM5.5 9C7.4329966 9 9 7.4329966 9 5.5S7.4329966 2 5.5 2 2 3.5670034 2 5.5 3.5670034 9 5.5 9z"
                        fill="#006dff"
                        fill-rule="evenodd"
                    />
                </svg>
            </template>
        </oButton>
        <input
            ref="clientListSearch"
            v-model="searchedDomain"
            class="domain-list-search bg-transparent"
            type="text"
            placeholder="Search Accounts"
            autocomplete="off"
            autofocus
            @keydown.enter.prevent="gotoFirstSearchedDomain"
            data-cy="domain-search"
        />
    </div>
</template>

<script lang="ts">
import { ref, onMounted } from 'vue'

import { useAccountList } from '@/composition/user/useAccountList'
import { oButton } from '@opteo/components-next'
import { delay } from '@/lib/globalUtils'

export default {
    name: 'AccountCenterSearchBar',
    setup() {
        const clientListSearch = ref()
        const { gotoFirstSearchedDomain, searchedDomain } = useAccountList()

        async function focusSearch() {
            await delay(250)
            clientListSearch.value.focus()
        }

        return { searchedDomain, gotoFirstSearchedDomain, clientListSearch, focusSearch }
    },
    components: { oButton },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.domain-list-search {
    box-shadow: inset 0 0 0 0 transparent !important;
    @include opteo-foreground;
    @include f-7;
    @include h-100;
    margin-left: 0.75rem;
    outline: none;
    border: none;
    padding: 0;
    transform: translateY(-1px);
}
.domain-list-search::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    @include opteo-foreground;
    opacity: 0.16; /* Firefox */
}
.focus-search-button {
    @include opteo-background-l2;
    @include flex;
    @include items-center;
    @include justify-center;
    @include br-999;
    @include pointer;
    width: 2.25rem !important;
    height: 2.25rem !important;
}

@media (min-width: $mq-768-min) {
    .domain-list-search {
        width: 300px;
    }
}
</style>
