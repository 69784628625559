<template>
    <svg
        :class="['featured-keyword', { animated }]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 452"
        fill="none"
    >
        <rect width="640" height="452" fill="white" />
        <rect x="75" y="388" width="106" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="388" width="70" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="400" width="95" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="400" width="82" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="412" width="66" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="412" width="57" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <circle cx="47" cy="404" r="14" fill="#EBEBEB" />
        <circle cx="499" cy="404" r="14" fill="#EBEBEB" />
        <rect x="33" y="34" width="146" height="24" rx="12" fill="black" fill-opacity="0.08" />
        <g filter="url(#filter0_dd_2_4903)">
            <rect x="33" y="94" width="574.842" height="258" rx="8" fill="white" />
        </g>
        <g filter="url(#filter1_dd_2_4903)">
            <rect x="33" y="94" width="576" height="260" rx="8" fill="white" />
        </g>
        <mask
            id="mask0_2_4903"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="33"
            y="94"
            width="576"
            height="260"
        >
            <rect x="33" y="94" width="576" height="260" rx="8" fill="white" />
        </mask>
        <g mask="url(#mask0_2_4903)">
            <rect
                class="metric metric-one"
                x="176"
                y="116"
                width="96"
                height="22"
                rx="11"
                fill="#006DFF"
                fill-opacity="0.1"
            />
            <rect
                class="metric metric-one"
                x="186"
                y="123"
                width="56"
                height="8"
                rx="4"
                fill="#004FE1"
                fill-opacity="0.2"
            />
            <rect
                class="metric metric-one"
                x="246"
                y="123"
                width="16"
                height="8"
                rx="4"
                fill="#004FE1"
            />
            <rect
                class="metric metric-two"
                x="280"
                y="116"
                width="88"
                height="22"
                rx="11"
                fill="#00A861"
                fill-opacity="0.1"
            />
            <rect
                class="metric metric-two"
                x="290"
                y="123"
                width="48"
                height="8"
                rx="4"
                fill="#008A43"
                fill-opacity="0.2"
            />
            <rect
                class="metric metric-two"
                x="342"
                y="123"
                width="16"
                height="8"
                rx="4"
                fill="#008A43"
            />
            <rect
                class="metric metric-three"
                x="376"
                y="116"
                width="90"
                height="22"
                rx="11"
                fill="#00A861"
                fill-opacity="0.1"
            />
            <rect
                class="metric metric-three"
                x="385.598"
                y="123"
                width="50"
                height="8"
                rx="4"
                fill="#008A43"
                fill-opacity="0.2"
            />
            <rect
                class="metric metric-three"
                x="439.598"
                y="123"
                width="16"
                height="8"
                rx="4"
                fill="#008A43"
            />
            <rect
                class="metric metric-four"
                x="72"
                y="310"
                width="94"
                height="22"
                rx="11"
                fill="#006DFF"
                fill-opacity="0.1"
            />
            <rect
                class="metric metric-four"
                x="82"
                y="317"
                width="54"
                height="8"
                rx="4"
                fill="#004FE1"
                fill-opacity="0.2"
            />
            <rect
                class="metric metric-four"
                x="140"
                y="317"
                width="16"
                height="8"
                rx="4"
                fill="#004FE1"
            />
            <rect
                class="metric metric-five"
                x="175"
                y="310"
                width="92"
                height="22"
                rx="11"
                fill="#006DFF"
                fill-opacity="0.1"
            />
            <rect
                class="metric metric-five"
                x="185"
                y="317"
                width="52"
                height="8"
                rx="4"
                fill="#004FE1"
                fill-opacity="0.2"
            />
            <rect
                class="metric metric-five"
                x="241"
                y="317"
                width="16"
                height="8"
                rx="4"
                fill="#004FE1"
            />
            <rect
                class="metric metric-six"
                x="279"
                y="310"
                width="88"
                height="22"
                rx="11"
                fill="#00A861"
                fill-opacity="0.1"
            />
            <rect
                class="metric metric-six"
                x="289"
                y="317"
                width="48"
                height="8"
                rx="4"
                fill="#008A43"
                fill-opacity="0.2"
            />
            <rect
                class="metric metric-six"
                x="341"
                y="317"
                width="16"
                height="8"
                rx="4"
                fill="#008A43"
            />
            <rect
                class="metric metric-seven"
                x="375"
                y="310"
                width="96"
                height="22"
                rx="11"
                fill="#006DFF"
                fill-opacity="0.1"
            />
            <rect
                class="metric metric-seven"
                x="385"
                y="317"
                width="56"
                height="8"
                rx="4"
                fill="#004FE1"
                fill-opacity="0.2"
            />
            <rect
                class="metric metric-seven"
                x="445"
                y="317"
                width="16"
                height="8"
                rx="4"
                fill="#004FE1"
            />
            <rect
                class="metric metric-eight"
                x="480"
                y="310"
                width="90"
                height="22"
                rx="11"
                fill="#00A861"
                fill-opacity="0.1"
            />
            <rect
                class="metric metric-eight"
                x="489.598"
                y="317"
                width="50"
                height="8"
                rx="4"
                fill="#008A43"
                fill-opacity="0.2"
            />
            <rect
                class="metric metric-eight"
                x="543.598"
                y="317"
                width="16"
                height="8"
                rx="4"
                fill="#008A43"
            />
            <g class="keyword" filter="url(#filter2_dd_2_4903)">
                <rect
                    x="209.126"
                    y="202.713"
                    width="223.748"
                    height="42.5731"
                    rx="21.2866"
                    fill="white"
                />
            </g>
            <path
                class="keyword"
                d="M235.961 231.602C240.135 231.602 243.52 228.198 243.52 223.999C243.52 219.801 240.135 216.397 235.961 216.397C231.786 216.397 228.402 219.801 228.402 223.999C228.402 228.198 231.786 231.602 235.961 231.602ZM235.961 233.122C230.951 233.122 226.89 229.038 226.89 223.999C226.89 218.961 230.951 214.876 235.961 214.876C240.97 214.876 245.032 218.961 245.032 223.999C245.032 229.038 240.97 233.122 235.961 233.122Z"
                fill="#00A861"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M237.61 227.8H239.441L236.446 223.543L239.42 220.254H237.504L234.499 223.671V220.254H233.038V227.8H234.499V225.704L235.43 224.671L237.61 227.8Z"
                fill="#00A861"
            />
            <rect class="keyword" x="253" y="217" width="160" height="14" rx="7" fill="#EBEBEB" />
        </g>
        <defs>
            <filter
                id="filter0_dd_2_4903"
                x="31"
                y="93"
                width="578.842"
                height="263"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_2_4903"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2_4903"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_2_4903"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_2_4903"
                    result="effect2_dropShadow_2_4903"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_2_4903"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_dd_2_4903"
                x="30"
                y="92"
                width="582"
                height="266"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1.5" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.054902 0 0 0 0 0.0705882 0 0 0 0 0.121569 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2_4903"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect2_dropShadow_2_4903"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0567206 0 0 0 0 0.0697228 0 0 0 0 0.121732 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_2_4903"
                    result="effect2_dropShadow_2_4903"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_2_4903"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_dd_2_4903"
                x="207.126"
                y="201.713"
                width="227.748"
                height="47.5732"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_2_4903"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2_4903"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_2_4903"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_2_4903"
                    result="effect2_dropShadow_2_4903"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_2_4903"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'

export default {
    props: {
        animated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        onMounted(() => {
            if (!props.animated) {
                return
            }

            const timeline = anime.timeline()
            timeline
                .add({
                    targets: '.featured-keyword .keyword',
                    opacity: [0, 1],
                    delay: anime.stagger(100, { start: 200 }),
                    duration: 500,
                    easing: 'easeInCubic',
                })
                .add(
                    {
                        targets:
                            '.featured-keyword .metric-one, .featured-keyword .metric-two, .featured-keyword .metric-three',
                        opacity: [0, 1],
                        translateY: [6, 0],
                        delay: anime.stagger(100),
                        duration: 500,
                        easing: 'easeOutCubic',
                    },
                    '-=800'
                )
                .add(
                    {
                        targets:
                            '.featured-keyword .metric-four, .featured-keyword .metric-five, .featured-keyword .metric-six, .featured-keyword .metric-seven, .featured-keyword .metric-eight',
                        opacity: [0, 1],
                        translateY: [6, 0],
                        delay: anime.stagger(100),
                        duration: 500,
                        easing: 'easeOutCubic',
                    },
                    '-=800'
                )
        })
    },
}
</script>

<style lang="scss" scoped>
svg {
    width: 100%;
    transform: scale(1);
    transform-origin: center;
}

svg.animated {
    .keyword,
    .metric {
        opacity: 0;
        will-change: opacity;
    }
    .metric {
        transform: translateY(6px);
    }
}
</style>
