<template>
    <div class="steps">
        <div v-for="(step, index) of steps" class="step-wrapper">
            <div
                :key="step.key"
                :tabindex="0"
                @click="stepIndex > index ? stepClicked(index) : null"
                :data-index="index"
                :data-step="stepIndex"
                :class="[
                    'step-link',
                    { active: stepIndex === index },
                    { first: index === 0 },
                    { last: steps.length === index + 1 },
                    { disabled: stepIndex < index },
                ]"
            >
                <div class="step-link-contents">
                    <div class="step-index">
                        <!-- Completed Check -->
                        <svg
                            v-if="stepIndex > index"
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_811_3368)">
                                <path
                                    d="M1 4.5L3 6.5L8 1.5"
                                    stroke="#CACAD3"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_811_3368">
                                    <rect
                                        width="9"
                                        height="7"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <span v-else class="step-index-number">{{ index + 1 }}</span>
                    </div>
                    <span class="step-text">{{ step.name }}</span>
                </div>
            </div>
            <div v-if="steps.length != index + 1" class="step-divider" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

type Props = {
    steps: any[]
    stepIndex: number
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update', index: number): void
}>()

const stepsLength = computed(() => props.steps.length)

function stepClicked(index: number) {
    emit('update', index)
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.steps {
    box-shadow: $opteo-shadow;
    display: grid;
    grid-template-columns: repeat(v-bind(stepsLength), 1fr);
    width: 100%;
    @include f-9;
    @include fw-500;
    @include br-999;
    height: 44px;
    @include relative;
}
.step-wrapper {
    @include flex;
    @include items-center;
    height: 100%;
}

.step-link-contents {
    height: 100%;
    width: 100%;
    @include flex;
    @include items-center;
    padding: 0 1.125rem;
}
.first .step-link-contents {
    padding-left: 1.25rem;
}
.last .step-link-contents {
    padding-right: 1.25rem;
}

.step-link {
    @include opteo-foreground;
    @include h-100;
    @include flex;
    @include justify-center;
    @include items-center;
    @include relative;
    outline: none;
    cursor: pointer;
    border-radius: 0.25rem;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    outline: none;
    user-select: none;
}
.step-link:focus {
    box-shadow: $opteo-shadow-focus-ring;
    z-index: 2;
}
.step-link:active {
    outline: none;
    box-shadow: $opteo-shadow-focus-ring;
    z-index: 2;
}
.step-link.first {
    border-radius: 2.5rem 0.5rem 0.5rem 2.5rem;
}
.step-link.last {
    border-radius: 0.5rem 2.5rem 2.5rem 0.5rem;
}
.step-link.first.last {
    border-radius: 2.5rem;
}
.step-link.disabled {
    cursor: default;
}

.step-link.disabled:focus {
    box-shadow: none;
}

// Step Index
.step-index {
    box-shadow: $opteo-shadow;
    width: 1.4375rem;
    height: 1.4375rem;
    @include flex-center;
    border-radius: 999px;
    font-size: 0.6875rem;
    transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    outline: none;
    letter-spacing: -0.02rem;
}
.step-link.active :deep(.step-index-number) {
    color: $opteo-dark-blue;
}
.step-link :deep(.step-index) {
    margin-right: 0.5rem;
}
.step-link:not(.active) :deep(.step-index-number) {
    opacity: 0.32;
}

// Step Text
.step-link:not(.active) .step-text {
    opacity: 0.32;
}
.step-link.active .step-text {
    color: $opteo-dark-blue;
}

.step-text {
    // transform: translateY(-1px);
    transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    outline: none;
}

// Step Divider
.step-divider {
    height: 100%;
    width: 1px;
    background: $opteo-light-gray;
    position: relative;
}
.step-link:last-child .step-divider {
    display: none;
}

@media (min-width: $mq-0-min) {
    .steps {
        @include flex;
        @include items-center;
        @include w-auto;
    }
}
</style>
