<template>
    <div class="report-slides-skeleton">
        <div class="preview-sidebar-skeleton">
            <InsightCard>
                <Text as="h3" class="mb3" size="f-6" weight="600">Insights</Text>
                <Text class="mb-24" size="f-9">
                    Unique insights are generated for each of your reports. Using your Google Ads
                    account data, they surface information that clients and managers might find
                    interesting. Use insights to supplement your own research and explain the why
                    behind account performance.
                </Text>
                <Text as="h3" class="mb3" size="f-6" weight="600">Advice</Text>
                <Text size="f-9">
                    Smart writing tips based on hundreds of customer interviews conducted by our
                    research team. Write useful, clear and engaging reports that clients and
                    managers are excited to read.
                </Text>
            </InsightCard>
        </div>
        <div class="slide-area-skeleton">
            <div class="slide-skeleton">
                <div class="flex justify-end">
                    <Skeleton height="2rem" width="12rem" />
                </div>
                <!-- <div class="slide-footer-skeleton">
                    <div>
                        <Skeleton height="0.75rem" />
                        <Spacer height="0.25rem" />
                        <Skeleton height="0.75rem" />
                        <Spacer height="0.25rem" />
                        <Skeleton height="0.75rem" />
                    </div>
                    <div>
                        <Skeleton height="0.75rem" />
                        <Spacer height="0.25rem" />
                        <Skeleton height="0.75rem" />
                        <Spacer height="0.25rem" />
                        <Skeleton height="0.75rem" />
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div v-for="i in 3" :key="i" class="report-slides-skeleton">
        <div class="preview-sidebar-skeleton">
            <InsightCard>
                <Tabs
                    :links="[
                        {
                            key: 'insight',
                            name: 'Insights',
                            count: 0,
                            disabled: true,
                        },
                        {
                            key: 'generic',
                            name: 'Advice',
                            count: 0,
                            disabled: true,
                        },
                    ]"
                    :router="false"
                />
                <Spacer />
                <Skeleton height="0.75rem" width="14rem" />
                <Spacer height="0.5rem" />
                <Skeleton height="0.75rem" width="14rem" />
                <Spacer height="0.5rem" />
                <Skeleton height="0.75rem" width="80%" />
                <Spacer height="0.5rem" />
                <Skeleton height="0.75rem" />
            </InsightCard>
        </div>
        <div class="slide-area-skeleton">
            <div class="slide-skeleton">
                <Skeleton height="2rem" width="14rem" />
                <!-- <div class="slide-footer-skeleton">
                    <div>
                        <Skeleton height="0.75rem" />
                        <Spacer height="0.25rem" />
                        <Skeleton height="0.75rem" />
                        <Spacer height="0.25rem" />
                        <Skeleton height="0.75rem" />
                    </div>
                    <div>
                        <Skeleton height="0.75rem" />
                        <Spacer height="0.25rem" />
                        <Skeleton height="0.75rem" />
                        <Spacer height="0.25rem" />
                        <Skeleton height="0.75rem" />
                    </div>
                </div> -->
            </div>
        </div>
        <!-- <ReportGridItemContainer v-for="i in 12" :key="i">
            <Skeleton />
        </ReportGridItemContainer> -->
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Skeleton from '@/components/util/Skeleton.vue'
import { Text, Tabs, Spacer } from '@opteo/components-next'

import InsightCard from '@/components/Reports/InsightCard.vue'

export default defineComponent({
    components: {
        Skeleton,
        Text,
        Tabs,
        InsightCard,
        Spacer,
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.report-slides-skeleton {
    display: grid;
    grid-template-columns: 342px 1fr;
    align-items: flex-start;
}
.preview-sidebar-skeleton {
    position: relative;
    height: 100%;
    flex: 0 0 342px;
    @include ph-32;
    border-right: 1px solid;
    @include opteo-border;
}
.slide-area-skeleton {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    @include pt-48;
}
.slide-skeleton {
    @include container;
    position: relative;
    width: 842px;
    height: 595px;
    border-radius: 0.75rem;
    // @include mb-32;
    @include pa-44;
}
.slide-footer-skeleton {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @include pa-44;
    display: flex;
    justify-content: space-between;
}
</style>
