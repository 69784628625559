<template>
    <div
        :class="{
            'full-width': fullWidth,
            'ad-container-width': adWidth,
            'table-width': tableWidth,
            'serp-container-width': serpWidth,
            'no-margin': noMargin,
        }"
        class="improvement-content"
    >
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'
export default {
    name: 'ImprovementContent',
    props: {
        noMargin: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        adWidth: {
            type: Boolean,
            default: false,
        },
        tableWidth: {
            type: Boolean,
            default: false,
        },
        serpWidth: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        onMounted(() => {
            anime.timeline().add({
                targets: ['.improvement-content'],
                opacity: [0, 1],
                translateY: [16, 0],
                translateZ: 0,
                easing: 'cubicBezier(0.19, 1, 0.22, 1)',
                duration: 1200,
                delay: 300,
            })
        })
    },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Base

.improvement-content {
    position: relative;
    opacity: 0;
    margin-bottom: 3rem;
    width: 100%;
    max-width: 37.75rem;
    margin-left: auto;
    margin-right: auto;
}
.improvement-content.no-margin {
    margin-bottom: 0;
}
.improvement-content.wide {
    max-width: 100%;
}

.improvement-content.full-width {
    padding: 0 3rem;
    max-width: 85rem;
}

.improvement-content.ad-container-width {
    padding: 0;
    width: 100%;
    max-width: 42.5rem;
    margin: 0 auto;
}

.improvement-content.table-width {
    padding: 0;
    width: 100%;
    max-width: 48.75rem;
    margin: 0 auto;
}

.improvement-content.serp-container-width {
    padding: 0;
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
}

// Responsive
@media screen and (min-width: 0px) and (max-width: 320px) {
    .improvement-content,
    .improvement-content.full-width,
    .improvement-content.ad-container-width,
    .improvement-content.serp-container-width {
        @include ph-16;
        position: relative;
    }
    .improvement-content .f5 {
        font-size: 13px;
    }
    .improvement-content.table-width {
        @include ph-16;
    }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
    .improvement-content,
    .improvement-content.full-width,
    .improvement-content.ad-container-width,
    .improvement-content.serp-container-width {
        @include ph-16;
        position: relative;
    }
    .improvement-content .f5 {
        font-size: 13px;
    }
    .improvement-content.table-width {
        @include ph-16;
    }
}
@media screen and (min-width: 376px) and (max-width: 480px) {
    .improvement-content,
    .improvement-content.full-width,
    .improvement-content.ad-container-width,
    .improvement-content.serp-container-width {
        @include ph-16;
        position: relative;
    }
    .improvement-content .f5 {
        font-size: 13px;
    }
    .improvement-content.table-width {
        @include ph-16;
    }
}
@media screen and (min-width: 481px) and (max-width: 636px) {
    .improvement-content,
    .improvement-content.full-width,
    .improvement-content.ad-container-width,
    .improvement-content.serp-container-width {
        @include ph-16;
        position: relative;
        max-width: 39.75rem;
    }
    .improvement-content .f5 {
        font-size: 13px;
    }
    .improvement-content.table-width {
        @include ph-16;
    }
}
@media screen and (min-width: 637px) and (max-width: 844px) {
    .improvement-content.table-width {
        @include ph-16;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
@media screen and (min-width: 1025px) and (max-width: 1279px) {
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
}
@media screen and (min-width: 1441px) and (max-width: 1600px) {
}
@media screen and (min-width: 1601px) and (max-width: 1920px) {
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
}
@media screen and (min-width: 2561px) and (max-width: 9999px) {
}
</style>
