import { ref, computed, watch } from 'vue'
import { Reports } from '@opteo/types'

import { showSnackbar } from '@opteo/components-next'
import { Endpoint, useAPI, authRequest } from '@/composition/api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'

export const useReportTemplateSelector = (
    props: { templateId?: number },
    emitTemplateId: (templateId: number) => void
) => {
    const { domainInfo, domainId } = useDomain()

    /*
        Get available categories & slides that are selectable in user-defined templates.
        The "BaseTemplate" is the frame from which templates are created/edited.
        It could in theory be set as constant in this repo, but today it comes from luckymetrics.
    */
    const baseTemplateName = ref('')
    const { data: baseTemplateCategories } = useAPI<Reports.Core.BaseReportTemplate>(
        Endpoint.GetBaseReportTemplate
    )

    const flattenedDefaultSlides = computed(() => {
        return (baseTemplateCategories.value ?? []).map(category => category.slides).flat()
    })

    /*
        Get list of templates.
    */
    const { data: userTemplates, mutate: refreshUserTemplates } = useAPI<
        Reports.Core.ReportTemplate[]
    >(Endpoint.GetUserReportTemplates, {
        waitFor: () => props.templateId,
    })

    const templates = computed(() =>
        (userTemplates.value ?? [])
            .map(template => {
                const newTemplate = {
                    ...template,
                    categories: baseTemplateCategories.value?.map(category => {
                        return {
                            ...category,
                            active: !!category.slides.find(slide =>
                                template.slide_types.includes(slide.slide_id)
                            ),
                            enabledSlidesCount: category.slides.filter(slide =>
                                template.slide_types.includes(slide.slide_id)
                            ).length,
                        }
                    }),
                }
                return newTemplate
            })
            .filter(template => template.template_name !== 'create')
    )

    const loading = computed(
        () => !userTemplates.value || !baseTemplateCategories.value || !props.templateId
    )

    /*
        Template selection
    */
    const selectedTemplateId = ref(props.templateId)
    const selectTemplate = (templateId: number) => {
        selectedTemplateId.value = templateId
        emitTemplateId(templateId)
    }

    watch(userTemplates, () => {
        /*
            If selectedTemplateId no longer exists, set a reasonable backup. Happens when:
            - The user deletes the currently selected template.
            - The templateId in reportPreferences refers to a deleted template.
        */
        if (!templates.value.find(t => t.template_id === selectedTemplateId.value)) {
            selectTemplate(templates.value[0].template_id)
        }
    })

    const slidesToScroll = 2
    const initialIndex = computed(() =>
        Math.floor(
            templates.value.findIndex(
                template => selectedTemplateId.value === template.template_id
            ) / slidesToScroll
        )
    )

    /*
        Modal/Popout Actions
        - Create
        - Edit
        - Delete
    */

    const createPopoutOpen = ref(false)
    const createTemplate = async (slides: Reports.Core.BaseReportSlide[], templateName: string) => {
        createPopoutOpen.value = false
        const { slideIds, slideCategories } = generateSaveableTemplate(slides)

        await authRequest(Endpoint.CreateReportTemplate, {
            body: {
                domain_id: domainId.value,
                template_name: templateName,
                slide_types: slideIds,
                slide_categories: slideCategories,
            },
        })

        await refreshUserTemplates()

        selectTemplate(templates.value.slice(-1)[0].template_id)

        showSnackbar(`Your template "${templateName}" has been created.`)
    }

    const editTemplate = async (
        templateId: number,
        slides: Reports.Core.BaseReportSlide[],
        templateName: string
    ) => {
        const { slideIds, slideCategories } = generateSaveableTemplate(slides)

        await authRequest(Endpoint.EditReportTemplate, {
            body: {
                template_id: templateId,
                domain_id: domainId.value,
                template_name: templateName,
                slide_types: slideIds,
                slide_categories: slideCategories,
            },
        })

        await refreshUserTemplates()

        showSnackbar(`Your template "${templateName}" has been updated.`)
    }

    const deleteTemplate = async (templateId: number, templateName: string) => {
        refreshUserTemplates(() => templates.value.filter(t => t.template_id !== templateId))

        await authRequest(Endpoint.DeleteReportTemplate, {
            body: {
                domain_id: domainId.value,
                template_id: templateId,
            },
        })
        await refreshUserTemplates()

        showSnackbar(`Your template "${templateName}" has been deleted.`)
    }

    /*
        Utils
    */

    const generateSaveableTemplate = (
        slides: Reports.Core.BaseReportSlide[]
    ): { slideIds: number[]; slideCategories: number[] } => {
        const slideIds = slides.filter(slide => slide.selected).map(slide => slide.slide_id)
        return {
            slideIds,
            slideCategories: (baseTemplateCategories.value ?? [])
                ?.filter(category => {
                    return slideIds.some(slideId => category.slide_ids.includes(slideId))
                })
                .map(category => category.category_id),
        }
    }

    return {
        loading,
        domainInfo,
        baseTemplateCategories,
        flattenedDefaultSlides,
        baseTemplateName,
        templates,
        deleteTemplate,
        selectedTemplateId,
        selectTemplate,
        initialIndex,
        createPopoutOpen,
        editTemplate,
        createTemplate,
        slidesToScroll,
    }
}
