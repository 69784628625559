<template>
    <div class="toggle-switch-container">
        <div
            @click="clicked"
            :class="[{ 'slider-active': active, disabled: disabled }, 'opteo-slider']"
            :style="{
                backgroundColor: active ? color : '',
            }"
        ></div>
    </div>
</template>

<script lang="ts">
export default {
    name: 'ToggleSwitch',
    props: {
        active: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        color: {
            type: String,
            required: false,
            default: '#006dff',
        },
    },
    emits: ['clicked'],
    setup(props, { emit }) {
        const clicked = () => {
            if (props.disabled) {
                return
            }
            emit('clicked')
        }
        return { clicked }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.toggle-switch-container {
    @include relative;
    height: 100%;
    width: 3rem;
}

.opteo-slider {
    @include absolute;
    @include pointer;
    top: calc(50% - 14px);
    left: calc(50% - 24px);
    background-color: #e0e0e5;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 80px;
    height: 28px;
    width: 48px;
}

.opteo-slider:before {
    @include absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 50%;
}

.opteo-slider.disabled {
    cursor: default;
    background-color: #f0f0f0;
}

.stop-animations .opteo-slider {
    -webkit-transition: 0s;
    transition: 0s;
}

.stop-animations .opteo-slider:before {
    -webkit-transition: 0s;
    transition: 0s;
}

.slider-active.opteo-slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
</style>
