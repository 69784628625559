<template>
    <OpteoLogo :width="44" :height="44" class="opteo-logo" />
    <section class="new-payment-method">
        <div class="payment-method-panel">
            <CreditCardForm @on-success="onSuccess" :updating-card="false" />
        </div>
        <div class="subscription-overview-panel">
            <LoginContainer :width="468" :title="subscriptionTitle">
                <template v-slot:content>
                    <div class="subscription-overview">
                        <Text as="span">
                            Add your preferred payment method to get started. Your card
                            <b>will not be charged</b> until the end of your trial. If you are
                            unhappy with Opteo for any reason, you can cancel your subscription at
                            any time. If you have any questions, don’t hesitate to contact our
                            <Text color="blue" as="span" @click="showNewMessage()">support</Text>
                            team.
                        </Text>
                    </div>
                    <Spacer height="1.5rem" />
                    <div class="subscription-info">
                        <div v-if="monthlyFee">
                            <div class="subscription-info-row">
                                <Text as="span">Trial End Date:</Text>
                                <Text as="span" weight="600">{{ trialEndDate }}</Text>
                            </div>
                            <Spacer height="0.5rem" />
                            <div class="subscription-info-row">
                                <Text as="span">Monthly Price:</Text>
                                <Text as="span" weight="600">
                                    <Money :value="monthlyFee" currency="USD" />
                                </Text>
                            </div>
                            <Spacer height="0.5rem" />
                            <div class="subscription-info-row">
                                <Text as="span">Due Today:</Text>
                                <Text as="span" weight="600" color="green">
                                    <Money :value="0" currency="USD" />
                                </Text>
                            </div>
                        </div>
                        <div class="subscription-info-loading" v-else>
                            <Spinner />
                        </div>
                    </div>
                </template>
            </LoginContainer>
        </div>
        <oButton color="white" size="small" class="logout-button" @clicked="logout">Logout</oButton>
    </section>
</template>

<script lang="ts">
import { Money, Spacer, Text, Link, oButton, OpteoLogo, Spinner } from '@opteo/components-next'
import LoginContainer from '@/components/global/LoginContainer.vue'
import CreditCardForm from '@/components/billing/CreditCardForm.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import { useNewPaymentMethod } from '@/composition/user/useNewPaymentMethod'

export default {
    name: 'NewPaymentMethod',
    components: {
        LoginContainer,
        CreditCardForm,
        Money,
        Spacer,
        Text,
        Link,
        oButton,
        OpteoLogo,
        Spinner,
        Skeleton,
    },
    computed: {
        subscriptionTitle() {
            if (window.innerWidth > 480) {
                return 'Subscription Overview'
            } else return 'Subscription'
        },
    },
    setup() {
        return useNewPaymentMethod()
    },
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.new-payment-method {
    @include flex;
    @include items-start;
    @include justify-center;
}
.new-payment-method .subscription-overview-panel {
    @include ml-48;
    @include flex;
    @include items-center;
    height: 100vh;
    position: sticky;
    top: 0;
}
.new-payment-method .subscription-info,
.new-payment-method .subscription-overview {
    @include f-8;
    @include container;
    @include pa-24;
    @include lh-150;
}
.new-payment-method .subscription-info-row {
    @include flex;
    @include items-center;
    @include justify-between;
}
.new-payment-method .subscription-info-loading {
    height: 4.9375rem;
    @include flex;
    @include items-center;
    @include justify-center;
}

@media screen and (max-width: $mq-1279-max) {
    .new-payment-method {
        @include block;
    }
    .new-payment-method .payment-method-panel .lo-section {
        @include mb-0;
    }
    .new-payment-method .subscription-overview-panel .lo-section {
        @include mt-40;
    }
    .new-payment-method .subscription-overview-panel {
        @include ml-0;
        height: auto;
    }
}
// External elements

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}
.logout-button {
    position: fixed !important;
    bottom: 1.625rem;
    left: 1.625rem;
}
@media screen and (max-width: $mq-767-max) {
    .opteo-logo,
    .logout-button {
        display: none !important;
    }
}
</style>
