<template>
    <!-- noSpend -->
    <div
        v-if="noSpend"
        class="data-container"
        :class="[{ 'zero-spend': noSpend }, { 'pdf-shadow-fix': isPdf }]"
    >
        <div class="description">
            <Text as="p" size="f-8" align="center">
                Performance Max campaigns have zero spend.
            </Text>
        </div>
        <div class="donut-chart">
            <DonutChart
                :showKeys="false"
                :removeInteractivity="true"
                :items="[
                    { y: 0.2633, label: 'Shopping' },
                    { y: 0.4482, label: 'Display' },
                    { y: 0.0627, label: 'Video' },
                    { y: 0.2258, label: 'Other' },
                ]"
                :metric="{ label: 'Cost', dataType: 'percentage' }"
            />
        </div>
        <oTable
            class="table"
            :headers="tableHeaders"
            :items="aboveMobile ? [{}, {}, {}, {}] : [{}]"
            :fixed-layout="true"
            no-shadow
            :border-radius="20"
            responsive-mode="columns"
            responsive-breakpoint="767px"
        >
            <template #column.channel="cellData">
                <Skeleton :height="16" :width="60" />
            </template>
            <template #column.cost="cellData">
                <Skeleton :height="16" :width="56" />
            </template>
            <template #column.conversions="cellData">
                <Skeleton :height="16" :width="48" />
            </template>
            <template #column.conversionsValue="cellData">
                <Skeleton :height="16" :width="56" />
            </template>
            <template #column.roas="cellData">
                <Skeleton :height="16" :width="56" />
            </template>
            <template #column.cpa="cellData">
                <Skeleton :height="16" :width="48" />
            </template>
        </oTable>
    </div>

    <!-- hasSpend -->
    <div v-else class="data-container" :class="{ 'pdf-shadow-fix': isPdf }">
        <div class="donut-chart">
            <DonutChart
                :showKeys="true"
                :items="donutChartItems"
                :metric="{ label: 'Cost', dataType: 'percentage' }"
            />
        </div>
        <oTable
            class="table"
            :headers="tableHeaders"
            :items="tableItems"
            :fixed-layout="true"
            no-shadow
            :border-radius="20"
            responsive-mode="columns"
            responsive-breakpoint="767px"
        >
            <template #column.cost="cellData">
                <Money :currency="domainCurrency" :value="cellData.value" />
            </template>
            <template #column.conversions="cellData">
                <NumberVue :value="cellData.value" />
            </template>
            <template #column.conversionsValue="cellData">
                <Money :currency="domainCurrency" :value="cellData.value ?? 0" />
            </template>
            <template #column.roas="cellData">
                <Roas :value="cellData.value ?? 0" />
            </template>
            <template #column.cpa="cellData">
                <Money :currency="domainCurrency" :value="cellData.value ?? 0" />
            </template>
        </oTable>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Skeleton from '@/components/util/Skeleton.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import {
    Text,
    Spacer,
    oTable,
    DonutChart,
    Money,
    Number as NumberVue,
    Roas,
} from '@opteo/components-next'

export default defineComponent({
    name: 'ChannelDistribution',
    props: {
        tableHeaders: {
            type: Array,
            required: false,
        },
        tableItems: {
            type: Array,
            required: false,
        },
        donutChartItems: {
            type: Array,
            required: false,
        },
        isPdf: {
            type: Boolean,
            required: true,
        },
        noSpend: {
            type: Boolean,
            required: true,
        },
        domainCurrency: {
            type: String,
            required: false,
        },
    },
    components: {
        Text,
        Spacer,
        DonutChart,
        oTable,
        Skeleton,
        Money,
        NumberVue,
        Roas,
    },
    setup() {
        const { aboveMobile } = useMediaQuery()

        return {
            aboveMobile,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.data-container {
    @include container;
    @include br-20;
    @include relative;
    & .donut-chart {
        border-bottom: 1px solid;
        @include opteo-border;
        @include pb-32;
    }
}
.data-container.zero-spend .donut-chart {
    opacity: 0.1;
}
.data-container.zero-spend :deep(thead) {
    opacity: 0.2;
}
.data-container.zero-spend .table {
    opacity: 0.3;
}
.data-container.zero-spend .description {
    @include container;
    @include ph-28;
    @include pv-20;
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    max-width: 22rem;
    z-index: 2;
}

@media screen and (max-width: $mq-480-max) {
    .data-container.zero-spend .description {
        max-width: 16rem;
    }
}
</style>
