<template>
    <!-- Spacer -->
    <Spacer width="1rem" height="0rem" />

    <!-- Settings Button -->
    <div v-if="account.accountId" class="settings-button-container">
        <oButton color="white" :circle="true" size="small" @clicked="settingsModalOpen = true">
            <template v-slot:icon>
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.135 6.784a2 2 0 0 1-1.23-2.969c.322-.536.225-.998-.094-1.316l-.31-.31c-.318-.318-.78-.415-1.316-.094A2 2 0 0 1 9.216.865C9.065.258 8.669 0 8.219 0h-.438c-.45 0-.845.258-.997.865a2 2 0 0 1-2.969 1.23c-.536-.322-.999-.225-1.317.093l-.31.31c-.318.318-.415.781-.093 1.317a2 2 0 0 1-1.23 2.969C.26 6.935 0 7.33 0 7.781v.438c0 .45.258.845.865.997a2 2 0 0 1 1.23 2.969c-.322.536-.225.998.094 1.316l.31.31c.319.319.782.415 1.316.094a2 2 0 0 1 2.969 1.23c.151.607.547.865.997.865h.438c.45 0 .845-.258.997-.865a2 2 0 0 1 2.969-1.23c.535.321.997.225 1.316-.094l.31-.31c.318-.318.415-.78.094-1.316a2 2 0 0 1 1.23-2.969c.607-.151.865-.547.865-.997v-.438c0-.451-.26-.846-.865-.997ZM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
                        fill="#E0E0E5"
                    />
                </svg>
            </template>
        </oButton>
    </div>
    <!-- 
        Quick settings Modal 
    -->
    <Modal v-model="settingsModalOpen" :noPadding="true" title="Settings">
        <template v-slot:content>
            <oForm @submitted="saveChanges" ref="settingsForm">
                <div class="domain-row-settings-form-section account">
                    <div class="current-account-container">
                        <Avatar :color="clientColor" :platform="account.platform" show-account-type>
                            {{ accountInitials }}
                        </Avatar>
                        <span :style="{ color: clientColor }" class="account-name">
                            {{ clientName }}
                        </span>
                        <div class="account-settings-colour-picker">
                            <Popout
                                v-model="colourPicker"
                                :externalToggleButton="true"
                                :trapFocus="true"
                                :offset="[0, 10]"
                                :zIndex="9999999999999"
                                placement="top"
                            >
                                <oButton
                                    color="white"
                                    :circle="true"
                                    size="small"
                                    @clicked="colourPicker = !colourPicker"
                                >
                                    <template v-slot:icon>
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.7 2.3C12.1.8 10.1 0 8 0S3.9.8 2.3 2.3C.8 3.9 0 5.9 0 8s.8 4.1 2.3 5.7C3.9 15.2 5.9 16 8 16s4.1-.8 5.7-2.3C15.2 12.1 16 10.1 16 8s-.8-4.1-2.3-5.7ZM2 8c0-1.6.6-3.1 1.8-4.2C5 2.7 6.4 2 8 2s3.1.6 4.2 1.8l-8.5 8.5C2.6 11.1 2 9.6 2 8Z"
                                                fill="#E0E0E5"
                                            />
                                        </svg>
                                    </template>
                                </oButton>
                                <template v-slot:content>
                                    <div class="colour-circle-container">
                                        <div
                                            v-for="color in accountColours"
                                            :key="color.name"
                                            :style="{ backgroundColor: color.hex }"
                                            class="colour-circle"
                                            @click="clientColor = color.hex"
                                        >
                                            <svg
                                                v-show="color.hex === clientColor"
                                                width="12"
                                                height="9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="m1.5 4.5 3 3 6-6"
                                                    stroke="#fff"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </template>
                            </Popout>
                        </div>
                    </div>
                    <Spacer height="1.5rem" />
                    <oInput
                        name="clientName"
                        v-model="clientName"
                        class="w-100"
                        type="text"
                        label="Account Name"
                        required
                        autofocus
                    />
                </div>
                <div class="domain-row-settings-form-section budget">
                    <AccountMonthlyBudget
                        ref="accountMonthlyBudget"
                        :account-id="account.accountId"
                        :currency-symbol="account.currencySymbol"
                        :currency-code="account.currencyCode"
                        :account-platform="account.platform"
                    />
                </div>
            </oForm>
        </template>
        <template v-slot:buttons>
            <oButton color="white" @clicked="settingsModalOpen = false">Cancel</oButton>
            <Spacer width="0.625rem" />
            <oButton :loading="loadingSettings" @clicked="saveChanges" color="blue"
                >Update Settings
            </oButton>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import {
    Number,
    Money,
    Percent,
    oInput,
    Spacer,
    CloseButton,
    oForm,
    oButton,
    Text,
    Modal,
    Label,
    Popout,
    Avatar,
    useShortKey,
} from '@opteo/components-next'
import { Domain, Account, Platform } from '@opteo/types'
import DomainListBudgetBar from '@/components/AccountCenter/DomainListBudgetBar.vue'
import AccountMonthlyBudget from '../accountsSettings/AccountMonthlyBudget.vue'

export default defineComponent({
    components: {
        Avatar,
        Money,
        Percent,
        Number,
        oInput,
        Spacer,
        CloseButton,
        oForm,
        oButton,
        DomainListBudgetBar,
        Text,
        Modal,
        Popout,
        Label,
        AccountMonthlyBudget,
    },
    props: {
        account: {
            type: Object as PropType<Account.Info>,
            required: true,
        },
        accountInitials: {
            type: String,
            required: true,
        },
        budgetBar: {
            type: Object as PropType<Domain.BudgetBar>,
            required: true,
        },
    },

    emits: ['client-updated'],
    setup(props, { emit }) {
        const colourPicker = ref(false)
        const settingsModalOpen = ref(false)
        const clientName = ref(props.account.name)
        const clientColor = ref(props.account.color)

        const accountMonthlyBudget = ref<typeof AccountMonthlyBudget>()

        const loadingSettings = ref(false)

        const saveChanges = async () => {
            loadingSettings.value = true
            emit('client-updated', clientName.value, clientColor.value)

            await accountMonthlyBudget.value?.updateAccountMonthlyBudget()

            loadingSettings.value = false
            settingsModalOpen.value = false
        }

        useShortKey({ keys: ['enter'], onPressCallBack: saveChanges })

        return {
            colourPicker,
            settingsModalOpen,
            clientName,
            accountColours: Account.Colours,
            clientColor,
            saveChanges,
            Platform,
            accountMonthlyBudget,
            loadingSettings,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.account-centre-row-budget-container {
    @include flex;
    @include items-center;
    height: 100%;
}

.settings-button-container {
    @include flex;
    @include items-center;
    height: 100%;
}

// Modal

.current-account-container {
    @include relative;
    @include container;
    @include br-16;
    @include flex;
    @include items-center;
    @include justify-center;
    @include pv-40;
    width: 100%;
}
.current-account-container .account-name {
    margin-left: 1rem;
    @include f-7;
    @include fw-500;
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.25rem;
}

.account-settings-colour-picker {
    position: absolute !important;
    top: 1rem;
    right: 1rem;
    width: 2.25rem;
    height: 2.25rem;
}

.colour-circle-container {
    width: 184px;
    @include flex;
    @include items-center;
    @include justify-center;
    @include pv-16;
    @include ph-12;
    outline: none;
}

.colour-circle {
    @include br-999;
    @include mh-4;
    @include flex;
    @include items-center;
    @include justify-center;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.domain-row-settings-form-section {
    @include pa-32;
}
.domain-row-settings-form-section.account {
    border-bottom: 1px solid $opteo-light-gray;
}
</style>
