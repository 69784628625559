<template>
    <router-link to="/">
        <OpteoLogo :width="44" :height="44" class="opteo-logo" />
    </router-link>
    <LoginContainer title="Reset Password">
        <template v-slot:info>
            <div v-if="showForm">
                Enter your email address and click the button below to reset your password. You may
                need to check your spam folder or search for mail sent by <b>support@opteo.com</b>.
            </div>
            <div v-else>
                An email with a password reset link has been sent to <b>{{ emailAddress }}</b
                >.
            </div>
        </template>
        <template v-slot:content>
            <oForm @submitted="sendResetLink" :validateOnSubmit="true">
                <div v-if="showForm">
                    <oInput
                        ref="userEmailInput"
                        :prefill="true"
                        class="w-100"
                        label="Email Address"
                        v-model="emailAddress"
                        type="email"
                        name="emailAddress"
                        placeholder="johnny@appleseed.com"
                        autocomplete="email"
                        required
                    />
                    <Spacer height="2rem" />
                    <oButton type="submit" :loading="loading" size="login">
                        Send Reset Link
                    </oButton>
                </div>
                <div v-else>
                    <router-link to="/login">
                        <oButton color="blue" size="login">Go to Login</oButton>
                    </router-link>
                </div>
            </oForm>
        </template>
        <template v-slot:links>
            <router-link to="/login" data-cy="login-link">
                Click here to return to the Login page.
            </router-link>
        </template>
    </LoginContainer>
</template>

<script lang="ts">
import LoginContainer from '@/components/global/LoginContainer.vue'
import { useResetPassword } from '@/composition/auth/useResetPassword'
import { OpteoLogo, oForm, oButton, oInput, Spacer } from '@opteo/components-next'

export default {
    name: 'Reset',
    components: { LoginContainer, OpteoLogo, oForm, oButton, oInput, Spacer },
    setup() {
        return useResetPassword()
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}
@media screen and (max-width: $mq-767-max) {
    .opteo-logo {
        display: none;
    }
}
</style>
