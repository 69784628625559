import { useIntercom } from '@/lib/intercom/useIntercom'
import { showSnackbar } from '@opteo/components-next'
import { Improvement } from '@opteo/types'
import { ref } from 'vue'
import { authRequest, Endpoint } from '../api/useAPI'
import { useDomain } from '../domain/useDomain'
import { useUser } from '../user/useUser'

export type DismissFeedbackImprovement = {
    improvementId: number
    improvementTitle: string
    improvementLocation: Improvement.AccountLocation[]
    improvementRecAction: Improvement.RecAction
}

const showFeedbackModal = ref(false)
const feedbackContent = ref('')
const dismissedImprovement = ref<DismissFeedbackImprovement>()

export function useDismissFeedbackModal() {
    const { showNewMessage } = useIntercom()
    const { domainId } = useDomain()
    const { userId, user } = useUser()

    const openDismissFeedbackModal = (dismissedImp: DismissFeedbackImprovement) => {
        dismissedImprovement.value = dismissedImp
        showFeedbackModal.value = true
    }

    const submitFeedback = ({ feedback }: { feedback: string }) => {
        if (!dismissedImprovement) {
            throw new Error('cannot submitFeedback unless the improvement is defined')
        }

        const impId = dismissedImprovement.value?.improvementId

        const apiCallParams = {
            domain_id: domainId.value,
            user_id: userId.value,
            improvement_id: impId,
            rec_action: dismissedImprovement.value?.improvementRecAction,
            reason: feedback,
            username: user.value?.profile.username,
        }

        showFeedbackModal.value = false

        authRequest(Endpoint.SaveDismissalFeedback, {
            body: apiCallParams,
        })

        showSnackbar({
            message: `Feedback received. Thanks for helping Opteo become a better tool!`,
            timeout: 3000,
        })
    }

    return {
        submitFeedback,
        feedbackContent,
        showNewMessage,
        showFeedbackModal,
        openDismissFeedbackModal,
        dismissedImprovement,
    }
}
