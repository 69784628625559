<template>
    <svg viewBox="0 0 48 48" width="24" height="24">
        <defs>
            <linearGradient
                id="d"
                x1="17.61"
                y1="33.39"
                x2="39.99"
                y2="14.61"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" />
                <stop offset=".1" stop-opacity=".8" />
                <stop offset="1" stop-opacity="0" />
            </linearGradient>
        </defs>
        <path
            d="M45.69 22.87 36 7a2.06 2.06 0 0 0-1.75-1H4.07a2.11 2.11 0 0 0-1.75 3.23l13 21.37a2.06 2.06 0 0 0 1.75 1h23.5a2.06 2.06 0 0 0 1.74-1l3.39-5.5a2.17 2.17 0 0 0-.01-2.23Z"
            :fill="color"
        />
        <path
            d="M45.69 25.13 36 41a2.06 2.06 0 0 1-1.75 1H4.07a2.11 2.11 0 0 1-1.75-3.23l13-21.37a2.06 2.06 0 0 1 1.75-1h23.5a2.06 2.06 0 0 1 1.74 1l3.39 5.5a2.17 2.17 0 0 1-.01 2.23Z"
            :fill="color"
            opacity=".5"
        />
        <path
            d="m45.82 24.86-.14.26-3.39 5.5a2.06 2.06 0 0 1-1.74 1H17.06a2.06 2.06 0 0 1-1.75-1l-4-6.6 4-6.61a2 2 0 0 1 1.75-1h23.49a2 2 0 0 1 1.74 1l3.39 5.48c0 .09.1.18.14.27a2.14 2.14 0 0 1 0 1.7Z"
            fill="url(#d)"
            opacity=".2"
        />
    </svg>
</template>

<script>
export default {
    name: 'Targets',
    props: {
        color: {
            type: String,
            default: '#E0E0E5',
            required: false,
        },
    },
}
</script>
