import { computed, ComputedRef, ref, Ref } from 'vue'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
    EntityPill,
} from '@/composition/improvement/useImprovement'
import { LocationBids, Improvement, Targets } from '@opteo/types'
import { buildMapImageUrl, mapFiles } from '@/composition/utils/useMaps'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { usePercent, useRoas } from '@opteo/components-next'

import mapLoaderSmall from '@/assets/img/map-loader-small.png'
import mapLoaderSmallPlaceholder from '@/assets/img/map-loader-small-placeholder.png'
import mapLoaderLarge from '@/assets/img/map-loader-large.png'
import mapLoaderLargePlaceholder from '@/assets/img/map-loader-large-placeholder.png'
import { useDomain } from '@/composition/domain/useDomain'
import sumBy from 'lodash-es/sumBy'
import { fromMicros } from '@/lib/globalUtils'
import round from 'lodash-es/round'

type Metrics = { [metric: string]: { value: number; copy?: string } }

type LocationTableItems =
    | {
          id: string
          location: string
          cpa: string
          avgCpa: string
          bidMod?: string
          roas?: never
          avgRoas?: never
      }
    | {
          id: string
          location: string
          roas: string
          avgRoas: string
          bidMod?: string
          cpa?: never
          avgCpa?: never
      }

interface UseAdjustLocationBids {
    isCpaMode: boolean
    mapLoaderSmall: string
    mapLoaderSmallPlaceholder: string
    mapLoaderLarge: string
    mapLoaderLargePlaceholder: string
    campaignGroupName: string
    campaignNames: EntityPill[]
    campaignCount: number
    singleCampaign: boolean
    trueLocationName: string
    shortLocationName: string
    locationTargetType: string
    locationTypeIsCountry: boolean
    hasExistingBidMod: boolean
    newBidIsPositive: boolean
    oldBidIsPositive: boolean
    newBidModValue: number
    newBidModifier: number
    oldBidModifier: number
    onBidUpdate: (inputBidMod: number) => void
    metrics: Metrics
    isLocationUnderperforming: boolean
    performanceDifference: number
    window: number
    locationTableHeaders: ComputedRef<{ key: string; text: string }[]>
    locationTableItems: ComputedRef<LocationTableItems[]>
    mapImageUrl?: string
    mapIsLoading: Ref<boolean>
    setMapIsLoadingTrue(): void
}

export function useAdjustLocationBids(): UseImprovement<UseAdjustLocationBids> {
    const { domainId } = useDomain()
    const { improvement, lastUpdated, title } = useImprovement<LocationBids.Body>()
    const { improvement_id: improvementId } = checkImprovement(improvement)

    const {
        rec_action,
        body: {
            performance_mode,
            window,
            campaigns,
            geometry,
            location: {
                information: {
                    name: locationName,
                    canonical_name: canonicalName,
                    bid_modifier: oldBidModValue,
                    country_code: countryCode,
                },
                geographic_view: { country_criterion_id: countryId },
                metrics: { all_conversions, all_conversions_value, cost_micros },
            },
            bid_generation: {
                new_bid_mod: newBidModValue,
                environment: { cpa: environmentCpaRaw, roas: environmentRoasRaw },
                entity: { cpa: locationCpaRaw, roas: locationRoasRaw },
            },
            campaign_group: { name: campaignGroupName },
            target_type: locationTargetTypeRaw,
        },
    } = checkImprovement(improvement)

    const isLocationExclusion = computed(() => rec_action == Improvement.RecAction.ExcludeLocation)
    const isCpaMode = !performance_mode || performance_mode === Targets.PerformanceMode.CPA

    let environmentPerformance: number
    let locationPerformance: number

    if (isCpaMode) {
        environmentPerformance = environmentCpaRaw ?? 0
        locationPerformance = all_conversions ? locationCpaRaw ?? 0 : Infinity
    } else {
        environmentPerformance = environmentRoasRaw ?? 0
        locationPerformance = all_conversions_value ? locationRoasRaw ?? 0 : 0
    }

    const campaignNames = (campaigns ?? []).map((campaign): EntityPill => {
        return { content: campaign.name, type: Improvement.LocationEntity.Campaign }
    })
    const campaignCount = campaigns.length
    const singleCampaign = campaignCount === 1

    const [locationNamePartOne, locationNamePartTwo] = canonicalName.split(',')
    const trueLocationName = locationNamePartTwo
        ? `${locationNamePartOne} (${locationNamePartTwo})`
        : locationNamePartOne

    const shortLocationName = locationNamePartOne

    let locationTargetType: string

    if (locationTargetTypeRaw === LocationBids.LocationTargetType.POSTAL_CODE) {
        if (countryId === 2840) {
            locationTargetType = 'zip code' // If country is USA
        } else {
            locationTargetType = 'postcode' // If country is civilised
        }
    } else {
        locationTargetType = locationTargetTypeRaw.toLowerCase()
    }

    const locationTypeIsCountry = locationTargetTypeRaw === LocationBids.LocationTargetType.COUNTRY

    const newBidIsPositive = newBidModValue > 1
    const oldBidIsPositive = oldBidModValue > 1
    const hasExistingBidMod = oldBidModValue !== 1

    // 1.2 => 0.2 (20%), 0.8 => -0.2 (-20%)
    const newBidModifier = newBidModValue - 1
    const oldBidModifier = oldBidModValue - 1

    const bidModToBeApplied = ref(newBidModValue)

    function onBidUpdate(inputBidMod: number) {
        bidModToBeApplied.value = inputBidMod
    }

    const locationCost = fromMicros(cost_micros)
    const totalCampaignCost = sumBy(campaigns ?? [], c => fromMicros(c.cost_micros ?? 0))
    const totalCampaignConversions = sumBy(campaigns ?? [], c => c.all_conversions ?? 0)
    const totalCampaignConversionsValue = sumBy(campaigns ?? [], c => c.all_conversions_value ?? 0)

    const costDifference = totalCampaignCost - locationCost
    const conversionsDifference = totalCampaignConversions - all_conversions
    const conversionsValueDifference = totalCampaignConversionsValue - all_conversions_value

    const cpaBefore = environmentCpaRaw ?? 0
    const roasBefore = environmentRoasRaw ?? 0
    const cpaAfter = conversionsDifference ? costDifference / conversionsDifference : costDifference
    const roasAfter = costDifference ? conversionsValueDifference / costDifference : 0

    const cpaDecreasePercent = cpaAfter / cpaBefore - 1
    const roasIncreasePercent = roasAfter / roasBefore - 1

    const numberOfMonthsInWindow = Math.round(window / 30)

    const monthlyLocationCost = locationCost / numberOfMonthsInWindow
    const monthlyCampaignCost = totalCampaignCost / numberOfMonthsInWindow
    const monthlyCostDecreasePercent = -(monthlyLocationCost / monthlyCampaignCost)

    const monthlyConversionDecrease = round(all_conversions / numberOfMonthsInWindow, 1)
    const monthlyConversionValueDecrease = all_conversions_value / numberOfMonthsInWindow

    const metrics: Metrics = isCpaMode
        ? {
              conversions: {
                  value: all_conversions,
                  copy: all_conversions === 1 ? 'conversion' : 'conversions',
              },
              cost: { value: cost_micros / 1000000 },
              costDecrease: { value: monthlyLocationCost },
              costDecreasePercent: { value: monthlyCostDecreasePercent },
              cpa: { value: locationPerformance },
              environmentCpa: { value: environmentPerformance },
              environmentCpaAfter: { value: cpaAfter },
              conversionsDecrease: { value: monthlyConversionDecrease, copy: 'conversions' },
              cpaDecreasePercent: { value: cpaDecreasePercent },
          }
        : {
              conversionsValue: {
                  value: all_conversions_value,
                  copy: 'in value',
              },
              cost: { value: cost_micros / 1000000 },
              costDecrease: { value: monthlyLocationCost },
              costDecreasePercent: { value: monthlyCostDecreasePercent },
              roas: { value: locationPerformance },
              environmentRoas: { value: environmentPerformance },
              environmentRoasAfter: { value: roasAfter },
              conversionValueDecrease: {
                  value: monthlyConversionValueDecrease,
                  copy: 'conversion value',
              },
              roasIncreasePercent: { value: roasIncreasePercent },
          }

    const isLocationUnderperforming = isCpaMode
        ? metrics.cpa.value > environmentPerformance
        : metrics.roas.value < environmentPerformance

    const performanceModeString = isCpaMode ? 'cpa' : 'roas'
    const avgPerformanceString = isCpaMode ? 'avgCpa' : 'avgRoas'

    let performanceDifferenceRaw =
        (environmentPerformance - metrics[performanceModeString].value) / environmentPerformance

    if (!isCpaMode && locationPerformance === 0) {
        performanceDifferenceRaw = Infinity
    }

    const performanceDifference = Math.abs(performanceDifferenceRaw)

    const locationTableHeaders = computed(() => [
        { key: 'location', text: 'Location' },
        { key: performanceModeString, text: performanceModeString.toUpperCase() },
        {
            key: avgPerformanceString,
            text: `Avg. ${performanceModeString.toUpperCase()}`,
        },
        ...(isLocationExclusion.value
            ? [{ key: 'cost', text: 'Cost' }]
            : [{ key: 'bidMod', text: 'Bid Adjustment' }]),
    ])

    const performanceItems = isCpaMode
        ? {
              cpa: useDomainMoney({
                  value: metrics[performanceModeString].value,
              }).value.displayValue.value,
              avgCpa: useDomainMoney({ value: environmentPerformance }).value.displayValue.value,
          }
        : {
              roas: useRoas({
                  value: metrics[performanceModeString].value,
              }).displayValue.value,
              avgRoas: useRoas({ value: environmentPerformance }).displayValue.value,
          }

    const bidMod = usePercent({ value: newBidModifier, includeSign: true }).displayValue.value
    const locationCostDisplay = useDomainMoney({ value: locationCost }).value.displayValue.value

    const locationTableItems = computed(() => [
        {
            id: 'itemOne',
            location: locationName,
            ...performanceItems,
            ...(!isLocationExclusion.value ? { bidMod } : {}),
            ...(isLocationExclusion.value && { cost: locationCostDisplay }),
        },
    ])

    const currentMap = locationTypeIsCountry
        ? 'worldHigh'
        : mapFiles[countryCode as keyof typeof mapFiles]

    const mapImageUrl =
        geometry && currentMap
            ? buildMapImageUrl({
                  version: 2,
                  domainId: domainId.value,
                  improvementId,
                  impIndex: 1,
                  currentMap,
                  countryCode,
                  locationTypeIsCountry,
              })
            : undefined

    const mapIsLoading = ref(true)
    function setMapIsLoadingTrue() {
        mapIsLoading.value = false
    }

    const pushActionText = ref(
        isLocationExclusion.value ? 'Apply Exclusion' : 'Apply Bid Adjustment'
    )
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        ...(isLocationExclusion.value
            ? ['Excluding Location..']
            : ['Applying location bid adjustment..']),
        'Confirming changes..',
        ...(isLocationExclusion.value
            ? ['Location excluded successfully.']
            : ['Bid adjustment applied successfully.']),
    ])

    const onPush: OnPushHandler<LocationBids.ExtraDetails> = () => {
        return { valid: true, pushedData: { new_bid_modifier: bidModToBeApplied.value } }
    }

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        isCpaMode,
        campaignGroupName,
        campaignNames,
        campaignCount,
        singleCampaign,
        trueLocationName,
        shortLocationName,
        locationTargetType,
        locationTypeIsCountry,
        hasExistingBidMod,
        newBidIsPositive,
        oldBidIsPositive,
        newBidModValue,
        newBidModifier,
        oldBidModifier,
        onBidUpdate,
        metrics,
        isLocationUnderperforming,
        performanceDifference,
        window,
        locationTableHeaders,
        locationTableItems,
        mapImageUrl,
        mapIsLoading,
        setMapIsLoadingTrue,
        mapLoaderSmall,
        mapLoaderSmallPlaceholder,
        mapLoaderLarge,
        mapLoaderLargePlaceholder,
        pushActionText,
    }
}
