<template>
    <div class="adjust-target-cell">
        <Popout
            v-model="popoutOpen"
            :trap-focus="true"
            @shown="focusInput"
            @update:modelValue="(isShowing: boolean) => onPopoutUpdate(isShowing)"
            placement="top-start"
            :external-toggle-button="true"
            :offset="[-26, 12]"
            max-width="12rem"
        >
            <div class="cell-value">
                <div v-if="localTargetValue > 0" style="display: flex; align-items: center">
                    <Money
                        v-if="isCpaMode"
                        :value="isInputError ? preEditNewTarget : localTargetValue"
                        :currency="currencyCode"
                    />
                    <Roas
                        v-else
                        :value="isInputError ? preEditNewTarget / 100 : localTargetValue / 100"
                        decimalPlaces="0"
                    />
                    <Spacer width="0.25rem" height="0.25rem" />
                    <!-- CPA Mode Diff. -->
                    <div v-if="isCpaMode">
                        <Text
                            :color="localTargetValue > existingTarget ? 'green' : 'red'"
                            as="span"
                            size="f-8"
                            weight="500"
                        >
                            (<Percent
                                :value="
                                    getNewTargetDifference({
                                        existingTargetValue: existingTarget,
                                        newTargetValue: localTargetValue,
                                        isAdjusting: true,
                                    })
                                "
                                includeSign
                            />)
                        </Text>
                    </div>
                    <!-- ROAS Mode Diff. -->
                    <div v-else>
                        <Text
                            :color="localTargetValue > existingTarget ? 'red' : 'green'"
                            as="span"
                            size="f-8"
                            weight="500"
                        >
                            (<Percent
                                :value="
                                    getNewTargetDifference({
                                        existingTargetValue: existingTarget,
                                        newTargetValue: localTargetValue,
                                        isAdjusting: true,
                                    })
                                "
                                includeSign
                            />)
                        </Text>
                    </div>
                </div>

                <NotApplicablePill v-else content="No target adjustment" />
                <oButton
                    color="white"
                    class="adjust-target-edit-icon"
                    :circle="true"
                    size="x-small"
                    @clicked="popoutOpen = !popoutOpen"
                >
                    <TableEdit style="transform: translateY(2px) !important" />
                </oButton>
            </div>
            <template #content>
                <oForm>
                    <div class="popout-content">
                        <div class="popout-inputs">
                            <div style="width: 100%">
                                <!-- localTargetValue here should always render as a positive integer -->
                                <oInput
                                    name="targetChange"
                                    ref="targetChangeRef"
                                    type="number"
                                    v-model="localTargetValue"
                                    min="0"
                                    :max="isCpaMode ? '' : 99999"
                                    @keyup="validateNewTarget($event)"
                                    autocomplete="off"
                                    :suffix="isCpaMode ? '' : '%'"
                                    :prefix="isCpaMode ? currencySymbol : ''"
                                    :validator="inputValidation"
                                />
                            </div>
                        </div>
                        <Spacer height="0.75rem" />
                        <div class="popout-input-note">
                            <Text size="f-9">
                                <b>Note:</b> If left empty, no target will be applied.
                            </Text>
                        </div>
                        <!-- hidden so `enter` still works on form -->
                        <div class="hidden-buttons">
                            <!-- this doesn't do anything because the field live updates -->
                            <oButton
                                type="submit"
                                color="blue"
                                @clicked="saveEdit({ closePopout: true })"
                            >
                                Save Changes
                            </oButton>
                        </div>
                    </div>
                </oForm>
            </template>
        </Popout>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useDomain } from '@/composition/domain/useDomain'
import { delay } from '@/lib/globalUtils'

import {
    Table as AdjustAdGroupMultiTable,
    MultiTargetUpdateArgs,
    getNewTargetDifference,
    limitDecimalPlaces,
} from '@/composition/improvement/types/useAdjustAdGroupMultiTargets'

import {
    oInput,
    Spacer,
    Text,
    Roas,
    Money,
    Popout,
    oButton,
    TableEdit,
    oForm,
    Percent,
} from '@opteo/components-next'
import NotApplicablePill from './NotApplicablePill.vue'

const props = defineProps<{
    newTarget: AdjustAdGroupMultiTable.NewTarget
    existingTarget: number
    isCpaMode?: boolean
}>()

const emit = defineEmits<{
    (e: 'update', args: MultiTargetUpdateArgs): void
}>()

const popoutOpen = ref(false)
const targetChangeRef = ref()
const preEditNewTarget = ref(0)
const isInputError = ref(false)

const { rawValue: rawTargetValue, adjustedValue: rawAdjustedTargetValue } = props.newTarget
const { currencySymbol, currencyCode } = useDomain()

const relevantRawTargetValue = rawAdjustedTargetValue ?? rawTargetValue ?? 0
const localTargetValue = props.isCpaMode
    ? ref(relevantRawTargetValue)
    : ref(relevantRawTargetValue * 100)

const saveEdit = (closePopout?: { closePopout: boolean }) => {
    const adjustedTarget = props.isCpaMode ? localTargetValue.value : localTargetValue.value / 100

    emit('update', {
        adjustedTarget,
        resourceName: props.newTarget.resourceName,
    })

    if (!targetChangeRef.value.valid) {
        targetChangeRef.value.setError(targetChangeRef.value.errorMessage)

        isInputError.value = true
        return
    }

    if (isInputError.value) isInputError.value = false

    if (closePopout) popoutOpen.value = false
}

const validateNewTarget = async (e: InputEvent) => {
    const { value, validity } = e.target as HTMLInputElement

    // In case user types something that would return NaN, e.g. '29.'
    if (!value) {
        // An empty input is not an error per se, so we remove it but return early so a new target isn't set
        const isEmptyInput = !validity.badInput

        if (isEmptyInput) {
            targetChangeRef.value.valid = true
            targetChangeRef.value.error = false
            return
        }

        targetChangeRef.value.setError(
            targetChangeRef.value.errorMessage || 'Please enter a number.'
        )

        isInputError.value = true
        return
    }

    saveEdit()
}

const inputValidation = (newTarget: number) => {
    if (newTarget === 0) {
        return 'Please ensure new targets are above 0.'
    }

    if (!props.isCpaMode && !Number.isInteger(newTarget)) {
        return 'Please insert a number value, without decimals.'
    }

    // CPA max handled here as to avoid confusing default message with too many 0s, 1000000, using '1 million' instead
    if (props.isCpaMode && newTarget > 1_000_000) {
        return 'Value must be less than or equal to 1 million.'
    }
}

const touchedTarget = ref(false)

const focusInput = async () => {
    await delay(10)
    touchedTarget.value = true
    targetChangeRef.value.inputRef.focus()
}

const onPopoutUpdate = (isShowing: boolean) => {
    if (isShowing) {
        preEditNewTarget.value = localTargetValue.value
    }

    localTargetValue.value = limitDecimalPlaces(localTargetValue.value)

    if (!isShowing) {
        if (isInputError.value) {
            localTargetValue.value = preEditNewTarget.value
        }

        saveEdit()
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.adjust-target-cell {
    position: relative;
}

.popout-content {
    @include pa-16;
}

.popout-input-note {
    @include container;
    @include ph-16;
    @include pv-12;
}

.popout-inputs {
    display: flex;
    align-items: center;
}

.cell-value {
    position: relative;
    display: flex;
    align-items: center;
}

:deep(.o-button.o-button--circle.adjust-target-edit-icon) {
    width: 24px;
    height: 24px;
    padding: 0;
    position: absolute;
    right: 2rem;
    margin-left: 0.75rem;
}

.hidden-buttons {
    display: none;
}
</style>
