import {
    EntityPill,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { buildMapImageUrl } from '@/composition/utils/useMaps'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { AdjustGeoBids, Improvement } from '@opteo/types'
import { usePercent } from '@opteo/components-next'
import { computed, ref } from 'vue'
import { useDomain } from '@/composition/domain/useDomain'

type TableHeader = { key: string; text: string }
type TableItem = { id: string; location: string; cpa: string; avgCpa: string; bidMod: string }

interface UseAdjustGeoBids {
    entityPillList: EntityPill[]
    campaignGroupName: string
    increasingBid: boolean
    bidModifier: number
    cpaOverCampaignGroupCpa: boolean
    cpaCampaignGroupCpaDifference: number
    metrics: { [metric: string]: { value: number; copy?: string } }
    campaignCount: number
    locationTableHeaders: TableHeader[]
    locationTableItems: TableItem[]
    mapImageUrl: string
    allCampaignNames: {
        content: string
        type: string
    }[]
}

export interface ParentLocation {
    ignore: boolean
    names: {
        name: string
    }
    bid_modifier?: number
    cpa?: number
    average_cpa?: number
    parent?: ParentLocation
}

export interface Body {
    campaign_group_name: string
    top_location_name: string
    location_type: string
    country_code: string
    map_files: {
        [countryCode: string]: string
    }
    imp_index: number
    top_location: {
        bid_modifier: number
        conversions: number
        cost: number
        cpa: number
        average_cpa: number
        campaigns_targeting_location: {
            campaign_label: string
        }[]
        parent?: ParentLocation
    }
    campaign_group: {
        cpa: number
    }
}

export function useAdjustGeoBids(): UseImprovement<UseAdjustGeoBids> {
    const { improvement, lastUpdated, title } = useImprovement<Body>()
    const { domainId } = useDomain()
    const {
        improvement_id: improvementId,
        body: {
            campaign_group_name: campaignGroupName,
            top_location_name: locationName,
            location_type: locationType,
            country_code: countryCode,
            map_files: mapFiles,
            imp_index: impIndex,
            top_location: {
                bid_modifier: bidModifierRaw,
                conversions,
                cost,
                cpa,
                average_cpa: averageCpa,
                campaigns_targeting_location: campaignsTargetingLocation,
                parent,
            },
            campaign_group: { cpa: campaignGroupCpa },
        },
    } = checkImprovement(improvement)

    const bidModifier = +Math.abs(bidModifierRaw - 1).toFixed(2)
    const increasingBid = bidModifierRaw > 1

    const cpaOverCampaignGroupCpa = cpa > campaignGroupCpa
    const cpaCampaignGroupCpaDifference = Math.abs((cpa - campaignGroupCpa) / campaignGroupCpa)

    const metrics = {
        conversions: {
            value: conversions,
            copy: +conversions.toFixed(2) === 1 ? 'conversion' : 'conversions',
        },
        cost: { value: cost },
        cpa: { value: cpa },
        campaignGroupCpa: { value: campaignGroupCpa },
    }

    const allCampaignNames = campaignsTargetingLocation.map(campaign => {
        return { content: campaign.campaign_label, type: 'campaign' }
    })

    const campaignCount = allCampaignNames.length

    const locationTypeIsCountry = locationType.includes('country')

    const locationTableHeaders: TableHeader[] = [
        { key: 'location', text: 'Location' },
        { key: 'cpa', text: 'CPA' },
        { key: 'avgCpa', text: 'Average CPA' },
        { key: 'bidMod', text: 'Bid Modifier' },
    ]

    const locationTableItems: TableItem[] = [
        {
            id: 'childLocation',
            location: locationName,
            cpa: useDomainMoney({ value: cpa }).value.displayValue.value,
            avgCpa: useDomainMoney({ value: averageCpa }).value.displayValue.value,
            bidMod: `${increasingBid ? '+' : '-'}${
                usePercent({ value: bidModifier }).displayValue.value
            }`,
        },
    ]

    function addParentLocationToTable({
        ignore: ignoreParent,
        names: { name: parentName },
        bid_modifier: parentBidModifier,
        cpa: parentCpa,
        average_cpa: parentAverageCpa,
    }: AdjustGeoBids.ParentLocation) {
        const parentHasData = !ignoreParent && parentBidModifier

        locationTableItems.push({
            id: 'parentLocation',
            location: `Rest of ${parentName}`,
            cpa:
                parentHasData && parentCpa
                    ? useDomainMoney({ value: parentCpa }).value.displayValue.value
                    : 'N/A',
            avgCpa:
                parentHasData && parentAverageCpa
                    ? useDomainMoney({
                          value: parentAverageCpa,
                      }).value.displayValue.value
                    : 'N/A',
            bidMod: parentHasData
                ? `${parentBidModifier > 1 ? '+' : '-'}${
                      usePercent({ value: Math.abs(parentBidModifier - 1) }).displayValue.value
                  }`
                : 'N/A',
        })
    }

    if (parent && !locationTypeIsCountry) {
        addParentLocationToTable(parent)

        if (parent.parent) {
            addParentLocationToTable(parent.parent)
        }
    }

    const currentMap = locationTypeIsCountry ? 'worldHigh' : mapFiles[countryCode]

    const mapImageUrl = buildMapImageUrl({
        version: 1,
        domainId: domainId.value,
        improvementId,
        impIndex,
        currentMap,
        countryCode,
        locationTypeIsCountry,
    })

    const entityPillList: EntityPill[] = [
        { type: Improvement.LocationEntity.CampaignGroup, content: campaignGroupName },
        { type: Improvement.LocationEntity.Location, content: locationName },
    ]

    const pushActionText = ref('Apply Bid Adjustment')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Applying location bid adjustment..',
        'Confirming changes..',
        'Bid adjustment applied successfully.',
    ])

    return {
        title,
        pushMessages,
        lastUpdated,
        entityPillList,
        campaignGroupName,
        increasingBid,
        bidModifier,
        cpaOverCampaignGroupCpa,
        cpaCampaignGroupCpaDifference,
        metrics,
        campaignCount,
        locationTableHeaders,
        locationTableItems,
        mapImageUrl,
        allCampaignNames,
        pushActionText,
    }
}
