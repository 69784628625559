import { onMounted, ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'

import { useAccount } from '@/composition/account/useAccount'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'
import { useUser } from '@/composition/user/useUser'
import { SLACK_CLIENT_ID } from '@/lib/env'
import { showSnackbar } from '@opteo/components-next'

import type { Slack, UUID } from '@opteo/types'

export function useSlack({
    isPopup,
    isDomainSpecificConnection,
}: {
    isPopup: boolean
    isDomainSpecificConnection: boolean
}) {
    const { userId } = useUser()
    const { accountId } = useAccount()

    const slackPopup = ref<Window>()

    /** Used for both getting the Slack OAuth and removing the Slack OAuth */
    const reqBody = computed(() => ({
        userId: userId.value,
        isDomainSpecificConnection,
        accountId: accountId.value,
    }))

    const { data: slackSettings, mutate } = useAPI<Slack.OAuth | null>(Endpoint.GetSlackOAuth, {
        body: () => reqBody.value,
        waitFor: () => (isDomainSpecificConnection ? accountId.value : true),
    })

    const route = useRoute()

    onMounted(() => {
        const postSlackOauthPageLoad = route.query.from === 'slack'
        const slackOauthError = route.query.err
        if (postSlackOauthPageLoad && slackOauthError) {
            showSnackbar({
                message: `Opteo couldn't connect to Slack. Try again later.`,
                timeout: 5000,
            })
        }
    })

    const connectSlack = async () => {
        const slackConnectState = await authRequest<UUID>(Endpoint.SetCSRFToken)

        const stateObj: Slack.State = {
            slackConnectState,
            userId: userId.value ?? undefined,
            isDomainSpecificConnection,
            ...(isDomainSpecificConnection && { accountId: accountId.value }),
        }

        const stateStr = JSON.stringify(stateObj)

        const textEncoder = new TextEncoder()
        const stateBase64 = window.btoa(String.fromCharCode(...textEncoder.encode(stateStr)))

        const slackAuthUrl = new URL('https://slack.com/oauth/v2/authorize')
        const params = new URLSearchParams({
            client_id: SLACK_CLIENT_ID,
            scope: 'incoming-webhook',
            state: stateBase64,
        })

        slackAuthUrl.search = params.toString()

        const formattedSlackAuthUrl = slackAuthUrl.toString()

        if (isPopup) {
            slackPopup.value = window.open(
                formattedSlackAuthUrl,
                '',
                'scrollbars=yes,menubar=no,width=400,height=600,resizable=yes,toolbar=no,status=no'
            )!
        } else {
            window.location.href = formattedSlackAuthUrl
        }
    }

    watch(slackPopup, newslackPopup => {
        if (isPopup && newslackPopup) {
            const slackTokenInterval = setInterval(async () => {
                const latestSettings = await authRequest(Endpoint.GetSlackOAuth)
                if (latestSettings?.channel_name) {
                    slackPopup.value?.close()
                    mutate()
                    clearInterval(slackTokenInterval)
                }
            }, 1000)
        }
    })

    const removeSlack = async () => {
        mutate(() => null)

        await authRequest(Endpoint.RemoveSlack, {
            body: () => reqBody.value,
        })

        mutate()
    }

    return {
        connectSlack,
        removeSlack,
        slackSettings,
    }
}
