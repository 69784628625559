<template>
    <!-- Account Info -->
    <div class="help-sidebar-menu-popover-account-info" @click="openMessenger()" tabindex="0">
        <!-- Support avatars -->
        <div class="avatars">
            <div class="dany">
                <oImage :src="dany" :placeholder="danyPlaceholder" :width="36" :height="36" />
            </div>
            <div class="adam">
                <oImage :src="adam" :placeholder="adamPlaceholder" :width="36" :height="36" />
            </div>
            <div class="shaquira">
                <oImage
                    :src="shaquira"
                    :placeholder="shaquiraPlaceholder"
                    :width="36"
                    :height="36"
                />
            </div>
        </div>
        <!-- <img src="@/assets/img/support-avatars.png" style="width: 5.25rem" /> -->
        <div>
            <p class="help-sidebar-menu-popover-name">Live Chat Support</p>
            <div class="help-sidebar-menu-popover-email">
                <div class="live-chat-support-status" :class="{ online: supportIsOnline }" />
                {{ supportIsOnline ? 'Online Now' : 'Back in a few hours' }}
            </div>
        </div>
    </div>

    <a
        id="help-centre"
        href="https://opteo.com/docs"
        target="_blank"
        rel="noopener"
        class="help-sidebar-menu-popover-link"
    >
        <span>Help Centre</span>
    </a>
    <a
        id="feature-requests"
        href="https://opteo.canny.io/feature-requests"
        target="_blank"
        rel="noopener"
        class="help-sidebar-menu-popover-link"
    >
        <span>Feature Requests</span>
    </a>
    <a
        id="planned-updates"
        href="https://opteo.com/planned-updates"
        target="_blank"
        rel="noopener"
        class="help-sidebar-menu-popover-link"
    >
        <span>Planned Updates</span>
    </a>
    <a
        id="changelog"
        href="https://changelog.opteo.com/"
        target="_blank"
        rel="noopener"
        class="help-sidebar-menu-popover-link"
    >
        <span>Changelog</span>
    </a>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from 'vue'
import { useRouter } from 'vue-router'
import { oButton, oImage } from '@opteo/components-next'

import { useLogin } from '@/composition/auth/useLogin'
import { useUser } from '@/composition/user/useUser'

import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import { useIntercom } from '@/lib/intercom/useIntercom'

import dany from '@/assets/img/support/dany.png'
import danyPlaceholder from '@/assets/img/support/dany-placeholder.png'
import adam from '@/assets/img/support/adam.png'
import adamPlaceholder from '@/assets/img/support/adam-placeholder.png'
import shaquira from '@/assets/img/support/shaquira.png'
import shaquiraPlaceholder from '@/assets/img/support/shaquira-placeholder.png'

export default defineComponent({
    name: 'HelpSidebarContent',
    components: { oImage, oButton },
    setup(_, { emit }) {
        const { openMessenger } = useIntercom()

        const supportIsOnline = computed(() => {
            const currentHourInLondon = +formatInTimeZone(new Date(), 'Europe/London', 'H')
            const currentWeekDayInLondon = +formatInTimeZone(new Date(), 'Europe/London', 'i')

            // 9am - 6pm, Mon - Fri
            return (
                currentHourInLondon > 9 &&
                currentHourInLondon < 18 &&
                [1, 2, 3, 4, 5].includes(currentWeekDayInLondon)
            )
        })

        const { userInfo, profileImageUrl } = useUser()

        const { logout } = useLogin()

        /*
            We use links instead of route names because we don't use router-link. 
            We don't use router-link because we don't want the distracting "link preview" 
            that happens when you hover over a link in chrome.
        */
        const linkToAccountSettings = computed(() => {
            return `/user/${userInfo.value?.user_id}/settings`
        })

        const linkToBillingCentre = computed(() => {
            return `/user/${userInfo.value?.user_id}/billing/subscription`
        })

        const linkToFeatureRequests = computed(() => {
            return `/user/${userInfo.value?.user_id}/featurerequests`
        })

        const linkToLinkedAccounts = computed(() => {
            return `/user/${userInfo.value?.user_id}/linkedaccounts`
        })

        const linkToChangelog = computed(() => {
            return `/user/${userInfo.value?.user_id}/changelog`
        })

        const closeMobileNav = inject<() => void>('closeMobileNav', () => {})

        const router = useRouter()

        function pushRoute(route: string) {
            const toRoute = route.split('/').pop()
            if (toRoute === router.currentRoute.value.name) {
                closeMobileNav()
            }

            router.push(route)
        }

        return {
            userInfo,
            logout,
            profileImageUrl,
            linkToAccountSettings,
            linkToBillingCentre,
            linkToFeatureRequests,
            linkToLinkedAccounts,
            linkToChangelog,
            pushRoute,
            supportIsOnline,
            openMessenger,
            dany,
            danyPlaceholder,
            adam,
            adamPlaceholder,
            shaquira,
            shaquiraPlaceholder,
        }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.help-sidebar-menu-popover-account-info {
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    @include no-select;
}
.help-sidebar-menu-popover-account-info:hover {
    box-shadow: $opteo-shadow;
}
.help-sidebar-menu-popover-account-info:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}
.help-sidebar-menu-popover-account-info:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}

.live-chat-support-status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 999rem;
    @include bg-opteo-amber;
    margin-right: 0.375rem;
}

.live-chat-support-status.online {
    @include bg-opteo-green;
}

.help-sidebar-menu-popover-account-info {
    @include flex;
    @include items-center;
    @include pa-24;
    @include container;
    @include br-20;
    @include mb-32;
    overflow: hidden;
}
.help-sidebar-menu-popover-name {
    @include ml-16;
    @include opteo-black;
    @include f-7;
    @include fw-500;
    line-height: 1.25rem;
    margin-bottom: 4px;
    white-space: nowrap;
}
.help-sidebar-menu-popover-email {
    @include ml-16;
    @include f-9;
    @include fw-400;
    line-height: 1rem;
    color: #b3b3b5;
    white-space: nowrap;
    @include flex;
    @include items-center;
}
.help-sidebar-menu-popover-link {
    @include flex;
    @include items-center;
    gap: 0.625rem;
    height: 2.875rem;
    @include opteo-black;
    @include f-8;
    @include mb-8;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    border-radius: 0.75rem;
    cursor: pointer;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.help-sidebar-menu-popover-link:hover {
    box-shadow: $opteo-shadow;
}
.help-sidebar-menu-popover-link:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}
.help-sidebar-menu-popover-link:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}
.help-sidebar-menu-popover-link:last-child {
    @include mb-0;
}

.help-sidebar-menu-popover-account-info .adam,
.help-sidebar-menu-popover-account-info .shaquira {
    margin-left: -0.5rem;
}
.help-sidebar-menu-popover-account-info .adam,
.help-sidebar-menu-popover-account-info .shaquira,
.help-sidebar-menu-popover-account-info .dany {
    border-radius: 999px;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

.avatars {
    @include flex;
}

@media screen and (max-width: $mq-767-max) {
    .help-sidebar-menu-popover-account-info {
        @include pa-20;
        @include mb-24;
    }

    .avatars {
        width: 2.25rem;
        height: 2.25rem;
        overflow: hidden;
        position: relative;
        border-radius: 999rem;
    }

    // TO DO: Fix animation
    .avatars > div {
        width: 100%;
        height: 100%;
        // background-size: cover;
        position: absolute;
        animation: fader 15s infinite;
        opacity: 0;
    }

    .avatars .dany {
        animation-delay: 0s;
    }
    .avatars .adam {
        animation-delay: 5s;
    }
    .avatars .shaquira {
        animation-delay: 10s;
    }

    @keyframes fader {
        0% {
            opacity: 0;
        }
        4% {
            opacity: 1;
        }
        30% {
            opacity: 1;
        }
        34% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @media screen and (max-width: 374px) {
        .avatars {
            display: none;
        }
        .help-sidebar-menu-popover-name,
        .help-sidebar-menu-popover-email {
            margin-left: unset;
        }
    }
}
</style>
