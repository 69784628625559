import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { User } from '@opteo/types'

import { useAPI, Endpoint, noAuthRequest } from '../../composition/api/useAPI'

interface UserProfile {
    ['profile']: User.Info
    message?: string
    error?: boolean
    status?: number
    statusText?: string
}

interface NewPasswordRes {
    data?: object | string
    meta?: { version: string; type: string; original_request: string }
}

export function useInviteWelcome() {
    let password = ref()
    let password2 = ref()
    const showForm = ref(true)
    const route = useRoute()
    const { params } = route
    let passwordRes = ref()
    const passwordLengthError = ref(false)
    const passwordMatchError = ref(false)

    let { data: user, loading } = useAPI<UserProfile>(Endpoint.GetUserInfoFromHash, {
        body: {
            hash: params.hash,
        },
        uniqueId: () => params.hash as string,
        waitFor: () => params.hash as string,
    })

    const errorMessage = computed(() => {
        if (passwordLengthError.value) {
            return `Your password must be at least 8 characters long.`
        } else if (passwordMatchError.value) {
            return `Passwords don't match! Please try again.`
        } else if (!!getUserInfoHashError.value || !!updatePasswordHashError.value) {
            return 'Invalid or expired invite link.'
        }

        return 'Something went wrong... Please try again later.'
    })

    const showError = computed(() => {
        if (
            getUserInfoHashError.value ||
            updatePasswordHashError.value ||
            passwordLengthError.value ||
            passwordMatchError.value ||
            updatePasswordError.value
        ) {
            return true
        }
        return false
    })

    const unrecoverable = computed(() => {
        if (!loading.value && user.value && user.value?.error) {
            return true
        }
        return false
    })

    const getUserInfoHashError = computed(() => {
        return !loading.value && user.value && user?.value?.message?.includes('link')
    })

    const updatePasswordHashError = computed(() => {
        return (
            typeof passwordRes?.value?.data === 'string' &&
            passwordRes?.value?.data?.includes('hash')
        )
    })
    const updatePasswordError = computed(() => {
        return (
            typeof passwordRes?.value?.data === 'string' &&
            passwordRes?.value?.data?.includes('error')
        )
    })

    const formLoading = ref(false)
    const password2Field = ref()

    async function createPassword() {
        if (password.value !== password2.value) {
            password2Field.value.setError('Passwords must match.')
            return
        }

        formLoading.value = true

        passwordRes.value = await noAuthRequest<NewPasswordRes>(Endpoint.SetNewPasswordFromHash, {
            meta: {
                function: 'auth:setNewPasswordFromHash',
                args: {
                    hash: params.hash,
                    password: password.value,
                },
            },
        })

        formLoading.value = false

        if (!updatePasswordHashError.value && !updatePasswordError.value) {
            showForm.value = false
            return
        }
    }
    return {
        loading,
        errorMessage,
        showError,
        unrecoverable,
        showForm,
        password,
        password2,
        createPassword,
        formLoading,
        password2Field,
    }
}
