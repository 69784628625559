<template>
    <PageContent v-if="!improvementOpen" class="improvement-rows">
        <!-- Skeleton rows -->
        <div v-if="loading">
            <div class="improvement-section-header">
                <Skeleton :height="24" :width="225" />
            </div>
            <div v-for="skid in 20" :key="skid">
                <ImprovementRowDismissed :improvement="{}" />
            </div>
        </div>
        <!-- No dismissed improvements -->
        <div v-else-if="noDismissedImps" class="improvements-empty-state-container">
            <img src="@/assets/img/noDismissedImprovements.png" style="height: 218px" />
            <Spacer height="2rem" />
            <div class="center" style="max-width: 350px">
                <Text as="h5" size="f-5" weight="600" align="center">
                    No dismissed Improvements
                </Text>
                <Spacer height="0.875rem" />
                <Text as="p" size="f-8" align="center">
                    Your dismissed improvements will appear here. Switch to the
                    <b>Active</b> tab to view your active improvements.
                </Text>
            </div>
        </div>
        <!-- Dismissed improvement rows -->
        <div v-else>
            <!-- Dismissed 1 week -->
            <div v-if="dismissedImprovements.week?.length > 0">
                <div class="improvement-section-header">
                    <ColorTag
                        color="red"
                        title="Dismissed for 1 Week"
                        :content="`${dismissedImprovements.week.length} Improvement${
                            dismissedImprovements.week.length === 1 ? '' : 's'
                        }`"
                    />
                </div>
                <div
                    v-for="improvement in dismissedImprovements.week"
                    :key="improvement.improvement_id"
                >
                    <ImprovementRowDismissed :improvement="improvement" :isDeferred="true" />
                </div>
            </div>

            <!-- Dismissed 1 month -->
            <div v-if="dismissedImprovements.month?.length > 0">
                <Spacer v-if="dismissedImprovements.week" />
                <div class="improvement-section-header">
                    <ColorTag
                        color="red"
                        title="Dismissed for 1 Month"
                        :content="`${dismissedImprovements.month.length} Improvement${
                            dismissedImprovements.month.length === 1 ? '' : 's'
                        }`"
                    />
                </div>
                <div
                    v-for="improvement in dismissedImprovements.month"
                    :key="improvement.improvement_id"
                >
                    <ImprovementRowDismissed :improvement="improvement" :isDeferred="true" />
                </div>
            </div>

            <!-- Dismissed forever -->
            <div v-if="dismissedImprovements.forever?.length > 0">
                <Spacer v-if="dismissedImprovements.month || dismissedImprovements.week" />
                <div class="improvement-section-header">
                    <ColorTag
                        color="red"
                        title="Dismissed Forever"
                        :content="`${dismissedImprovements.forever.length} Improvement${
                            dismissedImprovements.forever.length === 1 ? '' : 's'
                        }`"
                    />
                </div>
                <div
                    v-for="improvement in dismissedImprovementsForeverSubset"
                    :key="improvement.improvement_id"
                >
                    <ImprovementRowDismissed :improvement="improvement" />
                </div>
                <div v-if="moreAvailable" class="load-more">
                    <oButton @clicked="limit = limit + 100" color="white"> Show More </oButton>
                </div>
            </div>
        </div>
    </PageContent>
    <router-view v-else />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ColorTag, oButton, Text, Spacer } from '@opteo/components-next'

import PageContent from '@/layouts/PageContent.vue'
import ImprovementRowDismissed from '@/components/improvement/ImprovementRowDismissed.vue'
import { useDismissedImprovements } from '@/composition/improvement/useDismissedImprovements'
import Skeleton from '@/components/util/Skeleton.vue'

export default defineComponent({
    components: { PageContent, ImprovementRowDismissed, ColorTag, oButton, Text, Spacer, Skeleton },
    name: 'Dismissed',
    setup() {
        return useDismissedImprovements()
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';

.improvement-rows {
    @include mb-48;
}
.improvement-section-header {
    @include flex;
    @include items-center;
    @include mb-32;
    height: 1.75rem;
}

.load-more {
    @include flex;
    @include items-center;
    @include justify-center;
    @include mt-24;
}
.improvements-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 30rem;
}
@media screen and (max-width: $mq-767-max) {
    .improvements-empty-state-container {
        margin-bottom: 6rem;
    }
}
</style>
