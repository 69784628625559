import { computed, ref, Ref } from 'vue'
import {
    EntityPill,
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { Improvement } from '@opteo/types'

interface BlockedPositiveKeyword {
    keyword_id: number
    campaign_name: string
    formatted_positive_text: string
}

interface Body {
    formatted_negative_text: string
    container_type:
        | Improvement.LocationEntity.AdGroup
        | Improvement.LocationEntity.Campaign
        | Improvement.LocationEntity.NegativeList
    container_name: string
    blocked_positive_keywords: BlockedPositiveKeyword[]
}

type TableHeader = { key: string; text: string }
type TableItem = { id: number; campaign: string; keyword: string }

interface UseFixNegativesBlockingKeywords {
    pauseKeywordsPushAction: string
    removeNegativePushAction: string
    entityPillList: EntityPill[]
    pausingKeywords: Ref<boolean>
    keyword: string
    positiveKeywordCount: number
    singlePositiveKeyword: boolean
    positiveKeywordCopy: string
    tableHeaders: TableHeader[]
    tableItems: TableItem[]
    setPausingKeywords: (value: string) => void
}

const ADGROUP = Improvement.LocationEntity.AdGroup

export function useFixNegativesBlockingKeywords(): UseImprovement<UseFixNegativesBlockingKeywords> {
    const { improvement, lastUpdated, title } = useImprovement<Body>()

    const {
        body: {
            formatted_negative_text: keyword,
            blocked_positive_keywords: positiveKeywords,
            container_type: containerType,
            container_name: containerName,
        },
        body,
    } = checkImprovement(improvement)

    const pausingKeywords = ref(false) // false = removing negative keyword
    const positiveKeywordCount = positiveKeywords.length
    const singlePositiveKeyword = positiveKeywordCount === 1
    const positiveKeywordCopy = singlePositiveKeyword ? 'Keyword' : 'Keywords'

    const entityPillList: EntityPill[] = []

    // Add campaign aside from its own location entity if adgroup
    if (containerType === ADGROUP) {
        const campaignName = positiveKeywords.map(kw => kw.campaign_name)[0]
        entityPillList.push({ type: Improvement.LocationEntity.Campaign, content: campaignName })
    }

    /**
     * Location can be:
     * - Adgroup: Campaign > Adgroup > Negative Keyword
     * - Campaign: Campaign > Negative Keyword
     * - Shared Set: Negative List > Negative Keyword
     */
    entityPillList.push(
        { type: containerType, content: containerName },
        { type: Improvement.LocationEntity.NegativeKeyword, content: keyword }
    )

    const tableHeaders: TableHeader[] = [
        { key: 'campaign', text: 'Campaign' },
        { key: 'keyword', text: 'Blocked Keyword' },
    ]

    const tableItems: TableItem[] = positiveKeywords.map(
        ({
            keyword_id: keywordId,
            formatted_positive_text: keywordText,
            campaign_name: campaignName,
        }) => {
            return { id: keywordId, campaign: campaignName, keyword: keywordText }
        }
    )

    function setPausingKeywords(value: string) {
        if (value === 'remove-negative') {
            pausingKeywords.value = false
            pushActionText.value = removeNegativePushAction
        } else {
            pausingKeywords.value = true
            pushActionText.value = pauseKeywordsPushAction
        }
    }

    const adjustSteps = ref([
        singlePositiveKeyword ? 'Fix Keyword Conflict' : 'Fix Keyword Conflicts',
    ])

    const pauseKeywordsPushAction = singlePositiveKeyword
        ? `Pause Keyword`
        : `Pause ${positiveKeywordCount} Keywords`
    const removeNegativePushAction = `Remove Negative`

    const pushActionText = ref(
        pausingKeywords.value ? pauseKeywordsPushAction : removeNegativePushAction
    )

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        pausingKeywords.value ? `Pausing ${positiveKeywordCopy}..` : `Removing Negative Keyword..`,
        'Confirming changes..',
        pausingKeywords.value
            ? `${positiveKeywordCopy} paused successfully.`
            : `Negative Keyword removed successfully.`,
    ])

    const onPush: OnPushHandler = () => {
        return {
            valid: true,
            pushedData: {
                action: Improvement.Type.CheckNegativesBlockingKeywords,
                details: {
                    blocking_negative: body,
                    pause_positive_keywords: pausingKeywords.value,
                },
            },
        }
    }

    return {
        title,
        pushMessages,
        pauseKeywordsPushAction,
        removeNegativePushAction,
        onPush,
        lastUpdated,
        entityPillList,
        pausingKeywords,
        keyword,
        positiveKeywordCount,
        singlePositiveKeyword,
        positiveKeywordCopy,
        tableHeaders,
        tableItems,
        setPausingKeywords,
        pushActionText,
        adjustSteps,
    }
}
