<template>
    <svg
        width="680"
        height="394"
        viewBox="0 0 680 394"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="680" height="394" fill="white" />
        <mask
            id="mask0_1642_8391"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="680"
            height="394"
        >
            <rect width="680" height="394" fill="white" />
        </mask>
        <g mask="url(#mask0_1642_8391)">
            <g filter="url(#filter0_dd_1642_8391)">
                <rect x="196" y="235" width="440" height="218" rx="12" fill="white" />
            </g>
            <line x1="196" y1="388.5" x2="636" y2="388.5" stroke="#F7F7F9" />
            <g filter="url(#filter1_dd_1642_8391)">
                <rect x="246" y="289" width="340" height="84" rx="10" fill="white" />
            </g>
            <rect opacity="0.1" x="266" y="321" width="288" height="8" rx="4" fill="#1A0DAB" />
            <rect x="266" y="307" width="105" height="6" rx="3" fill="#ECECEF" />
            <rect x="266" y="337" width="292" height="6" rx="3" fill="#ECECEF" />
            <rect x="266" y="349" width="280" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter2_dd_1642_8391)">
                <rect x="212" y="251" width="45" height="22" rx="5" fill="white" />
            </g>
            <rect x="220.5" y="258" width="6" height="8" rx="1.5" stroke="black" />
            <circle cx="223.5" cy="263.75" r="0.75" fill="black" />
            <rect x="245" y="264" width="1" height="2" fill="#006DFF" />
            <rect x="242.5" y="265.5" width="6" height="1" rx="0.5" fill="#006DFF" />
            <path
                d="M241.609 263.454L241.86 263.326L241.609 263.454C241.705 263.642 241.858 263.795 242.046 263.891C242.182 263.96 242.317 263.982 242.431 263.992C242.535 264 242.658 264 242.784 264L242.8 264H248.2L248.216 264C248.342 264 248.465 264 248.569 263.992C248.683 263.982 248.818 263.96 248.954 263.891C249.142 263.795 249.295 263.642 249.391 263.454C249.46 263.318 249.482 263.183 249.492 263.069C249.5 262.965 249.5 262.842 249.5 262.716L249.5 262.7V259.3L249.5 259.284C249.5 259.158 249.5 259.035 249.492 258.931C249.482 258.817 249.46 258.682 249.391 258.546C249.295 258.358 249.142 258.205 248.954 258.109L248.73 258.549L248.954 258.109C248.818 258.04 248.683 258.018 248.569 258.008C248.465 258 248.342 258 248.216 258L248.2 258H242.8C242.795 258 242.789 258 242.784 258C242.658 258 242.535 258 242.431 258.008C242.317 258.018 242.182 258.04 242.046 258.109C241.858 258.205 241.705 258.358 241.609 258.546C241.54 258.682 241.518 258.817 241.508 258.931C241.5 259.035 241.5 259.158 241.5 259.284C241.5 259.289 241.5 259.295 241.5 259.3V262.7L241.5 262.716C241.5 262.842 241.5 262.965 241.508 263.069C241.518 263.183 241.54 263.318 241.609 263.454Z"
                stroke="#006DFF"
            />
            <rect opacity="0.7" x="234" y="251" width="1" height="22" fill="#F0F0F5" />
            <g filter="url(#filter3_dd_1642_8391)">
                <rect x="263" y="251" width="44.5" height="22" rx="5" fill="white" />
            </g>
            <path
                d="M275.5 259L272.5 262L275.5 265"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M295 259L298 262L295 265"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect opacity="0.7" x="285" y="251" width="1" height="22" fill="#F0F0F5" />
            <g filter="url(#filter4_dd_1642_8391)">
                <rect x="560" y="251" width="60" height="22" rx="11" fill="white" />
            </g>
            <g filter="url(#filter5_dd_1642_8391)">
                <rect x="12" y="342" width="656" height="40" rx="8" fill="white" />
            </g>
            <circle cx="33" cy="362" r="9" fill="#00B3A1" />
            <rect x="50" y="359" width="40" height="6" rx="3" fill="#00B3A1" />
            <g filter="url(#filter6_dd_1642_8391)">
                <rect x="538" y="351" width="40" height="22" rx="11" fill="white" />
            </g>
            <g filter="url(#filter7_d_1642_8391)">
                <rect x="584" y="351" width="72" height="22" rx="11" fill="#006DFF" />
            </g>
            <g filter="url(#filter8_dd_1642_8391)">
                <rect x="102" y="351" width="112" height="22" rx="5" fill="white" />
            </g>
            <rect x="126" y="359" width="82" height="6" rx="3" fill="#ECECEF" />
            <path
                d="M114 367C116.761 367 119 364.761 119 362C119 359.239 116.761 357 114 357C111.239 357 109 359.239 109 362C109 364.761 111.239 367 114 367ZM114 368C110.686 368 108 365.314 108 362C108 358.686 110.686 356 114 356C117.314 356 120 358.686 120 362C120 365.314 117.314 368 114 368Z"
                fill="#006DFF"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M114.064 364.605C115.457 364.605 116.171 363.681 116.36 362.918L115.457 362.645C115.324 363.107 114.911 363.66 114.064 363.66C113.266 363.66 112.524 363.079 112.524 362.022C112.524 360.895 113.308 360.363 114.05 360.363C114.911 360.363 115.296 360.888 115.415 361.364L116.325 361.077C116.129 360.272 115.422 359.432 114.05 359.432C112.72 359.432 111.523 360.44 111.523 362.022C111.523 363.604 112.678 364.605 114.064 364.605Z"
                fill="#006DFF"
            />
            <g filter="url(#filter9_dd_1642_8391)">
                <rect x="220" y="351" width="112" height="22" rx="5" fill="white" />
            </g>
            <path
                d="M232 367C234.761 367 237 364.761 237 362C237 359.239 234.761 357 232 357C229.239 357 227 359.239 227 362C227 364.761 229.239 367 232 367ZM232 368C228.686 368 226 365.314 226 362C226 358.686 228.686 356 232 356C235.314 356 238 358.686 238 362C238 365.314 235.314 368 232 368Z"
                fill="#6E3AFF"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M233.434 364.5L233.021 363.366H231.005L230.592 364.5H229.57L231.474 359.537H232.594L234.484 364.5H233.434ZM232.013 360.608L231.327 362.484H232.699L232.013 360.608Z"
                fill="#6E3AFF"
            />
            <rect x="244" y="359" width="82" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter10_dd_1642_8391)">
                <rect x="338" y="351" width="22" height="22" rx="5" fill="white" />
            </g>
            <path
                d="M349 367C351.761 367 354 364.761 354 362C354 359.239 351.761 357 349 357C346.239 357 344 359.239 344 362C344 364.761 346.239 367 349 367ZM349 368C345.686 368 343 365.314 343 362C343 358.686 345.686 356 349 356C352.314 356 355 358.686 355 362C355 365.314 352.314 368 349 368Z"
                fill="#00A861"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M350.091 364.5H351.302L349.321 361.7L351.288 359.537H350.021L348.033 361.784V359.537H347.067V364.5H348.033V363.121L348.649 362.442L350.091 364.5Z"
                fill="#00A861"
            />
            <g filter="url(#filter11_dd_1642_8391)">
                <rect x="196" y="24" width="440" height="158" rx="12" fill="white" />
            </g>
            <rect opacity="0.7" x="196" y="93" width="440" height="1" fill="#F0F0F5" />
            <g clip-path="url(#clip0_1642_8391)">
                <path
                    d="M231.575 52.4791C231.575 54.6387 229.886 56.2301 227.812 56.2301C225.739 56.2301 224.05 54.6387 224.05 52.4791C224.05 50.3042 225.739 48.728 227.812 48.728C229.886 48.728 231.575 50.3042 231.575 52.4791ZM229.928 52.4791C229.928 51.1295 228.949 50.2061 227.812 50.2061C226.676 50.2061 225.697 51.1295 225.697 52.4791C225.697 53.8151 226.676 54.752 227.812 54.752C228.949 54.752 229.928 53.8134 229.928 52.4791Z"
                    fill="#EA4335"
                />
                <path
                    d="M239.693 52.4791C239.693 54.6387 238.003 56.2301 235.93 56.2301C233.857 56.2301 232.167 54.6387 232.167 52.4791C232.167 50.3059 233.857 48.728 235.93 48.728C238.003 48.728 239.693 50.3042 239.693 52.4791ZM238.046 52.4791C238.046 51.1295 237.067 50.2061 235.93 50.2061C234.794 50.2061 233.814 51.1295 233.814 52.4791C233.814 53.8151 234.794 54.752 235.93 54.752C237.067 54.752 238.046 53.8134 238.046 52.4791Z"
                    fill="#FBBC05"
                />
                <path
                    d="M247.472 48.9546V55.6889C247.472 58.4591 245.839 59.5905 243.907 59.5905C242.089 59.5905 240.995 58.3745 240.583 57.3801L242.017 56.7831C242.272 57.3936 242.898 58.1141 243.906 58.1141C245.142 58.1141 245.908 57.3513 245.908 55.9155V55.376H245.851C245.482 55.831 244.772 56.2284 243.875 56.2284C242 56.2284 240.281 54.5947 240.281 52.4926C240.281 50.3752 242 48.728 243.875 48.728C244.77 48.728 245.48 49.1255 245.851 49.5669H245.908V48.9563H247.472V48.9546ZM246.025 52.4926C246.025 51.1718 245.144 50.2061 244.022 50.2061C242.886 50.2061 241.934 51.1718 241.934 52.4926C241.934 53.7999 242.886 54.752 244.022 54.752C245.144 54.752 246.025 53.7999 246.025 52.4926Z"
                    fill="#4285F4"
                />
                <path d="M250.051 45.0073V56H248.445V45.0073H250.051Z" fill="#34A853" />
                <path
                    d="M256.312 53.7134L257.591 54.5657C257.178 55.1762 256.184 56.2281 254.465 56.2281C252.335 56.2281 250.743 54.5809 250.743 52.4771C250.743 50.2464 252.348 48.7261 254.281 48.7261C256.228 48.7261 257.18 50.2752 257.491 51.1123L257.662 51.5385L252.647 53.6153C253.031 54.3678 253.628 54.7517 254.465 54.7517C255.304 54.7517 255.886 54.3391 256.312 53.7134ZM252.377 52.3638L255.729 50.972C255.544 50.5035 254.99 50.1771 254.337 50.1771C253.5 50.1771 252.335 50.9161 252.377 52.3638Z"
                    fill="#EA4335"
                />
                <path
                    d="M217.968 51.5033V49.9119H223.331C223.383 50.1892 223.41 50.5173 223.41 50.8725C223.41 52.0664 223.084 53.5428 222.032 54.5947C221.009 55.6602 219.702 56.2284 217.97 56.2284C214.76 56.2284 212.061 53.6139 212.061 50.404C212.061 47.1941 214.76 44.5796 217.97 44.5796C219.745 44.5796 221.01 45.2764 221.961 46.1845L220.838 47.3075C220.156 46.6682 219.233 46.171 217.968 46.171C215.624 46.171 213.791 48.06 213.791 50.404C213.791 52.748 215.624 54.637 217.968 54.637C219.488 54.637 220.354 54.0265 220.909 53.4718C221.359 53.0219 221.655 52.3793 221.772 51.5016L217.968 51.5033Z"
                    fill="#4285F4"
                />
            </g>
            <rect x="274" y="40" width="346" height="24" rx="12" fill="white" />
            <rect
                x="273.5"
                y="39.5"
                width="347"
                height="25"
                rx="12.5"
                stroke="#F0F0F5"
                stroke-opacity="0.75"
            />
            <rect x="286" y="49" width="72" height="6" rx="3" fill="#ECECEF" />
            <path
                d="M589.5 53.5C590.33 53.5 591 52.845 591 52.015V48.505C591 47.675 590.33 47 589.5 47C588.67 47 588 47.67 588 48.505V52.015C588 52.845 588.67 53.5 589.5 53.5Z"
                fill="#4285F4"
            />
            <path d="M589 55.04H590V57H589V55.04Z" fill="#34A853" />
            <path
                d="M587.025 54.435C586.39 53.77 586 53.02 586 52H587C587 52.725 587.28 53.21 587.735 53.69V53.85L587.16 54.44L587.025 54.435Z"
                fill="#FBBC05"
            />
            <path
                d="M589.5 54.465C589.171 54.466 588.845 54.398 588.541 54.2649C588.238 54.1319 587.962 53.9365 587.73 53.69L587.025 54.435C587.655 55.105 588.535 55.5 589.5 55.5C591.435 55.5 592.995 54.04 592.995 52H592C592 53.46 590.88 54.465 589.5 54.465Z"
                fill="#EA4335"
            />
            <path
                d="M607.25 53H606.855L606.715 52.865C607.222 52.277 607.501 51.5263 607.5 50.75C607.5 50.1072 607.309 49.4789 606.952 48.9444C606.595 48.4099 606.088 47.9934 605.494 47.7474C604.9 47.5014 604.246 47.437 603.616 47.5624C602.986 47.6879 602.406 47.9974 601.952 48.4519C601.497 48.9064 601.188 49.4855 601.062 50.116C600.937 50.7464 601.001 51.3999 601.247 51.9937C601.493 52.5876 601.91 53.0952 602.444 53.4523C602.979 53.8094 603.607 54 604.25 54C605.055 54 605.795 53.705 606.365 53.215L606.5 53.355V53.75L609 56.245L609.745 55.5L607.25 53ZM604.25 53C603.005 53 602 51.995 602 50.75C602 49.505 603.005 48.5 604.25 48.5C605.495 48.5 606.5 49.505 606.5 50.75C606.5 51.995 605.495 53 604.25 53Z"
                fill="#4285F4"
            />
            <g clip-path="url(#clip1_1642_8391)">
                <path
                    d="M287.333 76.6665V77.3332C287.864 77.3332 288.372 77.5439 288.747 77.919C289.123 78.294 289.333 78.8027 289.333 79.3332H290C290 78.6259 289.719 77.9476 289.219 77.4476C288.719 76.9475 288.04 76.6665 287.333 76.6665Z"
                    fill="#34A853"
                />
                <path
                    d="M287.333 77.3332V76.6665C286.626 76.6665 285.948 76.9475 285.448 77.4476C284.948 77.9476 284.667 78.6259 284.667 79.3332H285.333C285.333 78.2332 286.233 77.3332 287.333 77.3332Z"
                    fill="#EA4335"
                />
                <path
                    d="M285.333 79.3335H284.667C284.667 80.0407 284.948 80.719 285.448 81.2191C285.948 81.7192 286.626 82.0002 287.333 82.0002V81.3335C286.233 81.3335 285.333 80.4368 285.333 79.3335Z"
                    fill="#FBBC04"
                />
                <path
                    d="M291.333 82.8635L289.437 80.9668C289.801 80.5005 290 79.9255 290 79.3335H289.333C289.333 79.8639 289.123 80.3726 288.747 80.7477C288.372 81.1228 287.864 81.3335 287.333 81.3335V82.0002C287.95 82.0002 288.507 81.7868 288.96 81.4402L290.857 83.3368L291.333 82.8635Z"
                    fill="#4285F4"
                />
            </g>
            <rect opacity="0.25" x="296" y="78" width="12" height="4" rx="2" fill="#1A73E8" />
            <g opacity="0.32">
                <path
                    d="M326.536 76.9632C326.442 76.8684 326.33 76.7934 326.206 76.7424C326.083 76.6915 325.95 76.6657 325.816 76.6665H323.883C323.613 76.6665 323.356 76.7699 323.163 76.9632L321.916 78.2099L320.466 79.6599C320.276 79.851 320.169 80.1099 320.169 80.3799C320.169 80.6498 320.276 80.9087 320.466 81.0999L322.396 83.0332C322.491 83.1281 322.603 83.2036 322.726 83.2552C322.85 83.3067 322.982 83.3334 323.116 83.3337C323.25 83.3341 323.382 83.308 323.506 83.257C323.629 83.206 323.742 83.131 323.836 83.0365L326.533 80.3365C326.726 80.1432 326.833 79.8899 326.833 79.6165V77.6832C326.833 77.4132 326.726 77.1532 326.536 76.9632ZM326.166 79.6165C326.166 79.7099 326.126 79.7965 326.06 79.8632L324.816 81.1099L323.363 82.5632C323.226 82.6999 323.003 82.6999 322.866 82.5632L321.903 81.5965L320.936 80.6299C320.871 80.5637 320.834 80.4745 320.834 80.3815C320.834 80.2886 320.871 80.1993 320.936 80.1332L323.636 77.4332C323.703 77.3665 323.79 77.3332 323.883 77.3332L325.816 77.3299C325.863 77.3299 325.908 77.339 325.951 77.3568C325.994 77.3746 326.033 77.4007 326.065 77.4335C326.098 77.4664 326.123 77.5054 326.141 77.5482C326.158 77.5911 326.167 77.6369 326.166 77.6832V79.6165ZM324.833 77.9999C325.2 77.9999 325.5 78.2999 325.5 78.6665C325.5 79.0332 325.2 79.3332 324.833 79.3332C324.466 79.3332 324.166 79.0332 324.166 78.6665C324.166 78.2999 324.466 77.9999 324.833 77.9999Z"
                    fill="#5F6368"
                />
            </g>
            <rect x="331.5" y="78" width="30" height="4" rx="2" fill="#ECECEF" />
            <g opacity="0.32" clip-path="url(#clip2_1642_8391)">
                <path
                    d="M377.667 80.3332L379 81.9998H375L376.334 80.6665L376.93 81.2598L377.667 80.3332ZM375.664 79.3365C375.841 79.3369 376.011 79.267 376.137 79.1419C376.262 79.0169 376.333 78.8471 376.334 78.6698C376.334 78.4926 376.264 78.3224 376.139 78.1968C376.014 78.0711 375.844 78.0003 375.667 77.9998C375.49 77.9994 375.32 78.0694 375.194 78.1944C375.068 78.3194 374.997 78.4893 374.997 78.6665C374.997 78.8438 375.067 79.0139 375.192 79.1396C375.317 79.2652 375.486 79.3361 375.664 79.3365ZM380.334 77.6665V82.3332C380.333 82.5978 380.227 82.8513 380.04 83.0381C379.852 83.2249 379.598 83.3298 379.334 83.3298H374.667C374.12 83.3298 373.667 82.8765 373.667 82.3298V77.6665C373.667 77.1198 374.12 76.6665 374.667 76.6665H379.334C379.884 76.6665 380.334 77.1198 380.334 77.6665ZM379.664 77.6665C379.664 77.5781 379.629 77.4933 379.566 77.4308C379.504 77.3683 379.419 77.3332 379.33 77.3332H374.667C374.579 77.3332 374.494 77.3683 374.431 77.4308C374.369 77.4933 374.334 77.5781 374.334 77.6665V82.3332C374.334 82.4216 374.369 82.5064 374.431 82.5689C374.494 82.6314 374.579 82.6665 374.667 82.6665H377V82.6632H379.334C379.422 82.6632 379.507 82.6281 379.569 82.5655C379.632 82.503 379.667 82.4182 379.667 82.3298V77.6665"
                    fill="#5F6368"
                />
            </g>
            <rect x="385" y="78" width="22" height="4" rx="2" fill="#ECECEF" />
            <g opacity="0.32">
                <path
                    d="M422.251 76C423.796 76 425.003 77.2435 425.003 78.7135C425.003 79.882 424.438 80.5605 423.872 81.2385C423.646 81.5025 423.42 81.8035 423.194 82.1055C422.967 82.407 422.816 82.8595 422.703 83.0855C422.59 83.3115 422.515 83.5 422.251 83.5C421.987 83.5 421.912 83.3115 421.799 83.085C421.686 82.8595 421.536 82.407 421.309 82.1055C421.083 81.804 420.857 81.5025 420.631 81.2385C420.066 80.5605 419.5 79.882 419.5 78.7135C419.5 77.2435 420.706 76 422.251 76ZM422.251 76.682C421.11 76.682 420.181 77.593 420.181 78.7135C420.181 79.635 420.612 80.15 421.155 80.802C421.388 81.0755 421.625 81.391 421.855 81.697C422.025 81.923 422.152 82.192 422.251 82.419C422.35 82.1915 422.478 81.923 422.648 81.6965C422.877 81.3915 423.113 81.0755 423.354 80.795C423.891 80.15 424.321 79.635 424.321 78.7135C424.321 77.5935 423.392 76.682 422.251 76.682ZM422.251 78.049C422.341 78.0456 422.43 78.0602 422.514 78.0921C422.597 78.1239 422.674 78.1724 422.738 78.2344C422.803 78.2965 422.854 78.3709 422.889 78.4533C422.924 78.5357 422.942 78.6243 422.942 78.7138C422.942 78.8033 422.924 78.8918 422.889 78.9742C422.854 79.0565 422.802 79.1309 422.738 79.1929C422.673 79.255 422.597 79.3033 422.513 79.3351C422.43 79.3669 422.34 79.3815 422.251 79.378C422.079 79.3713 421.917 79.2984 421.798 79.1745C421.678 79.0506 421.612 78.8854 421.612 78.7135C421.612 78.5416 421.678 78.3764 421.798 78.2525C421.917 78.1286 422.079 78.0557 422.251 78.049"
                    fill="#5F6368"
                />
            </g>
            <rect x="429" y="78" width="20" height="4" rx="2" fill="#ECECEF" />
            <g opacity="0.32">
                <path
                    d="M464.5 79.6667H466.5V80.3333H464.5V79.6667ZM462.5 81.6667H466.5V81H462.5V81.6667ZM462.5 80.3333H463.834V78.3333H462.5V80.3333ZM467.834 77.9267V82.0733C467.834 82.5867 467.387 83 466.834 83H462.167C461.62 83 461.167 82.5833 461.167 82.0733V77.9267C461.167 77.42 461.62 77 462.167 77H466.834C467.38 77 467.834 77.4167 467.834 77.9267ZM467.164 80V77.9267C467.164 77.7867 467.01 77.6667 466.83 77.6667H462.167C461.987 77.6667 461.834 77.7867 461.834 77.9267V82.0733C461.834 82.2133 461.987 82.3333 462.167 82.3333H466.834C467.014 82.3333 467.167 82.2133 467.167 82.0733V80H467.164ZM464.5 79H466.5V78.3333H464.5V79Z"
                    fill="#5F6368"
                />
            </g>
            <rect x="472.5" y="78" width="20" height="4" rx="2" fill="#ECECEF" />
            <g opacity="0.32">
                <path
                    d="M508 78.6668C508.366 78.6668 508.666 78.3668 508.666 78.0002C508.666 77.6335 508.366 77.3335 508 77.3335C507.633 77.3335 507.333 77.6335 507.333 78.0002C507.333 78.3668 507.633 78.6668 508 78.6668ZM508 79.3335C507.633 79.3335 507.333 79.6335 507.333 80.0002C507.333 80.3668 507.633 80.6668 508 80.6668C508.366 80.6668 508.666 80.3668 508.666 80.0002C508.666 79.6335 508.366 79.3335 508 79.3335ZM508 81.3335C507.633 81.3335 507.333 81.6335 507.333 82.0002C507.333 82.3668 507.633 82.6668 508 82.6668C508.366 82.6668 508.666 82.3668 508.666 82.0002C508.666 81.6335 508.366 81.3335 508 81.3335Z"
                    fill="#5F6368"
                />
            </g>
            <rect x="513" y="78" width="16" height="4" rx="2" fill="#ECECEF" />
            <rect x="284" y="92" width="24" height="2" fill="#C6DCF9" />
            <g filter="url(#filter12_dd_1642_8391)">
                <rect x="196" y="194" width="45" height="22" rx="5" fill="white" />
            </g>
            <rect x="204.5" y="201" width="6" height="8" rx="1.5" stroke="black" />
            <circle cx="207.5" cy="206.75" r="0.75" fill="black" />
            <rect x="229" y="207" width="1" height="2" fill="#006DFF" />
            <rect x="226.5" y="208.5" width="6" height="1" rx="0.5" fill="#006DFF" />
            <path
                d="M225.609 206.454L225.86 206.326L225.609 206.454C225.705 206.642 225.858 206.795 226.046 206.891C226.182 206.96 226.317 206.982 226.431 206.992C226.535 207 226.658 207 226.784 207L226.8 207H232.2L232.216 207C232.342 207 232.465 207 232.569 206.992C232.683 206.982 232.818 206.96 232.954 206.891C233.142 206.795 233.295 206.642 233.391 206.454C233.46 206.318 233.482 206.183 233.492 206.069C233.5 205.965 233.5 205.842 233.5 205.716L233.5 205.7V202.3L233.5 202.284C233.5 202.158 233.5 202.035 233.492 201.931C233.482 201.817 233.46 201.682 233.391 201.546C233.295 201.358 233.142 201.205 232.954 201.109L232.73 201.549L232.954 201.109C232.818 201.04 232.683 201.018 232.569 201.008C232.465 201 232.342 201 232.216 201L232.2 201H226.8C226.795 201 226.789 201 226.784 201C226.658 201 226.535 201 226.431 201.008C226.317 201.018 226.182 201.04 226.046 201.109C225.858 201.205 225.705 201.358 225.609 201.546C225.54 201.682 225.518 201.817 225.508 201.931C225.5 202.035 225.5 202.158 225.5 202.284C225.5 202.289 225.5 202.295 225.5 202.3V205.7L225.5 205.716C225.5 205.842 225.5 205.965 225.508 206.069C225.518 206.183 225.54 206.318 225.609 206.454Z"
                stroke="#006DFF"
            />
            <rect opacity="0.7" x="218" y="194" width="1" height="22" fill="#F0F0F5" />
            <g filter="url(#filter13_dd_1642_8391)">
                <rect x="247" y="194" width="44.5" height="22" rx="5" fill="white" />
            </g>
            <path
                d="M259.5 202L256.5 205L259.5 208"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M279 202L282 205L279 208"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect opacity="0.7" x="269" y="194" width="1" height="22" fill="#F0F0F5" />
            <g filter="url(#filter14_dd_1642_8391)">
                <rect x="582" y="194" width="54" height="22" rx="5" fill="white" />
            </g>
            <rect x="605" y="202" width="24" height="6" rx="3" fill="#00A861" />
            <circle cx="594" cy="205" r="5" fill="#00A861" />
            <path
                d="M592.409 205.227L593.318 206.136L595.591 203.864"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <g filter="url(#filter15_dd_1642_8391)">
                <rect x="12" y="12" width="140" height="318" rx="8" fill="white" />
            </g>
        </g>
        <mask
            id="mask1_1642_8391"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="12"
            y="12"
            width="140"
            height="318"
        >
            <rect x="12" y="12" width="140" height="318" rx="8" fill="white" />
        </mask>
        <g mask="url(#mask1_1642_8391)">
            <line x1="12" y1="112.5" x2="152" y2="112.5" stroke="#F7F7F9" />
            <g filter="url(#filter16_dd_1642_8391)">
                <rect x="24" y="34" width="116" height="22" rx="5" fill="white" />
            </g>
            <rect x="24" y="24" width="26" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter17_dd_1642_8391)">
                <rect x="24" y="78" width="52" height="22" rx="5" fill="white" />
            </g>
            <rect x="24" y="68" width="26" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter18_dd_1642_8391)">
                <rect x="88" y="78" width="52" height="22" rx="5" fill="white" />
            </g>
            <rect x="88" y="68" width="26" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter19_dd_1642_8391)">
                <rect x="24" y="135" width="116" height="22" rx="5" fill="white" />
            </g>
            <rect x="24" y="125" width="26" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter20_dd_1642_8391)">
                <rect x="24" y="179" width="116" height="22" rx="5" fill="white" />
            </g>
            <rect x="24" y="169" width="26" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter21_dd_1642_8391)">
                <rect x="24" y="223" width="116" height="22" rx="5" fill="white" />
            </g>
            <rect x="24" y="213" width="26" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter22_dd_1642_8391)">
                <rect x="24" y="267" width="116" height="22" rx="5" fill="white" />
            </g>
            <rect x="24" y="257" width="26" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter23_dd_1642_8391)">
                <rect x="24" y="311" width="116" height="22" rx="5" fill="white" />
            </g>
            <rect x="24" y="301" width="26" height="4" rx="2" fill="#ECECEF" />
        </g>
        <defs>
            <filter
                id="filter0_dd_1642_8391"
                x="195"
                y="234"
                width="442"
                height="221"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_dd_1642_8391"
                x="245"
                y="288"
                width="342"
                height="87"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_dd_1642_8391"
                x="211"
                y="250"
                width="47"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_dd_1642_8391"
                x="262"
                y="250"
                width="46.5"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter4_dd_1642_8391"
                x="559"
                y="250"
                width="62"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter5_dd_1642_8391"
                x="4"
                y="338"
                width="672"
                height="56"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="4"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="6" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter6_dd_1642_8391"
                x="537"
                y="350"
                width="42"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter7_d_1642_8391"
                x="583"
                y="350.5"
                width="74"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter8_dd_1642_8391"
                x="101"
                y="350"
                width="114"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter9_dd_1642_8391"
                x="219"
                y="350"
                width="114"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter10_dd_1642_8391"
                x="337"
                y="350"
                width="24"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter11_dd_1642_8391"
                x="195"
                y="23"
                width="442"
                height="161"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter12_dd_1642_8391"
                x="195"
                y="193"
                width="47"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter13_dd_1642_8391"
                x="246"
                y="193"
                width="46.5"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter14_dd_1642_8391"
                x="581"
                y="193"
                width="56"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter15_dd_1642_8391"
                x="11"
                y="11"
                width="142"
                height="321"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter16_dd_1642_8391"
                x="23"
                y="33"
                width="118"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter17_dd_1642_8391"
                x="23"
                y="77"
                width="54"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter18_dd_1642_8391"
                x="87"
                y="77"
                width="54"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter19_dd_1642_8391"
                x="23"
                y="134"
                width="118"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter20_dd_1642_8391"
                x="23"
                y="178"
                width="118"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter21_dd_1642_8391"
                x="23"
                y="222"
                width="118"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter22_dd_1642_8391"
                x="23"
                y="266"
                width="118"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <filter
                id="filter23_dd_1642_8391"
                x="23"
                y="310"
                width="118"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1642_8391"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1642_8391"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1642_8391"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1642_8391"
                    result="effect2_dropShadow_1642_8391"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1642_8391"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_1642_8391">
                <rect width="46" height="16" fill="white" transform="translate(212 44)" />
            </clipPath>
            <clipPath id="clip1_1642_8391">
                <rect width="8" height="8" fill="white" transform="translate(284 76)" />
            </clipPath>
            <clipPath id="clip2_1642_8391">
                <rect width="8" height="8" fill="white" transform="translate(373 76)" />
            </clipPath>
        </defs>
    </svg>
</template>
