<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="false"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="header">
                <div v-if="aboveMobile">
                    <EntityPill type="campaign-group" :content="campaignGroupName" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    In this campaign group, your
                    <span v-if="isShopping">product</span> ads are being triggered by search terms
                    containing the n-gram listed below. This n-gram has spent a significant amount,
                    but converted few searchers by comparison. Consider reducing your bids to limit
                    wasted spend.
                </Text>
                <Spacer height="2rem" />
                <EntityPillList
                    v-if="!aboveMobile && ngramText.length > 30"
                    :items="[{ type: 'word', content: ngramText }]"
                />
                <EntityPill v-else type="n-gram" :content="ngramText" />
                <Spacer height="2rem" />
                <Text as="p">
                    Click <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    reduce bids by <b>10.00%</b> for all keywords containing this word. If you
                    consider this improvement to be a false positive, feel free to <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2185221-reduce-root-word-bids">
                Click here to learn more about decreasing n-gram bids in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <!-- Chart tooltip currency must match account currency -->
            <SegmentsChart
                v-if="performanceMode === PerformanceMode.CPA"
                :items="scatterPlotItems"
                :graphKeys="
                    hasTarget
                        ? ['Below CPA Target', 'Above CPA Target', 'Needs Attention']
                        : ['Below CPA Average', 'Above CPA Average', 'Needs Attention']
                "
                :target="campaignGroupTarget"
                :axis="{
                    x: {
                        dataType: 'percentage',
                        label: hasTarget
                            ? 'Percentage Difference from CPA Target'
                            : 'Percentage Difference from CPA Average',
                    },
                    y: { currency, dataType: 'money', label: 'Cost' },
                }"
                :metric="{ dataType: 'percentage', inverted: true, label: 'CPA' }"
            />
            <SegmentsChart
                v-if="performanceMode === PerformanceMode.ROAS"
                :items="scatterPlotItems"
                :graphKeys="
                    hasTarget
                        ? ['Needs Attention', 'Below ROAS Target', 'Above ROAS Target']
                        : ['Needs Attention', 'Below ROAS Average', 'Above ROAS Average']
                "
                :target="campaignGroupTarget"
                :axis="{
                    x: {
                        dataType: 'percentage',
                        label: hasTarget
                            ? 'Percentage Difference from ROAS Target'
                            : 'Percentage Difference from ROAS Average',
                    },
                    y: { currency, dataType: 'money', label: 'Cost' },
                }"
                :metric="{ dataType: 'percentage', inverted: false, label: 'ROAS' }"
            />
            <Spacer height="2rem" />
            <ImprovementNote v-if="performanceMode === PerformanceMode.ROAS">
                If you are actively targeting searches related to this n-gram, and spending
                <b><Money :value="ngramCost" :currency="currency" /></b> for
                <b><Money :value="ngramConversionsValue" /></b> in conversion value is acceptable,
                feel free to <b>Dismiss</b> this improvement.
            </ImprovementNote>
            <ImprovementNote v-else>
                If you are actively targeting searches related to this n-gram, and spending
                <b><Money :value="ngramCost" :currency="currency" /></b> for
                <b><Number :value="ngramConversions" /></b> conversion<span
                    v-if="ngramConversions !== 1"
                    >s</span
                >
                is acceptable, feel free to <b>Dismiss</b> this improvement.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="stats">
                <!-- ngram Cost -->
                <template v-slot:cost>
                    <b><Money :value="ngramCost" /></b> was spent on this word over the last
                    <b>{{ dataPeriod }}</b
                    >.
                </template>

                <!-- ngram Conversions (CPA)-->
                <template v-slot:conversions>
                    Searches containing this word generated
                    <b><Number :value="ngramConversions" /></b> conversion<span
                        v-if="ngramConversions !== 1"
                        >s</span
                    >
                    over the last <b>{{ dataPeriod }}</b
                    >.
                </template>

                <!-- ngram conversionsValue (ROAS) -->
                <template v-slot:conversionsValue>
                    Searches containing this word generated
                    <b><Money :value="ngramConversionsValue" /></b> value over the last
                    <b>{{ dataPeriod }}</b
                    >.
                </template>

                <!-- ngram CPA (CPA) -->
                <template v-slot:wordCpa>
                    Over the last <b>{{ dataPeriod }}</b
                    >, this n-gram has an average CPA of <b><Money :value="ngramCpa" /></b>.
                </template>

                <!-- ngram ROAS (ROAS) -->
                <template v-slot:wordRoas>
                    Over the last <b>{{ dataPeriod }}</b
                    >, this n-gram has an average ROAS of <b><Roas :value="ngramRoas" /></b>.
                </template>

                <!-- campaign group target/avg CPA -->
                <template v-slot:targetCpa v-if="hasTarget">
                    The current target CPA for this campaign group is
                    <b><Money :value="campaignGroupTarget" /></b>.
                </template>
                <template v-slot:targetCpa v-else>
                    The average CPA for this campaign group is
                    <b><Money :value="campaignGroupTarget" /></b>.
                </template>

                <!-- campaign group target/avg ROAS -->
                <template v-slot:targetRoas v-if="hasTarget">
                    The current target ROAS for this campaign group is
                    <b><Roas :value="campaignGroupTarget" /></b>.
                </template>
                <template v-slot:targetRoas v-else>
                    The average ROAS for this campaign group is
                    <b><Roas :value="campaignGroupTarget" /></b>.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3">Keyword{{ keywordCount > 1 ? 's' : '' }} Containing N-Gram</Text>
            <Spacer height="2rem" />
            <Text as="p">
                All campaigns with keywords containing this n-gram are listed below.
            </Text>
            <div v-for="group of keywordsByCampaign" :key="group.campaignName">
                <Spacer height="2rem" />
                <div class="campaign-container">
                    <div>
                        <EntityPill type="campaign" :content="group.campaignName" />
                        <Spacer height="1.5rem" />
                    </div>
                    <oTable
                        :headers="tableHeaders"
                        :items="group.keywordTableItems"
                        :fixed-layout="aboveMobile ? true : false"
                        responsive-breakpoint="768px"
                    >
                        <template #column.keyword="cellData">
                            <div class="column-content">
                                {{ cellData.value }}
                            </div>
                        </template>
                        <template #column.conversions="cellData">
                            <div class="column-content">
                                {{ cellData.value }}
                            </div>
                        </template>
                        <template #column.conversionsValue="cellData">
                            <div class="column-content">
                                <Money :value="cellData.value" />
                            </div>
                        </template>
                        <template #column.cost="cellData">
                            <div class="column-content">
                                <Money :value="cellData.value" />
                            </div>
                        </template>
                        <template #column.currentBid="cellData">
                            <div class="column-content">
                                <Money :value="cellData.value" />
                            </div>
                        </template>
                        <template #column.newBid="cellData">
                            <div class="column-content">
                                <Money :value="cellData.value" />
                            </div>
                        </template>
                        <!-- <template #header.winner>
                            <Text as="span" size="f-8" weight="500" color="green">Winning Ad</Text>
                        </template> -->
                    </oTable>
                </div>
            </div>
            <Spacer height="2rem" />
            <ImprovementNote>
                <b>Note:</b> The total cost and
                {{ performanceMode === PerformanceMode.ROAS ? 'value' : 'conversions' }}
                of {{ keywordCount > 1 ? 'these keywords are' : 'this keyword are' }} likely to be
                smaller than that of the n-gram. This is because other keywords, even if they do not
                include this n-gram, may have triggered search terms with
                {{ nGramWordCount > 1 ? 'these words' : 'this word' }}. This behaviour is expected.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="window" />
        </ImprovementContent>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
    ScatterPointChart,
    EntityPill,
    EntityPillList,
    EntityTransform,
    oInput,
    Number,
    Spacer,
    Text,
    SegmentsChart,
    oTable,
    Roas,
} from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import Ent from '@/components/util/Ent.vue'
import { useReduceNgramBids } from '@/composition/improvement/types/ngram/useReduceNgramBids'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default defineComponent({
    name: 'ReduceNgramBids',
    components: {
        ScatterPointChart,
        ImprovementView,
        ImprovementHelpLink,
        EntityPill,
        EntityPillList,
        EntityTransform,
        oInput,
        Ent,
        Money,
        Number,
        Spacer,
        Text,
        SegmentsChart,
        oTable,
        Roas,
    },
    setup() {
        return {
            ...useReduceNgramBids(),
            ...useMediaQuery(),
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaign-container {
    @include container;
    @include pa-24;
}

@media screen and (min-wdith: $mq-768-min) {
    .column-content {
        overflow: hidden;
    }

    .column-content::after {
        content: '';
        background: linear-gradient(270deg, #fff 0.5rem, rgba(255, 255, 255, 0) 100%);
        @include absolute;
        top: 0;
        right: 0;
        width: 2rem;
        bottom: 0;
    }
}
</style>
