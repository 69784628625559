import { computed, ComputedRef, inject, ref } from 'vue'
import round from 'lodash/round'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { DemoBids, Improvement, Targets } from '@opteo/types'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { CurrencyCode } from '@/composition/utils/types'
import { DonutChartTypes, useRoas } from '@opteo/components-next'
import { ProvideKeys } from '@/composition/useProvide'
import { getDemographicsStatistics, getExclusionTableHeaders, sortDemos } from './utils'
interface AdjustDemoBids {
    body: DemoBids.DemoBodies
    single: boolean
    entity: 'adgroup' | 'campaign'
}

enum InvalidBidReasonRaw {
    EXCLUDED = 'SEGMENT_EXCLUDED',
    PLACEMENT_COST_OVER_ENVIRONMENT_THRESHOLD = 'PLACEMENT_COST_OVER_ENVIRONMENT_THRESHOLD',
    MINIMUM_CLICKS_NOT_MET = `MINIMUM_CLICKS_NOT_MET`,
}

export enum InvalidBidReason {
    DISABLED = 'This demographic is disabled.',
    EXCLUDED = 'This demographic has already been excluded in Google Ads.',
    PLACEMENT_COST_OVER_ENVIRONMENT_THRESHOLD = 'This demographic has been excluded because it represents more than 35% of the total cost of the parent entity.',
}

interface TableItems {
    [key: string]: any
}

interface useExcludeDemoBids {
    dynamicSegmentColumn: string
    entity: 'adgroup' | 'campaign'
    entityLabel: 'ad group' | 'campaign'
    entityName: string
    demographic: DemoBids.AllowedCriteriaTypes
    demographicLabel: string
    entityPillList: { content: string; type: string }[]
    tableHeaders: { key: string; text?: string; sortable: boolean }[]
    segment: DemoBids.AllowedCriteriaTypes
    highlightedSegment?: string
    tableItems: TableItems[]
    donutChartItems: DonutChartTypes.DonutChartItem[]
    domainCurrencyCode?: CurrencyCode
    segmentBetterThanAverage: boolean
    formattedSegmentCpa: ComputedRef<string>
    formattedAdGroupCpa: ComputedRef<string>
    formattedAvgAdGroupsCpa: ComputedRef<string>
    formattedTargetCpa: ComputedRef<string>
    useDomainMoney: () => ComputedRef<any>
    improvementStatisticsItems: ComputedRef<
        {
            key: string
            value: string
            title: string
        }[]
    >
    comparisonCpaLabel: string
    segmentToAdGroupPerformanceRatio: number
    formattedComparisonCpa: ComputedRef<string>
    segmentToCampaignGroupPerformanceRatio: number
    campaignGroupName: string
    lookbackWindow: Improvement.LookbackWindow
    campaignGroupCpa: number
    avgAdGroupCpa: number
    segmentCpa: number
    singleSegmentExclusion?: DemoBids.HighVolumeBody | DemoBids.LowVolumeBody
    isUsingROAS: boolean
}

type PlacementWithMocked<T> = T & { mocked?: boolean }

interface MissingDemo {
    placement: string
    criteria: number
    ad_group_placement_cost: number
    ad_group_placement_conversions: number
    ad_group_placement_impressions: number
    ad_group_placement_all_conversions_value: number
    ad_group_placement_cpa: number
    ad_group_placement_roas: number
    current_bid: number
    new_bid_mod: number
    invalid: boolean
    mocked: boolean
    is_exclude?: boolean
    reason: string
}

export function useExcludeDemoBids() {
    const { improvement, lastUpdated, title } = useImprovement<AdjustDemoBids['body']>()
    const { body, rec_action } = checkImprovement(improvement)

    const missingPlacements: MissingDemo[] = []
    const [generalPlacementData] = body
    const segment = generalPlacementData.demographic
    const lookback_window = generalPlacementData.window
    const AGE_ENTITIES = [
        '18 to 24',
        '25 to 34',
        '35 to 44',
        '45 to 54',
        '55 to 64',
        '65+',
        'Unknown',
    ]
    const GENDER_ENTITIES = ['Male', 'Female', 'Unknown']
    const INCOME_ENTITIES = [
        'Lower 50%',
        '41-50%',
        '31-40%',
        '21-30%',
        '11-20%',
        'Top 10%',
        'Unknown',
    ]
    const PARENTAL_ENTITIES = ['Parent', 'Not a parent', 'Unknown']

    const calculateCPA = function ({ cost, conversions }: { cost: number; conversions: number }) {
        return cost ? cost / conversions : 0
    }

    const calculateROAS = function ({
        cost,
        conversionsValue,
    }: {
        cost: number
        conversionsValue: number
    }) {
        return conversionsValue ? conversionsValue / cost : 0
    }

    function fillMissingPlacements(body: DemoBids.DemoBodies) {
        let ENTITY_TYPE_TO_CHECK = AGE_ENTITIES
        if (segment === DemoBids.AllowedCriteriaTypes.GENDER) {
            ENTITY_TYPE_TO_CHECK = GENDER_ENTITIES
        } else if (segment === DemoBids.AllowedCriteriaTypes.INCOME) {
            ENTITY_TYPE_TO_CHECK = INCOME_ENTITIES
        } else if (segment === DemoBids.AllowedCriteriaTypes.PARENTAL_STATUS) {
            ENTITY_TYPE_TO_CHECK = PARENTAL_ENTITIES
        }

        const missingEntities = ENTITY_TYPE_TO_CHECK.filter(placement => {
            return !body.find(placement_data => {
                return placement === placement_data.placement
            })
        })

        if (missingEntities.length > 0) {
            missingEntities.forEach(missing => {
                const placement = {
                    placement: missing,
                    criteria: 0,
                    ad_group_placement_cost: 0,
                    ad_group_placement_conversions: 0,
                    ad_group_placement_impressions: 0,
                    ad_group_placement_all_conversions_value: 0,
                    ad_group_placement_cpa: 0,
                    ad_group_placement_roas: 0,
                    current_bid: 0,
                    new_bid_mod: 0,
                    invalid: true,
                    is_exclude: false,
                    mocked: true,
                    reason: 'MISSING_IN_BODY',
                }

                missingPlacements.push(placement)
            })
        }
    }

    fillMissingPlacements(body)

    const {
        ad_group_id,
        ad_group,
        campaign_name,
        demographic,
        performance_mode: performanceMode,
        ad_group_conversions,
        ad_group_cost,
        ad_group_conversions_value,
        campaign_group_cpa: campaignGroupCpa,
        campaign_group: campaign_group_name,
        target,
    } = generalPlacementData as DemoBids.HighVolumeBody

    const isUsingROAS = performanceMode === Targets.PerformanceMode.ROAS

    const singleSegmentExclusion = body.find(placement => placement.is_exclude)

    function instanceOfCampaign(object: any): object is DemoBids.LowVolumeBody {
        return object?.items_in_placement
    }

    function instanceOfAdGroup(object: any): object is DemoBids.HighVolumeBody[] {
        return !object[0]?.items_in_placement
    }
    const entity = ad_group_id ? 'adgroup' : 'campaign'

    const entityLabel = entity === 'adgroup' ? 'ad group' : 'campaign'

    const entityName = entity === 'adgroup' ? ad_group : campaign_name

    const avgAdGroupCpa = calculateCPA({ cost: ad_group_cost, conversions: ad_group_conversions })
    const avgAdGroupROAS = calculateROAS({
        cost: ad_group_cost,
        conversionsValue: ad_group_conversions_value,
    })

    const demographicLabel = demographic.replace('_', ' ')

    const entityPillList = instanceOfCampaign(generalPlacementData)
        ? generalPlacementData?.items_in_placement?.map(ad_group => {
              return { content: ad_group.name, type: 'adgroup' }
          })
        : []

    const sortedBody = sortDemos(segment, body, missingPlacements)

    const dynamicSegmentColumn = `column.${segment}`
    const dynamicSegmentHeader = `header.${segment}`

    const segmentLabel = computed(() => {
        switch (segment) {
            case 'age':
                return 'Age Group'
            case 'gender':
                return 'Gender'
            case 'income':
                return 'Income Group'
            case 'parental_status':
                return 'Parental Status'
            default:
                break
        }
    })

    const tableHeaders = getExclusionTableHeaders({
        performanceMode,
        segment,
        segmentLabel,
        isUsingROAS,
        recAction: rec_action,
    })

    const highlightedSegment = singleSegmentExclusion?.placement

    const usingTarget = generalPlacementData.target_set

    let donutChartItems = sortedBody.map(
        (placement_data: PlacementWithMocked<(typeof sortedBody)[0]>) => {
            return {
                y: !placement_data.mocked ? placement_data.ad_group_placement_cost! : 0,
                label: placement_data.placement,
                highlighted: placement_data?.placement === singleSegmentExclusion?.placement,
            }
        }
    )

    const domainCurrencyCode = inject<CurrencyCode>(ProvideKeys.CurrencyCode)

    const segmentCpa = singleSegmentExclusion
        ? calculateCPA({
              cost: singleSegmentExclusion.ad_group_placement_cost,
              conversions: singleSegmentExclusion.ad_group_placement_conversions,
          })
        : 0

    const segmentROAS = singleSegmentExclusion
        ? calculateROAS({
              cost: singleSegmentExclusion.ad_group_placement_cost,
              conversionsValue: singleSegmentExclusion.ad_group_placement_all_conversions_value,
          })
        : 0

    const campaignGroupName = campaign_group_name
    const targetCpa = target
    const targetROAS = target

    const { displayValue: formattedTargetCpa } = useDomainMoney({
        value: targetCpa,
    }).value

    const formattedTargetROAS = useRoas({
        value: targetROAS,
    }).displayValue.value

    function checkIfSegmentIsBetterThanAverage() {
        if (performanceMode === Targets.PerformanceMode.ROAS) {
            return segmentROAS > avgAdGroupROAS
        }
        return segmentCpa < avgAdGroupCpa
    }

    const getInvalidMessage = function (reason: string, mocked?: boolean) {
        if (reason === InvalidBidReasonRaw.EXCLUDED) {
            return { reason: InvalidBidReason.EXCLUDED, width: 190 }
        } else if (mocked) {
            return { reason: InvalidBidReason.DISABLED, width: 190 }
        } else if (reason === InvalidBidReasonRaw.PLACEMENT_COST_OVER_ENVIRONMENT_THRESHOLD) {
            return {
                reason: InvalidBidReason.PLACEMENT_COST_OVER_ENVIRONMENT_THRESHOLD,
                width: 230,
            }
        }
        return { width: 0, reason: '' }
    }

    const segmentBetterThanAverage = checkIfSegmentIsBetterThanAverage()

    const segmentToAdGroupPerformanceRatio =
        performanceMode === Targets.PerformanceMode.ROAS
            ? avgAdGroupROAS > 0
                ? Math.abs(segmentROAS - avgAdGroupROAS) / avgAdGroupROAS
                : 0
            : avgAdGroupCpa > 0
            ? Math.abs(segmentCpa - avgAdGroupCpa) / avgAdGroupCpa
            : 0

    const segmentToCampaignGroupPerformanceRatio =
        performanceMode === Targets.PerformanceMode.CPA
            ? Math.abs(segmentCpa - target) / target
            : Math.abs(segmentROAS - target) / target

    let tableItems = computed(() =>
        sortedBody.map((placement: PlacementWithMocked<(typeof sortedBody)[0]>) => {
            const placementCPA = !placement.mocked
                ? calculateCPA({
                      cost: placement.ad_group_placement_cost,
                      conversions: placement.ad_group_placement_conversions,
                  })
                : 0

            const placementROAS = !placement.mocked
                ? calculateROAS({
                      cost: placement.ad_group_placement_cost,
                      conversionsValue: placement.ad_group_placement_all_conversions_value,
                  })
                : 0

            const entityToCompareCPA = avgAdGroupCpa
            const entityToCompareROAS = avgAdGroupROAS

            const { reason, width } = getInvalidMessage(placement.reason, placement.mocked)

            return {
                id: placement.placement,
                [`${segment}`]: placement.placement,
                cost: placement.ad_group_placement_cost,
                impressions: placement.ad_group_placement_impressions,
                conversions: placement.ad_group_placement_conversions,
                conversions_value: placement.ad_group_placement_all_conversions_value,
                cpa: placementCPA,
                roas: placementROAS,
                'avg-cpa': entityToCompareCPA,
                'avg-roas': entityToCompareROAS,
                difference_cpa: calculateDifferencePercentage({
                    entity: placementCPA,
                    environment: entityToCompareCPA,
                }),
                difference_roas: calculateDifferencePercentage({
                    entity: placementROAS,
                    environment: entityToCompareROAS,
                }),
                action: {
                    id: placement.criteria,
                    width,
                    exclude: placement?.is_exclude ?? false,
                    message: placement?.invalid ? reason : '',
                },
            }
        })
    )

    function calculateDifferencePercentage({
        entity,
        environment,
    }: {
        entity: number
        environment: number
    }) {
        return (entity - environment) / environment
    }

    const showSegmentCpa = computed(() => entity === 'adgroup' || entity === 'campaign')
    const showAdgroupCpa = computed(() => entity === 'adgroup')
    const showAvgAdgroupCpa = computed(() => entity === 'campaign')

    const showCampaignGroupCpa = computed(
        () => (entity === 'adgroup' && usingTarget) || (entity === 'campaign' && usingTarget)
    )

    const { displayValue: formattedSegmentCpa } = useDomainMoney({
        value: segmentCpa,
    }).value

    const formattedSegmentROAS = useRoas({
        value: segmentROAS,
    }).displayValue.value

    const { displayValue: formattedAdGroupCpa } = useDomainMoney({
        value: avgAdGroupCpa,
    }).value

    const formattedAdGroupROAS = useRoas({
        value: avgAdGroupROAS,
    }).displayValue.value

    const { displayValue: formattedAvgAdGroupsCpa } = useDomainMoney({
        value: avgAdGroupCpa,
    }).value

    const formattedAvgAdGroupsROAS = useRoas({
        value: avgAdGroupROAS,
    }).displayValue.value

    const comparisonCpaLabel = 'average ad group'

    const formattedComparisonCpa =
        entity === 'campaign' ? formattedAvgAdGroupsCpa : formattedAdGroupCpa

    const formattedComparisonROAS =
        entity === 'campaign' ? formattedAvgAdGroupsROAS : formattedAdGroupROAS

    const monthsInWindow = lookback_window / 30

    const conversionLoss = singleSegmentExclusion
        ? round(singleSegmentExclusion?.ad_group_placement_conversions / monthsInWindow, 1)
        : 0
    const conversionValueLoss = singleSegmentExclusion
        ? singleSegmentExclusion?.ad_group_placement_all_conversions_value / monthsInWindow
        : 0
    const costSavings = singleSegmentExclusion
        ? singleSegmentExclusion?.ad_group_placement_cost / monthsInWindow
        : 0
    const costSavingPercent = singleSegmentExclusion
        ? -singleSegmentExclusion?.ad_group_placement_cost / ad_group_cost
        : 0

    const cpaBefore = avgAdGroupCpa
    const roasBefore = avgAdGroupROAS
    const cpaAfter = singleSegmentExclusion
        ? (ad_group_cost - singleSegmentExclusion?.ad_group_placement_cost) /
          (ad_group_conversions - singleSegmentExclusion?.ad_group_placement_conversions)
        : 0
    const roasAfter = singleSegmentExclusion
        ? (ad_group_conversions_value -
              singleSegmentExclusion?.ad_group_placement_all_conversions_value) /
          (ad_group_cost - singleSegmentExclusion?.ad_group_placement_cost)
        : 0
    const cpaDecreasePercent = cpaAfter / cpaBefore - 1
    const roasIncreasePercent = roasAfter / roasBefore - 1

    const improvementStatisticsItems = computed(() =>
        getDemographicsStatistics(
            showSegmentCpa,
            performanceMode === Targets.PerformanceMode.ROAS
                ? formattedSegmentROAS
                : formattedSegmentCpa.value,
            showAdgroupCpa,
            performanceMode === Targets.PerformanceMode.ROAS
                ? formattedAdGroupROAS
                : formattedAdGroupCpa.value,
            showAvgAdgroupCpa,
            performanceMode === Targets.PerformanceMode.ROAS
                ? formattedAvgAdGroupsROAS
                : formattedAvgAdGroupsCpa.value,
            showCampaignGroupCpa,
            performanceMode === Targets.PerformanceMode.ROAS
                ? formattedTargetROAS
                : formattedTargetCpa.value,
            performanceMode
        )
    )

    const pushActionText = ref('Apply Exclusion')

    const pushMessages = computed(() => [
        `Connecting to Google Ads..`,
        `Applying demographic exclusion..`,
        `Confirming changes..`,
        `Exclusion applied successfully.`,
    ])

    const updateExlusions = (placement: number, value: boolean) => {
        sortedBody.forEach(item => {
            if (item.criteria === placement) {
                item.is_exclude = value
            }
        })

        const numberOfExclusions = sortedBody.filter(d => d.is_exclude)

        pushActionText.value =
            numberOfExclusions.length > 1 ? 'Apply Exclusions' : 'Apply Exclusion'
    }
    const onPush: OnPushHandler = () => {
        let valid = true

        const placementsToExclude = instanceOfAdGroup(sortedBody)
            ? sortedBody
                  .filter((placementData: any) => placementData.is_exclude)
                  .map(excludedPlacement => {
                      return {
                          ad_group_criteria_resource_name: excludedPlacement.resource_name,
                      }
                  })
            : sortedBody
                  .filter(placementData => {
                      if (!instanceOfCampaign(placementData)) {
                          return
                      }
                      return placementData.is_exclude
                  })
                  .flatMap(excludedPlacement => {
                      if (!instanceOfCampaign(excludedPlacement)) {
                          return
                      }
                      return excludedPlacement?.items_in_placement?.map(items => {
                          return {
                              ad_group_criteria_resource_name: items.resource_name,
                          }
                      })
                  })
                  .filter(value => value)

        if (!singleSegmentExclusion || !placementsToExclude.length) {
            valid = false
        }

        return {
            valid,
            pushedData: placementsToExclude,
        }
    }

    return {
        dynamicSegmentColumn,
        dynamicSegmentHeader,
        segmentLabel,
        title,
        entity,
        entityLabel,
        entityName,
        demographic,
        demographicLabel,
        entityPillList,
        segment,
        highlightedSegment,
        tableHeaders,
        tableItems,
        donutChartItems,
        domainCurrencyCode,
        segmentBetterThanAverage,
        segmentToAdGroupPerformanceRatio,
        segmentROAS,
        avgAdGroupROAS,
        targetROAS,
        formattedSegmentCpa,
        formattedAdGroupCpa,
        formattedAvgAdGroupsCpa,
        formattedTargetCpa,
        formattedTargetROAS,
        formattedSegmentROAS,
        formattedAdGroupROAS,
        formattedAvgAdGroupsROAS,
        comparisonCpaLabel,
        formattedComparisonCpa,
        formattedComparisonROAS,
        segmentToCampaignGroupPerformanceRatio,
        improvementStatisticsItems,
        campaignGroupName,
        lookbackWindow: lookback_window,
        pushMessages,
        onPush,
        lastUpdated,
        campaignGroupCpa,
        avgAdGroupCpa,
        segmentCpa,
        singleSegmentExclusion,
        //@ts-ignore
        useDomainMoney,
        performanceMode,
        isUsingROAS,
        Targets,
        pushActionText,
        // Impact paragraph
        conversionLoss,
        conversionValueLoss,
        costSavings,
        costSavingPercent,
        cpaBefore,
        roasBefore,
        cpaAfter,
        roasAfter,
        cpaDecreasePercent,
        roasIncreasePercent,
        updateExlusions,
    }
}
