<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
        <path
            d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM9.35,11.91l-.93.35a2.47,2.47,0,0,1-.81.12A1.63,1.63,0,0,1,6.52,12a1.12,1.12,0,0,1-.39-.88,3.19,3.19,0,0,1,0-.42q0-.21.09-.48l.49-1.72c0-.16.08-.32.11-.47a3,3,0,0,0,0-.4.63.63,0,0,0-.14-.46.78.78,0,0,0-.51-.13,1.32,1.32,0,0,0-.39.06l-.34.11.13-.53c.32-.13.62-.24.91-.33a2.83,2.83,0,0,1,.83-.14,1.62,1.62,0,0,1,1.08.34,1.14,1.14,0,0,1,.38.88c0,.07,0,.21,0,.39a2.66,2.66,0,0,1-.1.52l-.48,1.71a4.68,4.68,0,0,0-.11.47,2.51,2.51,0,0,0,0,.4.59.59,0,0,0,.15.47.88.88,0,0,0,.53.12,1.62,1.62,0,0,0,.4-.06l.32-.11ZM9.26,5a1.13,1.13,0,0,1-.81.32A1.17,1.17,0,0,1,7.63,5a1,1,0,0,1,0-1.52,1.17,1.17,0,0,1,.82-.32,1.13,1.13,0,0,1,.81.32A1,1,0,0,1,9.26,5Z"
            :fill="hex"
        />
    </svg>
</template>

<script lang="ts">
export default {
    props: {
        hex: {
            type: String,
            required: false,
            default: '#dddfe4',
        },
    },
}
</script>
