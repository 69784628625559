import { Scorecard } from '@opteo/types'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'

export function useScorecardList() {
    const { domainId } = useDomain()

    const {
        data: scorecardData,
        loading: loadingScorecardList,
        error,
        mutate: mutateScorecardList,
    } = useAPI<{
        scorecardList: Scorecard.ScorecardListItem[]
        scorecardHistory: Scorecard.ScorecardListItem[]
        coverPageData: Scorecard.CoverPageData & { userId: number }
    }>(Endpoint.GetScorecardList, {
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
    })

    return {
        scorecardData,
        loadingScorecardList,
        mutateScorecardList,
        error,
    }
}
