import { computed, ComputedRef, inject, ref } from 'vue'

import {
    EntityPill,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { usePercent } from '@opteo/components-next'
import { DisableInterestLocations, Improvement } from '@opteo/types'
import { CurrencyCode } from '@/composition/utils/types'

type ChartItem = { y: number; label: string; highlighted?: true }
type ChartMetric = { label: string; dataType: string; currency: string }
type ChartMarker = { axis: string; label: string; value: number; labelPosition: string }

type TableHeader = { key: string; text: string }
type TableItem = {
    id: string
    metric: string
    presence: string | number
    interest: string | number
}

interface UseDisableInterestLocations {
    campaignGroup: EntityPill
    conversionLoss: number
    costSavings: number
    costSavingPercent: number
    cpaDecrease: number
    cpaDecreasePercent: number
    campaignCopy: string
    campaignNames: EntityPill[]
    showBarChart: boolean
    barChartItems: ChartItem[]
    barChartMetric: ChartMetric
    barChartMarkers: ChartMarker[]
    donutChartItems: ChartItem[]
    donutChartMetric: ChartMetric
    tableHeaders: TableHeader[]
    tableItems: TableItem[]
    interestCpa: number
    interestCost: number
}

export function useDisableInterestLocations(): UseImprovement<UseDisableInterestLocations> {
    const { improvement, lastUpdated, title } = useImprovement<DisableInterestLocations.Body>()

    const {
        body: {
            campaign_group_name: campaignGroupName,
            conversion_loss: conversionLoss,
            cost_savings: costSavings,
            cost_saving_percentage: costSavingPercentage,
            cpa_decrease: cpaDecrease,
            cpa_decrease_percentage: cpaDecreasePercentage,
            campaign_names: allCampaignNames,
            interest_conv: interestConv,
            presence_conv: presenceConv,
            interest_cpa: interestCpa,
            presence_cpa: presenceCpa,
            interest_cost: interestCost,
            presence_cost: presenceCost,
            interest_cpa_vs_target: interestCpaVsTarget,
            presence_cpa_vs_target: presenceCpaVsTarget,
            cpa_target: cpaTarget,
            auto_target: autoTarget,
        },
    } = checkImprovement(improvement)

    const domainCurrencyCode = inject<ComputedRef<CurrencyCode | undefined>>('domainCurrencyCode')

    const costSavingPercent = costSavingPercentage / 100
    const cpaDecreasePercent = cpaDecreasePercentage / 100

    const campaignGroupTargetType = autoTarget ? 'Average' : 'Target'

    // CAMPAIGNS

    const campaignCopy = allCampaignNames.length === 1 ? 'campaign' : 'campaigns'
    const campaignNames = allCampaignNames.map(campaign => {
        return { content: campaign, type: Improvement.LocationEntity.Campaign }
    })

    // GRAPHS

    const showBarChart = interestConv > 1
    const barChartItems: ChartItem[] = [
        { y: interestCpa, label: 'Location of Interest', highlighted: true },
        { y: presenceCpa, label: 'Location of Presence' },
    ]
    const barChartMetric: ChartMetric = {
        label: 'CPA',
        dataType: 'money',
        currency: domainCurrencyCode?.value ?? 'USD',
    }
    const barChartMarkers: ChartMarker[] = [
        {
            axis: 'y',
            label: `Campaign Group ${campaignGroupTargetType} CPA`,
            value: cpaTarget,
            labelPosition: 'end',
        },
    ]

    const donutChartItems: ChartItem[] = [
        { y: interestCost, label: 'Location of Interest', highlighted: true },
        { y: presenceCost, label: 'Location of Presence' },
    ]
    const donutChartMetric: ChartMetric = {
        label: 'Cost',
        dataType: 'money',
        currency: domainCurrencyCode?.value ?? 'USD',
    }

    // COMPARISON TABLE

    const tableHeaders: TableHeader[] = [
        { key: 'metric', text: 'Metric' },
        { key: 'presence', text: 'Location Of Presence' },
        { key: 'interest', text: 'Location Of Interest' },
    ]
    const tableItems: TableItem[] = [
        {
            id: 'cost',
            metric: 'Cost',
            presence: useDomainMoney({ value: presenceCost }).value.displayValue.value,
            interest: useDomainMoney({ value: interestCost }).value.displayValue.value,
        },
        {
            id: 'conversions',
            metric: 'Conversions',
            presence: presenceConv,
            interest: interestConv,
        },
        {
            id: 'cpa',
            metric: 'CPA',
            presence: useDomainMoney({ value: presenceCpa }).value.displayValue.value,
            interest: useDomainMoney({ value: interestCpa }).value.displayValue.value,
        },
        {
            id: 'campaign_group_cpa',
            metric: `${campaignGroupTargetType} CPA`,
            presence: useDomainMoney({ value: cpaTarget }).value.displayValue.value,
            interest: useDomainMoney({ value: cpaTarget }).value.displayValue.value,
        },
    ]

    function buildCPAvsTargetString(value: number) {
        const percentage = usePercent({ value: Math.abs(value) }).displayValue.value

        if (value > 0) {
            return `+${percentage}`
        } else if (value < 0) {
            return `-${percentage}`
        } else {
            return `On Target`
        }
    }

    if (interestConv > 0) {
        tableItems.push({
            id: 'cpa_comparison',
            metric: `CPA vs. ${campaignGroupTargetType}`,
            presence: buildCPAvsTargetString(presenceCpaVsTarget),
            interest: buildCPAvsTargetString(interestCpaVsTarget),
        })
    }

    // REQUIREMENTS

    const campaignGroup: EntityPill = {
        type: Improvement.LocationEntity.CampaignGroup,
        content: campaignGroupName,
    }

    const pushActionText = ref('Disable Locations of Interest')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Disabling Locations of Interest..',
        'Confirming changes..',
        'Locations of Interest disabled successfully.',
    ])

    return {
        title,
        lastUpdated,
        campaignGroup,
        pushMessages,
        conversionLoss,
        costSavings,
        costSavingPercent,
        cpaDecrease,
        cpaDecreasePercent,
        campaignCopy,
        campaignNames,
        showBarChart,
        barChartItems,
        barChartMetric,
        barChartMarkers,
        donutChartItems,
        donutChartMetric,
        tableHeaders,
        tableItems,
        interestCpa,
        interestCost,
        pushActionText,
    }
}
