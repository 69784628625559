<template>
    <svg
        style="display: block"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m9.4656 4.69381 1.8563-1.85625c.7859-.71632 1.8176-1.10227 2.8807-1.07766 1.0631.02461 2.0758.4579 2.8277 1.20982.7519.75192 1.1852 1.76466 1.2098 2.82776.0247 1.06309-.3613 2.09479-1.0776 2.88071l-2.6531 2.64371c-.3824.3838-.8368.6884-1.3371.8962-.5004.2078-1.0368.3147-1.5786.3147-.5417 0-1.0782-.1069-1.5785-.3147-.50034-.2078-.95473-.5124-1.3371-.8962"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="m10.5343 15.3058-1.85623 1.8563c-.78592.7163-1.81762 1.1022-2.88072 1.0776-1.06309-.0246-2.07583-.4579-2.82775-1.2098-.75193-.7519-1.18521-1.7647-1.20982-2.8278-.02462-1.063.36134-2.0947 1.07766-2.8807l2.65313-2.64372c.38237-.3838.83675-.68833 1.33708-.89612.50034-.2078 1.03677-.31476 1.57854-.31476.54177 0 1.07821.10696 1.57854.31476.50037.20779.95467.51232 1.33707.89612"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
