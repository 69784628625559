<template>
    <div
        :class="[
            hasDescription && !url ? 'small-pad' : 'large-pad',
            noShadow ? 'no-shadow' : '',
            'image-uploader-container',
        ]"
        :style="`border-radius: ${borderRadius}`"
    >
        <input
            id="naughty"
            ref="fileInput"
            name="imageuploaderfileInput"
            type="file"
            style="display: none"
            :accept="acceptedTypesString"
            :disabled="disabled"
            @change="selected"
        />
        <div class="image-uploader-content">
            <oButton
                v-if="url && showButton"
                size="small"
                color="white"
                :style="actionButtonStyle"
                :disabled="disabled"
                @clicked="remove"
            >
                Remove Image
            </oButton>
            <oButton
                v-else-if="!url && showButton"
                size="small"
                color="white"
                :style="actionButtonStyle"
                :disabled="disabled"
                @clicked="selectFile"
            >
                Upload Image
            </oButton>
            <div class="flex justify-center">
                <slot></slot>
            </div>
            <Spacer v-if="uploadError" height="1.25rem" />
            <Text v-if="uploadError" size="f-9" class="image-uploader-error" color="red">
                {{ uploadError }}.
            </Text>
            <Text v-if="hasDescription && !url" size="f-9" class="image-uploader-description">
                Image must be <b>PNG</b>, <b>GIF</b>, or <b>JPG</b> format. Transparent or white
                backgrounds work best. Image must be at least <b>300px</b> wide and no larger than
                <b>{{ ACCEPTED_FILE_SIZE }}MB</b>.
            </Text>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, computed } from 'vue'
import { Location, useImageUploader } from '@/composition/global/useImageUploader'
import { oButton, oImage, oInput, Text, Spacer, ProfileImage } from '@opteo/components-next'

export default {
    name: 'ImageUploader',
    components: {
        ProfileImage,
        oButton,
        oInput,
        oImage,
        Text,
        Spacer,
    },
    props: {
        location: {
            type: String as PropType<Location>,
            required: true,
        },
        url: {
            type: String as PropType<string>,
            required: true,
        },
        hasDescription: {
            type: Boolean as PropType<boolean>,
            default: false,
            required: false,
        },
        noShadow: {
            type: Boolean as PropType<boolean>,
            default: false,
            required: false,
        },
        buttonOnBounds: {
            type: Boolean as PropType<boolean>,
            default: false,
            required: false,
        },
        showButton: {
            type: Boolean as PropType<boolean>,
            default: true,
            required: false,
        },
        disabled: {
            type: Boolean as PropType<boolean>,
            default: false,
            required: false,
        },
        borderRadius: {
            type: String,
            default: '1rem',
            required: false,
        },
        fullImageUrlOnUpload: {
            type: Boolean as PropType<boolean>,
            default: false,
            required: false,
        },
    },
    emits: ['uploaded', 'beforeRemove', 'afterRemove'],
    setup(props, { emit }) {
        const actionButtonStyle = computed(() =>
            props.buttonOnBounds
                ? 'top: -1rem; right: -1rem; position: absolute; z-index: 2'
                : 'top: 1rem; right: 1rem; position: absolute; z-index: 2'
        )
        return {
            actionButtonStyle,
            ...useImageUploader({
                location: props.location,
                fullImageUrlOnUpload: props.fullImageUrlOnUpload,
                emit,
            }),
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.image-uploader-container {
    @include ph-24;
    @include relative;
    border-bottom: 1px solid $opteo-light-gray;
}
.image-uploader-container.large-pad {
    @include pv-56;
}
.image-uploader-container.small-pad {
    @include pt-48;
    @include pb-24;
}
.image-uploader-description {
    @include container;
    @include ph-24;
    @include pv-20;
}
.image-uploader-error {
    @include container;
    @include ph-24;
    @include pv-20;
}

@media (min-width: $mq-768-min) {
    .image-uploader-container {
        @include container;
        border-bottom: none;
    }
    .image-uploader-container.no-shadow {
        box-shadow: none;
        border-radius: 0 !important;
    }
    .image-uploader-container.large-pad {
        @include pv-48;
    }
}
</style>
