<template>
    <SettingsSection id="subscription-coupon" title="Discount Code" :maxWidth="380">
        <oForm @submitted="addCouponCode">
            <div class="discount-info">
                <div class="coupon-info">
                    <Text as="p" size="f-8" class="description">
                        If you have a discount code for Opteo, you can add it here. If your code
                        isn’t working for any reason, please message support.
                    </Text>
                    <div v-if="coupon" class="added-coupon">
                        <CheckIcon />
                        <Spacer width="0.875rem" />
                        <div>
                            <Text size="f-7" weight="600" style="letter-spacing: -0.02rem">
                                {{ coupon.promotionCode?.code ?? coupon.id }}
                            </Text>
                            <Spacer height="0.0625rem" />
                            <div v-if="coupon?.percent_off">
                                <Text as="p" size="f-9" color="gray">
                                    {{ coupon.percent_off }}% Discount&nbsp;
                                    <span v-if="coupon.duration === 'forever'">— Forever</span>
                                    <span
                                        v-if="
                                            coupon.duration === 'repeating' &&
                                            (coupon.duration_in_months ?? 0) > 0
                                        "
                                    >
                                        — {{ coupon.duration_in_months }} Months
                                    </span>
                                </Text>
                            </div>
                            <div v-if="coupon?.amount_off">
                                <Text as="p">${{ coupon.amount_off / 100 }} Discount</Text>
                            </div>
                        </div>
                    </div>
                </div>
                <Spacer height="1.875rem" />
                <oInput
                    ref="couponCodeInput"
                    class="w-100"
                    :prefill="true"
                    v-model="couponCode"
                    type="text"
                    :label="coupon ? 'New Discount Code' : 'Discount Code'"
                    name="couponCode"
                />
                <Spacer height="2rem" />
                <div class="flex-center">
                    <oButton
                        ref="couponCodeButton"
                        type="submit"
                        :loading="loading"
                        size="medium"
                        color="blue"
                    >
                        Apply Discount Code
                    </oButton>
                </div>
            </div>
        </oForm>
    </SettingsSection>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { useSubscription } from '@/composition/billing/useSubscription'
import { authRequest, Endpoint } from '@/composition/api/useAPI'

import {
    oInput,
    oButton,
    oForm,
    Spacer,
    Text,
    CheckIcon,
    Label,
    ColorTag,
} from '@opteo/components-next'
import SettingsSection from '@/components/global/SettingsSection.vue'
import { Billing } from '@opteo/types'

const couponCode = ref('')
const couponCodeInput = ref()
const couponCodeButton = ref()

const loading = ref(false)

const { coupon, mutateCoupon } = useSubscription()

async function handleCouponApplicationError(error: any) {
    const message = (error as Error)?.message ?? Billing.CouponApplicationError.UNKNOWN_ERROR

    const errorMessages: Record<string, string> = {
        [Billing.CouponApplicationError.INVALID_CODE]: 'Invalid Discount Code',
        [Billing.CouponApplicationError.NO_SUBSCRIPTION_OR_PRODUCT]:
            'Please start new free trial before using discount code',
        [Billing.CouponApplicationError.UNKNOWN_ERROR]: 'Unknown error. Please contact support',
    }

    const userErrorMessage =
        errorMessages[message] ?? errorMessages[Billing.CouponApplicationError.UNKNOWN_ERROR]

    couponCodeInput.value.setError(userErrorMessage)
    couponCodeButton.value.flashError()
}

async function addCouponCode() {
    if (!couponCode.value) {
        return
    }

    loading.value = true

    try {
        const res = await authRequest(Endpoint.SaveCouponForUser, {
            body: { coupon_code: couponCode.value },
        })

        if (res.error || !res.coupon) {
            throw new Error(res.error)
        }

        mutateCoupon(() => res.coupon)
        loading.value = false
        couponCodeButton.value.flashSuccess()
    } catch (err) {
        loading.value = false
        handleCouponApplicationError(err)
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.discount-info {
    // @include container;
    @include pa-32;
}
.coupon-info {
    @include container;
}
.coupon-info .description {
    @include ph-24;
    @include pv-20;
}
.added-coupon {
    @include flex;
    @include items-center;
    border-top: 1px solid;
    @include opteo-border;
    @include ph-24;
    @include pv-20;
}

.flex-center {
    @include flex;
    @include justify-center;
}
</style>
