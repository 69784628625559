<template>
    <div class="disapproval-cell">
        <Popout
            trigger="mouseenter"
            placement="top"
            :border-radius="24"
            max-width="60rem"
            :offset="[0, 12]"
            :z-index="2147483647"
        >
            <div v-if="hasReasons" class="disapproval-tag">
                <Text as="p" size="f-8" weight="500" color="red">
                    <slot></slot>
                </Text>
            </div>
            <div v-else class="disapproval-tag">
                <WarnIcon color="gray" translucent />
                <Spacer width="0.5rem" />
                <Text as="p" size="f-8" weight="500" color="gray">Unknown</Text>
            </div>
            <!-- Popout -->
            <template v-if="hasReasons" v-slot:content>
                <div class="popout-inner-container">
                    <div
                        v-for="(row, index) in rows"
                        :class="['issue-row', index !== rows.length - 1 ? 'bottom-border' : '']"
                    >
                        <div class="disapproval-tag">
                            <Text size="f-8" weight="500" color="red">
                                {{ titleCase(status) }}
                            </Text>
                        </div>
                        <Spacer width="0.75rem" />
                        <Text size="f-8"> {{ titleCase(row.topic) }}</Text>
                    </div>
                </div>
            </template>
        </Popout>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Text, Spacer, Number as NumberVue, WarnIcon, Popout } from '@opteo/components-next'

const props = defineProps<{
    rows: { topic: string }[]
    status: string
}>()

const hasReasons = computed(() => {
    return props.rows.length > 0
})

function titleCase(str) {
    str = str.toLowerCase().split('_')
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(' ')
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.disapproval-cell {
    @include pr-24;
}
.disapproval-tag {
    @include inline-flex;
    @include items-center;
    background-color: $opteo-red-translucent;
    padding: 0.5625rem 0.875rem;
    @include br-999;
    user-select: none;
}
.disapproval-tag p {
    font-size: 0.8125rem;
    line-height: 0.875rem;
    letter-spacing: -0.01rem;
}
.popout-inner-container {
    @include br-20;

    & .issue-row {
        padding: 18px 22px 18px 20px;
        @include flex;
        @include items-center;
        white-space: nowrap;
    }
    & .bottom-border {
        border-bottom: 1px solid;
        @include opteo-border;
    }
}
</style>
