<template>
    <Popout
        v-model="isOpen"
        :zIndex="9999999999999"
        :offset="[0, 12]"
        :trapFocus="true"
        :externalToggleButton="true"
        placement="top"
        :maxWidth="isExistingRsa ? '21.625rem' : '22.5rem'"
    >
        <RsaButton @click="isOpen = !isOpen">
            <rsaRefreshIcon v-if="isExistingRsa" />
            <rsaBinIcon v-else />
        </RsaButton>
        <template v-slot:content>
            <div class="confirm-popout-content">
                <Text as="p" size="f-8" v-if="isExistingRsa">
                    To reset your changes, click the
                    <b>Reset Changes</b> button. Your draft will be cleared and your RSA will be
                    reverted to its last published state.
                </Text>
                <Text as="p" size="f-8" v-else>
                    To delete this draft, click the
                    <b>Delete Draft</b> button below. Please note that your draft will be deleted
                    permanently and will not be able to be recovered.
                </Text>
                <Spacer height="1rem" />
                <div class="confirm-popout-buttons">
                    <oButton color="white" @clicked="isOpen = false">Cancel</oButton>
                    <Spacer width="0.625rem" />
                    <oButton v-if="isExistingRsa" color="blue" @clicked="confirm">
                        Reset Changes
                    </oButton>
                    <oButton v-else color="red" @clicked="confirm">Delete Draft</oButton>
                </div>
            </div>
        </template>
    </Popout>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import {
    oButton,
    Text,
    Spacer,
    Popout,
    rsaBinIcon,
    rsaRefreshIcon,
} from '@opteo/components-next'
import Money from '@/components/global/oMoney.vue'

import ToggleSwitch from '@/components/global/ToggleSwitch.vue'
import RsaButton from '@/components/rsaWriter/RsaButton.vue'

export default defineComponent({
    name: 'ShoppingBetaRow',
    components: {
        ToggleSwitch,
        oButton,
        Money,
        Text,
        Spacer,
        Popout,
        RsaButton,
        rsaBinIcon,
        rsaRefreshIcon,
    },
    emits: ['delete-confirmed'],
    props: {
        // Whether we're clearing changes on an existing RSA or a floating draft
        isExistingRsa: {
            type: Boolean,
            required: false,
        },
    },
    setup(props, { emit }) {
        const isOpen = ref(false)

        function confirm() {
            emit('delete-confirmed')
        }

        return {
            isOpen,
            confirm,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.confirm-popout-content {
    @include ph-24;
    @include pt-20;
    @include pb-24;
}
.confirm-popout-buttons {
    @include flex;
    @include items-center;
}
</style>
