<template>
    <div class="shadow note-container">
        <div class="note-content">
            <Text as="p" v-if="!note.currentlyEditing">{{ note.text }}</Text>
            <div v-if="note.currentlyEditing" class="edit-container">
                <div class="edit-input">
                    <oInput
                        type="text"
                        ref="inputField"
                        v-model="localText"
                        v-on:keyup.enter.exact="save"
                    />
                </div>
            </div>
        </div>
        <div class="note-footer">
            <div class="user-info">
                <div class="avatar-s">
                    <ProfileImage
                        :width="36"
                        :image-url="note.author_avatar_filename"
                        :avatar-url="avatarUrl"
                    />
                </div>
                <div>
                    <p class="user-name">{{ note.author_name }}</p>
                    <p class="user-email">{{ note.author_email }}</p>
                </div>
            </div>
            <div v-if="note.type === NoteType.Text" class="note-buttons">
                <oButton @clicked="remove" size="small" color="white" class="button delete-button">
                    Delete
                </oButton>
                <oButton
                    v-if="note.currentlyEditing"
                    @clicked="save"
                    size="small"
                    color="white"
                    class="button"
                >
                    Save
                </oButton>
                <oButton
                    v-if="!note.currentlyEditing"
                    @clicked="edit"
                    size="small"
                    color="white"
                    class="button"
                >
                    Edit
                </oButton>
            </div>
            <div v-else class="note-autogen">
                <ColorTag title="Auto Generated" content="" />
                <Spacer width="0.875rem" />
                <div class="autogen-logo">
                    <OpteoLogo />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, PropType, defineComponent, ref, watchEffect } from 'vue'
import {
    oButton,
    Spacer,
    oInput,
    Text,
    ColorTag,
    OpteoLogo,
    ProfileImage,
} from '@opteo/components-next'

import { useNotes, NormalisedNote, NoteType } from '@/composition/notes/useNotes'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { Account } from '@opteo/types'

export default defineComponent({
    name: 'Note',
    components: {
        Text,
        ProfileImage,
        oButton,
        Spacer,
        oInput,
        ColorTag,
        OpteoLogo,
    },
    props: {
        note: {
            type: Object as PropType<NormalisedNote>,
            required: true,
        },
        accountId: {
            type: String as PropType<Account.ID>,
            required: true,
        },
    },
    setup(props) {
        const { startNoteEdit, endNoteEdit, deleteNote, saveNote } = useNotes(ref(props.accountId))
        const { getAvatarUrl } = useBoringAvatar()
        const avatarUrl = computed(() => getAvatarUrl(props.note?.author_user_id))

        const localText = ref(props.note.text)

        const inputField = ref()
        const remove = () => {
            deleteNote(props.note.id, props.note.day)
        }

        const edit = () => {
            startNoteEdit(props.note.id)
        }

        const save = () => {
            endNoteEdit()
            saveNote(props.note.id, props.note.day, localText.value)
        }

        const isEditing = computed(() => props.note.currentlyEditing)

        watchEffect(() => {
            if (isEditing.value && inputField.value) {
                inputField.value.inputRef.focus()
            }
        })

        return { remove, edit, save, inputField, localText, NoteType, avatarUrl }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.note-container {
    @include br-16;
    @include mb-24;
}

.note-content {
    @include pa-28;
    border-bottom: 1px solid;
    @include opteo-border;
}

.note-buttons {
    white-space: nowrap;
}

.edit-container {
    @include flex;
    @include justify-between;
    @include items-center;
}

.edit-input {
    flex-grow: 1;
}
.note-footer {
    @include flex;
    @include pa-24;
    @include justify-between;
    gap: 24px;
    flex-wrap: wrap;
}

.note-autogen {
    @include flex;
    @include items-center;
}

.delete-button {
    @include mr-8;
}

.user-info {
    @include flex;
    @include items-center;
}
.user-name {
    @include ml-16;
    @include opteo-black;
    @include f-7;
    @include fw-500;
    @include lh-100;
    margin-bottom: 6px;
    white-space: nowrap;
}
.user-email {
    @include ml-16;
    @include f-9;
    @include fw-400;
    @include lh-130;
    @include o-30;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .note-container {
        width: unset;
    }
}

@media (max-width: 414px) {
    .note-content,
    .note-footer {
        @include pa-32;
    }
    .autogen-logo {
        display: none;
    }

    .note-container {
        @include br-12;
    }

    .note-buttons {
        @include flex;
        width: 100%;
    }

    .button {
        width: 100%;
    }
}
</style>
