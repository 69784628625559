import { checkImprovement, useImprovement } from '../useImprovement'
import { useNumber } from '@opteo/components-next'
import { KeywordBidsMS, Targets } from '@opteo/types'
import { computed, ref } from 'vue'
import { useAccount } from '@/composition/account/useAccount'
import { useMoney } from '@opteo/components-next'

export function usePauseKeyword() {
    const { improvement, lastUpdated, title } = useImprovement<KeywordBidsMS.PauseKeywordBody>()

    const {
        body: {
            campaignName,
            adGroupName,
            keyword,
            campaignGroupTargetMode,
            metrics: {
                spend: keywordSpend,
                conversions: keywordConversions,
                campaignGroupTargetCpa,
            },
            window,
        },
    } = checkImprovement(improvement)

    const campaignGroupTargetModeString =
        campaignGroupTargetMode === Targets.TargetMode.AUTO ? 'Average' : 'Target'

    const { currencyCode } = useAccount()

    const statistics = [
        {
            key: 'cost',
            value: useMoney({ value: keywordSpend, currency: currencyCode.value }).displayValue
                .value,
            title: 'Cost',
        },
        {
            key: 'conversions',
            value: useNumber({ value: keywordConversions }).displayValue.value,
            title: keywordConversions === 1 ? 'Conversion' : 'Conversions',
        },
        {
            key: 'targetCpa',
            value: useMoney({ value: campaignGroupTargetCpa, currency: currencyCode.value })
                .displayValue.value,
            title: 'Target CPA',
        },
    ]

    const keywordCpa = keywordConversions ? keywordSpend / keywordConversions : Infinity

    if (isFinite(keywordCpa)) {
        statistics.push({
            key: 'keywordCpa',
            value: useMoney({ value: keywordCpa, currency: currencyCode.value }).displayValue.value,
            title: 'Keyword CPA',
        })
    }

    const pushMessages = computed(() => [
        'Connecting to Microsoft Ads..',
        'Pausing keyword..',
        'Confirming changes..',
        'Keyword paused successfully.',
    ])

    const pushActionText = ref('Pause Keyword')

    return {
        // REQUIREMENTS
        title,
        pushMessages,
        pushActionText,
        lastUpdated,
        window,
        // DATA
        campaignGroupTargetModeString,
        campaignName,
        adGroupName,
        keyword,
        keywordCpa,
        keywordSpend,
        keywordConversions,
        campaignGroupTargetCpa,
        statistics,
        currencyCode,
    }
}
