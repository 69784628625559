import {
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { computed, ref } from 'vue'

import type { ImprovementStatistic } from '.'

interface UseFixBrokenLink {
    reason: string
    copy: { spread: string; campaign: string }
    campaignNames: string[]
    formattedCampaignNames: {
        content: string
        type: string
    }[]
    clicksPerDay: string
    costPerDay: string
    improvementStatistics: ImprovementStatistic[]
    url: string
}

interface Body {
    path: string
    url: string
    reason: string
    cost_per_day: number
    clicks_per_day: number
    account_cost_per_day: number
    account_clicks: number
    account_location: { campaign_name: string; adgroups: string[] }[]
}

export function useFixBrokenLink(): UseImprovement<UseFixBrokenLink> {
    const { improvement, lastUpdated, title } = useImprovement<Body>()

    const {
        body: {
            url,
            reason,
            account_location: campaigns,
            clicks_per_day: rawClicksPerDay,
            cost_per_day: rawCostPerDay,
        },
    } = checkImprovement(improvement)

    const singleCampaign = campaigns.length === 1
    const copy = {
        spread: singleCampaign ? 'in' : 'across',
        campaign: singleCampaign ? 'campaign' : 'campaigns',
    }

    const campaignNames = campaigns.map(campaign => campaign.campaign_name)

    const formattedCampaignNames = campaigns.map(campaign => {
        return { content: campaign.campaign_name, type: 'campaign' }
    })

    const clicksPerDay = Math.round(rawClicksPerDay).toString()
    const costPerDay = useDomainMoney({ value: rawCostPerDay }).value.displayValue.value

    const improvementStatistics: ImprovementStatistic[] = [
        {
            key: 'clicks',
            value: clicksPerDay,
            title: 'Clicks Per Day',
        },
        {
            key: 'cost',
            value: costPerDay,
            title: 'Cost Per Day',
        },
    ]

    const pushActionText = ref('Recheck Link')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Checking broken link..',
        'Confirming link status..',
        'Broken link fixed successfully.',
    ])

    return {
        title,
        lastUpdated,
        url,
        pushMessages,
        reason,
        copy,
        campaignNames,
        formattedCampaignNames,
        clicksPerDay,
        costPerDay,
        improvementStatistics,
        pushActionText,
    }
}
