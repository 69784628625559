export const WEBSITE_DOCS_URL = 'https://opteo.com/docs'
export const VAT_COUNTRIES = [
    { country_code: 'AT', name: 'Austria' },
    { country_code: 'BE', name: 'Belgium' },
    { country_code: 'BG', name: 'Bulgaria' },
    { country_code: 'CY', name: 'Cyprus' },
    { country_code: 'CZ', name: 'Czech Republic' },
    { country_code: 'DK', name: 'Denmark' },
    { country_code: 'EE', name: 'Estonia' },
    { country_code: 'EL', name: 'Greece' },
    { country_code: 'FI', name: 'Finland' },
    { country_code: 'FR', name: 'France' },
    { country_code: 'DE', name: 'Germany' },
    { country_code: 'GB', name: 'United Kingdom' },
    { country_code: 'HR', name: 'Croatia' },
    { country_code: 'HU', name: 'Hungary' },
    { country_code: 'IE', name: 'Ireland' },
    { country_code: 'IT', name: 'Italy' },
    { country_code: 'LV', name: 'Latvia' },
    { country_code: 'LT', name: 'Lithuania' },
    { country_code: 'LU', name: 'Luxembourg' },
    { country_code: 'MT', name: 'Malta' },
    { country_code: 'NL', name: 'Netherlands' },
    { country_code: 'PL', name: 'Poland' },
    { country_code: 'PT', name: 'Portugal' },
    { country_code: 'RO', name: 'Romania' },
    { country_code: 'SK', name: 'Slovakia' },
    { country_code: 'SI', name: 'Slovenia' },
    { country_code: 'ES', name: 'Spain' },
    { country_code: 'SE', name: 'Sweden' },
]
