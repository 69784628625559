<template>
    <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.25626 15.5937c-1.11667-1.884-1.50727-4.1108-1.09846-6.26236.4088-2.15157 1.58889-4.07994 3.31872-5.42308 1.72984-1.34315 3.89045-2.00871 6.07618-1.87172 2.1858.13698 4.2464 1.06708 5.795 2.61568 1.5486 1.54861 2.4787 3.60922 2.6157 5.79498.137 2.1858-.5286 4.3464-1.8717 6.0762-1.3431 1.7299-3.2715 2.91-5.4231 3.3188-2.1515.4088-4.37835.0182-6.26234-1.0985v0l-3.1125.8812c-.12752.0373-.26273.0397-.39145.0067-.12872-.0329-.24621-.0998-.34016-.1938-.09395-.0939-.16089-.2114-.19382-.3402-.03292-.1287-.03062-.2639.00668-.3914l.88125-3.1125Z"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 12.5c.82843 0 1.5-.6716 1.5-1.5S7.82843 9.5 7 9.5s-1.5.6716-1.5 1.5.67157 1.5 1.5 1.5Zm4 0c.8284 0 1.5-.6716 1.5-1.5s-.6716-1.5-1.5-1.5-1.5.6716-1.5 1.5.6716 1.5 1.5 1.5Zm5.5-1.5c0 .8284-.6716 1.5-1.5 1.5s-1.5-.6716-1.5-1.5.6716-1.5 1.5-1.5 1.5.6716 1.5 1.5Z"
            fill="#fff"
        />
    </svg>
</template>
