<template>
    <h2 class="f2 lh-title mb4">Custom Bid Adjustment</h2>
    <slot name="entity-pill"></slot>
    <Spacer height="2rem" />
    <p><slot name="text"></slot></p>
    <Spacer height="2rem" />

    <InlineInputContainer>
        <template #mobile>
            <Text as="p" size="f-8">Apply a bid adjustment to this {{ entType }}.</Text>
        </template>
        <template #left-side>
            <Text as="span" size="f-8" style="line-height: 2.8125rem">Apply a</Text>
        </template>
        <template #input>
            <div class="bid-inputs">
                <div class="input-bid-direction">
                    <oInput
                        v-model="bidDirection"
                        :items="[
                            { value: 'increase', label: '+' },
                            { value: 'decrease', label: '-' },
                        ]"
                        @updated="onBidUpdate"
                        type="select"
                    />
                </div>
                <div class="input-bid-change">
                    <oInput
                        ref="bidChangeRef"
                        type="number"
                        v-model="bidChange"
                        min="0"
                        max="1000"
                        suffix="%"
                        @updated="onBidUpdate"
                        required
                    />
                </div>
            </div>
        </template>
        <template #right-side>
            <Text v-if="aboveMobile" as="span" size="f-8" style="line-height: 2.8125rem">
                bid adjustment to this {{ entType }}.
            </Text>
        </template>
    </InlineInputContainer>
</template>
<script lang="ts">
import { oInput, EntityPillList, Spacer, Text } from '@opteo/components-next'
import { ref, computed, PropType } from 'vue'

import Ent from '@/components/util/Ent.vue'
import InlineInputContainer from '@/components/improvement/shared-components/InlineInputContainer.vue'
import { EntityPill } from '@/composition/improvement/useImprovement'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    name: 'ImprovementBidAdjustment',
    components: { oInput, Ent, EntityPillList, Spacer, Text, InlineInputContainer },
    props: {
        bidType: {
            type: String,
            required: true,
        },
        entType: {
            type: String,
            required: false,
            default: 'segment',
        },
        currentBid: {
            type: Number,
            required: true,
        },
        newBid: {
            type: Number,
            required: true,
        },
        entityPillList: {
            type: Array as PropType<EntityPill[]>,
            required: false,
        },
    },
    emits: ['on-bid-update'],
    setup(props, { emit }) {
        const bidDirection = ref(props.newBid > props.currentBid ? 'increase' : 'decrease')

        const bidChange = ref(
            +(Math.abs((props.newBid - props.currentBid) / props.currentBid) * 100).toFixed(2)
        )

        const bidChangeRef = ref()

        const updatedBid = computed(() => {
            const difference =
                bidDirection.value === 'increase' ? +bidChange.value / 100 : -bidChange.value / 100

            return (1 + difference) * props.currentBid
        })

        function onBidUpdate() {
            emit('on-bid-update', updatedBid.value)
        }

        const valid = computed(() => bidChangeRef.value.valid)

        const { aboveMobile } = useMediaQuery()

        return { bidDirection, bidChange, onBidUpdate, bidChangeRef, valid, aboveMobile }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.bid-inputs {
    display: grid;
    grid-template-columns: 5.5rem 1fr;
    grid-gap: 0.75rem;
}

.input-bid-direction {
    width: 4rem;
}

@media screen and (min-width: $mq-767-max) {
    .bid-inputs {
        @include flex;
    }
}
</style>
