<template>
    <div class="relative">
        <div
            v-if="url"
            :style="{
                backgroundImage: `url(${url})`,
                width: `${width}px`,
                height: `${height}px`,
            }"
            style="
                background-size: contain;
                background-repeat: no-repeat;
                border-radius: 12px;
                background-position: center center;
            "
        />

        <Skeleton v-else :width="width" :height="height" />
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import Skeleton from '@/components/util/Skeleton.vue'

export default {
    name: 'ScorecardDomainImage',
    components: {
        Skeleton,
    },
    props: {
        isAdmin: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
        url: {
            type: String as PropType<string>,
            required: false,
        },
        width: {
            type: Number as PropType<number>,
            required: false,
            default: 32,
        },
        height: {
            type: Number as PropType<number>,
            required: false,
            default: 32,
        },
        // We use user_id here as the randomness seed (name prop) because it's the field we're most likely to have on hand when using this component.
        userId: {
            type: Number as PropType<number>,
            required: false,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
</style>
