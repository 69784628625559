import { computed, ref } from 'vue'
import {
    EntityPill,
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

import { format as formatDate, sub } from 'date-fns'
import { useNumber, usePercent } from '@opteo/components-next'
import { Improvement, KeywordBidsMS } from '@opteo/types'
import { useAccount } from '@/composition/account/useAccount'
import { useMoney } from '@opteo/components-next'

type ImprovementStatistic = { key: string; value: string; title: string }

export function useIncreaseKeywordBid() {
    const { improvement, lastUpdated, title } =
        useImprovement<KeywordBidsMS.IncreaseKeywordBidsBody>()

    const {
        body: {
            campaignName,
            adGroupName,
            window,
            keyword,
            newBid,
            currentBid,
            bidChange,
            metrics: {
                spend: keywordSpend,
                clicks: keywordClicks,
                impressions: keywordImpressions,
                qualityScore: keywordQualityScore,
                firstPageBid: keywordFirstPageBid,
                impressionSharePercent: keywordImpressionSharePercent,
            },
            impressionSeries,
            impressionShareSeries,
        },
    } = checkImprovement(improvement)

    const { currencySymbol, currencyCode } = useAccount()

    const keywordCpc = keywordClicks ? keywordSpend / keywordClicks : 0

    const entityPillList: EntityPill[] = [
        {
            type: Improvement.LocationEntity.Campaign,
            content: campaignName,
        },
        {
            type: Improvement.LocationEntity.AdGroup,
            content: adGroupName,
        },
        {
            type: Improvement.LocationEntity.Keyword,
            content: keyword,
        },
    ]

    // KEYWORD BID
    const isUpdatedBidIncreasing = computed(() => adjustedBid.value > currentBid)

    const formattedCurrentBid = useMoney({
        value: currentBid,
        currency: currencyCode.value,
    }).displayValue.value

    const formattedNewBid = useMoney({ value: newBid, currency: currencyCode.value }).displayValue
        .value

    const adjustedBid = ref(Math.round(newBid * 100) / 100)
    const adjustedBidChange = computed(() => (adjustedBid.value - currentBid) / currentBid)

    function handleBidUpdate() {
        pushActionText.value = isUpdatedBidIncreasing.value
            ? 'Increase Keyword Bid'
            : 'Decrease Keyword Bid'
    }

    function resetBid() {
        adjustedBid.value = Math.round(newBid * 100) / 100
        handleBidUpdate()
    }

    // IMPROVEMENT STATISTICS
    const improvementStatistics: ImprovementStatistic[] = [
        {
            key: 'firstPageBid',
            value: useMoney({ value: keywordFirstPageBid, currency: currencyCode.value })
                .displayValue.value,
            title: 'First Page Bid Estimate',
        },
        {
            key: 'cost',
            value: useMoney({ value: keywordSpend }).displayValue.value,
            title: 'Cost',
        },
        {
            key: 'clicks',
            value: useNumber({ value: keywordClicks }).displayValue.value,
            title: keywordClicks === 1 ? 'Click' : 'Clicks',
        },
        {
            key: 'impressions',
            value: useNumber({ value: keywordImpressions }).displayValue.value,
            title: keywordImpressions === 1 ? 'Impression' : 'Impressions',
        },
        {
            key: 'qs',
            value: useNumber({ value: keywordQualityScore }).displayValue.value,
            title: 'Quality Score',
        },
    ]

    if (keywordImpressionSharePercent > 0) {
        improvementStatistics.push({
            key: 'searchImprShare',
            value: usePercent({ value: keywordImpressionSharePercent }).displayValue.value,
            title: 'Seach Impression Share',
        })
    }

    if (keywordCpc) {
        improvementStatistics.splice(3, 0, {
            key: 'cpc',
            value: useMoney({ value: keywordCpc, currency: currencyCode.value }).displayValue.value,
            title: 'CPC',
        })
    }

    const formattedStartDate = formatDate(sub(new Date(), { days: window }), 'do MMM')
    const formattedEndDate = formatDate(sub(new Date(), { days: 1 }), 'do MMM')

    const pushActionText = ref(
        isUpdatedBidIncreasing.value ? 'Increase Keyword Bid' : 'Decrease Keyword Bid'
    )

    const pushMessages = computed(() => [
        'Connecting to Microsoft Ads..',
        'Adjusting keyword bid..',
        'Confirming changes..',
        'Keyword bid adjusted succesfully.',
    ])

    const onPush: OnPushHandler<KeywordBidsMS.ExtraDetails> = () => {
        const valid =
            typeof adjustedBidChange.value === 'number' &&
            adjustedBidChange.value !== 0 &&
            adjustedBidChange.value !== currentBid

        return { valid, pushedData: { newBid: adjustedBid.value } }
    }

    return {
        // REQUIREMENTS
        title,
        pushMessages,
        onPush,
        pushActionText,
        lastUpdated,
        window,
        // DATA
        currencySymbol,
        currencyCode,
        entityPillList,
        keyword,
        keywordCpc,
        keywordSpend,
        keywordClicks,
        keywordImpressions,
        keywordFirstPageBid,
        keywordQualityScore,
        keywordImpressionSharePercent,
        // KEYWORD BID
        newBid,
        bidChange,
        currentBid,
        adjustedBid,
        formattedCurrentBid,
        formattedNewBid,
        handleBidUpdate,
        resetBid,
        // OTHERS
        improvementStatistics,
        impressionSeries,
        impressionShareSeries,
        formattedStartDate,
        formattedEndDate,
    }
}
