import { computed, ref } from 'vue'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { useUser } from '@/composition/user/useUser'

// TODO(types): use Location type in types package
export type Location = 'group' | 'user' | 'domain' | 'scorecard_sender_logo'

type EmittedEvent = (event: 'uploaded' | 'beforeRemove' | 'afterRemove', args?: string) => void

// TODO(types): use ACCEPTED_TYPES type in types package
export const ACCEPTED_TYPES = [
    'image/png',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/tiff',
    'image/apng',
]

// TODO(types): use ACCEPTED_FILE_SIZE type in types package
export const ACCEPTED_FILE_SIZE = 2 // megabytes

export function useImageUploader({
    location,
    emit,
    fullImageUrlOnUpload,
}: {
    location: Location
    emit: EmittedEvent
    fullImageUrlOnUpload?: boolean
}) {
    const { groupId } = useUser()

    const fileInput = ref<HTMLInputElement>()
    const uploadError = ref('')
    const loading = ref(false)

    const selectFile = () => {
        fileInput.value?.click()
    }

    const remove = async () => {
        if (!groupId.value) {
            throw new Error('cannot remove image until groupId is defined')
        }
        emit('beforeRemove')
        await deleteImage(location, groupId.value)
        emit('afterRemove')
    }

    const selected = async (event: any) => {
        if (!event.target.files) {
            return
        }
        loading.value = true

        // @ts-ignore no
        const [selectedFile]: FileList = event.target.files

        const selectedFileSizeMB = selectedFile.size / 1000000
        try {
            if (ACCEPTED_TYPES.indexOf(selectedFile.type) === -1) {
                throw new Error('INVALID_FILE_TYPE')
            }
            if (selectedFileSizeMB > ACCEPTED_FILE_SIZE) {
                throw new Error('INVALID_FILE_SIZE')
            }
            if (!groupId.value) {
                throw new Error('cannot remove image until groupId is defined')
            }

            const base64_image = await toBase64(selectedFile)

            const uploadedImageUrl = await uploadImage(
                location,
                base64_image,
                groupId.value,
                fullImageUrlOnUpload
            )
            emit('uploaded', uploadedImageUrl)
            loading.value = false

            if (uploadError.value) {
                uploadError.value = ''
            }
        } catch (error) {
            loading.value = false
            // if error exists in definitions
            if (error instanceof Error && errorDefinitions[error?.message]) {
                const readable_error = errorDefinitions[error.message].long
                uploadError.value = readable_error
            } else {
                uploadError.value = 'We were unable to upload this file'
            }
        }

        // clear the file holder so if you upload the same image as before it works
        if (fileInput.value?.value) {
            fileInput.value.value = ''
        }
    }

    const acceptedTypesString = computed(() => ACCEPTED_TYPES.join(', '))

    return {
        selectFile,
        fileInput,
        remove,
        selected,
        uploadError,
        acceptedTypesString,
        ACCEPTED_FILE_SIZE,
        loading,
    }
}

function toBase64(file: FileList): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file as unknown as Blob)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = error => reject(error)
    })
}

function uploadImage(
    location: Location,
    image: string,
    groupId: number | undefined,
    fullImageUrl?: boolean
): Promise<string> {
    //TODO : make sure group Id is always defined
    if (typeof groupId === 'undefined') {
        throw new Error('Group Id is undefined')
    }
    return authRequest(Endpoint.UploadImage, {
        body: { type: location, image, group_id: groupId, fullImageUrl },
    })
}

function deleteImage(location: Location, groupId: number | undefined) {
    //TODO : make sure group Id is always defined
    if (typeof groupId === 'undefined') {
        throw new Error('Group Id is undefined')
    }
    //type is referring to a key used in the backend, location makes more sense as a prop in my head
    return authRequest(Endpoint.DeleteImage, { body: { type: location, group_id: groupId } })
}

interface ErrorDefinitions {
    [index: string]: { long: string; short: string }
}

const errorDefinitions: ErrorDefinitions = {
    INVALID_FILE_TYPE: {
        long: `It looks like you're trying to upload an invalid file type, accepted file types are: PNG, GIF, JPG, JPEG, TIFF and APNG`,
        short: 'Invalid File Type',
    },
    FILE_UNDEFINED: {
        long: `It looks like you're uploading an unrecognisable file`,
        short: 'File Undefined',
    },
    UPLOAD_FAILED: {
        long: `We were unable to upload your file`,
        short: `Upload Failed`,
    },
    INVALID_BASE64: {
        long: `The file you're trying to upload is corrupted`,
        short: 'Invalid Base64',
    },
    INVALID_FILE_SIZE: {
        long: `This file is too large to upload. The max file upload size is 2mb`,
        short: `Invalid File Size`,
    },
    UNKNOWN_FILE_TYPE: {
        long: `We were unable to identify what file type this is and therefore did not upload it, accepted file types are: PNG, GIF, JPG, JPEG, tiff and APNG`,
        short: `Unknown File Type`,
    },
}
