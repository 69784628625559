<template>
    <div class="section-loading">
        <Spacer height="0.25rem" />
        <Skeleton width="96%" :height="13" style="margin: 0.125rem 0" />
        <Spacer height="0.4375rem" />
        <Skeleton width="80%" :height="13" style="margin: 0.125rem 0" />
        <Spacer height="0.4375rem" />
        <Skeleton width="88%" :height="13" style="margin: 0.125rem 0" />
        <Spacer height="2rem" />
        <div class="relative">
            <oTable
                class="table"
                :headers="[{ key: 'one' }, { key: 'two' }, { key: 'three' }, { key: 'four' }]"
                :items="new Array(2).fill({})"
            >
                <template v-slot:header.one="one">
                    <Skeleton :height="16" :width="Math.random() * 60 + 40" />
                </template>
                <template v-slot:header.two="two">
                    <Skeleton :height="16" :width="Math.random() * 80 + 40" />
                </template>
                <template v-slot:header.three="three">
                    <Skeleton :height="16" :width="Math.random() * 80 + 40" />
                </template>
                <template v-slot:header.four="four">
                    <Skeleton :height="16" :width="Math.random() * 80 + 40" />
                </template>
                <template v-slot:column.one="one">
                    <Skeleton :height="16" :width="Math.random() * 80 + 40" />
                </template>
                <template v-slot:column.two="two">
                    <Skeleton :height="16" :width="Math.random() * 80 + 40" />
                </template>
                <template v-slot:column.three="three">
                    <Skeleton :height="16" :width="Math.random() * 80 + 40" />
                </template>
                <template v-slot:column.four="four">
                    <Skeleton :height="16" :width="Math.random() * 80 + 40" />
                </template>
            </oTable>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { Spacer, Text, oTable } from '@opteo/components-next'

import Skeleton from '@/components/util/Skeleton.vue'

export default defineComponent({
    name: 'SectionLoading',
    components: {
        Text,
        Spacer,
        oTable,
        Skeleton,
    },
    props: {
        sectionName: {
            type: String,
            required: true,
        },
        domainName: {
            type: String,
        },
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.section-loading {
    width: 28.75rem;
}

.table::before {
    content: '';
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 98%);
    position: absolute;
    top: 4px;
    left: -4px;
    height: 100%;
    width: calc(100% + 8px);
    z-index: 2;
}

@media (max-width: $mq-1024-max) {
    .section-loading {
        width: 100%;
    }
}
</style>
