<template>
    <!-- Update Payment Method Modal -->
    <Modal v-model="opened" width="800px" :title="modalTitle">
        <template #content>
            <CreditCardForm
                :noMargin="true"
                :noTitle="true"
                :updating-card="!!creditCard"
                @on-success="onSuccess"
            />
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { Modal } from '@opteo/components-next'

import CreditCardForm from '@/components/billing/CreditCardForm.vue'

import { useSubscription } from '@/composition/billing/useSubscription'
import { Endpoint, authRequest } from '@/composition/api/useAPI'

const props = defineProps<{
    modelValue: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const modalTitle = computed(() =>
    creditCard.value ? 'Update Payment Method' : 'Add Payment Method'
)

const opened = ref(false)

const { mutate, creditCard } = useSubscription()

watch(props, async ({ modelValue }) => {
    opened.value = modelValue
})

watch(opened, async newVal => {
    emit('update:modelValue', newVal)
})

async function onSuccess(paymentMethod: string) {
    await authRequest(Endpoint.UpdateCustomerCc, {
        body: { token: paymentMethod },
    })

    await mutate()

    // For the animation but also lets swrv revalidate
    opened.value = false
}
</script>
