<template>
    <!-- 
        The v-if=loading here is important. 
        Carousels must not be initialised until:
            - InitialIndex is set
            - Slides that support that initialIndex are set (eg 4 slides if initialIndex=1 and slidesToScroll=2)
    -->
    <div class="template-selector-carousel">
        <Carousel
            :arrows="true"
            :options="{ slidesToScroll, align: 'center', speed: 8, startIndex: initialIndex }"
            v-if="!loading"
        >
            <template #slides>
                <div
                    v-for="template in templates"
                    :key="template.template_id"
                    class="template-slide"
                >
                    <ReportTemplateSelectorItem
                        @select-template="selectTemplate"
                        @delete-template="deleteTemplate"
                        @edit-template="editTemplate"
                        :baseTemplateCategories="baseTemplateCategories"
                        :template="template"
                        :selectedTemplateId="selectedTemplateId"
                    />
                </div>
                <div class="template-slide" key="create-new-template">
                    <div class="create-template-container">
                        <Popout
                            v-model="createPopoutOpen"
                            :trapFocus="true"
                            :externalToggleButton="true"
                            :maxWidth="340"
                            :offset="[0, 8]"
                            :popperOptions="{
                                modifiers: [{ name: 'flip', enabled: false }],
                            }"
                            placement="top"
                            :zIndex="10000000"
                            :borderRadius="20"
                        >
                            <oButton
                                color="white"
                                size="small"
                                @clicked="createPopoutOpen = !createPopoutOpen"
                            >
                                Create Template
                            </oButton>
                            <template #content>
                                <ReportTemplateSelectorItemPopout
                                    :slides="flattenedDefaultSlides"
                                    :templateName="''"
                                    :mode="'create'"
                                    @submit-template="createTemplate"
                                    @cancel="createPopoutOpen = false"
                                />
                            </template>
                        </Popout>
                    </div>
                </div>
            </template>
        </Carousel>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

import {
    oButton,
    oInput,
    Modal,
    Text,
    CheckIcon,
    Ent,
    Spacer,
    Count,
    Popout,
    Carousel,
} from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import ReportTemplateSelectorItem from './ReportTemplateSelectorItem.vue'
import ReportTemplateSelectorItemPopout from './ReportTemplateSelectorItemPopout.vue'

import { useReportTemplateSelector } from '@/composition/reports/useReportTemplateSelector'

export default {
    components: {
        oButton,
        oInput,
        Modal,
        Text,
        CheckIcon,
        Ent,
        Spacer,
        Count,
        Popout,
        Skeleton,
        Carousel,
        ReportTemplateSelectorItem,
        ReportTemplateSelectorItemPopout,
    },
    props: {
        templateId: {
            type: Number as PropType<number>,
            required: false,
        },
    },
    emits: ['update-template'],
    setup(props, { emit }) {
        const emitTemplateId = (templateId: number) => emit('update-template', templateId)
        return useReportTemplateSelector(props, emitTemplateId)
    },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.template-selector-carousel {
    height: 320px;
}

.template-slide {
    @include container;
    flex: 0 0 258px;
    max-width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 1.5rem;
}

.create-template-container {
    width: 100%;
    height: 100%;
    @include flex-center;
    @include pa-24;
    cursor: default;
}

.create-template-popout {
    @include pa-24;
}
.create-template-popout-content {
    @include container;
    @include pa-24;
    max-height: 300px;
    overflow-y: auto;
}
.create-template-popout-buttons {
    display: flex;
    justify-content: flex-start;
}
</style>
