<template>
    <svg
        :class="['statistics-grid', { animated }]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 452"
        fill="none"
    >
        <rect width="640" height="452" fill="white" />
        <line x1="34" y1="206.5" x2="409" y2="206.5" stroke="#EBEBEB" />
        <line x1="158.5" y1="320" x2="158.5" y2="93" stroke="#EBEBEB" />
        <line x1="283.5" y1="320" x2="283.5" y2="93" stroke="#EBEBEB" />
        <rect
            x="33.5"
            y="93.5"
            width="375"
            height="226"
            rx="7.5"
            stroke="black"
            stroke-opacity="0.04"
        />
        <mask
            id="mask0_2_4690"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="34"
            y="93"
            width="374"
            height="227"
        >
            <path
                d="M34 101C34 96.5817 37.5817 93 42 93H400C404.418 93 408 96.5817 408 101V312C408 316.418 404.418 320 400 320H42C37.5817 320 34 316.418 34 312V101Z"
                fill="white"
            />
            <path
                d="M34.5 101C34.5 96.8579 37.8579 93.5 42 93.5H400C404.142 93.5 407.5 96.8579 407.5 101V312C407.5 316.142 404.142 319.5 400 319.5H42C37.8579 319.5 34.5 316.142 34.5 312V101Z"
                stroke="black"
                stroke-opacity="0.08"
            />
        </mask>
        <g mask="url(#mask0_2_4690)"></g>
        <rect x="75" y="388" width="106" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="388" width="70" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="400" width="95" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="400" width="82" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="412" width="66" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="412" width="57" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <circle cx="47" cy="404" r="14" fill="#EBEBEB" />
        <circle cx="499" cy="404" r="14" fill="#EBEBEB" />
        <rect x="33" y="34" width="146" height="24" rx="12" fill="black" fill-opacity="0.08" />
        <rect
            class="metric-value"
            x="196"
            y="113"
            width="52"
            height="22"
            rx="11"
            fill="black"
            fill-opacity="0.12"
        />
        <circle class="delta-arrow-bg" cx="208" cy="179" r="8" fill="#E6F7F0" />
        <path
            class="delta-arrow"
            d="M208 181.908V176.09M208 176.09L205.818 178.272M208 176.09L210.182 178.272"
            stroke="#00A861"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect class="delta" x="220" y="172" width="24" height="14" rx="7" fill="#00A861" />
        <rect x="195" y="149" width="54" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect
            class="metric-value"
            x="71"
            y="113"
            width="52"
            height="22"
            rx="11"
            fill="black"
            fill-opacity="0.12"
        />
        <circle
            class="delta-arrow-bg"
            cx="83"
            cy="179"
            r="8"
            transform="rotate(-180 83 179)"
            fill="#E6F7F0"
        />
        <path
            class="delta-arrow"
            d="M83.0001 176.092V181.91M83.0001 181.91L85.1819 179.728M83.0001 181.91L80.8182 179.728"
            stroke="#00A861"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect class="delta" x="95" y="172" width="24" height="14" rx="7" fill="#00A861" />
        <rect x="70" y="149" width="54" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect
            class="metric-value"
            x="71"
            y="226"
            width="52"
            height="22"
            rx="11"
            fill="black"
            fill-opacity="0.12"
        />
        <circle class="delta-arrow-bg" cx="83" cy="292" r="8" fill="#E6F7F0" />
        <path
            class="delta-arrow"
            d="M82.9999 294.908V289.09M82.9999 289.09L80.8181 291.272M82.9999 289.09L85.1818 291.272"
            stroke="#00A861"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect class="delta" x="95" y="285" width="24" height="14" rx="7" fill="#00A861" />
        <rect x="70" y="262" width="54" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect
            class="metric-value"
            x="196"
            y="226"
            width="52"
            height="22"
            rx="11"
            fill="black"
            fill-opacity="0.12"
        />
        <circle
            class="delta-arrow-bg"
            cx="208"
            cy="292"
            r="8"
            transform="rotate(-180 208 292)"
            fill="#E6F7F0"
        />
        <path
            class="delta-arrow"
            d="M208 289.092V294.91M208 294.91L210.182 292.728M208 294.91L205.818 292.728"
            stroke="#00A861"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect class="delta" x="220" y="285" width="24" height="14" rx="7" fill="#00A861" />
        <rect x="195" y="262" width="54" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect
            class="metric-value"
            x="321"
            y="226"
            width="52"
            height="22"
            rx="11"
            fill="black"
            fill-opacity="0.12"
        />
        <circle class="delta-arrow-bg" cx="333" cy="292" r="8" fill="#E6F7F0" />
        <path
            class="delta-arrow"
            d="M333 294.908V289.09M333 289.09L330.818 291.272M333 289.09L335.182 291.272"
            stroke="#00A861"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect class="delta" x="345" y="285" width="24" height="14" rx="7" fill="#00A861" />
        <rect x="320" y="262" width="54" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect
            class="metric-value"
            x="321"
            y="112"
            width="52"
            height="22"
            rx="11"
            fill="black"
            fill-opacity="0.12"
        />
        <circle class="delta-arrow-bg" cx="333" cy="178" r="8" fill="#E6F7F0" />
        <path
            class="delta-arrow"
            d="M333 180.908V175.09M333 175.09L330.818 177.272M333 175.09L335.182 177.272"
            stroke="#00A861"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect class="delta" x="345" y="171" width="24" height="14" rx="7" fill="#00A861" />
        <rect
            class="line"
            x="320"
            y="148"
            width="54"
            height="8"
            rx="4"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="93"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="111"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="129"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="147"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="165"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="183"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="201"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="219"
            width="153"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
    </svg>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'

export default {
    props: {
        animated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        onMounted(() => {
            if (!props.animated) {
                return
            }

            const timeline = anime.timeline()
            timeline
                .add({
                    targets: '.statistics-grid .metric-value',
                    opacity: [0, 1],
                    translateY: [4, 0],
                    delay: 600,
                    duration: 600,
                    easing: 'easeInCubic',
                })
                .add(
                    {
                        targets: '.statistics-grid .delta',
                        opacity: [0, 1],
                        delay: anime.stagger(100),
                        duration: 600,
                        easing: 'easeInCubic',
                    },
                    '-=500'
                )
                .add(
                    {
                        targets: '.statistics-grid .delta-arrow',
                        opacity: [0, 1],
                        delay: anime.stagger(100),
                        duration: 600,
                        easing: 'easeInCubic',
                    },
                    '-=650'
                )
                .add(
                    {
                        targets: '.statistics-grid .delta-arrow-bg',
                        opacity: [0, 1],
                        delay: anime.stagger(100),
                        duration: 600,
                        easing: 'easeOutCubic',
                    },
                    '-=800'
                )
                .add(
                    {
                        targets: '.statistics-grid .line',
                        opacity: [0, 1],
                        delay: anime.stagger(100),
                        duration: 500,
                        easing: 'easeOutCubic',
                    },
                    '-=1200'
                )
        })
    },
}
</script>

<style lang="scss" scoped>
svg {
    width: 100%;
    transform: scale(1);
    transform-origin: center;
}

svg.animated {
    .line,
    .metric-value,
    .delta,
    .delta-arrow,
    .delta-arrow-bg {
        opacity: 0;
        will-change: opacity;
    }
    .metric-value {
        transform: translateY(4);
    }
}
</style>
