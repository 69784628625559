<template>
    <div :class="[noWrapper ? 'no-wrapper' : '', 'rsa-asset-collection']">
        <header v-if="noWrapper === false">
            <div class="left">
                <Text as="h5" weight="600">{{ title }}</Text>
            </div>
            <div class="header-slot">
                <slot name="header"></slot>
            </div>
        </header>
        <Spacer v-if="noWrapper === false" height="1.75rem" />
        <div v-if="!error" class="collections">
            <div class="headlines">
                <header>
                    <Text as="h6" size="f-6" weight="600">{{ entity }} Headlines</Text>
                </header>
                <perfect-scrollbar v-if="loading || (headlineAssets?.length ?? 0) > 0" class="assets">
                    <div v-for="(asset, index) in loading ? skeletonAssets : headlineAssets" :key="'asset-' + index">
                        <button class="asset" :class="{ loading }" @click="handleCopy(asset.text)">
                            <div class="asset-value">
                                <Skeleton
                                    v-if="loading"
                                    class="asset-skeleton"
                                    :height="12"
                                    :width="Math.round(Math.random() * 100 + 160)"
                                />
                                <p v-else style="-webkit-line-clamp: 1">
                                    {{ asset.text }}
                                </p>
                            </div>
                            <RsaAssetScore :score="loading ? 0 : asset.score">
                                <slot name="popout" :score="asset.score"></slot>
                            </RsaAssetScore>
                        </button>
                        <Spacer v-if="index + 1 != headlineAssets?.length" height="0.75rem" />
                    </div>
                </perfect-scrollbar>
                <div v-else class="no-assets">
                    <Text as="p" color="gray" size="f-8">No headline assets found.</Text>
                </div>
            </div>
            <div class="descriptions">
                <header>
                    <Text as="h6" size="f-6" weight="600">{{ entity }} Descriptions</Text>
                </header>
                <perfect-scrollbar v-if="loading || (descriptionAssets?.length ?? 0) > 0" class="assets">
                    <div v-for="(asset, index) in loading ? skeletonAssets : descriptionAssets" :key="'asset-' + index">
                        <button class="asset" :class="{ loading }" @click="handleCopy(asset.text)">
                            <div class="asset-value">
                                <div v-if="loading">
                                    <Skeleton
                                        class="asset-skeleton"
                                        :height="12"
                                        :width="Math.round(Math.random() * 100 + 160)"
                                    />
                                    <Spacer height="0.25rem" />
                                    <Skeleton
                                        class="asset-skeleton"
                                        :height="12"
                                        :width="Math.round(Math.random() * 100 + 140)"
                                    />
                                </div>
                                <p v-else style="-webkit-line-clamp: 2">{{ asset.text }}</p>
                            </div>
                            <RsaAssetScore :score="loading ? 0 : asset.score">
                                <slot name="popout" :score="asset.score"></slot>
                            </RsaAssetScore>
                        </button>
                        <Spacer v-if="index + 1 != descriptionAssets?.length" height="0.75rem" />
                    </div>
                </perfect-scrollbar>
                <div v-else class="no-assets">
                    <Text as="p" color="gray" size="f-8">No description assets found.</Text>
                </div>
            </div>
        </div>
        <div v-else class="error">
            <slot name="empty-state"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { useClipboard } from '@vueuse/core'
import { RsaWriter } from '@opteo/types'

import {
    Text,
    Spacer,
    ColorTag,
    oButton,
    Skeleton,
    CopyableString,
    rsaDesktopIcon,
    rsaMobileIcon,
    rsaLeftArrowIcon,
    rsaRightArrowIcon,
    showSnackbar,
} from '@opteo/components-next'

import RsaAssetScore from './RsaAssetScore.vue'

export default defineComponent({
    name: 'RsaAssetCollection',
    components: {
        Text,
        Spacer,
        ColorTag,
        oButton,
        rsaDesktopIcon,
        rsaMobileIcon,
        rsaLeftArrowIcon,
        rsaRightArrowIcon,
        RsaAssetScore,
        Skeleton,
    },
    props: {
        headlineAssets: {
            type: Array as PropType<RsaWriter.TextIdea[]>,
            required: false,
        },
        descriptionAssets: {
            type: Array as PropType<RsaWriter.TextIdea[]>,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        entity: {
            type: String,
            required: true,
        },
        noWrapper: {
            type: Boolean,
            required: false,
            default: false,
        },
        error: {
            type: Error,
            required: false,
        },
        loading: {
            type: Boolean,
            required: false,
        },
    },
    setup(props) {
        const valueToCopy = ref('')
        const { copy, copied } = useClipboard({})

        const skeletonAsset: RsaWriter.TextIdea = {
            text: '',
            score: 0,
        }
        const skeletonAssets = [
            skeletonAsset,
            skeletonAsset,
            skeletonAsset,
            skeletonAsset,
            skeletonAsset,
            skeletonAsset,
            skeletonAsset,
            skeletonAsset,
        ]

        function handleCopy(value: string) {
            if (props.loading) {
                return
            }
            valueToCopy.value = value
            copy(valueToCopy.value)
            showSnackbar('Copied to clipboard successfully.')
        }
        return { valueToCopy, handleCopy, copied, skeletonAssets }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.rsa-asset-collection {
    @include container;
    @include br-24;
    @include pt-28;
    @include ph-32;
    @include pb-32;
    // max-width: 55rem;
    margin: 0 auto;
    @include w-100;
}
.rsa-asset-collection.no-wrapper {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
}
.rsa-asset-collection header {
    @include flex;
    @include items-center;
    @include justify-between;
    height: 1.75rem;
}
.rsa-asset-collection header .left,
.rsa-asset-collection header .header-slot {
    @include flex;
    @include items-center;
}

.rsa-asset-collection .collections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.rsa-asset-collection .collections .no-assets {
    height: 15.875rem;
    @include flex-center;
}

.rsa-asset-collection .collections .headlines,
.rsa-asset-collection .collections .descriptions {
    @include container;
    @include br-20;
}
.rsa-asset-collection .collections .headlines header,
.rsa-asset-collection .collections .descriptions header {
    border-bottom: 1px solid $opteo-light-gray;
    @include pa-24;
    @include flex;
    @include items-center;
    height: auto;
}
.rsa-asset-collection .collections .headlines .assets,
.rsa-asset-collection .collections .descriptions .assets {
    @include pa-24;
    max-height: 24.25rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;
}

.rsa-asset-collection .collections .headlines .assets .asset,
.rsa-asset-collection .collections .descriptions .assets .asset {
    @include bg-opteo-white;
    @include container;
    @include br-16;
    @include flex;
    @include items-center;
    @include justify-between;
    @include w-100;
    @include no-select;
    gap: 0.25rem;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 0;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.rsa-asset-collection .collections .headlines .assets .asset.loading,
.rsa-asset-collection .collections .descriptions .assets .asset.loading {
    cursor: default;
}

.rsa-asset-collection .collections .headlines .assets .asset .asset-skeleton,
.rsa-asset-collection .collections .descriptions .assets .asset .asset-skeleton {
    margin-top: 4px;
    margin-bottom: 4px;
}

.rsa-asset-collection .collections .headlines .assets .asset:focus,
.rsa-asset-collection .collections .descriptions .assets .asset:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}

.rsa-asset-collection .collections .headlines .assets .asset:active,
.rsa-asset-collection .collections .descriptions .assets .asset:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}

.rsa-asset-collection .collections .headlines .assets .asset .asset-value,
.rsa-asset-collection .collections .descriptions .assets .asset .asset-value {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-left: 1rem;
}
.rsa-asset-collection .collections .headlines .assets .asset .asset-value p,
.rsa-asset-collection .collections .descriptions .assets .asset .asset-value p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.rsa-asset-collection .collections .headlines .assets .asset p,
.rsa-asset-collection .collections .descriptions .assets .asset p {
    @include f-8;
    @include lh-150;
    @include tl;
}

.error {
    @include container;
    @include pa-24;
    height: 28.5625rem;
}
</style>
