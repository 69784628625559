<template>
    <ViewBackground :active="modalVisible" :zIndex="999998" transparent />
    <div ref="onboardingtour">
        <OnboardingModal
            :mode="'large-modal'"
            :modelValue="modalVisible"
            :buttonText="buttonText"
            :contentMaxWidth="contentMaxWidth"
            @update:modelValue="modalClosed"
        >
            <template v-slot:header>
                <slot name="header" />
            </template>
            <template v-slot:copy>
                <slot name="copy" />
            </template>
        </OnboardingModal>
    </div>
</template>

<script lang="ts">
import { PropType, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { oButton, Spacer, Text, OnboardingModal, ViewBackground } from '@opteo/components-next'

import { useOnboardingTour, StepName } from '@/composition/user/useOnboardingTour'

export default {
    props: {
        stepName: {
            /* This param must match the field in the users table */
            type: String as PropType<StepName>,
            required: true,
        },
        buttonText: {
            type: String as PropType<string>,
            required: true,
        },
        contentMaxWidth: {
            type: String as PropType<string>,
            required: false,
            default: '25rem',
        },
    },
    components: { oButton, Spacer, Text, OnboardingModal, ViewBackground },
    setup(props) {
        const { modalVisible, dismiss } = useOnboardingTour(props)
        const modalClosed = (isOpen: boolean) => {
            if (isOpen === false) {
                dismiss()
            }
        }
        // Click outside
        const onboardingtour = ref(null)
        onClickOutside(onboardingtour, event => {
            dismiss()
        })
        return { modalClosed, modalVisible, onboardingtour }
    },
}
</script>
