<template>
    <svg
        style="display: block"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="10"
        />
        <path
            d="M14.5688 5.42813C14.9998 5.31124 15.4442 5.25136 15.8907 5.25C17.1837 5.25 18.4236 5.76361 19.3379 6.67785C20.2521 7.59209 20.7657 8.83207 20.7657 10.125C20.7657 11.4179 20.2521 12.6579 19.3379 13.5721C18.4236 14.4864 17.1837 15 15.8907 15"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M1.5 18.5061C2.2612 17.4229 3.27191 16.5388 4.44676 15.9285C5.6216 15.3181 6.92608 14.9995 8.25 14.9995C9.57392 14.9995 10.8784 15.3181 12.0532 15.9285C13.2281 16.5388 14.2388 17.4229 15 18.5061"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.8906 15C17.2147 14.9992 18.5194 15.3174 19.6944 15.9277C20.8693 16.5381 21.8799 17.4225 22.6406 18.5063"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
