import { Scorecard } from '@opteo/types'

export const SubScoreHeaderText = {
    convertingNgramsInAssets: 'Converting N-Grams in Assets',
    assetUniqueness: 'Asset Uniqueness',
    assetPerformanceRating: 'Asset Performance Rating',
    adVariations: 'Use of Ad Variations',
} as const satisfies Record<keyof Scorecard.RsaDetailsSubScores, string>

export type SubScoresPerRsa = Record<string, Scorecard.RsaSubScores>

// Table Items

export type WorstRsasTableItem = {
    rsa: number
    adGroupId: number
    campaignId: number
    cost: number
    cpa: number
    roas: number
    vsAvg: number
}

export type BreakdownTableItem = WorstRsasTableItem & {
    avgCpa: number
    avgRoas: number
    campaign: string
    adGroup: string
    conversions: number
    conversionsValue: number
    health: number
    assetUniqueness: number
    convertingNgramsInAssets: number
    assetPerformanceRating: number
}

export type CsvItem = Omit<BreakdownTableItem, 'adGroupId' | 'campaignId' | 'vsAvg'> & {
    vsAvgCpa: number
    vsAvgRoas: number
}
