import { format as formatDate } from 'date-fns'
import { camelCase, startCase } from 'lodash'

type DownloadCsvArgs = {
    dataSet: string
    columnHeaders?: string[]
    items: Record<string, any>[]
    options?: {
        initialColumnHeaders?: string[]
        orderAlphabetically?: boolean
    }
}

export const formatCsv = ({ columnHeaders, items, options }: Omit<DownloadCsvArgs, 'dataSet'>) => {
    const _items = items[0] ?? []

    const itemsKeys = options?.orderAlphabetically
        ? Object.keys(_items).sort()
        : Object.keys(_items)
    const isItemsEmpty = !items || !itemsKeys?.length

    if (isItemsEmpty) {
        throw new Error('Could not determine csv items')
    }

    let areHeadersValid = true
    let invalidHeader: null | string = null

    if (columnHeaders) {
        areHeadersValid = columnHeaders.every(header => {
            const isValid =
                itemsKeys.includes(header) ||
                itemsKeys.some(key => camelCase(key) === camelCase(header))

            if (!isValid) {
                invalidHeader = header
            }

            return isValid
        })
    }

    if (!areHeadersValid) {
        throw new Error(
            `Csv column headers are invalid: ${
                invalidHeader ?? 'some'
            } header is not found amongst item props`
        )
    }

    const getRowsInOrder = () => {
        const rows = []

        if (options?.initialColumnHeaders) {
            rows.push(...options.initialColumnHeaders)
        }

        if (columnHeaders) {
            rows.push(...columnHeaders)
        }

        const titleCasedRows = (
            options?.initialColumnHeaders ? [...options.initialColumnHeaders, ...rows] : [...rows]
        ).map(row => {
            if (columnHeaders && columnHeaders.includes(row)) {
                return row
            }

            return startCase(row)
        })

        itemsKeys.forEach(key => {
            const titleCaseKey = startCase(key)

            const isAlreadyIncluded =
                titleCasedRows.includes(titleCaseKey) ||
                titleCasedRows.some(row => row.toLowerCase() === key.toLowerCase())

            if (isAlreadyIncluded) return

            titleCasedRows.push(titleCaseKey)
        })

        const uniqueTitleCasedRows = Array.from(new Set(titleCasedRows))

        return uniqueTitleCasedRows
    }

    const rows = getRowsInOrder()
    const formattedRows = [`"${rows.join('","')}"`]

    items.forEach(item => {
        const stringifiedItem = rows
            .map(title => {
                // Convert the title back to camelCase to access the correct property in 'item'
                const camelCaseKey = camelCase(title)

                return `"${item[camelCaseKey] ?? item[title]}"`
            })
            .join(',')

        formattedRows.push(stringifiedItem)
    })

    return formattedRows
}

/**
 * @description Formats a data set to be downloaded as a csv file.
 * If no column headers are sent this function will use the prop names from the item keys.
 * Additionally, you can send initial column headers, which will correspond to the first column names,
 * @param args.dataSet - Will be used to format the filename
 * @param args.columnHeaders - Optional. Must be the equivalent as the keys of the items as title case. Required if sending special characters like parenthesis
 * @param args.items - Content to fill in the csv file with
 * @param args.options - Optional. Initial column headers, which will correspond to the first column names,
 * or whether or not you want to order the keys alphabetially (this will not include the initial column headers)
 * @returns Loading and valid states and a function to trigger the download
 */
export const downloadCsv = ({ dataSet, columnHeaders, items, options }: DownloadCsvArgs) => {
    const formattedRows = formatCsv({ columnHeaders, items, options })

    const link = document.createElement('a')

    if (link.download !== undefined) {
        const csvOutput = formattedRows.join('\n')
        const blob = new Blob([csvOutput], { type: 'data:text/csv;charset=utf-8,%EF%BB%BF' })

        const formattedDateTime = formatDate(new Date(), 'yyyy-MM-dd')

        const filename = `${dataSet} - ${formattedDateTime}.csv`

        // Works only in browsers that support HTML5 download attribute
        link.setAttribute('href', URL.createObjectURL(blob))
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
    }
}
