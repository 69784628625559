export class ApiError extends Error {
    public status: number
    public endpoint: string

    constructor(message: string, status: number, endpoint: string) {
        super(message)
        this.name = 'ApiError'
        this.status = status
        this.endpoint = endpoint
        Object.setPrototypeOf(this, ApiError.prototype)
    }
}
