<template>
    <button class="chat-sidebar-link" @click="onClicked">
        <slot></slot>
    </button>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ChatSidebarLink',
    emits: ['clicked'],
    setup(props, { emit }) {
        // onClicked
        function onClicked() {
            emit('clicked')
        }
        return { onClicked }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chat-sidebar-link {
    font-feature-settings: 'ss01' on, 'ss02' on, 'ss04' on, 'ss05' on, 'ss08' off;
    gap: 0.75rem;
    margin-bottom: 0.625rem;
    @include fw-500;
    outline: none;
    border: none;
    @include bg-opteo-white;
    border-radius: 0.875rem !important;
    padding-left: 0.875rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    @include flex;
    @include items-center;
    @include f-8;
    @include relative;
    cursor: pointer;
    user-select: none;
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    letter-spacing: -0.01rem;
    transition: transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.chat-sidebar-link:last-child {
    margin-bottom: 0;
}

.chat-sidebar-link:hover {
    @include container;
}

.chat-sidebar-link:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}

.chat-sidebar-link:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}
</style>
