<template>
    <section class="chat-error-wrapper">
        <div class="chat-error">
            <div class="icon">
                <ChatIcon />
            </div>
            <div class="response">{{ error }}</div>
        </div>
    </section>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue'

// Local components
import ChatIcon from '@/components/chat/ChatIcon.vue'

export default defineComponent({
    name: 'ChatError',
    components: {
        // Local components
        ChatIcon,
    },
    props: {
        error: {
            type: String,
            required: true,
        },
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Component
.chat-error {
    @include flex;
    @include items-start;
    color: $opteo-dark-red;
    background: $opteo-dark-red-translucent;
    @include br-24;
    @include relative;
    gap: 1.625rem;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
    width: 100%;
    min-height: 4.5rem;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem 1.875rem;
}
.icon {
    flex-shrink: 0;
    top: 1.0625rem;
    position: absolute;
    left: calc(-2.5rem - 1.5rem);
}
.response {
    letter-spacing: -0.08px;
    line-height: 1.625rem;
    width: 100%;
}
</style>
