<template>
    <div class="rsa-container">
        <header>
            <div class="left">
                <Text as="h5" weight="600">Competitor Ads</Text>
            </div>
        </header>
        <Spacer height="1.75rem" />
        <div v-if="state === 'loading'" class="loader"><Spinner /></div>
        <div v-else-if="state === 'no-ads'" class="no-ads">
            <Text as="span" size="f-8" color="gray">No competitor ads found.</Text>
        </div>
        <div v-else>
            <div
                v-for="(ad, index) in ads"
                v-show="index === currentIndex"
                :class="[{ mobile: isMobileMode }, 'ad-container']"
                :key="'ad-' + index"
            >
                <!-- Ad URL -->
                <div class="ad-url">
                    <b>Ad</b>
                    <svg
                        style="width: 0.125rem; height: 0.125rem; flex-shrink: 0; outline: none"
                        viewBox="0 0 2 2"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="1" cy="1" r="1" fill="#202124" />
                    </svg>
                    {{ generateRsaDisplayUrl(ad) }}
                </div>
                <!-- Headlines -->
                <p :style="{ '-webkit-line-clamp': isMobileMode ? 2 : 1 }" class="headlines">
                    <span v-for="(headline, index) in ad.headlines" :key="headline.text" class="headline-wrapper">
                        <CopyableString :value="headline.text" />
                        <span v-if="index + 1 != ad.headlines.length">&nbsp;|&nbsp;</span>
                    </span>
                </p>
                <!-- Descriptions -->
                <p :style="{ '-webkit-line-clamp': isMobileMode ? 3 : 2 }" class="descriptions">
                    <span v-for="(description, index) in ad.descriptions" :key="description.text">
                        <CopyableString :value="description.text" />
                        <span v-if="index + 1 != ad.headlines.length">&nbsp;</span>
                    </span>
                </p>
            </div>
            <Spacer height="2rem" />
            <div class="rsa-controls">
                <div class="left">
                    <RsaButton :double="true" @left-clicked="isMobileMode = false" @right-clicked="isMobileMode = true">
                        <template v-slot:left>
                            <rsaDesktopIcon :hex="isMobileMode ? '#000' : '#006dff'" />
                        </template>
                        <template v-slot:right>
                            <rsaMobileIcon :hex="isMobileMode ? '#006dff' : '#000'" />
                        </template>
                    </RsaButton>
                    <Spacer width="0.75rem" />
                    <RsaButton
                        :double="true"
                        :leftDisabled="currentIndex === 0"
                        :rightDisabled="ads && currentIndex === ads.length - 1"
                        @left-clicked="handlePrevious(currentIndex)"
                        @right-clicked="handleNext(currentIndex)"
                    >
                        <template v-slot:left>
                            <rsaLeftArrowIcon />
                        </template>
                        <template v-slot:right>
                            <rsaRightArrowIcon />
                        </template>
                    </RsaButton>
                    <Spacer width="0.75rem" />
                    <slot name="rsa-controls-left"></slot>
                </div>
                <div class="right">
                    <slot name="rsa-controls-right"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, computed, toRef } from 'vue'

import {
    Text,
    Spinner,
    Spacer,
    ColorTag,
    oButton,
    CopyableString,
    rsaDesktopIcon,
    rsaMobileIcon,
    rsaLeftArrowIcon,
    rsaRightArrowIcon,
} from '@opteo/components-next'
import RsaButton from '@/components/rsaWriter/RsaButton.vue'

import { RsaWriter } from '@opteo/types'
import { generateRsaDisplayUrl } from './useRsa'

export default defineComponent({
    name: 'RsaCompetitorAds',
    props: {
        ads: {
            type: Array as PropType<RsaWriter.CompetitorAd[]>,
            required: false, //can be undefined while loading
        },
    },
    components: {
        Text,
        Spinner,
        Spacer,
        ColorTag,
        oButton,
        RsaButton,
        CopyableString,
        rsaDesktopIcon,
        rsaMobileIcon,
        rsaLeftArrowIcon,
        rsaRightArrowIcon,
    },
    setup(props) {
        const currentIndex = ref(0)
        const isMobileMode = ref(false)

        function handlePrevious(i: number) {
            if (i === 0) {
                currentIndex.value = 0
            } else {
                currentIndex.value = currentIndex.value - 1
            }
        }

        function handleNext(i: number) {
            if (!props.ads) return

            if (i === props.ads.length - 1) {
                currentIndex.value = props.ads.length - 1
            } else {
                currentIndex.value = currentIndex.value + 1
            }
        }

        const state = computed<'loading' | 'no-ads' | 'ready'>(() => {
            if (!props.ads) {
                return 'loading'
            }
            if (props.ads.length === 0) {
                return 'no-ads'
            }

            return 'ready'
        })

        return {
            // variables
            currentIndex,
            isMobileMode,
            state,
            // functions
            handlePrevious,
            handleNext,
            generateRsaDisplayUrl,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.rsa-container {
    @include container;
    @include br-24;
    @include pt-28;
    @include ph-32;
    @include pb-32;
    // max-width: 55rem;
    margin: 0 auto;
    @include w-100;
    font-feature-settings: 'ss01' off, 'ss02' off, 'ss04' off, 'ss05' off, 'ss08' off;
}
.rsa-container:last-child {
    margin: 0 auto;
}
.rsa-container header {
    @include flex;
    @include items-center;
    @include justify-between;
    height: 1.75rem;
}
.rsa-container header .left {
    @include flex;
    @include items-center;
}
.rsa-container header .draft-indicator {
    width: 0.625rem;
    height: 0.625rem;
    @include bg-opteo-blue;
    border-radius: 999px;
}
.rsa-container header .header-slot {
    @include flex;
    @include items-center;
}

// RSA Container
.rsa-container .ad-container {
    @include block;
    @include container;
    @include br-20;
    max-width: 42.5rem;
    width: 100%;
    @include pa-40;
    margin: 0 auto;
    min-height: 11.125rem;
}
.rsa-container .ad-container .ad-url {
    @include flex;
    @include items-center;
    font-family: Arial, sans-serif;
    color: #202124;
    font-size: 0.875rem;
    letter-spacing: 0;
    white-space: nowrap;
}
.rsa-container .ad-container .ad-url b {
    letter-spacing: -0.4px;
}
.rsa-container .ad-container .ad-url svg {
    margin: 0 0.3125rem;
}
.rsa-container .ad-container .headlines {
    @include block;
    font-family: 'Google Sans Text', sans-serif;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: #1a0dab;
    margin-top: 0.5rem;
    margin-bottom: 0.375rem;
    -webkit-font-smoothing: subpixel-antialiased;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include no-select;
}
.rsa-container .ad-container .descriptions {
    @include block;
    font-family: Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    color: #4d5156;
    max-width: 600px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include no-select;
}
.rsa-container .ad-container .statistics {
    @include inline-flex;
    @include items-center;
}
.rsa-container .ad-container .statistic {
    margin-right: 0.375rem;
}

// RSA Container (Mobile)
.rsa-container .ad-container.mobile {
    max-width: 28.75rem;
}
.rsa-container .ad-container.mobile .headlines {
    margin-top: 0.6875rem;
    margin-bottom: 0.5625rem;
}
.rsa-container .ad-container.ad-container.mobile .statistics {
    @include container;
    @include pa-16;
    max-width: 100%;
}

// RSA Container (Paused)
.rsa-container .ad-container.paused .headlines,
.rsa-container .ad-container.paused .descriptions,
.rsa-container .ad-container.paused .ad-url {
    color: #010105;
    opacity: 0.24;
}
.rsa-container .ad-container .ad-url svg circle {
    fill: #010105;
}

.rsa-container .rsa-controls,
.rsa-container .rsa-controls .left,
.rsa-container .rsa-controls .right {
    @include flex;
    @include items-center;
}
.rsa-container .rsa-controls {
    @include justify-between;
}

.rsa-container .no-ads,
.rsa-container .loader {
    @include container;
    @include pa-24;
    height: 15.875rem;
    @include flex-center;
}
</style>
