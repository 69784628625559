<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <circle cx="12" cy="12" r="12" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0)">
            <circle cx="12" cy="12" r="12" fill="#EB2E35" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.244 9H7.75356C6.75955 9 6 9.75873 6 10.694V14.9639C6 14.9838 6.01729 15 6.03845 15H7.39478C7.41583 15 7.43323 14.9838 7.43323 14.9639V10.6154H16.5651V14.9639C16.5651 14.9838 16.5824 15 16.6036 15H17.9616C17.9826 15 18 14.9838 18 14.9639V10.694C18 9.75863 17.2421 9 16.244 9Z"
                fill="white"
            />
        </g>
    </svg>
</template>
