<template>
    <svg
        style="display: block"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            d="M7.0022 4.75195V2.50195"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.0022 17.502V15.252"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M4.7522 7.00195H2.5022"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.5022 13.002H15.2522"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M4.65834 10.0396L3.59896 11.0989C2.89531 11.8026 2.5 12.7569 2.5 13.7521C2.5 14.2448 2.59705 14.7327 2.78561 15.1879C2.97417 15.6431 3.25055 16.0568 3.59896 16.4052C3.94737 16.7536 4.361 17.03 4.81622 17.2185C5.27145 17.4071 5.75935 17.5041 6.25209 17.5041C7.2472 17.5041 8.20156 17.1088 8.90521 16.4052L9.96458 15.3458"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.346 9.96458L16.4054 8.90521C17.1091 8.20156 17.5044 7.2472 17.5044 6.25209C17.5044 5.25697 17.1091 4.30261 16.4054 3.59896C15.7018 2.89531 14.7474 2.5 13.7523 2.5C12.7572 2.5 11.8028 2.89531 11.0992 3.59896L10.0398 4.65834"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
