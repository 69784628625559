<template>
    <svg
        width="680"
        height="408"
        viewBox="0 0 680 408"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1194_11315)">
            <rect width="680" height="408" fill="white" />
            <circle cx="74" cy="51" r="10" fill="#6E3AFF" />
            <g filter="url(#filter0_dd_1194_11315)">
                <circle cx="84" cy="57" r="6" fill="white" />
            </g>
            <g clip-path="url(#clip1_1194_11315)">
                <path
                    d="M83.1851 54.8842C83.2378 54.7459 83.3102 54.6186 83.4178 54.5154C83.8481 54.0961 84.5593 54.2015 84.8513 54.7283C85.0708 55.1279 85.3035 55.5186 85.5296 55.9138C85.9072 56.5702 86.2892 57.2266 86.6624 57.8852C86.9763 58.4362 86.6361 59.1321 86.0126 59.2265C85.6306 59.2835 85.2728 59.1079 85.0752 58.7655C84.7437 58.1881 84.4101 57.6107 84.0786 57.0356C84.072 57.0224 84.0632 57.0114 84.0544 57.0005C84.0193 56.9719 84.0039 56.9302 83.982 56.8929C83.8349 56.6338 83.6834 56.377 83.5363 56.1201C83.4419 55.9533 83.3432 55.7887 83.2488 55.6218C83.1631 55.4725 83.1236 55.3101 83.128 55.1389C83.1346 55.051 83.1456 54.9632 83.1851 54.8842Z"
                    fill="#3C8BD9"
                />
                <path
                    d="M83.185 54.8843C83.1653 54.9633 83.1477 55.0423 83.1433 55.1258C83.1367 55.3102 83.1828 55.4814 83.275 55.6416C83.5165 56.0566 83.758 56.4737 83.9973 56.8908C84.0192 56.9281 84.0368 56.9654 84.0588 57.0005C83.927 57.2288 83.7953 57.4549 83.6614 57.6833C83.477 58.0016 83.2926 58.3221 83.106 58.6404C83.0972 58.6404 83.095 58.636 83.0928 58.6294C83.0906 58.6119 83.0972 58.5965 83.1016 58.5789C83.1916 58.2496 83.117 57.9577 82.8909 57.7074C82.7526 57.5559 82.577 57.4703 82.375 57.4418C82.1116 57.4045 81.8789 57.4725 81.6703 57.6372C81.633 57.6657 81.6088 57.7074 81.5649 57.7294C81.5561 57.7294 81.5518 57.725 81.5496 57.7184C81.6549 57.5362 81.7581 57.354 81.8635 57.1718C82.2982 56.4166 82.7328 55.6614 83.1697 54.9084C83.1741 54.8996 83.1807 54.8931 83.185 54.8843Z"
                    fill="#FABC04"
                />
                <path
                    d="M81.5583 57.7249C81.6 57.6876 81.6396 57.6481 81.6835 57.613C82.2169 57.1915 83.0182 57.4966 83.1345 58.164C83.1631 58.3242 83.1477 58.4779 83.0994 58.6316C83.0972 58.6447 83.095 58.6557 83.0906 58.6689C83.0709 58.704 83.0533 58.7413 83.0314 58.7765C82.836 59.0992 82.5484 59.2594 82.1708 59.2353C81.7384 59.2045 81.3981 58.8796 81.3388 58.4494C81.3103 58.2408 81.352 58.0454 81.4596 57.8654C81.4815 57.8259 81.5078 57.7908 81.532 57.7513C81.543 57.7425 81.5386 57.7249 81.5583 57.7249Z"
                    fill="#34A852"
                />
                <path
                    d="M81.5584 57.725C81.5496 57.7337 81.5496 57.7491 81.5343 57.7513C81.5321 57.7359 81.5409 57.7272 81.5496 57.7162L81.5584 57.725Z"
                    fill="#FABC04"
                />
                <path
                    d="M83.0906 58.6689C83.0818 58.6535 83.0906 58.6426 83.0993 58.6316C83.1015 58.6338 83.1059 58.6382 83.1081 58.6404L83.0906 58.6689Z"
                    fill="#E1C025"
                />
            </g>
            <path
                d="M97.6599 43.82V58H103.14C105.8 58 107.52 56.34 107.52 54.04C107.52 52.28 106.4 51.02 105.02 50.68C106.2 50.28 107.08 49.1 107.08 47.64C107.08 45.44 105.52 43.82 102.76 43.82H97.6599ZM100.38 49.68V46.14H102.32C103.66 46.14 104.36 46.82 104.36 47.9C104.36 48.98 103.6 49.68 102.34 49.68H100.38ZM100.38 55.7V51.96H102.66C104.04 51.96 104.76 52.74 104.76 53.86C104.76 55 103.96 55.7 102.62 55.7H100.38Z"
                fill="black"
            />
            <path
                d="M111.822 58V48.16H109.162V58H111.822ZM108.842 44.94C108.842 45.82 109.582 46.56 110.482 46.56C111.402 46.56 112.122 45.82 112.122 44.94C112.122 44.02 111.402 43.28 110.482 43.28C109.582 43.28 108.842 44.02 108.842 44.94Z"
                fill="black"
            />
            <path
                d="M123.453 43.52H120.833V49.14C120.553 48.62 119.753 47.92 118.053 47.92C115.273 47.92 113.333 50.18 113.333 53.06C113.333 56.04 115.333 58.24 118.133 58.24C119.453 58.24 120.453 57.64 120.893 56.86C120.893 57.32 120.953 57.8 120.993 58H123.533C123.493 57.6 123.453 56.88 123.453 56.22V43.52ZM116.013 53.06C116.013 51.3 117.093 50.3 118.453 50.3C119.813 50.3 120.873 51.28 120.873 53.04C120.873 54.82 119.813 55.86 118.453 55.86C117.053 55.86 116.013 54.82 116.013 53.06Z"
                fill="black"
            />
            <path
                d="M135.099 43.52H132.479V49.14C132.199 48.62 131.399 47.92 129.699 47.92C126.919 47.92 124.979 50.18 124.979 53.06C124.979 56.04 126.979 58.24 129.779 58.24C131.099 58.24 132.099 57.64 132.539 56.86C132.539 57.32 132.599 57.8 132.639 58H135.179C135.139 57.6 135.099 56.88 135.099 56.22V43.52ZM127.659 53.06C127.659 51.3 128.739 50.3 130.099 50.3C131.459 50.3 132.519 51.28 132.519 53.04C132.519 54.82 131.459 55.86 130.099 55.86C128.699 55.86 127.659 54.82 127.659 53.06Z"
                fill="black"
            />
            <path
                d="M139.886 58V48.16H137.226V58H139.886ZM136.906 44.94C136.906 45.82 137.646 46.56 138.546 46.56C139.466 46.56 140.186 45.82 140.186 44.94C140.186 44.02 139.466 43.28 138.546 43.28C137.646 43.28 136.906 44.02 136.906 44.94Z"
                fill="black"
            />
            <path
                d="M144.657 52.34C144.657 51.2 145.337 50.3 146.497 50.3C147.777 50.3 148.317 51.16 148.317 52.26V58H150.977V51.8C150.977 49.64 149.857 47.9 147.417 47.9C146.357 47.9 145.177 48.36 144.577 49.38V48.16H141.997V58H144.657V52.34Z"
                fill="black"
            />
            <path
                d="M152.418 58.54C152.698 60.42 154.498 62.1 157.198 62.1C160.898 62.1 162.358 59.64 162.358 56.94V48.16H159.798V49.32C159.458 48.66 158.558 47.98 156.998 47.98C154.298 47.98 152.418 50.18 152.418 52.78C152.418 55.54 154.378 57.56 156.998 57.56C158.418 57.56 159.338 56.94 159.738 56.3V57.02C159.738 58.92 158.798 59.76 157.118 59.76C155.858 59.76 154.998 58.98 154.818 57.9L152.418 58.54ZM157.458 55.28C156.078 55.28 155.098 54.32 155.098 52.78C155.098 51.26 156.138 50.3 157.458 50.3C158.738 50.3 159.778 51.26 159.778 52.78C159.778 54.3 158.818 55.28 157.458 55.28Z"
                fill="black"
            />
            <path
                d="M177.795 58V55.4H171.675V52.12H177.215V49.66H171.675V46.42H177.795V43.82H168.915V58H177.795Z"
                fill="black"
            />
            <path
                d="M178.517 58H181.517C181.857 57.46 183.177 55.52 183.537 54.98L185.597 58H188.717L185.157 52.98L188.597 48.16H185.577L183.637 51.08C183.297 50.54 182.017 48.7 181.677 48.16H178.517L182.017 53.06L178.517 58Z"
                fill="black"
            />
            <path
                d="M192.36 61.8V57.04C192.84 57.7 193.84 58.24 195.2 58.24C197.98 58.24 199.84 56.04 199.84 53.06C199.84 50.14 198.18 47.94 195.3 47.94C193.82 47.94 192.72 48.6 192.28 49.36V48.16H189.7V61.8H192.36ZM197.22 53.08C197.22 54.84 196.14 55.86 194.78 55.86C193.42 55.86 192.32 54.82 192.32 53.08C192.32 51.34 193.42 50.32 194.78 50.32C196.14 50.32 197.22 51.34 197.22 53.08Z"
                fill="black"
            />
            <path
                d="M203.485 51.96C203.545 51.06 204.305 50.02 205.685 50.02C207.205 50.02 207.845 50.98 207.885 51.96H203.485ZM208.145 54.52C207.825 55.4 207.145 56.02 205.905 56.02C204.585 56.02 203.485 55.08 203.425 53.78H210.465C210.465 53.74 210.505 53.34 210.505 52.96C210.505 49.8 208.685 47.86 205.645 47.86C203.125 47.86 200.805 49.9 200.805 53.04C200.805 56.36 203.185 58.3 205.885 58.3C208.305 58.3 209.865 56.88 210.365 55.18L208.145 54.52Z"
                fill="black"
            />
            <path
                d="M218.054 48.12C217.854 48.1 217.654 48.08 217.434 48.08C216.594 48.08 215.234 48.32 214.634 49.62V48.16H212.054V58H214.714V53.5C214.714 51.38 215.894 50.72 217.254 50.72C217.494 50.72 217.754 50.74 218.054 50.8V48.12Z"
                fill="black"
            />
            <path
                d="M221.868 58V48.16H219.208V58H221.868ZM218.888 44.94C218.888 45.82 219.628 46.56 220.528 46.56C221.448 46.56 222.168 45.82 222.168 44.94C222.168 44.02 221.448 43.28 220.528 43.28C219.628 43.28 218.888 44.02 218.888 44.94Z"
                fill="black"
            />
            <path
                d="M226.639 58V52.24C226.639 51.16 227.319 50.3 228.479 50.3C229.679 50.3 230.219 51.1 230.219 52.16V58H232.859V52.24C232.859 51.18 233.539 50.3 234.679 50.3C235.899 50.3 236.419 51.1 236.419 52.16V58H238.999V51.62C238.999 48.98 237.259 47.88 235.439 47.88C234.139 47.88 233.099 48.32 232.319 49.52C231.819 48.46 230.739 47.88 229.399 47.88C228.319 47.88 227.059 48.4 226.519 49.36V48.16H223.979V58H226.639Z"
                fill="black"
            />
            <path
                d="M243.116 51.96C243.176 51.06 243.936 50.02 245.316 50.02C246.836 50.02 247.476 50.98 247.516 51.96H243.116ZM247.776 54.52C247.456 55.4 246.776 56.02 245.536 56.02C244.216 56.02 243.116 55.08 243.056 53.78H250.096C250.096 53.74 250.136 53.34 250.136 52.96C250.136 49.8 248.316 47.86 245.276 47.86C242.756 47.86 240.436 49.9 240.436 53.04C240.436 56.36 242.816 58.3 245.516 58.3C247.936 58.3 249.496 56.88 249.996 55.18L247.776 54.52Z"
                fill="black"
            />
            <path
                d="M254.346 52.34C254.346 51.2 255.026 50.3 256.186 50.3C257.466 50.3 258.006 51.16 258.006 52.26V58H260.666V51.8C260.666 49.64 259.546 47.9 257.106 47.9C256.046 47.9 254.866 48.36 254.266 49.38V48.16H251.686V58H254.346V52.34Z"
                fill="black"
            />
            <path
                d="M266.147 45.22H263.747V46.6C263.747 47.48 263.267 48.16 262.227 48.16H261.727V50.52H263.507V55.1C263.507 57 264.707 58.14 266.627 58.14C267.407 58.14 267.887 58 268.127 57.9V55.7C267.987 55.74 267.627 55.78 267.307 55.78C266.547 55.78 266.147 55.5 266.147 54.64V50.52H268.127V48.16H266.147V45.22Z"
                fill="black"
            />
            <path
                d="M269.005 55.26C269.125 56.38 270.145 58.3 273.105 58.3C275.685 58.3 276.925 56.66 276.925 55.06C276.925 53.62 275.945 52.44 274.005 52.04L272.605 51.74C272.065 51.64 271.705 51.34 271.705 50.86C271.705 50.3 272.265 49.88 272.965 49.88C274.085 49.88 274.505 50.62 274.585 51.2L276.805 50.7C276.685 49.64 275.745 47.86 272.945 47.86C270.825 47.86 269.265 49.32 269.265 51.08C269.265 52.46 270.125 53.6 272.025 54.02L273.325 54.32C274.085 54.48 274.385 54.84 274.385 55.28C274.385 55.8 273.965 56.26 273.085 56.26C271.925 56.26 271.345 55.54 271.285 54.76L269.005 55.26Z"
                fill="black"
            />
            <g filter="url(#filter1_dd_1194_11315)">
                <rect x="420" y="40" width="196" height="22" rx="11" fill="white" />
            </g>
            <rect opacity="0.1" x="557" y="48" width="32" height="6" rx="3" fill="#006DFF" />
            <g filter="url(#filter2_dd_1194_11315)">
                <rect x="595" y="46" width="10" height="10" rx="5" fill="white" />
            </g>
            <g filter="url(#filter3_d_1194_11315)">
                <path d="M494 40H548V62H494V40Z" fill="white" />
            </g>
            <rect x="503" y="48" width="20" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter4_dd_1194_11315)">
                <rect x="529" y="46" width="10" height="10" rx="5" fill="white" />
            </g>
            <g filter="url(#filter5_d_1194_11315)">
                <path
                    d="M420 51C420 44.9249 424.925 40 431 40H494V62H431C424.925 62 420 57.0751 420 51Z"
                    fill="white"
                />
            </g>
            <rect x="430" y="48" width="56" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter6_dd_1194_11315)">
                <rect x="64" y="98" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="114" width="56" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter7_dd_1194_11315)">
                <circle cx="148" cy="118" r="6" fill="white" />
            </g>
            <rect x="160" y="114" width="65" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="237"
                y="107"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M251 123C253.761 123 256 120.761 256 118C256 115.239 253.761 113 251 113C248.239 113 246 115.239 246 118C246 120.761 248.239 123 251 123ZM251 124C247.686 124 245 121.314 245 118C245 114.686 247.686 112 251 112C254.314 112 257 114.686 257 118C257 121.314 254.314 124 251 124Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M251.064 120.605C252.457 120.605 253.171 119.681 253.36 118.918L252.457 118.645C252.324 119.107 251.911 119.66 251.064 119.66C250.266 119.66 249.524 119.079 249.524 118.022C249.524 116.895 250.308 116.363 251.05 116.363C251.911 116.363 252.296 116.888 252.415 117.364L253.325 117.077C253.129 116.272 252.422 115.432 251.05 115.432C249.72 115.432 248.523 116.44 248.523 118.022C248.523 119.604 249.678 120.605 251.064 120.605Z"
                fill="#0035EC"
            />
            <rect x="263" y="115" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="114" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="115" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="114" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="115" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip2_1194_11315)">
                <path
                    opacity="0.1"
                    d="M535.248 118C535.248 118.689 535.112 119.372 534.848 120.008C534.584 120.645 534.197 121.224 533.709 121.711C533.222 122.199 532.643 122.586 532.006 122.849C531.369 123.113 530.686 123.249 529.997 123.249C529.308 123.249 528.625 123.113 527.988 122.849C527.351 122.586 526.773 122.199 526.285 121.711C525.798 121.224 525.412 120.645 525.148 120.008C524.884 119.372 524.749 118.689 524.749 118C524.749 117.31 524.885 116.628 525.149 115.991C525.413 115.354 525.799 114.775 526.287 114.288C526.775 113.8 527.353 113.413 527.99 113.15C528.627 112.886 529.31 112.75 529.999 112.75C530.689 112.75 531.371 112.886 532.008 113.15C532.645 113.413 533.224 113.8 533.711 114.288C534.198 114.775 534.585 115.354 534.849 115.991C535.112 116.628 535.248 117.31 535.248 118L535.248 118Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 112.75C530.689 112.75 531.371 112.886 532.008 113.15C532.645 113.413 533.223 113.8 533.711 114.287C534.198 114.775 534.585 115.354 534.848 115.99C535.112 116.627 535.248 117.31 535.247 117.999C535.247 118.689 535.111 119.371 534.847 120.008C534.583 120.645 534.197 121.224 533.709 121.711C533.222 122.199 532.643 122.585 532.006 122.849C531.369 123.113 530.686 123.249 529.997 123.249C529.308 123.249 528.625 123.113 527.988 122.849C527.351 122.585 526.773 122.199 526.285 121.711C525.798 121.224 525.412 120.645 525.148 120.008C524.884 119.371 524.749 118.689 524.749 117.999C524.749 117.31 524.885 116.627 525.149 115.99C525.413 115.354 525.799 114.775 526.287 114.287C526.775 113.8 527.353 113.413 527.99 113.15C528.627 112.886 529.31 112.75 529.999 112.75L529.999 112.75Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="111" width="56" height="6" rx="3" fill="#00A861" />
            <rect x="544" y="121" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter8_dd_1194_11315)">
                <rect x="64" y="146" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="162" width="56" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter9_dd_1194_11315)">
                <circle cx="148" cy="166" r="6" fill="white" />
            </g>
            <rect x="160" y="162" width="72" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="244"
                y="155"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M258 171C260.761 171 263 168.761 263 166C263 163.239 260.761 161 258 161C255.239 161 253 163.239 253 166C253 168.761 255.239 171 258 171ZM258 172C254.686 172 252 169.314 252 166C252 162.686 254.686 160 258 160C261.314 160 264 162.686 264 166C264 169.314 261.314 172 258 172Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.064 168.605C259.457 168.605 260.171 167.681 260.36 166.918L259.457 166.645C259.324 167.107 258.911 167.66 258.064 167.66C257.266 167.66 256.524 167.079 256.524 166.022C256.524 164.895 257.308 164.363 258.05 164.363C258.911 164.363 259.296 164.888 259.415 165.364L260.325 165.077C260.129 164.272 259.422 163.432 258.05 163.432C256.72 163.432 255.523 164.44 255.523 166.022C255.523 167.604 256.678 168.605 258.064 168.605Z"
                fill="#0035EC"
            />
            <rect x="270" y="163" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="162" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="163" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="162" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="163" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip3_1194_11315)">
                <path
                    opacity="0.1"
                    d="M535.248 166C535.248 166.689 535.112 167.372 534.848 168.008C534.584 168.645 534.197 169.224 533.709 169.711C533.222 170.199 532.643 170.586 532.006 170.849C531.369 171.113 530.686 171.249 529.997 171.249C529.308 171.249 528.625 171.113 527.988 170.849C527.351 170.586 526.773 170.199 526.285 169.711C525.798 169.224 525.412 168.645 525.148 168.008C524.884 167.372 524.749 166.689 524.749 166C524.749 165.31 524.885 164.628 525.149 163.991C525.413 163.354 525.799 162.775 526.287 162.288C526.775 161.8 527.353 161.413 527.99 161.15C528.627 160.886 529.31 160.75 529.999 160.75C530.689 160.75 531.371 160.886 532.008 161.15C532.645 161.413 533.224 161.8 533.711 162.288C534.198 162.775 534.585 163.354 534.849 163.991C535.112 164.628 535.248 165.31 535.248 166L535.248 166Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 160.75C530.689 160.75 531.371 160.886 532.008 161.15C532.645 161.413 533.223 161.8 533.711 162.287C534.198 162.775 534.585 163.354 534.848 163.99C535.112 164.627 535.248 165.31 535.247 165.999C535.247 166.689 535.111 167.371 534.847 168.008C534.583 168.645 534.197 169.224 533.709 169.711C533.222 170.199 532.643 170.585 532.006 170.849C531.369 171.113 530.686 171.249 529.997 171.249C529.308 171.249 528.625 171.113 527.988 170.849C527.351 170.585 526.773 170.199 526.285 169.711C525.798 169.224 525.412 168.645 525.148 168.008C524.884 167.371 524.749 166.689 524.749 165.999C524.749 165.31 524.885 164.627 525.149 163.99C525.413 163.354 525.799 162.775 526.287 162.287C526.775 161.8 527.353 161.413 527.99 161.15C528.627 160.886 529.31 160.75 529.999 160.75L529.999 160.75Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="159" width="56" height="6" rx="3" fill="#00A861" />
            <rect x="544" y="169" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter10_dd_1194_11315)">
                <rect x="64" y="194" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="210" width="48" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter11_dd_1194_11315)">
                <circle cx="140" cy="214" r="6" fill="white" />
            </g>
            <rect x="152" y="210" width="64" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="228"
                y="203"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M242 219C244.761 219 247 216.761 247 214C247 211.239 244.761 209 242 209C239.239 209 237 211.239 237 214C237 216.761 239.239 219 242 219ZM242 220C238.686 220 236 217.314 236 214C236 210.686 238.686 208 242 208C245.314 208 248 210.686 248 214C248 217.314 245.314 220 242 220Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M242.064 216.605C243.457 216.605 244.171 215.681 244.36 214.918L243.457 214.645C243.324 215.107 242.911 215.66 242.064 215.66C241.266 215.66 240.524 215.079 240.524 214.022C240.524 212.895 241.308 212.363 242.05 212.363C242.911 212.363 243.296 212.888 243.415 213.364L244.325 213.077C244.129 212.272 243.422 211.432 242.05 211.432C240.72 211.432 239.523 212.44 239.523 214.022C239.523 215.604 240.678 216.605 242.064 216.605Z"
                fill="#0035EC"
            />
            <rect x="254" y="211" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="210" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="211" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="210" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="211" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip4_1194_11315)">
                <path
                    opacity="0.1"
                    d="M535.248 214C535.248 214.689 535.112 215.372 534.848 216.008C534.584 216.645 534.197 217.224 533.709 217.711C533.222 218.199 532.643 218.586 532.006 218.849C531.369 219.113 530.686 219.249 529.997 219.249C529.308 219.249 528.625 219.113 527.988 218.849C527.351 218.586 526.773 218.199 526.285 217.711C525.798 217.224 525.412 216.645 525.148 216.008C524.884 215.372 524.749 214.689 524.749 214C524.749 213.31 524.885 212.628 525.149 211.991C525.413 211.354 525.799 210.775 526.287 210.288C526.775 209.8 527.353 209.413 527.99 209.15C528.627 208.886 529.31 208.75 529.999 208.75C530.689 208.75 531.371 208.886 532.008 209.15C532.645 209.413 533.224 209.8 533.711 210.288C534.198 210.775 534.585 211.354 534.849 211.991C535.112 212.628 535.248 213.31 535.248 214L535.248 214Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 208.75C530.689 208.75 531.371 208.886 532.008 209.15C532.645 209.413 533.223 209.8 533.711 210.287C534.198 210.775 534.585 211.354 534.848 211.99C535.112 212.627 535.248 213.31 535.247 213.999C535.247 214.689 535.111 215.371 534.847 216.008C534.583 216.645 534.197 217.224 533.709 217.711C533.222 218.199 532.643 218.585 532.006 218.849C531.369 219.113 530.686 219.249 529.997 219.249C529.308 219.249 528.625 219.113 527.988 218.849C527.351 218.585 526.773 218.199 526.285 217.711C525.798 217.224 525.412 216.645 525.148 216.008C524.884 215.371 524.749 214.689 524.749 213.999C524.749 213.31 524.885 212.627 525.149 211.99C525.413 211.354 525.799 210.775 526.287 210.287C526.775 209.8 527.353 209.413 527.99 209.15C528.627 208.886 529.31 208.75 529.999 208.75L529.999 208.75Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="207" width="56" height="6" rx="3" fill="#00A861" />
            <rect x="544" y="217" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter12_dd_1194_11315)">
                <rect x="64" y="242" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="258" width="54" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter13_dd_1194_11315)">
                <circle cx="146" cy="262" r="6" fill="white" />
            </g>
            <rect x="158" y="258" width="64" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="234"
                y="251"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M248 267C250.761 267 253 264.761 253 262C253 259.239 250.761 257 248 257C245.239 257 243 259.239 243 262C243 264.761 245.239 267 248 267ZM248 268C244.686 268 242 265.314 242 262C242 258.686 244.686 256 248 256C251.314 256 254 258.686 254 262C254 265.314 251.314 268 248 268Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M248.064 264.605C249.457 264.605 250.171 263.681 250.36 262.918L249.457 262.645C249.324 263.107 248.911 263.66 248.064 263.66C247.266 263.66 246.524 263.079 246.524 262.022C246.524 260.895 247.308 260.363 248.05 260.363C248.911 260.363 249.296 260.888 249.415 261.364L250.325 261.077C250.129 260.272 249.422 259.432 248.05 259.432C246.72 259.432 245.523 260.44 245.523 262.022C245.523 263.604 246.678 264.605 248.064 264.605Z"
                fill="#0035EC"
            />
            <rect x="260" y="259" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="258" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="259" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="258" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="259" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip5_1194_11315)">
                <path
                    opacity="0.1"
                    d="M535.248 262C535.248 262.689 535.112 263.372 534.848 264.008C534.584 264.645 534.197 265.224 533.709 265.711C533.222 266.199 532.643 266.586 532.006 266.849C531.369 267.113 530.686 267.249 529.997 267.249C529.308 267.249 528.625 267.113 527.988 266.849C527.351 266.586 526.773 266.199 526.285 265.711C525.798 265.224 525.412 264.645 525.148 264.008C524.884 263.372 524.749 262.689 524.749 262C524.749 261.31 524.885 260.628 525.149 259.991C525.413 259.354 525.799 258.775 526.287 258.288C526.775 257.8 527.353 257.413 527.99 257.15C528.627 256.886 529.31 256.75 529.999 256.75C530.689 256.75 531.371 256.886 532.008 257.15C532.645 257.413 533.224 257.8 533.711 258.288C534.198 258.775 534.585 259.354 534.849 259.991C535.112 260.628 535.248 261.31 535.248 262L535.248 262Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 256.75C530.689 256.75 531.371 256.886 532.008 257.15C532.645 257.413 533.223 257.8 533.711 258.287C534.198 258.775 534.585 259.354 534.848 259.99C535.112 260.627 535.248 261.31 535.247 261.999C535.247 262.689 535.111 263.371 534.847 264.008C534.583 264.645 534.197 265.224 533.709 265.711C533.222 266.199 532.643 266.585 532.006 266.849C531.369 267.113 530.686 267.249 529.997 267.249C529.308 267.249 528.625 267.113 527.988 266.849C527.351 266.585 526.773 266.199 526.285 265.711C525.798 265.224 525.412 264.645 525.148 264.008C524.884 263.371 524.749 262.689 524.749 261.999C524.749 261.31 524.885 260.627 525.149 259.99C525.413 259.354 525.799 258.775 526.287 258.287C526.775 257.8 527.353 257.413 527.99 257.15C528.627 256.886 529.31 256.75 529.999 256.75L529.999 256.75Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="255" width="56" height="6" rx="3" fill="#00A861" />
            <rect x="544" y="265" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter14_dd_1194_11315)">
                <rect x="64" y="290" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="306" width="48" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter15_dd_1194_11315)">
                <circle cx="140" cy="310" r="6" fill="white" />
            </g>
            <rect x="152" y="306" width="62" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="226"
                y="299"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M240 315C242.761 315 245 312.761 245 310C245 307.239 242.761 305 240 305C237.239 305 235 307.239 235 310C235 312.761 237.239 315 240 315ZM240 316C236.686 316 234 313.314 234 310C234 306.686 236.686 304 240 304C243.314 304 246 306.686 246 310C246 313.314 243.314 316 240 316Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M240.064 312.605C241.457 312.605 242.171 311.681 242.36 310.918L241.457 310.645C241.324 311.107 240.911 311.66 240.064 311.66C239.266 311.66 238.524 311.079 238.524 310.022C238.524 308.895 239.308 308.363 240.05 308.363C240.911 308.363 241.296 308.888 241.415 309.364L242.325 309.077C242.129 308.272 241.422 307.432 240.05 307.432C238.72 307.432 237.523 308.44 237.523 310.022C237.523 311.604 238.678 312.605 240.064 312.605Z"
                fill="#0035EC"
            />
            <rect x="252" y="307" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="306" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="307" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="306" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="307" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip6_1194_11315)">
                <path
                    opacity="0.1"
                    d="M535.248 310C535.248 310.689 535.112 311.372 534.848 312.008C534.584 312.645 534.197 313.224 533.709 313.711C533.222 314.199 532.643 314.586 532.006 314.849C531.369 315.113 530.686 315.249 529.997 315.249C529.308 315.249 528.625 315.113 527.988 314.849C527.351 314.586 526.773 314.199 526.285 313.711C525.798 313.224 525.412 312.645 525.148 312.008C524.884 311.372 524.749 310.689 524.749 310C524.749 309.31 524.885 308.628 525.149 307.991C525.413 307.354 525.799 306.775 526.287 306.288C526.775 305.8 527.353 305.413 527.99 305.15C528.627 304.886 529.31 304.75 529.999 304.75C530.689 304.75 531.371 304.886 532.008 305.15C532.645 305.413 533.224 305.8 533.711 306.288C534.198 306.775 534.585 307.354 534.849 307.991C535.112 308.628 535.248 309.31 535.248 310L535.248 310Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 304.75C530.689 304.75 531.371 304.886 532.008 305.15C532.645 305.413 533.223 305.8 533.711 306.287C534.198 306.775 534.585 307.354 534.848 307.99C535.112 308.627 535.248 309.31 535.247 309.999C535.247 310.689 535.111 311.371 534.847 312.008C534.583 312.645 534.197 313.224 533.709 313.711C533.222 314.199 532.643 314.585 532.006 314.849C531.369 315.113 530.686 315.249 529.997 315.249C529.308 315.249 528.625 315.113 527.988 314.849C527.351 314.585 526.773 314.199 526.285 313.711C525.798 313.224 525.412 312.645 525.148 312.008C524.884 311.371 524.749 310.689 524.749 309.999C524.749 309.31 524.885 308.627 525.149 307.99C525.413 307.354 525.799 306.775 526.287 306.287C526.775 305.8 527.353 305.413 527.99 305.15C528.627 304.886 529.31 304.75 529.999 304.75L529.999 304.75Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="303" width="56" height="6" rx="3" fill="#00A861" />
            <rect x="544" y="313" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter16_dd_1194_11315)">
                <rect x="64" y="338" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="354" width="56" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter17_dd_1194_11315)">
                <circle cx="148" cy="358" r="6" fill="white" />
            </g>
            <rect x="160" y="354" width="48" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="220"
                y="347"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M234 363C236.761 363 239 360.761 239 358C239 355.239 236.761 353 234 353C231.239 353 229 355.239 229 358C229 360.761 231.239 363 234 363ZM234 364C230.686 364 228 361.314 228 358C228 354.686 230.686 352 234 352C237.314 352 240 354.686 240 358C240 361.314 237.314 364 234 364Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M234.064 360.605C235.457 360.605 236.171 359.681 236.36 358.918L235.457 358.645C235.324 359.107 234.911 359.66 234.064 359.66C233.266 359.66 232.524 359.079 232.524 358.022C232.524 356.895 233.308 356.363 234.05 356.363C234.911 356.363 235.296 356.888 235.415 357.364L236.325 357.077C236.129 356.272 235.422 355.432 234.05 355.432C232.72 355.432 231.523 356.44 231.523 358.022C231.523 359.604 232.678 360.605 234.064 360.605Z"
                fill="#0035EC"
            />
            <rect x="246" y="355" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="354" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="355" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="354" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="355" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip7_1194_11315)">
                <path
                    opacity="0.1"
                    d="M535.248 358C535.248 358.689 535.112 359.372 534.848 360.008C534.584 360.645 534.197 361.224 533.709 361.711C533.222 362.199 532.643 362.586 532.006 362.849C531.369 363.113 530.686 363.249 529.997 363.249C529.308 363.249 528.625 363.113 527.988 362.849C527.351 362.586 526.773 362.199 526.285 361.711C525.798 361.224 525.412 360.645 525.148 360.008C524.884 359.372 524.749 358.689 524.749 358C524.749 357.31 524.885 356.628 525.149 355.991C525.413 355.354 525.799 354.775 526.287 354.288C526.775 353.8 527.353 353.413 527.99 353.15C528.627 352.886 529.31 352.75 529.999 352.75C530.689 352.75 531.371 352.886 532.008 353.15C532.645 353.413 533.224 353.8 533.711 354.288C534.198 354.775 534.585 355.354 534.849 355.991C535.112 356.628 535.248 357.31 535.248 358L535.248 358Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 352.75C530.689 352.75 531.371 352.886 532.008 353.15C532.645 353.413 533.223 353.8 533.711 354.287C534.198 354.775 534.585 355.354 534.848 355.99C535.112 356.627 535.248 357.31 535.247 357.999C535.247 358.689 535.111 359.371 534.847 360.008C534.583 360.645 534.197 361.224 533.709 361.711C533.222 362.199 532.643 362.585 532.006 362.849C531.369 363.113 530.686 363.249 529.997 363.249C529.308 363.249 528.625 363.113 527.988 362.849C527.351 362.585 526.773 362.199 526.285 361.711C525.798 361.224 525.412 360.645 525.148 360.008C524.884 359.371 524.749 358.689 524.749 357.999C524.749 357.31 524.885 356.627 525.149 355.99C525.413 355.354 525.799 354.775 526.287 354.287C526.775 353.8 527.353 353.413 527.99 353.15C528.627 352.886 529.31 352.75 529.999 352.75L529.999 352.75Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="351" width="56" height="6" rx="3" fill="#00A861" />
            <rect x="544" y="361" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter18_dd_1194_11315)">
                <rect x="64" y="386" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="402" width="52" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter19_dd_1194_11315)">
                <circle cx="144" cy="406" r="6" fill="white" />
            </g>
            <rect x="156" y="402" width="54" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="222"
                y="395"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M236 411C238.761 411 241 408.761 241 406C241 403.239 238.761 401 236 401C233.239 401 231 403.239 231 406C231 408.761 233.239 411 236 411ZM236 412C232.686 412 230 409.314 230 406C230 402.686 232.686 400 236 400C239.314 400 242 402.686 242 406C242 409.314 239.314 412 236 412Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M236.064 408.605C237.457 408.605 238.171 407.681 238.36 406.918L237.457 406.645C237.324 407.107 236.911 407.66 236.064 407.66C235.266 407.66 234.524 407.079 234.524 406.022C234.524 404.895 235.308 404.363 236.05 404.363C236.911 404.363 237.296 404.888 237.415 405.364L238.325 405.077C238.129 404.272 237.422 403.432 236.05 403.432C234.72 403.432 233.523 404.44 233.523 406.022C233.523 407.604 234.678 408.605 236.064 408.605Z"
                fill="#0035EC"
            />
            <rect x="248" y="403" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="402" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="403" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="402" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="403" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip8_1194_11315)">
                <path
                    opacity="0.1"
                    d="M535.248 406C535.248 406.689 535.112 407.372 534.848 408.008C534.584 408.645 534.197 409.224 533.709 409.711C533.222 410.199 532.643 410.586 532.006 410.849C531.369 411.113 530.686 411.249 529.997 411.249C529.308 411.249 528.625 411.113 527.988 410.849C527.351 410.586 526.773 410.199 526.285 409.711C525.798 409.224 525.412 408.645 525.148 408.008C524.884 407.372 524.749 406.689 524.749 406C524.749 405.31 524.885 404.628 525.149 403.991C525.413 403.354 525.799 402.775 526.287 402.288C526.775 401.8 527.353 401.413 527.99 401.15C528.627 400.886 529.31 400.75 529.999 400.75C530.689 400.75 531.371 400.886 532.008 401.15C532.645 401.413 533.224 401.8 533.711 402.288C534.198 402.775 534.585 403.354 534.849 403.991C535.112 404.628 535.248 405.31 535.248 406L535.248 406Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 400.75C530.689 400.75 531.371 400.886 532.008 401.15C532.645 401.413 533.223 401.8 533.711 402.287C534.198 402.775 534.585 403.354 534.848 403.99C535.112 404.627 535.248 405.31 535.247 405.999C535.247 406.689 535.111 407.371 534.847 408.008C534.583 408.645 534.197 409.224 533.709 409.711C533.222 410.199 532.643 410.585 532.006 410.849C531.369 411.113 530.686 411.249 529.997 411.249C529.308 411.249 528.625 411.113 527.988 410.849C527.351 410.585 526.773 410.199 526.285 409.711C525.798 409.224 525.412 408.645 525.148 408.008C524.884 407.371 524.749 406.689 524.749 405.999C524.749 405.31 524.885 404.627 525.149 403.99C525.413 403.354 525.799 402.775 526.287 402.287C526.775 401.8 527.353 401.413 527.99 401.15C528.627 400.886 529.31 400.75 529.999 400.75L529.999 400.75Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="399" width="56" height="6" rx="3" fill="#00A861" />
        </g>
        <defs>
            <filter
                id="filter0_dd_1194_11315"
                x="76"
                y="50"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_dd_1194_11315"
                x="418"
                y="39"
                width="200"
                height="27"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_dd_1194_11315"
                x="593"
                y="45"
                width="14"
                height="15"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_d_1194_11315"
                x="494"
                y="40"
                width="55"
                height="22"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.960784 0 0 0 0.5 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter4_dd_1194_11315"
                x="527"
                y="45"
                width="14"
                height="15"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter5_d_1194_11315"
                x="420"
                y="40"
                width="75"
                height="22"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.960784 0 0 0 0.5 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter6_dd_1194_11315"
                x="62"
                y="97"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter7_dd_1194_11315"
                x="140"
                y="111"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter8_dd_1194_11315"
                x="62"
                y="145"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter9_dd_1194_11315"
                x="140"
                y="159"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter10_dd_1194_11315"
                x="62"
                y="193"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter11_dd_1194_11315"
                x="132"
                y="207"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter12_dd_1194_11315"
                x="62"
                y="241"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter13_dd_1194_11315"
                x="138"
                y="255"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter14_dd_1194_11315"
                x="62"
                y="289"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter15_dd_1194_11315"
                x="132"
                y="303"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter16_dd_1194_11315"
                x="62"
                y="337"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter17_dd_1194_11315"
                x="140"
                y="351"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter18_dd_1194_11315"
                x="62"
                y="385"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <filter
                id="filter19_dd_1194_11315"
                x="136"
                y="399"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11315"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11315"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11315"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11315"
                    result="effect2_dropShadow_1194_11315"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11315"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_1194_11315">
                <rect width="680" height="408" fill="white" />
            </clipPath>
            <clipPath id="clip1_1194_11315">
                <rect
                    width="5.48547"
                    height="5.48547"
                    fill="white"
                    transform="translate(81.3301 54)"
                />
            </clipPath>
            <clipPath id="clip2_1194_11315">
                <rect width="12" height="12" fill="white" transform="translate(524 112)" />
            </clipPath>
            <clipPath id="clip3_1194_11315">
                <rect width="12" height="12" fill="white" transform="translate(524 160)" />
            </clipPath>
            <clipPath id="clip4_1194_11315">
                <rect width="12" height="12" fill="white" transform="translate(524 208)" />
            </clipPath>
            <clipPath id="clip5_1194_11315">
                <rect width="12" height="12" fill="white" transform="translate(524 256)" />
            </clipPath>
            <clipPath id="clip6_1194_11315">
                <rect width="12" height="12" fill="white" transform="translate(524 304)" />
            </clipPath>
            <clipPath id="clip7_1194_11315">
                <rect width="12" height="12" fill="white" transform="translate(524 352)" />
            </clipPath>
            <clipPath id="clip8_1194_11315">
                <rect width="12" height="12" fill="white" transform="translate(524 400)" />
            </clipPath>
        </defs>
    </svg>
</template>
