<template>
    <svg
        width="680"
        height="408"
        viewBox="0 0 680 408"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1156_10828)">
            <rect width="680" height="408" fill="white" />
            <g filter="url(#filter0_dd_1156_10828)">
                <rect x="64" y="389" width="552" height="242" rx="12" fill="white" />
            </g>
            <rect x="64" y="364" width="552" height="1" fill="#F5F5F5" />
            <g filter="url(#filter1_dd_1156_10828)">
                <rect x="306" y="358" width="68" height="13" rx="6.5" fill="white" />
            </g>
            <rect x="314" y="363" width="52" height="3" rx="1.5" fill="#ECECEF" />
            <g filter="url(#filter2_dd_1156_10828)">
                <rect x="64" y="98" width="552" height="242" rx="16" fill="white" />
            </g>
            <mask
                id="mask0_1156_10828"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="64"
                y="98"
                width="552"
                height="242"
            >
                <rect x="64" y="98" width="552" height="242" rx="16" fill="white" />
            </mask>
            <g mask="url(#mask0_1156_10828)">
                <g filter="url(#filter3_dd_1156_10828)">
                    <rect x="84" y="118" width="220" height="202" rx="14" fill="white" />
                </g>
                <g filter="url(#filter4_dd_1156_10828)">
                    <rect x="100" y="212" width="188" height="58" rx="8" fill="white" />
                </g>
                <rect x="112" y="224" width="168" height="4" rx="2" fill="#ECECEF" />
                <rect x="112" y="234" width="166" height="4" rx="2" fill="#ECECEF" />
                <rect x="112" y="244" width="156" height="4" rx="2" fill="#ECECEF" />
                <rect x="112" y="254" width="138" height="4" rx="2" fill="#ECECEF" />
                <g filter="url(#filter5_dd_1156_10828)">
                    <rect x="100" y="158" width="188" height="42" rx="8" fill="white" />
                </g>
                <mask
                    id="mask1_1156_10828"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="100"
                    y="158"
                    width="188"
                    height="42"
                >
                    <rect x="100" y="158" width="188" height="42" rx="8" fill="white" />
                </mask>
                <g mask="url(#mask1_1156_10828)">
                    <rect
                        x="110"
                        y="168"
                        width="114"
                        height="22"
                        rx="11"
                        fill="#0035EC"
                        fill-opacity="0.06"
                    />
                    <path
                        d="M124 184C126.761 184 129 181.761 129 179C129 176.239 126.761 174 124 174C121.239 174 119 176.239 119 179C119 181.761 121.239 184 124 184ZM124 185C120.686 185 118 182.314 118 179C118 175.686 120.686 173 124 173C127.314 173 130 175.686 130 179C130 182.314 127.314 185 124 185Z"
                        fill="#0035EC"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M124.064 181.605C125.457 181.605 126.171 180.681 126.36 179.918L125.457 179.645C125.324 180.107 124.911 180.66 124.064 180.66C123.266 180.66 122.524 180.079 122.524 179.022C122.524 177.895 123.308 177.363 124.05 177.363C124.911 177.363 125.296 177.888 125.415 178.364L126.325 178.077C126.129 177.272 125.422 176.432 124.05 176.432C122.72 176.432 121.523 177.44 121.523 179.022C121.523 180.604 122.678 181.605 124.064 181.605Z"
                        fill="#0035EC"
                    />
                    <rect
                        x="136"
                        y="176"
                        width="80"
                        height="6"
                        rx="3"
                        fill="#0035EC"
                        fill-opacity="0.16"
                    />
                    <rect
                        x="230"
                        y="168"
                        width="114"
                        height="22"
                        rx="11"
                        fill="#0035EC"
                        fill-opacity="0.06"
                    />
                    <path
                        d="M244 184C246.761 184 249 181.761 249 179C249 176.239 246.761 174 244 174C241.239 174 239 176.239 239 179C239 181.761 241.239 184 244 184ZM244 185C240.686 185 238 182.314 238 179C238 175.686 240.686 173 244 173C247.314 173 250 175.686 250 179C250 182.314 247.314 185 244 185Z"
                        fill="#0035EC"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M244.064 181.605C245.457 181.605 246.171 180.681 246.36 179.918L245.457 179.645C245.324 180.107 244.911 180.66 244.064 180.66C243.266 180.66 242.524 180.079 242.524 179.022C242.524 177.895 243.308 177.363 244.05 177.363C244.911 177.363 245.296 177.888 245.415 178.364L246.325 178.077C246.129 177.272 245.422 176.432 244.05 176.432C242.72 176.432 241.523 177.44 241.523 179.022C241.523 180.604 242.678 181.605 244.064 181.605Z"
                        fill="#0035EC"
                    />
                    <rect
                        x="256"
                        y="176"
                        width="80"
                        height="6"
                        rx="3"
                        fill="#0035EC"
                        fill-opacity="0.16"
                    />
                </g>
                <g filter="url(#filter6_d_1156_10828)">
                    <rect x="129" y="282" width="130" height="22" rx="11" fill="#006DFF" />
                </g>
                <rect x="100" y="134" width="64" height="8" rx="4" fill="#ECECEF" />
                <g filter="url(#filter7_dd_1156_10828)">
                    <circle cx="176" cy="138" r="6" fill="white" />
                </g>
                <rect x="188" y="134" width="80" height="8" rx="4" fill="#ECECEF" />
                <g filter="url(#filter8_dd_1156_10828)">
                    <rect x="320" y="118" width="220" height="202" rx="14" fill="white" />
                </g>
                <g filter="url(#filter9_dd_1156_10828)">
                    <rect x="336" y="212" width="188" height="58" rx="8" fill="white" />
                </g>
                <rect x="348" y="224" width="168" height="4" rx="2" fill="#ECECEF" />
                <rect x="348" y="234" width="166" height="4" rx="2" fill="#ECECEF" />
                <rect x="348" y="244" width="156" height="4" rx="2" fill="#ECECEF" />
                <rect x="348" y="254" width="138" height="4" rx="2" fill="#ECECEF" />
                <g filter="url(#filter10_dd_1156_10828)">
                    <rect x="336" y="158" width="188" height="42" rx="8" fill="white" />
                </g>
                <mask
                    id="mask2_1156_10828"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="336"
                    y="158"
                    width="188"
                    height="42"
                >
                    <rect x="336" y="158" width="188" height="42" rx="8" fill="white" />
                </mask>
                <g mask="url(#mask2_1156_10828)">
                    <rect
                        x="346"
                        y="168"
                        width="114"
                        height="22"
                        rx="11"
                        fill="#0035EC"
                        fill-opacity="0.06"
                    />
                    <path
                        d="M360 184C362.761 184 365 181.761 365 179C365 176.239 362.761 174 360 174C357.239 174 355 176.239 355 179C355 181.761 357.239 184 360 184ZM360 185C356.686 185 354 182.314 354 179C354 175.686 356.686 173 360 173C363.314 173 366 175.686 366 179C366 182.314 363.314 185 360 185Z"
                        fill="#0035EC"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M360.064 181.605C361.457 181.605 362.171 180.681 362.36 179.918L361.457 179.645C361.324 180.107 360.911 180.66 360.064 180.66C359.266 180.66 358.524 180.079 358.524 179.022C358.524 177.895 359.308 177.363 360.05 177.363C360.911 177.363 361.296 177.888 361.415 178.364L362.325 178.077C362.129 177.272 361.422 176.432 360.05 176.432C358.72 176.432 357.523 177.44 357.523 179.022C357.523 180.604 358.678 181.605 360.064 181.605Z"
                        fill="#0035EC"
                    />
                    <rect
                        x="372"
                        y="176"
                        width="80"
                        height="6"
                        rx="3"
                        fill="#0035EC"
                        fill-opacity="0.16"
                    />
                    <rect
                        x="466"
                        y="168"
                        width="114"
                        height="22"
                        rx="11"
                        fill="#0035EC"
                        fill-opacity="0.06"
                    />
                    <path
                        d="M480 184C482.761 184 485 181.761 485 179C485 176.239 482.761 174 480 174C477.239 174 475 176.239 475 179C475 181.761 477.239 184 480 184ZM480 185C476.686 185 474 182.314 474 179C474 175.686 476.686 173 480 173C483.314 173 486 175.686 486 179C486 182.314 483.314 185 480 185Z"
                        fill="#0035EC"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M480.064 181.605C481.457 181.605 482.171 180.681 482.36 179.918L481.457 179.645C481.324 180.107 480.911 180.66 480.064 180.66C479.266 180.66 478.524 180.079 478.524 179.022C478.524 177.895 479.308 177.363 480.05 177.363C480.911 177.363 481.296 177.888 481.415 178.364L482.325 178.077C482.129 177.272 481.422 176.432 480.05 176.432C478.72 176.432 477.523 177.44 477.523 179.022C477.523 180.604 478.678 181.605 480.064 181.605Z"
                        fill="#0035EC"
                    />
                    <rect
                        x="492"
                        y="176"
                        width="80"
                        height="6"
                        rx="3"
                        fill="#0035EC"
                        fill-opacity="0.16"
                    />
                </g>
                <g filter="url(#filter11_d_1156_10828)">
                    <rect x="365" y="282" width="130" height="22" rx="11" fill="#006DFF" />
                </g>
                <rect x="336" y="134" width="64" height="8" rx="4" fill="#ECECEF" />
                <g filter="url(#filter12_dd_1156_10828)">
                    <circle cx="412" cy="138" r="6" fill="white" />
                </g>
                <rect x="424" y="134" width="80" height="8" rx="4" fill="#ECECEF" />
                <g filter="url(#filter13_dd_1156_10828)">
                    <rect x="556" y="118" width="220" height="202" rx="14" fill="white" />
                </g>
                <g filter="url(#filter14_dd_1156_10828)">
                    <rect x="572" y="212" width="188" height="58" rx="8" fill="white" />
                </g>
                <rect x="584" y="224" width="168" height="4" rx="2" fill="#ECECEF" />
                <rect x="584" y="234" width="166" height="4" rx="2" fill="#ECECEF" />
                <rect x="584" y="244" width="156" height="4" rx="2" fill="#ECECEF" />
                <rect x="584" y="254" width="138" height="4" rx="2" fill="#ECECEF" />
                <g filter="url(#filter15_dd_1156_10828)">
                    <rect x="572" y="158" width="188" height="42" rx="8" fill="white" />
                </g>
                <mask
                    id="mask3_1156_10828"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="572"
                    y="158"
                    width="188"
                    height="42"
                >
                    <rect x="572" y="158" width="188" height="42" rx="8" fill="white" />
                </mask>
                <g mask="url(#mask3_1156_10828)">
                    <rect
                        x="582"
                        y="168"
                        width="114"
                        height="22"
                        rx="11"
                        fill="#0035EC"
                        fill-opacity="0.06"
                    />
                    <path
                        d="M596 184C598.761 184 601 181.761 601 179C601 176.239 598.761 174 596 174C593.239 174 591 176.239 591 179C591 181.761 593.239 184 596 184ZM596 185C592.686 185 590 182.314 590 179C590 175.686 592.686 173 596 173C599.314 173 602 175.686 602 179C602 182.314 599.314 185 596 185Z"
                        fill="#0035EC"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M596.064 181.605C597.457 181.605 598.171 180.681 598.36 179.918L597.457 179.645C597.324 180.107 596.911 180.66 596.064 180.66C595.266 180.66 594.524 180.079 594.524 179.022C594.524 177.895 595.308 177.363 596.05 177.363C596.911 177.363 597.296 177.888 597.415 178.364L598.325 178.077C598.129 177.272 597.422 176.432 596.05 176.432C594.72 176.432 593.523 177.44 593.523 179.022C593.523 180.604 594.678 181.605 596.064 181.605Z"
                        fill="#0035EC"
                    />
                    <rect
                        x="608"
                        y="176"
                        width="80"
                        height="6"
                        rx="3"
                        fill="#0035EC"
                        fill-opacity="0.16"
                    />
                </g>
                <g filter="url(#filter16_d_1156_10828)">
                    <rect x="601" y="282" width="130" height="22" rx="11" fill="#006DFF" />
                </g>
                <rect x="572" y="134" width="64" height="8" rx="4" fill="#ECECEF" />
            </g>
            <circle cx="74" cy="51" r="10" fill="#6E3AFF" />
            <g filter="url(#filter17_dd_1156_10828)">
                <circle cx="84" cy="57" r="6" fill="white" />
            </g>
            <g clip-path="url(#clip1_1156_10828)">
                <path
                    d="M83.185 54.8842C83.2377 54.7459 83.3101 54.6186 83.4177 54.5154C83.8479 54.0961 84.5592 54.2015 84.8512 54.7283C85.0707 55.1279 85.3034 55.5186 85.5295 55.9138C85.9071 56.5702 86.2891 57.2266 86.6623 57.8852C86.9762 58.4362 86.6359 59.1321 86.0125 59.2265C85.6305 59.2835 85.2727 59.1079 85.0751 58.7655C84.7436 58.1881 84.4099 57.6107 84.0784 57.0356C84.0719 57.0224 84.0631 57.0114 84.0543 57.0005C84.0192 56.9719 84.0038 56.9302 83.9819 56.8929C83.8348 56.6338 83.6833 56.377 83.5362 56.1201C83.4418 55.9533 83.343 55.7887 83.2486 55.6218C83.163 55.4725 83.1235 55.3101 83.1279 55.1389C83.1345 55.051 83.1455 54.9632 83.185 54.8842Z"
                    fill="#3C8BD9"
                />
                <path
                    d="M83.185 54.8843C83.1652 54.9633 83.1477 55.0423 83.1433 55.1258C83.1367 55.3102 83.1828 55.4814 83.275 55.6416C83.5165 56.0566 83.7579 56.4737 83.9972 56.8908C84.0192 56.9281 84.0367 56.9654 84.0587 57.0005C83.927 57.2288 83.7953 57.4549 83.6614 57.6833C83.477 58.0016 83.2925 58.3221 83.106 58.6404C83.0972 58.6404 83.095 58.636 83.0928 58.6294C83.0906 58.6119 83.0972 58.5965 83.1016 58.5789C83.1916 58.2496 83.1169 57.9577 82.8908 57.7074C82.7525 57.5559 82.5769 57.4703 82.3749 57.4418C82.1115 57.4045 81.8788 57.4725 81.6702 57.6372C81.6329 57.6657 81.6088 57.7074 81.5649 57.7294C81.5561 57.7294 81.5517 57.725 81.5495 57.7184C81.6549 57.5362 81.7581 57.354 81.8634 57.1718C82.2981 56.4166 82.7328 55.6614 83.1696 54.9084C83.174 54.8996 83.1806 54.8931 83.185 54.8843Z"
                    fill="#FABC04"
                />
                <path
                    d="M81.5583 57.7249C81.6 57.6876 81.6395 57.6481 81.6834 57.613C82.2169 57.1915 83.0181 57.4966 83.1345 58.164C83.163 58.3242 83.1477 58.4779 83.0994 58.6316C83.0972 58.6447 83.095 58.6557 83.0906 58.6689C83.0708 58.704 83.0533 58.7413 83.0313 58.7765C82.8359 59.0992 82.5483 59.2594 82.1708 59.2353C81.7383 59.2045 81.398 58.8796 81.3387 58.4494C81.3102 58.2408 81.3519 58.0454 81.4595 57.8654C81.4814 57.8259 81.5078 57.7908 81.5319 57.7513C81.5429 57.7425 81.5385 57.7249 81.5583 57.7249Z"
                    fill="#34A852"
                />
                <path
                    d="M81.5583 57.725C81.5495 57.7337 81.5495 57.7491 81.5342 57.7513C81.532 57.7359 81.5407 57.7272 81.5495 57.7162L81.5583 57.725Z"
                    fill="#FABC04"
                />
                <path
                    d="M83.0906 58.6689C83.0818 58.6535 83.0906 58.6426 83.0994 58.6316C83.1016 58.6338 83.106 58.6382 83.1082 58.6404L83.0906 58.6689Z"
                    fill="#E1C025"
                />
            </g>
            <path
                d="M97.66 43.82V58H103.14C105.8 58 107.52 56.34 107.52 54.04C107.52 52.28 106.4 51.02 105.02 50.68C106.2 50.28 107.08 49.1 107.08 47.64C107.08 45.44 105.52 43.82 102.76 43.82H97.66ZM100.38 49.68V46.14H102.32C103.66 46.14 104.36 46.82 104.36 47.9C104.36 48.98 103.6 49.68 102.34 49.68H100.38ZM100.38 55.7V51.96H102.66C104.04 51.96 104.76 52.74 104.76 53.86C104.76 55 103.96 55.7 102.62 55.7H100.38Z"
                fill="black"
            />
            <path
                d="M111.822 58V48.16H109.162V58H111.822ZM108.842 44.94C108.842 45.82 109.582 46.56 110.482 46.56C111.402 46.56 112.122 45.82 112.122 44.94C112.122 44.02 111.402 43.28 110.482 43.28C109.582 43.28 108.842 44.02 108.842 44.94Z"
                fill="black"
            />
            <path
                d="M123.453 43.52H120.833V49.14C120.553 48.62 119.753 47.92 118.053 47.92C115.273 47.92 113.333 50.18 113.333 53.06C113.333 56.04 115.333 58.24 118.133 58.24C119.453 58.24 120.453 57.64 120.893 56.86C120.893 57.32 120.953 57.8 120.993 58H123.533C123.493 57.6 123.453 56.88 123.453 56.22V43.52ZM116.013 53.06C116.013 51.3 117.093 50.3 118.453 50.3C119.813 50.3 120.873 51.28 120.873 53.04C120.873 54.82 119.813 55.86 118.453 55.86C117.053 55.86 116.013 54.82 116.013 53.06Z"
                fill="black"
            />
            <path
                d="M135.1 43.52H132.48V49.14C132.2 48.62 131.4 47.92 129.7 47.92C126.92 47.92 124.98 50.18 124.98 53.06C124.98 56.04 126.98 58.24 129.78 58.24C131.1 58.24 132.1 57.64 132.54 56.86C132.54 57.32 132.6 57.8 132.64 58H135.18C135.14 57.6 135.1 56.88 135.1 56.22V43.52ZM127.66 53.06C127.66 51.3 128.74 50.3 130.1 50.3C131.46 50.3 132.52 51.28 132.52 53.04C132.52 54.82 131.46 55.86 130.1 55.86C128.7 55.86 127.66 54.82 127.66 53.06Z"
                fill="black"
            />
            <path
                d="M139.886 58V48.16H137.226V58H139.886ZM136.906 44.94C136.906 45.82 137.646 46.56 138.546 46.56C139.466 46.56 140.186 45.82 140.186 44.94C140.186 44.02 139.466 43.28 138.546 43.28C137.646 43.28 136.906 44.02 136.906 44.94Z"
                fill="black"
            />
            <path
                d="M144.657 52.34C144.657 51.2 145.337 50.3 146.497 50.3C147.777 50.3 148.317 51.16 148.317 52.26V58H150.977V51.8C150.977 49.64 149.857 47.9 147.417 47.9C146.357 47.9 145.177 48.36 144.577 49.38V48.16H141.997V58H144.657V52.34Z"
                fill="black"
            />
            <path
                d="M152.418 58.54C152.698 60.42 154.498 62.1 157.198 62.1C160.898 62.1 162.358 59.64 162.358 56.94V48.16H159.798V49.32C159.458 48.66 158.558 47.98 156.998 47.98C154.298 47.98 152.418 50.18 152.418 52.78C152.418 55.54 154.378 57.56 156.998 57.56C158.418 57.56 159.338 56.94 159.738 56.3V57.02C159.738 58.92 158.798 59.76 157.118 59.76C155.858 59.76 154.998 58.98 154.818 57.9L152.418 58.54ZM157.458 55.28C156.078 55.28 155.098 54.32 155.098 52.78C155.098 51.26 156.138 50.3 157.458 50.3C158.738 50.3 159.778 51.26 159.778 52.78C159.778 54.3 158.818 55.28 157.458 55.28Z"
                fill="black"
            />
            <path
                d="M177.795 58V55.4H171.675V52.12H177.215V49.66H171.675V46.42H177.795V43.82H168.915V58H177.795Z"
                fill="black"
            />
            <path
                d="M178.517 58H181.517C181.857 57.46 183.177 55.52 183.537 54.98L185.597 58H188.717L185.157 52.98L188.597 48.16H185.577L183.637 51.08C183.297 50.54 182.017 48.7 181.677 48.16H178.517L182.017 53.06L178.517 58Z"
                fill="black"
            />
            <path
                d="M192.36 61.8V57.04C192.84 57.7 193.84 58.24 195.2 58.24C197.98 58.24 199.84 56.04 199.84 53.06C199.84 50.14 198.18 47.94 195.3 47.94C193.82 47.94 192.72 48.6 192.28 49.36V48.16H189.7V61.8H192.36ZM197.22 53.08C197.22 54.84 196.14 55.86 194.78 55.86C193.42 55.86 192.32 54.82 192.32 53.08C192.32 51.34 193.42 50.32 194.78 50.32C196.14 50.32 197.22 51.34 197.22 53.08Z"
                fill="black"
            />
            <path
                d="M203.485 51.96C203.545 51.06 204.305 50.02 205.685 50.02C207.205 50.02 207.845 50.98 207.885 51.96H203.485ZM208.145 54.52C207.825 55.4 207.145 56.02 205.905 56.02C204.585 56.02 203.485 55.08 203.425 53.78H210.465C210.465 53.74 210.505 53.34 210.505 52.96C210.505 49.8 208.685 47.86 205.645 47.86C203.125 47.86 200.805 49.9 200.805 53.04C200.805 56.36 203.185 58.3 205.885 58.3C208.305 58.3 209.865 56.88 210.365 55.18L208.145 54.52Z"
                fill="black"
            />
            <path
                d="M218.055 48.12C217.855 48.1 217.655 48.08 217.435 48.08C216.595 48.08 215.235 48.32 214.635 49.62V48.16H212.055V58H214.715V53.5C214.715 51.38 215.895 50.72 217.255 50.72C217.494 50.72 217.755 50.74 218.055 50.8V48.12Z"
                fill="black"
            />
            <path
                d="M221.868 58V48.16H219.208V58H221.868ZM218.888 44.94C218.888 45.82 219.628 46.56 220.528 46.56C221.448 46.56 222.168 45.82 222.168 44.94C222.168 44.02 221.448 43.28 220.528 43.28C219.628 43.28 218.888 44.02 218.888 44.94Z"
                fill="black"
            />
            <path
                d="M226.639 58V52.24C226.639 51.16 227.319 50.3 228.479 50.3C229.679 50.3 230.219 51.1 230.219 52.16V58H232.859V52.24C232.859 51.18 233.54 50.3 234.68 50.3C235.9 50.3 236.419 51.1 236.419 52.16V58H238.999V51.62C238.999 48.98 237.26 47.88 235.439 47.88C234.139 47.88 233.1 48.32 232.32 49.52C231.82 48.46 230.74 47.88 229.4 47.88C228.32 47.88 227.059 48.4 226.52 49.36V48.16H223.979V58H226.639Z"
                fill="black"
            />
            <path
                d="M243.116 51.96C243.176 51.06 243.936 50.02 245.316 50.02C246.836 50.02 247.476 50.98 247.516 51.96H243.116ZM247.776 54.52C247.456 55.4 246.776 56.02 245.536 56.02C244.216 56.02 243.116 55.08 243.056 53.78H250.096C250.096 53.74 250.136 53.34 250.136 52.96C250.136 49.8 248.316 47.86 245.276 47.86C242.756 47.86 240.436 49.9 240.436 53.04C240.436 56.36 242.816 58.3 245.516 58.3C247.936 58.3 249.496 56.88 249.996 55.18L247.776 54.52Z"
                fill="black"
            />
            <path
                d="M254.346 52.34C254.346 51.2 255.026 50.3 256.186 50.3C257.466 50.3 258.006 51.16 258.006 52.26V58H260.666V51.8C260.666 49.64 259.546 47.9 257.106 47.9C256.046 47.9 254.866 48.36 254.266 49.38V48.16H251.686V58H254.346V52.34Z"
                fill="black"
            />
            <path
                d="M266.147 45.22H263.747V46.6C263.747 47.48 263.267 48.16 262.227 48.16H261.727V50.52H263.507V55.1C263.507 57 264.707 58.14 266.627 58.14C267.407 58.14 267.887 58 268.127 57.9V55.7C267.987 55.74 267.627 55.78 267.307 55.78C266.547 55.78 266.147 55.5 266.147 54.64V50.52H268.127V48.16H266.147V45.22Z"
                fill="black"
            />
            <path
                d="M269.005 55.26C269.125 56.38 270.145 58.3 273.105 58.3C275.685 58.3 276.925 56.66 276.925 55.06C276.925 53.62 275.945 52.44 274.005 52.04L272.605 51.74C272.065 51.64 271.705 51.34 271.705 50.86C271.705 50.3 272.265 49.88 272.965 49.88C274.085 49.88 274.505 50.62 274.585 51.2L276.805 50.7C276.685 49.64 275.745 47.86 272.945 47.86C270.825 47.86 269.265 49.32 269.265 51.08C269.265 52.46 270.125 53.6 272.025 54.02L273.325 54.32C274.085 54.48 274.385 54.84 274.385 55.28C274.385 55.8 273.965 56.26 273.085 56.26C271.925 56.26 271.345 55.54 271.285 54.76L269.005 55.26Z"
                fill="black"
            />
            <g filter="url(#filter18_dd_1156_10828)">
                <rect x="420" y="40" width="196" height="22" rx="11" fill="white" />
            </g>
            <rect x="557" y="48" width="32" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter19_dd_1156_10828)">
                <rect x="595" y="46" width="10" height="10" rx="5" fill="white" />
            </g>
            <g filter="url(#filter20_d_1156_10828)">
                <path d="M494 40H548V62H494V40Z" fill="white" />
            </g>
            <rect x="503" y="48" width="20" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter21_dd_1156_10828)">
                <rect x="529" y="46" width="10" height="10" rx="5" fill="white" />
            </g>
            <g filter="url(#filter22_d_1156_10828)">
                <path
                    d="M420 51C420 44.9249 424.925 40 431 40H494V62H431C424.925 62 420 57.0751 420 51Z"
                    fill="white"
                />
            </g>
            <rect opacity="0.1" x="430" y="48" width="56" height="6" rx="3" fill="#006DFF" />
        </g>
        <defs>
            <filter
                id="filter0_dd_1156_10828"
                x="63"
                y="388"
                width="554"
                height="245"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_dd_1156_10828"
                x="304"
                y="357"
                width="72"
                height="18"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_dd_1156_10828"
                x="63"
                y="97"
                width="554"
                height="245"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_dd_1156_10828"
                x="82"
                y="117"
                width="224"
                height="207"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter4_dd_1156_10828"
                x="98"
                y="211"
                width="192"
                height="63"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter5_dd_1156_10828"
                x="98"
                y="157"
                width="192"
                height="47"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter6_d_1156_10828"
                x="128"
                y="281.5"
                width="132"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter7_dd_1156_10828"
                x="168"
                y="131"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter8_dd_1156_10828"
                x="318"
                y="117"
                width="224"
                height="207"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter9_dd_1156_10828"
                x="334"
                y="211"
                width="192"
                height="63"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter10_dd_1156_10828"
                x="334"
                y="157"
                width="192"
                height="47"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter11_d_1156_10828"
                x="364"
                y="281.5"
                width="132"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter12_dd_1156_10828"
                x="404"
                y="131"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter13_dd_1156_10828"
                x="554"
                y="117"
                width="224"
                height="207"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter14_dd_1156_10828"
                x="570"
                y="211"
                width="192"
                height="63"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter15_dd_1156_10828"
                x="570"
                y="157"
                width="192"
                height="47"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter16_d_1156_10828"
                x="600"
                y="281.5"
                width="132"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter17_dd_1156_10828"
                x="76"
                y="50"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter18_dd_1156_10828"
                x="418"
                y="39"
                width="200"
                height="27"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter19_dd_1156_10828"
                x="593"
                y="45"
                width="14"
                height="15"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter20_d_1156_10828"
                x="494"
                y="40"
                width="55"
                height="22"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.960784 0 0 0 0.5 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter21_dd_1156_10828"
                x="527"
                y="45"
                width="14"
                height="15"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_10828"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_10828"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_10828"
                    result="effect2_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <filter
                id="filter22_d_1156_10828"
                x="420"
                y="40"
                width="75"
                height="22"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.960784 0 0 0 0.5 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_10828"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1156_10828"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_1156_10828">
                <rect width="680" height="408" fill="white" />
            </clipPath>
            <clipPath id="clip1_1156_10828">
                <rect
                    width="5.48547"
                    height="5.48547"
                    fill="white"
                    transform="translate(81.33 54)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
