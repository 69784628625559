<template>
    <section class="chat-response-wrapper">
        <div class="chat-response">
            <div class="icon">
                <ChatIcon />
            </div>
            <div class="response">
                <div class="dot-flashing one" />
                <div class="dot-flashing two" />
                <div class="dot-flashing three" />
            </div>
        </div>
    </section>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue'

// Local components
import ChatIcon from '@/components/chat/ChatIcon.vue'

export default defineComponent({
    name: 'ChatResponseLoading',
    components: {
        // Local components
        ChatIcon,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Component
.chat-response {
    @include flex;
    @include items-start;
    color: $opteo-dark-blue;
    background-color: rgba(0, 53, 236, 0.06);
    @include br-24;
    @include relative;
    gap: 1.625rem;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
    width: 100%;
    min-height: 4.5rem;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem 1.875rem;
}
.icon {
    flex-shrink: 0;
    top: 1.0625rem;
    position: absolute;
    left: calc(-2.5rem - 1.5rem);
}
.response {
    letter-spacing: -0.08px;
    height: 1.5rem;
    width: 100%;
    @include flex;
    @include items-center;
}

.dot-flashing {
    position: relative;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 999rem;
    background-color: $opteo-dark-blue;
    color: $opteo-dark-blue;
    animation: dot-flashing 0.75s infinite ease-in-out alternate;
}
.dot-flashing.one {
    animation-delay: 0s;
}
.dot-flashing.two {
    animation-delay: 0.375s;
    margin: 0 0.3125rem;
}
.dot-flashing.three {
    animation-delay: 0.75s;
}

@keyframes dot-flashing {
    0% {
        background-color: $opteo-dark-blue;
    }
    100% {
        background-color: rgba(0, 53, 236, 0.2);
    }
}
</style>
