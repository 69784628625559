<template>
    <svg
        width="680"
        height="408"
        viewBox="0 0 680 408"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1156_13156)">
            <rect width="680" height="408" fill="white" />
            <circle cx="90" cy="51" r="10" fill="#6E3AFF" />
            <g filter="url(#filter0_dd_1156_13156)">
                <circle cx="100" cy="57" r="6" fill="white" />
            </g>
            <g clip-path="url(#clip1_1156_13156)">
                <path
                    d="M99.185 54.8842C99.2376 54.7459 99.3101 54.6186 99.4177 54.5154C99.8479 54.0961 100.559 54.2015 100.851 54.7283C101.071 55.1279 101.303 55.5186 101.53 55.9138C101.907 56.5702 102.289 57.2266 102.662 57.8852C102.976 58.4362 102.636 59.1321 102.012 59.2265C101.631 59.2835 101.273 59.1079 101.075 58.7655C100.744 58.1881 100.41 57.6107 100.078 57.0356C100.072 57.0224 100.063 57.0114 100.054 57.0005C100.019 56.9719 100.004 56.9302 99.9818 56.8929C99.8348 56.6338 99.6833 56.377 99.5362 56.1201C99.4418 55.9533 99.343 55.7887 99.2486 55.6218C99.163 55.4725 99.1235 55.3101 99.1279 55.1389C99.1345 55.051 99.1454 54.9632 99.185 54.8842Z"
                    fill="#3C8BD9"
                />
                <path
                    d="M99.185 54.8843C99.1652 54.9633 99.1476 55.0423 99.1433 55.1258C99.1367 55.3102 99.1828 55.4814 99.275 55.6416C99.5165 56.0566 99.7579 56.4737 99.9972 56.8908C100.019 56.9281 100.037 56.9654 100.059 57.0005C99.927 57.2288 99.7953 57.4549 99.6613 57.6833C99.4769 58.0016 99.2925 58.3221 99.1059 58.6404C99.0972 58.6404 99.095 58.636 99.0928 58.6294C99.0906 58.6119 99.0972 58.5965 99.1015 58.5789C99.1916 58.2496 99.1169 57.9577 98.8908 57.7074C98.7525 57.5559 98.5769 57.4703 98.3749 57.4418C98.1115 57.4045 97.8788 57.4725 97.6702 57.6372C97.6329 57.6657 97.6088 57.7074 97.5649 57.7294C97.5561 57.7294 97.5517 57.725 97.5495 57.7184C97.6549 57.5362 97.758 57.354 97.8634 57.1718C98.2981 56.4166 98.7327 55.6614 99.1696 54.9084C99.174 54.8996 99.1806 54.8931 99.185 54.8843Z"
                    fill="#FABC04"
                />
                <path
                    d="M97.5583 57.7249C97.6 57.6876 97.6395 57.6481 97.6834 57.613C98.2168 57.1915 99.0181 57.4966 99.1345 58.164C99.163 58.3242 99.1476 58.4779 99.0993 58.6316C99.0972 58.6447 99.095 58.6557 99.0906 58.6689C99.0708 58.704 99.0532 58.7413 99.0313 58.7765C98.8359 59.0992 98.5483 59.2594 98.1707 59.2353C97.7383 59.2045 97.398 58.8796 97.3387 58.4494C97.3102 58.2408 97.3519 58.0454 97.4595 57.8654C97.4814 57.8259 97.5078 57.7908 97.5319 57.7513C97.5429 57.7425 97.5385 57.7249 97.5583 57.7249Z"
                    fill="#34A852"
                />
                <path
                    d="M97.5583 57.725C97.5495 57.7337 97.5495 57.7491 97.5341 57.7513C97.5319 57.7359 97.5407 57.7272 97.5495 57.7162L97.5583 57.725Z"
                    fill="#FABC04"
                />
                <path
                    d="M99.0906 58.6689C99.0818 58.6535 99.0906 58.6426 99.0993 58.6316C99.1015 58.6338 99.1059 58.6382 99.1081 58.6404L99.0906 58.6689Z"
                    fill="#E1C025"
                />
            </g>
            <path
                d="M120.08 58.3C124.06 58.3 126.1 55.66 126.64 53.48L124.06 52.7C123.68 54.02 122.5 55.6 120.08 55.6C117.8 55.6 115.68 53.94 115.68 50.92C115.68 47.7 117.92 46.18 120.04 46.18C122.5 46.18 123.6 47.68 123.94 49.04L126.54 48.22C125.98 45.92 123.96 43.52 120.04 43.52C116.24 43.52 112.82 46.4 112.82 50.92C112.82 55.44 116.12 58.3 120.08 58.3Z"
                fill="black"
            />
            <path
                d="M133.877 48.12C133.677 48.1 133.477 48.08 133.257 48.08C132.417 48.08 131.057 48.32 130.457 49.62V48.16H127.877V58H130.537V53.5C130.537 51.38 131.717 50.72 133.077 50.72C133.317 50.72 133.577 50.74 133.877 50.8V48.12Z"
                fill="black"
            />
            <path
                d="M137.014 51.96C137.074 51.06 137.834 50.02 139.214 50.02C140.734 50.02 141.374 50.98 141.414 51.96H137.014ZM141.674 54.52C141.354 55.4 140.674 56.02 139.434 56.02C138.114 56.02 137.014 55.08 136.954 53.78H143.994C143.994 53.74 144.034 53.34 144.034 52.96C144.034 49.8 142.214 47.86 139.174 47.86C136.654 47.86 134.334 49.9 134.334 53.04C134.334 56.36 136.714 58.3 139.414 58.3C141.834 58.3 143.394 56.88 143.894 55.18L141.674 54.52Z"
                fill="black"
            />
            <path
                d="M147.683 53.04C147.683 51.24 148.723 50.18 150.143 50.18C151.543 50.18 152.563 51.24 152.563 53.04C152.563 54.84 151.523 55.98 150.143 55.98C148.703 55.98 147.683 54.82 147.683 53.04ZM152.583 56.78C152.583 57.26 152.643 57.78 152.683 58H155.223C155.203 57.78 155.143 57.1 155.143 56.22V48.16H152.563V49.38C152.323 48.82 151.503 47.88 149.743 47.88C146.883 47.88 145.003 50.16 145.003 53.04C145.003 56.02 146.923 58.28 149.823 58.28C151.143 58.28 152.163 57.6 152.583 56.66V56.78Z"
                fill="black"
            />
            <path
                d="M160.729 45.22H158.329V46.6C158.329 47.48 157.849 48.16 156.809 48.16H156.309V50.52H158.089V55.1C158.089 57 159.289 58.14 161.209 58.14C161.989 58.14 162.469 58 162.709 57.9V55.7C162.569 55.74 162.209 55.78 161.889 55.78C161.129 55.78 160.729 55.5 160.729 54.64V50.52H162.709V48.16H160.729V45.22Z"
                fill="black"
            />
            <path
                d="M166.249 51.96C166.309 51.06 167.069 50.02 168.449 50.02C169.969 50.02 170.609 50.98 170.649 51.96H166.249ZM170.909 54.52C170.589 55.4 169.909 56.02 168.669 56.02C167.349 56.02 166.249 55.08 166.189 53.78H173.229C173.229 53.74 173.269 53.34 173.269 52.96C173.269 49.8 171.449 47.86 168.409 47.86C165.889 47.86 163.569 49.9 163.569 53.04C163.569 56.36 165.949 58.3 168.649 58.3C171.069 58.3 172.629 56.88 173.129 55.18L170.909 54.52Z"
                fill="black"
            />
            <path
                d="M188.145 58V55.4H182.025V52.12H187.565V49.66H182.025V46.42H188.145V43.82H179.265V58H188.145Z"
                fill="black"
            />
            <path
                d="M188.867 58H191.867C192.207 57.46 193.527 55.52 193.887 54.98L195.947 58H199.067L195.507 52.98L198.947 48.16H195.927L193.987 51.08C193.647 50.54 192.367 48.7 192.027 48.16H188.867L192.367 53.06L188.867 58Z"
                fill="black"
            />
            <path
                d="M202.71 61.8V57.04C203.19 57.7 204.19 58.24 205.55 58.24C208.33 58.24 210.19 56.04 210.19 53.06C210.19 50.14 208.53 47.94 205.65 47.94C204.17 47.94 203.07 48.6 202.63 49.36V48.16H200.05V61.8H202.71ZM207.57 53.08C207.57 54.84 206.49 55.86 205.13 55.86C203.77 55.86 202.67 54.82 202.67 53.08C202.67 51.34 203.77 50.32 205.13 50.32C206.49 50.32 207.57 51.34 207.57 53.08Z"
                fill="black"
            />
            <path
                d="M213.835 51.96C213.895 51.06 214.655 50.02 216.035 50.02C217.555 50.02 218.195 50.98 218.235 51.96H213.835ZM218.495 54.52C218.175 55.4 217.495 56.02 216.255 56.02C214.935 56.02 213.835 55.08 213.775 53.78H220.815C220.815 53.74 220.855 53.34 220.855 52.96C220.855 49.8 219.035 47.86 215.995 47.86C213.475 47.86 211.155 49.9 211.155 53.04C211.155 56.36 213.535 58.3 216.235 58.3C218.655 58.3 220.215 56.88 220.715 55.18L218.495 54.52Z"
                fill="black"
            />
            <path
                d="M228.405 48.12C228.205 48.1 228.005 48.08 227.785 48.08C226.945 48.08 225.585 48.32 224.985 49.62V48.16H222.405V58H225.065V53.5C225.065 51.38 226.245 50.72 227.605 50.72C227.845 50.72 228.105 50.74 228.405 50.8V48.12Z"
                fill="black"
            />
            <path
                d="M232.218 58V48.16H229.558V58H232.218ZM229.238 44.94C229.238 45.82 229.978 46.56 230.878 46.56C231.798 46.56 232.518 45.82 232.518 44.94C232.518 44.02 231.798 43.28 230.878 43.28C229.978 43.28 229.238 44.02 229.238 44.94Z"
                fill="black"
            />
            <path
                d="M236.99 58V52.24C236.99 51.16 237.67 50.3 238.83 50.3C240.03 50.3 240.57 51.1 240.57 52.16V58H243.21V52.24C243.21 51.18 243.89 50.3 245.03 50.3C246.25 50.3 246.77 51.1 246.77 52.16V58H249.35V51.62C249.35 48.98 247.61 47.88 245.79 47.88C244.49 47.88 243.45 48.32 242.67 49.52C242.17 48.46 241.09 47.88 239.75 47.88C238.67 47.88 237.41 48.4 236.87 49.36V48.16H234.33V58H236.99Z"
                fill="black"
            />
            <path
                d="M253.466 51.96C253.526 51.06 254.286 50.02 255.666 50.02C257.186 50.02 257.826 50.98 257.866 51.96H253.466ZM258.126 54.52C257.806 55.4 257.126 56.02 255.886 56.02C254.566 56.02 253.466 55.08 253.406 53.78H260.446C260.446 53.74 260.486 53.34 260.486 52.96C260.486 49.8 258.666 47.86 255.626 47.86C253.106 47.86 250.786 49.9 250.786 53.04C250.786 56.36 253.166 58.3 255.866 58.3C258.286 58.3 259.846 56.88 260.346 55.18L258.126 54.52Z"
                fill="black"
            />
            <path
                d="M264.696 52.34C264.696 51.2 265.376 50.3 266.536 50.3C267.816 50.3 268.356 51.16 268.356 52.26V58H271.016V51.8C271.016 49.64 269.896 47.9 267.456 47.9C266.396 47.9 265.216 48.36 264.616 49.38V48.16H262.036V58H264.696V52.34Z"
                fill="black"
            />
            <path
                d="M276.497 45.22H274.097V46.6C274.097 47.48 273.617 48.16 272.577 48.16H272.077V50.52H273.857V55.1C273.857 57 275.057 58.14 276.977 58.14C277.757 58.14 278.237 58 278.477 57.9V55.7C278.337 55.74 277.977 55.78 277.657 55.78C276.897 55.78 276.497 55.5 276.497 54.64V50.52H278.477V48.16H276.497V45.22Z"
                fill="black"
            />
            <g filter="url(#filter1_dd_1156_13156)">
                <rect x="80" y="98" width="520" height="398" rx="12" fill="white" />
            </g>
            <rect x="80" y="322" width="520" height="1" fill="#F5F5F5" />
            <g filter="url(#filter2_dd_1156_13156)">
                <rect x="246" y="146" width="188" height="156" rx="10" fill="white" />
            </g>
            <g filter="url(#filter3_dd_1156_13156)">
                <rect x="262" y="172" width="156" height="22" rx="5" fill="white" />
            </g>
            <rect x="262" y="162" width="26" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter4_dd_1156_13156)">
                <rect x="262" y="216" width="156" height="70" rx="5" fill="white" />
            </g>
            <rect x="262" y="206" width="26" height="4" rx="2" fill="#ECECEF" />
            <rect x="100" y="118" width="80" height="10" rx="5" fill="#ECECEF" />
            <g filter="url(#filter5_dd_1156_13156)">
                <rect x="246" y="371" width="188" height="152" rx="10" fill="white" />
            </g>
            <g filter="url(#filter6_dd_1156_13156)">
                <rect x="262" y="397" width="156" height="22" rx="5" fill="white" />
            </g>
            <rect x="262" y="387" width="26" height="4" rx="2" fill="#ECECEF" />
            <rect x="100" y="343" width="80" height="10" rx="5" fill="#ECECEF" />
            <g filter="url(#filter7_dd_1156_13156)">
                <rect y="342" width="680" height="66" fill="white" />
            </g>
            <g filter="url(#filter8_d_1156_13156)">
                <rect x="276" y="360" width="128" height="30" rx="15" fill="#006DFF" />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_dd_1156_13156"
                x="92"
                y="50"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_13156"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_13156"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_13156"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_13156"
                    result="effect2_dropShadow_1156_13156"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_13156"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_dd_1156_13156"
                x="79"
                y="97"
                width="522"
                height="401"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_13156"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_13156"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_13156"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_13156"
                    result="effect2_dropShadow_1156_13156"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_13156"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_dd_1156_13156"
                x="245"
                y="145"
                width="190"
                height="159"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_13156"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_13156"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_13156"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_13156"
                    result="effect2_dropShadow_1156_13156"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_13156"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_dd_1156_13156"
                x="261"
                y="171"
                width="158"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_13156"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_13156"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_13156"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_13156"
                    result="effect2_dropShadow_1156_13156"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_13156"
                    result="shape"
                />
            </filter>
            <filter
                id="filter4_dd_1156_13156"
                x="261"
                y="215"
                width="158"
                height="73"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_13156"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_13156"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_13156"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_13156"
                    result="effect2_dropShadow_1156_13156"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_13156"
                    result="shape"
                />
            </filter>
            <filter
                id="filter5_dd_1156_13156"
                x="245"
                y="370"
                width="190"
                height="155"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_13156"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_13156"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_13156"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_13156"
                    result="effect2_dropShadow_1156_13156"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_13156"
                    result="shape"
                />
            </filter>
            <filter
                id="filter6_dd_1156_13156"
                x="261"
                y="396"
                width="158"
                height="25"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_13156"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_13156"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_13156"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_13156"
                    result="effect2_dropShadow_1156_13156"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_13156"
                    result="shape"
                />
            </filter>
            <filter
                id="filter7_dd_1156_13156"
                x="-1"
                y="341"
                width="682"
                height="69"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1156_13156"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_13156"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1156_13156"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1156_13156"
                    result="effect2_dropShadow_1156_13156"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1156_13156"
                    result="shape"
                />
            </filter>
            <filter
                id="filter8_d_1156_13156"
                x="275"
                y="359.5"
                width="130"
                height="32"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="0.5" />
                <feGaussianBlur stdDeviation="0.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1156_13156"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1156_13156"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_1156_13156">
                <rect width="680" height="408" fill="white" />
            </clipPath>
            <clipPath id="clip1_1156_13156">
                <rect
                    width="5.48547"
                    height="5.48547"
                    fill="white"
                    transform="translate(97.33 54)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
