<template>
    <div class="sender-info-layout-container" :class="{ 'is-pdf': isPdf }">
        <Skeleton v-if="isLoading" :width="48" :height="48" class="sender-profile-image" />
        <div v-else-if="isAutoGenerated && !profileImageUrl">
            <OpteoLogo :width="48" :height="48" />
        </div>
        <ProfileImage
            v-else
            :image-url="profileImageUrl ?? undefined"
            :width="48"
            :user-id="userId"
            class="sender-profile-image"
        />
        <Spacer v-if="userFullName || userEmail" width="1rem" />
        <div>
            <Skeleton
                v-if="isLoading"
                :width="138"
                :height="13"
                class="sender-user-name-skeleton"
                style="margin: 0.125rem 0"
            />
            <Text v-else as="h6" size="f-7" weight="400" class="sender-user-name">
                {{ userFullName }}
            </Text>
            <Spacer height="0.25rem" />
            <Skeleton
                v-if="isLoading"
                :width="130"
                :height="10"
                class="sender-email-skeleton"
                style="margin: 0.1875rem 0 0.125rem 0"
            />
            <Text v-else as="span" size="f-8" color="gray" class="sender-email">
                {{ userEmail }}
            </Text>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue'

import { Spacer, Text, ProfileImage, OpteoLogo } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'

type Props = {
    userId: number | undefined
    userFullName: string | undefined
    userEmail: string | undefined
    profileImageUrl?: string | null
    isPdf?: boolean
    isAutoGenerated: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isPdf: false,
})

const isLoading = ref(true)
watchEffect(() => {
    if (isLoading.value) {
        const isAnyPropertySet =
            props.isAutoGenerated || !!props.userId || !!props.userFullName || !!props.userEmail

        isLoading.value = !isAnyPropertySet
    }
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.sender-info-layout-container {
    @include flex;
    @include items-center;
}

// Responsive.
// PDF renders should never be responsive. For arcane reasons sometimes they trigger media queries despite being plenty wide, so we prevent that here with :not().
.cover-outer-container:not(.is-pdf) {
    @media (max-width: $mq-480-max) {
        .sender-user-name {
            @include f-8;
        }
        .sender-email {
            @include f-9;
        }
        .sender-user-name-skeleton {
            height: 12px !important;
        }
        .sender-email-skeleton {
            height: 9px !important;
        }
    }

    @media screen and (min-width: $mq-481-min) and (max-width: $mq-767-max) {
        .sender-user-name {
            @include f-8;
        }
        .sender-email {
            @include f-9;
        }
        .sender-profile-image {
            width: 40px !important;
            height: 40px !important;
        }
        .sender-profile-image :deep(.profile-image-wrapper) {
            width: 40px !important;
            height: 40px !important;
        }
        .sender-profile-image :deep(.profile-image-wrapper img) {
            width: 42px !important;
            height: 42px !important;
        }
        .sender-user-name-skeleton {
            height: 12px !important;
        }
        .sender-email-skeleton {
            height: 9px !important;
        }
    }
}
</style>
