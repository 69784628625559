<template>
    <div class="improvement-created-date">
        <CalendarIcon class="calendar-icon" />
        <span v-if="totalDays">
            This task was last updated <span class="fw6">{{ lastUpdatedValue }}</span>
            {{ lastUpdatedUnit }}, using data from the last
            <span class="fw6">{{ totalDays }}</span> days.
        </span>
        <span v-else>
            This task was last updated <span class="fw6">{{ lastUpdatedValue }}</span>
            {{ lastUpdatedUnit }}.
        </span>
    </div>
</template>

<script lang="ts">
import { CalendarIcon } from '@opteo/components-next'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'

export default {
    components: { CalendarIcon },
    props: {
        lastUpdated: {
            type: Date,
            required: true,
        },
        totalDays: {
            type: Number,
            required: false,
        },
    },
    setup(props) {
        const distanceToNow = formatDistanceToNowStrict(props.lastUpdated, { addSuffix: true })
        const [lastUpdatedValue, ...lastUpdatedUnit] = distanceToNow.split(' ')
        return { lastUpdatedValue, lastUpdatedUnit: lastUpdatedUnit.join(' ') }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.improvement-created-date {
    @include container;
    @include br-20;
    width: 100%;
    @include pa-24;
    @include flex;
    @include items-center;
    @include f-8;
    @include lh-150;
}

.calendar-icon {
    @include mr-16;
}

@media screen and (min-width: $mq-0-min) and (max-width: $mq-767-max) {
    .improvement-created-date {
        flex-direction: column;
        align-items: flex-start;
    }

    .calendar-icon {
        @include mb-16;
        margin-right: unset;
    }
}
</style>
