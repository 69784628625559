<template>
    <div class="subscription-grid">
        <!-- Right Column -->
        <div class="billing-side">
            <!-- Status -->
            <SubscriptionStatus v-if="userType" />
        </div>
        <!-- Left Column -->
        <div class="billing-main">
            <!-- Update Credit Card -->
            <SubscriptionUpdateCC v-if="userType === 'update_cc'" />

            <!-- Skeleton -->
            <SettingsSectionSkeleton v-if="loading" />
            <!-- Subscription Overview -->
            <SubscriptionOverview v-else-if="customerIsActive" />
            <!-- Renew Subscription -->
            <SubscriptionRenew v-else />

            <!-- Discount Code -->
            <SubscriptionCoupon />

            <!-- Cancel Subscription -->
            <SubscriptionCancel v-if="customerIsActive"></SubscriptionCancel>
        </div>
    </div>
</template>
<script lang="ts">
import { computed } from 'vue'
import { useSubscription } from '@/composition/billing/useSubscription'
import { useUser } from '@/composition/user/useUser'

import SubscriptionOverview from '@/components/billing/SubscriptionOverview.vue'
import SubscriptionStatus from '@/components/billing/SubscriptionStatus.vue'
import SubscriptionRenew from '@/components/billing/SubscriptionRenew.vue'
import SubscriptionCoupon from '@/components/billing/SubscriptionCoupon.vue'
import SubscriptionCancel from '@/components/billing/SubscriptionCancel.vue'
import SubscriptionUpdateCC from '@/components/billing/SubscriptionUpdateCC.vue'
import SettingsSectionSkeleton from '@/components/billing/SettingsSectionSkeleton.vue'

export default {
    name: 'Subscription',
    components: {
        SubscriptionOverview,
        SubscriptionStatus,
        SubscriptionRenew,
        SubscriptionCoupon,
        SubscriptionCancel,
        SubscriptionUpdateCC,
        SettingsSectionSkeleton,
    },
    setup() {
        const { latestSubscription, userType, loading, couponLoading } = useSubscription()
        const { currentPlan } = useUser()

        const customerIsActive = computed<boolean>(() => {
            if (latestSubscription.value) {
                return (
                    !latestSubscription.value?.cancel_at_period_end &&
                    userType.value !== 'cancelled' &&
                    userType.value !== 'incomplete'
                )
            }
            return false
        })

        return {
            customerIsActive,
            currentPlan,
            userType,
            latestSubscription,
            loading,
            couponLoading,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

@media screen and (min-width: 1279px) {
    .subscription-grid {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 372px;
        grid-gap: 2rem;
    }
    .billing-main {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .billing-side {
        grid-column: 2 / 3;
    }
}
</style>
