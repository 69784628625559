<template>
    <svg
        width="680"
        height="408"
        viewBox="0 0 680 408"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1194_11519)">
            <rect width="680" height="408" fill="white" />
            <g filter="url(#filter0_dd_1194_11519)">
                <rect x="64" y="98" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="114" width="56" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter1_dd_1194_11519)">
                <circle cx="148" cy="118" r="6" fill="white" />
            </g>
            <rect x="160" y="114" width="60" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="232"
                y="107"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M246 123C248.761 123 251 120.761 251 118C251 115.239 248.761 113 246 113C243.239 113 241 115.239 241 118C241 120.761 243.239 123 246 123ZM246 124C242.686 124 240 121.314 240 118C240 114.686 242.686 112 246 112C249.314 112 252 114.686 252 118C252 121.314 249.314 124 246 124Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M246.064 120.605C247.457 120.605 248.171 119.681 248.36 118.918L247.457 118.645C247.324 119.107 246.911 119.66 246.064 119.66C245.266 119.66 244.524 119.079 244.524 118.022C244.524 116.895 245.308 116.363 246.05 116.363C246.911 116.363 247.296 116.888 247.415 117.364L248.325 117.077C248.129 116.272 247.422 115.432 246.05 115.432C244.72 115.432 243.523 116.44 243.523 118.022C243.523 119.604 244.678 120.605 246.064 120.605Z"
                fill="#0035EC"
            />
            <rect x="258" y="115" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="114" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="115" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="114" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="115" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip1_1194_11519)">
                <path
                    opacity="0.1"
                    d="M535.248 118C535.248 118.689 535.112 119.372 534.848 120.008C534.584 120.645 534.197 121.224 533.709 121.711C533.222 122.199 532.643 122.586 532.006 122.849C531.369 123.113 530.686 123.249 529.997 123.249C529.308 123.249 528.625 123.113 527.988 122.849C527.351 122.586 526.773 122.199 526.285 121.711C525.798 121.224 525.412 120.645 525.148 120.008C524.884 119.372 524.749 118.689 524.749 118C524.749 117.31 524.885 116.628 525.149 115.991C525.413 115.354 525.799 114.775 526.287 114.288C526.775 113.8 527.353 113.413 527.99 113.15C528.627 112.886 529.31 112.75 529.999 112.75C530.689 112.75 531.371 112.886 532.008 113.15C532.645 113.413 533.224 113.8 533.711 114.288C534.198 114.775 534.585 115.354 534.849 115.991C535.112 116.628 535.248 117.31 535.248 118L535.248 118Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 112.75C530.689 112.75 531.371 112.886 532.008 113.15C532.645 113.413 533.223 113.8 533.711 114.287C534.198 114.775 534.585 115.354 534.848 115.99C535.112 116.627 535.248 117.31 535.247 117.999C535.247 118.689 535.111 119.371 534.847 120.008C534.583 120.645 534.197 121.224 533.709 121.711C533.222 122.199 532.643 122.585 532.006 122.849C531.369 123.113 530.686 123.249 529.997 123.249C529.308 123.249 528.625 123.113 527.988 122.849C527.351 122.585 526.773 122.199 526.285 121.711C525.798 121.224 525.412 120.645 525.148 120.008C524.884 119.371 524.749 118.689 524.749 117.999C524.749 117.31 524.885 116.627 525.149 115.99C525.413 115.354 525.799 114.775 526.287 114.287C526.775 113.8 527.353 113.413 527.99 113.15C528.627 112.886 529.31 112.75 529.999 112.75L529.999 112.75Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="111" width="56" height="6" rx="3" fill="#00A861" />
            <rect x="544" y="121" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter2_dd_1194_11519)">
                <rect x="64" y="146" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="162" width="59" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter3_dd_1194_11519)">
                <circle cx="151" cy="166" r="6" fill="white" />
            </g>
            <rect x="163" y="162" width="61" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="236"
                y="155"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M250 171C252.761 171 255 168.761 255 166C255 163.239 252.761 161 250 161C247.239 161 245 163.239 245 166C245 168.761 247.239 171 250 171ZM250 172C246.686 172 244 169.314 244 166C244 162.686 246.686 160 250 160C253.314 160 256 162.686 256 166C256 169.314 253.314 172 250 172Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M250.064 168.605C251.457 168.605 252.171 167.681 252.36 166.918L251.457 166.645C251.324 167.107 250.911 167.66 250.064 167.66C249.266 167.66 248.524 167.079 248.524 166.022C248.524 164.895 249.308 164.363 250.05 164.363C250.911 164.363 251.296 164.888 251.415 165.364L252.325 165.077C252.129 164.272 251.422 163.432 250.05 163.432C248.72 163.432 247.523 164.44 247.523 166.022C247.523 167.604 248.678 168.605 250.064 168.605Z"
                fill="#0035EC"
            />
            <rect x="262" y="163" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="162" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="163" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="162" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="163" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip2_1194_11519)">
                <path
                    opacity="0.1"
                    d="M535.248 166C535.248 166.689 535.112 167.372 534.848 168.008C534.584 168.645 534.197 169.224 533.709 169.711C533.222 170.199 532.643 170.586 532.006 170.849C531.369 171.113 530.686 171.249 529.997 171.249C529.308 171.249 528.625 171.113 527.988 170.849C527.351 170.586 526.773 170.199 526.285 169.711C525.798 169.224 525.412 168.645 525.148 168.008C524.884 167.372 524.749 166.689 524.749 166C524.749 165.31 524.885 164.628 525.149 163.991C525.413 163.354 525.799 162.775 526.287 162.288C526.775 161.8 527.353 161.413 527.99 161.15C528.627 160.886 529.31 160.75 529.999 160.75C530.689 160.75 531.371 160.886 532.008 161.15C532.645 161.413 533.224 161.8 533.711 162.288C534.198 162.775 534.585 163.354 534.849 163.991C535.112 164.628 535.248 165.31 535.248 166L535.248 166Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 160.75C530.689 160.75 531.371 160.886 532.008 161.15C532.645 161.413 533.223 161.8 533.711 162.287C534.198 162.775 534.585 163.354 534.848 163.99C535.112 164.627 535.248 165.31 535.247 165.999C535.247 166.689 535.111 167.371 534.847 168.008C534.583 168.645 534.197 169.224 533.709 169.711C533.222 170.199 532.643 170.585 532.006 170.849C531.369 171.113 530.686 171.249 529.997 171.249C529.308 171.249 528.625 171.113 527.988 170.849C527.351 170.585 526.773 170.199 526.285 169.711C525.798 169.224 525.412 168.645 525.148 168.008C524.884 167.371 524.749 166.689 524.749 165.999C524.749 165.31 524.885 164.627 525.149 163.99C525.413 163.354 525.799 162.775 526.287 162.287C526.775 161.8 527.353 161.413 527.99 161.15C528.627 160.886 529.31 160.75 529.999 160.75L529.999 160.75Z"
                    stroke="#00A861"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="159" width="56" height="6" rx="3" fill="#00A861" />
            <rect x="544" y="169" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter4_dd_1194_11519)">
                <rect x="64" y="194" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="210" width="56" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter5_dd_1194_11519)">
                <circle cx="148" cy="214" r="6" fill="white" />
            </g>
            <rect x="160" y="210" width="70" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="242"
                y="203"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M256 219C258.761 219 261 216.761 261 214C261 211.239 258.761 209 256 209C253.239 209 251 211.239 251 214C251 216.761 253.239 219 256 219ZM256 220C252.686 220 250 217.314 250 214C250 210.686 252.686 208 256 208C259.314 208 262 210.686 262 214C262 217.314 259.314 220 256 220Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M256.064 216.605C257.457 216.605 258.171 215.681 258.36 214.918L257.457 214.645C257.324 215.107 256.911 215.66 256.064 215.66C255.266 215.66 254.524 215.079 254.524 214.022C254.524 212.895 255.308 212.363 256.05 212.363C256.911 212.363 257.296 212.888 257.415 213.364L258.325 213.077C258.129 212.272 257.422 211.432 256.05 211.432C254.72 211.432 253.523 212.44 253.523 214.022C253.523 215.604 254.678 216.605 256.064 216.605Z"
                fill="#0035EC"
            />
            <rect x="268" y="211" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="210" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="211" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="210" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="211" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip3_1194_11519)">
                <path
                    opacity="0.1"
                    d="M535.248 214C535.248 214.689 535.112 215.372 534.848 216.008C534.584 216.645 534.197 217.224 533.709 217.711C533.222 218.199 532.643 218.586 532.006 218.849C531.369 219.113 530.686 219.249 529.997 219.249C529.308 219.249 528.625 219.113 527.988 218.849C527.351 218.586 526.773 218.199 526.285 217.711C525.798 217.224 525.412 216.645 525.148 216.008C524.884 215.372 524.749 214.689 524.749 214C524.749 213.31 524.885 212.628 525.149 211.991C525.413 211.354 525.799 210.775 526.287 210.288C526.775 209.8 527.353 209.413 527.99 209.15C528.627 208.886 529.31 208.75 529.999 208.75C530.689 208.75 531.371 208.886 532.008 209.15C532.645 209.413 533.224 209.8 533.711 210.288C534.198 210.775 534.585 211.354 534.849 211.991C535.112 212.628 535.248 213.31 535.248 214L535.248 214Z"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 208.75C531.108 208.75 532.188 209.101 533.084 209.753C533.981 210.404 534.648 211.323 534.991 212.377C535.333 213.431 535.333 214.567 534.99 215.621C534.647 216.676 533.98 217.595 533.083 218.246C532.186 218.898 531.105 219.249 529.997 219.249C528.888 219.249 527.808 218.898 526.912 218.246C526.015 217.595 525.348 216.676 525.005 215.621C524.663 214.567 524.663 213.431 525.006 212.377"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="207" width="56" height="6" rx="3" fill="#006DFF" />
            <rect x="544" y="217" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter6_dd_1194_11519)">
                <rect x="64" y="242" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="258" width="54" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter7_dd_1194_11519)">
                <circle cx="146" cy="262" r="6" fill="white" />
            </g>
            <rect x="158" y="258" width="70" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="240"
                y="251"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M254 267C256.761 267 259 264.761 259 262C259 259.239 256.761 257 254 257C251.239 257 249 259.239 249 262C249 264.761 251.239 267 254 267ZM254 268C250.686 268 248 265.314 248 262C248 258.686 250.686 256 254 256C257.314 256 260 258.686 260 262C260 265.314 257.314 268 254 268Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M254.064 264.605C255.457 264.605 256.171 263.681 256.36 262.918L255.457 262.645C255.324 263.107 254.911 263.66 254.064 263.66C253.266 263.66 252.524 263.079 252.524 262.022C252.524 260.895 253.308 260.363 254.05 260.363C254.911 260.363 255.296 260.888 255.415 261.364L256.325 261.077C256.129 260.272 255.422 259.432 254.05 259.432C252.72 259.432 251.523 260.44 251.523 262.022C251.523 263.604 252.678 264.605 254.064 264.605Z"
                fill="#0035EC"
            />
            <rect x="266" y="259" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="258" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="259" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="258" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="259" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip4_1194_11519)">
                <path
                    opacity="0.1"
                    d="M535.248 262C535.248 262.689 535.112 263.372 534.848 264.008C534.584 264.645 534.197 265.224 533.709 265.711C533.222 266.199 532.643 266.586 532.006 266.849C531.369 267.113 530.686 267.249 529.997 267.249C529.308 267.249 528.625 267.113 527.988 266.849C527.351 266.586 526.773 266.199 526.285 265.711C525.798 265.224 525.412 264.645 525.148 264.008C524.884 263.372 524.749 262.689 524.749 262C524.749 261.31 524.885 260.628 525.149 259.991C525.413 259.354 525.799 258.775 526.287 258.288C526.775 257.8 527.353 257.413 527.99 257.15C528.627 256.886 529.31 256.75 529.999 256.75C530.689 256.75 531.371 256.886 532.008 257.15C532.645 257.413 533.224 257.8 533.711 258.288C534.198 258.775 534.585 259.354 534.849 259.991C535.112 260.628 535.248 261.31 535.248 262L535.248 262Z"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 256.75C530.995 256.75 531.971 257.033 532.812 257.567C533.653 258.101 534.324 258.863 534.748 259.764C535.172 260.666 535.331 261.669 535.206 262.657C535.081 263.646 534.677 264.578 534.042 265.345C533.407 266.113 532.567 266.684 531.619 266.992C530.672 267.3 529.656 267.331 528.691 267.084C527.727 266.836 526.852 266.319 526.171 265.593C525.489 264.867 525.028 263.961 524.841 262.983"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="255" width="56" height="6" rx="3" fill="#006DFF" />
            <rect x="544" y="265" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter8_dd_1194_11519)">
                <rect x="64" y="290" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="306" width="48" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter9_dd_1194_11519)">
                <circle cx="140" cy="310" r="6" fill="white" />
            </g>
            <rect x="152" y="306" width="66" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="230"
                y="299"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M244 315C246.761 315 249 312.761 249 310C249 307.239 246.761 305 244 305C241.239 305 239 307.239 239 310C239 312.761 241.239 315 244 315ZM244 316C240.686 316 238 313.314 238 310C238 306.686 240.686 304 244 304C247.314 304 250 306.686 250 310C250 313.314 247.314 316 244 316Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M244.064 312.605C245.457 312.605 246.171 311.681 246.36 310.918L245.457 310.645C245.324 311.107 244.911 311.66 244.064 311.66C243.266 311.66 242.524 311.079 242.524 310.022C242.524 308.895 243.308 308.363 244.05 308.363C244.911 308.363 245.296 308.888 245.415 309.364L246.325 309.077C246.129 308.272 245.422 307.432 244.05 307.432C242.72 307.432 241.523 308.44 241.523 310.022C241.523 311.604 242.678 312.605 244.064 312.605Z"
                fill="#0035EC"
            />
            <rect x="256" y="307" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="306" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="307" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="306" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="307" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip5_1194_11519)">
                <path
                    opacity="0.1"
                    d="M535.248 310C535.248 310.689 535.112 311.372 534.848 312.008C534.584 312.645 534.197 313.224 533.709 313.711C533.222 314.199 532.643 314.586 532.006 314.849C531.369 315.113 530.686 315.249 529.997 315.249C529.308 315.249 528.625 315.113 527.988 314.849C527.351 314.586 526.773 314.199 526.285 313.711C525.798 313.224 525.412 312.645 525.148 312.008C524.884 311.372 524.749 310.689 524.749 310C524.749 309.31 524.885 308.628 525.149 307.991C525.413 307.354 525.799 306.775 526.287 306.288C526.775 305.8 527.353 305.413 527.99 305.15C528.627 304.886 529.31 304.75 529.999 304.75C530.689 304.75 531.371 304.886 532.008 305.15C532.645 305.413 533.224 305.8 533.711 306.288C534.198 306.775 534.585 307.354 534.849 307.991C535.112 308.628 535.248 309.31 535.248 310L535.248 310Z"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 304.75C530.911 304.75 531.808 304.988 532.6 305.44C533.392 305.892 534.053 306.542 534.517 307.327C534.981 308.112 535.233 309.005 535.247 309.917C535.261 310.829 535.037 311.729 534.598 312.528C534.158 313.328 533.518 313.999 532.74 314.475C531.962 314.952 531.073 315.217 530.162 315.246C529.25 315.275 528.347 315.065 527.541 314.639C526.735 314.212 526.054 313.582 525.565 312.812"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="303" width="56" height="6" rx="3" fill="#006DFF" />
            <rect x="544" y="313" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter10_dd_1194_11519)">
                <rect x="64" y="338" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="354" width="48" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter11_dd_1194_11519)">
                <circle cx="140" cy="358" r="6" fill="white" />
            </g>
            <rect x="152" y="354" width="60" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="224"
                y="347"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M238 363C240.761 363 243 360.761 243 358C243 355.239 240.761 353 238 353C235.239 353 233 355.239 233 358C233 360.761 235.239 363 238 363ZM238 364C234.686 364 232 361.314 232 358C232 354.686 234.686 352 238 352C241.314 352 244 354.686 244 358C244 361.314 241.314 364 238 364Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M238.064 360.605C239.457 360.605 240.171 359.681 240.36 358.918L239.457 358.645C239.324 359.107 238.911 359.66 238.064 359.66C237.266 359.66 236.524 359.079 236.524 358.022C236.524 356.895 237.308 356.363 238.05 356.363C238.911 356.363 239.296 356.888 239.415 357.364L240.325 357.077C240.129 356.272 239.422 355.432 238.05 355.432C236.72 355.432 235.523 356.44 235.523 358.022C235.523 359.604 236.678 360.605 238.064 360.605Z"
                fill="#0035EC"
            />
            <rect x="250" y="355" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="354" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="355" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="354" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="355" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip6_1194_11519)">
                <path
                    opacity="0.1"
                    d="M535.248 358C535.248 358.689 535.112 359.372 534.848 360.008C534.584 360.645 534.197 361.224 533.709 361.711C533.222 362.199 532.643 362.586 532.006 362.849C531.369 363.113 530.686 363.249 529.997 363.249C529.308 363.249 528.625 363.113 527.988 362.849C527.351 362.586 526.773 362.199 526.285 361.711C525.798 361.224 525.412 360.645 525.148 360.008C524.884 359.372 524.749 358.689 524.749 358C524.749 357.31 524.885 356.628 525.149 355.991C525.413 355.354 525.799 354.775 526.287 354.288C526.775 353.8 527.353 353.413 527.99 353.15C528.627 352.886 529.31 352.75 529.999 352.75C530.689 352.75 531.371 352.886 532.008 353.15C532.645 353.413 533.224 353.8 533.711 354.288C534.198 354.775 534.585 355.354 534.849 355.991C535.112 356.628 535.248 357.31 535.248 358L535.248 358Z"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 352.75C530.772 352.75 531.535 352.921 532.234 353.25C532.933 353.579 533.551 354.058 534.043 354.653C534.536 355.249 534.891 355.945 535.083 356.694C535.275 357.442 535.299 358.224 535.154 358.983C535.009 359.742 534.698 360.46 534.244 361.085C533.79 361.71 533.203 362.227 532.526 362.599C531.849 362.972 531.098 363.19 530.327 363.238C529.555 363.287 528.783 363.164 528.065 362.88"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="351" width="56" height="6" rx="3" fill="#006DFF" />
            <rect x="544" y="361" width="48" height="4" rx="2" fill="#ECECEF" />
            <g filter="url(#filter12_dd_1194_11519)">
                <rect x="64" y="386" width="552" height="40" rx="10" fill="white" />
            </g>
            <rect x="80" y="402" width="56" height="8" rx="4" fill="#ECECEF" />
            <g filter="url(#filter13_dd_1194_11519)">
                <circle cx="148" cy="406" r="6" fill="white" />
            </g>
            <rect x="160" y="402" width="56" height="8" rx="4" fill="#ECECEF" />
            <rect
                x="228"
                y="395"
                width="90"
                height="22"
                rx="11"
                fill="#0035EC"
                fill-opacity="0.06"
            />
            <path
                d="M242 411C244.761 411 247 408.761 247 406C247 403.239 244.761 401 242 401C239.239 401 237 403.239 237 406C237 408.761 239.239 411 242 411ZM242 412C238.686 412 236 409.314 236 406C236 402.686 238.686 400 242 400C245.314 400 248 402.686 248 406C248 409.314 245.314 412 242 412Z"
                fill="#0035EC"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M242.064 408.605C243.457 408.605 244.171 407.681 244.36 406.918L243.457 406.645C243.324 407.107 242.911 407.66 242.064 407.66C241.266 407.66 240.524 407.079 240.524 406.022C240.524 404.895 241.308 404.363 242.05 404.363C242.911 404.363 243.296 404.888 243.415 405.364L244.325 405.077C244.129 404.272 243.422 403.432 242.05 403.432C240.72 403.432 239.523 404.44 239.523 406.022C239.523 407.604 240.678 408.605 242.064 408.605Z"
                fill="#0035EC"
            />
            <rect x="254" y="403" width="56" height="6" rx="3" fill="#0035EC" fill-opacity="0.16" />
            <rect x="382" y="402" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="419" y="403" width="20" height="6" rx="3" fill="#00A861" />
            <rect x="451" y="402" width="33" height="8" rx="4" fill="#ECECEF" />
            <rect opacity="0.1" x="488" y="403" width="20" height="6" rx="3" fill="#FF2828" />
            <g clip-path="url(#clip7_1194_11519)">
                <path
                    opacity="0.1"
                    d="M535.248 406C535.248 406.689 535.112 407.372 534.848 408.008C534.584 408.645 534.197 409.224 533.709 409.711C533.222 410.199 532.643 410.586 532.006 410.849C531.369 411.113 530.686 411.249 529.997 411.249C529.308 411.249 528.625 411.113 527.988 410.849C527.351 410.586 526.773 410.199 526.285 409.711C525.798 409.224 525.412 408.645 525.148 408.008C524.884 407.372 524.749 406.689 524.749 406C524.749 405.31 524.885 404.628 525.149 403.991C525.413 403.354 525.799 402.775 526.287 402.288C526.775 401.8 527.353 401.413 527.99 401.15C528.627 400.886 529.31 400.75 529.999 400.75C530.689 400.75 531.371 400.886 532.008 401.15C532.645 401.413 533.224 401.8 533.711 402.288C534.198 402.775 534.585 403.354 534.849 403.991C535.112 404.628 535.248 405.31 535.248 406L535.248 406Z"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M529.999 400.75C531.334 400.75 532.619 401.259 533.592 402.173C534.565 403.087 535.154 404.337 535.237 405.67C535.321 407.002 534.893 408.317 534.042 409.345C533.191 410.374 531.979 411.04 530.655 411.207"
                    stroke="#006DFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
            </g>
            <rect opacity="0.16" x="544" y="399" width="56" height="6" rx="3" fill="#006DFF" />
            <circle cx="74" cy="51" r="10" fill="#6E3AFF" />
            <g filter="url(#filter14_dd_1194_11519)">
                <circle cx="84" cy="57" r="6" fill="white" />
            </g>
            <g clip-path="url(#clip8_1194_11519)">
                <path
                    d="M83.185 54.8842C83.2377 54.7459 83.3101 54.6186 83.4177 54.5154C83.8479 54.0961 84.5592 54.2015 84.8512 54.7283C85.0707 55.1279 85.3034 55.5186 85.5295 55.9138C85.9071 56.5702 86.2891 57.2266 86.6623 57.8852C86.9762 58.4362 86.6359 59.1321 86.0125 59.2265C85.6305 59.2835 85.2727 59.1079 85.0751 58.7655C84.7436 58.1881 84.4099 57.6107 84.0784 57.0356C84.0719 57.0224 84.0631 57.0114 84.0543 57.0005C84.0192 56.9719 84.0038 56.9302 83.9819 56.8929C83.8348 56.6338 83.6833 56.377 83.5362 56.1201C83.4418 55.9533 83.343 55.7887 83.2486 55.6218C83.163 55.4725 83.1235 55.3101 83.1279 55.1389C83.1345 55.051 83.1455 54.9632 83.185 54.8842Z"
                    fill="#3C8BD9"
                />
                <path
                    d="M83.185 54.8843C83.1652 54.9633 83.1477 55.0423 83.1433 55.1258C83.1367 55.3102 83.1828 55.4814 83.275 55.6416C83.5165 56.0566 83.7579 56.4737 83.9972 56.8908C84.0192 56.9281 84.0367 56.9654 84.0587 57.0005C83.927 57.2288 83.7953 57.4549 83.6614 57.6833C83.477 58.0016 83.2925 58.3221 83.106 58.6404C83.0972 58.6404 83.095 58.636 83.0928 58.6294C83.0906 58.6119 83.0972 58.5965 83.1016 58.5789C83.1916 58.2496 83.1169 57.9577 82.8908 57.7074C82.7525 57.5559 82.5769 57.4703 82.3749 57.4418C82.1115 57.4045 81.8788 57.4725 81.6702 57.6372C81.6329 57.6657 81.6088 57.7074 81.5649 57.7294C81.5561 57.7294 81.5517 57.725 81.5495 57.7184C81.6549 57.5362 81.7581 57.354 81.8634 57.1718C82.2981 56.4166 82.7328 55.6614 83.1696 54.9084C83.174 54.8996 83.1806 54.8931 83.185 54.8843Z"
                    fill="#FABC04"
                />
                <path
                    d="M81.5583 57.7249C81.6 57.6876 81.6395 57.6481 81.6834 57.613C82.2169 57.1915 83.0181 57.4966 83.1345 58.164C83.163 58.3242 83.1477 58.4779 83.0994 58.6316C83.0972 58.6447 83.095 58.6557 83.0906 58.6689C83.0708 58.704 83.0533 58.7413 83.0313 58.7765C82.8359 59.0992 82.5483 59.2594 82.1708 59.2353C81.7383 59.2045 81.398 58.8796 81.3387 58.4494C81.3102 58.2408 81.3519 58.0454 81.4595 57.8654C81.4814 57.8259 81.5078 57.7908 81.5319 57.7513C81.5429 57.7425 81.5385 57.7249 81.5583 57.7249Z"
                    fill="#34A852"
                />
                <path
                    d="M81.5583 57.725C81.5495 57.7337 81.5495 57.7491 81.5342 57.7513C81.532 57.7359 81.5407 57.7272 81.5495 57.7162L81.5583 57.725Z"
                    fill="#FABC04"
                />
                <path
                    d="M83.0906 58.6689C83.0818 58.6535 83.0906 58.6426 83.0993 58.6316C83.1015 58.6338 83.1059 58.6382 83.1081 58.6404L83.0906 58.6689Z"
                    fill="#E1C025"
                />
            </g>
            <path
                d="M97.66 43.82V58H103.14C105.8 58 107.52 56.34 107.52 54.04C107.52 52.28 106.4 51.02 105.02 50.68C106.2 50.28 107.08 49.1 107.08 47.64C107.08 45.44 105.52 43.82 102.76 43.82H97.66ZM100.38 49.68V46.14H102.32C103.66 46.14 104.36 46.82 104.36 47.9C104.36 48.98 103.6 49.68 102.34 49.68H100.38ZM100.38 55.7V51.96H102.66C104.04 51.96 104.76 52.74 104.76 53.86C104.76 55 103.96 55.7 102.62 55.7H100.38Z"
                fill="black"
            />
            <path
                d="M111.822 58V48.16H109.162V58H111.822ZM108.842 44.94C108.842 45.82 109.582 46.56 110.482 46.56C111.402 46.56 112.122 45.82 112.122 44.94C112.122 44.02 111.402 43.28 110.482 43.28C109.582 43.28 108.842 44.02 108.842 44.94Z"
                fill="black"
            />
            <path
                d="M123.453 43.52H120.833V49.14C120.553 48.62 119.753 47.92 118.053 47.92C115.273 47.92 113.333 50.18 113.333 53.06C113.333 56.04 115.333 58.24 118.133 58.24C119.453 58.24 120.453 57.64 120.893 56.86C120.893 57.32 120.953 57.8 120.993 58H123.533C123.493 57.6 123.453 56.88 123.453 56.22V43.52ZM116.013 53.06C116.013 51.3 117.093 50.3 118.453 50.3C119.813 50.3 120.873 51.28 120.873 53.04C120.873 54.82 119.813 55.86 118.453 55.86C117.053 55.86 116.013 54.82 116.013 53.06Z"
                fill="black"
            />
            <path
                d="M135.1 43.52H132.48V49.14C132.2 48.62 131.4 47.92 129.7 47.92C126.92 47.92 124.98 50.18 124.98 53.06C124.98 56.04 126.98 58.24 129.78 58.24C131.1 58.24 132.1 57.64 132.54 56.86C132.54 57.32 132.6 57.8 132.64 58H135.18C135.14 57.6 135.1 56.88 135.1 56.22V43.52ZM127.66 53.06C127.66 51.3 128.74 50.3 130.1 50.3C131.46 50.3 132.52 51.28 132.52 53.04C132.52 54.82 131.46 55.86 130.1 55.86C128.7 55.86 127.66 54.82 127.66 53.06Z"
                fill="black"
            />
            <path
                d="M139.886 58V48.16H137.226V58H139.886ZM136.906 44.94C136.906 45.82 137.646 46.56 138.546 46.56C139.466 46.56 140.186 45.82 140.186 44.94C140.186 44.02 139.466 43.28 138.546 43.28C137.646 43.28 136.906 44.02 136.906 44.94Z"
                fill="black"
            />
            <path
                d="M144.657 52.34C144.657 51.2 145.337 50.3 146.497 50.3C147.777 50.3 148.317 51.16 148.317 52.26V58H150.977V51.8C150.977 49.64 149.857 47.9 147.417 47.9C146.357 47.9 145.177 48.36 144.577 49.38V48.16H141.997V58H144.657V52.34Z"
                fill="black"
            />
            <path
                d="M152.418 58.54C152.698 60.42 154.498 62.1 157.198 62.1C160.898 62.1 162.358 59.64 162.358 56.94V48.16H159.798V49.32C159.458 48.66 158.558 47.98 156.998 47.98C154.298 47.98 152.418 50.18 152.418 52.78C152.418 55.54 154.378 57.56 156.998 57.56C158.418 57.56 159.338 56.94 159.738 56.3V57.02C159.738 58.92 158.798 59.76 157.118 59.76C155.858 59.76 154.998 58.98 154.818 57.9L152.418 58.54ZM157.458 55.28C156.078 55.28 155.098 54.32 155.098 52.78C155.098 51.26 156.138 50.3 157.458 50.3C158.738 50.3 159.778 51.26 159.778 52.78C159.778 54.3 158.818 55.28 157.458 55.28Z"
                fill="black"
            />
            <path
                d="M177.795 58V55.4H171.675V52.12H177.215V49.66H171.675V46.42H177.795V43.82H168.915V58H177.795Z"
                fill="black"
            />
            <path
                d="M178.517 58H181.517C181.857 57.46 183.177 55.52 183.537 54.98L185.597 58H188.717L185.157 52.98L188.597 48.16H185.577L183.637 51.08C183.297 50.54 182.017 48.7 181.677 48.16H178.517L182.017 53.06L178.517 58Z"
                fill="black"
            />
            <path
                d="M192.36 61.8V57.04C192.84 57.7 193.84 58.24 195.2 58.24C197.98 58.24 199.84 56.04 199.84 53.06C199.84 50.14 198.18 47.94 195.3 47.94C193.82 47.94 192.72 48.6 192.28 49.36V48.16H189.7V61.8H192.36ZM197.22 53.08C197.22 54.84 196.14 55.86 194.78 55.86C193.42 55.86 192.32 54.82 192.32 53.08C192.32 51.34 193.42 50.32 194.78 50.32C196.14 50.32 197.22 51.34 197.22 53.08Z"
                fill="black"
            />
            <path
                d="M203.485 51.96C203.545 51.06 204.305 50.02 205.685 50.02C207.205 50.02 207.845 50.98 207.885 51.96H203.485ZM208.145 54.52C207.825 55.4 207.145 56.02 205.905 56.02C204.585 56.02 203.485 55.08 203.425 53.78H210.465C210.465 53.74 210.505 53.34 210.505 52.96C210.505 49.8 208.685 47.86 205.645 47.86C203.125 47.86 200.805 49.9 200.805 53.04C200.805 56.36 203.185 58.3 205.885 58.3C208.305 58.3 209.865 56.88 210.365 55.18L208.145 54.52Z"
                fill="black"
            />
            <path
                d="M218.055 48.12C217.855 48.1 217.655 48.08 217.435 48.08C216.595 48.08 215.235 48.32 214.635 49.62V48.16H212.055V58H214.715V53.5C214.715 51.38 215.895 50.72 217.255 50.72C217.494 50.72 217.755 50.74 218.055 50.8V48.12Z"
                fill="black"
            />
            <path
                d="M221.868 58V48.16H219.208V58H221.868ZM218.888 44.94C218.888 45.82 219.628 46.56 220.528 46.56C221.448 46.56 222.168 45.82 222.168 44.94C222.168 44.02 221.448 43.28 220.528 43.28C219.628 43.28 218.888 44.02 218.888 44.94Z"
                fill="black"
            />
            <path
                d="M226.639 58V52.24C226.639 51.16 227.319 50.3 228.479 50.3C229.679 50.3 230.219 51.1 230.219 52.16V58H232.859V52.24C232.859 51.18 233.54 50.3 234.68 50.3C235.9 50.3 236.419 51.1 236.419 52.16V58H238.999V51.62C238.999 48.98 237.26 47.88 235.439 47.88C234.139 47.88 233.1 48.32 232.32 49.52C231.82 48.46 230.74 47.88 229.4 47.88C228.32 47.88 227.059 48.4 226.52 49.36V48.16H223.979V58H226.639Z"
                fill="black"
            />
            <path
                d="M243.116 51.96C243.176 51.06 243.936 50.02 245.316 50.02C246.836 50.02 247.476 50.98 247.516 51.96H243.116ZM247.776 54.52C247.456 55.4 246.776 56.02 245.536 56.02C244.216 56.02 243.116 55.08 243.056 53.78H250.096C250.096 53.74 250.136 53.34 250.136 52.96C250.136 49.8 248.316 47.86 245.276 47.86C242.756 47.86 240.436 49.9 240.436 53.04C240.436 56.36 242.816 58.3 245.516 58.3C247.936 58.3 249.496 56.88 249.996 55.18L247.776 54.52Z"
                fill="black"
            />
            <path
                d="M254.346 52.34C254.346 51.2 255.026 50.3 256.186 50.3C257.466 50.3 258.006 51.16 258.006 52.26V58H260.666V51.8C260.666 49.64 259.546 47.9 257.106 47.9C256.046 47.9 254.866 48.36 254.266 49.38V48.16H251.686V58H254.346V52.34Z"
                fill="black"
            />
            <path
                d="M266.147 45.22H263.747V46.6C263.747 47.48 263.267 48.16 262.227 48.16H261.727V50.52H263.507V55.1C263.507 57 264.707 58.14 266.627 58.14C267.407 58.14 267.887 58 268.127 57.9V55.7C267.987 55.74 267.627 55.78 267.307 55.78C266.547 55.78 266.147 55.5 266.147 54.64V50.52H268.127V48.16H266.147V45.22Z"
                fill="black"
            />
            <path
                d="M269.005 55.26C269.125 56.38 270.145 58.3 273.105 58.3C275.685 58.3 276.925 56.66 276.925 55.06C276.925 53.62 275.945 52.44 274.005 52.04L272.605 51.74C272.065 51.64 271.705 51.34 271.705 50.86C271.705 50.3 272.265 49.88 272.965 49.88C274.085 49.88 274.505 50.62 274.585 51.2L276.805 50.7C276.685 49.64 275.745 47.86 272.945 47.86C270.825 47.86 269.265 49.32 269.265 51.08C269.265 52.46 270.125 53.6 272.025 54.02L273.325 54.32C274.085 54.48 274.385 54.84 274.385 55.28C274.385 55.8 273.965 56.26 273.085 56.26C271.925 56.26 271.345 55.54 271.285 54.76L269.005 55.26Z"
                fill="black"
            />
            <g filter="url(#filter15_dd_1194_11519)">
                <rect x="420" y="40" width="196" height="22" rx="11" fill="white" />
            </g>
            <rect x="557" y="48" width="32" height="6" rx="3" fill="#ECECEF" />
            <g filter="url(#filter16_dd_1194_11519)">
                <rect x="595" y="46" width="10" height="10" rx="5" fill="white" />
            </g>
            <g filter="url(#filter17_d_1194_11519)">
                <path d="M494 40H548V62H494V40Z" fill="white" />
            </g>
            <rect opacity="0.1" x="503" y="48" width="20" height="6" rx="3" fill="#006DFF" />
            <g filter="url(#filter18_dd_1194_11519)">
                <rect x="529" y="46" width="10" height="10" rx="5" fill="white" />
            </g>
            <g filter="url(#filter19_d_1194_11519)">
                <path
                    d="M420 51C420 44.9249 424.925 40 431 40H494V62H431C424.925 62 420 57.0751 420 51Z"
                    fill="white"
                />
            </g>
            <rect x="430" y="48" width="56" height="6" rx="3" fill="#ECECEF" />
        </g>
        <defs>
            <filter
                id="filter0_dd_1194_11519"
                x="62"
                y="97"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_dd_1194_11519"
                x="140"
                y="111"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_dd_1194_11519"
                x="62"
                y="145"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_dd_1194_11519"
                x="143"
                y="159"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter4_dd_1194_11519"
                x="62"
                y="193"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter5_dd_1194_11519"
                x="140"
                y="207"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter6_dd_1194_11519"
                x="62"
                y="241"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter7_dd_1194_11519"
                x="138"
                y="255"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter8_dd_1194_11519"
                x="62"
                y="289"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter9_dd_1194_11519"
                x="132"
                y="303"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter10_dd_1194_11519"
                x="62"
                y="337"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter11_dd_1194_11519"
                x="132"
                y="351"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter12_dd_1194_11519"
                x="62"
                y="385"
                width="556"
                height="45"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter13_dd_1194_11519"
                x="140"
                y="399"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter14_dd_1194_11519"
                x="76"
                y="50"
                width="16"
                height="17"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter15_dd_1194_11519"
                x="418"
                y="39"
                width="200"
                height="27"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter16_dd_1194_11519"
                x="593"
                y="45"
                width="14"
                height="15"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter17_d_1194_11519"
                x="494"
                y="40"
                width="55"
                height="22"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.960784 0 0 0 0.5 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter18_dd_1194_11519"
                x="527"
                y="45"
                width="14"
                height="15"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_1194_11519"
                />
                <feOffset />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="2"
                    operator="erode"
                    in="SourceAlpha"
                    result="effect2_dropShadow_1194_11519"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_1194_11519"
                    result="effect2_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <filter
                id="filter19_d_1194_11519"
                x="420"
                y="40"
                width="75"
                height="22"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.960784 0 0 0 0.5 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1194_11519"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1194_11519"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0_1194_11519">
                <rect width="680" height="408" fill="white" />
            </clipPath>
            <clipPath id="clip1_1194_11519">
                <rect width="12" height="12" fill="white" transform="translate(524 112)" />
            </clipPath>
            <clipPath id="clip2_1194_11519">
                <rect width="12" height="12" fill="white" transform="translate(524 160)" />
            </clipPath>
            <clipPath id="clip3_1194_11519">
                <rect width="12" height="12" fill="white" transform="translate(524 208)" />
            </clipPath>
            <clipPath id="clip4_1194_11519">
                <rect width="12" height="12" fill="white" transform="translate(524 256)" />
            </clipPath>
            <clipPath id="clip5_1194_11519">
                <rect width="12" height="12" fill="white" transform="translate(524 304)" />
            </clipPath>
            <clipPath id="clip6_1194_11519">
                <rect width="12" height="12" fill="white" transform="translate(524 352)" />
            </clipPath>
            <clipPath id="clip7_1194_11519">
                <rect width="12" height="12" fill="white" transform="translate(524 400)" />
            </clipPath>
            <clipPath id="clip8_1194_11519">
                <rect
                    width="5.48547"
                    height="5.48547"
                    fill="white"
                    transform="translate(81.33 54)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
