/**
 * Extracts the ID from a resource name. Works for campaigns, ad groups, ads.
 * @param resourceName - The resource name.
 * @returns - The extracted ID.
 */
export const extractIdFromResourceName = (resourceName: string): number => {
    const end = resourceName.split('/').slice(-1)[0]

    return end.includes('~') ? +end.split('~')[1] : +end
}
