<template>
    <svg
        class="bg"
        width="268"
        height="364"
        viewBox="0 0 268 364"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="268" height="364" rx="28" fill="#fff" />
        <g filter="url(#filter0_dd_131_3942)">
            <rect x="118" y="251" width="113" height="64" rx="8" fill="#fff" />
        </g>
        <rect x="202" y="305" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="305" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="305" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="305" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path stroke="#F6F6F9" d="M118 298.5h113" />
        <rect x="202" y="289" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="289" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="289" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="289" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path stroke="#F6F6F9" d="M118 282.5h113" />
        <rect x="202" y="273" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="273" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="273" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="273" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path stroke="#F6F6F9" d="M118 266.5h113" />
        <rect x="202" y="257" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="257" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="257" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="257" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="240" width="45" height="5" rx="2.5" fill="#000" fill-opacity=".1" />
        <rect x="117" y="224" width="94" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="117" y="218" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="117" y="212" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <g filter="url(#filter1_dd_131_3942)">
            <rect x="118" y="131" width="113" height="68" rx="8" fill="#fff" />
        </g>
        <rect x="159" y="190" width="31" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="216" y="152" width="6" height="31" rx="2" fill="#00A367" />
        <rect x="206" y="156" width="6" height="27" rx="2" fill="#00A367" />
        <rect x="196" y="142" width="6" height="41" rx="2" fill="#00A367" />
        <rect x="186" y="154" width="6" height="29" rx="2" fill="#00A367" />
        <rect x="176" y="152" width="6" height="31" rx="2" fill="#FF9500" />
        <rect x="166" y="154" width="6" height="29" rx="2" fill="#FF9500" />
        <rect x="156" y="150" width="6" height="33" rx="2" fill="#FF9500" />
        <rect x="146" y="158" width="6" height="25" rx="2" fill="#FF2828" />
        <rect x="136" y="162" width="6" height="21" rx="2" fill="#FF2828" />
        <rect x="126" y="167" width="6" height="16" rx="2" fill="#FF2828" />
        <rect x="118" y="120" width="45" height="5" rx="2.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="104" width="82" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="98" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="92" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="86" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="74" width="94" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="68" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="62" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="56" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="50" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="36" width="45" height="5" rx="2.5" fill="#000" fill-opacity=".1" />
        <path
            opacity=".1"
            d="M98.4931 69c-.0008 4.0053-.7906 7.9714-2.3242 11.6718-1.5335 3.7005-3.7809 7.0628-6.6137 9.895-2.8327 2.8321-6.1955 5.0788-9.8963 6.6115-3.7007 1.5328-7.667 2.3217-11.6723 2.3217-4.0054 0-7.9713-.7889-11.6714-2.3217-3.7001-1.5327-7.0619-3.7794-9.8935-6.6115-2.8316-2.8322-5.0774-6.1945-6.6094-9.895-1.532-3.7004-2.32-7.6665-2.3192-11.6718.0009-4.0053.7906-7.9714 2.3242-11.6718 1.5336-3.7005 3.7809-7.0628 6.6137-9.895 2.8328-2.8321 6.1956-5.0788 9.8963-6.6115C60.0281 39.2889 63.9944 38.5 67.9997 38.5s7.9712.7889 11.6713 2.3217c3.7001 1.5327 7.0619 3.7794 9.8935 6.6115 2.8316 2.8322 5.0775 6.1945 6.6095 9.895 1.5319 3.7004 2.32 7.6665 2.3191 11.6718h0Z"
            stroke="#00A367"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M67.9997 38.5c5.7063 0 11.2978 1.6008 16.1389 4.6204 4.8412 3.0197 8.7379 7.3371 11.2473 12.4615 2.5093 5.1244 3.5305 10.8502 2.9477 16.5268-.5828 5.6766-2.7464 11.0761-6.2448 15.585-3.4984 4.5088-8.1912 7.9461-13.5453 9.9211-5.354 1.9751-11.1543 2.4082-16.7418 1.2515-5.5875-1.1572-10.7379-3.8587-14.866-7.7974-4.128-3.9388-7.0681-8.9569-8.486-14.4839"
            stroke="#00A367"
            stroke-width="3"
            stroke-linecap="round"
        />
        <rect x="58" y="78" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path
            d="M69.1747 63.3238v-1.692c1.692-.144 2.772-1.134 3.006-2.394h2.16v10.62h2.664v2.142h-7.866v-2.142h2.736v-6.534h-2.7ZM68.6278 59.2378v2.142c-1.026.846-4.608 4.104-5.184 10.62h-2.574c.558-7.002 4.896-10.602 4.896-10.602h-6.714v-2.16h9.576Z"
            fill="#00A367"
        />
        <defs>
            <filter
                id="filter0_dd_131_3942"
                x="116"
                y="250"
                width="117"
                height="69"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_131_3942"
                />
                <feOffset />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_131_3942" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect2_dropShadow_131_3942" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend in2="effect1_dropShadow_131_3942" result="effect2_dropShadow_131_3942" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_131_3942" result="shape" />
            </filter>
            <filter
                id="filter1_dd_131_3942"
                x="116"
                y="130"
                width="117"
                height="73"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_131_3942"
                />
                <feOffset />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_131_3942" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect2_dropShadow_131_3942" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend in2="effect1_dropShadow_131_3942" result="effect2_dropShadow_131_3942" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_131_3942" result="shape" />
            </filter>
        </defs>
    </svg>
</template>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.bg {
    box-shadow: $opteo-shadow-l;
    @include br-28;
}
</style>
