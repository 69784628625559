<template>
    <!-- Header -->
    <header class="header">
        <Avatar
            v-if="domainColour && domainInitials"
            :platform="Platform.Platform.GoogleAds"
            :color="domainColour"
            :width="40"
            :height="40"
            showAccountType
        >
            {{ domainInitials }}
        </Avatar>
        <Skeleton v-else :width="40" :height="40" />
        <Spacer width="1.75rem" />
        <Text as="h1" weight="600">Create Experiment</Text>
    </header>
    <Spacer height="4.5rem" />
    <!-- Create Experiment Flow -->
    <oForm @submitted="createExperiment" :validate-on-submit="true">
        <div class="create-experiment-flow">
            <!-- Experiment Name -->
            <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Experiment Name</Text>
                </div>
                <div class="section-content">
                    <oInput
                        label="Experiment Name"
                        name="experimentName"
                        :maxCharacters="244"
                        required
                        autofocus
                        v-model="experimentCreationFormBase.experimentName"
                    />
                    <oInput
                        label="Experiment Description"
                        name="experimentDescription"
                        type="textarea"
                        :maxCharacters="2048"
                        style="min-height: 5.625rem"
                        v-model="experimentCreationFormBase.experimentDescription"
                    />
                </div>
            </section>
            <!-- Included Campaigns -->
            <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Included Campaigns</Text>
                </div>
                <!-- Campaigns table -->
                <oTable
                    :headers="baseCampaignTableHeaders"
                    :items="selectedCampaigns"
                    :borderRadius="20"
                    fixedLayout
                    order-by="cost"
                    order="DESC"
                >
                    <!-- Campaign column -->
                    <template v-slot:column.campaign="campaign">
                        <div class="table-cell overflow">
                            <Tooltip
                                v-if="campaign.value.length > 28"
                                :content="campaign.value"
                                :offset="[0, 8]"
                                :maxWidth="600"
                                placement="top-start"
                            >
                                <EntityPill
                                    :tooltip="false"
                                    :content="campaign.value"
                                    type="campaign"
                                />
                            </Tooltip>
                            <EntityPill
                                v-else
                                :tooltip="false"
                                :content="campaign.value"
                                type="campaign"
                            />
                            <!-- <CircleCampaignIcon :tooltip="false" />
                            <Tooltip
                                v-if="campaign.value.length > 28"
                                :content="campaign.value"
                                :offset="[-12, 8]"
                                :maxWidth="600"
                                placement="top-start"
                            >
                                {{ campaign.value }}
                            </Tooltip>
                            <span v-else>
                                {{ campaign.value }}
                            </span> -->
                        </div>
                    </template>

                    <template v-slot:column.cost="cost">
                        <Money :value="cost.value" />
                    </template>

                    <template v-slot:column.conversions="conversions">
                        <Number :value="conversions.value" />
                    </template>

                    <template v-slot:column.cpa="cpa">
                        <Money :value="cpa.value" />
                    </template>

                    <template v-slot:column.convValue="convValue">
                        <Money :value="convValue.value" />
                    </template>

                    <template v-slot:column.roas="roas">
                        <Roas :value="roas.value" />
                    </template>

                    <!-- Action column -->
                    <template v-slot:column.action="row">
                        <oButton
                            size="small"
                            color="white"
                            @clicked="toggleSelectedCampaign(row.row)"
                            >{{ row.row.selected ? 'Remove' : 'Include' }}</oButton
                        >
                    </template>
                </oTable>
            </section>
            <!-- Bidding Strategies -->
            <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Bidding Strategies</Text>
                </div>
                <div class="section-content">
                    <oInput
                        label="Current Bidding Strategy"
                        name="currentBiddingStrategy"
                        type="select"
                        :items="allBiddingStrategiesSelectItems"
                        v-model="currentBiddingStrategy"
                        disabled
                    />

                    <template v-if="isAdvertisingChannelTypeSearch">
                        <oInput
                            label="Experiment Bidding Strategy"
                            name="experimentBiddingStrategy"
                            type="select"
                            :items="searchBiddingStrategiesSelectItems"
                            v-model="searchExperimentCreationForm.experimentBiddingStrategy"
                            @updated="handleSelectedBiddingStrategy(searchExperimentCreationForm)"
                            required
                        />

                        <!-- Target impression share -->
                        <template
                            v-if="
                                searchExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.TARGET_IMPRESSION_SHARE
                            "
                        >
                            <oInput
                                label="Where do you want ads to appear?"
                                name="targetImpressionShareLocationSelectItems"
                                type="select"
                                :items="targetImpressionShareLocationSelectItems"
                                v-model="searchExperimentCreationForm.targetImpressionShareLocation"
                                required
                            />
                            <oInput
                                label="Impression Share Target"
                                name="targetImpressionSharePercentage"
                                type="number"
                                suffix="%"
                                min="0.01"
                                max="100"
                                v-model="
                                    searchExperimentCreationForm.targetImpressionSharePercentage
                                "
                                required
                            />
                            <oInput
                                label="Maximum CPC Bid Limit"
                                name="targetImpressionShareMaxCpcBidLimit"
                                type="number"
                                :prefix="currencySymbol"
                                min="0.01"
                                v-model="
                                    searchExperimentCreationForm.targetImpressionShareMaxCpcBidLimit
                                "
                                required
                            />
                        </template>
                        <!-- Maximise conversion value -->
                        <template
                            v-if="
                                searchExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE
                            "
                        >
                        </template>
                        <!-- Target ROAS -->
                        <template
                            v-if="
                                searchExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.TARGET_ROAS
                            "
                        >
                            <oInput
                                label="Target ROAS"
                                name="maximiseConversionValueTargetRoas"
                                type="number"
                                suffix="%"
                                min="0.01"
                                max="1000"
                                v-model="
                                    searchExperimentCreationForm.maximiseConversionValueTargetRoas
                                "
                                required
                            />

                            <oInput
                                v-if="
                                    searchExperimentCreationForm.maximiseConversionValueIsTargetRoas
                                "
                                label="Maximum CPC Bid Limit (Optional)"
                                name="maximiseConversionValueMaxCpcBidLimit"
                                type="number"
                                :prefix="currencySymbol"
                                min="0.01"
                                v-model="
                                    searchExperimentCreationForm.maximiseConversionValueMaxCpcBidLimit
                                "
                            />
                        </template>
                        <!-- Maximise conversions -->
                        <template
                            v-if="
                                searchExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.MAXIMIZE_CONVERSIONS
                            "
                        >
                        </template>
                        <!-- Target CPA -->
                        <template
                            v-if="
                                searchExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.TARGET_CPA
                            "
                        >
                            <oInput
                                v-if="searchExperimentCreationForm.maximiseConversionsIsTargetCpa"
                                label="Target CPA"
                                name="maximiseConversionsTargetCpa"
                                type="number"
                                :prefix="currencySymbol"
                                min="0.01"
                                v-model="searchExperimentCreationForm.maximiseConversionsTargetCpa"
                                required
                            />
                            <oInput
                                v-if="searchExperimentCreationForm.maximiseConversionsIsTargetCpa"
                                label="Maximum CPC Bid Limit (Optional)"
                                name="maximiseConversionsMaxCpcBidLimit"
                                type="number"
                                :prefix="currencySymbol"
                                min="0.01"
                                v-model="
                                    searchExperimentCreationForm.maximiseConversionsMaxCpcBidLimit
                                "
                            />
                        </template>
                        <!-- Target spend -->
                        <template
                            v-if="
                                searchExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.TARGET_SPEND
                            "
                        >
                            <oInput
                                label="Set Maximum CPC Bid Limit"
                                name="targetSpendSetMaxCpc"
                                type="checkbox"
                                v-model="searchExperimentCreationForm.targetSpendSetMaxCpc"
                            />
                            <oInput
                                v-if="searchExperimentCreationForm.targetSpendSetMaxCpc"
                                label="Maximum CPC Bid Limit"
                                name="targetSpendMaxCpc"
                                type="number"
                                :prefix="currencySymbol"
                                min="0.01"
                                v-model="searchExperimentCreationForm.targetSpendMaxCpc"
                                required
                            />
                        </template>

                        <!-- Manual CPC -->
                        <template
                            v-if="
                                searchExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.MANUAL_CPC
                            "
                        >
                            <oInput
                                label="Use Enhanced CPC"
                                name="manualCpcUseEnhancedCpc"
                                type="checkbox"
                                v-model="searchExperimentCreationForm.manualCpcUseEnhancedCpc"
                            />
                            <oInput
                                label="Maximum Ad Group CPC Bid Limit"
                                name="manualCpcMaxAdGroupCpcBidLimit"
                                type="number"
                                :prefix="currencySymbol"
                                min="0.01"
                                v-model="
                                    searchExperimentCreationForm.manualCpcMaxAdGroupCpcBidLimit
                                "
                                required
                            />
                        </template>
                    </template>

                    <template v-else>
                        <oInput
                            label="Experiment Bidding Strategy"
                            name="experimentBiddingStrategy"
                            type="select"
                            :items="displayBiddingStrategiesSelectItems"
                            v-model="displayExperimentCreationForm.experimentBiddingStrategy"
                            required
                        />

                        <!-- Target spend -->
                        <template
                            v-if="
                                displayExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.TARGET_SPEND
                            "
                        >
                            <oInput
                                label="Set Maximum CPC Bid Limit"
                                name="targetSpendSetMaxCpc"
                                type="checkbox"
                                v-model="displayExperimentCreationForm.targetSpendSetMaxCpc"
                            />
                            <oInput
                                v-if="displayExperimentCreationForm.targetSpendSetMaxCpc"
                                label="Maximum CPC Bid Limit"
                                name="targetSpendMaxCpc"
                                type="number"
                                :prefix="currencySymbol"
                                min="0.01"
                                v-model="displayExperimentCreationForm.targetSpendMaxCpc"
                                required
                            />
                        </template>

                        <!-- Target ROAS -->
                        <template
                            v-if="
                                displayExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.TARGET_ROAS
                            "
                        >
                            <oInput
                                label="Target ROAS"
                                name="targetRoas"
                                type="number"
                                suffix="%"
                                min="0.01"
                                max="1000"
                                v-model="displayExperimentCreationForm.targetRoas"
                                required
                            />
                        </template>

                        <!-- Target CPA -->
                        <template
                            v-if="
                                displayExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.TARGET_CPA
                            "
                        >
                            <oInput
                                label="Target CPA"
                                name="targetCpa"
                                type="number"
                                :prefix="currencySymbol"
                                min="0.01"
                                v-model="displayExperimentCreationForm.targetCpa"
                                required
                            />
                        </template>

                        <!-- Manual CPC -->
                        <template
                            v-if="
                                displayExperimentCreationForm.experimentBiddingStrategy ===
                                BiddingStrategyType.MANUAL_CPC
                            "
                        >
                            <oInput
                                label="Use Enhanced CPC"
                                name="manualCpcUseEnhancedCpc"
                                type="checkbox"
                                v-model="displayExperimentCreationForm.manualCpcUseEnhancedCpc"
                            />
                            <oInput
                                label="Maximum Ad Group CPC Bid Limit"
                                name="manualCpcMaxAdGroupCpcBidLimit"
                                type="number"
                                :prefix="currencySymbol"
                                min="0.01"
                                v-model="
                                    displayExperimentCreationForm.manualCpcMaxAdGroupCpcBidLimit
                                "
                                required
                            />
                        </template>
                    </template>
                </div>
            </section>
            <!-- Budget Allocation -->
            <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Budget Allocation</Text>
                </div>
                <div class="section-content">
                    <oInput
                        label="Current Campaigns"
                        name="experimentBaseBudgetAllocation"
                        type="number"
                        suffix="%"
                        v-model="experimentCreationFormBase.experimentBaseBudgetAllocation"
                        max="99"
                        min="1"
                        required
                    />
                    <oInput
                        label="Experiment Campaigns"
                        name="experimentBudgetAllocation"
                        type="number"
                        suffix="%"
                        disabled
                        v-model="experimentBudgetAllocation"
                    />
                    <Text as="p" size="f-10" style="font-size: 0.75rem" color="gray">
                        The <b>Experiment Campaigns</b> field automatically updates according to the
                        amount in the <b>Current Campaigns</b> field.
                        <Spacer height="0.75rem" />
                        Set the amount you wish to spend on existing campaigns and the remainder
                        will be allocated to your experiment.
                    </Text>
                </div>
            </section>
            <!-- Experiment Duration -->
            <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Experiment Duration</Text>
                </div>
                <div class="section-content">
                    <oInput
                        label="Experiment Duration"
                        name="experimentDurationDays"
                        type="number"
                        suffix="days"
                        min="30"
                        max="90"
                        v-model="experimentCreationFormBase.experimentDurationDays"
                        required
                    />
                    <Text as="p" size="f-10" style="font-size: 0.75rem" color="gray">
                        Opteo has calculated a reasonable minimum duration to gather enough data for
                        a statistically confident decision. Use this field to adjust the duration of
                        your experiment.
                    </Text>
                </div>
            </section>
        </div>
        <!-- Create Button -->
        <section class="create-button">
            <oButton
                size="extra-large"
                :loading="createExperimentLoading"
                :disabled="!canSubmitForm"
                type="submit"
                ref="createExperimentButton"
            >
                Create Experiment
                <template v-slot:icon>
                    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 11 11 1M11 1H3M11 1v8"
                            stroke="#fff"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </template>
            </oButton>
            <div
                v-if="createExperimentErrors.length > 0"
                class="create-experiment-flow-error-container"
            >
                <InputErrorIcon style="flex-shrink: 0" />
                <Text
                    v-for="(error, index) in createExperimentErrors"
                    :key="'error-' + index"
                    as="p"
                    color="red"
                    size="f-9"
                    style="font-size: 0.75rem"
                >
                    {{ error }}
                </Text>
            </div>
        </section>
    </oForm>
</template>

<script lang="ts">
// Imports
import { defineComponent, onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'
import { enums } from '@opteo/types/gads'
import { useCreateFlow } from '@/composition/toolkit/experiments/useCreateFlow'
import { useDomain } from '@/composition/domain/useDomain'
import { Platform } from '@opteo/types'

// Components
import {
    Text,
    Spacer,
    Number,
    oInput,
    oForm,
    oButton,
    oTable,
    Roas,
    RightArrowIcon,
    CircleCampaignIcon,
    Avatar,
    InputErrorIcon,
    Tooltip,
    EntityPill,
} from '@opteo/components-next'

// Local Components
import Money from '@/components/global/oMoney.vue'
import Skeleton from '@/components/util/Skeleton.vue'

// Export
export default defineComponent({
    name: 'CreateFlow',
    components: {
        Text,
        Spacer,
        Money,
        Number,
        Roas,
        oForm,
        oInput,
        oButton,
        oTable,
        RightArrowIcon,
        CircleCampaignIcon,
        Avatar,
        Skeleton,
        InputErrorIcon,
        Tooltip,
        EntityPill,
    },
    setup() {
        // Anime
        onMounted(() => {
            anime.timeline().add({
                targets: ['.create-experiment-flow', '.header'],
                opacity: [0, 1],
                translateY: [16, 0],
                translateZ: 0,
                easing: 'cubicBezier(0.19, 1, 0.22, 1)',
                duration: 1200,
                delay: 300,
            })
        })
        // Domain
        const { domainInitials, domainColour } = useDomain()
        const { searchExperimentCreationForm } = useCreateFlow()

        const handleSelectedBiddingStrategy = function (
            form: typeof searchExperimentCreationForm.value
        ) {
            /* Target ROAS */
            if (form.experimentBiddingStrategy === enums.BiddingStrategyType.TARGET_ROAS) {
                form.maximiseConversionValueIsTargetRoas = true
            } else if (
                form.experimentBiddingStrategy ===
                enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE
            ) {
                form.maximiseConversionValueIsTargetRoas = false
            }

            /* Target CPA */
            if (form.experimentBiddingStrategy === enums.BiddingStrategyType.TARGET_CPA) {
                form.maximiseConversionsIsTargetCpa = true
            } else if (
                form.experimentBiddingStrategy === enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS
            ) {
                form.maximiseConversionsIsTargetCpa = false
            }
        }
        // Return
        return {
            domainInitials,
            domainColour,
            Platform,
            BiddingStrategyType: enums.BiddingStrategyType,
            ...useCreateFlow(),
            handleSelectedBiddingStrategy,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

header {
    max-width: 67.5rem;
    @include flex;
    @include items-center;
    height: 2.75rem;
    margin: 0 auto;
    margin-top: 5rem;
}
.create-experiment-flow {
    max-width: 67.5rem;
    @include container;
    @include br-32;
    margin: 0 auto;
    margin-bottom: 5rem;
}
section.form-section {
    border-bottom: 1px solid $opteo-light-gray;
    @include ph-40;
    @include pb-40;
}
section.create-button {
    @include flex-center;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 2rem;
    margin: auto;
    @include sticky;
    @include bg-opteo-white;
    @include z-max;
    @include container;
    border-radius: 0;
    @include pv-36;
    width: 100%;
    bottom: 0;
    left: 0;
}
section:last-child {
    border-bottom: none;
}
section .section-header {
    line-height: 2.75rem;
    @include flex;
    @include items-center;
    @include pv-28;
}
section .section-content {
    max-width: 23.5rem;
    margin: 0 auto;
    @include flex;
    flex-direction: column;
    gap: 1.5rem;
    @include container;
    @include br-24;
    @include pa-32;
}

.create-experiment-flow-error-container {
    @include flex;
    @include items-center;
    @include container;
    @include br-12;
    padding: 0.625rem 1rem;
    gap: 0.625rem;
    max-width: 30rem;
}
.create-experiment-flow-error-container p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// oTable
.create-experiment-flow .table-cell {
    @include flex;
    @include items-center;
    @include relative;
    gap: 0.875rem;
    height: 4.75rem;
}
.create-experiment-flow :deep(.o-table-container td.remove-padding) {
    padding: 0;
}
.create-experiment-flow .table-cell.overflow {
    padding-left: 1.5rem;
    overflow: hidden;
}
.create-experiment-flow .table-cell.overflow::after {
    content: '';
    @include absolute;
    width: 4rem;
    height: 100%;
    right: 0;
    top: 0;
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 50%);
    @include z-2;
}
</style>
