<template>
    <Carousel
        :arrows="true"
        :options="{
            slidesToScroll: slidesToScroll,
            align: 'center',
            speed: 8,
            startIndex: initialIndex,
        }"
    >
        <template #slides>
            <div
                v-for="theme in themes"
                :key="theme.name"
                @click="updateThemeValue(theme.name, $event)"
                @mouseenter="setHoveredTheme(theme.name)"
                @mouseleave="setHoveredTheme(null)"
                class="theme-slide"
                tabindex="0"
            >
                <Carousel
                    :arrows="false"
                    :options="{
                        slidesToScroll: 1,
                        align: 'center',
                        speed: 8,
                    }"
                >
                    <template #slides>
                        <div
                            v-for="(img, index) in theme.images"
                            :key="index"
                            class="theme-preview-slide"
                        >
                            <div class="slide-thumbnail">
                                <oImage
                                    :src="img"
                                    :ref="'image-' + (index + 1)"
                                    style="display: block; font-size: 0"
                                />
                            </div>
                        </div>
                    </template>
                    <template #navigation="{ carousel, prevEnabled, nextEnabled }">
                        <transition name="fade">
                            <div
                                v-if="hoveredTheme === theme.name && prevEnabled"
                                class="carousel-button prev-button"
                            >
                                <oButton
                                    :circle="true"
                                    color="white"
                                    size="small"
                                    @clicked="carousel.scrollPrev()"
                                    :disabled="!prevEnabled"
                                >
                                    <template v-slot:icon>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="7"
                                            height="12"
                                            viewBox="0 0 7 12"
                                            fill="none"
                                            :class="['left-arrow', { disabled: !prevEnabled }]"
                                        >
                                            <path
                                                d="M.293 10.293a1 1 0 1 0 1.414 1.414L.293 10.293ZM6 6l.707.707a1 1 0 0 0 0-1.414L6 6ZM1.707.293A1 1 0 0 0 .293 1.707L1.707.293Zm0 11.414 5-5-1.414-1.414-5 5 1.414 1.414Zm5-6.414-5-5L.293 1.707l5 5 1.414-1.414Z"
                                                fill="#000"
                                            />
                                        </svg>
                                    </template>
                                </oButton>
                            </div>
                        </transition>
                        <transition name="fade">
                            <div
                                v-if="hoveredTheme === theme.name && nextEnabled"
                                class="carousel-button next-button"
                            >
                                <oButton
                                    :circle="true"
                                    color="white"
                                    size="small"
                                    @clicked="carousel.scrollNext()"
                                    :disabled="!nextEnabled"
                                >
                                    <template v-slot:icon>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="7"
                                            height="12"
                                            viewBox="0 0 7 12"
                                            fill="none"
                                            :class="['right-arrow', { disabled: !nextEnabled }]"
                                        >
                                            <path
                                                d="M.293 10.293a1 1 0 1 0 1.414 1.414L.293 10.293ZM6 6l.707.707a1 1 0 0 0 0-1.414L6 6ZM1.707.293A1 1 0 0 0 .293 1.707L1.707.293Zm0 11.414 5-5-1.414-1.414-5 5 1.414 1.414Zm5-6.414-5-5L.293 1.707l5 5 1.414-1.414Z"
                                                fill="#000"
                                            />
                                        </svg>
                                    </template>
                                </oButton>
                            </div>
                        </transition>
                    </template>
                </Carousel>
                <div class="theme-info">
                    <Text as="h5" size="f-7" weight="500" :capitalise="true">
                        {{ theme.name }}
                    </Text>
                    <Spacer height="0.8125rem" />
                    <div class="theme-description">
                        <Text as="span" size="f-9" weight="400" style="line-height: 150%">
                            {{ theme.description }}
                        </Text>
                    </div>
                    <CheckIcon
                        checkable
                        :checked="themeName === theme.name"
                        style="position: absolute; top: 20px; right: 20px"
                    />
                </div>
            </div>
        </template>
        <!-- <div>initialIndex: {{ initialIndex }}</div> -->
    </Carousel>
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue'

import { Reports } from '@opteo/types'
import { Modal, oButton, Text, CheckIcon, Carousel, Spacer, oImage } from '@opteo/components-next'

import professional1 from '@/assets/img/report-themes/professional-1.png'
import professional2 from '@/assets/img/report-themes/professional-2.png'
import professional3 from '@/assets/img/report-themes/professional-3.png'
import professional4 from '@/assets/img/report-themes/professional-4.png'
import professional5 from '@/assets/img/report-themes/professional-5.png'
import professional6 from '@/assets/img/report-themes/professional-6.png'
import professional7 from '@/assets/img/report-themes/professional-7.png'
import professional8 from '@/assets/img/report-themes/professional-8.png'

import colourful1 from '@/assets/img/report-themes/colourful-1.png'
import colourful2 from '@/assets/img/report-themes/colourful-2.png'
import colourful3 from '@/assets/img/report-themes/colourful-3.png'
import colourful4 from '@/assets/img/report-themes/colourful-4.png'
import colourful5 from '@/assets/img/report-themes/colourful-5.png'
import colourful6 from '@/assets/img/report-themes/colourful-6.png'
import colourful7 from '@/assets/img/report-themes/colourful-7.png'
import colourful8 from '@/assets/img/report-themes/colourful-8.png'

import modern1 from '@/assets/img/report-themes/modern-1.png'
import modern2 from '@/assets/img/report-themes/modern-2.png'
import modern3 from '@/assets/img/report-themes/modern-3.png'
import modern4 from '@/assets/img/report-themes/modern-4.png'

import serif1 from '@/assets/img/report-themes/serif-1.png'
import serif2 from '@/assets/img/report-themes/serif-2.png'
import serif3 from '@/assets/img/report-themes/serif-3.png'
import serif4 from '@/assets/img/report-themes/serif-4.png'

import traditional1 from '@/assets/img/report-themes/traditional-1.png'
import traditional2 from '@/assets/img/report-themes/traditional-2.png'
import traditional3 from '@/assets/img/report-themes/traditional-3.png'
import traditional4 from '@/assets/img/report-themes/traditional-4.png'

const THEMES_PER_PAGE = 3

export default {
    components: { Modal, oButton, Text, CheckIcon, Carousel, Spacer, oImage },
    props: {
        themeName: {
            type: String as PropType<Reports.Core.ReportTypeface>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const themes = ref([
            {
                name: 'professional',
                description: 'A simple professional theme designed for all purposes.',
                modalOpen: false,
                images: [
                    professional1,
                    professional2,
                    professional3,
                    professional4,
                    professional5,
                    professional6,
                    professional7,
                    professional8,
                ],
            },
            {
                name: 'colourful',
                description: 'A colourful theme that puts your brand front and centre.',
                modalOpen: false,
                images: [
                    colourful1,
                    colourful2,
                    colourful3,
                    colourful4,
                    colourful5,
                    colourful6,
                    colourful7,
                    colourful8,
                ],
            },
            {
                name: 'modern',
                description: 'A modern theme perfect for fashion, media and marketing.',
                modalOpen: false,
                images: [modern1, modern2, modern3, modern4],
            },
            {
                name: 'serif',
                description: 'A modern serif theme suitable for contemporary businesses.',
                modalOpen: false,
                images: [serif1, serif2, serif3, serif4],
            },
            {
                name: 'traditional',
                description: 'A theme perfect for businesses with more traditional brands.',
                modalOpen: false,
                images: [traditional1, traditional2, traditional3, traditional4],
            },
        ])

        const hoveredTheme = ref()

        const setHoveredTheme = (theme: Reports.Core.ReportTypeface) => {
            hoveredTheme.value = theme
        }

        const updateThemeValue = (themeName: string, event: Event) => {
            const clickedElement = event.target as HTMLElement
            // if carousel nav button are clicked, prevent theme selection
            if (!!clickedElement.closest('.carousel-button')) {
                return
            }

            emit('update-theme', themeName)
        }

        // Get the initial scroll position of the carousel. Since there are two slides per "index", we divide by 2.
        const slidesToScroll = 2
        const initialIndex = Math.floor(
            themes.value.findIndex(theme => props.themeName === theme.name) / slidesToScroll
        )

        return {
            THEMES_PER_PAGE,
            themes,
            updateThemeValue,
            hoveredTheme,
            setHoveredTheme,
            slidesToScroll,
            initialIndex,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.theme-slide {
    @include container;
    position: relative;
    cursor: pointer;
    flex: 0 0 258px;
    max-width: 100%;
    overflow: hidden;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 1.5rem;
    height: 311px;
}
.theme-preview-slide {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0rem;
    padding: 0rem 1.5rem;
    height: 194px;
    position: relative;
    display: flex;
    align-items: center;
}
.slide-thumbnail {
    @include container;
    overflow: hidden;
    width: 100%;
    height: 148px;
}
.theme-info {
    position: relative;
    border-top: 1px solid;
    @include opteo-border;
    @include pa-24;
}
.theme-description {
    max-width: 176px;
}
.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0rem 0.5rem;
}
.carousel-button svg {
    transform: translateX(1px);
}
.prev-button {
    left: 0;
}
.next-button {
    right: 0;
}
svg.left-arrow {
    transform: rotate(180deg) translateX(1px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
