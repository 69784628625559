<template>
    <section class="page-not-found-container">
        <div class="page-not-found">
            <div class="illustration-container">
                <img src="@/assets/img/page-not-found.png" />
            </div>
            <Spacer height="3rem" />
            <div class="not-found-content">
                <Text as="h3">Page Not Found</Text>
                <Spacer height="1.25rem" />
                <p class="lh-copy opteo-gray">
                    We couldn't find the page you were looking for. Check the URL and try again. If
                    that doesn't work, click below to go back to the Account Centre.
                </p>
                <Spacer height="1.5rem" />
                <router-link to="/">
                    <oButton size="medium" color="blue">Go to Account Centre</oButton>
                </router-link>
            </div>
        </div>
    </section>
</template>
<script lang="ts">
import { oButton, Text, Spacer } from '@opteo/components-next'
export default {
    name: 'PageNotFound',
    components: { oButton, Text, Spacer },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.illustration-container {
    width: 100%;
    max-width: 374px;
    margin: 0 auto;
    aspect-ratio: 748 / 512;
}
.illustration-container img {
    width: 100%;
}

.page-not-found-container {
    height: 100vh;
    width: 100vw;
    @include flex;
    @include items-center;
    @include justify-center;
}

.page-not-found {
    width: 100%;
    max-width: 23.375rem;
}
.page-not-found img {
    width: 100%;
    max-width: 374px;
}

.not-found-content {
    width: 100%;
    max-width: 22.25rem;
    @include flex;
    @include items-center;
    @include tc;
    flex-direction: column;
    margin: 0 auto;
}
</style>
