<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.70711 2.29289c-.39053-.39052-1.02369-.39052-1.41422 0-.39052.39053-.39052 1.02369 0 1.41422L4.58579 6l-2.2929 2.29289c-.39052.39053-.39052 1.02369 0 1.41422.39053.39049 1.02369.39049 1.41422 0L6 7.41421l2.29289 2.2929c.39053.39049 1.02369.39049 1.41422 0 .39049-.39053.39049-1.02369 0-1.41422L7.41421 6l2.2929-2.29289c.39049-.39053.39049-1.02369 0-1.41422-.39053-.39052-1.02369-.39052-1.41422 0L6 4.58579l-2.29289-2.2929Z"
            :fill="hex"
        />
    </svg>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SmallCrossIcon',
    props: {
        hex: {
            type: String,
            default: '#000',
        },
    },
})
</script>
