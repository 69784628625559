<template>
    <ImprovementView :pushMessages="pushMessages" pushActionText="Push Improvement">
        <ImprovementContent>
            <ImprovementHeader :title="`No template found for ${componentName}`">
                Make sure to create a vue file located at
                <b class="code-block nowrap">{{ improvementLocation }}</b>
            </ImprovementHeader>
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import camelCase from 'lodash-es/camelCase'
import kebabCase from 'lodash-es/kebabCase'
import upperFirst from 'lodash-es/upperFirst'
import { computed, defineComponent, ref } from 'vue'

import { useImprovement } from '@/composition/improvement/useImprovement'

import ImprovementView from './ImprovementView.vue'
import ImprovementHeader from './ImprovementHeader.vue'

export default defineComponent({
    components: { ImprovementView, ImprovementHeader },
    setup() {
        const { improvement } = useImprovement()

        const componentName = computed(() => upperFirst(camelCase(improvement.value?.rec_action)))
        const componentType = computed(() => kebabCase(improvement.value?.type))

        const improvementLocation = computed(
            () =>
                `@/components/improvements/types/${componentType.value}/${componentName.value}.vue`
        )

        return { componentName, improvementLocation }
    },
})
</script>

<style scoped>
.image-component-container {
    background-color: #e9f0fd;
}

.code-block {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    line-height: normal;
    background: rgba(135, 131, 120, 0.15);
    color: #eb5757;
    border-radius: 3px;
    font-size: 85%;
    padding: 0.2em 0.4em;
}
</style>
