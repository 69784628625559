<template>
    <Popout
        v-model="budgetPopoverOpen"
        :disabled="budgetBar.status === BudgetStatus.INVALID"
        :offset="[0, 16]"
        :borderRadius="24"
        trigger="mouseenter"
        placement="top"
        max-width="23.25rem"
    >
        <div class="pill-container">
            <div
                class="pill"
                :class="[statusColor]"
                @click="budgetPopoverOpen = !budgetPopoverOpen"
            >
                <Percent
                    v-if="budgetBar.has_budget"
                    :value="budgetBar.display_difference / 100"
                    :decimal-places="0"
                />
                <span v-else>N/A</span>
            </div>
        </div>
        <template v-slot:content>
            <div class="budget-popover">
                <div class="budget-popover-title">
                    <Avatar
                        :color="account.color"
                        :platform="account.platform ?? 0"
                        show-account-type
                        account-type-size="small"
                    >
                        {{ accountInitials }}
                    </Avatar>
                    <Spacer width="1.125rem" />
                    <Text as="h6" weight="600">Budget Status</Text>
                </div>
                <Spacer height="1.25rem" />
                <Text as="p" v-if="budgetBar.status !== BudgetStatus.TOO_EARLY">
                    So far this month, you have spent
                    <b>
                        <Money :value="budgetBar.actual_spend" :currency="account.currencyCode" />
                    </b>
                    — or around
                    <b>
                        <Percent
                            :value="budgetBar.month_spent_percentage / 100"
                            :decimal-places="0"
                        />
                    </b>
                    of your monthly budget.

                    <span v-if="budgetBar.display_difference === 999">
                        If you continue spending at this pace, you will spend
                        <Text as="span" weight="600" color="red">significantly</Text> more than your
                        budget this month.
                    </span>
                    <span v-else>
                        If spending continues at this pace, you should spend around
                        <Text as="span" :color="statusColor" weight="600">
                            <Percent
                                :value="budgetBar.display_difference / 100"
                                :decimal-places="0"
                            />
                        </Text>
                        of your budget this month.
                    </span>
                </Text>
                <Text as="p" v-else>
                    It's too early in the month to make any predictions, but for reference, you have
                    spent about
                    <b
                        ><Money :value="budgetBar.actual_spend" :currency="account.currencyCode"
                    /></b>
                    of your
                    <b><Money :value="budgetBar.budget" :currency="account.currencyCode" /></b>
                    budget so far this month.
                </Text>
            </div>
        </template>
    </Popout>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue'
import { Account, Domain } from '@opteo/types'

import { Avatar, Percent, Money, Spacer, Text, Popout } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'

const BUDGET_COLOUR_MAP: Record<Domain.BudgetStatus, string> = {
    [Domain.BudgetStatus.GOOD]: 'green',
    [Domain.BudgetStatus.UNDER]: 'amber',
    [Domain.BudgetStatus.OVER]: 'amber',
    [Domain.BudgetStatus.FAR_UNDER]: 'red',
    [Domain.BudgetStatus.FAR_OVER]: 'red',
    [Domain.BudgetStatus.TOO_EARLY]: 'na',
    [Domain.BudgetStatus.INVALID]: 'na',
}

export default defineComponent({
    components: {
        Percent,
        Money,
        Skeleton,
        Spacer,
        Text,
        Popout,
        Avatar,
    },
    props: {
        account: {
            type: Object as PropType<Account.Info>,
            required: true,
        },
        accountInitials: {
            type: String,
            required: true,
        },
        budgetBar: {
            type: Object as PropType<Domain.BudgetBar>,
            required: true,
        },
    },
    setup(props) {
        const budgetPopoverOpen = ref(false)

        const statusColor = computed(() => BUDGET_COLOUR_MAP[props.budgetBar.status])

        const toggleBudgetPopover = () => (budgetPopoverOpen.value = !budgetPopoverOpen.value)

        return {
            BudgetStatus: Domain.BudgetStatus,
            statusColor,
            toggleBudgetPopover,
            budgetPopoverOpen,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.pill-container {
    @include flex;
    @include justify-center;
    @include items-center;
    @include no-select;
}
.pill {
    @include br-999;
    @include flex;
    @include items-center;
    @include justify-center;
    @include flex;
    @include opteo-white;
    @include f-9;
    @include fw-600;
    letter-spacing: -0.025rem;
    height: 2.25rem;
    width: 3.875rem;
}
.pill div,
.pill span {
    transform: translateY(-1px);
}

// Shadow Containers
.pill.green {
    background: $opteo-dark-green-translucent;
    color: $opteo-dark-green;
    @include br-999;
}
.pill.amber {
    background: $opteo-dark-amber-translucent;
    color: $opteo-dark-amber;
    @include br-999;
}
.pill.red {
    background: $opteo-dark-red-translucent;
    color: $opteo-dark-red;
    @include br-999;
}

.pill.na {
    @include br-999;
    background-color: $opteo-light-gray;
    color: $opteo-steel;
    @include fw-500;
}
.pill.na span {
    transform: translateY(0);
}

.budget-popover {
    @include pa-28;
    @include br-20;
    width: 23.25rem;
}
.budget-popover-title {
    @include flex;
    @include items-center;
}
.budget-popover p {
    @include f-8;
}
</style>
