<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        section-type="integrations"
        section-name="Integrations"
        :mode="mode"
        @score-history-button-clicked="$emit('score-history-button-clicked')"
    >
        <template #content>
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>

            <Spacer height="1.5rem" />

            <!-- Google Ads Connections -->
            <Text as="h6" weight="600">Google Ads Connections</Text>
            <Spacer height="2rem" />
            <div class="sub-scores-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <div v-for="row in connectionsSubScores" class="item">
                    <div style="margin-top: 2px">
                        <ScorecardDonut
                            :width="44"
                            :strokeWidth="2"
                            :compact="true"
                            :hidden="isLoading"
                            :score="isLoading ? 0 : row.score === true ? 100 : 0"
                        />
                    </div>
                    <Spacer width="1.5rem" />
                    <div>
                        <Text as="p" size="f-7" weight="500">{{ row.title }}</Text>
                        <Spacer height="0.25rem" />
                        <Text as="p" size="f-8">
                            {{ row.copy }}
                        </Text>
                    </div>
                </div>
            </div>
        </template>
    </ScorecardSection>
</template>

<script lang="ts" setup>
import { Scorecard } from '@opteo/types'
import ScorecardSection from './ScorecardSection.vue'
import { computed } from 'vue'
import { getSectionCopy, formatBreakdown } from './utils'

import { Text, Spacer } from '@opteo/components-next'

import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'

const props = defineProps<{
    score: number
    invalid: boolean
    isLoading: boolean
    domainName: string
    mode: 'live' | 'pdf' | 'app'
    details: Scorecard.IntegrationsScoreSection['details']
}>()

const isPdf = props.mode === 'pdf'

const textOptions = {
    0: [
        `Connecting your Google Ads account to external data sources like Analytics, Search Console, and Business Profile can provide a host of benefits, like enriching your reporting data, improving conversion tracking, and boosting your online presence.`,
    ],
    90: [
        `Your Google Ads account is connected to a number of external data sources. These data sources provide additional conversion tracking data, keyword performance data, and reporting data. This data is essential for making informed optimisation decisions and better understanding customer behaviour patterns.`,
    ],
}

const sectionCopy = computed(() => {
    return getSectionCopy(textOptions, props.score ?? 0)
})

const connectionsSubScores = [
    {
        title: 'Google Analytics',
        copy: 'Create a GA4 connection and import conversion actions for deeper insights into the customer cycle.',
        score: props.details.ga4,
    },
    {
        title: 'Search Console',
        copy: 'Google Search Console helps to monitor, maintain, and troubleshoot a site’s presence in search results.',
        score: props.details.searchConsole,
    },
    {
        title: 'Google Business Profile',
        copy: 'Enhance ads for local customers with location extensions, reviews and distance to your business.',
        score: props.details.googleMyBusiness,
    },
    {
        title: 'YouTube',
        copy: 'Show ads to people who visit your channel and get insights about how they interact with your content.',
        score: props.details.youtube,
    },
]
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.sub-scores-container {
    @include container;
    @include br-20;
    @include w-100;
}
.sub-scores-container .item {
    @include ph-24;
    @include pv-20;
    @include flex;
    border-bottom: 1px solid #f7f7f9;
}
.sub-scores-container .item:last-child {
    border-bottom: none;
}
</style>
