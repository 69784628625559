<template>
    <CloseButton class="close" @clicked="$emit('close-full-screen')"></CloseButton>

    <div class="content">
        <PerformanceChartContainer
            :chart-label="chart.label"
            :total="chart.total"
            :delta="chart.delta"
            :current-values="chart.currentValues"
            :noDataAvailable="!chart.chartData.length"
        >
            <span v-if="chart.chartType === ChartType.LINE">
                <LineChart
                    v-if="chart.chartData.length"
                    :series="chart.chartData"
                    :metric="chart.chartMetric"
                    :show-zero="chart.showZero"
                    :horizontal-markers="chart.chartMarkers"
                    disable-fallback
                />
            </span>
            <span v-else-if="chart.chartType === ChartType.AREA">
                <AreaChart
                    v-if="chart.chartData.length"
                    :series="chart.chartData"
                    :metric="chart.chartMetric"
                    :show-zero="chart.showZero"
                    :horizontal-markers="chart.chartMarkers"
                    :max-item="chart.maxItem"
                    disable-fallback
                />
            </span>
        </PerformanceChartContainer>

        <Spacer height="1.5rem" />

        <!-- TODO : add more stats when available-->
        <div v-if="chart.total" class="stats">
            <div v-for="stat in stats" :key="stat.title" class="stat-box">
                <Text size="f-8" align="center">{{ stat.title }} Period</Text>
                <Spacer height="0.25rem" />
                <Text size="f-9" color="gray" align="center">{{ stat.range }}</Text>
                <Spacer height="0.5rem" />
                <Text
                    as="h2"
                    size="f-5"
                    weight="600"
                    style="letter-spacing: -0.045rem"
                    align="center"
                    >{{ stat.total }}</Text
                >
                <Spacer height="0.875rem" />
                <Delta class="flex justify-center" v-bind="stat.delta" :threshold="0.05" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useLayouts } from '@/composition/layouts/useLayouts'
import {
    CloseButton,
    PerformanceChartContainer,
    LineChart,
    AreaChart,
    Spacer,
    Text,
    Delta,
} from '@opteo/components-next'
import { ChartType, Chart } from '@/composition/performance/types'
import { inject, ComputedRef, defineComponent, PropType } from 'vue'
import { ProvideKeys } from '@/composition/useProvide'
import { format } from 'date-fns'

export default defineComponent({
    props: {
        chart: {
            type: Object as PropType<Chart>,
            required: true,
        },
    },
    emits: ['close-full-screen'],
    setup(props) {
        useLayouts('full-screen')

        const startOfStartDate = inject<ComputedRef<Date>>(ProvideKeys.PerformanceStartDate)
        const endStartDate = inject<ComputedRef<Date>>(ProvideKeys.PerformanceEndDate)
        const extendedStartDate = inject<ComputedRef<Date>>(
            ProvideKeys.PerformanceExtendedStartDate
        )

        function formatDateRage(from?: ComputedRef<Date>, to?: ComputedRef<Date>) {
            if (!from || !to) return ''
            return `${format(from.value, 'dd/MM/yyyy')} → ${format(to.value, 'dd/MM/yyyy')}`
        }
        const stats: {
            title: string
            range: string
            total?: string
            delta?: { delta: number; reverse?: boolean }
        }[] = [
            {
                title: 'Current',
                range: formatDateRage(startOfStartDate, endStartDate),
                total: props.chart.total,
                delta: props.chart.delta,
            },
            {
                title: 'Previous',
                range: formatDateRage(extendedStartDate, startOfStartDate),
                total: props.chart.pTotal,
                delta: { delta: 0, reverse: false },
            },
        ]

        return {
            stats,
            ChartType,
        }
    },
    components: {
        CloseButton,
        PerformanceChartContainer,
        LineChart,
        AreaChart,
        Spacer,
        Text,
        Delta,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.close {
    @include fixed;
    top: 1.5rem;
    right: 1.5rem;
}

.content {
    max-width: 48rem;
    margin: 7.25rem auto 7.25rem auto;
}

.stats {
    @include inline-flex;
    justify-content: space-around;
    @include container;
    @include w-100;
    @include ph-32;
    @include pt-40;
    @include pb-44;
}
.stat-box {
    display: grid;
    align-items: center;
}

// Responsive
@media screen and (min-width: 0px) and (max-width: $mq-767-max) {
    .content {
        padding: 0 1.5rem;
        @include mb-64;
    }
    .stats {
        @include block;
    }
    .stat-box:first-child {
        @include mb-40;
    }
}
</style>
