<template>
    <Modal title="Share Feedback" v-model="showFeedbackModal" @closed="feedbackContent = ''">
        <template #content>
            <Text>
                Let us know why you dismissed this improvement and how we could do things better in
                the future. If you have an issue that needs fixing immediately, please
                <a @click="showNewMessage()">message support</a>.
            </Text>
            <Spacer height="1.5rem" />
            <perfect-scrollbar class="dismiss-feedback-location">
                <Text as="h4" class="dismiss-feedback-location-title">
                    {{ dismissedImprovement?.improvementTitle }}
                </Text>

                <div class="flex items-center" style="gap: 0.625rem">
                    <ImprovementLocation
                        v-for="location in dismissedImprovement?.improvementLocation"
                        :key="location.entity"
                        :entity="location.entity"
                        :label="location.label || ''"
                    />
                </div>
            </perfect-scrollbar>
            <Spacer height="2rem" />
            <oForm @submitted="submitFeedback" ref="feedbackForm">
                <fieldset>
                    <Label :fieldset="true">Share Feedback</Label>
                    <oInput
                        v-model="feedbackContent"
                        type="textarea"
                        name="feedback"
                        id="feedback-textarea"
                        class="dismiss-feedback-textarea"
                        autofocus
                    />
                </fieldset>
            </oForm>
        </template>
        <template #buttons>
            <oButton @clicked="showFeedbackModal = false" color="white">Cancel</oButton>
            <Spacer width="0.5rem" />
            <oButton
                @clicked="$refs.feedbackForm.submit()"
                :disabled="feedbackContent.length === 0"
            >
                Share Feedback
            </oButton>
        </template>
    </Modal>
</template>
<script lang="ts">
import {
    oButton,
    Popout,
    SelectArrowIcon,
    Modal,
    Text,
    oForm,
    Spacer,
    oInput,
    Label,
} from '@opteo/components-next'
import { PropType, defineComponent } from 'vue'

import ImprovementBatchIcon from './ImprovementBatchIcon.vue'
import ImprovementLocation from './ImprovementLocation.vue'

import ImprovementRowContainer from '@/components/improvement/ImprovementRowContainer.vue'

import {
    useDismissFeedbackModal,
    DismissFeedbackImprovement,
} from '@/composition/improvement/useDismissFeedbackModal'

export default defineComponent({
    components: {
        ImprovementBatchIcon,
        ImprovementLocation,
        oButton,
        Popout,
        SelectArrowIcon,
        ImprovementRowContainer,
        Modal,
        Text,
        oForm,
        Spacer,
        oInput,
        Label,
    },
    setup() {
        return useDismissFeedbackModal()
    },
})
</script>
<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.dismiss-feedback-location {
    @include container;
    @include flex;
    @include items-center;
    @include ph-24;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.dismiss-feedback-location-title {
    white-space: nowrap;
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
    letter-spacing: -0.0225rem !important;
    font-weight: 600 !important;
    @include mr-20;
}
.dismiss-feedback-textarea textarea {
    min-height: 6rem;
}
</style>
