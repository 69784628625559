import { ref } from 'vue'
import { Routes } from '@/router/routes'
import { useRoute, useRouter } from 'vue-router'
import { Endpoint, noAuthRequest } from '../api/useAPI'
import { useAuth } from './useAuth'
import Cookies from 'universal-cookie'
import { Api, Team, Billing } from '@opteo/types'
import { pushToDatalayer } from '@/lib/datalayer'

const cookies = new Cookies()

export function validatePhoneNumber(inputTelephoneNumber: string) {
    /*
        This regex allows a single optional '+' at the start of the input, followed
        by 6 to 16 numerical digits, allowing no more than one space between each digit.
    */
    if (!inputTelephoneNumber.match(/^\+?\b\d(?: ?\d){5,15}\b$/g)) {
        return 'Please enter a valid telephone number.'
    }
}

export function useCreateAccount() {
    const { setCredentials } = useAuth()
    const route = useRoute()
    const router = useRouter()

    const showConfCodeInput = ref(false)

    const name = ref('')
    const telephoneNumber = ref('')
    const emailAddress = ref('')
    const password = ref('')
    const avatar = ref<Team.Avatar | ''>('')
    const ati = ref(cookies.get('ati'))
    const planId = ref(
        route.query.plan_id ?? cookies.get('chosen_plan_id') ?? Billing.StandardPlan.Basic
    )
    cookies.set('chosen_plan_id', planId.value, { path: '/' })

    const avatarRadioOptions = ref([
        { value: 'agency', label: '<b>Agency</b> (I manage Google Ads for my clients).' },
        { value: 'business', label: '<b>Business</b> (I manage Google Ads for my business).' },
    ])

    const loading = ref(false)
    const error = ref('')

    /*
        Confirmation code steps:
        - user fills out form and clicks submit
        - we call createAccount() with no confirmation code
        - if the email is suspicious, the user will be prompted to input the confirmation code
        - the user will receive an email with a confirmation code
        - the user will input the confirmation code
        - the user clicks submit() again
        - we call createAccount() again with a confirmation code this time
    */

    const verifyConfCode = async ({ confcode }: { confcode: string }) => {
        await createAccount({
            name: name.value,
            telephoneNumber: telephoneNumber.value,
            emailAddress: emailAddress.value,
            password: password.value,
            avatar: avatar.value,
            inputtedConfCode: confcode,
        })
    }

    async function createAccount(inputFields: {
        name: string
        telephoneNumber?: string
        emailAddress: string
        password: string
        avatar?: Team.Avatar | ''
        inputtedConfCode?: string
    }) {
        error.value = ''
        try {
            if (!inputFields.avatar) {
                throw new Error('Please select an account type.')
            }

            loading.value = true

            // Send confirmation code to the user's email. If the email isn't suspicious
            // the confirmation will be returned here instead.
            let confCode: string | null = inputFields.inputtedConfCode ?? ''

            if (!confCode) {
                try {
                    const { data, meta } = await noAuthRequest<
                        | {
                              data: { confCode: string | null }
                              meta: { type: 'result' }
                          }
                        | { data: string; meta: { type: 'error' } }
                    >(`/api/${Endpoint.GetConfirmationCode}`, {
                        meta: {
                            function: 'auth:getConfirmationCode',
                            args: { email: inputFields.emailAddress },
                        },
                    })

                    if (meta.type === 'error') {
                        throw new Error(data as string)
                    }

                    confCode = (data as { confCode: string | null }).confCode as string

                    // no conf code returned, so the user needs to find it in their email and input it in the next step
                    if (!confCode) {
                        showConfCodeInput.value = true
                        return
                    }
                } catch (e: any) {
                    error.value = e.message
                    return
                }
            }

            if (!confCode) {
                throw new Error('confCode undefined, this should never happen.')
            }

            const response = await noAuthRequest<Api.CreateAccount.Response>('/createAccount', {
                name: inputFields.name,
                telephoneNumber: inputFields.telephoneNumber,
                emailAddress: inputFields.emailAddress,
                password: inputFields.password,
                avatar: inputFields.avatar,
                ati: ati.value,
                planId: planId.value,
                accountCreationDomain: window.location.hostname,
                confCode,
                ...(window.Rewardful?.referral && { referral: window.Rewardful.referral }),
                ...(window.Rewardful?.coupon && { coupon: window.Rewardful.coupon.id }),
            })

            if (!response.success) {
                error.value = response.error
                throw new Error(error.value)
            }

            const { user_id, token } = response
            setCredentials(token, user_id)
            router.push({ name: Routes.ConnectGoogleAds, params: { id: user_id } })

            pushToDatalayer('gtm_created_account', {
                user_attributes: {
                    user_id,
                    email: inputFields.emailAddress,
                    role: 'admin',
                    first_name: (inputFields.name ?? '').split(' ')[0],
                    last_name: (inputFields.name ?? '').split(' ')[1],
                },
                team_attributes: {
                    plan_id: planId.value,
                    avatar: inputFields.avatar,
                },
            })

            return
        } catch (err: any) {
            console.log('Sign up error: ', error)
            error.value = err.message
        } finally {
            loading.value = false
        }
    }

    return {
        name,
        telephoneNumber,
        emailAddress,
        password,
        avatar,
        planId,
        avatarRadioOptions,
        createAccount,
        validatePhoneNumber,
        loading,
        error,
        showConfCodeInput,
        verifyConfCode,
    }
}
