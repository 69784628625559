<template>
    <CampaignGroupSection>
        <template v-slot:header-left>
            <div style="margin: 0.125rem 0">
                <Skeleton :width="270" :height="24" />
            </div>
            <Spacer width="0.75rem" />
            <div class="icon">
                <TableEdit />
            </div>
        </template>
        <template v-slot:header-right>
            <!-- Target CPA/ROAS/Profit -->
            <button class="group-panel-button" style="width: 100%">
                <Label class="label" noMargin>Target {{ isUsingCpa ? 'CPA' : 'ROAS' }}</Label>
                <Text as="h6">
                    <Money v-if="isUsingCpa" :value="0" />
                    <Roas v-else :value="0" />
                </Text>
                <div class="icon">
                    <TableEdit />
                </div>
            </button>
            <Spacer width="0.75rem" />
            <button class="group-panel-button" style="width: 100%">
                <Label class="label" noMargin>Conversion Actions</Label>
                <Text as="h6"> 0 Selected </Text>
                <div class="icon">
                    <TableEdit />
                </div>
            </button>
        </template>
        <template v-slot:content>
            <oTable
                :headers="campaignTableHeaders"
                :items="skeletonTableItems"
                :border-radius="16"
                order-by="campaignGroupCost"
                order="DESC"
                fixed-layout
                no-shadow
                :per-page="7"
            >
                <template v-slot:header.campaignName>
                    <oInput type="checkbox" label="Campaigns" class="campaigns-campaign-checkbox" />
                </template>
                <template v-slot:column.campaignName>
                    <div class="flex items-center">
                        <oInput type="checkbox" class="campaigns-campaign-checkbox" />
                        <Spacer width="12px" />
                        <Skeleton :width="200 - Math.random() * 50" :height="16" />
                    </div>
                </template>
                <template v-slot:column.campaignType>
                    <Skeleton :width="48" :height="16" />
                </template>
                <template v-slot:column.campaignStrategy>
                    <Skeleton :width="68" :height="16" />
                </template>
                <template v-slot:column.cost>
                    <Skeleton :width="56" :height="16" />
                </template>
                <template v-slot:column.conversions>
                    <Skeleton :width="28" :height="16" />
                </template>
                <template v-slot:column.conversionsValue>
                    <Skeleton :width="56" :height="16" />
                </template>
                <template v-slot:column.campaignCpa>
                    <Skeleton :width="56" :height="16" />
                </template>
                <template v-slot:column.campaignRoas>
                    <Skeleton :width="56" :height="16" />
                </template>
            </oTable>
        </template>
    </CampaignGroupSection>
</template>

<script lang="ts">
import CampaignGroupSection from './CampaignGroupSection.vue'
import {
    oButton,
    Spacer,
    oInput,
    Label,
    oTable,
    SelectArrowIcon,
    Text,
    TopRightArrow,
    TableEdit,
    Roas,
} from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import { useCampaignGroups } from '@/composition/campaignGroups/useCampaignGroups'
import Money from '@/components/global/oMoney.vue'

export default {
    name: 'CampaignGroupSkeleton',
    components: {
        CampaignGroupSection,
        oButton,
        Spacer,
        oInput,
        Label,
        oTable,
        SelectArrowIcon,
        Skeleton,
        Text,
        TopRightArrow,
        TableEdit,
        Money,
        Roas,
    },
    setup(props) {
        const skeletonTableItems = [
            {
                campaignName: 1,
                campaignType: '',
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                campaignCpa: 0,
                campaignRoas: 0,
            },
            {
                campaignName: 2,
                campaignType: '',
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                campaignCpa: 0,
                campaignRoas: 0,
            },
            {
                campaignName: 3,
                campaignType: '',
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                campaignCpa: 0,
                campaignRoas: 0,
            },
            {
                campaignName: 4,
                campaignType: '',
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                campaignCpa: 0,
                campaignRoas: 0,
            },
            {
                campaignName: 5,
                campaignType: '',
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                campaignCpa: 0,
                campaignRoas: 0,
            },
            {
                campaignName: 6,
                campaignType: '',
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                campaignCpa: 0,
                campaignRoas: 0,
            },
            {
                campaignName: 7,
                campaignType: '',
                cost: 0,
                conversions: 0,
                conversionsValue: 0,
                campaignCpa: 0,
                campaignRoas: 0,
            },
        ]

        return {
            ...useCampaignGroups(),
            skeletonTableItems,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaigns-campaign-checkbox {
    @include pl-24;
    padding-top: 4px;
}
:deep(.o-table-wrapper),
:deep(.o-table-container) {
    @include br-16;
}

// Group Panel Button
.group-panel-button {
    @include container;
    @include bg-opteo-white;
    border-radius: 0.625rem;
    outline: none;
    border: none;
    @include flex-center;
    height: 2.75rem;
    padding: 0 0.9375rem;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    cursor: pointer;
}
.group-panel-button:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}

.group-panel-button:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}
.group-panel-button h6 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: -0.025rem;
    transform: translateY(-1px);
    margin-left: 0.625rem;
    margin-right: 0.75rem;
    white-space: nowrap;
}
.icon {
    @include container;
    width: 1.5rem;
    height: 1.5rem;
    @include br-999;
    @include flex-center;
}
</style>
