import { computed, ref, Ref } from 'vue'
import clone from 'lodash-es/clone'
import some from 'lodash-es/some'
import {
    OnPushHandler,
    useImprovement,
    UseImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

interface AddMissingCampaignsToSharedSetBody {
    campaign_id: string
    campaign_label: string
    primary_shared_set_id: string
    primary_shared_set: {
        shared_set_id: string
        reference_count: number
        shared_set_name: string
        state: string
    }
    all_shared_sets: SharedSet[]
}

interface SharedSet {
    shared_set_id: string
    reference_count: number
    shared_set_name: string
    state: string
    selected?: boolean
}

interface UseAddMissingCampaignsToSharedSet {
    improvement: Ref<AddMissingCampaignsToSharedSetBody> | Ref
    campaignName: string
    error: Ref<string>
    primarySharedSet: SharedSet
    sharedSets: Ref<SharedSet[]>
    resetSelection: () => void
}

export function useAddMissingCampaignsToSharedSet(): UseImprovement<UseAddMissingCampaignsToSharedSet> {
    const sharedSets = ref()
    const error = ref()
    const { improvement, lastUpdated } = useImprovement<AddMissingCampaignsToSharedSetBody>()

    const {
        body: { all_shared_sets, campaign_label, primary_shared_set, campaign_id },
    } = checkImprovement<AddMissingCampaignsToSharedSetBody>(improvement)

    const campaignName = campaign_label
    const primarySharedSet = primary_shared_set
    sharedSets.value = clone(all_shared_sets)

    sharedSets.value.forEach((sharedSet: SharedSet) => {
        console.log(sharedSets.value.filter((s: SharedSet) => s.selected))
        sharedSet.selected = sharedSet.shared_set_id === primary_shared_set.shared_set_id
    })

    const title = computed(() => {
        return sharedSets.value.filter((s: SharedSet) => s.selected).length > 1
            ? 'Apply Negative Lists'
            : 'Apply Negative List'
    })

    const pushActionText = ref(
        title
        /*sharedSets.value.filter((s: SharedSet) => s.selected).length > 1
            ? 'Apply Negative Lists'
            : 'Apply Negative List'*/
    )

    function resetSelection() {
        console.log('selection should be reset')
        sharedSets.value.forEach((sharedSet: SharedSet) => {
            sharedSet.selected = sharedSet.shared_set_id === primary_shared_set.shared_set_id
        })
    }

    const onPush: OnPushHandler<{ campaign_id: string; shared_sets: SharedSet[] } | {}> = () => {
        const valid = validate()

        const setsSelected: SharedSet[] = sharedSets.value.filter((s: SharedSet) => s.selected)

        if (
            setsSelected.length === 1 &&
            setsSelected[0].shared_set_id === primary_shared_set.shared_set_id
        ) {
            return { valid }
        }

        const pushedData = {
            campaign_id: campaign_id,
            shared_sets: sharedSets.value,
        }

        return {
            valid,
            pushedData,
        }
    }

    function validate() {
        error.value = null
        const sharedSetSelected = some(sharedSets.value, 'selected')

        if (!sharedSetSelected) {
            error.value = 'Please select at least one negative list.'
            return false
        }
        return true
    }

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Finding campaign and lists..',
        `Confirming changes..`,
        `Negative list(s) applied successfully.`,
    ])

    return {
        improvement,
        lastUpdated,
        title,
        pushMessages,
        sharedSets,
        primarySharedSet,
        campaignName,
        onPush,
        error,
        pushActionText,
        resetSelection,
    }
}
