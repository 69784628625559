import { Improvement } from '@opteo/types'

import {
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { computed, ref } from 'vue'
interface MissingCampaign {
    id: number
    name: string
    advertising_channel_sub_type: number
    resource_name: string
}

interface Body {
    exclusion_list_id: string
    exclusion_list_name: string
    missing_campaigns: MissingCampaign[]
    placement_types: {
        urls: string[]
        counts: {
            [key: string]: number
        }
    }
}

interface UseResyncExclusionLists {
    exclusionListName: string
    campaignNames: string[]
    entityPill: {
        type: Improvement.LocationEntity
        content: string
    }
}

export function useResyncExclusionLists(): UseImprovement<UseResyncExclusionLists> {
    const { improvement, lastUpdated, title } = useImprovement<Body>()

    const { body } = checkImprovement<Body>(improvement)

    const { missing_campaigns: campaigns, exclusion_list_name: exclusionListName } = body

    const campaignNames = campaigns.map(campaign => campaign.name)

    const pushActionText = ref(campaigns.length === 1 ? 'Sync Campaign' : 'Sync Campaigns')

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Applying placement exclusion list..',
        'Confirming changes..',
        'Exclusion list applied successfully.',
    ])

    const entityPill = {
        type: Improvement.LocationEntity.PlacementExclusionList,
        content: exclusionListName,
    }

    return {
        title,
        pushMessages,
        campaignNames,
        exclusionListName,
        lastUpdated,
        entityPill,
        pushActionText,
    }
}
