import { addMinutes } from 'date-fns'

/**
 *  @name adaptDateToTimezone
 *  @description Gets the offset of the local browser time from UTC and adds
        that many minutes to the time. That way no matter where a user
        is in the world they will see the same data. Test this by going
        to Chrome > More Tools > Sensors > Location.
 */
export function adaptDateToTimezone(date: Date): Date {
    // Gets the offset of the local browser time from UTC and adds
    // that many minutes to the time. That way no matter where a user
    // is in the world they will see the same data. Test this by going
    // to Chrome > More Tools > Sensors > Location.
    const timezoneOffset = date.getTimezoneOffset()

    return addMinutes(date, timezoneOffset)
}
