<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill :type="entityPill.type" :content="entityPill.content" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    In this campaign,
                    <span v-if="searchTermCount > 1">
                        <b>{{ searchTermCount }}</b> search terms
                    </span>
                    <span v-else>a search term</span>
                    containing the location name listed below
                    <span v-if="searchTermCount > 1"> have</span>
                    <span v-else> has</span> been triggering your ads. This campaign does not
                    currently target this location, so this traffic may be irrelevant. Consider
                    adding this location name as a negative.
                    <Spacer height="2rem" />
                    <EntityPillList
                        v-if="!aboveMobile && locationName.length > 30"
                        :items="[{ type: 'location', content: locationName }]"
                    />
                    <EntityPill v-else :content="locationName" type="location" />
                </Text>
                <Spacer height="2rem" />
                <Text as="p">
                    Click <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    add this location as a negative keyword for this campaign. This will prevent ads
                    from showing when <Ent>{{ locationName }}</Ent> appears in search queries.
                    Pushing this improvement will result in a saving of
                    <b><Money :value="cost" /></b>, with
                    <!-- impressions copy -->
                    <span v-if="impressions === 0">no loss in {{ copy.impressions }},</span>
                    <span v-else>
                        <b><Number :value="impressions" /></b> fewer {{ copy.impressions }},
                    </span>
                    <!-- clicks copy -->
                    <span v-if="clicks === 0"> no loss in {{ copy.clicks }}, and</span>
                    <span v-else>
                        <b><Number :value="clicks" /></b> fewer {{ copy.clicks }}, and
                    </span>

                    <!-- no conversions copy -->
                    <span v-if="isUsingCpa && conversions === 0">
                        no loss in {{ copy.conversions }}.</span
                    >

                    <!-- no conversion value copy -->
                    <span v-else-if="!isUsingCpa && conversionsValue === 0">
                        no loss in conversion value.</span
                    >

                    <!-- conversions copy -->
                    <span v-else-if="isUsingCpa">
                        <b><Number :value="conversions" /></b> fewer {{ copy.conversions }}.
                    </span>

                    <!-- conversion value copy -->
                    <span v-else>
                        a <b><Money :value="conversionsValue" /></b> loss in conversion value.
                    </span>
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2370971-add-location-name-as-negative-keyword">
                Click here to learn more about adding location names as negatives.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="p">
                The following locations are currently being targeted by this campaign:
            </Text>
            <div v-if="targetedLocationsCount > 2">
                <Spacer height="2rem" />
                <EntityPillList :items="targetedLocationNames" />
            </div>
            <div v-else>
                <Spacer height="2rem" />
                <div class="location-pill-list-container">
                    <div
                        v-for="(location, index) in targetedLocationNames"
                        :key="'location-' + index"
                        class="location-pill"
                    >
                        <EntityPill :content="location.content" :type="location.type" />
                    </div>
                </div>
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <RelatedSearchTerms :items="searchTerms" :keyword="locationName"> </RelatedSearchTerms>
        </ImprovementContent>

        <ImprovementContent v-if="isUsingCpa && cpa > 0">
            <ImprovementStatistics :items="cpaImprovementStats">
                <template v-slot:cpa>
                    <span v-if="searchTermCount > 1">
                        Search terms containing this location have a CPA of
                        <b><Money :value="cpa" /></b>.
                    </span>
                    <span v-else
                        >The search term containing this location has a CPA of
                        <b><Money :value="cpa" /></b>.</span
                    >
                </template>
                <template v-slot:groupTargetCpa>
                    <span v-if="searchTermCount > 1">
                        The parent campaign group has
                        {{ autoTarget ? 'an average' : 'a target' }} CPA of
                        <b><Money :value="targetCpa" /></b>. This means search terms containing this
                        location cost <b><Percent :value="cpaDifference" /></b> more to convert than
                        the campaign group {{ autoTarget ? 'average' : 'target' }}.
                    </span>
                    <span v-else>
                        The parent campaign group has
                        {{ autoTarget ? 'an average' : 'a target' }} CPA of
                        <b><Money :value="targetCpa" /></b>. This means the search term containing
                        this location costs <b><Percent :value="cpaDifference" /></b> more to
                        convert than the campaign group {{ autoTarget ? 'average' : 'target' }}.
                    </span>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent v-if="!isUsingCpa && roas > 0">
            <ImprovementStatistics :items="roasImprovementStats">
                <template v-slot:roas>
                    <span v-if="searchTermCount > 1">
                        Search terms containing this location have a ROAS of
                        <b><Roas :value="roas" /></b>.
                    </span>
                    <span v-else>
                        The search term containing this location has a ROAS of
                        <b><Roas :value="roas" /></b>.
                    </span>
                </template>
                <template v-slot:groupTargetRoas>
                    <span v-if="searchTermCount > 1">
                        The parent campaign group has
                        {{ autoTarget ? 'an average' : 'a target' }} ROAS of
                        <b><Roas :value="targetRoas" /></b>. This means search terms containing this
                        location have a <b><Percent :value="roasDifference" stripped /></b> lower
                        ROAS than the campaign group {{ autoTarget ? 'average' : 'target' }}.
                    </span>
                    <span v-else>
                        The parent campaign group has
                        {{ autoTarget ? 'an average' : 'a target' }} ROAS of
                        <b><Roas :value="targetRoas" /></b>. This means the search term containing
                        this location has a
                        <b><Percent :value="roasDifference" stripped /></b> lower ROAS than the
                        campaign group {{ autoTarget ? 'average' : 'target' }}.
                    </span>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Select Negative List">
                    <div v-if="aboveMobile">
                        <EntityPill :content="campaignName" type="campaign" />
                        <Spacer height="2rem" />
                    </div>
                    <Text as="p">
                        By default, Opteo will add the negative keyword to the campaign listed
                        above. If you need to add it to a shared negative list instead, please
                        select it from the menu below.
                    </Text>
                </ImprovementHeader>
                <div class="adjust-negative-geo-list">
                    <oInput
                        v-model="selectedSharedSet"
                        :items="sharedSets"
                        type="select"
                        label="Shared Negative List"
                    />
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
    EntityPillList,
    EntityPill,
    oButton,
    oInput,
    Percent,
    Number,
    Spacer,
    Text,
    Roas,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import RelatedSearchTerms from '@/components/improvement/shared-components/RelatedSearchTerms.vue'
import Money from '@/components/global/oMoney.vue'
import Ent from '@/components/util/Ent.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAddNegativeGeoKeyword } from '@/composition/improvement/types/useAddNegativeGeoKeyword'

export default defineComponent({
    name: 'AddNegativeGeoKeyword',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        EntityPillList,
        EntityPill,
        oButton,
        oInput,
        Ent,
        Money,
        Percent,
        Number,
        Spacer,
        Text,
        RelatedSearchTerms,
        Roas,
    },
    setup() {
        return { ...useAddNegativeGeoKeyword(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.adjust-negative-geo-list {
    @include container;
    @include pa-32;
}
.location-pill-list-container {
    @include flex;
    @include items-center;
}
.location-pill {
    margin-left: 0.625rem;
}
.location-pill:first-child {
    @include ml-0;
}
@media screen and (max-width: $mq-767-max) {
    .adjust-negative-geo-list {
        @include pa-24;
    }
}
</style>
