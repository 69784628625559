import { inject } from 'vue'
import { useRoute } from 'vue-router'

import { UseReportExports } from '@/composition/reports/provideReport'

export function useReport() {
    const { params } = useRoute()

    const injected = inject<UseReportExports>('report:' + params.reportId)

    if (!injected) {
        throw new Error(
            'Report not yet injected, something is wrong. useReport() can only be called in a /:reportId route.'
        )
    }
    return injected
}
