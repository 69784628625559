import { computed } from 'vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

export function useTopNavBar() {
    const { aboveMobile } = useMediaQuery()
    const showDomainNavBar = computed(() => aboveMobile.value)

    return {
        showDomainNavBar,
    }
}
