export const getAccentColor = (color: string) => {
    let color2 = '#ffffff'
    const percentage = 0.35

    if (color.length === 4) {
        color = color[1] + color[1] + color[2] + color[2] + color[3] + color[3]
    } else {
        color = color.substring(1)
    }

    color2 = color2.substring(1)

    const colorArr = [
        parseInt(color[0] + color[1], 16),
        parseInt(color[2] + color[3], 16),
        parseInt(color[4] + color[5], 16),
    ]

    const colorArr2 = [
        parseInt(color2[0] + color2[1], 16),
        parseInt(color2[2] + color2[3], 16),
        parseInt(color2[4] + color2[5], 16),
    ]

    const color3 = [
        (1 - percentage) * colorArr[0] + percentage * colorArr2[0],
        (1 - percentage) * colorArr[1] + percentage * colorArr2[1],
        (1 - percentage) * colorArr[2] + percentage * colorArr2[2],
    ]

    return '#' + intToHex(color3[0]) + intToHex(color3[1]) + intToHex(color3[2])
}

const intToHex = (num: number) => {
    let hex = Math.round(num).toString(16)
    if (hex.length === 1) {
        hex = '0' + hex
    }
    return hex
}
