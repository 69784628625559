<template>
    <oForm
        @submitted="updatePerformanceGoals"
        ref="budgetForm"
        class="account-performance-goals-form"
    >
        <div class="section-note">
            <Text as="p" size="f-8" color="dark-blue">
                Set an account-level goal to track your progress. Please note, performance goals use
                the conversion actions you selected above to track and count conversions.
            </Text>
        </div>

        <Spacer height="1.5rem" />

        <oInput
            :model-value="accountPerformanceGoals?.primaryGoal"
            :items="primaryGoalItems"
            name="primaryGoal"
            type="select"
            label="Primary Goal"
            @updated="handlePrimaryGoalUpdate"
        />

        <Spacer height="1.5rem" />

        <div class="performance-goal-info">
            <oInput
                :model-value="accountPerformanceGoals?.goalCycle"
                :items="goalCycleItems"
                name="primaryConversionAction"
                type="select"
                label="Goal Cycle"
                @updated="goalCycle = $event"
            />
            <oInput
                ref="goalAmountInput"
                :model-value="goalAmount ?? accountPerformanceGoals?.goalAmount"
                name="goalAmount"
                type="number"
                label="Goal Amount"
                :prefix="primaryGoal === 1 ? goalMetric : null"
                :suffix="primaryGoal === 0 ? goalMetric : null"
                :minmum="0"
                :step="1"
                @updated="goalAmount = $event"
            />
        </div>

        <Spacer height="1.5rem" />
        <div class="divider" />
        <Spacer height="1.5rem" />

        <oInput
            :model-value="accountPerformanceGoals?.secondaryGoal"
            :items="secondaryGoalItems"
            name="secondaryGoal"
            type="select"
            label="Secondary Goal"
            @updated="secondaryGoal = $event"
        />

        <div v-if="showUpdateButton" class="account-budget-button-container">
            <oButton
                ref="updatePerformanceGoalsButton"
                :loading="updatePerformanceGoalsLoading"
                size="medium"
                type="submit"
            >
                Update Performance Goals
            </oButton>
        </div>
    </oForm>
</template>

<script lang="ts" setup>
import { useAccount } from '@/composition/account/useAccount'
import { Endpoint, authRequest, useAPI } from '@/composition/api/useAPI'
import { Text, Spacer, oInput, oForm, oButton } from '@opteo/components-next'
import { Targets } from '@opteo/types'
import { computed, ref } from 'vue'

defineProps<{
    showUpdateButton?: boolean
}>()

const { accountId, currencySymbol } = useAccount()

const { data: accountPerformanceGoals, mutate: mutateAccountPerformanceGoals } =
    useAPI<Targets.AccountPerformanceGoals>(Endpoint.GetAccountPerformanceGoals, {
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
        body: () => {
            return {
                accountId: accountId.value,
            }
        },
    })

// Set default values
const primaryGoal = ref(accountPerformanceGoals.value?.primaryGoal)

const goalAmount = ref(accountPerformanceGoals.value?.goalAmount)
const goalCycle = ref(accountPerformanceGoals.value?.goalCycle)
const secondaryGoal = ref(accountPerformanceGoals.value?.secondaryGoal)

const goalAmountInput = ref()

// These objects are typed check, if we add more goals to the enum, they will error out
const primaryGoalNameMap: { [goal in Targets.PrimaryPerformanceGoal]: string } = {
    [Targets.PrimaryPerformanceGoal.CONVERSIONS]: 'Conversions',
    [Targets.PrimaryPerformanceGoal.CONVERSION_VALUE]: 'Conversion Value',
}

const secondaryGoalNameMap: { [goal in Targets.SecondaryPerformanceGoal]: string } = {
    [Targets.SecondaryPerformanceGoal.IMPROVE_CTR]: 'Improve Click Through Rate',
    [Targets.SecondaryPerformanceGoal.IMPROVE_SIS]: 'Improve Search Impression Share',
    [Targets.SecondaryPerformanceGoal.IMPROVE_AVG_QS]: 'Improve Average Quality Score',
    [Targets.SecondaryPerformanceGoal.REDUCE_CPC]: 'Reduce Cost Per Click',
}

const primaryGoalMetricMap = computed<{ [goal in Targets.PrimaryPerformanceGoal]: string }>(() => ({
    [Targets.PrimaryPerformanceGoal.CONVERSIONS]: 'Conv.',
    [Targets.PrimaryPerformanceGoal.CONVERSION_VALUE]: currencySymbol.value,
}))

const goalCycleNameMap: { [goal in Targets.PerformanceGoalCycle]: string } = {
    [Targets.PerformanceGoalCycle.DAY]: 'Daily',
    [Targets.PerformanceGoalCycle.WEEK]: 'Weekly',
    [Targets.PerformanceGoalCycle.MONTH]: 'Monthly',
    [Targets.PerformanceGoalCycle.QUARTER]: 'Quarterly',
    [Targets.PerformanceGoalCycle.YEAR]: 'Yearly',
}

const goalMetric = computed(() => {
    if (typeof primaryGoal.value === 'undefined') return 'Conv.'

    return primaryGoalMetricMap.value[primaryGoal.value]
})

const primaryGoals: Targets.PrimaryPerformanceGoal[] = Object.keys(primaryGoalNameMap).map(Number)

const primaryGoalItems = computed(() =>
    primaryGoals.map(goal => {
        return {
            label: primaryGoalNameMap[goal],
            value: goal,
        }
    })
)

function handlePrimaryGoalUpdate(newGoal: Targets.PrimaryPerformanceGoal) {
    primaryGoal.value = newGoal

    goalAmount.value = undefined
}

const secondaryGoals: Targets.SecondaryPerformanceGoal[] =
    Object.keys(secondaryGoalNameMap).map(Number)

const secondaryGoalItems = computed(() =>
    secondaryGoals.map(goal => {
        return {
            label: secondaryGoalNameMap[goal],
            value: goal,
        }
    })
)

const goalCycles: Targets.PerformanceGoalCycle[] = Object.keys(goalCycleNameMap).map(Number)

const goalCycleItems = computed(() =>
    goalCycles.map(goalCycle => {
        return {
            label: goalCycleNameMap[goalCycle],
            value: goalCycle,
        }
    })
)

const updatePerformanceGoalsLoading = ref(false)
const updatePerformanceGoalsButton = ref()

async function updatePerformanceGoals() {
    if (
        primaryGoal.value === accountPerformanceGoals.value?.primaryGoal &&
        goalAmount.value === accountPerformanceGoals.value?.goalAmount &&
        goalCycle.value === accountPerformanceGoals.value?.goalCycle &&
        secondaryGoal.value === accountPerformanceGoals.value?.secondaryGoal
    ) {
        return
    }

    if (!goalAmountInput.value?.valid) {
        throw new Error('Invalid goal amount')
    }

    updatePerformanceGoalsLoading.value = true

    await authRequest(Endpoint.UpdateAccountPerformanceGoals, {
        body: {
            account_id: accountId.value,
            performance_goals: {
                primaryGoal: primaryGoal.value,
                goalAmount: goalAmount.value,
                goalCycle: goalCycle.value,
                secondaryGoal: secondaryGoal.value,
            },
        },
    })

    await mutateAccountPerformanceGoals()

    updatePerformanceGoalsLoading.value = false
    updatePerformanceGoalsButton.value?.flashSuccess()
}

defineExpose({
    updatePerformanceGoals,
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.section-content .account-performance-goals-form,
.settings-section .account-performance-goals-form {
    @include pa-32;
}

.account-budget-button-container {
    @include flex-center;
    @include ph-32;
    @include pt-32;
}

.performance-goal-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.account-performance-goals-form .divider {
    width: 100%;
    height: 1px;
    @include bg-opteo-light-gray;
}
</style>
