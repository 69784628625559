<template>
    <svg width="64" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="a"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="64"
            height="32"
        >
            <rect width="64" height="32" rx="4" fill="#C4C4C4" />
        </mask>
        <g mask="url(#a)">
            <path
                d="M69 33h1V17.26c0-3.1198-2.0647-5.8631-5.0627-6.7266l-6.0891-1.75374c-1.9094-.54991-3.9625-.2625-5.6474.79058l-2.2421 1.40126c-1.752 1.0951-3.9971.9997-5.65-.2399l-.2921-.2191-.6.8.6-.8c-2.9125-2.18439-7.0131-1.7716-9.4318.9494l-1.918 2.1577c-1.2366 1.3913-3.1438 1.9804-4.9497 1.5289l-2.662-.6655c-1.3772-.3443-2.8265-.2617-4.1556.2367l-4.5012 1.6879c-.9494.3561-1.9846.415-2.9683.1691l-4.89679-1.2242c-.99181-.2479-2.0259-.2756-3.02956-.0811l-8.83546 1.7123C-5.62306 17.6215-8 20.5033-8 23.8558V33h77Z"
                fill="#F0F0F5"
                fill-opacity=".4"
                stroke="#E0E0E5"
                stroke-width="2"
            />
        </g>
    </svg>
</template>
