<template>
    <div class="rsa-recommended-search-terms">
        <header>
            <div class="left">
                <Text as="h5" weight="600">Campaign Search Terms</Text>
            </div>
            <div class="header-slot">
                <slot name="header"></slot>
            </div>
        </header>
        <Spacer height="1.75rem" />
        <div v-if="itemsWithMetrics.length" class="search-terms-table">
            <oTable
                :headers="headers"
                :items="itemsWithMetrics"
                :borderRadius="20"
                :per-page="5"
                :fixedLayout="true"
                order-by="impressions"
                order="DESC"
            >
                <!-- Search Term -->
                <template v-slot:column.text="cell">
                    <CopyableString :value="cell.value" :maxWidth="200" />
                </template>
                <!-- Impressions -->
                <template v-slot:column.impressions="cell">
                    <Number :value="cell.value" />
                </template>
                <!-- CTR -->
                <template v-slot:column.ctr="cell">
                    <Percent :value="cell.value" />
                </template>
                <!-- Conversions -->
                <template v-slot:column.conversions="cell">
                    <Number :value="cell.value" />
                </template>
                <!-- Conversions Value -->
                <template v-slot:column.conversionsValue="cell">
                    <Money :value="cell.value" :currency="currencyCode" />
                </template>
                <!-- Status -->
                <template v-slot:column.includedInRsa="cell">
                    <RsaRecommendedSearchTermsPopout :status="cell.value" />
                </template>
            </oTable>
        </div>
        <div v-else class="no-search-terms">
            <slot name="empty-state"></slot>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { RsaWriter } from '@opteo/types'
import { CurrencyCode } from '@opteo/types/currency'

export interface Header {
    key: string
    text: string
    sortable?: boolean
    sortTransform?: (value: any) => number | string
    editable?: boolean
    currency?: string
    // TODO: removePadding, noPadding and vHeaderPadding should be more clearly named or
    // have better documentation
    removePadding?: boolean
    noPadding?: boolean
    vHeaderPadding?: string
    width?: number
    color?: 'red' | 'green'
}

import { Text, Spacer, oTable, Number, Percent, Money, CopyableString } from '@opteo/components-next'

import RsaRecommendedSearchTermsPopout from './RsaRecommendedSearchTermsPopout.vue'



const props = defineProps<{
    headers: Header[]
    items: RsaWriter.OTableSearchTermRow[]
    currencyCode: CurrencyCode
}>()

const itemsWithMetrics = computed(() =>
    props.items.map((item) => {
        return {
            ...item,
            impressions: item.metrics.impressions,
            ctr: item.metrics.impressions ? item.metrics.clicks / item.metrics.impressions : 0,
            conversions: item.metrics.conversions,
            conversionsValue: item.metrics.conversionsValue,
        }
    }),
)
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.rsa-recommended-search-terms {
    @include container;
    @include br-24;
    @include pt-28;
    @include ph-32;
    @include pb-32;
    // max-width: 55rem;
    margin: 0 auto;
    @include w-100;
}
.rsa-recommended-search-terms header {
    @include flex;
    @include items-center;
    @include justify-between;
    height: 1.75rem;
}
.rsa-recommended-search-terms header .left,
.rsa-recommended-search-terms header .header-slot {
    @include flex;
    @include items-center;
}
.rsa-recommended-search-terms header .header-slot {
    opacity: 0.24;
}
.rsa-recommended-search-terms .search-terms-table {
    // max-width: 47.25rem;
    margin: 0 auto;
}

.rsa-recommended-search-terms .no-search-terms {
    @include container;
    @include pa-24;
}
</style>
