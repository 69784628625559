<template>
    <ImprovementView
        :pushMessages="pushMessages"
        :pushActionText="pushAction"
        :isAdjustable="true"
        :onPush="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <EntityPillList
                    :items="[
                        { type: 'campaign', content: 'campaignName' },
                        { type: 'adGroup', content: 'adGroupName' },
                    ]"
                />
                <Spacer height="2rem" />
                <Text as="p">
                    This is some text with an <Ent>Ent</Ent> and the number
                    <b><Number :value="42.99" /></b>. Here is some money
                    <b><Money :value="12.99" /></b>. Click
                    <Text as="p" color="green" weight="600">Do Something</Text> to make a change.
                    Click <b>Adjust</b> to change the change. In the
                    <b>ImprovementHeader</b> component, if placing content above the
                    <span class="rainbow">main paragraph</span>, stick to <b>EntityPill</b> and
                    <b>EntityPillList</b> to ensure paragraph is clearly visible.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/902970-adjust-keyword-bid">
                Learn how modifying keyword bids can help lower overall CPA here.
            </ImprovementHelpLink>
        </ImprovementContent>
        <ImprovementContent>
            <oTable
                :headers="[
                    { key: 'campaign', text: 'Campaign', sortable: true },
                    { key: 'cost', text: 'Cost', sortable: true },
                    { key: 'conversions', text: 'Conv.', sortable: true },
                    { key: 'ctr', text: 'CTR', sortable: true },
                ]"
                :items="[
                    {
                        id: 'itemOne',
                        campaign: 'Campaign A',
                        cost: 4703.09,
                        conversions: 88,
                        ctr: 0.439,
                    },
                    {
                        id: 'itemTwo',
                        campaign: 'Campaign B',
                        cost: 3829.35,
                        conversions: 66,
                        ctr: 0.001222,
                    },
                    {
                        id: 'itemThree',
                        campaign: 'Campaign C',
                        cost: 2218.48,
                        conversions: 44,
                        ctr: 1,
                    },
                ]"
                order-by="conversions"
                order="DESC"
            >
                <!-- Cost column -->
                <template v-slot:column.cost="cost">
                    <Money :value="cost.value" />
                </template>
                <!-- CTR column -->
                <template v-slot:column.ctr="ctr">
                    <Percent :value="ctr.value" />
                </template>
            </oTable>
        </ImprovementContent>
        <ImprovementContent>
            <ImprovementStatistics
                :items="[
                    {
                        key: 'impressions',
                        value: '556,928',
                        title: 'Impressions',
                    },
                    {
                        key: 'cost',
                        deltaMode: true,
                        beforeValue: '£1052.00',
                        beforeTitle: 'Current Cost',
                        afterValue: '£1252.00',
                        afterTitle: 'Updated Cost',
                    },
                ]"
            >
                <template v-slot:impressions>
                    Ads in this campaign received <b>556,928</b> impressions over the last
                    <b>60</b> days.
                </template>
                <template v-slot:cost>
                    Cost should increase from <b>£1052.00</b> to <b>£1252.00</b> over the next
                    <b>30</b> days.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="60" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Adjust Step One" />
                <div class="adjustment-container">
                    <Text as="p">
                        Content in adjust steps should be contained within a shadow container. Make
                        sure copy is typeset appropriately, adjust for
                        <a
                            href="https://barbarakristaponis.files.wordpress.com/2015/11/typedesignquote2-e1447789973739.png"
                            rel="noopener"
                            target="_blank"
                            >orphans and widows</a
                        >,
                        <a
                            href="https://cloud.netlifyusercontent.com/assets/344dbf88-fdf9-42bb-adb4-46f01eedd629/1e15119e-7a94-49be-8263-664c711ca490/image8.jpg"
                            rel="noopener"
                            target="_blank"
                            >alignment</a
                        >, where possible. Add autofocus attributes to inputs where appropriate.
                    </Text>
                    <Spacer height="1.25rem" />
                    <oInput type="number" suffix="%" class="adjustment-input" autofocus />
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import {
    Percent,
    oInput,
    oTable,
    Number,
    EntityPillList,
    Spacer,
    Text,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Ent from '@/components/util/Ent.vue'
import Money from '@/components/global/oMoney.vue'

import { useDemoImprovement } from '@/composition/improvement/types/useDemoImprovement'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'DemoImprovement',
    components: {
        ImprovementHelpLink,
        Money,
        Percent,
        ImprovementView,
        oInput,
        oTable,
        Ent,
        ImprovementBidAdjustment,
        Number,
        EntityPillList,
        Spacer,
        Text,
    },
    setup() {
        return useDemoImprovement()
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Base

.rainbow {
    @include fw-600;
    background: -webkit-linear-gradient(90deg, #95d7e3, #eb76ff);
    background-size: 600vw 600vw;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbowText 5s linear infinite alternate;
}

// Adjustment

.adjustment-container {
    @include container;
    @include pa-24;
}
.adjustment-input {
    width: 12rem;
}

// Responsive

@media (min-width: $mq-321-min) and (max-width: $mq-375-max) {
    .adjustment-input {
        width: 100%;
    }
}

// Keyframes

@keyframes rainbowText {
    from {
        filter: hue-rotate(0deg);
        background-position-x: 0%;
    }
    to {
        filter: hue-rotate(360deg);
        background-position-x: 600vw;
    }
}
</style>
