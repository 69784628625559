import { computed, Ref } from 'vue'
import formatISO from 'date-fns/formatISO'
import upperCase from 'lodash-es/upperCase'

import { useRouter } from 'vue-router'
import { Account, Domain } from '@opteo/types'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'
import { useAccountList } from '../user/useAccountList'
import { Platform } from '@opteo/types'

export type UseAccount = ReturnType<typeof useAccount>

export function useAccount(_accountId?: Ref<string>) {
    const { currentRoute } = useRouter()

    const { mutateDomainList } = useAccountList()

    const accountId = computed<Account.ID>(
        () => (_accountId?.value ?? (currentRoute.value.params.accountId as string)) as Account.ID
    )

    const {
        data: accountInfo,
        loading,
        error,
        mutate,
    } = useAPI<Account.Info>(Endpoint.GetAccountInfo, {
        body: () => ({ account_id: accountId.value }),
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const accountName = computed(() => accountInfo.value?.name ?? '')
    const accountInitials = computed(() => accountInfo.value?.name.slice(0, 2).toUpperCase())

    const accountColor = computed(() => accountInfo.value?.color)
    const accountShadowColor = computed(() => `${accountInfo.value?.color}1a`)

    const currencyCode = computed(() => accountInfo.value?.currencyCode)
    const currencySymbol = computed(() => accountInfo.value?.currencySymbol)
    const accountPlatform = computed(() => accountInfo?.value?.platform)
    const performanceMode = computed(() => accountInfo?.value?.performanceMode)

    const domainId = computed<Domain.ID | undefined>(() => {
        if (accountPlatform.value === Platform.Platform.GoogleAds && accountInfo.value) {
            if (typeof accountInfo.value.id === 'string') {
                throw new Error('accountInfo.value.id must be a number when platform is GoogleAds')
            }
            return accountInfo.value.id as Domain.ID
        }
        return undefined
    })

    const mutateAccount = (updatedAccount?: Partial<Account.Info>) => {
        if (typeof updatedAccount === 'undefined') {
            mutate()
            return
        }

        // @ts-ignore fix this later on
        mutate(() => {
            if (typeof accountInfo.value === 'undefined') {
                throw new Error('accountInfo.value must be defined before mutating')
            }
            return { ...accountInfo.value, ...updatedAccount }
        })
    }

    const trackAccountVisit = async () => {
        const lastVisited = formatISO(new Date())

        mutateAccount({ lastVisitedAt: lastVisited })
        mutateDomainList(accountId.value, { lastVisitedAt: lastVisited })

        await authRequest(Endpoint.TrackAccountVisit, {
            body: () => ({ account_id: accountId.value }),
        })
    }

    return {
        accountId,
        accountName,
        accountInitials,
        accountColor,
        accountShadowColor,
        currencyCode,
        currencySymbol,
        accountInfo,
        loading,
        error,
        mutateAccount,
        accountPlatform,
        trackAccountVisit,
        performanceMode,
        domainId, // Used for in a few places for legacy reasons. Avoid.
    }
}

export function getAccountInitials(domainName: string) {
    return upperCase(domainName ? domainName.slice(0, 2) : '')
}
