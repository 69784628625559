<template>
    <transition name="notification-fade" appear>
        <div
            v-if="unreadCount > 0"
            class="alerts-notification-badge"
            :class="{ 'over-99': unreadCount > 99 }"
        >
            <span class="alerts-notification-badge-span no-select"> {{ unreadCount }} </span>
        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAlerts } from '@/composition/alerts/useAlerts'

export default defineComponent({
    name: 'AlertsCounter',

    setup() {
        const { unreadCount } = useAlerts()

        return {
            unreadCount,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/theme.scss';

.alerts-notification-badge {
    @include opteo-white;
    @include bg-opteo-red;
    @include flex-center;
    height: 1.375rem;
    width: 1.375rem;
    border-radius: 999rem;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

.alerts-notification-badge.over-99 {
    height: 1.25rem;
    width: auto;
    padding: 0 0.4375rem;
}

span.alerts-notification-badge-span {
    font-size: 0.625rem;
    letter-spacing: -0.015rem;
    line-height: 0.75rem;
    @include fw-500;
    @include block;
    @include opteo-white;
    transform: translateY(-0.5px) !important;
}
</style>
