import '@/lib/fetch'

import { ref, Ref } from 'vue'
/**
 *
 * This composable is used for triggering a "download" flow
 * in the user's browser. Useful for files that take a long time
 * to be ready, eg rendered PDFs.
 *
 */
export function useDownloadFile(
    url: Ref<string>,
    filename: Ref<string>,
    fileType: 'application/pdf' | 'image/png' // add more here as required
) {
    const isDownloading = ref(false)
    const hasErrored = ref(false)
    const download = async () => {
        if (isDownloading.value || hasErrored.value) {
            return
        }
        isDownloading.value = true
        hasErrored.value = false

        try {
            const response = await fetch(url.value, { method: 'GET' })

            if (response.status !== 200) {
                hasErrored.value = true
                throw new Error(response.statusText)
            }

            // Some stackoverflow whizbang magic. Tested to work on chrome/safari/firefox.
            const blob = await response.blob()
            const downloadableBlob = new Blob([blob], { type: fileType })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(downloadableBlob)
            link.download = filename.value
            link.click()
            URL.revokeObjectURL(link.href)
        } catch (error) {
            hasErrored.value = true
            throw error
        } finally {
            isDownloading.value = false
        }
    }

    const resetErrorState = () => (hasErrored.value = false)

    return { isDownloading, hasErrored, download, resetErrorState }
}
