<template>
    <svg
        :class="['bar-chart', { animated }]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 452"
        fill="none"
    >
        <rect width="640" height="452" fill="white" />
        <rect
            x="33.5"
            y="93.5"
            width="375"
            height="236"
            rx="7.5"
            stroke="black"
            stroke-opacity="0.04"
        />
        <mask
            id="mask0_413_2539"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="34"
            y="93"
            width="374"
            height="236"
        >
            <path
                d="M34 101C34 96.5817 37.5817 93 42 93H400C404.418 93 408 96.5817 408 101V321C408 325.418 404.418 329 400 329H42C37.5817 329 34 325.418 34 321V101Z"
                fill="white"
            />
            <path
                d="M42 93.5H400C404.142 93.5 407.5 96.8579 407.5 101V321C407.5 325.142 404.142 328.5 400 328.5H42C37.8579 328.5 34.5 325.142 34.5 321V101C34.5 96.8579 37.8579 93.5 42 93.5Z"
                stroke="black"
                stroke-opacity="0.08"
            />
        </mask>
        <g mask="url(#mask0_413_2539)">
            <rect x="48" y="110" width="64" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <!-- bars -->
            <g class="bars" clip-path="url(#clip0_413_2539)">
                <path
                    class="bar"
                    d="M80 224C80 220.686 82.6863 218 86 218H100C103.314 218 106 220.686 106 224V293C106 296.314 103.314 299 100 299H86C82.6863 299 80 296.314 80 293V224Z"
                    fill="#CCE2FF"
                />
                <path
                    class="bar"
                    d="M136 192.556C136 189.242 138.686 186.556 142 186.556H156C159.314 186.556 162 189.242 162 192.556V293C162 296.314 159.314 299 156 299H142C138.686 299 136 296.314 136 293V192.556Z"
                    fill="#CCE2FF"
                />
                <path
                    class="bar"
                    d="M192 169.556C192 166.242 194.686 163.556 198 163.556H212C215.314 163.556 218 166.242 218 169.556V293C218 296.314 215.314 299 212 299H198C194.686 299 192 296.314 192 293V169.556Z"
                    fill="#CCE2FF"
                />
                <path
                    class="bar"
                    d="M248 167C248 163.686 250.686 161 254 161H268C271.314 161 274 163.686 274 167V293C274 296.314 271.314 299 268 299H254C250.686 299 248 296.314 248 293V167Z"
                    fill="#CCE2FF"
                />
                <path
                    class="bar"
                    d="M304 199.371C304 196.057 306.686 193.371 310 193.371H324C327.314 193.371 330 196.057 330 199.371V293C330 296.314 327.314 299 324 299H310C306.686 299 304 296.314 304 293V199.371Z"
                    fill="#CCE2FF"
                />
                <path
                    class="bar"
                    d="M360 209.593C360 206.279 362.686 203.593 366 203.593H380C383.314 203.593 386 206.279 386 209.593V293C386 296.314 383.314 299 380 299H366C362.686 299 360 296.314 360 293V209.593Z"
                    fill="#006DFF"
                />
            </g>
            <rect x="50" y="167" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="195" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="223" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="251" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <rect x="50" y="279" width="20" height="8" rx="4" fill="black" fill-opacity="0.08" />
            <path
                d="M82 309C82 306.791 83.7909 305 86 305H100C102.209 305 104 306.791 104 309V309C104 311.209 102.209 313 100 313H86C83.7909 313 82 311.209 82 309V309Z"
                fill="#EBEBEB"
            />
            <path
                d="M138 309C138 306.791 139.791 305 142 305H156C158.209 305 160 306.791 160 309V309C160 311.209 158.209 313 156 313H142C139.791 313 138 311.209 138 309V309Z"
                fill="#EBEBEB"
            />
            <path
                d="M194 309C194 306.791 195.791 305 198 305H212C214.209 305 216 306.791 216 309V309C216 311.209 214.209 313 212 313H198C195.791 313 194 311.209 194 309V309Z"
                fill="#EBEBEB"
            />
            <path
                d="M250 309C250 306.791 251.791 305 254 305H268C270.209 305 272 306.791 272 309V309C272 311.209 270.209 313 268 313H254C251.791 313 250 311.209 250 309V309Z"
                fill="#EBEBEB"
            />
            <path
                d="M306 309C306 306.791 307.791 305 310 305H324C326.209 305 328 306.791 328 309V309C328 311.209 326.209 313 324 313H310C307.791 313 306 311.209 306 309V309Z"
                fill="#EBEBEB"
            />
            <path
                d="M362 309C362 306.791 363.791 305 366 305H380C382.209 305 384 306.791 384 309V309C384 311.209 382.209 313 380 313H366C363.791 313 362 311.209 362 309V309Z"
                fill="#EBEBEB"
            />
            <rect x="358" y="108" width="36" height="36" rx="18" fill="white" />
            <rect
                x="357.5"
                y="107.5"
                width="37"
                height="37"
                rx="18.5"
                stroke="black"
                stroke-opacity="0.04"
            />
            <path
                d="M373.536 120.629C373.685 120.238 373.89 119.877 374.195 119.585C375.413 118.398 377.426 118.697 378.252 120.188C378.873 121.319 379.532 122.425 380.172 123.543C381.241 125.401 382.322 127.259 383.378 129.123C384.267 130.683 383.304 132.652 381.539 132.919C380.458 133.081 379.445 132.584 378.886 131.615C377.948 129.98 377.003 128.346 376.065 126.718C376.046 126.681 376.021 126.65 375.997 126.619C375.897 126.538 375.854 126.42 375.792 126.314C375.375 125.581 374.946 124.854 374.53 124.127C374.263 123.655 373.983 123.189 373.716 122.717C373.474 122.294 373.362 121.834 373.374 121.35C373.393 121.101 373.424 120.853 373.536 120.629Z"
                fill="#3C8BD9"
            />
            <path
                d="M373.536 120.629C373.48 120.853 373.43 121.076 373.418 121.312C373.399 121.834 373.53 122.319 373.791 122.773C374.474 123.947 375.158 125.128 375.835 126.308C375.897 126.414 375.947 126.519 376.009 126.619C375.636 127.265 375.263 127.905 374.884 128.551C374.362 129.452 373.84 130.359 373.312 131.26C373.287 131.26 373.281 131.248 373.275 131.229C373.269 131.18 373.287 131.136 373.3 131.086C373.555 130.154 373.343 129.328 372.703 128.62C372.312 128.191 371.815 127.948 371.243 127.868C370.498 127.762 369.839 127.955 369.249 128.421C369.143 128.501 369.075 128.62 368.95 128.682C368.926 128.682 368.913 128.669 368.907 128.651C369.205 128.135 369.497 127.619 369.795 127.103C371.026 124.966 372.256 122.829 373.493 120.697C373.505 120.672 373.524 120.654 373.536 120.629Z"
                fill="#FABC04"
            />
            <path
                d="M368.932 128.669C369.05 128.564 369.162 128.452 369.286 128.353C370.796 127.16 373.064 128.023 373.393 129.912C373.474 130.366 373.43 130.801 373.294 131.236C373.287 131.273 373.281 131.304 373.269 131.341C373.213 131.441 373.163 131.546 373.101 131.646C372.548 132.559 371.734 133.013 370.665 132.944C369.441 132.857 368.478 131.938 368.31 130.72C368.23 130.13 368.348 129.577 368.652 129.067C368.714 128.955 368.789 128.856 368.857 128.744C368.888 128.719 368.876 128.669 368.932 128.669Z"
                fill="#34A852"
            />
            <path
                d="M368.932 128.669C368.907 128.694 368.907 128.738 368.863 128.744C368.857 128.7 368.882 128.676 368.907 128.645L368.932 128.669Z"
                fill="#FABC04"
            />
            <path
                d="M373.269 131.341C373.244 131.297 373.269 131.266 373.294 131.235C373.3 131.242 373.312 131.254 373.319 131.26L373.269 131.341Z"
                fill="#E1C025"
            />
        </g>
        <circle cx="103" cy="134" r="8" fill="#E6F7F0" />
        <path
            d="M103 136.908V131.09M103 131.09L100.818 133.272M103 131.09L105.182 133.272"
            stroke="#00A861"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect x="75" y="388" width="106" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="388" width="70" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="400" width="95" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="400" width="82" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="75" y="412" width="66" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <rect x="527" y="412" width="57" height="8" rx="4" fill="black" fill-opacity="0.08" />
        <circle cx="47" cy="404" r="14" fill="#EBEBEB" />
        <circle cx="499" cy="404" r="14" fill="#EBEBEB" />
        <rect x="33" y="34" width="146" height="24" rx="12" fill="black" fill-opacity="0.08" />
        <rect x="48" y="127" width="40" height="14" rx="7" fill="black" fill-opacity="0.08" />
        <rect x="117" y="127" width="30" height="14" rx="7" fill="#00A861" />
        <rect x="33" y="176" width="376" height="46" fill="#00A861" fill-opacity="0.08" />

        <!-- editor text -->
        <rect
            class="line"
            x="431"
            y="93"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="111"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="129"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="147"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="165"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="183"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="201"
            width="178"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />
        <rect
            class="line"
            x="431"
            y="219"
            width="153"
            height="10"
            rx="5"
            fill="black"
            fill-opacity="0.08"
        />

        <defs>
            <clipPath id="clip0_413_2539">
                <rect width="306" height="138" fill="white" transform="translate(80 161)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'

export default {
    props: {
        animated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        onMounted(() => {
            if (!props.animated) {
                return
            }
            anime.set('.bar-chart .line', {
                opacity: () => 0,
            })

            const timeline = anime.timeline()
            timeline
                .add({
                    targets: '.bar',
                    opacity: [0, 1],
                    translateY: [100, 0],
                    delay: anime.stagger(100, { start: 850 }),
                    duration: 600,
                    easing: 'easeOutCubic',
                })
                .add(
                    {
                        targets: '.bar-chart .line',
                        opacity: [0, 1],
                        delay: anime.stagger(100),
                        duration: 500,
                        easing: 'easeOutCubic',
                    },
                    '-=800'
                )
        })
    },
}
</script>

<style lang="scss" scoped>
svg {
    width: 100%;
    transform: scale(1);
    transform-origin: center;
}
.bars {
    overflow: hidden;
}
svg.animated {
    .bar {
        opacity: 0;
    }
}
.line {
    opacity: 1;
    will-change: opacity;
}
</style>
