export default function useImpactStatsTimeSavings() {
    const minutesToDuration = (minutes: number) => {
        return { hours: Math.floor(minutes / 60), minutes: minutes % 60 }
    }

    const formatDuration = (duration: Duration, long: boolean) => {
        // If minutes only or 0
        if (!duration.hours) return `${duration.minutes} ${long ? 'minutes' : 'min'}`
        // If hours only
        else if (duration.hours && !duration.minutes)
            return `${duration.hours} ${duration.hours > 1 ? 'hours' : 'hour'}`
        // If hours + minutes
        else return `${duration.hours}h ${duration.minutes}m`
    }

    const formatFromMinutes = (minutes: number, long: boolean) => {
        return formatDuration(minutesToDuration(minutes), long)
    }

    return { formatFromMinutes, minutesToDuration }
}
