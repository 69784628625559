import { ref, Ref, computed, onBeforeMount, nextTick } from 'vue'
import {
    loadStripe,
    Stripe,
    PaymentMethod,
    StripeCardNumberElement,
    StripeElementsOptions,
    StripeCardNumberElementOptions,
} from '@stripe/stripe-js'
import { useAPI, Endpoint } from '@/composition/api/useAPI'
import { STRIPE_PUBLISHABLE_KEY } from '@/lib/env'

const stripeElementsOptions: StripeElementsOptions = {
    fonts: [
        {
            family: 'Circular',
            src: 'url(https://d33wubrfki0l68.cloudfront.net/a9cbc707d3cbd736c74c809dea1cb200a8a174cf/30ccd/fonts/circularxxweb-book.woff2) format("woff2")',
        },
    ],
}

const stripeCardNumberElementOptions: StripeCardNumberElementOptions = {
    style: {
        base: {
            color: '#000',
            fontWeight: 400,
            fontFamily: 'Circular',
            fontSize: '14px',
            lineHeight: '44px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#00000840',
            },
            ':-webkit-autofill': {
                color: '#000',
            },
        },
        invalid: {
            color: '#000',
            '::placeholder': {
                color: '#00000840',
            },
        },
    },
}

interface UseNewPaymentMethod {
    title: string
    addPaymentMethodLoading: Ref<boolean>
    stripeElementsLoading: Ref<boolean>
    addPaymentMethodButton: Ref<any>
    cardEntryError: Ref<string | null>
    addPaymentMethod(): Promise<void>
}

type EmittedEvent = (event: 'on-success', paymentMethod: string | PaymentMethod) => void

export function useCreditCardForm({
    updatingCard,
    emit,
}: {
    updatingCard?: boolean
    emit: EmittedEvent
}): UseNewPaymentMethod {
    const { data: setupIntent } = useAPI<{ client_secret: string }>(
        Endpoint.CreateStripeSetupIntent
    )

    const title = updatingCard ? 'Update Payment Card' : 'Add Payment Card'
    let stripe: Stripe | null = null
    const addPaymentMethodButton = ref()
    const stripeElementsLoading = ref(true)
    const addPaymentMethodLoading = ref(false)
    const cardNumberElement = ref<StripeCardNumberElement | null>(null)
    const cardEntryError = ref<string | null>(null)

    const clientSecret = computed(() => setupIntent.value?.client_secret)

    async function addPaymentMethod() {
        addPaymentMethodLoading.value = true
        cardEntryError.value = null

        if (clientSecret.value && cardNumberElement.value && stripe) {
            const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret.value, {
                payment_method: { card: cardNumberElement.value },
            })
            if (error) {
                const { message: stripeErrorMessage } = error
                cardEntryError.value = stripeErrorMessage ?? null
                addPaymentMethodLoading.value = false
            } else if (setupIntent) {
                const { payment_method: paymentMethod } = setupIntent
                if (paymentMethod) {
                    emit('on-success', paymentMethod)
                }
                // Note that we don't set the loader back to false here. We want it to be loading until either:
                // The page is changed (newpaymentmethod)
                // The modal is closed (billing)
            }
        }
    }

    onBeforeMount(async () => {
        addPaymentMethodLoading.value = false
        stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)

        if (!stripe) {
            return
        }

        const elements = stripe.elements(stripeElementsOptions)

        cardNumberElement.value = elements.create('cardNumber', stripeCardNumberElementOptions)
        const cardExpiryDateElement = elements.create('cardExpiry', stripeCardNumberElementOptions)
        const cardSecurityCodeElement = elements.create('cardCvc', stripeCardNumberElementOptions)

        cardNumberElement.value.mount('#card-number')
        cardExpiryDateElement.mount('#card-expiry-date')
        cardSecurityCodeElement.mount('#card-security-code')

        cardNumberElement.value.on('ready', async () => {
            stripeElementsLoading.value = false
            await nextTick()
            cardNumberElement.value?.focus()
        })
    })

    return {
        title,
        stripeElementsLoading,
        addPaymentMethodLoading,
        addPaymentMethodButton,
        cardEntryError,
        addPaymentMethod,
    }
}
