<template>
    <div ref="tableWrapper" class="table-wrapper">
        <perfect-scrollbar
            ref="container"
            class="product-groups-container"
            tabindex="0"
            role="group"
        >
            <div
                class="product-groups-table relative"
                @mouseenter="setHovered(true)"
                @mouseleave="setHovered(false)"
            >
                <header>
                    <div class="product-group-header">
                        <span>Product Group</span>
                    </div>
                    <div class="attribute-header">
                        <span>Attribute</span>
                    </div>
                </header>
                <div class="product-group-rows">
                    <ProductGroupRow :product-group="groups" />
                </div>
            </div>
        </perfect-scrollbar>

        <transition name="fade-up">
            <div
                v-if="isHovered && hoveredProductGroup"
                class="metrics-popover"
                :style="{ transform: `translate(calc(100% + 1rem), ${popoverDistanceY}px)` }"
            >
                <ChartTooltip
                    :title="hoveredProductGroup.name"
                    :metrics="[
                        {
                            label: 'Cost',
                            value: `${
                                useMoney({
                                    value: hoveredProductGroup.cost ?? 0,
                                    currency: currencyCode,
                                }).displayValue.value
                            }`,
                        },
                        {
                            label: 'Clicks',
                            value: useNumber({ value: hoveredProductGroup.clicks }).displayValue
                                .value,
                        },
                        {
                            label: 'Impressions',
                            value: useNumber({ value: hoveredProductGroup.impressions })
                                .displayValue.value,
                        },
                    ]"
                    maxWidth="13.5rem"
                    :fullWidth="true"
                />
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, reactive, toRefs, provide } from 'vue'
import { Currency } from '@opteo/types'

// Local Components
import ProductGroupRow from './ProductGroupRow.vue'

import {
    // Components
    ChartTooltip,
    // Composables
    useMoney,
    useNumber,
} from '@opteo/components-next'

interface ProductGroup {
    name: string
    product_group_attribute: string
    resource_name: string | null
    products_submitted: number
    product_groups?: ProductGroup[]
    generated_product_group?: boolean
    cost: number
    clicks: number
    impressions: number
}

export default defineComponent({
    name: 'ProductGroupTable',
    components: { ProductGroupRow, ChartTooltip },
    props: {
        groups: {
            type: Object as PropType<ProductGroup>,
            required: true,
        },
        hover: {
            // if set to false MetricsPopover will not appear
            type: Boolean as PropType<boolean>,
            required: false,
            default: true,
        },
        currencyCode: {
            type: String as PropType<Currency.CurrencyCode>,
            required: true,
        },
    },
    setup(props) {
        const { groups, hover } = toRefs(props)

        const isHovered = ref<boolean>(false)
        const hoveredProductGroup = ref<ProductGroup | null>(null)
        const tableWrapper = ref<HTMLDivElement | null>(null)
        const popoverDistanceY = ref<number>(0)

        const productGroups = reactive(groups)
        provide('productGroups', productGroups)

        const handleHoverRow = (event: MouseEvent, group: ProductGroup) => {
            // if touch capable device
            // https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
            if (
                'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0 ||
                !hover
            ) {
                return
            }
            const row = event.target as HTMLElement
            const tableDistance = tableWrapper?.value?.getBoundingClientRect().top ?? 0

            hoveredProductGroup.value = group
            popoverDistanceY.value = row.getBoundingClientRect().top - tableDistance
        }
        // pass down function as a callback rather than emit events
        provide('handleHoverRow', handleHoverRow)

        const setHovered = (hovered: boolean) => (isHovered.value = hovered)

        return {
            isHovered,
            hoveredProductGroup,
            tableWrapper,
            popoverDistanceY,
            setHovered,
            useMoney,
            useNumber,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.table-wrapper {
    width: 100%;
    position: relative;
}

.product-groups-container {
    width: 100%;
    @include container;
}

.product-groups-table {
    @include tl;
    @include lh-130;
    border-spacing: 0;
    font-size: 0.875rem;
    letter-spacing: -0.00625rem;
    min-width: 512px;
    width: 100%;
}

header {
    @include fw-500;
    @include flex;
    border-bottom: 1px solid;
    @include opteo-border;
}

header div {
    padding: 1.4375rem 1.5rem;
}

.product-group-header {
    flex-grow: 1;
}

.attribute-header {
    width: 12rem;
}

.product-group-rows {
    position: relative;
}

.metrics-popover {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate(calc(100% + 0.5rem), 0);
}

.fade-up-enter-active,
.fade-up-leave-active {
    transition: opacity 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.fade-up-enter-from,
.fade-up-leave-to {
    opacity: 0;
}
</style>
