import { Ngram, Targets } from '@opteo/types'
import groupBy from 'lodash-es/groupBy'
import sortBy from 'lodash-es/sortBy'

import {
    OnPushHandler,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { useDomain } from '@/composition/domain/useDomain'
import { getCommonVariables } from './utils'
import { computed, ref } from 'vue'

export function useReduceNgramBids() {
    const { currencyCode: currency } = useDomain()
    const { improvement, lastUpdated, title } = useImprovement<Ngram.NgramImpType.Body>()
    const { body } = checkImprovement<Ngram.NgramImpType.Body>(improvement)

    const keywordCount = body.keywords_to_reduce_bids.length
    const header = `Reduce N-Gram Bid${keywordCount > 1 ? 's' : ''} 10%`

    const pushActionText = ref('Reduce Keyword Bids')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Reducing keyword bids..',
        'Confirming changes..',
        'Keyword bids reduced successfully.',
    ])

    const {
        performanceMode,
        ngramText,
        campaignGroupName,
        campaignGroupTarget,
        hasTarget,
        scatterPlotItems,
        ngramConversions,
        ngramConversionsValue,
        ngramCost,
        ngramCpa,
        ngramRoas,
        dataPeriod,
        window,
        contributingSearchTerms,
        isShopping,
        stats,
    } = getCommonVariables(body)

    const keywordsByCampaign = sortBy(
        Object.values(groupBy(body.keywords_to_reduce_bids, k => k.campaign_name)).map(group => {
            return {
                campaignName: group[0].campaign_name,
                keywordTableItems: group.map(k => {
                    return {
                        id: k.resource_name,
                        keyword: k.text_inc_matchtype,
                        conversions: k.all_conversions,
                        conversionsValue: k.all_conversions_value,
                        cost: k.cost,
                        currentBid: k.current_bid,
                        newBid: k.new_bid,
                    }
                }),
            }
        }),
        g => g.campaignName
    )

    const tableHeaders = [
        { key: 'keyword', text: 'Keyword', width: 228 },
        performanceMode === Targets.PerformanceMode.CPA
            ? { key: 'conversions', text: 'Conv.', noPadding: true, width: 78 }
            : { key: 'conversionsValue', text: 'Value', noPadding: true, width: 78 },
        { key: 'cost', text: 'Cost', noPadding: true, width: 86 },
        { key: 'currentBid', text: 'Cur. Bid', noPadding: true, width: 80 },
        { key: 'newBid', text: 'New Bid', noPadding: true, width: 84 },
    ]

    const nGramWordCount = ngramText.split(' ').length

    const onPush: OnPushHandler<Ngram.NgramImpType.NegativeNgramExtraDetails> = () => {
        return { valid: true }
    }

    return {
        title,
        header,
        currency,
        pushMessages,
        onPush,
        lastUpdated,
        ngramText,
        campaignGroupName,
        campaignGroupTarget,
        hasTarget,
        performanceMode,
        PerformanceMode: Targets.PerformanceMode,
        scatterPlotItems,
        ngramConversions,
        ngramConversionsValue,
        ngramCost,
        ngramCpa,
        ngramRoas,
        dataPeriod,
        window,
        contributingSearchTerms,
        isShopping,
        stats,
        keywordsByCampaign,
        tableHeaders,
        keywordCount,
        nGramWordCount,
        pushActionText,
    }
}
