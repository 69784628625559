<template>
    <div class="improvement-note" :class="{ 'pdf-shadow-fix': isPdf }">
        <Text as="p" size="f-8">
            <slot></slot>
        </Text>
    </div>
</template>

<script lang="ts" setup>
import { Text } from '@opteo/components-next'
import { inject } from 'vue'
const isPdf = inject('isPdf', false)
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.improvement-note {
    @include container;
    width: 100%;
    @include ph-24;
    @include pv-20;
    @include br-20;
}
</style>
