import { Endpoint, useAPI } from '../api/useAPI'
import { useUser } from './useUser'

// TODO(types): move to @opteo/types for auth/getTeam
export interface TeamMember {
    user_id: number
    name: string
    email: string
    last_login_date: string
    role: 'admin' | 'user'
    avatar_filename: string
    status: 'Onboarded' | 'Invite email sent'
    last_login: string
    avatar_url: string
}

export function useTeam() {
    const { groupId } = useUser()

    const { data: team, mutate } = useAPI<TeamMember[]>(Endpoint.GetTeam, {
        body: {
            teamId: groupId.value,
        },
    })

    const mutateTeam = (updatedTeam?: TeamMember[]) => {
        if (typeof updatedTeam === 'undefined') {
            mutate()
        } else {
            mutate(() => updatedTeam)
        }
    }

    return {
        team,
        mutateTeam,
    }
}
