<template>
    <SettingsSection
        id="renew-subscription"
        :title="resubAction"
        :maxWidth="400"
        :noInnerContentShadow="true"
    >
        <div class="renew-subscription-info">
            <div v-if="trialLength && currentPlan">
                <Text size="f-8">
                    To start a new free trial, click the button below. Your new trial will last for
                    <b>{{ trialLength }} days</b>. You will be subscribed to the
                    <b>{{ currentPlan.name }}</b> plan. If you have any questions, please don't
                    hesitate to contact support using the chat bubble at the bottom right of the
                    screen.
                </Text>
            </div>
            <div v-else>
                <Text v-if="cancelsOn" size="f-8">
                    Your subscription will end on <b>{{ cancelsOn }}</b
                    >. Your payment card <b>will not</b> be charged again. To renew your
                    subscription, click
                    <Text as="span" weight="600" color="green">Renew Subscription</Text>.
                </Text>
                <Text v-else size="f-8">
                    Your subscription has ended. To start using Opteo again, click
                    <Text as="span" weight="600" color="green">Renew Subscription</Text>. If you
                    have any questions, please message our support team using the chat bubble at the
                    bottom right of the screen.
                </Text>
            </div>
            <Spacer height="1.75rem" />
            <div class="current-plan-container">
                <div>
                    <Text size="f-7" weight="600" style="letter-spacing: -0.02rem">
                        {{ currentPlan?.name }}
                    </Text>
                    <Spacer height="0.0625rem" />
                    <Text size="f-9" color="gray">
                        <Money :value="currentPlan?.price" currency="USD" />/{{
                            currentPlan?.period_interval
                        }}
                    </Text>
                </div>
                <oButton color="white" size="small" @clicked="openPlansModal()">
                    <span>Change Plan</span>
                </oButton>
            </div>
            <Spacer height="2rem" />
            <div class="renew-button-container">
                <oButton
                    id="renew-subscription"
                    size="medium"
                    color="green"
                    @clicked="loadStripeElements"
                >
                    {{ resubAction }}
                </oButton>
            </div>
        </div>
    </SettingsSection>

    <Modal
        v-model="showRenewModal"
        title="Renew Subscription"
        @closed="clearErrorStates"
        width="490px"
    >
        <template #content>
            <div v-if="currentPlan">
                <div v-if="!updateSuccessful">
                    <div class="mini-invoice-container">
                        <div class="mini-invoice-row">
                            <Text as="span" size="f-9">Payment Method</Text>
                            <div v-if="creditCard" class="credit-card-container">
                                <Text as="p" size="f-8">**** {{ creditCard.last4 }}</Text>
                                <Spacer width="0.5rem" height="0.25rem" />
                                <component :is="`${creditCard.brand}-logo`" />
                            </div>
                            <Text as="p" size="f-8" v-else>None</Text>
                        </div>
                        <div class="mini-invoice-row">
                            <Text as="span" size="f-9">Pricing Plan</Text>
                            <Text as="p" size="f-8">{{ currentPlan.name }}</Text>
                        </div>
                        <div class="mini-invoice-row">
                            <Text as="span" size="f-9">Due Today</Text>
                            <Text
                                as="p"
                                size="f-8"
                                v-if="latestSubscription?.cancel_at_period_end || trialLength"
                            >
                                $0.00
                            </Text>
                            <Text as="p" size="f-8" v-else>
                                <Money :value="currentPlan.price" currency="USD" />
                            </Text>
                        </div>
                    </div>
                    <Spacer height="1.625rem" />
                    <Text v-if="trialLength" size="f-8">
                        To start your {{ trialLength }} day trial, please click the button below.
                        You are currently subscribed to the <b>{{ currentPlan.name }}</b> plan. You
                        will be billed <b><Money :value="currentPlan.price" currency="USD" /></b> at
                        the end of your trial.
                    </Text>
                    <Text size="f-8" v-else-if="!latestSubscription?.cancel_at_period_end">
                        To continue, please click the
                        <Text as="span" weight="600" color="green">Renew Subscription</Text> button
                        below. You will be subscribed to the <b>{{ currentPlan.name }}</b> plan.
                        Your chosen payment method will be charged
                        <b> <Money :value="currentPlan.price" currency="USD" /> </b>.
                    </Text>
                    <Text v-else size="f-8">
                        To continue using Opteo after your subscription expires, click the
                        <Text as="span" weight="600" color="green">Renew Subscription</Text> button
                        below. You will be subscribed to the <b>{{ currentPlan.name }}</b> plan.
                        Your next invoice will be for
                        <b><Money :value="currentPlan.price" currency="USD" /></b>, to be paid on
                        <b>{{ cancelsOn }}</b
                        >.
                    </Text>
                    <!-- Update payment method -->
                    <Spacer v-if="!creditCard" height="0.75rem" />
                    <Text v-if="!creditCard" size="f-8" color="red">
                        Please <b>update your payment method</b> before renewing.
                    </Text>
                    <!-- Errors -->
                    <Spacer v-if="subscriptionUpdateError" height="0.75rem" />
                    <Text
                        v-if="subscriptionUpdateError"
                        size="f-8"
                        color="red"
                        v-html="subscriptionUpdateError"
                    >
                    </Text>
                </div>

                <div v-else>
                    <div class="ph4 ph5-ns pv4" style="margin-left: 15px; margin-right: 15px">
                        <img src="@/assets/img/cancel-confirm.png" style="width: 100%" />
                    </div>
                    <p class="f6 lh-copy db pa4 bt b--opteo-light-gray">
                        <span v-if="!latestSubscription?.cancel_at_period_end && !trialLength">
                            Your payment of
                            <b><Money :value="currentPlan.price" currency="USD" /></b> was
                            successful.
                        </span>
                        <span v-else>Thanks for resubscribing.</span> If you have any questions,
                        visit our <a href="https://opteo.com/docs/en">Help Centre</a> or reach out
                        to our
                        <a @click="showNewMessage()"> Team via chat.</a>
                    </p>
                </div>
            </div>
        </template>
        <template #buttons>
            <div v-if="!updateSuccessful">
                <oButton
                    size="medium"
                    color="white"
                    @clicked="showRenewModal = false"
                    class="cancel-button"
                >
                    Cancel
                </oButton>
                <oButton
                    v-if="updateErrorAction === 'support'"
                    ref="renewButton"
                    size="medium"
                    color="white"
                    @clicked="showNewMessage()"
                >
                    Contact Support
                </oButton>

                <oButton
                    v-else-if="updateErrorAction === 'payment_method' || !creditCard"
                    ref="renewButton"
                    size="medium"
                    color="blue"
                    @clicked="openPaymentModal"
                >
                    Update Payment Method
                </oButton>

                <oButton
                    v-else-if="trialLength && !updateErrorAction"
                    ref="renewButton"
                    :loading="updatingSubscription"
                    @clicked="renewSubscription()"
                >
                    Start {{ trialLength }} Day Trial
                </oButton>

                <oButton
                    v-else-if="!latestSubscription?.cancel_at_period_end && !updateErrorAction"
                    ref="renewButton"
                    size="medium"
                    color="green"
                    :loading="updatingSubscription"
                    @clicked="renewSubscription()"
                >
                    Renew Subscription
                </oButton>

                <oButton
                    v-else-if="!updateErrorAction"
                    ref="renewButton"
                    size="medium"
                    color="green"
                    :loading="updatingSubscription"
                    @clicked="renewSubscription()"
                >
                    Renew Subscription
                </oButton>
            </div>
            <div v-else>
                <oButton
                    id="close-cancel-modal"
                    size="medium"
                    color="white"
                    @clicked="showRenewModal = false"
                >
                    Close Window
                </oButton>
            </div>
        </template>
    </Modal>
    <PlanSelectionModal />
    <PaymentMethodModal v-model="showPaymentMethodModal" />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useSubscription } from '@/composition/billing/useSubscription'
import {
    Money,
    oButton,
    Text,
    CloseButton,
    VisaLogo,
    MastercardLogo,
    MaestroLogo,
    AmexLogo,
    Modal,
    Spacer,
} from '@opteo/components-next'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { useUser } from '@/composition/user/useUser'
import PlanSelectionModal from '@/components/billing/PlanSelectionModal.vue'
import SettingsSection from '@/components/global/SettingsSection.vue'
import PaymentMethodModal from '@/components/billing/PaymentMethodModal.vue'
import { usePlanSelectionModal } from '@/composition/billing/usePlanSelectionModal'

import { STRIPE_PUBLISHABLE_KEY } from '@/lib/env'

import { loadStripe, Stripe } from '@stripe/stripe-js'

export default defineComponent({
    name: 'SubscriptionRenew',
    components: {
        PlanSelectionModal,
        SettingsSection,
        Money,
        oButton,
        Text,
        CloseButton,
        VisaLogo,
        MastercardLogo,
        MaestroLogo,
        AmexLogo,
        Modal,
        Spacer,
        PaymentMethodModal,
    },
    setup() {
        const { trialLength, cancelsOn, creditCard, paymentMethod, latestSubscription, mutate } =
            useSubscription()

        const { currentPlan, userId, userInfo } = useUser()

        let stripe: Stripe | null = null

        const renewSubscriptionLoading = ref(false)

        const loadStripeElements = async () => {
            showRenewModal.value = true

            renewSubscriptionLoading.value = false
            stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)
        }

        const renewButton = ref()

        const updateSuccessful = ref(false)

        const updateErrorAction = ref()

        const resubAction = computed(() =>
            (trialLength.value ?? 0) > 0 ? 'Start New Free Trial' : 'Renew Subscription'
        )

        const updatingSubscription = ref(false)

        const showRenewModal = ref(false)

        const subscriptionUpdateError = ref()

        const { showNewMessage } = useIntercom()

        const { showPlanSelectionModal } = usePlanSelectionModal()

        function openPlansModal() {
            showPlanSelectionModal.value = true
        }

        async function renewSubscription() {
            if (paymentMethod.value && stripe) {
                renewSubscriptionLoading.value = true
                updatingSubscription.value = true

                try {
                    const response = await authRequest(Endpoint.RenewSubscription)
                    const requiresAction =
                        (response?.latest_invoice?.payment_intent?.status === 'requires_action' ||
                            response?.latest_invoice?.payment_intent?.status ===
                                'requires_payment_method') &&
                        response?.status === 'incomplete'

                    if (requiresAction) {
                        const clientSecret = response?.latest_invoice?.payment_intent?.client_secret

                        const { paymentIntent, error } = await stripe.confirmCardPayment(
                            clientSecret,
                            {
                                payment_method: paymentMethod.value,
                            }
                        )

                        if (error) {
                            throw error
                        }

                        await authRequest(Endpoint.HandleSuccessfullRenewal, {
                            body: {
                                user_id: userId.value,
                                group_id: userInfo.value?.group_id,
                                customer_id: latestSubscription.value?.customer,
                                name: userInfo.value?.name,
                                username: userInfo.value?.username,
                                opteo_lifecycle_stage: userInfo.value?.opteo_lifecycle_stage,
                                stripe_subscription_status:
                                    paymentIntent.status === 'succeeded'
                                        ? 'active'
                                        : paymentIntent.status,
                            },
                        })
                    }

                    mutate()

                    updatingSubscription.value = false
                    renewButton.value?.flashSuccess()

                    setTimeout(() => (showRenewModal.value = false), 1000)
                } catch (error) {
                    updatingSubscription.value = false

                    subscriptionUpdateError.value = 'Something went wrong, please contact support.'
                    updateErrorAction.value = 'support'
                }
            }
        }

        const showPaymentMethodModal = ref(false)

        function openPaymentModal() {
            showPaymentMethodModal.value = true
        }

        function clearErrorStates() {
            subscriptionUpdateError.value = null
            updateErrorAction.value = null
        }

        return {
            resubAction,
            cancelsOn,
            currentPlan,
            showNewMessage,
            trialLength,
            creditCard,
            latestSubscription,
            showRenewModal,
            updateErrorAction,
            renewSubscription,
            updatingSubscription,
            updateSuccessful,
            renewButton,
            subscriptionUpdateError,
            openPlansModal,
            openPaymentModal,
            showPaymentMethodModal,
            clearErrorStates,
            renewSubscriptionLoading,
            loadStripeElements,
        }
    },
})
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.renew-subscription-info {
    @include container;
    @include pa-32;
}

.current-plan-container {
    @include container;
    @include flex;
    @include items-center;
    @include justify-between;
    @include ph-24;
    @include pv-20;
}

.renew-button-container {
    @include flex-center;
    @include w-100;
}

.cancel-button {
    @include mr-12;
}

.mini-invoice-container {
    @include container;
    @include ph-32;
    @include pv-28;
}
.mini-invoice-row {
    @include flex;
    @include items-center;
    @include justify-between;
    text-transform: capitalize;
    @include mb-12;
}
.mini-invoice-row:last-child {
    @include mb-0;
}
.mini-invoice-row span {
    line-height: 1.25rem;
}
.mini-invoice-row p {
    line-height: 1.25rem;
    font-size: 0.8125rem;
}
.credit-card-container {
    @include flex;
    @include items-center;
}

.renew-subscription-modal .mdc-text-field .mdc-line-ripple {
    background-color: #ff2828 !important;
}

.renew-subscription-modal .mdc-text-field--focused .text-area-label[data-v-62e03386] {
    color: #ff2828 !important;
}
</style>
