<template>
    <router-link to="/">
        <OpteoLogo :width="44" :height="44" class="opteo-logo" />
    </router-link>
    <LoginContainer title="Reset Password">
        <template v-slot:info>
            <div v-if="showForm">
                Set and confirm your new password using the form below. Click the button to approve.
            </div>
            <div v-else-if="invalidToken">This password reset link is no longer valid.</div>
            <div v-else>
                Password for
                <b>{{ email }}</b> updated successfully. Click the button below to return to the
                login page.
            </div>
        </template>
        <template v-slot:content>
            <oForm ref="updatePasswordForm" @submitted="resetPassword" :validateOnSubmit="true">
                <div v-if="showForm">
                    <oInput
                        v-model="newPassword"
                        :validator="newPasswordValidator"
                        type="password"
                        name="newPassword"
                        ref="newPasswordInput"
                        label="New Password"
                        placeholder="••••••••••"
                        autocomplete="new-password"
                        required
                    />
                    <Spacer height="1.5rem" />
                    <oInput
                        v-model="confirmNewPassword"
                        :validator="confirmNewPasswordValidator"
                        type="password"
                        name="confirmNewPassword"
                        ref="confirmNewPasswordInput"
                        label="Confirm New Password"
                        placeholder="••••••••••"
                        autocomplete="new-password"
                        required
                    />
                    <Spacer height="2rem" />
                    <oButton type="submit" :loading="loading" size="login">
                        Save New Password
                    </oButton>
                </div>
                <div v-else-if="invalidToken">
                    <router-link to="/login">
                        <oButton color="blue" size="login">Go to Login</oButton>
                    </router-link>
                </div>
                <div v-else>
                    <router-link to="/login">
                        <oButton color="blue" size="login">Go to Login</oButton>
                    </router-link>
                </div>
            </oForm>
        </template>
        <template v-slot:links>
            <router-link to="/login" data-cy="login-link">
                Click here to return to the Login page.
            </router-link>
        </template>
    </LoginContainer>
</template>

<script lang="ts">
import LoginContainer from '@/components/global/LoginContainer.vue'
import { oForm, oInput, oButton, OpteoLogo, Spacer } from '@opteo/components-next'

import { useResetPasswordLink } from '@/composition/auth/useResetPasswordLink'

export default {
    name: 'ResetLink',
    components: { LoginContainer, oForm, oInput, oButton, OpteoLogo, Spacer },
    setup() {
        return useResetPasswordLink()
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}
@media screen and (max-width: $mq-767-max) {
    .opteo-logo {
        display: none;
    }
}
</style>
