<template>
    <div class="rsa-sidebar-outer">
        <perfect-scrollbar class="rsa-sidebar">
            <oForm ref="formRef" :autofill="false">
                <!-- URL -->
                <section>
                    <oInput
                        label="Destination URL"
                        v-model="rsa.finalUrl"
                        :validator="urlValidator"
                        required
                        name="destination-url"
                    />
                    <Spacer height="1.5rem" />
                    <div class="path-urls">
                        <oInput label="Path One" v-model="rsa.pathOne" :maxCharacters="15" name="path1" />
                        <Spacer width="1.25rem" />
                        <oInput label="Path Two" v-model="rsa.pathTwo" :maxCharacters="15" name="path2" />
                    </div>
                    <Spacer height="1.5rem" />
                    <div v-if="optionsShown">
                        <oInput
                            label="Tracking Template"
                            v-model="rsa.trackingUrlTemplate"
                            :validator="urlValidator"
                            name="tracking-template"
                        />
                        <Spacer height="1.5rem" />
                        <oInput label="Final URL Suffix" v-model="rsa.finalUrlSuffix" name="final-url-suffix" />
                        <Spacer height="1.5rem" />
                        <oInput
                            label="Mobile URL"
                            v-model="rsa.finalUrlMobile"
                            :validator="urlValidator"
                            name="mobile-url"
                        />
                        <Spacer height="1.5rem" />
                    </div>
                    <oButton color="white" @clicked="optionsShown = !optionsShown">
                        <span v-if="optionsShown">Hide</span><span v-else>Show</span> Options
                    </oButton>
                </section>
                <!-- Headlines -->
                <section>
                    <div v-for="(headline, index) in rsa.headlines" :key="index">
                        <RsaHeadlineInput
                            :label="`Headline ${getNumberWord(index + 1)}`"
                            :name="`headline-${index}`"
                            :required="index < 3"
                            :rsa="rsa"
                            v-model:text="headline.text"
                            v-model:pinnedTo="headline.pinnedTo"
                        />
                        <Spacer v-if="index + 1 != rsa.headlines?.length" height="1.5rem" />
                    </div>
                    <Spacer v-if="(rsa.headlines?.length ?? 0) < 15" height="1.5rem" />
                    <oButton v-if="(rsa.headlines?.length ?? 0) < 15" color="white" @clicked="addHeadline">
                        Add Headline
                    </oButton>
                </section>
                <!-- Descriptions -->
                <section class="final">
                    <div v-for="(description, index) in rsa.descriptions" :key="index">
                        <RsaDescriptionInput
                            :label="`Description ${getNumberWord(index + 1)}`"
                            :name="`description-${index}`"
                            :required="index < 2"
                            :rsa="rsa"
                            v-model:text="description.text"
                            v-model:pinnedTo="description.pinnedTo"
                        />
                        <Spacer v-if="index + 1 != rsa.descriptions?.length" height="1.5rem" />
                    </div>
                    <Spacer v-if="(rsa.descriptions?.length ?? 0) < 4" height="1.5rem" />
                    <oButton v-if="(rsa.descriptions?.length ?? 0) < 4" color="white" @clicked="addDescription">
                        Add Description
                    </oButton>
                </section>
            </oForm>
        </perfect-scrollbar>
        <!-- Clear Form Button -->
        <div class="clear-form-sticky-button">
            <oButton color="white" v-if="originalRsa" @clicked="resetToOriginal" :disabled="!changesExist">
                Reset Changes
            </oButton>
            <Spacer v-if="originalRsa" width="0.625rem" />
            <oButton color="white" @clicked="clearForm" :disabled="formIsEmpty">Clear Form</oButton>
        </div>
    </div>
</template>

<script lang="ts">
import cloneDeep from 'lodash-es/cloneDeep'
import stableSingify from 'json-stable-stringify'
import { defineComponent, ref, PropType, watch, computed } from 'vue'
import { RsaWriter } from '@opteo/types'

import { getNumberWord, urlValidator } from '@/lib/globalUtils'

import {
    Text,
    Spacer,
    oInput,
    oButton,
    oForm,
} from '@opteo/components-next'

import RsaHeadlineInput from './RsaHeadlineInput.vue'
import RsaDescriptionInput from './RsaDescriptionInput.vue'

export default defineComponent({
    name: 'RsaSidebar',
    components: {
        Text,
        Spacer,
        oInput,
        oButton,
        oForm,
        RsaHeadlineInput,
        RsaDescriptionInput,
    },
    props: {
        rsa: {
            type: Object as PropType<Partial<RsaWriter.RsaAd>>,
            required: true,
        },
        originalRsa: {
            type: Object as PropType<RsaWriter.RsaAd>,
            required: false,
        },
        formIsEmpty: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    emits: ['update:rsa', 'fields-changed'],
    setup(props, { emit }) {
        const formRef = ref()

        const optionsShown = ref(false)

        const addHeadline = () => {
            const _rsa = cloneDeep(props.rsa)
            _rsa.headlines?.push({
                text: '',
                pinnedTo: 0,
            })

            emit('update:rsa', _rsa)
        }
        const addDescription = () => {
            const _rsa = cloneDeep(props.rsa)
            _rsa.descriptions?.push({
                text: '',
                pinnedTo: 0,
            })

            emit('update:rsa', _rsa)
        }

        const clearForm = () => {
            const _rsa = cloneDeep(props.rsa)

            if (_rsa.headlines) {
                for (const i in _rsa.headlines) {
                    _rsa.headlines[i].text = ''
                    _rsa.headlines[i].pinnedTo = 0
                }
            }
            if (_rsa.descriptions) {
                for (const i in _rsa.descriptions) {
                    _rsa.descriptions[i].text = ''
                    _rsa.descriptions[i].pinnedTo = 0
                }
            }

            _rsa.pathOne = ''
            _rsa.pathTwo = ''

            _rsa.finalUrl = ''
            _rsa.finalUrlMobile = ''
            _rsa.finalUrlSuffix = ''
            _rsa.trackingUrlTemplate = ''

            emit('update:rsa', _rsa)
        }

        const resetToOriginal = async () => {
            if (!props.originalRsa) {
                return
            }

            emit('update:rsa', cloneDeep(props.originalRsa))
        }

        function submitForm(): boolean {
            const formResults = formRef.value.submit()
            return formResults
        }

        const changesExist = computed(() => {
            return (
                stableSingify(extractEditableFields(props.rsa)) !==
                stableSingify(extractEditableFields(props.originalRsa))
            )
        })

        const extractEditableFields = (rsa?: Partial<RsaWriter.RsaAd>): RsaWriter.EditableRsaAdFields => {
            return {
                pathOne: rsa?.pathOne ?? '',
                pathTwo: rsa?.pathTwo ?? '',
                headlines: rsa?.headlines ?? [],
                descriptions: rsa?.descriptions ?? [],
                finalUrl: rsa?.finalUrl ?? '',
                finalUrlMobile: rsa?.finalUrlMobile ?? '',
                finalUrlSuffix: rsa?.finalUrlSuffix ?? '',
                trackingUrlTemplate: rsa?.trackingUrlTemplate ?? '',
            }
        }

        // Fire the 'fields-changed' event on change of the form, ultimately to trigger a save of the draft to DB
        const editableFields = computed(() => extractEditableFields(props.rsa))
        watch(editableFields, saveChanges, { deep: true })
        async function saveChanges() {
            emit('fields-changed')
        }

        return {
            optionsShown,
            getNumberWord,
            resetToOriginal,
            addHeadline,
            addDescription,
            clearForm,
            formRef,
            urlValidator,
            submitForm,
            saveChanges,
            changesExist,
        }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.rsa-sidebar-outer {
    @include grid;
}
.rsa-sidebar {
    // 100vh - footerHeight - footerBottomMargin - sidebarBottomGap - sidebarTopGap - buttonContainer
    max-height: calc(100vh - 80px - 24px - 24px - 24px - 93px);
    overflow: hidden;
}
.rsa-sidebar section {
    border-bottom: 1px solid $opteo-light-gray;
    @include pa-32;
}
.rsa-sidebar section.final {
    border-bottom: 0px;
}
.rsa-sidebar section .path-urls {
    @include flex;
    @include items-center;
}
.rsa-sidebar section .description-input textarea {
    min-height: 5.625rem;
}
.clear-form-sticky-button {
    @include relative;
    @include w-100;
    @include ph-32;
    @include pv-24;
    @include flex-center;
    @include bg-opteo-white;
    @include z-2;
    border-top: 1px solid $opteo-light-gray;
    height: 5.75rem;
}
</style>
