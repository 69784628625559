import { Routes } from '@/router/routes'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { User } from '@opteo/types'

import { useAuth } from './useAuth'
import { noAuthRequest } from '../api/useAPI'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { pushToDatalayer } from '@/lib/datalayer'

interface LoginResponse {
    token: { token: string }
    user_info: {
        profile: User.Info
    }
    err?: string
}

export function useLogin() {
    const { setCredentials, clearSession } = useAuth()
    const { bootAnonymous } = useIntercom()

    const router = useRouter()
    const route = useRoute()

    const emailInput = ref()
    const passwordInput = ref()
    const loading = ref(false)

    // TODO: Don't use state above until oInput is upgraded
    const login = async ({
        email,
        password,
    }: {
        email: string
        password: string
    }): Promise<{ token: string; id: number } | undefined> => {
        loading.value = true

        // Attempt login request
        const res = await noAuthRequest<LoginResponse>('/login', {
            username: email,
            password: password,
        })

        if (res.err) {
            // We use the same error message in both cases for better security
            if (res.err === 'unknown email' || res.err === 'incorrect password') {
                passwordInput.value.setError('Incorrect email or password')
            } else {
                // Fallback in case of server error
                passwordInput.value.setError(
                    'Something went wrong, please try again or contact support'
                )
            }
            loading.value = false
            return
        }

        const { token, user_info } = res
        const { user_id, username } = user_info.profile

        // Save credentials to localstorage
        setCredentials(token.token, user_id)

        pushToDatalayer('gtm_active_login', {
            user_attributes: {
                user_id,
                email: username,
                first_name: (user_info.profile.name ?? '').split(' ')[0],
                last_name: (user_info.profile.name ?? '').split(' ')[1],
            },
        })

        // Redirect to original destination if "to" query params exists
        if (route.query.to) {
            router.push({ path: route.query.to as string })
        } else {
            // Redirect to account centre
            router.push({ name: Routes.AccountCentre, params: { id: user_id } })
        }
    }

    // Revalidate the password input manually on every change of the email input
    // to clear login errors on email change.
    const validatePassword = () => passwordInput.value.validate()

    const logout = () => {
        clearSession()
        bootAnonymous()
    }

    return {
        loading,
        login,
        logout,
        emailInput,
        passwordInput,
        validatePassword,
    }
}
