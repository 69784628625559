<template>
    <!-- Account Profile Preview -->
    <div class="account-profile-preview" v-if="newAccountName">
        <Avatar
            :color="newAccountColor"
            :width="40"
            :height="40"
            :platform="accountPlatform"
            show-account-type
        >
            {{ accountInitials }}
        </Avatar>
        <Text :style="{ color: newAccountColor }" as="span" class="account-name">
            {{ newAccountName }}
        </Text>
    </div>
    <div class="account-profile-preview" v-else>
        <Skeleton :width="40" :height="40" />
        <div class="client-selector">
            <Skeleton :width="100" />
        </div>
    </div>
    <!-- Account Profile Form -->
    <oForm class="account-profile-form" @submitted="updateAccountProfile">
        <oInput
            ref="accountNameInput"
            :model-value="accountName"
            label="Account Name"
            name="accountName"
            @updated="newAccountName = $event"
            autofocus
            :validator="nameValidator"
            spellcheck="false"
        />
        <Spacer height="1.5rem" />
        <Label>Account Colour</Label>
        <div tabindex="0" class="colour-circle-container">
            <div
                v-for="color in domainColours"
                :key="color.name"
                :style="{ backgroundColor: color.hex }"
                class="colour-circle"
                @click="newAccountColor = color.hex"
                @keydown.enter="newAccountColor = color.hex"
                tabindex="0"
            >
                <svg
                    v-show="color.hex === newAccountColor"
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m1.5 4.5 2 2 5-5"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </div>
        </div>
        <div v-if="showUpdateButton" class="account-profile-button-container">
            <oButton
                ref="updateAccountProfileButton"
                :loading="updatingAccountProfile"
                :disabled="accountProfileInvalid"
                size="medium"
                type="submit"
            >
                Update Account Profile
            </oButton>
        </div>
    </oForm>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { Text, Spacer, oInput, Avatar, Label, oButton, oForm } from '@opteo/components-next'
import { Account } from '@opteo/types'

import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { useAccountList } from '@/composition/user/useAccountList'
import Skeleton from '@/components/util/Skeleton.vue'
import { useAccount } from '@/composition/account/useAccount'

defineProps<{ showUpdateButton?: boolean }>()

const domainColours = Account.Colours

const { mutateAccount, accountColor, accountName, accountInitials, accountPlatform } = useAccount()
const { mutateDomainList } = useAccountList()

const accountNameInput = ref()
const updateAccountProfileButton = ref()

// Set default values
const newAccountName = ref(accountName.value)
const newAccountColor = ref(accountColor.value)

watch(accountColor, () => {
    newAccountColor.value = accountColor.value
})

const updatingAccountProfile = ref(false)

function nameValidator(name: string) {
    const strippedName = name.trim()
    if (!strippedName) return 'Account name is required'
}

const accountProfileInvalid = computed(() => {
    return !accountNameInput.value?.valid || !newAccountColor.value || !accountName.value
})

async function updateAccountProfile() {
    if (accountProfileInvalid.value) {
        throw new Error('Invalid account name or colour')
    }

    if (
        newAccountName.value === accountName.value &&
        newAccountColor.value === accountColor.value
    ) {
        updateAccountProfileButton.value?.flashSuccess()
        return
    }

    updatingAccountProfile.value = true

    await Promise.all([
        newAccountName.value && newAccountName.value !== accountName.value
            ? authRequest(Endpoint.SaveAccountDisplayName, {
                  body: { display_name: newAccountName.value.trim() },
              })
            : undefined,
        newAccountColor.value !== accountColor.value
            ? authRequest(Endpoint.SaveAccountColorPreference, {
                  body: { color_preference: newAccountColor.value },
              })
            : undefined,
    ])

    await mutateAccount()
    updatingAccountProfile.value = false
    updateAccountProfileButton.value?.flashSuccess()
    // Refresh account list so it includes the new name
    mutateDomainList()
}

// This is needed to trigger the function from the parent component
defineExpose({
    updateAccountProfile,
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.account-profile-preview {
    @include flex-center;
    width: 100%;
    border-bottom: 1px solid $opteo-light-gray;
    @include pv-56;
    gap: 1rem;
}
.account-profile-preview .account-name {
    font-size: 1.5rem;
    line-height: 1.875rem;
    letter-spacing: -0.04rem;
    @include fw-600;
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.account-profile-form {
    @include pa-32;
}
.colour-circle-container {
    @include container;
    border-radius: 0.875rem;
    height: 2.75rem;
    @include flex;
    @include items-center;
    padding-left: 0.875rem;
    gap: 0.375rem;
    outline: none;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
.colour-circle-container:focus {
    box-shadow: $opteo-shadow-focus;
}
.colour-circle {
    @include br-999;
    @include flex-center;
    width: 1.375rem;
    height: 1.375rem;
    cursor: pointer;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    outline: none;
}
.colour-circle:focus {
    box-shadow: $opteo-shadow-focus-ring;
}

.account-profile-button-container {
    @include flex-center;
    @include ph-32;
    @include pt-32;
}
</style>
