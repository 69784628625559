<template>
    <button ref="chatAccountSelector" class="chat-account-selector-container">
        <div class="chat-account-selector">
            <Avatar
                v-if="selectedAccount?.name"
                :color="selectedAccount.color_preference"
                :platform="Platform.Platform.GoogleAds"
                show-account-type
            >
                {{ getDomainInitials(selectedAccount.name) }}
            </Avatar>
            <Skeleton v-else :width="36" :height="36" />

            <p
                v-if="selectedAccount?.name"
                class="client-name"
                :style="{ color: selectedAccount.color_preference }"
            >
                {{ selectedAccount?.name }}
            </p>
            <SelectArrowIcon
                v-if="selectedAccount?.name"
                :hex="selectedAccount.color_preference"
                class="chat-account-selector-arrow"
            />
            <div class="chat-account-selector-skeleton" v-else>
                <Spacer width="0.75rem" />
                <Skeleton :width="Math.random() * 80 + 100" />
            </div>
        </div>
    </button>

    <div ref="chatAccountSelectorContent" class="select-client-dropdown">
        <!-- Search Bar -->
        <div class="select-client-dropdown-search-bar">
            <div class="select-client-dropdown-search-button">
                <svg width="14" height="14">
                    <path
                        d="M10.0317379 8.6175243l3.503796 3.503796-1.4142136 1.4142136-3.503796-3.503796C7.7314831 10.642436 6.6574983 11 5.5 11 2.4624339 11 0 8.5375661 0 5.5S2.4624339 0 5.5 0 11 2.4624339 11 5.5c0 1.1574983-.357564 2.2314831-.9682621 3.1175243zM5.5 9C7.4329966 9 9 7.4329966 9 5.5S7.4329966 2 5.5 2 2 3.5670034 2 5.5 3.5670034 9 5.5 9z"
                        fill="#006dff"
                        fill-rule="evenodd"
                    />
                </svg>
            </div>
            <div class="select-client-dropdown-search">
                <oInput
                    ref="searchInput"
                    v-model="domainSearch"
                    placeholder="Search.."
                    type="text"
                    class="select-client-dropdown-search-input"
                    name="accountSearchInput"
                    autocomplete="off"
                    @keydown.enter.stop.prevent="selectAccount(displayAccountsList[0].accountId)"
                />
            </div>
        </div>
        <!-- Client List -->
        <perfect-scrollbar class="select-client-dropdown-scroll-container">
            <div class="w-100 relative">
                <div
                    v-for="(domain, index) in displayAccountsList"
                    id="clientItem"
                    :key="domain.accountId"
                    :class="[!index ? 'hit-enter' : '']"
                    class="select-client-dropdown-client"
                    :style="{ color: domain.color }"
                    @click="selectAccount(domain.accountId)"
                >
                    <Avatar
                        :color="domain.color"
                        :platform="domain.platform"
                        show-account-type
                        account-type-size="small"
                    >
                        {{ getDomainInitials(domain.name) }}
                    </Avatar>
                    <span class="select-client-dropdown-client-name">
                        {{ domain.name }}
                    </span>
                </div>
                <div v-if="displayAccountsList?.length === 0" class="empty-state-container">
                    <img
                        src="@/assets/img/no-results-small.png"
                        class="empty-state-image"
                        alt="No accounts found"
                    />
                    <div class="empty-state-text">
                        We couldn't find an account matching your search query.
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onMounted, watch } from 'vue'
import { useTippy } from 'vue-tippy'
import sortBy from 'lodash-es/sortBy'
import { oForm, oInput, Spacer, SelectArrowIcon, Avatar } from '@opteo/components-next'
import { Platform, Account } from '@opteo/types'
import { useRouter } from 'vue-router'

import { useAccountList } from '@/composition/user/useAccountList'
import { getDomainInitials } from '@/composition/domain/useDomain'
import Skeleton from '@/components/util/Skeleton.vue'
import { searchForAccountMatcher } from '@/lib/search'

export default defineComponent({
    name: 'ChatAccountSelector',
    components: { Avatar, Skeleton, oForm, oInput, Spacer, SelectArrowIcon },
    emits: ['account-selected'],
    setup(_, { emit }) {
        const { sortedDomainList, accountList } = useAccountList()
        const router = useRouter()

        const chatAccountSelector = ref()
        const chatAccountSelectorContent = ref()

        const searchInput = ref()
        const domainSearch = ref('')

        const { hide: hideTippy, show: showTippy } = useTippy(chatAccountSelector, {
            content: () => chatAccountSelectorContent.value,
            theme: 'chat-account-selector',
            placement: 'bottom-start',
            offset: [-1, -4],
            maxWidth: 340,
            zIndex: 999999,
            touch: true,
            interactive: true,
            animateFill: true,
            trigger: 'click',
            popperOptions: {
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            padding: 0,
                        },
                    },
                ],
            },
            onMount: () => {
                searchInput.value?.inputRef.focus()
                document.body.classList.add('no-scroll')
            },
            onHidden: () => {
                domainSearch.value = ''
                document.body.classList.remove('no-scroll')
            },
            onClickOutside: (instance, event) => {
                const clickedElement = event.target as HTMLElement
                if (!!clickedElement.closest('[data-tippy-root]')) return
                hideTippy()
            },
        })

        const displayAccountsList = computed<Account.Info[]>(() => {
            if (!sortedDomainList.value) return []

            return sortBy(
                sortedDomainList.value.filter(
                    domain =>
                        searchForAccountMatcher(domainSearch.value, domain) &&
                        domain.accountId !== selectedAccount.value?.accountId &&
                        domain.platform === Platform.Platform.GoogleAds // Chat only supports gads accounts
                ),
                // @ts-ignore TODO: Update Domain.Info.last_visited to string
                d => -Date.parse(d.lastVisitedAt)
            ).slice(0, 20)
        })

        // Simple `selectedAccount` interface
        interface SelectedAccount {
            accountId: Account.ID
            name: string
            color_preference?: string
        }

        const selectedAccount = ref<SelectedAccount>()

        // TODO(accounts-migration): Make this accountId
        const selectAccount = async (accountId: Account.ID) => {
            const account = sortedDomainList.value.find(a => a.accountId === accountId)

            if (!account) {
                throw new Error('Account not found')
            }

            selectedAccount.value = {
                accountId: account.accountId,
                name: account.name,
                color_preference: account.color,
            }

            hideTippy()

            emit('account-selected', selectedAccount.value.accountId)
        }

        onMounted(() => init())

        watch(accountList, (newVal, oldVal) => {
            if (!oldVal && newVal) init() // we had nothing, but new data just came in -- initialize!
        })

        const init = () => {
            if (selectedAccount.value) return // Already have an account selected

            if (!accountList.value) return // Data not ready yet -- will be called again via watch()

            selectAccount(displayAccountsList.value[0].accountId) // Set most recently visited domain as selectedAccount on load

            if (router.currentRoute.value?.params?.accountId) {
                const account = displayAccountsList.value.find(a => {
                    return a.accountId === router.currentRoute.value.params.accountId
                })

                if (!account) return
                selectAccount(account.accountId)
            }
        }

        return {
            chatAccountSelector,
            chatAccountSelectorContent,
            searchInput,
            domainSearch,
            displayAccountsList,
            getDomainInitials,
            selectAccount,
            Platform,
            selectedAccount,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chat-account-selector-container {
    outline: none;
    border: none;
    @include bg-opteo-white;
    @include no-select;
    @include flex;
    @include items-center;
    box-shadow: $opteo-shadow;
    padding: 1rem 1.125rem;
    @include br-16;
    transition:
        box-shadow,
        transform cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    width: 100%;
    @include relative;
    cursor: pointer;
}
.chat-account-selector-container:focus {
    box-shadow: $opteo-shadow-focus;
    outline: none;
}
.chat-account-selector-container:active {
    transform: translate3d(0, 1px, 0);
    box-shadow: $opteo-shadow-focus;
    outline: none;
}
.chat-account-selector {
    @include flex;
    @include items-center;
    width: 100%;
}
.chat-account-selector-skeleton {
    @include flex;
    @include items-center;
}
.client-name {
    @include f-7;
    @include fw-500;
    margin-left: 1rem;
    transform: translateY(-1px);
    white-space: nowrap;
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.chat-account-selector-arrow {
    @include absolute;
    right: 1.125rem;
}
.select-client-dropdown {
    @include z-max;
    @include opteo-background;
    @include br-20;
    box-shadow: $opteo-shadow-xl;
    width: 18.25rem;
    overflow: hidden;
}
.select-client-dropdown-search-bar {
    @include flex;
    @include items-center;
    border-bottom: 1px solid $opteo-light-gray;
    padding: 1rem 1.5rem;
}
.select-client-dropdown-search-button {
    @include br-999;
    @include flex;
    @include items-center;
    @include justify-center;
    box-shadow: $opteo-shadow;
    width: 2.25rem;
    height: 2.25rem;
    flex-shrink: 0;
}
.select-client-dropdown-search-input {
    width: 12rem;
}
.select-client-dropdown-search {
    @include ml-16;
}
.select-client-dropdown-scroll-container {
    height: 100%;
    max-height: 17.125rem;
}
// Scrollbar styles
.select-client-dropdown-scroll-container :deep(.ps__thumb-y) {
    background: #cfd0d2;
}

.select-client-dropdown-client {
    @include ph-24;
    @include pv-16;
    @include flex;
    @include items-center;
    @include relative;
    @include pointer;
    border-bottom: 1px solid $opteo-light-gray;
}
.select-client-dropdown-client:last-child {
    border-bottom: none;
}
.select-client-dropdown-client:hover {
    background: $opteo-lighter-gray;
}
.select-client-dropdown-client-name {
    @include f-8;
    @include fw-500;
    @include lh-150;
    margin-left: 1rem;
    max-width: 10.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media screen and (min-width: $mq-1025-min) {
    .hit-enter::after {
        content: 'Enter';
        @include absolute;
        @include bg-opteo-white;
        border-radius: 0.375rem;
        right: 1.5rem;
        font-size: 0.625rem;
        color: #000;
        box-shadow: $opteo-shadow;
        height: auto;
        padding: 6px 8px 6px 8px;
        z-index: 2;
    }
    .hit-enter::before {
        content: '';
        background: white;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 25%);
        width: 5rem;
        right: 1rem;
        height: 100%;
        position: absolute;
        z-index: 1;
    }
}

.select-client-dropdown-client:hover::before {
    background: $opteo-lighter-gray;
    background: linear-gradient(90deg, rgba(250, 250, 253, 0) 0%, $opteo-lighter-gray 25%);
}
.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 16.5rem;
}
.empty-state-image {
    width: 4.375rem;
    @include mb-16;
}
.empty-state-text {
    @include f-9;
    @include lh-150;
    @include tc;
    width: 11rem;
}
</style>

<style>
.tippy-box[data-theme~='chat-account-selector'] {
    overflow: visible !important;
}
</style>
