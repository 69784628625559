<template>
    <svg
        width="104"
        height="104"
        viewBox="0 0 104 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 51.2c0-17.9217 0-26.8825 3.48779-33.7277C6.55574 11.4511 11.4511 6.55574 17.4723 3.48779 24.3175 0 33.2783 0 51.2 0h1.6c17.9217 0 26.8825 0 33.7277 3.48779 6.0212 3.06795 10.9166 7.96331 13.9843 13.98451C104 24.3175 104 33.2783 104 51.2v1.6c0 17.9217 0 26.8825-3.488 33.7277-3.0677 6.0212-7.9631 10.9166-13.9843 13.9843C79.6825 104 70.7217 104 52.8 104h-1.6c-17.9217 0-26.8825 0-33.7277-3.488-6.0212-3.0677-10.91656-7.9631-13.98451-13.9843C0 79.6825 0 70.7217 0 52.8v-1.6Z"
            fill="url(#paint0_radial_4616_34540)"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22 51.7656c0-1.6391 1.4033-3.0468 3.0469-3.0468 1.8227 0 3.2243 1.4077 3.2812 3.0468C28.2712 65.0096 38.9156 75.7011 52 75.6719c13.0844.0292 23.7288-10.6623 23.6719-23.9063.0569-1.6391 1.4585-3.0468 3.2812-3.0468 1.6436 0 3.0469 1.4077 3.0469 3.0468C82 68.4824 68.5419 82 52 82S22 68.4824 22 51.7656Z"
            fill="#0035EC"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M75.6719 52.2344C75.7288 38.9904 65.0844 28.2989 52 28.3281c-13.0844-.0292-23.7288 10.6623-23.6719 23.9063-.0569 1.6391-1.4585 3.0468-3.2812 3.0468-1.6436 0-3.0469-1.4077-3.0469-3.0468C22 35.5176 35.4581 22 52 22s30 13.5176 30 30.2344c0 1.6391-1.4033 3.0468-3.0469 3.0468-1.8227 0-3.2243-1.4077-3.2812-3.0468Z"
            fill="#73D8FF"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M75.9057 53.6406C71.015 44.6732 61.4318 40.6874 51.5307 40.75c-9.318-.0626-18.4697 3.7883-23.4375 12.8906-1.1211 1.3414-3.0349 1.8462-4.6875.9375-1.3453-.8281-1.8505-2.7437-.9375-4.4531 6.2932-10.6587 17.5219-15.7031 29.0625-15.7031 12.0903 0 23.8032 5.2091 30 15.7031.913 1.7094.4078 3.625-.9375 4.4531-.6493.3303-1.1913.4688-1.6407.4688-1.1663 0-2.217-.5574-3.0468-1.4063Z"
            fill="#3FBBFF"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.4697 53.8742c-.9124-1.7097-.4072-3.6271.9375-4.4531 1.6463-.9126 3.5672-.4078 4.6875.9375 4.8862 8.9689 14.4749 12.9531 24.375 12.8906 9.3176.0625 18.4712-3.7885 23.4375-12.8906 1.1209-1.3453 3.0435-1.8431 4.6875-.9375 1.3452.826 1.8505 2.7434.9375 4.4531-6.2899 10.6604-17.5188 15.7032-29.0625 15.7032-12.0929 0-23.806-5.2093-30-15.7032Z"
            fill="#006DFF"
        />
        <defs>
            <radialGradient
                id="paint0_radial_4616_34540"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(0 114.5 -114.5 0 52 39)"
            >
                <stop stop-color="#fff" />
                <stop offset="1" stop-color="#F1F1FE" />
            </radialGradient>
        </defs>
    </svg>
</template>
