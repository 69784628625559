<template>
    <div :style="{ width: `${width}px`, height: `${width}px` }" :class="['score-container']">
        <div class="donut">
            <svg
                :width="width"
                :height="width"
                :viewBox="`0 0 ${width} ${width}`"
                style="transform: rotate(-90deg)"
            >
                <circle
                    :style="{ stroke: customColor ? backstrokeColor : undefined }"
                    :stroke-width="strokeWidth"
                    :cx="middle"
                    :cy="middle"
                    :r="radius"
                    :class="backstrokeColor"
                    fill="none"
                />
                <circle
                    :style="{
                        'stroke-dashoffset': circumference * (1 - score / 100),
                        stroke: scoreColor,
                    }"
                    :stroke-width="strokeWidth"
                    :stroke-dasharray="circumference"
                    :cx="middle"
                    :cy="middle"
                    :r="radius"
                    class="donut-stroke"
                    fill="none"
                />
            </svg>
        </div>

        <div :class="[compact ? 'compact' : '', 'center-content-container']">
            <div class="center-content">
                <!-- Score -->
                <span
                    v-if="!hideScore"
                    :style="{ color: scoreColor }"
                    :class="[compact ? 'compact' : '', 'score-figure']"
                >
                    {{ scoreRounded }}
                </span>
                <Spacer v-if="!compact" height="0.25rem" />
                <!-- Metric Name -->
                <Text v-if="!compact" as="p" size="f-9" weight="500">{{ name }}</Text>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { oButton, Spacer, Text, Tooltip } from '@opteo/components-next'

export default defineComponent({
    name: 'ScorecardDonut',
    components: {
        oButton,
        Spacer,
        Text,
        Tooltip,
    },
    props: {
        name: {
            type: String,
            required: false,
        },
        score: {
            type: Number,
            required: false,
            default: 0,
        },
        hideScore: {
            type: Boolean,
            required: false,
            default: false,
        },
        width: {
            type: Number,
            default: 256,
        },
        strokeWidth: {
            type: Number,
            required: false,
            default: 12,
        },
        hidden: {
            type: Boolean,
        },
        compact: {
            type: Boolean,
            default: false,
            required: false,
        },
        compactTextSize: {
            type: String,
            default: '0.8125rem',
            required: false,
        },
        // Must be HEX
        customColor: {
            type: String,
            required: false,
        },
    },
    computed: {
        middle() {
            return this.width / 2
        },
        radius() {
            return this.width / 2 - this.strokeWidth
        },
        circumference() {
            return 2 * Math.PI * this.radius
        },
        small() {
            return this.radius < 106 ? true : false
        },
        scoreRounded() {
            return (this.score ?? 0).toFixed()
        },

        scoreColor() {
            if (this.customColor) {
                return this.customColor
            }
            if (this.hidden) {
                return '#e6e6e6'
            }
            if (+this.scoreRounded >= 70) {
                return '#00A367'
            }
            if (+this.scoreRounded > 50) {
                return '#FF9500'
            }
            return '#FF2828'
        },
        backstrokeColor() {
            if (this.customColor) {
                return this.customColor + '1A'
            }
            if (this.hidden) {
                return 'lighter-grey-stroke '
            }
            if (+this.scoreRounded >= 70) {
                return 'lighter-green-stroke '
            }
            if (+this.scoreRounded > 50) {
                return 'lighter-amber-stroke '
            }
            return 'lighter-red-stroke '
        },
    },
    beforeMount() {
        this.$emit('scoreColor', this.scoreColor)
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.score-container {
    @include relative;
    @include w-100;
    @include block;
    flex-shrink: 0;
}

.donut {
    @include absolute;
    top: 0;
    left: 0;
}
.donut svg {
    @include block;
}
.center-content-container {
    @include h-100;
    @include w-100;
    @include flex;
    @include items-center;
    @include justify-center;
    z-index: 1;
    transform: translateY(-4px);
}

.center-content-container.compact {
    transform: translateY(0);
}

.center-content {
    @include flex;
    flex-direction: column;
    @include items-center;
}

.score-figure {
    font-size: 4.5625rem;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.035em;
    font-weight: 600;
}
.score-figure.compact {
    font-size: v-bind(compactTextSize);
    line-height: 2.25rem;
    font-weight: 600;
}

.donut-stroke {
    stroke-linecap: round;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.lighter-grey-stroke {
    stroke: #e6e6e6;
}
.lighter-green-stroke {
    stroke: $opteo-green-translucent;
}
.lighter-amber-stroke {
    stroke: $opteo-amber-translucent;
}
.lighter-red-stroke {
    stroke: $opteo-red-translucent;
}
</style>
