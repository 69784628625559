import { computed, ref } from 'vue'
import { useNGramAddNegatives } from './useNGramAddNegatives'
import { useNGramTool } from './useNGramTool'
import { PanelItem } from './types'

export enum ActivePanel {
    Keywords = 'keywords',
    SearchTerms = 'search-terms',
}

const activePanel = ref<ActivePanel>()
/**
 * Used in the ActionsCell component to open a single item's panel.
 * This item can be an N-Gram or a Search Term.
 */
const singleItemPanel = ref<PanelItem>()

export function useNgramPanels() {
    function setPanel(panel: ActivePanel, item?: PanelItem) {
        activePanel.value = panel
        if (item) {
            singleItemPanel.value = item
        }
    }

    function closePanel() {
        activePanel.value = undefined
        if (singleItemPanel.value) {
            singleItemPanel.value = undefined
        }
    }

    return {
        setPanel,
        activePanel,
        closePanel,
        singleItemPanel,
    }
}
