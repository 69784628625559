import type { TextColor } from '@opteo/components-next'

export function scoreColor(score: number): { name: TextColor; hex: string } {
    if (score >= 70) {
        return { name: 'green', hex: '#00a861' }
    } else if (score > 50) {
        return { name: 'amber', hex: '#FF9500' }
    } else {
        return { name: 'red', hex: '#FF2828' }
    }
}
