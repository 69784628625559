<template>
    <div class="container">
        <Text as="p" :size="font.size" :weight="font.weight" :color="disabled ? 'gray' : 'black'">
            {{ left.name }}
        </Text>
        <Spacer width="0.5rem" />
        <div class="chart-container">
            <div class="circles">
                <div :class="[`circle`, left.color, { disabled: disabled }]"></div>
                <div :class="[`circle`, right.color, { disabled: disabled }]"></div>
            </div>
        </div>
        <Spacer width="0.5rem" />
        <Text as="p" :size="font.size" :weight="font.weight" :color="disabled ? 'gray' : 'black'">
            {{ right.name }}
        </Text>
    </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { Text, Spacer } from '@opteo/components-next'

export interface Props {
    disabled?: boolean
    size: 'small' | 'large'
    left: {
        color: string
        name: string
    }
    right: {
        color: string
        name: string
    }
}
const props = withDefaults(defineProps<Props>(), {
    diabled: false,
    size: 'large',
    left: {
        color: 'blue',
        name: 'Left',
    },
    right: {
        color: 'blue',
        name: 'Right',
    },
})

const font = computed(() => {
    if (props.size === 'large') {
        return {
            size: 'f-7',
            weight: '500',
        }
    }
    return {
        size: 'f-8',
        weight: '400',
    }
})
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.container {
    @include flex;
    @include items-center;
    flex-wrap: wrap;

    .chart-container {
        width: 24px;
        @include flex;
        @include items-center;
        @include justify-between;

        .circles {
            @include relative;
            @include flex-center;
            mix-blend-mode: multiply;

            .circle {
                width: 1rem;
                height: 1rem;
                opacity: 0.68;
                border-radius: 100%;
                flex-shrink: 0;
            }
            .circle:last-child {
                transform: translateX(-0.5rem);
            }
            .circle.disabled {
                opacity: 0.48;
            }
        }
    }
}

.pink {
    background-color: $opteo-pink;
}
.green {
    background-color: $opteo-green;
}
.purple {
    background-color: $opteo-purple;
}
</style>
