import { useIntercom } from '@/lib/intercom/useIntercom'
import { useProfitwell } from '@/lib/profitwell/useProfitwell'

export function useApp() {
    const { bootAnonymous } = useIntercom()

    bootAnonymous()

    useProfitwell()
}
