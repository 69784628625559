<template>
    <Modal
        id="alert-modal"
        v-model="alertModalOpen"
        title="Alert"
        @closed="onModalClosed"
        @opened="onModalOpened"
        width="538px"
    >
        <template #content>
            <Alert />
            <!-- Spacer -->
            <Spacer height="2rem" />
            <router-link :to="fullPageAlertRoute" @click="alertModalOpen = false">
                <oButton color="white">Open Alert in Full Page</oButton>
            </router-link>
        </template>
    </Modal>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'

import { Modal, oButton, Spacer } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import Alert from '@/components/Alerts/Alert.vue'
import { provideAlert, setFloatingAlertId } from '@/composition/alerts/useAlert'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

export default defineComponent({
    components: {
        Modal,
        oButton,
        Spacer,
        Skeleton,
        Alert,
    },
    setup() {
        const { alert, markAsRead, alertModalOpen } = provideAlert()

        const { currentRoute } = useRouter()
        const isFullScreenAlert = computed(() => {
            return !!currentRoute.value.params.alertId
        })

        watch(alert, newVal => {
            if (newVal && !isFullScreenAlert.value) {
                alertModalOpen.value = true
            } else {
                alertModalOpen.value = false
            }
        })

        const onModalOpened = () => {
            markAsRead()
        }

        const onModalClosed = () => {
            setFloatingAlertId(undefined)
        }

        const fullPageAlertRoute = computed(() => {
            return {
                name: Routes.Alert,
                params: {
                    alertId: alert.value?.hashId,
                },
            }
        })

        return { alertModalOpen, onModalClosed, onModalOpened, alert, fullPageAlertRoute }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
</style>
