import {
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { computed, ref } from 'vue'

interface UseFixBrokenSitelink {
    reason: string
    copy: { spread: string; campaign: string }
    formattedCampaignNames: {
        content: string
        type: string
    }[]
    url: string
}

interface Body {
    is_sitelink: boolean
    url: string
    path: string
    reason: string
    affected_campaigns: string[]
}

export function useFixBrokenSitelink(): UseImprovement<UseFixBrokenSitelink> {
    const { improvement, lastUpdated, title } = useImprovement<Body>()

    const {
        body: { url, reason, affected_campaigns: campaignNames },
    } = checkImprovement(improvement)

    const singleCampaign = campaignNames.length === 1
    const copy = {
        spread: singleCampaign ? 'in' : 'across',
        campaign: singleCampaign ? 'campaign' : 'campaigns',
    }

    const formattedCampaignNames = campaignNames.map(campaign => {
        return { content: campaign, type: 'campaign' }
    })

    const pushActionText = ref('Recheck Sitelink')
    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Checking broken link..',
        'Confirming link status..',
        'Broken link fixed successfully.',
    ])

    return {
        title,
        lastUpdated,
        pushMessages,
        reason,
        copy,
        formattedCampaignNames,
        url,
        pushActionText,
    }
}
