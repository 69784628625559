import range from 'lodash-es/range'
import { computed, inject, onMounted, onUnmounted, provide, ref, watch, watchEffect } from 'vue'

import { ProvideKeys } from '@/composition/useProvide'
import { pmap } from '@opteo/promise'

import { ImprovementGroup, provideActiveImprovements } from './useActiveImprovements'
import { useDismissedImprovements } from './useDismissedImprovements'
import { useDismissImprovement } from './useDismissImprovement'
import { useImprovementQueue } from './useImprovementQueue'

import type { DismissDuration } from './types'
import type { QueuedImprovement } from '@/composition/improvement/useImprovementQueue'
import { useAccount } from '../account/useAccount'

const IMPROVEMENT_REFRESH_MS = 30_000 // refresh imps list every 30s
const FINISHED_IMPROVEMENT_STATES = ['pushed', 'dismissed']

export function provideBatchBox() {
    const { mutateAccount } = useAccount()
    const {
        improvements,
        improvementSort,
        mutateImprovementsList,
        removeImprovements,
        updateImprovementSort,
    } = provideActiveImprovements()
    const { dismissImprovement } = useDismissImprovement()
    const { refreshDismissedList } = useDismissedImprovements()

    const {
        queuedImprovements,
        queueDone,
        currentImpBatchPushingId,
        pushToImprovementQueue,
        pushQueue,
    } = useImprovementQueue()

    /*
        Batched improvements stateful properties 
    */
    const batchedImprovements = ref<QueuedImprovement[]>([])

    watchEffect(() => {
        batchedImprovements.value = queuedImprovements.value.filter(
            queuedImprovement => queuedImprovement.pushingFrom === 'batchBar'
        )
    })

    const currentImpBatchPushing = computed(() => {
        return queuedImprovements.value.find(i => i.id === currentImpBatchPushingId.value)
    })

    const batchQueueRunning = computed(() =>
        batchedImprovements.value.some(
            batchedImprovement => batchedImprovement.id === currentImpBatchPushing?.value?.id
        )
    )

    const batchDone = computed(() => {
        const areAllImprovementsDone = batchedImprovements.value.every(batchedImprovement =>
            [...FINISHED_IMPROVEMENT_STATES, 'skipped'].some(
                finishedState => finishedState === batchedImprovement.state
            )
        )

        return (queueDone.value || areAllImprovementsDone) && !!batchedImprovements.value.length
    })

    /*
        Improvements batch selection & de-selection, with support for shift-click
    */
    const toggleSelection = (id: number, selected: boolean, withShift?: boolean) => {
        if (!selected) {
            unSelect(id)
            return
        }

        // Important: clear the batch queue of already-pushed imps when selecting a new improvement.
        if (batchDone.value) {
            cleanupBatch()
        }
        select(id, withShift)
    }

    let shiftStartId: number = -1

    const select = async (id: number, withShift?: boolean) => {
        const _select = (id: number) => {
            if (queuedImprovements.value.find(q => q.id === id)) {
                // Do nothing if already selected
                return
            }

            pushToImprovementQueue({ improvementId: id, pushingFrom: 'batchBar' })
        }

        _select(id)
        if (withShift) {
            // find IDs between shiftStartId and id, and select them
            let startIndex = improvements.value.findIndex(i => i.improvement_id === shiftStartId)
            if (startIndex === -1) {
                shiftStartId = -1 // something is wrong, reset
            }
            let endIndex = improvements.value.findIndex(i => i.improvement_id === id)

            range(startIndex, endIndex).forEach(index =>
                _select(improvements.value[index].improvement_id)
            )
        } else {
            // if normal click, then make this the new "start" of subsequent shift-clicks
            shiftStartId = id
        }
    }

    const unSelect = async (id: number) => {
        queuedImprovements.value = queuedImprovements.value.filter(q => q.id !== id)
    }

    const toggleImprovementGroup = (impGroup: ImprovementGroup) => {
        const allSelected = impGroup.improvementsInGroup
            .map(i => !!queuedImprovements.value.find(q => q.id === i.improvement_id))
            .every(t => t)

        for (const imp of impGroup.improvementsInGroup) {
            toggleSelection(imp.improvement_id, !allSelected)
        }
    }

    /*
        Push batch to queue
    */
    const pushBatch = async () => {
        await pushQueue({ pushingFrom: 'batchBar' })
    }

    /*
        Mass dismiss
    */
    const dismissBatch = async (duration: DismissDuration) => {
        for (const imp of queuedImprovements.value) {
            imp.state = 'working'
        }

        await pmap(
            queuedImprovements.value,
            imp => {
                return dismiss(imp.id, duration)
            },
            { concurrency: 5 }
        )

        for (const imp of queuedImprovements.value) {
            imp.state = 'dismissed'
        }

        refreshDismissedList()
        mutateAccount()
    }

    const dismiss = async (id: number, duration: DismissDuration) => {
        await dismissImprovement(id, duration)
    }

    /*
        Clear batch
    */
    const clearBatch = () => {
        // Clearing from the overall queue, batch will also be cleared due to the watchEffect linking these two lists
        queuedImprovements.value = queuedImprovements.value.filter(queuedImprovement => {
            const isInBatch = batchedImprovements.value.some(
                batchedImprovement => batchedImprovement.id === queuedImprovement.id
            )

            return !isInBatch
        })
    }

    watch(improvementSort, () => {
        // Clear the queue when the sorting/grouping changes
        clearBatch()
    })

    const cleanupBatch = () => {
        /*
            1. remove pushed & dismissed imps from list
            2. clearQueue
        */
        const idsToRemove = queuedImprovements.value
            .filter(i => FINISHED_IMPROVEMENT_STATES.includes(i.state))
            .map(i => i.id)

        removeImprovements(idsToRemove)

        clearBatch()
    }

    /*
        Refresh imp list on schedule
    */
    let improvementCheckInterval = 0
    onMounted(() => {
        improvementCheckInterval = window.setInterval(() => {
            if (!queuedImprovements.value.length) {
                // only mutate if there's nothing in the queue right now.
                mutateImprovementsList() // comment to disable refresh
            }
        }, IMPROVEMENT_REFRESH_MS)
    })
    onUnmounted(() => clearInterval(improvementCheckInterval))

    const toInject = {
        batchedImprovements,
        currentImpBatchPushing,
        batchQueueRunning,
        batchDone,
        improvementSort,
        toggleSelection,
        toggleImprovementGroup,
        pushBatch,
        dismissBatch,
        clearBatch,
        cleanupBatch,
        updateImprovementSort,
    }

    provide(ProvideKeys.BatchBox, toInject)

    return toInject
}

export function useBatchBox() {
    const injected = inject<ReturnType<typeof provideBatchBox>>(ProvideKeys.BatchBox)

    if (!injected) {
        throw new Error(`batchBox not yet injected, something is wrong. `)
    }

    return injected
}
