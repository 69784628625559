<template>
    <PageContent>
        <div class="toolkit-history-coming-soon">
            <Text as="p" size="f-8">
                Toolkit History is <b>coming soon</b>. You won't be able to use any of the fields on
                this page, but you can start to get a feel for the feature. If there are any
                additions you want to see, or if we missed anything, please let us know.
            </Text>
        </div>
        <div class="tools-history-container">
            <!-- Item 1 -->
            <div class="tool-history-item">
                <div class="tool-history-icon">
                    <CheckIcon />
                </div>
                <Spacer width="1rem" />
                <div class="tool-history-text">
                    <Text as="p" size="f-8">
                        Created 3 Responsive Search Ads with <b>Ad Writer</b>
                    </Text>
                    <Text as="p" size="f-9" color="gray">
                        on Thursday 27th of January 2024 at 11:30am
                    </Text>
                </div>
                <div class="tool-history-button">
                    <oButton color="white" size="small">View Ads</oButton>
                </div>
            </div>
            <!-- Spacer -->
            <Spacer height="1rem" />
            <!-- Item 2 -->
            <div class="tool-history-item">
                <div class="tool-history-icon">
                    <CheckIcon />
                </div>
                <Spacer width="1rem" />
                <div class="tool-history-text">
                    <Text as="p" size="f-8">Created 5 Keywords with <b>N-Gram Generator</b></Text>
                    <Text as="p" size="f-9" color="gray">
                        on Wednesday 26th of January 2024 at 2:38pm
                    </Text>
                </div>
                <div class="tool-history-button">
                    <oButton color="white" size="small">View Keywords</oButton>
                </div>
            </div>
            <!-- Spacer -->
            <Spacer height="1rem" />
            <!-- Item 3 -->
            <div class="tool-history-item">
                <div class="tool-history-icon">
                    <CheckIcon />
                </div>
                <Spacer width="1rem" />
                <div class="tool-history-text">
                    <Text as="p" size="f-8">
                        Created 2 Conversion Types with <b>Conversion Tracking</b>
                    </Text>
                    <Text as="p" size="f-9" color="gray">
                        on Tuesday 25th of January 2024 at 4:52pm
                    </Text>
                </div>
                <div class="tool-history-button">
                    <oButton color="white" size="small">View Conversion Types</oButton>
                </div>
            </div>
            <!-- Spacer -->
            <Spacer height="1rem" />
            <!-- Item 4 -->
            <div class="tool-history-item">
                <div class="tool-history-icon">
                    <CheckIcon />
                </div>
                <Spacer width="1rem" />
                <div class="tool-history-text">
                    <Text as="p" size="f-8">
                        Created 2 Audiences with <b>Audience Generator</b>
                    </Text>
                    <Text as="p" size="f-9" color="gray">
                        on Tuesday 25th of January 2024 at 12:48pm
                    </Text>
                </div>
                <div class="tool-history-button">
                    <oButton color="white" size="small">View Audiences</oButton>
                </div>
            </div>
            <!-- Spacer -->
            <Spacer height="1rem" />
            <!-- Item 5 -->
            <div class="tool-history-item">
                <div class="tool-history-icon">
                    <CheckIcon />
                </div>
                <Spacer width="1rem" />
                <div class="tool-history-text">
                    <Text as="p" size="f-8">Generated 1 Scorecard with <b>Scorecard</b></Text>
                    <Text as="p" size="f-9" color="gray">
                        on Tuesday 25th of January 2024 at 10:48am
                    </Text>
                </div>
                <div class="tool-history-button">
                    <oButton color="white" size="small">View Scorecard</oButton>
                </div>
            </div>
            <!-- Spacer -->
            <Spacer height="1rem" />
            <!-- Item 6 -->
            <div class="tool-history-item">
                <div class="tool-history-icon">
                    <CheckIcon />
                </div>
                <Spacer width="1rem" />
                <div class="tool-history-text">
                    <Text as="p" size="f-8">
                        Created 2 Responsive Search Ads with <b>Ad Writer</b>
                    </Text>
                    <Text as="p" size="f-9" color="gray">
                        on Monday 24th of January 2024 at 12:38pm
                    </Text>
                </div>
                <div class="tool-history-button">
                    <oButton color="white" size="small">View Ads</oButton>
                </div>
            </div>
            <!-- Spacer -->
            <Spacer height="1rem" />
            <!-- Item 7 -->
            <div class="tool-history-item">
                <div class="tool-history-icon">
                    <CheckIcon />
                </div>
                <Spacer width="1rem" />
                <div class="tool-history-text">
                    <Text as="p" size="f-8">Created 8 Keywords with <b>Keyword Tool</b></Text>
                    <Text as="p" size="f-9" color="gray">
                        on Monday 24th of January 2024 at 10:22am
                    </Text>
                </div>
                <div class="tool-history-button">
                    <oButton color="white" size="small">View Keywords</oButton>
                </div>
            </div>
        </div>
    </PageContent>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { oButton, CheckIcon, Text, Spacer } from '@opteo/components-next'

import PageContent from '@/layouts/PageContent.vue'

export default defineComponent({
    components: { PageContent, oButton, CheckIcon, Text, Spacer },
    setup() {
        return {}
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.toolkit-history-coming-soon {
    @include br-12;
    @include ph-32;
    @include pv-28;
    background-color: #e8f0ff;
    @include fixed;
    bottom: 32px;
    left: 50%;
    width: 100%;
    max-width: 416px;
    transform: translateX(calc(-50% + 46px));
    @include z-max;
}
.toolkit-history-coming-soon p {
    color: #0035ec;
}

.tools-history-container {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    @include relative;
}
.tools-history-container::after {
    content: '';
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    @include absolute;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffffff 100%);
    top: -10px;
    left: -10px;
}
.tool-history-item {
    @include flex;
    @include items-center;
    @include container;
    @include pv-20;
    @include ph-24;
    @include relative;
}
.tool-history-button {
    @include absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
}

@media (min-width: 0px) and (max-width: 768px) {
    .tool-history-item {
        @include block;
    }
    .tool-history-button {
        @include absolute;
        right: 1.5rem;
        top: 1.25rem;
        transform: none;
    }
    .tool-history-text p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
