<template>
    <div
        class="ad-container"
        :class="[
            { mobile: isMobileMode },
            { 'full-width': fullWidth },
            { 'hide-border': hideBorder },
        ]"
    >
        <!-- Ad URL -->
        <div class="ad-url-container">
            <b>Ad</b>
            <svg
                style="width: 0.125rem; height: 0.125rem; flex-shrink: 0; outline: none"
                viewBox="0 0 2 2"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="1" cy="1" r="1" fill="#202124" />
            </svg>
            <div class="ad-url">
                <span>{{ displayUrl }}</span>
            </div>
        </div>
        <!-- Headlines -->
        <p :style="{ '-webkit-line-clamp': isMobileMode ? 2 : 1 }" class="headlines">
            <span v-for="(headline, index) in rsaAssets.headlines" class="headline-wrapper">
                <CopyableString v-if="assetsCopyable" :value="headline.text" />
                <span v-else-if="areAdCustomisersStripped">{{
                    stripAdCustomisers(headline.text)
                }}</span>
                <span v-else>{{ headline.text }}</span>
                <span v-if="index + 1 !== rsaAssets.headlines.length">&nbsp;|&nbsp;</span>
            </span>
        </p>
        <!-- Descriptions -->
        <p :style="{ '-webkit-line-clamp': isMobileMode ? 3 : 2 }" class="descriptions">
            <span v-for="(description, index) in rsaAssets.descriptions">
                <CopyableString v-if="assetsCopyable" :value="description.text" />
                <span v-else-if="areAdCustomisersStripped">{{
                    stripAdCustomisers(description.text)
                }}</span>
                <span v-else>{{ description.text }}</span>
                <span v-if="index + 1 !== rsaAssets.headlines.length">&nbsp;</span>
            </span>
        </p>
    </div>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue'
import { RsaWriter, Scorecard } from '@opteo/types'
import { CopyableString } from '@opteo/components-next'
import {
    generateRsaDisplayUrl,
    stripAdCustomisers,
    useRsaPermutations,
    type RsaAssets,
} from '@/components/rsaWriter/useRsa'
type Props = {
    rsa: RsaWriter.RsaAd | RsaWriter.CompetitorAd | Scorecard.WorstRsa
    currentPermutation?: RsaAssets
    generatePermutation?: boolean
    isMobileMode?: boolean
    assetsCopyable?: boolean
    areAdCustomisersStripped?: boolean
    fullWidth?: boolean
    hideBorder?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    generatePermutation: false,
    isMobileMode: false,
    assetsCopyable: false,
    areAdCustomisersStripped: false,
    fullWidth: false,
    hideBorder: false,
})
const displayUrl = computed(() => generateRsaDisplayUrl(props.rsa))
const rsaAssets = computed<RsaAssets>(() => {
    if (props.currentPermutation) {
        return props.currentPermutation
    }
    if (props.generatePermutation) {
        const { currentPermutation } = useRsaPermutations(toRef(props.rsa))
        return currentPermutation.value
    }
    return {
        headlines: props.rsa.headlines,
        descriptions: props.rsa.descriptions,
    }
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.ad-container {
    @include block;
    @include container;
    @include br-20;
    @include pa-40;
    max-width: 42.5rem;
    width: 100%;
    margin: 0 auto;
    font-feature-settings:
        'ss01' off,
        'ss02' off,
        'ss04' off,
        'ss05' off,
        'ss08' off;
}
.ad-container .ad-url-container {
    @include flex;
    @include items-center;
    font-family: Arial, sans-serif;
    color: #202124;
    font-size: 0.875rem;
    letter-spacing: 0;
    white-space: nowrap;
    @include no-select;
}
.ad-container .ad-url-container b {
    letter-spacing: -0.4px;
}
.ad-container .ad-url-container svg {
    margin: 0 0.3125rem;
}
.ad-url {
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: visible;
    white-space: nowrap;
    padding: 2px 0;
}
.ad-container .headlines {
    @include block;
    font-family: 'Google Sans Text', sans-serif;
    font-size: 1.25rem;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
    color: #1a0dab;
    margin-top: 0.5rem;
    margin-bottom: 0.375rem;
    -webkit-font-smoothing: subpixel-antialiased;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include no-select;
}
.ad-container .headlines .headline-wrapper {
    @include inline;
}
.ad-container .descriptions {
    @include block;
    font-family: Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    color: #4d5156;
    max-width: 600px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include no-select;
}
// Mobile
.ad-container.mobile {
    max-width: 27.5rem;
}
.ad-container.mobile .headlines {
    margin-top: 0.6875rem;
    margin-bottom: 0.5625rem;
}
// Full width
.ad-container.mobile.full-width,
.ad-container.full-width {
    max-width: unset;
}
// hide border
.ad-container.mobile.hide-border,
.ad-container.hide-border {
    box-shadow: unset;
}
</style>
