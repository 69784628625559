<template>
    <div
        :id="sectionTypeId"
        :class="['section-container', { 'not-applicable': invalid, 'not-pdf-render': !isPdf }]"
    >
        <div>
            <ScorecardDonut
                :class="['sticky', { 'is-pdf-render': isPdf }]"
                :hidden="isLoading || invalid"
                :score="isLoading || invalid ? 0 : score ?? 0"
                :name="sectionName"
                :strokeWidth="12"
            />
        </div>
        <div class="section-button" :class="{ 'is-pdf-render': isPdf }">
            <!-- <div class="section-button"> -->
            <oButton
                color="white"
                size="medium"
                :icon-before="aboveMobile ? true : false"
                :disabled="invalid"
                :circle="aboveMobile ? false : true"
                @clicked="buttonClicked"
            >
                <template #icon>
                    <!-- Clock Icon -->
                    <svg
                        style="display: block"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM10 5C10 4.44772 9.55229 4 9 4C8.44771 4 8 4.44772 8 5V8.75C8 9.44036 8.55964 10 9.25 10H12C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8H10V5Z"
                            fill="black"
                        />
                    </svg>
                </template>
                <span v-if="aboveMobile">Score History</span>
            </oButton>
            <!-- <Spacer width="0.5rem" /> -->
            <!-- <oButton color="white" size="small" @clicked="toggleSection && toggleSection(sectionType ?? '')">
                <span>Hide</span>
            </oButton> -->
        </div>
        <div class="section-content">
            <Text as="h5" weight="600">{{ sectionName }}</Text>
            <Spacer height="1.5rem" />
            <SectionLoading
                v-if="isLoading"
                :domain-name="domainName"
                :section-name="sectionName ?? ''"
            />
            <SectionHidden v-else-if="invalid" :invalid-reason="invalidReason" :isPdf="isPdf" />
            <div v-else>
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { inject, computed, defineComponent, PropType, onMounted } from 'vue'
import { nextTick } from 'process'
import { useRoute, useRouter } from 'vue-router'
import kebabCase from 'lodash-es/kebabCase'

import useMediaQuery from '@/composition/global/useMediaQuery'
import { scrollTo } from '@/lib/globalUtils'

import { Text, Spacer, oButton } from '@opteo/components-next'

// Local imports

import SectionHidden from './SectionHidden.vue'
import SectionLoading from './SectionLoading.vue'
import ScorecardDonut from './ScorecardDonut.vue'

export default defineComponent({
    name: 'ScorecardSection',
    components: {
        Text,
        Spacer,
        SectionHidden,
        SectionLoading,
        ScorecardDonut,
        oButton,
    },

    props: {
        score: {
            type: Number,
        },
        invalid: {
            type: Boolean,
        },
        invalidReason: {
            type: String,
        },
        isLoading: {
            type: Boolean,
        },
        domainName: {
            type: String,
        },
        sectionName: {
            type: String,
        },
        sectionType: {
            type: String,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
    },
    emits: ['score-history-button-clicked'],
    setup(props, { emit }) {
        const toggleSection = inject<(name: string) => void>('toggleScorecardSection', () => {})
        const isPdf = props.mode === 'pdf'
        const buttonClicked = () => {
            emit('score-history-button-clicked')
        }
        const { aboveMobile } = useMediaQuery()

        const sectionTypeId = computed(() => kebabCase(props.sectionType ?? 'scorecard-section'))

        const { query } = useRoute()
        const router = useRouter()

        nextTick(() => {
            if (typeof query.section !== 'string') return

            const isQueryCurrentSection =
                !!query?.section && kebabCase(query.section) === sectionTypeId.value

            if (!isQueryCurrentSection) return

            scrollTo(sectionTypeId.value)
        })

        onMounted(() => {
            // Remove query after we scroll to section on new tab so we don't re-scroll if refreshing
            router.replace({ query: {} })
        })

        return {
            toggleSection,
            isPdf,
            buttonClicked,
            aboveMobile,
            sectionTypeId,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.section-container {
    @include w-100;
    @include flex;
    @include justify-center;
    @include relative;
    break-before: page; // used for pdf
}
.not-pdf-render.section-container {
    @include container;
    border-radius: 2rem;
    @include flex;
    @include justify-center;
    @include relative;
    padding-top: 8rem;
    padding-left: 8rem;
    padding-bottom: 8rem;
    padding-right: 8rem;
}
.section-container.not-applicable {
    height: 32rem;
}
.sticky {
    @include sticky;
    top: 8rem;
    margin-right: 5rem;
}

.sticky.is-pdf-render {
    position: relative;
    top: 0rem;
}

.section-button {
    @include absolute;
    top: 1.5rem;
    right: 1.5rem;
    @include flex;
}
.section-button.is-pdf-render {
    display: none;
}

.section-content {
    @include mt-8;
    @include w-100;
    max-width: 28.875rem;
}

// Responsive

@media (max-width: $mq-480-max) {
    .not-pdf-render.section-container {
        flex-direction: column;
        @include items-center;
        @include justify-start;
        @include ph-16;
        @include pt-48;
        @include pb-20;
    }
    .not-pdf-render.section-container.not-applicable {
        height: 44rem;
        overflow: hidden;
    }
    .not-pdf-render .sticky {
        @include mb-24;
        margin-left: auto;
        margin-right: auto;
    }
    .not-pdf-render .section-content {
        @include mt-24;
    }
    .not-pdf-render .section-button {
        display: none;
    }
}

@media screen and (min-width: $mq-481-min) and (max-width: $mq-767-max) {
    .not-pdf-render.section-container {
        flex-direction: column;
        @include items-center;
        @include justify-start;
        @include ph-48;
        @include pv-64;
    }
    .not-pdf-render.section-container.not-applicable {
        height: 44rem;
        overflow: hidden;
    }
    .not-pdf-render .sticky {
        @include mb-32;
        margin-left: auto;
        margin-right: auto;
    }
    .not-pdf-render .section-content {
        @include mt-32;
    }
}
@media screen and (min-width: $mq-768-min) and (max-width: $mq-1024-max) {
    .not-pdf-render.section-container {
        flex-direction: column;
        @include items-center;
    }
    .not-pdf-render.section-container.not-applicable {
        height: 100%;
    }
    .not-pdf-render .sticky {
        @include mb-32;
        margin-left: auto;
        margin-right: auto;
    }
    .not-pdf-render .section-content {
        @include mt-32;
    }
    .not-pdf-render .section-button {
        display: none;
    }
}
</style>
