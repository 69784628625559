<template>
    <section class="chat-query-wrapper">
        <div class="chat-query">
            <div class="icon" v-if="icon">
                <ProfileImage
                    :width="40"
                    :image-url="profileImageUrl"
                    :avatar-url="currentUserAvatarUrl"
                />
            </div>
            <p class="query" v-html="HTML" />
        </div>
    </section>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue'

// @opteo/components-next
import { ProfileImage } from '@opteo/components-next'

// Composition
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { useUser } from '@/composition/user/useUser'
import { useChatEntities } from '@/composition/chat/useChatEntities'

export default defineComponent({
    name: 'ChatQuery',
    components: { ProfileImage },
    props: {
        entities: {
            type: Object,
            default: {},
        },
        content: {
            type: String,
            required: true,
        },
        icon: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const { userInfo, profileImageUrl } = useUser()
        const { currentUserAvatarUrl } = useBoringAvatar()
        const { HTML } = useChatEntities(props.content, props.entities)

        return {
            userInfo,
            profileImageUrl,
            currentUserAvatarUrl,
            HTML,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chat-query-wrapper {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
}
.chat-query {
    @include inline-flex;
    @include items-start;
    @include container;
    @include br-24;
    @include relative;
    gap: 1.625rem;
    min-height: 4.5rem;
    padding: 1.5rem 1.875rem;
}
.icon {
    flex-shrink: 0;
    top: 1.0625rem;
    position: absolute;
    left: calc(-2.5rem - 1.5rem);
}
.query {
    width: 100%;
    word-break: break-word;
    letter-spacing: -0.005rem;
    font-size: 1rem !important;
    line-height: 1.625rem;
    white-space: pre-wrap;
}
</style>
