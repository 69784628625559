import { computed, ref, Ref, ComputedRef, inject } from 'vue'
import isNaN from 'lodash-es/isNaN'
import { BarChartTypes, usePercent } from '@opteo/components-next'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { AdjustAgeBid } from '@opteo/types'
import {
    OnPushHandler,
    useImprovement,
    UseImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'
import { ProvideKeys } from '@/composition/useProvide'
import { CurrencyCode } from '@/composition/utils/types'

interface UseAdjustAgeBid {
    usingTargetCpa: boolean
    campaignName: string
    improvement: Ref<AdjustAgeBid.AdjustAgeBidBody> | Ref
    ageGroupCpaToCampaignRatio: ComputedRef<number>
    ageGroupCpaToCampaignGroupRatio: ComputedRef<number>
    statsBidMod: ComputedRef<string>
    statsCampaignCpa: ComputedRef<string>
    statsCpa: ComputedRef<string>
    statsBucketCpa: ComputedRef<string>
    statsCampaignGroupDifference: ComputedRef<number>
    campaignCpa: number
    bucketCpa: number
    adjustedBid: Ref<number>
    currentBidModRelativeToUnitBid: Ref<number>
    onBidUpdate: (updatedBid: number) => void
    barChartItems: BarChartTypes.BarChartItem[]
    domainCurrencyCode: CurrencyCode | undefined
    ageRange: string
    campaignGroupName: string
    negativeBidMod: number
    // Table Data
    TableHeaders: any[]
    TableItems: any[]
}

type BidDirection = 'increase' | 'decrease'

interface PushedData {
    sign: BidDirection | undefined
    value: number | undefined
}

const AGES: AdjustAgeBid.AgeRanges[] = [
    '18-24',
    '25-34',
    '35-44',
    '45-54',
    '55-64',
    '65 or more',
    'Undetermined',
]

export function useAdjustAgeBid(): UseImprovement<UseAdjustAgeBid> {
    const bidAdjusted = ref(false)
    const bidToPush = ref<number | undefined>()
    const sign = ref<BidDirection | undefined>()
    const { improvement, lastUpdated, title } = useImprovement<AdjustAgeBid.AdjustAgeBidBody>()

    const {
        body: {
            negative_bid_mod: negativeBidMod,
            cpa,
            campaign_cpa: campaignCpa,
            bucket_cpa: bucketCpa,
            using_target_cpa: usingTargetCpa,
            other_ages: otherAges,
            conversion_type_name: campaignGroupName,
            campaign_label: campaignName,
            age_range: ageRange,
        },
    } = checkImprovement<AdjustAgeBid.AdjustAgeBidBody>(improvement)

    function setDefaultCustomValues() {
        bidToPush.value = negativeBidMod
        sign.value = 'decrease'
    }

    const currentBidModRelativeToUnitBid = ref(1 - negativeBidMod / 100)
    const adjustedBid = ref(1)

    const ageGroupCpaToCampaignRatio = computed(() => {
        return (cpa - campaignCpa) / campaignCpa
    })

    const ageGroupCpaToCampaignGroupRatio = computed(() => {
        return (cpa - bucketCpa) / bucketCpa
    })

    const { displayValue: statsBidMod } = usePercent({
        value: negativeBidMod / 100,
    })

    const { displayValue: statsCpa } = useDomainMoney({
        value: cpa,
    }).value
    const { displayValue: statsCampaignCpa } = useDomainMoney({
        value: campaignCpa,
    }).value
    const { displayValue: statsBucketCpa } = useDomainMoney({
        value: bucketCpa,
    }).value

    const statsCampaignGroupDifference = computed(() => {
        return (cpa - bucketCpa) / bucketCpa
    })

    const onPush: OnPushHandler<PushedData> = () => {
        const pushedData = {
            sign: sign.value,
            value: parseFloat(bidToPush.value?.toFixed(2) ?? '0'),
        }
        const valid = validate(pushedData)

        if (!bidAdjusted.value) {
            return { valid }
        }

        return {
            valid,
            pushedData,
        }
    }

    function onBidUpdate(updatedBid: number) {
        const absolutePercentBid = (updatedBid - 1) * 100
        sign.value = absolutePercentBid > 0 ? 'increase' : 'decrease'
        bidToPush.value = Math.abs(absolutePercentBid)
        if (
            sign.value !== 'decrease' ||
            updatedBid.toFixed(2) !== ((100 - negativeBidMod) / 100).toFixed(2)
        ) {
            bidAdjusted.value = true
        } else {
            bidAdjusted.value = false
        }
    }

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Applying age bid adjustment..',
        'Confirming changes..',
        'Bid adjustment applied successfully.',
    ])

    const pushActionText = ref('Apply Bid Adjustment')

    function validate(pushedData: { value: number | undefined; sign: BidDirection | undefined }) {
        if (!pushedData.value || !pushedData.sign) {
            return false
        }
        if (+pushedData.value === 0 || isNaN(+pushedData.value)) {
            return false
        } else if (pushedData.value < 0) {
            return false
        } else if (pushedData.sign === 'decrease' && pushedData.value > 90) {
            return false
        } else if (pushedData.sign === 'increase' && pushedData.value > 900) {
            return false
        }

        return true
    }

    const barChartItems: BarChartTypes.BarChartItem[] = AGES.map(type => {
        const currentAgeRange: AdjustAgeBid.OtherAges | undefined = otherAges.find(
            a => a.age_range === type
        )

        const highlighted = ageRange === type
        let y_value = 0

        if (highlighted) {
            y_value = isFinite(cpa) ? cpa : 0
        } else {
            y_value = currentAgeRange?.cost
                ? isFinite(currentAgeRange?.cost) && currentAgeRange?.conversions !== 0
                    ? currentAgeRange?.cost / currentAgeRange?.conversions
                    : 0
                : 0
        }

        return {
            y: y_value ?? 0,
            label: type,
            highlighted,
        }
    }).filter(chart_item => chart_item?.y)

    // Table Data

    const TableHeaders = [
        { key: 'age_range', text: 'Age Group', sortable: true },
        { key: 'cost', text: 'Cost', sortable: true },
        { key: 'cpa', text: 'CPA', sortable: true },
        { key: 'average-cpa', text: 'Avg. CPA' },
        { key: 'difference', text: 'Diff.', sortable: true },
    ]

    const TableData = [
        {
            age_range: ageRange,
            cost: improvement.value!.body.cost,
            conversions: improvement.value!.body.conversions,
            clicks: improvement.value!.body.clicks,
        },
        ...otherAges,
    ]

    const TableItems = TableData.map(group => {
        return {
            id: group.age_range,
            age_range: group.age_range,
            cost: group.cost,
            cpa: group.cost / group.conversions,
            'average-cpa': campaignCpa,
            difference: (group.cost / group.conversions - campaignCpa) / campaignCpa,
        }
    })

    const domainCurrencyCode = inject<CurrencyCode>(ProvideKeys.CurrencyCode)

    setDefaultCustomValues()

    return {
        usingTargetCpa,
        campaignName,
        improvement,
        title,
        ageGroupCpaToCampaignRatio,
        ageGroupCpaToCampaignGroupRatio,
        campaignCpa,
        bucketCpa,
        adjustedBid,
        currentBidModRelativeToUnitBid,
        onBidUpdate,
        onPush,
        pushMessages,
        lastUpdated,
        barChartItems,
        domainCurrencyCode,
        statsCpa,
        statsCampaignCpa,
        statsBucketCpa,
        statsCampaignGroupDifference,
        statsBidMod,
        ageRange,
        campaignGroupName,
        negativeBidMod,
        // Table Data
        TableHeaders,
        TableItems,

        pushActionText,
    }
}
