<template>
    <ImprovementRowContainer :improvement="improvement" @improvement-opened="openImprovement">
        <template #icon>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 2C6.477153 2 2 6.4771525 2 12s4.477153 10 10 10 10-4.4771525 10-10S17.522847 2 12 2zm1 9V6h-2v7h6v-2h-4zM12 0c6.627417 0 12 5.372583 12 12s-5.372583 12-12 12S0 18.627417 0 12 5.372583 0 12 0z"
                    fill-rule="nonzero"
                    fill="#DBDCDD"
                />
            </svg>
        </template>
        <template #buttons>
            <oButton @clicked="shareFeedback" color="white">Share Feedback</oButton>
            <Spacer width="0.625rem" />
            <oButton @clicked="openImprovement">Open Improvement</oButton>
        </template>
    </ImprovementRowContainer>
</template>
<script lang="ts">
import { PropType } from 'vue'
import { Improvement } from '@opteo/types'
import { useRouter } from 'vue-router'
import { oButton, Spacer } from '@opteo/components-next'

import { Routes } from '@/router/routes'
import ImprovementRowContainer from '@/components/improvement/ImprovementRowContainer.vue'
import DismissFeedbackModal from '@/components/improvement/DismissFeedbackModal.vue'
import { useDismissFeedbackModal } from '@/composition/improvement/useDismissFeedbackModal'

export default {
    components: {
        oButton,
        Spacer,
        ImprovementRowContainer,
        DismissFeedbackModal,
    },
    props: {
        improvement: {
            required: true,
            type: Object as PropType<Improvement.Object>,
        },
        isDeferred: {
            // should be false if the deferred_until date is far into the future
            required: false,
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const router = useRouter()
        const { openDismissFeedbackModal } = useDismissFeedbackModal()

        const shareFeedback = () => {
            return openDismissFeedbackModal({
                improvementId: props.improvement.improvement_id,
                improvementTitle: props.improvement.title,
                improvementLocation: props.improvement.location,
                improvementRecAction: props.improvement.rec_action,
            })
        }

        const openImprovement = () => {
            router.push({
                name: Routes.DismissedImprovement,
                params: { improvementId: props.improvement.improvement_id },
            })
        }

        return {
            openImprovement,
            shareFeedback,
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
</style>
