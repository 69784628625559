import orderBy from 'lodash-es/orderBy'
import uniqBy from 'lodash-es/uniqBy'
import { computed, ref, onMounted, Ref } from 'vue'
import { ExcludeMobileApps, Improvement } from '@opteo/types'

import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import {
    useImprovement,
    checkImprovement,
    EntityPill,
} from '@/composition/improvement/useImprovement'

import defaultAppIcon from '@/assets/img/appReplacement.svg'

interface MobileGridIcon {
    name: string
    alt: string
    image?: string
    placeholder: string
}

export function useExcludeMobileApps() {
    const { improvement, lastUpdated, title } = useImprovement<ExcludeMobileApps.Body>()
    const { body } = checkImprovement<ExcludeMobileApps.Body>(improvement)
    const affectedCampaigns =
        body.campaigns?.map((campaign): EntityPill => {
            return {
                type: Improvement.LocationEntity.Campaign,
                content: campaign.name,
            }
        }) ?? []

    const getIcon = async (id: string): Promise<{ url: string; name: string }> => {
        return authRequest<{ name: string; url: string; platform: string }>(
            Endpoint.GetMobileAppMetadata,
            { body: { appId: id } }
        )
    }

    const placementToIcon: Ref<Record<string, string>> = ref({})
    const placementToName: Ref<Record<string, string>> = ref({})

    const topFourApps = uniqBy(
        orderBy(body.top_10, app => app.cost, 'desc'),
        app => app.placement
    ).slice(0, 4)

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Excluding mobile apps..',
        'Confirming changes..',
        'Mobile apps excluded successfully.',
    ])

    const pushActionText = ref('Exclude Mobile Apps')

    const mobileApps = computed((): MobileGridIcon[] => {
        return topFourApps.map(app => {
            const placement = app.placement.substring(13)
            let app_name: string | string[] = placement.split('.')
            if (app_name.length > 1) {
                app_name = app_name[app_name.length - 2] + ' ' + app_name[app_name.length - 1]
            } else {
                app_name = app_name[0]
            }

            return {
                name: placementToName.value[placement] ?? app_name,
                alt: app_name,
                image: placementToIcon.value[placement] ?? undefined,
                placeholder: defaultAppIcon,
            }
        })
    })

    onMounted(async () => {
        await Promise.all(
            topFourApps.map(async app => {
                const placement = app.placement.substring(13)
                const { url, name } = await getIcon(app.placement.substring(13))

                if (url.includes('/')) {
                    placementToIcon.value[placement] = `https://${url}`
                } else {
                    placementToIcon.value[placement] = defaultAppIcon
                }

                const parsedName = name.length > 30 ? name.substring(0, 30) + '...' : name
                placementToName.value[placement] = parsedName
            })
        )
    })

    const stats = computed(() => {
        return [
            {
                key: 'cost',
                value: useDomainMoney({
                    value: body.cost,
                }).value.displayValue.value,
                title: 'Cost',
            },
            {
                key: 'conversions',
                value: body.conversions,
                title: 'Conversions',
            },
            {
                key: 'cpa',
                value: useDomainMoney({
                    value: body.cpa,
                }).value.displayValue.value,
                title: 'CPA',
            },
            {
                key: 'avgCpa',
                value: useDomainMoney({
                    value: body.cpa_avg,
                }).value.displayValue.value,
                title: 'Campaign Group CPA',
            },
        ]
    })

    return {
        body,
        title,
        lastUpdated,
        affectedCampaigns,
        mobileApps,
        placementToIcon,
        stats,
        pushMessages,
        pushActionText,
    }
}
