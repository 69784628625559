<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
    >
        <template #content>
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>
            <Spacer height="1.5rem" />

            <Text as="h6" weight="600">Performance by Match Type</Text>
            <Spacer height="2rem" />

            <div class="data-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <DonutChart
                    :showKeys="true"
                    :items="formatDonutChartData(details?.pie_data ?? [])"
                    :metric="{ label: 'Cost', dataType: 'percentage' }"
                />

                <oTable
                    class="table"
                    :headers="tableHeaders"
                    :items="tableItems"
                    :fixed-layout="true"
                    :border-radius="0"
                    no-shadow
                    responsive-mode="columns"
                    responsive-breakpoint="767px"
                >
                    <!-- Match type column -->
                    <template v-slot:[`column.match`]="cellData">
                        <Text style="transform: translateY(1px)" as="p" size="f-8">{{
                            cellData.value
                        }}</Text>
                    </template>

                    <!-- Cost column -->
                    <template v-slot:[`column.cost`]="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value ?? 0" />
                    </template>

                    <!-- Conversions column (CPA) -->
                    <template #column.conversions="conversions">
                        <NumberVue :value="conversions.value ?? 0" />
                    </template>

                    <!-- Conversions Value column (ROAS) -->
                    <template #column.conversionsValue="conversionsValue">
                        <Money :currency="domainCurrency" :value="conversionsValue.value ?? 0" />
                    </template>

                    <!-- CPA column -->
                    <template #column.cpa="cellValue">
                        <Money :currency="domainCurrency" :value="cellValue.value ?? 0" />
                    </template>

                    <!-- ROAS column -->
                    <template #column.roas="roas">
                        <Roas :value="roas.value ?? 0" />
                    </template>

                    <!-- Avg column -->
                    <template #column.vsAvg="cellData">
                        <Text
                            as="span"
                            v-if="!cellData.value || cellData.value === 0"
                            color="gray"
                            weight="500"
                        >
                            <Percent :value="0" :decimal-places="2" :include-sign="true" />
                        </Text>
                        <Text
                            v-else-if="isUsingCpa && cellData.value"
                            as="span"
                            size="f-8"
                            :color="cellData.value?.toString().includes('-') ? 'green' : 'red'"
                            weight="500"
                        >
                            <Percent
                                :value="cellData.value"
                                :decimal-places="1"
                                :include-sign="true"
                            />
                        </Text>
                        <Text
                            v-else
                            as="span"
                            size="f-8"
                            :color="cellData.value?.toString().includes('-') ? 'red' : 'green'"
                            weight="500"
                        >
                            <Percent
                                :value="cellData.value"
                                :decimal-places="1"
                                :include-sign="true"
                            />
                        </Text>
                    </template>
                </oTable>
            </div>
            <Spacer height="1.5rem" />
            <ImprovementNote>
                <Text size="f-9" color="gray">
                    Score weighted by cost. The data above includes Search campaigns only, excluding
                    brand campaigns. Data collected over the last <b>30 days</b>.
                </Text>
            </ImprovementNote>
        </template>
    </ScorecardSection>
</template>

<script lang="ts">
// Local imports

import SectionHidden from './SectionHidden.vue'
import { formatDonutChartData } from './utils'
import { getSectionCopy } from './utils'
import ScorecardSection from './ScorecardSection.vue'
import { defineComponent, inject, computed, PropType } from 'vue'
import { Scorecard } from '@opteo/types'
import sumBy from 'lodash-es/sumBy'

import {
    Text,
    Spacer,
    oTable,
    Money,
    Percent,
    Roas,
    Number as NumberVue,
    DonutChart,
    ColorTag,
    oButton,
    Tooltip,
    Panel,
    BarChart,
    BarChartTypes,
} from '@opteo/components-next'

export default defineComponent({
    name: 'MatchTypeBreakdown',
    components: {
        Text,
        Spacer,
        DonutChart,
        oTable,
        Money,
        Percent,
        Roas,
        NumberVue,
        SectionHidden,
        ScorecardSection,
    },
    props: {
        score: {
            type: Number,
        },
        domainCurrency: {
            type: String,
        },
        invalid: {
            type: Boolean,
        },
        domainName: {
            type: String,
        },
        isLoading: {
            type: Boolean,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object as PropType<Scorecard.MatchTypeSpreadScoreDetails>,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
    },
    setup(props) {
        const isPdf = props.mode === 'pdf'

        const textOptions = computed(() => {
            if ((props.details?.exact_score ?? 0) > (props.details?.non_broad_score ?? 0)) {
                return {
                    0: [
                        `Your account is spending excessively through broad keywords. Searchers may be seeing your ads when searching for something not relevant to your brand, products or services. This can lead to wasted budget, increasing costs without increasing ${
                            props.isUsingCpa ? 'conversions' : 'value'
                        }.`,
                        `Overspending on broad keywords can be an expensive mistake. We'd recommend building your keyword lists with more specific match types to more effectively target interested audiences.`,
                    ],
                    50: [
                        `Your account is allocating too much budget to broad keywords. Searchers may be seeing your ads when searching for something not relevant to your brand, products or services. This can lead to wasted budget, increasing costs without increasing ${
                            props.isUsingCpa ? 'conversions' : 'value'
                        }.`,
                        `We'd recommend building your keyword lists with more specific match types to more effectively target interested audiences.`,
                    ],
                    70: [
                        `Your account has a healthy mix of exact and phrase match keywords. This means that searchers with a high level of interest are being targeted specifically, and exploratory keywords are in place to capture any new audiences.`,
                        `We'd recommend continuing to build your account with a focus on specific match types. This should help with capturing relevant searchers and ensure cost per click remains as low as possible.`,
                    ],
                }
            }
            return {
                0: [
                    `Your account is not allocating enough budget to exact match keywords. Exact match type keywords ensure that only relevant search queries are targeted, which should in turn lead to ${
                        props.isUsingCpa ? 'more efficient conversions' : 'better conversion value'
                    } and increased overall efficiency.`,
                    `Focusing on non-specific keywords can be a costly mistake. We'd recommend building out your account with a focus on exact match keywords to target audiences more effectively.`,
                ],
                50: [
                    `Your account should be allocating more budget to exact match keywords. Exact match type keywords ensure that only relevant search queries are targeted, which should in turn lead to ${
                        props.isUsingCpa ? 'more conversions' : 'higher conversion value'
                    } and increased efficiency.`,
                    `Focusing on non-specific keywords can be a costly mistake. We'd recommend building out your account with a focus on exact match keywords to target audiences more effectively.`,
                ],
                75: [
                    `Your account has a healthy mix of exact and phrase match keywords. This means that searchers with a high level of interest are being targeted specifically, and exploratory keywords are in place to capture any new audiences.`,
                    `We'd recommend continuing to build your account with a focus on specific match types. This should help with capturing relevant searchers and ensure cost per click remains as low as possible.`,
                ],
            }
        })

        const sectionCopy = computed(() => {
            return getSectionCopy(textOptions.value, props.score ?? 0)
        })

        const tableHeaders = computed(() => {
            const performanceSpecificHeaders = props.isUsingCpa
                ? [
                      { key: 'conversions', text: 'Conv.', width: 84 },
                      { key: 'cpa', text: 'CPA', width: 84 },
                  ]
                : [
                      { key: 'conversionsValue', text: 'Value', width: 86 },
                      { key: 'roas', text: 'ROAS', width: 84 },
                  ]

            return [
                { key: 'match', text: 'Type' },
                { key: 'cost', text: 'Cost', width: 92 },
                ...performanceSpecificHeaders,
                { key: 'vsAvg', text: 'vs Avg.', width: 100 },
            ]
        })

        const tableItems = computed(() => {
            if (!props.details?.table_data) return []

            const totalCost = sumBy(props.details.table_data, r => r.metrics.cost ?? 0)
            const totalConv = sumBy(props.details.table_data, r => r.metrics.conversions ?? 0)
            const totalConvValue = sumBy(
                props.details.table_data,
                r => r.metrics.conversions_value ?? 0
            )

            const avgCpa = totalCost / totalConv
            const avgRoas = totalConvValue / totalCost

            return (props.details?.table_data ?? []).map(tableItem => {
                const { conversions, conversions_value: conversionsValue, cost } = tableItem.metrics

                // Result will be 0 if either: values are undefined, both dividend and divisor equal 0; else, return the result of the division
                const cpa =
                    cost !== undefined && conversions !== undefined ? cost / conversions || 0 : 0
                const roas =
                    conversionsValue !== undefined && cost !== undefined
                        ? conversionsValue / cost || 0
                        : 0

                const vsAvg = (props.isUsingCpa ? cpa / avgCpa || 1 : roas / avgRoas || 1) - 1

                // calculate diff from avg for CPA & ROAS
                return {
                    id: tableItem.name,
                    match: tableItem.name,
                    cost: cost ?? 0,
                    conversions: conversions ?? 0,
                    conversionsValue: conversionsValue ?? 0,
                    cpa,
                    roas,
                    vsAvg,
                }
            })
        })

        return {
            sectionType: Scorecard.SectionTypes.matchtype,
            sectionName: Scorecard.SectionNames.matchtype,
            formatDonutChartData,
            sectionCopy,
            tableHeaders,
            tableItems,
            isPdf,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.data-container {
    @include container;
    @include br-20;
    overflow: hidden;

    & .table {
        border-top: 1px solid;
        @include opteo-border;
        margin-top: 32px;
    }
}
:deep(.improvement-note) {
    @include br-20;
}
</style>
