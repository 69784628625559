<template>
    <Modal
        v-model="filtersModalOpen"
        title="N-Gram Filters"
        width="832px"
        content-max-height="556px"
    >
        <template #content>
            <div class="ngram-filters-modal">
                <div class="insights-toggle-container">
                    <!-- TODO (dev): wire up insightsMode -->
                    <Toggle v-model="insightsMode" size="medium" />
                    <div>
                        <div class="insights-toggle-label">
                            <Text size="f-8" weight="500" as="h4">Insights Mode</Text>
                            <Spacer width="0.625rem" />
                            <!-- TODO (dev): wire up insightNgramCount -->
                            <Count :count="insightNgramCount" />
                        </div>
                        <Spacer height="0.25rem" />
                        <Text size="f-9">
                            Toggle this option to see n-grams with related insights like bounce rate
                            and industry data.
                        </Text>
                    </div>
                </div>
                <Spacer height="2.5rem" />
                <div v-for="(type, typeIndex) in newFilters" :key="type.label">
                    <Label>{{ type.label }}</Label>
                    <div class="checkbox-group">
                        <div
                            v-for="(filter, filterKey) in type.state"
                            role="button"
                            class="checkbox-group-item"
                            @click.prevent="setFilter(typeIndex, filterKey, filter.checked)"
                        >
                            <Checkbox
                                v-if="!filter.filterActive || filter.filterActive(activeTab)"
                                :model-value="filter.checked"
                            >
                                <template #label>
                                    <Text size="f-8">{{ filter.label }}</Text>
                                    <Text
                                        v-if="filter.subLabel"
                                        size="f-8"
                                        color="gray"
                                        style="margin-left: 0.75rem"
                                        >{{ filter.subLabel }}</Text
                                    >
                                </template>
                            </Checkbox>
                        </div>
                    </div>
                    <Spacer v-if="typeIndex !== newFilters.length - 1" height="2.25rem" />
                </div>
            </div>
        </template>
        <template #buttons>
            <oButton @clicked="filtersModalOpen = false" color="white">Cancel</oButton>
            <Spacer width="0.625rem" />
            <oButton @clicked="handleApplyFilterClick" color="dark-blue">
                Apply Filters
                <template #icon><RightArrowIcon hex="#fff" /></template>
            </oButton>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { computed, ref, unref } from 'vue'

import {
    Spacer,
    Text,
    Checkbox,
    Label,
    Modal,
    oButton,
    RightArrowIcon,
    Toggle,
    Count,
} from '@opteo/components-next'
import { useNGramFilters, FilterKey } from '@/composition/toolkit/nGramTool/useNGramFilters'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'

const {
    excludeNGramFilters,
    excludeSearchTermFilters,
    excludeKeywordFilters,
    excludeMatchTypeFilters,
    filtersModalOpen,
    activeTab,
} = useNGramFilters()

const newFilters = ref(
    [
        { label: 'Exclude N-Grams', state: { ...unref(excludeNGramFilters.value) } },
        { label: 'Exclude Search Term Data', state: { ...unref(excludeSearchTermFilters.value) } },
        { label: 'Exclude Keywords', state: { ...unref(excludeKeywordFilters.value) } },
        { label: 'Exclude Match Types', state: { ...unref(excludeMatchTypeFilters.value) } },
    ]
        .filter(
            f =>
                Object.values(f.state).filter(
                    f => !f.filterActive || f.filterActive(activeTab.value)
                ).length > 0
        )
        .map(f => {
            f.state = Object.fromEntries(
                Object.entries(f.state).filter(
                    ([, filter]) => !filter.filterActive || filter.filterActive(activeTab.value)
                )
            )
            return f
        })
)

function setFilter(typeIndex: number, filterKey: FilterKey, checked: boolean) {
    newFilters.value[typeIndex].state[filterKey].checked = !checked
}

const { applyFilters, insightNgramCount, insightsMode } = useNGramTool()

function handleApplyFilterClick() {
    applyFilters()
    filtersModalOpen.value = false
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.ngram-filters-modal {
    max-width: 400px;
    margin: 0 auto;
}

.insights-toggle-container {
    @include container;
    @include ph-24;
    @include pv-20;
    border-radius: 1.5rem;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 20px;
}

.insights-toggle-label {
    display: flex;
    align-items: center;
}

.checkbox-group {
    @include container;
    border-radius: 1.125rem;
}
.checkbox-group-item {
    padding: 1.125rem 1.5rem;
    cursor: pointer;
}
.checkbox-group-item:not(:last-child) {
    border-bottom: 1px solid;
    @include opteo-border;
}
</style>
