<template>
    <MobileSplashContainer wide>
        Campaign Groups is available on screens <b>1200px or wider</b>, increase your window size or
        use another device. If you'd like to see us build this feature for touch devices, please
        consider submitting a feature request.
    </MobileSplashContainer>
    <FullScreenOverlay @close="handleCampaignGroupsClose">
        <PageHeader
            page-title="Campaign Groups"
            :show-in-full-screen="true"
            :no-margin="true"
            :show-avatar="true"
            class="campaign-groups-header"
        >
            <template v-if="aboveiPadLandscape" v-slot:title-content>
                <div class="header-blurb-wrapper">
                    <Text as="p" color="gray" class="header-blurb">
                        All Campaign Groups data taken from the past 90 days
                    </Text>
                </div>
            </template>

            <template v-slot:right-content>
                <!-- Action Buttons -->
                <div v-if="!showCampaignGroupsError">
                    <RsaButton
                        double
                        :max-width="450"
                        leftValue="Reset Groups"
                        rightValue="Add New Group"
                        @leftClicked="resetModalShown = !resetModalShown"
                        @rightClicked="createNewGroup"
                    >
                        <template v-slot:left>
                            <RegenerateIcon hex="#000" style="margin-right: 2px; flex-shrink: 0" />
                        </template>
                        <template v-slot:right>
                            <RoundedPlusIcon hex="#000" />
                        </template>
                    </RsaButton>
                </div>
            </template>
        </PageHeader>

        <!-- Reset Groups Modal -->
        <Modal title="Reset Groups" v-model="resetModalShown" width="474px">
            <template #content>
                <Text as="p" size="f-8">
                    Use this option to reset your campaign groups to their default settings. Your
                    brand campaigns will be sorted into a campaign group called
                    <b>Brand Campaigns</b> and all other campaigns will be sorted by campaign type.
                    Click the button below to confirm and reset.
                </Text>
            </template>
            <template #buttons>
                <oButton color="white" @clicked="resetModalShown = false">Cancel</oButton>
                <Spacer width="0.625rem" />
                <oButton color="blue" @clicked="resetCampaignGroups" :loading="resetButtonLoading">
                    Confirm and Reset Groups
                </oButton>
            </template>
        </Modal>

        <div class="campaign-groups-container">
            <SettingsModalCampaignGroups />
        </div>
    </FullScreenOverlay>
</template>

<script lang="ts">
import { computed, defineComponent, ref, provide } from 'vue'
import {
    Tabs,
    Spacer,
    WarnIcon,
    Text,
    Popout,
    oButton,
    RoundedPlusIcon,
    RegenerateIcon,
    Modal,
} from '@opteo/components-next'
import { useDomain } from '@/composition/domain/useDomain'
import { useRouter } from 'vue-router'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useCampaignGroups } from '@/composition/campaignGroups/useCampaignGroups'

import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'
import PageHeader from '@/layouts/PageHeader.vue'
import MobileSplashContainer from '@/components/util/MobileSplashContainer.vue'

import SettingsModalCampaignGroups from '@/components/improvement/SettingsModalCampaignGroups.vue'
import RsaButton from '@/components/rsaWriter/RsaButton.vue'
import { Routes } from '@/router/routes'

export default defineComponent({
    components: {
        SettingsModalCampaignGroups,
        Popout,
        oButton,
        PageHeader,
        Text,
        Tabs,
        Spacer,
        WarnIcon,
        FullScreenOverlay,
        RsaButton,
        RoundedPlusIcon,
        RegenerateIcon,
        Modal,
        MobileSplashContainer,
    },
    name: 'CampaignGroups',
    setup() {
        const { domainInfo } = useDomain()

        const { clearCampaignSelection } = useCampaignGroups()

        const { push } = useRouter()

        const handleCampaignGroupsClose = () => {
            push({ name: Routes.ImprovementsActive })
            clearCampaignSelection()
        }

        return {
            domainInfo,
            handleCampaignGroupsClose,
            ...useMediaQuery(),
            ...useCampaignGroups(),
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaign-groups-container {
    max-width: calc(69rem + 6rem);
    margin: 1rem auto;
    padding-left: 3rem;
    padding-right: 3rem;
}

.header-blurb-wrapper {
    height: 2.75rem;
    @include flex;
    @include items-center;
    @include ml-24;
}
.header-blurb {
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: -0.0025rem;
    width: 11.5rem;
    @include container;
    border-radius: 0.875rem;
    padding: 0.6875rem 1rem;
}

// Reset Groups Popout
.reset-groups-popout {
    @include pt-20;
    @include pb-24;
    @include ph-24;
}
.reset-groups-popout-action-buttons {
    @include flex;
    @include items-center;
}

.campaign-groups-header {
    max-width: 75rem !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

:deep(.rsa-button) {
    border-radius: 0.875rem;
}
:deep(.rsa-button.double .left),
:deep(.rsa-button.double .right) {
    padding: 0.75rem 1rem;
}
:deep(.rsa-button.double .left:focus),
:deep(.rsa-button.double .right:focus) {
    border-radius: 0.875rem;
}
</style>
