import { computed, ref, Ref } from 'vue'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import {
    OnPushHandler,
    UseImprovement,
    useImprovement,
    checkImprovement,
} from '@/composition/improvement/useImprovement'

import type { ImprovementStatistic } from '.'

type DropDownItem = { label: string; value: string }

interface UseAddFrequencyCappingBody {
    campaign: string
    campaign_id: string
    cost: number
}

interface UseAddFrequencyCapping {
    campaignName: string
    cost: number
    improvementStatistics: ImprovementStatistic[]
    frequencyCaps: DropDownItem[]
    selectedFrequencyCap: Ref<string>
}

export function useAddFrequencyCapping(): UseImprovement<UseAddFrequencyCapping> {
    const { improvement, lastUpdated, title } = useImprovement<UseAddFrequencyCappingBody>()

    const {
        body: { campaign: campaignName, cost },
    } = checkImprovement<UseAddFrequencyCappingBody>(improvement)

    const improvementStatistics: ImprovementStatistic[] = [
        {
            key: 'cost',
            value: useDomainMoney({ value: cost }).value.displayValue.value,
            title: 'Cost',
        },
    ]

    // Creates an array of drop down items from 1 to 11...
    const frequencyCaps: DropDownItem[] = Array.from({ length: 11 }, (_, i) => {
        return { label: (i + 1).toString(), value: (i + 1).toString() }
    })
    const selectedFrequencyCap = ref('3')

    const pushActionText = ref('Add Frequency Cap')

    const pushMessages = computed(() => [
        'Connecting to Google Ads..',
        'Adding Frequency Cap..',
        'Confirming changes..',
        'Frequency Cap added successfully.',
    ])

    const onPush: OnPushHandler<{ value: number }> = () => {
        return {
            valid: true,
            pushedData: { value: +selectedFrequencyCap.value },
        }
    }

    return {
        title,
        pushMessages,
        onPush,
        lastUpdated,
        campaignName,
        cost,
        improvementStatistics,
        frequencyCaps,
        selectedFrequencyCap,
        pushActionText,
    }
}
