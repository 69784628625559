<template>
    <OpteoLogo :width="44" :height="44" class="opteo-logo" />
    <LoginContainer :width="382" title="Welcome to Opteo">
        <template v-slot:illustration>
            <div class="illustration-container">
                <img src="@/assets/img/welcome-to-opteo-small.png" />
            </div>
        </template>
        <template v-slot:info>
            <div v-if="showForm">
                Your account is almost ready. To complete the process, create a secure password:
            </div>
            <div v-else>Your account is ready. Login below.</div>
        </template>
        <template v-slot:content>
            <oForm id="login-form" @submitted="createPassword()">
                <div v-if="showError" class="error-container">
                    {{ errorMessage }}
                </div>
                <div v-show="loading" style="width: 100%; padding: 0.5rem 0">
                    <Spinner style="margin: 0 auto" />
                </div>
                <div v-show="!unrecoverable && !loading">
                    <div v-show="showForm">
                        <oInput
                            id="new-password-input"
                            name="password_field"
                            v-model="password"
                            type="password"
                            label="New Password"
                            minlength="8"
                            autofocus
                            required
                        />
                        <Spacer height="1.5rem" />
                        <oInput
                            id="repeat-new-password-input"
                            name="password2_field"
                            ref="password2Field"
                            v-model="password2"
                            type="password"
                            label="Confirm New Password"
                            minlength="8"
                            required
                        />
                        <Spacer height="2rem" />
                        <oButton
                            id="send-reset-link"
                            :loading="formLoading"
                            color="blue"
                            size="login"
                            type="submit"
                            style="width: 100%"
                        >
                            Create Password
                        </oButton>
                    </div>
                    <div v-show="!showForm">
                        <router-link to="/login">
                            <oButton id="go-login" color="blue" size="login" style="width: 100%">
                                Go to Login
                            </oButton>
                        </router-link>
                    </div>
                </div>
            </oForm>
        </template>
    </LoginContainer>
</template>

<script lang="ts">
import LoginContainer from '@/components/global/LoginContainer.vue'

import { oButton, oInput, oForm, Spacer, Spinner, OpteoLogo } from '@opteo/components-next'
import { useInviteWelcome } from '../composition/misc/useInviteWelcome'

export default {
    name: 'InviteWelcome',
    setup() {
        return useInviteWelcome()
    },
    components: { LoginContainer, oButton, oInput, oForm, Spacer, Spinner, OpteoLogo },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.illustration-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    aspect-ratio: 15 / 8;
}
.illustration-container img {
    width: 100%;
}

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}

.error-container {
    @include container;
    @include opteo-red;
    font-size: 14px;
}

@media screen and (max-width: $mq-767-max) {
    .opteo-logo {
        display: none;
    }
}
</style>
