<template>
    <FullScreenOverlay @close="handleClose">
        <PageHeader
            page-title="Account Setup"
            :show-in-full-screen="true"
            :no-margin="true"
            :show-avatar="true"
        />
        <!-- Content -->
        <div class="account-setup-wrapper">
            <PageContent showInFullScreen>
                <div class="account-setup-container">
                    <!-- Account Profile -->
                    <section class="form-section">
                        <div class="section-header">
                            <Text as="h4" weight="600">Account Profile</Text>
                        </div>
                        <div class="section-content">
                            <AccountProfile ref="accountProfile" />
                        </div>
                    </section>

                    <!-- Monthly Budget -->
                    <section class="form-section">
                        <div class="section-header">
                            <Text as="h4" weight="600">Monthly Budget</Text>
                        </div>
                        <div class="section-content">
                            <AccountMonthlyBudget
                                ref="accountMonthlyBudget"
                                :account-id="accountId"
                                :currency-symbol="currencySymbol"
                                :currency-code="currencyCode"
                                :account-platform="accountPlatform"
                            />
                        </div>
                    </section>

                    <!-- Performance Mode -->
                    <section class="form-section">
                        <div class="section-header">
                            <Text as="h4" weight="600">Performance Mode</Text>
                        </div>
                        <div class="section-content">
                            <AccountPerformanceMode ref="accountPerformanceMode" />
                        </div>
                    </section>

                    <!-- Conversion Actions -->
                    <section class="form-section">
                        <div class="section-header">
                            <Text as="h4" weight="600">Conversion Actions</Text>
                        </div>
                        <div class="section-content">
                            <AccountConversionActions ref="accountConversionActions" />
                        </div>
                    </section>

                    <!-- Performance Goals -->
                    <section class="form-section">
                        <div class="section-header">
                            <Text as="h4" weight="600">Performance Goals</Text>
                        </div>
                        <div class="section-content">
                            <AccountPerformanceGoals ref="accountPerformanceGoals" />
                        </div>
                    </section>

                    <!-- Google Analytics -->
                    <section
                        class="form-section"
                        v-if="accountPlatform === Platform.Platform.GoogleAds"
                    >
                        <div class="section-header">
                            <Text as="h4" weight="600">Google Analytics</Text>
                        </div>
                        <div class="section-content analytics">
                            <Analytics
                                view="domain-settings"
                                button-color="dark-blue"
                                @before-oauth-redirect="saveSettingsBeforeGa4OAuthFlow"
                            >
                                <template #no-connection-copy>
                                    Connect a Google Analytics account and corresponding view to get
                                    access to Google Analytics data in Opteo.
                                </template>
                            </Analytics>
                        </div>
                    </section>
                </div>
            </PageContent>
        </div>
    </FullScreenOverlay>
</template>

<script lang="ts" setup>
import { SKIP_ONBOARDING_DISMISSAL } from '@/lib/env'

// Vue
import { onMounted, ref } from 'vue'

// Router
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Local components
import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'
import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'
import Analytics from '@/components/analytics/Analytics.vue'
import AccountProfile from '@/components/accountsSettings/AccountProfile.vue'
import AccountMonthlyBudget from '@/components/accountsSettings/AccountMonthlyBudget.vue'
import AccountPerformanceGoals from '@/components/accountsSettings/AccountPerformanceGoals.vue'
import AccountPerformanceMode from '@/components/accountsSettings/AccountPerformanceMode.vue'
import AccountConversionActions from '@/components/accountsSettings/AccountConversionActions.vue'

// Composition
import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { useAccountSetupBar } from '@/composition/account/useAccountSetupBar'
import { useAccount } from '@/composition/account/useAccount'
import { useAccountList } from '@/composition/user/useAccountList'

// Components Next
import { Text } from '@opteo/components-next'

// Types
import { Platform } from '@opteo/types'

const { showAccountSetupBar, stepValidators, setIndex } = useAccountSetupBar()
const { accountId, currencyCode, currencySymbol, accountPlatform } = useAccount()
const { mutateDomainList } = useAccountList()
const router = useRouter()

// Handle close
const handleClose = () => {
    router.push({ name: Routes.Improvements })
}

showAccountSetupBar()
setIndex(0) // Reset the index to the first step

const accountProfile = ref()
const accountMonthlyBudget = ref()
const accountPerformanceMode = ref()
const accountPerformanceGoals = ref()

onMounted(() => {
    mutateDomainList(accountId.value, {
        accountSetupSeen: true,
        lastVisitedAt: new Date().toISOString(),
    })

    if (SKIP_ONBOARDING_DISMISSAL) {
        return
    }

    authRequest(Endpoint.MarkAccountSetupSeen)
})

const saveSettingsBeforeGa4OAuthFlow = async () => {
    await handleSubmit()
}

// Handle submit
const handleSubmit = async () => {
    // Validators
    if (!stepValidators.value['account-setup']) return

    // API requests
    try {
        await Promise.all([
            // Save account profile
            accountProfile.value.updateAccountProfile(),
            // Save Budget
            accountMonthlyBudget.value?.updateAccountMonthlyBudget(),
            // Save Performance Mode
            accountPerformanceMode.value?.updatePerformanceMode(),
            // Save Performance Goals
            accountPerformanceGoals.value?.updatePerformanceGoals(),
        ])
        stepValidators.value['account-setup'].error = false
    } catch (error) {
        stepValidators.value['account-setup'].error = true
    }
}

// Plug in callbacks to the account setup bar

if (stepValidators.value['account-setup'])
    stepValidators.value['account-setup'].callback = handleSubmit
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.account-setup-wrapper {
    margin: 4rem 0 10rem 0;
}

.account-setup-container {
    @include container;
    @include br-32;
}
.form-section {
    @include ph-40;
    @include pb-64;
}

.form-section:not(:last-child) {
    border-bottom: 1px solid $opteo-light-gray;
}
.form-section .section-header {
    line-height: 2.75rem;
    @include flex;
    @include items-center;
    @include pv-28;
}
.form-section .section-content {
    max-width: 23.5rem;
    margin: 0 auto;
    @include flex;
    flex-direction: column;
    @include container;
    @include br-24;
}

// Performance Mode

// Google Analytics
.form-section .section-content.analytics {
    max-width: 26.25rem;
    @include pa-0;
    overflow: hidden;
}

.call-to-action {
    @include flex;
    @include items-center;
    @include justify-center;
}
</style>
