<template>
    <Popout
        v-model="popoutOpen"
        :zIndex="9999999999999"
        :offset="[0, 12]"
        trigger="mouseenter"
        placement="top"
        :maxWidth="status ? '18.625rem' : '19.25rem'"
    >
        <div v-if="status === true" class="status-cell">
            <CheckIcon />
            <Spacer width="0.625rem" />
            <Text as="span" size="f-8" weight="500" color="green">Added</Text>
        </div>
        <div v-else class="status-cell">
            <WarnIcon color="gray" />
            <Spacer width="0.625rem" />
            <Text as="span" size="f-8" weight="500" color="gray">Not Added</Text>
        </div>
        <template v-slot:content>
            <div class="rsa-recommended-search-terms-popout-container">
                <Text as="p" size="f-8" v-if="status === true">
                    This search term <Text as="span" size="f-8" weight="600" color="green">has been added</Text> as an
                    asset (or part of an asset) in your RSA.
                </Text>
                <Text as="p" size="f-8" v-else>
                    This search term <Text as="span" size="f-8" weight="600" color="black">has not been added</Text> as
                    an asset (or part of an asset) in your RSA.
                </Text>
            </div>
        </template>
    </Popout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { Text, Spacer, Popout, CheckIcon, WarnIcon } from '@opteo/components-next'

export default defineComponent({
    components: {
        Popout,
        Text,
        Spacer,
        CheckIcon,
        WarnIcon,
    },
    props: {
        status: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            popoutOpen: false,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.status-cell {
    @include flex;
    @include items-center;
    cursor: default;
    @include no-select;
}
.rsa-recommended-search-terms-popout-container {
    @include ph-24;
    @include pv-20;
}
</style>
