<template>
    <div class="toolkit-section-container">
        <header>
            <Text as="h4" weight="600">{{ title }}</Text>
            <div class="header-slot">
                <slot name="header"></slot>
            </div>
        </header>
        <Spacer height="2.25rem" />
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { Text, Spacer } from '@opteo/components-next'

export default defineComponent({
    name: 'ToolkitContainer',
    components: {
        Text,
        Spacer,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.toolkit-section-container {
    @include container;
    @include br-32;
    @include pt-36;
    @include ph-40;
    @include pb-40;
}
.toolkit-section-container header {
    @include flex;
    @include items-center;
    @include justify-between;
    height: 1.75rem;
}
.toolkit-section-container header .header-slot {
    @include flex;
    @include items-center;
}
</style>
