<template>
    <Modal v-model="isDeleteScorecardModalOpen" title="Delete Scorecard" width="32.5rem">
        <template #content>
            <Text as="p">
                Confirm below to permanently delete this Scorecard and the shared links associated
                with it. This action is permanent and will also delete this scorecard for all
                members of your team.
            </Text>
        </template>
        <template #buttons>
            <oButton @clicked="closeModal()" color="white">Cancel</oButton>
            <Spacer width="0.5rem" />
            <oButton color="red" @clicked="deleteScorecard()">Delete Scorecard</oButton>
        </template>
    </Modal>
</template>

<script lang="ts">
import { computed, defineComponent, inject, Ref } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

import { Text, Spacer, oButton, Modal, showSnackbar } from '@opteo/components-next'

import { Endpoint, authRequest } from '@/composition/api/useAPI'

export default defineComponent({
    name: 'DeleteScorecard',
    props: {
        scorecardId: {
            type: String,
            required: false,
        },
    },
    components: {
        Text,
        Spacer,
        Modal,
        oButton,
        showSnackbar,
    },
    emits: ['close-modal'],
    setup(props) {
        const router = useRouter()

        const injected = inject<{
            isDeleteScorecardModalOpen: Ref<boolean>
            mutateScorecardList?: () => void
        }>('delete-scorecard')
        const closeModal = () => {
            if (injected) {
                injected.isDeleteScorecardModalOpen.value = false
            }
        }

        const deleteScorecard = async () => {
            if (!props.scorecardId) {
                throw new Error('Cannot delete scorecard. Missing id')
            }

            await authRequest(Endpoint.DeleteScorecard, {
                body: {
                    scorecard_id: props.scorecardId,
                },
            })

            if (injected?.mutateScorecardList) {
                console.log(injected?.mutateScorecardList)
                injected.mutateScorecardList()
            }

            closeModal()
            router.push({ name: Routes.ToolkitScorecardList })

            showSnackbar({
                message: `Scorecard deleted successfully.`,
                timeout: 3000,
            })
        }

        return {
            isDeleteScorecardModalOpen: injected?.isDeleteScorecardModalOpen,
            deleteScorecard,
            closeModal,
        }
    },
})
</script>
