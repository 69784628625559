<template>
    <div class="rsa-ad-strength">
        <ScorecardDonut
            v-if="value === AdStrength.EXCELLENT"
            :score="100"
            :compact="true"
            :width="24"
            :strokeWidth="2"
            :hideScore="true"
        />
        <ScorecardDonut
            v-if="value === AdStrength.GOOD"
            :score="75"
            :compact="true"
            :width="24"
            :strokeWidth="2"
            :hideScore="true"
        />
        <ScorecardDonut
            v-if="value === AdStrength.AVERAGE"
            :score="51"
            :compact="true"
            :width="24"
            :strokeWidth="2"
            :hideScore="true"
        />
        <ScorecardDonut
            v-if="value === AdStrength.POOR"
            :score="25"
            :compact="true"
            :width="24"
            :strokeWidth="2"
            :hideScore="true"
        />
        <ScorecardDonut
            v-if="value === AdStrength.PENDING"
            :score="0"
            :compact="true"
            :width="24"
            :strokeWidth="2"
            :hideScore="true"
            :hidden="true"
        />
        <!-- TODO make the grey class work -->
        <Text
            v-if="value"
            :class="[
                { green: value === AdStrength.EXCELLENT || value === AdStrength.GOOD },
                { amber: value === AdStrength.AVERAGE },
                { red: value === AdStrength.POOR },
                { grey: value === AdStrength.PENDING },
            ]"
            as="p"
            class="text-slot"
        >
            {{ text }}
        </Text>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { Gads } from '@opteo/types'

import { Text } from '@opteo/components-next'
import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'

export default defineComponent({
    name: 'RsaAdStrength',
    components: { Text, ScorecardDonut },
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const text = computed(() => {
            switch (props.value) {
                case Gads.enums.AdStrength.POOR:
                    return 'Poor'
                case Gads.enums.AdStrength.AVERAGE:
                    return 'Average'
                case Gads.enums.AdStrength.GOOD:
                    return 'Good'
                case Gads.enums.AdStrength.EXCELLENT:
                    return 'Excellent'
                case Gads.enums.AdStrength.PENDING:
                    return 'Pending'
            }
        })

        return { text, AdStrength: Gads.enums.AdStrength }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Base
.rsa-ad-strength {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none;
    font-family: inherit;
    transition: transform 0.25s cubic-bezier(0.19, 1, 0.22, 1), background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0.625rem;
    position: relative;
    font-weight: 500;
    user-select: none;
    -webkit-user-select: none;
    @include opteo-foreground;
    box-shadow: $opteo-shadow;
    padding: 0.625rem 0.8125rem;
    overflow: hidden;
}
.rsa-ad-strength .text-slot {
    @include ml-8;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8125rem;
    letter-spacing: -0.0125rem;
    font-weight: 500;
}
</style>
